import type { CmlContent, CmlDefinition } from 'bundles/cml';
import type { ProductGlanceList } from 'bundles/enterprise-legacy-xdp/components/ProductGlance';
import {
  make100percentOnlineCourseGlance,
  makeCourseCanBeOfferedForCreditGlance,
  makeCourseLimitedAvailabilityGlance,
  makeCourseOfferedForCreditGlance,
  makeCourseRecommendedForCreditGlance,
  makeDifficultyLevelGlance,
  makeExclusiveCourseGlance,
  makeFixedScheduleGlance,
  makeFlexibleDeadlineGlance,
  makeHasCourseraLabsGlance,
  makeHoursToCompleteGlance,
  makeLanguagesGlance,
  makeMultipleAvailabilityOptionsGlance,
  makeS12nGlance,
  makeShareAbleC4CCertificateGlance,
  makeShareAbleCertificateGlance,
} from 'bundles/enterprise-legacy-xdp/components/atAGlanceItems';
import type S12nDerivativesV1 from 'bundles/naptimejs/resources/s12nDerivatives.v1';
import type {
  DifficultyLevel,
  EnterpriseProductConfiguration,
  Specialization,
} from 'bundles/program-common/types/xdpTypes';
import { isAppliedProjectManagementCert } from 'bundles/program-common/utils/xdpUtils';
import {
  ExternalCertificateS12n,
  ProfessionalCertificateS12n,
} from 'bundles/s12n-common/constants/s12nProductVariants';
import type { S12nProductVariant } from 'bundles/s12n-common/constants/s12nProductVariants';

type CourseAtAGlanceConfig = {
  activeCourseId: string;
  activeS12n?: Specialization;
  avgLearningHoursAdjusted?: number;
  cmlRecommendedBackground?: { cml: CmlDefinition };
  htmlRecommendedBackground?: string;
  hasCertificate?: boolean;
  isSCDP?: boolean;
  s12nProductVariant?: S12nProductVariant;
  level: DifficultyLevel;
  primaryLanguages: Array<string>;
  subtitleLanguages: Array<string>;
  s12ns?: Array<Specialization>;
  isPrivate?: boolean;
  privateSessionDates?: { startsAt: number; endsAt: number };
  isC4C?: boolean;
  enterpriseProductConfiguration?: EnterpriseProductConfiguration;
  isEnterpriseAdminView?: boolean;
  enableCurriculumBuilder?: boolean;
  hasMultipleAvailabilities?: boolean;
  s12nDerivative?: S12nDerivativesV1;
  showCourseraLabsGlance?: boolean;
};

const getCourseGlanceList = (config: CourseAtAGlanceConfig): ProductGlanceList => {
  const {
    activeCourseId,
    activeS12n,
    avgLearningHoursAdjusted,
    hasCertificate,
    isSCDP,
    s12nProductVariant,
    level,
    cmlRecommendedBackground,
    primaryLanguages = [],
    s12ns = [],
    subtitleLanguages = [],
    isPrivate,
    privateSessionDates,
    isC4C,
    enterpriseProductConfiguration,
    isEnterpriseAdminView,
    enableCurriculumBuilder,
    hasMultipleAvailabilities,
    showCourseraLabsGlance,
  } = config;

  const s12nCourses = activeS12n ? activeS12n.courses : [];
  const activeCourseNumber = s12nCourses.findIndex(({ id }) => id === activeCourseId) + 1;
  const backgroundLevelCml: CmlContent | undefined = cmlRecommendedBackground && {
    typeName: 'cml',
    definition: cmlRecommendedBackground.cml,
  };
  const showFlexibleScheduleCopy = !(activeS12n && isAppliedProjectManagementCert(activeS12n.id));

  const hasMultipleS12ns = s12ns.length > 1;
  const shouldShowS12nGlance = !!activeS12n && !!s12nCourses && !hasMultipleS12ns;
  const hasEarnableCertificate =
    s12nProductVariant === ProfessionalCertificateS12n ||
    s12nProductVariant === ExternalCertificateS12n ||
    isSCDP ||
    hasCertificate;

  const showShareableCertificateGlance = hasEarnableCertificate;
  const glanceList = [
    isPrivate ? makeExclusiveCourseGlance() : make100percentOnlineCourseGlance(showFlexibleScheduleCopy),
  ];

  if (showCourseraLabsGlance) {
    glanceList.push(makeHasCourseraLabsGlance());
  }

  if (showShareableCertificateGlance && isC4C) {
    glanceList.unshift(makeShareAbleC4CCertificateGlance(isEnterpriseAdminView));
  } else if (showShareableCertificateGlance) {
    glanceList.unshift(makeShareAbleCertificateGlance());
  }

  if (isEnterpriseAdminView && enableCurriculumBuilder) {
    glanceList.unshift(
      hasMultipleAvailabilities ? makeMultipleAvailabilityOptionsGlance() : makeCourseLimitedAvailabilityGlance()
    );

    if (isC4C && enterpriseProductConfiguration?.isRecommendedForCredit) {
      glanceList.unshift(makeCourseRecommendedForCreditGlance());
    } else if (isC4C) {
      glanceList.unshift(makeCourseCanBeOfferedForCreditGlance());
    }
  }

  if (!isEnterpriseAdminView) {
    if (enterpriseProductConfiguration?.isSelectedForCredit) {
      glanceList.unshift(makeCourseOfferedForCreditGlance());
    }

    if (privateSessionDates) {
      glanceList.unshift(makeFixedScheduleGlance(privateSessionDates.startsAt, privateSessionDates.endsAt));
    } else if (showFlexibleScheduleCopy) {
      glanceList.unshift(makeFlexibleDeadlineGlance());
    }
  }

  if (shouldShowS12nGlance && activeS12n) {
    glanceList.push(
      makeS12nGlance({
        activeCourseNumber,
        numCourses: s12nCourses.length,
        activeS12n,
      })
    );
  }

  if (level) {
    glanceList.push(makeDifficultyLevelGlance(level, backgroundLevelCml));
  }

  if (avgLearningHoursAdjusted) {
    glanceList.push(makeHoursToCompleteGlance(avgLearningHoursAdjusted));
  }

  if (primaryLanguages.length > 0) {
    glanceList.push(makeLanguagesGlance(primaryLanguages, subtitleLanguages));
  }

  return glanceList.filter((itm) => itm);
};

export default getCourseGlanceList;
