import type React from 'react';
import { useCallback, useMemo } from 'react';

import { debounce } from 'lodash';
import type { Editor } from 'slate';

import { KEYDOWN_HANDLERS } from 'bundles/cml/editor/keyboard/constants';
import type { Options } from 'bundles/cml/editor/keyboard/types';

export const useKeyDownHandler = (editor: Editor, options: Options) => {
  const onKeyDown = useMemo(
    () =>
      debounce(() => {
        editor.composing = false; // eslint-disable-line no-param-reassign
      }, 50),
    [editor]
  );

  return useCallback(
    (event: React.KeyboardEvent) => {
      editor.composing = true; // eslint-disable-line no-param-reassign
      onKeyDown();

      for (const keydownHandler of KEYDOWN_HANDLERS) {
        if (keydownHandler(editor, event, options)) {
          return;
        }
      }
    },
    [editor, options, onKeyDown]
  );
};
