/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import { LoadingItem } from '@coursera/cds-core';

import ProgressTrackerPopoverContent from 'bundles/skills-common/private/progress-tracker/ProgressTrackerPopoverContent';
import {
  generateMyScorePercentileCopy,
  generateMyScoreScoreCopy,
} from 'bundles/skills-common/private/progress-tracker/messages';
import useSkillPercentileData from 'bundles/skills-common/private/progress-tracker/useSkillPercentileData';
import { isSkillsInsightsTooltip } from 'bundles/skills-common/private/skillsInsightsEnabled';

type Props = {
  score: number;
  skillId?: string;
  skillName: string;
  occupationName?: string;
  occupation?: Occupation;
  target?: number;
  percentile?: number | null;
};

export const ProgressTrackerMyScorePopoverWithScore: React.FC<Props> = ({
  score,
  skillName,
  occupation,
  occupationName = '',
  skillId,
  target,
  percentile: overridePercentile,
}) => {
  const { id = '' } = occupation ?? {};
  const skillsInsightTooltip = isSkillsInsightsTooltip();
  const shouldUseOverridePercentile = overridePercentile !== null && overridePercentile !== undefined;
  const { loading, skillPercentile } = useSkillPercentileData({
    // Can't check !!overridePercentile because the percentile could be 0
    // also, skip if the skills insight is not a tooltip
    shouldSkipQuery: shouldUseOverridePercentile || !skillId || skillsInsightTooltip,
    occupationId: id,
    // We check that there is a skill id in the shouldSkipQuery field

    skillScores: [{ skillId: skillId!, score }],
  });

  if (loading) return <LoadingItem />;

  const percentile = shouldUseOverridePercentile ? overridePercentile : skillPercentile[0]?.percentile;

  const copy =
    skillsInsightTooltip && percentile && score > 150
      ? generateMyScorePercentileCopy(Math.max(100 - percentile, 1), occupationName) // Invert the percentile (to get the top %), and make sure that it is never 0 or below
      : generateMyScoreScoreCopy(score);

  return (
    <ProgressTrackerPopoverContent
      trackingName="skill_tracker_score_popover"
      trackingData={{
        skillName,
        score,
        target,
        percentile: percentile ? 100 - percentile : 0,
      }}
      copy={copy}
      score={score}
      skillName={skillName}
    />
  );
};

export type Occupation = {
  id: string;
  name: string;
};

export default ProgressTrackerMyScorePopoverWithScore;
