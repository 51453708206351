import * as React from 'react';

import { SvgLoaderSignal } from '@coursera/coursera-ui/svg';

const LoadingSpinner = ({ size }: { size?: number }) => {
  return (
    <div className="rc-LoadingSpinner horizontal-box align-items-absolute-center">
      <SvgLoaderSignal size={size} />
    </div>
  );
};

export default LoadingSpinner;
