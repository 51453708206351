import * as React from 'react';

import type { XdpV1_Org_Coursera_Xdp_Common_XdpCourseWeek as CDPWeek } from '__generated__/graphql-types';
import { pure } from 'recompose';

import cookie from 'js/lib/cookie';

import { Box, Card, CardBlock, StyleSheet, color, css, font, gradient, spacing } from '@coursera/coursera-ui';

import IENotificationForGuidedProject from 'bundles/common/components/IENotificationForGuidedProject';
import CourseReviewPaginatedOverview from 'bundles/coursera-ui/components/rich/MiniXDP/CourseReviewPaginatedOverview';
import type { VideoData } from 'bundles/enterprise-legacy-xdp/components/MiniXDPVideoPlayer';
import MiniXDPVideoPlayer from 'bundles/enterprise-legacy-xdp/components/MiniXDPVideoPlayer';
import AboutCourse from 'bundles/enterprise-legacy-xdp/components/cdp/AboutCourse';
import CourseRating from 'bundles/enterprise-legacy-xdp/components/cdp/CourseRating';
import Syllabus from 'bundles/enterprise-legacy-xdp/components/cdp/syllabus/Syllabus';
import InstructorListSection from 'bundles/enterprise-legacy-xdp/components/instructors/InstructorListSection';
import MiniPartnerList from 'bundles/enterprise-legacy-xdp/components/partners/MiniPartnerList';
import { CreditBadge } from 'bundles/enterprise-ui/components/Badges';
import type { LearnerIncentive as LearnerIncentiveType } from 'bundles/learner-incentives/types';
import type {
  DifficultyLevel,
  EnterpriseProductConfiguration,
  InstructorType,
  Partner,
  Specialization,
} from 'bundles/program-common/types/xdpTypes';
import type { CourseTypeMetadata } from 'bundles/program-common/utils/courseTypeMetadataUtils';
import { isGuidedProject } from 'bundles/program-common/utils/courseTypeMetadataUtils';
import { getTotalHoursFromCDPWeeks } from 'bundles/program-common/utils/xdpUtils';
import UserDataProvider from 'bundles/unified-description-page-common/providers/user/UserDataProvider';
import { setOrUpdateRecentlyViewedXDP } from 'bundles/unified-description-page-common/utils/setOrUpdateRecentlyViewedXDP';

const styles = StyleSheet.create({
  MiniCDP: {
    minHeight: 80,
    minWidth: spacing.minWidth,
    scrollBehavior: 'smooth',
  },
  gradientLine: {
    background: `linear-gradient(-135deg, ${gradient.sea.start}, ${gradient.sea.end}) `,
    opacity: 0.85,
    width: '100%',
    height: 10,
  },
  titleAndPartner: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },
  },
  partnerContainer: {
    color: color.secondaryText,
    fontSize: font.xs,
  },
  courseDetailContainer: {
    borderBottom: `1px solid ${color.divider}`,
  },
  courseInfoContainer: {
    padding: 0,
  },
  courseRatingContainer: {
    width: '100%',
    display: 'block',
  },
  actionElementContainer: {
    width: '100%',
    display: 'block',
  },
  buttonShowContainer: {
    position: 'absolute',
    right: 0,
    bottom: spacing.lg,
  },
  viewSylabusText: {
    borderBottom: 'none',
  },
  description: {
    whiteSpace: 'pre-line',
  },
  courseMetadataContainer: {
    display: 'flex',
    marginTop: '18px',
  },
  creditBadgeContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    maxWidth: 1200,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

type Course = {
  id: string;
  name: string;
  description: string;
  instructors: Array<InstructorType>;
  learningObjectives: string;
  level: DifficultyLevel;
  material: { weeks: Array<CDPWeek> } | null;
  primaryLanguages: Array<string>;
  ratings: { commentCount: number; ratingCount: number; averageFiveStarRating: number };
  slug: string;
  skills: Array<string>;
  s12ns: Array<Specialization>;
  subtitleLanguages: Array<string>;
  partners: Array<Partner>;
  topReviews: Array<unknown>;
  reviewHighlights: Array<unknown>;
  courseTypeMetadata: CourseTypeMetadata;
  workload: string;
  totalContentHours: number;
  avgLearningHoursAdjusted: string;
  certificates?: string[];
  display?: boolean;
};

type DefaultProps = { tag?: typeof Card | 'div' };

export type Props = {
  course: Course;
  actionElement?: JSX.Element;
  htmlAttributes: { 'data-e2e': string };
  topNavigationElement?: JSX.Element;
  videoData?: VideoData;
  showNoCertificateNotification?: boolean;
  privateSessionDates?: { startsAt: number; endsAt: number };
  ratingColor?: string;
  enterpriseProductConfiguration?: EnterpriseProductConfiguration;
  isEnterpriseAdminView?: boolean;
  isC4C?: boolean;
  enableCurriculumBuilder?: boolean;
  hasMultipleAvailabilities?: boolean;
  disableS12nUpsellLinks?: boolean;
  learnerIncentives?: Array<LearnerIncentiveType>;
  enableRecentlyViewedHistory?: boolean;
  hasLabAssignments?: boolean;
} & DefaultProps;

class MiniCDP extends React.Component<Props> {
  declare _div?: HTMLElement | null;

  componentDidMount() {
    this.scrollTop();
    const id = this.props.course.id;
    const sessionId = cookie.get('__204u');
    if (sessionId && id && this.props.enableRecentlyViewedHistory) {
      setOrUpdateRecentlyViewedXDP('COURSE~' + id, sessionId);
    }
  }

  // force modal to scroll to top
  scrollTop = () => {
    setTimeout(() => {
      if (this._div?.parentElement) {
        this._div.parentElement.scrollTop = 0;
      }
    }, 200);
  };

  render() {
    const {
      course: {
        id,
        name,
        description,
        instructors,
        level,
        material,
        primaryLanguages,
        ratings,
        slug,
        skills,
        s12ns,
        subtitleLanguages,
        partners,
        topReviews,
        reviewHighlights,
        courseTypeMetadata,
        certificates,
        display,
      },
      actionElement,
      videoData,
      htmlAttributes,
      tag: Tag = Card,
      topNavigationElement,
      showNoCertificateNotification,
      privateSessionDates,
      ratingColor,
      enterpriseProductConfiguration,
      isEnterpriseAdminView,
      isC4C,
      enableCurriculumBuilder,
      hasMultipleAvailabilities,
      disableS12nUpsellLinks,
      learnerIncentives,
      hasLabAssignments,
    } = this.props;
    const tagProps = Tag === 'div' ? { ...htmlAttributes } : { htmlAttributes };
    const isProject = isGuidedProject(courseTypeMetadata);
    const weeks = material?.weeks;

    return (
      <UserDataProvider>
        <div
          {...css(styles.MiniCDP)}
          ref={(ref) => {
            this._div = ref;
          }}
        >
          <Tag {...tagProps}>
            <CardBlock isFullBleed>
              <div {...css(styles.gradientLine)} />
              {videoData && <MiniXDPVideoPlayer videoData={videoData} courseId={id} courseName={name} />}
              {topNavigationElement && <div {...css(styles.actionElementContainer)}>{topNavigationElement}</div>}
              <div {...css(styles.contentContainer)}>
                <div {...css(styles.content)}>
                  <div {...css(styles.courseInfoContainer)}>
                    <div className="p-t-2 p-b-1 p-x-3">
                      <div {...css(styles.titleAndPartner)}>
                        <div>
                          <div {...css('horizontal-box wrap m-r-1 m-b-0')}>
                            <h2 id="programMiniModalName" aria-live="polite" aria-busy={!name} className="m-r-1">
                              {name}
                            </h2>
                          </div>
                          <div {...css(styles.courseMetadataContainer)}>
                            {!isEnterpriseAdminView && enterpriseProductConfiguration?.isSelectedForCredit && (
                              <div {...css(styles.creditBadgeContainer)}>
                                <CreditBadge className="m-r-2 m-b-1s" />
                              </div>
                            )}
                            {ratings && !!(ratings.averageFiveStarRating || ratings.ratingCount) && (
                              <CourseRating
                                averageFiveStarRating={ratings.averageFiveStarRating}
                                ratingCount={ratings.ratingCount}
                                ratingColor={ratingColor}
                                commentCount={ratings.commentCount}
                                rootClassName="m-b-1s"
                              />
                            )}
                          </div>
                        </div>
                        <Box rootClassName={styles.partnerContainer} alignItems="center" justifyContent="start">
                          {partners && partners.length > 0 && (
                            <MiniPartnerList partners={partners} tempDisplayPartnerLimit={1} showBackground={true} />
                          )}
                        </Box>
                      </div>
                      {actionElement && <div {...css(styles.actionElementContainer)}>{actionElement}</div>}
                    </div>
                  </div>
                  <IENotificationForGuidedProject rootClassName="p-x-3 p-y-1" isProject={isProject} />
                  <div className="p-x-3">
                    <AboutCourse
                      activeCourseId={id}
                      activeCourseName={name}
                      activeCourseSlug={slug}
                      description={description}
                      level={level}
                      primaryColor={color.black}
                      primaryLanguages={primaryLanguages}
                      skills={skills}
                      subtitleLanguages={subtitleLanguages}
                      s12ns={s12ns}
                      showProductMetrics={false}
                      avgLearningHoursAdjusted={weeks ? getTotalHoursFromCDPWeeks(weeks) : undefined}
                      hasCertificate={certificates && certificates.length > 0}
                      showNoCertificateNotification={showNoCertificateNotification || false}
                      isPrivate={display === false}
                      privateSessionDates={privateSessionDates}
                      enterpriseProductConfiguration={enterpriseProductConfiguration}
                      isC4C={isC4C}
                      isEnterpriseAdminView={isEnterpriseAdminView}
                      enableCurriculumBuilder={enableCurriculumBuilder}
                      hasMultipleAvailabilities={hasMultipleAvailabilities}
                      disableS12nUpsellLinks={disableS12nUpsellLinks}
                      learnerIncentives={learnerIncentives}
                      showCourseraLabsGlance={hasLabAssignments}
                    />
                  </div>
                  {weeks && (
                    <div className="border-top p-b-1 p-x-3">
                      <Syllabus activeCourseId={id} slug={slug} weeks={weeks} />
                    </div>
                  )}
                  {ratings && ratings.ratingCount > 0 && (
                    <div className="bg-light-blue-2 p-t-3 p-b-2 p-x-3">
                      <CourseReviewPaginatedOverview
                        id={id}
                        topReviews={topReviews}
                        ratings={ratings}
                        reviewHighlights={reviewHighlights}
                      />
                    </div>
                  )}
                  {instructors && instructors.length > 0 && (
                    <div className="p-b-2 p-t-3 p-x-3">
                      <InstructorListSection id={id} instructors={instructors} noLink={true} />
                    </div>
                  )}
                </div>
              </div>
            </CardBlock>
          </Tag>
        </div>
      </UserDataProvider>
    );
  }
}

export default pure(MiniCDP);
export const BaseComponent = MiniCDP;
