import { Editor, Element } from 'slate';

import { isMarkActive } from 'bundles/cml/editor/utils/markUtils';
import { BLOCK_TYPES, MARKS } from 'bundles/cml/shared/constants';
import type { BLOCK_VALUES, MARK_VALUES } from 'bundles/cml/shared/constants';
import type { BlockElement, InlineElement } from 'bundles/cml/shared/types/elementTypes';

type DisabledType = {
  blocks: Set<BLOCK_VALUES>;
  marks: MARK_VALUES[];
};

const DISABLED_TYPES: Record<string, DisabledType> = {
  [BLOCK_TYPES.HEADING]: {
    blocks: new Set<BLOCK_VALUES>([
      BLOCK_TYPES.IMAGE,
      BLOCK_TYPES.LEGACY_AUDIO,
      BLOCK_TYPES.BULLET_LIST,
      BLOCK_TYPES.NUMBER_LIST,
      BLOCK_TYPES.TABLE_CELL,
      BLOCK_TYPES.ASSET,
      BLOCK_TYPES.CODE,
      BLOCK_TYPES.FILLABLE_BLANK,
      BLOCK_TYPES.MATH_BLOCK,
      BLOCK_TYPES.MATH_INLINE,
      BLOCK_TYPES.WIDGET,
    ]),
    marks: [],
  },
  [BLOCK_TYPES.TEXT]: {
    blocks: new Set<BLOCK_VALUES>([
      BLOCK_TYPES.IMAGE,
      BLOCK_TYPES.LEGACY_AUDIO,
      BLOCK_TYPES.ASSET,
      BLOCK_TYPES.MATH_BLOCK,
      BLOCK_TYPES.CODE,
      BLOCK_TYPES.WIDGET,
    ]),
    marks: [],
  },
  [MARKS.BOLD]: {
    blocks: new Set([
      BLOCK_TYPES.IMAGE,
      BLOCK_TYPES.LEGACY_AUDIO,
      BLOCK_TYPES.ASSET,
      BLOCK_TYPES.CODE,
      BLOCK_TYPES.FILLABLE_BLANK,
      BLOCK_TYPES.MATH_BLOCK,
      BLOCK_TYPES.MATH_INLINE,
      BLOCK_TYPES.WIDGET,
    ]),
    marks: [MARKS.MONOSPACE],
  },
  [MARKS.ITALIC]: {
    blocks: new Set([
      BLOCK_TYPES.ASSET,
      BLOCK_TYPES.LEGACY_AUDIO,
      BLOCK_TYPES.IMAGE,
      BLOCK_TYPES.CODE,
      BLOCK_TYPES.FILLABLE_BLANK,
      BLOCK_TYPES.MATH_BLOCK,
      BLOCK_TYPES.MATH_INLINE,
      BLOCK_TYPES.WIDGET,
    ]),
    marks: [MARKS.MONOSPACE],
  },
  [MARKS.UNDERLINE]: {
    blocks: new Set([
      BLOCK_TYPES.IMAGE,
      BLOCK_TYPES.LEGACY_AUDIO,
      BLOCK_TYPES.ASSET,
      BLOCK_TYPES.CODE,
      BLOCK_TYPES.FILLABLE_BLANK,
      BLOCK_TYPES.MATH_BLOCK,
      BLOCK_TYPES.MATH_INLINE,
      BLOCK_TYPES.LINK,
      BLOCK_TYPES.WIDGET,
    ]),
    marks: [MARKS.MONOSPACE],
  },
  [MARKS.MONOSPACE]: {
    blocks: new Set([
      BLOCK_TYPES.IMAGE,
      BLOCK_TYPES.ASSET,
      BLOCK_TYPES.LEGACY_AUDIO,
      BLOCK_TYPES.CODE,
      BLOCK_TYPES.LINK,
      BLOCK_TYPES.FILLABLE_BLANK,
      BLOCK_TYPES.MATH_BLOCK,
      BLOCK_TYPES.MATH_INLINE,
      BLOCK_TYPES.WIDGET,
    ]),
    marks: [MARKS.BOLD, MARKS.ITALIC, MARKS.UNDERLINE],
  },
  [BLOCK_TYPES.LINK]: {
    blocks: new Set([
      BLOCK_TYPES.IMAGE,
      BLOCK_TYPES.ASSET,
      BLOCK_TYPES.LEGACY_AUDIO,
      BLOCK_TYPES.CODE,
      BLOCK_TYPES.PERSONALIZATION_TAG,
      BLOCK_TYPES.FILLABLE_BLANK,
      BLOCK_TYPES.MATH_BLOCK,
      BLOCK_TYPES.MATH_INLINE,
      BLOCK_TYPES.LINK,
      BLOCK_TYPES.WIDGET,
    ]),
    marks: [MARKS.MONOSPACE],
  },
  [BLOCK_TYPES.BULLET_LIST]: {
    blocks: new Set([
      BLOCK_TYPES.IMAGE,
      BLOCK_TYPES.LEGACY_AUDIO,
      BLOCK_TYPES.HEADING,
      BLOCK_TYPES.ASSET,
      BLOCK_TYPES.CODE,
      BLOCK_TYPES.MATH_BLOCK,
      BLOCK_TYPES.NUMBER_LIST,
      BLOCK_TYPES.WIDGET,
    ]),
    marks: [],
  },
  [BLOCK_TYPES.NUMBER_LIST]: {
    blocks: new Set([
      BLOCK_TYPES.IMAGE,
      BLOCK_TYPES.LEGACY_AUDIO,
      BLOCK_TYPES.HEADING,
      BLOCK_TYPES.ASSET,
      BLOCK_TYPES.CODE,
      BLOCK_TYPES.MATH_BLOCK,
      BLOCK_TYPES.BULLET_LIST,
      BLOCK_TYPES.WIDGET,
    ]),
    marks: [],
  },
  [BLOCK_TYPES.TABLE]: {
    blocks: new Set([
      BLOCK_TYPES.IMAGE,
      BLOCK_TYPES.LEGACY_AUDIO,
      BLOCK_TYPES.BULLET_LIST,
      BLOCK_TYPES.NUMBER_LIST,
      BLOCK_TYPES.LIST_ITEM,
      BLOCK_TYPES.HEADING,
      BLOCK_TYPES.ASSET,
      BLOCK_TYPES.TABLE,
      BLOCK_TYPES.CODE,
      BLOCK_TYPES.FILLABLE_BLANK,
      BLOCK_TYPES.MATH_BLOCK,
      BLOCK_TYPES.MATH_INLINE,
      BLOCK_TYPES.WIDGET,
    ]),
    marks: [],
  },
  [BLOCK_TYPES.IMAGE]: {
    blocks: new Set([
      BLOCK_TYPES.LINK,
      BLOCK_TYPES.LEGACY_AUDIO,
      BLOCK_TYPES.BULLET_LIST,
      BLOCK_TYPES.NUMBER_LIST,
      BLOCK_TYPES.TABLE,
      BLOCK_TYPES.ASSET,
      BLOCK_TYPES.CODE,
      BLOCK_TYPES.FILLABLE_BLANK,
      BLOCK_TYPES.MATH_BLOCK,
      BLOCK_TYPES.MATH_INLINE,
      BLOCK_TYPES.WIDGET,
    ]),
    marks: [],
  },
  [BLOCK_TYPES.FILLABLE_BLANK]: {
    blocks: new Set([
      BLOCK_TYPES.IMAGE,
      BLOCK_TYPES.ASSET,
      BLOCK_TYPES.LEGACY_AUDIO,
      BLOCK_TYPES.CODE,
      BLOCK_TYPES.LINK,
      BLOCK_TYPES.MATH_BLOCK,
      BLOCK_TYPES.MATH_INLINE,
      BLOCK_TYPES.WIDGET,
    ]),
    marks: [MARKS.BOLD, MARKS.ITALIC, MARKS.UNDERLINE, MARKS.SUPERSCRIPT, MARKS.SUBSCRIPT],
  },
  [BLOCK_TYPES.ASSET]: {
    blocks: new Set([
      BLOCK_TYPES.LINK,
      BLOCK_TYPES.BULLET_LIST,
      BLOCK_TYPES.NUMBER_LIST,
      BLOCK_TYPES.TABLE_CELL,
      BLOCK_TYPES.IMAGE,
      BLOCK_TYPES.LEGACY_AUDIO,
      BLOCK_TYPES.CODE,
      BLOCK_TYPES.FILLABLE_BLANK,
      BLOCK_TYPES.MATH_BLOCK,
      BLOCK_TYPES.MATH_INLINE,
      BLOCK_TYPES.WIDGET,
    ]),
    marks: [],
  },
  [BLOCK_TYPES.CODE]: {
    blocks: new Set([
      BLOCK_TYPES.HEADING,
      BLOCK_TYPES.LINK,
      BLOCK_TYPES.BULLET_LIST,
      BLOCK_TYPES.NUMBER_LIST,
      BLOCK_TYPES.TABLE,
      BLOCK_TYPES.ASSET,
      BLOCK_TYPES.IMAGE,
      BLOCK_TYPES.LEGACY_AUDIO,
      BLOCK_TYPES.TABLE,
      BLOCK_TYPES.TABLE_CELL,
      BLOCK_TYPES.FILLABLE_BLANK,
      BLOCK_TYPES.MATH_BLOCK,
      BLOCK_TYPES.MATH_INLINE,
      BLOCK_TYPES.WIDGET,
    ]),
    marks: [],
  },
  [BLOCK_TYPES.MATH_INLINE]: {
    blocks: new Set([
      BLOCK_TYPES.ASSET,
      BLOCK_TYPES.IMAGE,
      BLOCK_TYPES.LEGACY_AUDIO,
      BLOCK_TYPES.CODE,
      BLOCK_TYPES.FILLABLE_BLANK,
      BLOCK_TYPES.MATH_BLOCK,
      BLOCK_TYPES.MATH_INLINE,
      BLOCK_TYPES.WIDGET,
    ]),
    marks: [MARKS.BOLD, MARKS.ITALIC, MARKS.UNDERLINE, MARKS.MONOSPACE, MARKS.SUPERSCRIPT, MARKS.SUBSCRIPT],
  },
  [BLOCK_TYPES.MATH_BLOCK]: {
    blocks: new Set([
      BLOCK_TYPES.ASSET,
      BLOCK_TYPES.IMAGE,
      BLOCK_TYPES.LEGACY_AUDIO,
      BLOCK_TYPES.CODE,
      BLOCK_TYPES.FILLABLE_BLANK,
      BLOCK_TYPES.MATH_BLOCK,
      BLOCK_TYPES.MATH_INLINE,
      BLOCK_TYPES.WIDGET,
    ]),
    marks: [MARKS.BOLD, MARKS.ITALIC, MARKS.UNDERLINE, MARKS.MONOSPACE, MARKS.SUPERSCRIPT, MARKS.SUBSCRIPT],
  },
  [MARKS.SUPERSCRIPT]: {
    blocks: new Set([
      BLOCK_TYPES.IMAGE,
      BLOCK_TYPES.ASSET,
      BLOCK_TYPES.LEGACY_AUDIO,
      BLOCK_TYPES.CODE,
      BLOCK_TYPES.FILLABLE_BLANK,
      BLOCK_TYPES.MATH_BLOCK,
      BLOCK_TYPES.MATH_INLINE,
      BLOCK_TYPES.WIDGET,
    ]),
    marks: [MARKS.SUBSCRIPT],
  },
  [MARKS.SUBSCRIPT]: {
    blocks: new Set([
      BLOCK_TYPES.IMAGE,
      BLOCK_TYPES.LEGACY_AUDIO,
      BLOCK_TYPES.ASSET,
      BLOCK_TYPES.CODE,
      BLOCK_TYPES.FILLABLE_BLANK,
      BLOCK_TYPES.MATH_BLOCK,
      BLOCK_TYPES.MATH_INLINE,
      BLOCK_TYPES.WIDGET,
    ]),
    marks: [MARKS.SUPERSCRIPT],
  },
  [BLOCK_TYPES.WIDGET]: {
    blocks: new Set([
      BLOCK_TYPES.LINK,
      BLOCK_TYPES.BULLET_LIST,
      BLOCK_TYPES.NUMBER_LIST,
      BLOCK_TYPES.TABLE_CELL,
      BLOCK_TYPES.IMAGE,
      BLOCK_TYPES.LEGACY_AUDIO,
      BLOCK_TYPES.CODE,
      BLOCK_TYPES.FILLABLE_BLANK,
      BLOCK_TYPES.MATH_BLOCK,
      BLOCK_TYPES.MATH_INLINE,
      BLOCK_TYPES.WIDGET,
    ]),
    marks: [],
  },
  [BLOCK_TYPES.AI_ELEMENT]: {
    blocks: new Set([
      BLOCK_TYPES.IMAGE,
      BLOCK_TYPES.ASSET,
      BLOCK_TYPES.WIDGET,
      BLOCK_TYPES.FILLABLE_BLANK,
      BLOCK_TYPES.LEGACY_AUDIO,
      BLOCK_TYPES.AI_ELEMENT,
    ]),
    // @ts-expect-error
    marks: ['selected'],
  },
};

export const isTypeDisabled = (editor: Editor, type: BLOCK_VALUES | MARK_VALUES): boolean => {
  const { blocks, marks } = DISABLED_TYPES[type] ?? { blocks: new Set(), marks: [] };

  const entries = Array.from(
    Editor.nodes<BlockElement | InlineElement>(editor, {
      match: (node) => Element.isElement(node) && blocks.has(node.type),
    })
  );

  if (entries.length > 0) {
    return true;
  }

  return marks.some((mark) => isMarkActive(editor, mark));
};
