import moment from 'moment';

import { SHORT_DATE_ONLY_DISPLAY } from 'js/utils/DateTimeUtils';

import _t from 'i18n!nls/promotions';

// AoE (Anywhere on Earth) timezone is UTC-12
const AOE_TIMEZONE_OFFSET = 12;

// Gets the promotion endsAt / couponHardExpiry date based on the AoE timezone-based timestamp
export const getPromotionExpirationDate = (timestamp?: number | string | null, format?: string): string => {
  if (!timestamp) {
    return '';
  }
  return moment
    .utc(timestamp)
    .subtract(AOE_TIMEZONE_OFFSET, 'hours')
    .locale(_t.getLocale())
    .format(format || SHORT_DATE_ONLY_DISPLAY);
};
