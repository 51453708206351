import * as React from 'react';
import { useEffect, useState } from 'react';
import Media from 'react-media';

import DesktopSearchFilters from 'bundles/enterprise-collections/components/filters/DesktopSearchFilters';
import { DOMAIN_PAGE_SEARCH_FILTERS } from 'bundles/enterprise-learner-search/constants';
import { filterHiddenFilters } from 'bundles/enterprise-learner-search/utils/search';
import { useUserAgent } from 'bundles/page/contexts/UserAgentApiContext';
import SearchFiltersComponent from 'bundles/search-common/components/filters/SearchFilters';
import { SEARCH_FILTERS_INDEX_ID, SEARCH_MAIN_INDEX_ID } from 'bundles/search-common/constants';
import { FILTER_OVERFLOW_WIDTH } from 'bundles/search-common/constants/filter';
import { useSearch } from 'bundles/search-common/providers/SearchContext';
import type { SearchFacet, SearchFacetValueAndCounts } from 'bundles/search-common/providers/searchTypes';
import type { SearchFilterOption } from 'bundles/search-common/types';

import 'css!./__styles__/SearchFilters';

const filterSubtraction = (filter: SearchFacetValueAndCounts) => filter.value.indexOf('-') !== 0;

export const SearchFilters = () => {
  const { getSearchResults, addFilters, removeFilters, isLoading } = useSearch();
  const searchData = getSearchResults(SEARCH_MAIN_INDEX_ID);
  const filterData = getSearchResults(SEARCH_FILTERS_INDEX_ID);
  const userAgent = useUserAgent();
  const [filters, setFilters] = useState<Record<string, SearchFilterOption[] | undefined>>({});
  const [facets, setFacets] = useState<SearchFacet[] | undefined | null>([]);
  const [activeFilters, setActiveFilters] = useState<string[]>([]);

  // remove hidden filters set from config from showing up in the UI
  const activeFiltersToShow = activeFilters.filter(filterHiddenFilters);
  const eventingData = { searchIndex: filterData?.source?.indexName || '', searchIndexPosition: 0 };

  useEffect(() => {
    if (!isLoading) {
      const newActiveFilters = [...new Set(searchData?.facetFilters?.flat())];
      setActiveFilters(newActiveFilters);
    }
  }, [isLoading, filterData, searchData]);
  useEffect(() => {
    // handle optimistic active filter changes
    if (isLoading) {
      const filterOptions: Record<string, SearchFilterOption[] | undefined> = {};
      facets?.forEach(({ name, valuesAndCounts }) => {
        filterOptions[name] =
          valuesAndCounts?.filter(filterSubtraction)?.map((filter) => ({
            ...filter,
            label: filter.value,
            isRefined: activeFilters?.includes(`${name}:${filter.value}`),
          })) || [];
      });
      setFilters(filterOptions);
    } else {
      const newActiveFilters = [...new Set(searchData?.facetFilters?.flat())];
      const filterOptions: Record<string, SearchFilterOption[] | undefined> = {};
      filterData?.facets?.forEach(({ name, valuesAndCounts }) => {
        filterOptions[name] =
          valuesAndCounts?.filter(filterSubtraction)?.map((filter) => ({
            ...filter,
            label: filter.value,
            isRefined: newActiveFilters?.includes(`${name}:${filter.value}`),
          })) || [];
      });
      setFilters(filterOptions);
      setFacets(filterData?.facets);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, searchData, filterData, activeFilters]);

  return (
    <div id="main" className="rc-SearchFilters">
      <Media query={{ maxWidth: FILTER_OVERFLOW_WIDTH }} defaultMatches={userAgent && userAgent.isMobileBrowser}>
        {(matches) => (
          <div className={`SearchFilters-container `}>
            {matches ? (
              <SearchFiltersComponent
                filters={filters}
                activeFilters={activeFiltersToShow}
                eventingData={eventingData}
                filtersListOrder={DOMAIN_PAGE_SEARCH_FILTERS}
                addFilters={(filterItem: string) => {
                  addFilters(SEARCH_MAIN_INDEX_ID, [filterItem]);
                  setActiveFilters([...activeFilters, filterItem]); // optimistic update
                }}
                removeFilters={(filterItem: string) => {
                  removeFilters(SEARCH_MAIN_INDEX_ID, [filterItem]);
                  setActiveFilters(activeFilters.filter((f) => f !== filterItem)); // optimistic update
                }}
                clearFilters={() => {
                  setActiveFilters([]);
                  removeFilters(SEARCH_MAIN_INDEX_ID, activeFiltersToShow);
                  // not using clearFilters because we shouldn't remove hidden filters
                }}
                isMobile
              />
            ) : (
              <div className="DesktopSearchFiltersContainer">
                <DesktopSearchFilters
                  activeFilters={activeFiltersToShow}
                  filters={filters}
                  addFilters={(filterItem: string) => {
                    addFilters(SEARCH_MAIN_INDEX_ID, [filterItem]);
                    setActiveFilters([...activeFilters, filterItem]); // optimistic update
                  }}
                  removeFilters={(filtersToRemove: string[]) => {
                    removeFilters(SEARCH_MAIN_INDEX_ID, filtersToRemove);
                    setActiveFilters(activeFilters.filter((f) => filtersToRemove.includes(f))); // optimistic update
                  }}
                  clearFilters={() => {
                    setActiveFilters([]);
                    removeFilters(SEARCH_MAIN_INDEX_ID, activeFiltersToShow);
                    // not using clearFilters because we shouldn't remove hidden filters
                  }}
                  isLoading={isLoading}
                />
              </div>
            )}
          </div>
        )}
      </Media>
    </div>
  );
};

export default SearchFilters;
