import * as React from 'react';
import { useContext } from 'react';

import type {
  CareerAcademy_Category as CareerAcademyCategory,
  CareerAcademy_Role as CareerAcademyRole,
} from '__generated__/graphql-types';

export type CareerAcademyContentfulContextType = {
  careerAcademyCategories: Array<CareerAcademyCategory>;
  careerAcademyRoles: Array<CareerAcademyRole>;
  careerAcademyCategoriesLoading?: boolean;
};

const CareerAcademyContentfulContext = React.createContext<CareerAcademyContentfulContextType | undefined>(undefined);

export const useCareerAcademyData = () => {
  const careerAcademyContentfulContext = useContext(CareerAcademyContentfulContext);
  return careerAcademyContentfulContext;
};

export default CareerAcademyContentfulContext;
