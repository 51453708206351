import type { Asset } from 'bundles/asset-admin/types/assets';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { AssetElement, ImageElement } from 'bundles/cml/shared/types/elementTypes';

export const assetToElement = ({ typeName: assetType, id }: Asset): AssetElement | ImageElement => {
  switch (assetType) {
    case 'image':
      return {
        type: BLOCK_TYPES.IMAGE,
        id,
        size: 'default',
        isVoid: true,
        children: [{ text: '' }],
      };
    case 'pdf':
      return {
        type: BLOCK_TYPES.ASSET,
        id,
        embedEnabled: true,
        isVoid: true,
        children: [{ text: '' }],
      };
    default:
      return {
        type: BLOCK_TYPES.ASSET,
        id,
        isVoid: true,
        children: [{ text: '' }],
      };
  }
};
