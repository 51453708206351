import { get as getUser } from 'js/lib/user';
import type UserAgentInfo from 'js/lib/useragent';

import type CartsV2 from 'bundles/naptimejs/resources/carts.v2';
import {
  FREE_REFERRAL_FOR_FRIEND_PROMO_CODE,
  FRIENDBUY_PROMOTION_IDS,
  FRIENDBUY_STRING,
} from 'bundles/referral/constants';
import { loadFriendbuyScript } from 'bundles/referral/utils/loadScriptUtils';

// Determine if referral is enabled for a cart based on
// 1) if the referral experiment is turned on
// 2) if the associated promotion is the "Referral a Friend" promotion.
export const isReferralEnabled = (cart?: CartsV2) => {
  // There should be only one promotion associated with the cart's coupon
  const promotionId = cart?.promotionId;
  return !!promotionId && FRIENDBUY_PROMOTION_IDS.includes(promotionId);
};

// Send the purchase success event to Friendbuy(the referral vendor) to mark the referral as successful.
export const sendPurchaseEventToFriendbuy = (
  cart: CartsV2,
  onScriptSuccess: (ev?: Event) => void,
  onScriptFailure: OnErrorEventHandler
) => {
  window.friendbuyAPI = window.friendbuyAPI || [];
  window.friendbuyAPI.push([
    'track',
    'purchase',
    {
      id: cart.id.toString(),
      amount: 0, // required field so 0 as placeholder
      currency: 'USD', // required field so USD as placeholder
    },
  ]);

  loadFriendbuyScript(onScriptSuccess, onScriptFailure);
};

// Determine if Friendbuy "free referral for friend" campaign is enabled
// 1) if the referral experiment is turned on
// 2) if the associated promotion is the "Free referral for friend - Sept 2021" promotion.
export const isFreeReferralForFriendEnabled = (referralSource: string, promoCode?: string) => {
  return !!promoCode && promoCode === FREE_REFERRAL_FOR_FRIEND_PROMO_CODE && referralSource === FRIENDBUY_STRING;
};

// Send the purchase success event for a free course to Friendbuy(the referral vendor) to mark the referral as successful.
export const sendBlankPurchaseEventToFriendbuy = (
  onScriptSuccess: (ev?: Event) => void,
  onScriptFailure: OnErrorEventHandler,
  userAgent?: UserAgentInfo
) => {
  const user = getUser();

  window.friendbuyAPI = window.friendbuyAPI || [];
  window.friendbuyAPI.push([
    'track',
    'purchase',
    {
      id: 'free-purchase-' + user.id,
      amount: 0,
      customer: {
        id: user.id.toString(),
        email: user.email_address,
        name: '', // required field so 0 as placeholder
      },
      products: [
        {
          sku: `${userAgent?.browser.name} - ${userAgent?.browser.version}`, // INPUT BROWSER
          price: 0,
          quantity: 1,
        },
      ],
    },
  ]);

  loadFriendbuyScript(onScriptSuccess, onScriptFailure);
};
