import * as React from 'react';

import { Typography2 } from '@coursera/cds-core';

import CourseActionBox from 'bundles/course-cards/components/course-card/enterprise/CourseActionBox';
import type { PropsForWithModal as PropsForCourseAndPartnerNameWithModal } from 'bundles/course-cards/components/course-card/enterprise/CourseAndPartnerName';
import { CourseAndPartnerNameWithModal } from 'bundles/course-cards/components/course-card/enterprise/CourseAndPartnerName';
import CourseHomeLink from 'bundles/course-cards/components/course-card/enterprise/CourseHomeLink';
import TogglableContent from 'bundles/course-cards/components/course-card/enterprise/TogglableContent';
import type CoursesV1 from 'bundles/naptimejs/resources/courses.v1';

import _t from 'i18n!nls/course-cards';

type Props = {
  course: CoursesV1;
  cardDropdownWidget: JSX.Element;
  isPartOfS12n?: boolean;
} & Omit<PropsForCourseAndPartnerNameWithModal, 'courseId' | 'courseName' | 'partnerIds'>;

const CourseEnrolledCapstoneCard: React.FC<Props> = ({
  course: { id, name, partnerIds, homeLink, description, isPreEnroll },
  cardDropdownWidget,
  isPartOfS12n,
  ...rest
}) => {
  return (
    <div className="rc-CourseEnrolledCapstoneCard row flex-1">
      <div className="col-xs-12 col-sm-8 col-md-9 pos-static-override-hack">
        <CourseAndPartnerNameWithModal
          courseId={id}
          courseName={name}
          partnerIds={partnerIds}
          isPartOfS12n={isPartOfS12n}
          {...rest}
        />
        {cardDropdownWidget}
        {isPartOfS12n && (
          <Typography2 component="p" className="m-b-1" variant="bodyPrimary">
            {_t('Started')}
          </Typography2>
        )}
        {description && (
          <TogglableContent>
            <Typography2 component="p" variant="bodySecondary">
              {description}
            </Typography2>
          </TogglableContent>
        )}
      </div>
      <CourseActionBox>
        <CourseHomeLink homeLink={homeLink} label={isPreEnroll ? _t('Preview') : _t('Resume')} courseName={name} />
      </CourseActionBox>
    </div>
  );
};

export default CourseEnrolledCapstoneCard;
