import * as React from 'react';

import { useS12nEnrollModalData } from 'bundles/enroll/data/usePageData';
import PriceWithDiscountIndicator from 'bundles/payments-common/components/PriceWithDiscountIndicator';
import ReactPriceDisplay from 'bundles/payments-common/components/ReactPriceDisplay';
import {
  getEnrolledDiscountedBulkPayBodyMessage,
  getEnrolledNotDiscountedBulkPayBodyMessage,
  getNotEnrolledDiscountedBulkPayBodyMessage,
  getNotEnrolledNotDiscountedBulkPayBodyMessage,
} from 'bundles/s12n-common/lib/s12nProductVariantUtils';
import PaymentChoice from 'bundles/s12n-enroll/components/bulk-pay/PaymentChoice';
import type { PaymentChoiceType } from 'bundles/s12n-enroll/constants';

import _t from 'i18n!nls/s12n-enroll';

type PropsFromCaller = {
  currentType: PaymentChoiceType;
  onClick: (newType: PaymentChoiceType) => void;
  onSdp?: boolean;
};

const PaymentChoiceFull: React.FC<PropsFromCaller> = ({ currentType, onClick, onSdp }) => {
  const { enrollmentAvailableChoices, productOwnerships, s12nProductPrice, s12n } = useS12nEnrollModalData();
  const { isEnrolled } = enrollmentAvailableChoices;

  if (!s12nProductPrice) {
    return null;
  }

  const getBulkPayBodyMessage = () => {
    const hasDiscount = !!s12nProductPrice.promotionInfo;

    const unownedCourseCount = productOwnerships.unownedCourses.length;

    if (isEnrolled) {
      if (hasDiscount) {
        return getEnrolledDiscountedBulkPayBodyMessage(s12n.productVariant);
      } else {
        return getEnrolledNotDiscountedBulkPayBodyMessage(s12n.productVariant);
      }
    } else if (hasDiscount) {
      return getNotEnrolledDiscountedBulkPayBodyMessage(s12n.productVariant, unownedCourseCount);
    } else {
      return getNotEnrolledNotDiscountedBulkPayBodyMessage(s12n.productVariant, unownedCourseCount);
    }
  };

  const getBulkPayTitleMessage = () => {
    if (isEnrolled) {
      return (
        <span>
          {_t('Buy Remaining Courses')}
          &nbsp;•&nbsp;
          <span className="price">
            <ReactPriceDisplay value={s12nProductPrice.finalAmount} currency={s12nProductPrice.currencyCode} />
          </span>
        </span>
      );
    } else {
      return (
        <span>
          <span>
            {onSdp ? _t('Pre-Pay') : _t('Purchase Specialization')}
            &nbsp;•&nbsp;
          </span>
          <PriceWithDiscountIndicator
            amount={s12nProductPrice.amount}
            finalAmount={s12nProductPrice.finalAmount}
            currencyCode={s12nProductPrice.currencyCode}
          />
        </span>
      );
    }
  };

  return (
    <PaymentChoice
      title={getBulkPayTitleMessage()}
      disabled={false}
      type="full"
      currentType={currentType}
      onClick={onClick}
    >
      <span>{getBulkPayBodyMessage()}</span>
    </PaymentChoice>
  );
};

export default PaymentChoiceFull;
