import { color } from '@coursera/coursera-ui';

import type { DiscoveryCollectionEntity } from 'bundles/browse/components/types/DiscoveryCollections';
import type {
  PreformattedDataFromBrowseForSavedDataLookup,
  ProductForSavedDataLookup,
} from 'bundles/browse/types/withSavedDataFromProducts';
import {
  PROFESSIONAL_CERTIFICATE as SEARCH_PRODUCT_CERTIFICATE,
  COURSE as SEARCH_PRODUCT_COURSE,
  DEGREE as SEARCH_PRODUCT_DEGREE,
  MASTERTRACK as SEARCH_PRODUCT_MASTERTRACK,
  GUIDED_PROJECT as SEARCH_PRODUCT_PROJECT,
  SPECIALIZATION as SEARCH_PRODUCT_SPECIALIZATION,
  RHYME_PROJECT as SEARCH_RHYME_PROJECT,
  SPECIALIZATION_ENTITY_TYPE,
} from 'bundles/search-common/constants/entityTypes';
import { savedProductTypes } from 'bundles/unified-description-page-common/constants';

import _t from 'i18n!nls/browse';

const savingSupportedProducts = {
  SPECIALIZATION: 's12n',
  CERTIFICATE: 'professional_certificate',
  DEGREE: 'degree',
  MASTERTRACK: 'mastertrack',
  GUIDED_PROJECT: 'project',
  COURSE: 'course',
  SEARCH_CARD: 'search_card',
} as const;

type SavingSupportedProduct = (typeof savingSupportedProducts)[keyof typeof savingSupportedProducts];

const productCardsWithWhiteBackground: SavingSupportedProduct[] = [savingSupportedProducts.COURSE];

export const SAVING_BOOKMARK_WHITE = color.white;
export const SAVING_BOOKMARK_PRIMARY = color.primary;
export const SAVING_BOOKMARK_BLACK = color.black;

const SEARCH_PAGE_PRODUCT_PROJECT = 'GUIDED PROJECT';
const SAVED_TAB_MASTERTRACK = 'MASTERTRACK® CERTIFICATE';

export const getProductCardTextColor = (type: SavingSupportedProduct) => {
  if (productCardsWithWhiteBackground.includes(type)) return SAVING_BOOKMARK_PRIMARY;

  return SAVING_BOOKMARK_WHITE;
};

// using case to allow for simple addition of text/sources/types
export const getSavingProductType = (productType: string | undefined): string => {
  switch (productType) {
    case savingSupportedProducts.COURSE:
    case SEARCH_PRODUCT_COURSE:
      return savedProductTypes.COURSE;
    case SPECIALIZATION_ENTITY_TYPE:
    case savingSupportedProducts.SPECIALIZATION:
    case SEARCH_PRODUCT_SPECIALIZATION:
      return savedProductTypes.SPECIALIZATION;
    case savingSupportedProducts.GUIDED_PROJECT:
    case SEARCH_PRODUCT_PROJECT:
    case SEARCH_RHYME_PROJECT:
    case SEARCH_PAGE_PRODUCT_PROJECT:
      return savedProductTypes.GUIDED_PROJECT;
    case savingSupportedProducts.CERTIFICATE:
    case SEARCH_PRODUCT_CERTIFICATE:
      return savedProductTypes.PROFESSIONAL_CERTIFICATE;
    case savingSupportedProducts.MASTERTRACK:
    case SEARCH_PRODUCT_MASTERTRACK:
    case SAVED_TAB_MASTERTRACK:
      return savedProductTypes.MASTERTRACK;
    case savingSupportedProducts.DEGREE:
    case SEARCH_PRODUCT_DEGREE:
      return savedProductTypes.DEGREE;
    default:
      return savedProductTypes.INVALID;
  }
};

export const translateBrowseCarouselContentForSaveLookup = ({
  id,
  isRhymeProject,
  productVariant,
}: PreformattedDataFromBrowseForSavedDataLookup): ProductForSavedDataLookup => {
  let offeringType: SavingSupportedProduct = savingSupportedProducts.COURSE;
  if (isRhymeProject) offeringType = savingSupportedProducts.GUIDED_PROJECT;
  if (productVariant === 'ProfessionalCertificateS12n') offeringType = savingSupportedProducts.CERTIFICATE;
  if (productVariant === 'NormalS12n') offeringType = savingSupportedProducts.SPECIALIZATION;

  return {
    id,
    offeringType,
  };
};

export const translateDiscoveryCollectionCarouselContentForSaveLookup = (
  entity: DiscoveryCollectionEntity | null
): ProductForSavedDataLookup | null => {
  if (!entity) return null;

  let offeringType: SavingSupportedProduct = savingSupportedProducts.COURSE;

  if (entity?.__typename === 'DiscoveryCollections_professionalCertificate') {
    offeringType = savingSupportedProducts.CERTIFICATE;
  } else if (entity?.__typename === 'DiscoveryCollections_guidedProject') {
    offeringType = savingSupportedProducts.GUIDED_PROJECT;
  } else if (entity?.__typename === 'DiscoveryCollections_specialization') {
    offeringType = savingSupportedProducts.SPECIALIZATION;
  }

  return {
    id: entity.id,
    offeringType,
  };
};

export const getBookmarkText = {
  unsaved: () => _t('Save'),
  saved: () => _t('Saved'),
};

export const getTooltipText = {
  unsaved: () => _t('Removed from your '),
  saved: () => _t('Added to your '),
};

export const getAriaText = {
  unsaved: () => _t('Removed from your Saved List'),
  saved: () => _t('Added to your Saved List'),
};
