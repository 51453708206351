/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useMemo, useState } from 'react';

import { ButtonGroup, Grid, Radio, RadioGroup, TextField } from '@coursera/cds-core';

import _t from 'i18n!nls/cml';

const styles = {
  formContainer: css`
    padding: var(--cds-spacing-800);
  `,
  radioButton: css`
    margin-bottom: var(--cds-spacing-200);

    label {
      margin: 0;
    }
  `,
  textField: css`
    justify-content: space-between;
    height: 100%;

    label {
      margin: 0;
    }
  `,
  buttonContainer: css`
    margin-top: var(--cds-spacing-800);
  `,
};
export const PAGE_RENDER_OPTIONS = {
  ALL_PAGES: 'allPages',
  CUSTOM_RANGE: 'customRange',
} as const;

export type PAGE_RENDER_OPTIONS_TYPE = (typeof PAGE_RENDER_OPTIONS)[keyof typeof PAGE_RENDER_OPTIONS];

type Props = {
  embedStartPage?: number;
  embedEndPage?: number;
  totalPages: number;
  onPreviewPageStartChange: (pageNum?: number) => void;
  onPreviewPageEndChange: (pageNum?: number) => void;
  onSubmit: (renderType: PAGE_RENDER_OPTIONS_TYPE) => void;
  onCancel: () => void;
};

type EmbedModalForm = {
  pageStart: {
    val: string;
    error: string | undefined;
  };
  pageEnd: {
    val: string;
    error: string | undefined;
  };
};

export const PdfPageSelectModalForm: React.FC<Props> = ({
  onPreviewPageEndChange,
  onPreviewPageStartChange,
  embedStartPage,
  embedEndPage,
  totalPages,
  onSubmit,
  onCancel,
}) => {
  const [pageRenderOption, setPageRenderOption] = useState<PAGE_RENDER_OPTIONS_TYPE>(
    embedStartPage ? PAGE_RENDER_OPTIONS.CUSTOM_RANGE : PAGE_RENDER_OPTIONS.ALL_PAGES
  );
  const [form, setForm] = useState<EmbedModalForm>({
    pageStart: {
      val: embedStartPage?.toString() || '1',
      error: undefined,
    },
    pageEnd: {
      val: embedEndPage?.toString() || totalPages.toString(),
      error: undefined,
    },
  });

  const pageStartValidation = (pageStartString: string, pageEndString: string) => {
    const pageStart = Number(pageStartString);
    const pageEnd = Number(pageEndString);
    if (pageStartString === '') {
      return undefined;
    }
    if (!Number.isInteger(pageStart)) {
      return _t('Decimals are not allowed. Please enter a whole number.');
    } else if (pageStart < 1) {
      return _t('You cannot select a page before the start of the PDF.');
    } else if (pageStart > totalPages) {
      return _t('You cannot exceed the maximum of #{numOfPages} pages.', {
        numOfPages: totalPages,
      });
    } else if (pageEnd && pageStart > pageEnd) {
      return _t('Your start page must be lower than your end page.');
    } else {
      return undefined;
    }
  };

  const pageEndValidation = (pageStartString: string, pageEndString: string) => {
    const pageStart = Number(pageStartString);
    const pageEnd = Number(pageEndString);
    if (pageEndString === '' || pageStartString === '') {
      return undefined;
    }

    if (!Number.isInteger(pageEnd)) {
      return _t('Decimals are not allowed. Please enter a whole number.');
    } else if (pageEnd && totalPages && pageEnd > totalPages) {
      return _t('You cannot exceed the maximum of #{numOfPages} pages.', {
        numOfPages: totalPages,
      });
    } else if (pageStart && pageEnd < pageStart) {
      return _t('Your end page must be greater than your start page.');
    } else {
      return undefined;
    }
  };

  const pageStartFormChange = (val: string) => {
    const pageStartError = pageStartValidation(val, form.pageEnd.val);
    const pageEndError = pageEndValidation(val, form.pageEnd.val);
    setForm({
      pageStart: {
        val,
        error: pageStartError,
      },
      pageEnd: {
        val: form.pageEnd.val,
        error: pageEndError,
      },
    });

    if (val === '') {
      onPreviewPageStartChange(undefined);
    } else if (!pageStartError && !pageEndError) {
      onPreviewPageStartChange(Number(val));
      onPreviewPageEndChange(Number(form.pageEnd.val));
    }
  };

  const pageEndFormChange = (val: string) => {
    const pageStartError = pageStartValidation(form.pageStart.val, val);
    const pageEndError = pageEndValidation(form.pageStart.val, val);
    setForm({
      pageStart: {
        val: form.pageStart.val,
        error: pageStartError,
      },
      pageEnd: {
        val,
        error: pageEndError,
      },
    });

    if (val === '') {
      onPreviewPageEndChange(undefined);
    } else if (!pageStartError && !pageEndError) {
      onPreviewPageStartChange(Number(form.pageStart.val));
      onPreviewPageEndChange(Number(val));
    }
  };

  const changePageRenderOption = (option: PAGE_RENDER_OPTIONS_TYPE) => {
    setPageRenderOption(option);
    if (option === PAGE_RENDER_OPTIONS.ALL_PAGES) {
      pageStartFormChange('');
      pageEndFormChange('');
    } else {
      pageStartFormChange(embedStartPage?.toString() || '1');
      pageEndFormChange(embedEndPage?.toString() || totalPages.toString());
    }
  };

  const submitHandler = () => {
    onSubmit(pageRenderOption);
  };

  const formHasErrors = useMemo(() => {
    return Object.values(form).some((field) => !!field.error);
  }, [form]);

  return (
    <Grid item xs={4} css={styles.formContainer} direction="column" container spacing={16}>
      <form onSubmit={submitHandler}>
        <Grid item>
          <RadioGroup
            css={styles.radioButton}
            value={pageRenderOption}
            onChange={(e) => changePageRenderOption(e.target.value as PAGE_RENDER_OPTIONS_TYPE)}
            label={_t('Select which pages you want to display')}
            name="embedRenderOptionRadio"
          >
            <Radio label={_t('All pages')} value={PAGE_RENDER_OPTIONS.ALL_PAGES} />
            <Radio label={_t('Custom')} value={PAGE_RENDER_OPTIONS.CUSTOM_RANGE} />
          </RadioGroup>
        </Grid>

        {pageRenderOption === PAGE_RENDER_OPTIONS.CUSTOM_RANGE && (
          <Grid item container spacing={16}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth={true}
                css={styles.textField}
                type="number"
                inputProps={{ inputMode: 'numeric', step: '1' }}
                validationStatus={form.pageStart.error ? 'error' : undefined}
                validationLabel={form.pageStart.error}
                value={form.pageStart.val}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => pageStartFormChange(e.target.value)}
                label={_t('Start')}
                aria-label={_t('Enter the start page you want to display.')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth={true}
                css={styles.textField}
                type="number"
                inputProps={{ inputMode: 'numeric', step: '1' }}
                validationStatus={form.pageEnd.error ? 'error' : undefined}
                validationLabel={form.pageEnd.error}
                value={form.pageEnd.val}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => pageEndFormChange(e.target.value)}
                label={_t('End')}
                aria-label={_t('Enter the end page you want to display.')}
              />
            </Grid>
          </Grid>
        )}
        <Grid item container spacing={8}>
          <Grid css={styles.buttonContainer} item xs={12}>
            <ButtonGroup
              primaryButtonProps={{
                type: 'submit',
                disabled: formHasErrors,
                fullWidth: true,
                children: 'Confirm',
                onClick: submitHandler,
              }}
              alternateButtonProps={{
                children: 'Cancel',
                variant: 'secondary',
                onClick: onCancel,
                fullWidth: true,
              }}
            />
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};
