import * as React from 'react';
import { useEffect, useState } from 'react';

import { useLocation } from 'js/lib/useRouter';

import type { PropsFromCaller as EnrollModalProps } from 'bundles/enroll/components/EnrollModal';
import PageDataProvider from 'bundles/enroll/data/PageDataProvider';
import { ENROLL } from 'bundles/enroll/utils/enrollActionParams';
import inServerContext from 'bundles/ssr/util/inServerContext';

type PropsFromCaller = Omit<EnrollModalProps, 'onClose'>;

const PreloadEnrollModal: React.FunctionComponent<PropsFromCaller> = (props) => {
  const [shouldPreloadOnClient, setShouldPreloadOnClient] = useState(false);
  const {
    query: { action },
  } = useLocation();

  // Preload on SSR if the ?action=enroll param exists which occurs when the learner signs up through
  // the XDP CTA - this means the enroll modal will show up immediately on page load
  const shouldPreloadOnServer = action === ENROLL;

  useEffect(() => {
    // Otherwise, if not preloaded on SSR, preload on CSR after getting mounted
    // and on the next event cycle after everything else has already loaded (through setTimeout(0))
    if (!inServerContext) {
      window.setTimeout(() => {
        setShouldPreloadOnClient(true);
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return shouldPreloadOnClient || shouldPreloadOnServer ? <PageDataProvider {...props} /> : null;
};

export default PreloadEnrollModal;
