import * as React from 'react';

import type { IconSize } from 'bundles/ai-coach-platform/types';

/**
 * MUI icon for Chevron left
 */
const ChevronLeftIcon = ({ size = 16 }: { size?: IconSize }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M7.26668 8L10 10.7333C10.1222 10.8556 10.1833 10.9972 10.1833 11.1583C10.1833 11.3194 10.1222 11.4611 10 11.5833C9.87779 11.7056 9.73613 11.7667 9.57501 11.7667C9.4139 11.7667 9.27035 11.7037 9.14435 11.5777L5.98335 8.41666C5.92779 8.35666 5.88613 8.29166 5.85835 8.22166C5.83057 8.15166 5.81668 8.07666 5.81668 7.99666C5.81668 7.91666 5.83057 7.84166 5.85835 7.77166C5.88613 7.70166 5.92779 7.63889 5.98335 7.58333L9.14435 4.42233C9.27035 4.29633 9.4139 4.23333 9.57501 4.23333C9.73613 4.23333 9.87779 4.29444 10 4.41666C10.1222 4.53889 10.1833 4.68055 10.1833 4.84166C10.1833 5.00277 10.1222 5.14444 10 5.26666L7.26668 8Z"
      fill="var(--cds-color-grey-950)"
    />
  </svg>
);

export default ChevronLeftIcon;
