import * as React from 'react';
import { useState } from 'react';

import DeleteCodeBlockButton from 'bundles/cml/editor/components/elements/asset/buttons/DeleteAssetButton';
import CodeBlockNameButton from 'bundles/cml/editor/components/elements/code/buttons/CodeBlockNameButton';
import CodeBlockNameDialog from 'bundles/cml/editor/components/elements/code/dialogs/CodeBlockNameDialog';
import Toolbar from 'bundles/cml/editor/components/toolbars/Toolbar';
import RestoreFocus from 'bundles/cml/editor/utils/RestoreFocus';
import type { CodeElement } from 'bundles/cml/shared/types/elementTypes';

import _t from 'i18n!nls/cml';

type Props = {
  anchorEl: HTMLElement | null;
  element: CodeElement;
  onClose: () => void;
};

const CodeMenu: React.FC<Props> = ({ anchorEl, element, onClose }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  if (dialogOpen) {
    return <CodeBlockNameDialog element={element} onClose={() => setDialogOpen(false)} />;
  }

  return (
    <RestoreFocus anchorEl={anchorEl} onClose={onClose}>
      <Toolbar role="menubar" className="rc-ImageMenu" autoFocus={true}>
        <CodeBlockNameButton onClick={() => setDialogOpen(true)} />
        <DeleteCodeBlockButton title={_t('Delete code block')} />
      </Toolbar>
    </RestoreFocus>
  );
};

export default CodeMenu;
