import * as React from 'react';

const ReskillIcon = () => (
  <svg
    role="presentation"
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="reskill-icon"
  >
    <circle cx="12" cy="12" r="12" fill="#F5F7F8" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.995 4.342 8.005 8.332 7.709 8.036 11.495 4.25 15.479 8.232 15.183 8.529 11.995 5.341V10.53h-.418V4.342zM12.412 20.064l2.989-2.989.296.296-3.494 3.495-3.495-3.495.296-.295 2.99 2.987V13.876h.418v6.188zM4.342 12.411l2.989 2.989-.296.296-3.495-3.495 3.495-3.494.296.295-2.989 2.99H10.53v.418H4.342zM19.645 12.411l-2.989 2.989.296.296 3.494-3.495-3.494-3.494-.295.295 2.987 2.99H13.457v.418h6.188z"
      fill="#1F1F1F"
    />
  </svg>
);

export default ReskillIcon;
