import * as React from 'react';

import classNames from 'classnames';

import { Col, Row } from '@coursera/coursera-ui';

import type { Props as InstructorListProps } from 'bundles/enterprise-legacy-xdp/components/instructors/InstructorList';
import InstructorList, {
  getTitle,
  shouldShowAvgInstructorRating,
} from 'bundles/enterprise-legacy-xdp/components/instructors/InstructorList';

type InstructorListSectionProps = InstructorListProps;

function InstructorListSection(props: InstructorListSectionProps) {
  const { instructors, averageInstructorRating, instructorRatingCount } = props;

  if (instructors.length < 1) {
    return null;
  }
  const showAvgInstructorRating = shouldShowAvgInstructorRating({ averageInstructorRating, instructorRatingCount });

  return (
    <div className="rc-InstructorListSection">
      <Row>
        <Col>
          <h2
            className={classNames('bold headline-4-text', {
              'p-b-1': !showAvgInstructorRating,
              'm-b-0': showAvgInstructorRating,
            })}
          >
            {getTitle(instructors)}
          </h2>
        </Col>
      </Row>
      <InstructorList {...props} />
    </div>
  );
}

export default InstructorListSection;
