import { useContext } from 'react';

import { FluxibleContext } from './FluxibleContext';

function useFluxibleAction() {
  const { executeAction } = useContext(FluxibleContext);

  return executeAction;
}

export default useFluxibleAction;
