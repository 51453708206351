/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback } from 'react';

import type { NodeEntry } from 'slate';
import { Transforms } from 'slate';
import { ReactEditor, useSlate, useSlateStatic } from 'slate-react';

import type { Theme } from '@coursera/cds-core';

import { Menu, MenuItem } from 'bundles/authoring/common/components/Menu';
import type { MenuProps } from 'bundles/authoring/common/components/Menu/Menu';
import { HEADINGS } from 'bundles/cml/editor/components/buttons/typography/constants';
import { hasHeadingByLevel } from 'bundles/cml/editor/components/buttons/typography/headingUtils';
import { isTypeDisabled } from 'bundles/cml/editor/utils/elementUtils';
import {
  DEFAULT_HEADING_VARIANTS,
  HEADINGS_VARIANTS,
  getHeadingDisplayName,
  getVariantDisplayName,
} from 'bundles/cml/editor/utils/headingUtils';
import { getAncestorOfType } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { HeadingLevel, HeadingVariant } from 'bundles/cml/shared/types/coreTypes';
import type { HeadingElement } from 'bundles/cml/shared/types/elementTypes';
import type { ToolsKeys } from 'bundles/cml/shared/utils/customTools';

const styles = {
  h1semibold: (theme: Theme) => css`
    ${theme.typography.h1semibold}
  `,
  h1regular: (theme: Theme) => css`
    ${theme.typography.h1}
  `,
  h2semibold: (theme: Theme) => css`
    ${theme.typography.h2semibold}
  `,
  h2regular: (theme: Theme) => css`
    ${theme.typography.h2}
  `,
  h3semibold: (theme: Theme) => css`
    ${theme.typography.h3semibold}
  `,
  h3bold: (theme: Theme) => css`
    ${theme.typography.h3bold}
  `,
  h4bold: (theme: Theme) => css`
    ${theme.typography.h4bold}
  `,
};

type Props = {
  tools: ToolsKeys[];
  className?: string;
};

const HeadingMenu: React.FC<Props> = ({ tools, className }) => {
  const editor = useSlate();
  const staticEditor = useSlateStatic();
  const headingDisabled = isTypeDisabled(editor, BLOCK_TYPES.HEADING);

  const handleHeading = useCallback(
    (level: HeadingLevel, variant: HeadingVariant | undefined, active: boolean) => {
      ReactEditor.focus(staticEditor);
      if (active) {
        Transforms.setNodes(staticEditor, { type: 'text' });
      } else {
        Transforms.setNodes(staticEditor, { type: 'heading', level, variant });
      }
    },
    [staticEditor]
  );

  const renderHeadingVariants = (level: HeadingLevel) => {
    if (level === '4') {
      return undefined;
    }

    const variants = HEADINGS_VARIANTS[level];
    const heading = getAncestorOfType(editor, BLOCK_TYPES.HEADING) as NodeEntry<HeadingElement>;
    hasHeadingByLevel(level);

    return (headingMenuProps: MenuProps) => (
      <Menu {...headingMenuProps} className={className}>
        {variants.map((variant: HeadingVariant) => {
          const variantMatcher = hasHeadingByLevel(level, variant);
          const variantName = getVariantDisplayName(variant);

          const selected = !!heading && variantMatcher(heading[0]);
          return (
            <MenuItem
              key={`heading${level}-${variant}`}
              className={`rc-Heading${level}Button rc-Heading${level}${variantName}Button ${className}`}
              label={<div css={styles[variant]}>{variantName}</div>}
              selected={selected}
              onClick={() => handleHeading(level, variant, selected)}
            />
          );
        })}
      </Menu>
    );
  };

  return (
    <React.Fragment>
      {HEADINGS.map(({ tool, level, match }) => {
        if (!tools.includes(tool)) {
          return null;
        }

        const label = getHeadingDisplayName(level);
        const heading = getAncestorOfType(editor, BLOCK_TYPES.HEADING);
        const selected = !!heading && match(heading[0] as HeadingElement);
        const defaultVariant = DEFAULT_HEADING_VARIANTS[level];
        const handleClick = level === '4' ? () => handleHeading(level, defaultVariant, selected) : undefined;

        return (
          <MenuItem
            key={`heading${level}`}
            label={<div css={styles[defaultVariant]}>{label}</div>}
            selected={selected}
            disabled={headingDisabled}
            onClick={handleClick}
            className={className}
          >
            {renderHeadingVariants(level)}
          </MenuItem>
        );
      })}
    </React.Fragment>
  );
};

export default HeadingMenu;
