import type * as React from 'react';

import AIButton from 'bundles/cml/editor/components/buttons/ai/AIButton';
import AssetButton from 'bundles/cml/editor/components/buttons/asset/AssetButton';
import BoldButton from 'bundles/cml/editor/components/buttons/bold/BoldButton';
import CodeButton from 'bundles/cml/editor/components/buttons/code/CodeButton';
import FillableBlankButton from 'bundles/cml/editor/components/buttons/fillable-blank/FillableBlankButton';
import ImageButton from 'bundles/cml/editor/components/buttons/image/ImageButton';
import DecreaseIndentButton from 'bundles/cml/editor/components/buttons/indent/DecreaseIndentButton';
import IncreaseIndentButton from 'bundles/cml/editor/components/buttons/indent/IncreaseIndentButton';
import ItalicButton from 'bundles/cml/editor/components/buttons/italic/ItalicButton';
import LinkButton from 'bundles/cml/editor/components/buttons/link/LinkButton';
import UnlinkButton from 'bundles/cml/editor/components/buttons/link/UnlinkButton';
import BulletListButton from 'bundles/cml/editor/components/buttons/list/BulletListButton';
import NumberedListButton from 'bundles/cml/editor/components/buttons/list/NumberedListButton';
import MathButton from 'bundles/cml/editor/components/buttons/math/MathButton';
import MonospaceButton from 'bundles/cml/editor/components/buttons/monospace/MonospaceButton';
import { PersonalizationTagButton } from 'bundles/cml/editor/components/buttons/personalization-tag/PersonalizationTagButton';
import SubscriptButton from 'bundles/cml/editor/components/buttons/subscript/SubscriptButton';
import SuperscriptButton from 'bundles/cml/editor/components/buttons/subscript/SuperscriptButton';
import TableButton from 'bundles/cml/editor/components/buttons/table/TableButton';
import TypographyButton from 'bundles/cml/editor/components/buttons/typography/TypographyButton';
import UnderlineButton from 'bundles/cml/editor/components/buttons/underline/UnderlineButton';
import RedoButton from 'bundles/cml/editor/components/buttons/undo/RedoButton';
import UndoButton from 'bundles/cml/editor/components/buttons/undo/UndoButton';
import WidgetButton from 'bundles/cml/editor/components/buttons/widget/WidgetButton';
import type {
  HoverToolsKeys,
  ToolbarToolsKeys,
  ToolsKeys,
  TypographyToolsKeys,
} from 'bundles/cml/shared/utils/customTools';
import { Tools } from 'bundles/cml/shared/utils/customTools';

export const TYPOGRAPHY_TOOLS: TypographyToolsKeys[] = [
  Tools.HEADING_1,
  Tools.HEADING_2,
  Tools.HEADING_3,
  Tools.HEADING_4,
  Tools.BODY1,
  Tools.BODY2,
];

export const TYPOGRAPHY_TOOLS_SET = new Set<ToolsKeys>(TYPOGRAPHY_TOOLS);

export const DEFAULT_TOOLS: ToolsKeys[] = [
  ...TYPOGRAPHY_TOOLS,
  Tools.AI,
  Tools.BOLD,
  Tools.ITALIC,
  Tools.UNDERLINE,
  Tools.MONOSPACE,
  Tools.MATH,
  Tools.LINK,
  Tools.UNLINK,
  Tools.UNORDERED_LIST,
  Tools.ORDERED_LIST,
  Tools.TABLE,
  Tools.IMAGE,
  Tools.ASSET,
  Tools.WIDGET,
  Tools.SUBSCRIPT,
  Tools.SUPERSCRIPT,
  Tools.CODE,
];

export const PAGELESS_TOOLS: ToolsKeys[] = [Tools.UNDO, Tools.REDO, ...DEFAULT_TOOLS, Tools.WIDGET];

export const RELATED_TOOLS: ToolsKeys[][] = [
  [Tools.UNDO, Tools.REDO],
  [Tools.LINK, Tools.UNLINK],
  [Tools.UNORDERED_LIST, Tools.ORDERED_LIST],
  [Tools.INCREASE_INDENT, Tools.DECREASE_INDENT],
];

export const HOVERING_TOOLS: HoverToolsKeys[] = [
  Tools.TYPOGRAPHY,
  Tools.AI,
  Tools.BOLD,
  Tools.ITALIC,
  Tools.UNDERLINE,
  Tools.MONOSPACE,
  Tools.LINK,
  Tools.UNLINK,
  Tools.MATH,
  Tools.SUBSCRIPT,
  Tools.SUPERSCRIPT,
  Tools.INCREASE_INDENT,
  Tools.DECREASE_INDENT,
];

export const PAGELESS_BUTTON_SPACING = 16;
export const TOOLBAR_BUTTON_SPACING = 8;
export const TOOLBAR_PADDING = 12;

export const ToolButtonFactory: Record<ToolbarToolsKeys, () => React.ComponentType<any>> = {
  [Tools.TYPOGRAPHY]: () => TypographyButton,
  [Tools.BOLD]: () => BoldButton,
  [Tools.ITALIC]: () => ItalicButton,
  [Tools.UNDERLINE]: () => UnderlineButton,
  [Tools.LINK]: () => LinkButton,
  [Tools.MONOSPACE]: () => MonospaceButton,
  [Tools.UNLINK]: () => UnlinkButton,
  [Tools.UNORDERED_LIST]: () => BulletListButton,
  [Tools.ORDERED_LIST]: () => NumberedListButton,
  [Tools.TABLE]: () => TableButton,
  [Tools.IMAGE]: () => ImageButton,
  [Tools.ASSET]: () => AssetButton,
  [Tools.CODE]: () => CodeButton,
  [Tools.PERSONALIZATION_TAG]: () => PersonalizationTagButton,
  [Tools.MATH]: () => MathButton,
  [Tools.UNDO]: () => UndoButton,
  [Tools.REDO]: () => RedoButton,
  [Tools.FILLABLE_BLANK]: () => FillableBlankButton,
  [Tools.DECREASE_INDENT]: () => DecreaseIndentButton,
  [Tools.INCREASE_INDENT]: () => IncreaseIndentButton,
  [Tools.SUPERSCRIPT]: () => SuperscriptButton,
  [Tools.SUBSCRIPT]: () => SubscriptButton,
  [Tools.WIDGET]: () => WidgetButton,
  [Tools.AI]: () => AIButton,
} as const;
