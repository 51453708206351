import React from 'react';

import type { LocalNotificationProps } from '@core/notifications/LocalNotification';
import { LocalNotification } from '@core/notifications/LocalNotification';
import { usePageNotificationListContext } from '@core/notifications/PageNotificationList';
import type { NotificationSeverity } from '@core/notifications/types';

export type PageNotificationProps = {
  /**
   * Notification title.
   */
  title?: string;
  /**
   * Severity type of Notification
   * @default information
   */
  severity?: NotificationSeverity;
} & Omit<LocalNotificationProps, 'titleProps' | 'severity'>;

/**
 * Page Notifications are system notifications that appear at the page level to
 * communicate information during a user journey or at the account level.
 *
 * See [Props](__storybookUrl__/components-feedback-notifications--default#props)
 */
const PageNotification = React.forwardRef(function PageNotification(
  props: PageNotificationProps,
  ref: React.Ref<HTMLDivElement>
) {
  /**
   * PageNotificationListContext is used only to determine if this component is wrapped
   * inside PageNotificationList or not. If it is, the title is marked as H3 as opposed to H2.
   */
  const stacked = usePageNotificationListContext();
  const { title, severity, ...rest } = props;

  return (
    <LocalNotification
      {...rest}
      ref={ref}
      severity={severity || 'information'}
      titleProps={{
        children: title,
        component: stacked ? 'h3' : 'h2',
      }}
    />
  );
});

export default PageNotification;
