import PropTypes from 'prop-types';

export const curriculumProductPropType = PropTypes.shape({
  isSelected: PropTypes.bool,
  isEnrolled: PropTypes.bool,
  isUnavailable: PropTypes.bool,
  canEnroll: PropTypes.bool,
  canSelect: PropTypes.bool,
  courseId: PropTypes.string,
  s12nId: PropTypes.string,
  isCourse: PropTypes.bool,
  definition: PropTypes.object,
});

export const PRODUCT_TYPES = {
  COURSE: 'COURSE',
  SPECIALIZATION: 'SPECIALIZATION',
};

// Use this to generate product id for querying ProgramCurriculumProducts
export const PRODUCT_ID_INFIX = {
  COURSE: 'VerifiedCertificate',
  S12N: 'Specialization',
};

// ProgramVisibilityRule
export const PROGRAM_VISIBILITY_RULE = {
  EVERYONE: 'EVERYONE',
  ACCEPTED_PRIVACY_NOTICE: 'ACCEPTED_PRIVACY_NOTICE',
};
