import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useFocusRing } from '@coursera/cds-core';

import { ManagedFocusContext } from 'bundles/cml/editor/components/utils/managedFocusContext';
import type { ManagedFocusContextType } from 'bundles/cml/editor/components/utils/managedFocusContext';
import {
  getNextButton,
  getPreviousButton,
  maybeUpdateFocusedButton,
} from 'bundles/cml/editor/components/utils/managedFocusUtils';
import { mergeRefs } from 'bundles/cml/editor/utils/mergeRefs';

type Props = {
  autoFocus?: boolean;
  children: (updateFocus: () => void) => JSX.Element;
};

const ManagedFocus: React.FC<Props> = ({ autoFocus = false, children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [focusedButton, setFocusedButton] = useState<HTMLButtonElement | null>(null);
  const { isFocusVisible, focusProps } = useFocusRing({ within: true });

  const focusNextButton = useCallback(
    (focus = true) => {
      const nextButton = getNextButton(ref.current, focusedButton);
      setFocusedButton(nextButton);

      if (focus) {
        nextButton?.focus();
      }
    },
    [focusedButton]
  );

  const focusPreviousButton = useCallback(
    (focus = true) => {
      const nextButton = getPreviousButton(ref.current, focusedButton);
      setFocusedButton(nextButton);

      if (focus) {
        nextButton?.focus();
      }
    },
    [focusedButton]
  );

  const updateFocus = useCallback(() => {
    const nextButton = maybeUpdateFocusedButton(ref.current, focusedButton);
    if (nextButton === focusedButton) {
      return;
    }

    setFocusedButton(nextButton);
    if (isFocusVisible || autoFocus) {
      setTimeout(() => nextButton?.focus());
    }
  }, [autoFocus, focusedButton, isFocusVisible]);

  useEffect(
    () => {
      updateFocus();
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const managedFocusContext: ManagedFocusContextType = useMemo(
    () => ({
      focusedButton,
      setFocusedButton,
      focusNext: focusNextButton,
      focusPrevious: focusPreviousButton,
    }),
    [focusedButton, setFocusedButton, focusNextButton, focusPreviousButton]
  );

  const child = children(updateFocus);

  // @ts-expect-error
  const originalRef = child?.ref;

  const mergedRef = useMemo(() => mergeRefs([originalRef, ref]), [originalRef, ref]);

  // useFocusRing includes an onBlur handler, we merge it with what ever has been passed  so that both the user and hook provided functions run
  const mergedOnBlur = (e: React.FocusEvent<HTMLElement>) => {
    focusProps?.onBlur?.(e);
    child.props.onBlur?.(e);
  };

  return (
    <ManagedFocusContext.Provider value={managedFocusContext}>
      {child && React.cloneElement(child, { ...child.props, ref: mergedRef, ...focusProps, onBlur: mergedOnBlur })}
    </ManagedFocusContext.Provider>
  );
};

export default ManagedFocus;
