import { useState } from 'react';

type PaginationProps<T> = {
  elements: T[];
  defaultPageSize: number;
};
const usePagination = <T,>(props: PaginationProps<T>) => {
  const [pageSize, setPageSize] = useState(props.defaultPageSize);
  const [currentPage, setCurrentPage] = useState(1);

  const startElement = pageSize * (currentPage - 1);
  if (startElement > props.elements.length) {
    setCurrentPage(1);
  }
  const endElement = startElement + pageSize;

  return {
    elements: props.elements.slice(startElement, endElement),
    currentPage: [currentPage, setCurrentPage] as const,
    pageSize: [pageSize, setPageSize] as const,
  };
};

export { usePagination };
