const SEARCH_TERM_MAP: { [key: string]: string } = {
  ux: 'user experience',
  ai: 'artificial intelligence',
  aws: 'amazon web services',
  nlp: 'natural language processing',
};

function getSkillSearchTerm(searchTerm: string) {
  const search = searchTerm.toLowerCase().trim();
  return SEARCH_TERM_MAP[search] || search;
}

export { getSkillSearchTerm };
