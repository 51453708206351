import * as React from 'react';

import user from 'js/lib/user';

import type { ButtonProps } from '@coursera/cds-core';
import { Button } from '@coursera/cds-core';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import AdjustingDeadlinesModal from 'bundles/course-sessions/components/AdjustingDeadlinesModal';
import ConfirmVersionChangesModal from 'bundles/course-sessions/components/ConfirmVersionChangesModal';
import type CourseScheduleSuggestion from 'bundles/course-sessions/models/CourseScheduleSuggestion';
import LearnerCourseSchedulesAPIUtils from 'bundles/course-sessions/utils/LearnerCourseSchedulesAPIUtils';

import _t from 'i18n!nls/course-sessions';

import 'css!./../__styles__/BranchSwitchInfo';
import 'css!./../__styles__/ResetDeadlinesButton';

const ButtonWrapper: React.FunctionComponent<ButtonProps> = (props) => <Button {...props} />;

const TrackedButton = withSingleTracked({ type: 'BUTTON' })(ButtonWrapper);

type Props = {
  isThemeDark?: boolean;
  trackingName: string;
  className?: string;
  style?: { [styleAttr: string]: string | number };
  variant: ButtonProps['variant'];
  size: ButtonProps['size'];
  buttonText?: string;
  trackingData: any;
  courseId: string;
  courseScheduleSuggestion: CourseScheduleSuggestion;
  courseName?: string;
};

type State = {
  showConfirmVersionChangesModal: boolean;
  isAdjustingSchedule: boolean;
  adjustScheduleError: boolean;
};

export default class ResetDeadlinesButton extends React.Component<Props, State> {
  state = {
    showConfirmVersionChangesModal: false,
    isAdjustingSchedule: false,
    adjustScheduleError: false,
  };

  handleClose = () => {
    this.setState({ showConfirmVersionChangesModal: false, adjustScheduleError: false, isAdjustingSchedule: false });
  };

  handleConfirm = () => {
    this.setState({ showConfirmVersionChangesModal: false });
    this.adjustSchedule();
  };

  onResetDeadlinesClick = () => {
    const { courseScheduleSuggestion } = this.props;
    if (!courseScheduleSuggestion.hasVersionChangeForShiftDeadlinesSuggestion()) {
      this.adjustSchedule();
    } else {
      this.setState({ showConfirmVersionChangesModal: true });
    }
  };

  adjustSchedule = () => {
    const { courseId, courseScheduleSuggestion } = this.props;
    const { days } = courseScheduleSuggestion;

    this.setState({ isAdjustingSchedule: true });

    LearnerCourseSchedulesAPIUtils.adjustSchedule(user.get().id, courseId, days)
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        this.setState({ isAdjustingSchedule: false, adjustScheduleError: true, showConfirmVersionChangesModal: false });
      });
  };

  render() {
    const { trackingName, style, buttonText, variant, size, trackingData, courseScheduleSuggestion, courseName } =
      this.props;
    const { showConfirmVersionChangesModal, isAdjustingSchedule, adjustScheduleError } = this.state;

    const showAdjustingDeadlinesModal = isAdjustingSchedule || adjustScheduleError;

    const changesDescription = courseScheduleSuggestion.changesDescriptionForShiftDeadlinesSuggestion;

    return (
      <div className="rc-ResetDeadlinesButton">
        {showConfirmVersionChangesModal && (
          <ConfirmVersionChangesModal
            handleConfirm={this.handleConfirm}
            handleClose={this.handleClose}
            changesDescription={changesDescription}
          />
        )}

        {showAdjustingDeadlinesModal && (
          <AdjustingDeadlinesModal
            isAdjustingSchedule={isAdjustingSchedule}
            adjustScheduleError={adjustScheduleError}
            handleClose={this.handleClose}
          />
        )}

        <TrackedButton
          variant={variant}
          size={size}
          onClick={this.onResetDeadlinesClick}
          trackingName={trackingName}
          trackingData={trackingData}
          style={style}
        >
          {buttonText || _t('Reset my deadlines')}
          {courseName && <span className="sr-only">: {courseName}</span>}
        </TrackedButton>
      </div>
    );
  }
}
