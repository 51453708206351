/* eslint-disable no-restricted-syntax */
import { Stackability_ProductType as UpsellType } from '__generated__/graphql-types';

import type { DegreeUnenrolledStackableItem } from 'bundles/stackability-common/types';

import _t from 'i18n!nls/stackability-common';

const getHomepagesNavXDPCopy = () => ({
  buildTowardADegree: _t('Build toward a degree'),
  earnDegreeCredit: _t('Earn degree credit'),
  degreeCreditEligible: _t('Degree credit eligible'),
  variantAceCreditRecommended: (variantTitle: string) =>
    _t(
      'This #{variantTitle} has ACE® recommendation. It is eligible for college credit at participating U.S. colleges and universities. Note: The decision to accept specific credit recommendations is up to each institution.',
      { variantTitle }
    ),
  prepareForADegree: _t('Prepare for a degree'),
  getAHeadStartOnYourDegree: _t('Get a head start on your degree'),
  eligibleDegreesFcndDescription: (productName: string, degreePartnerName: string) =>
    _t(
      'This #{productName} is part of the following online degree programs offered by #{degreePartnerName}. When you enroll as a non-degree student with the university and complete the #{productName} online, you will earn transferable college credit, which will count toward your degree learning if you are accepted into the full degree program.',
      {
        productName,
        degreePartnerName,
      }
    ),
  eligibleDegreesPartOfDescription: (productName: string, degreePartnerName: string) =>
    _t(
      'This #{productName} is part of the following degree program(s) offered by #{degreePartnerName}. If you are admitted and enroll, your completed coursework may count toward your degree learning and your progress can transfer with you.¹',
      {
        productName,
        degreePartnerName,
      }
    ),
  eligibleDegreesOffersCreditDescription: (productName: string) =>
    _t(
      'When you complete this #{productName}, you may be able to have your learning recognized for credit if you are admitted and enroll in one of the following online degree programs.¹',
      {
        productName,
      }
    ),
  eligibleDegreesOffersCreditDisclaimer: _t(
    '¹Each university determines the number of pre-approved prior learning credits that may count towards the degree requirements according to institutional policies.'
  ),
  eligibleDegreesRelatedToDescription: (productName: string, productPagePartnerName: string) =>
    _t(
      'Taking this #{productName} by #{productPagePartnerName} may provide you with a preview of the topics, materials and instructors in a related degree program which can help you decide if the topic or university is right for you.',
      {
        productName,
        productPagePartnerName,
      }
    ),
  eligibleDegreesFallbackDescription: (productName: string) =>
    _t(
      'When you complete this #{productName}, you can earn college credit if you apply and are accepted into one of the following online degree programs.',
      {
        productName,
      }
    ),
  eligibleDegreesDisclaimerSuccessfulAppRequired: _t(
    '¹Successful application and enrollment are required. Eligibility requirements apply. Each institution determines the number of credits recognized by completing this content that may count towards degree requirements, considering any existing credits you may have. Click on a specific course for more information.'
  ),
  stackablyUnenrollTitle: (degreeName: string) =>
    _t('Interested in #{degreeName}?', {
      degreeName,
    }),
  stackablyUnenrollSubtitle: (
    degreeName: string,
    partnerName: string,
    stackableItems: DegreeUnenrolledStackableItem[]
  ) => {
    if (stackableItems.length === 0) return '';

    const productTypeAmountMap = new Map<UpsellType, number>();
    stackableItems.forEach((item) => {
      const currentCount = productTypeAmountMap.get(item.productType) || 0;
      productTypeAmountMap.set(item.productType, currentCount + 1);
    });

    const commonMessage = _t(
      "part of the #{degreeName} from #{partnerName}. Explore and make progress toward the degree, while deciding if it's right for you.",
      { degreeName, partnerName }
    );

    let productTypeMessage = '';
    if (productTypeAmountMap.size > 1) {
      // Equivalent to stackableItems.length > 1 && productTypeAmountMap.has('Specialization') && productTypeAmountMap.has('Course')
      const courseSubject = (productTypeAmountMap.get(UpsellType.Course) as number) > 1 ? _t('Courses') : _t('Course');
      const specializationSubject =
        (productTypeAmountMap.get(UpsellType.Specialization) as number) > 1
          ? _t('Specializations')
          : _t('Specialization');
      productTypeMessage = _t('These #{courseSubject} and #{specializationSubject} are', {
        courseSubject,
        specializationSubject,
      });
    } else if (stackableItems.length > 1) {
      // Equivalent to productTypeAmountMap.has('Specialization') or productTypeAmountMap.has('Course')
      productTypeMessage = _t('These #{productType} are', {
        productType: productTypeAmountMap.has(UpsellType.Specialization) ? _t('Specializations') : _t('Courses'),
      });
    } else {
      productTypeMessage = _t('This #{productType} is', {
        productType: productTypeAmountMap.has(UpsellType.Specialization) ? _t('Specialization') : _t('Course'),
      });
    }

    return _t('#{productTypeMessage} #{commonMessage}', {
      productTypeMessage,
      commonMessage,
    });
  },
  stackablyUnenrollOffersCreditSubtitle: (
    degreeName: string,
    partnerName: string,
    stackableItems: DegreeUnenrolledStackableItem[]
  ) => {
    if (stackableItems.length === 1) {
      return _t(
        "When you complete this #{productVariantName}, you can earn college credit if you're admitted and enroll in the #{degreeName} from #{partnerName}",
        {
          productVariantName: stackableItems[0].productVariant,
          degreeName,
          partnerName,
        }
      );
    }

    return _t(
      "When you complete any of these Professional Certificates or Specializations, you can earn college credit if you're admitted and enroll in the #{degreeName} from #{partnerName}.",
      {
        degreeName,
        partnerName,
      }
    );
  },
  stackablyUnenrollOffersCreditDisclaimer: _t(
    'Each institution determines the number of credits (if any), earned by completing this content, that may count towards the degree requirements according to institutional policies, which may also take into account any other existing credits or other prior learning you may have.'
  ),
});

export default getHomepagesNavXDPCopy;
