import type { ProgramSuggestion } from 'bundles/clips/components/short-form-content/SFCVLPSharedTypes';

type ProgramHomeMiniXdpLinkParameters = {
  courseId?: string;
  orgSlug?: string;
  programSlug?: string;
  accessProvidedViaProgram?: ProgramSuggestion;
  trackingName?: string;
  itemId?: string;
};

export const getProgramHomeMiniXdpLink = ({
  courseId,
  orgSlug,
  programSlug,
  accessProvidedViaProgram,
  trackingName,
  itemId,
}: ProgramHomeMiniXdpLinkParameters) => {
  if (!courseId) {
    return '';
  }
  let programHomeMiniXdpLink;
  if (orgSlug) {
    programHomeMiniXdpLink = `/programs/all/${orgSlug}`;
  } else if (programSlug) {
    programHomeMiniXdpLink = `/programs/${programSlug}`;
  } else if (accessProvidedViaProgram?.slug) {
    programHomeMiniXdpLink = `/programs/${accessProvidedViaProgram.slug}`;
  } else {
    programHomeMiniXdpLink = '';
  }
  const params: Record<string, string> = { productId: courseId, productType: 'course', showMiniModal: 'true' };
  if (trackingName && itemId) {
    params.fromClip = encodeURIComponent(trackingName + '~' + itemId);
  }
  const qs = new URLSearchParams(params).toString();
  if (qs) {
    programHomeMiniXdpLink += `?${qs}`;
  }
  return programHomeMiniXdpLink;
};

export const getClipsTrackingInfo = (fromClip?: string) => {
  const clipsTrackingInfo: Record<string, string> = {};
  if (fromClip) {
    const trackingInfo = fromClip.split('~');
    if (trackingInfo.length === 2) {
      clipsTrackingInfo.fromClip = trackingInfo[0];
      clipsTrackingInfo.itemId = trackingInfo[1];
    }
  }
  return clipsTrackingInfo;
};
