import URI from 'jsuri';

import API from 'js/lib/api';
import { tupleToStringKey } from 'js/lib/stringKeyTuple';

const learnerCourseSchedulesAPI = API('/api/learnerCourseSchedules.v1/', { type: 'rest' });

export const getByUserIdAndCourseId = async (userId: number, courseId: string) => {
  const id = tupleToStringKey([userId, courseId]);
  const uri = new URI(id);
  return learnerCourseSchedulesAPI.get(uri.toString()).then((response) => {
    return response.elements && response.elements[0];
  });
};

export const adjustSchedule = async (userId: number, courseId: string, days: number | undefined) => {
  const id = tupleToStringKey([userId, courseId]);
  const uri = new URI().addQueryParam('action', 'adjust').addQueryParam('id', id);

  const data = {
    'org.coursera.ondemand.schedule.ShiftDeadlinesRequest': {
      days,
    },
  };

  return learnerCourseSchedulesAPI.post(uri.toString(), { data });
};

export const updateScheduleForUserLevelGoals = async (userId: number, courseId: string) => {
  const uri = new URI()
    .addQueryParam('action', 'updateScheduleForUserLevelGoals')
    .addQueryParam('userId', userId)
    .addQueryParam('courseId', courseId);

  return learnerCourseSchedulesAPI.post(uri.toString());
};

export default {
  getByUserIdAndCourseId,
  adjustSchedule,
  updateScheduleForUserLevelGoals,
};
