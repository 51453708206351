import type { Options } from 'bundles/cml/editor/html/types';
import type { Marks } from 'bundles/cml/shared/types/elementTypes';

const DEFAULT_MARKS: Record<string, () => Marks> = {
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  U: () => ({ underline: true }),
  STRONG: () => ({ bold: true }),
  PRE: () => ({ monospace: true }),
  SUP: () => ({ superscript: true }),
  SUB: () => ({ subscript: true }),
};

const MARKS_WITH_BOLD: Record<string, () => Marks> = {
  ...DEFAULT_MARKS,
  B: () => ({ bold: true }),
};

export const deserializeMarks = (el: HTMLElement, { isGoogleDocs }: Options): Marks | null => {
  // Google Docs uses `<b>` in weird ways so exclude it by default
  const marks = isGoogleDocs ? DEFAULT_MARKS : MARKS_WITH_BOLD;
  const markHandler = marks[el.nodeName];
  if (!markHandler) {
    return null;
  }

  return markHandler();
};
