/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { FormattedHTMLMessage } from 'react-intl';

import { Typography, Typography2, useTheme } from '@coursera/cds-core';

import { IdTypes } from 'bundles/product-features';
import { useProductFeatures } from 'bundles/program-home/productFeatureFlags';

import _t from 'i18n!nls/program-home';

type Props = {
  isLoggedIn: boolean;
  thirdPartyOrganizationId?: string;
  programId?: string;
};

export const EnterpriseHomeAccessErrorPage = ({ isLoggedIn, thirdPartyOrganizationId, programId }: Props) => {
  const theme = useTheme();

  const styles = {
    container: css`
      text-align: center;
      padding: var(--cds-spacing-600) 0;
      background-color: var(--cds-color-white-0);
    `,
    heading: css`
      margin-bottom: var(--cds-spacing-300);
    `,
    description: css`
      margin-bottom: var(--cds-spacing-600);
    `,
  };

  const { loading: isFeaturesLoading, features } = useProductFeatures({
    idType: programId ? IdTypes.Program : IdTypes.Organization,
    id: programId || thirdPartyOrganizationId || '',
    skip: !programId && !thirdPartyOrganizationId,
    ssr: true,
  });

  const description = isLoggedIn
    ? _t('You need to join or be invited to a program to access this page.')
    : _t('You need to log in to access this page.');

  let details: string = _t('Loading...');
  if (!isFeaturesLoading) {
    const customizedDetails = features.get('enterprise', 'inviteOnlyProgramDenyCustomMessage');
    if (customizedDetails) {
      details = customizedDetails;
    } else {
      details = isLoggedIn
        ? _t('Please ask your organization administrator to invite you to a program.')
        : _t('If you have not yet been invited to a program, please contact your organization administrator.');
    }
  }

  return (
    <div css={styles.container}>
      <Typography variant="d2" component="h1" css={styles.heading}>
        {_t('You cannot access this page')}
      </Typography>
      <Typography variant="h2" component="p" css={styles.description}>
        {description}
      </Typography>
      <Typography2 variant="bodyPrimary" component="p">
        <FormattedHTMLMessage message={details} />
      </Typography2>
    </div>
  );
};

export default EnterpriseHomeAccessErrorPage;
