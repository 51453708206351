/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { Typography, Typography2, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

import _t from 'i18n!nls/enterprise-admin-grow-with-google';

export type PropsFromCaller = {
  testimony: string | JSX.Element;
  personName: string;
  personTitle: string;
  personProfile: string;
};

const personDetails = (theme: Theme) =>
  css({
    display: 'flex',
    marginTop: 'var(--cds-spacing-150)',
  });

const quoteStyle = css({
  '&&': {
    lineHeight: '1rem',
    verticalAlign: 'bottom',
  },
});

const TestimonyCard = ({ testimony, personName, personTitle, personProfile }: PropsFromCaller) => {
  const theme = useTheme();

  return (
    <div>
      <FormattedMessage
        message={_t('{leftQuote} {testimony} {rightQuote}')}
        leftQuote={
          <Typography variant="d2semibold" component="span" color="eyebrowAqua" css={quoteStyle}>
            &ldquo;
          </Typography>
        }
        testimony={testimony}
        rightQuote={
          <Typography variant="d2semibold" component="span" color="eyebrowAqua" css={quoteStyle}>
            &rdquo;
          </Typography>
        }
      />
      <div css={personDetails(theme)}>
        <img src={personProfile} width="50px" height="50px" alt="" style={{ marginRight: 'var(--cds-spacing-150)' }} />
        <div>
          <Typography2 variant="subtitleMedium" component="p">
            {personName}
          </Typography2>
          <Typography2 component="p" color="supportText">
            {personTitle}
          </Typography2>
        </div>
      </div>
    </div>
  );
};

export default TestimonyCard;
