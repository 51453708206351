import { useCallback, useEffect, useState } from 'react';
import type React from 'react';

import { ReactEditor, useSlateStatic } from 'slate-react';

import Retracked from 'js/lib/retracked';
import useRouter from 'js/lib/useRouter';
import user from 'js/lib/user';

import {
  toggleEquatio,
  updateEquatioFormula,
  useEquatioChangeEffect,
  useEquatioDoneEffect,
  useEquatioFocusedState,
} from 'bundles/cml/editor/components/elements/math/equatioUtils';
import { useEventingContext } from 'bundles/cml/editor/context/eventingContext';

export type Props = {
  value: string;
  onChange: (value: string) => void;
  onDone: () => void;
  onBlur: () => void;
};

const MathEditorEquatio: React.FC<Props> = ({ value, onDone, onChange, onBlur }) => {
  const [open, setOpen] = useState<boolean | undefined>(undefined);
  const router = useRouter();
  const { isLearnerUpload, eventingData } = useEventingContext();

  const trackEquatioUsage = useCallback(() => {
    const userId = user.get().id;
    if (eventingData && userId) {
      /* eslint-disable camelcase */
      const trackingDetails = {
        user_id: userId,
        user_role: isLearnerUpload ? 'learner' : 'educator',
        item_id: router.params.itemId,
      };
      /* eslint-enable camelcase */

      Retracked.trackComponent(eventingData, trackingDetails, 'cml_editor', 'open_equatio');
    }
  }, [eventingData, isLearnerUpload, router.params.itemId]);

  useEffect(
    () => {
      toggleEquatio(true).then((didOpen: boolean) => {
        setOpen(didOpen);
        if (didOpen) {
          updateEquatioFormula(value);
          trackEquatioUsage();
        }
      });

      return () => {
        toggleEquatio(false);
      };
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const focused = useEquatioFocusedState(open);

  const staticEditor = useSlateStatic();
  const handleBlur = useCallback(() => {
    onBlur();
    setTimeout(() => {
      ReactEditor.blur(staticEditor);
    }, 250);
  }, [onBlur, staticEditor]);

  useEffect(() => {
    if (focused === false) {
      handleBlur();
    }
  }, [focused, handleBlur]);

  useEquatioDoneEffect(onDone);
  useEquatioChangeEffect(onChange);

  return null;
};

export default MathEditorEquatio;
