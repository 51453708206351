import URI from 'jsuri';
import Q from 'q';

import API from 'js/lib/api';

import type { EvaluatorSummaryConstructorType } from 'bundles/code-evaluator/models/EvaluatorSummary';

const evalResultsAPI = API('/api/evalResults.v1?includes=logs,feedback', {
  type: 'rest',
});

const evalEvaluatorSummariesAPI = API('/api/evalEvaluatorSummaries.v1', {
  type: 'rest',
});

export const run = (evaluatorId: string, expression: string) => {
  const options = {
    data: { evaluatorId, expression },
  };
  return Q(evalResultsAPI.post('', options)).then((response) => {
    // Parse out related logs resource
    const newResponse = Object.assign({}, response.elements[0]);
    newResponse.logUrls = response.linked['evalEvaluationLogs.v1'][0];

    const relatedHints = response.linked['evalNextGenResults.v1'];
    newResponse.hints = (relatedHints && relatedHints[0] && relatedHints[0].hints) || [];

    return newResponse;
  });
};

export const getSummary = (evaluatorId: string): Q.Promise<EvaluatorSummaryConstructorType> => {
  const uri = new URI(evaluatorId).addQueryParam('fields', ['executionTimeSummary', 'latestUserExpression'].join());

  return Q(evalEvaluatorSummariesAPI.get(uri.toString())).then((response) => response.elements[0]);
};

export const EvaluatorAPIUtils = { run, getSummary };

export default EvaluatorAPIUtils;
