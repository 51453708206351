import gql from 'graphql-tag';

import discoveryCollectionsGql from 'js/lib/discoveryCollectionsGql';
import enterpriseBadgeCollectionsGql from 'js/lib/enterpriseBadgeCollectionsGql';

import { CollectionListItemFragment } from 'bundles/program-common/constants/ProgramCommonGraphqlQueries';

export const SkillBasedRecommendationsQuery = gql`
query SkillBasedRecommendationsQuery($programId: String!, $numEntriesPerCollection: Int!, $start: String, $limit: Int) {
  ProgramCurriculumCollectionsV1Resource {
    skillProfileCollectionsByProgram(programId: $programId, numEntriesPerCollection: $numEntriesPerCollection, start: $start, limit: $limit) {
      elements {
        ...CollectionListItemFragment
      }
      paging {
        total
        next
      }
    }
  }
}
${CollectionListItemFragment},
`;

export const SingleProgramProductMetadataFragment = gql`
  fragment SingleProgramProductMetadataFragment on ProgramProductMetadataV1 {
    id
    metadataType {
      ... on ProgramProductMetadataV1_courseMember {
        course {
          isExclusive
          isSelectedForCredit
          courseId
        }
      }

      ... on ProgramProductMetadataV1_specializationMember @include(if: $allowOrgForSpecializationConfiguration) {
        specialization {
          isSelectedForCredit
          s12nId
          associatedSessionId
        }
      }
    }
  }
`;

export const CurriculumCollectionsQuery = gql`
query CurriculumCollectionsQuery($programId: String!, $start: String!, $limit: Int, $enableCurriculumBuilder: Boolean!, $allowOrgForSpecializationConfiguration: Boolean!) {
  ProgramCurriculumCollectionsV1Resource {
    curriculumTracksByProgram(programId: $programId, start: $start, limit: $limit ) {
      elements {
        ...CollectionListItemFragment
        programProductMetadata @include(if: $enableCurriculumBuilder) {
          elements {
          ...SingleProgramProductMetadataFragment
          }
        }
      }
      paging {
        total
        next
      }
    }
  }
}
${CollectionListItemFragment},
${SingleProgramProductMetadataFragment},
`;

export const ProgramMembershipsByUserQuery = gql`
  query ProgramMembershipsByUserQuery($userId: String!) {
    ProgramMembershipsV2Resource {
      byUser(userId: $userId) {
        elements {
          id
          enterpriseProgram(eventualConsistency: true) {
            id
            thirdPartyOrganizationId
          }
        }
      }
    }
  }
`;

export type AcademicDisciplineSummariesResult = {
  AcademicDiscipline: {
    queryAcademicDisciplineById: {
      name: string;
    };
  };
};

export type AcademicDisciplineNameQueryVariables = {
  disciplineId: string;
};

export const AcademicDisciplineNameQuery = gql`
  query AcademicDisciplineNameQuery($disciplineId: String!) {
    AcademicDiscipline {
      queryAcademicDisciplineById(id: $disciplineId) {
        name
      }
    }
  }
`;

export const ForCreditCurriculumTracksByProgramQuery = gql`
  query ForCreditCurriculumTracksByProgramQuery($programId: String!, $allowOrgForSpecializationConfiguration: Boolean!) {
    ProgramCurriculumCollectionsV1Resource {
      forCreditCurriculumTracksByProgram(programId: $programId) {
        elements {
          ...CollectionListItemFragment
          programProductMetadata {
            elements {
              ...SingleProgramProductMetadataFragment
            }
          }
        }
      }
    }
  }
  ${CollectionListItemFragment},
  ${SingleProgramProductMetadataFragment},
`;

export const DiscoveryBrowseCollectionsQuery = discoveryCollectionsGql`
query DiscoveryBrowseCollectionsQuery($contextType: String!, $contextId: String!, $programId: String!, $start: Int!, $limit: Int!) {
  DiscoveryCollections {
    queryEnterpriseCollectionsByProgram(
      input: {
        contextType: $contextType, 
        contextId: $contextId,
        programId: $programId, 
        cursor: {
          start: $start, 
          limit: $limit
        }
      }
      ) {
        productCollections {
          id
          label
          linkedCollectionPageMetadata {
            url
          }
          entities {
            __typename
            id
            slug
            name
            url
            partnerIds
            partners {
              id
              name
              logo
            }
            imageUrl
            ... on DiscoveryCollections_specialization {
              courseCount
              productCard {
                productTypeAttributes {
                  ... on ProductCard_Specialization {
                    isPathwayContent
                  }
                }
              }
            }
            ... on DiscoveryCollections_professionalCertificate {
              productCard {
                productTypeAttributes {
                  ... on ProductCard_Specialization {
                    isPathwayContent
                  }
                }
              }
            }
            ... on DiscoveryCollections_course {
              productCard {
                productTypeAttributes {
                  ... on ProductCard_Course {
                    isPathwayContent
                  }
                }
              }
              enterpriseCustomSessions {
                courseId
                startsAt {
                  seconds
                }
                endsAt {
                  seconds
                }
              }
            }
            ... on DiscoveryCollections_guidedProject {
              productCard {
                productTypeAttributes {
                  ... on ProductCard_Course {
                    isPathwayContent
                  }
                }
              }
              enterpriseCustomSessions {
                courseId
                startsAt {
                  seconds
                }
                endsAt {
                  seconds
                }
              }
            }
          }
        }
      next
    }
  }
}
`;

export const EnterpriseBadgeCollectionsQuery = enterpriseBadgeCollectionsGql`
  query EnterpriseBadgeCollectionsQuery($userId: String!, $programId: String!) {
      EnterpriseBadge {
        findByUserAndProgram(userId: $userId, programId: $programId) {
          badgeCollectionsWithUserState {
            title
            collectionId
            badgeTemplateId
            description
            learningObjectives
            imageUrl
            userState
            productIds {
              id
              type
            }
          }
        }
      }
  }
`;

export const EnterpriseBadgesCoursesSessionDatesQuery = gql`
  query EnterpriseBadgesCoursesSessionDatesQuery($courseIds: [String!]!, $programId: String!) {
    EnterpriseProgramSessionAssociationsV1Resource {
      byProgramAndCourses(courseIds: $courseIds, programId: $programId, limit: 50) {
        elements {
          id
          session {
            id
            courseId
            startsAt
            endsAt
          }
        }
      }
    }
  }
`;

export const S12nSessionDatesQuery = gql`
  query S12nSessionDatesQuery($ids: [String!]!) {
    OnDemandCourseSessionsV1Resource {
      multiGet(ids: $ids) {
        elements {
          id
          startsAt
          endsAt
        }
      }
    }
  }
`;
