import config from 'js/app/config';

export const PRIVACY_NOTICE_KEY_INFORMATION_SECTION_UNIQUE_ID = 'key-information-privacy-notice';

export const PRIVACY_NOTICE_ADDITIONAL_INFORMATION_SECTION_UNIQUE_ID = 'additional-information-privacy-notice';

export const TERMS_PAGE_CONTENTFUL_SLUG = 'terms';
export const OLD_TERMS_PAGE_CONTENTFUL_SLUG = 'terms-old';
export const OLD_TERMS_PAGE_PATHNAME = '/about/terms-old';

export const PRIVACY_PAGE_CONTENTFUL_SLUG = 'privacy';
export const OLD_PRIVACY_PAGE_CONTENTFUL_SLUG = 'privacy-old';
export const OLD_PRIVACY_PAGE_PATHNAME = '/about/privacy-old';

export const PRIVACY_CCPA_PAGE_CONTENTFUL_SLUG = 'privacy-ccpa';

export const PRIVACY_DPA_PAGE_CONTENTFUL_SLUG = 'privacy-data-protection-addendum';

export const ZAPP_PAGE_CONTENTFUL_SLUG = 'live2coursera-terms';

export const SECURITY_PAGE_SLUG = 'security';

export const COOKIES_PAGE_SLUG = 'cookies';

export const REFUND_POLICY_LINK = `${config.url.base}about/terms#payments-and-refund-policy`;

export const COURSE_BUILDER_CONTENTFUL_SLUG = 'coursera-content-authoring-addendum';
