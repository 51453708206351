import type { FunctionComponent } from 'react';
import * as React from 'react';

import { compose, mapProps, pure } from 'recompose';

import waitForProps from 'js/lib/waitForProps';

import { Button, Loading, color } from '@coursera/coursera-ui';

import { withRenderNothing } from 'bundles/coursera-ui/components/hocs/withBranches';
import { getManageProgramLearnersLink } from 'bundles/enterprise-admin-navigation/utils/AdminNavigationUtils';
import Naptime from 'bundles/naptimejs';
import EnterpriseProgramsV1 from 'bundles/naptimejs/resources/enterprisePrograms.v1';
import ThirdPartyOrganizations from 'bundles/naptimejs/resources/thirdPartyOrganizations.v1';

import _t from 'i18n!nls/program-home';

type Props = {
  programSlug: string;
  orgSlug: string;
  orgId: string;
};

/**
 * Consolidate all program admin related logic that's living at program home
 * Currently only the invitation link. But may add more later
 */
export const ProgramAdminActions: FunctionComponent<Props> = ({ programSlug, orgSlug }) => {
  const programInvitationUrl = getManageProgramLearnersLink({
    programSlug,
    thirdPartySlug: orgSlug,
  });

  return (
    <div className="vertical-box align-items-left">
      {programInvitationUrl && (
        <span>
          <Button
            type="primary"
            tag="a"
            label={_t('Go to Invitations')}
            style={{
              backgroundColor: color.admin,
              borderColor: color.admin,
            }}
            htmlAttributes={{
              href: programInvitationUrl,
              target: '_blank',
              rel: 'noopener noreferrer',
            }}
          />
        </span>
      )}
      <p className="m-b-0 font-sm text-secondary font-italic">
        {_t('You do not have full access to this program. To join, send yourself an invitation.')}
      </p>
    </div>
  );
};

type PropsFromCaller = {
  programId: string;
};

type EnterpriseProgramStub = {
  thirdPartyOrganizationId: string;
  slug: string;
};

type ThirdPartyOrgStub = {
  id: string;
  name: string;
  slug: string;
};

type ProgramFromNaptime = {
  program?: EnterpriseProgramStub;
};

type ThirdPartyOrgFromNaptime = {
  thirdPartyOrg?: ThirdPartyOrgStub;
};

type PropsFromNaptime = Required<ProgramFromNaptime> & ThirdPartyOrgFromNaptime;

type PropsForRenderNothing =
  | {
      shouldRenderNothing: true;
    }
  | (Props & {
      shouldRenderNothing: false;
    });

export const enhancer = compose<Props, PropsFromCaller>(
  Naptime.createContainer<ProgramFromNaptime, PropsFromCaller>(({ programId }) => ({
    program: EnterpriseProgramsV1.get(programId, {
      fields: ['thirdPartyOrganizationId'],
      eventualConsistency: true,
    }),
  })),
  waitForProps(['program'], <Loading />),
  Naptime.createContainer<PropsFromNaptime, Required<ProgramFromNaptime>>(({ program }) => ({
    thirdPartyOrg:
      program && program.thirdPartyOrganizationId
        ? ThirdPartyOrganizations.get(program.thirdPartyOrganizationId, {
            fields: ['name', 'slug'],
          })
        : undefined,
  })),
  mapProps<PropsForRenderNothing, PropsFromNaptime>(({ program, thirdPartyOrg }) =>
    !thirdPartyOrg
      ? { shouldRenderNothing: true }
      : { shouldRenderNothing: false, programSlug: program.slug, orgId: thirdPartyOrg.id, orgSlug: thirdPartyOrg.slug }
  ),
  withRenderNothing,
  pure
);

export default enhancer(ProgramAdminActions);
