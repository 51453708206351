/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useReducer } from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { Button, Link, Typography, Typography2 } from '@coursera/cds-core';

import { useSkillAdvancementData } from 'bundles/skills-common/private/skill-advancement/useSkillAdvancementData';

import _t from 'i18n!nls/skills-common';

import SkillAdvancementCard from './SkillAdvancementCard';

type Props = {
  className?: string;
  courseId: string;
  isS12n: boolean;
  userId: number;
};

const styles = {
  container: css`
    padding: var(--cds-spacing-200);
    display: flex;
    flex-direction: column;
    gap: var(--cds-spacing-150);
  `,
};

const SkillAdvancementContainer: React.FC<Props> = ({ className, isS12n, courseId, userId }) => {
  const [showMore, toggleShowMore] = useReducer((value) => !value, false);
  const { error, advancements, skillset, programSlug } = useSkillAdvancementData({ courseId, userId });

  // Don't show if:
  //    there are no advancements
  //    the user is not part of a program (won't have access to skills)
  //    there's been an error
  if (!advancements?.length || !programSlug || error) {
    return null;
  }

  return (
    <div className={className} css={styles.container}>
      <Typography variant="h2semibold">{_t('You increased your skill scores!')}</Typography>
      {!isS12n && skillset && (
        <Typography2 component="p" variant="bodySecondary">
          <FormattedMessage
            message={_t('You’re making great progress in the {skillset}')}
            skillset={
              <Link variant="standard" href={`/programs/${programSlug}/skillsets/${skillset.slug}`} target="_blank">
                {_t('#{skillsetName} SkillSet', {
                  skillsetName: skillset.name,
                })}
              </Link>
            }
          />
        </Typography2>
      )}
      {advancements.slice(0, showMore ? 5 : 1).map((advancement) => (
        <SkillAdvancementCard
          key={advancement.skillId}
          skill={{
            ...advancement,
            // is in a specialization, or there isn't a skillset, or if the skill is not found, don't show a targetScore
            targetScore:
              (!isS12n && skillset?.skills.find((skill) => skill.skillId === advancement.skillId)?.targetScore) ||
              undefined,
          }}
          programSlug={programSlug}
          showSkillPageLink={!isS12n && !skillset}
        />
      ))}
      <div>
        <Button edgeAlign="start" variant="ghost" onClick={toggleShowMore}>
          {showMore ? _t('Show less skills') : _t('Show more skills')}
        </Button>
      </div>
    </div>
  );
};

export default SkillAdvancementContainer;
