/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useState } from 'react';

import { Dialog, InlineNotification, breakpoints, useTheme } from '@coursera/cds-core';

import { Button } from 'bundles/enterprise-ui/components/Button';

import _t from 'i18n!nls/enterprise-ui';

const { HeadingGroup, Content, Actions } = Dialog;

export const ConfirmationDialog: React.FC<{
  open: boolean;
  heading: string;
  children?: React.ReactNode;
  confirmationLabel: string;
  onConfirm: () => void;
  onClose: () => void;
}> = ({ children, open, heading, confirmationLabel, onClose, onConfirm, ...rest }) => {
  const theme = useTheme();
  const [error, setError] = useState<Error | undefined>(undefined);
  return (
    <Dialog aria-describedby="confirmation-dialog-content" open={open} variant="standard" onClose={onClose} {...rest}>
      <HeadingGroup>{heading}</HeadingGroup>
      <Content id="confirmation-dialog-content">
        {children}
        {error && (
          <InlineNotification
            severity="error"
            title={_t('Something went wrong!')}
            css={{ marginTop: 'var(--cds-spacing-300)' }}
          >
            {error.message}
          </InlineNotification>
        )}
      </Content>
      <Actions
        css={css`
          display: flex;
          gap: var(--cds-spacing-300);
          ${breakpoints.down('xs')} {
            flex-direction: column;
            gap: var(--cds-spacing-200);
          }
        `}
      >
        <Button
          css={css`
            ${breakpoints.down('xs')} {
              max-width: none;
            }
          `}
          size="medium"
          variant="secondary"
          onClick={onClose}
          data-testid="cancel-confirmation"
        >
          {_t('Cancel')}
        </Button>
        <Button
          css={css`
            ${breakpoints.down('xs')} {
              max-width: none;
            }
          `}
          size="medium"
          variant="primary"
          onClick={async () => {
            try {
              await onConfirm();
              onClose();
            } catch (thrownError) {
              setError(thrownError);
            }
          }}
          data-testid="confirm"
        >
          {confirmationLabel}
        </Button>
      </Actions>
    </Dialog>
  );
};
