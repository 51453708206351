/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import _t from 'i18n!nls/skills-common';

import ProgressTrackerPopoverContent from './ProgressTrackerPopoverContent';
import { generatePersonalCopy } from './messages';

type PersonalScorePopoverContentProps = {
  score?: number;
  target?: number;
  skillName?: string;
};

const styles = {
  noScoreText: {
    fontSize: 12,
    color: '#666666',
  },
};

const PersonalScorePopoverContent: React.FC<PersonalScorePopoverContentProps> = (props) => {
  const { score, target, skillName } = props;

  if (score === undefined) {
    return (
      <ProgressTrackerPopoverContent trackingData={{ skillName, score, target }} score={score} skillName={skillName}>
        <div css={styles.noScoreText}>{_t('You are currently not enrolled in any courses tagged to this skill')}</div>
      </ProgressTrackerPopoverContent>
    );
  }

  const personalCopy = generatePersonalCopy();

  return (
    <ProgressTrackerPopoverContent
      trackingData={{ skillName, score, target }}
      copy={personalCopy}
      score={score}
      skillName={skillName}
    />
  );
};

export default PersonalScorePopoverContent;
