import * as React from 'react';
import { createContext, useContext } from 'react';

import PropTypes from 'prop-types';
import type { LegacyContextType } from 'types/legacy-context-types';

import type { TrackingContextData } from 'js/lib/retracked';

export type EventingContextType = {
  isLearnerUpload: boolean;
  eventingData?: TrackingContextData;
};

export const EventingContext = createContext<EventingContextType>({
  isLearnerUpload: false,
});

export const useEventingContext = () => useContext(EventingContext);

export const withEventingContext = <T extends { isLearnerUpload?: boolean }>(
  Component: React.ComponentType<T>
): React.ComponentType<T> => {
  const componentContextTypes = {
    _eventData: PropTypes.object,
  };
  return class extends React.Component<T> {
    static contextTypes = componentContextTypes;

    declare context: LegacyContextType<typeof componentContextTypes>;

    render() {
      const context: EventingContextType = {
        eventingData: this.context._eventData,
        isLearnerUpload: this.props.isLearnerUpload ?? false,
      };

      return (
        <EventingContext.Provider value={context}>
          <Component {...this.props} context={this.context} />
        </EventingContext.Provider>
      );
    }
  };
};
