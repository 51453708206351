import type { ApolloCache, ApolloClient, OperationVariables, QueryOptions } from '@apollo/client';

type CacheDataStub = {
  data: Record<string, any>;
  rootIds: Record<string, number>;
};

/**
 * Remove objects with given typeName from React Apollo cache.
 * Warning: it removes only objects and don't remove references to them in other objects.
 * That might lead to cache inconsistency and unexpedted results.
 *
 * TODO: Deprecate it in favor of `evict` after we migrate to Apollo Client 3.
 */
export const clearCacheWithTypename = (cache: ApolloCache<any>, typeName: string) => {
  // Loop through all the data in our cache
  // And delete any items that start with typeName
  // This clears all the cache items with given typeName and
  // forces a refetch of the data.
  const typeNameRegex = new RegExp(`^${typeName.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&')}`);
  // @ts-expect-error Property 'data' does not exist on type 'ApolloCache<any>'
  const data: CacheDataStub = cache.data;
  Object.keys(data.data).forEach((id) => id.match(typeNameRegex) && cache.evict({ id }));
  Object.keys(data.rootIds).forEach((id) =>
    Object.keys(data.data[id]).forEach(
      (fieldName: string) => data.data[id][fieldName]?.__typename === typeName && cache.evict({ id, fieldName })
    )
  );
};

/**
 * Remove objects with given multiple typeNames from React Apollo cache.
 * Warning: it removes only objects and don't remove references to them in other objects.
 * That might lead to cache inconsistency and unexpedted results.
 *
 * TODO: Deprecate it in favor of `evict` after we migrate to Apollo Client 3.
 */
export const clearCacheWithTypenames = (cache: ApolloCache<any>, typeNames: Array<string>) => {
  typeNames.forEach((typeName) => clearCacheWithTypename(cache, typeName));
};

/** Refetches an Apollo query if it is found in the cache. Use this as a replacement for client.refetchQuery */
export async function refetchQuery<T = unknown, TVariables extends OperationVariables = OperationVariables>(
  client: ApolloClient<unknown>,
  options: QueryOptions<TVariables>
): Promise<void> {
  // If we don't have any cached data, we don't have to do anything. Note: apollo changed behavior, so the docs
  // are wrong for our version, so check both ways so it doesn't explode later.
  //
  // Excerpt: Prior to Apollo Client 3.3, readQuery would throw MissingFieldError exceptions to report missing
  // fields. Beginning with Apollo Client 3.3, readQuery always returns null to indicate fields were missing.
  //
  // Source: https://www.apollographql.com/docs/react/caching/cache-interaction/#readquery
  try {
    const data = client.readQuery<T, TVariables>(options);
    if (data == null) {
      return;
    }
  } catch (err) {
    const shouldSwallowError =
      err?.message?.includes("Can't find field") ||
      // In production, the actual message gets compiled away for some reason: it's always just "Invariant
      // Violation"
      err?.message?.includes('Invariant Violation');
    if (shouldSwallowError) {
      return;
    } else {
      throw err;
    }
  }
  // If we do have cached data, we have to refetch it here because our version of apollo doesn't support cache
  // invalidation 😂
  const refetchOptions = options.fetchPolicy ? options : { ...options, fetchPolicy: 'network-only' as const };
  await client.query<T, TVariables>(refetchOptions);
}
