import {
  DOMAIN_PAGE_SEARCH_FILTERS,
  ENTERPRISE_AUTOCOMPLETE_CLIPS_FACETFILTER_VALUES,
  ENTERPRISE_AUTOCOMPLETE_NON_CLIP_FACETFILTER_VALUES,
} from 'bundles/enterprise-learner-search/constants';
import {
  SEARCH_ENTERPRISE_AUTOCOMPLETE_CLIPS_INDEX_ID,
  SEARCH_ENTERPRISE_AUTOCOMPLETE_COURSES_S12N_INDEX_ID,
} from 'bundles/search-common/constants';
import type { SearchConfig, SearchContext } from 'bundles/search-common/providers/searchTypes';

export const filterHiddenFilters = (f: string) =>
  !f.includes(':-') && DOMAIN_PAGE_SEARCH_FILTERS.includes(f.split(':')[0]);

export const getEnterpriseSearchConfigs = (searchContext?: SearchContext): SearchConfig[] => {
  return [
    {
      id: SEARCH_ENTERPRISE_AUTOCOMPLETE_COURSES_S12N_INDEX_ID,
      entityType: 'PRODUCTS',
      searchContext,
      limit: 7,
      facetFilters: ENTERPRISE_AUTOCOMPLETE_NON_CLIP_FACETFILTER_VALUES,
    },
    {
      id: SEARCH_ENTERPRISE_AUTOCOMPLETE_CLIPS_INDEX_ID,
      entityType: 'PRODUCTS',
      searchContext,
      limit: 4,
      facetFilters: ENTERPRISE_AUTOCOMPLETE_CLIPS_FACETFILTER_VALUES,
    },
  ];
};
