import type { ApiOptions } from 'js/lib/api';

import wrapApi from 'bundles/phoenix/lib/apiWrapper';

const API_DEFAULTS = {
  type: 'rest',
};

export default function (moreApiConfig?: ApiOptions) {
  const apiConfig: ApiOptions = Object.assign({}, API_DEFAULTS, moreApiConfig);
  return wrapApi('/api/', apiConfig);
}
