/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import gql from 'graphql-tag';

import { breakpoints } from '@coursera/cds-core';

import Skills from 'bundles/enterprise-legacy-xdp/components/cds/Skills';
import type { Skills as PdpSkillsType } from 'bundles/enterprise-legacy-xdp/components/miniPDP/__generated__/Skills';
import withFragments from 'bundles/graphql/components/withFragments';

import _t from 'i18n!nls/program-common';

const styles = {
  container: () => css`
    ${breakpoints.down('md')} {
      margin-top: var(--cds-spacing-400);
    }
  `,
};

type Props = Omit<PdpSkillsType, '__typename'>;

export function PdpSkills({ skills }: Props) {
  return (
    // NOTE: The `Skills` component has generally been used in pages where it was wide enough such that there weren't any overflow issues for the skills pills. This changes with `PdpSkills` which only takes up 1/4th-1/3rd of `PdpPage`. This causes the skill label to get cut-off on web for skills like 'Machine Learning (ML) Algorithms', which was previously only a problem on mobile-web. We (#falcon-fe) are deferring fixing this now for Pdps in favor of a more global fix in CUI later: https://coursera.slack.com/archives/CAH3JRZ9V/p1583355249044100
    <Skills
      skills={skills}
      sectionTitle={_t('Skills you will develop')}
      rootClassName="pdp-skills-cds"
      shouldShowBorder={false}
      css={styles.container}
    />
  );
}

export default withFragments({
  PdpSkills: gql`
    fragment Skills on XdpV1_org_coursera_xdp_cdp_CDPMetadata {
      skills
    }
  `,
})(PdpSkills);
