import {
  org_coursera_product_enterprise_contract_EnterpriseContractState as EnterpriseContractState,
  org_coursera_product_enterprise_contract_EnterpriseContractType as EnterpriseContractType,
} from 'bundles/admin-dashboard/utils/__generated__/globalTypes';

export const allContractsHardPausedOrTerminated = (
  contracts?:
    | {
        contractType: string;
        contractState?: string | null;
        hardPausedAt?: number | null;
      }[]
    | null
) => {
  if (!contracts || contracts.length === 0) return false;
  return contracts.every((contract) => {
    const wes = contract.contractType === EnterpriseContractType.SELF_SERVE;
    const hardPaused = contract.contractState === EnterpriseContractState.PAUSED && contract.hardPausedAt;
    const terminated = contract.contractState === EnterpriseContractState.TERMINATED;
    return !wes && (hardPaused || terminated);
  });
};
