import * as React from 'react';
import { useContext } from 'react';

import { useReadOnly } from 'slate-react';

import { CommentDotsIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import { AssetContext } from 'bundles/cml/editor/context/assetContext';

import _t from 'i18n!nls/cml';

export type Props = {
  onClick: () => void;
};

const ImageDescriptionButton: React.FC<Props> = ({ onClick }) => {
  const { isLearnerUpload } = useContext(AssetContext);
  const readonly = useReadOnly();

  if (isLearnerUpload) {
    return null;
  }

  return (
    <Button
      role="menuitem"
      disabled={readonly}
      onClick={onClick}
      title={_t('Accessibility description')}
      tooltipProps={{ placement: 'bottom' }}
      type={TOOLBAR_BUTTON_TYPES.menu}
    >
      <CommentDotsIcon size="small" />
    </Button>
  );
};

export default ImageDescriptionButton;
