import * as React from 'react';
import { createContext, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';

import { COACH_STUDIO_DEFAULT_PROMPT, COACH_STUDIO_SESSION_STORAGE_KEY } from 'bundles/ai-coach-studio/constants';
import type { StudioSettings } from 'bundles/ai-coach-studio/types';
import { usePersistedState } from 'bundles/ai-coach-studio/utils/sessionUtils';

type StudioSettingsState = {
  /** Coach Studio settings state */
  studioSettings: StudioSettings;

  /** update view state of settings screen */
  setStudioSettings: Dispatch<SetStateAction<StudioSettings>>;

  /** valid state of settings form */
  isSettingsFormValid: boolean;

  /** update valid state of settings form */
  setIsSettingsFormValid: Dispatch<SetStateAction<boolean>>;
};

const defaultStudioSettings = {
  model: 'gpt-4o',
  systemPrompt: COACH_STUDIO_DEFAULT_PROMPT,
  pageContext: '',
  additionalContext: [],
  contextFetchers: [],
  additionalProducts: [],
  courseId: '',
  specializationId: '',
  modelParameters: { chatHistoryLimit: 10, chatHistoryTokenLimit: 500, totalTokenLimit: 4000 },
};
const CoachStudioSettingsContext = createContext<StudioSettingsState>({
  studioSettings: defaultStudioSettings,
  setStudioSettings: () => {},
  isSettingsFormValid: true,
  setIsSettingsFormValid: () => {},
});

const CoachStudioSettingsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [studioSettings, setStudioSettings] = usePersistedState(
    defaultStudioSettings,
    COACH_STUDIO_SESSION_STORAGE_KEY
  );

  const [isSettingsFormValid, setIsSettingsFormValid] = useState<boolean>(true);

  return (
    <CoachStudioSettingsContext.Provider
      value={{ studioSettings, setStudioSettings, isSettingsFormValid, setIsSettingsFormValid }}
    >
      {children}
    </CoachStudioSettingsContext.Provider>
  );
};

export { CoachStudioSettingsContext, CoachStudioSettingsContextProvider };
