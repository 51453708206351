import clsx from 'clsx';

import {
  useLocale,
  useId,
  useLocalizedStringFormatter,
} from '@coursera/cds-common';
import { StarFilledColoredIcon } from '@coursera/cds-icons';

import Typography from '@core/Typography2';
import VisuallyHidden from '@core/VisuallyHidden';

import getRatingStatCss, { classes } from './getRatingStatCss';
import i18nMessages from './i18n';

export type Props = {
  id?: string;

  /**
   * Rating number.
   * **Note:**
   * The number is clamped between 0 and 5.
   * The number is localized and formatted up to two fraction digits with default rounding strategy.
   */
  rating: number;

  /**
   * Number of reviews.
   * **Note:**
   * The number is localized.
   */
  reviews?: number;

  /**
   * The size of the component, wheres label option is the smallest size.
   * @default label
   */
  size?: 'label' | 'display';

  /**
   * If set to  `true`, inverted styles are applied.
   */
  invert?: boolean;
  className?: string;

  /**
   * Use this render prop to provide custom wrapper for "a number reviews".
   * Where `children` is concatenation of localized reviews number and a word "reviews".
   * Use `aria-describedby` to create improved relationship between the rating and reviews elements.
   * Be aware that the `aria-describedby` attribute can be used with semantic HTML elements and with elements that have an ARIA role.
   *
   * @example
   *
   * ```tsx
   * <RatingStat rating={4.9}>
   *   {({ 'aria-describedby': ariaDescribedby, content }) => (
   *      <Button
   *        size="small"
   *        variant="ghost"
   *        edgeAlign="start"
   *        aria-describedby={ariaDescribedby}
   *      >
   *       {content}
   *      </Button>
   *    )}
   * </RatingStat>
   *
   * ```
   */
  renderReviews?: (props: {
    'aria-describedby': string;
    children: string;
  }) => React.ReactNode;

  /**
   * Alternative accessible label for the `meter` element.
   */
  'aria-label'?: string;

  /**
   * Identifies the element (or elements) that labels `meter` element.
   */
  'aria-labelledby'?: string;
};

/**
 * The rating stat provides information for users to discover the opinions of other users who have previously used or taken a course, item, or topic
 *
 * See [Props](__storybookUrl__/components-data-display-rating-stat--default#props)
 */
const RatingStat = (props: Props) => {
  const {
    id: idFromProps,
    renderReviews,
    rating,
    reviews,
    size = 'label',
    invert,
    className,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledby,
    ...rest
  } = props;
  const RatingStatDelimiter = '·';

  const supportTextColor = invert ? 'invertBody' : 'supportText';

  const { locale } = useLocale();
  const stringFormatter = useLocalizedStringFormatter(i18nMessages);

  const ratingNumberFormatter = Intl.NumberFormat(locale, {
    maximumFractionDigits: 2,
  });

  const reviewsNumberFormatter = Intl.NumberFormat(locale, {
    notation: 'compact',
    compactDisplay: 'short',
  });

  const id = useId(idFromProps);
  const meterId = `${id}-meter`;
  const describedByLabelId = `${id}-aria-describedby-label`;

  const reviewsStr = reviews
    ? stringFormatter.format('reviews', {
        number: reviewsNumberFormatter.format(reviews),
      })
    : undefined;

  const clampedRating = Math.max(0, Math.min(rating, 5));

  const ratingStr = ratingNumberFormatter.format(clampedRating);

  return (
    <div
      className={clsx(className, {
        [classes.sizeLabel]: size === 'label',
        [classes.sizeDisplay]: size === 'display',
        [classes.inverted]: invert,
      })}
      css={getRatingStatCss}
      id={id}
      {...rest}
    >
      <div
        aria-label={ariaLabel ?? stringFormatter.format('rating')}
        aria-labelledby={ariaLabelledby}
        aria-valuemax={5}
        aria-valuemin={0}
        aria-valuenow={clampedRating}
        aria-valuetext={stringFormatter.format('ratingValue', {
          number: ratingStr,
        })}
        className={classes.meter}
        id={meterId}
        role="meter"
      >
        <StarFilledColoredIcon
          className={classes.icon}
          size={size === 'display' ? 'large' : 'medium'}
        />

        <Typography
          color={invert ? 'invertBody' : 'body'}
          component="span"
          variant={size === 'display' ? 'titleSmall' : 'subtitleMedium'}
        >
          {ratingStr}
        </Typography>

        <VisuallyHidden id={describedByLabelId}>
          {`${stringFormatter.format('rating')}, ${stringFormatter.format(
            'ratingValue',
            {
              number: ratingStr,
            }
          )}`}
        </VisuallyHidden>
      </div>

      {reviewsStr && (
        <>
          <Typography
            aria-hidden
            color={supportTextColor}
            component="span"
            variant="bodySecondary"
          >
            {RatingStatDelimiter}
          </Typography>
          <Typography
            color={supportTextColor}
            component="div"
            variant="bodySecondary"
          >
            {renderReviews
              ? renderReviews({
                  'aria-describedby': describedByLabelId,
                  children: reviewsStr,
                })
              : reviewsStr}
          </Typography>
        </>
      )}
    </div>
  );
};

export default RatingStat;
