/* eslint-disable no-param-reassign */
import ProductType from 'bundles/payments/common/ProductType';
import createCartPromise from 'bundles/payments/promises/createCart';

/**
 * Create a Credential Track Cart. NOTE: Refer to ./createCart for more information.
 * @param {string} id - The productItemId
 * @param {Object} options - Refer to ./createCart
 * @param {Array} options.auxiliaryCartInfo - Refer to ./createCart
 * @returns {Promise.<Object>} ./createCart response.
 */
export default function (id: string, options: $TSFixMe = {}) {
  options = {
    ...options,
    productItems: [
      {
        productType: options.productType || ProductType.CREDENTIAL_TRACK,
        productItemId: id,
        productAction: 'Buy',
        cartItemIdToRefund: {},
      },
    ],
  };
  return createCartPromise(options);
}
