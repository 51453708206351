import * as React from 'react';
import { useContext } from 'react';

import type { UserEmail } from '@coursera/grpc-types-useremails/coursera/proto/useremails/v1/email_address';

import type { EnterpriseContractTagsV1 } from 'bundles/naptimejs/resources/__generated__/EnterpriseContractTagsV1';
import type { EnterpriseContractsV1 } from 'bundles/naptimejs/resources/__generated__/EnterpriseContractsV1';
import type { EnterpriseNoticeAcceptanceLogsV1 } from 'bundles/naptimejs/resources/__generated__/EnterpriseNoticeAcceptanceLogsV1';
import type { EnterpriseProgramsV1 } from 'bundles/naptimejs/resources/__generated__/EnterpriseProgramsV1';
import type { ProgramBrowsingExperiencesV1 } from 'bundles/naptimejs/resources/__generated__/ProgramBrowsingExperiencesV1';
import type { ProgramMembershipsV2 } from 'bundles/naptimejs/resources/__generated__/ProgramMembershipsV2';
import type ProgramBrowsingExperienceV1 from 'bundles/naptimejs/resources/programBrowsingExperiences.v1';
import type { TargetSkillProfileUserStatesQuery_TargetSkillProfileUserStatesV1Resource_byUserAndProgram_elements as UserSkillProfileStateType } from 'bundles/program-home/components/__generated__/TargetSkillProfileUserStatesQuery';
import type { org_coursera_enterprise_skills_TargetSkillProfileUserGoalState as SkillProfileStates } from 'bundles/program-home/components/__generated__/globalTypes';

export type EnterpriseProgramContextType = {
  programBrowsingExperience?: ProgramBrowsingExperiencesV1;
  programContractTags?: EnterpriseContractTagsV1[];
  enterpriseContracts?: EnterpriseContractsV1[];
  isC4er?: boolean;
  isC4wr?: boolean;
  isWESContract?: boolean;
  isC4Program?: boolean;
  requirePreJoinCheck?: boolean;
  browsingExperienceType?: ProgramBrowsingExperienceV1;
  program?: EnterpriseProgramsV1;
  isProgramLoading?: boolean;
  programMembership?: ProgramMembershipsV2;
  isC4cv?: boolean;
  isUpswell?: boolean;
  isC4C?: boolean;
  isProgramMember?: boolean;
  membershipCreatedAt?: number | null;
  isMembershipLoading?: boolean;
  isProgramAdmin?: boolean;
  isInvited?: boolean;
  userEmails?: UserEmail[];
  refetchUserEmails?: () => Promise<unknown> | unknown;
  hasFreemiumTag?: boolean;
  isGwGProgram?: boolean;
  showNoCertificateNotification?: boolean;
  isWildcard?: boolean;
  isNonDenylistedC4cv?: boolean;
  isAllowlistedC4er?: boolean;
  userSkillProfileStates?: UserSkillProfileStateType[];
  finishedSkillsets?: UserSkillProfileStateType[];
  refetchUserSkillProfileStates?: () => Promise<unknown> | unknown;
  userSkillProfileStatesLoading?: boolean;
  shouldRefetchUserSkillProfileStates?: boolean;
  setShouldRefetchUserSkillProfileStates?: (shouldRefetchUserSkillProfileStates: boolean) => void;
  onSkillSetSaveToggle?: ({
    targetSkillProfileId,
    state,
    setSaveInProgress,
    shouldRefetch,
  }: {
    targetSkillProfileId: string;
    state: SkillProfileStates;
    setSaveInProgress?: (b: boolean) => void;
    shouldRefetch?: boolean;
  }) => Promise<void>;
  showFreemiumCourseReenrollModal?: boolean;
  acceptanceNoticeLogs?: EnterpriseNoticeAcceptanceLogsV1[];
};

const EnterpriseProgramContext = React.createContext<EnterpriseProgramContextType | undefined>(undefined);

export const useProgramBrowsingExperience = () => {
  const programContext = useContext(EnterpriseProgramContext);

  return programContext?.programBrowsingExperience;
};
export const useProgramContractTags = () => {
  const programContext = useContext(EnterpriseProgramContext);

  return programContext?.programContractTags;
};
export const useEnterpriseContracts = () => {
  const programContext = useContext(EnterpriseProgramContext);

  return programContext?.enterpriseContracts;
};
export const useIsC4er = () => {
  const programContext = useContext(EnterpriseProgramContext);

  return programContext?.isC4er;
};
export const useIsC4wr = () => {
  const programContext = useContext(EnterpriseProgramContext);

  return programContext?.isC4wr;
};
export const useIsWESContract = () => {
  const programContext = useContext(EnterpriseProgramContext);

  return programContext?.isWESContract;
};
export const useIsC4Program = () => {
  const programContext = useContext(EnterpriseProgramContext);

  return programContext?.isC4Program;
};
export const useRequirePreJoinCheck = () => {
  const programContext = useContext(EnterpriseProgramContext);

  return programContext?.requirePreJoinCheck;
};
export const useBrowsingExperienceType = () => {
  const programContext = useContext(EnterpriseProgramContext);

  return programContext?.browsingExperienceType;
};

export const useProgram = () => {
  const programContext = useContext(EnterpriseProgramContext);

  return programContext?.program;
};

export const useIsProgramLoading = () => {
  const programContext = useContext(EnterpriseProgramContext);

  return programContext?.isProgramLoading;
};

export const useIsC4cv = () => {
  const programContext = useContext(EnterpriseProgramContext);

  return programContext?.isC4cv;
};

export const useIsC4C = () => {
  const programContext = useContext(EnterpriseProgramContext);

  return programContext?.isC4C;
};

export const useIsUpswell = () => {
  const programContext = useContext(EnterpriseProgramContext);

  return programContext?.isUpswell;
};

export const useUserEmails = () => {
  const programContext = useContext(EnterpriseProgramContext);

  return { userEmails: programContext?.userEmails, refetchUserEmails: programContext?.refetchUserEmails };
};

export const useHasFreemiumTag = () => {
  const programContext = useContext(EnterpriseProgramContext);

  return programContext?.hasFreemiumTag;
};

export const useIsGwGProgram = () => {
  const programContext = useContext(EnterpriseProgramContext);

  return programContext?.isGwGProgram;
};

export const useShowNoCertificateNotification = () => {
  const programContext = useContext(EnterpriseProgramContext);

  return programContext?.showNoCertificateNotification;
};

export const useProgramMembership = () => {
  const programContext = useContext(EnterpriseProgramContext);

  return {
    membershipCreatedAt: programContext?.membershipCreatedAt,
    membership: programContext?.programMembership,
    isProgramMember: programContext?.isProgramMember,
    isProgramAdmin: programContext?.isProgramAdmin,
    isInvited: programContext?.isInvited,
    isEmailNotVerified: programContext?.programMembership?.membershipState === 'INVITED_EMAIL_NOT_VERIFIED',
    isMembershipLoading: programContext?.isMembershipLoading,
  };
};

export const useIsWildcard = () => {
  const programContext = useContext(EnterpriseProgramContext);

  return programContext?.isWildcard;
};

export const useIsNonDenylistedC4cv = () => {
  const programContext = useContext(EnterpriseProgramContext);

  return programContext?.isNonDenylistedC4cv;
};

export const useIsAllowlistedC4er = () => {
  const programContext = useContext(EnterpriseProgramContext);

  return programContext?.isAllowlistedC4er;
};

export const useSkillSetSaving = () => {
  const programContext = useContext(EnterpriseProgramContext);

  return {
    userSkillProfileStates: programContext?.userSkillProfileStates,
    refetchUserSkillProfileStates: programContext?.refetchUserSkillProfileStates,
    userSkillProfileStatesLoading: programContext?.userSkillProfileStatesLoading,
    shouldRefetchUserSkillProfileStates: programContext?.shouldRefetchUserSkillProfileStates,
    setShouldRefetchUserSkillProfileStates: programContext?.setShouldRefetchUserSkillProfileStates,
    finishedSkillsets: programContext?.finishedSkillsets,
  };
};

export const useShowFreemiumCourseReenrollModal = () => {
  const programContext = useContext(EnterpriseProgramContext);

  return programContext?.showFreemiumCourseReenrollModal;
};

export const useAcceptanceNoticeLogs = () => {
  const programContext = useContext(EnterpriseProgramContext);

  return programContext?.acceptanceNoticeLogs;
};

export default EnterpriseProgramContext;
