import config from 'js/app/config';

import { HelpCenters, buildSalesforceHelpCenterHomeUrl } from 'bundles/common/utils/salesforceUrlBuilder';

export const REFUND_POLICY_LINK = `${config.url.base}about/terms-for-enterprise`;

export const HELP_CENTER_LINK = buildSalesforceHelpCenterHomeUrl(HelpCenters.ENTERPRISE, false);

export const UPDATE_LEARNER_ATTRIBUTES_IN_BULK_HELP_LINK = 'https://business.coursera.help/hc/articles/000002191';

export const CUSTOM_INVITATION_HELP_LINK =
  'https://www.coursera.support/s/article/admin-000002185-Inviting-learners-to-your-learning-program?#customize';

export const ADMIN_GETTING_STARTED_LINK = 'https://business.coursera.help/hc/articles/000001673';

export const ADMIN_STRATEGIES_FOR_LAUNCHING_LINK =
  'https://business.coursera.help/hc/articles/115006270007-Strategies-for-launching-a-learning-program';
