import { useEffect } from 'react';

import { useQuery } from '@apollo/client';

import googleCertificateExperiments from 'bundles/epic/clients/GoogleCertificate';
import courseraPlusExperiments from 'bundles/epic/clients/courseraPlus';
import GetPriceConfigurationIdByLegacyProductId from 'bundles/payments-common/api/GetPriceConfigurationIdByLegacyProductIdQuery.graphql';
import type {
  GetPriceConfigurationIdByLegacyProductIdQuery,
  GetPriceConfigurationIdByLegacyProductIdQueryVariables,
} from 'bundles/payments-common/api/__generated__/GetPriceConfigurationIdByLegacyProductIdQuery';

const GOOGLE_INDUSTRY_PARTNER_ID = '463';

const ELIGIBLE_PRICE_CONFIGURATION_IDS = [
  'pc_5113ld3IQEyz9ZKZhtotaw',
  'pc_72Nz4mLRTIeNVDbDrx7Omw',
  'pc_BdS1BsjTSdS7Q7dZwa9dow',
  'pc_O6W_2uRuTPqnZEPxThoaUg',
  'pc_wmTDsekoRWykLjRy-uOm-w',
  'pc_k0EE3_9CRnK1GHmBiFGb7A',
  'pc_jI4cIXRqR_y25SkFLv9Bug',
  'pc_koKNeI6zTV6_HDDsonx9Gg',
  'pc_nSiV39nUQJ236aEVxPyYdA',
];

const isGoogleS12n = (partnerIds: string[]) =>
  partnerIds.some((partnerId) => [GOOGLE_INDUSTRY_PARTNER_ID].includes(partnerId));

export const useGenerateImpressionForS12nPriceDecrease = ({
  partnerIds,
  s12nSubscriptionId,
  skip,
}: {
  partnerIds?: string[];
  s12nSubscriptionId?: string;
  skip?: boolean;
}) => {
  const shouldSkip = skip || !partnerIds || partnerIds?.length === 0 || !s12nSubscriptionId;

  const { data } = useQuery<
    GetPriceConfigurationIdByLegacyProductIdQuery,
    GetPriceConfigurationIdByLegacyProductIdQueryVariables
  >(GetPriceConfigurationIdByLegacyProductId, {
    variables: {
      legacyProductId: {
        productType: 'PRODUCT_TYPE_SPECIALIZATION_SUBSCRIPTION',
        productItemId: s12nSubscriptionId!,
      },
    },
    context: { clientName: 'gatewayGql' },
    skip: shouldSkip,
  });

  const priceConfigurationId = data?.OwnableProductQueries?.findLatestByLegacyProductId?.price?.configuration?.id;
  const isEligibleS12n = priceConfigurationId ? ELIGIBLE_PRICE_CONFIGURATION_IDS.includes(priceConfigurationId) : false;

  useEffect(() => {
    if (shouldSkip || !isEligibleS12n) {
      return;
    }

    if (isGoogleS12n(partnerIds)) {
      googleCertificateExperiments.get('googleS12nPriceDecreaseTestVariant');
    } else {
      courseraPlusExperiments.get('nonGoogleS12nPriceDecreaseTestVariant');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSkip, isEligibleS12n]);
};
