import * as React from 'react';

import { setDisplayName } from 'recompose';

import Imgix from 'js/components/Imgix';
import useRouter from 'js/lib/useRouter';

import ProductTypePill from 'bundles/browse/components/Domain/ProductTypePill';
import { getProductTypeFromProduct } from 'bundles/enterprise-collections/components/getCollectionItemList';
import withMiniModal from 'bundles/enterprise-legacy-xdp/hocs/withMiniModal';
import { shouldRedirectToUnifiedDescriptionPage } from 'bundles/enterprise-legacy-xdp/hocs/withMiniModal';
import TrackedButton from 'bundles/page/components/TrackedButton';
import type { TrackedButtonProps } from 'bundles/page/components/TrackedButton';
import { TrackedA } from 'bundles/page/components/TrackedLink2';
import { useTracker, useVisibilityTracker } from 'bundles/page/lib/event-pulse/react';
import ProductInfo from 'bundles/search-common/components/ProductInfo';
import {
  COURSE,
  GUIDED_PROJECT,
  RHYME_PROJECT,
  SPECIALIZATION_ENTITY_TYPE,
} from 'bundles/search-common/constants/entityTypes';

import 'css!bundles/enterprise-collections/components/__styles__/DesktopSearchCard';

type Props = {
  hit: any;
  hitPosition?: number;
  isProgramHome?: boolean;
  queryID?: string;
  eventingV3Data: {
    algoliaIndexName?: string;
  };
};

const MiniXDPButton = withMiniModal<TrackedButtonProps>()(TrackedButton);

const DesktopParitySearchCard: React.FC<Props> = ({ hit, hitPosition, queryID, isProgramHome, eventingV3Data }) => {
  const track = useTracker();
  const router = useRouter();
  // Directly link to unified description page if not in multi-program home.
  const redirectToUnifiedDescriptionPage = shouldRedirectToUnifiedDescriptionPage(router);
  const { partners, name, tagline, imageUrl, url, entityType, objectID } = hit;
  const { algoliaIndexName } = eventingV3Data;

  const hitImage = hit && imageUrl;
  const hitID = hit && hit.objectID && hit.objectID.split('~')[1];
  const eventingProductType = getProductTypeFromProduct(objectID?.split('~')[0]);

  const sharedProps = {
    data: { objectID, hitPosition, queryID },
    withVisibilityTracking: true,
    requireFullyVisible: true,
    className: 'rc-DesktopSearchCard parity-card anchor-wrapper',
    'data-e2e': 'DesktopSearchCard',
    trackingName: 'search_card',
  };

  const eventingV3ProductCardData = {
    productCard: {
      index: hitPosition ?? 0,
      algoliaIndexName,
    },
    product: {
      id: objectID,
      name,
      ...(eventingProductType && {
        type: eventingProductType,
      }),
    },
  };

  const productCardRef: React.MutableRefObject<HTMLDivElement | null> = useVisibilityTracker(
    'view_product_card',
    eventingV3ProductCardData
  );

  const handleTrackProductCard = () => {
    track('click_product_card', eventingV3ProductCardData);
  };

  const children = (
    // suppresing lint warning because onClick below is only used for event capturing
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className="vertical-box" ref={productCardRef} onClick={handleTrackProductCard}>
      <div className="card-content">
        <div className="image-wrapper">
          <Imgix
            src={hitImage}
            alt={name}
            maxWidth={100}
            maxHeight={100}
            imgParams={{ fit: 'fill', auto: 'format,compress', bg: 'FFF' }}
            className="product-photo"
          />
        </div>

        <div className="card-info vertical-box">
          <h2 className="color-primary-text card-title headline-1-text">{name}</h2>
          <div className="horizontal-box">
            {entityType && <ProductTypePill productTypeName={entityType} />}
            <span className="partner-name">{partners ? partners[0] : ''}</span>
          </div>
          <ProductInfo tagline={entityType !== COURSE && entityType !== SPECIALIZATION_ENTITY_TYPE ? tagline : null} />
        </div>
      </div>
    </div>
  );

  if (isProgramHome && !redirectToUnifiedDescriptionPage) {
    return (
      <MiniXDPButton
        {...sharedProps}
        miniModalProps={{
          isCourse: entityType === COURSE || entityType === GUIDED_PROJECT || entityType === RHYME_PROJECT,
          id: hitID,
        }}
      >
        {children}
      </MiniXDPButton>
    );
  } else if (isProgramHome && redirectToUnifiedDescriptionPage) {
    return (
      <TrackedA {...sharedProps} href={`/programs/${router.params.programSlug}${url}?source=search`}>
        {children}
      </TrackedA>
    );
  } else {
    return (
      <TrackedA {...sharedProps} href={url}>
        {children}
      </TrackedA>
    );
  }
};

export const DesktopParitySearchCardPlaceholder = (): JSX.Element => {
  return (
    <div className="rc-DesktopSearchCard parity-card anchor-wrapper" data-e2e="DesktopSearchCard">
      <div className="vertical-box">
        <div className="card-content">
          <div className="image-wrapper">
            <div className="product-photo shimmer" style={{ width: 100, height: 100 }} />
          </div>

          <div className="card-info vertical-box">
            <div className="color-primary-text card-title headline-1-text placeholder shimmer" />
            <div className="horizontal-box">
              <div className="product-pill placeholder" />
              <div className="partner-name placeholder" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default setDisplayName<Props>('DesktopParitySearchCard')(DesktopParitySearchCard);
