import * as React from 'react';
import { useContext } from 'react';

import type { Program, ThirdPartyOrganization } from 'bundles/enroll-course/common/Enterprise';

type EnterpriseAvailableEnrollmentProgramsContextType = {
  programs?: Array<Program>;
  invitedPrograms?: Array<Program>;
  thirdPartyOrganizations?: Array<ThirdPartyOrganization>;
  invitedThirdPartyOrganizations?: Array<ThirdPartyOrganization>;
  isLoading?: boolean;
};

// This context holds programs and third party orgs that the user is a part of and are able to enroll to the current product through.
const EnterpriseAvailableEnrollmentProgramsContext = React.createContext<
  EnterpriseAvailableEnrollmentProgramsContextType | undefined
>(undefined);

export const useAvailableEnrollmentOptions = () => {
  const context = useContext(EnterpriseAvailableEnrollmentProgramsContext);

  return context;
};

export default EnterpriseAvailableEnrollmentProgramsContext;
