/** @jsx jsx */
import { useMemo } from 'react';

import { debounce } from 'lodash';

import useFluxibleAction from 'js/lib/fluxibleHooks/useFluxibleAction';

import { updateWidgetSessionProvider } from 'bundles/author-widget/actions/WidgetAuthoringActions';
import type { AuthoringWidgetSessionProvidersV1 } from 'bundles/naptimejs/resources/__generated__/AuthoringWidgetSessionProvidersV1';

const CONFIG_CHANGE_DELAY = 500;

type PropsToChangeHandler = {
  widgetSessionProviderDraft?: AuthoringWidgetSessionProvidersV1;
  catchInvalidJsonError: (value: boolean) => void;
  // Custom onChange provided for cml embedded plugins.
  onChange?: (value: AuthoringWidgetSessionProvidersV1['draft']['configuration']) => void;
  courseId: string;
  onConfigChangeComplete?: () => void;
};

export const useHandleConfigurationChange = ({
  widgetSessionProviderDraft,
  catchInvalidJsonError,
  // Custom onChange provided for cml embedded plugins.
  onChange,
  courseId,
  onConfigChangeComplete,
}: PropsToChangeHandler) => {
  const executeAction = useFluxibleAction();
  const debouncedOnChange = useMemo(() => {
    return debounce((value: string | object) => {
      let configuration: object;
      if (typeof value === 'string') {
        try {
          configuration = JSON.parse(value);
        } catch (e) {
          catchInvalidJsonError(true);
          return;
        }
      } else {
        configuration = value;
      }

      catchInvalidJsonError(false);

      const widgetSessionProvider = widgetSessionProviderDraft;
      const draft = widgetSessionProviderDraft?.draft;
      const widgetSessionProviderId = widgetSessionProviderDraft?.id;
      const iframeTitle = draft?.iframeTitle;

      if (onChange) {
        onChange(configuration);
        return;
      }

      executeAction(updateWidgetSessionProvider, {
        widgetSessionProvider,
        iframeTitle,
        widgetSessionProviderId,
        configuration,
        courseId,
        callback: onConfigChangeComplete,
      });
    }, CONFIG_CHANGE_DELAY);
  }, [catchInvalidJsonError, executeAction, widgetSessionProviderDraft, onChange, courseId, onConfigChangeComplete]);

  return debouncedOnChange;
};
