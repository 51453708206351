import * as React from 'react';

import { compose } from 'recompose';

import { StyleSheet, css, font, spacing } from '@coursera/coursera-ui';

import withSessionsV2EnrollmentEnabled from 'bundles/course-sessions/utils/withSessionsV2EnrollmentEnabled';
import { getFormattedStartDate } from 'bundles/enroll-course/components/StartDateString';
import Naptime from 'bundles/naptimejs';
import CoursesV1 from 'bundles/naptimejs/resources/courses.v1';

import _t from 'i18n!nls/program-common';

const styles = StyleSheet.create({
  StartDateMessage: {
    fontSize: font.xs,
    paddingTop: spacing.xxs,
  },
});

type PropsFromCaller = {
  courseId?: string;
  privateSessionStart?: number;
};

type PropsFromWithSessionsV2EnrollmentEnabled = {
  sessionsV2EnrollmentEnabled?: boolean;
};

type PropsFromNaptime = {
  course: CoursesV1 | null;
};

type PropsToComponent = PropsFromCaller & PropsFromWithSessionsV2EnrollmentEnabled & PropsFromNaptime;

export const StartDateButton: React.FC<PropsToComponent> = ({
  course,
  sessionsV2EnrollmentEnabled = false,
  privateSessionStart,
}) => {
  if (!course) {
    return null;
  }

  return (
    <div className="rc-StartDateButton">
      {_t('Enroll')}
      <div {...css(styles.StartDateMessage)}>
        {getFormattedStartDate(course, sessionsV2EnrollmentEnabled, privateSessionStart)}
      </div>
    </div>
  );
};

export default compose<PropsToComponent, PropsFromCaller>(
  // TODO: courseId here should not require a default value, but without it there is a cascade of
  // TS errors stemming from the optional productId prop on ProgramMiniModal
  Naptime.createContainer<PropsFromNaptime, PropsFromCaller>(({ courseId = '' }) => ({
    course: CoursesV1.get(courseId, {
      fields: ['courseStatus', 'upcomingSessionStartDate', 'plannedLaunchDate', 'courseMode'],
      params: {
        showHidden: true,
      },
    }),
  })),
  withSessionsV2EnrollmentEnabled(({ courseId }) => courseId)
)(StartDateButton);
