/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect } from 'react';

import { usePdfViewerContext } from 'bundles/cml/shared/components/asset/pdfViewer/PdfViewerContext';
import { pdfjsStyles } from 'bundles/cml/shared/components/asset/pdfViewer/pdfjsStyles';

export const TextLayer = () => {
  const textLayerRef = React.useRef<HTMLDivElement>(null);
  const { currentPage, zoom, pdfJsLib } = usePdfViewerContext();

  useEffect(() => {
    if (!currentPage || !pdfJsLib || !textLayerRef.current) {
      return;
    }
    currentPage.getTextContent().then((textContent) => {
      if (!textLayerRef.current) return;

      textLayerRef.current.innerHTML = '';

      const viewport = currentPage.getViewport({ scale: zoom });

      const textLayer = new pdfJsLib.TextLayer({
        textContentSource: textContent,
        container: textLayerRef.current,
        viewport: viewport.clone({ dontFlip: true }),
      });

      textLayer.render();
    });
  }, [currentPage, pdfJsLib, zoom]);

  return <div ref={textLayerRef} css={pdfjsStyles.textLayer} />;
};
