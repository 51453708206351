import { useLocation } from 'js/lib/useRouter';

import { shouldShowLegalConsolidationAuthFlow } from 'bundles/authentication/utils';

const useIsInLegalConsolidationFlowAndHasTouQueryParam = () => {
  const { showTouAccept } = useLocation().query;
  return shouldShowLegalConsolidationAuthFlow() && showTouAccept === '1';
};

export default useIsInLegalConsolidationFlowAndHasTouQueryParam;
