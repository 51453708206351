import SkillsExperiments from 'bundles/epic/clients/Skills';

const getSkillsInsightExperiment = () => {
  return SkillsExperiments.get('skillsInsights') ?? 'tooltip';
};

const isSkillsInsightsTooltip = () => {
  return getSkillsInsightExperiment() === 'tooltip';
};

const isSkillsInsightsInline = () => {
  return getSkillsInsightExperiment() === 'inline' || getSkillsInsightExperiment() === 'inline-updated-home';
};

const isSkillUpdatedHome = () => {
  return getSkillsInsightExperiment() === 'inline-updated-home';
};

export { isSkillsInsightsTooltip, isSkillsInsightsInline, isSkillUpdatedHome };
