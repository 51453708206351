import type { Editor, NodeEntry } from 'slate';

import { getAncestorOfType } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { TextVariant } from 'bundles/cml/shared/types/coreTypes';
import { BODY1, BODY2 } from 'bundles/cml/shared/types/coreTypes';
import type { TextElement } from 'bundles/cml/shared/types/elementTypes';

import _t from 'i18n!nls/cml';

export const hasTextByVariant = (variant?: TextVariant) => {
  return (text: TextElement) => {
    if (variant) {
      const nodeVariant = text.variant;
      return nodeVariant === variant || (!text.variant && variant === BODY1);
    }

    return true;
  };
};

const hasBody2Variant = hasTextByVariant('body2');

export const hasTextWithBody2Variant = (editor: Editor) => {
  const text = getAncestorOfType(editor, BLOCK_TYPES.TEXT) as NodeEntry<TextElement>;
  return !!text && hasBody2Variant(text[0]);
};

export const getTextVariantsDisplayNames: () => Record<TextVariant, string> = () => ({
  [BODY1]: _t('Body 1'),
  [BODY2]: _t('Body 2'),
});
