import * as React from 'react';
import { useState } from 'react';
import type { MutationFn } from 'react-apollo';
import { Mutation } from 'react-apollo';

import { PreEnrollment_ProductType as PreEnrollmentProductType, PreEnrollmentType } from '__generated__/graphql-types';
import gql from 'graphql-tag';
import Uri from 'jsuri';

import logger from 'js/app/loggerSingleton';
import redirect from 'js/lib/coursera.redirect';
import prices from 'js/lib/prices';
import requestCountry from 'js/lib/requestCountry';
import { tupleToStringKey } from 'js/lib/stringKeyTuple';
import user from 'js/lib/user';
import waitForGraphQL from 'js/lib/waitForGraphQL';

import PreEnrollMutation from 'bundles/enroll/api/PreEnrollMutation.graphql';
import type {
  PreEnrollMutationMutation,
  PreEnrollMutationMutationVariables,
} from 'bundles/enroll/api/__generated__/PreEnrollMutation';
import EnrollErrorModal from 'bundles/enroll/components/common/EnrollErrorModal';
import { PRE_ENROLLMENT_FLOW_VARIANTS } from 'bundles/enroll/constants/constants';
import usePageData from 'bundles/enroll/data/usePageData';
import type { ApiError } from 'bundles/enroll/utils/errorUtils';
import { SPECIALIZATION, SPECIALIZATION_SUBSCRIPTION } from 'bundles/payments/common/ProductType';
import AutomaticallyPreEnrollModal from 'bundles/s12n-enroll/components/pre-enrollment/AutomaticallyPreEnrollModal';
import CancelPreEnrollModal from 'bundles/s12n-enroll/components/pre-enrollment/CancelPreEnrollModal';
import CreditCardPreEnrollModal from 'bundles/s12n-enroll/components/pre-enrollment/CreditCardPreEnrollModal';
import type {
  S12nPreEnrollmentProductSkus,
  S12nPreEnrollmentProductSkusVariables,
} from 'bundles/s12n-enroll/components/pre-enrollment/__generated__/S12nPreEnrollmentProductSkus';

type InputProps = {
  s12nId: string;
  onSdp: boolean;
  onClose: () => void;
  courseId?: string;
  isFromS12nSelection?: boolean;
  automaticallyEnroll: boolean;
};

type PropsFromProductSkus = {
  productItemId?: string;
};

type Props = InputProps & PropsFromProductSkus;

const MODAL_ERROR = 'ERROR';

export const S12nPreEnrollModal: React.FC<Props> = ({
  automaticallyEnroll,
  onClose,
  onSdp,
  s12nId,
  courseId,
  productItemId,
}) => {
  const { s12nDerivatives, enrollmentAvailableChoices, s12n } = usePageData();
  let initalActiveModal = automaticallyEnroll
    ? PRE_ENROLLMENT_FLOW_VARIANTS.automaticallyEnroll
    : PRE_ENROLLMENT_FLOW_VARIANTS.creditCard;

  const preEnrollmentId = enrollmentAvailableChoices?.getPreEnrollmentReasonMetadata(s12nId)?.preEnrollmentId;

  if (preEnrollmentId) {
    initalActiveModal = PRE_ENROLLMENT_FLOW_VARIANTS.isEnrolled;
  }

  const [activeModal, setActiveModal] = useState(initalActiveModal);
  const [openModal, setOpenModal] = useState(true);
  const [isEnrolling, setIsEnrolling] = useState(false);
  const [enrollmentError, setEnrollmentError] = useState<ApiError | undefined>(undefined);
  const requestCountryCode = requestCountry.get();

  const handleClose = () => {
    if (onClose) {
      onClose();
      setOpenModal(false);
    }
  };

  const handleEnroll = (
    preEnrollUser: MutationFn<PreEnrollMutationMutation, PreEnrollMutationMutationVariables>,
    goToCheckoutPage?: boolean
  ) => {
    setIsEnrolling(true);
    setEnrollmentError(undefined);

    preEnrollUser({
      variables: {
        userId: user.get().id,
        productType: automaticallyEnroll
          ? PreEnrollmentProductType.Specialization
          : PreEnrollmentProductType.SpecializationSubscription,
        preEnrollmentType: automaticallyEnroll ? PreEnrollmentType.AutoEnroll : PreEnrollmentType.PaymentWalletCapture,
        productItemId: automaticallyEnroll ? s12nId : productItemId ?? s12nId,
        currencyCode: prices.getCurrencyFromCountry(requestCountryCode),
        countryIsoCode: requestCountryCode,
        ...(courseId && { enrollCourseId: courseId }),
      },
    })
      .then((result) => {
        const responsePreEnrollmentId = result?.data?.preEnrollUser?.id;

        if (responsePreEnrollmentId) {
          if (goToCheckoutPage) {
            const checkoutPageUri = new Uri()
              .setPath(`/payments/pre-enrollment`)
              .addQueryParam('preEnrollmentId', responsePreEnrollmentId);
            redirect.setLocation(checkoutPageUri.toString());
          } else {
            setIsEnrolling(false);
          }
        }
      })
      .catch((error: ApiError) => {
        setEnrollmentError(error);
        setActiveModal(MODAL_ERROR);
      });
  };

  const renderVariantModals = (
    preEnrollUser: MutationFn<PreEnrollMutationMutation, PreEnrollMutationMutationVariables>
  ) => {
    switch (activeModal) {
      case PRE_ENROLLMENT_FLOW_VARIANTS.isEnrolled: {
        return (
          <CancelPreEnrollModal
            s12nId={s12nId}
            onSdp={onSdp}
            handleClose={handleClose}
            openModal={openModal}
            automaticallyEnroll={automaticallyEnroll}
            preEnrollmentId={preEnrollmentId}
            s12n={s12n}
          />
        );
      }
      case PRE_ENROLLMENT_FLOW_VARIANTS.automaticallyEnroll: {
        return (
          <AutomaticallyPreEnrollModal
            s12nId={s12nId}
            handleClose={handleClose}
            openModal={openModal}
            onEnrollButtonClick={() => handleEnroll(preEnrollUser)}
            isEnrolling={isEnrolling}
          />
        );
      }
      case PRE_ENROLLMENT_FLOW_VARIANTS.creditCard: {
        return (
          <CreditCardPreEnrollModal
            s12nId={s12nId}
            onSdp={onSdp}
            handleClose={handleClose}
            openModal={openModal}
            price={s12nDerivatives?.catalogPrice}
            isEnrolling={isEnrolling}
            onEnrollButtonClick={() => handleEnroll(preEnrollUser, true)}
          />
        );
      }
      case MODAL_ERROR: {
        return (
          <EnrollErrorModal error={enrollmentError} onClose={handleClose} isFinancialAid={false} isFreeEnroll={true} />
        );
      }
      default:
        logger.error('Unable to render S12nPreEnrollModal');
        return null;
    }
  };

  return (
    <Mutation<PreEnrollMutationMutation, PreEnrollMutationMutationVariables>
      mutation={PreEnrollMutation}
      context={{ clientName: 'gatewayGql' }}
    >
      {(preEnrollUser) => <div data-test="rc-S12nPreEnrollModal">{renderVariantModals(preEnrollUser)}</div>}
    </Mutation>
  );
};

export const productSkusQuery = gql`
  query S12nPreEnrollmentProductSkus($underlyingProductId: String!) {
    ProductSkusV1Resource {
      findByUnderlying(id: $underlyingProductId) {
        elements {
          id
          productItemId
          productType
          underlyingProductId
        }
      }
    }
  }
`;

export default waitForGraphQL<
  InputProps,
  S12nPreEnrollmentProductSkus,
  S12nPreEnrollmentProductSkusVariables,
  PropsFromProductSkus
>(productSkusQuery, {
  options: ({ s12nId }) => {
    return {
      variables: {
        underlyingProductId: tupleToStringKey([SPECIALIZATION, s12nId]),
      },
      errorPolicy: 'all',
    };
  },
  props: ({ data }) => {
    const s12nSubscriptionProductSku = data?.ProductSkusV1Resource?.findByUnderlying?.elements?.find(
      (productSku) => productSku?.productType === SPECIALIZATION_SUBSCRIPTION
    );

    return {
      productItemId: s12nSubscriptionProductSku?.productItemId,
    };
  },
})(S12nPreEnrollModal);
