/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import ReactDOM from 'react-dom';

import classNames from 'classnames';
import $ from 'jquery';
import PropTypes from 'prop-types';
import Q from 'q';
import type { LegacyContextType } from 'types/legacy-context-types';

import { color } from '@coursera/coursera-ui';
import { SvgClose, SvgMaximize } from '@coursera/coursera-ui/svg';

import { zIndex } from 'bundles/authoring/style-constants/layout';
import type { PluginRpcMessage, SetHeightRpcMessage } from 'bundles/widget-simulator/types/RpcMessages';
import Simulator from 'bundles/widget-simulator/utils/Simulator';
import { initiateChild, listenToChildMessages } from 'bundles/widget/lib/coursera-connect/coursera-connect-parent';
import type { WidgetSession } from 'bundles/widget/types/WidgetSession';

import _t from 'i18n!nls/author-widget';

// keeping this in place becuase there is a body-level selector...
import 'css!./__styles__/WidgetPreviewFrame';

// TODO: DRY this up (with the simulator's Frame, etc).
const rpcActionTypes = [
  'LOAD_WIDGET_ERROR',
  'GET_SESSION_CONFIGURATION',
  'SET_STORED_VALUES',
  'GET_STORED_VALUES',
  'SET_ANSWER',
  'SET_HEIGHT',
  'SET_COMPLETE',
];

const styles = {
  wrapper: css`
    height: 100%;
    margin-bottom: 10px;

    .widget-container {
      .frame-container {
        background-color: #f5f5f5;
        overflow: hidden;
        width: 100%;

        iframe {
          border: 0;
          display: block;
          width: 100%;
        }
      }

      .expander {
        height: 44px;
        width: 100px;
        margin-top: 10px;
        background-color: #fff;
        border: 1px solid #ccc;
      }
    }

    &.expanded {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;

      iframe {
        height: calc(100vh - 50px);
      }

      /* We treat this as a modal to make sure it shows up above things such as the context menu etc. */
      z-index: ${zIndex.modal};

      .dimmer {
        width: 100%;
        height: 100%;
        z-index: 5002;
        background-color: rgb(0 0 0 / 50%);
      }

      .widget-container {
        position: absolute;
        width: 100vw;
        height: auto;
        max-height: 100vh;
        top: 0;
        left: 0;

        .widget-title {
          height: 50px;
          min-height: 50px;
          background-color: #f0f0f0;
        }
      }
    }

    &.unexpanded {
      iframe.default-height {
        height: 500px;
      }

      width: 100%;

      .widget-container {
        width: 100%;
      }
    }
  `,
};

export type Session = WidgetSession;

type PropsToComponent = {
  widgetContentTitle?: string;
  sessionId?: string;
  session: WidgetSession | undefined;
  showPopupButton: boolean;
  showWidgetFooter?: (show: boolean) => void;
  renderCustomContainer?: (onExpand: () => void) => React.ReactNode;
};

type State = {
  specifiedHeight: string;
  expanded: boolean;
  iframeContentLoaded: boolean;
};

export const FALLBACK_DEFAULT_HEIGHT = '500px';

/*
There are several ways the height of the iframe is set, at different points in time. Below is the order of application:

1) SET_HEIGHT rpc message
- I believe this happens after the plugin content starts loading (ie whenever the plugin content runs the JS for this)
- sent by the plugin contents via coursera-connect.js script embedded in iframe contents
- sets the specifiedHeight state in here, which then sets the height attribute on the iframe and removes the `default-height` classname on the iframe

2) Iframe content height (<-- doesn't currently work but it looks liek this is the next intended order or priority)
- runs onLoad of the iframe
- smartHeight function is called which tries to grab the height of the content within the iframe. 
- if it succeeds, write directly to the iframe 'height' attribute
- it looks like the default-height classname is still applied so there is a conflict between the ifram eheight attribute and height css property in this scenario???
- a little unclear what the intent is in setting both??? From manual testing,
it appears that the classname css property overrides the height property.

3) DEFAULT_HEIGHT
- if no height is provided, use the default height settings
- the iframe height attribute is set to 500px while the css defaultHeight classnaem is also set which sets the height to calc(90vh - 50px)
- a little unclear what the intent is in setting both??? From manual testing,
it appears that the classname css property overrides the height property.

 */
class LegacyWidgetPreviewFrame extends React.Component<PropsToComponent, State> {
  static contextTypes = {
    executeAction: PropTypes.func,
  };

  declare context: LegacyContextType<typeof LegacyWidgetPreviewFrame.contextTypes>;

  state = {
    specifiedHeight: '',
    expanded: false,
    iframeContentLoaded: false,
  };

  removeListenerHandle: (() => void) | undefined = undefined;

  simulator: Simulator | undefined = undefined;

  declare frame: HTMLIFrameElement | null;

  componentDidMount() {
    // set as custom attribute since react 15 automatically removes the allow attributes in iframes
    // https://github.com/facebook/react/issues/12225
    const iframeElement = ReactDOM.findDOMNode(this.frame) as Element;

    iframeElement?.setAttribute('allow', 'camera');

    const { session } = this.props;
    // no need to make connections if there are no rpc actions specified.
    if (!session?.rpcActionTypes?.length) {
      return;
    }

    // TODO: Figure out what these values should be.
    const iFrameSrc = '*';
    const widgetId = this.props.sessionId || 'widgetId123';

    this.setupSimulator();

    initiateChild(this.sendMessageToIFrame, widgetId, iFrameSrc).then(() => {
      this.removeListenerHandle = listenToChildMessages(
        widgetId,
        rpcActionTypes,
        // @ts-expect-error ts-migrate(2339) FIXME: Argument of type '(message: $TSFixMe) => Q.Promise<void> | Promise<any>' is not assignable to parameter of type '(request: CourseraConnectMessage) => Promise<any>'.
        // Type 'Q.Promise<void> | Promise<any>' is not assignable to type 'Promise<any>'.
        // I'm guessing this will require migrating all widget functionality off of q promises to native promises.
        this.onReceiveMessage,
        this.sendMessageToIFrame,
        iFrameSrc
      );
    });
  }

  componentWillUnmount() {
    this.removeListenerHandle?.();
  }

  onExpandToggle = () => {
    // TODO @sgogia
    // slightly hacky way of ensuring body (higher in hierarchy) can't be scrolled when widget expanded
    const { expanded } = this.state;

    if (!expanded) {
      $('body').addClass(' noscroll');
    } else {
      $('body').removeClass(' noscroll');
    }
    this.setState({ expanded: !expanded });
  };

  onReceiveMessage = (message: PluginRpcMessage) => {
    if (this.simulator) {
      return this.simulator.handleMessage(message);
    }

    // Execution should never reach this point.
    return Q();
  };

  sendMessageToIFrame = (message: unknown, src: string) => {
    if (this.frame && this.frame.contentWindow) {
      this.frame.contentWindow.postMessage(message, src);
    }
  };

  onContentLoad = () => {
    this.trySmartHeightDefault();
    this.props.showWidgetFooter?.(true);
    this.setState({ iframeContentLoaded: true });
  };

  trySmartHeightDefault = () => {
    const { frame } = this;
    const { specifiedHeight } = this.state;
    /* This doesn't seem to actually work because the iframe source is at a different
    domain than coursera.org and fails due to cross origin security issues.
    I dont think there'd be many plugins pointing to the coursera.org domain but
    this is left in case there are.
    */

    if (!!frame && !specifiedHeight) {
      try {
        const contentsHeight = frame.contentWindow?.document?.body?.scrollHeight;
        if (contentsHeight && contentsHeight > 0) {
          frame.height = contentsHeight + 'px';
        }
      } catch (e) {
        // if we can't get the height of the contents, we'll keep our default height
      }
    }
  };

  setupSimulator() {
    // TODO: Move this to another file.
    const simulator = new Simulator();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    simulator.addHandler('LOAD_WIDGET_ERROR', (_message) => {
      return Promise.resolve();
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    simulator.addHandler('GET_SESSION_CONFIGURATION', (_message) => {
      const { session } = this.props;
      return Promise.resolve(session?.configuration);
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    simulator.addHandler('SET_STORED_VALUES', (_message) => {
      // Does nothing in the preview tool for now. Returns an empty array representing no stored value names.
      return Promise.resolve([]);
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    simulator.addHandler('GET_STORED_VALUES', (_message) => {
      // Does nothing in the preview tool for now. Returns an empty object representing no returned values.
      return Promise.resolve({});
    });

    simulator.addHandler('SET_HEIGHT', (message) => {
      function isHeightMessage(messageToCheck: PluginRpcMessage): messageToCheck is SetHeightRpcMessage {
        return (messageToCheck as SetHeightRpcMessage).body?.height !== undefined;
      }

      if (isHeightMessage(message)) {
        const { height } = message.body;
        this.setState({ specifiedHeight: height });
      }
      return Promise.resolve();
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    simulator.addHandler('SET_ANSWER', (_message) => {
      // Does nothing in the preview tool for now.
      return Promise.resolve();
    });

    // REMOTE
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    simulator.addHandler('SET_COMPLETE', (_message) => {
      // Does nothing in the preview tool for now.
      return Promise.resolve();
    });

    this.simulator = simulator;
  }

  renderExpandContainer() {
    const { showPopupButton, renderCustomContainer } = this.props;
    const { expanded, iframeContentLoaded } = this.state;
    const showExpansionButton = !expanded && showPopupButton;
    if (!showExpansionButton || !iframeContentLoaded) {
      return null;
    }

    if (renderCustomContainer) {
      return renderCustomContainer(this.onExpandToggle);
    }

    return (
      <button
        className="expander passive horizontal-box align-items-horizonal-center read-only-wrapper-allow-interaction"
        onClick={this.onExpandToggle}
        type="button"
      >
        <SvgMaximize size={25} color={color.primary} />
      </button>
    );
  }

  getModalTitle = (session?: WidgetSession, widgetContentTitle?: string): string => {
    const defaultUrlIframeTitles = ['External Webpage (iframe)', 'YouTube'];

    if (session?.iframeTitle) {
      return defaultUrlIframeTitles.includes(session.iframeTitle) ? '' : session.iframeTitle;
    }
    return widgetContentTitle ?? '';
  };

  render() {
    const { session, widgetContentTitle } = this.props;

    const { expanded, specifiedHeight } = this.state;
    const src = session?.src;
    const sandbox = session?.sandbox;

    const height = specifiedHeight || FALLBACK_DEFAULT_HEIGHT;
    const width = '100%';

    const iframeClassName = classNames({
      'default-height': !specifiedHeight,
    });

    const expandedClass = expanded ? 'expanded' : 'unexpanded';

    return (
      <div className={`rc-WidgetPreviewFrame ${expandedClass}`} css={styles.wrapper}>
        {expanded && <div className="dimmer" />}
        <div className="widget-container vertical-box">
          {expanded && (
            <div className="widget-title horizontal-box align-items-vertical-center">
              <span className="body-2-text m-l-1">{this.getModalTitle(session, widgetContentTitle)}</span>

              <div className="flex-1" />
              <button
                className="nostyle horizontal-box align-items-absolute-center m-r-1 read-only-wrapper-allow-interaction"
                onClick={this.onExpandToggle}
                type="button"
                aria-label={_t('Close expanded preview.')}
              >
                <SvgClose size={24} color={color.bgGrayThemeDark} />
              </button>
            </div>
          )}
          <div className="frame-container">
            <iframe
              title={_t('Plugin')}
              src={src}
              height={height}
              className={iframeClassName}
              width={width}
              sandbox={sandbox}
              ref={(c) => {
                this.frame = c;
              }}
              onLoad={this.onContentLoad}
            />
          </div>
          {this.renderExpandContainer()}
        </div>
      </div>
    );
  }
}

export default LegacyWidgetPreviewFrame;
