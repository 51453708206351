import { graphql } from 'react-apollo';

import gql from 'graphql-tag';
import { branch, compose, renderNothing } from 'recompose';

import Retracked from 'js/app/retracked';
import { tupleToStringKey } from 'js/lib/stringKeyTuple';

import type {
  HasCourseraLabsQuery as HasCourseraLabsQueryData,
  HasCourseraLabsQueryVariables,
} from 'bundles/coursera-ui/components/rich/MiniXDP/__generated__/HasCourseraLabsQuery';
import Naptime from 'bundles/naptimejs';
import EnterprisePermittedProductsV1 from 'bundles/naptimejs/resources/enterprisePermittedProducts.v1';

type PropsFromCaller = {
  productId: string;
  isS12n?: boolean;
  primaryLanguages: Array<string>;
  isMobile?: boolean;
  iconColor?: string;
};

type InnerProps = {
  isMobile?: boolean;
  iconColor?: string;
};

type PropsFromHasCourseraLabsQuery = {
  hasLabAssignments?: boolean;
};

type PropsFromEnterprisePermittedProductsQuery = {
  permittedProducts: {
    accessType: 'BLACKLIST' | 'WHITELIST';
    productIds: Array<string>;
  };
};

type PropsRequiredByWrapper = PropsFromCaller &
  PropsFromHasCourseraLabsQuery &
  PropsFromEnterprisePermittedProductsQuery;

export const shouldHidePromoUnit = ({
  primaryLanguages,
  productId,
  isS12n,
  permittedProducts,
  hasLabAssignments,
}: PropsRequiredByWrapper): boolean => {
  const id = `${isS12n ? 'Specialization' : 'VerifiedCertificate'}~${productId}`;

  // Prevent error when permittedProducts is undefined
  let isPermittedInC4B: boolean;
  if (!permittedProducts) {
    isPermittedInC4B = false;
  } else {
    isPermittedInC4B =
      permittedProducts.accessType === 'BLACKLIST'
        ? !permittedProducts.productIds.includes(id)
        : permittedProducts.productIds.includes(id);
  }

  // only show promo units that:
  //  * XDP is in English
  //  * offered by C4B for now
  //  * not have labs
  const isVisible = primaryLanguages?.includes('en') && isPermittedInC4B && !hasLabAssignments;
  return !isVisible;
};

const HasCourseraLabsQuery = gql`
  query HasCourseraLabsQuery($key: String!) {
    ExternallyAccessibleNostosV1Resource {
      getAllProperties(job_name: "enterprise_products_with_labs", keys: $key) {
        elements {
          id
          content
        }
      }
    }
  }
`;

const withEnterprisePermittedProducts = Naptime.createContainer(() => ({
  permittedProducts: EnterprisePermittedProductsV1.get('LegacyGlobalScopeId', { fields: ['accessType', 'productIds'] }),
}));

const withTrackingNamespace = Retracked.createTrackedContainer(() => {
  const namespace = {
    app: 'enterprise',
    page: 'xdp',
  };

  return {
    namespace,
  };
});

const withEnterprisePromoWrapper = compose<InnerProps, PropsFromCaller>(
  withTrackingNamespace,
  withEnterprisePermittedProducts,
  graphql<PropsFromCaller, HasCourseraLabsQueryData, HasCourseraLabsQueryVariables, PropsFromHasCourseraLabsQuery>(
    HasCourseraLabsQuery,
    {
      options: ({ productId: courseId }) => ({
        variables: { key: tupleToStringKey(['VerifiedCertificate', courseId]) },
      }),
      props: ({ data }) => {
        const content = data?.ExternallyAccessibleNostosV1Resource?.getAllProperties?.elements?.[0]?.content as
          | {
              hasLabAssignments?: boolean;
            }
          | undefined;

        return {
          hasLabAssignments: content?.hasLabAssignments,
        };
      },
    }
  ),
  branch<PropsRequiredByWrapper>(shouldHidePromoUnit, renderNothing)
);

export default withEnterprisePromoWrapper;
