import * as React from 'react';
import { useMemo } from 'react';

import imgix from 'js/lib/imgix';
import { useLocation } from 'js/lib/useRouter';

import { CardMetadata, Grid, ProductCard } from '@coursera/cds-core';

import { PRODUCT_TYPE_NAMES } from 'bundles/browse/constants';
import { getTranslatedProductName } from 'bundles/browse/utils';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import type ProgramCurriculumProductsV1 from 'bundles/naptimejs/resources/programCurriculumProducts.v1';
import type { ProductCardCourseFragmentFragment as CourseType } from 'bundles/program-common/queries/__generated__/ProductCoursesQuery';
import type { Product } from 'bundles/program-common/types/programCommon';
import { getCourseType } from 'bundles/program-common/utils/courseUtils';
import type { SavedContainerS12n_ProductCardS12nFragment as S12nProduct } from 'bundles/program-home/queries/__generated__/SavedContainerS12nQuery';
import mapExists from 'bundles/program-home/utils/mapExists';
import type { S12nProductVariant } from 'bundles/s12n-common/constants/s12nProductVariants';
import {
  ExternalCertificateS12n,
  GoogleCertificateS12n,
  NormalS12n,
  ProfessionalCertificateS12n,
} from 'bundles/s12n-common/constants/s12nProductVariants';
import { mapCourseToProduct } from 'bundles/unified-home-common/utils/formatEnterpriseSavedProductData';

import { useSavedCourseAndS12n } from './hooks/useSavedCourseAndS12n';

type PropsFromCaller = {
  savedProducts: Array<ProgramCurriculumProductsV1>;
};

function mapS12nToProduct(s12ns: Array<S12nProduct>): Array<Product> {
  return s12ns.map((s12n) => ({
    ...s12n,
    partners: {
      elements: s12n.partners,
    },
    courseIds: s12n.courses.map((course) => course.id),
  }));
}
const getProductsToShow = (
  courses: Array<CourseType>,
  s12ns: Array<S12nProduct>,
  displayOrderIds?: Array<string>
): Array<Product> => {
  // If no display order provided, return merged product lists
  const s12nProduct: Array<Product> = mapS12nToProduct(s12ns);
  const courseProduct = mapCourseToProduct(courses);
  if (!displayOrderIds) {
    const products: Product[] = [...s12nProduct, ...courseProduct];
    return products;
  }

  // Map each product to its id
  const productsObj: Record<string, Product> = {};

  s12nProduct.forEach((s12n) => {
    productsObj[s12n.id] = s12n;
  });

  courseProduct.forEach((course) => {
    productsObj[course.id] = course;
  });

  // Iterate through productStates and construct ordered list
  const products = mapExists(displayOrderIds, (id) => productsObj[id]);
  return products;
};

const getS12nType = (productVariant: S12nProductVariant | string | undefined): string => {
  switch (productVariant) {
    case ProfessionalCertificateS12n:
    case GoogleCertificateS12n:
    case ExternalCertificateS12n:
      return 'ProfessionalCertificate';
    case NormalS12n:
    default:
      return 'Specialization';
  }
};

const getLearningProduct = (productType: string | boolean) => {
  switch (productType) {
    case 'StandardCourse':
      return PRODUCT_TYPE_NAMES.COURSE;
    case 'GuidedProject':
      return PRODUCT_TYPE_NAMES.GUIDED_PROJECT;
    case 'Specialization':
      return PRODUCT_TYPE_NAMES.SPECIALIZATION;
    case 'Project':
      return PRODUCT_TYPE_NAMES.SELF_PACED_PROJECT;
    case 'ProfessionalCertificate':
      return PRODUCT_TYPE_NAMES.CERTIFICATE;
    default:
      return '';
  }
};

const getRoute = (productType: string) => {
  switch (productType) {
    case PRODUCT_TYPE_NAMES.COURSE:
      return '/learn';
    case PRODUCT_TYPE_NAMES.GUIDED_PROJECT:
    case PRODUCT_TYPE_NAMES.SELF_PACED_PROJECT:
      return '/projects';
    case PRODUCT_TYPE_NAMES.SPECIALIZATION:
      return '/specializations';
    case PRODUCT_TYPE_NAMES.CERTIFICATE:
      return '/professional-certificates';
    default:
      return '';
  }
};

export const SavedCourses = ({ savedProducts }: PropsFromCaller) => {
  const { courses, s12ns, displayOrderIds, productStateObj } = useSavedCourseAndS12n(savedProducts);
  const location = useLocation();
  const products = useMemo(() => getProductsToShow(courses, s12ns, displayOrderIds), [courses, displayOrderIds, s12ns]);
  return (
    <>
      {products.map((product) => {
        const { id } = product;
        const productState = productStateObj[id];
        const courseType = product?.courseTypeMetadata && getCourseType(product.courseTypeMetadata);
        const isS12n = productState.isS12n || !!product.courseIds;
        const s12nType = isS12n && getS12nType(product.productVariant);
        const productType = getLearningProduct(courseType ?? s12nType);
        const translatedProductName = getTranslatedProductName(productType);

        return (
          <Grid key={product.id} item xs={12} sm={6} md={4} lg={3}>
            <ProductCard
              id={product.id}
              title={{
                name: product.name,
                linkProps: {
                  href: `${location.pathname}${getRoute(productType)}/${product.slug}`,
                  target: '_blank',
                },
              }}
              previewImageSrc={product.promoPhoto ? imgix.processImage(product.promoPhoto) : ''}
              productType={productType}
              variant="grid"
              footer={<CardMetadata metadata={translatedProductName ? [translatedProductName] : []} />}
            />
          </Grid>
        );
      })}
    </>
  );
};
