import * as React from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { StarRating } from '@coursera/coursera-ui';

import _t from 'i18n!nls/browse';

import 'css!./__styles__/Ratings';

type Props = {
  averageFiveStarRating: number;
  ratingCount?: number;
  starSpacing?: number;
};

class Ratings extends React.Component<Props> {
  render() {
    const { averageFiveStarRating, ratingCount, starSpacing = 5 } = this.props;
    const reviewsAriaLabel = ratingCount ? _t('#{ratingCount} reviews', { ratingCount }) : '';

    return (
      <div className="rc-Ratings horizontal-box">
        <div className="ratings-icon">
          <StarRating rating={averageFiveStarRating} starSpacing={starSpacing} />
        </div>
        <div className="screenreader-only">
          {_t('Rated #{averageFiveStarRating} out of five stars. #{reviewsAriaLabel}', {
            averageFiveStarRating,
            reviewsAriaLabel,
          })}
        </div>
        <span className="ratings-text" aria-hidden={true}>
          {averageFiveStarRating}
        </span>
        {ratingCount ? (
          <span className="ratings-count" aria-hidden={true}>
            <FormattedMessage message="({count})" count={ratingCount.toLocaleString()} />
          </span>
        ) : null}
      </div>
    );
  }
}

export default Ratings;
