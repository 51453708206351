/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { CourseraTierSubscriptions_BillingCycle as BillingCycleType } from '__generated__/graphql-types';

import { Dialog, Hidden, Typography, Typography2, breakpoints } from '@coursera/cds-core';
import { LockOneIcon, SuccessFilledIcon, SuccessOutlineIcon } from '@coursera/cds-icons';

import ChangePaymentMethodButton from 'bundles/coursera-plus/components/subscriptionTiers/upgradeToPlus/buttons/ChangePaymentMethodButton';
import ModalBackButton from 'bundles/coursera-plus/components/subscriptionTiers/upgradeToPlus/buttons/ModalBackButton';
import UpgradeNowButton from 'bundles/coursera-plus/components/subscriptionTiers/upgradeToPlus/buttons/UpgradeNowButton';
import type { StepTwoCopyType } from 'bundles/coursera-plus/components/subscriptionTiers/upgradeToPlus/upgradeCopies';
import TrackedDiv from 'bundles/page/components/TrackedDiv';

const styles = {
  stepWrapper: css`
    list-style: none;
    padding-left: 0;
    overflow: hidden;
  `,
  step: css`
    display: flex;
  `,
  stepContent: css`
    margin-bottom: 10px;
    margin-top: -2px;
  `,
  stepIcon: css`
    color: blue;
    margin-right: 8px;
    position: relative;

    &::after {
      display: inline-block;
      content: '';
      position: absolute;
      top: 18px;
      left: 8px;
      width: 10px;
      height: calc(100% - 17px);
      border-left: 4px solid #0057d2;
    }
  `,
  lastIcon: css`
    &::after {
      display: none;
    }
  `,
  paymentDetails: css`
    padding: 20px 0 0;
    margin: 20px auto 16px;
    border: 1px solid lightgray;
  `,
  underline: css`
    text-decoration: underline;
  `,
  paymentDetailsTitle: css`
    padding: 0 20px;
  `,
  secureTextWrapper: css`
    color: red;
    display: flex;
    margin-top: 8px;
    align-items: center;
    font-size: 18px;
    padding: 0 20px;
  `,
  lockIcon: css`
    margin-right: 8px;
    position: relative;

    &::before {
      display: inline-block;
      content: '';
      position: absolute;
      top: -4px;
      right: -6px;
      height: 28px;
      width: 28px;
      background-color: #c7fbe7;
      border-radius: 50%;
      opacity: 0.4;
    }
  `,
  walletDetailsRow: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    padding: 0 20px;
    background-color: #f0f0f0;
  `,
  todaysTotalRow: css`
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    padding: 10px 20px;
  `,
  content: css`
    ${breakpoints.down('xs')} {
      padding: 0;
      margin: var(--cds-spacing-200) var(--cds-spacing-400);
    }
  `,
  actions: css`
    padding-top: 16px;
    ${breakpoints.down('xs')} {
      padding: 0;
      margin: var(--cds-spacing-400);
    }
  `,
  todaysPrice: css`
    text-align: right;
  `,
  grayText: css`
    color: #636363;
  `,
  title: css`
    padding-bottom: 16px;
    margin-top: var(--cds-spacing-200);
    ${breakpoints.down('xs')} {
      padding: 0;
      margin: var(--cds-spacing-200) var(--cds-spacing-400);
    }
  `,
};

type Props = {
  copy: StepTwoCopyType;
  courseId?: string;
  s12nId?: string;
  fromTierSubscriptionId: string;
  goToPreviousStep: () => void;
  showErrorStateModal: () => void;
  handleUpgradeSuccessful: () => void;
  isStandaloneCourseraPlusUpgrade: boolean;
  toTierBillingCycle: BillingCycleType;
  countryIsoCode?: string;
  currencyCode?: string;
};

export const StepTwoUpgradeModalContent: React.FC<Props> = ({
  copy,
  courseId,
  s12nId,
  fromTierSubscriptionId,
  goToPreviousStep,
  showErrorStateModal,
  handleUpgradeSuccessful,
  isStandaloneCourseraPlusUpgrade,
  toTierBillingCycle,
  countryIsoCode,
  currencyCode,
}) => {
  const trackingData = { courseId, s12nId };
  const {
    title,
    primaryCta,
    secondaryCta,
    legal,
    paymentDetails,
    stepProgressNextBillingPriceCopy,
    stepProgressTodayPriceCopy,
  } = copy;
  const { Content, HeadingGroup, Actions } = Dialog;

  // We are temporarily disabling the ability to change credit card info during upgrade
  // as we transition to support multiple payment processors through the Payment Processor
  // Agnostic upgrade solution. This feature will be brought back in the future.
  const shouldShowChangeCreditCardButton = false;

  const getPrimaryCta = (isFullWidth: boolean) => (
    <UpgradeNowButton
      courseId={courseId}
      s12nId={s12nId}
      fromTierSubscriptionId={fromTierSubscriptionId}
      showErrorStateModal={showErrorStateModal}
      handleUpgradeSuccessful={handleUpgradeSuccessful}
      isFullWidth={isFullWidth}
      isStandaloneCourseraPlusUpgrade={isStandaloneCourseraPlusUpgrade}
      toTierBillingCycle={toTierBillingCycle}
      countryIsoCode={countryIsoCode}
      currencyCode={currencyCode}
    >
      {primaryCta}
    </UpgradeNowButton>
  );

  return (
    <TrackedDiv
      trackingName="ideal_upgrade_step_two_modal"
      data-testId="ideal-upgrade-step-two-modal"
      trackClicks={false}
      withVisibilityTracking={true}
      data={trackingData}
    >
      <ModalBackButton goToPreviousStep={goToPreviousStep} />
      <HeadingGroup css={styles.title}>{title}</HeadingGroup>
      <Content id="dialog-content" css={styles.content}>
        <ul css={styles.stepWrapper}>
          <li css={styles.step}>
            <span css={styles.stepIcon}>
              <SuccessFilledIcon color="interactive" />
            </span>
            <span css={styles.stepContent}>{stepProgressTodayPriceCopy}</span>
          </li>
          <li css={styles.step}>
            <span css={[styles.stepIcon, styles.lastIcon]}>
              <SuccessOutlineIcon color="interactive" />
            </span>
            <span css={styles.stepContent}>{stepProgressNextBillingPriceCopy}</span>
          </li>
        </ul>
        <div css={styles.paymentDetails}>
          <Typography variant="h2semibold" css={styles.paymentDetailsTitle}>
            {paymentDetails.title}
          </Typography>
          <div css={styles.secureTextWrapper}>
            <span css={styles.lockIcon}>
              <LockOneIcon size="small" color="success" />
            </span>
            <Typography2 component="p" css={styles.grayText}>
              {paymentDetails.securityText}
            </Typography2>
          </div>

          {shouldShowChangeCreditCardButton && paymentDetails.lastFourCreditCardDigits && (
            <div css={styles.walletDetailsRow}>
              <ChangePaymentMethodButton
                variant="ghost"
                edgeAlign="start"
                size="small"
                courseId={courseId}
                s12nId={s12nId}
              >
                <span css={styles.underline}>{secondaryCta}</span>
              </ChangePaymentMethodButton>
              <Typography2 component="p" css={styles.grayText}>
                {paymentDetails.lastFourCreditCardDigits}
              </Typography2>
            </div>
          )}
          <div css={styles.todaysTotalRow}>
            <Typography2 component="h3" variant="subtitleMedium">
              {paymentDetails.todaysTotalLabel}
            </Typography2>
            <div css={styles.todaysPrice}>
              <Typography2 variant="subtitleMedium" component="span">
                {paymentDetails.todaysTotalPrice}
              </Typography2>
              <Typography2 component="p" css={styles.grayText}>
                {paymentDetails.monthlyPrice}
              </Typography2>
            </div>
          </div>
        </div>
        <Typography2 component="p">{legal}</Typography2>
      </Content>
      <Actions css={styles.actions}>
        <Hidden xsDown>{getPrimaryCta(false)}</Hidden>
        <Hidden smUp>{getPrimaryCta(true)}</Hidden>
      </Actions>
    </TrackedDiv>
  );
};

export default StepTwoUpgradeModalContent;
