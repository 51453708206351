export const HEADING_LEVELS = ['1', '2', '3', '4'] as const;
export type HeadingLevel = (typeof HEADING_LEVELS)[number];
export type HeadingVariant =
  | 'h1regular'
  | 'h2regular'
  | 'h1semibold'
  | 'h2semibold'
  | 'h3semibold'
  | 'h3bold'
  | 'h4bold';

export const HEADING_BOLD_VARIANTS: HeadingVariant[] = ['h3bold', 'h4bold'];

export const BODY1 = 'body1' as const;
export const BODY2 = 'body2' as const;

export const TEXT_VARIANTS = [BODY1, BODY2] as const;
export type TextVariant = (typeof TEXT_VARIANTS)[number];

export type ImageSizes = 'default' | 'small' | 'fullWidth';
