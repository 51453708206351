import paymentsFlowExperiments from 'bundles/epic/clients/payments';

type Partners = Array<{ id: string | number } | undefined> | null;

export const previewHideAuditFromCourseInS12n = (partners?: Partners) => {
  const isPartnerEligible = partners
    // Epic tag targeting values are stored as strings
    ?.map((p) => String(p?.id))
    // Every partner must be in the exclusion list, e.g. a course with an included and excluded partner should still show audit
    ?.every((partnerId) =>
      // eslint-disable-next-line camelcase
      paymentsFlowExperiments.preview('hideAuditFromIndustryPartnerCourseInS12n', { partner_id: partnerId })
    );

  return Boolean(isPartnerEligible);
};

export const generateImpressionForHideAuditFromCoursesInS12nTest = (partners?: Partners) => {
  if (!previewHideAuditFromCourseInS12n(partners)) {
    return;
  }

  paymentsFlowExperiments.get('hideAuditFromIndustryPartnerCourseInS12n');
};
