import type React from 'react';
import { forwardRef, useState } from 'react';

import type { BaseComponentProps } from '@coursera/cds-common';
import { useId } from '@coursera/cds-common';

import type { ButtonProps } from '@core/Button';
import { ItemsCollectionProvider } from '@core/utils';

import type { CloseReason } from './ActionOverflowMenu';
import ActionOverflowMenu from './ActionOverflowMenu';
import ActionOverflowMenuTrigger from './ActionOverflowMenuTrigger';
import getActionOverflowWrapperCss from './styles/getActionOverflowWrapperCss';

export type Props = {
  /**
   * Individual `ActionOverflow.Item` elements used as menu items.
   * Use `ActionOverflow.Group` to combine menu items in groups.
   *
   * @see ActionOverflow
   * @see ActionOverflow.Item
   * @see ActionOverflow.Group
   */
  children: React.ReactNode;
  /**
   * Invert the color scheme. Use when displaying over dark backgrounds
   * @default false
   */
  invert?: boolean;
  /**
   * Trigger button label.
   */
  label?: string;
  /**
   * The size of the button.
   * @default medium
   */
  size?: ButtonProps['size'];
  /**
   * Ref of a root element `<ul></ul>` that wraps action items inside of popup.
   */
  menuRef?: React.RefObject<HTMLOListElement>;
  /**
   * Callback fired when the action overflow menu opens.
   */
  onOpen?: () => void;
  /**
   * Callback fired when the action overflow menu closes.
   *
   * @param {object} event The event source of the callback.
   * @param {string} reason Can be: `"escapeKeyDown"`, `"backdropClick"`, `"itemClick"`.
   */
  onClose?: (event: React.SyntheticEvent, reason: CloseReason) => void;
} & BaseComponentProps<'button'>;

const ActionOverflow = forwardRef<HTMLButtonElement, Props>(
  function ActionOverflow(props, ref) {
    const {
      children,
      invert,
      label,
      size = 'medium',
      onOpen,
      onClose,
      menuRef,
      ...rest
    } = props;

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleTriggerClick = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      setAnchorEl(event.currentTarget);
      rest.onClick?.(event);
      onOpen?.();
    };

    const handleClose = (event: React.SyntheticEvent, reason?: CloseReason) => {
      setAnchorEl(null);
      onClose?.(event, reason || 'backdropClick');
    };

    const open = Boolean(anchorEl);
    const triggerButtonId = useId(rest.id);

    return (
      <div css={getActionOverflowWrapperCss}>
        <ActionOverflowMenuTrigger
          ref={ref}
          expanded={open}
          invert={invert}
          size={size}
          {...rest}
          id={triggerButtonId}
          onClick={handleTriggerClick}
        >
          {label}
        </ActionOverflowMenuTrigger>
        <ItemsCollectionProvider commonProps={null}>
          <ActionOverflowMenu
            ref={menuRef}
            anchorEl={anchorEl}
            label={label}
            open={open}
            triggerButtonId={triggerButtonId}
            onClose={handleClose}
          >
            {children}
          </ActionOverflowMenu>
        </ItemsCollectionProvider>
      </div>
    );
  }
);

export default ActionOverflow;
