import type React from 'react';

import { isHotkey } from 'is-hotkey';
import { Transforms } from 'slate';
import type { Editor } from 'slate';

import { getAncestorOfType } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';

const isDeleteOrBackspaceKey = isHotkey(['delete', 'backspace']);

export const imageUploaderKeyDownHandler = (editor: Editor, event: React.KeyboardEvent): boolean => {
  const { nativeEvent } = event;
  if (!isDeleteOrBackspaceKey(nativeEvent)) {
    return false;
  }

  const entry = getAncestorOfType(editor, BLOCK_TYPES.IMAGE_UPLOADER);
  if (!entry) {
    return false;
  }

  const [, path] = entry;

  Transforms.delete(editor, { at: path });
  event.preventDefault();
  return true;
};
