/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useEffect, useRef } from 'react';

import { isHotkey } from 'is-hotkey';
import { ReactEditor, useSlateStatic } from 'slate-react';

import { Button, MuiClickAwayListener, TextField } from '@coursera/cds-core';

import AssetDescriptionEditor from 'bundles/author-common/components/AssetDescriptionEditor';
import { isValidURL } from 'bundles/cml/editor/components/buttons/link/linkUtils';
import { useFocusedContext } from 'bundles/cml/editor/context/focusContext';
import { useFocusTrap } from 'bundles/cml/editor/utils/dialogUtils';

import _t from 'i18n!nls/cml';

const isEscapeKey = isHotkey('escape');

const styles = {
  dialog: css`
    width: 392px;
    padding: var(--cds-spacing-200);
  `,
  textField: css`
    margin-bottom: var(--cds-spacing-400);

    label {
      margin-bottom: 0;
    }
  `,
  footer: css`
    margin-top: var(--cds-spacing-300);
  `,
};

type Props = {
  href: string;
  title: string;
  onClose: () => void;
  onDone: () => void;
  onHrefChange: (text: string) => void;
  onTitleChange: (description: string) => void;
};

const EditLinkDialog: React.FC<Props> = ({ href, title, onClose, onDone, onHrefChange, onTitleChange }) => {
  const { setFocused } = useFocusedContext();
  const ref = useRef<HTMLDivElement | null>(null);
  const staticEditor = useSlateStatic();

  useFocusTrap(ref);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setFocused(true);
    return () => {
      ReactEditor.focus(staticEditor);
      setFocused(false);
    };
  }, [staticEditor, setFocused]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef.current?.focus();
    }, 150);

    return () => clearTimeout(timeout);
  }, [inputRef]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (isEscapeKey(event.nativeEvent)) {
        event.stopPropagation();
        onDone();
      }
    },
    [onDone]
  );

  const error = !href || !isValidURL(href);

  return (
    <MuiClickAwayListener onClickAway={onClose}>
      <div
        role="dialog"
        ref={ref}
        aria-label={_t('Edit Dialog')}
        css={styles.dialog}
        contentEditable={false}
        onKeyDownCapture={handleKeyDown}
      >
        <TextField
          inputRef={inputRef}
          fullWidth
          defaultValue={href}
          label={_t('URL Link')}
          placeholder={_t('Type or paste link')}
          onChange={(e) => onHrefChange(e.target.value)}
          validationStatus={error ? 'error' : 'success'}
          validationLabel={error ? _t('Please enter a valid url (http, https, or mailto only):') : undefined}
          css={styles.textField}
        />
        <AssetDescriptionEditor
          autoFocus={false}
          defaultValue={title}
          handleChange={(e) => onTitleChange(e.target.value)}
          descriptionLength={title.length}
          itemType="link"
        />
        <div css={styles.footer}>
          <Button onClick={onDone} size="small" disabled={error}>
            {_t('Done')}
          </Button>
        </div>
      </div>
    </MuiClickAwayListener>
  );
};

export default EditLinkDialog;
