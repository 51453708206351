import * as React from 'react';

import { pure } from 'recompose';

import SvgIcon from '../../SvgIcon';

let KeyboardArrowDown = (props: $TSFixMe) => (
  <SvgIcon {...props}>
    <title>keyboard arrow down</title>
    <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z" />
  </SvgIcon>
);

// @ts-expect-error ts-migrate(2322) FIXME: Type 'ComponentType<any>' is not assignable to typ... Remove this comment to see the full error message
KeyboardArrowDown = pure(KeyboardArrowDown);
// @ts-expect-error ts-migrate(2339) FIXME: Property 'displayName' does not exist on type '(pr... Remove this comment to see the full error message
KeyboardArrowDown.displayName = 'KeyboardArrowDown';

export default KeyboardArrowDown;
