import { StyleSheet, color } from '@coursera/coursera-ui';

export const howItWorksInformationCardStyles = StyleSheet.create({
  cardHeader: {
    margin: '2.25rem 1.5rem 1.5rem 1.5rem',
    '@media (min-width: 824px)': {
      margin: '2.5rem 3.8rem',
    },
  },
  phoneIconWrapper: {
    marginBottom: '1rem',
    marginRight: '1.5rem',
  },
  cardActionBox: {
    padding: '1.5rem',
  },
  footer: {
    backgroundColor: color.bgGray,
    padding: '2.25rem 3rem',

    ':nth-child(n) .mobile-app-badges a:nth-child(n+2)': {
      marginLeft: 25,
    },

    ':nth-child(n) .mobile-app-badges': {
      flex: '0 0 294px',
      whiteSpace: 'nowrap',
    },
  },
});

export const howItWorksSkillItemStyles = StyleSheet.create({
  root: {
    flex: '0 0 50%',
  },
  iconWrapper: {
    margin: '1.25rem 1.0rem 0rem 0.75rem',
    '@media (min-width: 824px)': {
      margin: '1rem 1.5rem 1.5rem 0.5rem',
    },
    '@media (min-width: 1024px)': {
      margin: '1rem 1.5rem 1.5rem 1.5rem',
    },
  },
  bodyWrapper: {
    marginTop: '0.3rem',
    marginLeft: '0rem',
    '@media (min-width: 824px)': {
      marginLeft: '0.3rem',
    },
  },
});

export const skillTargetsStyles = StyleSheet.create({
  demoBlock: {
    marginTop: -40,
    color: '#666666',

    '@media (max-width: 1024px)': {
      marginTop: 0,
    },

    ':nth-child(n) p': {
      marginTop: 8,
      marginBottom: 8,
    },
  },

  definitionList: {
    marginTop: 10,
    display: 'flex',
    flexFlow: 'row wrap',
    fontSize: 14,

    ':nth-child(n) :nth-child(n)': {
      margin: '3px 0',
      padding: '3px 8px',
      backgroundColor: '#f7f7f7',
    },

    ':nth-child(n) dt:nth-child(n)': {
      flexBasis: '30%',
      textAlign: 'right',
      marginRight: 5,
      fontWeight: 'bold',
    },

    ':nth-child(n) dd': {
      flexBasis: '60%',
      flexGrow: 1,
      fontSize: 14,
    },
  },
});
