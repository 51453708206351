import type { Editor, Node, NodeEntry } from 'slate';
import { Element, Transforms } from 'slate';

import type { Options } from 'bundles/cml/editor/normalize/types';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import { BODY2 } from 'bundles/cml/shared/types/coreTypes';
import type { TextElement } from 'bundles/cml/shared/types/elementTypes';
import { Tools } from 'bundles/cml/shared/utils/customTools';

const shouldNormalizeBody = ({ tools }: Options, [node]: NodeEntry<TextElement>) => {
  return node.variant === BODY2 && !tools.has(Tools.BODY2);
};

export const normalizeBody = (editor: Editor, options: Options, nodeEntry: NodeEntry<Node>): boolean => {
  if (!Element.isElement(nodeEntry[0])) {
    return false;
  }

  const [node, path] = nodeEntry;

  if (node.type !== BLOCK_TYPES.TEXT) {
    return false;
  }

  if (!shouldNormalizeBody(options, [node, path])) {
    return false;
  }

  Transforms.unsetNodes(editor, ['variant'], { at: path });
  return true;
};
