import { compose, lifecycle, withState } from 'recompose';

type PropsFromWithState = {
  isMounted: boolean;
  setIsMounted: (isMounted: boolean) => void;
};

export type PropsFromWithIsMounted = Pick<PropsFromWithState, 'isMounted'>;

export default <T extends PropsFromWithIsMounted>() =>
  compose<T, T>(
    withState('isMounted', 'setIsMounted', false),
    lifecycle<PropsFromWithState, {}>({
      componentDidMount() {
        this.props.setIsMounted(true);
      },
    })
  );
