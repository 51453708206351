/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Dialog, Typography2, useTheme } from '@coursera/cds-core';

import { TrackedCdsButton } from 'bundles/common/components/withSingleTracked';

import _t from 'i18n!nls/program-common';

const useStyles = () => {
  const theme = useTheme();
  return {
    paragraphBottomMargin: css`
      margin-bottom: var(--cds-spacing-100);
    `,
    listBottomMargin: css`
      margin-bottom: var(--cds-spacing-50);
    `,
  };
};

const { HeadingGroup, Content, Actions } = Dialog;

export function IdVerificationWarningModal() {
  const styles = useStyles();
  const [showModal, setShowModal] = React.useState(true);

  function closeModal() {
    setShowModal(false);
  }
  if (!showModal) {
    return null;
  }

  return (
    <Dialog open={showModal} aria-describedby="id-verification-warning-dialog-content" onClose={closeModal}>
      <HeadingGroup>{_t('ID verification is required for certification')}</HeadingGroup>
      <Content>
        <Typography2 component="p" variant="bodyPrimary" css={styles.paragraphBottomMargin}>
          {_t(
            'Please note that you will be required to provide a government-issued ID to earn a certificate in this course.'
          )}
        </Typography2>
        <Typography2 component="h3" variant="subtitleMedium" css={styles.listBottomMargin}>
          {_t('Examples of IDs you can use are:')}
        </Typography2>
        <ul>
          <li>
            <Typography2 component="p" variant="bodyPrimary">
              {_t("Driver's License")}
            </Typography2>
          </li>
          <li>
            <Typography2 component="p" variant="bodyPrimary">
              {_t('Passport')}
            </Typography2>
          </li>
          <li>
            <Typography2 component="p" variant="bodyPrimary">
              {_t('National ID Card')}
            </Typography2>
          </li>
          <li>
            <Typography2 component="p" variant="bodyPrimary">
              {_t('We currently do NOT accept student ID')}
            </Typography2>
          </li>
        </ul>
        <Typography2 component="p" variant="bodyPrimary">
          {_t(
            'More details about the requirements are available here. We will notify you when set up is required after you enroll in the course.'
          )}
        </Typography2>
      </Content>
      <Actions>
        <TrackedCdsButton variant="primary" onClick={closeModal} trackingName="id_verification_warning_close_button">
          {_t('Got it')}
        </TrackedCdsButton>
      </Actions>
    </Dialog>
  );
}

export default IdVerificationWarningModal;
