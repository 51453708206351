/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { useRetracked } from 'js/lib/retracked';

import { CardMetadata, CardWatchNow, ProductCard, Typography } from '@coursera/cds-core';

import { FilterableShowMoreGridSection, FilterableShowMoreGridSectionPlaceholder } from 'bundles/cds-labs/components/';
import { TrackedCdsButton } from 'bundles/common/components/withSingleTracked';
import { filterUndefined } from 'bundles/common/utils/commonUtils';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import ClipsCardPreviewImage from 'bundles/program-personalized-tab/components/ClipsCardPreviewImage';
import { LEADERSHIP_SKILLS } from 'bundles/program-personalized-tab/constants';
import { useClipsRecsData } from 'bundles/program-personalized-tab/contexts/ClipsRecsContext';
import { useOnboardingData } from 'bundles/program-personalized-tab/contexts/OnboardingContext';
import type { ClipsCollectionItem } from 'bundles/program-personalized-tab/types/sharedTypes';
import { getClipsRecsMetadata, getFormattedViewCount } from 'bundles/program-personalized-tab/utils/displayUtils';

import _t from 'i18n!nls/program-personalized-tab';

export const styles = {
  productCardWrapper: css`
    height: 100%;
  `,
};

type Props = {
  programSlug: string;
  showLeadershipRecs?: boolean;
};

const ClipsRecsCollection = ({ programSlug, showLeadershipRecs }: Props) => {
  const clipsRecsData = useClipsRecsData();
  const onboardingData = useOnboardingData();
  const track = useRetracked();
  const filters = useMemo(() => {
    if (showLeadershipRecs) {
      return (
        LEADERSHIP_SKILLS.filter((skill) => clipsRecsData?.leadershipRecs?.[skill.id]).map((skill) => ({
          id: skill.id,
          label: skill.name,
        })) ?? []
      );
    } else {
      return (
        onboardingData?.skills
          .filter((skill) => clipsRecsData?.recs[skill.id])
          .map((skill) => ({ id: skill.id, label: skill.name })) ?? []
      );
    }
  }, [onboardingData, clipsRecsData, showLeadershipRecs]);
  const [currentFilterId, setCurrentFilterId] = useState<string>('');

  useEffect(() => {
    if (filters.length > 0) {
      setCurrentFilterId(filters[0]?.id);
    }
  }, [filters]);

  const handleFilterSelection = (filterId?: string) => {
    if (filterId) {
      const filterIndex = filters.findIndex((f) => f.id === filterId);
      track({
        trackingData: { filterId, filterIndex, filterLabel: filters[filterIndex].label },
        trackingName: showLeadershipRecs ? 'leadership_clips_recs_filter_change' : 'clips_recs_filter_change',
        action: 'click',
      });
      setCurrentFilterId(filterId);
    }
  };

  const handleProductCardClick = (trackingData: Record<string, unknown>) => {
    track({
      trackingData,
      trackingName: showLeadershipRecs ? 'leadership_clips_card_click' : 'clips_card_click',
      action: 'click',
    });
  };
  const title = _t('Kickstart your learning with bite-sized video lessons');
  const clipsCollection = showLeadershipRecs
    ? clipsRecsData?.leadershipRecs?.[currentFilterId]
    : clipsRecsData?.recs[currentFilterId];
  const items: ClipsCollectionItem[] = clipsCollection?.entities?.filter(filterUndefined) || [];
  const isLoading = showLeadershipRecs ? clipsRecsData?.leadershipRecsLoading : clipsRecsData?.loading;

  if (isLoading)
    return (
      <FilterableShowMoreGridSectionPlaceholder
        renderPlaceholderItem={() => (
          <div css={styles.productCardWrapper} data-testid="placeholder-card-wrapper">
            <ProductCard variant="grid" productType="course" loading />{' '}
          </div>
        )}
      />
    );
  if (!isLoading && !items) {
    track({
      trackingData: {},
      trackingName: showLeadershipRecs ? 'no_leadership_clips_recs' : 'no_clips_recs',
      action: 'hide',
    });
    return null;
  }

  return (
    <TrackedDiv trackingName="clips_recs" withVisibilityTracking>
      <FilterableShowMoreGridSection<ClipsCollectionItem>
        filters={filters}
        selectedFilterId={currentFilterId}
        onFilterSelect={handleFilterSelection}
        title={title}
        renderTitle={() => (
          <Typography component="h2" variant="h2semibold">
            {title}
          </Typography>
        )}
        items={items}
        spacing={{ xs: 16, sm: 24 }}
        renderItem={({ item, itemIndex, itemRef }) => {
          const { id, duration, imageUrl, name, partners, url, views } = item;
          const partnersCleaned = partners
            ?.filter(filterUndefined)
            .map(({ name, logo }) => ({ name, logoUrl: logo ?? undefined }));

          return (
            <div css={styles.productCardWrapper} key={id}>
              <ProductCard
                variant="grid"
                productType="video"
                title={{
                  name,
                  linkProps: {
                    href: `${url}?program=${programSlug}&source=my-recommendations&skill=${currentFilterId}`,
                    target: '_blank',
                    rel: 'noopener noreferrer',
                  },
                  customLinkProps: { refalt: itemRef },
                }}
                renderPreviewImage={() => <ClipsCardPreviewImage imageUrl={imageUrl} />}
                partners={partnersCleaned}
                footer={
                  <>
                    <CardWatchNow watchNow={{ viewCount: getFormattedViewCount(views) }} />
                    <CardMetadata metadata={getClipsRecsMetadata(duration)} />
                  </>
                }
                onClick={() => handleProductCardClick({ id, url, name, itemIndex, collectionId: clipsCollection?.id })}
              />
            </div>
          );
        }}
        renderExpandButton={({ onClick, defaultLabel, isExpanded, showMoreCount, ...rest }) => {
          return (
            <TrackedCdsButton
              trackingName={showLeadershipRecs ? 'show_more_leadership_clips_recs' : 'show_more_clips_recs'}
              trackingData={{
                title,
                isExpanded,
                showMoreCount,
              }}
              size="small"
              variant="secondary"
              onClick={onClick}
              {...rest}
            >
              {defaultLabel}
            </TrackedCdsButton>
          );
        }}
      />
    </TrackedDiv>
  );
};

export default ClipsRecsCollection;
