/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import { Typography2 } from '@coursera/cds-core';

import _t from 'i18n!nls/ai-coach-platform';

import IndeterminateIndicator from './IndeterminateIndicator';

type Props = {
  /**
   * Type of action that the loading message refers to.
   * @default TYPED_MESSAGE
   */
  action?: string;
};

const getProgressiveLabel = (action: Props['action'], progressStep: number = 0): string => {
  let labels;

  switch (action) {
    case 'TYPED_MESSAGE':
    case 'GENERIC_ACTION':
    case 'REAL_LIFE_EXAMPLES':
    default:
      labels = [_t('Thinking...'), _t('Still thinking...'), _t('Almost ready...')];
      break;

    case 'HELP_GET_STARTED_WITH_COURSE':
    case 'WELCOME_MESSAGE':
      labels = [_t('Preparing...'), _t('Still preparing...'), _t('Almost ready...')];
      break;

    case 'PRACTICE_QUESTIONS':
      labels = [_t('Compiling...'), _t('Still compiling...'), _t('Almost ready...')];
      break;

    case 'SUMMARIZE':
      labels = [_t('Summarizing...'), _t('Still summarizing...'), _t('Almost ready...')];
      break;

    case 'SIMPLE_EXPLANATION':
      labels = [_t('Simplifying...'), _t('Still simplifying...'), _t('Almost ready...')];
      break;

    case 'WHY_TAKE_COURSE':
      labels = [_t('Considering...'), _t('Still considering...'), _t('Almost ready...')];
      break;
  }

  return labels[progressStep];
};

/**
 * A loading message that updates progressively based on elapsed time and type of action.
 *
 *  e.g. "Thinking..." --> "Still thinking..." --> "Almost ready..."
 */
const ProgressiveLoader = (props: Props): React.ReactElement => {
  const { action = 'TYPED_MESSAGE' } = props;

  // zero-based progress step maps to array of progressive labels above
  const [progressStep, setProgressStep] = React.useState<number>(0);
  // tracks time elapsed since component mount
  const [elapsedTime, setElapsedTime] = React.useState<number>(0);

  React.useEffect(() => {
    // track every 1 second
    const timer = setInterval(() => {
      setElapsedTime(elapsedTime + 1);
    }, 1000);

    // update progressive label if needed
    if (elapsedTime > 5) {
      setProgressStep(2);
    } else if (elapsedTime > 3) {
      setProgressStep(1);
    } else {
      setProgressStep(0);
    }

    // clear timer on unmount
    return () => {
      clearInterval(timer);
    };
  }, [elapsedTime]);

  return (
    <div>
      <Typography2 variant="bodyPrimary" component="span">
        {getProgressiveLabel(action, progressStep)}
      </Typography2>
      <IndeterminateIndicator />
    </div>
  );
};

export default ProgressiveLoader;
