import moment from 'moment';

import _t from 'i18n!nls/program-common';

const getDurationFormatted = (durationMs: number, abbreviate?: boolean) => {
  const momentDuration = moment.duration(durationMs);
  const hours = momentDuration.hours();
  const minutes = momentDuration.minutes();
  const seconds = momentDuration.seconds();

  // if duration is less than 1 minute, show seconds
  if (hours === 0 && minutes === 0) {
    return abbreviate ? _t(`#{seconds} secs`, { seconds }) : _t(`#{seconds} seconds`, { seconds });
  } else if (hours === 0) {
    // if duration is less than 1 hour, show minutes
    return abbreviate ? _t(`#{minutes} mins`, { minutes }) : _t(`#{minutes} minutes`, { minutes });
  } else {
    // if duration is more than 1 hour, show hours and minutes
    return abbreviate
      ? _t(`#{hours} hrs, #{minutes} mins`, { hours, minutes })
      : _t(`#{hours} hours, #{minutes} minutes`, { hours, minutes });
  }
};

export default getDurationFormatted;
