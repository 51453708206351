import { graphql } from 'react-apollo';

import type { FinancialAidApplication } from '__generated__/graphql-types';
import flatMap from 'lodash/flatMap';
import { compose } from 'recompose';
import type { ComponentEnhancer } from 'recompose';

import logger from 'js/app/loggerSingleton';
import user from 'js/lib/user';
import waitForGraphQL from 'js/lib/waitForGraphQL';

import QueryByCart from 'bundles/payments/components/finaid-2021/enhancers/QueryByCart.graphql';
import QueryByUser from 'bundles/payments/components/finaid-2021/enhancers/QueryByUser.graphql';
import QueryByUserAndProduct from 'bundles/payments/components/finaid-2021/enhancers/QueryByUserAndProduct.graphql';
import type { FinancialAidApplicationsByCart_FinancialAidApplicationsV2Resource_cart_elements as FinancialAidApplicationByCart } from 'bundles/payments/components/finaid-2021/enhancers/__generated__/FinancialAidApplicationsByCart';
import type { FinancialAidApplicationsByUserAndProduct_FinancialAidApplicationsV2Resource_byUserAndProduct_elements as FinancialAidApplicationByUserAndProduct } from 'bundles/payments/components/finaid-2021/enhancers/__generated__/FinancialAidApplicationsByUserAndProduct';
import type { FinancialAidApplicationsByUserWithBackgroundInfo_FinancialAidApplicationsV2Resource_byUser_elements as FinancialAidApplicationByUser } from 'bundles/payments/components/finaid-2021/enhancers/__generated__/FinancialAidApplicationsByUserWithBackgroundInfo';
import type {
  QueryByUserAndProductQuery,
  QueryByUserAndProductQueryVariables,
} from 'bundles/payments/components/finaid-2021/enhancers/__generated__/QueryByUserAndProduct';
import type { FinancialAidApplicationType } from 'bundles/payments/components/finaid-2021/enhancers/types';
import { transformFinancialAidApplication } from 'bundles/payments/components/finaid-2021/enhancers/utils';

import type { QueryByCartQuery, QueryByCartQueryVariables } from './__generated__/QueryByCart';
import type { QueryByUserQuery, QueryByUserQueryVariables } from './__generated__/QueryByUser';

export type WithFinancialAidApplicationsByUserProps = {
  financialAidApplicationsByUser: FinancialAidApplicationByUser[];
  loading?: boolean;
};

export type WithFinancialAidApplicationsByCartProps = {
  financialAidApplicationsByCart: FinancialAidApplicationByCart[];
};

export type WithFinancialAidApplicationsByUserAndProductProps = {
  financialAidApplicationsByUserAndProduct: FinancialAidApplicationByUserAndProduct[];
};

export type WithQueryByUserProps = {
  financialAidApplicationsByUser?: FinancialAidApplicationType[];
  loading?: boolean;
};

export type WithQueryByUserAndProductProps = {
  financialAidApplicationsByUserAndProduct: FinancialAidApplicationType[];
};

export type WithQueryByCartProps = {
  financialAidApplicationsByCart: FinancialAidApplicationType[];
};

type QueryByCartInputProps = {
  cartId: string;
};

type ByUserAndProductInputProps = {
  courseId: string;
};

export const withFinancialAidApplicationsByCart: <InputProps extends {}>() => ComponentEnhancer<
  InputProps,
  unknown
> = () =>
  compose(
    waitForGraphQL<QueryByCartInputProps, QueryByCartQuery, QueryByCartQueryVariables, WithQueryByCartProps>(
      QueryByCart,
      {
        props: ({ data }) => {
          if (data?.error) {
            logger.error(data?.error);
          }
          const finaidApps =
            flatMap(data?.FinancialAidApplication?.queryByCart || [], (finaidApp) => {
              if (!finaidApp) return [];
              return [
                {
                  ...transformFinancialAidApplication(finaidApp as FinancialAidApplication),
                },
              ];
            }) || [];
          return {
            financialAidApplicationsByCart: finaidApps,
          };
        },
        options: ({ cartId }) => {
          return {
            variables: {
              cartId,
            },
            errorPolicy: 'all',
            context: { clientName: 'gatewayGql' },
          };
        },
      }
    )
  );

export const withFinancialAidApplicationsByUser = (
  {
    limit = 1,
    state,
  }: {
    limit?: number;
    state?: 'PENDING' | 'APPROVED' | 'WITHDRAWN' | 'REJECTED';
  } = {},
  isNotRequired = false
) => {
  const graphqlHoc = isNotRequired ? graphql : waitForGraphQL;

  return compose(
    graphqlHoc<{}, QueryByUserQuery, QueryByUserQueryVariables, WithQueryByUserProps>(QueryByUser, {
      skip: () => !user.isAuthenticatedUser(),
      props: ({ data }) => {
        if (data?.error) {
          logger.error(data?.error);
        }
        const loading = data?.loading;
        if (loading) {
          return { loading };
        }

        const finaidApps =
          flatMap(data?.FinancialAidApplication?.queryByUser.elements || [], (finaidApp) => {
            if (!finaidApp) return [];
            return [
              {
                ...transformFinancialAidApplication(finaidApp as FinancialAidApplication),
              },
            ];
          }) || [];

        return {
          financialAidApplicationsByUser: finaidApps,
          loading,
        };
      },
      options: () => {
        return {
          variables: {
            userId: user.get()?.id?.toString(),
            state,
            limit,
          },
          errorPolicy: 'all',
          context: { clientName: 'gatewayGql' },
        };
      },
    })
  );
};

export const withFinancialAidApplicationsByUserAndProduct: <InputProps extends {}>(
  data?: { limit?: number },
  isNotRequired?: boolean
) => ComponentEnhancer<InputProps, unknown> = ({ limit = 1 }: { limit?: number } = {}, isNotRequired?: boolean) => {
  const graphqlHoc = isNotRequired ? graphql : waitForGraphQL;

  return compose(
    graphqlHoc<
      ByUserAndProductInputProps,
      QueryByUserAndProductQuery,
      QueryByUserAndProductQueryVariables,
      WithQueryByUserAndProductProps
    >(QueryByUserAndProduct, {
      skip: ({ courseId }) => !user.isAuthenticatedUser() || !courseId,
      props: ({ data }) => {
        if (data?.error) {
          logger.error(data?.error);
        }

        const finaidApps =
          flatMap(data?.FinancialAidApplication?.queryByUserAndProduct.elements || [], (finaidApp) => {
            if (!finaidApp) return [];
            return [
              {
                ...transformFinancialAidApplication(finaidApp as FinancialAidApplication),
              },
            ];
          }) || [];

        return {
          financialAidApplicationsByUserAndProduct: finaidApps,
        };
      },
      options: ({ courseId }) => {
        return {
          variables: {
            userId: user.get()?.id?.toString(),
            productId: { productItemId: courseId, productType: 'VerifiedCertificate' },
            limit,
          },
          errorPolicy: 'all',
          context: { clientName: 'gatewayGql' },
        };
      },
    })
  );
};
