import { graphql } from 'react-apollo';

import type {
  EnrollmentChoiceQueries,
  EnrollmentChoiceQueriesEnrollmentChoicesArgs,
} from '__generated__/graphql-types';
import { branch, compose, withProps } from 'recompose';

import logger from 'js/app/loggerSingleton';
import user from 'js/lib/user';
import waitForGraphql from 'js/lib/waitForGraphQL';

import { previewPricingTestVariant } from 'bundles/coursera-plus/utils/subscriptionTiersUtils';
import EnrollmentChoiceQuery from 'bundles/enroll/api/EnrollmentChoiceQuery.graphql';
import EnrollmentChoicesDGS from 'bundles/enroll/models/EnrollmentChoices';
import GetPartnerIdsByCourseIdOrS12nId from 'bundles/enroll/queries/GetPartnerIdsByCourseIdOrS12nIdQuery.graphql';
import type {
  GetPartnerIdsByCourseIdOrS12nIdQuery,
  GetPartnerIdsByCourseIdOrS12nIdQueryVariables,
} from 'bundles/enroll/queries/__generated__/GetPartnerIdsByCourseIdOrS12nIdQuery';
import { SPECIALIZATION, VERIFIED_CERTIFICATE } from 'bundles/payments/common/ProductType';
import type { PropsFromWithPromotionInfo } from 'bundles/promotions/components/withPromotionInfo';

type PropsToHOC = {
  isSpecialization?: boolean;
  s12nId?: string;
  courseId?: string;
  partnerIds?: Array<string>;
} & Partial<PropsFromWithPromotionInfo>;

type PropsFromWithProps = {
  userId: number;
  productItemId?: string;
  productType: string;
  partnerIds?: Array<string>;
};

type PropsFromGetPartnerIds = {
  partnerIds?: Array<string>;
};

export type EnrollmentChoices = EnrollmentChoicesDGS;

export type PropsFromWithEnrollment = {
  enrollmentAvailableChoices?: EnrollmentChoices;
  isLoadingEnrollmentChoices?: boolean;
  partnerIds?: Array<string>;
};

type EnrollmentChoiceQueriesType = {
  __typename?: 'Query';
  EnrollmentChoice: EnrollmentChoiceQueries;
};

const withEnrollment = <InputProps extends PropsToHOC>(isNotRequired?: boolean) => {
  const graphqlHoc = !isNotRequired ? waitForGraphql : graphql;

  return compose(
    branch<InputProps>(
      // We are only fetching the course or s12n here because we need the partnerIds
      // within EnrollmentChoices to determine if we should enable the partner in the pricing test or not.
      ({ partnerIds }) =>
        (!partnerIds || partnerIds?.length === 0) && previewPricingTestVariant({ skipProductTypeCheck: true }) === '3',
      graphqlHoc<
        PropsToHOC,
        GetPartnerIdsByCourseIdOrS12nIdQuery,
        GetPartnerIdsByCourseIdOrS12nIdQueryVariables,
        PropsFromGetPartnerIds
      >(GetPartnerIdsByCourseIdOrS12nId, {
        skip: ({ courseId, s12nId, isSpecialization }) =>
          !(s12nId && isSpecialization) && !(courseId && !isSpecialization),
        options: ({ courseId, s12nId, isSpecialization }) => {
          const s12nIdToUse = s12nId && isSpecialization ? `${s12nId}` : '';
          const courseIdToUse = courseId && !isSpecialization ? `${courseId}` : '';

          return {
            variables: { s12nId: s12nIdToUse, courseId: courseIdToUse },
            context: { clientName: 'gatewayGql' },
            // Avoid collision with any other component that may have cached a result
            // for the Course_Course or Specialization_Specialization entities.
            // This HOC is used in the enroll modal which can get imported into any app,
            // so we can't account for all possible collisions in the Apollo cache
            fetchPolicy: 'no-cache',
            errorPolicy: isNotRequired ? 'all' : undefined,
          };
        },
        props: ({ data }) => {
          if (data?.error) {
            throw data.error;
          }

          const partnerIds =
            data?.Course?.queryById?.partners?.map?.(({ id }: { id: string }) => id) ??
            data?.Specialization?.queryById?.partners?.map?.(({ id }: { id: string }) => id) ??
            [];

          return {
            partnerIds,
          };
        },
      })
    ),
    withProps<PropsFromWithProps, InputProps>(({ isSpecialization, s12nId, courseId, partnerIds }) => {
      // Only get enrollment from s12nId, instead of courseId, if the `isSpecialization` flag is passed
      // or if the courseId is not available; getting from courseId is preferred otherwise
      // because it returns more enrollment choices
      const useS12nId = isSpecialization || Boolean(s12nId && !courseId);
      const productItemId = useS12nId ? s12nId : courseId;
      const productType = useS12nId ? SPECIALIZATION : VERIFIED_CERTIFICATE;

      return {
        userId: user.get().id,
        productItemId,
        productType,
        partnerIds,
      };
    }),
    graphqlHoc<
      Required<PropsFromWithProps> & PropsToHOC,
      EnrollmentChoiceQueriesType,
      EnrollmentChoiceQueriesEnrollmentChoicesArgs,
      PropsFromWithEnrollment
    >(EnrollmentChoiceQuery, {
      skip: ({ productItemId }) => !user.isAuthenticatedUser() || !productItemId,
      options: ({ productItemId, productType, userId }) => ({
        variables: {
          userId,
          productType,
          productItemId,
          // We're defaulting includeProgramInvitationCheck to true to avoid updating all the call-sites and making it possible
          // to accidentally add new usages that don't send this flag. If we don't do this, we'll end up with multiple requests
          // with and without the parameter to this high-latency resource.
          includeProgramInvitationCheck: true,
        },
        context: { clientName: 'gatewayGql' },
        errorPolicy: isNotRequired ? 'all' : undefined,
      }),

      props: ({ data, ownProps }) => {
        const { promotionEligibilities } = ownProps ?? {};
        const { isEligible: isEligibleForPromo, promoCodeId } = promotionEligibilities ?? {};

        if (data?.error) {
          logger.error('Unable to fetch GetEnrollmentChoices query');
        }

        return {
          isLoadingEnrollmentChoices: data?.loading ?? false,
          ...(data?.EnrollmentChoice
            ? {
                enrollmentAvailableChoices: new EnrollmentChoicesDGS({
                  ...data.EnrollmentChoice.enrollmentChoices,
                  partnerIds: ownProps?.partnerIds,
                  hasPromotion: Boolean(isEligibleForPromo ? promoCodeId : undefined),
                }),
              }
            : {}),
        };
      },
    })
  );
};

export default withEnrollment;
