import { useState } from 'react';

import type { StudioSettings } from 'bundles/ai-coach-studio/types';

export const usePersistedState = (defaultValue: StudioSettings, key: string) => {
  const [sessionState, setSessionState] = useState(() => {
    const persistedState = sessionStorage.getItem(key);
    return persistedState ? JSON.parse(persistedState) : defaultValue;
  });

  return [sessionState, setSessionState];
};
