/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import type { LegacyContextType } from 'types/legacy-context-types';
import connectToStores from 'vendor/cnpm/fluxible.v0-4/addons/connectToStores';

import { Grid } from '@coursera/cds-core';
import { StarRating } from '@coursera/coursera-ui';

import { deleteFeedback } from 'bundles/content-feedback/actions/CourseRatingActions';
import type RatingFeedback from 'bundles/content-feedback/models/RatingFeedback';
import { TranslateContentButton } from 'bundles/ondemand-translation/components/TranslateContentButton';
import { TranslationContextProvider } from 'bundles/ondemand-translation/components/TranslationContextProvider';
import { TranslatedCMLViewer } from 'bundles/ondemand-translation/components/cml/TranslatedCMLViewer';
import ReplyMessage from 'bundles/reply-message/components/ReplyMessage';
import { EntryTypes } from 'bundles/reply-message/constants/ReplyMessageConstants';

import _t from 'i18n!nls/content-feedback';

import 'css!./__styles__/CourseReview';

const styles = {
  translateButton: css`
    margin-top: 20px;
  `,
};

type InputProps = {
  ratingFeedback: RatingFeedback;
  isAdminView?: boolean;
};

type Props = InputProps & {
  isSuperuser: boolean;
};

class CourseReview extends React.Component<Props> {
  state = {
    isReplyInputActive: false,
  };
  static contextTypes = {
    executeAction: PropTypes.func.isRequired,
  };

  declare context: LegacyContextType<typeof CourseReview.contextTypes>;

  deleteFeedback = () => {
    if (confirm('Are you sure you want to delete this?')) {
      this.context.executeAction(deleteFeedback, this.props.ratingFeedback.id);
    }
  };

  render() {
    const { ratingFeedback, isSuperuser, isAdminView } = this.props;
    const { value, comment, timestamp, id } = ratingFeedback;

    const prettyTimestamp = moment(timestamp).format('D MMM YYYY');

    return (
      <TranslationContextProvider>
        <div className="rc-CourseReview">
          <div className="c-course-review-content">
            <div className="c-course-review-header">
              <StarRating
                rootClassName="star-rating"
                rating={value}
                enableColorOpacity
                ariaLabel={_t('#{rating} out of 5 stars', { rating: Number(value.toFixed(1)) })}
              />
              <div className="c-course-review-timestamp">{prettyTimestamp}</div>
              {isSuperuser && (
                <button
                  className="delete-feedback-container button-link"
                  onClick={this.deleteFeedback}
                  type="button"
                  aria-label={_t('Delete feedback')}
                >
                  <i className="delete-feedback" />
                </button>
              )}
            </div>

            <TranslatedCMLViewer cml={comment} enableTranslation />
            <Grid container spacing={16}>
              {isAdminView && (
                <Grid item>
                  <ReplyMessage
                    entryId={id}
                    entryType={EntryTypes.Feedback}
                    onMessageBoxToggle={(isMessageBoxOpen: boolean) =>
                      this.setState({ isReplyInputActive: isMessageBoxOpen })
                    }
                  />
                </Grid>
              )}

              {!this.state.isReplyInputActive && (
                <Grid item>
                  <TranslateContentButton cssOverride={styles.translateButton} eventingSectionName="course_feedback" />
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      </TranslationContextProvider>
    );
  }
}

export default connectToStores<Props, InputProps>(CourseReview, ['ApplicationStore'], ({ ApplicationStore }, props) => {
  const isSuperuser = ApplicationStore.isSuperuser();
  return { ...props, isSuperuser };
});
