import * as React from 'react';
import { Fragment, useCallback, useContext, useEffect } from 'react';

import { ReactEditor, useSlateStatic } from 'slate-react';

import AuthorConfirmModal from 'bundles/author-course/components/AuthorConfirmModal';
import { unlinkText } from 'bundles/cml/editor/components/buttons/link/linkUtils';
import { FocusContext } from 'bundles/cml/editor/context/focusContext';

import _t from 'i18n!nls/cml';

type Props = {
  onClose: () => void;
};

const UnlinkDialog: React.FC<Props> = ({ onClose }) => {
  const staticEditor = useSlateStatic();
  const { setFocused } = useContext(FocusContext);

  useEffect(
    () => setFocused(true),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleUnlinkClose = useCallback(
    (e: React.MouseEvent | React.KeyboardEvent) => {
      e.preventDefault();
      e.stopPropagation();
      ReactEditor.focus(staticEditor);
      setFocused(false);
      onClose();
    },
    [onClose, setFocused, staticEditor]
  );

  const handleUnlinkConfirm = useCallback(
    (e: React.MouseEvent | React.KeyboardEvent) => {
      unlinkText(staticEditor);
      handleUnlinkClose(e);
    },
    [staticEditor, handleUnlinkClose]
  );

  return (
    <Fragment>
      <AuthorConfirmModal
        title={_t('Unlink this content')}
        content={_t('Are you sure you want to unlink this content?')}
        acceptLabel={_t('Unlink')}
        onCancel={handleUnlinkClose}
        onAccept={handleUnlinkConfirm}
        showModal={true}
        allowClose={true}
      />
    </Fragment>
  );
};

export default UnlinkDialog;
