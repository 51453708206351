/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { useMutation } from '@apollo/client';

import config from 'js/app/config';
import { FormattedHTMLMessage } from 'js/lib/coursera.react-intl';

import { Dialog, Grid, Link, Typography2 } from '@coursera/cds-core';
import { useTracker } from '@coursera/event-pulse/react';

import { CoachButton } from 'bundles/ai-coach-platform/components/building-blocks';
import { CoachSettingsContext } from 'bundles/ai-coach-platform/components/patterns/chat/settings/SettingsContextProvider';
import { ChatHistoryStatus } from 'bundles/ai-coach-platform/components/patterns/chat/settings/types';
import { useSettingsNotification } from 'bundles/ai-coach-platform/components/patterns/chat/settings/useSettingsNotification';
import type {
  AiCoachPlatform_DeleteAllChatSessionsForUserMutation as DeleteAllChatSessionsForUserMutation,
  AiCoachPlatform_DeleteAllChatSessionsForUserMutationVariables as DeleteAllChatSessionsForUserMutationVariables,
} from 'bundles/ai-coach-platform/queries/__generated__/aiCoachDeleteAllChatSessionsMutation';
import aiCoachDeleteAllChatSessionsMutation from 'bundles/ai-coach-platform/queries/aiCoachDeleteAllChatSessionsMutation.graphql';
import { captureMessageInSentry } from 'bundles/ai-coach-platform/utils/sentry';
import { useCoachTrackingContext } from 'bundles/ai-coach-platform/utils/tracking';

import _t from 'i18n!nls/ai-coach-platform';

import SettingsPage from '../../SettingsPage';
import useChatHistoryDeleteAll from './useChatHistoryDeleteAll';

const styles = {
  root: css`
    height: 100%;
  `,
};

const DeleteConfirmModal = ({
  onConfirm,
  onCancel,
  loading,
}: {
  onConfirm: () => void;
  onCancel: () => void;
  loading: boolean;
}) => {
  return (
    <Dialog open variant="transactional">
      <Dialog.HeadingGroup>{_t('Delete chat history')}</Dialog.HeadingGroup>
      <Dialog.Content>
        <Typography2 component="p" variant="bodyPrimary">
          {_t(
            `Coursera will erase all records of your chat history with #{coachLabel}. Don’t worry, this will not delete your Coursera account. `,
            { coachLabel: 'Coach' }
          )}
        </Typography2>
      </Dialog.Content>
      <Dialog.Actions>
        <Grid container spacing={16}>
          <Grid item>
            <CoachButton variant="primary" loading={loading} onClick={onConfirm}>
              {_t('Confirm deletion')}
            </CoachButton>
          </Grid>
          <Grid item>
            <CoachButton variant="secondary" disabled={loading} onClick={onCancel}>
              {_t('Cancel')}
            </CoachButton>
          </Grid>
        </Grid>
      </Dialog.Actions>
    </Dialog>
  );
};

type Props = {
  onBack: () => void;
};

export const SettingsChatHistory = (props: Props): React.ReactElement => {
  const { onBack } = props;
  const { addNotification, clearNotification } = useSettingsNotification();
  const { updateData, data } = React.useContext(CoachSettingsContext);
  const [showConfirm, setShowConfirm] = React.useState<boolean>(false);
  const [deleteDisabled, setDeleteDisabled] = React.useState<boolean>(
    data?.chatHistory?.status === ChatHistoryStatus.SESSIONS_DELETED
  );
  const { isDurationElapsed, setChatHistoryDeleted } = useChatHistoryDeleteAll();
  const track = useTracker();
  const trackingData = useCoachTrackingContext()?.data;
  const [deleteAllChatHistory, { loading: isDeleting }] = useMutation<
    DeleteAllChatSessionsForUserMutation,
    DeleteAllChatSessionsForUserMutationVariables
  >(aiCoachDeleteAllChatSessionsMutation, {
    context: { clientName: 'gatewayGql' },
  });

  React.useEffect(() => {
    track('view_coach_settings', {
      pageType: 'chatHistory',
      coach: {
        mode: trackingData?.mode,
      },
    });
  }, []);

  React.useEffect(() => {
    if (isDurationElapsed) {
      // set disabled status based on chat history status after duration elapsed
      setDeleteDisabled(data?.chatHistory?.status === ChatHistoryStatus.SESSIONS_DELETED);
    } else {
      // keep button disabled otherwise
      setDeleteDisabled(true);
    }

    return () => {
      clearNotification();
    };
  }, [isDurationElapsed, clearNotification]);

  const handleDeleteAll = () => {
    track('interact_coach_settings', {
      settingsInteractionType: 'deleteall_chatHistory',
      coach: {
        mode: trackingData?.mode,
      },
    });

    deleteAllChatHistory({
      variables: {},
    })
      .then(() => {
        updateData({ ...data, chatHistory: { status: ChatHistoryStatus.SESSIONS_DELETED } });
        addNotification({
          content: _t('Your chat history is being deleted.'),
          severity: 'information',
        });
        setDeleteDisabled(true);
        setShowConfirm(false);

        // set localStorage
        setChatHistoryDeleted(Date.now());
      })
      .catch((error) => {
        updateData({ ...data, chatHistory: { status: ChatHistoryStatus.SESSIONS_AVAILABLE } });
        addNotification({
          content: _t('Something went wrong, please try again.'),
          severity: 'error',
        });
        setDeleteDisabled(false);
        setShowConfirm(false);
        captureMessageInSentry({ error, source: 'DeleteAllChatHistory' });
      });
  };

  return (
    <div className="coach-settings-chathistory" css={styles.root}>
      <SettingsPage
        onBack={onBack}
        title={_t('Chat history')}
        footer={
          <CoachButton
            aria-label={_t('Delete all chat history')}
            onClick={() => setShowConfirm(true)}
            loading={isDeleting}
            aria-disabled={deleteDisabled}
          >
            {_t('Delete all')}
          </CoachButton>
        }
      >
        <Typography2 variant="subtitleMedium" component="h3">
          {_t('Chat history')}
        </Typography2>
        <Typography2 variant="bodySecondary" component="p" color="supportText" className="chat-history-support-text">
          <FormattedHTMLMessage
            message={_t(
              `Coursera keeps an internal record of your chat history with #{coachLabel} in accordance with our
          {privacyNoticeUrl} to help us improve your learning experience. {helpArticleUrl}`,
              { coachLabel: 'Coach' }
            )}
            privacyNoticeUrl={
              <Link href={`${config.url.base}about/privacy`} target="_blank" rel="noopener noreferrer">
                {_t('Privacy Notice')}
              </Link>
            }
            helpArticleUrl={
              <Link
                href="https://www.coursera.support/s/article/learner-000002224-Advance-your-learning-with-Coursera-Coach"
                target="_blank"
                rel="noopener noreferrer"
              >
                {_t('Learn more')}
              </Link>
            }
          />
        </Typography2>
        {showConfirm && (
          <DeleteConfirmModal loading={isDeleting} onConfirm={handleDeleteAll} onCancel={() => setShowConfirm(false)} />
        )}
      </SettingsPage>
    </div>
  );
};

export default SettingsChatHistory;
