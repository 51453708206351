const productActionsObject = {
  BUY: 'Buy',
  REFUND: 'Refund',
  APPLE_IN_APP_BUY: 'AppleInAppBuy',
  APPLE_IN_APP_REFUND: 'AppleInAppRefund',
  CHARGEBACK: 'Chargeback',
  EXPIRE: 'Expire',
  EXTEND: 'Extend',

  // Pure FE construct to capture Pre Enroll in carts
  PRE_ENROLL: 'Pre-Enroll',
} as const;

export default productActionsObject;

export const { BUY, REFUND, APPLE_IN_APP_BUY, APPLE_IN_APP_REFUND, CHARGEBACK, EXPIRE, EXTEND, PRE_ENROLL } =
  productActionsObject;

export type ProductActionsType = typeof productActionsObject[keyof typeof productActionsObject];
