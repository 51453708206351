import type { InjectedRouter } from 'react-router';

import redirect from 'js/lib/coursera.redirect';
import { MOMENT_FORMATS, formatDateTimeDisplay } from 'js/utils/DateTimeUtils';

import { RedirectError } from 'bundles/ssr/lib/errors/directives';

export const renderMedDate = (timestamp?: number) => {
  return timestamp ? formatDateTimeDisplay(timestamp, MOMENT_FORMATS.MED_DATE_ONLY_DISPLAY) : '';
};

export const handleRedirect = (redirectUrl: string, router: InjectedRouter) => {
  // Check to see if it's ssr
  if (typeof window === 'undefined') {
    throw new RedirectError(302, redirectUrl);
  } else if (redirect) {
    redirect.setLocation(redirectUrl);
  } else {
    router.replace(redirectUrl);
  }
};
