/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Dialog, LoadingItem, useId } from '@coursera/cds-core';

import _t from 'i18n!nls/course-sessions';

type Props = {
  handleClose: () => void;
  isAdjustingSchedule: boolean;
  adjustScheduleError: boolean;
};

const styles = {
  container: css`
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  inner: css`
    margin-top: -36px;
  `,
};

const AdjustingDeadlinesModal = (props: Props) => {
  const { adjustScheduleError, handleClose } = props;

  const contentId = useId();

  return (
    <Dialog aria-describedby={contentId} className="rc-AdjustingDeadlinesModal" open={true} onClose={handleClose}>
      <Dialog.HeadingGroup>{_t('Adjusting deadlines')}</Dialog.HeadingGroup>
      <Dialog.Content id={contentId}>
        <div css={styles.container}>
          <div css={styles.inner}>
            <LoadingItem
              label={
                adjustScheduleError ? _t('Something went wrong. Please try again.') : _t('Resetting your deadlines')
              }
              hasError={adjustScheduleError}
            />
          </div>
        </div>
      </Dialog.Content>
    </Dialog>
  );
};

export default AdjustingDeadlinesModal;
