import Uri from 'jsuri';
import isEmpty from 'lodash/isEmpty';

import redirect from 'js/lib/coursera.redirect';

import type CartsV2 from 'bundles/naptimejs/resources/carts.v2';

function getRoute(isFinaid?: boolean) {
  if (isFinaid) {
    return 'financial-aid';
  }
  return 'checkout';
}

export const redirectToCheckout = (cart: CartsV2, params?: Record<string, string>, isFinaid?: boolean) => {
  const route = getRoute(isFinaid);

  const checkoutPageUri = new Uri().setPath(`/payments/${route}`).addQueryParam('cartId', cart.id);

  if (params && !isEmpty(params)) {
    Object.keys(params).forEach((key) => {
      // guard against null entries so the url doesn't get `&a=&b=`
      if (params[key]) {
        checkoutPageUri.addQueryParam(key, params[key]);
      }
    });
  }

  redirect.setLocation(checkoutPageUri.toString());
};

export default redirectToCheckout;
