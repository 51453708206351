/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { LONG_DATE_ONLY_DISPLAY, formatDateTimeDisplay } from 'js/utils/DateTimeUtils';

import { color } from '@coursera/coursera-ui';
import { SvgCalendar } from '@coursera/coursera-ui/svg';

import _t from 'i18n!nls/program-home';

type Props = {
  startsAt: number;
  endsAt: number;
};

const wrapperStyle = css({
  display: 'inline-flex',
  alignItems: 'center',
  border: `1px solid ${color.divider}`,
  borderRadius: '4px',
  padding: '18px 24px',

  hr: {
    border: `1px solid ${color.divider}`,
    height: '48px',
    margin: '0 21px',
  },

  strong: {
    color: color.secondaryText,
    fontSize: '14px',
  },

  div: {
    display: 'flex',
    flexDirection: 'column',
  },

  span: {
    fontSize: '20px',
  },
});

const FixedSchedule: React.FC<Props> = ({ startsAt, endsAt }) => {
  return (
    <div css={wrapperStyle}>
      <SvgCalendar color="#002D72" style={{ marginRight: '24px' }} />
      <div>
        <strong>{_t('Course starts')}</strong>
        <span>{formatDateTimeDisplay(startsAt, LONG_DATE_ONLY_DISPLAY)}</span>
      </div>
      <hr />
      <div>
        <strong>{_t('Course ends')}</strong>
        <span>{formatDateTimeDisplay(endsAt, LONG_DATE_ONLY_DISPLAY)}</span>
      </div>
    </div>
  );
};

export default FixedSchedule;
