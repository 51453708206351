/* @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Dialog, Grid, TextField, Typography2 } from '@coursera/cds-core';

import { CoachButton } from 'bundles/ai-coach-platform/components/building-blocks';

import _t from 'i18n!nls/ai-coach-platform';

type Props = {
  title: string;
  placeholder?: string;
  onSubmit(e: any): void;
  onClose(e: any): void;
  onCancel(e: any): void;
};

const FeedbackModal: React.FC<Props> = ({ title, placeholder, onSubmit, onClose, onCancel, ...rest }) => {
  const { Content, Actions } = Dialog;
  const [show, setShow] = React.useState(true);
  const textFieldRef = React.useRef<HTMLInputElement>(null);

  const handleOnClose = (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => {
    setShow(false);
    onClose(e);
  };

  const handleOnSubmit = (
    e: (React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) & {
      data: { userComments: string | undefined };
    }
  ) => {
    setShow(false);
    onSubmit(e);
  };

  const handleOnCancel = (e: React.MouseEvent<Element, MouseEvent>) => {
    setShow(false);
    onCancel(e);
  };

  return (
    <Dialog aria-describedby="dialog-content" open={show} sticky="actions" onClose={handleOnClose} {...rest}>
      <Content id="dialog-content">
        <Grid container>
          <Grid item sm={12} xs={12}>
            <TextField
              maxLength={255}
              placeholder={placeholder}
              inputRef={textFieldRef}
              multiline
              fullWidth
              label={title}
              renderLabel={() => (
                <Typography2 variant="titleMedium" component="h2">
                  {title}
                </Typography2>
              )}
            />
          </Grid>
        </Grid>
      </Content>

      <Actions
        css={css`
          display: flex;
        `}
      >
        <CoachButton
          size="medium"
          variant="primary"
          onClick={(e: React.MouseEvent<Element, MouseEvent>) =>
            handleOnSubmit({ ...e, data: { userComments: textFieldRef.current?.value } })
          }
          css={css`
            margin: 0 24px 0 0;
          `}
        >
          {_t('Submit')}
        </CoachButton>

        <CoachButton size="medium" variant="secondary" onClick={handleOnCancel}>
          {_t('Cancel')}
        </CoachButton>
      </Actions>
    </Dialog>
  );
};

export default FeedbackModal;
