/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { useParams } from 'js/lib/useRouter';

import { Button, Grid, PageGridContainer, Tag, Typography2, breakpoints } from '@coursera/cds-core';
import { ArrowNextIcon } from '@coursera/cds-icons';

import EnterpriseLearningPathProductCard from 'bundles/page-config-common/components/enterprise/EnterpriseLearningPathProductCard';
import type { EnterpriseBadgeWithCollection } from 'bundles/page-config-common/providers/enterprise/EnterpriseBadgeCollectionsProvider';

import _t from 'i18n!nls/consumer-home';

type Props = {
  enterpriseBadgeWithCollection: EnterpriseBadgeWithCollection;
};

const styles = {
  pageGrid: css`
    padding: 0 var(--cds-spacing-600);
    margin: var(--cds-spacing-600) auto;

    ${breakpoints.down('sm')} {
      margin: var(--cds-spacing-300) auto;
    }
  `,
  learningPath: css`
    padding: 48px 56px;
    background-color: var(--cds-color-grey-25, #f5f7f8);
    border-radius: 10px;
  `,
};

const EnterpriseSingleLearningPathModule = ({ enterpriseBadgeWithCollection }: Props) => {
  const { programSlug } = useParams();
  return (
    <PageGridContainer
      data-testid="enterprise-learning-paths"
      css={styles.pageGrid}
      className="learning-path-container"
    >
      <Grid item xs={12}>
        <Grid container alignItems="center" css={styles.learningPath} className="single-lp">
          <Grid item xs={12} sm={12} md={5}>
            <Typography2 variant="titleSmall" component="h3" css={{ marginBottom: '16px' }}>
              {_t('Elevate your skills and career with a learning path designed by your organization')}
            </Typography2>
            <Grid spacing={8} container>
              {enterpriseBadgeWithCollection.enterpriseBadge.skillsMetadata.slice(0, 8).map((skill) => {
                if (skill?.id && skill?.skillName) {
                  return (
                    <Grid item key={skill.id}>
                      <Tag>{skill.skillName}</Tag>
                    </Grid>
                  );
                } else {
                  return null;
                }
              })}
            </Grid>
            <Button
              css={{ marginTop: '12px', paddingLeft: 0 }}
              variant="ghost"
              icon={<ArrowNextIcon />}
              component="a"
              href={`/programs/${programSlug}/learning-path/${enterpriseBadgeWithCollection.enterpriseBadge.slug}?collectionId=${enterpriseBadgeWithCollection.enterpriseBadge.collectionId}`}
            >
              {_t('View learning path')}
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={7}>
            <EnterpriseLearningPathProductCard
              isSingularView={true}
              enterpriseBadgeWithCollection={enterpriseBadgeWithCollection}
              numOfEnterpriseBadges={1}
              itemIndex={0}
            />
          </Grid>
        </Grid>
      </Grid>
    </PageGridContainer>
  );
};

export default EnterpriseSingleLearningPathModule;
