import * as React from 'react';

import type UserAgentInfo from 'js/lib/useragent';

import type { ScopedContentRule } from 'bundles/enroll/utils/scopedContentRulesQuery';

/* migrate below */
import type { PdpPageQuery_XdpV1Resource_slug_elements_xdpMetadata_XdpV1_cdpMetadataMember_cdpMetadata as CDPMetadata } from 'bundles/enterprise-legacy-xdp/components/miniPDP/__generated__/PdpPageQuery';
import type { PdpPageQueryById_XdpV1Resource_get_xdpMetadata_XdpV1_cdpMetadataMember_cdpMetadata as CDPMetadataById } from 'bundles/enterprise-legacy-xdp/components/miniPDP/__generated__/PdpPageQueryById';
import type BannerInfoBoxViewController from 'bundles/enterprise-legacy-xdp/components/miniPDP/data/controllers/BannerInfoBoxViewController';
import type FaqsViewController from 'bundles/enterprise-legacy-xdp/components/miniPDP/data/controllers/FaqsViewController';
import type HowItWorksViewController from 'bundles/enterprise-legacy-xdp/components/miniPDP/data/controllers/HowItWorksViewController';
import type PDPRecommendationsViewController from 'bundles/enterprise-legacy-xdp/components/miniPDP/data/controllers/PDPRecommendationsViewController';
import type PageViewController from 'bundles/enterprise-legacy-xdp/components/miniPDP/data/controllers/PageViewController';

export type PageDataContext = {
  data: CDPMetadata;
  slug: string;
  userAgent: UserAgentInfo;
  metaImgSrc?: string;
  scopedContentRule?: ScopedContentRule;

  // view controllers
  faqsViewController: FaqsViewController;
  howItWorksViewController: HowItWorksViewController;
  bannerInfoBoxViewController: BannerInfoBoxViewController;
  pageViewController: PageViewController;
  pdpRecommendationsViewController: PDPRecommendationsViewController;
};

export type MiniPdpPageDataContext = {
  data: CDPMetadataById;
  userAgent: UserAgentInfo;

  // view controllers
  howItWorksViewController: HowItWorksViewController;
  bannerInfoBoxViewController: BannerInfoBoxViewController;
  pageViewController: PageViewController;
};

const PageContext = React.createContext<PageDataContext | MiniPdpPageDataContext | undefined>(undefined);

export default PageContext;
