import _t from 'i18n!nls/common';

function getBadgeConstants() {
  return {
    new: _t('NEW'),
    deprecated: _t('DEPRECATED'),
  };
}

export default getBadgeConstants;
