import * as React from 'react';

import type { ApolloError } from '@apollo/client';
import { useApolloClient } from '@apollo/client';

import type { UpdateChatHistory } from 'bundles/ai-coach-platform/components/patterns/chat/store/actions/types';
import { updateChatHistory } from 'bundles/ai-coach-platform/components/patterns/chat/store/dispatcher';
import type {
  AiCoachPlatform_ChatMessagesPaginatedResultQueryVariables as AiCoachChatMessagesPaginatedResultQueryVariables,
  AiCoachPlatform_ChatMessagesPaginatedResultQuery as ChatMessagesPaginatedResultQuery,
} from 'bundles/ai-coach-platform/queries/__generated__/aiCoachChatMessagesByChatSessionId';
import aiCoachChatMessagesByChatSessionIdQuery from 'bundles/ai-coach-platform/queries/aiCoachChatMessagesByChatSessionId.graphql';
import { captureMessageInSentry } from 'bundles/ai-coach-platform/utils/sentry';

import { MESSAGE_SENDER } from './constants';

type Props = {
  historyLimit: number;
  chatSessionId?: string;
};

export function useChatHistory({
  historyLimit,
}: Props): { loading: boolean; error: any; refreshHistory: (chatSessionId: string) => void } | undefined {
  const client = useApolloClient();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<any>(null);
  let isMounted = false; // Add this flag to prevent setting state on an unmounted component
  const fetchData = async (chatSessionId: string) => {
    setLoading(true);
    try {
      const result = await client.query<
        ChatMessagesPaginatedResultQuery,
        AiCoachChatMessagesPaginatedResultQueryVariables
      >({
        query: aiCoachChatMessagesByChatSessionIdQuery,
        variables: {
          chatSessionId,
          limit: historyLimit,
        },
        context: { clientName: 'gatewayGql' },
        fetchPolicy: 'network-only',
      });
      if (result.data?.AiCoach?.queryByChatSessionId?.elements) {
        const messages = result.data?.AiCoach?.queryByChatSessionId?.elements;
        // @ts-expect-error DateTime: string
        const parsedMessages: UpdateChatHistory['messages'] = messages.map((message) => {
          if (message.senderType === MESSAGE_SENDER.CLIENT) {
            return {
              id: message.id,
              sender: message.senderType,
              text: message.message,
              timestamp: message.createdAt,
            };
          } else if (message.senderType === MESSAGE_SENDER.RESPONSE) {
            return {
              id: message.id,
              sender: message.senderType,
              text: message.message,
              timestamp: message.createdAt,
              /** Feedback from the user on the AI coach response via a like/dislike */
              userFeedback: message.userFeedback,
            };
          }
          return {
            id: message.id,
            sender: message.senderType,
            text: message.message,
            timestamp: message.createdAt,
          };
        });

        // TODO: MAGIC: chat history is coming in reverse order, so we make it the new messages are at the bottom of the chat by reversing it here.
        updateChatHistory(parsedMessages.reverse());
        setLoading(false);
      }
    } catch (fetchError) {
      if (isMounted) setError(fetchError);
      if (isMounted) setLoading(false);

      const apolloError = fetchError as ApolloError;
      captureMessageInSentry({ source: 'useChatHistory', error: apolloError });
    }
  };

  React.useEffect(() => {
    // TODO: Assignments to the 'isMounted' variable from inside React Hook React.useEffect will be lost after each render. To preserve the value over time, store it in a useRef Hook and keep the mutable value in the '.current' property. Otherwise, you can move this variable directly inside React.useEffect.
    // FIXME: existing react-hooks/exhaustive-deps violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isMounted = true;

    return () => {
      isMounted = false;
    };
  }, []); // Empty dependence array ensures this runs only once.

  return { loading, error, refreshHistory: fetchData };
}
