import { deleteConfirmKeyDownHandler } from 'bundles/cml/editor/keyboard/deleteConfirmKeyDownHandler';
import { deleteMarkKeyDownHandler } from 'bundles/cml/editor/keyboard/deleteMarkKeyDownHandler';
import { headingKeyDownHandler } from 'bundles/cml/editor/keyboard/headingKeyDownHandler';
import { imageUploaderKeyDownHandler } from 'bundles/cml/editor/keyboard/imageUploaderKeyDownHandler';
import { linkKeyDownHandler } from 'bundles/cml/editor/keyboard/linkKeyDownHandler';
import { listKeyDownHandler } from 'bundles/cml/editor/keyboard/listKeyDownHandler';
import { marksKeyDownHandler } from 'bundles/cml/editor/keyboard/marksKeyDownHandler';
import { newLineKeyDownHandler } from 'bundles/cml/editor/keyboard/newLineKeyDownHandler';
import { softBreaksKeyDownHandler } from 'bundles/cml/editor/keyboard/softBreaksKeyDownHandler';
import { tableKeyDownHandler } from 'bundles/cml/editor/keyboard/tableKeyDownHandler';

export const KEYDOWN_HANDLERS = [
  deleteMarkKeyDownHandler,
  softBreaksKeyDownHandler,
  tableKeyDownHandler,
  deleteConfirmKeyDownHandler,
  listKeyDownHandler,
  marksKeyDownHandler,
  linkKeyDownHandler,
  newLineKeyDownHandler,
  imageUploaderKeyDownHandler,
  headingKeyDownHandler,
];
