/** @jsx jsx */
import type { Interpolation } from '@emotion/react';
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import logger from 'js/app/loggerSingleton';

import type { Theme } from '@coursera/cds-core';
import { Dialog, Typography } from '@coursera/cds-core';

import SubscriptionTiersEnrollChoices from 'bundles/coursera-plus/components/subscriptionTiers/SubscriptionTiersEnrollChoices';
import { getCopies } from 'bundles/coursera-plus/components/subscriptionTiers/SubscriptionTiersEnrollCopies';
import {
  isLiteEnrollModalDesignTweaksVariant4,
  isLiteEnrollModalDesignTweaksVariant5,
} from 'bundles/coursera-plus/utils/subscriptionTiersUtils';
import usePageData from 'bundles/enroll/data/usePageData';
import TrackedDiv from 'bundles/page/components/TrackedDiv';

const styles: Record<string, Interpolation<Theme>> = {
  dialog: css`
    .cds-Dialog-dialog {
      max-width: 900px !important;
    }
  `,
  header: css`
    text-align: center;
    padding-bottom: 24px;
  `,
};

type PropsFromCaller = {
  onClose: () => void;
  onSdp: boolean;
};

export const SubscriptionTiersSideBySideEnrollModal: React.FC<PropsFromCaller> = ({ onClose }) => {
  const { s12n, course, courseraLiteProductPrice, courseraPlusProductPrice, enrollmentAvailableChoices } =
    usePageData();

  if (!s12n || !courseraLiteProductPrice || !courseraPlusProductPrice) {
    logger.error('Unable to render Subscription Tiers enroll modal without s12n or pricing');
    return null;
  }

  const copies = getCopies(courseraLiteProductPrice, courseraPlusProductPrice);
  const { Content } = Dialog;
  const isVariant4Or5 = isLiteEnrollModalDesignTweaksVariant4() || isLiteEnrollModalDesignTweaksVariant5();
  const hasBothFreeTrials =
    enrollmentAvailableChoices.canSubscribeToCourseraLiteFreeTrial &&
    enrollmentAvailableChoices.canSubscribeToCourseraPlusFreeTrial;

  return (
    <TrackedDiv
      trackingName="subscription_tiers_side_by_side_enroll_modal"
      trackClicks={false}
      withVisibilityTracking={true}
      data={{ course: course.id, s12n: s12n.id }}
    >
      <Dialog
        aria-describedby="dialog-content"
        open
        variant="standard"
        width="large"
        onClose={onClose}
        css={styles.dialog}
      >
        <Typography
          variant={isVariant4Or5 ? 'h1' : 'd2semibold'}
          css={styles.header}
          component="h1"
          data-testid="dialog-title"
        >
          {isVariant4Or5 && hasBothFreeTrials ? copies.getStartedFreeTrial : copies.dialogTitle}
        </Typography>
        <Content id="dialog-content">
          <SubscriptionTiersEnrollChoices />
        </Content>
      </Dialog>
    </TrackedDiv>
  );
};

export default SubscriptionTiersSideBySideEnrollModal;
