/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import user from 'js/lib/user';

import { Breadcrumbs, Link, Typography2 } from '@coursera/cds-core';

import type { Domain } from 'bundles/browse/components/Domain/types/Domain';
import type { Subdomain } from 'bundles/browse/components/Domain/types/SubDomain';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';
import useHasMultipleProgramProps from 'bundles/program-home/components/multiprogram/useHasMultipleProgramProps';

import _t from 'i18n!nls/enterprise-collections';

type Props = {
  className?: string;
  programSlug?: string;
  thirdPartyOrgSlug: string;
  thirdPartyOrganizationId: string;
  programName?: string;
  domain?: Domain;
  subdomain?: Subdomain;
};

const styles = {
  breadcrumbs: css`
    .cds-breadcrumbs-list {
      align-items: center;
    }

    .DomainBreadcrumbs-link {
      color: var(--cds-color-neutral-primary-invert) !important;
    }
  `,
  itemText: css`
    font-weight: 700;
  `,
};

export const DomainBreadcrumbs = ({
  className,
  programSlug,
  programName,
  thirdPartyOrgSlug,
  thirdPartyOrganizationId,
  domain,
  subdomain,
}: Props): JSX.Element | null => {
  const { hasMultipleProgramsInOrg } = useHasMultipleProgramProps({ userId: user.get().id, thirdPartyOrganizationId });
  const baseUrl = programSlug ? `/programs/${programSlug}` : `/programs/all/${thirdPartyOrgSlug}`;
  const homeUrl = hasMultipleProgramsInOrg ? `/programs/all/${thirdPartyOrgSlug}` : baseUrl;
  const breadcrumbs: JSX.Element[] = [<Breadcrumbs.Item href={homeUrl}>{_t('Home')}</Breadcrumbs.Item>];

  if (hasMultipleProgramsInOrg) {
    breadcrumbs.push(
      <Breadcrumbs.Item key="home">
        <Link
          trackingName="program_home_domain_breadcrumb_home_link"
          data={{ thirdPartyOrgSlug }}
          component={TrackedLink2}
          href={`/programs/all/${thirdPartyOrgSlug}`}
          className="DomainBreadcrumbs-link"
          css={styles.itemText}
        >
          {_t('All Learning Programs')}
        </Link>
      </Breadcrumbs.Item>
    );
  }
  if (programSlug && programName) {
    breadcrumbs.push(
      <Breadcrumbs.Item>
        <Link
          trackingName="program_home_domain_breadcrumb_program_link"
          data={{ programSlug }}
          component={TrackedLink2}
          href={`/programs/${programSlug}`}
          className="DomainBreadcrumbs-link"
          css={styles.itemText}
        >
          {programName}
        </Link>
      </Breadcrumbs.Item>
    );
  }
  if (domain?.name) {
    if (subdomain?.name) {
      breadcrumbs.push(
        <Breadcrumbs.Item>
          <Link
            trackingName="program_home_domain_breadcrumb_domain_link"
            data={{ programSlug }}
            component={TrackedLink2}
            href={`${baseUrl}/${domain.slug || domain.id}`}
            className="DomainBreadcrumbs-link"
            css={styles.itemText}
          >
            {domain.name}
          </Link>
        </Breadcrumbs.Item>
      );
      breadcrumbs.push(
        <Breadcrumbs.Item key="subdomain" css={styles.itemText}>
          {subdomain.name}
        </Breadcrumbs.Item>
      );
    } else {
      breadcrumbs.push(
        <Breadcrumbs.Item key="domain" css={styles.itemText}>
          {domain.name}
        </Breadcrumbs.Item>
      );
    }
  }
  return (
    <Typography2 className={className} variant="subtitleMedium" color="invertBody" component="div">
      <Breadcrumbs invert css={styles.breadcrumbs}>
        {breadcrumbs}
      </Breadcrumbs>
    </Typography2>
  );
};

export default DomainBreadcrumbs;
