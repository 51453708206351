import * as React from 'react';
import { useCallback } from 'react';

import { useSlate, useSlateStatic } from 'slate-react';

import { TableIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import { insertTable } from 'bundles/cml/editor/components/elements/table/tableUtils';
import { isTypeDisabled } from 'bundles/cml/editor/utils/elementUtils';
import { hasAncestorOfType } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';

import _t from 'i18n!nls/cml';

const TableButton: React.FC = () => {
  const editor = useSlate();
  const staticEditor = useSlateStatic();

  const active = hasAncestorOfType(editor, BLOCK_TYPES.TABLE);
  const disabled = isTypeDisabled(editor, BLOCK_TYPES.TABLE);

  const handleChange = useCallback(() => insertTable(staticEditor), [staticEditor]);

  return (
    <Button
      onClick={handleChange}
      disabled={disabled}
      active={active}
      className="rc-TableButton"
      title={_t('Insert table')}
      type={TOOLBAR_BUTTON_TYPES.insertion}
    >
      <TableIcon size="small" />
    </Button>
  );
};

export default TableButton;
