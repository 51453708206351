import type React from 'react';

import type { BaseComponentProps } from '@coursera/cds-common';

import { useItemsCollection, useKeyboardNavigation } from '@core/utils';

export type Props = BaseComponentProps<'div'> & {
  role: 'radiogroup' | 'group';
  children?: React.ReactNode;
};

const ChipsCollection = (props: Props) => {
  const { children, role, ...rest } = props;
  const collection = useItemsCollection<
    HTMLButtonElement,
    { role: 'button' | 'checkbox' | 'radio' }
  >();

  const { onKeyDown } = useKeyboardNavigation({
    elements: collection?.items.map((item) => item.element),
    disableArrowsNavigation: collection?.commonProps.role !== 'radio',
  });

  return (
    <div
      role={role}
      onKeyDown={(event) => {
        onKeyDown(event)?.click();
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

export default ChipsCollection;
