import * as React from 'react';

import { ExternalLinkIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import { useRouter } from 'bundles/cml/shared/hooks/context';

import _t from 'i18n!nls/cml';

type Props = {
  widgetTypeId?: string;
  title?: string;
};

const OpenInLibraryButton: React.FC<Props> = ({ widgetTypeId, title = _t('Open in library') }) => {
  const router = useRouter();

  const handleOpenInLibrary = () => {
    const {
      params: { courseSlug, contextId },
    } = router;

    window.open(`${window.location.origin}/teach/${courseSlug}/${contextId}/content/plugins/${widgetTypeId}`);
  };

  return (
    <Button
      role="menuitem"
      title={title}
      onClick={handleOpenInLibrary}
      tooltipProps={{ placement: 'bottom' }}
      data-pendo="cml-widget-menu-open"
      type={TOOLBAR_BUTTON_TYPES.menu}
    >
      <ExternalLinkIcon size="small" />
    </Button>
  );
};

export default OpenInLibraryButton;
