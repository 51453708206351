import type { Skill } from 'bundles/program-personalized-tab/types/sharedTypes';

export const NUM_ENTRIES_PER_SKILL = 10;

export const MAX_ONBOARDING_SKILLS_LENGTH = 6;

export const SKILL_LIMIT = 1000;

export const DEFAULT_SKILLS: Skill[] = [
  { id: 'communication', name: 'Communication' },
  { id: 'problem-solving', name: 'Problem Solving' },
  { id: 'critical-thinking', name: 'Critical Thinking' },
  { id: 'innovation', name: 'Innovation' },
  { id: 'risk-management', name: 'Risk Management' },
  { id: 'creativity', name: 'Creativity' },
];

export const LEADERSHIP_SKILLS: Skill[] = [
  { id: 'leadership-development', name: 'Leadership Development' },
  { id: 'management', name: 'Leadership and Management' },
  { id: 'strategy', name: 'Strategy' },
  { id: 'problem-solving', name: 'Problem Solving' },
  { id: 'decision-making', name: 'Decision Making' },
  { id: 'business-communication', name: 'Business Communication' },
];

export const DEFAULT_RELATED_SKILLS: Skill[] = [
  { id: 'business-analysis', name: 'Business Analysis' },
  { id: 'project-management', name: 'Project Management' },
  { id: 'communication', name: 'Communication' },
  { id: 'emotional-intelligence', name: 'Emotional Intelligence' },
  { id: 'critical-thinking', name: 'Critical Thinking' },
  { id: 'change-management', name: 'Change Management' },
  { id: 'conflict-management', name: 'Conflict Management' },
  { id: 'entrepreneurship', name: 'Entrepreneurship' },
  { id: 'innovation', name: 'Innovation' },
  { id: 'negotiation', name: 'Negotiation' },
  { id: 'creativity', name: 'Creativity' },
  { id: 'risk-management', name: 'Risk Management' },
  { id: 'product-management', name: 'Product Management' },
  { id: 'marketing', name: 'Marketing' },
  { id: 'adaptability', name: 'Adaptability' },
  { id: 'operations-management', name: 'Operations Management' },
];

// BE uses these IDs to track enrollments
export const PRODUCT_RECS_COLLECTION_ID = 'precs';
export const LEADERSHIP_RECS_COLLECTION_ID = 'lrecs';
export const SCORED_SKILL_RECS_SOURCE_QUERY_VALUE = 'skills-with-score-recs';
export const SELECTED_SKILL_RECS_SOURCE_QUERY_VALUE = 'selected-skill-recs';
