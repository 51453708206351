import * as React from 'react';

import AssetAdminManager from 'bundles/asset-admin/components/AssetAdminManager';
import type { PropsFromCaller } from 'bundles/asset-admin/components/AssetAdminManager';
import { MODAL_WIDTH } from 'bundles/asset-admin/constants/TableConstants';
import Modal from 'bundles/phoenix/components/Modal';

export type Props = PropsFromCaller & {
  autoFocusOnCloseButton?: boolean;
};

const AssetAdminModalV2: React.FunctionComponent<Props> = ({ autoFocusOnCloseButton, ...props }) => {
  const propsToPass = {
    ...props,
    assetBrowserWidth: MODAL_WIDTH,
  };
  return (
    <Modal
      handleClose={propsToPass.onCancel}
      modalName={propsToPass.title || ''}
      shouldFocusOnXButton={autoFocusOnCloseButton}
      className="rc-AssetAdminModalV2"
    >
      <AssetAdminManager {...propsToPass} />
    </Modal>
  );
};

export default AssetAdminModalV2;
