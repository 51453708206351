import * as React from 'react';

import { Grid, PageGridContainer } from '@coursera/cds-core';

import 'css!./__styles__/CatalogSection';

type PropsForCatalogSection = {
  children: React.ReactNode;
};

const CatalogSection = ({ children }: PropsForCatalogSection): JSX.Element | null => {
  let childrenCount = 0;
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      childrenCount += 1;
    }
  });
  if (childrenCount <= 0) {
    return null;
  }

  return (
    <div className="CatalogSection" data-testId="catalog-section">
      <div className="CatalogSection-Separator">
        <PageGridContainer className="CatalogSection-Margins">
          <Grid item xs={12}>
            {children}
          </Grid>
        </PageGridContainer>
      </div>
    </div>
  );
};

export default CatalogSection;
