/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useContext, useEffect, useState } from 'react';

import type { AiCoach_AdditionalContextInput as AiCoachAdditionalContextInput } from '__generated__/graphql-types';

import { randomUUID as uuid } from 'js/lib/uuid';

import {
  Accordion,
  AccordionHeader,
  AccordionPanel,
  ContextualHelp,
  FormLabel,
  MuiSlider,
  MultiSelect,
  SelectField,
  SelectOption,
  TextField,
  Typography2,
} from '@coursera/cds-core';

import { CoachIconButton } from 'bundles/ai-coach-platform/components/building-blocks';
import { AddIcon, CloseIcon } from 'bundles/ai-coach-platform/components/icons/mui';
import { CoachStudioSettingsContext } from 'bundles/ai-coach-studio/components/CoachStudioSettingsContextProvider';
import { AVAILABLE_CONTEXT_FETCHERS, COACH_STUDIO_MODELS } from 'bundles/ai-coach-studio/constants';
import { ContextGroup } from 'bundles/ai-coach-studio/types';
import type { ContextFetcher } from 'bundles/ai-coach-studio/types';

import _t from 'i18n!nls/coach-studio';

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    gap: var(--cds-spacing-300);
    padding: var(--cds-spacing-300);
    max-width: 770px;
    margin: 0 auto;
  `,
  column: css`
    display: flex;
    flex-direction: column;
  `,
  label: css`
    margin-bottom: var(--cds-spacing-100);
  `,
  additionalContext: css`
    display: flex;
    flex-direction: column;
    gap: var(--cds-spacing-200);
  `,
  additionalContextKey: (validationStatus: boolean) =>
    css`
      color: ${validationStatus ? 'var(--cds-color-green-700)' : 'var(--cds-color-red-700)'};
    `,
  additionalProductRow: css`
    display: flex;
    flex-direction: row;
    gap: var(--cds-spacing-300);
    align-items: end;
  `,
  deleteAdditionalProductRow: css`
    flex-shrink: 0;
  `,
  contextFetcherInputs: css`
    display: flex;
    flex-direction: column;
    gap: var(--cds-spacing-300);
  `,
  additionalProductSection: css`
    display: flex;
    flex-direction: column;
    gap: var(--cds-spacing-100);
  `,
  additionalProductLabel: (validationStatus: boolean) => css`
    border: 1px solid ${validationStatus ? 'var(--cds-color-green-700)' : 'var(--cds-color-red-700)'};
    border-radius: var(--cds-border-radius-25);
  `,
  slider: css`
    color: var(--cds-color-blue-600);
  `,
};

type AdditionalContextRowProps = {
  contextKey: string;
  contextValue: string;
  index: number;
  handleContextChange: (index: number, key: string, value: string) => void;
};

type AdditionalProductRowProps = {
  index: number;
  handleAdditionalProductsChange: (index: number, productIdValue?: string, productTypeValue?: string) => void;
  deleteRow: () => void;
  productId: string;
  productType: string;
};

const AdditionalContextRow = ({
  contextKey,
  contextValue,
  index,
  handleContextChange,
}: AdditionalContextRowProps): React.ReactElement => {
  return (
    <div css={styles.column}>
      <Typography2 component="p" aria-label={_t('Context key')} css={styles.additionalContextKey(!!contextValue)}>
        {`\${${contextKey}}`}
      </Typography2>
      <TextField
        multiline
        minRows={1}
        maxRows={8}
        {...(contextValue ? { value: contextValue } : { placeholder: _t('Example value') })}
        validationStatus={!contextValue ? 'error' : 'success'}
        onChange={(e) => handleContextChange(index, contextKey, e.target.value)}
        aria-label={_t('Context value')}
      />
    </div>
  );
};

const AdditionalProductRow = ({
  index,
  handleAdditionalProductsChange,
  deleteRow,
  productType,
  productId,
}: AdditionalProductRowProps): React.ReactElement => {
  return (
    <div css={styles.additionalProductRow}>
      <TextField
        aria-label={_t('Product ID')}
        placeholder={_t('Product ID')}
        onChange={(e) => handleAdditionalProductsChange(index, e.target.value, '')}
        value={productId}
        validationStatus={productId ? 'success' : 'error'}
      />
      <SelectField
        aria-label={_t('Product type')}
        placeholder={_t('Product type')}
        onChange={(e) => handleAdditionalProductsChange(index, '', e.target.value as string)}
        value={productType}
        validationStatus={productType ? 'success' : 'error'}
      >
        <SelectOption value="COURSE">COURSE</SelectOption>
        <SelectOption value="SPECIALIZATION">SPECIALIZATION</SelectOption>
      </SelectField>

      <CoachIconButton
        onClick={deleteRow}
        icon={<CloseIcon />}
        tooltip={_t('Delete additional product')}
        aria-label={_t('Delete additional product')}
        data-testid="delete-additional-product"
        css={styles.deleteAdditionalProductRow}
      />
    </div>
  );
};

const ContextFetcherList = ({
  contextFetchers,
  onChange,
  value,
}: {
  contextFetchers: ContextFetcher[];
  onChange: (values: Iterable<string | number>) => void;
  value: string[];
}): React.ReactElement => {
  return (
    <MultiSelect
      placeholder="Select context fetchers"
      value={value}
      onChange={onChange}
      aria-label={_t('Context fetcher list')}
    >
      {contextFetchers.map((contextFetcher) => {
        return (
          <MultiSelect.Option key={contextFetcher.contextFetcherKey}>
            {contextFetcher.contextFetcherKey}
          </MultiSelect.Option>
        );
      })}
    </MultiSelect>
  );
};

// Matches all strings that start with '${additionalContext.' and end with '}'
const regex = /\$\{(additionalContext\.[^}]+)\}/g;

export const CoachStudioSettings = (): React.ReactElement => {
  const { studioSettings, setStudioSettings, setIsSettingsFormValid } = useContext(CoachStudioSettingsContext);
  const [isAdditionalProductStylesValid, setIsAdditionalProductStylesValid] = useState<boolean>(false);
  const [selectedContextFetcherGroups, setSelectedContextFetcherGroups] = useState<ContextGroup[]>([]);

  const isCourseContextFetcherSelected = selectedContextFetcherGroups.includes(ContextGroup.COURSE);
  const isS12nContextFetcherSelected = selectedContextFetcherGroups.includes(ContextGroup.S12N);
  const isAdditionalProductsContextFetcherSelected = selectedContextFetcherGroups.includes(
    ContextGroup.ADDITIONAL_PRODUCTS
  );

  useEffect(() => {
    // If a user selects context fetchers that are related to a course, specialization, or additional products, we need additional info from the user like courseId, specializationId, and additionalProducts.
    // We get the context fetcher groups that correlate to the selected context fetchers so we can render the relevant inputs for the user to provide the additional info.
    const getContextFetcherGroups = () => {
      const contextFetcherGroups: ContextGroup[] = studioSettings.contextFetchers
        .map(
          (contextFetcher) =>
            AVAILABLE_CONTEXT_FETCHERS.find(
              (availableContextFetcher) => contextFetcher === availableContextFetcher.contextFetcherKey
            )?.contextGroup
        )
        .filter(Boolean);

      const contextFetcherGroupsArray = [...new Set(contextFetcherGroups)];

      setSelectedContextFetcherGroups(contextFetcherGroupsArray);

      // If a user deselects a context fetcher group from the dropdown menu, we need to clear the additional info related to that group in state.
      const { courseId, specializationId, additionalProducts } = studioSettings;
      const newStudioSettings = { ...studioSettings };

      if (!contextFetcherGroupsArray.includes(ContextGroup.COURSE) && courseId) {
        newStudioSettings.courseId = '';
      }

      if (!contextFetcherGroupsArray.includes(ContextGroup.S12N) && specializationId) {
        newStudioSettings.specializationId = '';
      }

      if (!contextFetcherGroupsArray.includes(ContextGroup.ADDITIONAL_PRODUCTS) && additionalProducts.length >= 1) {
        newStudioSettings.additionalProducts = [];
      }

      setStudioSettings((prev) => ({ ...prev, ...newStudioSettings }));
    };

    getContextFetcherGroups();
    // Adding studioSettings to the dependency array causes an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    studioSettings.contextFetchers,
    setSelectedContextFetcherGroups,
    studioSettings.courseId,
    studioSettings.specializationId,
  ]);

  // Validation for the additional context inputs
  useEffect(() => {
    if (studioSettings.additionalContext.length >= 1) {
      const isMissingAdditionalContextValue = studioSettings.additionalContext.some(
        (contextItem) => !contextItem.contextValue
      );
      setIsSettingsFormValid(!isMissingAdditionalContextValue);
    }
  }, [setIsSettingsFormValid, studioSettings.additionalContext]);

  // Validation for context fetcher inputs
  useEffect(() => {
    let additionalProductsValidState = false;
    const checkAdditionalProductsValidity = (
      additionalProducts: { productId: string; productType: string; id: string }[]
    ) => {
      if (additionalProducts.length === 0) {
        return false;
      }
      const isMissingAdditionalProductValue = additionalProducts.some(
        (productItem) => !productItem.productId || !productItem.productType
      );
      return !isMissingAdditionalProductValue;
    };

    if (isAdditionalProductsContextFetcherSelected) {
      additionalProductsValidState = checkAdditionalProductsValidity(studioSettings.additionalProducts);
      setIsAdditionalProductStylesValid(additionalProductsValidState);
    }

    const isFormMissingRequiredInput =
      (isCourseContextFetcherSelected && !studioSettings.courseId) ||
      (isS12nContextFetcherSelected && !studioSettings.specializationId) ||
      (isAdditionalProductsContextFetcherSelected && !additionalProductsValidState);
    setIsSettingsFormValid(!isFormMissingRequiredInput);
  }, [
    setIsSettingsFormValid,
    isCourseContextFetcherSelected,
    isS12nContextFetcherSelected,
    studioSettings.courseId,
    studioSettings.specializationId,
    studioSettings.additionalProducts,
    selectedContextFetcherGroups,
    isAdditionalProductsContextFetcherSelected,
  ]);

  const getAdditionalContextFromPrompt = (prompt: string) => {
    // Find all context keys in the system prompt that are wrapped in ${additionalContext.KEY}, e.g.${additionalContext.roleData}
    // Return an array of all additional context items, e.g. [{contextKey: 'additionalContext.existingContextKey', contextValue: 'existingContextValue'}, {contextKey: 'additionalContext.roleData', contextValue: ''}]
    let match = regex.exec(prompt);
    const matches: AiCoachAdditionalContextInput[] = [];

    while (match !== null) {
      const matchedContextKey = match[1];
      const existingContextObject = studioSettings.additionalContext.find(
        (context) => context.contextKey === matchedContextKey
      );
      const defaultContextValue = existingContextObject?.contextValue || '';
      const isDuplicate = matches.some((item) => item.contextKey === matchedContextKey);
      if (!isDuplicate) {
        matches.push({ contextKey: matchedContextKey, contextValue: defaultContextValue });
      }
      match = regex.exec(prompt);
    }

    return matches;
  };

  const handleContextChange = (index: number, key: string, value: string) => {
    setStudioSettings((prev) => {
      const newAdditionalContext = [...prev.additionalContext];
      newAdditionalContext[index] = { contextKey: key, contextValue: value };
      return { ...prev, additionalContext: newAdditionalContext };
    });
  };

  const handleContextFetcherChange = (values: Iterable<string | number>) => {
    setStudioSettings((prev) => {
      return { ...prev, contextFetchers: Array.from(values) as Array<string> };
    });
  };

  const handleAdditionalProductsChange = (index: number, productIdValue?: string, productTypeValue?: string) => {
    setStudioSettings((prev) => {
      const newAdditionalProducts = [...prev.additionalProducts];
      newAdditionalProducts[index] = {
        ...newAdditionalProducts[index],
        ...(productIdValue ? { productId: productIdValue } : {}),
        ...(productTypeValue ? { productType: productTypeValue } : {}),
      };
      return { ...prev, additionalProducts: newAdditionalProducts };
    });
  };

  return (
    <div
      css={styles.container}
      aria-label={_t('#{coachStudio} container', { coachStudio: 'Coach Studio' })}
      data-testid="coach-studio-container"
    >
      {/* Model */}
      <SelectField
        label={_t('Model')}
        value={studioSettings.model}
        onChange={(e) => setStudioSettings((prev) => ({ ...prev, model: e.target.value as string }))}
        fullWidth
        renderLabel={(labelComponent: React.ReactElement) => (
          <ContextualHelp
            helpToggleProps={{
              'aria-label': _t('Information about model'),
            }}
            label={labelComponent}
          >
            <Typography2 component="p">{_t('Model used to generate response')}</Typography2>
          </ContextualHelp>
        )}
      >
        {COACH_STUDIO_MODELS.map((model) => (
          <SelectOption value={model} key={model}>
            {model}
          </SelectOption>
        ))}
      </SelectField>

      {/* System prompt */}
      <TextField
        multiline
        maxRows={8}
        label={_t('System prompt')}
        supportText={_t(
          `You can add additional context variables inline using this format \${additionalContext.exampleKey}`
        )}
        value={studioSettings.systemPrompt}
        onChange={(e) => {
          setStudioSettings((prev) => ({ ...prev, systemPrompt: e.target.value }));
          const additionalContextFromPrompt = getAdditionalContextFromPrompt(e.target.value);
          setStudioSettings((prev) => ({ ...prev, additionalContext: additionalContextFromPrompt as [] }));
        }}
        data-testid="system-prompt"
        renderLabel={(labelComponent: React.ReactElement) => (
          <ContextualHelp
            helpToggleProps={{
              'aria-label': _t('Information about system prompt'),
            }}
            label={labelComponent}
          >
            <Typography2 component="p">{_t('Prompt used to generate response')}</Typography2>
          </ContextualHelp>
        )}
      />

      {/* Page context */}
      <TextField
        multiline
        minRows={1}
        maxRows={8}
        label={_t('Page context')}
        placeholder={_t(
          'Copy and paste content from the page you would like to provide as context for #{coachStudio}',
          { coachStudio: 'Coach Studio' }
        )}
        supportText={_t('Please ensure you screen any content you copy')}
        value={studioSettings.pageContext}
        onChange={(e) => {
          setStudioSettings((prev) => ({ ...prev, pageContext: e.target.value }));
        }}
        renderLabel={(labelComponent: React.ReactElement) => (
          <ContextualHelp
            helpToggleProps={{
              'aria-label': _t('Information about page context'),
            }}
            label={labelComponent}
          >
            <Typography2 component="p">
              {_t('Content from the current page that will be included in the prompt as page context')}
            </Typography2>
          </ContextualHelp>
        )}
      />

      {/* Additional context */}

      <div css={styles.column}>
        {studioSettings.additionalContext.length >= 1 && (
          <FormLabel
            css={styles.label}
            renderLabel={(labelComponent: React.ReactElement) => (
              <ContextualHelp
                helpToggleProps={{
                  'aria-label': _t('Information about additional context'),
                }}
                label={labelComponent}
              >
                <Typography2 component="p">
                  {_t('Additional context values that correspond to any variables added in the prompt')}
                </Typography2>
              </ContextualHelp>
            )}
          >
            {_t('Additional context')}
          </FormLabel>
        )}
        <div css={styles.additionalContext}>
          {studioSettings.additionalContext.map(
            (additionalContextItem: AiCoachAdditionalContextInput, index: number) => {
              const { contextKey, contextValue } = additionalContextItem;
              return (
                <AdditionalContextRow
                  key={contextKey}
                  contextKey={contextKey}
                  contextValue={contextValue}
                  index={index}
                  handleContextChange={handleContextChange}
                />
              );
            }
          )}
        </div>
      </div>

      {/* Context fetchers */}
      <div css={styles.column}>
        <FormLabel
          css={styles.label}
          renderLabel={(labelComponent: React.ReactElement) => (
            <ContextualHelp
              helpToggleProps={{
                'aria-label': _t('Information about context fetchers'),
              }}
              label={labelComponent}
            >
              <Typography2 component="p">
                {_t(
                  'Common and use-case-specific prompt placeholders that are automatically populated during response generation'
                )}
              </Typography2>
            </ContextualHelp>
          )}
        >
          {_t('Context fetchers')}
        </FormLabel>
        <ContextFetcherList
          contextFetchers={AVAILABLE_CONTEXT_FETCHERS}
          onChange={handleContextFetcherChange}
          value={studioSettings.contextFetchers}
        />
      </div>

      <div css={styles.contextFetcherInputs}>
        {isCourseContextFetcherSelected && (
          <TextField
            /* eslint-disable no-restricted-syntax */
            label={_t('Course ID')}
            placeholder={_t('Example course ID')}
            value={studioSettings.courseId}
            /* eslint-enable no-restricted-syntax */
            onChange={(e) => setStudioSettings((prev) => ({ ...prev, courseId: e.target.value as string }))}
            validationStatus={!studioSettings.courseId ? 'error' : 'success'}
            renderLabel={(labelComponent: React.ReactElement) => (
              <ContextualHelp
                helpToggleProps={{
                  'aria-label': _t('Information about course ID'),
                }}
                label={labelComponent}
              >
                <Typography2 component="p">{_t('ID related to course context fetcher(s)')}</Typography2>
              </ContextualHelp>
            )}
          />
        )}

        {isS12nContextFetcherSelected && (
          <TextField
            label={_t('Specialization ID')}
            placeholder={_t('Example specialization ID')}
            value={studioSettings.specializationId}
            onChange={(e) => setStudioSettings((prev) => ({ ...prev, specializationId: e.target.value as string }))}
            validationStatus={!studioSettings.specializationId ? 'error' : 'success'}
            renderLabel={(labelComponent: React.ReactElement) => (
              <ContextualHelp
                helpToggleProps={{
                  'aria-label': _t('Information about specialization ID'),
                }}
                label={labelComponent}
              >
                <Typography2 component="p">{_t('ID related to specialization context fetcher(s)')}</Typography2>
              </ContextualHelp>
            )}
          />
        )}

        {selectedContextFetcherGroups.includes(ContextGroup.ADDITIONAL_PRODUCTS) && (
          <div css={styles.additionalProductSection}>
            <FormLabel
              css={styles.additionalProductLabel(isAdditionalProductStylesValid)}
              renderLabel={(labelComponent: React.ReactElement) => (
                <ContextualHelp
                  helpToggleProps={{
                    'aria-label': _t('Information about additional products'),
                  }}
                  label={labelComponent}
                >
                  <Typography2 component="p">{_t('Additional product metadata to include in the prompt')}</Typography2>
                </ContextualHelp>
              )}
            >
              {_t('Additional Products')}
            </FormLabel>
            {studioSettings.additionalProducts.map((_, index) => (
              <AdditionalProductRow
                key={studioSettings.additionalProducts[index].id}
                index={index}
                handleAdditionalProductsChange={handleAdditionalProductsChange}
                deleteRow={() => {
                  setStudioSettings((prev) => ({
                    ...prev,
                    additionalProducts: prev.additionalProducts.filter((__, i) => i !== index),
                  }));
                }}
                productId={studioSettings.additionalProducts[index].productId}
                productType={studioSettings.additionalProducts[index].productType}
              />
            ))}

            <CoachIconButton
              onClick={() => {
                const id = uuid();
                setStudioSettings((prev) => ({
                  ...prev,
                  additionalProducts: [...prev.additionalProducts, { productId: '', productType: '', id }],
                }));
              }}
              tooltip={_t('Add additional product')}
              aria-label={_t('Add additional product')}
              icon={<AddIcon />}
              data-testid="add-additional-product"
            />
          </div>
        )}
      </div>

      {/* Model parameters */}

      <Accordion variant="standard">
        <AccordionHeader label={_t('Model parameters')} />

        <AccordionPanel>
          <FormLabel
            renderLabel={(labelComponent: React.ReactElement) => (
              <ContextualHelp
                helpToggleProps={{
                  'aria-label': _t('Information about chat history limit'),
                }}
                label={labelComponent}
              >
                <Typography2 component="p">
                  {_t('The max number of chat history messages included in the prompt')}
                </Typography2>
              </ContextualHelp>
            )}
          >
            {_t('Chat history limit')}
          </FormLabel>
          <MuiSlider
            value={studioSettings.modelParameters.chatHistoryLimit}
            min={10}
            max={100}
            step={1}
            marks={[
              {
                value: 10,
                label: '10',
              },
              {
                value: 100,
                label: '100',
              },
            ]}
            css={styles.slider}
            aria-label={_t('Chat history limit')}
            valueLabelDisplay="auto"
            onChange={(e, value) =>
              setStudioSettings((prev) => ({
                ...prev,
                modelParameters: {
                  ...prev.modelParameters,
                  chatHistoryLimit: value as number,
                },
              }))
            }
          />

          <FormLabel
            renderLabel={(labelComponent: React.ReactElement) => (
              <ContextualHelp
                helpToggleProps={{
                  'aria-label': _t('Information about chat history token limit'),
                }}
                label={labelComponent}
              >
                <Typography2 component="p">
                  {_t('The max token of chat history messages included in the prompt')}
                </Typography2>
              </ContextualHelp>
            )}
          >
            {_t('Chat history token limit')}
          </FormLabel>
          <MuiSlider
            value={studioSettings.modelParameters.chatHistoryTokenLimit}
            min={500}
            max={5000}
            step={100}
            marks={[
              {
                value: 500,
                label: '500',
              },
              {
                value: 5000,
                label: '5K',
              },
            ]}
            css={styles.slider}
            aria-label={_t('Chat history token limit')}
            valueLabelDisplay="auto"
            onChange={(e, value) =>
              setStudioSettings((prev) => ({
                ...prev,
                modelParameters: {
                  ...prev.modelParameters,
                  chatHistoryTokenLimit: value as number,
                },
              }))
            }
          />

          <FormLabel
            renderLabel={(labelComponent: React.ReactElement) => (
              <ContextualHelp
                helpToggleProps={{
                  'aria-label': _t('Information about total token limit'),
                }}
                label={labelComponent}
              >
                <Typography2 component="p">
                  {_t('The max token of the prompt, including system prompt template, context, and chat history')}
                </Typography2>
              </ContextualHelp>
            )}
          >
            {_t('Total token limit')}
          </FormLabel>
          <MuiSlider
            value={studioSettings.modelParameters.totalTokenLimit}
            min={4000}
            max={64000}
            step={1000}
            marks={[
              {
                value: 4000,
                label: '4000',
              },
              {
                value: 64000,
                label: '64K',
              },
            ]}
            css={styles.slider}
            aria-label={_t('Total token limit')}
            valueLabelDisplay="auto"
            onChange={(e, value) =>
              setStudioSettings((prev) => ({
                ...prev,
                modelParameters: {
                  ...prev.modelParameters,
                  totalTokenLimit: value as number,
                },
              }))
            }
          />
        </AccordionPanel>
      </Accordion>
    </div>
  );
};

export default CoachStudioSettings;
