/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Typography } from '@coursera/cds-core';
import { SpinnerIcon } from '@coursera/cds-icons';

import _t from 'i18n!nls/program-home';

const overlay = css`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 12000;
  background-color: rgb(192 192 192 / 70%);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const container = css`
  background-color: white;
  padding: 24px 36px;
  display: flex;
  gap: 12px;
  align-items: center;
`;

const RedirectOverlay: React.FC = () => {
  return (
    <div css={overlay}>
      <div css={container}>
        <SpinnerIcon />
        <Typography component="span" variant="h1" color="supportText">
          {_t('Redirecting')}
        </Typography>
      </div>
    </div>
  );
};

export default RedirectOverlay;
