/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EnterpriseBadgeCollectionsQuery
// ====================================================


export enum EnterpriseBadge_productType {
  VERIFIED_CERTIFICATE = "VERIFIED_CERTIFICATE",
}

export enum EnterpriseBadge_userState {
  ENROLLED = "ENROLLED",
  ISSUED = "ISSUED",
  NEVER_ENROLLED = "NEVER_ENROLLED",
  UNENROLLED = "UNENROLLED",
}

export interface EnterpriseBadgeCollectionsQuery_EnterpriseBadge_findByUserAndProgram_badgeCollectionsWithUserState_productIds {
  __typename: "EnterpriseBadge_productId";
  id: string;
  type: EnterpriseBadge_productType;
}

export interface EnterpriseBadgeCollectionsQuery_EnterpriseBadge_findByUserAndProgram_badgeCollectionsWithUserState {
  __typename: "EnterpriseBadge_badgeCollectionWithUserState";
  /**
   *  Title of the badge 
   */
  title: string;
  /**
   *  Id of the curriculum collection 
   */
  collectionId: string;
  /**
   *  Id of the badge 
   */
  badgeTemplateId: string;
  /**
   *  Description of the badge 
   */
  description: string | null;
  /**
   *  Set of learning objectives for the badge  
   */
  learningObjectives: string[];
  /**
   *  Url for the badge logo image 
   */
  imageUrl: string;
  /**
   *  State that the user is in 
   */
  userState: EnterpriseBadge_userState;
  /**
   *  List of products that require completion to attain the badge 
   */
  productIds: EnterpriseBadgeCollectionsQuery_EnterpriseBadge_findByUserAndProgram_badgeCollectionsWithUserState_productIds[];
}

export interface EnterpriseBadgeCollectionsQuery_EnterpriseBadge_findByUserAndProgram {
  __typename: "EnterpriseBadge_paginationBadgeCollectionWithUserState";
  badgeCollectionsWithUserState: EnterpriseBadgeCollectionsQuery_EnterpriseBadge_findByUserAndProgram_badgeCollectionsWithUserState[];
}

export interface EnterpriseBadgeCollectionsQuery_EnterpriseBadge {
  __typename: "EnterpriseBadgeQueries";
  /**
   * This query returns all enterprise badge collections for a program with user states.
   */
  findByUserAndProgram: EnterpriseBadgeCollectionsQuery_EnterpriseBadge_findByUserAndProgram;
}

export interface EnterpriseBadgeCollectionsQuery {
  EnterpriseBadge: EnterpriseBadgeCollectionsQuery_EnterpriseBadge | null;
}

export interface EnterpriseBadgeCollectionsQueryVariables {
  userId: string;
  programId: string;
}
