/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import CodeblockWithReadOnlyHandler from 'bundles/author-widget/components/CodeblockWithReadOnlyHandler';
import type { AuthoringWidgetSessionProvider } from 'bundles/naptimejs/resources/__generated__/AuthoringWidgetSessionProvidersV1';

import _t from 'i18n!nls/author-widget';

// On widget creation we don't have a config available, so we fallback to the default.
export const DEFAULT_CONFIG = {
  url: 'https://www.example.com',
};

const styles = {
  root: css`
    background-color: #f5f5f5;
    padding: 1rem !important;
  `,
  text: css`
    margin-bottom: 1rem !important;
  `,
};

type PropsFromCaller = {
  readOnly: boolean;
  hasCustomLabel?: boolean;
  className?: string;
  handleChange?: (value: AuthoringWidgetSessionProvider['configuration']) => void;
  widgetConfig: AuthoringWidgetSessionProvider['configuration'];
};

type Props = PropsFromCaller;

export const ConfigurationEditorView = ({
  readOnly = false,
  hasCustomLabel,
  className,
  handleChange,
  widgetConfig = DEFAULT_CONFIG,
}: Props) => {
  return (
    <div className={className} css={styles.root}>
      {!hasCustomLabel && (
        <div css={styles.text}>
          {_t(
            "Edit this configuration JSON to set up your Plugin Item. Preview it to ensure it's working properly, then save the configuration to create a publishable Item."
          )}
        </div>
      )}

      <CodeblockWithReadOnlyHandler
        key={widgetConfig}
        codeLanguage="json"
        expression={JSON.stringify(widgetConfig, null, '\t')}
        onChange={handleChange}
        readOnly={readOnly}
      />
    </div>
  );
};
