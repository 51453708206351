import { Editor, Node, Transforms } from 'slate';

import {
  BLOCK_DELIMS,
  INLINE_DELIMS,
  LATEX_BLOCK_REGEXP,
  LATEX_INLINE_REGEXP,
} from 'bundles/cml/editor/components/elements/math/constants';
import type { Delims, Formula } from 'bundles/cml/editor/components/elements/math/types';
import { getAncestorOfType, insertBlockNodes } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { MathBlockElement, MathInlineElement } from 'bundles/cml/shared/types/elementTypes';

export const parseFormula = (raw: string, isBlock = false): Formula => {
  const matches = raw.match(LATEX_INLINE_REGEXP) || raw.match(LATEX_BLOCK_REGEXP);
  if (matches) {
    const delims: Delims = [matches[1], matches[4]];
    const value = matches[2];
    return { raw, delims, value };
  }

  const delims = isBlock ? BLOCK_DELIMS : INLINE_DELIMS;
  const value = raw;

  return {
    raw: `${delims[0]}${value}${delims[1]}`,
    delims,
    value,
  };
};

const isBlockFormula = (formula: string) => LATEX_BLOCK_REGEXP.test(formula);

const isMathBlock = (editor: Editor) => {
  const entry = getAncestorOfType(editor, [BLOCK_TYPES.TEXT, BLOCK_TYPES.HEADING]);
  if (!entry) {
    return false;
  }

  const [element, path] = entry;
  return path.length === 1 && !Node.string(element);
};

const createMathBlockElement = (formula: string): MathBlockElement => {
  return {
    type: BLOCK_TYPES.MATH_BLOCK,
    isVoid: true,
    formula,
    editMathDialog: true,
    children: [{ text: '' }],
  };
};

const createMathInlineElement = (formula: string): MathInlineElement => {
  return {
    type: BLOCK_TYPES.MATH_INLINE,
    isVoid: true,
    isInline: true,
    formula,
    editMathDialog: true,
    children: [{ text: '' }],
  };
};

const getFormulaFromSelection = (editor: Editor): string => {
  const text = editor.selection ? Editor.string(editor, editor.selection) : '';
  return text ? parseFormula(text).raw : '';
};

export const insertMath = (editor: Editor) => {
  const formula = getFormulaFromSelection(editor);
  if (isBlockFormula(formula)) {
    Transforms.insertNodes(editor, createMathBlockElement(formula));
    return;
  }

  if (isMathBlock(editor)) {
    insertBlockNodes(editor, createMathBlockElement(formula));
    return;
  }

  Transforms.insertNodes(editor, createMathInlineElement(formula));
};
