import Q from 'q';

import logger from 'js/app/loggerSingleton';
import user from 'js/lib/user';

import { UserPreferenceKeys } from 'bundles/account-settings/constants/UserPreferences';
import handleResponse from 'bundles/naptime/handleResponse';
import API from 'bundles/phoenix/lib/apiWrapper';

const makeKeyForUser = (key: $TSFixMe) => user.get().id + '~' + key;

export const apiClient = API('/api/userPreferences.v1', { type: 'rest' });

/**
 * @param  {String} key Should be defined in userPreferenceKeys
 * @return {Promise}
 */
export const getUserPreference = (key: UserPreferenceKeys): Q.Promise<$TSFixMe> => {
  if (!Object.values(UserPreferenceKeys).includes(key)) {
    throw new Error('Invalid user preference key: ' + key);
  }

  return Q(apiClient.get(makeKeyForUser(key)))
    .then(handleResponse)
    .then(({ elements }) => {
      const element = elements && elements[0];
      const definition = element && element.preference && element.preference.definition;
      return definition;
    });
};

/**
 * @param  {String} key Should be defined in userPreferenceKeys
 * @param  {Object} definition
 * @return {Promise}
 */
export const setUserPreference = (key: UserPreferenceKeys, definition: $TSFixMe) => {
  if (!Object.values(UserPreferenceKeys).includes(key)) {
    throw new Error('Invalid user preference key: ' + key);
  }

  return Q(
    apiClient.put(makeKeyForUser(key), {
      data: {
        preference: {
          typeName: key,
          definition,
        },
      },
    })
  ).fail((err) => {
    logger.error('Could not PUT user preferences for preference key ' + key);
  });
};
