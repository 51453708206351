import * as React from 'react';

import { snakeCase } from 'lodash';

import { Button, Chip, ChipGroup, VisuallyHidden } from '@coursera/cds-core';
import type { ChipProps } from '@coursera/cds-core';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import TrackedButton from 'bundles/page/components/TrackedButton';
import { FILTERS_CONFIG } from 'bundles/search-common/components/filters/getFiltersList';
import { getSearchFilterOptionItemTranslations } from 'bundles/search-common/utils/getFilterItemsTranslations';

import _t from 'i18n!nls/enterprise-collections';

const TrackedChip = withSingleTracked({ type: 'BUTTON' })<ChipProps>(Chip);

type Props = { currentFilters: string[]; removeFilters: (x0: string) => void; clearFilters?: () => void };

export class CurrentAppliedFilters extends React.Component<Props> {
  state = {
    hasSelectedClearAll: false,
  };

  clear = () => {
    this.setState({ hasSelectedClearAll: true });
    this.props.clearFilters?.();
  };

  render() {
    const { currentFilters, removeFilters } = this.props;
    const { hasSelectedClearAll } = this.state;
    const ariaClearFiltersLabel = _t('All filter options have been cleared');
    const currentAriaLabelForFilters = hasSelectedClearAll ? ariaClearFiltersLabel : '';
    if (hasSelectedClearAll && currentFilters.length) this.setState({ hasSelectedClearAll: false });

    return (
      <div>
        {currentFilters.length > 0 && (
          <div>
            <ChipGroup label="">
              {currentFilters.map((item) => {
                const [category, label] = item.split(':');
                const optionItemTranslations = getSearchFilterOptionItemTranslations()[category];
                const translatedItem = optionItemTranslations?.[label];
                const translatedLabel = typeof translatedItem !== 'string' ? translatedItem?.title : translatedItem;
                const translatedCategoryName = FILTERS_CONFIG[category]?.getName();
                const itemDisplayValue = `${translatedCategoryName}: ${translatedLabel || label}`;
                return (
                  <TrackedChip
                    key={itemDisplayValue}
                    trackingName={snakeCase(`filter_${category}_unchecked`)}
                    trackingData={{ label: itemDisplayValue }}
                    aria-label={_t('Clear #{filterItem} filter', { filterItem: itemDisplayValue })}
                    onClick={() => removeFilters(item)}
                    variant="delete"
                  >
                    {itemDisplayValue}
                  </TrackedChip>
                );
              })}
            </ChipGroup>
            <div style={{ marginLeft: '-8px' }}>
              <Button
                data-test="clear-all-button"
                trackingName="clear_all_filters_button"
                withVisibilityTracking={false}
                requireFullyVisible={false}
                component={TrackedButton}
                variant="ghost"
                onClick={this.clear}
                aria-label={_t('Clear all filters')}
              >
                {_t('Clear all')}
              </Button>
            </div>
          </div>
        )}
        <VisuallyHidden component="span" className="rc-A11yScreenReaderOnly">
          <div role="alert" aria-live="polite">
            {currentAriaLabelForFilters}
          </div>
        </VisuallyHidden>
      </div>
    );
  }
}

export default CurrentAppliedFilters;
