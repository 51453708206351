import type { UserContextType } from 'bundles/unified-description-page-common/contexts/UserContext';

const hideEnterpriseUpsell = (userData?: UserContextType) => {
  return Boolean(
    userData?.loadingThirdPartyOrganizations ||
      userData?.thirdPartyOrganizations?.some((org) => typeof org?.organizationType === 'string')
  );
};

export default hideEnterpriseUpsell;
