import * as React from 'react';
import { useState } from 'react';

import EnrollmentChoiceTypes from 'bundles/enroll-course/common/EnrollmentChoiceTypes';
import {
  choiceTypeToHandleSubmitPromise,
  submitEnrollmentPromise,
} from 'bundles/enroll-course/lib/enrollmentChoiceUtils';
import EnrollErrorModal from 'bundles/enroll/components/common/EnrollErrorModal';
import PromotionApplicableCheckoutMessage from 'bundles/enroll/components/common/PromotionApplicableCheckoutMessage';
import { useCourseEnrollModalData } from 'bundles/enroll/data/usePageData';
import { EnrollModalType } from 'bundles/enroll/types/modalTypes';
import type { ApiError } from 'bundles/enroll/utils/errorUtils';
import { useEnrollModalEventing } from 'bundles/enroll/utils/eventingUtils';
import Icon from 'bundles/iconfont/Icon';
import TrackedButton from 'bundles/page/components/TrackedButton';
import ReactPriceDisplay from 'bundles/payments-common/components/ReactPriceDisplay';
import Modal from 'bundles/phoenix/components/Modal';

import _t from 'i18n!nls/enroll-course';

import 'css!./__styles__/ClosedCourseEnrollModal';

const getBulletPoints = () => [
  _t('Access to course materials, including videos, readings, and discussion forums'),
  _t('Access to graded assignments'),
  _t('Final grade at the end of the course'),
  _t('Shareable Course Certificate'),
];

type PropsFromCaller = {
  onClose: () => void;
};

const ClosedCourseEnrollModal: React.FC<PropsFromCaller> = ({ onClose }) => {
  const [didClickContinue, setDidClickContinue] = useState<boolean>(false);
  const [error, setError] = useState<ApiError | undefined>(undefined);
  const [activeModal, setActiveModal] = useState<EnrollModalType>(EnrollModalType.ENROLL);

  const { promotionEligibilities, course, productPrice } = useCourseEnrollModalData();

  const { getEnrollModalTrackingRef, trackEnrollModalContinue, trackEnrollModalClose } = useEnrollModalEventing();
  const visibilityTrackingRef = getEnrollModalTrackingRef();
  const enrollmentChoiceType = EnrollmentChoiceTypes.PURCHASE_SINGLE_COURSE;

  const handleClose = () => {
    trackEnrollModalClose();
    onClose();
  };

  const handleContinue = () => {
    trackEnrollModalContinue(enrollmentChoiceType);
    setDidClickContinue(true);

    const promoCode = promotionEligibilities?.isEligible ? promotionEligibilities.promoCodeId : undefined;
    const options = { courseId: course.id };

    const handleSubmitPromise = choiceTypeToHandleSubmitPromise[enrollmentChoiceType];
    submitEnrollmentPromise({ handleSubmitPromise, options, promoCode }).catch((apiError: ApiError) => {
      setActiveModal(EnrollModalType.ERROR);
      setError(apiError);
    });
  };

  switch (activeModal) {
    case EnrollModalType.ERROR:
      return <EnrollErrorModal error={error} onClose={handleClose} />;
    case EnrollModalType.ENROLL:
      return (
        <Modal
          className="rc-ClosedCourseEnrollModal"
          modalName="ClosedCourseEnrollModal"
          handleClose={handleClose}
          trackingName="closed_course_enroll_modal"
        >
          <div className="color-primary-text align-horizontal-center modal-container" ref={visibilityTrackingRef}>
            <div className="headline-4-text">{course.name}</div>
            <div className="headline-6-text">
              {productPrice && (
                <ReactPriceDisplay value={productPrice.finalAmount} currency={productPrice.currencyCode} />
              )}
            </div>
            <PromotionApplicableCheckoutMessage course={course} />
            <div className="body">
              <p className="body-2-text">{_t('Your course fee includes:')}</p>
              {getBulletPoints().map((bulletPoint) => (
                <div className="bullet-point horizontal-box" key={bulletPoint}>
                  <Icon name="check-list" className="color-primary" />
                  <span className="body-1-text text">{bulletPoint}</span>
                </div>
              ))}
            </div>
            <TrackedButton
              className="primary continue-button comfy"
              onClick={handleContinue}
              disabled={didClickContinue}
              trackingName="closed_course_enroll_modal_continue_button"
            >
              {didClickContinue ? <Icon name="spinner" spin={true} /> : _t('Continue to enroll')}
            </TrackedButton>
          </div>
        </Modal>
      );
    default:
      return null;
  }
};

export default ClosedCourseEnrollModal;
