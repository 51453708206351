/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';

import { CheckIcon, WindowsIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import type { LinkElement } from 'bundles/cml/shared/types/elementTypes';

import _t from 'i18n!nls/cml';

type Props = {
  link: LinkElement;
};

const styles = {
  copied: css`
    display: flex;
    align-items: center;
    column-gap: 4px;
  `,
};

const CopyLinkButton: React.FC<Props> = ({ link }) => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => setIsCopied(false), 1000);
    }
  }, [isCopied]);

  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(link.href);
    setIsCopied(true);
  }, [link]);

  const copied = (
    <div css={styles.copied}>
      {_t('Copied!')} <CheckIcon size="small" color="success" />
    </div>
  );

  return (
    <Button
      role="menuitem"
      onClick={handleCopyLink}
      title={_t('Copy link')}
      type={TOOLBAR_BUTTON_TYPES.menu}
      tooltipProps={{ trigger: isCopied ? 'controlled' : 'hover', message: isCopied ? copied : _t('Copy link') }}
    >
      <WindowsIcon size="small" />
    </Button>
  );
};

export default CopyLinkButton;
