import * as React from 'react';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import redirect from 'js/lib/coursera.redirect';

import { useS12nEnrollModalData } from 'bundles/enroll/data/usePageData';
import Modal from 'bundles/phoenix/components/Modal';
import S12nEnrollModalPaymentChoices from 'bundles/s12n-enroll/components/bulk-pay/PaymentChoices';
import TrackedDiv from 'bundles/page/components/TrackedDiv';

import _t from 'i18n!nls/s12n-enroll';

import 'css!./__styles__/S12nBulkPayEnrollModal';

export type PropsFromCaller = {
  onClose: () => void;
  isContentGate?: boolean;
  onSdp?: boolean;
  // Callback for free enroll
  onFreeEnroll?: (choiceType?: string) => void;
  onEnrollFailure?: () => void;
  onEnrollSuccess?: () => void;
};

const S12nBulkPayEnrollModal: React.FC<PropsFromCaller> = ({
  onClose,
  isContentGate = false,
  onSdp = false,
  onFreeEnroll,
  onEnrollFailure = () => undefined,
  onEnrollSuccess,
}) => {
  const { course, s12n, enrollmentAvailableChoices } = useS12nEnrollModalData();

  const handleFreeEnroll = () => {
    if (onFreeEnroll) {
      onFreeEnroll();
      onClose();
    } else {
      redirect.refresh();
    }
  };

  const getModalName = () => {
    if (isContentGate) {
      return _t('Join to continue');
    } else if (enrollmentAvailableChoices?.isEnrolled) {
      return _t('Continue this Specialization');
    } else {
      return _t('Join this Specialization');
    }
  };

  const renderModalBody = () => {
    if (enrollmentAvailableChoices?.hasChoice) {
      const title = onSdp ? s12n.name : course.name;
      return (
        <S12nEnrollModalPaymentChoices
          s12nId={s12n.id}
          courseId={course.id}
          title={title}
          onFreeEnroll={handleFreeEnroll}
          onEnrollFailure={onEnrollFailure}
          onEnrollSuccess={onEnrollSuccess}
          onSdp={onSdp}
        />
      );
    } else {
      const humanReadableReasonCode = enrollmentAvailableChoices
        ? enrollmentAvailableChoices.humanReadableReasonCode
        : _t("Sorry, we couldn't identify any available enrollment choice for this Specialization at this time.");
      return <TrackedDiv trackingName="s12n_enroll_modal_no_choices_message" withVisibilityTracking><p className="no-enrollment-option">{humanReadableReasonCode}</p></TrackedDiv>;
    }
  };

  return (
    <div className="rc-S12nBulkPayEnrollModal">
      <CSSTransitionGroup transitionName="fade" transitionEnter={false} transitionLeaveTimeout={300}>
        <Modal
          trackingName="s12n_enroll_modal"
          data={{ id: s12n.id }}
          key="S12nBulkPayEnrollModal"
          modalName={getModalName()}
          handleClose={onClose}
          allowClose={true}
        >
          {renderModalBody()}
        </Modal>
      </CSSTransitionGroup>
    </div>
  );
};

export default S12nBulkPayEnrollModal;
