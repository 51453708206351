import _t from 'i18n!nls/skills-common';

type PopoverContentMessages = {
  simpleDescription: string;
  fullDescription: string;
};

export const generatePersonalCopy = (): PopoverContentMessages => ({
  simpleDescription: _t('My score for this skill'),
  fullDescription: _t('Skill scores are calculated based on performance on graded assignments'),
});

export const generateBenchmarkCopyForSkillScoring = (score: number): PopoverContentMessages => {
  const simpleDescription = _t('Target score for this skill');
  let fullDescription = _t(
    'This is your organization’s skill development goal for your role. At a conversant level, you’ll have familiarity with the vocabulary and will be able to pass easy content.'
  );

  if (score > 350) {
    fullDescription = _t(
      'This is your organization’s skill development goal for your role. At an advanced level, you’ll have a mastery of the material and will be able to pass advanced content. You will be able to both teach others and identify novel applications of this skill.'
    );
  } else if (score > 150) {
    fullDescription = _t(
      'This is your organization’s skill development goal for your role. At an intermediate level, you’ll have a solid understanding of the material and will be able to pass intermediate content. You will be able to apply key concepts on most tasks.'
    );
  } else if (score > 50) {
    fullDescription = _t(
      'This is your organization’s skill development goal for your role. At a beginner level, you’ll have a working knowledge and will be able to pass beginner content. You have limited experience applying it.'
    );
  }

  return {
    simpleDescription,
    fullDescription,
  };
};

export const generateMyScorePercentileCopy = (percentile: number, occupationName: string): PopoverContentMessages => {
  const occupation = occupationName.replace(' (General)', '') || _t('Learner');

  return {
    simpleDescription: _t('Your Score'),
    fullDescription: _t('Well done! You’re in the <b>top #{percentile}%</b> of #{occupation}s on Coursera.', {
      percentile,
      occupation,
    }),
  };
};

export const generateMyScoreScoreCopy = (score: number): PopoverContentMessages => {
  const simpleDescription = _t('Your Score');
  let fullDescription = _t(
    'At a conversant level, you have familiarity with the vocabulary and are able to pass easy content.'
  );

  if (score > 350) {
    fullDescription = _t(
      'At an advanced level, you have a mastery of the material and are able to pass advanced content. You can both teach others and identify novel applications of this skill.'
    );
  } else if (score > 150) {
    fullDescription = _t(
      'At an intermediate level, you have a solid understanding of the material and are able to pass intermediate content. You can apply key concepts on the job.'
    );
  } else if (score > 50) {
    fullDescription = _t(
      'At a beginner level, you have a working knowledge and are able to pass beginner content, but limited experience applying it.'
    );
  }

  return {
    simpleDescription,
    fullDescription,
  };
};
