import * as React from 'react';
import Media from 'react-media';

import hoistNonReactStatics from 'js/lib/hoistNonReactStatics';

import type { BreakPoint } from '@coursera/coursera-ui';
import { breakPoint } from '@coursera/coursera-ui';

/**
 * A HOC to detect the device size and match up with breakPoint,
 * then pass it down to props
 * Sample usage:
 * <DomainSectionS12nList
 *    responsiveConfig={{
 *      xs: {initialS12nCount: 2},
 *      sm: {initialS12nCount: 2},
 *      md: {initialS12nCount: 4},
 *      lg: {initialS12nCount: 6},
 *      xl: {initialS12nCount: 8},
 *      xxl: {initialS12nCount: 8},
 *    }}
 *    ...
 *  />
 */

type Props = {
  showBreakPointNumber: boolean;
  breakPoint: BreakPoint;
};

function getComponentHtml<T extends Props>(Component: React.ComponentType<T>, props: T, breakPointName: BreakPoint) {
  return <Component {...props} breakPoint={breakPointName} />;
}

const withBreakPoint = function <P extends Props>(Component: React.ComponentType<P>) {
  const componentName = Component.displayName || Component.name;
  const HOC = (props: P) => {
    return (
      <Media query={{ minWidth: breakPoint.xxl }}>
        {(matchesXxl) => {
          if (matchesXxl) {
            return getComponentHtml(Component, props, 'xxl');
          }
          return (
            <Media query={{ minWidth: breakPoint.xl }}>
              {(matchesXl) => {
                if (matchesXl) {
                  return getComponentHtml(Component, props, 'xl');
                }
                return (
                  <Media query={{ minWidth: breakPoint.lg }}>
                    {(matchesLg) => {
                      if (matchesLg) {
                        return getComponentHtml(Component, props, 'lg');
                      }
                      return (
                        <Media query={{ minWidth: breakPoint.md }}>
                          {(matchesMd) => {
                            if (matchesMd) {
                              return getComponentHtml(Component, props, 'md');
                            }
                            return (
                              <Media query={{ minWidth: breakPoint.sm }}>
                                {(matchesSm) => {
                                  if (matchesSm) {
                                    return getComponentHtml(Component, props, 'sm');
                                  }
                                  return getComponentHtml(Component, props, 'xs');
                                }}
                              </Media>
                            );
                          }}
                        </Media>
                      );
                    }}
                  </Media>
                );
              }}
            </Media>
          );
        }}
      </Media>
    );
  };

  HOC.displayName = `withBreakPoint(${componentName})`;

  hoistNonReactStatics(HOC, Component);
  return HOC;
};

export default withBreakPoint;
