/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { TextField } from '@coursera/cds-core';

import type { AuthoringWidgetSessionProvider } from 'bundles/naptimejs/resources/__generated__/AuthoringWidgetSessionProvidersV1';
import { LabelComponent } from 'bundles/widget/components/config/ExternalUrlEditor';
import {
  convertStartToNumber,
  convertStartToString,
  getVideoId,
  isValidTimestamp,
  isValidYoutubeUrl,
} from 'bundles/widget/components/config/utils/configUtils';

import _t from 'i18n!nls/widget';

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    gap: var(--cds-spacing-300);
  `,
};

const YOUTUBE_URL = 'https://www.youtube.com/watch?v=';

type WidgetSessionDraftConfig = AuthoringWidgetSessionProvider['configuration'];

type Props = {
  readOnly?: boolean;
  config: WidgetSessionDraftConfig;
  onUrlChange: (val: string, isValid: boolean) => void;
  onStartChange: (val: string, isValid: boolean) => void;

  onConfigChange: (config: WidgetSessionDraftConfig) => void;
  urlError: boolean;
  startError: boolean;
};
export const YoutubeConfigTextFields: React.FC<Props> = ({
  config,
  onUrlChange,
  onStartChange,
  onConfigChange,
  urlError,
  startError,
  readOnly,
}) => {
  const handleUrlChange = (value: string) => {
    if (!isValidYoutubeUrl(value)) {
      onUrlChange(value, false);
      return;
    }
    onUrlChange(value, true);
    onConfigChange({
      ...config,
      videoId: getVideoId(value),
    });
  };

  const handleStartChange = (value: string) => {
    if (!isValidTimestamp(value)) {
      onStartChange(value, false);
      return;
    }
    onStartChange(value, true);
    onConfigChange({
      ...config,
      start: convertStartToNumber(value),
    });
  };

  return (
    <div css={styles.root}>
      <TextField
        fullWidth
        defaultValue={`${YOUTUBE_URL}${config.videoId}`}
        label={_t('Video URL')}
        supportText={_t(
          'The web address that appears in the browser when you view the video. To change the video, enter a new URL.'
        )}
        placeholder={`${YOUTUBE_URL}OnoNITE-CLc`}
        onChange={(e) => handleUrlChange(e.target.value)}
        renderLabel={(label: React.ReactElement) => <LabelComponent label={label} />}
        validationStatus={urlError ? 'error' : undefined}
        validationLabel={urlError ? _t('Invalid video URL') : undefined}
        readOnly={readOnly}
      />
      <TextField
        fullWidth
        defaultValue={convertStartToString(config.start || 0)}
        label={_t('Start Time')}
        supportText={_t('The time that you want the video to start playing.')}
        placeholder="mm:ss"
        onChange={(e) => handleStartChange(e.target.value)}
        validationStatus={startError ? 'error' : undefined}
        validationLabel={startError ? _t('Invalid start time') : undefined}
        readOnly={readOnly}
      />
    </div>
  );
};
