export default {
  'computer-science': [
    { name: 'Java', query: 'java', skillId: 'java-programming-language' },
    // RTL and LTR control mark for GR-24295
    {
      name: '\u200fC++\u200e',
      query: 'c%2B%2B',
      skillName: 'C Programming Language Family',
      skillId: 'c-programming-language-family',
    },
    { name: 'Javascript', query: 'javascript', skillId: 'javascript' },
    { name: 'Blockchain', query: 'blockchain', skillId: 'blockchains' },
    { name: 'Linux', query: 'linux' },
    { name: 'Agile', query: 'agile', skillId: 'agile-software-development' },
    { name: 'IOT', query: 'iot' },
    { name: 'Scala', query: 'scala' },
    { name: 'HTML', query: 'html', skillId: 'html-and-css' },
    { name: 'Data Structures', query: 'data%20structures%20and%20algorithms', skillId: 'data-structure' },
  ],
  'information-technology': [
    { name: 'CyberSecurity', query: 'cybersecurity', skillId: 'network-security' },
    { name: 'AWS', query: 'aws', skillId: 'amazon-web-services' },
    { name: 'Google Cloud', query: 'gcp', skillId: 'google-cloud-platform' },
    { name: 'SAP', query: 'sap' },
    { name: 'Google', query: 'google' },
  ],
  'data-science': [
    { name: 'Python', query: 'python', skillId: 'python-programming-language' },
    { name: 'Excel', query: 'microsoft%20excel', skillName: 'Spreadsheet Software', skillId: 'spreadsheet-software' },
    { name: 'SQL', query: 'sql', skillId: 'sql' },
    { name: 'R', query: 'r', skillId: 'r-programming-language' },
    { name: 'Tableau', query: 'tableau' },
    { name: 'Big Data', query: 'big%20data', skillId: 'big-data' },
    { name: 'TensorFlow', query: 'tensorflow' },
    { name: 'MATLAB', query: 'matlab' },
    { name: 'Deep learning', query: 'deep%20learning', skillId: 'deep-learning' },
    { name: 'Machine Learning', query: 'machine%20learning', skillId: 'machine-learning' },
    {
      name: 'Artificial Intelligence',
      query: 'artificial%20intelligence',
      skillName: 'Artificial Neural Networks',
      skillId: 'artificial-neural-network',
    },
    { name: 'Statistics', query: 'statistics', skillId: 'probability-and-statistics' },
    { name: 'IBM', query: 'ibm' },
  ],
} as { [domainId: string]: Array<{ name: string; query: string; skillName?: string; skillId?: string }> };
