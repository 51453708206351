/** @jsx jsx */
import { jsx } from '@emotion/react';
import type { Interpolation } from '@emotion/react';

import * as React from 'react';
import { useState } from 'react';

import type { CourseraTierSubscriptions_BillingCycle as BillingCycleType } from '__generated__/graphql-types';

import type { ButtonProps, Theme } from '@coursera/cds-core';
import { Button } from '@coursera/cds-core';
import type { ApiStatus } from '@coursera/coursera-ui';
import {
  API_BEFORE_SEND,
  API_ERROR,
  API_IN_PROGRESS,
  API_SUCCESS,
} from '@coursera/coursera-ui/lib/constants/sharedConstants';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import {
  removeRedirectUrlAfterUpgrade,
  saveRedirectUrlAfterUpgrade,
} from 'bundles/coursera-plus/utils/subscriptionTiersUtils';
import type { PropsFromWithCreateMidCycleUpgradeCartMutation } from 'bundles/coursera-plus/utils/withCreateMidCycleUpgradeCartMutation';
import withCreateMidCycleUpgradeCartMutation from 'bundles/coursera-plus/utils/withCreateMidCycleUpgradeCartMutation';
import { submitEnrollmentPromise } from 'bundles/enroll-course/lib/enrollmentChoiceUtils';

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps>(Button);

type PropsFromCaller = {
  courseId?: string;
  s12nId?: string;
  variant?: ButtonProps['variant'];
  edgeAlign?: ButtonProps['edgeAlign'];
  size?: ButtonProps['size'];
  shouldRedirectAfterUpgrade?: boolean;
  children: React.ReactNode;
  customCss?: Interpolation<Theme>;
};

type PropsToComponent = PropsFromCaller & PropsFromWithCreateMidCycleUpgradeCartMutation;

const ChangePaymentMethodButton: React.FC<PropsToComponent> = ({
  children,
  courseId,
  s12nId,
  variant = 'primary',
  edgeAlign,
  size,
  shouldRedirectAfterUpgrade = true,
  createMidCycleUpgradeCart,
  customCss,
}) => {
  const [apiStatus, setApiStatus] = useState<ApiStatus>(API_BEFORE_SEND);
  const trackingData = {
    courseId,
    s12nId,
  };

  const handleClick = () => {
    if (apiStatus === API_IN_PROGRESS || typeof createMidCycleUpgradeCart !== 'function') {
      return;
    }

    setApiStatus(API_IN_PROGRESS);
    const toTierBillingCycle = 'MONTHLY' as BillingCycleType;
    const options = { courseId, s12nId, toTierBillingCycle };
    submitEnrollmentPromise({
      handleSubmitPromise: createMidCycleUpgradeCart,
      options,
    })
      .then(() => {
        setApiStatus(API_SUCCESS);
        if (shouldRedirectAfterUpgrade) {
          saveRedirectUrlAfterUpgrade();
        } else {
          // actively remove any lingering redirect url from before
          removeRedirectUrlAfterUpgrade();
        }
      })
      .catch(() => {
        setApiStatus(API_ERROR);
      });
  };

  return (
    <TrackedButton
      variant={variant}
      size={size ?? 'medium'}
      trackingName="mid_cycle_upgrade_update_payment_method_button"
      data-testId="mid-cycle-upgrade-update-payment-method-button"
      trackingData={trackingData}
      onClick={handleClick}
      loading={apiStatus !== API_BEFORE_SEND}
      disabled={apiStatus !== API_BEFORE_SEND}
      edgeAlign={edgeAlign}
      css={customCss}
    >
      {children}
    </TrackedButton>
  );
};

export default withCreateMidCycleUpgradeCartMutation<PropsToComponent>(ChangePaymentMethodButton);
