import {
  getWidgetSessionProviderByIdAndCourseId,
  publishWidgetSessionProvider as publishWidgetSessionProviderApi,
  updateWidgetSessionProvider as updateWidgetSessionProviderApi,
} from 'bundles/author-common/utils/AuthoringWidgetSessionAPIUtils';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import WidgetConfigDialogComponent from 'bundles/cml/shared/components/widget/dialog/WidgetConfigDialog';
import type { WidgetSession, WidgetSessionDraftConfig } from 'bundles/cml/shared/types/widgetManager';
import type {
  AuthoringWidgetSummariesV1,
  AuthoringWidgetSummariesV1Connection as WidgetSummaries,
} from 'bundles/naptimejs/resources/__generated__/AuthoringWidgetSummariesV1';
import { getAuthoringWidgetSummary } from 'bundles/widget-admin/utils/WidgetAdminAPIUtils';

export type WidgetSummary = AuthoringWidgetSummariesV1;

export const createWidgetMap = (widgets: Array<WidgetSummary>) => {
  /* eslint-disable no-param-reassign */
  return widgets.reduce((widgetMap: Record<string, WidgetSummary>, widget: WidgetSummary) => {
    widgetMap[widget.id] = widget;
    return widgetMap;
  }, {});
};

const getWidgetSessionProvider = async ({ id, courseId }: { id: string; courseId: string }) => {
  return getWidgetSessionProviderByIdAndCourseId({ id, courseId }).then((response) => {
    return response.elements[0];
  });
};

const publishWidgetSessionProvider = async (input: { id: string; courseId: string }) => {
  return publishWidgetSessionProviderApi(input);
};

const updateWidgetSessionProvider = async (
  widgetSession: WidgetSession,
  iframeTitle: string,
  id: string,
  config: WidgetSessionDraftConfig,
  courseId: string
) => {
  return updateWidgetSessionProviderApi(widgetSession, iframeTitle, id, config, courseId);
};

export const getWidgetList = async (courseId: string) => {
  return getAuthoringWidgetSummary(courseId).then(
    (response: WidgetSummaries) => response.elements?.filter((widget) => !widget.isGraded) || []
  );
};

export const createWidgetMenuOptions = () => {
  return {
    WidgetConfigDialog: WidgetConfigDialogComponent,
    getWidgetSession: getWidgetSessionProvider,
    updateWidgetSession: updateWidgetSessionProvider,
    publishWidgetSession: publishWidgetSessionProvider,
  };
};
