/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { useQuery } from '@apollo/client';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import { getCmsLocaleString } from 'js/lib/language';
import useRouter from 'js/lib/useRouter';

import { Button, Dialog, Tag, Typography2 } from '@coursera/cds-core';
import type { ButtonProps } from '@coursera/cds-core';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import { Spacer } from 'bundles/enterprise-ui';
import type {
  UnifiedRolePageCareerContentQuery,
  UnifiedRolePageCareerContentQueryVariables,
} from 'bundles/unified-career-academy/__generated__/contentfulTypes';
import { unifiedRolePageCareerContentQuery } from 'bundles/unified-career-academy/queries/contentfulQueries';

import _t from 'i18n!nls/program-personalized-tab';

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps<'button'>>(Button);

type PropsFromCaller = {
  isOpen: boolean;
  onClose: () => void;
  title?: string | null;
  description?: string | null;
  skills?: Array<string | null> | null;
  roleSlug?: string | null;
  onSetFocusedRoleClick: (occupationId: string) => void;
};

export type Props = PropsFromCaller;

const dialogContent = css({ display: 'flex', gap: '32px', justifyContent: 'space-between' });
const roleJobList = css({ paddingLeft: '24px' });
const skillTag = css({ marginTop: '8px', marginRight: '8px' });

export const RoleInfoDialogModule = ({
  isOpen,
  onClose,
  title,
  description,
  skills,
  roleSlug,
  onSetFocusedRoleClick,
}: Props) => {
  const router = useRouter();
  const previewParam = router.location.query.preview;
  const isPreview = Number(previewParam) === 1;

  const { data } = useQuery<UnifiedRolePageCareerContentQuery, UnifiedRolePageCareerContentQueryVariables>(
    unifiedRolePageCareerContentQuery,
    {
      variables: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        slug: roleSlug!,
        locale: getCmsLocaleString(),
        preview: isPreview,
      },
      skip: !roleSlug,
      context: { clientName: isPreview ? 'contentfulPreviewGql' : 'contentfulGql' },
    }
  );

  const roleData = data?.unifiedCareerAcademyCareerCardCollection?.items?.[0];
  const roleJobTitles = roleData?.rdpDescriptionJobTitles;
  const roleTitle = roleData?.title;
  const rdpSkills = roleData?.rdpDescriptionSkills;

  return (
    <Dialog open={isOpen} onClose={onClose} width="large">
      {title && <Dialog.HeadingGroup>{title}</Dialog.HeadingGroup>}
      <Dialog.Content>
        <div css={dialogContent}>
          <section css={{ flexGrow: 5 }}>
            <Typography2 variant="bodyPrimary" component="p">
              {description}
            </Typography2>
            <Spacer size={16} />
            <Typography2 component="p" variant="bodyPrimary">
              <FormattedMessage
                message={_t('{prefix} {skills}')}
                prefix={
                  <Typography2 variant="subtitleMedium" component="span">
                    {_t('If you like: ')}
                  </Typography2>
                }
                skills={skills?.join(', ')}
              />
            </Typography2>
            <Spacer size={16} />
            <Typography2 variant="subtitleMedium" component="p">
              {_t('Common job titles:')}
            </Typography2>
            <ul css={roleJobList}>
              {roleJobTitles?.map((jobTitle) => (
                <li key={jobTitle}>
                  <Typography2 component="p" variant="bodyPrimary">
                    {jobTitle}
                  </Typography2>
                </li>
              ))}
            </ul>
          </section>
          <section css={{ flexGrow: 2 }}>
            <Typography2 variant="bodyPrimary" component="p">
              {_t('Top 6 skills needed for #{roleTitle}:', { roleTitle })}
            </Typography2>
            <Spacer size={8} />
            <div>
              {rdpSkills?.map((rdpSkill) => (
                <Tag variant="category" css={skillTag} key={rdpSkill}>
                  {rdpSkill ?? ''}
                </Tag>
              ))}
            </div>
          </section>
        </div>
      </Dialog.Content>
      <Dialog.Actions>
        <TrackedButton
          {...Button.defaultProps}
          variant="primary"
          onClick={() => onSetFocusedRoleClick(roleSlug ?? '')}
          trackingName="focused_role_btn"
          trackingData={{ roleSlug }}
        >
          {_t('Set this as my focused role')}
        </TrackedButton>
      </Dialog.Actions>
    </Dialog>
  );
};
