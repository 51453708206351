import * as React from 'react';

import PropTypes from 'prop-types';

import { FormattedHTMLMessage } from 'js/lib/coursera.react-intl';

import CourseInfoRow from 'bundles/coursera-ui/components/rich/CourseInfoRow';
import SvgCertificate from 'bundles/coursera-ui/components/svg/coursera/common/SvgCertificate';
import SvgStack from 'bundles/coursera-ui/components/svg/coursera/common/SvgStack';

import _t from 'i18n!nls/coursera-ui';

const CONFIG = {
  defaultIconSize: 44,
};

const MiniSDPInfoRows = ({ courseCount, capstoneProjectCount }: $TSFixMe) => {
  return (
    <div className="MiniSDPInfoRows vertical-box">
      <CourseInfoRow
        hasBorderBottom
        hasBorderTop
        icon={<SvgStack size={CONFIG.defaultIconSize} />}
        title={_t('Structure')}
        subtitle={
          <span>
            <FormattedHTMLMessage
              id="courseCount"
              message={_t(
                `
                <span class="display font-italic font-lg">{courseCount} </span>
                {courseCount, plural, =1 {course} other {courses}}
              `
              )}
              courseCount={courseCount}
            />
            {!!capstoneProjectCount && capstoneProjectCount >= 0 && (
              <FormattedHTMLMessage
                id="capstoneProjectCount"
                message={_t(
                  `
                  <span class="display font-italic font-lg">{capstoneProjectCount}</span>
                  capstone
                  {capstoneProjectCount, plural, =1 {project} other {projects}}
                `
                )}
                capstoneProjectCount={capstoneProjectCount}
              />
            )}
          </span>
        }
      />
      <CourseInfoRow
        hasBorderBottom
        icon={<SvgCertificate size={CONFIG.defaultIconSize} />}
        title={_t('Earn')}
        subtitle={
          <span>
            <FormattedHTMLMessage
              message={_t(
                `
                  <span className="display font-italic font-lg d-inline-block m-r-1s">
                    {dataSize}
                  </span>
                  certificate
                `
              )}
              dataSize={1}
            />
          </span>
        }
      />
    </div>
  );
};

MiniSDPInfoRows.propTypes = {
  courseCount: PropTypes.number,
  capstoneProjectCount: PropTypes.number,
};

export default MiniSDPInfoRows;
