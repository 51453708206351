import { createContext, useContext } from 'react';

import type { AssetModalOptions, AssetUploadOptions } from 'bundles/cml/editor/types/assetTypes';
import type { AssetManager } from 'bundles/cml/shared/types/assetManager';

export type AssetContextType = {
  setAssetModalOptions: (assetOptions?: AssetModalOptions) => void;
  isLearnerUpload: boolean | undefined;
  assetManager: AssetManager;
  pageless: boolean;
  uploadOptions?: AssetUploadOptions;
};

export const AssetContext = createContext<AssetContextType>({
  setAssetModalOptions: () => undefined,
  isLearnerUpload: false,
  pageless: false,
  assetManager: {
    getAsset: () => undefined,
    fetchAsset: () => Promise.resolve(undefined),
    addAssetListener: () => undefined,
    removeAssetListener: () => undefined,
    updateAssetMap: () => undefined,
  },
});

export const useAssetContext = () => useContext(AssetContext);
