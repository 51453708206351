import extend from 'lodash/extend';

import ProductType from 'bundles/payments/common/ProductType';
import createCartPromise from 'bundles/payments/promises/createCart';

/**
 * Create a VC Cart. NOTE: Refer to ./createCart for more information.
 * @param {string} id - The productItemId
 * @param {Object} options - Refer to ./createCart
 * @returns {Promise.<Object>} ./createCart response.
 */
export default function (id: string, options: $TSFixMe) {
  options = extend(
    {},
    {
      productItems: [
        {
          productType: ProductType.VERIFIED_CERTIFICATE,
          productItemId: id,
          productAction: 'Buy',
          cartItemIdToRefund: {},
        },
      ],
    },
    options
  );

  return createCartPromise(options);
}
