import type { HeadingLevel, HeadingVariant } from 'bundles/cml/shared/types/coreTypes';

import _t from 'i18n!nls/cml';

// each level has a different default variant, so we need this mapping for backwards compatibility
export const DEFAULT_HEADING_VARIANTS: Record<HeadingLevel, HeadingVariant> = {
  1: 'h1semibold',
  2: 'h2semibold',
  3: 'h3bold',
  4: 'h4bold',
};

// maps all the variants supported for each level
export const HEADINGS_VARIANTS: Record<HeadingLevel, HeadingVariant[]> = {
  1: [DEFAULT_HEADING_VARIANTS[1], 'h1regular'],
  2: [DEFAULT_HEADING_VARIANTS[2], 'h2regular'],
  3: [DEFAULT_HEADING_VARIANTS[3], 'h3semibold'],
  4: [DEFAULT_HEADING_VARIANTS[4]],
};

// maps variant name to the UI display name in the menu
export const getVariantDisplayName = (variant: HeadingVariant): string => {
  switch (variant) {
    case 'h1regular':
    case 'h2regular':
      return _t('Regular');

    case 'h1semibold':
    case 'h2semibold':
    case 'h3semibold':
      return _t('Semibold');

    case 'h3bold':
    case 'h4bold':
      return _t('Bold');

    default:
      return '';
  }
};

export const getHeadingDisplayName = (level: HeadingLevel) => {
  switch (level) {
    case '1':
      return _t('Heading 1');

    case '2':
      return _t('Heading 2');

    case '3':
      return _t('Heading 3');

    case '4':
      return _t('Heading 4');

    default:
      return '';
  }
};
