import * as React from 'react';

import { pure } from 'recompose';

import type { ApiStatus, ButtonProps } from '@coursera/coursera-ui';
import { ApiButton, Button } from '@coursera/coursera-ui';

import S12nStartNextCourseButton from 'bundles/program-common/components/S12nStartNextCourseButton';

import _t from 'i18n!nls/program-common';

type Props = {
  btnAttributes?: ButtonProps;
  onClick: () => void;
  apiStatus: ApiStatus;
  canEnroll?: boolean;
  isEnrolled?: boolean;
  renderNothingIfConditionNotMet?: boolean;
  initialBtnLabel?: JSX.Element | string;
  productId: string;
  renderCourseHomeLink?: boolean;
};

const S12nEnrollButton = ({
  btnAttributes = {},
  onClick,
  apiStatus,
  isEnrolled,
  canEnroll,
  renderNothingIfConditionNotMet,
  initialBtnLabel = _t('Enroll'),
  productId,
  renderCourseHomeLink,
}: Props) => {
  const renderEnroll = !isEnrolled && canEnroll;
  const renderEnrolled = isEnrolled && !renderNothingIfConditionNotMet;

  if (renderEnroll) {
    return (
      <ApiButton
        {...btnAttributes}
        htmlAttributes={{
          ...btnAttributes?.htmlAttributes,
          'aria-live': 'polite',
          'aria-busy': apiStatus === 'API_IN_PROGRESS',
        }}
        iconAttributes={{
          suppressTitle: true,
        }}
        onClick={onClick}
        apiStatus={apiStatus}
        apiStatusAttributesConfig={{
          label: {
            API_BEFORE_SEND: initialBtnLabel,
            API_IN_PROGRESS: _t('Enrolling...'),
            API_SUCCESS: _t('Enrolled'),
            API_ERROR: _t('Failed'),
          },
        }}
      />
    );
  } else if (renderEnrolled) {
    const enrolledHtml = <Button {...btnAttributes} type="disabled" label={_t('Enrolled')} disabled={true} />;
    if (renderCourseHomeLink) {
      return (
        <span>
          {enrolledHtml}
          <span className="d-inline-block">
            <S12nStartNextCourseButton s12nId={productId} />
          </span>
        </span>
      );
    } else {
      return enrolledHtml;
    }
  }
  return null;
};

export default pure(S12nEnrollButton);
