/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Switch } from 'bundles/cds-labs/components';
import type { SwitchProps } from 'bundles/cds-labs/components';

type Props = {} & SwitchProps;

const styles = {
  root: css`
    /* Custom gradients */
    --default-gradient: linear-gradient(
      279deg,
      var(--cds-color-emphasis-primary-content-default) -5.78%,
      var(--cds-color-blue-600) 83.48%
    );

    /* CSS overrides */
    --cds-color-interactive-stroke-primary-focus: var(--cds-color-emphasis-primary-background-xxstrong);
    --cds-color-interactive-primary: var(--default-gradient);
  `,
};

/**
 * Coach-themed wrapper around CDS `Switch`
 */
export const SettingsSwitch = (props: Props): React.ReactElement => {
  const { selected, onToggle, label, ...rest } = props;

  const handleToggle = (isSelected: boolean) => {
    onToggle?.(isSelected);
  };

  return (
    <Switch
      css={styles.root}
      className="coach-settings-switch"
      selected={selected}
      onToggle={handleToggle}
      label={label}
      {...rest}
    />
  );
};

export default SettingsSwitch;
