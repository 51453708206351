import * as React from 'react';

import type Q from 'q';

import hoistNonReactStatics from 'js/lib/hoistNonReactStatics';

import type { NaptimeError } from 'bundles/naptimejs';
import { API_BEFORE_SEND, API_ERROR, API_IN_PROGRESS, API_SUCCESS } from 'bundles/phoenix/components/ApiNotification';
import type { ApiError, ApiNotification, ApiResponse, ApiStatusType } from 'bundles/phoenix/components/ApiNotification';

type State = {
  apiStatus: ApiStatusType;
  error?: NaptimeError | null;
  response?: ApiResponse | null;
  apiNotification?: ApiNotification | null;
  apiTag?: string | null;
};

type Response = ApiResponse;
export type HandleApiPromiseOptions = {
  apiPromise?: Q.Promise<Response>;
  apiPromiseFn?: () => Q.Promise<Response> | undefined;
  refreshDataPromiseFn?: () => Q.Promise<Response>;
  apiSuccessCallback?: (result: Response) => void;
  apiErrorCallback?: (response: ApiError) => void;
  apiTag?: string | null;

  apiNotification?: $TSFixMe;
  silent?: boolean;
  apiInProgressCallback?: () => void;
};

export type PropsFromWithApiHandler = {
  handleApiPromise: (options: HandleApiPromiseOptions) => void;
} & State;

type WithApiHandlerParams = {
  shouldResetToDefaultStatus?: boolean;
  apiStatusResetTime?: number;
};

/**
 * A HOC to handle common api call related state changes and notifications
 * Optionally config whether the apiStatus should reset to API_BEFORE_SEND after the call
 * The apiStatus, response, error, apiNotification will be passed down to the wrapped component
 * So all the process information will be immediately available (faster without going through fluxible)
 */

const withApiHandler = function withApiHandler<Props = $TSFixMe>({
  shouldResetToDefaultStatus = false,
  apiStatusResetTime = 3000,
}: WithApiHandlerParams) {
  return (Component: React.ComponentType<Props & PropsFromWithApiHandler>): React.ComponentType<Props> => {
    const componentName = Component.displayName || Component.name;
    class HOC extends React.Component<Props, State> {
      static displayName = `withApiHandler(${componentName})`;

      _isMounted = false;

      constructor(props: Props) {
        super(props);
        this.state = this.getDefaultState();
      }

      componentDidMount() {
        // When the api call finishes, the component may not be mounted anymore
        // We have to check _isMounted before we use setState
        this._isMounted = true;
      }

      componentWillUpdate(nextProps: Props & WithApiHandlerParams, { apiStatus }: State) {
        const resetToDefault =
          'shouldResetToDefaultStatus' in nextProps ? nextProps.shouldResetToDefaultStatus : shouldResetToDefaultStatus;
        const resetTime = 'apiStatusResetTime' in nextProps ? nextProps.apiStatusResetTime : apiStatusResetTime;

        if (resetToDefault && (apiStatus === API_SUCCESS || apiStatus === API_ERROR)) {
          setTimeout(() => {
            if (this._isMounted) {
              this.setState(this.getDefaultState);
            }
          }, resetTime);
        }
      }

      componentWillUnmount() {
        this._isMounted = false;
      }

      handleApiPromise = ({
        apiPromise: apiPromiseIn,
        apiPromiseFn,
        refreshDataPromiseFn,
        apiInProgressCallback,
        apiSuccessCallback,
        apiErrorCallback,
        apiNotification,
        silent,
        apiTag,
      }: HandleApiPromiseOptions) => {
        // Use silent if we don't want to do anything, just silently invoke the promise
        // Good for naptimeRefreshData
        let apiPromise = apiPromiseIn;
        if (apiPromiseFn) {
          apiPromise = apiPromiseFn();
        }
        if (silent) {
          apiPromise?.then?.(() => null)?.done?.();
          return;
        }

        this.setState({
          apiStatus: API_IN_PROGRESS,
          error: null,
          response: null,
          apiNotification,
          apiTag,
        });
        if (apiInProgressCallback) apiInProgressCallback();
        this.notifyStore();

        apiPromise
          ?.then((response) => {
            if (this._isMounted) {
              this.setState({
                response,
                apiStatus: API_SUCCESS,
                apiTag,
              });
            }
            this.notifyStore();
            // Silently refresh data, useful when you want to refresh other resources
            if (refreshDataPromiseFn) refreshDataPromiseFn()?.done?.();
            if (apiSuccessCallback) apiSuccessCallback(response);
          })
          .fail((error) => {
            if (this._isMounted) {
              this.setState({
                error,
                apiStatus: API_ERROR,
              });
            }
            this.notifyStore();
            if (apiErrorCallback) apiErrorCallback(error);
          })
          ?.done?.();
      };

      getDefaultState(): State {
        return {
          apiStatus: API_BEFORE_SEND,
          error: null,
          response: null,
          apiNotification: null,
          apiTag: null,
        };
      }

      notifyStore = () => {
        const { apiStatus, error, response, apiNotification } = this.state;
        if (!apiNotification) return;
        if (apiStatus === API_IN_PROGRESS) {
          apiNotification.setApiInProgress();
        } else if (apiStatus === API_SUCCESS) {
          apiNotification.setApiSuccess(response);
        } else if (apiStatus === API_ERROR) {
          apiNotification.setApiError(error);
        }
      };

      render() {
        return <Component {...this.props} {...this.state} handleApiPromise={this.handleApiPromise} />;
      }
    }

    hoistNonReactStatics(HOC, Component);
    return HOC;
  };
};

export default withApiHandler;
