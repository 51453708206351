import * as React from 'react';
import { useContext } from 'react';

import type { ThirdPartyOrganizationsV1 } from '__generated__/graphql-types';

import type OpenCourseMembershipsV1 from 'bundles/naptimejs/resources/openCourseMemberships.v1';

export type UserContextType = {
  openCourseMemberships?: OpenCourseMembershipsV1;
  thirdPartyOrganizations?: Array<Pick<ThirdPartyOrganizationsV1, 'id' | 'organizationType'> | undefined | null>;
  loadingThirdPartyOrganizations: boolean;
};

const UserContext = React.createContext<undefined | UserContextType>(undefined);

export const useOpenCourseMemberships = () => {
  const userContext = useContext(UserContext);

  return userContext?.openCourseMemberships;
};

export const useIsEnterpriseLearner = () => {
  const isEnterpriseLearner = useContext(UserContext)?.thirdPartyOrganizations?.some(
    (org) => typeof org?.organizationType === 'string'
  );

  return {
    isEnterpriseLearner: !!isEnterpriseLearner,
    loadingIsEnterpriseLearner: useContext(UserContext)?.loadingThirdPartyOrganizations,
  };
};

export default UserContext;
