import * as React from 'react';

import gql from 'graphql-tag';

import { StyleSheet } from '@coursera/coursera-ui';

import AdminBanner from 'bundles/enterprise-legacy-xdp/components/banner/admin/AdminBanner';
import PdpSection from 'bundles/enterprise-legacy-xdp/components/miniPDP/PdpSection';
import type { PdpAdminBanner as PdpAdminBannerType } from 'bundles/enterprise-legacy-xdp/components/miniPDP/__generated__/PdpAdminBanner';
import withFragments from 'bundles/graphql/components/withFragments';

const sectionStyles = StyleSheet.create({
  root: {
    // AdminBanner only wants to inerhit the left/right padding
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
});

type Props = Omit<PdpAdminBannerType, '__typename'> & {
  projectId: string;
  slug: string;
};

const PdpAdminBanner: React.FunctionComponent<Props> = ({ slug, projectId, courseStatus }) => {
  return (
    <PdpSection styles={sectionStyles}>
      <AdminBanner slug={slug} courseId={projectId} courseStatus={courseStatus} />
    </PdpSection>
  );
};

export default withFragments({
  PdpAdminBanner: gql`
    fragment PdpAdminBanner on XdpV1_org_coursera_xdp_cdp_CDPMetadata {
      courseStatus
    }
  `,
})(PdpAdminBanner);
