import { Editor, Transforms } from 'slate';
import type { Descendant } from 'slate';
import { ReactEditor } from 'slate-react';

import logger from 'js/app/loggerSingleton';
import type { TrackingContextData } from 'js/lib/retracked';

import { deserializeHtml } from 'bundles/cml/editor/html/deserializeHtml';
import { deserializeImages } from 'bundles/cml/editor/html/deserializeImages';
import { isGoogleDocsContent } from 'bundles/cml/editor/html/googleDocsUtils';
import { isMsWordContent } from 'bundles/cml/editor/html/msWordUtils';
import type { NotificationMessage } from 'bundles/cml/editor/types/notification';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';

const insertHtmlData = (
  editor: Editor,
  html: string,
  images: DataTransferItem[],
  setNotification: (message: NotificationMessage) => void,
  eventingData?: TrackingContextData
) => {
  const document = new DOMParser().parseFromString(html, 'text/html');
  const isGoogleDocs = isGoogleDocsContent(document);
  const isWord = isMsWordContent(document);

  const fragment = deserializeHtml(document.body, images, {
    isGoogleDocs,
    isWord,
    setNotification,
    eventingData,
  }) as Descendant[];
  if (!fragment.length) {
    return;
  }

  fragment.push({ type: BLOCK_TYPES.TEXT, children: [{ text: '' }] });

  try {
    Transforms.insertFragment(editor, fragment);
  } catch (e) {
    logger.error(e);
    Editor.normalize(editor, { force: true });
  }
};

const getImages = (data: DataTransfer) => {
  return Array.from(data.items).filter((item) => item.type.startsWith('image/'));
};

const insertImageData = (editor: Editor, images: DataTransferItem[], eventingData?: TrackingContextData) => {
  const fragment = deserializeImages(images, eventingData);

  try {
    Transforms.insertFragment(editor, fragment);
  } catch (e) {
    logger.error(e);
    Editor.normalize(editor, { force: true });
  }
};

export const insertData = (
  editor: Editor,
  insertTextData: (data: DataTransfer) => void,
  setNotification: (message: NotificationMessage) => void,
  eventingData?: TrackingContextData
) => {
  return (data: DataTransfer) => {
    // handle pasting content from another CML Editor instance
    if (ReactEditor.insertFragmentData(editor, data)) {
      return;
    }

    const html = data.getData('text/html');
    const images = getImages(data);

    if (html) {
      insertHtmlData(editor, html, images, setNotification, eventingData);
      return;
    }

    if (images.length) {
      insertImageData(editor, images, eventingData);
      return;
    }

    Editor.withoutNormalizing(editor, () => insertTextData(data));
  };
};
