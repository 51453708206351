import * as React from 'react';

import SocialShareCard from 'bundles/program-home/components/SocialShareCard';
import { getC4erSocialCaptions } from 'bundles/program-home/utils/ProgramHomeUtils';

import _t from 'i18n!nls/program-home';

type Props = {
  isAllowlistedC4er: boolean;
};

export const C4ERSocialShareCard = () => {
  return (
    <SocialShareCard
      captions={getC4erSocialCaptions()}
      title={_t('Help spread the word')}
      description={_t('Invite your colleagues to learn for free')}
      utmMediumParam="share"
      utmCampaignParam="social_icons_c4er"
      useCustomUrl
    />
  );
};

const SocialShareCardForProgramType = ({ isAllowlistedC4er }: Props) => {
  if (isAllowlistedC4er) {
    return <C4ERSocialShareCard />;
  }
  return null;
};

export default SocialShareCardForProgramType;
