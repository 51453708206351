/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useState } from 'react';

import logger from 'js/app/loggerSingleton';
import { FormattedMessage } from 'js/lib/coursera.react-intl';

import type { ButtonProps } from '@coursera/cds-core';
import { Button, Dialog, Divider, Typography2, useTheme } from '@coursera/cds-core';
import { CheckIcon } from '@coursera/cds-icons';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import SubscriptionTiersEnrollButton from 'bundles/coursera-plus/components/subscriptionTiers/SubscriptionTiersEnrollButton';
import { getWithS12nEnrollModalCopies } from 'bundles/coursera-plus/components/subscriptionTiers/SubscriptionTiersEnrollCopies';
import SubscriptionTiersLiteOnlyEnrollModal from 'bundles/coursera-plus/components/subscriptionTiers/SubscriptionTiersLiteOnlyEnrollModal';
import EnrollmentChoiceTypes from 'bundles/enroll-course/common/EnrollmentChoiceTypes';
import {
  choiceTypeToHandleSubmitPromise,
  submitEnrollmentPromise,
} from 'bundles/enroll-course/lib/enrollmentChoiceUtils';
import PromotionApplicableCheckoutMessage from 'bundles/enroll/components/common/PromotionApplicableCheckoutMessage';
import {
  getFreeTrialBullets,
  getPaidSubscriptionBullets,
} from 'bundles/enroll/components/subscriptions/free-trialV2/utils/bulletData';
import usePageData from 'bundles/enroll/data/usePageData';
import {
  getCanEnrollThroughS12nSubscriptionFreeTrial,
  getS12nMonthlySkuId,
} from 'bundles/enroll/utils/mixAndMatchUtils';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import ReactPriceDisplay from 'bundles/payments-common/components/ReactPriceDisplay';

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps>(Button);

const useStyles = () => {
  const theme = useTheme();
  return {
    dialog: css`
      .cds-Dialog-dialog {
        max-width: 720px !important;
      }
    `,
    header: css`
      padding-bottom: var(--cds-spacing-100);
    `,
    subtitle: css`
      margin-bottom: var(--cds-spacing-200);

      p {
        margin: var(--cds-spacing-100) 0;
      }

      p:last-child {
        margin-bottom: 0;
      }
    `,
    valueProps: css`
      padding: 0;
      margin: 0;
      list-style-type: none;
    `,
    valuePropListItem: css`
      display: flex;
      align-items: flex-start;
      margin: var(--cds-spacing-100) 0;
    `,
    valuePropHeader: css`
      margin-bottom: 0;
    `,
    checkMark: css`
      margin-top: 2px;
      margin-right: var(--cds-spacing-100);
    `,
    cta: css`
      padding: var(--cds-spacing-150) 0 var(--cds-spacing-300);
    `,
    divider: css`
      margin: var(--cds-spacing-300) 0;
    `,
    footerLink: css`
      font-weight: 400;
      padding-left: 0;
      background-color: #fff !important;
      margin: var(--cds-spacing-50) 0 var(--cds-spacing-300);
    `,
  };
};

type PropsFromCaller = {
  onClose: () => void;
  onSdp: boolean;
  isFromS12nSelection?: boolean;
};

const MODALS = {
  S12N: 'S12N',
  LITE: 'LITE',
} as const;

type ModalType = (typeof MODALS)[keyof typeof MODALS];

const SubscriptionTiersWithS12nEnrollModal: React.FC<PropsFromCaller> = ({ onClose, onSdp, isFromS12nSelection }) => {
  const [activeModal, setActiveModal] = useState<ModalType>(MODALS.S12N);
  const {
    s12n,
    course,
    enrollmentAvailableChoices,
    s12nDerivatives,
    promotionEligibilities,
    courseraLiteProductPrice,
  } = usePageData();
  const styles = useStyles();

  if (!s12n || !s12nDerivatives || !s12nDerivatives.catalogPrice || !courseraLiteProductPrice) {
    logger.error('Unable to render Subscription Tiers enroll modal without s12n or pricing');
    return null;
  }

  const { catalogPrice } = s12nDerivatives;
  const enrollmentData = { enrollmentAvailableChoices, isFromS12nSelection, s12nId: s12n.id };
  const s12nMonthlySkuId = getS12nMonthlySkuId(enrollmentData);
  const canEnrollThroughS12nSubscriptionFreeTrial = getCanEnrollThroughS12nSubscriptionFreeTrial(enrollmentData);
  const copy = getWithS12nEnrollModalCopies({
    litePrice: courseraLiteProductPrice,
    s12nPrice: s12nDerivatives,
    s12n,
    course,
  });
  const { HeadingGroup, Content, Actions } = Dialog;

  const getTitle = () => {
    if (canEnrollThroughS12nSubscriptionFreeTrial) {
      return copy.freeTrialTitle;
    } else {
      return copy.paidTitle;
    }
  };

  const getSubtitle = () => {
    if (onSdp && canEnrollThroughS12nSubscriptionFreeTrial) {
      return null;
    } else if (onSdp && !canEnrollThroughS12nSubscriptionFreeTrial) {
      return <p>{copy.paidS12nSubtitle}</p>;
    } else if (!onSdp && canEnrollThroughS12nSubscriptionFreeTrial) {
      return <p>{copy.freeTrialSubtitle}</p>;
    } else {
      return (
        <>
          <p>{copy.paidCourseSubtitle1}</p>
          <p>{copy.paidCourseSubtitle2}</p>
        </>
      );
    }
  };

  const getBullets = () => {
    if (canEnrollThroughS12nSubscriptionFreeTrial) {
      const bullets = getFreeTrialBullets({ s12nProductVariant: s12n.productVariant }).FREE_TRIAL_LOGGED_IN_BULLETS;
      return (
        <ul css={styles.valueProps}>
          {bullets.map((bullet) => (
            <li key={`value-prop-${bullet}`} css={styles.valuePropListItem}>
              <CheckIcon size="small" color="interactive" css={styles.checkMark} />
              <Typography2 component="div">
                <p css={styles.valuePropHeader}>
                  <strong>
                    <FormattedMessage
                      message={bullet.header}
                      monthlyPrice={
                        <ReactPriceDisplay
                          value={catalogPrice.amount}
                          currency={catalogPrice.currencyCode}
                          hideCurrencyCode={true}
                        />
                      }
                    />
                  </strong>
                </p>
                <p>{bullet.subheader}</p>
              </Typography2>
            </li>
          ))}
        </ul>
      );
    } else {
      const bullets = getPaidSubscriptionBullets();
      return (
        <ul css={styles.valueProps}>
          {bullets.map((bullet) => (
            <li key={`value-prop-${bullet}`} css={styles.valuePropListItem}>
              <CheckIcon size="small" color="interactive" css={styles.checkMark} />
              <Typography2 component="p">{bullet}</Typography2>
            </li>
          ))}
        </ul>
      );
    }
  };

  const onCtaClick = () => {
    const courseId = course.id;
    const promoCode = promotionEligibilities?.isEligible ? promotionEligibilities?.promoCodeId : undefined;

    const handleSubmitPromise = choiceTypeToHandleSubmitPromise[EnrollmentChoiceTypes.ENROLL_THROUGH_S12N_SUBSCRIPTION];
    const options = { productSkuId: s12nMonthlySkuId, courseId };

    return submitEnrollmentPromise({
      handleSubmitPromise,
      options,
      promoCode,
    });
  };

  const getCta = () => {
    const ctaCopy = canEnrollThroughS12nSubscriptionFreeTrial ? copy.freeTrialCta : copy.paidCta;
    return (
      <SubscriptionTiersEnrollButton
        trackingName="subscription_tiers_with_s12n_enroll_cta"
        variant="primary"
        data-testid="cta"
        onCtaClick={onCtaClick}
      >
        {ctaCopy}
      </SubscriptionTiersEnrollButton>
    );
  };

  const getFooter = () => {
    return (
      <TrackedButton
        trackingName="subscription_tiers_with_s12n_try_lite_link"
        variant="ghost"
        css={styles.footerLink}
        onClick={() => setActiveModal(MODALS.LITE)}
      >
        {copy.tryLiteMessage}
      </TrackedButton>
    );
  };

  switch (activeModal) {
    case MODALS.LITE:
      return (
        <SubscriptionTiersLiteOnlyEnrollModal
          onClose={onClose}
          onGoBack={() => {
            setActiveModal(MODALS.S12N);
          }}
        />
      );
    case MODALS.S12N:
    default:
      return (
        <TrackedDiv
          trackingName="subscription_tiers_with_s12n_enroll_modal"
          trackClicks={false}
          withVisibilityTracking={true}
          data={{ course: course.id, s12n: s12n.id }}
        >
          <Dialog
            aria-describedby="dialog-content"
            open
            variant="standard"
            width="large"
            onClose={onClose}
            css={styles.dialog}
          >
            <HeadingGroup css={styles.header}>{getTitle() as string}</HeadingGroup>
            <Content id="dialog-content">
              <Typography2 variant="bodyPrimary" component="div" css={styles.subtitle}>
                <PromotionApplicableCheckoutMessage
                  course={course}
                  s12n={s12n}
                  isFromS12nSelection={isFromS12nSelection}
                />
                {getSubtitle()}
              </Typography2>
              {getBullets()}
              <Actions css={styles.cta}>{getCta()}</Actions>
              <Divider />
              <Typography2 component="p" variant="bodyPrimary">
                {getFooter()}
              </Typography2>
            </Content>
          </Dialog>
        </TrackedDiv>
      );
  }
};

export default SubscriptionTiersWithS12nEnrollModal;
