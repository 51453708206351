import * as React from 'react';

import { useSlate } from 'slate-react';

import { ReplyIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import { getToolBarShortcutKey } from 'bundles/cml/editor/utils/toolbarUtils';

import _t from 'i18n!nls/cml';

const UndoButton: React.FC = () => {
  const editor = useSlate();
  const disabled = editor.history.undos.length === 0;

  const handleUndo = () => editor.undo();
  const shortcutKey = getToolBarShortcutKey('Z');

  return (
    <Button
      onClick={handleUndo}
      disabled={disabled}
      className="rc-UndoButton"
      title={_t('Undo #{shortcutKey}', { shortcutKey })}
      type={TOOLBAR_BUTTON_TYPES.insertion}
    >
      <ReplyIcon size="small" />
    </Button>
  );
};

export default UndoButton;
