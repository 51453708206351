import { useState } from 'react';

import _t from 'i18n!nls/program-home';

import type { SkillProgress } from './withSkillsData';

function getSubjects(skills: SkillProgress[]) {
  const subjects = [
    {
      value: '',
      label: _t('All subjects'),
    },
  ];
  skills.forEach((skill) => {
    skill.domains.forEach((skillDomain) => {
      if (!subjects.find((subject) => subject.value === skillDomain.id)) {
        subjects.push({
          value: skillDomain.id,
          label: skillDomain.name,
        });
      }
    });
  });
  return subjects;
}

export type LevelSetFiltersOptions = 'ALL' | 'AVAILABLE' | 'IN_PROGRESS' | 'COMPLETED_ONCE' | 'COMPLETED_ALL';

const LevelSetFilters: { [key in LevelSetFiltersOptions]: (skill: SkillProgress) => boolean } = {
  ALL: () => true,
  AVAILABLE: (skill) => skill.levelsetStatus !== 'UNAVAILABLE' && skill.levelsetStatus !== 'COMPLETED_ALL',
  IN_PROGRESS: (skill) => skill.levelsetStatus === 'IN_PROGRESS',
  COMPLETED_ONCE: (skill) => skill.levelsetStatus === 'COMPLETED_ONCE',
  COMPLETED_ALL: (skill) => skill.levelsetStatus === 'COMPLETED_ALL',
};

function getLevelsetStatus() {
  return [
    {
      value: 'ALL',
      label: _t('All'),
    },
    {
      value: 'AVAILABLE',
      label: _t('Available'),
    },
    {
      value: 'IN_PROGRESS',
      label: _t('In Progress'),
    },
    {
      value: 'COMPLETED_ONCE',
      label: _t('1 Attempt Remaining'),
    },
    {
      value: 'COMPLETED_ALL',
      label: _t('Completed'),
    },
  ];
}

const useFilter = (skills: SkillProgress[]) => {
  const subject = useState('');
  const levelsetStatus = useState<LevelSetFiltersOptions>('ALL');
  const [subjectValue] = subject;
  const [levelsetValue] = levelsetStatus;
  const filter = (skill: SkillProgress) => {
    if (subjectValue && !skill.domains.find((skillDomain) => skillDomain.id === subjectValue)) {
      return false;
    }
    if (!LevelSetFilters[levelsetValue](skill)) {
      return false;
    }
    return true;
  };
  return {
    subject,
    subjectOptions: getSubjects(skills),
    levelsetStatus,
    levelsetStatusOptions: getLevelsetStatus(),
    filter,
  };
};

const SORT_FUNCTIONS = {
  maxScore: (skillA: SkillProgress, skillB: SkillProgress) => skillB.score - skillA.score,
  alphabetic: (skillA: SkillProgress, skillB: SkillProgress) => skillA.skillName.localeCompare(skillB.skillName),
  lastUpdated: () => 0, // Sort is stable, so returning 0 should preserve original order
};

export type SortOptions = 'maxScore' | 'alphabetic' | 'lastUpdated';

const useSort = () => {
  const select = useState<SortOptions>('lastUpdated');
  const [sortValue] = select;
  return {
    select,
    options: [
      { value: 'lastUpdated', label: _t('Last Updated') },
      { value: 'maxScore', label: _t('Highest Score') },
      { value: 'alphabetic', label: _t('A-Z') },
    ],
    sort: SORT_FUNCTIONS[sortValue],
  };
};

export { useFilter, useSort };
