import * as React from 'react';

import AuthorEvaluatorSectionCaption from 'bundles/author-code-evaluator/components/AuthorEvaluatorSectionCaption';
import AuthorEvaluatorSectionHeader from 'bundles/author-code-evaluator/components/AuthorEvaluatorSectionHeader';
import { useReadOnlyContext } from 'bundles/authoring/common/components/ReadOnlyContext';
import type { LanguageType } from 'bundles/cml';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import CodeBlockV2 from 'bundles/code-evaluator/components/CodeBlockV2';

import _t from 'i18n!nls/author-code-evaluator';

type Props = {
  preamble: string;
  language: LanguageType;
  onChange: (value: string) => void;
};

const PreambleEditor = ({ preamble, language, onChange }: Props) => {
  const { isReadOnly } = useReadOnlyContext();

  return (
    <div className="rc-PreambleEditor">
      <AuthorEvaluatorSectionHeader style={{ paddingTop: 20, paddingBottom: 5 }}>
        {_t('Preamble Code')}
      </AuthorEvaluatorSectionHeader>

      <AuthorEvaluatorSectionCaption style={{ paddingBottom: 10 }}>
        {_t("This code will be executed as a pre-requisite before the learner's code gets executed.")}
      </AuthorEvaluatorSectionCaption>

      <CodeBlockV2 readOnly={isReadOnly} codeLanguage={language} expression={preamble} onChange={onChange} />
    </div>
  );
};

export default PreambleEditor;
