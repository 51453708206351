import * as React from 'react';
import { useContext, useState } from 'react';

import type { Asset } from 'bundles/asset-admin/types/assets';
import DeleteAssetButton from 'bundles/cml/editor/components/elements/asset/buttons/DeleteAssetButton';
import OpenInLibraryButton from 'bundles/cml/editor/components/elements/asset/buttons/OpenInLibraryButton';
import SwapAssetButton from 'bundles/cml/editor/components/elements/asset/buttons/SwapAssetButton';
import ImageDescriptionButton from 'bundles/cml/editor/components/elements/image/buttons/ImageDescriptionButton';
import ImageResizeButton from 'bundles/cml/editor/components/elements/image/buttons/ImageResizeButton';
import ImageResizeDialog from 'bundles/cml/editor/components/elements/image/dialogs/imageResizeDialog';
import Toolbar from 'bundles/cml/editor/components/toolbars/Toolbar';
import { ImageContext } from 'bundles/cml/editor/context/imageContext';
import RestoreFocus from 'bundles/cml/editor/utils/RestoreFocus';
import type { ImageElement } from 'bundles/cml/shared/types/elementTypes';

import _t from 'i18n!nls/cml';

export type Props = {
  anchorEl: HTMLElement | null;
  image: ImageElement;
  asset: Asset;
  onClose: () => void;
};

type DialogOptions = 'resize' | 'image-description' | null;

const ImageMenu: React.FC<Props> = ({ anchorEl, asset, image, onClose }) => {
  const [dialogOption, setDialogOption] = useState<DialogOptions>(null);
  const { ImageDescriptionDialog } = useContext(ImageContext);

  if (dialogOption) {
    const handleCloseDialog = () => setDialogOption(null);

    switch (dialogOption) {
      case 'resize':
        return <ImageResizeDialog image={image} onClose={handleCloseDialog} />;

      case 'image-description':
        return <ImageDescriptionDialog asset={asset} onClose={handleCloseDialog} />;

      default:
        return null;
    }
  }

  return (
    <RestoreFocus anchorEl={anchorEl} onClose={onClose}>
      <Toolbar role="menubar" className="rc-ImageMenu" autoFocus={true}>
        <OpenInLibraryButton asset={asset} />
        <DeleteAssetButton title={_t('Delete image')} />
        <SwapAssetButton element={image} title={_t('Swap image')} onClick={onClose} />
        <ImageResizeButton onClick={() => setDialogOption('resize')} />
        <ImageDescriptionButton onClick={() => setDialogOption('image-description')} />
      </Toolbar>
    </RestoreFocus>
  );
};

export default ImageMenu;
