/* @jsx jsx */

/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react';

import * as React from 'react';
import Media from 'react-media';

import { compose } from 'recompose';

import Retracked from 'js/app/retracked';
import { useRetracked } from 'js/lib/retracked';
import type { TrackingProps } from 'js/lib/retracked';

import { Pagination, breakpoints } from '@coursera/cds-core';

import PaginationControls from 'bundles/page/components/cds/PaginationControls';
import { useUserAgent } from 'bundles/page/contexts/UserAgentApiContext';
import type { Pagination as PaginationType } from 'bundles/search-common/providers/searchTypes';
import { createSeoURL, getAllTabIndexName } from 'bundles/search-common/utils/utils';

type PropsFromCaller = {
  // If true anchor tags will be used for page links over client-side redirects.
  shouldRenderLinks?: boolean;
  paginationData?: PaginationType & { totalPages?: number; page: number };
  currentQuery?: string;
  onPageChange?: () => void;
  activeFilters?: string[];
  setPage: (page: number) => void;
  eventingData?: {
    searchIndex: string;
    searchIndexPosition: number;
  };
};

type PropsToComponent = Omit<PropsFromCaller, 'eventingData'>;

const styles = {
  paginationContainerStyles: () => ({
    '.pagination': {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '3rem',
      marginTop: '3rem',
    },
  }),
};

const clientSideChangePage = (
  nextPage: number,
  setPage: (arg0: number) => void,
  onPageChange: (() => void) | undefined,
  trackComponent: (p: TrackingProps) => void
) => {
  setPage(nextPage);
  if (onPageChange) {
    onPageChange();
  }
  window.scroll(0, 0);

  trackComponent({
    trackingData: { resultsPageNumber: nextPage, page: window.location.pathname },
    trackingName: 'search_results_change_page_number',
    action: 'search',
  });
};

export const generatePaginationURLForEntityPages = (
  pageNumber: number,
  currentQuery: string,
  activeFilters?: string[]
) => {
  if (pageNumber === 1) {
    const firstPageNumberURL = new URLSearchParams(`query=${currentQuery}`).toString();

    return firstPageNumberURL;
  }
  return createSeoURL(currentQuery, getAllTabIndexName(), pageNumber, activeFilters);
};

export const SearchResultsPagination = ({
  shouldRenderLinks,
  paginationData,
  currentQuery,
  onPageChange,
  setPage,
  activeFilters,
}: PropsToComponent) => {
  const userAgent = useUserAgent();
  const trackComponent = useRetracked();
  const paginationContainerStyles = {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '3rem',
    marginTop: '3rem',
  };

  if (paginationData) {
    const onPaginationChange = (_event: React.ChangeEvent<unknown>, pageNumber: number) =>
      clientSideChangePage(pageNumber, setPage, onPageChange, trackComponent);
    const pageLinkProps = {
      renderLinks: true,
      algoliaCreatePaginateLink: (pageNumber: number) =>
        generatePaginationURLForEntityPages(pageNumber, currentQuery || '', activeFilters),
    };

    return shouldRenderLinks || !paginationData?.totalElements ? (
      <Media query={{ maxWidth: breakpoints.values.sm }} defaultMatches={userAgent && userAgent.isMobileBrowser}>
        {(matches) => (
          <PaginationControls
            pageCount={paginationData.totalPages}
            currentPage={paginationData.page || 1}
            maxPages={matches ? 4 : 5}
            style={paginationContainerStyles}
            largeStyle
            {...pageLinkProps}
          />
        )}
      </Media>
    ) : (
      <div css={styles.paginationContainerStyles}>
        <div className="pagination">
          <Pagination
            total={Math.min(paginationData?.totalElements, 1000)}
            page={paginationData?.page || 1}
            pageSize={12}
            hidePageSizeInput
            onPageChange={onPaginationChange}
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default compose<PropsToComponent, PropsFromCaller>(
  Retracked.createTrackedContainer<PropsFromCaller>((props) => {
    return {
      indexName: props.eventingData?.searchIndex,
      indexPosition: props.eventingData?.searchIndexPosition || 0,
    };
  })
)(SearchResultsPagination);
