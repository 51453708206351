/** @jsx jsx */
import { jsx } from '@emotion/react';

import { breakpoints, useMediaQuery } from '@coursera/cds-core';

import { ShowMoreGridSectionPlaceholder } from 'bundles/cds-labs/components';
import type { GridConfig } from 'bundles/cds-labs/components/ShowMoreGridSection/ShowMoreGridSection';
import { CARD_LIST_BREAKPOINTS_CONFIG } from 'bundles/cds-labs/components/ShowMoreGridSection/constants';
import { styles as CollectionStyles } from 'bundles/collections-common/components/ProductCardCollection';
import { ProductCardV2Placeholder } from 'bundles/search-common/components/search-cards/Placeholder';

type Props = {
  hideTitle?: boolean;
  hideButton?: boolean;
  itemsGridConfig?: GridConfig;
  defaultRows?: number;
  defaultMobileRows?: number;
  shouldShowListView?: boolean;
};

const ProductCardCollectionPlaceholder = ({
  defaultRows = 1,
  defaultMobileRows = 2,
  shouldShowListView,
  ...props
}: Props) => {
  const isMobile = useMediaQuery(breakpoints.down('xs'));
  const isTablet = useMediaQuery(breakpoints.down('sm'));
  const shouldUseListVariant = shouldShowListView ?? (isTablet || isMobile);
  let itemsGridConfig = props.itemsGridConfig;

  if (!itemsGridConfig && shouldUseListVariant) {
    itemsGridConfig = CARD_LIST_BREAKPOINTS_CONFIG;
  }

  return (
    <ShowMoreGridSectionPlaceholder
      {...props}
      className="rc-ProductCardCollectionPlaceholder"
      itemsGridConfig={itemsGridConfig}
      defaultRows={isMobile ? defaultMobileRows : defaultRows}
      renderPlaceholderItem={() => (
        <div css={CollectionStyles.targetCardWrapper} data-testid="card-wrapper">
          <ProductCardV2Placeholder variant={shouldUseListVariant ? 'list' : 'grid'} />
        </div>
      )}
    />
  );
};

export default ProductCardCollectionPlaceholder;
