/* eslint-disable no-restricted-syntax */
import _t from 'i18n!nls/stackability-common';

const getDegreesPathwaysCopy = () => ({
  myLearningTab: {
    disclaimer: _t(
      '¹Successful application and enrollment are required. Eligibility requirements apply. Each institution determines the number of credits recognized by completing this content that may count towards degree requirements, considering any existing credits you may have. Click on a specific course for more information.'
    ),
    offersCreditCopy: {
      title: _t('Build toward a degree'),
      body: (productVariant: string) =>
        _t(
          'By completing this #{productVariant}, you can have this learning recognized for university credit toward one of the following online degree programs, if you are admitted and enroll.¹',
          { productVariant }
        ),
    },
    partOfCopy: {
      title: _t('Build toward a degree'),
      body: (productVariant: string) =>
        _t(
          'This #{productVariant} is part of the following degree program(s). If you are admitted and enroll, your completed coursework may count toward your degree learning and your progress can transfer with you.¹',
          { productVariant }
        ),
    },
    relatedToCopy: {
      title: _t('Prepare for a degree'),
      body: (productVariant: string) =>
        _t(
          'Your completion of this #{productVariant} may have provided you with a preview of the topics, materials and instructors from one of these degree programs. View the programs to decide which one might be a good fit for your career goals.',
          { productVariant }
        ),
    },
  },
});

export default getDegreesPathwaysCopy;
