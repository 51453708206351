import * as React from 'react';
import { useState } from 'react';

import PropTypes from 'prop-types';
import type Q from 'q';
import { getContext } from 'recompose';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import user from 'js/lib/user';

// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import ProductEventInterestsV1 from 'bundles/naptimejs/resources/productEventInterests.v1';
import TrackedButton from 'bundles/page/components/TrackedButton';
import { VERIFIED_CERTIFICATE } from 'bundles/payments/common/ProductType';
import Modal from 'bundles/phoenix/components/Modal';

import _t from 'i18n!nls/enroll';

import 'css!./__styles__/UserInterestModal';

type PropsFromCaller = {
  courseId: string;
  onClose: () => void;
};

type PropsFromContext = {
  executeMutation: <Mutation, Response>(mutation: Mutation) => Q.Promise<Response>;
};

type PropsToComponent = PropsFromCaller & PropsFromContext;

const UserInterestModal: React.FC<PropsToComponent> = ({ courseId, onClose, executeMutation }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [registrationComplete, setRegistrationComplete] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string | null | undefined>(undefined);

  const handleInterestRegistration = () => {
    setIsLoading(!isLoading);

    const body = {
      productType: VERIFIED_CERTIFICATE,
      productItemId: courseId,
      interestedEventType: 'PRODUCT_LAUNCHED',
      interestedEmailAddress: user.get().email_address,
      productEventInterestStatus: 'ACTIVE',
    };

    let resErrorCode: string | null | undefined;
    const eventClient = ProductEventInterestsV1.create(body);
    executeMutation(eventClient)
      .fail((error: Error) => {
        resErrorCode = error.message ? JSON.parse(error.message).errorCode : 'generic-error';
      })
      .finally(() => {
        setRegistrationComplete(true);
        setErrorCode(resErrorCode);
        setIsLoading(false);
      })
      .done();
  };

  let modalMessage = _t(
    'Your course is not live yet - but you’ll be notified at {email} when your session is open for enrollment!'
  );
  let modalTitle = _t('Coming soon!');

  let buttonContent: string;
  if (isLoading) {
    buttonContent = _t('Registering...');
  } else if (registrationComplete) {
    if (errorCode && errorCode !== 'ExistingProductEventInterest') {
      modalMessage = _t('There was a problem registering with the following email: {email}');
    } else {
      modalTitle = _t('Thank you!');
      modalMessage = _t('Registration complete');
    }
    buttonContent = _t('Done');
  } else {
    buttonContent = _t('Yes, let me know');
  }

  return (
    <div className="rc-UserInterestModal">
      <Modal
        trackingName="user_interest_modal"
        key="UserInterestEnrollModal"
        modalName={_t('This product is not available yet')}
        handleClose={onClose}
      >
        <div className="modal-container">
          <h2 className="headline-4-text modal-title">{modalTitle}</h2>
          <h3 className="headline-1-text detailed-message">
            <FormattedMessage message={modalMessage} email={user.get().email_address} />
          </h3>
          <div className="horizontal-box align-items-right register-button">
            <TrackedButton
              trackingName="ra_user_interest_button"
              className="primary cozy register-button"
              onClick={registrationComplete ? onClose : handleInterestRegistration}
              disabled={isLoading}
            >
              {buttonContent}
            </TrackedButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default getContext({
  executeMutation: PropTypes.func.isRequired,
})(UserInterestModal);
