import * as React from 'react';

import _t from 'i18n!nls/program-common';

type SvgSkillsProps = React.SVGProps<SVGSVGElement>;

const SvgSkills: React.FC<SvgSkillsProps> = (props) => (
  <svg height={62} viewBox="0 0 60 62" width={60} {...props}>
    <title id="SvgSkills-Title">{_t('Skill Tracking')}</title>
    <g fill="none" fillRule="evenodd" transform="translate(0 1)">
      <rect height={60} opacity={0.8} rx={15} width={60} />
      <path
        d="M32.413.614L58.14 17.177a4 4 0 011.81 3.813h.037v19.022h-.107a4 4 0 01-1.74 2.492L31.918 59.386a4 4 0 01-4.33 0L1.858 42.823a3.99 3.99 0 01-1.796-2.81H.012V20.988h.014a4 4 0 011.833-3.494L28.082.616a4 4 0 014.33 0z"
        fill="#2a73cc"
        fillOpacity={0.1}
      />
      <g fill="#1f1f1f" opacity={0.75}>
        <path d="M22.5 18a4.502 4.502 0 014.244 2.999L46.5 21a1.5 1.5 0 010 3H26.744a4.502 4.502 0 01-8.488 0H13.5a1.5 1.5 0 010-3l4.756-.001A4.502 4.502 0 0122.5 18zm0 2a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM36.5 33a4.502 4.502 0 00-4.244 3H13.5a1.5 1.5 0 000 3l18.756.001a4.502 4.502 0 008.488 0L46.5 39a1.5 1.5 0 000-3h-5.756a4.502 4.502 0 00-4.244-3zm0 2a2.5 2.5 0 110 5 2.5 2.5 0 010-5z" />
      </g>
    </g>
  </svg>
);

export default SvgSkills;
