import { withProps } from 'recompose';

import EnterpriseExperiments from 'bundles/epic/clients/Enterprise';
import type EnterpriseProgramsV1 from 'bundles/naptimejs/resources/enterprisePrograms.v1';
import type ProgramBrowsingExperienceV1 from 'bundles/naptimejs/resources/programBrowsingExperiences.v1';

const CollectionLengths = new Map<number, number>([
  [1, 3],
  [2, 4],
  [3, 2],
  [4, 2],
]);
const DefaultLength = 2;

export type PropsForWithCatalogCollectionProps = {
  program?: EnterpriseProgramsV1;
  browsingExperienceType?: ProgramBrowsingExperienceV1;
  shouldHideCourseraRecommendations: boolean;
};

export type PropsFromWithCatalogCollectionProps = {
  hasTSPs: boolean;
  hasCuratedCollections: boolean;
  hasBrowseCollections: boolean;
  catalogCollectionCount: number;
  catalogCollectionLength: number;
  catalogCollectionShouldLoadMore: boolean;
  isInDeepBrowseMode: boolean;
};

export const withCatalogCollectionProps = withProps<
  PropsFromWithCatalogCollectionProps,
  PropsForWithCatalogCollectionProps
>(({ program, browsingExperienceType, shouldHideCourseraRecommendations }) => {
  const hasTSPs = Boolean(
    program?.metadata?.linkedTargetSkillProfileIds?.length ||
      (EnterpriseExperiments.get('enableSkillSetsByDefaultDGS') &&
        program?.metadata?.skillSetAvailabilityType === 'ALL_SKILLSETS')
  );
  const hasAcademicDiscipline = Boolean(program?.metadata?.linkedAcademicDisciplineIds?.length);
  const hasCuratedCollections = Boolean(browsingExperienceType?.numCollections);
  // domain or wildcard program will satisfy isInDeepBrowseMode
  const isInDeepBrowseMode = Boolean(browsingExperienceType?.isInDeepBrowseMode);
  const hasBrowseCollections = isInDeepBrowseMode && !shouldHideCourseraRecommendations;
  const catalogCollectionCount = +hasTSPs + +hasCuratedCollections + +hasBrowseCollections + +hasAcademicDiscipline;
  const catalogCollectionLength = CollectionLengths.get(catalogCollectionCount) ?? DefaultLength;
  const catalogCollectionShouldLoadMore = catalogCollectionCount === 1;
  return {
    hasTSPs,
    hasCuratedCollections,
    hasBrowseCollections,
    catalogCollectionCount,
    catalogCollectionLength,
    catalogCollectionShouldLoadMore,
    isInDeepBrowseMode,
  };
});

export const getCatalogCollectionProps = ({
  program,
  browsingExperienceType,
  shouldHideCourseraRecommendations,
}: PropsForWithCatalogCollectionProps): PropsFromWithCatalogCollectionProps => {
  const hasTSPs = Boolean(
    program?.metadata?.linkedTargetSkillProfileIds?.length ||
      (EnterpriseExperiments.get('enableSkillSetsByDefaultDGS') &&
        program?.metadata?.skillSetAvailabilityType === 'ALL_SKILLSETS')
  );
  const hasAcademicDiscipline = Boolean(program?.metadata?.linkedAcademicDisciplineIds?.length);
  const hasCuratedCollections = Boolean(browsingExperienceType?.numCollections);
  // domain or wildcard program will satisfy isInDeepBrowseMode
  const isInDeepBrowseMode = Boolean(browsingExperienceType?.isInDeepBrowseMode);
  const hasBrowseCollections = isInDeepBrowseMode && !shouldHideCourseraRecommendations;
  const catalogCollectionCount = +hasTSPs + +hasCuratedCollections + +hasBrowseCollections + +hasAcademicDiscipline;
  const catalogCollectionLength = CollectionLengths.get(catalogCollectionCount) ?? DefaultLength;
  const catalogCollectionShouldLoadMore = catalogCollectionCount === 1;
  return {
    hasTSPs,
    hasCuratedCollections,
    hasBrowseCollections,
    catalogCollectionCount,
    catalogCollectionLength,
    catalogCollectionShouldLoadMore,
    isInDeepBrowseMode,
  };
};
