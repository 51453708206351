/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================
export enum org_coursera_program_enrollment_ProgramUserProductState {
  AVAILABLE = "AVAILABLE",
  COMPLETED = "COMPLETED",
  ENROLLED = "ENROLLED",
  UNAVAILABLE = "UNAVAILABLE",
}

export enum org_coursera_enterprise_skills_TargetSkillProfileUserGoalState {
  DELETED = "DELETED",
  DISMISSED = "DISMISSED",
  SAVED = "SAVED",
  UNSAVED = "UNSAVED",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
