import * as React from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import _t from 'i18n!nls/s12n-enroll';

const MAX_NUM_OF_HOURS_TO_DISPLAY = 20;

export const getNumberOfHoursToDisplay = (numOfHours: number) =>
  numOfHours > MAX_NUM_OF_HOURS_TO_DISPLAY ? `${MAX_NUM_OF_HOURS_TO_DISPLAY}+` : String(numOfHours);

export const getDurationMessage = (numOfHours: number) =>
  numOfHours > MAX_NUM_OF_HOURS_TO_DISPLAY ? (
    <FormattedMessage message={_t('{numOfHours} hours/week')} numOfHours={getNumberOfHoursToDisplay(numOfHours)} />
  ) : (
    <FormattedMessage
      message={_t('{numOfHours, plural, =1 {1 hour/week} other {# hours/week}}')}
      numOfHours={numOfHours}
    />
  );
