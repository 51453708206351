/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { RenderLeafProps } from 'slate-react';

import { typography2 } from '@coursera/cds-core';

import TextRenderer from 'bundles/cml/shared/components/text/Text';

const styles = {
  // The following is a workaround for a Chromium bug where,
  // if you have an inline at the end of a block,
  // clicking the end of a block puts the cursor inside the inline
  // instead of inside the final {text: ''} node
  // https://github.com/ianstormtaylor/slate/issues/4704#issuecomment-1006696364
  root: css`
    padding-left: 0.1px;
  `,
  variableSubstition: css`
    ${typography2.subtitleMedium};
    color: var(--cds-color-emphasis-primary-content-default);
    background-color: var(--cds-color-emphasis-primary-background-xweak);
    padding: var(--cds-spacing-50) 0;
  `,
  selected: css`
    padding: var(--cds-spacing-50) 0;
    background-color: var(--cds-color-interactive-background-primary-hover-weak);
    cursor: not-allowed;
  `,
};

const Text: React.FC<RenderLeafProps> = (props) => {
  const { leaf, children } = props;
  const contentEditable = leaf.selected ? false : undefined;

  return (
    <TextRenderer
      {...props}
      css={[
        !leaf.text && !leaf.placeholder && styles.root,
        leaf.variableSubstitution && styles.variableSubstition,
        leaf.selected && styles.selected,
      ]}
    >
      <span contentEditable={contentEditable}>{children}</span>
    </TextRenderer>
  );
};

export default Text;
