/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import { Grid, TextField, Typography2 } from '@coursera/cds-core';

import type { AuthoringWidgetSessionProvider } from 'bundles/naptimejs/resources/__generated__/AuthoringWidgetSessionProvidersV1';
import { isValidURL } from 'bundles/widget/components/config/utils/configUtils';

import _t from 'i18n!nls/widget';

export const CONFIG_ERROR_MESSAGES = {
  simple: () => _t("Your changes can't be saved because some of them aren't in a valid format."),
  advanced: () =>
    _t("Your changes can't be saved because the advanced configuration still includes some invalid JSON."),
  json: () => _t("Your changes can't be saved because the configuration still includes some invalid JSON."),
};

type WidgetSessionDraftConfig = AuthoringWidgetSessionProvider['configuration'];

type LabelProps = {
  label: React.ReactElement;
};

export const LabelComponent: React.FC<LabelProps> = ({ label }) => (
  <Grid container>
    <Typography2 component="p">{label}</Typography2>
    <Typography2 component="p" color="error">
      *
    </Typography2>
  </Grid>
);

type Props = {
  config: WidgetSessionDraftConfig;
  handleChange: (val: string, isValid: boolean) => void;
  onConfigChange: (config: WidgetSessionDraftConfig) => void;
  urlError: boolean;
  readOnly?: boolean;
};

export const ExternalUrlConfigTextField: React.FC<Props> = ({
  config,
  handleChange,
  onConfigChange,
  urlError,
  readOnly,
}) => {
  const handleUrlChange = (value: string) => {
    if (!isValidURL(value)) {
      handleChange(value, false);
      return;
    }
    handleChange(value, true);
    onConfigChange({
      ...config,
      url: value,
    });
  };

  return (
    <TextField
      fullWidth
      defaultValue={config.url}
      label={_t('URL')}
      supportText={_t('The URL for the page that you want to appear here.')}
      placeholder="https://www.example.com"
      type="url"
      onChange={(e) => handleUrlChange(e.target.value)}
      renderLabel={(label: React.ReactElement) => <LabelComponent label={label} />}
      validationStatus={urlError ? 'error' : undefined}
      validationLabel={urlError ? _t('Invalid URL') : undefined}
      readOnly={readOnly}
    />
  );
};
