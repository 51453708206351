import URI from 'jsuri';
import Q from 'q';

import API from 'js/lib/api';

import type AuthoringEvaluator from 'bundles/author-code-evaluator/models/AuthoringEvaluator';
import type { RawEvaluatorDraft } from 'bundles/author-code-evaluator/models/EvaluatorDraft';
import type { RawEvaluatorTestCase } from 'bundles/author-code-evaluator/models/EvaluatorTestCase';

const authoringEvaluatorsAPI = API('/api/authoringEvaluators.v1', {
  type: 'rest',
});

export type Metadata = {
  publishedVersion: string;
};

export type AuthoringEvaluatorResponse = {
  id: string;
  draft: RawEvaluatorDraft;
  testCases: Array<RawEvaluatorTestCase>;
  metadata: Metadata;
};

export const get = (id: string): Q.Promise<AuthoringEvaluatorResponse> =>
  Q(authoringEvaluatorsAPI.get(id)).then((response) => response.elements[0]);

export const create = ({
  courseId,
  evaluatorId,
  branchId,
  itemId,
}: {
  courseId: string;
  evaluatorId: string;
  branchId: string;
  itemId: string;
}): Q.Promise<AuthoringEvaluatorResponse> => {
  return Q(
    authoringEvaluatorsAPI.post('', {
      data: {
        courseId,
        parentEvaluatorId: evaluatorId,
        branchId,
        itemId,
      },
    })
  ).then((response) => response.elements[0]);
};

export const save = (authoringEvaluator: AuthoringEvaluator): Q.Promise<AuthoringEvaluatorResponse> => {
  const { id } = authoringEvaluator;

  return Q(
    authoringEvaluatorsAPI.put(id, {
      data: authoringEvaluator.toJSON(),
    })
  ).then((response) => response.elements[0]);
};

export const publish = (authoringEvaluator: AuthoringEvaluator): Q.Promise<AuthoringEvaluatorResponse> => {
  const { id, metadata } = authoringEvaluator;

  const uri = new URI().addQueryParam('action', 'publish').addQueryParam('id', id);

  return Q(
    authoringEvaluatorsAPI.post(uri.toString(), {
      data: { metadata },
    })
  );
};

export const AuthoringEvaluatorApiUtils = { get, create, save, publish };

export default AuthoringEvaluatorApiUtils;
