/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { useQuery } from '@apollo/client';

import Collection from 'bundles/enterprise-collections/components/Collection';
import CollectionDescription from 'bundles/enterprise-collections/components/CollectionDescription';
import CollectionTitle from 'bundles/enterprise-collections/components/CollectionTitle';
import type { EnterpriseProductMetadataConfiguration } from 'bundles/program-common/types/programCommon';
import { Heading } from 'bundles/program-home/components/AutoHeading';
import CatalogSection from 'bundles/program-home/components/single-program/CatalogSection';
import { CollectionsView } from 'bundles/program-home/components/single-program/CuratedCollections';
import ForCreditCurriculumTracksByProgramQuery from 'bundles/program-home/components/single-program/queries/ForCreditCurriculumTracksByProgram.graphql';
import type {
  ForCreditCurriculumTracksByProgramQuery as ForCreditCurriculumTracksByProgramQueryData,
  ForCreditCurriculumTracksByProgramQueryVariables,
} from 'bundles/program-home/components/single-program/queries/__generated__/ForCreditCurriculumTracksByProgram';
import type { OnProductCardClick } from 'bundles/program-home/types/Products';
import { castToStruct } from 'bundles/program-skills-objectives/utils';
import { useCampusWordingForLearner } from 'bundles/unified-common/hooks/useCampusWordingForLearner';

import _t from 'i18n!nls/program-home';

const styles = {
  container: css`
    margin-bottom: 16px;

    .carousel-dots {
      position: relative;
    }
  `,
  header: css`
    margin-top: 12px;
    margin-bottom: 32px;
  `,
};

type PropsFromCaller = {
  programId: string;
  headingLevel: number;
  onProductCardClick: OnProductCardClick;
  isSingleProgram?: boolean;
  allowOrgForSpecializationConfiguration?: boolean;
};

export type Props = PropsFromCaller;

const ConditionalCatalogSectionWrapper: React.FC<{ isSingleProgram: boolean | undefined; children: JSX.Element }> = ({
  isSingleProgram,
  children,
}) => (isSingleProgram ? <CatalogSection>{children}</CatalogSection> : children);

export const ForCreditCollection = ({
  headingLevel,
  onProductCardClick,
  isSingleProgram,
  allowOrgForSpecializationConfiguration,
  programId,
}: Props) => {
  const isCampusWording = useCampusWordingForLearner();
  const { data, loading } = useQuery<
    ForCreditCurriculumTracksByProgramQueryData,
    ForCreditCurriculumTracksByProgramQueryVariables
  >(ForCreditCurriculumTracksByProgramQuery, {
    variables: {
      programId,
      allowOrgForSpecializationConfiguration: true,
    },
    context: { clientName: 'gatewayGql' },
    ssr: false,
    skip: !isCampusWording,
  });

  if (!isCampusWording) {
    // Skip this component for C4B/G programs
    return null;
  }

  if (loading) {
    return (
      <CatalogSection>
        <CollectionsView.Placeholder length={1} />
      </CatalogSection>
    );
  }

  const collection =
    data?.NaptimeQueries?.ProgramCurriculumCollectionsV1Resource?.forCreditCurriculumTracksByProgram?.elements?.[0];

  if (!collection || collection.items.length === 0) {
    return null;
  }

  return (
    <ConditionalCatalogSectionWrapper isSingleProgram={isSingleProgram}>
      <div css={styles.container} data-testid="for-credit-collection">
        <div css={styles.header}>
          <Heading defaultLevel={headingLevel} className="Collection-Title">
            <CollectionTitle title={_t('Earn credit towards your degree with these Coursera courses')} />
          </Heading>
          <div className="Collection-Description">
            <CollectionDescription
              collection={{
                ...collection,
                description: {
                  cml: {
                    dtdId: '',
                    value: `<co-content><text>${_t(
                      'View the courses offered for a limited time and offered for credit by your organization.'
                    )}</text></co-content>`,
                  },
                },
              }}
            />
          </div>
        </div>
        <Collection
          id={collection.id}
          collection={{
            ...castToStruct(collection),
            title: '',
          }}
          onProductCardClick={onProductCardClick}
          productMetadata={collection.programProductMetadata?.elements as EnterpriseProductMetadataConfiguration}
          disableDescription={true}
          allowOrgForSpecializationConfiguration={allowOrgForSpecializationConfiguration}
        />
      </div>
    </ConditionalCatalogSectionWrapper>
  );
};

export default ForCreditCollection;
