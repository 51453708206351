import * as React from 'react';

import { GradientWrapper, StyleSheet, css, font, spacing } from '@coursera/coursera-ui';

import { getTranslatedProductName } from 'bundles/browse/utils';

const styles = StyleSheet.create({
  productTypePill: {
    fontSize: font.xs,
    fontWeight: 'bold',
    width: 'fit-content',
    textDecoration: 'none',
    color: 'black',
    display: 'inline-block',
  },
  withoutGradient: {
    padding: `${spacing.xxs} ${spacing.md}`,
    fontWeight: 'normal',
  },
});

type Props = {
  productTypeName?: string | null;
};

export default class ProductTypePill extends React.Component<Props> {
  getGradientColor = () => {
    return { deg: 0, start: '#F0F0F0', end: '#F0F0F0' };
  };

  render() {
    const { productTypeName } = this.props;
    const productNameTranslated = getTranslatedProductName(productTypeName);

    return (
      <GradientWrapper
        gradient={this.getGradientColor()}
        rootClassName={css(styles.productTypePill, styles.withoutGradient).className}
      >
        {productNameTranslated}
      </GradientWrapper>
    );
  }
}
