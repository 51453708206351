/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect } from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import keysToConstants from 'js/lib/keysToConstants';
import { LONG_DATE_ONLY_DISPLAY, formatDateTimeDisplay } from 'js/utils/DateTimeUtils';

import { Button, Typography, Typography2, breakpoints, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import FinancialAidApprovedModal from 'bundles/payments/components/finaid-2021/FinancialAidApprovedModal';
import { APPROVAL_BUFFER_MILLI_SECS } from 'bundles/payments/components/finaid-2021/constants/limits';
import { FindAidState as APPLICATION_STATE } from 'bundles/payments/components/finaid-2021/enhancers/types';
import type { FinancialAidApplicationStateType } from 'bundles/payments/components/finaid-2021/enhancers/types';
import Modal from 'bundles/phoenix/components/Modal';

import _t from 'i18n!nls/finaid';

const useStyles = (theme: Theme) => ({
  openAidApplicationModal: css({
    [breakpoints.only('xs')]: {
      'div.rc-Modal': {
        'div.c-modal-content': {
          left: '1%',
          bottom: '0px',
          top: 'revert',
        },
      },
    },
  }),
  buttonContainer: css({
    paddingTop: 'var(--cds-spacing-400)',
    paddingBottom: 'var(--cds-spacing-100)',
    display: 'flex',
    [breakpoints.only('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  }),
});
export const MODAL_TYPES = keysToConstants(['COURSE', 'SPECIALIZATION', 'PROFESSIONAL_CERTIFICATE']);
type Props = {
  handleCancelAndEroll: () => void;
  handleClose: () => void;
  type: keyof typeof MODAL_TYPES | null;
  courseName?: string;
  approvalDate?: number | null;
  applicationState?: FinancialAidApplicationStateType | null;
  cartId?: number;
  s12nId?: string;
};
const OpenAidApplicationModal: React.FC<Props> = ({
  handleCancelAndEroll,
  handleClose,
  type,
  courseName,
  approvalDate,
  applicationState,
  cartId,
  s12nId,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  useEffect(() => {
    if (!type) {
      handleClose();
    }
  }, [handleClose, type]);

  const feedbackInfo = (
    <Typography2
      component="p"
      variant="bodyPrimary"
      css={css({
        paddingTop: 'var(--cds-spacing-150)',
      })}
    >
      {!!approvalDate && (
        <FormattedMessage
          message={_t(
            `You should hear back by {approvalDate}. If you don’t want to wait you can cancel your financial aid application and pay for the course yourself.`
          )}
          approvalDate={
            <strong>{formatDateTimeDisplay(approvalDate + APPROVAL_BUFFER_MILLI_SECS, LONG_DATE_ONLY_DISPLAY)}</strong>
          }
        />
      )}
    </Typography2>
  );

  switch (type) {
    case MODAL_TYPES.COURSE:
      if (applicationState === APPLICATION_STATE.PaymentPending && cartId) {
        return <FinancialAidApprovedModal productType={type} cartId={cartId} handleClose={handleClose} />;
      }
      return (
        <div className="rc-OpenAidApplicationModal" css={styles.openAidApplicationModal}>
          <Modal modalName="Open Aid Application Modal" shouldFocusOnXButton={true} handleClose={handleClose}>
            <div className="title-body-container">
              <Typography
                variant="h2semibold"
                className="modal-title center"
                css={css({
                  paddingTop: 'var(--cds-spacing-300)',
                })}
              >
                {_t('You have an open financial aid request for this course')}
              </Typography>
            </div>
            <div
              className="content modal-container"
              css={css({
                paddingTop: 'var(--cds-spacing-300)',
              })}
            >
              <Typography2 component="p" variant="bodyPrimary">
                {_t(
                  `Continuing to the enrollment page will cancel your financial aid application and require a payment.`
                )}
              </Typography2>
              {feedbackInfo}
            </div>
            <div css={styles.buttonContainer}>
              <Button
                css={css({
                  width: '291px',
                  maxWidth: 'unset',
                })}
                onClick={handleClose}
                className="primary cozy"
              >
                {_t('Back to the course page')}
              </Button>
              <Button variant="ghost" onClick={handleCancelAndEroll}>
                {_t('Cancel my application and enroll')}
              </Button>
            </div>
          </Modal>
        </div>
      );
    case MODAL_TYPES.SPECIALIZATION:
    case MODAL_TYPES.PROFESSIONAL_CERTIFICATE:
      if (applicationState === APPLICATION_STATE.PaymentPending && cartId) {
        return (
          <FinancialAidApprovedModal
            handleCancelAndEroll={handleCancelAndEroll}
            s12nId={s12nId}
            cartId={cartId}
            productType={type}
            handleClose={handleClose}
          />
        );
      }
      return (
        <div className="rc-OpenAidApplicationModal" css={styles.openAidApplicationModal}>
          <Modal modalName="Open Aid Application Modal" shouldFocusOnXButton={true} handleClose={handleClose}>
            <div className="title-body-container">
              <Typography
                variant="h2semibold"
                className="modal-title center"
                css={css({
                  paddingTop: 'var(--cds-spacing-300)',
                })}
              >
                {type === MODAL_TYPES.PROFESSIONAL_CERTIFICATE
                  ? _t('You have an open aid request for a course in this Professional Certificate')
                  : _t('You have an open aid request for a course in this Specialization')}
              </Typography>
            </div>
            <div
              className="content modal-container"
              css={css({
                paddingTop: 'var(--cds-spacing-300)',
              })}
            >
              <Typography2 component="p" variant="bodyPrimary">
                <FormattedMessage
                  message={_t(
                    `Continuing to the enrollment page will cancel your financial aid application for {courseName} and require a payment.`
                  )}
                  courseName={<strong>{courseName}</strong>}
                />
              </Typography2>
              {feedbackInfo}
            </div>
            <div css={styles.buttonContainer}>
              <Button
                css={css({
                  maxWidth: 'unset',
                })}
                onClick={handleClose}
                className="primary cozy"
              >
                {type === MODAL_TYPES.PROFESSIONAL_CERTIFICATE
                  ? _t('Back to the professional certificate page')
                  : _t('Back to the specialization page')}
              </Button>
              <Button variant="ghost" onClick={handleCancelAndEroll}>
                {_t('Cancel my application and enroll')}
              </Button>
            </div>
          </Modal>
        </div>
      );
    default:
      return null;
  }
};

export default OpenAidApplicationModal;
