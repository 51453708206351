/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import Imgix from 'js/components/Imgix';

import { Button, Dialog, Hidden, Typography2, breakpoints } from '@coursera/cds-core';
import type { ButtonProps } from '@coursera/cds-core';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import type { StepThreeCopyType } from 'bundles/coursera-plus/components/subscriptionTiers/upgradeToPlus/upgradeCopies';
import { logo } from 'bundles/coursera-plus/utils/courseraPlusUtils';
import TrackedDiv from 'bundles/page/components/TrackedDiv';

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps>(Button);

const styles = {
  logo: css`
    margin-left: var(--cds-spacing-600);
    ${breakpoints.down('xs')} {
      margin: var(--cds-spacing-200) var(--cds-spacing-400);
    }
  `,
  heading: css`
    font-size: 26px;
    padding-bottom: 0;
    margin: var(--cds-spacing-200) 0 var(--cds-spacing-200);
    ${breakpoints.down('xs')} {
      padding: 0;
      margin: var(--cds-spacing-200) var(--cds-spacing-400);
    }
  `,
  content: css`
    ${breakpoints.down('xs')} {
      padding: 0;
      margin: var(--cds-spacing-200) var(--cds-spacing-400);
    }
  `,
  actions: css`
    padding-top: 16px;
    ${breakpoints.down('xs')} {
      padding: 0;
      margin: var(--cds-spacing-400);
    }
  `,
  actionPointsTitle: css`
    font-size: 20px;
    margin-bottom: var(--cds-spacing-200);
  `,
};

type Props = {
  copy: StepThreeCopyType;
  courseId?: string;
  s12nId?: string;
  handleCloseModal: () => void;
  isPrimaryCtaLoading?: boolean;
};

export const StepThreeUpgradeModalContent: React.FC<Props> = ({
  copy,
  courseId,
  s12nId,
  isPrimaryCtaLoading,
  handleCloseModal,
}) => {
  const trackingData = { courseId, s12nId };
  const { Content, HeadingGroup, Actions } = Dialog;

  const getPrimaryCta = (isFullWidth: boolean) => (
    <TrackedButton
      variant="primary"
      trackingName="ideal_upgrade_step_three_confirm_button"
      data-testId="ideal-upgrade-step-three-confirm-button"
      trackingData={trackingData}
      onClick={handleCloseModal}
      loading={isPrimaryCtaLoading}
      fullWidth={isFullWidth}
    >
      {copy.primaryCta}
    </TrackedButton>
  );

  return (
    <TrackedDiv
      trackingName="ideal_upgrade_confirm_modal"
      data-testId="ideal-upgrade-confirm-modal"
      trackClicks={false}
      withVisibilityTracking={true}
      data={trackingData}
    >
      <div css={styles.logo}>
        <Imgix src={logo.PLUS_FULL_BLUE} alt={copy.courseraPlus} maxWidth={159} maxHeight={16} />
      </div>
      <HeadingGroup css={styles.heading}>{copy.title}</HeadingGroup>
      <Content id="dialog-content" css={styles.content}>
        <Typography2 component="h3" variant="subtitleMedium" css={styles.actionPointsTitle}>
          {copy.actionPointsTitle}
        </Typography2>
        <ul>
          {copy.actionPointsList.map((actionPoint) => (
            <li data-testId="action-point" key={actionPoint}>
              <Typography2 component="p">{actionPoint}</Typography2>
            </li>
          ))}
        </ul>
      </Content>
      <Actions css={styles.actions}>
        <Hidden xsDown>{getPrimaryCta(false)}</Hidden>
        <Hidden smUp>{getPrimaryCta(true)}</Hidden>
      </Actions>
    </TrackedDiv>
  );
};

export default StepThreeUpgradeModalContent;
