import gql from 'graphql-tag';

export const ProgramCurriculumLatestEnrolledCoursesForOrg = gql`
  query ProgramCurriculumLatestEnrolledCoursesForOrgQuery($thirdPartyOrganizationId: String!, $userId: String!) {
    ProgramCurriculumProductsV1 @naptime {
      latestEnrolledCourseActivitiesByThirdPartyOrganizationAndUser(
        thirdPartyOrganizationId: $thirdPartyOrganizationId
        userId: $userId
        limit: 1
      ) {
        elements {
          id
          productItemId
          productType
          programId
          productState
        }
      }
    }
  }
`;

export default ProgramCurriculumLatestEnrolledCoursesForOrg;
