import * as React from 'react';

import { isHotkey } from 'is-hotkey';

import Toolbar from 'bundles/cml/editor/components/toolbars/Toolbar';
import { ToolButtonFactory } from 'bundles/cml/editor/components/toolbars/constants';
import FloatingMenu from 'bundles/cml/shared/components/menu/FloatingMenu';
import type { ToolbarToolsKeys, ToolsKeys } from 'bundles/cml/shared/utils/customTools';

type Props = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  isDisabled?: boolean;
  tools: ToolsKeys[];
  pageless?: boolean;
  menuDirection?: 'top' | 'bottom';
  overflowTools: ToolbarToolsKeys[];
  autoFocus: boolean;
};

const isEscapeKey = isHotkey('escape');

const MoreFloatingMenu: React.FC<Props> = ({ anchorEl, onClose, overflowTools, tools, autoFocus, ...props }) => {
  const handleChange = () => {
    onClose();
  };

  const handleKeyDown = ({ nativeEvent: event }: React.KeyboardEvent) => {
    if (isEscapeKey(event)) {
      onClose();
      anchorEl?.focus();
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    // if focus moves out of the overflow menu, close it
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      onClose();
    }
  };

  const expandButtons = overflowTools.map((tool: ToolbarToolsKeys) => {
    const ToolbarButton = ToolButtonFactory[tool]();
    return <ToolbarButton key={tool} {...props} onChange={handleChange} tools={tools} />;
  });

  return (
    <FloatingMenu
      anchorEl={anchorEl}
      pageless
      placement="bottom-end"
      enableReferenceHidden={false}
      enableEscaped={false}
      onClose={onClose}
    >
      <Toolbar onKeyDown={handleKeyDown} onBlur={handleBlur} autoFocus={autoFocus}>
        {expandButtons}
      </Toolbar>
    </FloatingMenu>
  );
};

export default MoreFloatingMenu;
