import { requireFields } from 'bundles/naptimejs/util/requireFieldsDecorator';

import { ProgramBrowsingExperiencesV1NaptimeResource } from './__generated__/ProgramBrowsingExperiencesV1';

const BrowsingExperienceType = {
  SMALL_SUBSET: 'SMALL_SUBSET',
  DEEP_BROWSE: 'DEEP_BROWSE',
};

class ProgramBrowsingExperience extends ProgramBrowsingExperiencesV1NaptimeResource {
  static RESOURCE_NAME = 'programBrowsingExperiences.v1' as const;

  @requireFields('browsingExperience')
  get isInDeepBrowseMode() {
    return this.browsingExperience === BrowsingExperienceType.DEEP_BROWSE;
  }

  @requireFields('browsingExperience')
  get isInSmallSubsetMode() {
    return this.browsingExperience === BrowsingExperienceType.SMALL_SUBSET;
  }
}

export default ProgramBrowsingExperience;
