import type { CourseraTierSubscriptions_BillingCycle as BillingCycleType } from '__generated__/graphql-types';

import API from 'js/lib/api';
import { tupleToStringKey } from 'js/lib/stringKeyTuple';

import EnrollmentChoiceTypes from 'bundles/enroll-course/common/EnrollmentChoiceTypes';
import * as apiClient from 'bundles/enroll-course/lib/apiClient';
import { checkSessionsV2Epic } from 'bundles/enroll-course/lib/sessionsV2ExperimentUtils';
import { enrollProduct as enrollProductThroughCourseraPlus } from 'bundles/enroll/lib/courseraPlusEnrollmentsApi';
import CartsV2 from 'bundles/naptimejs/resources/carts.v2';
import { redirectToCheckout } from 'bundles/payments-common/utils/redirectToCheckout';
import {
  COURSERA_PLUS,
  SPECIALIZATION,
  SPECIALIZATION_PREPAID,
  SPECIALIZATION_SUBSCRIPTION,
  VERIFIED_CERTIFICATE,
} from 'bundles/payments/common/ProductType';
import AuxiliaryInfo from 'bundles/payments/models/cart/auxiliaryInfo';
import CourseEnrollInfoItem from 'bundles/payments/models/cart/courseEnrollInfoItem';
import createCourseraPlusSubscriptionCart from 'bundles/payments/promises/createCourseraPlusSubscriptionCart';
import createCredentialTrackCart from 'bundles/payments/promises/createCredentialTrackCart';
import createCredentialTrackSubscriptionCart from 'bundles/payments/promises/createCredentialTrackSubscriptionCart';
import createGuidedProjectCart from 'bundles/payments/promises/createGuidedProjectCart';
import createPrepaidCart from 'bundles/payments/promises/createPrepaidCart';
import createProjectCart from 'bundles/payments/promises/createProjectCart';
import createSpecializationCart from 'bundles/payments/promises/createSpecializationCart';
import createVCCart from 'bundles/payments/promises/createVCCart';
import { SOURCE_PROMOTION_BANNER } from 'bundles/promotions/constants';
import { redeemPromotion } from 'bundles/promotions/utils/productDiscountPromoUtils';
import type { CouponResponse, PromoErrorResponse } from 'bundles/promotions/utils/productDiscountPromoUtils';

interface Data {
  [key: string]: string | number | Data;
}

function getCourseAuxiliaryCartInfo(courseId?: string) {
  const options: { auxiliaryCartInfo?: AuxiliaryInfo } = {};
  if (courseId) {
    options.auxiliaryCartInfo = new AuxiliaryInfo([
      new CourseEnrollInfoItem({
        definition: { courseId },
      }),
    ]).toJSON();
  }
  return options;
}

function getCredentialTrackSubscriptionPromise(credentialTrackProductId: string, productType: string, data?: Data) {
  const options: Data = {};
  if (data?.couponId) {
    options.couponId = data.couponId;
  }
  options.productType = productType;
  return Promise.resolve(createCredentialTrackSubscriptionCart(credentialTrackProductId, options));
}

function getCredentialTrackPromise(credentialTrackProductId: string, productType: string, data?: Data) {
  const options: Data = {};
  if (data?.couponId) {
    options.couponId = data.couponId;
  }
  options.productType = productType;
  return Promise.resolve(createCredentialTrackCart(credentialTrackProductId, options));
}

function getSpecializationSubscriptionPromise(
  productSkuId: string,
  courseId?: string,
  skipWelcomeEmail = false,
  data?: Data
) {
  let options: Data = {};
  if (courseId) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ auxiliaryCartInfo?: AuxiliaryInfo | undefi... Remove this comment to see the full error message
    options = getCourseAuxiliaryCartInfo(courseId);
  }
  if (data?.couponId) {
    options.couponId = data.couponId;
  }
  options.productType = SPECIALIZATION_SUBSCRIPTION;

  return Promise.resolve(createSpecializationCart(productSkuId, options, skipWelcomeEmail));
}

function getSpecializationPrepaidPromise(courseId: string, data?: Data) {
  let options: Data = {};
  const id = (data?.paymentPassOption as Data)?.productItemId as string;

  if (courseId) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ auxiliaryCartInfo?: AuxiliaryInfo | undefi... Remove this comment to see the full error message
    options = getCourseAuxiliaryCartInfo(courseId);
  }
  if (data?.couponId) {
    options.couponId = data.couponId;
  }
  options.productType = SPECIALIZATION_PREPAID;

  return Promise.resolve(createPrepaidCart(id, options));
}

function getCourseraPlusPrepaidPromise(courseId: string, data?: Data) {
  let options: Data = {};
  const id = (data?.paymentPassOption as Data)?.productItemId as string;

  if (courseId) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ auxiliaryCartInfo?: AuxiliaryInfo | undefi... Remove this comment to see the full error message
    options = getCourseAuxiliaryCartInfo(courseId);
  }
  if (data?.couponId) {
    options.couponId = data.couponId;
  }
  options.productType = COURSERA_PLUS;
  const productIdToEnroll = (data?.s12nId as string) || null;
  if (productIdToEnroll) {
    options.metadata = {
      'org.coursera.payment.CourseraPlusCartItemMetadata': {
        productEnrollmentInformation: {
          productIdToEnroll: tupleToStringKey([SPECIALIZATION, productIdToEnroll]),
        },
      },
    };
  }

  return Promise.resolve(createPrepaidCart(id, options));
}

type ProductEnrollmentInputType = {
  s12nId?: string;
  courseId?: string;
};

export function getProductIdForCourseraPlus({ courseId, s12nId }: ProductEnrollmentInputType) {
  if (s12nId) {
    return tupleToStringKey([SPECIALIZATION, s12nId]);
  } else if (courseId) {
    return tupleToStringKey([VERIFIED_CERTIFICATE, courseId]);
  } else {
    throw new Error('Cannot create Coursera Plus subscription for unsupported product type');
  }
}

export function getCourseIdToGrantMembershipForCourseraPlus({ courseId, s12nId }: ProductEnrollmentInputType) {
  // If enrolling in a s12n, need a specific courseId to also grant membership for
  if (s12nId && courseId) {
    return courseId;
  } else if (courseId) {
    return undefined;
  } else {
    throw new Error('Cannot create Coursera Plus subscription without specific course membership to grant');
  }
}

export const getProductEnrollmentInformationFromCourseAndS12nIds = ({
  s12nId,
  courseId,
}: ProductEnrollmentInputType) => {
  if (!s12nId && !courseId) {
    return undefined;
  }

  const productIdToEnroll = getProductIdForCourseraPlus({ s12nId, courseId });
  const courseIdToGrantMembership = getCourseIdToGrantMembershipForCourseraPlus({ s12nId, courseId });
  if ((s12nId || courseId) && !productIdToEnroll) {
    throw new Error("Couldn't find a product id to enroll in");
  }

  return {
    productIdToEnroll: productIdToEnroll as string,
    ...(courseIdToGrantMembership ? { courseIdToGrantMembership } : {}),
  };
};

function getCourseraPlusSubscriptionPromise(
  productId?: string,
  courseIdToGrantMembership?: string,
  productItemId?: string,
  couponId?: number,
  currencyCode?: string,
  bundleId?: string
) {
  return Promise.resolve(
    createCourseraPlusSubscriptionCart(
      productId,
      courseIdToGrantMembership,
      productItemId,
      couponId,
      currencyCode,
      bundleId
    )
  );
}

/**
 * @type {Object}
 *   <EnrollmentChoiceType>: {Function} that takes in product properties and
 *     returns a promise that handles submit action for the choice type. data is
 *     passed from CourseEnrollModal by parsing data-* field in the top level element
 *     exported by this file.
 */
export const choiceTypeToHandleSubmitPromise = {
  [EnrollmentChoiceTypes.BULKPAY_FULL_SPECIALIZATION]: ({ courseId, data }: { courseId: string; data: Data }) => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
    return Promise.resolve(createSpecializationCart(data.s12nId, getCourseAuxiliaryCartInfo(courseId)));
  },
  [EnrollmentChoiceTypes.BULKPAY_REMAINING_SPECIALIZATION_COURSES]: ({
    courseId,
    data,
  }: {
    courseId: string;
    data: Data;
  }) => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
    return Promise.resolve(createSpecializationCart(data.s12nId, getCourseAuxiliaryCartInfo(courseId)));
  },
  [EnrollmentChoiceTypes.PURCHASE_SINGLE_COURSE]: ({ courseId, data }: { courseId: string; data: Data }) => {
    return Promise.resolve(createVCCart(courseId, data));
  },
  [EnrollmentChoiceTypes.ENROLL_COURSE]: ({ courseId }: { courseId: string }) => {
    return apiClient.enrollInCourseForFreePromise(courseId);
  },
  [EnrollmentChoiceTypes.AUDIT_COURSE]: ({ courseId }: { courseId: string }) => {
    return apiClient.enrollInCourseForFreePromise(courseId);
  },
  [EnrollmentChoiceTypes.ENROLL_COURSE_WITH_FULL_DISCOUNT]: ({ courseId }: { courseId: string }) => {
    const userEntitlementsApi = API('/api/userEntitlements.v1', { type: 'rest' });
    const productId = tupleToStringKey([VERIFIED_CERTIFICATE, courseId]);
    return Promise.resolve(userEntitlementsApi.post('', { data: { productId } }));
  },
  [EnrollmentChoiceTypes.ENROLL_THROUGH_PROGRAM]: ({
    courseId,
    s12nId,
    data,
  }: {
    courseId?: string;
    s12nId?: string;
    data: Data;
  }) => {
    const programId = data.programId as string;

    if (s12nId) {
      return apiClient.enrollInS12nWithProgram(s12nId, programId);
    } else if (courseId) {
      return apiClient.enrollInCourseWithProgram(courseId, programId);
    } else {
      return Promise.reject(new Error('Cannot enroll through program without courseId or s12nId'));
    }
  },
  [EnrollmentChoiceTypes.ENROLL_THROUGH_GROUP]: ({
    courseId,
    s12nId,
    data,
  }: {
    courseId: string;
    s12nId: string;
    data: Data;
  }) => {
    const groupId = data.groupId as string;

    if (s12nId) {
      return apiClient.enrollInS12nWithGroup(s12nId, groupId);
    } else if (courseId) {
      return apiClient.enrollInCourseWithGroup(courseId, groupId);
    } else {
      return Promise.reject(new Error('Cannot enroll through group without courseId or s12nId'));
    }
  },
  [EnrollmentChoiceTypes.ENROLL_THROUGH_S12N_PREPAID]: ({ courseId, data }: { courseId: string; data: Data }) => {
    return getSpecializationPrepaidPromise(courseId, data);
  },
  [EnrollmentChoiceTypes.ENROLL_THROUGH_S12N_SUBSCRIPTION]: ({
    productSkuId,
    courseId,
    skipWelcomeEmail,
    data,
  }: {
    productSkuId: string;
    courseId: string;
    skipWelcomeEmail: boolean;
    data: Data;
  }) => {
    return getSpecializationSubscriptionPromise(productSkuId, courseId, skipWelcomeEmail, data);
  },
  [EnrollmentChoiceTypes.ENROLL_THROUGH_S12N_SUBSCRIPTION_TRIAL]: ({
    productSkuId,
    courseId,
    skipWelcomeEmail,
    data,
  }: {
    productSkuId: string;
    courseId?: string;
    skipWelcomeEmail: boolean;
    data: Data;
  }) => {
    return getSpecializationSubscriptionPromise(productSkuId, courseId, skipWelcomeEmail, data);
  },
  [EnrollmentChoiceTypes.PURCHASE_THROUGH_COURSERA_PLUS]: ({ courseId, data }: { courseId: string; data: Data }) => {
    return getCourseraPlusPrepaidPromise(courseId, data);
  },
  [EnrollmentChoiceTypes.SUBSCRIBE_TO_COURSERA_PLUS]: ({
    courseId,
    s12nId,
    isSubscriptionOnly,
    bundleId,
    productItemId,
    data,
  }: {
    courseId?: string;
    s12nId?: string;
    isSubscriptionOnly?: boolean;
    bundleId?: string;
    productItemId: string;
    data: Data;
  }) => {
    let productId;
    let courseIdToGrantMembership;

    // `isSubscriptionOnly` specifically indicates user is subscribing to Coursera Plus without enrollment
    if (!isSubscriptionOnly) {
      productId = getProductIdForCourseraPlus({ courseId, s12nId });
      courseIdToGrantMembership = getCourseIdToGrantMembershipForCourseraPlus({ courseId, s12nId });
    }
    return getCourseraPlusSubscriptionPromise(
      productId,
      courseIdToGrantMembership,
      productItemId,
      data?.couponId as number,
      data?.currencyCode as string,
      bundleId
    );
  },
  [EnrollmentChoiceTypes.ENROLL_THROUGH_COURSERA_PLUS]: ({
    courseId,
    s12nId,
  }: {
    courseId?: string;
    s12nId?: string;
  }) => {
    const productId = getProductIdForCourseraPlus({ courseId, s12nId });
    const courseIdToGrantMembership = getCourseIdToGrantMembershipForCourseraPlus({ courseId, s12nId });
    return enrollProductThroughCourseraPlus(productId, courseIdToGrantMembership);
  },
  // Rename to ENROLL_THROUGH_CREDENTIALTRACK_SUBSCRIPTION_V2 after cleaning up V1 code
  [EnrollmentChoiceTypes.ENROLL_THROUGH_CREDENTIALTRACK_SUBSCRIPTION]: ({
    credentialTrackProductId,
    productType,
    data,
  }: {
    credentialTrackProductId: string;
    productType: string;
    data: Data;
  }) => {
    return getCredentialTrackSubscriptionPromise(credentialTrackProductId, productType, data);
  },
  [EnrollmentChoiceTypes.ENROLL_THROUGH_CREDENTIALTRACK]: ({
    credentialTrackProductId,
    productType,
    data,
  }: {
    credentialTrackProductId: string;
    productType: string;
    data: Data;
  }) => {
    return getCredentialTrackPromise(credentialTrackProductId, productType, data);
  },
  [EnrollmentChoiceTypes.ENROLL_GUIDED_PROJECT]: ({ courseId, data }: { courseId: string; data: Data }) => {
    return createGuidedProjectCart(courseId, data);
  },
  [EnrollmentChoiceTypes.ENROLL_PROJECT]: ({ courseId, data }: { courseId: string; data: Data }) => {
    return createProjectCart(courseId, data);
  },
};

export type CHOICES_TYPE_WITH_SUBMIT_PROMISE = keyof typeof choiceTypeToHandleSubmitPromise;

export const doesChoiceTypeHaveSubmitHandler = (choiceType: string) => {
  return (
    Object.prototype.hasOwnProperty.call(choiceTypeToHandleSubmitPromise, choiceType) ||
    choiceType === EnrollmentChoiceTypes.ENROLL_THROUGH_PROGRAM_INVITATION
  );
};

export type SubmitEnrollmentPromiseOptions = {
  courseId?: string;
  s12nId?: string;
  productSkuId?: string;
  toTierBillingCycle?: BillingCycleType;
  skipWelcomeEmail?: boolean;
  credentialTrackProductId?: string;
  isSubscriptionOnly?: boolean;
  productType?: string;
  data?: Data;
  productItemId?: string;
  bundleId?: string;
};

export const submitEnrollmentPromise = ({
  handleSubmitPromise,
  options,
  promoCode,
  additionalParams,
  isFinaid,
}: {
  handleSubmitPromise: $TSFixMe;
  options: SubmitEnrollmentPromiseOptions;
  promoCode?: string;
  additionalParams?: Record<string, string>;
  isFinaid?: boolean;
}) => {
  const queryParams = { ...additionalParams };
  const promiseOptions = { ...options };
  if (promoCode) {
    queryParams.source = SOURCE_PROMOTION_BANNER;
  }

  // redeemPromotion passes through if no promoCode
  return redeemPromotion(promoCode)
    .then((promoData) => {
      const couponId = (promoData as CouponResponse)?.couponId;
      const promoErrorCode = (promoData as PromoErrorResponse)?.promoErrorCode;

      if (couponId) {
        promiseOptions.data = { ...options.data, couponId };
      } else if (promoErrorCode) {
        queryParams.promoErrorCode = promoErrorCode;
      }
    })
    .then(() => {
      const { courseId } = promiseOptions;
      return courseId ? checkSessionsV2Epic(courseId) : Promise.resolve();
    })
    .then(() => {
      return handleSubmitPromise(promiseOptions);
    })
    .then((cart?: CartsV2) => {
      // Not all enrollment submission promises will result in a cart
      // If a cart was not created, enrollment already occurred
      if (cart instanceof CartsV2) {
        redirectToCheckout(cart, queryParams, isFinaid);
        return { cart };
      } else {
        return { didEnroll: true };
      }
    });
};
