import URI from 'jsuri';
import Q from 'q';

import logger from 'js/app/loggerSingleton';
import user from 'js/lib/user';

import { apiClient } from 'bundles/account-settings/utils/UserPreferenceUtils';

export const setOrUpdateRecentlyViewedXDP = (productId: $TSFixMe, sessionId: $TSFixMe) => {
  const uri = new URI()
    .addQueryParam('action', 'setOrUpdateRecentlyViewedXDP')
    .addQueryParam('sessionId', 'SESSION~' + sessionId)
    .addQueryParam('productId', productId);
  if (user.isAuthenticatedUser()) {
    uri.addQueryParam('id', user.get().id);
  }
  return Q(apiClient.post(uri.toString())).fail((err) => {
    logger.error('Could not POST user preferences for RECENTLY_VIEWED_XDP preference key');
  });
};
