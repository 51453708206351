/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import Imgix from 'js/components/Imgix';

import { Typography2 } from '@coursera/cds-core';

import { logo } from 'bundles/coursera-plus/utils/courseraPlusUtils';
import { LEARNING_PRODUCTS } from 'bundles/search-common/constants';
import { chooseCourseraLite } from 'bundles/search-common/providers/LearnerProvider';

import _t from 'i18n!nls/product-card';

const styles = {
  cPlusContainer: css`
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  `,
  cPlusLogo: css`
    margin-right: 8px;
    border-radius: 4px;
  `,
};

type Props = {
  ownsCourseraLite: boolean;
  ownsCourseraPlus: boolean;
  isPartOfCourseraPlus: boolean;
  productType?: (typeof LEARNING_PRODUCTS)[keyof typeof LEARNING_PRODUCTS];
};

const ProductCardCourseraPlus = ({ ownsCourseraLite, ownsCourseraPlus, isPartOfCourseraPlus, productType }: Props) => {
  if ((!ownsCourseraLite && !ownsCourseraPlus) || !isPartOfCourseraPlus) {
    return null;
  }

  const productsPartOfLite: Array<(typeof LEARNING_PRODUCTS)[keyof typeof LEARNING_PRODUCTS]> = [
    LEARNING_PRODUCTS.Course,
    LEARNING_PRODUCTS.Specialization,
    LEARNING_PRODUCTS.ProfessionalCertificate,
  ];

  // Only show Coursera Lite badge if the user owns Coursera Lite and the product is a course, s12n, or Prof Cert
  // because other products (Guided Projects, Projects) are not included with Coursera Lite
  // Note: this is only a stopgap until we properly add a separate Algolia attribute for `isPartOfCourseraLite`
  // instead of the current hacky solution where both Lite and Plus use the same attribute `isPartOfCourseraPlus`
  const chooseLite = chooseCourseraLite(ownsCourseraLite) && productType && productsPartOfLite.includes(productType);

  const src = chooseLite ? logo.LITE_PILL_BLUE : logo.PLUS_PILL_BLUE;
  const alt = chooseLite ? _t('Coursera Lite') : _t('Coursera Plus');
  const message = chooseLite ? _t('Included with Coursera Lite') : _t('Included with Coursera Plus');

  return (
    <div data-testid="product-card-coursera-plus" css={styles.cPlusContainer}>
      <Imgix src={src} alt={alt} maxWidth={41} maxHeight={16} css={styles.cPlusLogo} aria-hidden={true} />
      <Typography2 component="p" variant="bodySecondary" color="supportText">
        {message}
      </Typography2>
    </div>
  );
};

export default ProductCardCourseraPlus;
