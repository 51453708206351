import type { CarouselType } from '@coursera/event-pulse-types';

import type { CollectionEntity } from 'bundles/collections-carousel/types/CarouselCollection';
import type { ProductCardV2Type } from 'bundles/product-card/components/types/ProductCardV2';
import { CAROUSEL_TYPES } from 'bundles/program-common/constants/cardTypes';

export const DISCO_ENTITY_TO_PRODUCT_TYPES = {
  DiscoveryCollections_course: 'COURSE',
  DiscoveryCollections_guidedProject: 'GUIDED PROJECT',
  DiscoveryCollections_specialization: 'SPECIALIZATION',
  DiscoveryCollections_project: 'PROJECT',
  DiscoveryCollections_professionalCertificate: 'PROFESSIONAL CERTIFICATE',
  DiscoveryCollections_degree: 'DEGREE',
  DiscoveryCollections_masterTrack: 'MASTERTRACK',
  DiscoveryCollections_Clip: 'VIDEO',
} as const;

export const mapToCardProps = (entity: CollectionEntity, entityIndex: number): ProductCardV2Type => {
  return {
    cardProps: {
      ...entity,
      cardPosition: entityIndex,
    },
    objectUrl: entity.link,
    forceOpenInNewTab: entity.forceOpenInNewTab,
    ownsCourseraPlus: false,
    ownsCourseraLite: false,
  };
};
export const getDataE2ETag = (collectionIdForTracking: string) => {
  switch (String(collectionIdForTracking)) {
    case 'degree':
      return 'degree-card-collection';
    case 'mastertrack':
      return 'mastertrack-card-collection';
    case 'university-certificate':
      return 'university-certificate-card-collection';
    default:
      return 'mixed-card-collection';
  }
};
export const getCollectionType = (input: string | undefined): CarouselType => {
  if (!input) {
    return 'curated';
  }

  const parts = input.split(':');

  // Extract the first part and convert it to lowercase
  const type = parts[0].toLowerCase();
  const carouselTypes = Object.values(CAROUSEL_TYPES);

  if (type === 'degree') {
    // Check if the lowercase first part is a valid CarouselType
    return CAROUSEL_TYPES.RECONFIGURABLE_COMPONENTS;
  } else if (carouselTypes.includes(type as CarouselType)) {
    return type as CarouselType;
  } else {
    return CAROUSEL_TYPES.CURATED;
  }
};
