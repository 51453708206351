import { useContext } from 'react';

import PageContext from 'bundles/coursera-plus-landing-page/contentfulData/PageContext';
import type {
  LandingPageDataContext,
  LandingPageExtended,
} from 'bundles/coursera-plus-landing-page/contentfulData/PageContext';

export const useLandingPageData = (): LandingPageDataContext => {
  const context = useContext<LandingPageDataContext | undefined>(PageContext);
  if (!context) {
    throw new Error('Missing LandingPageDataContext. Make sure you wrap your page with a data provider.');
  }
  return context;
};

export const useLandingPageExtended = (): LandingPageExtended => {
  return useLandingPageData().pageController.landingPageExtended;
};

export const useLandingPageExtendedMaybe = () => {
  const context = useContext<LandingPageDataContext | undefined>(PageContext);
  if (context) {
    return context.pageController.landingPageExtended;
  }
  return undefined;
};
