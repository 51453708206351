/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useState } from 'react';
import type { MutationFn } from 'react-apollo';
import { Mutation } from 'react-apollo';

import logger from 'js/app/loggerSingleton';
import { SHORT_MONTH_DAY_DISPLAY, formatDateTimeDisplay } from 'js/utils/DateTimeUtils';

import type { SelectFieldProps } from '@coursera/cds-core';
import { Button, Dialog, SelectField, SelectOption, Typography2, useTheme } from '@coursera/cds-core';
import { ArrowNextIcon } from '@coursera/cds-icons';

import LoadingIcon from 'bundles/courseraLoadingIcon/LoadingIcon';
import RemovePreEnrollMutation from 'bundles/enroll/api/RemovePreEnrollMutation.graphql';
import type {
  RemovePreEnrollMutationMutation,
  RemovePreEnrollMutationMutationVariables,
} from 'bundles/enroll/api/__generated__/RemovePreEnrollMutation';
import { useCourseEnrollModalData } from 'bundles/enroll/data/usePageData';
import type { ApiError } from 'bundles/enroll/utils/errorUtils';
import { getS12nOrder } from 'bundles/enroll/utils/mixAndMatchUtils';
import TrackedButton from 'bundles/page/components/TrackedButton';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { getS12nDisplayName } from 'bundles/s12n-common/lib/s12nProductVariantUtils';

import _t from 'i18n!nls/enroll';

const { HeadingGroup, Content, Actions } = Dialog;

export type Props = {
  handleClose: () => void;
  automaticallyEnroll?: boolean;
};

const CancelPreEnrollS12nSelectModal: React.FC<Props> = ({ handleClose, automaticallyEnroll }) => {
  const theme = useTheme();
  const { course, s12ns, enrollmentAvailableChoices } = useCourseEnrollModalData();
  const [isCancelling, setIsCancelling] = useState(false);
  const [isSuccessfullyUnenrolled, setIsSuccessfullyUnenrolled] = useState(false);
  const [enrollmentError, setEnrollmentError] = useState<ApiError | undefined>(undefined);
  const [openModal, setOpenModal] = useState(true);

  // Filter s12ns by pre enrolled s12nIds
  const orderedPreEnrolledS12ns = s12ns
    ? getS12nOrder(
        course.id,
        s12ns?.filter((s12n) => enrollmentAvailableChoices.preEnrolledS12nIds.includes(s12n.id))
      )
    : undefined;
  const [selectedS12nId, setSelectedS12nId] = useState<string | undefined>(undefined);
  const preEnrollmentId = enrollmentAvailableChoices.getPreEnrollmentReasonMetadata(selectedS12nId)?.preEnrollmentId;

  if (!orderedPreEnrolledS12ns) {
    logger.error('Unable to render CancelPreEnrollModal');
    return null;
  }

  const onClose = () => {
    setOpenModal(false);
    handleClose();
  };

  const closeAndRefresh = () => {
    onClose();
    window.location.reload();
  };

  const handleRemove = (
    removePreEnrollUser: MutationFn<RemovePreEnrollMutationMutation, RemovePreEnrollMutationMutationVariables>
  ) => {
    if (!preEnrollmentId) {
      setEnrollmentError({});
      return;
    }
    setIsCancelling(true);
    setIsSuccessfullyUnenrolled(false);
    setEnrollmentError(undefined);

    removePreEnrollUser({
      variables: {
        preEnrollmentId,
      },
    })
      .then((result) => {
        const onSuccess = result?.data?.removePreEnrollment;

        if (onSuccess) {
          setIsSuccessfullyUnenrolled(true);
          setIsCancelling(false);
        }
      })
      .catch((error: ApiError) => {
        setEnrollmentError(error);
      });
  };

  if (enrollmentError) {
    return (
      <Dialog
        onClose={onClose}
        open={openModal}
        css={css`
          text-align: center;
        `}
      >
        <HeadingGroup>{_t('Could not unenroll user. Please try again later.')}</HeadingGroup>
        <Actions>
          <Button variant="primary" onClick={onClose}>
            {_t('Close')}
          </Button>
        </Actions>
      </Dialog>
    );
  }

  if (isSuccessfullyUnenrolled) {
    return (
      <Dialog
        onClose={closeAndRefresh}
        open={openModal}
        css={css`
          text-align: center;
        `}
      >
        <HeadingGroup>{_t('You successfully cancelled your pre-enrollment')}</HeadingGroup>
        <Actions>
          <Button variant="primary" onClick={closeAndRefresh}>
            {_t('Close')}
          </Button>
        </Actions>
      </Dialog>
    );
  }

  const onChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void = (newValue) => {
    const value = newValue?.target.value as string;
    if (value) {
      setSelectedS12nId(value);
    }
  };

  const renderLabel: SelectFieldProps['renderLabel'] = (labelComponent) => {
    return (
      <Typography2 component="p" variant="bodyPrimary">
        {_t("This course is part of multiple learning programs. Please select the pre-enrollment you'd like to cancel")}
        <div hidden={true}>{labelComponent}</div>
      </Typography2>
    );
  };

  return (
    <Mutation<RemovePreEnrollMutationMutation, RemovePreEnrollMutationMutationVariables>
      mutation={RemovePreEnrollMutation}
      context={{ clientName: 'gatewayGql' }}
    >
      {(removePreEnrollUser) => (
        <Dialog onClose={onClose} open={openModal}>
          <TrackedDiv
            trackingName="cancel_pre_enroll_s12n_select_modal"
            data={{ s12nId: selectedS12nId }}
            withVisibilityTracking
            requireFullyVisible={false}
          >
            <HeadingGroup>{_t('Which pre-enrollment would you like to cancel?')}</HeadingGroup>
            {isCancelling ? (
              <Content>
                <LoadingIcon />
              </Content>
            ) : (
              <div>
                <Content>
                  <Typography2 component="p" variant="bodyPrimary">
                    {automaticallyEnroll
                      ? _t(
                          'When you cancel your pre-enrollment, you will no longer be automatically enrolled when this course launches. You can still come back and pre-enroll again if you change your mind.'
                        )
                      : _t(
                          'When you cancel your pre-enrollment, you will no longer be automatically enrolled when this course launches and your credit card will not be charged. You can still come back and pre-enroll again if you change your mind.'
                        )}
                  </Typography2>
                  <br />
                  <SelectField
                    label="Pre-enrollment selection"
                    value={selectedS12nId}
                    onChange={onChange}
                    placeholder="Select the pre-enrollment to cancel"
                    renderLabel={renderLabel}
                  >
                    {orderedPreEnrolledS12ns.map((s12n) => {
                      const plannedLaunchDate = enrollmentAvailableChoices.getPlannedLaunchDateForS12n(s12n.id); // TODO: fill with actual planned launch date from EAC
                      return (
                        <SelectOption key={s12n.id} value={s12n.id} css={css({ display: 'flex' })}>
                          <Typography2 component="p" color="highlightBlue" display="inline">
                            {getS12nDisplayName(s12n.name, s12n.productVariant)}
                          </Typography2>
                          {plannedLaunchDate && (
                            <Typography2
                              component="p"
                              color="supportText"
                              display="inline"
                              css={css({ paddingLeft: 'var(--cds-spacing-100)' })}
                            >
                              {_t('(Pre-enrolled — opens #{startDate})', {
                                startDate: formatDateTimeDisplay(plannedLaunchDate, SHORT_MONTH_DAY_DISPLAY),
                              })}
                            </Typography2>
                          )}
                        </SelectOption>
                      );
                    })}
                  </SelectField>
                </Content>
                <Actions>
                  <Button
                    variant="secondary"
                    onClick={onClose}
                    css={css`
                      margin-right: 8px;
                    `}
                  >
                    {_t('Nevermind')}
                  </Button>
                  <Button
                    component={TrackedButton}
                    icon={<ArrowNextIcon size="small" />}
                    iconPosition="after"
                    variant="primary"
                    onClick={() => handleRemove(removePreEnrollUser)}
                    withVisibilityTracking={false}
                    requireFullyVisible={false}
                    trackingName="cancel_pre_enroll_modal_cancel_button"
                    data={{ preEnrollmentId }}
                    disabled={selectedS12nId === undefined}
                  >
                    {_t('Cancel this pre-enrollment')}
                  </Button>
                </Actions>
              </div>
            )}
          </TrackedDiv>
        </Dialog>
      )}
    </Mutation>
  );
};

export default CancelPreEnrollS12nSelectModal;
