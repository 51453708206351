/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum org_coursera_enterprise_admin_role_EnterpriseAdminRole {
  ORGANIZATION_FULL = "ORGANIZATION_FULL",
  PROGRAM_FULL = "PROGRAM_FULL",
}

export enum org_coursera_program_enrollment_CoursesRemainingReason {
  CONTRACT_COURSES_PER_USER_LIMIT = "CONTRACT_COURSES_PER_USER_LIMIT",
  ENROLLMENT_CAP_LIMIT = "ENROLLMENT_CAP_LIMIT",
  PROGRAM_COURSES_PER_USER_LIMIT = "PROGRAM_COURSES_PER_USER_LIMIT",
}

export enum org_coursera_program_enrollment_ProgramUserProductAction {
  ENROLL = "ENROLL",
  MANAGE = "MANAGE",
  PREVIEW = "PREVIEW",
  RESUME = "RESUME",
  SELECT = "SELECT",
  UNENROLL = "UNENROLL",
  UNSELECT = "UNSELECT",
  UPGRADE = "UPGRADE",
  VIEW = "VIEW",
}

export enum org_coursera_program_enrollment_ProgramUserProductState {
  AVAILABLE = "AVAILABLE",
  COMPLETED = "COMPLETED",
  ENROLLED = "ENROLLED",
  UNAVAILABLE = "UNAVAILABLE",
}

export enum org_coursera_program_enrollment_ProgramUserProductStateReason {
  UNAVAILABLE_COURSE_NOT_LAUNCHED_NOR_PRE_ENROLLABLE = "UNAVAILABLE_COURSE_NOT_LAUNCHED_NOR_PRE_ENROLLABLE",
  UNAVAILABLE_COURSE_NO_OPEN_SESSIONS = "UNAVAILABLE_COURSE_NO_OPEN_SESSIONS",
  UNAVAILABLE_NOT_PERMITTED = "UNAVAILABLE_NOT_PERMITTED",
  UNAVAILABLE_NO_CREDITS_REMAINING = "UNAVAILABLE_NO_CREDITS_REMAINING",
}

export enum org_coursera_program_membership_ProgramMembershipState {
  DELETED_MEMBER = "DELETED_MEMBER",
  INVITED = "INVITED",
  INVITED_EMAIL_NOT_VERIFIED = "INVITED_EMAIL_NOT_VERIFIED",
  MEMBER = "MEMBER",
  NOT_MEMBER = "NOT_MEMBER",
  SOFT_DELETED_MEMBER = "SOFT_DELETED_MEMBER",
  WHITELISTED = "WHITELISTED",
}

export enum org_coursera_skill_GoalType {
  LITERACY = "LITERACY",
  RESKILL = "RESKILL",
  UPSKILL = "UPSKILL",
}

export enum org_coursera_program_SkillSetAvailabilityType {
  ALL_SKILLSETS = "ALL_SKILLSETS",
  LINKED_SKILLSETS_ONLY = "LINKED_SKILLSETS_ONLY",
}

export enum org_coursera_authentication_thirdpartyauth_SsoLoginMethod {
  JWT = "JWT",
  JWT_INVITE = "JWT_INVITE",
  SAML = "SAML",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
