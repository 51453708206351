/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import type { ChangeEvent as AutosuggestChangeEvent, OnSuggestionSelected } from 'react-autosuggest';
import Autosuggest from 'react-autosuggest';
import Media from 'react-media';

import type { Search_ProductHit as SearchProductHit } from '__generated__/graphql-types';
import classNames from 'classnames';
import URI from 'jsuri';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { compose, getContext, withProps } from 'recompose';

import Imgix from 'js/components/Imgix';
import { useRetracked } from 'js/lib/retracked';
import type { TrackingData } from 'js/lib/retracked';
import useRouter from 'js/lib/useRouter';

import { IconButton, Typography2 } from '@coursera/cds-core';
import { CloseIcon, SearchIcon, VideoIcon } from '@coursera/cds-icons';

import { withSkillSearchResults } from 'bundles/enterprise-learner-search/components/skillSearch/SkillSearchDataProvider';
import type { SkillSearchResults } from 'bundles/enterprise-learner-search/components/skillSearch/SkillSearchDataProvider';
import type { SkillSetSearchResults } from 'bundles/enterprise-learner-search/components/skillsetSearch/SkillSetSearchDataProvider';
import { withSkillSetSearchResults } from 'bundles/enterprise-learner-search/components/skillsetSearch/SkillSetSearchDataProvider';
import { SHORT_FORM_CONTENT_VIDEO_ENTITY_TYPE } from 'bundles/enterprise-learner-search/constants';
import { getEnterpriseAutoCompleteSections } from 'bundles/enterprise-learner-search/utils/autocomplete';
import { getEnterpriseSearchConfigs } from 'bundles/enterprise-learner-search/utils/search';
import { launchMiniModal } from 'bundles/enterprise-legacy-xdp/hocs/withMiniModal';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import { PAGE_HEADER_MOBILE_BREAKPOINT } from 'bundles/page-header/components/PageHeader';
import { useIsSimplifiedPageHeader } from 'bundles/page-header/contexts/PageHeaderContext';
import TrackedButton from 'bundles/page/components/TrackedButton';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import AppInfoContext from 'bundles/page/contexts/AppInfoContext';
import { useTracker } from 'bundles/page/lib/event-pulse/react';
import { createEnterpriseSearchExperimentImpression } from 'bundles/program-common/utils/enterpriseSearchExperiment';
import getDurationFormatted from 'bundles/program-common/utils/getDurationFormatted';
import SvgCompatibleImage from 'bundles/search-common/components/SvgCompatibleImage';
import { AUTOCOMPLETE_PHOTO_SIZE, DEBOUNCE_DURATION_MS } from 'bundles/search-common/constants/autocomplete';
import {
  SEARCH_ENTERPRISE_AUTOCOMPLETE_CLIPS_INDEX_ID,
  SEARCH_ENTERPRISE_AUTOCOMPLETE_COURSES_S12N_INDEX_ID,
} from 'bundles/search-common/constants/index';
import { useSearch } from 'bundles/search-common/providers/SearchContext';
import SearchProvider from 'bundles/search-common/providers/SearchProvider';
import type { SearchContext } from 'bundles/search-common/providers/searchTypes';
import type { AutocompleteSection } from 'bundles/search-common/types/autocomplete';
import { getDataToTrackFromHit } from 'bundles/search-common/utils/SearchUtils';

import CourseraLogo from 'images/page-header/coursera-logo-c-mark.png';

import _t from 'i18n!nls/program-common';

import 'css!./__styles__/EnterpriseAutoComplete';

/**
 * This autocomplete component is a controlled one: `refine` is a function taking in a user
 * We are using `react-autosuggest`: https://github.com/moroshko/react-autosuggest
 * query and will return `hits` that Algolia thinks are good autocomplete suggestions.
 */

type InputProps = {
  pathname: string;
  placeholder: string;
  programIds: string[];
  /* This prop with a A/B test is used to determine if we should show skill pages in auto complete results */
  enableSkillsInSearchAndBrowse?: boolean;
  shouldShowShortFormContent?: boolean;
  shouldRenderScopeSelect?: boolean;
};

type Hit = {
  name: string;
  objectID: string;
  imageUrl?: string;
  partners?: Array<string>;
  topSkill?: string;
  trackingName?: string;
  trackingData?: TrackingData;
  entityType?: string;
  durationMs?: number;
};

// AutoSuggest types don't correctly handle grouped suggestions, so yolo it with any.
type SuggestionType = $TSFixMe;

type Props = InputProps & {
  skillsetHits?: SkillSetSearchResults[];
  skillHits?: SkillSearchResults[];
};

const styles = {
  autoComplete: css`
    input[type='text'].react-autosuggest__input {
      :not(:focus) {
        position: absolute;
        top: -100%;
      }

      :focus {
        position: relative;
        top: unset;
      }
    }

    .react-autosuggest__container {
      width: auto;
    }

    .react-autosuggest__suggestions-container--open,
    .suggestion-wrapper.withSuggestion .react-autosuggest__suggestions-container {
      /* page-header and program-home bundles have different breakpoints, so we have to duplicate 
         the styles from EnterpriseAutoComplete.styl, to make sure search is available for viewports b/w 993px and 1060px */
      position: absolute;
      left: 0;
      top: 64px;
      width: 100% !important;
      max-width: unset;
      max-height: unset;
      padding: 0;
      z-index: 1;
      background-color: var(--cds-color-neutral-background-primary);
      height: calc(100vh - 64px);
      overflow-x: hidden;
      overflow-y: auto;
      margin-top: 0;
    }

    .cancel-button {
      display: block;
    }
  `,
  autoCompleteIsFocused: css`
    /* page-header and program-home bundles have different breakpoints, so we have to duplicate 
       the styles from EnterpriseAutoComplete.styl, to make sure search is available for viewports b/w 993px and 1060px */
    padding: 12px 14px;
    background-color: var(--cds-color-emphasis-primary-background-xxstrong);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 4000;

    input[type='text'].react-autosuggest__input {
      padding-right: 30px;
    }

    .react-autosuggest__container {
      width: 100%;
    }
  `,
  autoCompleteSearchIcon: (isFocused: boolean) => css`
    display: ${isFocused ? 'block' : 'none'} !important;
  `,
  mobileSearchIcon: css`
    && {
      width: 36px;
      height: 36px;
      color: var(--cds-color-neutral-primary);

      .isFocused & {
        display: none;
      }

      :focus,
      :hover {
        background: transparent;
      }
    }
  `,
  preUnifiedPHSearchBar: css`
    /* As per PageHeader.styl, mobile header kicks in at 1060px; these styles are for desktop only */
    @media (min-width: 1061px) {
      .react-autosuggest__container > input {
        border-radius: 24px !important;
        padding: var(--cds-spacing-100) var(--cds-spacing-600) !important;
        border: 1px solid var(--design-sprint-gray-4, #dbe0e1) !important;
        color: var(--design-sprint-gray-2, #63676b) !important;
        line-height: 24px !important;
        font-family: 'Open Sans', sans-serif !important;
        min-width: 265px;
        height: 44px !important;
      }

      .search-button {
        position: relative;
        right: 41px;
        min-width: 32px;
        display: inline-grid;
        border-radius: 24px;
        width: 32px;
        height: 32px;
        padding: var(--cds-spacing-100);
        top: 6px;
        border: 1px solid transparent;
      }
    }
  `,
  preUnifiedPHSearchBarCourseraIcon: css`
    @media (min-width: 1061px) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin-left: 7px;
    }
  `,
};

export const EnterpriseAutoComplete = (props: Props) => {
  const {
    pathname,
    placeholder,
    skillsetHits = [],
    skillHits = [],
    enableSkillsInSearchAndBrowse,
    shouldShowShortFormContent,
    shouldRenderScopeSelect,
  } = props;

  const isSimplifiedPageHeader = useIsSimplifiedPageHeader();
  const inputRef = useRef<HTMLInputElement | undefined>(undefined);
  const router = useRouter();
  const query = router.location.query.query || '';
  const trackComponent = useRetracked();

  const track = useTracker();
  const [isFocused, setIsFocused] = useState(false);
  const [currentText, setCurrentText] = useState(query || '');
  const [suggestionSelected, setSuggestionSelected] = useState(false);
  const [hiddenEmptySearchClipsObjectIds, setHiddenEmptySearchClipsObjectIds] = useState<string[]>([]);
  const { getSearchResults, updateQuery, isLoading } = useSearch();
  const clipsData = shouldShowShortFormContent
    ? getSearchResults(SEARCH_ENTERPRISE_AUTOCOMPLETE_CLIPS_INDEX_ID)
    : undefined;
  const { elements: clipElements, source: clipsSource } = clipsData || {};
  const indexName = clipsSource?.indexName;
  const coursesS12nData = getSearchResults(SEARCH_ENTERPRISE_AUTOCOMPLETE_COURSES_S12N_INDEX_ID);
  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState<AutocompleteSection[]>([]);

  const refineAndTrack = (newValue: string) => {
    const whitespacelessSearchValue = newValue.replace(/\s+/g, ' '); // white space collapsed
    trackComponent({
      trackingData: { newSearchValue: whitespacelessSearchValue, page: window.location.pathname },
      trackingName: 'algolia_search_input_changes',
      action: 'search',
    });

    updateQuery(newValue);
  };

  const debouncedRefineAndTrack = debounce(refineAndTrack, DEBOUNCE_DURATION_MS);

  const memoizedEnterpriseAutoCompleteSections = useMemo(() => {
    return getEnterpriseAutoCompleteSections(clipsData, coursesS12nData);
  }, [clipsData, coursesS12nData]);

  useEffect(() => {
    if (query && typeof window !== 'undefined' && query !== 'undefined') {
      debouncedRefineAndTrack(query);
    }

    return debouncedRefineAndTrack.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // optimization for reducing calculation when loading
    if (!isLoading) {
      setAutocompleteSuggestions(memoizedEnterpriseAutoCompleteSections);
    }
  }, [isLoading, memoizedEnterpriseAutoCompleteSections]);

  const isInEnterpriseSearch = () => {
    const {
      params: { programSlug },
      location: { pathname: routerPathname },
    } = router;

    return routerPathname.includes(`/programs/${programSlug}/browse`);
  };

  const hideEmptySearchClips = (renderHits: SearchProductHit[]) => {
    const hiddenObjectIds: string[] = (renderHits || [])
      .filter((hit) => hit?.productType === SHORT_FORM_CONTENT_VIDEO_ENTITY_TYPE && hit?.id !== undefined)
      .map((hit) => hit?.id as string);

    setHiddenEmptySearchClipsObjectIds(hiddenObjectIds);
  };

  const constructHardRedirectURI = (path: string) => {
    // We are intentionally removing this from filteredQuery to remove them from the query
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { source, currentTab, showMiniModal, productId, productType, collectionId, ...filteredQuery } =
      router.location.query;
    const isEnterpriseSERP = source === 'search';
    const uri = new URI(path);

    if (!isEnterpriseSERP && filteredQuery) {
      Object.keys(filteredQuery).forEach((key) => {
        uri.addQueryParam(key, filteredQuery[key]);
      });
    }

    uri.replaceQueryParam('source', 'search').replaceQueryParam('query', currentText);

    return uri.toString();
  };

  const doSearch = () => {
    // make sure that the modal is not displayed after going to a search page
    // We are intentionally removing this from filteredQuery to remove them from the query
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { source, currentTab, showMiniModal, productId, productType, collectionId, ...filteredQuery } =
      router.location.query;
    trackComponent({
      trackingData: { searchText: currentText, page: window.location.pathname },
      trackingName: 'algolia_search_page_query_updated',
      action: 'search',
    });

    track('perform_search', {
      searchedQuery: currentText,
    });

    if (isInEnterpriseSearch()) {
      const isEnterpriseSERP = source === 'search';
      const routerConfig = {
        pathname: `${pathname}/browse`,
        query: {
          query: currentText,
          source: 'search',
        },
      };

      if (!isEnterpriseSERP) {
        routerConfig.query = {
          ...filteredQuery,
          ...routerConfig.query,
        };
      }

      router.push(routerConfig);

      // Unified pages can use this search bar too, ensure we hard redirect for these pages.
    } else {
      window.location.href = constructHardRedirectURI(`${pathname}/browse`);
    }
  };

  const onAutoSuggestChange = (event: React.FormEvent, { newValue, method }: AutosuggestChangeEvent) => {
    // When there's an input change on AutoComplete, we update with new value
    // if the user typed or clicked, we call refine (from Algolia) to update the
    // AutoComplete suggestions
    setCurrentText(newValue || '');
    if ((method === 'type' || method === 'click') && newValue !== currentText) {
      debouncedRefineAndTrack(newValue);
    }
  };

  const onSuggestionSelected: OnSuggestionSelected<SuggestionType> = (event, { suggestion, method }) => {
    // If selected using enter key, we set this to true to avoid operation on onKeyDown, where we will set it back to false
    if (method === 'enter') {
      setSuggestionSelected(true);
    }

    track('perform_search', {
      searchedQuery: suggestion.name,
      sourceLinkCategory: 'autocomplete_suggestions',
    });
    trackComponent({
      trackingData: {
        searchText: currentText,
        page: window.location.pathname,
        ...getDataToTrackFromHit(suggestion),
        hitType: suggestion.trackingName,
        ...(suggestion.trackingData ?? {}),
      },
      trackingName: suggestion.trackingName,
      action: 'search',
    });

    const suggestionProductId: string[] | undefined = suggestion?.objectID?.split('~');
    if (suggestionProductId && suggestionProductId.length >= 2) {
      const [suggestionType, suggestionID] = suggestionProductId;
      if (suggestionType === 'skillset') {
        const [programSlug, skillsetId] = suggestionID.split('/');
        const nextPath = `/programs/${programSlug}/skillsets/${skillsetId}`;

        if (isInEnterpriseSearch()) {
          router.push(nextPath);
        } else {
          window.location.href = nextPath;
        }

        return;
      }

      const queryText = encodeURIComponent(currentText);

      if (suggestionType === 'skill') {
        const { programSlug } = router.params;
        const nextPath = `/programs/${programSlug}/skills/${suggestionID}?query=${queryText}&source=search`;

        if (isInEnterpriseSearch()) {
          router.push(nextPath);
        } else {
          window.location.href = nextPath;
        }

        return;
      }

      if (['sfcvideo', 'sfclesson'].includes(suggestionType)) {
        window.open(`${suggestion.objectUrl}?query=${queryText}&source=search`, '_blank');
        return;
      }

      // Object URL's are in this format "/learn/slug". If value is present, index 1 is product type path and index 2 is slug.
      const splitObjectUrl = suggestion?.objectUrl?.split('/');
      // If we're doing a cross-scope auto-complete, then also redirect to the correct context. This avoids us having to
      // make every page multi-program mini-modal aware just yet.
      const shouldRedirect = !router.location.pathname.startsWith(pathname);
      if (shouldRedirect) {
        launchMiniModal(
          router,
          suggestionID,
          suggestionType === 'course',
          '',
          pathname,
          { query: currentText },
          splitObjectUrl && { slug: splitObjectUrl[2], productPagePath: splitObjectUrl[1] }
        );
      } else {
        launchMiniModal(
          router,
          suggestionID,
          suggestionType === 'course',
          '',
          undefined,
          undefined,
          splitObjectUrl && { slug: splitObjectUrl[2], productPagePath: splitObjectUrl[1] }
        );
      }
    }
  };

  const blurAutocomplete = () => {
    if (inputRef) {
      inputRef.current?.blur();
    }
  };

  const onBlur = (tabIndex?: string) => () => {
    if (tabIndex) {
      inputRef?.current?.setAttribute('tabIndex', tabIndex);
    }
    setIsFocused(false);
  };

  const onFocus = () => {
    trackComponent({
      trackingData: { page: window.location.pathname },
      trackingName: 'autocomplete_focused',
      action: 'search',
    });

    setIsFocused(true);

    // Checks that the component is mounted and search hits have been fetched, then makes sure the Clips that are
    // returned with an empty search are hidden by adding their objectIds to the 'hiddenEmptySearchClipsObjectIds'
    // state
    if (clipElements && !currentText && !hiddenEmptySearchClipsObjectIds.length) {
      hideEmptySearchClips(clipElements as SearchProductHit[]);
    }
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      // This is done so that the container is closed when pressing escape
      blurAutocomplete();
    }
    // Without the second check this logic would also happen if the user selected a suggestion via the keyboard, so we set this.suggestionSelected
    // to true on onSuggestionSelected
    else if (event.key === 'Enter' && !suggestionSelected) {
      doSearch();
      blurAutocomplete();
    }
    // We now set it back to false to indicate that the selection has been finalized & if the user presses a key again it won't be
    // interpreted as selected from the menu unless they select from the menu, which will cause onSuggestionSelected is fired again and set it
    // back to true
    setSuggestionSelected(false);
  };

  const clearSearchQuery = () => {
    setCurrentText('');
  };

  const storeInputReference = (instance: Autosuggest<SuggestionType> | null) => {
    if (instance?.input) {
      inputRef.current = instance.input;
    }
  };

  const searchButtonOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    doSearch();
    blurAutocomplete();
  };

  const clearSearchQueryAndCloseContainer = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    clearSearchQuery();
    blurAutocomplete();
  };

  const renderAutoCompleteSuggestion = (hit: Hit | SuggestionType) => {
    const searchIndexName = indexName;
    const e2eTestLabel = hit.objectID ? `${hit.objectID?.split('~')[0]}-autocomplete-result` : undefined;

    let svgIcon;

    if (hit?.entityType === SHORT_FORM_CONTENT_VIDEO_ENTITY_TYPE) {
      svgIcon = <VideoIcon size="medium" color="interactive" />;
    }

    return (
      <TrackedDiv
        key={hit.objectID}
        trackingName={hit.trackingName ?? 'autocomplete_item'}
        data={{
          name: hit.name,
          objectID: hit.objectID,
          indexName: searchIndexName,
          ...(hit.trackingData ?? {}),
        }}
        withVisibilityTracking={true}
        className="section-item direct-match-section"
        aria-label={`${hit.sectionTitle}: ${hit.name}`}
        data-e2e={e2eTestLabel}
      >
        {svgIcon ? (
          <div className="svg-icon">{svgIcon}</div>
        ) : (
          <SvgCompatibleImage
            className="item-photo"
            src={hit.imageUrl}
            alt={_t('Suggestion Image')}
            imgParams={{ fit: 'crop', auto: 'format,compress' }}
            width={AUTOCOMPLETE_PHOTO_SIZE}
            height={AUTOCOMPLETE_PHOTO_SIZE}
          />
        )}
        <div className="item-label">
          <Typography2 className="item-name" title={hit.name} component="span">
            <span className="sr-only">{hit.sectionTitle}:&nbsp;</span>
            {hit.name}
          </Typography2>
          {hit.partners?.[0] && (
            <Typography2 color="supportText" component="span">
              {hit.partners[0]}
              {hit?.durationMs && (
                <Typography2 color="supportText" component="span">
                  {' · ' + getDurationFormatted(hit.durationMs || 0, true)}
                </Typography2>
              )}
            </Typography2>
          )}

          {hit.topSkill && (
            <Typography2 className="partner-name" color="supportText" component="span">
              {_t('Learn #{skillName} and other skills...', { skillName: hit.topSkill })}
            </Typography2>
          )}
        </div>
      </TrackedDiv>
    );
  };

  const renderSectionTitle = ({ sectionTitle, hits: sectionHits }: { sectionTitle: string; hits: Hit[] }) => {
    if (!sectionTitle || !sectionHits.length) {
      return null;
    }

    return (
      <div className="section-title-box">
        <Typography2 className="section-title" variant="subtitleMedium" component="span">
          {sectionTitle}
        </Typography2>
      </div>
    );
  };

  // we are using onChange rather than the following 2 props since it's a more common pattern to handle input changes

  const onSuggestionsFetchRequested = () => {
    createEnterpriseSearchExperimentImpression();
  };

  const onSuggestionsClearRequested = () => undefined;

  const getSuggestionValue = (hit: Hit, currentTextValue: string) => {
    if (hit.entityType === SHORT_FORM_CONTENT_VIDEO_ENTITY_TYPE) {
      return currentTextValue;
    }
    return hit.name;
  };

  const shouldRenderSuggestions = () => {
    return true;
  };

  const getSectionSuggestions = (section: { hits: Hit[] }) => {
    return section.hits;
  };

  const openAutosuggest = () => {
    inputRef.current?.focus();
    inputRef.current?.setAttribute('tabIndex', '0');
  };

  const autoCompleteClass = classNames(
    `rc-EnterpriseAutoComplete ${shouldRenderScopeSelect ? '--scope' : '--no-scope'} horizontal-box`,
    { isFocused }
  );

  const suggestions = [
    ...(skillsetHits.length > 0 ? skillsetHits : []),
    ...(skillHits.length > 0 && enableSkillsInSearchAndBrowse ? skillHits : []),
    ...autocompleteSuggestions,
  ];

  return (
    <AppInfoContext.Consumer>
      {({ userAgent }) => (
        <Media query={{ maxWidth: `${PAGE_HEADER_MOBILE_BREAKPOINT}px` }} defaultMatches={userAgent?.isMobile}>
          {(matches) => {
            const shouldShowPreUnifiedMobileAutoComplete = isSimplifiedPageHeader && matches;

            return (
              <div
                id={`short-form-content-enabled-${shouldShowShortFormContent}`}
                data-e2e="EnterpriseAutoComplete"
                className={autoCompleteClass}
                {...(shouldShowPreUnifiedMobileAutoComplete
                  ? { css: [styles.autoComplete, isFocused && styles.autoCompleteIsFocused] }
                  : { css: [isSimplifiedPageHeader && styles.preUnifiedPHSearchBar] })}
              >
                <button
                  type="button"
                  className="nostyle mobile-magnifier"
                  aria-label={_t('Enter Search')}
                  // Tracking it programatically to pass the query data
                  onMouseDown={searchButtonOnClick}
                  css={shouldShowPreUnifiedMobileAutoComplete && styles.autoCompleteSearchIcon(isFocused)}
                >
                  <SearchIcon color="invert" size="medium" />
                </button>
                {shouldShowPreUnifiedMobileAutoComplete && (
                  <IconButton
                    intent="search"
                    variant="ghost"
                    size="medium"
                    onClick={openAutosuggest}
                    css={styles.mobileSearchIcon}
                    aria-label={_t('Open Search Auto Complete')}
                  />
                )}
                {isSimplifiedPageHeader && !shouldShowPreUnifiedMobileAutoComplete ? (
                  <Imgix
                    src={CourseraLogo}
                    maxWidth={32}
                    maxHeight={32}
                    alt={_t('Coursera logo')}
                    css={styles.preUnifiedPHSearchBarCourseraIcon}
                  />
                ) : null}
                <Autosuggest<SuggestionType>
                  // The first index is the placeholder index
                  id="enterpriseAutoComplete"
                  suggestions={suggestions}
                  focusInputOnSuggestionClick={false}
                  multiSection={true}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionSelected={onSuggestionSelected}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  getSuggestionValue={(suggestion) => getSuggestionValue(suggestion, currentText)}
                  renderSuggestion={renderAutoCompleteSuggestion}
                  shouldRenderSuggestions={shouldRenderSuggestions}
                  inputProps={{
                    placeholder,
                    value: currentText,
                    onChange: onAutoSuggestChange,
                    onKeyDown,
                    onFocus,
                    onBlur: onBlur(shouldShowPreUnifiedMobileAutoComplete ? '-1' : undefined),
                    'aria-label': _t('Search catalog'),
                  }}
                  renderSectionTitle={renderSectionTitle}
                  getSectionSuggestions={getSectionSuggestions}
                  ref={storeInputReference}
                />
                {!shouldShowPreUnifiedMobileAutoComplete && (
                  <button
                    type="button"
                    className={`nostyle search-button ${isSimplifiedPageHeader ? 'unified' : ''}`}
                    aria-label={_t('Enter Search')}
                    // Tracking it programatically to pass the query data
                    onClick={searchButtonOnClick}
                  >
                    <SearchIcon color="invert" size={isSimplifiedPageHeader ? 'small' : 'medium'} />
                  </button>
                )}
                {isFocused && (
                  <TrackedButton
                    trackingName="close_auto_complete"
                    className="nostyle cancel-button"
                    aria-label={_t('Close Search Auto Complete')}
                    onClick={clearSearchQueryAndCloseContainer}
                  >
                    <CloseIcon size="medium" />
                  </TrackedButton>
                )}
              </div>
            );
          }}
        </Media>
      )}
    </AppInfoContext.Consumer>
  );
};
const EnterpriseAutoCompleteWithDataAndQuery = compose<Props, InputProps>(
  getContext({
    _eventData: PropTypes.object.isRequired,
  }),
  withProps(() => {
    const currentText = useSearch().getSearchResults(SEARCH_ENTERPRISE_AUTOCOMPLETE_CLIPS_INDEX_ID)?.query;
    return { currentRefinement: currentText };
  }),
  withSkillSetSearchResults(),
  withSkillSearchResults
)(EnterpriseAutoComplete);

type AutoCompleteProps = {
  pathname: string;
  placeholder: string;
  programIds: Array<string>;
  /* This prop with a A/B test is used to determine if we should show skill pages in auto complete results */
  enableSkillsInSearchAndBrowse?: boolean;
  shouldShowShortFormContent?: boolean;
  shouldRenderScopeSelect?: boolean;
};

const EnterpriseAutoCompleteWithIndex = ({
  pathname,
  placeholder,
  programIds,
  enableSkillsInSearchAndBrowse,
  shouldShowShortFormContent,
  shouldRenderScopeSelect,
}: AutoCompleteProps) => {
  const searchContext: SearchContext = { searchInPrograms: { programIds } };
  return (
    <SearchProvider searchConfigs={getEnterpriseSearchConfigs(searchContext)}>
      <EnterpriseAutoCompleteWithDataAndQuery
        programIds={programIds}
        pathname={pathname}
        placeholder={placeholder}
        enableSkillsInSearchAndBrowse={enableSkillsInSearchAndBrowse}
        shouldShowShortFormContent={shouldShowShortFormContent}
        shouldRenderScopeSelect={shouldRenderScopeSelect}
      />
    </SearchProvider>
  );
};

export default EnterpriseAutoCompleteWithIndex;
