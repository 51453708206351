/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { Button, Dialog, Typography2 } from '@coursera/cds-core';
import { ArrowNextIcon, CheckIcon } from '@coursera/cds-icons';

import EnrollmentChoiceTypes from 'bundles/enroll-course/common/EnrollmentChoiceTypes';
import { useEnrollModalEventing } from 'bundles/enroll/utils/eventingUtils';
import Icon from 'bundles/iconfont/Icon';
import type { CatalogPrice } from 'bundles/naptimejs/resources/__generated__/S12nDerivativesV1';
import type OnDemandSpecializationV1 from 'bundles/naptimejs/resources/onDemandSpecializations.v1';
import TrackedButton from 'bundles/page/components/TrackedButton';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import ReactPriceDisplay from 'bundles/payments-common/components/ReactPriceDisplay';
import { freeTrial } from 'bundles/payments/common/constants';
import { NormalS12n } from 'bundles/s12n-common/constants/s12nProductVariants';
import { getProductVariantString } from 'bundles/s12n-common/lib/s12nProductVariantUtils';

import _t from 'i18n!nls/enroll';

const { HeadingGroup, Content, Actions } = Dialog;

type Props = {
  s12nId: string;
  onSdp: boolean;
  price?: CatalogPrice | null;
  handleClose: () => void;
  openModal: boolean;
  isEnrolling: boolean;
  onEnrollButtonClick: () => void;
};

const styles = {
  freemiumBulletPoint: () => css`
    margin-bottom: 16px;
    display: flex;

    .check-icon {
      margin-top: 4px;
    }

    .subheader {
      color: var(--cds-color-grey-975);
      display: inline;
    }

    .text-container {
      margin-left: 30px;
    }
  `,
  bulletPoints: css`
    margin-top: 24px;
  `,
};

const getPreEnrollValueProps = (s12n?: OnDemandSpecializationV1) => {
  const FREE_TRIAL_BULLET = {
    header: _t('Start with #{numFreeDays} days of free learning.', {
      numFreeDays: freeTrial.preEnrollmentNumDays,
    }),
    subheader: _t(`Cancel anytime before the #{numFreeDays}-day free trial period ends. No penalties.`, {
      numFreeDays: freeTrial.preEnrollmentNumDays,
    }),
  };

  const ACCESS_BULLET = {
    header: _t('Unlimited access to all courses in the #{productVariant}', {
      productVariant: getProductVariantString(s12n?.productVariant || NormalS12n),
    }),
    subheader: _t('Watch lectures, try assignments, participate in discussion forums, and more.'),
  };

  const PRICE_BULLET = {
    header: _t('{price} per month to continue learning after trial ends.'),
    subheader: _t('Go as fast as you can - the faster you go, the more you save.'),
  };

  const CERTIFICATE_BULLET = {
    header: _t('Certificate when you complete.'),
    subheader: _t('Share on your resume, LinkedIn, and CV.'),
  };

  return [FREE_TRIAL_BULLET, ACCESS_BULLET, PRICE_BULLET, CERTIFICATE_BULLET];
};

const getPriceDisplay = (price?: CatalogPrice | null) => {
  if (!price) {
    return undefined;
  }

  return <ReactPriceDisplay value={price.amount} currency={price.currencyCode} />;
};

const CreditCardPreEnrollModal: React.FC<Props> = ({
  handleClose,
  openModal,
  price,
  isEnrolling,
  s12nId,
  onEnrollButtonClick,
}) => {
  const { getEnrollModalTrackingRef, trackEnrollModalClose, trackEnrollModalContinue } = useEnrollModalEventing();
  const visibilityTrackingRef = getEnrollModalTrackingRef();
  const valueProps = getPreEnrollValueProps();
  const priceDisplay = getPriceDisplay(price);

  const _handleClose = () => {
    trackEnrollModalClose();
    handleClose();
  };

  const handleContinue = () => {
    trackEnrollModalContinue(EnrollmentChoiceTypes.PRE_ENROLLMENT_ELIGIBLE);
    onEnrollButtonClick();
  };

  return (
    <Dialog onClose={_handleClose} open={openModal}>
      <TrackedDiv
        trackingName="credit_card_pre_enroll_modal"
        data={{ id: s12nId }}
        withVisibilityTracking
        requireFullyVisible={false}
      >
        <HeadingGroup>
          {_t('Pre-enroll and access #{numFreeDays} days of free learning!', {
            numFreeDays: freeTrial.preEnrollmentNumDays,
          })}
        </HeadingGroup>
        <Content>
          <Typography2 component="p" variant="bodyPrimary">
            {_t(`You'll be notified as soon as the program opens. You can then start learning for free right away.`)}
          </Typography2>
          <Typography2 component="p" variant="bodyPrimary">
            {_t('You will not be charged until the free trial period ends #{numFreeDays} days later.', {
              numFreeDays: freeTrial.preEnrollmentNumDays,
            })}
          </Typography2>
          <div css={styles.bulletPoints} ref={visibilityTrackingRef}>
            <ul className="bullet_list nostyle">
              {valueProps.map((bullet: { header: string; subheader: string }) => {
                const { subheader, header } = bullet;

                return (
                  <li key={header} css={styles.freemiumBulletPoint}>
                    <div>
                      <CheckIcon size="medium" color="interactive" name="checkmark" className="check-icon" />
                    </div>
                    <div className="text-container">
                      <Typography2 component="h4" variant="subtitleMedium" className="header">
                        <FormattedMessage message={header} price={priceDisplay} />
                      </Typography2>

                      <Typography2 variant="bodySecondary" component="p" className="subheader">
                        <FormattedMessage message={subheader} />
                      </Typography2>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </Content>
        <Actions>
          <Button
            component={TrackedButton}
            icon={<ArrowNextIcon size="small" />}
            iconPosition="after"
            variant="primary"
            disabled={isEnrolling}
            onClick={handleContinue}
            withVisibilityTracking={false}
            requireFullyVisible={false}
            trackingName="credit_card_pre_enroll_modal_pre_enroll_button"
            data={{ s12nId }}
          >
            {isEnrolling ? <Icon name="spinner" spin /> : _t('Pre-enroll')}
          </Button>
        </Actions>
      </TrackedDiv>
    </Dialog>
  );
};

export default CreditCardPreEnrollModal;
