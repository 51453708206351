/** @jsx jsx */

/** @jsxFrag Fragment */
import { css, jsx, keyframes } from '@emotion/react';

import { Fragment } from 'react';

import { useTheme } from '@coursera/cds-core';
import { SpinnerIcon } from '@coursera/cds-icons';

import ProgramMiniModal from 'bundles/enterprise-legacy-xdp/components/ProgramMiniModal';
import type { PropsFromCaller as PropsForMiniModal } from 'bundles/enterprise-legacy-xdp/components/ProgramMiniModal';
import type EnterpriseProgramsV1 from 'bundles/naptimejs/resources/enterprisePrograms.v1';
import Modal from 'bundles/phoenix/components/Modal';
import { isFullscreenProductModalEnabled } from 'bundles/program-common/epicFeatureFlags';
import ProgramHomeWrapper from 'bundles/program-home/components/ProgramHomeWrapper';
import { EnterpriseHomeWrapper, getSearchBar } from 'bundles/program-home/components/enterprise-home/EnterpriseHomeApp';
import type { EnterpriseHomePageQuery_EnterpriseLearnerSummariesV1Resource_byUserIdAndThirdPartyOrgSlug_elements_enterprisePrograms_elements as EnterpriseProgram } from 'bundles/program-home/components/enterprise-home/__generated__/EnterpriseHomePageQuery';

type PropsForFullScreenModal = {
  thirdPartyOrganizationSlug: string;

  breadcrumb: JSX.Element;
  breadcrumbExtraMargin?: boolean;
  noBreadcrumbMargin?: boolean;

  program?: EnterpriseProgramsV1;
  programs?: EnterpriseProgram[];
};
type Props = PropsForFullScreenModal & PropsForMiniModal;

const useStyles = () => {
  const { spacing } = useTheme();
  const rotate = keyframes`
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
        `;
  return {
    loadingModal: css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 150,
    }),
    spinnerIcon: css({
      animation: `${rotate} 2s infinite linear`,
    }),
    breadcrumb: (breadcrumbExtraMargin = false) =>
      css({
        margin: breadcrumbExtraMargin ? spacing(12, 24) : spacing(24),
      }),
  };
};

export const ExperimentalProgramFullScreenModal = (props: Props) => {
  const styles = useStyles();

  if (isFullscreenProductModalEnabled()) {
    return (
      <>
        {/* Show a Modal with a spinner, since the ProgramMiniModal won't show until loading is finished */}
        <Modal modalName="">
          <div css={styles.loadingModal}>
            <SpinnerIcon size="large" css={styles.spinnerIcon} />
          </div>
        </Modal>
        <ProgramMiniModal
          {...props}
          modalClassName="rc-ModalFullScreen"
          Wrapper={({ children }) => {
            if (props.program) {
              return (
                <ProgramHomeWrapper
                  thirdPartyOrganizationId={props.thirdPartyOrgId || ''}
                  thirdPartyOrganizationSlug={props.thirdPartyOrganizationSlug}
                  program={props.program}
                  programId={props.program.id}
                  programName={props.program.metadata.name}
                  isAuthenticatedUser={!!props.userId}
                  enableSkillsInSearchAndBrowse={true}
                  shouldShowShortFormContent={true}
                >
                  {children}
                </ProgramHomeWrapper>
              );
            } else {
              return (
                <EnterpriseHomeWrapper
                  thirdPartyOrganizationId={props.thirdPartyOrgId}
                  searchBar={getSearchBar(props.programs ?? [], props.thirdPartyOrganizationSlug)}
                >
                  {children}
                </EnterpriseHomeWrapper>
              );
            }
          }}
          WrapperHeader={
            <div css={props.noBreadcrumbMargin ? {} : styles.breadcrumb(props.breadcrumbExtraMargin)}>
              {props.breadcrumb}
            </div>
          }
        />
      </>
    );
  } else {
    return <ProgramMiniModal {...props} />;
  }
};

export default ExperimentalProgramFullScreenModal;
