/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import type { Interpolation } from '@emotion/react';

import * as React from 'react';

import { Typography2 } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

import TrackedDiv from 'bundles/page/components/TrackedDiv';
import CopyItemWithCdsTextField from 'bundles/ui/components/cds/CopyItemWithCdsTextField';
import type { PropsForCDSTextField } from 'bundles/ui/components/cds/DisabledTextField';

import _t from 'i18n!nls/ui';

const styles = {
  copyItem: css`
    position: relative;
    display: flex;
    margin-bottom: 16px;
    margin-top: 16px;

    @media (max-width: 767px) {
      margin-top: 25px;
    }
  `,
  copyItemInputWrapper: css`
    display: inline-block;
    width: 100%;
  `,
  copyItemInput: () => css`
    background-color: var(--cds-color-neutral-background-primary-weak);
    border: 1px solid #ebebeb;
    width: 100%;
    padding: 10px 15px;
    font-size: 1rem;
  `,
  copyItemButton: () => css`
    position: absolute;
    display: inline-block;
    background-color: var(--cds-color-neutral-background-primary-weak);
    background: linear-gradient(270deg, var(--cds-color-neutral-background-primary-weak) 80%, transparent 100%);
    padding: 8px;
    width: 78px;
    right: 2px;
    top: 2px;
    text-align: center;
    align-items: center;
    cursor: pointer;
  `,
  copyText: css`
    color: #2a73cc !important;
  `,
};

type Props = {
  item: string;
  trackingName?: string;
  e2eDataName?: string;
  onItemClick?: () => void;
  copySuccessMessage?: string;
  useCdsTextField?: boolean;
  textFieldStyles?: Interpolation<Theme>;
  autoFocus?: boolean;
  copyButtonLabel?: string;
} & PropsForCDSTextField;

type State = {
  showCopiedMessage: boolean;
};

export default class CopyItem extends React.Component<Props, State> {
  textInput: HTMLInputElement | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      showCopiedMessage: false,
    };
  }

  static defaultProps = {
    trackingName: 'copy_item',
  };

  handleClick = () => {
    const { onItemClick } = this.props;

    if (this.textInput) {
      this.textInput.select();
      navigator.clipboard.writeText(this.textInput.value);
      this.toggleCopiedMessage(true);
      setTimeout(() => this.toggleCopiedMessage(false), 2500);
      // clear text input selection
      const selection = document.getSelection();
      if (selection) {
        selection.empty();
      }

      if (onItemClick) onItemClick();
    }
  };

  toggleCopiedMessage = (show: boolean) => {
    this.setState(() => ({
      showCopiedMessage: show,
    }));
  };

  render() {
    const { showCopiedMessage } = this.state;
    const { useCdsTextField, textFieldStyles, ...propsExceptCDSFlag } = this.props;
    const { item, trackingName, e2eDataName } = propsExceptCDSFlag;
    if (useCdsTextField) {
      return <CopyItemWithCdsTextField {...propsExceptCDSFlag} />;
    }

    return (
      <TrackedDiv className="rc-CopyItem" css={styles.copyItem} trackingName={`${trackingName}_container`}>
        <TrackedDiv
          className="rc-CopyItem__input_wrapper"
          css={styles.copyItemInputWrapper}
          trackingName={`${trackingName}_input`}
        >
          <input
            ref={(node) => {
              this.textInput = node;
            }}
            css={[styles.copyItemInput, textFieldStyles]}
            className="rc-CopyItem__input"
            type="text"
            value={item}
            {...(e2eDataName && { 'data-e2e': e2eDataName })}
            readOnly
            tabIndex={-1}
            aria-hidden={true}
            dir="ltr"
          />
        </TrackedDiv>
        <TrackedDiv
          css={styles.copyItemButton}
          trackingName={`${trackingName}_button`}
          aria-label={_t('Copy share link')}
          onClick={this.handleClick}
          tabIndex={0}
          role="button"
        >
          {showCopiedMessage ? (
            <Typography2 css={styles.copyText} variant="subtitleMedium" component="span" role="alert">
              {' '}
              {_t('COPIED!')}{' '}
            </Typography2>
          ) : (
            <Typography2 variant="subtitleMedium" css={styles.copyText} component="span">
              {' '}
              {_t('COPY')}
            </Typography2>
          )}
        </TrackedDiv>
      </TrackedDiv>
    );
  }
}
