import ASSET_TYPES from 'bundles/asset-admin/constants/AssetTypes';
import { AssetTypeNames } from 'bundles/asset-admin/types/assets';
import type {
  AssetData,
  AudioAssetData,
  MediaAssetData,
  VideoAssetData,
} from 'bundles/cml/shared/types/assetDataTypes';
import type { AssetElement } from 'bundles/cml/shared/types/elementTypes';

export const isPDfEmbedEnabled = (assetElement: AssetElement): boolean => {
  return assetElement.extension === AssetTypeNames.PDF && assetElement.embedEnabled === true;
};

export const isSupportedAudioExtension = (extension: string): boolean =>
  !!ASSET_TYPES.Audio.extensions?.includes(extension);

export const isSupportedAudioAsset = (assetData: AssetData | undefined): assetData is AudioAssetData => {
  return assetData?.type === AssetTypeNames.AUDIO && isSupportedAudioExtension(assetData.extension);
};

export const isSupportedVideoExtension = (extension: string): boolean =>
  !!ASSET_TYPES.Video.extensions?.includes(extension);

export const isSupportedVideoAsset = (assetData: AssetData | undefined): assetData is VideoAssetData => {
  return assetData?.type === AssetTypeNames.VIDEO && isSupportedVideoExtension(assetData.extension);
};

export const isSupportedMediaAsset = (assetData: AssetData | undefined): assetData is MediaAssetData => {
  return isSupportedAudioAsset(assetData) || isSupportedVideoAsset(assetData);
};
