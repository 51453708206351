import URI from 'jsuri';

import API from 'js/lib/api';

import { storeKey } from 'bundles/ondemand/utils/onDemandTutorialViewsApi';
import type { ApiResponse } from 'bundles/phoenix/components/ApiNotification';

const SessionsV2EnrollmentEligibilityCheckResource = API('/api/sessionsV2EnrollmentEligibilityCheck.v1', {
  type: 'rest',
});

// This method is in place to handle which users enter the sessionsV2 experience.
// Epic is handled via the blacklist API

export const checkSessionsV2Epic = (courseId: string) => {
  const uri = new URI(courseId).addQueryParam('fields', 'eligibleForSessionsV2');
  return new Promise((resolve) => {
    return resolve(SessionsV2EnrollmentEligibilityCheckResource.get(uri.toString()));
  }).then((response) => {
    const isInExperiment = (response as ApiResponse)?.elements?.[0]?.eligibleForSessionsV2 ?? false;

    if (isInExperiment) {
      const key = `sessionsV2Experiment.${courseId}`;
      return storeKey(key);
    }

    return undefined;
  });
};
