/* eslint-disable @typescript-eslint/no-explicit-any, camelcase */

/**
 * This file is autogenerated, please don't edit it manually.
 * Use this command to regenerate it if you modified Epic experiments:
 * yarn epic:types
 */

/**
 * Epic experiments namespaces with their possible keys.
 * Autogenerated from static/bundles/epic/data/defaults/
 */
import client from 'bundles/epic/client';
import Skills from 'bundles/epic/data/defaults/Skills.json';
import type { Tags } from 'bundles/epic/lib/EpicTypes';

type Namespace = {
  skillsInsights: 'tooltip' | 'inline' | 'inline-updated-home';
  enhancedSkillScoringEnabled: boolean;
  'enableTaxonomy2.0': boolean;
  enableSkillsDashboardV2: boolean;
};

const NAMESPACE = 'Skills';

// Adding safe optional chaining to avoid massive changes in unit tests.
client.addDefaults?.([Skills as $TSFixMe]);

const SkillsEpicClient = {
  get<K extends keyof Namespace>(key: K, tags?: Tags): Namespace[K] {
    return client.get(NAMESPACE, key, tags);
  },

  preview<K extends keyof Namespace>(key: K, tags?: Tags, defaults?: Namespace): Namespace[K] {
    return client.preview(NAMESPACE, key, tags);
  },
};

export default SkillsEpicClient;
