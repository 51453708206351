import { LearnerOnboarding_Goal as GoalNames } from '__generated__/graphql-types';

import _t from 'i18n!nls/program-personalized-tab';

function mapGoalToText(goal: string) {
  const goalToTextMapping: Record<string, string> = {
    [GoalNames.Credentials]: _t('earn academic credentials'),
    [GoalNames.Explore]: _t('I just want to explore'),
    [GoalNames.Grow]: _t('grow in my current role'),
    [GoalNames.Land]: _t('land my first job'),
    [GoalNames.Leader]: _t('become a successful leader'),
    [GoalNames.Switch]: _t('switch to a different role'),
  };

  return goalToTextMapping[goal];
}

export default mapGoalToText;
