/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { useSelected } from 'slate-react';
import type { RenderElementProps } from 'slate-react';

import type { Theme } from '@coursera/cds-core';

import { useFocusedContext } from 'bundles/cml/editor/context/focusContext';
import type { PersonalizationTagElement } from 'bundles/cml/shared/types/elementTypes';

const styles = {
  root: css`
    border: 1px solid var(--cds-color-neutral-stroke-primary);
    display: inline-block;
    padding: 3px;
    margin: 0 1px;
    font-weight: 600;
  `,
  selected: css`
    border-color: var(--cds-color-interactive-primary);
    color: var(--cds-color-interactive-primary);
  `,
  tag: css`
    user-select: none;
  `,
};

const PersonalizationTag: React.FC<RenderElementProps> = ({ element, attributes, children }) => {
  const { tagValue } = element as PersonalizationTagElement;
  const { focused } = useFocusedContext();
  const selected = useSelected();

  return (
    <span {...attributes} css={[styles.root, focused && selected && styles.selected]}>
      <span contentEditable={false} css={styles.tag}>
        {tagValue}
      </span>
      {children}
    </span>
  );
};

export default PersonalizationTag;
