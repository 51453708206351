import user from 'js/lib/user';

import { isS12nEligibleForCourseraPlusUpgrade } from 'bundles/coursera-plus/utils/courseraPlusUpgradeUtils';
import EnrollmentChoiceTypes from 'bundles/enroll-course/common/EnrollmentChoiceTypes';
import {
  choiceTypeToHandleSubmitPromise,
  submitEnrollmentPromise,
} from 'bundles/enroll-course/lib/enrollmentChoiceUtils';
import usePageData from 'bundles/enroll/data/usePageData';
import { useEnrollModalEventing } from 'bundles/enroll/utils/eventingUtils';
import {
  getCanEnrollThroughS12nSubscription,
  getCanEnrollThroughS12nSubscriptionFreeTrial,
  getS12nMonthlySkuId,
} from 'bundles/enroll/utils/mixAndMatchUtils';
import adsTracker from 'bundles/event-tracking/lib';

export const useEnrollInS12nSubscription = (isFromS12nSelection: boolean = false) => {
  const { trackEnrollModalContinue } = useEnrollModalEventing();
  const { enrollmentAvailableChoices, s12n, course, promotionEligibilities } = usePageData();
  const s12nId = s12n?.id;
  const courseId = course.id;
  const enrollmentData = s12nId ? { enrollmentAvailableChoices, isFromS12nSelection, s12nId } : undefined;
  const canEnrollThroughS12nSubscription = enrollmentData ? getCanEnrollThroughS12nSubscription(enrollmentData) : false;
  const canEnrollThroughS12nSubscriptionFreeTrial = enrollmentData
    ? getCanEnrollThroughS12nSubscriptionFreeTrial(enrollmentData)
    : false;
  const s12nMonthlySkuId = enrollmentData ? getS12nMonthlySkuId(enrollmentData) : undefined;
  const promoCode = promotionEligibilities?.isEligible ? promotionEligibilities?.promoCodeId : undefined;

  const enrollInSubscription = async (additionalParams?: Record<string, string>) => {
    const enrollmentChoiceType = EnrollmentChoiceTypes.ENROLL_THROUGH_S12N_SUBSCRIPTION;

    // Track S12nSubscriptionEnrollButtonClick GTM event
    adsTracker.trackS12nEnrollModalButtonClick({ userId: user.get()?.id, productId: s12nId });

    trackEnrollModalContinue(enrollmentChoiceType);

    // Skip the s12n welcome email if user can enroll in free trial, user is in Plus upsell epic,
    // and the s12n is eligible for the Plus upgrade
    let skipWelcomeEmail = false;
    if (s12nId && canEnrollThroughS12nSubscriptionFreeTrial) {
      skipWelcomeEmail = await isS12nEligibleForCourseraPlusUpgrade(s12nId);
    }

    const handleSubmitPromise = choiceTypeToHandleSubmitPromise[enrollmentChoiceType];
    const options = { productSkuId: s12nMonthlySkuId, courseId, skipWelcomeEmail };

    return submitEnrollmentPromise({
      handleSubmitPromise,
      options,
      promoCode,
      additionalParams,
    });
  };

  return {
    enrollInSubscription,
    s12nMonthlySkuId,
    canEnrollThroughS12nSubscription,
    canEnrollThroughS12nSubscriptionFreeTrial,
  };
};
