import * as React from 'react';
import Media from 'react-media';

import { renderToggleButtonWithoutCollapse } from 'bundles/enterprise-legacy-xdp/components/aboutXrenderFuncs';
import TogglableContent from 'bundles/page/components/TogglableContent';
import { EXP_TOGGLABLE_CONTENT_HEIGHT_PX, MOBILE_BREAKPOINT_PX } from 'bundles/program-common/constants/xdpConstants';
import type { AboutSectionTogglableContentProps } from 'bundles/program-common/types/xdpTypes';

export const AboutSectionTogglableContent: React.FC<AboutSectionTogglableContentProps> = ({
  userAgent,
  trackingName,
  renderedContentClassName,
  children,
}) => {
  return (
    <Media query={{ maxWidth: MOBILE_BREAKPOINT_PX - 1 }} defaultMatches={userAgent?.isMobileBrowser}>
      {(matches) => {
        if (matches) {
          return (
            <TogglableContent
              trackingName={trackingName}
              toggleButtonType="link"
              renderToggleButtonContentFunction={renderToggleButtonWithoutCollapse('ABOUT THIS SPECIALIZATION')}
              hideShowLessBtn
              disableCollapseScroll={true}
              childrenWrapperTag="div"
              collapsedHeight={EXP_TOGGLABLE_CONTENT_HEIGHT_PX}
              useSmartCollapse
            >
              {children}
            </TogglableContent>
          );
        } else {
          return <div className={renderedContentClassName}>{children}</div>;
        }
      }}
    </Media>
  );
};

export default AboutSectionTogglableContent;
