/* @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import type { CSSProperties } from '@coursera/coursera-ui';
import { breakPoint } from '@coursera/coursera-ui';

import type { PrepaidProduct } from 'bundles/enroll/types/ProductInfoTypes';
import Icon from 'bundles/iconfont/Icon';
import TrackedButton from 'bundles/page/components/TrackedButton';
import {
  getCurrentPaymentPassOption,
  getSortedPaymentPassOptions,
  useNonRecurringChoiceData,
} from 'bundles/s12n-enroll/components/non-recurring/utils/nonRecurringChoiceDataUtils';

import _t from 'i18n!nls/s12n-enroll';

export type PropsFromCaller = {
  choiceType: string;
  onButtonClick: (paymentPassOption: PrepaidProduct) => void;
  isEnrolling: boolean;
  auditComponent?: JSX.Element;
};

const useStyles = (): Record<string, CSSProperties> => {
  return {
    nonRecurringFooter: {
      width: '100%',
      background: 'white',
      padding: '16px 50px',
      boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.13)',
      [`@media (max-width: ${breakPoint.md - 1}px)`]: {
        padding: '16px 30px',
      },
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    continueButton: {
      background: '#2a73cc',
      color: 'white',
      border: 'none',
      borderRadius: '2px',
      padding: '16px 40px',
      fontWeight: 700,
      fontSize: '14px',
      width: '100%',
      maxWidth: '300px',
      '&:disabled': {
        background: '#e5e5e5',
        borderColor: '#e5e5e5',
        color: 'rgba(0,0,0,.26)',
      },
    },
  };
};

const NonRecurringChoiceFooter: React.FunctionComponent<PropsFromCaller> = ({
  choiceType,
  onButtonClick,
  isEnrolling,
  auditComponent,
}) => {
  const styles = useStyles();
  const { prepaidOptions } = useNonRecurringChoiceData();

  if (!prepaidOptions) {
    return null;
  }

  const sortedPaymentPassOptions = getSortedPaymentPassOptions(prepaidOptions);
  const paymentPassOption = getCurrentPaymentPassOption(choiceType, sortedPaymentPassOptions);

  return (
    <div css={styles.nonRecurringFooter}>
      <div css={styles.buttonContainer}>
        <TrackedButton
          data-test="continueButton"
          trackingName="continue_button"
          className="cozy"
          css={styles.continueButton}
          onClick={() => onButtonClick(paymentPassOption)}
          disabled={isEnrolling}
        >
          {isEnrolling ? <Icon name="spinner" spin /> : _t('Continue')}
        </TrackedButton>
      </div>
      {auditComponent}
    </div>
  );
};

export default NonRecurringChoiceFooter;
