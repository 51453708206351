import * as React from 'react';

import URI from 'jsuri';
import { compose, withProps } from 'recompose';

import connectToRouter from 'js/lib/connectToRouter';
import connectToStores from 'js/lib/connectToStores';
import type UserAgentInfo from 'js/lib/useragent';

import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { TrackedA } from 'bundles/page/components/TrackedLink2';
import { WHATSAPP_SOURCE_PARAM } from 'bundles/sharing-common/constants';
import type {
  SocialButtonPropsFromCaller,
  SocialButtonPropsToComponent,
} from 'bundles/sharing-common/types/sharingSharedTypes';
import withCustomUrl from 'bundles/sharing-common/utils/withCustomUrl';
import withFormattedShareLink from 'bundles/sharing-common/utils/withFormattedShareLink';
import ApplicationStoreClass from 'bundles/ssr/stores/ApplicationStore';

import _t from 'i18n!nls/sharing-common';

const BASE_WHATSAPP_DESKTOP_SHARE_URL = 'https://web.whatsapp.com/send';

const BASE_WHATSAPP_MOBILE_SHARE_URL = 'whatsapp://send';

class WhatsAppButton extends React.Component<SocialButtonPropsToComponent> {
  render() {
    const { customShareLink, createSocialShareLink, targetShareLink, withLabel, onSocialButtonClick } = this.props;

    return (
      <TrackedDiv className="rc-WhatsAppButton social-media-button" trackingName="social_share_button" trackMouseEnters>
        <TrackedA
          trackingName="whatsapp_share_button"
          className="social-media-button_link"
          href={createSocialShareLink(customShareLink || targetShareLink)}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onSocialButtonClick}
          ariaLabel={_t('Share with WhatsApp')}
        >
          <svg
            className="social-media-button_icon"
            width="53"
            height="52"
            viewBox="0 0 53 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="26.6428" cy="25.9805" r="25.7727" fill="#2A73CC" />
            <path
              d="M9.85742 43.3902L12.4488 33.9205C10.847 31.1434 10.0057 27.9931 10.0097 24.7872C10.0097 14.7147 18.2088 6.51947 28.2787 6.51947C30.6792 6.51981 33.0561 6.99312 35.2736 7.91237C37.4911 8.83162 39.5058 10.1788 41.2025 11.8769C42.8992 13.575 44.2446 15.5908 45.162 17.8091C46.0794 20.0273 46.5507 22.4046 46.549 24.8051C46.549 34.8777 38.3487 43.0742 28.28 43.0742H28.2723C25.2222 43.074 22.2207 42.3093 19.5422 40.85L9.85742 43.3902ZM19.9901 37.542L20.5455 37.8709C22.8852 39.2566 25.5544 39.9877 28.2736 39.9875H28.28C36.6493 39.9875 43.4611 33.1757 43.465 24.8026C43.4651 22.4499 42.9185 20.1293 41.8683 18.024C40.8182 15.9187 39.2932 14.0862 37.4137 12.671C35.5341 11.2559 33.3515 10.2968 31.0379 9.86954C28.7243 9.44224 26.3431 9.55838 24.0821 10.2088C21.8211 10.8592 19.7421 12.0261 18.0092 13.6174C16.2763 15.2087 14.9369 17.1809 14.0966 19.3785C13.2563 21.576 12.9382 23.9387 13.1672 26.2803C13.3962 28.6218 14.1662 30.8781 15.4164 32.8711L15.7773 33.4457L14.2417 39.0495L19.9901 37.542Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M37.484 29.1485C37.3701 28.9578 37.0656 28.8439 36.6087 28.6148C36.1519 28.3858 33.9085 27.2826 33.4901 27.1316C33.0716 26.9806 32.767 26.9026 32.4663 27.3607C32.1656 27.8188 31.2877 28.8464 31.0215 29.1523C30.7554 29.4581 30.4892 29.4953 30.0323 29.2662C29.5755 29.0371 28.1051 28.556 26.3621 26.9998C25.0044 25.7892 24.0881 24.2945 23.8232 23.8377C23.5583 23.3808 23.7951 23.1339 24.0228 22.9061C24.2289 22.7013 24.4797 22.3724 24.7075 22.1063C24.8957 21.8734 25.0495 21.6149 25.1643 21.3384C25.2248 21.2124 25.253 21.0733 25.2463 20.9337C25.2396 20.794 25.1982 20.6583 25.1259 20.5386C25.0121 20.3095 24.1022 18.0637 23.7183 17.15C23.3484 16.2542 22.9709 16.3821 22.6945 16.3655C22.4181 16.3489 22.1238 16.3502 21.8192 16.3502C21.588 16.3562 21.3605 16.4101 21.151 16.5083C20.9416 16.6065 20.7547 16.747 20.6022 16.9209C20.1837 17.3778 19.0051 18.4821 19.0051 20.7293C19.0051 22.9764 20.6406 25.1468 20.8697 25.4514C21.0987 25.756 24.0881 30.3667 28.6682 32.3439C29.5182 32.7104 30.387 33.0316 31.2711 33.3062C32.2021 33.589 33.1862 33.6508 34.1453 33.4866C35.0232 33.3587 36.8467 32.3823 37.2268 31.3112C37.6069 30.24 37.5979 29.3391 37.484 29.1485Z"
              fill="white"
            />
          </svg>
          {withLabel && <p className="social-media-button_label">{_t('WhatsApp')}</p>}
        </TrackedA>
      </TrackedDiv>
    );
  }
}

export default compose<SocialButtonPropsToComponent, SocialButtonPropsFromCaller>(
  // order of these are important
  connectToRouter((router) => ({ router })),
  connectToStores<SocialButtonPropsFromCaller, Omit<SocialButtonPropsFromCaller, 'userAgent'>, ApplicationStoreClass>(
    [ApplicationStoreClass],
    (ApplicationStore, props) => ({
      ...props,
      userAgent: ApplicationStore.getUserAgent(),
    })
  ),
  withFormattedShareLink(WHATSAPP_SOURCE_PARAM),
  withProps(({ generateCaption, userAgent }: { userAgent: UserAgentInfo } & SocialButtonPropsFromCaller) => {
    const createSocialShareLink = (shareLink: string) => {
      const whatsAppButtonUrl = userAgent.isMobileBrowser
        ? new URI(BASE_WHATSAPP_MOBILE_SHARE_URL)
        : new URI(BASE_WHATSAPP_DESKTOP_SHARE_URL);
      const message = generateCaption ? generateCaption(shareLink) : shareLink;

      whatsAppButtonUrl.addQueryParam('text', message);

      return whatsAppButtonUrl.toString();
    };

    return {
      createSocialShareLink,
    };
  }),
  withCustomUrl()
)(WhatsAppButton);
