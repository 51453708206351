import * as Sentry from '@sentry/react';
import type { Extras } from '@sentry/types';

type ErrorSource = string;

type Message = {
  error: Error;
  source?: ErrorSource | '';
  extra?: Extras;
  level?: Sentry.SeverityLevel;
  mode?: string;
};

/**
 * Capture messages in Sentry with the Coach prefix which enables us to filter for errors
 * related to Coach platform which we can target for slack alerts.
 * See https://coursera.sentry.io/alerts/rules/ondemand/15386162
 */
export const captureMessageInSentry = ({
  error,
  source = '',
  extra = {},
  level = 'error',
  mode = 'BASIC',
}: Message) => {
  const sourceString = source ? `[${source}]` : '';

  try {
    Sentry.captureMessage(`[AiCoachPlatform]: ${sourceString} ${error.toString()}`, {
      level,
      extra: {
        error,
        mode,
        ...extra,
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('[AiCoachPlatform]: Unable to capture this exception in Sentry', { error });
  }
};
