import * as React from 'react';

import moment from 'moment';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { Button, Divider } from '@coursera/coursera-ui';

import { CML } from 'bundles/cml';
import type OnDemandLearnerMaterialsV1 from 'bundles/naptimejs/resources/onDemandLearnerMaterials.v1';
import S12nInfo from 'bundles/ondemand/components/s12n-upgrade/S12nInfo';
import s12nUpgradeUtils from 'bundles/ondemand/utils/s12nUpgradeUtils';
import { TrackedA } from 'bundles/page/components/TrackedLink2';
import Modal from 'bundles/phoenix/components/Modal';
import type {
  S12nVersioningNotification_CourseFragment,
  S12nVersioningNotification_NewSpecializationFragment,
  S12nVersioningNotification_PartnerFragment,
  S12nVersioningNotification_SpecializationFragment,
} from 'bundles/program-home/queries/__generated__/S12nVersioningNotificationQuery';

import _t from 'i18n!nls/program-home';

import 'css!./__styles__/S12nVersioningInfoModal';

const NEW_VERSION = 'NEW_VERSION';
const CURRENT_VERSION = 'CURRENT_VERSION';
const LHC_HELP_LINK = 'https://learner.coursera.help/hc/articles/115000487063';

type Props = {
  currentS12n: S12nVersioningNotification_SpecializationFragment;
  newS12n: S12nVersioningNotification_NewSpecializationFragment;
  partners: Array<S12nVersioningNotification_PartnerFragment>;
  currentCourseProgress: Array<OnDemandLearnerMaterialsV1>;
  handleClose: () => void;
  handleUpgradingS12n: () => void;
  handleNotUpgradingS12n: () => void;
};

type State = {
  step: string;
};

class S12nVersioningInfoModal extends React.Component<Props, State> {
  state = {
    step: NEW_VERSION,
  };

  showCurrentVersion = () => {
    this.setState({ step: CURRENT_VERSION });
  };

  renderNewVersionCopy = () => {
    const { newS12n } = this.props;
    const newCourses = newS12n.courses;
    return (
      <div>
        <CML className="m-t-1" cml={newS12n.versionDescriptionAsCml} />
        {this.renderSwitchToNewVersionCopy()}
        {this.renderS12nInfo(newCourses)}
      </div>
    );
  };

  renderCurrentVersionCopy = () => {
    const { currentS12n } = this.props;
    const currentCourses = currentS12n.courses;
    const launchedAt = moment(currentS12n.launchedAt).format('MMMM Do, YYYY');
    const numCourses = currentS12n.courses.length;
    return (
      <div>
        <div className="vertical-box m-t-1">
          <FormattedMessage
            message={_t('Your version of {s12nName} was published on {launchedAt} and has {numCourses} courses.')}
            s12nName={currentS12n.name}
            launchedAt={launchedAt}
            numCourses={numCourses}
          />
          <p className="m-t-1">{_t("Here's what you have left:")}</p>
          {this.renderS12nInfo(currentCourses)}
          {this.renderSwitchToNewVersionCopy()}
        </div>
      </div>
    );
  };

  renderS12nInfo = (courses: Array<S12nVersioningNotification_CourseFragment>) => {
    const { currentS12n, newS12n, currentCourseProgress } = this.props;
    const progressStatus = s12nUpgradeUtils.getCoursesStatusById(
      currentCourseProgress,
      currentS12n.courses.map((course) => course.id),
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
      newS12n.courses.map((course) => course.id)
    );
    return <S12nInfo courses={courses} progressStatus={progressStatus} />;
  };

  renderSwitchToNewVersionCopy = () => {
    return (
      <div>
        <h5 className="headline-2-text m-t-1 m-b-1">{_t('Would you like to switch to the new version?')}</h5>
        <p className="m-b-1">
          {_t(
            "You'll receive credit for anything you've already completed, as long as it's included in the new version. "
          )}
          <TrackedA trackingName="learn_more_link" href={LHC_HELP_LINK} target="_blank" rel="noopener noreferrer">
            {_t('Learn More')}
          </TrackedA>
        </p>
      </div>
    );
  };

  render() {
    const { handleClose, newS12n, partners, handleUpgradingS12n, handleNotUpgradingS12n } = this.props;
    const partnerName = partners[0] && partners[0].name;
    const { step } = this.state;
    return (
      <Modal className="rc-S12nVersioningInfoModal" modalName="S12nVersioningInfoModal" handleClose={handleClose}>
        <div className="vertical-box align-items-vertical-center">
          <div className="c-bannerContainer">
            <div className="c-bannerImage" />
            <div className="c-bannerOverlay" />
            <div className="c-bannerText vertical-box align-items-absolute-center">
              <h2 className="display-6-text text-xs-center m-t-1 m-b-1 c-bannerHeading">{newS12n.name}</h2>
              <h4 className="display-4-text m-b-1 c-bannerSubheading">{partnerName}</h4>
            </div>
          </div>

          <div className="vertical-box p-l-1 p-r-1">
            <h5 className="c-contentHeader headline-2-text m-b-0 m-t-1">
              {step === NEW_VERSION && _t('About the update')}
              {step === CURRENT_VERSION && _t('About the current version')}
            </h5>
            <Divider />
            {step === NEW_VERSION && this.renderNewVersionCopy()}
            {step === CURRENT_VERSION && this.renderCurrentVersionCopy()}
            <div className="horizontal-box w-100 align-items-right m-t-1">
              <Button
                rootClassName="m-r-1"
                type="secondary"
                label={_t('Finish Current Version')}
                onClick={step === NEW_VERSION ? this.showCurrentVersion : handleNotUpgradingS12n}
              />
              <Button type="primary" label={_t('Switch to New Version')} onClick={handleUpgradingS12n} />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default S12nVersioningInfoModal;
