import URI from 'jsuri';
import Q from 'q';

import API from 'js/lib/api';

import type {
  WidgetAtomAuthoringData,
  WidgetAtomDraftPublish,
  WidgetAtomDraftUpdate,
  WidgetItemContent,
} from '../types/widgetItemContent';

const authoringWidgetItems = API('/api/authoringWidgetItems.v2/', { type: 'rest' });

const fields = ['draft', 'published', 'metadata', 'writeAccessToken', 'writeAccessState'];

export const getAuthoringWidgetItemDraft = (id: string): Q.Promise<WidgetAtomAuthoringData> => {
  const uri = new URI(id).addQueryParam('fields', fields.join(','));

  return Q(authoringWidgetItems.get(uri.toString())).then((response) => response.elements[0]);
};

export const createWidgetItemDraft = (id: string) => {
  const uri = new URI().addQueryParam('fields', 'draftData');
  const data = id;

  return Q(authoringWidgetItems.post(uri.toString(), { data }));
};

export const publishAuthoringWidgetItem = (id: string, data: WidgetAtomDraftPublish) => {
  const uri = new URI().addQueryParam('action', 'publish').addQueryParam('id', id);

  return Q(authoringWidgetItems.post(uri.toString(), { data }));
};

export const updateAuthoringWidgetItemDraft = (
  id: string,
  draft: WidgetItemContent,
  writeAccessToken: string
): Q.Promise<WidgetAtomAuthoringData> => {
  const uri = new URI(id).addQueryParam('fields', fields.join(','));

  const data: WidgetAtomDraftUpdate = {
    draft,
    writeAccessToken,
  };

  return Q(authoringWidgetItems.put(uri.toString(), { data })).then((response) => response.elements[0]);
};

export const deleteAuthoringWidgetItemDraft = (id: string) => {
  const uri = new URI(id);

  return Q(authoringWidgetItems.get(uri.toString()));
};
