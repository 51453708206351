/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { type Descendant, Editor, Transforms } from 'slate';
import { Editable, ReactEditor, Slate } from 'slate-react';

import { Typography2, useId } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

import ResizableContainer from 'bundles/authoring/common/components/ResizableContainer';
import DeleteConfirmDialog from 'bundles/cml/editor/components/dialogs/DeleteConfirmDialog';
import { useCMLToSlate } from 'bundles/cml/editor/components/hooks/useCMLToSlate';
import { useFocusHandler } from 'bundles/cml/editor/components/hooks/useFocusHandler';
import { useKeyDownHandler } from 'bundles/cml/editor/components/hooks/useKeyDownHandler';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import {
  useRenderElement,
  useRenderPlaceholder,
  useRenderTextElement,
} from 'bundles/cml/editor/components/hooks/useRenderElement';
import { useSlateToCML } from 'bundles/cml/editor/components/hooks/useSlateToCML';
import Toolbars from 'bundles/cml/editor/components/toolbars/Toolbars';
import FocusContextProvider from 'bundles/cml/editor/context/FocusContextProvider';
import StyleContextProvider from 'bundles/cml/editor/context/StyleContextProvider';
import { useFocusedContext } from 'bundles/cml/editor/context/focusContext';
import { useToolsContext } from 'bundles/cml/editor/context/toolsContext';
import type { Props as EditorProps } from 'bundles/cml/editor/types/cmlEditorProps';
import { useVariableSubstitutions } from 'bundles/cml/editor/utils/variableSubstitutionUtils';
import { cdsToCMLStyles } from 'bundles/cml/legacy/components/cds/cdsToCMLStyles';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import { usePrevious } from 'bundles/cml/shared/hooks/usePrevious';

import _t from 'i18n!nls/cml';

const styles = {
  label: css`
    margin-bottom: var(--cds-spacing-100);
  `,
  editor: (theme: Theme) => css`
    position: relative;
    outline: none;
    background-color: var(--cds-color-neutral-background-primary);

    [data-slate-editor='true'] {
      ${cdsToCMLStyles(theme)}

      // Slate does not properly handle scrolling with void nodes (images, assets, code blocks, etc...).
      // The bug is due to the internal spacer node having "absolute" positioning. This is especially a problem for void nodes
      // that have their own scrollable sections like Code blocks. CP-12378
      // https://github.com/ianstormtaylor/slate/issues/2302
      [data-slate-void='true'],
      [data-slate-spacer='true'] {
        position: relative !important;
      }
    }
  `,
  pageless: css`
    &,
    [data-testid='resizable-root'],
    [data-testid='resizable-container'],
    .data-cml-editor-scroll-container,
    .data-cml-editor-scroll-content,
    .data-cml-editor-padding-container {
      height: 100%;
    }

    [data-slate-editor='true'] {
      min-height: 100% !important;
      padding-bottom: var(--cds-spacing-300);
    }
  `,
  scrollContainer: css`
    height: 100%;
    overflow: auto;
  `,
  resizableContent: css`
    padding: var(--cds-spacing-150) var(--cds-spacing-200);
  `,
  pagelessContent: css`
    padding: var(--cds-spacing-50);
  `,
};

const TOOLBAR_HEIGHT = 53;
const BORDER_WIDTH = 1 * 2;
const MIN_HEIGHT = 104;
const MAX_HEIGHT = 320;

export type Props = Omit<
  EditorProps,
  | 'initialCML'
  | 'onContentChange'
  | 'uploadOptions'
  | 'fillableBlankTags'
  | 'enableMarkdown'
  | 'isLearnerUpload'
  | 'enableMonospace'
  | 'enableWidgets'
  | 'enableAiWritingAssistant'
  | 'customTools'
  | 'debounceDuration'
> & {
  editor: Editor;
  cmlValue: string;
  resizable?: boolean;
  className?: string;
  footer?: { node: React.ReactNode; height: number };
  children?: React.ReactNode;
  onChange?: (value: Descendant[]) => void;
};

const CMLEditor = React.forwardRef<HTMLDivElement, Props>((props, scrollRef) => {
  const {
    editor,
    cmlValue,
    onChange = () => undefined,
    onFocus,
    onBlur,
    macros,
    minHeight = MIN_HEIGHT,
    maxHeight = MAX_HEIGHT,
    usePagelessDesign: pageless = false,
    scrollingContainer,
    placeholder = _t('Enter text here'),
    focusOnLoad: autoFocus,
    shouldFocus = false,
    label,
    ariaLabel = _t('Rich Text Editor, toolbar available, standard formatting hotkeys supported'),
    ariaRequired,
    ariaLabelledBy,
    ariaDescribedBy,
    ariaInvalid,
    contentId,
    readOnly,
    footer,
    toolbarPosition = 'bottom',
    borderColor,
    resizable,
    className,
    children,
  } = props;

  const { tools, options } = useToolsContext();
  const staticEditor = useRef(editor).current;
  const id = useId();

  const [element, setElement] = useState<HTMLDivElement | null>(null);
  const { focused: focusedOverride } = useFocusedContext();
  const { focused, setFocused, setFocusedOverride } = useFocusHandler(element, onFocus, onBlur);

  const handleVariableSubstitutions = useVariableSubstitutions(macros);

  const value = useCMLToSlate(staticEditor, focused, cmlValue);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const renderElement = useRenderElement();
  const renderLeaf = useRenderTextElement();
  const renderPlaceholder = useRenderPlaceholder();

  const keydownHandlerOptions = useMemo(() => ({ setDeleteConfirm }), []);
  const handleKeyDown = useKeyDownHandler(staticEditor, keydownHandlerOptions);
  const handleChange = useSlateToCML(staticEditor, onChange);

  // disable scrollIntoView if the editor doesn't have focus
  // to prevent Slate from scrolling to the previous selection
  // if the container is scrolled when the editor is not focused
  const handleScrollIntoView = focused ? undefined : () => undefined;

  useEffect(() => {
    if (value.length > 0) {
      Editor.normalize(staticEditor, { force: true });
    }
  }, [tools, value, staticEditor]);

  const focusEditor = useCallback(() => {
    if (!staticEditor.selection) {
      if (!staticEditor.children.length) {
        Transforms.insertNodes(staticEditor, { type: BLOCK_TYPES.TEXT, children: [{ text: '' }] }, { at: [0] });
      }

      const start = Editor.start(staticEditor, [0]);
      staticEditor.selection = { anchor: start, focus: start };
    }
    ReactEditor.focus(staticEditor);
  }, [staticEditor]);

  useEffect(() => {
    if (autoFocus) {
      focusEditor();
    }
  }, [staticEditor, autoFocus, focusEditor]);

  const previousShouldFocus = usePrevious(shouldFocus);
  useEffect(() => {
    if (!previousShouldFocus && shouldFocus) {
      focusEditor();
    }
  }, [previousShouldFocus, shouldFocus, focusEditor]);

  const toolbar = !readOnly && (
    <Toolbars
      customTools={tools}
      scrollingContainer={scrollingContainer}
      pageless={pageless}
      position={toolbarPosition}
      options={options}
    />
  );

  const minContentHeight = resizable
    ? Math.max(minHeight, MIN_HEIGHT) - (readOnly ? 0 : TOOLBAR_HEIGHT) - BORDER_WIDTH - (footer?.height ?? 0)
    : undefined;

  return (
    <React.Fragment>
      {label && (
        <Typography2 css={styles.label} variant="subtitleMedium" component="label">
          {label}
        </Typography2>
      )}
      <div
        id={id}
        data-testid={id}
        ref={setElement}
        tabIndex={-1}
        className="rc-CMLEditor slate-editor"
        css={[styles.editor, pageless && styles.pageless]}
      >
        <FocusContextProvider focused={focused || focusedOverride} setFocused={setFocusedOverride}>
          <StyleContextProvider element={element} pageless={pageless}>
            <ResizableContainer
              contentSelector={`[id="${id}"] .data-cml-editor-scroll-content`}
              resizable={resizable}
              minHeight={Math.max(minHeight, MIN_HEIGHT)}
              initialHeight={maxHeight}
              borderColor={borderColor || (focused ? 'var(--cds-color-blue-700)' : undefined)}
              className={className}
            >
              <Slate editor={editor} value={value} onChange={handleChange}>
                <div
                  ref={scrollRef}
                  className="data-cml-editor-scroll-container"
                  css={resizable && styles.scrollContainer}
                >
                  <div className="data-cml-editor-scroll-content">
                    {toolbarPosition === 'top' && toolbar}
                    <div
                      className="data-cml-editor-padding-container"
                      css={resizable ? styles.resizableContent : styles.pagelessContent}
                      style={{ minHeight: minContentHeight }}
                    >
                      <Editable
                        decorate={macros ? handleVariableSubstitutions : undefined}
                        renderElement={renderElement}
                        renderPlaceholder={renderPlaceholder}
                        renderLeaf={renderLeaf}
                        placeholder={placeholder}
                        autoFocus={autoFocus}
                        onKeyDown={handleKeyDown}
                        onFocus={setFocused}
                        scrollSelectionIntoView={handleScrollIntoView}
                        aria-label={ariaLabel ?? label}
                        aria-required={ariaRequired}
                        aria-labelledby={ariaLabelledBy}
                        aria-describedby={ariaDescribedBy}
                        readOnly={readOnly}
                        data-testid={contentId}
                        aria-invalid={ariaInvalid}
                        tabIndex={0}
                      />
                    </div>
                    {toolbarPosition === 'bottom' && toolbar}
                    {footer?.node}
                  </div>
                </div>
              </Slate>
            </ResizableContainer>
          </StyleContextProvider>
        </FocusContextProvider>
        {deleteConfirm && <DeleteConfirmDialog editor={staticEditor} setConfirm={setDeleteConfirm} />}
        {children}
      </div>
    </React.Fragment>
  );
});

export default React.memo(CMLEditor);
