import React from 'react';

type ActionOverflowGroupContextType = {
  label: string;
  id: string;
};

export const ActionOverflowGroupContext = React.createContext<ActionOverflowGroupContextType | null>(
  null
);
