import { Transforms } from 'slate';
import type { Editor, Path } from 'slate';

import {
  ASSET_MANAGER_TRANSITION_MS,
  OPACITY_TRANSITION_MS,
} from 'bundles/cml/editor/components/elements/imageUploader/constants';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { ImageElement } from 'bundles/cml/shared/types/elementTypes';

export const imageUploadTransitionPromise = () =>
  new Promise((resolve) => {
    setTimeout(resolve, ASSET_MANAGER_TRANSITION_MS + OPACITY_TRANSITION_MS);
  });

export const updateImageAsset = (editor: Editor, id: string, path: Path) => {
  const imageElement: ImageElement = {
    type: BLOCK_TYPES.IMAGE,
    id,
    isVoid: true,
    children: [{ text: '' }],
  };

  Transforms.setNodes(editor, imageElement, { at: path });
};
