import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { getLanguageDirection, isUserRightToLeft } from 'js/lib/language';

import { Typography } from '@coursera/cds-core';
import { StarRating, StyleSheet, css } from '@coursera/coursera-ui';

import _t from 'i18n!nls/coursera-ui';

const styles = StyleSheet.create({
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  verticalOnMobile: {
    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },
  },
  titleStarContainer: {
    marginRight: '1.5rem',
    '@media (max-width: 600px)': {
      marginRight: 0,
    },
  },
  titleStarContainerRTL: {
    marginLeft: '1.5rem',
    '@media (max-width: 600px)': {
      marginLeft: 0,
    },
  },
});

type Props = {
  averageFiveStarRating?: number;
  commentCount?: number;
  ratingCount?: number;
};

class HorizontalCourseRatingBlock extends React.PureComponent<Props> {
  render() {
    const { averageFiveStarRating, commentCount, ratingCount } = this.props;

    return (
      <div {...css(styles.flexCenter, styles.verticalOnMobile)}>
        {averageFiveStarRating && (
          <span
            {...css(isUserRightToLeft() ? styles.titleStarContainerRTL : styles.titleStarContainer, styles.flexCenter)}
          >
            <Typography component="span" className="m-r-2" variant="d2semibold">
              {averageFiveStarRating.toFixed(1)}
            </Typography>
            <StarRating
              rating={averageFiveStarRating}
              enableColorOpacity
              dir={getLanguageDirection()}
              ariaLabel={_t('#{rating} out of 5 stars', { rating: Number(averageFiveStarRating.toFixed(1)) })}
            />
          </span>
        )}
        <span>
          {ratingCount && (
            <span className="m-r-1s">
              <FormattedMessage
                message={_t('{count} {count, plural, =1 { rating} other { ratings}}')}
                count={ratingCount.toLocaleString()}
              />
            </span>
          )}
          {commentCount && (
            <span>
              <span aria-hidden>&bull;</span>
              <span>
                {' '}
                <FormattedMessage
                  message={_t('{count} {count, plural, =1 { review} other { reviews}}')}
                  count={commentCount.toLocaleString()}
                />
              </span>
            </span>
          )}
        </span>
      </div>
    );
  }
}

export default HorizontalCourseRatingBlock;
