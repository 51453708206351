/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import type { Interpolation } from '@emotion/react';

import * as React from 'react';

import type { Boundary } from '@floating-ui/react';

import type { Theme } from '@coursera/cds-core';

import FloatingTooltip from 'bundles/common/components/Tooltip/FloatingTooltip';
import type { Placement, Variant } from 'bundles/common/components/Tooltip/types';

import { getOnlyChild } from './utils';

export type Props = {
  className?: string;
  tooltipId?: string;
  tooltipClassName?: string;
  tooltipCss?: Interpolation<Theme>;
  message?: React.ReactNode;
  show?: boolean | string;
  placement?: Placement;
  delayShow?: number;
  delayHide?: number;
  variant?: Variant;
  trigger?: 'hover' | 'click';
  offset?: number;
  enableArrow?: boolean;
  boundaryElement?: Boundary;
  boundaryElementMargin?: number;
  component?: React.ElementType;
  children: React.ReactNode;
};

const styles = {
  root: css`
    button {
      &:disabled {
        pointer-events: none;
      }
    }
  `,
};

const TooltipWrapper: React.FC<Props> = ({
  show = true,
  message,
  children,
  className = 'rc-TooltipWrapper',
  component: Tag = 'span',
  tooltipId,
  tooltipClassName,
  tooltipCss,
  offset,
  enableArrow,
  boundaryElement,
  boundaryElementMargin,
  ...props
}) => {
  const hasTooltip = show && !!message;

  if (!hasTooltip) {
    return (
      <Tag className={className} css={hasTooltip && styles.root}>
        {children}
      </Tag>
    );
  }

  const childIsArray = Array.isArray(children);
  const childIsString = typeof children === 'string';
  const onlyChild = !childIsArray && !childIsString ? getOnlyChild(children) : null;
  // @ts-expect-error [fe-tech-debt] getOnlyChild types don't cover the wide variety of possible outputs
  const childIsDisabled = Boolean(onlyChild?.disabled || onlyChild?.props.disabled);
  // @ts-expect-error [fe-tech-debt] getOnlyChild types don't cover the wide variety of possible outputs
  const childHasLegacyRef = typeof onlyChild?.ref === 'string';

  // FloatingTooltip only supports single enabled elements as children, so if we a disabled element, string or array we need to wrap it.
  // NOTE: like all tooltips on non-focusable elements, the tooltip will not be accessible in this case because the aria-describedby will be attached to the wrapper span which will not recieve focus and be announced
  const needsWrapper = childIsDisabled || childIsArray || childHasLegacyRef || childIsString;

  if (needsWrapper) {
    return (
      <FloatingTooltip
        {...props}
        message={message}
        id={tooltipId}
        className={tooltipClassName}
        css={tooltipCss}
        offset={offset}
        enableArrow={enableArrow}
        boundaryElement={boundaryElement}
        boundaryElementMargin={boundaryElementMargin}
      >
        <Tag data-testid="tooltip-wrapper" className={className} css={hasTooltip && styles.root}>
          {children}
        </Tag>
      </FloatingTooltip>
    );
  }

  return (
    <Tag data-testid="tooltip-wrapper" className={className} css={hasTooltip && styles.root}>
      <FloatingTooltip
        {...props}
        message={message}
        id={tooltipId}
        className={tooltipClassName}
        css={tooltipCss}
        offset={offset}
        enableArrow={enableArrow}
        boundaryElement={boundaryElement}
        boundaryElementMargin={boundaryElementMargin}
      >
        {children}
      </FloatingTooltip>
    </Tag>
  );
};

export default TooltipWrapper;
