/**
 * This is a language mapping that contains languages that fulfill all the requirements below:
 * 1. Languages supported by franc - language recognition library.
 * 2. Languages that are supported by Amazon Translate.
 *
 * The values are used for frontend language detection which determines whether we should show the translation button.
 *
 * Some of the mappings are not exactly 1:1, the language detection library may detect a more specific variant of the
 * language which wouldn't have a 2-character code. For example, it can detect both Iranian Persian and Afghan Persian,
 * but we are mapping both to 'fa' which is generic Persian.
 */
export const supportedLanguages: {
  francCode: string;
  languageCode: string;
}[] = [
  { francCode: 'afr', languageCode: 'af' },
  { francCode: 'als', languageCode: 'sq' },
  { francCode: 'amh', languageCode: 'am' },
  { francCode: 'arb', languageCode: 'ar' },
  { francCode: 'hye', languageCode: 'hy' },
  { francCode: 'azj', languageCode: 'az' },
  { francCode: 'ben', languageCode: 'bn' },
  { francCode: 'bos', languageCode: 'bs' },
  { francCode: 'bul', languageCode: 'bg' },
  { francCode: 'cat', languageCode: 'ca' },
  { francCode: 'cmn', languageCode: 'zh' },
  { francCode: 'hrv', languageCode: 'hr' },
  { francCode: 'ces', languageCode: 'cs' },
  { francCode: 'dan', languageCode: 'da' },
  { francCode: 'prs', languageCode: 'fa' },
  { francCode: 'nld', languageCode: 'nl' },
  { francCode: 'eng', languageCode: 'en' },
  { francCode: 'ekk', languageCode: 'et' },
  { francCode: 'fin', languageCode: 'fi' },
  { francCode: 'fra', languageCode: 'fr' },
  { francCode: 'kat', languageCode: 'ka' },
  { francCode: 'deu', languageCode: 'de' },
  { francCode: 'ell', languageCode: 'el' },
  { francCode: 'guj', languageCode: 'gu' },
  { francCode: 'hat', languageCode: 'ht' },
  { francCode: 'hau', languageCode: 'ha' },
  { francCode: 'heb', languageCode: 'he' },
  { francCode: 'hin', languageCode: 'hi' },
  { francCode: 'hun', languageCode: 'hu' },
  { francCode: 'ind', languageCode: 'id' },
  { francCode: 'ita', languageCode: 'it' },
  { francCode: 'jpn', languageCode: 'ja' },
  { francCode: 'kan', languageCode: 'kn' },
  { francCode: 'kaz', languageCode: 'kk' },
  { francCode: 'kor', languageCode: 'ko' },
  { francCode: 'lvs', languageCode: 'lv' },
  { francCode: 'lit', languageCode: 'lt' },
  { francCode: 'mkd', languageCode: 'mk' },
  { francCode: 'zlm', languageCode: 'ms' },
  { francCode: 'mal', languageCode: 'ml' },
  { francCode: 'mar', languageCode: 'mr' },
  { francCode: 'khk', languageCode: 'mn' },
  { francCode: 'nob', languageCode: 'no' },
  { francCode: 'pbu', languageCode: 'ps' },
  { francCode: 'pes', languageCode: 'fa' },
  { francCode: 'pol', languageCode: 'pl' },
  { francCode: 'por', languageCode: 'pt' },
  { francCode: 'pan', languageCode: 'pa' },
  { francCode: 'ron', languageCode: 'ro' },
  { francCode: 'rus', languageCode: 'ru' },
  { francCode: 'srp', languageCode: 'sr' },
  { francCode: 'sin', languageCode: 'si' },
  { francCode: 'slk', languageCode: 'sk' },
  { francCode: 'slv', languageCode: 'sl' },
  { francCode: 'som', languageCode: 'so' },
  { francCode: 'spa', languageCode: 'es' },
  { francCode: 'swh', languageCode: 'sw' },
  { francCode: 'swe', languageCode: 'sv' },
  { francCode: 'tgl', languageCode: 'tl' },
  { francCode: 'tam', languageCode: 'ta' },
  { francCode: 'tel', languageCode: 'te' },
  { francCode: 'tha', languageCode: 'th' },
  { francCode: 'tur', languageCode: 'tr' },
  { francCode: 'ukr', languageCode: 'uk' },
  { francCode: 'urd', languageCode: 'ur' },
  { francCode: 'uzn', languageCode: 'uz' },
  { francCode: 'vie', languageCode: 'vi' },
];

export const francLangCodes = supportedLanguages.map((x) => x.francCode);
export const francCodeToLangCodeMap = new Map(supportedLanguages.map((x) => [x.francCode, x.languageCode]));
