import * as React from 'react';

import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import type { LearnerOnboarding_Goal as LearnerGoal } from '__generated__/graphql-types';
import { keyBy } from 'lodash';

import { SKILLS_IDS_FOR_CLIPS_ALLOW_LIST } from 'bundles/program-home/constants/skillCollectionsConstants';
import { LEADERSHIP_SKILLS } from 'bundles/program-personalized-tab/constants';
import ClipsRecsContext from 'bundles/program-personalized-tab/contexts/ClipsRecsContext';
import { useOnboardingData } from 'bundles/program-personalized-tab/contexts/OnboardingContext';
import EnterpriseClipsRecommendationsForSkills from 'bundles/program-personalized-tab/queries/EnterpriseClipsRecommendationsForSkills.graphql';
import type {
  EnterpriseClipsRecommendationsForSkillsQuery,
  EnterpriseClipsRecommendationsForSkillsQueryVariables,
} from 'bundles/program-personalized-tab/queries/__generated__/EnterpriseClipsRecommendationsForSkills';
import type { ClipsCollectionsBySkill, Skill } from 'bundles/program-personalized-tab/types/sharedTypes';
import { getClipsRequestSkillsParams } from 'bundles/program-personalized-tab/utils/clipsRecsUtils';

type PropsToComponent = {
  programId: string;
  children: React.ReactNode;
};

const useClipsRecsForSkillsQuery = (skills: Skill[] | undefined, programId: string) => {
  const skillParams = getClipsRequestSkillsParams(skills);
  const { loading, error, data } = useQuery<
    EnterpriseClipsRecommendationsForSkillsQuery,
    EnterpriseClipsRecommendationsForSkillsQueryVariables
  >(EnterpriseClipsRecommendationsForSkills, {
    variables: {
      programId,
      skillParams,
      contextId: 'personalized-tab-skill-based-clips',
    },
    skip: skillParams.length === 0,
    notifyOnNetworkStatusChange: true,
    context: { clientName: 'gatewayGql' },
  });

  const skillByName = keyBy(skills, 'name');
  const clipsCollectionsBySkill: ClipsCollectionsBySkill = {};
  for (const collection of data?.DiscoveryCollections?.queryEnterpriseCollectionsByProgram?.productCollections ?? []) {
    if (collection?.label && skillByName[collection.label]) {
      const k = skillByName[collection.label].id;
      // @ts-expect-error Duration: string
      clipsCollectionsBySkill[k] = collection;
    }
  }

  return { loading, error, recs: clipsCollectionsBySkill };
};

const ClipsRecsDataProvider = ({ programId, children }: PropsToComponent) => {
  const onboardingData = useOnboardingData();
  const allowlistedSkills = onboardingData?.skills?.filter((skill) =>
    SKILLS_IDS_FOR_CLIPS_ALLOW_LIST.includes(skill.id)
  );
  // Only need to get the Clips leadership recs in the case that the learner has selected the LEADER goal,
  // and they haven't selected GROW, LAND, or SWITCH
  const shouldGetLeadershipRecs =
    onboardingData?.selectedGoals?.includes('LEADER') &&
    !(['GROW', 'LAND', 'SWITCH'] as LearnerGoal[]).some((goal) => onboardingData.selectedGoals?.includes(goal));

  const { loading, error, recs } = useClipsRecsForSkillsQuery(allowlistedSkills, programId);
  const {
    loading: leadershipRecsLoading,
    error: leadershipRecsError,
    recs: leadershipRecs,
  } = useClipsRecsForSkillsQuery(shouldGetLeadershipRecs ? LEADERSHIP_SKILLS : [], programId);

  if (error) {
    Sentry.captureException(error);
  }

  return (
    <ClipsRecsContext.Provider
      value={{ loading, error, recs, leadershipRecs, leadershipRecsLoading, leadershipRecsError }}
    >
      {children}
    </ClipsRecsContext.Provider>
  );
};

export default ClipsRecsDataProvider;
