import * as React from 'react';
import { Mutation } from 'react-apollo';

import type {
  CourseraTierSubscriptionsMutationQueriesCreateCartArgs,
  Mutation as MutationType,
} from '__generated__/graphql-types';

import prices from 'js/lib/prices';
import requestCountry from 'js/lib/requestCountry';
import { tupleToStringKey } from 'js/lib/stringKeyTuple';
import user from 'js/lib/user';

import { createCartMutation } from 'bundles/coursera-plus/api/CourseraTierSubscriptionsMutations';
import { courseraLiteProductVariants } from 'bundles/coursera-plus/constants/CourseraPlusProductVariant';
import CartsV2 from 'bundles/naptimejs/resources/carts.v2';

type CartRequestOptions = {
  productType: string;
  productItemId: string;
  courseId: string;
};

export type PropsFromWithCourseraLiteCreateCartMutation = {
  createCourseraLiteCart: (options: CartRequestOptions) => Promise<CartsV2 | undefined>;
};

type PropsToComponent = { children: (renderProps: PropsFromWithCourseraLiteCreateCartMutation) => React.ReactElement };

export const CourseraLiteCreateCartProvider: React.FunctionComponent<PropsToComponent> = (props) => {
  const { children } = props;

  return (
    <Mutation<{}, CourseraTierSubscriptionsMutationQueriesCreateCartArgs>
      mutation={createCartMutation}
      context={{ clientName: 'gatewayGql' }}
    >
      {(mutation) => {
        const createCourseraLiteCart = ({ productType, productItemId, courseId }: CartRequestOptions) => {
          const requestCountryCode = requestCountry.get();
          const isProductEnrollmentInformationAvailable = productType && productItemId && courseId;
          return mutation({
            variables: {
              input: {
                userId: String(user.get().id),
                currencyCode: prices.getCurrencyFromCountry(requestCountryCode),
                countryIsoCode: requestCountryCode,
                productItems: [
                  {
                    productType: 'COURSERA_TIER_LITE',
                    productItemId: courseraLiteProductVariants.MONTHLY_WITH_FREE_TRIAL,
                    productAction: 'BUY',
                    metadata: {
                      courseraTierLiteCartItemMetadataInput: {
                        paymentType: 'SUBSCRIPTION_CART_ITEM_METADATA_PAYMENT_TYPE_INITIAL_CHECKOUT',
                        ...(isProductEnrollmentInformationAvailable
                          ? {
                              productEnrollmentInformation: {
                                productIdToEnroll: tupleToStringKey([productType, productItemId]),
                                courseIdToGrantMembership: courseId,
                              },
                            }
                          : {}),
                      },
                    },
                  },
                ],
              },
            },
          }).then((response) => {
            // Return a CartsV2 object so that it's the same shape as other cart create requests in enrollmentChoiceUtils.ts
            const cart = (response?.data as MutationType)?.CourseraTierSubscriptionsMutations?.createCart?.cart;

            if (cart) {
              return new CartsV2(cart);
            }

            return undefined;
          });
        };
        return children({ createCourseraLiteCart });
      }}
    </Mutation>
  );
};

export const withCourseraLiteCreateCartMutation = function <PropsFromCaller>(
  Component: React.ComponentType<PropsFromCaller & PropsFromWithCourseraLiteCreateCartMutation>
) {
  return (props: PropsFromCaller) => (
    <CourseraLiteCreateCartProvider>
      {({ createCourseraLiteCart }) => <Component {...props} createCourseraLiteCart={createCourseraLiteCart} />}
    </CourseraLiteCreateCartProvider>
  );
};

export default withCourseraLiteCreateCartMutation;
