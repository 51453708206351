/** @jsx jsx */
import { jsx } from '@emotion/react';

// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import { ConfigurationEditorView } from 'bundles/author-widget/components/ConfigurationEditorView';
import { useHandleConfigurationChange } from 'bundles/author-widget/components/useHandleConfigurationChange';
import type { AuthoringWidgetSessionProvidersV1 } from 'bundles/naptimejs/resources/__generated__/AuthoringWidgetSessionProvidersV1';

type Props = {
  widgetSessionProviderDraft: AuthoringWidgetSessionProvidersV1;
  catchInvalidJsonError: (value: boolean) => void;
  readOnly: boolean;
  hasCustomLabel?: boolean;
  className?: string;
  // Custom onChange provided for cml embedded plugins.
  onChange?: (value: AuthoringWidgetSessionProvidersV1['draft']['configuration']) => void;
  courseId: string;
};

const ConfigurationEditor = ({
  widgetSessionProviderDraft,
  catchInvalidJsonError,
  readOnly = false,
  hasCustomLabel,
  className,
  onChange,
  courseId,
}: Props) => {
  const handleChange = useHandleConfigurationChange({
    widgetSessionProviderDraft,
    catchInvalidJsonError,
    courseId,
    onChange,
  });

  return (
    <ConfigurationEditorView
      readOnly={readOnly}
      hasCustomLabel={hasCustomLabel}
      className={className}
      handleChange={handleChange}
      widgetConfig={widgetSessionProviderDraft?.draft.configuration}
    />
  );
};

export default ConfigurationEditor;
