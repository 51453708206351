import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Measure from 'react-measure';
import type { ContentRect } from 'react-measure';

import { isEqual } from 'lodash';

import MoreButton from 'bundles/cml/editor/components/buttons/more/MoreButton';
import Toolbar from 'bundles/cml/editor/components/toolbars/Toolbar';
import { ToolButtonFactory } from 'bundles/cml/editor/components/toolbars/constants';
import type { ToolbarProps } from 'bundles/cml/editor/components/toolbars/types';
import type { ToolsWidthMap } from 'bundles/cml/editor/components/toolbars/utils';
import { getEditorTools, getVisibleTools } from 'bundles/cml/editor/components/toolbars/utils';
import type { ToolbarToolsKeys } from 'bundles/cml/shared/utils/customTools';

type Props = React.HTMLAttributes<HTMLElement> & ToolbarProps;

type ToolbarButtonsProps = ToolbarProps & {
  toolbarWidth: number;
  updateFocus: () => void;
};

const ToolbarButtons: React.FC<ToolbarButtonsProps> = (props) => {
  const { customTools, options, pageless, toolbarWidth, updateFocus } = props;

  const [toolsWidth, setToolsWidth] = useState<ToolsWidthMap>({} as ToolsWidthMap);
  const [visibleTools, setVisibleTools] = useState<ToolbarToolsKeys[]>([]);
  const [overflowTools, setOverflowTools] = useState<ToolbarToolsKeys[]>([]);

  const toolbarTools = useMemo(() => getEditorTools(customTools, options), [options, customTools]);

  useEffect(() => {
    if (toolbarWidth === 0 || toolbarTools.length === 0) {
      return;
    }

    const { visible, overflow } = getVisibleTools(toolbarTools, pageless, toolsWidth, toolbarWidth);
    if (!isEqual(visible, visibleTools) || !isEqual(overflow, overflowTools)) {
      setOverflowTools(overflow);
      setVisibleTools(visible);
    }
  }, [toolbarWidth, pageless, toolbarTools, toolsWidth, overflowTools, visibleTools]);

  useEffect(() => {
    updateFocus();
  }, [visibleTools, updateFocus]);

  const handleButtonResize = useCallback((tool: ToolbarToolsKeys) => {
    return ({ offset }: ContentRect) => {
      setToolsWidth((prev) => ({ ...prev, [tool]: offset?.width ?? 0 }));
    };
  }, []);

  if (customTools.length === 0) {
    return null;
  }

  const visibleButtons = visibleTools.map((tool: ToolbarToolsKeys) => {
    const toolbarButtonFactory = ToolButtonFactory[tool];
    if (!toolbarButtonFactory) {
      return null;
    }

    const ToolbarButton = toolbarButtonFactory();
    return (
      <Measure key={tool} offset onResize={handleButtonResize(tool)}>
        {({ measureRef }) => (
          <div ref={measureRef}>
            <ToolbarButton tools={customTools} pageless={pageless} />
          </div>
        )}
      </Measure>
    );
  });

  if (overflowTools.length) {
    visibleButtons.push(<MoreButton key="MORE" {...props} tools={customTools} overflowTools={overflowTools} />);
  }

  return <React.Fragment>{visibleButtons}</React.Fragment>;
};

const ResponsiveToolbar: React.FC<Props> = (props) => {
  const { customTools, options, pageless, ...attributes } = props;

  const [toolbarWidth, setToolbarWidth] = useState(0);
  const handleToolbarResize = useCallback(({ offset }: ContentRect) => {
    setToolbarWidth(offset?.width ?? 0);
  }, []);

  return (
    <Measure offset onResize={handleToolbarResize}>
      {({ measureRef }) => (
        <Toolbar ref={measureRef} pageless={pageless} {...attributes}>
          {(updateFocus) => (
            <ToolbarButtons
              customTools={customTools}
              options={options}
              pageless={pageless}
              toolbarWidth={toolbarWidth}
              updateFocus={updateFocus}
            />
          )}
        </Toolbar>
      )}
    </Measure>
  );
};

export default ResponsiveToolbar;
