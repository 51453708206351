import * as React from 'react';
import { useContext } from 'react';

import type { ApolloError } from '@apollo/client';

import type { MixedCollectionsBySkill, SkillData } from 'bundles/program-personalized-tab/types/sharedTypes';

export type ScoredSkillRecsContextType = {
  getRecs: () => MixedCollectionsBySkill;
  skillData: SkillData;
  loading?: boolean;
  error?: { [key: string]: ApolloError | undefined };
};

const ScoredSkillRecsContext = React.createContext<ScoredSkillRecsContextType>({ getRecs: () => ({}), skillData: {} });

export const useScoredSkillRecsData = () => {
  const scoredSkillRecsContext = useContext(ScoredSkillRecsContext);
  return scoredSkillRecsContext;
};

export default ScoredSkillRecsContext;
