import { useQuery } from '@apollo/client';

import ProgramCurriculumCoursesQuery from 'bundles/page-config-common/queries/ProgramCurriculumCoursesQuery.graphql';
import type {
  ProgramCurriculumCoursesQuery as ProgramCurriculumCoursesQueryType,
  ProgramCurriculumCoursesQueryVariables,
} from 'bundles/page-config-common/queries/__generated__/ProgramCurriculumCoursesQuery';
import type { Product as CurriculumProduct } from 'bundles/program-common/types/programCommon';

const courseEntityTypeMetadataToCurriculumProduct = {
  // eslint-disable-next-line camelcase
  Course_ProjectMetadata: 'CourseTypeMetadataV1_projectMember',
  // eslint-disable-next-line camelcase
  Course_RhymeProjectMetadata: 'CourseTypeMetadataV1_rhymeProjectMember',
  // eslint-disable-next-line camelcase
  Course_ShortFormContentMetadata: 'CourseTypeMetadataV1_shortFormContentMember',
  // eslint-disable-next-line camelcase
  Course_StandardCourseMetadata: 'CourseTypeMetadataV1_standardCourseMember',
};

// There is a bug with private courses not showing up for valid users returned from calls to program curriculum collections,
// we use this hook for curated and for credit collections to get valid courses for the courses in those collections.
const useCourseEntitiesAsCurriculumCourses = (courseIds?: string[]) => {
  const { data, loading } = useQuery<ProgramCurriculumCoursesQueryType, ProgramCurriculumCoursesQueryVariables>(
    ProgramCurriculumCoursesQuery,
    {
      variables: {
        // @ts-expect-error we check for data being present in skip.
        courseIds,
      },
      skip: !courseIds?.length,
      ssr: false,
      errorPolicy: 'all' as const,
      context: {
        clientName: 'gatewayGql',
      },
    }
  );

  const courseMap = data?.Course?.queryByIds?.reduce((map, course) => {
    if (course) {
      // eslint-disable-next-line no-param-reassign
      map[course.id] = {
        id: course.id,
        name: course.name,
        slug: course.slug,
        partners: {
          elements: course.partners.map((partner) => {
            return {
              name: partner.name,
              id: partner.id,
              logo: partner.logo,
              squareLogo: partner.squareLogo,
              classLogo: partner.classLogo,
            };
          }),
        },
        promoPhoto: course.promoPhotoUrl,
        courseTypeMetadata: {
          __typename: 'CourseTypeMetadataV1',
          courseTypeMetadata: {
            __typename: courseEntityTypeMetadataToCurriculumProduct[course.courseTypeMetadata.metadata.__typename],
          },
        },
      } as CurriculumProduct;
    }

    return map;
  }, {} as { [key: string]: CurriculumProduct | null });

  return [courseMap, loading] as [{ [key: string]: CurriculumProduct | null }, boolean];
};

export default useCourseEntitiesAsCurriculumCourses;
