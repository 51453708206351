import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

export type EnterpriseBadgeEnrollInput = {
  input: { user_id: number; badge_template_id: string; request_origin: string };
};

const EnterpriseBadgeEnrollMutation = gql`
  mutation EnterpriseBadgeEnrollMutation($input: EnterpriseBadgeEnrollInput!) {
    EnrollBadgeResponse(input: $input)
      @rest(
        type: "EnrollBadgeResponse"
        path: "grpc/badge/badgeuserstates/v1/BadgeUserStatesAPI/EnrollBadge"
        method: "POST"
      ) {
      response
    }
  }
`;

const useEnterpriseBadgeEnrollMutation = function () {
  const [enterpriseBadgeEnroll] = useMutation<{}, EnterpriseBadgeEnrollInput>(EnterpriseBadgeEnrollMutation);
  return ({
    user_id,
    badge_template_id,
    request_origin = 'REQUEST_ORIGIN_USER',
  }: EnterpriseBadgeEnrollInput['input']) => {
    return enterpriseBadgeEnroll({ variables: { input: { user_id, badge_template_id, request_origin } } });
  };
};

export default useEnterpriseBadgeEnrollMutation;
