import * as React from 'react';

import CodeEvaluatorSlowProgressIndicator from 'bundles/code-evaluator/components/CodeEvaluatorSlowProgressIndicator';
import ConsoleOutput from 'bundles/code-evaluator/components/ConsoleOutput';
import type EvaluatorSummary from 'bundles/code-evaluator/models/EvaluatorSummary';

import _t from 'i18n!nls/code-evaluator';

interface Props {
  evaluatorSummary: EvaluatorSummary;
  onCancel: () => void;
}

const CodeEvaluatorProgress = ({ evaluatorSummary, onCancel }: Props) => {
  const { executionTimeSummary } = evaluatorSummary;
  const { isExecutionSlow } = executionTimeSummary;

  return (
    <div className="rc-CodeEvaluatorProgress">
      {!isExecutionSlow && (
        <div className="in-progress">
          <ConsoleOutput>{_t('Processing...')}</ConsoleOutput>
        </div>
      )}

      {isExecutionSlow && (
        <CodeEvaluatorSlowProgressIndicator executionTimeSummary={executionTimeSummary} onCancel={onCancel} />
      )}
    </div>
  );
};

export default CodeEvaluatorProgress;
