import * as React from 'react';

import { useQuery } from '@apollo/client';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { Notification } from '@coursera/coursera-ui';

import { useNaptime } from 'bundles/naptimejs';
import OnDemandLearnerMaterialsV1 from 'bundles/naptimejs/resources/onDemandLearnerMaterials.v1';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import type ProgramCurriculumProductsV1 from 'bundles/naptimejs/resources/programCurriculumProducts.v1';
import S12nVersioningNotificationQuery from 'bundles/program-home/queries/S12nVersioningNotificationQuery.graphql';
import type {
  S12nVersioningNotificationQuery as S12nVersioningNotificationQueryType,
  S12nVersioningNotificationQueryVariables,
} from 'bundles/program-home/queries/__generated__/S12nVersioningNotificationQuery';
import S12nVersioningConfirmationModal from 'bundles/s12n-common/components/s12n-versioning/S12nVersioningConfirmationModal';
import S12nVersioningInfoModal from 'bundles/s12n-common/components/s12n-versioning/S12nVersioningInfoModal';

import _t from 'i18n!nls/program-home';

import 'css!./__styles__/S12nVersioningNotification';

type Props = {
  userId: number;
  product: ProgramCurriculumProductsV1;
  programId: string;
};

type PropsFromNaptime = {
  currentCourseProgress: Array<OnDemandLearnerMaterialsV1>;
};

export default function S12nVersioningNotification(props: Props) {
  const [infoModalIsOpen, setInfoModalIsOpen] = React.useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = React.useState(false);
  const [willUpgradeS12n, setWillUpgradeS12n] = React.useState(true);

  const handleUpgradingS12n = React.useCallback(() => {
    setInfoModalIsOpen(false);
    setConfirmationModalIsOpen(true);
    setWillUpgradeS12n(true);
  }, [setInfoModalIsOpen, setConfirmationModalIsOpen, setWillUpgradeS12n]);

  const handleNotUpgradingS12n = React.useCallback(() => {
    setInfoModalIsOpen(false);
    setConfirmationModalIsOpen(true);
    setWillUpgradeS12n(false);
  }, [setInfoModalIsOpen, setConfirmationModalIsOpen, setWillUpgradeS12n]);

  const openInfoModal = React.useCallback(() => {
    setInfoModalIsOpen(true);
  }, [setInfoModalIsOpen]);

  const closeInfoModal = React.useCallback(() => {
    setInfoModalIsOpen(false);
  }, [setInfoModalIsOpen]);

  const closeConfirmationModal = React.useCallback(() => {
    setConfirmationModalIsOpen(false);
  }, [setConfirmationModalIsOpen]);

  const { product, userId, programId } = props;

  const { data } = useQuery<S12nVersioningNotificationQueryType, S12nVersioningNotificationQueryVariables>(
    S12nVersioningNotificationQuery,
    {
      variables: {
        s12nId: product.s12nId,
        latestS12nId: product.latestS12nId,
      },
      context: {
        clientName: 'gatewayGql',
      },
    }
  );

  const newS12n = data?.Specialization?.newS12n;
  const currentS12n = data?.Specialization?.currentS12n;

  const {
    data: { currentCourseProgress },
    naptime,
  } = useNaptime<PropsFromNaptime>(() => {
    if (!currentS12n) {
      return {};
    }
    return {
      currentCourseProgress: OnDemandLearnerMaterialsV1.finder('courses', {
        params: {
          courseIds: currentS12n?.courses.map((course) => course.id) ?? [],
        },
        fields: ['isOverallPassed', 'overallGrade', 'courseId'],
      }),
    };
  }, [currentS12n]);

  if (!newS12n || !currentS12n || !currentCourseProgress) {
    return null;
  }

  const s12nName = currentS12n.name;

  return (
    <div className="rc-S12nVersioningNotification horizontal-box">
      <Notification
        message={
          <FormattedMessage message={_t('An updated version of {s12nName} is now available.')} s12nName={s12nName} />
        }
        type="error"
        action={_t('Learn More')}
        onAction={openInfoModal}
        style={{ zIndex: 'unset' }} // override default style
      />
      {infoModalIsOpen && (
        <S12nVersioningInfoModal
          newS12n={newS12n}
          currentS12n={currentS12n}
          currentCourseProgress={currentCourseProgress}
          partners={newS12n.partners}
          handleClose={closeInfoModal}
          handleUpgradingS12n={handleUpgradingS12n}
          handleNotUpgradingS12n={handleNotUpgradingS12n}
        />
      )}
      {confirmationModalIsOpen && (
        <S12nVersioningConfirmationModal
          programId={programId}
          userId={userId}
          willUpgradeS12n={willUpgradeS12n}
          naptime={naptime}
          s12nId={currentS12n.id}
          handleClose={closeConfirmationModal}
        />
      )}
    </div>
  );
}
