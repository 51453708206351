import * as React from 'react';

import { s12nCourseProgressStatus } from 'bundles/ondemand/constants/Constants';
import CustomPropTypes from 'bundles/ondemand/constants/PropTypes';

import 'css!./__styles__/S12NCourseProgress';

class S12NCourseProgress extends React.Component {
  static propTypes = {
    progressStatus: CustomPropTypes.shape({
      status: CustomPropTypes.string,
      progress: CustomPropTypes.string,
    }).isRequired,
    course: CustomPropTypes.course.isRequired,
  };

  static defaultProps = {
    progressStatus: {},
  };

  getProgressClass(progress: $TSFixMe) {
    switch (progress) {
      case s12nCourseProgressStatus().NEW.progress:
        return 'pill-light pill-new';
      default:
        return 'pill-light pill-success color-success';
    }
  }

  renderProgressPill(progress: $TSFixMe) {
    if (!progress) {
      return null;
    }

    const progressClass = this.getProgressClass(progress);

    return <div className={`course-progress-pill ${progressClass}`}>{progress}</div>;
  }

  renderStatusText(status: $TSFixMe) {
    return <p className="course-status caption-text">{status}</p>;
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'course' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { course, progressStatus } = this.props;

    return (
      <div className="rc-S12NCourseProgress horizontal-box">
        <div className="course-info flex-1">
          <p className="course-name">{course.name}</p>
          {this.renderStatusText(progressStatus.status)}
        </div>
        {this.renderProgressPill(progressStatus.progress)}
      </div>
    );
  }
}

export default S12NCourseProgress;
