import * as React from 'react';
import { Helmet } from 'react-helmet';

import Imgix from 'js/components/Imgix';
import type { Props } from 'js/components/Imgix';
import imgix from 'js/lib/imgix';

export type LinkRelationAndProperties = { rel: string; hreflang?: string; href: string };

const ImgixWithPrioritizedLoading = (props: Props) => {
  // Borrowed from js/components/Imgix.jsx
  const { src, maxWidth, maxHeight, imgParams } = props;
  const srcs = imgix.getOptimizedSrcs(src, maxWidth, maxHeight, imgParams);
  const srcSet = srcs.dpr2 && srcs.dpr3 && `${srcs.dpr2} 2x${imgParams?.ignoreDpr3SrcSet ? '' : `, ${srcs.dpr3} 3x`}`;

  const helmetMeta = {
    link: [
      {
        rel: 'preload',
        as: 'image',
        href: srcs.dpr1,
        imagesrcset: srcSet,
      },
    ],
  };

  return (
    <React.Fragment>
      <Helmet {...helmetMeta} />
      <Imgix {...props} />
    </React.Fragment>
  );
};

export default ImgixWithPrioritizedLoading;
