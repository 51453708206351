const exported = {
  collectionResponsiveConfig: {
    xs: { displayLimit: 2 },
    sm: { displayLimit: 2 },
    md: { displayLimit: 2 },
    lg: { displayLimit: 3 },
    xl: { displayLimit: 4 },
    xxl: { displayLimit: 4 },
  },
};

export default exported;

export const { collectionResponsiveConfig } = exported;
