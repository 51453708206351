import * as React from 'react';
import type { FormattedMessage } from 'react-intl';

import classNames from 'classnames';

import { Typography, Typography2 } from '@coursera/cds-core';
import { Box } from '@coursera/coursera-ui';
import type { Svg, Svg as SvgComponentType } from '@coursera/coursera-ui/svg';
import {
  SvgAssignment,
  SvgCalendar,
  SvgCertificate,
  SvgChevronRight,
  SvgClock,
  SvgCommentDots,
  SvgGlobe,
  SvgGraduationCap,
  SvgHonorsAssignment,
  SvgInstitution,
  SvgLayer,
  SvgLevelAdvanced,
  SvgLevelBeginner,
  SvgLevelIntermediate,
  SvgMoney,
  SvgStacks,
} from '@coursera/coursera-ui/svg';

import IconInCircle from 'bundles/enterprise-legacy-xdp/components/IconInCircle';
import {
  GLANCE_ICON_BORDER_SIZE,
  GLANCE_ICON_SVG_SIZE,
} from 'bundles/enterprise-legacy-xdp/components/__styles__/shared';
import type { S12nGlanceItem } from 'bundles/program-common/types/xdpTypes';

// Icons supported by this component
const ALL_ICONS: { [key: string]: Svg } = {
  SvgCertificate,
  SvgGlobe,
  SvgMoney,
  SvgClock,
  SvgLayer,
  SvgInstitution,
  SvgCommentDots,
  SvgAssignment,
  SvgLevelAdvanced,
  SvgLevelIntermediate,
  SvgLevelBeginner,
  SvgHonorsAssignment,
  SvgCalendar,
  SvgStacks,
  SvgGraduationCap,
};

const DEFAULT_ICON = SvgChevronRight;

const RTL_FLIPPED_ICONS = new Set([SvgCommentDots]);

export type ProductGlanceList = Array<S12nGlanceItem>;

export type ProductGlanceProps = {
  dataList: ProductGlanceList;
  iconColor?: string;
  rootClassName?: string;
};

const GlanceIcon: React.SFC<{
  className: string;
  iconColor?: string;
  svg: SvgComponentType;
  title: string | React.ReactElement<typeof FormattedMessage> | undefined;
}> = ({ iconColor, svg, ...otherProps }) => {
  return (
    <IconInCircle
      Svg={svg}
      iconColor={iconColor}
      iconSize={GLANCE_ICON_SVG_SIZE}
      circleSize={GLANCE_ICON_BORDER_SIZE}
      iconFlipInRtl={RTL_FLIPPED_ICONS.has(svg)}
      aria-hidden="true"
      {...otherProps}
    />
  );
};

export default function ProductGlance({ dataList = [], iconColor, rootClassName }: ProductGlanceProps) {
  if (!dataList.length) {
    return null;
  }

  const dataListWithIcons = dataList.map((item) => ({
    ...item,
    IconTag: ALL_ICONS[item.icon] || DEFAULT_ICON,
  }));

  return (
    <div className={classNames('ProductGlance', rootClassName)}>
      {dataListWithIcons.map(({ title, subtitle, IconTag, iconTitle, hasBottomBorder }, index) => (
        <Box
          key={typeof title === 'string' ? title : index}
          rootClassName={`m-b-2 p-t-1s ${hasBottomBorder ? 'border-bottom p-b-2' : ''}`}
        >
          <GlanceIcon className="m-x-1s m-b-1s m-r-1" svg={IconTag} iconColor={iconColor} title={iconTitle || title} />
          <Box flexDirection="column">
            <Typography component="div" className={classNames('m-b-0', { 'm-t-1s': !subtitle })} variant="h2semibold">
              {title}
            </Typography>
            {subtitle && (
              <Typography2 className="font-sm text-secondary" variant="bodyPrimary" component="div">
                {subtitle}
              </Typography2>
            )}
          </Box>
        </Box>
      ))}
    </div>
  );
}
