import * as React from 'react';

import user from 'js/lib/user';

import { IdTypes } from 'bundles/product-features';
import OnboardingDataProvider from 'bundles/program-personalized-tab/providers/OnboardingDataProvider';
import { useProductFeatures } from 'bundles/unified-common/features';

type Props = {
  children: React.ReactNode;
  isProgramMemberOrInvited?: boolean;
  programId: string;
  thirdPartyOrganizationId: string;
};

// This should be removed in favor of just using OnboardingDataProvider once pre-unified test is finished.
const EnterpriseOnboardingHeaderProvider = ({
  children,
  isProgramMemberOrInvited,
  thirdPartyOrganizationId,
  programId,
}: Props) => {
  const { features: orgFeatures } = useProductFeatures({
    idType: IdTypes.Organization,
    id: thirdPartyOrganizationId || '',
    skip: !thirdPartyOrganizationId,
    ssr: true,
  });

  const { features: programFeatures } = useProductFeatures({
    idType: IdTypes.Program,
    id: programId || '',
    skip: !programId,
    ssr: true,
  });

  const enablePersonalizedRecommendationsForOrg = orgFeatures.get('enterprise', 'personalizedRecommendations');
  const enablePersonalizedRecommendationsForProgram = programFeatures.get('enterprise', 'personalizedRecommendations');
  const hasEnabledPersonalizedRecommendations =
    enablePersonalizedRecommendationsForOrg || enablePersonalizedRecommendationsForProgram;

  const enableLearnerOnboarding = user.isAuthenticatedUser() && hasEnabledPersonalizedRecommendations;

  if (enableLearnerOnboarding && isProgramMemberOrInvited) {
    return <OnboardingDataProvider>{children}</OnboardingDataProvider>;
  } else {
    return <React.Fragment>{children}</React.Fragment>;
  }
};

export default EnterpriseOnboardingHeaderProvider;
