/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Heading } from 'bundles/program-home/components/AutoHeading';

import _t from 'i18n!nls/program-home';

const styles = {
  headingContainer: css`
    max-width: 720px;
    margin-bottom: var(--cds-spacing-300);
  `,
  header: css`
    margin-bottom: var(--cds-spacing-100);
  `,
};

const SkillSetDiscoveryExperimentHeader = () => {
  return (
    <div css={styles.headingContainer}>
      <Heading variant="h1semibold" css={styles.header} defaultLevel={2}>
        {_t('Get the in-demand skills you need with SkillSets')}
      </Heading>
    </div>
  );
};

export { SkillSetDiscoveryExperimentHeader };
