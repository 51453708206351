/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { useEffect, useRef, useState } from 'react';

/* @ts-expect-error ts-migrate(7016) FIXME: Untyped import */
import ReactTooltip from 'react-tooltip';

import { InfoOutlineIcon } from '@coursera/cds-icons';

type Props = {
  text: string | JSX.Element;
  hideIcon?: boolean;
  children?: JSX.Element;
  id: string;
};

const styles = {
  icon: css`
    line-height: 18px;
    width: 18px;
    margin-left: 4px;
  `,
  wrapper: css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
  `,
  info: css`
    text-align: left;
    font-size: 14px;
    margin: 5px;
  `,
  tooltip: css`
    max-width: 200px;
    opacity: 1;
    background-color: black !important;
    text-align: left;
    line-height: 1.4;

    .__react_component_tooltip.place-top {
      margin-top: -6px;
    }
  `,
};

const ExpertiseTooltip = ({ text, hideIcon = false, id: idFromProps, children }: Props) => {
  const [isClient, setIsClient] = useState(false);
  const suffixRef = useRef(0);
  const id = `${idFromProps}-${suffixRef.current}`;

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    suffixRef.current += 1;
  });

  return (
    <div className="rc-ExpertiseTooltip info-button">
      <div css={styles.wrapper} aria-describedby={id} data-tip data-for={id}>
        {children}
        {!hideIcon && <InfoOutlineIcon css={styles.icon} />}

        {isClient && (
          <ReactTooltip css={styles.tooltip} place="top" effect="solid" id={id}>
            {text}
          </ReactTooltip>
        )}
      </div>
    </div>
  );
};

export default ExpertiseTooltip;
