import * as React from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import { useRetracked } from 'js/lib/retracked';

import { InlineNotification } from '@coursera/cds-core';

import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';
import { useHasMultipleProgramProps } from 'bundles/program-home/components/multiprogram/useHasMultipleProgramProps';

import _t from 'i18n!nls/program-home';

import { getMyLearningDeepLink } from '../myLearning/MyLearningDeepLink';

type PropsFromCaller = {
  userId: number;
  thirdPartyOrganizationId: string;
  thirdPartyOrgSlug: string;
};

export type Props = PropsFromCaller;

export const SingleProgramSkillsetWarningBanner: React.FC<Props> = ({
  userId,
  thirdPartyOrgSlug,
  thirdPartyOrganizationId,
}: Props) => {
  const trackComponent = useRetracked();
  const [isDismissed, setIsDismissed] = React.useState(false);
  const { hasMultipleProgramsInOrg } = useHasMultipleProgramProps({ userId, thirdPartyOrganizationId });

  const onDismissClick = () => {
    setIsDismissed(true);
    trackComponent({
      trackingData: { userId, thirdPartyOrgSlug },
      trackingName: 'single_program_skillset_warning_notification',
      action: 'dismissed',
    });
  };

  if (isDismissed || !hasMultipleProgramsInOrg) {
    return null;
  }

  return (
    <TrackedDiv
      trackingName="single_program_skillset_warning_notification"
      withVisibilityTracking={true}
      data={{ userId, thirdPartyOrgSlug }}
      data-testid="single-program-skillset-warning"
    >
      <InlineNotification
        severity="information"
        title={_t('SkillSets from this learning program')}
        onDismiss={onDismissClick}
      >
        <FormattedMessage
          message={_t('To view all the SkillSets you have access to, go to {link}')}
          link={
            <TrackedLink2
              trackingName="single_program_skillset_warning_notification_home_link"
              href={`/programs/all/${thirdPartyOrgSlug}?currentTab=SKILLSETS`}
            >
              {_t('all learning programs')}
            </TrackedLink2>
          }
        />
      </InlineNotification>
    </TrackedDiv>
  );
};

export const SingleProgramMyCoursesWarningBanner: React.FC<Props> = ({
  userId,
  thirdPartyOrgSlug,
  thirdPartyOrganizationId,
}: Props) => {
  const trackComponent = useRetracked();
  const [isDismissed, setIsDismissed] = React.useState(false);
  const { hasMultipleProgramsInOrg } = useHasMultipleProgramProps({ userId, thirdPartyOrganizationId });

  const onDismissClick = () => {
    setIsDismissed(true);
    trackComponent({
      trackingData: { userId, thirdPartyOrgSlug },
      trackingName: 'single_program_my_courses_warning_notification',
      action: 'dismissed',
    });
  };

  if (isDismissed || !hasMultipleProgramsInOrg) {
    return null;
  }

  return (
    <TrackedDiv
      trackingName="single_program_my_courses_warning_notification"
      withVisibilityTracking={true}
      data={{ userId, thirdPartyOrgSlug }}
      data-testid="single-program-my-courses-warning"
    >
      <InlineNotification
        severity="information"
        title={_t('Courses from this learning program')}
        onDismiss={onDismissClick}
      >
        <FormattedMessage
          message={_t('To view all the courses you have enrolled in, go to {link}')}
          link={
            <TrackedLink2
              trackingName="single_program_my_courses_warning_notification_home_link"
              href={`/programs/all/${thirdPartyOrgSlug}?${getMyLearningDeepLink('MY_COURSES')}`}
            >
              {_t('all learning programs')}
            </TrackedLink2>
          }
        />
      </InlineNotification>
    </TrackedDiv>
  );
};
