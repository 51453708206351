import { css } from '@emotion/react';

import { classes as buttonBaseClasses } from '@core/ButtonBase/getButtonBaseCss';
import { typography } from '@core/Typography2';
import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('actionOverflowMenu', [
  'menu',
  'menuItem',
  'groupLabel',
  'groupDivider',
]);

const getActionOverflowMenuCss = css`
  &.${classes.menu} {
    display: flex;
    flex-direction: column;
    gap: var(--cds-spacing-50);
    margin: 0;
    padding: var(--cds-spacing-200);
    list-style: none;
    overflow-y: auto;

    li {
      display: flex;
    }
  }

  .${classes.menuItem} {
    ${typography.bodyPrimary};
    display: flex;
    justify-content: flex-start;
    gap: var(--cds-spacing-150);
    padding: var(--cds-spacing-150) var(--cds-spacing-100);
    border-radius: var(--cds-border-radius-25);
    width: 100%;
    cursor: pointer;

    &.${buttonBaseClasses.focusVisible} {
      background-color: var(
        --cds-color-interactive-background-primary-hover-weak
      );
    }

    &:hover,
    &:active {
      background-color: var(
        --cds-color-interactive-background-primary-hover-weak
      );
    }

    &[aria-disabled='true'] {
      color: var(--cds-color-neutral-disabled-strong);
      background-color: var(--cds-color-neutral-background-primary);
    }

    &[aria-current='page'] {
      background-color: var(--cds-color-neutral-background-primary-weak);
      border-left: 4px solid var(--cds-color-interactive-primary);
    }
  }

  .${classes.groupDivider} {
    padding: var(--cds-spacing-200) 0;
    flex-direction: column;

    &:first-of-type {
      display: none;
    }
  }

  .${classes.groupLabel} {
    ${typography.subtitleMedium};
    padding: var(--cds-spacing-100);
    text-align: left;
  }
`;

export default getActionOverflowMenuCss;
