import videojs from 'video.js';

class ClippedProgressControl extends videojs.getComponent('ProgressControl') {
  constructor(player, options) {
    super(player, options);
    this.el_.classList.add('customComponent-ClippedProgressControl');
  }

  getChild(name) {
    return super.getChild(name === 'seekBar' ? 'clippedSeekBar' : name);
  }
}

class ClippedPlayProgressBar extends videojs.getComponent('PlayProgressBar') {
  constructor(player, options) {
    super(player, options);
    this.el_.classList.add('customComponent-ClippedPlayProgressBar');
  }

  startTime = 0;

  setStartTime(value) {
    this.startTime = value;
  }

  update(seekBarRect, seekBarPoint) {
    // If there is an existing rAF ID, cancel it so we don't over-queue.
    if (this.rafId_) {
      this.cancelAnimationFrame(this.rafId_);
    }

    this.rafId_ = this.requestAnimationFrame(() => {
      const time =
        (this.player_.scrubbing() ? this.player_.getCache().currentTime : this.player_.currentTime()) - this.startTime;
      const content = videojs.time.formatTime(time, this.player_.duration());
      const timeTooltip = this.getChild('timeTooltip');

      if (timeTooltip) {
        timeTooltip.update(seekBarRect, seekBarPoint, content);
      }
    });
  }
}

class ClippedSeekBar extends videojs.getComponent('SeekBar') {
  constructor(player, options) {
    super(player, options);
    this.el_.classList.add('customComponent-ClippedSeekBar');
  }

  startTime = 0;

  setStartTime(value) {
    this.startTime = value;
  }

  getCurrentTime_() {
    return super.getCurrentTime_() - this.startTime;
  }

  stepForward() {
    return super.stepForward() - this.startTime;
  }

  stepBack() {
    return super.stepBack() - this.startTime;
  }

  handleMouseMove(event) {
    let newTime = this.calculateDistance(event) * this.player_.duration() + this.startTime;

    // Don't let video end while scrubbing.
    if (newTime === this.player_.duration()) {
      newTime -= 0.1;
    }

    // Set new time (tell player to seek to new time)
    this.player_.currentTime(newTime);
  }
}

videojs.registerComponent('clippedProgressControl', ClippedProgressControl);
videojs.registerComponent('clippedSeekBar', ClippedSeekBar);
videojs.registerComponent('clippedPlayProgressBar', ClippedPlayProgressBar);

export default ClippedSeekBar;
