import * as React from 'react';
import { graphql } from 'react-apollo';

import gql from 'graphql-tag';
import { compose, mapProps } from 'recompose';

import { tupleToStringKey } from 'js/lib/stringKeyTuple';

import { withLoading, withRenderNothing } from 'bundles/coursera-ui/components/hocs/withBranches';
import type { Props as MiniCDPProps } from 'bundles/coursera-ui/components/rich/MiniXDP/MiniCDP';
import MiniCDP from 'bundles/coursera-ui/components/rich/MiniXDP/MiniCDP';
import type {
  HasCourseraLabsQuery as HasCourseraLabsQueryData,
  HasCourseraLabsQueryVariables,
} from 'bundles/coursera-ui/components/rich/MiniXDP/__generated__/HasCourseraLabsQuery';
import type {
  MiniCDPQuery_XdpV1Resource_get as XdpV1,
  MiniCDPQuery_XdpV1Resource_get_xdpMetadata_XdpV1_cdpMetadataMember_cdpMetadata as XdpV1CdpMetadata,
} from 'bundles/coursera-ui/components/rich/MiniXDP/__generated__/MiniCDPQuery';
import { PartnerIncentivesCdpFragment } from 'bundles/learner-incentives/components/LearnerIncentive';
import type { LearnerIncentive as LearnerIncentiveType } from 'bundles/learner-incentives/types';

type PropsFromCaller = Omit<MiniCDPProps, 'htmlAttributes' | 'course'> & {
  id: string;
};

type PropsFromHasCourseraLabsQuery = {
  hasLabAssignments?: boolean;
};

type Props = PropsFromCaller &
  PropsFromHasCourseraLabsQuery & {
    course: XdpV1 & XdpV1CdpMetadata;
    disableS12nUpsellLinks?: boolean;
    learnerIncentives: Array<LearnerIncentiveType>;
  };

const MiniCDPContainer = (props: Props) => {
  return <MiniCDP {...props} htmlAttributes={{ 'data-e2e': 'MiniCDP' }} />;
};

const MiniCDPQuery = gql`
  query MiniCDPQuery($id: String!) {
    XdpV1Resource {
      get(id: $id) {
        id
        name
        slug
        xdpMetadata {
          __typename
          ... on XdpV1_cdpMetadataMember {
            cdpMetadata {
              id
              learningObjectives
              level
              display
              domains {
                domainId
                domainName
                subdomainName
                subdomainId
              }
              primaryLanguages
              skills
              subtitleLanguages
              name
              slug
              description
              topReviews {
                comment
                authorName
                averageFiveStarRating
                timestamp
              }
              reviewHighlights {
                highlightText: highlighttext
                reviewcount
              }
              ratings {
                averageFiveStarRating
                ratingCount
                commentCount
              }
              level
              badges
              partners {
                id
                name
                shortName
                landingPageBanner
                description
                partnerMarketingBlurb
                logo
                squareLogo
                rectangularLogo
                primaryColor
                productBrandingLogo
                secondaryColor
              }
              instructors {
                id
                fullName
                photo
                title
                shortName
                department
              }
              cmlFaqs {
                question {
                  ... on XdpV1_cmlMember {
                    cml {
                      dtdId
                      value
                    }
                  }
                }
                answer {
                  ... on XdpV1_cmlMember {
                    cml {
                      dtdId
                      value
                    }
                  }
                }
              }
              material {
                weeks {
                  modules {
                    id
                    name
                    description
                    totalVideos
                    totalQuizzes
                    totalReadings
                    totalDuration
                    items {
                      id
                      name
                      typeName
                      slug
                    }
                  }
                }
              }
              primaryS12n {
                id
                slug
              }
              s12ns {
                id
                name
                courseCount
                slug
                badges
                subheader
                headline
                description
                productVariant
                domains {
                  domainId
                  domainName
                  subdomainName
                  subdomainId
                }
                courses {
                  id
                  name
                  slug
                }
                partners {
                  id
                  name
                  shortName
                  landingPageBanner
                  description
                  partnerMarketingBlurb
                  logo
                  squareLogo
                  rectangularLogo
                  primaryColor
                }
              }
              certificates
              courseTypeMetadata {
                __typename
                ... on XdpV1_rhymeProjectMember {
                  rhymeProject {
                    typeNameIndex
                  }
                }
              }
              ...PartnerIncentivesCdpFragment
            }
          }
        }
      }
    }
  }
  ${PartnerIncentivesCdpFragment}
`;

const HasCourseraLabsQuery = gql`
  query HasCourseraLabsQuery($key: String!) {
    ExternallyAccessibleNostosV1Resource {
      getAllProperties(job_name: "enterprise_products_with_labs", keys: $key) {
        elements {
          id
          content
        }
      }
    }
  }
`;

export default compose<Props, PropsFromCaller>(
  graphql<PropsFromCaller, HasCourseraLabsQueryData, HasCourseraLabsQueryVariables, PropsFromHasCourseraLabsQuery>(
    HasCourseraLabsQuery,
    {
      options: ({ id }) => ({
        variables: { key: tupleToStringKey(['VerifiedCertificate', id]) },
      }),
      props: ({ data }) => {
        const content = data?.ExternallyAccessibleNostosV1Resource?.getAllProperties?.elements?.[0]?.content as
          | {
              hasLabAssignments?: boolean;
            }
          | undefined;

        return {
          hasLabAssignments: content?.hasLabAssignments,
        };
      },
    }
  ),
  // @ts-expect-error TODO: type it
  mapProps(({ id, ...rest }) => ({ ...rest, id: tupleToStringKey(['COURSE', id]) })),
  graphql(MiniCDPQuery, {
    options: {
      errorPolicy: 'all',
    },
  }),
  withLoading,
  // @ts-expect-error TODO: type it
  mapProps(({ data, ...rest }) => {
    if (
      !data.XdpV1Resource ||
      !data.XdpV1Resource.get ||
      !data.XdpV1Resource.get.xdpMetadata ||
      !data.XdpV1Resource.get.xdpMetadata.cdpMetadata
    ) {
      return { shouldRenderNothing: true };
    }
    const course = data.XdpV1Resource.get;
    const learnerIncentives = data.XdpV1Resource.get.xdpMetadata.cdpMetadata.partnerIncentives;
    return {
      ...rest,
      course: {
        ...course,
        ...course.xdpMetadata.cdpMetadata,
      },
      learnerIncentives: learnerIncentives ?? [],
    };
  }),
  withRenderNothing
)(MiniCDPContainer);
