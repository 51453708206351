import gql from 'graphql-tag';

const CourseRecsFragment = gql`
  fragment CourseRecsFragment on ProgramCurriculumCollectionsV1 {
    id
    title
    image
    courseIds
    collectionTrackingId
    description {
      ... on ProgramCurriculumCollectionsV1_cmlMember {
        cml {
          dtdId
          value
        }
      }
    }
    trackId
    courses(limit: $numEntriesPerCollection) {
      elements {
        id
        name
        slug
        promoPhoto: photoUrl
        courseTypeMetadata {
          id
          courseTypeMetadata {
            __typename
          }
        }
        partners {
          elements {
            id
            name
            squareLogo
          }
        }
      }
    }
    associatedSessions {
      elements {
        id
        courseId
        startsAt
        endsAt
      }
    }
  }
`;

export const EnterpriseCourseRecommendationsForSkillIdQuery = gql`
  query EnterpriseCourseRecommendationsForSkillId(
    $programId: String!
    $numEntriesPerCollection: Int!
    $hasSkillId1: Boolean = false
    $skillId1: String = ""
    $hasSkillId2: Boolean = false
    $skillId2: String = ""
    $hasSkillId3: Boolean = false
    $skillId3: String = ""
    $hasSkillId4: Boolean = false
    $skillId4: String = ""
    $hasSkillId5: Boolean = false
    $skillId5: String = ""
    $hasSkillId6: Boolean = false
    $skillId6: String = ""
  ) {
    ProgramCurriculumCollectionsV1Resource {
      courseRecs1: skillCollectionBySkillId(
        programId: $programId
        skillId: $skillId1
        numEntriesPerCollection: $numEntriesPerCollection
      ) @include(if: $hasSkillId1) {
        elements {
          ...CourseRecsFragment
        }
      }
      courseRecs2: skillCollectionBySkillId(
        programId: $programId
        skillId: $skillId2
        numEntriesPerCollection: $numEntriesPerCollection
      ) @include(if: $hasSkillId2) {
        elements {
          ...CourseRecsFragment
        }
      }
      courseRecs3: skillCollectionBySkillId(
        programId: $programId
        skillId: $skillId3
        numEntriesPerCollection: $numEntriesPerCollection
      ) @include(if: $hasSkillId3) {
        elements {
          ...CourseRecsFragment
        }
      }
      courseRecs4: skillCollectionBySkillId(
        programId: $programId
        skillId: $skillId4
        numEntriesPerCollection: $numEntriesPerCollection
      ) @include(if: $hasSkillId4) {
        elements {
          ...CourseRecsFragment
        }
      }
      courseRecs5: skillCollectionBySkillId(
        programId: $programId
        skillId: $skillId5
        numEntriesPerCollection: $numEntriesPerCollection
      ) @include(if: $hasSkillId5) {
        elements {
          ...CourseRecsFragment
        }
      }
      courseRecs6: skillCollectionBySkillId(
        programId: $programId
        skillId: $skillId6
        numEntriesPerCollection: $numEntriesPerCollection
      ) @include(if: $hasSkillId6) {
        elements {
          ...CourseRecsFragment
        }
      }
    }
  }
  ${CourseRecsFragment}
`;
