import * as React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import PropTypes from 'prop-types';

import Imgix from 'js/components/Imgix';

import { Box, Col, StyleSheet, css } from '@coursera/coursera-ui';

import TopInstructor from 'bundles/enterprise-legacy-xdp/components/instructors/TopInstructor';
import { TrackedA } from 'bundles/page/components/TrackedLink2';
import LazyImgix from 'bundles/page/components/shared/LazyImgix';
import { noheadshotUrl } from 'bundles/partners-graphql/constants';
import { XDP_EXPERTISE_ICONS } from 'bundles/program-common/constants/xdpConstants';
import type { InstructorType, PRODUCT_TYPE } from 'bundles/program-common/types/xdpTypes';
import { generateInstructorImageAltValue } from 'bundles/program-common/utils/xdpUtils';

import _t from 'i18n!nls/program-common';

import 'css!bundles/enterprise-legacy-xdp/components/instructors/__styles__/InstructorList';

const PHOTO_SIZE = 112;

type Props = InstructorType & {
  productType?: PRODUCT_TYPE;
  noLink?: boolean;
};
const styles = StyleSheet.create({
  Images: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '8px',
  },
});

type Context = {
  isProgramHome?: boolean;
};

function getInstructorPageUrl(id: string, shortName: string | null) {
  if (shortName) {
    return `/instructor/${shortName}`;
  } else {
    return `/instructor/~${id}`;
  }
}

const Instructor = (
  { id, title, fullName, photo, shortName, department, learnersReached, coursesTaught, isTopInstructor, noLink }: Props,
  { isProgramHome = false }: Context
) => {
  const shouldShowInstructorInfo = !!learnersReached && !!coursesTaught;
  const instructorImageProps = {
    maxWidth: PHOTO_SIZE,
    maxHeight: PHOTO_SIZE,
    imgParams: { fit: 'crop' },
    alt: generateInstructorImageAltValue(fullName),
    src: photo ?? noheadshotUrl,
    role: 'presentation',
  };

  const content = (
    <div className="instructor-wrapper">
      <div className="avatar-container">
        {isProgramHome ? <Imgix {...instructorImageProps} /> : <LazyImgix {...instructorImageProps} />}
      </div>
      <Box justifyContent="center" flexDirection="column" flex={1}>
        <h3 className="instructor-name headline-3-text bold">
          {fullName}
          {isTopInstructor && <TopInstructor instructorId={id} instructorName={fullName} />}
        </h3>
        <span className="instructor-title">{title}</span>
        {department && <div className="instructor-department caption-text color-black">{department}</div>}
        {shouldShowInstructorInfo && (
          <div className="instructor-expertise">
            <div className="learners-count">
              <div {...css(styles.Images)}>
                <LazyImgix src={XDP_EXPERTISE_ICONS.learners} alt="" maxHeight={12} maxWidth={12} />
              </div>{' '}
              <FormattedHTMLMessage
                message={_t(
                  '<strong>{learnersCount, number}</strong> {learnersCount, plural, =1 {Learner} other {Learners}}'
                )}
                learnersCount={learnersReached}
              />
            </div>
            <div className="courses-count">
              <div {...css(styles.Images)}>
                <LazyImgix src={XDP_EXPERTISE_ICONS.courseBook} alt="" maxHeight={12} maxWidth={12} />
              </div>{' '}
              <FormattedHTMLMessage
                message={_t(
                  '<strong>{coursesCount, number}</strong> {coursesCount, plural, =1 {Course} other {Courses}}'
                )}
                coursesCount={coursesTaught}
              />
            </div>
          </div>
        )}
      </Box>
    </div>
  );

  return (
    <Col xs={12} lg={6} key={id} rootClassName="p-y-1 p-r-1">
      {noLink ? (
        content
      ) : (
        <TrackedA
          className="link-no-style"
          href={getInstructorPageUrl(id, shortName)}
          target="_blank"
          rel="noopener noreferrer"
          trackingName="instructor_avatar"
        >
          {content}
        </TrackedA>
      )}
    </Col>
  );
};

Instructor.contextTypes = {
  isProgramHome: PropTypes.bool,
};

export default Instructor;
