/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useRetracked } from 'js/lib/retracked';
import useRouter from 'js/lib/useRouter';

import { CardMetadata, CardSkills, ProductCard, Typography, breakpoints } from '@coursera/cds-core';

import { FilterableShowMoreGridSection, FilterableShowMoreGridSectionPlaceholder } from 'bundles/cds-labs/components/';
import { TrackedCdsButton } from 'bundles/common/components/withSingleTracked';
import { filterUndefined } from 'bundles/common/utils/commonUtils';
import { formatRoleIdToString } from 'bundles/enterprise-learner-onboarding/utils';
import { DESCRIPTION_PAGE_PATHS, launchMiniModal } from 'bundles/enterprise-legacy-xdp/hocs/withMiniModal';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { useCourseRecsData } from 'bundles/program-personalized-tab/contexts/CourseRecsContext';
import { useOnboardingData } from 'bundles/program-personalized-tab/contexts/OnboardingContext';
import { useS12nAndProCertRecsData } from 'bundles/program-personalized-tab/contexts/S12nAndProCertRecsContext';
import type { ProductWithMetadata } from 'bundles/program-personalized-tab/types/sharedTypes';
import { getProductRecsMetadata } from 'bundles/program-personalized-tab/utils/displayUtils';

import _t from 'i18n!nls/program-personalized-tab';

export const styles = {
  productCardWrapper: css`
    height: 100%;
    cursor: pointer;
  `,
};

type Props = {
  showLeadershipRecs?: boolean;
};

type ProductCollectionEntry = ProductWithMetadata & {
  productType: 'course' | 's12n' | 'proCert' | 'guidedProject';
  collectionId: string;
};
type ProductCollection = ProductCollectionEntry[];

type ProductCollectionMap = {
  [key: string]: ProductCollection;
};

const mapElementToEntry = (
  element: ProductWithMetadata | null,
  productType: 'course' | 's12n' | 'proCert' | 'guidedProject',
  collectionId: string
) => {
  return {
    ...element,
    id: element?.id || '',
    name: element?.name || '',
    partners: element?.partners || null,
    promoPhoto: element?.promoPhoto || null,
    slug: element?.slug,
    productType,
    collectionId,
  };
};

const ProductRecsCollection = ({ showLeadershipRecs }: Props) => {
  const track = useRetracked();
  const router = useRouter();
  const onboardingData = useOnboardingData();
  const courseRecsData = useCourseRecsData();
  const s12nRecsData = useS12nAndProCertRecsData();
  const courseRecs = useMemo(() => courseRecsData?.recs?.courses?.elements ?? [], [courseRecsData]);
  const leadershipCourseRecs = useMemo(() => courseRecsData?.leadershipRecs?.courses?.elements ?? [], [courseRecsData]);
  const guidedProjectRecs = useMemo(() => courseRecsData?.recs?.guidedProjects?.elements ?? [], [courseRecsData]);
  const leadershipGuidedProjectRecs = useMemo(
    () => courseRecsData?.leadershipRecs?.guidedProjects?.elements ?? [],
    [courseRecsData]
  );
  const s12nRecs = useMemo(() => s12nRecsData?.recs?.s12ns?.elements ?? [], [s12nRecsData]);
  const leadershipS12nRecs = useMemo(() => s12nRecsData?.leadershipRecs?.s12ns?.elements ?? [], [s12nRecsData]);
  const proCertRecs = useMemo(() => s12nRecsData?.recs?.proCerts?.elements ?? [], [s12nRecsData]);
  const leadershipProCertRecs = useMemo(() => s12nRecsData?.leadershipRecs?.proCerts?.elements ?? [], [s12nRecsData]);
  const filters = useMemo(
    () =>
      showLeadershipRecs
        ? [
            ...(leadershipCourseRecs.length > 0 ? [{ id: 'course', label: _t('Courses (1-3 months)') }] : []),
            ...(leadershipGuidedProjectRecs.length > 0
              ? [{ id: 'guidedProject', label: _t('Hands-on Projects (2 hours)') }]
              : []),
            ...(leadershipProCertRecs.length > 0
              ? [{ id: 'proCert', label: _t('Professional Certificates (3-6 months)') }]
              : []),
            ...(leadershipS12nRecs.length > 0 ? [{ id: 's12n', label: _t('Specializations (3-6 months)') }] : []),
          ]
        : [
            ...(courseRecs.length > 0 ? [{ id: 'course', label: _t('Courses (1-3 months)') }] : []),
            ...(guidedProjectRecs.length > 0
              ? [{ id: 'guidedProject', label: _t('Hands-on Projects (2 hours)') }]
              : []),
            ...(proCertRecs.length > 0 ? [{ id: 'proCert', label: _t('Professional Certificates (3-6 months)') }] : []),
            ...(s12nRecs.length > 0 ? [{ id: 's12n', label: _t('Specializations (3-6 months)') }] : []),
          ],
    [
      showLeadershipRecs,
      leadershipCourseRecs.length,
      leadershipGuidedProjectRecs.length,
      leadershipProCertRecs.length,
      leadershipS12nRecs.length,
      courseRecs.length,
      guidedProjectRecs.length,
      proCertRecs.length,
      s12nRecs.length,
    ]
  );
  const [currentFilterId, setCurrentFilterId] = useState<string>('');
  const isCourseRecsLoading = showLeadershipRecs ? courseRecsData?.leadershipRecsLoading : courseRecsData?.loading;
  const isS12nRecsLoading = showLeadershipRecs ? s12nRecsData?.leadershipRecsLoading : s12nRecsData?.loading;
  const isDataLoading = onboardingData?.loading || isCourseRecsLoading || isS12nRecsLoading;
  const hasRecs = showLeadershipRecs
    ? leadershipCourseRecs.length > 0 ||
      leadershipS12nRecs.length > 0 ||
      leadershipProCertRecs.length > 0 ||
      leadershipGuidedProjectRecs.length > 0
    : courseRecs.length > 0 || s12nRecs.length > 0 || proCertRecs.length > 0 || guidedProjectRecs.length > 0;
  const productCollectionMap: ProductCollectionMap = showLeadershipRecs
    ? {
        ...(leadershipCourseRecs.length > 0 && {
          course: leadershipCourseRecs?.map((element) =>
            mapElementToEntry(element, 'course', courseRecsData?.leadershipRecs?.id || '')
          ),
        }),
        ...(leadershipS12nRecs.length > 0 && {
          s12n: leadershipS12nRecs?.map((element) =>
            mapElementToEntry(element, 's12n', s12nRecsData?.leadershipRecs?.id || '')
          ),
        }),
        ...(leadershipProCertRecs.length > 0 && {
          proCert: leadershipProCertRecs?.map((element) =>
            mapElementToEntry(element, 'proCert', s12nRecsData?.leadershipRecs?.id || '')
          ),
        }),
        ...(leadershipGuidedProjectRecs.length > 0 && {
          guidedProject: leadershipGuidedProjectRecs?.map((element) =>
            mapElementToEntry(element, 'guidedProject', courseRecsData?.leadershipRecs?.id || '')
          ),
        }),
      }
    : {
        ...(courseRecs.length > 0 && {
          course: courseRecs?.map((element) => mapElementToEntry(element, 'course', courseRecsData?.recs?.id || '')),
        }),
        ...(s12nRecs.length > 0 && {
          s12n: s12nRecs?.map((element) => mapElementToEntry(element, 's12n', s12nRecsData?.recs?.id || '')),
        }),
        ...(proCertRecs.length > 0 && {
          proCert: proCertRecs?.map((element) => mapElementToEntry(element, 'proCert', s12nRecsData?.recs?.id || '')),
        }),
        ...(guidedProjectRecs.length > 0 && {
          guidedProject: guidedProjectRecs?.map((element) =>
            mapElementToEntry(element, 'guidedProject', courseRecsData?.recs?.id || '')
          ),
        }),
      };

  const handleFilterSelection = (filterId?: string) => {
    if (filterId) {
      const filterIndex = filters.findIndex((f) => f.id === filterId);
      track({
        trackingData: { filterId, filterIndex, filterLabel: filters[filterIndex]?.label },
        trackingName: showLeadershipRecs ? 'leadership_product_recs_filter_change' : 'product_recs_filter_change',
        action: 'click',
      });
      setCurrentFilterId(filterId);
    }
  };

  const getDescriptionPagePath = (productType: string) => {
    switch (productType) {
      case 'course':
        return DESCRIPTION_PAGE_PATHS.course;
      case 's12n':
        return DESCRIPTION_PAGE_PATHS.specialization;
      case 'proCert':
        return DESCRIPTION_PAGE_PATHS.professionalCertificate;
      case 'guidedProject':
        return DESCRIPTION_PAGE_PATHS.project;
      default:
        return undefined;
    }
  };

  const handleProductCardClick = useCallback(
    (
      productId: string,
      collectionId: string,
      productName: string,
      productType: string,
      itemIndex: number,
      productCollectionTrackingId: string,
      slug?: string
    ) => {
      track({
        trackingData: { productId, collectionId, productName, productType, itemIndex },
        trackingName: showLeadershipRecs ? 'leadership_product_card_click' : 'product_card_click',
        action: 'click',
      });
      launchMiniModal(
        router,
        productId,
        productType === 'course' || productType === 'guidedProject',
        productCollectionTrackingId,
        undefined,
        {
          source: collectionId,
        },
        {
          slug,
          productPagePath: getDescriptionPagePath(productType),
        }
      );
    },
    [router, track, showLeadershipRecs]
  );

  // Setting the default initial filter that's selected based on what recs are available
  useEffect(() => {
    if (!isDataLoading && filters.length > 0) {
      setCurrentFilterId(filters[0].id);
    }
  }, [isDataLoading, filters]);

  if (!isDataLoading && !hasRecs) {
    track({
      trackingData: {},
      trackingName: showLeadershipRecs ? 'no_leadership_product_recs' : 'no_product_recs',
      action: 'hide',
    });
    return null;
  }

  if (isDataLoading)
    return (
      <FilterableShowMoreGridSectionPlaceholder
        renderPlaceholderItem={() => (
          <div css={styles.productCardWrapper} data-testid="placeholder-card-wrapper">
            {' '}
            <ProductCard variant="grid" productType="course" loading />{' '}
          </div>
        )}
      />
    );
  if (!isDataLoading && !productCollectionMap[currentFilterId]) return null;
  const title =
    // eslint-disable-next-line no-nested-ternary
    (onboardingData?.isUnsureOfRole || !onboardingData.hasRoleSkills) && !showLeadershipRecs
      ? _t('Versatile skills for career development')
      : showLeadershipRecs
      ? _t('Develop your leadership skills')
      : _t('Advance your #{role} skills at your own pace', {
          role: formatRoleIdToString(onboardingData?.selectedRoleId ?? ''),
        });

  return (
    <TrackedDiv trackingName={showLeadershipRecs ? 'leadership_product_recs' : 'product_recs'} withVisibilityTracking>
      <FilterableShowMoreGridSection<ProductCollectionEntry>
        filters={filters}
        selectedFilterId={currentFilterId}
        onFilterSelect={handleFilterSelection}
        title={title}
        renderTitle={() => (
          <Typography component="h2" variant="h2semibold">
            {title}
          </Typography>
        )}
        items={productCollectionMap[currentFilterId]}
        spacing={{ sm: 24, xs: 16 }}
        renderItem={({ item, itemIndex, itemRef }) => {
          const {
            id,
            name,
            promoPhoto,
            level,
            totalContentWeeks,
            partners,
            skills,
            productType,
            collectionId,
            collectionTrackingId,
            slug,
          } = item;
          const partnersCleaned = partners?.elements
            ?.filter(filterUndefined)
            .map(({ name: partnerName, squareLogo }) => ({ name: partnerName, logoUrl: squareLogo ?? undefined }));

          return (
            <div css={styles.productCardWrapper} key={id}>
              <ProductCard
                variant="grid"
                productType={productType}
                previewImageSrc={promoPhoto ?? undefined}
                title={{ name, customLinkProps: { refalt: itemRef } }}
                partners={partnersCleaned}
                body={skills && skills.length > 0 ? <CardSkills skills={skills} /> : null}
                footer={
                  <CardMetadata
                    metadata={getProductRecsMetadata({
                      level,
                      productType,
                      durationWeeks: totalContentWeeks,
                    })}
                  />
                }
                onClick={() =>
                  handleProductCardClick(
                    id,
                    collectionId,
                    name,
                    productType,
                    itemIndex,
                    collectionTrackingId ?? '',
                    slug
                  )
                }
              />
            </div>
          );
        }}
        renderExpandButton={({ onClick, defaultLabel, isExpanded, showMoreCount, ...rest }) => {
          return (
            <TrackedCdsButton
              trackingName={showLeadershipRecs ? 'show_more_leadership_product_recs' : 'show_more_product_recs'}
              trackingData={{
                title,
                isExpanded,
                showMoreCount,
              }}
              size="small"
              variant="secondary"
              onClick={onClick}
              {...rest}
            >
              {defaultLabel}
            </TrackedCdsButton>
          );
        }}
      />
    </TrackedDiv>
  );
};

export default ProductRecsCollection;
