import type React from 'react';

import type { BaseComponentProps, IconProps } from '@coursera/cds-common';
import { IconContext } from '@coursera/cds-common';

import { classes } from '@core/cards/common/getCommonCardCss';
import Typography from '@core/Typography2';

export type CardCreditTypeProps = {
  icon: React.ReactElement<IconProps>;
  label: string;
};

type Props = {
  /**
   * Icon+label combination for the credit type
   * @param icon CDS icon to use, size and color not overridable.
   * @param label translated label for the credit type
   * @example
   * {
   *   icon: <GraduationCap />,
   *   label: 'Earn a degree'
   * }
   **/
  creditType: CardCreditTypeProps;
} & BaseComponentProps<'div'>;

/**
 * Common component to render type of credit for a product card.
 * @example
 * <CardCreditType
 *   creditType={{
 *     icon: <GraduationCap />,
 *     label: 'Earn a degree'
 *   }}
 * />
 */
const CardCreditType = ({ creditType, ...rest }: Props) => {
  return (
    <div className={classes.creditType} {...rest}>
      <IconContext.Provider
        value={{
          'aria-hidden': true,
          size: 'medium',
          color: 'interactive',
        }}
      >
        {creditType.icon}
      </IconContext.Provider>
      <Typography
        component="p"
        css={{ color: 'var(--cds-color-interactive-primary)' }}
        variant="bodySecondary"
      >
        {creditType.label}
      </Typography>
    </div>
  );
};

export default CardCreditType;
