import { useQuery } from '@apollo/client';

import EnterpriseForceSSOLogin from 'bundles/saml-sso/components/queries/EnterpriseForceSSOLogin.graphql';
import type {
  EnterpriseForceSsoLoginQuery as EnterpriseForceSsoLoginQueryType,
  EnterpriseForceSsoLoginQueryVariables,
} from 'bundles/saml-sso/components/queries/__generated__/EnterpriseForceSSOLogin';
import useForcedSsoEpicEnabled from 'bundles/saml-sso/shared/hooks/useForcedSsoEpicEnabled';

const useHasThirdPartyOrgEnforcedSSOLogin = (thirdPartyOrganizationId: string, emailAddress: string) => {
  const { isEmailAllocatedForForceSsoEpic, loading } = useForcedSsoEpicEnabled(emailAddress);

  const { data: entForceSsoData, loading: entForceSsoLoading } = useQuery<
    EnterpriseForceSsoLoginQueryType,
    EnterpriseForceSsoLoginQueryVariables
  >(EnterpriseForceSSOLogin, {
    skip: !isEmailAllocatedForForceSsoEpic,
    variables: { thirdPartyOrganizationId },
    context: { clientName: 'gatewayGql' },
  });
  const hasThirdPartyOrgEnforcedSSOLogin = !!entForceSsoData?.EntSso?.queryIfSsoForceLoginByOrgIdV2?.isSsoLoginForced;

  const isSsoLoginForced = isEmailAllocatedForForceSsoEpic && hasThirdPartyOrgEnforcedSSOLogin;
  const isLoading = loading || entForceSsoLoading;

  return { isSsoLoginForced, isLoading };
};

export default useHasThirdPartyOrgEnforcedSSOLogin;
