import NaptimeResource from './NaptimeResource';

export type DiscountAmount = { [currencyCode: string]: number };
class PromotionDetails extends NaptimeResource {
  static RESOURCE_NAME = 'promotionDetails.v1';

  // These properties are always included.
  declare id: string;

  // These properties must be requested.
  declare promotionName?: string;

  declare promotionDescription?: string;

  declare discountPercent?: number;

  declare discountAmount?: DiscountAmount;

  declare startsAt?: number;

  declare endsAt?: number;

  declare promotionId?: number;

  static byPromoCodeId(id: string, opts: { [key: string]: string | number | boolean | Array<string> }) {
    return this.finder('byPromoCodeId', Object.assign({ params: { id } }, opts), (promoDetails) => promoDetails?.[0]);
  }
}

export default PromotionDetails;
