import URI from 'jsuri';
import Q from 'q';

import API from 'js/lib/api';

const jwtLoginAPI = API('/api/jwtLogins.v1/', { type: 'rest' });

export const getToken = (organization: string, webToken: string): Q.Promise<{ id: string }> => {
  const data = {
    organization,
    webToken,
  };

  return Q(jwtLoginAPI.post('', { data })).then((response) => response.elements[0]);
};

export const getTokenFromInvitation = (organization: string, invitationToken: string): Q.Promise<{ id: string }> => {
  const uri = new URI().addQueryParam('action', 'loginWithInvitationToken');
  const data = {
    organization,
    invitationToken,
  };
  return Q(jwtLoginAPI.post(uri.toString(), { data }));
};
