import * as React from 'react';
import { useState } from 'react';

import redirect from 'js/lib/coursera.redirect';

import EnrollmentChoiceTypes from 'bundles/enroll-course/common/EnrollmentChoiceTypes';
import {
  choiceTypeToHandleSubmitPromise,
  submitEnrollmentPromise,
} from 'bundles/enroll-course/lib/enrollmentChoiceUtils';
import EnrollErrorModal from 'bundles/enroll/components/common/EnrollErrorModal';
import { useCourseEnrollModalData } from 'bundles/enroll/data/usePageData';
import { EnrollModalType } from 'bundles/enroll/types/modalTypes';
import Icon from 'bundles/iconfont/Icon';
import TrackedButton from 'bundles/page/components/TrackedButton';
import Modal from 'bundles/phoenix/components/Modal';

import _t from 'i18n!nls/enroll';

import 'css!./__styles__/CourseWithFullDiscountEnrollModal';

type PropsFromCaller = {
  courseId: string;
  onClose: () => void;
};

const CourseWithFullDiscountEnrollModal: React.FC<PropsFromCaller> = ({ courseId, onClose }) => {
  const [didClickContinue, setDidClickContinue] = useState<boolean>(false);
  const [activeModal, setActiveModal] = useState<EnrollModalType>(EnrollModalType.ENROLL);

  const { course, courseTypeMetadataWithVersion } = useCourseEnrollModalData();

  const onClickContinue = () => {
    setDidClickContinue(true);

    const handleSubmitPromise = choiceTypeToHandleSubmitPromise[EnrollmentChoiceTypes.ENROLL_COURSE_WITH_FULL_DISCOUNT];
    const options = { courseId };
    return submitEnrollmentPromise({ handleSubmitPromise, options })
      .then(() => {
        redirect.setLocation(course.learnerPhoenixHomeLink);
      })
      .catch(() => {
        setActiveModal(EnrollModalType.ERROR);
      });
  };

  const renderEnrollModal = (): React.ReactElement => {
    const welcomeMessage = _t('Start learning from this #{courseType}.', {
      courseType: courseTypeMetadataWithVersion.courseLabel,
    });

    return (
      <Modal
        className="rc-CourseWithFullDiscountEnrollModal"
        modalName="CourseWithFullDiscount"
        handleClose={onClose}
        trackingName="course_with_full_discount"
        data={{ id: course.id }}
      >
        <div className="theme-dark">
          <div className="cem-title color-primary-text align-horizontal-center headline-4-text">{course.name}</div>
        </div>
        <div className="cem-body align-horizontal-center">
          <div className="message">{welcomeMessage}</div>
          <TrackedButton
            className="primary cozy continue-button"
            data-e2e="course_with_full_discount"
            onClick={onClickContinue}
            disabled={didClickContinue}
            trackingName="course_with_full_discount_continue"
          >
            {didClickContinue ? <Icon name="spinner" spin={true} /> : _t('Continue')}
          </TrackedButton>
        </div>
      </Modal>
    );
  };

  switch (activeModal) {
    case EnrollModalType.ERROR:
      return (
        <EnrollErrorModal
          onClose={onClose}
          messageOverride={_t('Enrollment failed. Please contact customer support or try again.')}
        />
      );
    case EnrollModalType.ENROLL:
      return renderEnrollModal();
    default:
      return null;
  }
};

export default CourseWithFullDiscountEnrollModal;
