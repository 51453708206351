/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { ContentRect } from 'react-measure';
import Measure from 'react-measure';

import { ReactEditor, useSelected, useSlateStatic } from 'slate-react';
import type { RenderElementProps } from 'slate-react';

import logger from 'js/app/loggerSingleton';

import ImageUploaderProgress from 'bundles/cml/editor/components/elements/imageUploader/ImageUploaderProgress';
import {
  ASSET_MANAGER_TRANSITION_MS,
  OPACITY_TRANSITION_MS,
  UPPY_PROGRESS_PERCENTAGE,
} from 'bundles/cml/editor/components/elements/imageUploader/constants';
import type { UppyOptions } from 'bundles/cml/editor/components/elements/imageUploader/uppy/types';
import { uploadImage } from 'bundles/cml/editor/components/elements/imageUploader/uppy/uppyUtils';
import {
  imageUploadTransitionPromise,
  updateImageAsset as updateImageElement,
} from 'bundles/cml/editor/components/elements/imageUploader/utils';
import { useAssetContext } from 'bundles/cml/editor/context/assetContext';
import { useFocusedContext } from 'bundles/cml/editor/context/focusContext';
import ImageRenderer from 'bundles/cml/shared/components/image/ImageRenderer';
import { useMounted } from 'bundles/cml/shared/hooks/useMounted';
import type { ImageUploaderElement } from 'bundles/cml/shared/types/elementTypes';

const styles = {
  root: css`
    line-height: 0;
  `,
  container: css`
    position: relative;
  `,
  image: css`
    transition: opacity ${OPACITY_TRANSITION_MS}ms ease-out;
    transition-delay: ${ASSET_MANAGER_TRANSITION_MS}ms;
    opacity: 1;
  `,
  loading: css`
    opacity: 0.5;
  `,
  hover: css`
    &:hover {
      outline: 3px solid var(--cds-color-interactive-primary);
      cursor: pointer;
    }
  `,
  selected: css`
    cursor: pointer;
    outline: 3px solid var(--cds-color-callouts-tertiary);
  `,
};

const ImageUploader: React.FC<RenderElementProps> = (props) => {
  const { element, attributes, children } = props;
  const { src, alt, file } = element as ImageUploaderElement;
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(false);

  const mounted = useMounted();
  const { focused } = useFocusedContext();
  const selected = useSelected();

  const staticEditor = useSlateStatic();
  const { isLearnerUpload, uploadOptions, assetManager } = useAssetContext();
  const path = ReactEditor.findPath(staticEditor, element);

  // use learner-specific asset creation endpoints when passed in
  const assetCreationUrl = isLearnerUpload
    ? uploadOptions?.image?.assetCreationUrl || uploadOptions?.asset?.assetCreationUrl
    : undefined;

  const handleAssetUploaded = useCallback(
    async (assetId: string) => {
      if (!mounted.current) {
        return;
      }

      const asset = await assetManager.fetchAsset(assetId);
      if (!mounted.current || !asset) {
        return;
      }

      setProgress(100);
      await imageUploadTransitionPromise();

      if (!mounted.current) {
        return;
      }

      updateImageElement(staticEditor, assetId, path);
    },
    [mounted, assetManager, staticEditor, path]
  );

  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  const handleResize = useCallback(({ client }: ContentRect) => {
    const imageHeight = client?.height ?? 0;
    const imageWidth = client?.width ?? 0;

    setHeight(imageHeight);
    setWidth(imageWidth);
  }, []);

  const handleUppyProgress = useCallback(
    (value: number) => {
      if (mounted.current) {
        setProgress(value * UPPY_PROGRESS_PERCENTAGE);
      }
    },
    [mounted]
  );

  const uppyOptions: UppyOptions = useMemo(
    () => ({
      src,
      file,
      mounted,
      assetContext: uploadOptions?.image?.context || uploadOptions?.asset?.context,
      assetCreationUrl,
      uploadOptions,
      onProgress: handleUppyProgress,
      isLearnerUpload: isLearnerUpload ?? false,
    }),
    [src, file, mounted, uploadOptions, assetCreationUrl, handleUppyProgress, isLearnerUpload]
  );

  const handleError = useCallback((reason: unknown) => {
    logger.warn(reason);
    setError(true);
  }, []);

  useEffect(
    () => {
      let cancelled = false;
      const uploadImageFunc = async () => {
        try {
          const assetId = await uploadImage(uppyOptions);
          if (!cancelled) {
            handleAssetUploaded(assetId);
          }
        } catch (e) {
          handleError(e);
        }
      };

      uploadImageFunc();
      return () => {
        cancelled = true;
      };
    },
    // TODO: React Hook useEffect has missing dependencies: 'handleAssetUploaded', 'handleError', and 'uppyOptions'. Either include them or remove the dependency array.
    // FIXME: exhaustive-deps became an error; existing violations are excused to prevent seeing errors when modifying other parts of the same file; fix it carefully
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const loading = progress < 100;

  return (
    <div {...attributes} css={styles.root}>
      <Measure client onResize={handleResize}>
        {({ measureRef }) => (
          <div css={styles.container} contentEditable={false} data-testid="cml-image-uploader">
            <ImageRenderer ref={measureRef} src={src} alt={alt} css={[styles.image, loading && styles.loading]} />
            <ImageUploaderProgress
              progress={progress}
              height={height}
              width={width}
              error={error}
              css={selected && focused ? styles.selected : styles.hover}
            />
          </div>
        )}
      </Measure>
      {children}
    </div>
  );
};

export default ImageUploader;
