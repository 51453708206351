import ProductOwnershipsV1 from 'bundles/naptimejs/resources/productOwnerships.v1';

import type { QueryProductOwnershipQuery } from './__generated__/QueryProductOwnership';
import type { QueryProductOwnershipsByIdQuery } from './__generated__/QueryProductOwnershipsById';
import type { QueryStoredProductOwnershipsByUserQuery } from './__generated__/QueryStoredProductOwnershipsByUser';
import type { ProductOwnershipAttributesFragment } from './__generated__/productOwnershipFragment';

const convertProductOwnershipGQLNodeToNaptime = (node: ProductOwnershipAttributesFragment) => {
  const { expiredOwns, id, owns, s12nCourseOwnerships } = node;
  const [, productType, productId] = id.split('~');
  const productOwnership: ProductOwnershipsV1 = new ProductOwnershipsV1({
    productId,
    expiredOwns,
    owns,
    productType,
    s12nCourseOwnerships,
  });
  return productOwnership;
};

type ProductOwnershipsByUserQueryToNaptimeType = (
  data: QueryStoredProductOwnershipsByUserQuery | undefined
) => ProductOwnershipsV1[];

export const convertProductOwnershipByUserToNaptime: ProductOwnershipsByUserQueryToNaptimeType = (data) => {
  if (!data) {
    return [];
  }
  const edges = data?.Entitlement?.queryStoredProductOwnershipsByUser.edges || [];
  const naptimeElements = edges.map((edge) => {
    if (edge?.node) {
      return convertProductOwnershipGQLNodeToNaptime(edge.node);
    }
    return undefined;
  });
  return naptimeElements.filter((element) => !!element) as ProductOwnershipsV1[];
};

type ProductOwnershipsByIdQueryType = (data: QueryProductOwnershipsByIdQuery | undefined) => ProductOwnershipsV1[];

export const convertProductOwnershipsByIdToNaptime: ProductOwnershipsByIdQueryType = (data) => {
  if (!data) {
    return [];
  }
  const edges = data?.Entitlement?.queryProductOwnershipsById.edges || [];
  const naptimeElements = edges.map((edge) => {
    if (edge?.node) {
      return convertProductOwnershipGQLNodeToNaptime(edge.node);
    }
    return undefined;
  });
  return naptimeElements.filter((element) => !!element) as ProductOwnershipsV1[];
};

type ProductOwnershipQueryToNaptimeType = (
  data: QueryProductOwnershipQuery | undefined
) => ProductOwnershipsV1 | undefined;

export const convertProductOwnershipToNaptime: ProductOwnershipQueryToNaptimeType = (data) => {
  const node = data?.Entitlement?.queryProductOwnership;
  if (!node) {
    return undefined;
  }
  return convertProductOwnershipGQLNodeToNaptime(node);
};
