import * as React from 'react';

import { Link } from '@coursera/cds-core';

import { DISCLAIMER_LINKS } from 'bundles/payments/common/constants';

import _t from 'i18n!nls/payments';

export const getTermsOfUseLink = (label?: string) => (
  <Link href={DISCLAIMER_LINKS.TERMS_OF_USE} target="_blank" rel="noopener noreferrer">
    {label ?? _t('Terms of Use')}
  </Link>
);

export const getRefundPolicyLink = (label?: string) => (
  <Link href={DISCLAIMER_LINKS.REFUND_POLICY} target="_blank" rel="noopener noreferrer">
    {label ?? _t('Refund Policy')}
  </Link>
);

export const getPrivacyPolicy = (label?: string) => (
  <Link href={DISCLAIMER_LINKS.PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
    {label ?? _t('Privacy Policy')}
  </Link>
);

export const getCancelSubscriptionLink = (label?: string) => (
  <Link href={DISCLAIMER_LINKS.CANCEL_SUBSCRIPTION} target="_blank" rel="noopener noreferrer">
    {label ?? _t('cancel')}
  </Link>
);
