import type React from 'react';
import { useEffect } from 'react';

export const useFocusTrap = (elementRef: React.RefObject<HTMLElement>) => {
  useEffect(() => {
    const handleFocus = (e: KeyboardEvent) => {
      const element = elementRef.current;

      if (e.key !== 'Tab' || !element) {
        return;
      }

      const focusableElements = (element.querySelectorAll(
        'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
      ) || []) as NodeListOf<HTMLElement>;

      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      if (e.shiftKey && document.activeElement === firstElement) {
        lastElement.focus();
        e.preventDefault();
      } else if (!e.shiftKey && document.activeElement === lastElement) {
        firstElement.focus();
        e.preventDefault();
      }
    };

    document.addEventListener('keydown', handleFocus);

    return () => document.removeEventListener('keydown', handleFocus);
  }, [elementRef]);
};
