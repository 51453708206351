import * as React from 'react';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet';

import requestUrlInfo from 'js/lib/requestUrlInfo';
import { useLocation } from 'js/lib/useRouter';

import GrowthExperiments from 'bundles/epic/clients/Growth';
import type { MetaNameAndPropertiesProps } from 'bundles/seo/CourseraMetatagsV2Types';
import SeoPathConfigurationQueryWrapper from 'bundles/seo/api/SeoPathConfigurationQueryWrapper';
import { useSeoPageData } from 'bundles/seo/contexts/SeoPageDataContext';
import {
  generateMetaNameAndProperties,
  generateMetaTitle,
  generateOverrideRuleFromMetaTags,
} from 'bundles/seo/utils/pageMetadata';

declare const COURSERA_APP_NAME: string;

/** Simple Coursera Metatags Component
 * Provides default title, description, and image meta
 * Does not include the override
 * */
const SimpleCourseraMetatagsBaseComponent: React.FC<MetaNameAndPropertiesProps> = (props) => {
  const { title, disableCourseraSuffix } = props;
  const { metaTags: metaTagsFromSeoPageData } = useSeoPageData();

  const overrideRule = useMemo(() => {
    if (metaTagsFromSeoPageData) {
      return generateOverrideRuleFromMetaTags(metaTagsFromSeoPageData);
    }

    return undefined;
  }, [metaTagsFromSeoPageData]);

  const location = useLocation();
  return (
    <Helmet
      title={generateMetaTitle(title, disableCourseraSuffix, overrideRule)}
      meta={generateMetaNameAndProperties(props, location, overrideRule)}
    />
  );
};

const SimpleCourseraMetatags: React.FC<MetaNameAndPropertiesProps> = (props) => {
  const { pathname, search } = useLocation();
  const hostname = requestUrlInfo().hostname;

  // Only fetch page data overrides for apps enabled in experiment
  const enabledAppList = GrowthExperiments.get('seoOverrideEnabledApps');
  const appName = COURSERA_APP_NAME;

  return (
    <SeoPathConfigurationQueryWrapper
      path={hostname + pathname + search}
      shouldSkip={!enabledAppList.includes(appName)}
    >
      <SimpleCourseraMetatagsBaseComponent {...props} />
    </SeoPathConfigurationQueryWrapper>
  );
};
export default SimpleCourseraMetatags;

export const BaseComponent = SimpleCourseraMetatagsBaseComponent;
