import * as React from 'react';

import type { IconColor, IconSize } from 'bundles/ai-coach-platform/types';

/**
 * MUI icon for "AI" sparkle
 */

const SparkleIcon = ({ size = 16, color = 'default' }: { size?: IconSize; color?: IconColor }) => {
  if (color === 'invert') {
    return (
      <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="SparkleFilled_small icon">
          <path
            id="Union"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.9253 1.17372C12.8307 0.942092 12.5027 0.942092 12.4081 1.17372L12.0049 2.16147C11.9103 2.3931 11.7265 2.57691 11.4948 2.67148L10.5071 3.07476C10.2755 3.16933 10.2755 3.49734 10.5071 3.59191L11.4948 3.99518C11.7265 4.08975 11.9103 4.27357 12.0049 4.50519L12.4081 5.49294C12.5027 5.72458 12.8307 5.72457 12.9253 5.49294L13.3286 4.50519C13.4231 4.27357 13.6069 4.08975 13.8386 3.99518L14.8263 3.59191C15.0579 3.49734 15.0579 3.16933 14.8263 3.07476L13.8386 2.67148C13.6069 2.57691 13.4231 2.3931 13.3286 2.16147L12.9253 1.17372ZM7.65358 2.84536C7.49582 2.45895 6.94863 2.45894 6.79086 2.84536L5.93602 4.93911C5.46274 6.09834 4.54279 7.01828 3.38357 7.49157L1.28981 8.34641C0.903396 8.50418 0.903395 9.05137 1.28981 9.20913L3.38357 10.064C4.54279 10.5373 5.46274 11.4572 5.93602 12.6164L6.79086 14.7102C6.94863 15.0966 7.49582 15.0966 7.65358 14.7102L8.50842 12.6164C8.98171 11.4572 9.90165 10.5373 11.0609 10.064L13.1546 9.20913C13.541 9.05137 13.541 8.50418 13.1546 8.34641L11.0609 7.49157C9.90165 7.01829 8.98171 6.09834 8.50842 4.93912L7.65358 2.84536Z"
            fill="white"
          />
        </g>
      </svg>
    );
  }
  return (
    <svg
      className="sparkle-icon"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9253 1.17372C12.8307 0.942092 12.5027 0.942092 12.4081 1.17372L12.0049 2.16147C11.9103 2.3931 11.7265 2.57691 11.4948 2.67148L10.5071 3.07476C10.2755 3.16933 10.2755 3.49734 10.5071 3.59191L11.4948 3.99518C11.7265 4.08975 11.9103 4.27357 12.0049 4.50519L12.4081 5.49294C12.5027 5.72458 12.8307 5.72457 12.9253 5.49294L13.3286 4.50519C13.4231 4.27357 13.6069 4.08975 13.8386 3.99518L14.8263 3.59191C15.0579 3.49734 15.0579 3.16933 14.8263 3.07476L13.8386 2.67148C13.6069 2.57691 13.4231 2.3931 13.3286 2.16147L12.9253 1.17372ZM7.65358 2.84536C7.49582 2.45895 6.94863 2.45894 6.79086 2.84536L5.93602 4.93911C5.46274 6.09834 4.54279 7.01828 3.38357 7.49157L1.28981 8.34641C0.903396 8.50418 0.903395 9.05137 1.28981 9.20913L3.38357 10.064C4.54279 10.5373 5.46274 11.4572 5.93602 12.6164L6.79086 14.7102C6.94863 15.0966 7.49582 15.0966 7.65358 14.7102L8.50842 12.6164C8.98171 11.4572 9.90165 10.5373 11.0609 10.064L13.1546 9.20913C13.541 9.05137 13.541 8.50418 13.1546 8.34641L11.0609 7.49157C9.90165 7.01829 8.98171 6.09834 8.50842 4.93912L7.65358 2.84536Z"
        fill="url(#paint0_linear_3012_16580)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3012_16580"
          x1="-0.0592136"
          y1="15"
          x2="18.8911"
          y2="14.5309"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--cds-color-emphasis-primary-content-default)" />
          <stop offset="1" stopColor="var(--cds-color-blue-600)" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SparkleIcon;
