import { FinancialAidApplication_State as FinancialAidApplicationState } from '__generated__/graphql-types';

export const FindAidState = {
  ...FinancialAidApplicationState,
  ApprovedPaymentSuccessful: 'APPROVED_PAYMENT_SUCCESSFUL',
} as const;

export type FinancialAidApplicationStateType = typeof FindAidState[keyof typeof FindAidState];

export type FinancialAidApplicationType = {
  id: string;
  userId?: string;
  cartId?: string;
  country?: string | null;
  annualIncome?: number;
  annualIncomeCurrencyCode?: string;
  whyApplying?: string | null;
  whyTakingCourse?: string | null;
  howContributeToCommunity?: string | null;
  denied?: boolean;
  state?: FinancialAidApplicationStateType;
  additionalQuestions?: {
    educationalBackground?: string | null;
    employmentStatus?: string | null;
    affordToPay?: number | null;
    affordToPayCurrencyCode?: string | null;
    payPeriod?: string | null;
    howContributeToCareer?: string | null;
    willingToBecomeCourseMentor?: boolean;
    willingToHelpTranslate?: boolean;
    willingToTest?: boolean;
    willingToTakeOutLoan?: boolean | null;
    whyNotWillingToTakeLoan?: string | null;
  };
  approvalDate?: number | null;
  applicationDate?: number | null;
  productId?: string | null;
};
