import { graphql } from 'react-apollo';

import gql from 'graphql-tag';
import { flatMap, isEmpty, partition } from 'lodash';
import { compose, withProps } from 'recompose';

import type { TrackingData } from 'js/lib/retracked';

import { getSkillSearchTerm } from 'bundles/enterprise-learner-search/components/skillSearch/SkillNameMapping';
import type {
  EnterpriseProgramWithSkillsetsQuery,
  EnterpriseProgramWithSkillsetsQueryVariables,
} from 'bundles/enterprise-learner-search/components/skillsetSearch/__generated__/EnterpriseProgramWithSkillsetsQuery';

import _t from 'i18n!nls/program-common';

const MAX_SKILLSET_MATCHES = 3;
const DEFAULT_SKILLSET_LOGO = `data:image/svg+xml;.svg;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyMSAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuNzY1NjI1IDE3LjM5ODZWNi40MDQ2OUwxMC4wMDU2IDAuOTA4OTM2TDE5LjI0NTYgNi40MDcxMVYxNy4zOTg2TDEwLjAwNTYgMjIuODk0NEwwLjc2NTYyNSAxNy4zOTg2WiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDUuOTUxNTJMMTAuMDA0NyAwTDIwIDUuOTUxNTJWMTcuODU0NUwxMC4wMDQ3IDIzLjgwNjFMMCAxNy44NTIxVjUuOTUxNTJaTTE4LjQ3NTIgNi44NjA1OUwxMC4wMDQ2IDEuODIwNTlMMS41MzQwMiA2Ljg2MDU5VjE2Ljk0M0wxMC4wMDQ2IDIxLjk4NTRMMTguNDc1MiAxNi45NDU0VjYuODYwNTlaIiBmaWxsPSIjRUJBNjAwIi8+CjxwYXRoIGQ9Ik0xOC40NzUgNi44NzI4N0wyMC4wMDkxIDUuOTUxNjZWMTcuODU0N0wxOC40NzI3IDE2Ljk0MDhMMTguNDc1IDYuODcyODdaIiBmaWxsPSIjRkZDNTAwIi8+CjxwYXRoIGQ9Ik0wIDE3Ljg1MjFMMS41MTc2NSAxNi45MzMzTDEwLjAxODggMjEuOTkwM0wxMC4wMDQ3IDIzLjgwMzdMMCAxNy44NTIxWiIgZmlsbD0iI0ZGQzUwMCIvPgo8cGF0aCBkPSJNMS41MjQ3MSA2Ljg1ODE4TDAgNS45NTE1MkwxMC4wMDQ3IDBMOS45OTUyOSAxLjgwNjA2TDEuNTI0NzEgNi44NTgxOFoiIGZpbGw9IiNGRkM1MDAiLz4KPHBhdGggZD0iTTEwLjAwMzkgNy4wOTM1MUwxMS41MTkyIDEwLjI1OTZMMTQuOTEyMiAxMC43NjYyTDEyLjQ1ODEgMTMuMjMxN0wxMy4wMzY5IDE2LjcxMDVMMTAuMDAzOSAxNS4wNjkzTDYuOTcxIDE2LjcxMDVMNy41NDk4MiAxMy4yMzE3TDUuMDk1NyAxMC43NjYyTDguNDg2MjkgMTAuMjU5NkwxMC4wMDM5IDcuMDkzNTFaIiBmaWxsPSIjRkZDNTAwIiBzdHJva2U9IiNFQkE2MDAiIHN0cm9rZS13aWR0aD0iMS42MTYxNiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=`;

export type SkillSetResult = {
  name: string;
  imageUrl: string;
  topSkill: string;
  objectID: string;
  trackingName: string;
  trackingData: TrackingData;
};

export type SkillSetSearchResults = {
  sectionTitle: string;
  hits: SkillSetResult[];
};

export type Props = {
  currentRefinement?: string;
  programIds: string[];
};

type OutputProps = {
  skillsetHits: SkillSetSearchResults[];
  loading: boolean;
  error: boolean;
};

export const EnterpriseProgramWithSkillsetsQueryText = gql`
  query EnterpriseProgramWithSkillsetsQuery($programIds: [String!]!) {
    EnterpriseProgramsV1Resource {
      multiGet(ids: $programIds) {
        elements {
          id
          metadata {
            slug
          }
          tspSummaries {
            elements {
              id
              slug
              title
              targetSkillProficiencies {
                skillName
              }
            }
          }
        }
      }
    }
  }
`;

export type SkillSet = {
  title: string;
  programSlug: string;
  skillsetSlug: string;
  skills: string[];
};
type SkillSetsData = {
  skillsets: SkillSet[];
  loading: boolean;
  error: boolean;
};
const withSkillSetData = graphql<
  Props,
  EnterpriseProgramWithSkillsetsQuery,
  EnterpriseProgramWithSkillsetsQueryVariables,
  SkillSetsData
>(EnterpriseProgramWithSkillsetsQueryText, {
  props: ({ data }) => {
    const loading = data?.loading ?? true;
    const error = !!data?.error;

    const skillsets =
      flatMap(data?.EnterpriseProgramsV1Resource?.multiGet?.elements, (program) => {
        return (
          program?.tspSummaries?.elements.map((skillset) => ({
            title: skillset?.title ?? '',
            programSlug: program.metadata.slug,
            skillsetSlug: skillset?.slug ?? '',
            skills: skillset?.targetSkillProficiencies.map((skill) => skill.skillName) || [],
          })) || []
        );
      }) || [];

    return {
      loading,
      error,
      skillsets,
    };
  },
});

const getSkillMatch = (skillset: SkillSet, search: string) => {
  return skillset.skills.find((skill) => skill.toLowerCase().includes(search));
};

const searchSkillSets = (skillsets: SkillSet[], search: string, maxMatches: number): SkillSet[] => {
  const [titleMatches, notTitleMatches] = partition(skillsets, (s) => s.title.toLowerCase().includes(search));
  const skillMatches = notTitleMatches.filter((s) => !!getSkillMatch(s, search));
  const allMatches = [...titleMatches, ...skillMatches];

  return allMatches.slice(0, maxMatches);
};

const withFilteredSkillSets = withProps<OutputProps, Props & SkillSetsData>((data) => {
  const loading = data.loading;
  const error = !!data.error;
  const search = getSkillSearchTerm(data.currentRefinement ?? '');
  const sectionTitle = _t('SkillSet Roles');
  const hits =
    loading || error || !data.skillsets || isEmpty(search)
      ? []
      : searchSkillSets(data.skillsets, search, MAX_SKILLSET_MATCHES);

  if (hits.length === 0) {
    return {
      loading,
      error,
      skillsetHits: [],
    };
  }

  return {
    loading,
    error,
    skillsetHits: [
      {
        sectionTitle,
        hits: hits.map((s) => ({
          name: s.title,
          sectionTitle,
          imageUrl: DEFAULT_SKILLSET_LOGO,
          topSkill: getSkillMatch(s, search) || s.skills[0],
          objectID: `skillset~${s.programSlug}/${s.skillsetSlug}`,
          trackingName: 'skillset_search_autocomplete',
          trackingData: {
            programSlug: s.programSlug,
            skillsetSlug: s.skillsetSlug,
          },
        })),
      },
    ],
  };
});

const withSkillSetSearchResults = <OtherInputProps = {}>() =>
  compose<OutputProps & Props & OtherInputProps, Props & OtherInputProps>(withSkillSetData, withFilteredSkillSets);

export {
  withSkillSetSearchResults,
  withSkillSetData,
  withFilteredSkillSets,
  searchSkillSets,
  getSkillMatch,
  DEFAULT_SKILLSET_LOGO,
};
