import * as React from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import Icon from 'bundles/iconfont/Icon';

import 'css!./__styles__/SubscriptionVPropBulletPoint';

export type PropsFromCaller = {
  highlightHeader?: boolean;
  header: string;
  subheader?: string;
  messageProps?: Record<string, string | JSX.Element>;
};

const SubscriptionVPropBulletPoint: React.FC<PropsFromCaller> = ({
  highlightHeader,
  header,
  subheader,
  messageProps,
}) => {
  const renderHeader = () => {
    if (highlightHeader) {
      return (
        <strong>
          <FormattedMessage message={header} {...messageProps} />
        </strong>
      );
    } else {
      return <FormattedMessage message={header} {...messageProps} />;
    }
  };

  return (
    <div className="rc-SubscriptionVPropBulletPoint horziontal-box">
      <div className="flex-1">
        <Icon name="checkmark" className="color-primary" />
      </div>
      <div className="flex-11 text-container">
        <div className="headline-1-text header">{renderHeader()}</div>
        {subheader && (
          <div className="body-1-text subheader">
            <FormattedMessage message={subheader} {...messageProps} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscriptionVPropBulletPoint;
