/** @jsx jsx */
import { jsx } from '@emotion/react';

import { Grid, PageGridContainer } from '@coursera/cds-core';

import ProductCardCollectionPlaceholder from 'bundles/collections-common/components/ProductCardCollectionPlaceholder';
import EnterpriseLearningPathsModule from 'bundles/page-config-common/components/enterprise/EnterpriseLearningPathsModule';
import EnterpriseSingleLearningPathModule from 'bundles/page-config-common/components/enterprise/EnterpriseSingleLearningPathModule';
import HomeSection from 'bundles/page-config-common/components/shared/HomeSection';
import { useEnterpriseBadgeCollections } from 'bundles/page-config-common/contexts/enterprise/EnterpriseBadgeCollectionsContext';

const EnterpriseLearningPathsNotConfigurable = () => {
  const { enterpriseBadgesWithCollection, enterpriseBadgeCollectionsLoading } = useEnterpriseBadgeCollections();

  if (enterpriseBadgeCollectionsLoading) {
    return (
      <HomeSection>
        <PageGridContainer data-testid="enterprise-learning-paths" className="learning-path-container">
          <Grid item xs={12}>
            <ProductCardCollectionPlaceholder />
          </Grid>
        </PageGridContainer>
      </HomeSection>
    );
  }

  if (enterpriseBadgesWithCollection.length === 0) {
    return null;
  }
  if (enterpriseBadgesWithCollection.length === 1) {
    return <EnterpriseSingleLearningPathModule enterpriseBadgeWithCollection={enterpriseBadgesWithCollection[0]} />;
  }
  return <EnterpriseLearningPathsModule enterpriseBadgesWithCollection={enterpriseBadgesWithCollection} />;
};

export default EnterpriseLearningPathsNotConfigurable;
