import * as React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import Media from 'react-media';

import classNames from 'classnames';

import { isRightToLeft } from 'js/lib/language';
import type UserAgentInfo from 'js/lib/useragent';

import { Box, breakPoint, css } from '@coursera/coursera-ui';

import { noShowStyles } from 'bundles/enterprise-legacy-xdp/components/__styles__/shared';
import Avatar from 'bundles/enterprise-legacy-xdp/components/instructors/Avatar';
import TopInstructor from 'bundles/enterprise-legacy-xdp/components/instructors/TopInstructor';
import { TrackedA } from 'bundles/page/components/TrackedLink2';
import { navItemsEnum } from 'bundles/program-common/constants/xdpConstants';
import type { InstructorType } from 'bundles/program-common/types/xdpTypes';
import { generateInstructorImageAltValue } from 'bundles/program-common/utils/xdpUtils';

import _t from 'i18n!nls/program-common';

import 'css!bundles/enterprise-legacy-xdp/components/banner/__styles__/BannerInstructorInfo';

type Props = {
  instructors: Array<InstructorType>;
  userAgent?: UserAgentInfo;
  extraClassName?: string;
};

const BannerInstructorInfo: React.SFC<Props> = ({ instructors, userAgent, extraClassName }) => {
  if (!Array.isArray(instructors) || instructors.length === 0) {
    return null;
  }

  const fullName = instructors?.[0]?.fullName;
  const photo = instructors?.[0]?.photo;
  const instructorId = instructors?.[0]?.id;

  if (!fullName || !photo) {
    return null;
  }

  const INSTRUCTOR_AVATAR_SIZE_MOBILE = 28;
  const INSTRUCTOR_AVATAR_SIZE_DESKTOP = 36;
  const topInstructorsCount = instructors.filter((instructor) => instructor.isTopInstructor).length;

  const showTopInstructorLabel = topInstructorsCount > 0;

  const isRTL = isRightToLeft(_t.getLocale());

  return (
    <div className={classNames('rc-BannerInstructorInfo', extraClassName, 'rc-BannerInstructorInfo__seo-experiment')}>
      <div {...css(noShowStyles.noShowList)} style={isRTL ? { paddingRight: '0px' } : { paddingLeft: '0px' }}>
        <Box>
          <TrackedA href={`#${navItemsEnum.instructors}`} trackingName={`nav_item_${navItemsEnum.instructors}`}>
            <Box alignItems="center">
              <Box
                alignItems="center"
                rootClassName={classNames({
                  'instructor-count-display': instructors.length > 1,
                })}
              >
                <Media query={{ maxWidth: breakPoint.md - 1 }} defaultMatches={userAgent && userAgent.isMobileBrowser}>
                  {(matches) => (
                    <Avatar
                      imgAlt={generateInstructorImageAltValue(fullName)}
                      imgSrc={photo}
                      size={matches ? INSTRUCTOR_AVATAR_SIZE_MOBILE : INSTRUCTOR_AVATAR_SIZE_DESKTOP}
                      style={isRTL ? { marginLeft: '8px' } : { marginRight: '8px' }}
                    />
                  )}
                </Media>
                <FormattedHTMLMessage
                  message={_t(
                    `{fullName} {instructorsCount, plural,
                =0 {} 
                =1 {<span class="more-instructors underline">+{instructorsCount} more instructor</span>}
                other {<span class="more-instructors underline">+{instructorsCount} more instructors</span>}}`
                  )}
                  fullName={fullName}
                  instructorsCount={instructors.length - 1}
                />
                &nbsp;&nbsp;
              </Box>
              {showTopInstructorLabel && (
                <>
                  <TopInstructor
                    instructorId={instructorId}
                    instructorCount={topInstructorsCount}
                    instructorName={fullName}
                  />
                  &nbsp;&nbsp;
                </>
              )}
            </Box>
          </TrackedA>
        </Box>
      </div>
    </div>
  );
};

export default BannerInstructorInfo;
