/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { isRightToLeft } from 'js/lib/language';

import type { IconSize } from 'bundles/ai-coach-platform/types';

import _t from 'i18n!nls/ai-coach-platform';

/**
 * MUI icon for "Send" chat action, flips correctly for RTL
 */
const SendIcon = ({ size = 16 }: { size?: IconSize }) => {
  // eslint-disable-next-line import/no-named-as-default-member
  const isRTL = isRightToLeft(_t.getLocale());
  return (
    <svg
      css={css`
        ${isRTL && `transform: scaleX(-1);`}
      `}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5945 8.67752L1.98483 13.9879C1.7531 14.0909 1.53103 14.0748 1.31862 13.9396C1.10621 13.8044 1 13.6081 1 13.3506V2.69131C1 2.44671 1.10621 2.25683 1.31862 2.12166C1.53103 1.98648 1.7531 1.96395 1.98483 2.05407L14.5945 7.36441C14.8648 7.49315 15 7.71522 15 8.03062C15 8.34602 14.8648 8.56166 14.5945 8.67752ZM2.25517 12.4623L12.8566 8.02097L2.25517 3.52166V6.7851L7.06345 8.02097L2.25517 9.21821V12.4623Z"
        fill="var(--cds-color-grey-950)"
      />
    </svg>
  );
};
export default SendIcon;
