/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';

import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import { Button, TextField } from '@coursera/cds-core';
import { ChevronPreviousIcon } from '@coursera/cds-icons';

import { NotificationContext } from 'bundles/cml/editor/context/notificationContext';
import { useFocusTrap } from 'bundles/cml/editor/utils/dialogUtils';
import type { CodeElement } from 'bundles/cml/shared/types/elementTypes';

import _t from 'i18n!nls/cml';

const MAX_LENGTH = 125;

const styles = {
  dialog: css`
    padding: var(--cds-spacing-200);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: var(--cds-spacing-300);
    width: 356px;

    label {
      margin-bottom: 0;
    }
  `,
};

export type Props = {
  element: CodeElement;
  onClose: () => void;
};

export const CodeBlockNameDialog: React.FC<Props> = ({ element, onClose }) => {
  const [nameUpdated, setNameUpdated] = useState(false);
  const [name, setName] = useState(element.name ?? '');
  const { setNotification } = useContext(NotificationContext);
  const showNotification = useRef<boolean>(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const staticEditor = useSlateStatic();

  useEffect(() => {
    showNotification.current = nameUpdated;
  }, [nameUpdated]);

  useEffect(
    () => {
      // display notification when the dialog is unmounted
      return () => {
        if (showNotification.current) {
          setNotification({ message: 'Code block name saved', type: 'information' });
        }
      };
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useFocusTrap(ref);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setName(e.target.value);
    if (e.target.value.length > MAX_LENGTH) {
      return;
    }

    setNameUpdated(true);
    Transforms.setNodes(
      staticEditor,
      { ...element, name: e.target.value.trim() },
      { at: ReactEditor.findPath(staticEditor, element) }
    );
  };

  const handleClose = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onClose();
  };

  return (
    <div css={styles.dialog} ref={ref}>
      <Button
        variant="ghost"
        size="small"
        icon={<ChevronPreviousIcon />}
        iconPosition="before"
        onClick={handleClose}
        data-testid="back-btn"
        edgeAlign="start"
      >
        {_t('Back')}
      </Button>

      <TextField
        fullWidth
        label={_t('Accessibility name')}
        supportText={_t('Add a name to this code block for learners using assistive technologies')}
        multiline={true}
        onChange={handleChange}
        value={name}
        characterLimit={MAX_LENGTH}
      />

      <Button size="small" onClick={handleClose} data-testid="done-btn">
        {_t('Done')}
      </Button>
    </div>
  );
};

export default CodeBlockNameDialog;
