import * as React from 'react';

import { pure } from 'recompose';

import SvgIcon from '../../SvgIcon';

let SvgCertificate = (props: $TSFixMe) => (
  <SvgIcon {...props} viewBox="0 0 44 44">
    <title>certificate</title>
    <defs>
      <rect x="2" y="8" width="44" height="27.5" />
      <mask
        maskContentUnits="userSpaceOnUse"
        maskUnits="objectBoundingBox"
        x="0"
        y="0"
        width="44"
        height="27.5"
        fill="white"
      />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-2.000000, -8.000000)">
        <polygon points="0 0 44 0 44 44 0 44" />
        <use stroke="#8C8C8C" mask="url(#mask-2)" strokeWidth="2" fill="#FFFFFF" />
        <g transform="translate(6.583333, 13.500000)" stroke="#8C8C8C" strokeLinejoin="round">
          <path d="M0.337719298,1.375 L11.3377193,1.375" />
          <path d="M0.337719298,5.95833333 L11.3377193,5.95833333" />
          <path d="M0.337719298,10.5416667 L11.3377193,10.5416667" />
          <path d="M0.337719298,15.125 L11.3377193,15.125" />
        </g>
        <g transform="translate(22.166667, 12.583333)">
          <polyline
            fill="#DDDDDD"
            points="13.5666667 16.8666667 13.5666667 27.8666667 9.16666667 24.2 4.76666667 27.8666667 4.76666667 16.8666667"
          />
          <polyline
            stroke="#8C8C8C"
            strokeLinejoin="round"
            points="14.0479167 17.1561404 14.0479167 27.8666667 9.71666667 24.2964912 5.38541667 27.8666667 5.38541667 17.1561404"
          />
          <path
            d="M19.1010417,10.0157895 C19.1010417,8.97686842 18.353901,8.11217193 17.3613229,7.91652632 C17.1851854,7.2853193 16.933251,6.68695789 16.6120167,6.13072456 C16.8134198,5.8058386 16.9354167,5.42668596 16.9354167,5.01754386 C16.9354167,3.83510175 15.9652167,2.8754386 14.7697917,2.8754386 C14.3561573,2.8754386 13.9728417,2.99611053 13.6443885,3.19532632 C13.0820479,2.8775807 12.4771167,2.62838246 11.8389792,2.45415789 C11.6411854,1.47235965 10.7669948,0.733333333 9.71666667,0.733333333 C8.66633854,0.733333333 7.79214792,1.47235965 7.59435417,2.45415789 C6.95621667,2.62838246 6.35128542,2.8775807 5.78894479,3.19532632 C5.46049167,2.99611053 5.07717604,2.8754386 4.66354167,2.8754386 C3.46811667,2.8754386 2.49791667,3.83510175 2.49791667,5.01754386 C2.49791667,5.42668596 2.61991354,5.8058386 2.82131667,6.13072456 C2.50008229,6.68695789 2.24814792,7.2853193 2.07201042,7.91652632 C1.07943229,8.11217193 0.332291667,8.97686842 0.332291667,10.0157895 C0.332291667,11.0547105 1.07943229,11.919407 2.07201042,12.1150526 C2.24814792,12.7462596 2.50008229,13.3446211 2.82131667,13.9008544 C2.61991354,14.2257404 2.49791667,14.604893 2.49791667,15.0140351 C2.49791667,16.1964772 3.46811667,17.1561404 4.66354167,17.1561404 C5.07717604,17.1561404 5.46049167,17.0354684 5.78894479,16.8362526 C6.35128542,17.1539982 6.95621667,17.4031965 7.59435417,17.5774211 C7.79214792,18.5592193 8.66633854,19.2982456 9.71666667,19.2982456 C10.7669948,19.2982456 11.6411854,18.5592193 11.8389792,17.5774211 C12.4771167,17.4031965 13.0820479,17.1539982 13.6443885,16.8362526 C13.9728417,17.0354684 14.3561573,17.1561404 14.7697917,17.1561404 C15.9652167,17.1561404 16.9354167,16.1964772 16.9354167,15.0140351 C16.9354167,14.604893 16.8134198,14.2257404 16.6120167,13.9008544 C16.933251,13.3446211 17.1851854,12.7462596 17.3613229,12.1150526 C18.353901,11.919407 19.1010417,11.0547105 19.1010417,10.0157895"
            fill="#FFFFFF"
          />
          <path
            d="M14.0479167,10.0157895 C14.0479167,12.3813877 12.1089604,14.3 9.71666667,14.3 C7.32509479,14.3 5.38541667,12.3813877 5.38541667,10.0157895 C5.38541667,7.64947719 7.32509479,5.73157895 9.71666667,5.73157895 C12.1089604,5.73157895 14.0479167,7.64947719 14.0479167,10.0157895"
            fill="#DDDDDD"
          />
          <path
            d="M19.1010417,10.0157895 C19.1010417,8.97686842 18.353901,8.11217193 17.3613229,7.91652632 C17.1851854,7.2853193 16.933251,6.68695789 16.6120167,6.13072456 C16.8134198,5.8058386 16.9354167,5.42668596 16.9354167,5.01754386 C16.9354167,3.83510175 15.9652167,2.8754386 14.7697917,2.8754386 C14.3561573,2.8754386 13.9728417,2.99611053 13.6443885,3.19532632 C13.0820479,2.8775807 12.4771167,2.62838246 11.8389792,2.45415789 C11.6411854,1.47235965 10.7669948,0.733333333 9.71666667,0.733333333 C8.66633854,0.733333333 7.79214792,1.47235965 7.59435417,2.45415789 C6.95621667,2.62838246 6.35128542,2.8775807 5.78894479,3.19532632 C5.46049167,2.99611053 5.07717604,2.8754386 4.66354167,2.8754386 C3.46811667,2.8754386 2.49791667,3.83510175 2.49791667,5.01754386 C2.49791667,5.42668596 2.61991354,5.8058386 2.82131667,6.13072456 C2.50008229,6.68695789 2.24814792,7.2853193 2.07201042,7.91652632 C1.07943229,8.11217193 0.332291667,8.97686842 0.332291667,10.0157895 C0.332291667,11.0547105 1.07943229,11.919407 2.07201042,12.1150526 C2.24814792,12.7462596 2.50008229,13.3446211 2.82131667,13.9008544 C2.61991354,14.2257404 2.49791667,14.604893 2.49791667,15.0140351 C2.49791667,16.1964772 3.46811667,17.1561404 4.66354167,17.1561404 C5.07717604,17.1561404 5.46049167,17.0354684 5.78894479,16.8362526 C6.35128542,17.1539982 6.95621667,17.4031965 7.59435417,17.5774211 C7.79214792,18.5592193 8.66633854,19.2982456 9.71666667,19.2982456 C10.7669948,19.2982456 11.6411854,18.5592193 11.8389792,17.5774211 C12.4771167,17.4031965 13.0820479,17.1539982 13.6443885,16.8362526 C13.9728417,17.0354684 14.3561573,17.1561404 14.7697917,17.1561404 C15.9652167,17.1561404 16.9354167,16.1964772 16.9354167,15.0140351 C16.9354167,14.604893 16.8134198,14.2257404 16.6120167,13.9008544 C16.933251,13.3446211 17.1851854,12.7462596 17.3613229,12.1150526 C18.353901,11.919407 19.1010417,11.0547105 19.1010417,10.0157895 L19.1010417,10.0157895 Z"
            stroke="#8C8C8C"
          />
          <path
            d="M14.0479167,10.0157895 C14.0479167,12.3813877 12.1089604,14.3 9.71666667,14.3 C7.32509479,14.3 5.38541667,12.3813877 5.38541667,10.0157895 C5.38541667,7.64947719 7.32509479,5.73157895 9.71666667,5.73157895 C12.1089604,5.73157895 14.0479167,7.64947719 14.0479167,10.0157895 L14.0479167,10.0157895 Z"
            stroke="#8C8C8C"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

// @ts-expect-error ts-migrate(2322) FIXME: Type 'ComponentType<any>' is not assignable to typ... Remove this comment to see the full error message
SvgCertificate = pure(SvgCertificate);
// @ts-expect-error ts-migrate(2339) FIXME: Property 'displayName' does not exist on type '(pr... Remove this comment to see the full error message
SvgCertificate.displayName = 'SvgCertificate';

export default SvgCertificate;
