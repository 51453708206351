import { isValidURL as isValidLinkUrl } from 'bundles/cml/editor/components/buttons/link/linkUtils';

export const VIDEO_ID_LENGTH = 11;
const PROTOCOL = 'https://';

// https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url
export const getVideoId = (url: string) => {
  const parsedURL = new URL(url);
  // If the URL doesn't have the v parameter, we fallback to regex which matches all cases here: https://regexr.com/3u0d4.
  return (
    parsedURL.searchParams.get('v') ||
    url.match(
      /(https?:\/\/)?(((m|www)\.)?(youtube(-nocookie)?|youtube.googleapis)\.com.*(v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i
    )?.[8]
  );
};

const getTimestamp = (timestamp: string) => timestamp.match(/^[0-5]?\d:[0-5]\d$/);

export const convertStartToString = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  const convertedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${minutes}:${convertedSeconds}`;
};

export const isValidTimestamp = (timestamp: string) => {
  if (!timestamp) {
    return true;
  }
  return !!getTimestamp(timestamp);
};

export const convertStartToNumber = (timestamp: string) => {
  if (!isValidTimestamp(timestamp)) {
    return 0;
  }
  const [minutes, seconds] = timestamp.split(/:/);
  return parseInt(minutes, 10) * 60 + parseInt(seconds, 10) || 0;
};

export const isValidYoutubeUrl = (url: string) => {
  if (!url.startsWith(PROTOCOL)) {
    return false;
  }

  const videoId = getVideoId(url);
  // A youtube videoId is currently 11 characters long, but ids will get longer.
  if (!videoId || videoId.length !== VIDEO_ID_LENGTH) {
    return false;
  }

  return true;
};

export const isValidURL = isValidLinkUrl;

export const hasSimpleConfigEditor = (url: string, videoId: string) => !!(url || videoId);
