/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Typography, Typography2 } from '@coursera/cds-core';
import { breakPoint } from '@coursera/coursera-ui';

import PartnerContainer from 'bundles/coursera-ui/components/rich/PartnerContainer';
import type { PropsForInjectedComponent } from 'bundles/enterprise-legacy-xdp/hocs/withMiniModal';
import withMiniModal from 'bundles/enterprise-legacy-xdp/hocs/withMiniModal';
import TrackedDiv from 'bundles/page/components/TrackedDiv';

export type PartnerWithName = {
  name: string;
};

export type Props = {
  courseId: string;
  courseName: string;
  /**
   * @deprecated Use `partners` instead.
   */
  partnerIds?: Array<string> | Array<number>;
  partners?: Array<PartnerWithName>;
  isPartOfS12n?: boolean;
  isS12n?: boolean;
  isEnrolled?: boolean;
  style?: React.CSSProperties;
} & Partial<PropsForInjectedComponent>;

const CourseAndPartnerName = ({
  courseId,
  courseName,
  partners,
  partnerIds,
  isPartOfS12n,
  isS12n,
  isEnrolled,
  style,
  // Pass optional withMiniModal inline to avoid double interactive divs which confuse the screen reader.
  onClick,
  onKeyDown,
  role,
  tabIndex,
}: Props): JSX.Element => {
  const styles = {
    container: css`
      margin-bottom: 3 rem;

      @media (max-width: ${breakPoint.md}px) {
        margin-bottom: -1rem;
      }
    `,
  };
  const firstPartnerName = partners?.[0]?.name;
  const firstPartnerId = partnerIds?.[0];
  return (
    <TrackedDiv
      trackingName="course_thumbnail"
      data={{ courseId }}
      trackClicks={true}
      className="CourseAndPartnerName"
      style={style}
      onClick={onClick}
      // @ts-expect-error Not sure what this is doing but removing Aphrodite flags it as an error
      onKeyDown={onKeyDown}
      role={role}
      tabIndex={tabIndex}
      css={isS12n && isEnrolled ? styles.container : undefined}
      id={courseId}
    >
      <Typography
        component="div"
        variant={isPartOfS12n && !isS12n ? 'h3semibold' : 'h2'}
        className={isPartOfS12n ? 'm-b-1 m-r-1' : 'm-b-0 m-r-1'}
      >
        {courseName}
      </Typography>
      {!isPartOfS12n && !!(firstPartnerName || firstPartnerId) && (
        <Typography2 component="span" className="d-block m-b-1" variant="bodySecondary" color="supportText">
          {firstPartnerName}
          {/* if !firstPartnerName, then firstPartnerId must be truthy, because of the `!!(firstPartnerName || firstPartnerId)` check above */}
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          {!firstPartnerName && <PartnerContainer id={firstPartnerId!} />}
        </Typography2>
      )}
    </TrackedDiv>
  );
};

export default CourseAndPartnerName;
export const CourseAndPartnerNameWithModal = withMiniModal<Props>()(CourseAndPartnerName);
export type PropsForWithModal = typeof CourseAndPartnerNameWithModal extends React.ComponentType<infer P> ? P : never;
