import _ from 'lodash';

import courseraPlusExperiments from 'bundles/epic/clients/courseraPlus';
import type {
  SpecializationProgressAndCourses_ProgressFragment,
  SpecializationProgressAndCourses_SpecializationFragment,
} from 'bundles/program-common/queries/__generated__/SpecializationProgressAndCourses';

// TODO(Audrey):remove after https://coursera.atlassian.net/browse/UP-7587
export function getNextCourseSlug({
  s12n,
  s12nProgress,
}: {
  s12n: SpecializationProgressAndCourses_SpecializationFragment;
  s12nProgress?: SpecializationProgressAndCourses_ProgressFragment;
}): string {
  const allCourses = s12n.courses;
  if (allCourses.length === 0) {
    throw new Error('Specialization must have at least one course.');
  }
  const firstCourse = allCourses[0];
  const coursesObj = _.keyBy(allCourses, 'id');
  const activeCourseId = s12nProgress?.lastActiveCourseId;

  if (!activeCourseId || !coursesObj[activeCourseId]) {
    return coursesObj[firstCourse.id].slug;
  }

  return coursesObj[activeCourseId].slug;
}

export function getCourseHomeLinkBySlug(slug: string) {
  return `/learn/${slug}/home/welcome`;
}

export const previewCourseUpsellVariant = (): number => courseraPlusExperiments.preview('courseUpsellVariant');
