import * as React from 'react';
import { useState } from 'react';

import logger from 'js/app/loggerSingleton';
import { FormattedHTMLMessage, FormattedMessage } from 'js/lib/coursera.react-intl';
import user from 'js/lib/user';

import { API_ERROR, API_SUCCESS } from 'bundles/coursera-ui/constants/apiNotificationConstants';
import type { ApiState } from 'bundles/coursera-ui/constants/apiNotificationConstants';
import EnrollmentReasonCode from 'bundles/enroll-course/common/EnrollmentReasonCode';
import CourseEnrollmentConfirmation from 'bundles/enroll-course/components/CourseEnrollmentConfirmation';
import { enrollInCourseForFreePromise } from 'bundles/enroll-course/lib/apiClient';
import { checkSessionsV2Epic } from 'bundles/enroll-course/lib/sessionsV2ExperimentUtils';
import { useCourseEnrollModalData } from 'bundles/enroll/data/usePageData';
import S12NUpgradeButton from 'bundles/ondemand/components/s12n-upgrade/S12NUpgradeButton';

import _t from 'i18n!nls/enroll-course';

type PropsFromCaller = {
  reasonCode: keyof typeof EnrollmentReasonCode;
  s12nId?: string;
};

const EnrollmentReasonMessage: React.FC<PropsFromCaller> = ({ reasonCode, s12nId }) => {
  const { course } = useCourseEnrollModalData();

  const [didEnroll, setDidEnroll] = useState(false);
  const [upgradeS12nApiStatus, setUpgradeS12nApiStatus] = useState<ApiState | undefined>();

  if (!reasonCode) {
    return null;
  }

  if (didEnroll) {
    return <CourseEnrollmentConfirmation courseId={course.id} />;
  }

  const handleUpgradeS12nSuccess = () => {
    setUpgradeS12nApiStatus(API_SUCCESS);
  };

  const handleUpgradeS12nError = () => {
    setUpgradeS12nApiStatus(API_ERROR);
  };

  const renderAlreadyEnrolled = () => {
    return (
      <div className="align-horizontal-center">
        <p>
          <FormattedMessage message={_t('You are already enrolled in {courseName}.')} courseName={course.name} />
        </p>
        <p>
          {/* eslint-disable-next-line no-restricted-syntax */}
          <a href={course.homeLink}>{_t('Go to course home')}</a>
        </p>
      </div>
    );
  };

  const renderAlreadyPurchased = () => {
    const enrollInAlreadyPaidCourse = () => {
      checkSessionsV2Epic(course.id).then(() => {
        enrollInCourseForFreePromise(course.id).then(() => setDidEnroll(true));
      });
    };

    return (
      <div className="align-horizontal-center">
        {/* eslint-disable-next-line no-restricted-syntax */}
        <p>{_t('Please click the button below to access course materials.')}</p>
        <br />
        <p>
          <button type="button" className="primary" onClick={enrollInAlreadyPaidCourse}>
            {/* eslint-disable-next-line no-restricted-syntax */}
            {_t('Join Course')}
          </button>
        </p>
      </div>
    );
  };

  const renderCapstoneLocked = () => {
    return (
      <div className="align-horizontal-center">
        {_t('You can only access this Capstone after completing the courses in the Specialization')}
      </div>
    );
  };

  const renderRegionBlocked = () => {
    return (
      <div className="align-horizontal-center">
        <FormattedHTMLMessage
          message={_t(
            `We apologize for the inconvenience. This course is not available in your region.
          Click <a href="{policyUrl}">here</a> for more information.`
          )}
          policyUrl="https://learner.coursera.help/hc/articles/208280116-International-restrictions"
        />
      </div>
    );
  };

  const renderNoAvailableSession = () => {
    return (
      <p className="align-horizontal-center">
        {/* eslint-disable-next-line no-restricted-syntax */}
        {_t('There is no upcoming session for this course. Please check back later.')}
      </p>
    );
  };

  const renderSpecializationUpgradeRequired = () => {
    return (
      <div className="s12n-upgrade-required">
        {upgradeS12nApiStatus !== API_SUCCESS && (
          <p className="font-sm m-b-2 align-horizontal-center">
            {_t(
              `You are currently enrolled in an old version of the specialization.
              Upgrade the specialization to continue with your purchase.`
            )}
          </p>
        )}
        {upgradeS12nApiStatus === API_SUCCESS && (
          <div className="font-sm m-b-2 align-horizontal-center">
            <p>{_t('You have been successfully upgraded to the new version of this specialization!')}</p>
            <p>
              {_t(
                `The page will automatically reload with your upgraded version,
                so you can continue with the enrollment.`
              )}
            </p>
          </div>
        )}
        {s12nId && (
          <div className="align-horizontal-center">
            <S12NUpgradeButton
              onSuccess={handleUpgradeS12nSuccess}
              onError={handleUpgradeS12nError}
              reloadPageOnSuccess={true}
              s12nId={s12nId}
              userId={user.get().id}
            />
          </div>
        )}
      </div>
    );
  };

  const renderUnknownReasonCode = () => {
    return (
      <div className="align-horizontal-center">
        <p>
          <FormattedMessage
            message={_t('Sorry, we could not find any enrollment option for {courseName} at this time.')}
            courseName={course.name}
          />
        </p>
        <p>
          {/* eslint-disable-next-line no-restricted-syntax */}
          <a href={course.link}>{_t('Go to course')}</a>
        </p>
      </div>
    );
  };

  // TODO(jnam) remove duplicate definitions between here and
  // bundles/naptimejs/resources/enrollmentAvailableChoices.v1.js
  switch (reasonCode) {
    case EnrollmentReasonCode.ENROLLED:
      return renderAlreadyEnrolled();
    case EnrollmentReasonCode.PURCHASED_SINGLE_COURSE:
    case EnrollmentReasonCode.SPECIALIZATION_BULK_PAID:
    case EnrollmentReasonCode.SPECIALIZATION_SUBSCRIBED:
      return renderAlreadyPurchased();
    case EnrollmentReasonCode.CAPSTONE_ACCESS_LOCKED:
      return renderCapstoneLocked();
    case EnrollmentReasonCode.REGION_BLOCKED:
      return renderRegionBlocked();
    case EnrollmentReasonCode.NO_AVAILABLE_SESSION:
      return renderNoAvailableSession();
    case EnrollmentReasonCode.SPECIALIZATION_UPGRADE_REQUIRED:
      return renderSpecializationUpgradeRequired();
    default:
      logger.error(`Unrecognized enrollment reason code: ${reasonCode}`);
      return renderUnknownReasonCode();
  }
};

export default EnrollmentReasonMessage;
