import * as React from 'react';
import { useCallback } from 'react';

import { useSlate, useSlateStatic } from 'slate-react';

import { SubscriptIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import { isTypeDisabled } from 'bundles/cml/editor/utils/elementUtils';
import { isMarkActive, toggleMark } from 'bundles/cml/editor/utils/markUtils';
import { getToolBarShortcutKey } from 'bundles/cml/editor/utils/toolbarUtils';
import { MARKS } from 'bundles/cml/shared/constants';

import _t from 'i18n!nls/cml';

const SubscriptButton: React.FC = () => {
  const editor = useSlate();
  const staticEditor = useSlateStatic();
  const disabled = isTypeDisabled(editor, MARKS.SUBSCRIPT);
  const active = isMarkActive(editor, MARKS.SUBSCRIPT);

  const handleClick = useCallback(() => toggleMark(staticEditor, MARKS.SUBSCRIPT), [staticEditor]);

  const shortcutKey = getToolBarShortcutKey(',');
  return (
    <Button
      onClick={handleClick}
      disabled={disabled}
      active={active}
      className="rc-SubscriptButton"
      title={_t('Subscript #{shortcutKey}', { shortcutKey })}
      aria-label={_t('Superscript #{shortcutKey}', { shortcutKey: getToolBarShortcutKey('comma') })}
      type={TOOLBAR_BUTTON_TYPES.formatting}
    >
      <SubscriptIcon size="small" />
    </Button>
  );
};

export default SubscriptButton;
