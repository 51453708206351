import { hashToHex } from 'js/lib/hash';

import sessionStoreEx from 'bundles/common/utils/sessionStorageEx';

import { ACTIVE_COURSERA_COACH_SESSION_STORAGE_KEY } from '../constants';

export function getHashKeyForString(promptText?: string): string {
  // it is expected we will get a string, but incase of failure, we will return a timestamp
  // providing a uniqe value here will ensure that we do not collide with chat session storage keys
  // the probability of creating a collision with timestamps is extremely low, but not impossible. this is a fallback in case of failure.
  if (!promptText) {
    return `${Date.now()}`;
  }
  try {
    return hashToHex(promptText);
  } catch (e) {
    return `${Date.now()}`;
  }
}

export function createChatSessionStorageKey(uniqueDataString?: string) {
  return `${ACTIVE_COURSERA_COACH_SESSION_STORAGE_KEY}-${getHashKeyForString(uniqueDataString)}`;
}

export const persistentChatSessionId = {
  get: (key: string) => {
    const chatSessionIdFromMemory = sessionStoreEx.getItem<string>(key, (value) => value, '');
    const sanitizedChatSessionId =
      chatSessionIdFromMemory && chatSessionIdFromMemory !== 'undefined' ? chatSessionIdFromMemory : undefined;
    return sanitizedChatSessionId;
  },
  set: (key: string, sessionId: string) => {
    const chatSessionId = sessionId;
    sessionStoreEx.setItem(key, chatSessionId, (value) => value);
  },
  clear: (key: string) => {
    sessionStoreEx.removeItem(key);
  },
};
