/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect } from 'react';

import { get as getUser } from 'js/lib/user';

import { ChatEmptyState, CoachChat } from 'bundles/ai-coach-platform';
import { firstNameFromFullName } from 'bundles/ai-course-coach/utils/nameParser';
import leeCoachEpicClient from 'bundles/epic/clients/leeCoach';
import CoachChatContainer from 'bundles/unified-common/components/CoachChatContainer';
import { useCoachState } from 'bundles/unified-common/providers/CoachStateProvider';
import { showPathwaysUserBased } from 'bundles/unified-common/utils/showPathways';

import _t from 'i18n!nls/unified-common';

type CoachPathwaysChatComponentType = {
  headerRef: React.RefObject<HTMLSpanElement>;
  footerRef: React.RefObject<HTMLDivElement>;
};

// the purpose of this component is to reduce duplication of code
// - coach pathways epic integration
// - handling coach close actions to update context for coach
// - broad overriding of styles to remove padding from CoachChat component
const CoachComponent = ({ headerRef, footerRef }: CoachPathwaysChatComponentType) => {
  const { coachOpen, setCoachOpen, contextConfig } = useCoachState();
  const [pathwaysEnabled, setPathwaysEnabled] = React.useState(false);

  const { fullName: userName } = getUser();
  const userFirstName = userName ? firstNameFromFullName(userName) : null;
  const displayTitle = userFirstName ? _t('Hi, #{userFirstName}!', { userFirstName }) : _t('Hi, learner!');
  const displaySubtitle = _t('How can I help?');

  useEffect(() => {
    const enabled = showPathwaysUserBased();
    setPathwaysEnabled(enabled);
  }, []);

  return (
    <React.Fragment>
      {pathwaysEnabled && coachOpen && (
        <CoachChatContainer headerRef={headerRef} footerRef={footerRef}>
          <CoachChat
            additionalStyles={css`
              padding: 0;
            `}
            showSettings={leeCoachEpicClient.get('showCoachSettings')}
            mode={contextConfig.config.coachModeId}
            contextConfig={contextConfig}
            onClose={() => setCoachOpen(false)}
            storeSessionId={false}
            emptyState={<ChatEmptyState title={displayTitle} subtitle={displaySubtitle} />}
          />
        </CoachChatContainer>
      )}
    </React.Fragment>
  );
};

export default CoachComponent;
