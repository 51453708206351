/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useMemo, useState } from 'react';

import { compose } from 'recompose';

import { InlineNotification, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

import FinancialAidApplicationsLimitModal from 'bundles/payments/components/finaid-2021/FinancialAidApplicationsLimitModal';
import { MAX_PENDING_FINAID_APPLICATIONS as max } from 'bundles/payments/components/finaid-2021/constants/limits';
import type { WithFinancialAidApplicationsByUserProps } from 'bundles/payments/components/finaid-2021/enhancers/withFinancialAidApplications';
import { withFinancialAidApplicationsByUser } from 'bundles/payments/components/finaid-2021/enhancers/withFinancialAidApplications';

import _t from 'i18n!nls/finaid';

export type Props = WithFinancialAidApplicationsByUserProps & PropsFromCaller;

type PropsFromCaller = {
  readOnly?: boolean;
};

const useStyles = (theme: Theme) => ({
  notification: css({
    width: '100%',
    cursor: 'pointer',
    border: '1px solid',
    '&.warning-severity': {
      borderColor: 'var(--cds-color-yellow-700)',
    },
    '&.information-severity': {
      borderColor: 'var(--cds-color-blue-900)',
    },
  }),
});

/**
 * Renders a notification displaying the count of "pending" financial aid applications for the current user
 */
export const FinancialAidApplicationsCountNotification: React.FC<Props> = ({
  financialAidApplicationsByUser,
  readOnly = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const theme = useTheme();
  const styles = useStyles(theme);

  const count = useMemo(() => {
    if (Array.isArray(financialAidApplicationsByUser)) {
      return readOnly ? financialAidApplicationsByUser.length : financialAidApplicationsByUser.length + 1;
    }
    return 1;
  }, [financialAidApplicationsByUser, readOnly]);

  if (!count) return null;

  // sorted by approvalDate ascending
  const earliestFinancialAidApplication = financialAidApplicationsByUser?.sort(
    (a, b) => (a?.approvalDate ?? 0) - (b?.approvalDate ?? 0)
  )?.[0];

  return (
    <>
      <InlineNotification
        severity={count >= max ? 'warning' : 'information'}
        className={count >= max ? 'warning-severity' : 'information-severity'}
        role="button"
        tabIndex={0}
        onClick={() => {
          setIsModalOpen(true);
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            setIsModalOpen(true);
          }
        }}
        css={styles.notification}
      >
        {_t('#{count}/#{max} open applications.', { count, max })}
      </InlineNotification>

      {isModalOpen && (
        <FinancialAidApplicationsLimitModal
          approvalDate={earliestFinancialAidApplication?.approvalDate}
          applicationsCount={count - 1}
          applicationsMax={max}
          handleClose={() => {
            setIsModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export default compose<Props, PropsFromCaller>(withFinancialAidApplicationsByUser({ limit: max, state: 'PENDING' }))(
  FinancialAidApplicationsCountNotification
);
