import config from 'js/app/config';

import GrowthDiscoveryEpicClient from 'bundles/epic/clients/GrowthDiscovery';

// TODO(htran) remove after UCI APM Contentful migration is done in GNG-1259
export const UCI_APM_OBJECT_ID = 's12n~kLWnFWsyEeeVdhKUpvOPZg';
export const UCI_APM_IMAGE_URL = `${config.url.resource_assets}search/assets/uci_apm.png`;

// TODO(htran) remove after UCI APM Contentful migration is done in GNG-1259
export const getProductCardImageUrl = (
  id?: string | null,
  imageUrl?: string | null,
  partnerLogos?: string[] | null
): string => {
  if (!id && !imageUrl && !partnerLogos) {
    return '';
  } else if (id && id === UCI_APM_OBJECT_ID) {
    return UCI_APM_IMAGE_URL;
  } else {
    const productImage = imageUrl || '';

    // If image is a gif it is detrimental to performance, serve the partner logo instead.
    if (/\.gif/.test(productImage)) {
      return partnerLogos ? partnerLogos[0] : productImage;
    } else {
      return productImage;
    }
  }
};

const LIST_OF_GENERATED_IMAGES_SLUGS = [
  'advanced-data-science-ibm',
  'ai-engineer',
  'ai-foundations-for-everyone',
  'applied-artifical-intelligence-ibm-watson-ai',
  'applied-data-science',
  'data-science-fundamentals-python-sql',
  'data-science-methodology',
  'data-warehouse-engineering',
  'ds',
  'key-technologies-for-business',
  'ibm-ai-foundations-for-business',
  'ibm-cybersecurity-analyst',
  'ibm-data-engineer',
  'ibm-frontend-developer',
  'ibm-full-stack-cloud-developer',
  'ibm-machine-learning',
  'ibm-z-mainframe',
  'introduction-data-science',
  'it-fundamentals-cybersecurity',
  'linux-private-cloud-administration-power-systems',
  'security-analyst-fundamentals',
];

export const getGeneratedImageUrl = (slug?: string) => {
  if (!slug) {
    return undefined;
  }

  if (LIST_OF_GENERATED_IMAGES_SLUGS.includes(slug)) {
    const showGeneratedImage = GrowthDiscoveryEpicClient.get('showGeneratedProductCardImage');

    return showGeneratedImage ? `${config.url.resource_assets}collections/product-images/${slug}.jpeg` : undefined;
  }

  return undefined;
};

export default getProductCardImageUrl;
