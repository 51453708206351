import * as React from 'react';

import type { CmlContent } from 'bundles/cml';
import BranchSwitchInfo from 'bundles/course-sessions/components/BranchSwitchInfo';
import Modal from 'bundles/phoenix/components/Modal';

import _t from 'i18n!nls/course-sessions';

type Props = {
  handleConfirm: () => void;
  handleClose: () => void;
  changesDescription?: CmlContent;
};

const ConfirmVersionChangesModal = (props: Props) => {
  const { handleConfirm, handleClose, changesDescription } = props;

  return (
    <Modal handleClose={handleClose} modalName={_t('Confirm Version Changes')}>
      <div className="vertical-box align-items-absolute-center" style={{ height: '300px' }}>
        <BranchSwitchInfo handleConfirm={handleConfirm} changesDescription={changesDescription} />
      </div>
    </Modal>
  );
};

export default ConfirmVersionChangesModal;
