/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react';

import * as React from 'react';

import { TrackProgressCTA } from 'bundles/degree-home/components/v3-dpm/components/TrackProgressCTA/TrackProgressLearnerHome';
import type { AddedProps as PropsFromNaptimeContainer } from 'bundles/naptimejs';
import { useNaptime } from 'bundles/naptimejs';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import ProgramCurriculumProductsV1 from 'bundles/naptimejs/resources/programCurriculumProducts.v1';
import type ThirdPartyOrganizationsV1 from 'bundles/naptimejs/resources/thirdPartyOrganizations.v1';
import SocialShareCardForProgramType from 'bundles/program-home/components/SocialShareCardForProgramType';
import CatalogSection from 'bundles/program-home/components/single-program/CatalogSection';
import EnterpriseContinueLearningModule from 'bundles/program-home/components/single-program/EnterpriseContinueLearningModule';
import MyRecentActivity from 'bundles/program-home/components/single-program/MyRecentActivity';

export type PropsFromCaller = {
  programId: string | undefined;
  thirdPartyOrganization: ThirdPartyOrganizationsV1;
  programSlug: string;
  userId: number;
  infoCard: React.ReactNode;
  enableSocialShare: boolean;
  isAllowlistedC4er: boolean;
  isAuthenticatedUser: boolean;
  isCareerAcademyFreemium?: boolean;
  viewMyCoursesUrl: string;
};

type PropsFromNaptime = PropsFromNaptimeContainer & {
  enrolledCourses?: ProgramCurriculumProductsV1[];
};

export const InitialActivitySection = ({
  programId,
  thirdPartyOrganization,
  programSlug,
  userId,
  infoCard,
  enableSocialShare,
  isAllowlistedC4er,
  isAuthenticatedUser,
  isCareerAcademyFreemium,
  viewMyCoursesUrl,
}: PropsFromCaller) => {
  const {
    data: { enrolledCourses },
  } = useNaptime<PropsFromNaptime>(
    () => ({
      enrolledCourses: ProgramCurriculumProductsV1.finder('latestEnrolledCourseActivities', {
        params: { programId, limit: 1 },
        required: false,
      }),
    }),
    [programId]
  );

  const inProgressCourse = enrolledCourses
    ? enrolledCourses.find((course: ProgramCurriculumProductsV1) => !course.isCompleted)
    : null;

  const socialShareWillNotRender = !enableSocialShare || !isAllowlistedC4er;
  const continueLearningModuleWillNotRender =
    !programId || !inProgressCourse || !isAuthenticatedUser || isCareerAcademyFreemium;

  if (!infoCard && socialShareWillNotRender && continueLearningModuleWillNotRender) {
    return null;
  }

  return (
    <>
      <CatalogSection>
        {infoCard}
        {enableSocialShare && <SocialShareCardForProgramType isAllowlistedC4er={isAllowlistedC4er} />}
      </CatalogSection>
      {isAuthenticatedUser && !isCareerAcademyFreemium && (
        <CatalogSection>
          <EnterpriseContinueLearningModule
            programId={programId}
            programSlug={programSlug}
            userId={userId}
            thirdPartyOrganization={thirdPartyOrganization}
            inProgressCourse={inProgressCourse}
          />
        </CatalogSection>
      )}
    </>
  );
};

export default InitialActivitySection;
