import * as React from 'react';

import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';

import type {
  AiCoachPlatform_QueryCoachUserSettingsForUserQuery as QueryCoachUserSettingsForUser,
  AiCoachPlatform_QueryCoachUserSettingsForUserQueryVariables as QueryCoachUserSettingsForUserQueryVariables,
} from 'bundles/ai-coach-platform/queries/__generated__/aiCoachUserSettingsByUser';
import aiCoachUserSettingsByUserQuery from 'bundles/ai-coach-platform/queries/aiCoachUserSettingsByUser.graphql';
import { captureMessageInSentry } from 'bundles/ai-coach-platform/utils/sentry';

type ProfilePreference = {
  enableProfile: boolean;
  enableWorkHistory: boolean;
  enableEducationHistory: boolean;
  enableLocation: boolean;
};

const defaultPreference: ProfilePreference = {
  enableProfile: true,
  enableEducationHistory: true,
  enableWorkHistory: true,
  enableLocation: true,
};

const useCoachSettings = ({
  settingsEnabled,
}: {
  settingsEnabled: boolean;
}): {
  loading: boolean;
  error?: ApolloError;
  data: ProfilePreference;
} => {
  const {
    data: queryData,
    loading,
    error,
  } = useQuery<QueryCoachUserSettingsForUser, QueryCoachUserSettingsForUserQueryVariables>(
    aiCoachUserSettingsByUserQuery,
    {
      variables: {},
      context: { clientName: 'gatewayGql' },
      fetchPolicy: 'network-only',
      skip: !settingsEnabled,
    }
  );

  const profilePreference = queryData?.AiCoach?.queryCoachUserSettingsForUser?.profilePreference;

  if (error) {
    captureMessageInSentry({ error, source: 'queryCoachUserSettingsForUser' });
  }

  return { data: profilePreference ?? defaultPreference, loading, error };
};

export default useCoachSettings;
