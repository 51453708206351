import type { FunctionComponent } from 'react';
import * as React from 'react';

import { compose, withProps } from 'recompose';

import type { InjectedRouter } from 'js/lib/connectToRouter';

import type { UserEmail } from '@coursera/grpc-types-useremails/coursera/proto/useremails/v1/email_address';

import ProgramHomeDomainSubdomainContainer from 'bundles/enterprise-collections/components/ProgramHomeDomainSubdomainContainer';
import { enterpriseDomainsIndexConfig } from 'bundles/enterprise-learner-search/components/search/enterpriseIndexConfig';
import { getProductPath, launchMiniModal } from 'bundles/enterprise-legacy-xdp/hocs/withMiniModal';
import C4CExperiments from 'bundles/epic/clients/C4C';
import CareerAcademyExperiments from 'bundles/epic/clients/CareerAcademy';
import type { InjectedNaptime } from 'bundles/naptimejs';
import type EnterpriseProgramsV1 from 'bundles/naptimejs/resources/enterprisePrograms.v1';
import type ProgramBrowsingExperienceV1 from 'bundles/naptimejs/resources/programBrowsingExperiences.v1';
import type ProgramMembershipsV2 from 'bundles/naptimejs/resources/programMemberships.v2';
import type ThirdPartyOrganizationsV1 from 'bundles/naptimejs/resources/thirdPartyOrganizations.v1';
import { IdTypes } from 'bundles/product-features';
import type { ProductCardCourseFragmentFragment as Course } from 'bundles/program-common/queries/__generated__/ProductCoursesQuery';
import type { Product, ProductType } from 'bundles/program-common/types/programCommon';
import type {
  PropsFromRefetchUserSkillProfileStates,
  PropsFromSaveSkillSetToggle,
} from 'bundles/program-home/components/ProgramHomeApp';
import ProgramHomeWrapper from 'bundles/program-home/components/ProgramHomeWrapper';
import type { TargetSkillProfileUserStatesQuery_TargetSkillProfileUserStatesV1Resource_byUserAndProgram_elements as UserSkillProfileStateType } from 'bundles/program-home/components/__generated__/TargetSkillProfileUserStatesQuery';
import EnterpriseBanner from 'bundles/program-home/components/enterprise-home/EnterpriseBanner';
import EnterpriseHomeAccessErrorPage from 'bundles/program-home/components/enterprise-home/EnterpriseHomeAccessErrorPage';
import ProgramHomeModalManager from 'bundles/program-home/components/modals/ProgramHomeModalManager';
import SingleProgramTabs, { TabName } from 'bundles/program-home/components/single-program/SingleProgramTabs';
import type { TabName as TabNameType } from 'bundles/program-home/components/single-program/SingleProgramTabs';
import type { PropsFromWithCatalogCollectionProps } from 'bundles/program-home/components/single-program/withCatalogCollectionProps';
import { withCatalogCollectionProps } from 'bundles/program-home/components/single-program/withCatalogCollectionProps';
import { withProductFeatures } from 'bundles/program-home/productFeatureFlags';
import SearchProvider from 'bundles/search-common/providers/SearchProvider';
import { getProgramCareerAcademyVariantQueryGraphQl } from 'bundles/unified-career-academy/queries/enterpriseQueries';
import type { PropCareerAcademyVariant } from 'bundles/unified-career-academy/utils/helpers';
import { isCareerAcademyFreemiumEnabled, isCareerAcademyV2Enabled } from 'bundles/unified-career-academy/utils/helpers';

import 'css!./__styles__/SingleProgramPage';

type PropsFromProductFeatures = {
  enablePersonalizedRecommendationsForProgram: boolean;
};
type QueryParams = {
  domainSlug?: string;
  subdomainSlug?: string;
};

export type PropsFromCaller = {
  programId: string;
  program: EnterpriseProgramsV1;
  thirdPartyOrganizationId: string;
  thirdPartyOrganization: ThirdPartyOrganizationsV1;
  userId: number;
  programMembership?: ProgramMembershipsV2;
  router: InjectedRouter;
  isUpswell: boolean;
  isC4cv: boolean;
  isNonBlacklistedC4cv: boolean;
  isAllowlistedC4er: boolean;
  isC4C?: boolean;
  isGwGProgram?: boolean;
  isWESContract: boolean;
  showNoCertificateNotification?: boolean;
  hasFreemiumTag?: boolean;
  isProgramMember: boolean;
  isProgramAdmin: boolean;
  reloadProgramMembership: () => void;
  userEmails?: UserEmail[];
  isInLoginMode: boolean;
  isAuthenticatedUser: boolean;
  params?: QueryParams;
  programSlug: string;
  naptime: InjectedNaptime;
  refetchUserSkillProfileStates?: () => void;
  userSkillProfileStates?: UserSkillProfileStateType[];
  userSkillProfileStatesLoading?: boolean;
  browsingExperienceType?: ProgramBrowsingExperienceV1;
  isBrowseOnlyProgram: boolean;
  shouldShowSponsoredByMessage: boolean;
  shouldShowEulaBanner: boolean;
  shouldHideCourseraRecommendations: boolean;
  shouldUseJoinTrackingVerbiage: boolean;
  programCollectionSectionDescription: boolean;
  programRecommendationsAvailableCoursesCopy: boolean;
  enableAcademicDisciplines?: boolean;
  allowOrgForCurriculumBuilder: boolean;
  allowOrgForSpecializationConfiguration?: boolean;
  autoEnrolledCoursesWithData?: Course[];
  forceEnableProgramSkillSetFilters: boolean | null;
  isCareerAcademyLimitedCatalogProgram?: boolean;
  enableEnterpriseCustomBadges: boolean;
  enableSkillsInSearchAndBrowse: boolean;
  isLevelSetsEnabled: boolean;
  enableSkillsDashboard: boolean;
  enableRenderDomainsMenu: boolean;
  shouldRenderAccessErrorPage: boolean;
  showSkillSetRoleFilter: boolean;
  shouldShowShortFormContent: boolean;
  enablePersonalizedRecommendationsForOrg: boolean;
};

type PropsFromWithProps = {
  tabNames: TabNameType[];
  isCareerAcademy: boolean;
  enableCareerAcademyTabToggle: boolean;
  isCareerAcademyFreemium: boolean;
  isMyLearningTabEnabled: boolean;
  hasEnabledPersonalizedRecommendations: boolean;
  showPersonalizedRecommendations: boolean;
};

export type Props = PropsFromCaller &
  PropsFromSaveSkillSetToggle &
  PropsFromRefetchUserSkillProfileStates &
  PropsFromWithCatalogCollectionProps &
  PropsFromWithProps;

export const SingleProgramPage: FunctionComponent<Props> = ({
  programId,
  program,
  thirdPartyOrganizationId,
  thirdPartyOrganization,
  userId,
  router,
  isProgramAdmin,
  programMembership,
  reloadProgramMembership,
  userEmails,
  isUpswell,
  isC4cv,
  isC4C,
  isGwGProgram,
  isNonBlacklistedC4cv,
  isAllowlistedC4er,
  hasFreemiumTag,
  isInLoginMode,
  showNoCertificateNotification,
  isAuthenticatedUser,
  isProgramMember,
  params,
  programSlug,
  isWESContract,
  naptime,
  userSkillProfileStates,
  userSkillProfileStatesLoading,
  onSkillSetSaveToggle,
  shouldRefetchUserSkillProfileStates,
  setShouldRefetchUserSkillProfileStates,
  refetchUserSkillProfileStates,
  browsingExperienceType,
  isBrowseOnlyProgram,
  shouldShowSponsoredByMessage,
  shouldShowEulaBanner,
  shouldHideCourseraRecommendations,
  shouldUseJoinTrackingVerbiage,
  programCollectionSectionDescription,
  programRecommendationsAvailableCoursesCopy,
  enableAcademicDisciplines,
  allowOrgForCurriculumBuilder,
  allowOrgForSpecializationConfiguration,
  autoEnrolledCoursesWithData,
  forceEnableProgramSkillSetFilters,
  isCareerAcademyLimitedCatalogProgram,
  enableEnterpriseCustomBadges,
  enableSkillsInSearchAndBrowse,
  isLevelSetsEnabled,
  enableSkillsDashboard,
  enableRenderDomainsMenu,
  shouldRenderAccessErrorPage,
  showSkillSetRoleFilter,
  shouldShowShortFormContent,
  enablePersonalizedRecommendationsForOrg,
  tabNames,
  isCareerAcademy,
  enableCareerAcademyTabToggle,
  isCareerAcademyFreemium,
  isMyLearningTabEnabled,
  hasEnabledPersonalizedRecommendations,
  showPersonalizedRecommendations,
  hasTSPs,
  hasCuratedCollections,
  hasBrowseCollections,
  catalogCollectionCount,
  catalogCollectionLength,
  catalogCollectionShouldLoadMore,
  isInDeepBrowseMode,
}): JSX.Element => {
  function onProductCardClick(product: Product, productType: ProductType, collectionId?: string | null) {
    if (productType === 'clip') {
      window.open(`/videos/${product.slug}?program=${programSlug}`, '_blank');
    } else {
      launchMiniModal(router, product.id, productType === 'course', collectionId || '', undefined, undefined, {
        slug: product.slug,
        productPagePath: getProductPath(product),
      });
    }
  }
  const domainSlug = params && params.domainSlug;
  const subdomainSlug = params && params.subdomainSlug;
  const programName = shouldRenderAccessErrorPage ? '' : program.metadata.name;

  return (
    <ProgramHomeWrapper
      className="rc-SingleProgramPage"
      pageTitle={`Coursera | ${programName}`}
      thirdPartyOrganizationId={thirdPartyOrganizationId}
      thirdPartyOrganizationSlug={thirdPartyOrganization.slug}
      isAuthenticatedUser={isAuthenticatedUser}
      programId={programId}
      program={program}
      programName={programName}
      shouldHideSearch={shouldRenderAccessErrorPage}
      shouldRenderDomainsMenu={enableRenderDomainsMenu && !domainSlug && !shouldRenderAccessErrorPage}
      enableSkillsInSearchAndBrowse={enableSkillsInSearchAndBrowse}
      shouldShowShortFormContent={shouldShowShortFormContent}
      tabNames={tabNames}
    >
      {shouldRenderAccessErrorPage ? (
        <EnterpriseHomeAccessErrorPage
          isLoggedIn={isAuthenticatedUser}
          thirdPartyOrganizationId={thirdPartyOrganizationId}
          programId={programId}
        />
      ) : (
        <>
          {domainSlug ? (
            <SearchProvider
              // key to refresh API when subdomain change
              key={`${domainSlug}:${subdomainSlug}`}
              searchConfigs={enterpriseDomainsIndexConfig({
                searchContext: { searchInPrograms: { programIds: [programId] } },
                domainId: domainSlug,
                subdomainId: subdomainSlug,
              })}
            >
              <ProgramHomeDomainSubdomainContainer
                programId={programId}
                domainSlug={domainSlug}
                subdomainSlug={subdomainSlug}
                programSlug={programSlug}
                thirdPartyOrganizationId={thirdPartyOrganizationId}
                onProductCardClick={onProductCardClick}
                isWESContract={isWESContract}
                isC4cv={isC4cv}
                isUpswell={isUpswell}
                program={program}
                thirdPartyOrgSlug={thirdPartyOrganization.slug}
                programName={programName}
              />
            </SearchProvider>
          ) : (
            <div role="main">
              <EnterpriseBanner
                thirdPartyOrganization={thirdPartyOrganization}
                program={program}
                isMultiProgram={false}
                isProgramDetailsView={true}
                shouldShowJoinButton={false}
                isC4cv={isC4cv}
                isUpswell={isUpswell}
                disableCTA
              />
              <SingleProgramTabs
                userId={userId}
                programMembership={programMembership}
                program={program}
                onProductCardClick={onProductCardClick}
                // update when implementing the home tab
                isProgramDetailsView={true}
                isAuthenticatedUser={isAuthenticatedUser}
                isProgramMember={isProgramMember}
                isProgramAdmin={isProgramAdmin}
                thirdPartyOrganization={thirdPartyOrganization}
                naptime={naptime}
                userSkillProfileStates={userSkillProfileStates}
                userSkillProfileStatesLoading={userSkillProfileStatesLoading}
                onSkillSetSaveToggle={onSkillSetSaveToggle}
                shouldRefetchUserSkillProfileStates={shouldRefetchUserSkillProfileStates}
                setShouldRefetchUserSkillProfileStates={setShouldRefetchUserSkillProfileStates}
                refetchUserSkillProfileStates={refetchUserSkillProfileStates}
                tutorialVideoAssetId={program.metadata?.tutorialVideoAssetId ?? undefined}
                tutorialVideoUrl={program.metadata?.tutorialVideoUrl ?? undefined}
                userEmails={userEmails}
                browsingExperienceType={browsingExperienceType}
                shouldShowEulaBanner={shouldShowEulaBanner}
                shouldHideCourseraRecommendations={shouldHideCourseraRecommendations}
                shouldShowSponsoredByMessage={shouldShowSponsoredByMessage}
                isAllowlistedC4er={isAllowlistedC4er}
                programCollectionSectionDescription={programCollectionSectionDescription}
                programRecommendationsAvailableCoursesCopy={programRecommendationsAvailableCoursesCopy}
                enableCurriculumBuilder={allowOrgForCurriculumBuilder}
                enableAcademicDisciplines={enableAcademicDisciplines}
                allowOrgForSpecializationConfiguration={allowOrgForSpecializationConfiguration}
                isGwGProgram={isGwGProgram}
                forceEnableProgramSkillSetFilters={forceEnableProgramSkillSetFilters}
                isCareerAcademyLimitedCatalogProgram={isCareerAcademyLimitedCatalogProgram}
                enableEnterpriseCustomBadges={enableEnterpriseCustomBadges}
                isLevelSetsEnabled={isLevelSetsEnabled}
                showSkillSetRoleFilter={showSkillSetRoleFilter}
                enableSkillsDashboard={enableSkillsDashboard}
                enablePersonalizedRecommendationsForOrg={enablePersonalizedRecommendationsForOrg}
                tabNames={tabNames}
                isCareerAcademy={isCareerAcademy}
                enableCareerAcademyTabToggle={enableCareerAcademyTabToggle}
                isCareerAcademyFreemium={isCareerAcademyFreemium}
                isMyLearningTabEnabled={isMyLearningTabEnabled}
                hasEnabledPersonalizedRecommendations={hasEnabledPersonalizedRecommendations}
                showPersonalizedRecommendations={showPersonalizedRecommendations}
                hasTSPs={hasTSPs}
                hasCuratedCollections={hasCuratedCollections}
                hasBrowseCollections={hasBrowseCollections}
                catalogCollectionCount={catalogCollectionCount}
                catalogCollectionLength={catalogCollectionLength}
                catalogCollectionShouldLoadMore={catalogCollectionShouldLoadMore}
                isInDeepBrowseMode={isInDeepBrowseMode}
                shouldShowShortFormContent={shouldShowShortFormContent}
              />
            </div>
          )}
          <ProgramHomeModalManager
            isProgramAdmin={isProgramAdmin}
            program={program}
            programId={programId}
            programMembership={programMembership}
            reloadProgramMembership={reloadProgramMembership}
            thirdPartyOrganization={thirdPartyOrganization}
            userEmails={userEmails}
            userId={userId}
            isInLoginMode={isInLoginMode}
            isUpswell={isUpswell}
            isC4cv={isC4cv}
            isC4C={isC4C}
            isNonBlacklistedC4cv={isNonBlacklistedC4cv}
            showNoCertificateNotification={showNoCertificateNotification}
            hasFreemiumTag={hasFreemiumTag}
            isBrowseOnlyProgram={isBrowseOnlyProgram}
            shouldShowSponsoredByMessage={shouldShowSponsoredByMessage}
            shouldUseJoinTrackingVerbiage={shouldUseJoinTrackingVerbiage}
            isGwGProgram={isGwGProgram}
            programRecommendationsAvailableCoursesCopy={programRecommendationsAvailableCoursesCopy}
            autoEnrolledCoursesWithData={autoEnrolledCoursesWithData}
            programSlug={programSlug}
          />
        </>
      )}
    </ProgramHomeWrapper>
  );
};

export default compose<Props, PropsFromCaller>(
  withCatalogCollectionProps,
  getProgramCareerAcademyVariantQueryGraphQl<{ program: EnterpriseProgramsV1 }>(),
  withProductFeatures<PropsFromProductFeatures, PropsFromCaller>(
    ({ program }) => ({
      idType: IdTypes.Program,
      id: program.id,
    }),
    ({ features }) => ({
      enablePersonalizedRecommendationsForProgram: features.get('enterprise', 'personalizedRecommendations'),
    })
  ),
  withProps<
    PropsFromWithProps,
    PropsFromCaller & PropsFromWithCatalogCollectionProps & PropCareerAcademyVariant & PropsFromProductFeatures
  >(
    ({
      isProgramMember,
      isProgramAdmin,
      hasTSPs,
      isCareerAcademyLimitedCatalogProgram,
      careerAcademyVariant,
      enableSkillsDashboard,
      enablePersonalizedRecommendationsForOrg,
      enablePersonalizedRecommendationsForProgram,
      isAuthenticatedUser,
      isInDeepBrowseMode,
    }) => {
      let tabNames: TabNameType[] = [];

      const enableCareerAcademyTabToggle = C4CExperiments.get('enableCareerAcademyTabToggle');
      const enableCareerAcademyFreemium = CareerAcademyExperiments.get('enableCareerAcademyFreemium');

      const isCareerAcademy = isCareerAcademyV2Enabled(careerAcademyVariant);
      const isCareerAcademyLimitedCatalog = isCareerAcademyLimitedCatalogProgram;
      const isCareerAcademyFreemium =
        enableCareerAcademyFreemium && isCareerAcademyFreemiumEnabled(careerAcademyVariant);

      // Personalized Discovery (My Recommendations) Tab Product Feature Toggle
      const hasEnabledPersonalizedRecommendations =
        enablePersonalizedRecommendationsForOrg || enablePersonalizedRecommendationsForProgram;

      const showPersonalizedRecommendations =
        hasEnabledPersonalizedRecommendations && isAuthenticatedUser && isProgramMember && isInDeepBrowseMode;

      let isMyLearningTabEnabled = false;

      if (!isCareerAcademyLimitedCatalog) {
        tabNames.push(TabName.Catalog);
      }

      if (showPersonalizedRecommendations) {
        tabNames.push(TabName.Recommendations);
      }

      if (isCareerAcademy || isCareerAcademyFreemium) {
        tabNames.push(TabName.CareerAcademy);
      }

      if (hasTSPs) {
        tabNames.push(TabName.SkillSets);
      }

      if (isProgramMember || isProgramAdmin) {
        if (isAuthenticatedUser) {
          tabNames.push(TabName.MyLearning);
          isMyLearningTabEnabled = true;
        } else if (enableSkillsDashboard) {
          tabNames.push(TabName.MyLearning);
          isMyLearningTabEnabled = true;
        } else {
          tabNames.push(TabName.MyCourses);
        }
      }

      if (isCareerAcademyFreemium) {
        tabNames = [TabName.CareerAcademy, ...tabNames.filter((tabName) => tabName !== TabName.CareerAcademy)];
      }

      return {
        tabNames,
        isCareerAcademy,
        enableCareerAcademyTabToggle,
        isCareerAcademyFreemium,
        isMyLearningTabEnabled,
        showPersonalizedRecommendations,
        hasEnabledPersonalizedRecommendations,
      };
    }
  )
)(SingleProgramPage);
