import * as React from 'react';

import { branch, compose, withProps } from 'recompose';

import type { InjectedRouter } from 'js/lib/connectToRouter';
import connectToRouter from 'js/lib/connectToRouter';

import { create, isEmpty } from 'bundles/cml';
import withResponsiveConfig from 'bundles/coursera-ui/components/hocs/withResponsiveConfig';
import CollectionDescription from 'bundles/enterprise-collections/components/CollectionDescription';
import type { CollectionType } from 'bundles/enterprise-collections/components/CollectionItem';
import EnterpriseProductCardCollection from 'bundles/enterprise-collections/components/EnterpriseProductCardCollection';
import withFragments from 'bundles/graphql/components/withFragments';
import { collectionResponsiveConfig } from 'bundles/program-common/constants/ProgramCommonConfig';
import { CollectionListItemFragment } from 'bundles/program-common/constants/ProgramCommonGraphqlQueries';
import type {
  EnterpriseProductMetadataConfiguration,
  Product,
  ProductType,
} from 'bundles/program-common/types/programCommon';

import 'css!./__styles__/Collection';

type PropsFromRouter = {
  programSlug: string;
  router: InjectedRouter;
};

type PropsFromCaller = {
  id?: string;
  collectionOrder?: number;
  collection: CollectionType;
  customCollection?: CollectionType;
  displayLimit?: number;
  onProductCardClick?: (product: Product, productType: ProductType) => void;
  title?: string;
  disableDescription?: boolean;
  productMetadata?: EnterpriseProductMetadataConfiguration;
  allowOrgForSpecializationConfiguration?: boolean;
  itemIdMappingFunction?: (id: string) => string;
  productCardTrackingName?: string;
};

export type Item = {
  productId: string;
  productState: string;
};

export type Props = PropsFromCaller & PropsFromRouter;

export const Collection = ({
  collectionOrder,
  customCollection,
  collection,
  disableDescription,
  onProductCardClick,
  productMetadata = [],
  itemIdMappingFunction,
  productCardTrackingName,
}: Props) => {
  const collectionInUse = customCollection ?? collection;
  const { id, title, description } = collectionInUse;
  const shouldShowDescription =
    !disableDescription && description?.cml && !isEmpty(create(description.cml.value, description.cml.dtdId));

  return (
    <div className="rc-Collection">
      {/* for scrolling offset */}
      <div id={id} className="Collection-Anchor" />

      <EnterpriseProductCardCollection
        title={title}
        description={shouldShowDescription ? <CollectionDescription collection={collectionInUse} /> : undefined}
        collectionOrder={collectionOrder}
        collection={collectionInUse}
        onProductCardClick={onProductCardClick}
        enterpriseProductConfigurations={productMetadata}
        itemIdMappingFunction={itemIdMappingFunction}
        productCardTrackingName={productCardTrackingName}
      />
    </div>
  );
};

export default compose<Props, PropsFromCaller>(
  branch(
    (props: PropsFromCaller) => !props.customCollection,
    withFragments({
      collection: CollectionListItemFragment,
    })
  ),
  withProps({ responsiveConfig: collectionResponsiveConfig }),
  withResponsiveConfig,
  connectToRouter((router) => ({ router, programSlug: router.params.programSlug }))
)(Collection);
