import type React from 'react';

import { isHotkey } from 'is-hotkey';
import { Element, Range, Transforms } from 'slate';
import type { Editor, Node } from 'slate';

import type { Options } from 'bundles/cml/editor/keyboard/types';
import {
  getCurrentNode,
  getNextNode,
  getPreviousNode,
  getTextNode,
  isCurrentLocationEmptyTextNode,
} from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';

const isBackSpaceKey = isHotkey('backspace');
const isDeleteKey = isHotkey('delete');
const isDeleteOrBackSpaceKey = isHotkey(['backspace', 'delete']);

const DELETE_CONFIRM_TYPES = new Set<string>([
  BLOCK_TYPES.CODE,
  BLOCK_TYPES.ASSET,
  BLOCK_TYPES.IMAGE,
  BLOCK_TYPES.TABLE,
  BLOCK_TYPES.WIDGET,
  BLOCK_TYPES.LEGACY_AUDIO,
]);

const DELETE_OR_BACKSPACE_CONFIRM_TYPES = new Set<string>([
  BLOCK_TYPES.ASSET,
  BLOCK_TYPES.IMAGE,
  BLOCK_TYPES.CODE,
  BLOCK_TYPES.WIDGET,
  BLOCK_TYPES.LEGACY_AUDIO,
]);

const shouldShowDeleteConfirm = (node?: Node, types = DELETE_CONFIRM_TYPES) =>
  !!node && Element.isElement(node) && types.has(node.type);

const handleBackspaceKey = (editor: Editor, event: React.KeyboardEvent, { setDeleteConfirm }: Options) => {
  const { nativeEvent } = event;
  if (!isBackSpaceKey(nativeEvent)) {
    return false;
  }

  const { selection } = editor;
  if (!selection || !Range.isCollapsed(selection)) {
    return false;
  }

  if (isCurrentLocationEmptyTextNode(editor)) {
    const previousNode = getPreviousNode(editor);
    if (!shouldShowDeleteConfirm(previousNode)) {
      return false;
    }

    const index = editor.selection?.anchor.path[0] ?? 0;
    Transforms.delete(editor, { at: [index] });
    Transforms.move(editor, { distance: 1, unit: 'character', reverse: true });
    event.preventDefault();
    return true;
  }

  const offset = selection.anchor.offset;
  if (offset > 0) {
    return false;
  }

  const previousNode = getPreviousNode(editor);
  if (!shouldShowDeleteConfirm(previousNode)) {
    return false;
  }

  event.preventDefault();

  Transforms.move(editor, { unit: 'line', distance: 1, reverse: true });
  setDeleteConfirm(true);

  return true;
};

const handleDeleteKey = (editor: Editor, event: React.KeyboardEvent, { setDeleteConfirm }: Options) => {
  const { nativeEvent } = event;
  if (!isDeleteKey(nativeEvent)) {
    return false;
  }

  const { selection } = editor;
  if (!selection || !Range.isCollapsed(selection)) {
    return false;
  }

  if (isCurrentLocationEmptyTextNode(editor)) {
    const nextNode = getNextNode(editor);
    if (!shouldShowDeleteConfirm(nextNode)) {
      return false;
    }

    const index = editor.selection?.anchor.path[0] ?? 0;
    Transforms.delete(editor, { at: [index] });

    Transforms.move(editor, { distance: 1, unit: 'line' });
    event.preventDefault();
    return true;
  }

  const offset = selection.anchor.offset;
  if (offset < getTextNode(editor).length) {
    return false;
  }

  const nextNode = getNextNode(editor);
  if (!shouldShowDeleteConfirm(nextNode)) {
    return false;
  }

  event.preventDefault();

  Transforms.move(editor, { unit: 'line', distance: 1 });
  setDeleteConfirm(true);

  return true;
};

const handleDeleteOrBackspaceKey = (editor: Editor, event: React.KeyboardEvent, { setDeleteConfirm }: Options) => {
  const { nativeEvent } = event;

  // open delete confrimation modal
  if (!isDeleteOrBackSpaceKey(nativeEvent)) {
    return false;
  }

  const currentNode = getCurrentNode(editor);
  if (!shouldShowDeleteConfirm(currentNode, DELETE_OR_BACKSPACE_CONFIRM_TYPES)) {
    return false;
  }

  event.preventDefault();
  setDeleteConfirm(true);
  return true;
};

export const deleteConfirmKeyDownHandler = (editor: Editor, event: React.KeyboardEvent, options: Options) => {
  if (handleDeleteOrBackspaceKey(editor, event, options)) {
    return true;
  }

  if (handleDeleteKey(editor, event, options)) {
    return true;
  }

  if (handleBackspaceKey(editor, event, options)) {
    return true;
  }

  return false;
};
