/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import { compose } from 'recompose';

import { COPYLINK_SOURCE_PARAM } from 'bundles/sharing-common/constants';
import withCustomUrl from 'bundles/sharing-common/utils/withCustomUrl';
import withFormattedShareLink from 'bundles/sharing-common/utils/withFormattedShareLink';
import CopyItem from 'bundles/ui/components/cds/CopyItem';

type PropsFromCaller = {
  shareLink?: string;
  utmMediumParam?: string;
  utmContentParam?: string;
  utmCampaignParam?: string;
  utmProductParam?: string;
  extraQueryParams?: {
    [key: string]: string | number;
  };
  useCustomUrl?: boolean;
  onSocialButtonClick?: () => void;
};

type PropsToComponent = PropsFromCaller & {
  targetShareLink: string;
  customShareLink?: string;
};

export const CopyLinkInput: React.FC<PropsToComponent> = ({
  targetShareLink,
  customShareLink,
  onSocialButtonClick,
}) => (
  <CopyItem
    trackingName="copy_link"
    item={customShareLink || targetShareLink}
    onItemClick={onSocialButtonClick}
    e2eDataName="share-modal-copy-input"
  />
);

export default compose<PropsToComponent, PropsFromCaller>(
  withFormattedShareLink(COPYLINK_SOURCE_PARAM),
  withCustomUrl()
)(CopyLinkInput);
