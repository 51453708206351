/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { RenderPlaceholderProps } from 'slate-react';

import { typography2 } from '@coursera/cds-core';

const styles = {
  placeholder: css`
    ${typography2.bodyPrimary};
    line-height: inherit;
    opacity: 1 !important;
    color: var(--cds-color-neutral-primary-weak);
  `,
};
const Placeholder: React.FC<RenderPlaceholderProps> = ({ attributes, children }) => {
  return (
    <span {...attributes} css={styles.placeholder}>
      {children}
    </span>
  );
};

export default Placeholder;
