import type React from 'react';
import { forwardRef, useContext } from 'react';

import useForkRef from '@material-ui/core/utils/useForkRef';

import clsx from 'clsx';

import type {
  IconProps,
  OverrideProps,
  OverridableComponent,
} from '@coursera/cds-common';
import { IconContext } from '@coursera/cds-common';

import ButtonBase from '@core/ButtonBase';
import { usePopoverContext } from '@core/Popover';
import {
  useItemIndexInCollection,
  useRegisterItemInCollection,
} from '@core/utils';

import { ActionOverflowGroupContext } from './context';
import { classes } from './styles/getActionOverflowCss';
import VisuallyHiddenGroupLabel from './VisuallyHiddenGroupLabel';

export interface ActionOverflowItemTypeMap<
  D extends React.ElementType = 'button'
> {
  props: {
    /**
     * An optional icon to increase affordance.
     */
    icon?: React.ReactElement<IconProps>;
  };
  defaultComponent: D;
}

export type Props<
  D extends React.ElementType = ActionOverflowItemTypeMap['defaultComponent']
> = OverrideProps<ActionOverflowItemTypeMap<D>, D> & {
  component?: React.ElementType;
};

const ActionOverflowItem: OverridableComponent<ActionOverflowItemTypeMap> = forwardRef<
  HTMLButtonElement,
  Props
>(function ActionOverflowItem(props, ref) {
  const {
    icon,
    children,
    className,
    onClick,
    id: idFromProps,
    ...rest
  } = props;
  const { onClose: onPopoverClose } = usePopoverContext();

  const groupContext = useContext(ActionOverflowGroupContext);

  const {
    ref: groupItemRef,
    id,
  } = useRegisterItemInCollection<HTMLButtonElement>({
    id: idFromProps,
    groupId: groupContext?.id,
  });

  const indexInActionMenu = useItemIndexInCollection(id);

  return (
    <li role="none">
      <ButtonBase
        ref={useForkRef(ref, groupItemRef)}
        className={clsx(className, classes.menuItem)}
        role="menuitem"
        tabIndex={indexInActionMenu === 0 ? 0 : -1}
        onClick={(event) => {
          onPopoverClose?.(event, 'backdropClick');
          onClick?.(event);
        }}
        {...rest}
        id={id}
      >
        {icon && (
          <IconContext.Provider value={{ size: 'medium' }}>
            {icon}
          </IconContext.Provider>
        )}
        {groupContext && (
          <VisuallyHiddenGroupLabel
            groupId={groupContext.id}
            groupLabel={groupContext.label}
            id={id}
          />
        )}
        {children}
      </ButtonBase>
    </li>
  );
});

export default ActionOverflowItem;
