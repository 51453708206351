/*
  this contains utility functions for dealing with copy-paste via google docs, see `rules.ts` for usage.
*/

export const GDOCS_ROOT_GUID_PREFIX = 'docs-internal-guid';
export const GDOCS_SELECTOR = `b[id^="${GDOCS_ROOT_GUID_PREFIX}"]`;

// google docs markup has a unique id `docs-internal-guid-{uuid}` to differentiate from other external content,
// if there's an ancestor node with that unique id we consider this as google docs content and handle accordingly.
export const isGoogleDocsContent = (document: Document): boolean => {
  return !!document.body?.querySelector(GDOCS_SELECTOR);
};

// parse style string for text formatting attributes - Safari maintains the space between the attribute key-value pairs.
export const isBold = (style: string): boolean =>
  style.includes('font-weight:700') || style.includes('font-weight: 700') || false;
export const isItalic = (style: string): boolean =>
  style.includes('font-style:italic') || style.includes('font-style: italic') || false;
export const isUnderline = (style: string): boolean =>
  style.includes('text-decoration:underline') || style.includes('text-decoration: underline') || false;

export const isSuperscript = (style: string): boolean => style.includes('vertical-align:super') || false;
export const isSubscript = (style: string): boolean => style.includes('vertical-align:sub') || false;
