import type React from 'react';
import { graphql } from 'react-apollo';

import { compose } from 'recompose';

import withCourseraPlusProductOwnerships from 'bundles/coursera-plus/utils/withCourseraPlusProductOwnerships';
import type { PropsFromCourseraPlusProductOwnerships } from 'bundles/coursera-plus/utils/withCourseraPlusProductOwnerships';
import { IsProductSavedQuery } from 'bundles/unified-common/utils/restQueries';
import type { SimpleProductRequestQueryVariables } from 'bundles/xddp/types';
import type { IsProductSavedDataType, IsProductSavedInjectedProps } from 'bundles/xddp/types/withIsProductSavedData';

type QueryVariables = {
  input: SimpleProductRequestQueryVariables;
};

const withIsSavedProduct = <T extends {}>(
  BaseComponent: React.ComponentType<T & IsProductSavedInjectedProps>
): React.ComponentType<T> =>
  compose<T & IsProductSavedInjectedProps, T & PropsFromCourseraPlusProductOwnerships>(
    withCourseraPlusProductOwnerships(),
    graphql<SimpleProductRequestQueryVariables, IsProductSavedDataType, QueryVariables, IsProductSavedInjectedProps>(
      IsProductSavedQuery,
      {
        // @ts-expect-error react-apollo@2.2.0
        skip: ({ product, ownsCourseraPlus }) => {
          return !product || !ownsCourseraPlus;
        },
        options: ({ product }) => ({
          variables: {
            input: {
              product,
            },
          },
        }),
        props: (props) => {
          const { data } = props as IsProductSavedDataType;
          const { loading, IsProductSavedResponse } = data;

          const savedProduct: boolean = IsProductSavedResponse?.isSavedProduct || false;
          return {
            isSavedProduct: savedProduct,
            loadingSavedProduct: loading,
          };
        },
      }
    )
  )(BaseComponent);

export default withIsSavedProduct;
