import * as React from 'react';

import { compose, getDisplayName, setDisplayName } from 'recompose';

import user from 'js/lib/user';

import { Card, CardBlock, StyleSheet, color, css, spacing } from '@coursera/coursera-ui';

import CourseCompletedCard from 'bundles/course-cards/components/course-card/enterprise/CourseCompletedCard';
import CourseEnrolledCard from 'bundles/course-cards/components/course-card/enterprise/CourseEnrolledCard';
import CourseNotEnrolledCard from 'bundles/course-cards/components/course-card/enterprise/CourseNotEnrolledCard';
import Naptime from 'bundles/naptimejs';
import CoursesV1 from 'bundles/naptimejs/resources/courses.v1';

const MIN_CARD_HEIGHT = 112;

const styles = StyleSheet.create({
  CourseCard: {
    minHeight: MIN_CARD_HEIGHT,
    position: 'relative',
    backgroundColor: color.white,
  },
});

type PropsFromCaller = {
  programId: string;
  courseId: string;
  isEnrolled?: boolean;
  completedAt?: string;
  canUnenroll?: boolean;
  isPartOfS12n?: boolean;
  isGrouped?: boolean;
  thirdPartyOrganizationId?: string;
  hideAdditionalInfo?: boolean;
};

type PropsFromNaptime = {
  course?: CoursesV1;
};

type Props = PropsFromCaller & PropsFromNaptime;

export const CourseCard = ({
  canUnenroll = true,
  course,
  isEnrolled,
  isPartOfS12n,
  programId,
  isGrouped,
  courseId,
  thirdPartyOrganizationId,
  hideAdditionalInfo,
  completedAt,
}: Props): JSX.Element | null => {
  if (!course) {
    return null;
  }
  const userId = user.get().id;
  const shouldRenderNotEnrolled = !isEnrolled || !userId;
  const Tag = isPartOfS12n ? 'div' : Card;
  const tagProps = isPartOfS12n
    ? { role: 'tabpanel', id: `menuItem~${courseId}` }
    : {
        tagAttributes: { xsMinWidth: true, isInteractive: true },
      };
  const tagStyles = {
    minHeight: MIN_CARD_HEIGHT,
    marginBottom: isPartOfS12n || isGrouped ? 0 : spacing.md,
    marginLeft: isPartOfS12n || isGrouped ? 0 : '2px',
    marginRight: isPartOfS12n || isGrouped ? 0 : '2px',
    boxShadow: '0px 1px 4px rgba(49, 49, 49, 0.24)',
    border: '1px solid #EAEAEA',
  };

  const renderCourseCard = () => {
    if (shouldRenderNotEnrolled) {
      return (
        <CourseNotEnrolledCard
          course={course}
          isPartOfS12n={isPartOfS12n}
          programId={programId}
          hideAdditionalInfo={hideAdditionalInfo}
        />
      );
    } else if (completedAt) {
      return <CourseCompletedCard course={course} membershipId={`${userId}~${courseId}`} completedAt={completedAt} />;
    } else {
      return (
        <CourseEnrolledCard
          course={course}
          userId={userId}
          isPartOfS12n={isPartOfS12n}
          programId={programId}
          canUnenroll={canUnenroll}
          thirdPartyOrganizationId={thirdPartyOrganizationId}
        />
      );
    }
  };

  return (
    <Tag {...tagProps} style={tagStyles}>
      <CardBlock isFullBleed={isPartOfS12n}>
        <div
          {...css(`${isPartOfS12n ? 'p-a-1 m-b-0' : 'm-b-0'}`, styles.CourseCard)}
          data-e2e={`CourseCard~${course.id}`}
        >
          {renderCourseCard()}
        </div>
      </CardBlock>
    </Tag>
  );
};

const CourseCardWithData = compose<Props, PropsFromCaller>(
  setDisplayName(getDisplayName(CourseCard)),
  Naptime.createContainer<Omit<Props, 'addCreditTag'>, PropsFromCaller>(({ courseId }) => ({
    course: CoursesV1.get(courseId, {
      fields: [
        'description',
        'photoUrl',
        'slug',
        'upcomingSessionStartDate',
        'workload',
        'plannedLaunchDate',
        'courseStatus',
        'partnerIds',
        'instructorIds',
      ],
      params: { showHidden: true },
    }),
  }))
)(CourseCard);

export default CourseCardWithData;
export { CourseCard as BaseComponent };
