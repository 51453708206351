import * as React from 'react';
import { useCallback, useMemo } from 'react';

import { Transforms } from 'slate';
import { ReactEditor, useReadOnly, useSlateStatic } from 'slate-react';

import { SwapIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import { useWidgetContext } from 'bundles/cml/editor/context/widgetContext';
import { useCourseContext } from 'bundles/cml/shared/hooks/context';
import type { WidgetElement } from 'bundles/cml/shared/types/elementTypes';

import _t from 'i18n!nls/cml';

type Props = {
  widget: WidgetElement;
  widgetTypeId?: string;
  onClick: () => void;
};

const SwapWidgetButton: React.FC<Props> = ({ widget, widgetTypeId, onClick }) => {
  const editor = useSlateStatic();
  const readonly = useReadOnly();
  const { setWidgetModalOptions, widgetMenuOptions } = useWidgetContext();
  const { courseContext } = useCourseContext();
  const courseId = useMemo(() => courseContext?.courseId ?? '', [courseContext?.courseId]);

  const handleSwap = useCallback(
    async (id: string) => {
      setWidgetModalOptions();
      // BE will never return back the default configs and instead return the first available published config.
      const result = await widgetMenuOptions?.publishWidgetSession({ id, courseId });
      const path = ReactEditor.findPath(editor, widget);
      Transforms.setNodes(editor, { id: result?.publishedId || id }, { at: path });
      onClick();
    },
    [onClick, editor, widget, setWidgetModalOptions, widgetMenuOptions, courseId]
  );

  const handleClick = useCallback(() => {
    setWidgetModalOptions({
      handleSelect: handleSwap,
      currentId: widgetTypeId,
    });
  }, [handleSwap, setWidgetModalOptions, widgetTypeId]);

  return (
    <Button
      role="menuitem"
      title={_t('Swap plugin')}
      disabled={readonly}
      onClick={handleClick}
      tooltipProps={{ placement: 'bottom' }}
      data-pendo="cml-widget-menu-swap"
      type={TOOLBAR_BUTTON_TYPES.menu}
    >
      <SwapIcon size="small" />
    </Button>
  );
};

export default SwapWidgetButton;
