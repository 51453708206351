/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useState } from 'react';

import { useLocation, useNavigate } from 'js/lib/useRouter';

import { Grid, Pagination } from '@coursera/cds-core';
import { Loading } from '@coursera/coursera-ui';

import { PAGE_SIZE_DEFAULT } from 'bundles/course-cards/constants';
import { SavedCourses } from 'bundles/program-home/components/myLearning/SavedCourses';
import SingleProgramBrowseCTA from 'bundles/program-home/components/single-program/SingleProgramBrowseCTA';

import { useSavedProductsData } from './hooks/useSavedProductsData';

type PropsFromCaller = {
  programId: string;
  toggleCatalogTab: () => void;
};

const styles = {
  cta: () => css`
    margin-top: var(--cds-spacing-400);
    margin-bottom: var(--cds-spacing-400);
  `,
};

export const SavedTabContainer = ({ programId, toggleCatalogTab }: PropsFromCaller) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_DEFAULT);
  const start = String((currentPageNum - 1) * pageSize);

  const { loading, savedProducts, totalNumSavedProducts } = useSavedProductsData({ programId, start, limit: pageSize });

  const onPageChange = useCallback(
    (_event: React.ChangeEvent<unknown>, page: number) => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setCurrentPageNum(page);
      const newParams = new URLSearchParams(location?.query);
      newParams.set('page', String(page));
      navigate({ search: `?${newParams?.toString()}`, pathname: location?.pathname });
    },
    [location?.pathname, location?.query, navigate]
  );

  const handlePageSizeChange = useCallback(
    (_event: React.ChangeEvent<unknown>, size: number) => {
      setPageSize(size);
      onPageChange(_event, 1);
    },
    [onPageChange]
  );

  if (loading) {
    return <Loading />;
  }

  if (totalNumSavedProducts === 0 || !savedProducts) {
    return <SingleProgramBrowseCTA css={styles.cta} toggleCatalogTab={toggleCatalogTab} />;
  }

  return (
    <Grid container spacing={24} data-testid="saved-container">
      <SavedCourses savedProducts={savedProducts} />
      <Grid item xs={12} data-testid="pagination">
        {PAGE_SIZE_DEFAULT < totalNumSavedProducts && (
          <Pagination
            page={Number(currentPageNum) || 1}
            pageSize={pageSize}
            total={totalNumSavedProducts}
            onPageChange={onPageChange}
            onPageSizeChange={handlePageSizeChange}
            pageSizeInputOptions={[
              {
                value: 8,
                label: '8',
              },
              {
                value: 12,
                label: '12',
              },
              {
                value: 16,
                label: '16',
              },
              {
                value: 20,
                label: '20',
              },
            ]}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default SavedTabContainer;
