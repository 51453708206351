import * as React from 'react';
import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { compose } from 'recompose';

import redirect from 'js/lib/coursera.redirect';
import useRouter from 'js/lib/useRouter';
import user from 'js/lib/user';

import { InfoOutlineIcon } from '@coursera/cds-icons';
import { useTracker } from '@coursera/event-pulse/react';

import EnrollmentChoiceTypes from 'bundles/enroll-course/common/EnrollmentChoiceTypes';
import {
  choiceTypeToHandleSubmitPromise,
  submitEnrollmentPromise,
} from 'bundles/enroll-course/lib/enrollmentChoiceUtils';
import EnrollErrorModal from 'bundles/enroll/components/common/EnrollErrorModal';
import FinaidDeterrentModal from 'bundles/enroll/components/finaid/FinaidDeterrentModal';
import type EnrollmentChoices from 'bundles/enroll/models/EnrollmentChoices';
import type { Product, ProductId } from 'bundles/enroll/types/productTypes';
import { hasCourse, hasCourseId, hasSpecialization } from 'bundles/enroll/types/productTypes';
import { shouldShowFinaid } from 'bundles/enroll/utils/finaidUtils';
import GoogleCertificateEpicClient from 'bundles/epic/clients/GoogleCertificate';
import edgeExperiments from 'bundles/epic/clients/edgeRouting';
import paymentsExperiments from 'bundles/epic/clients/payments';
import QueryProductOwnershipsById from 'bundles/internal-enrollment/queries/QueryProductOwnershipsById.graphql';
import type {
  QueryProductOwnershipsByIdQuery,
  QueryProductOwnershipsByIdQueryVariables,
} from 'bundles/internal-enrollment/queries/__generated__/QueryProductOwnershipsById';
import { convertProductOwnershipsByIdToNaptime } from 'bundles/internal-enrollment/queries/mapProductOwnershipsGraphQLToNaptime';
import TrackedButton from 'bundles/page/components/TrackedButton';
import CourseChoiceModal from 'bundles/payments/components/finaid-2021/CourseChoiceModal';
import FinancialAidApplicationsLimitModal from 'bundles/payments/components/finaid-2021/FinancialAidApplicationsLimitModal';
import { MAX_PENDING_FINAID_APPLICATIONS } from 'bundles/payments/components/finaid-2021/constants/limits';
import type { FinancialAidApplicationType } from 'bundles/payments/components/finaid-2021/enhancers/types';
import {
  withFinancialAidApplicationsByUser,
  withFinancialAidApplicationsByUserAndProduct,
} from 'bundles/payments/components/finaid-2021/enhancers/withFinancialAidApplications';
import { isFinaidCeilingEnabled } from 'bundles/payments/utils/financialAidCeilingUtils';
import isGoogleMarketingPage from 'bundles/unified-description-page-common/utils/isGoogleMarketingPage';

import _t from 'i18n!nls/certificate-enroll';

import 'css!./__styles__/FinaidLink';

export type PropsFromCaller = ProductId &
  Product & {
    enrollmentAvailableChoices?: EnrollmentChoices;
    applyText?: string;
    alreadyAppliedText?: string;
    wrapperClass?: string;
    canOpenFinAidOnMount?: boolean;
    courseIds?: Array<string>;
  };

type PropsFromFinaidLinkS12nOwnershipProvider = {
  firstUnownedCourseId?: string;
};

type PropsFromWithFinaidApplications = {
  financialAidApplicationsByUserAndProduct?: FinancialAidApplicationType[];
  financialAidApplicationsByUser?: FinancialAidApplicationType[];
};

export type PropsToComponent = PropsFromCaller &
  PropsFromFinaidLinkS12nOwnershipProvider &
  PropsFromWithFinaidApplications;

export const FinaidLink: React.FunctionComponent<PropsToComponent> = (props) => {
  const {
    children,
    applyText = _t('Apply for Financial Aid'),
    alreadyAppliedText = _t(
      `You have already applied for financial aid.
            We will contact you if you are approved, or if we need more information from you.`
    ),
    wrapperClass = '',
    canOpenFinAidOnMount,
    firstUnownedCourseId,
    financialAidApplicationsByUserAndProduct: finaidApplicationsForCourse,
    financialAidApplicationsByUser: finaidApplicationsForUser,
    enrollmentAvailableChoices,
  } = props;

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showDeterrentModal, setShowDeterrentModal] = useState(false);
  const [showCourseChoiceModal, setShowCourseChoiceModal] = useState(false);
  const [showFinaidLimitModal, setShowFinaidLimitModal] = useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState<string | undefined>(undefined);

  const track = useTracker();
  const router = useRouter();

  const hasPendingCourseApplications = () => {
    return (
      finaidApplicationsForCourse?.some(
        (application) => application.state === 'PENDING' || application.state === 'PAYMENT_PENDING'
      ) || false
    );
  };

  const handleClick = (ev?: React.MouseEvent<HTMLElement>): void => {
    track('click_button', {
      button: { name: 'finaid_enroll' },
      pageSection: { sectionName: 'hero_banner' },
    });

    ev?.preventDefault();

    const { pathname, query } = router.location;

    // Only generate impression when a learner is on Google-XDP.
    if (isGoogleMarketingPage(pathname)) {
      // Generate impression for Financial Aid ceiling experiment:
      // https://tools.coursera.org/epic/experiment/YavE1USJEe-lBhJcz2rvZw
      GoogleCertificateEpicClient.get('finaidCeilingVariant');

      // Generate impression for Financial Aid ceiling 2.0 experiment:
      // https://tools.coursera.org/epic/experiment/QyRTFkSTEe-lBhJcz2rvZw
      GoogleCertificateEpicClient.get('finaidCeilingVariantV2');

      // Generate impression for Financial Aid ceiling 3.0 rollout:
      // https://tools.coursera.org/epic/experiment/YAunBUUZEe-bTA5FPPZCdw
      GoogleCertificateEpicClient.get('finaidCeilingVariantV3');
    }
    if (!user.isAuthenticatedUser()) {
      router.replace({
        pathname,
        query: { ...query, ...{ authMode: 'login', aid: 'true' } },
      });
      return;
    }

    const pendingUserApplications = finaidApplicationsForUser?.filter((application) => application.state === 'PENDING');

    if (pendingUserApplications && pendingUserApplications.length >= MAX_PENDING_FINAID_APPLICATIONS) {
      setShowFinaidLimitModal(true);
    } else if (hasSpecialization(props)) {
      setShowCourseChoiceModal(true);
    } else {
      setShowDeterrentModal(true);
    }
  };

  useEffect(() => {
    const alreadyApplied = !hasSpecialization(props) && hasPendingCourseApplications();
    const openFinAid = Boolean(!alreadyApplied && router?.location?.query?.openFinAid === 'true');
    if (!hasSpecialization(props) && openFinAid && canOpenFinAidOnMount && !showDeterrentModal) {
      handleClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCourseChoiceSubmit = (courseId?: string) => {
    setSelectedCourseId(courseId);
    setShowCourseChoiceModal(false);
    setShowDeterrentModal(true);
  };

  const renderFinaidPrompt = () => {
    const alreadyApplied = !hasSpecialization(props) && hasPendingCourseApplications();
    const showingOnUnifiedDescriptionPage = edgeExperiments.get('enableUnifiedXDP') === 'alternate';

    if (children) {
      return children;
    } else if (alreadyApplied) {
      return alreadyAppliedText;
    } else {
      return (
        <TrackedButton
          data-testid="finaid-link"
          className="button-link finaid-link"
          onClick={handleClick}
          trackingName="finaid"
        >
          {applyText}
          {
            // TODO: Clean this up or remove check after unified description page experiment.
            // https://coursera.atlassian.net/browse/CORDISC-893
            showingOnUnifiedDescriptionPage && <InfoOutlineIcon size="small" color="interactive" />
          }
        </TrackedButton>
      );
    }
  };

  const renderFinAidErrorModal = () => {
    return <EnrollErrorModal onClose={() => setShowErrorModal(false)} isFinancialAid />;
  };

  const redirectToFinancialAidApplication = (productType: string, productId: string) => {
    redirect.setLocation(`/payments/financial-aid/${productType}/${productId}`);
  };

  const redirectToFinancialAidLegacayApplication = () => {
    const finaidCourseId = hasSpecialization(props) ? selectedCourseId || firstUnownedCourseId : props.course.id;
    const additionalQueryParams = {
      ...(hasSpecialization(props) ? { specialization: props.s12n.slug } : {}),
      ...(hasCourse(props) ? { course: props.course.slug } : {}),
      ...(finaidCourseId ? { courseId: finaidCourseId } : {}),
    };
    const handleSubmitPromise = choiceTypeToHandleSubmitPromise[EnrollmentChoiceTypes.PURCHASE_SINGLE_COURSE];
    const promiseRequest = {
      courseId: finaidCourseId,
      ...(hasSpecialization(props) ? { data: { s12nId: props.s12n.id } } : {}),
    };

    submitEnrollmentPromise({
      handleSubmitPromise,
      options: promiseRequest,
      additionalParams: additionalQueryParams,
      isFinaid: true,
    }).catch(() => {
      setShowErrorModal(true);
    });
  };

  const renderFinAidDeterrentModal = () => {
    const finaidCourseId = hasSpecialization(props) ? selectedCourseId || firstUnownedCourseId : props.course.id;
    const routeToFinancialAidApplicationV3 = paymentsExperiments.preview('routeToFinancialAidApplicationV3');
    const isFinancialAidCeilingVariant = isFinaidCeilingEnabled();
    if (!finaidCourseId) return null;

    return (
      <FinaidDeterrentModal
        courseId={finaidCourseId}
        productType={hasSpecialization(props) && props.s12n.id && !selectedCourseId ? 'specialization' : 'course'}
        s12nId={hasSpecialization(props) ? props.s12n.id : undefined}
        onContinue={() => {
          setShowDeterrentModal(false);

          if (routeToFinancialAidApplicationV3 || isFinancialAidCeilingVariant) {
            redirectToFinancialAidApplication('course', finaidCourseId);
          } else {
            redirectToFinancialAidLegacayApplication();
          }
        }}
        onClose={() => setShowDeterrentModal(false)}
      />
    );
  };

  const renderFinAidLimitModal = () => {
    // sorted by approvalDate ascending
    const pendingUserApplications = finaidApplicationsForUser
      ?.filter((application) => application.state === 'PENDING')
      ?.sort((a, b) => (a?.approvalDate ?? 0) - (b?.approvalDate ?? 0));

    if (!pendingUserApplications?.length) return null;

    return (
      <FinancialAidApplicationsLimitModal
        approvalDate={pendingUserApplications[0].approvalDate ?? null}
        applicationsCount={pendingUserApplications.length}
        applicationsMax={MAX_PENDING_FINAID_APPLICATIONS}
        handleClose={() => setShowFinaidLimitModal(false)}
      />
    );
  };

  // @ts-expect-error: next-line
  if (!props.course && !props.s12n) {
    return null;
  }

  const product = hasCourse(props)
    ? {
        course: props.course,
      }
    : {
        s12n: props.s12n,
      };

  if (!shouldShowFinaid({ enrollmentAvailableChoices, ...product })) {
    return null;
  }

  // Generate impressions for Remove Financial Aid Link experiment
  // https://tools.coursera.org/epic/experiment/27IIMi4SEe25pAqaBVw4tQ
  // https://tools.coursera.org/epic/experiment/0miPYS4SEe25pAqaBVw4tQ
  const removeFinancialAidLink =
    paymentsExperiments.get('removeFinancialAidLink') || paymentsExperiments.get('removeFinancialAidLinkIndia');

  if (removeFinancialAidLink) {
    return null;
  }

  const wrapperClasses = classNames('rc-FinaidLink', wrapperClass);

  return (
    <div className={wrapperClasses} data-e2e="finaid-link">
      <p className="caption-text">{renderFinaidPrompt()}</p>

      {showErrorModal && renderFinAidErrorModal()}

      {showDeterrentModal && renderFinAidDeterrentModal()}
      {showCourseChoiceModal && hasSpecialization(props) && (
        <CourseChoiceModal
          s12nId={props.s12n.id}
          s12nSlug={props.s12n.slug}
          handleSubmit={handleCourseChoiceSubmit}
          handleClose={() => setShowCourseChoiceModal(false)}
        />
      )}
      {showFinaidLimitModal && renderFinAidLimitModal()}
    </div>
  );
};

const WithFinaidApplications = compose<PropsToComponent, PropsFromCaller & PropsFromFinaidLinkS12nOwnershipProvider>(
  // Only need to fetch the max pending application amount
  // to know if the learner already reached the limit (no need to fetch more than the max)
  withFinancialAidApplicationsByUser({ limit: MAX_PENDING_FINAID_APPLICATIONS }),
  withFinancialAidApplicationsByUserAndProduct()
)(FinaidLink);

const FinaidLinkS12nOwnershipProvider: React.FunctionComponent<PropsFromCaller> = (props) => {
  const userId = user.get().id?.toString();
  const courseIds = props.courseIds ?? [];
  const productOwnershipIds = courseIds.map((courseId) => `${userId}~VerifiedCertificate~${courseId}`);

  const { data } = useQuery<QueryProductOwnershipsByIdQuery, QueryProductOwnershipsByIdQueryVariables>(
    QueryProductOwnershipsById,
    {
      variables: { productOwnershipIds },
      context: { clientName: 'gatewayGql' },
      skip: !(userId && hasSpecialization(props) && productOwnershipIds.length > 0),
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    }
  );

  const s12nCourseOwnerships = convertProductOwnershipsByIdToNaptime(data) ?? [];

  const firstUnownedCourseOwnership = s12nCourseOwnerships.find((ownership) => !ownership.owns);
  let courseId;
  if (firstUnownedCourseOwnership?.productId) {
    courseId = firstUnownedCourseOwnership.productId;
  } else if (hasCourseId(props)) {
    courseId = props.courseId;
  }

  return (
    <WithFinaidApplications
      {...props}
      firstUnownedCourseId={firstUnownedCourseOwnership && firstUnownedCourseOwnership.productId}
      {...(courseId ? { courseId } : {})}
    />
  );
};
export default FinaidLinkS12nOwnershipProvider;
