import { useState } from 'react';

import type { TranslatedContentId } from 'bundles/ondemand-translation/components/TranslationContextProvider';

export type TranslationProgressTracker = {
  isLoading: boolean;
  hasError: boolean;
  registerQueryProgress: (id: TranslatedContentId, isLoading: boolean) => void;
  registerError: (id: TranslatedContentId, refetch: () => void) => void;
  unregisterError: (id: TranslatedContentId) => void;
  refetchFailedQueries: () => void;
};

type TranslationError = {
  refetch: () => void;
};

type TranslationQueryStatus = {
  isLoading: boolean;
  error: TranslationError | undefined;
};

/**
 * This is a very simple query status tracker. It just stores a boolean value that indicates whether any error occurred
 * during the content translation.
 *
 * When an error happens during the translation, the translation components will call the registerError function to
 * update the error state.
 *
 * The hasError value is then read to determine if we should show the error banner.
 *
 * TODO: Support more fine-grained errors.
 */
export const useTranslationProgressTracker = (): TranslationProgressTracker => {
  const defaultQueryStatus: TranslationQueryStatus = {
    isLoading: false,
    error: undefined,
  };

  const [queryStatusMap, setQueryStatusMap] = useState(new Map<TranslatedContentId, TranslationQueryStatus>());

  const registerQueryProgress = (id: TranslatedContentId, isLoading: boolean) => {
    const queryStatus = queryStatusMap.get(id) || defaultQueryStatus;

    if (queryStatus.isLoading !== isLoading) {
      queryStatusMap.set(id, {
        ...queryStatus,
        isLoading,
      });
      setQueryStatusMap(new Map(queryStatusMap));
    }
  };

  const registerError = (id: TranslatedContentId, refetch: () => void) => {
    const queryStatus = queryStatusMap.get(id) || defaultQueryStatus;

    if (!queryStatus.error) {
      queryStatusMap.set(id, {
        ...queryStatus,
        error: {
          refetch,
        },
      });
      setQueryStatusMap(new Map(queryStatusMap));
    }
  };

  const unregisterError = (id: TranslatedContentId) => {
    const queryStatus = queryStatusMap.get(id) || defaultQueryStatus;

    if (queryStatus.error) {
      queryStatusMap.set(id, {
        ...queryStatus,
        error: undefined,
      });
      setQueryStatusMap(new Map(queryStatusMap));
    }
  };

  const refetchFailedQueries = () => {
    queryStatusMap.forEach((queryStatus) => {
      if (queryStatus.error) {
        queryStatus.error.refetch();
      }
    });
  };

  const isLoading = Array.from(queryStatusMap.values()).some((x) => x.isLoading);
  const hasError = Array.from(queryStatusMap.values()).some((x) => x.error);

  return {
    isLoading,
    hasError,
    registerQueryProgress,
    registerError,
    unregisterError,
    refetchFailedQueries,
  };
};
