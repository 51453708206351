import * as React from 'react';

import { setDisplayName } from 'recompose';

import Imgix from 'js/components/Imgix';
import useRouter from 'js/lib/useRouter';

import ProductTypePill from 'bundles/browse/components/Domain/ProductTypePill';
import { getProductTypeFromProduct } from 'bundles/enterprise-collections/components/getCollectionItemList';
import withMiniModal from 'bundles/enterprise-legacy-xdp/hocs/withMiniModal';
import { shouldRedirectToUnifiedDescriptionPage } from 'bundles/enterprise-legacy-xdp/hocs/withMiniModal';
import type { TrackedButtonProps } from 'bundles/page/components/TrackedButton';
import TrackedButton from 'bundles/page/components/TrackedButton';
import { TrackedA } from 'bundles/page/components/TrackedLink2';
import { useTracker, useVisibilityTracker } from 'bundles/page/lib/event-pulse/react';
import ProductInfo from 'bundles/search-common/components/ProductInfo';

import 'css!bundles/enterprise-collections/components/__styles__/MobileSearchCard';

const mapHitTypeToUpperCaseProductType: Record<string, string | undefined> = {
  course: 'COURSE',
  s12n: 'SPECIALIZATION',
  career: 'CAREER',
  degree: 'DEGREE',
  certificate: 'CERTIFICATE',
  mastertrack: 'MASTERTRACK',
};

type Props = {
  hit: any;
  hitPosition?: number;
  queryID?: string;
  isProgramHome?: boolean;
  eventingV3Data: {
    algoliaIndexName?: string;
  };
};

const MiniXDPButton = withMiniModal<TrackedButtonProps>()(TrackedButton);

/**
 * An Algolia card expects an Algolia `hit`. Hit's attributes can be viewed on Algolia's Dashboard
 * https://www.algolia.com/apps/LUA9B20G37/explorer/display/{INDEX_NAME}
 */
const MobileSearchCard: React.FC<Props> = ({ hit, hitPosition, queryID, isProgramHome, eventingV3Data }) => {
  const track = useTracker();
  const router = useRouter();
  // Directly link to unified description page if no in multi-program home.
  const redirectToUnifiedDescriptionPage = shouldRedirectToUnifiedDescriptionPage(router);
  const { name, tagline, url, objectID, imageUrl, partners } = hit;
  const { algoliaIndexName } = eventingV3Data;
  const hitType = objectID?.split('~')[0];
  const hitID = hit && hit.objectID && hit.objectID.split('~')[1];
  const productTypeName = mapHitTypeToUpperCaseProductType[hitType];
  const eventingProductType = getProductTypeFromProduct(hitType);

  const sharedProps = {
    className: 'rc-MobileSearchCard parity-card',
    href: url,
    trackingName: 'search_card',
    withVisibilityTracking: true,
    requireFullyVisible: true,
    data: { objectID: hit.objectID, hitPosition, queryID },
  };

  const eventingV3ProductCardData = {
    productCard: {
      index: hitPosition ?? 0,
      algoliaIndexName,
    },
    product: {
      id: objectID,
      name,
      ...(eventingProductType && { type: eventingProductType }),
    },
  };

  const productCardRef: React.MutableRefObject<HTMLDivElement | null> = useVisibilityTracker(
    'view_product_card',
    eventingV3ProductCardData
  );

  const handleTrackProductCard = () => {
    track('click_product_card', eventingV3ProductCardData);
  };

  const children = (
    // suppresing lint warning because onClick below is only used for event capturing
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className="vertical-box" ref={productCardRef} onClick={handleTrackProductCard}>
      <div className="card-content horizontal-box">
        <div className="card-info vertical-box">
          <h2 className="color-primary-text card-title headline-1-text">{name}</h2>
          {productTypeName && <ProductTypePill productTypeName={productTypeName} />}
          <span className="partner-name">{partners ? partners[0] : ''}</span>
        </div>
        <div className="image-wrapper vertical-box">
          <Imgix
            src={imageUrl}
            alt={name}
            maxWidth={100}
            maxHeight={100}
            imgParams={{ fit: 'fill', auto: 'format,compress', bg: 'FFF' }}
          />
        </div>
      </div>
      <ProductInfo tagline={hitType !== 'course' && hitType !== 's12n' ? tagline : null} />
    </div>
  );

  if (isProgramHome && !redirectToUnifiedDescriptionPage) {
    return (
      <MiniXDPButton {...sharedProps} miniModalProps={{ isCourse: hitType === 'course', id: hitID }}>
        {children}
      </MiniXDPButton>
    );
  } else if (isProgramHome && redirectToUnifiedDescriptionPage) {
    return (
      <TrackedA {...sharedProps} href={`/programs/${router.params.programSlug}${url}?source=search`}>
        {children}
      </TrackedA>
    );
  } else {
    return (
      <TrackedA {...sharedProps} href={url}>
        {children}
      </TrackedA>
    );
  }
};

export default setDisplayName<Props>('MobileSearchCard')(MobileSearchCard);
