/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import Waypoint from 'react-waypoint';

import { times } from 'lodash';
import { compose, withProps, withState } from 'recompose';

import { Grid, InlineNotification, PageGridContainer, Typography2, useTheme } from '@coursera/cds-core';

import ErrorMessage from 'bundles/coursera-ui/components/extended/ErrorMessage';
import { withError } from 'bundles/coursera-ui/components/hocs/withBranches';
import Collection from 'bundles/enterprise-collections/components/Collection';
import { EnterpriseProductCardCollectionsPlaceholder } from 'bundles/enterprise-collections/components/EnterpriseProductCardCollection';
import type { CollectionListItemStruct, Product, ProductType } from 'bundles/program-common/types/programCommon';
import { Heading, Section } from 'bundles/program-home/components/AutoHeading';
import withDiscoveryBrowseCollections from 'bundles/program-home/components/single-program/withDiscoveryBrowseCollections';
import type { OnProductCardClick } from 'bundles/program-home/types/Products';
import getCatalogBrowseCollections from 'bundles/program-home/utils/getCatalogBrowseCollection';

import _t from 'i18n!nls/program-home';

export const COLLECTION_LOAD_LIMIT = 3;

type OuterProps = {
  programId: string;
  programSlug: string;
  start?: string;
  limit?: number;
  isCSROnly?: boolean;
  isAuthenticatedUser: boolean;
  onProductCardClick: OnProductCardClick;
  title?: string;
  description?: string;
  allowOrgForSpecializationConfiguration?: boolean;
};

type PropsFromWithProps1 = {
  start: string;
  limit: number;
};

type PropsFromWithState2 = {
  paginationError: boolean;
  setPaginationError: (val: boolean) => void;
};

type PropsFromGraphql = {
  collections: Array<CollectionListItemStruct> | undefined;
  loading: boolean;
  error: boolean;
  loadMoreItems: () => void;
  isAllLoaded: boolean;
};

type InnerProps = OuterProps & PropsFromWithProps1 & PropsFromWithState2 & PropsFromGraphql;

export function AllBrowseCollections({
  collections,
  loading,
  loadMoreItems,
  onProductCardClick,
  isAllLoaded,
  paginationError,
  error,
  limit,
  title = _t('All recommendations from Coursera'),
  description,
  allowOrgForSpecializationConfiguration,
}: InnerProps) {
  const theme = useTheme();
  const styles = {
    title: css`
      margin-top: var(--cds-spacing-600);
      margin-bottom: var(--cds-spacing-300);
    `,
    description: css`
      margin-top: ${4 /* XXS */ - 24 /* L */}px;
      margin-bottom: var(--cds-spacing-200);
    `,
  };

  const handleProductCardClick =
    (collectionTrackingId: string | null) => (product: Product, productType: ProductType) => {
      onProductCardClick(product, productType, collectionTrackingId);
    };

  if (error) {
    return (
      <InlineNotification severity="error">
        {_t('Sorry! Something went wrong. Please refresh the page.')}
      </InlineNotification>
    );
  }

  const descriptionElement = description && (
    <Typography2 component="p" css={styles.description}>
      {description}
    </Typography2>
  );

  if (collections?.length) {
    return (
      <PageGridContainer className="rc-AllBrowseCollections CollectionListPlaceholder">
        <Grid item xs={12}>
          <Heading defaultLevel={2} css={styles.title}>
            {title}
          </Heading>
          <Section initialLevel={3}>
            {descriptionElement}
            {collections.map((collection, index) => (
              <div key={collection.id}>
                <Collection
                  collectionOrder={index + 1}
                  id={collection.id}
                  collection={collection}
                  onProductCardClick={handleProductCardClick(collection.collectionTrackingId)}
                  allowOrgForSpecializationConfiguration={allowOrgForSpecializationConfiguration}
                />
              </div>
            ))}
            {!isAllLoaded && !paginationError && (
              <Waypoint onEnter={loadMoreItems}>
                <EnterpriseProductCardCollectionsPlaceholder />
              </Waypoint>
            )}
            {paginationError && (
              <InlineNotification severity="error">
                {_t('Sorry! Something went wrong. Please refresh the page.')}
              </InlineNotification>
            )}
          </Section>
        </Grid>
      </PageGridContainer>
    );
  }

  if (loading) {
    return (
      <PageGridContainer className="rc-AllBrowseCollections">
        <Grid item xs={12}>
          <Heading defaultLevel={2} css={styles.title}>
            {title}
          </Heading>
          <Section initialLevel={3}>
            {descriptionElement}
            {times(limit, (key) => (
              <EnterpriseProductCardCollectionsPlaceholder key={key} />
            ))}
          </Section>
        </Grid>
      </PageGridContainer>
    );
  }

  return null;
}

export const defaultPropsMap = ({ start, limit }: OuterProps): PropsFromWithProps1 => ({
  start: start || '0',
  limit: limit || COLLECTION_LOAD_LIMIT,
});

export const enhance = compose<InnerProps, OuterProps>(
  withProps(defaultPropsMap),
  withState('paginationError', 'setPaginationError', false),
  withProps(({ isAuthenticatedUser }: OuterProps) =>
    getCatalogBrowseCollections(isAuthenticatedUser, COLLECTION_LOAD_LIMIT)
  ),
  withDiscoveryBrowseCollections,
  withError(ErrorMessage)
);

const AllBrowseCollectionsHookProxy = (props: InnerProps) => <AllBrowseCollections {...props} />;

export default enhance(AllBrowseCollectionsHookProxy);
