import { css } from '@emotion/react';

import { calcCircumference, calcStrokeOffset } from '@core/loaders/utils';
import generateEmotionClasses from '@core/utils/generateEmotionClasses';

import type { Props } from './LoadingItem';

export const classes = generateEmotionClasses('LoadingItem', [
  'overlay',
  'loading',
  'backgroundCircle',
  'fillCircle',
  'mainCircle',
  'invert',
]);

const styles = (props?: Props) => css`
  align-items: center;
  display: flex;
  gap: var(--cds-spacing-150);
  width: max-content;

  .${classes.loading} {
    fill: none;
    stroke-width: 0.75rem;
    width: 1.5rem;
    height: 1.5rem;
    transition: opacity 0.2s ease-in-out;
    stroke-dasharray: 300;
    animation: ${Number.isFinite(props?.value)
      ? 'none'
      : 'loading-item-animation 2.5s linear infinite'};
  }

  .${classes.mainCircle} {
    cx: 50%;
    cy: 50%;
    r: 40px;
    stroke: var(--cds-color-interactive-primary);
  }

  .${classes.backgroundCircle} {
    cx: 50%;
    cy: 50%;
    r: 40px;
    stroke: var(--cds-color-neutral-disabled);
    stroke-width: 0.75rem;
  }

  .${classes.fillCircle} {
    cx: 50%;
    cy: 50%;
    r: 40px;
    stroke: var(--cds-color-interactive-primary);
    stroke-width: 0.75rem;
    transition: stroke-dashoffset 0.5s linear;
    stroke-dasharray: ${calcCircumference(40)};
    stroke-dashoffset: ${calcStrokeOffset(props?.value, 40)};
    transform-origin: center;
    transform: rotate(-90deg);
  }

  @keyframes loading-item-animation {
    0% {
      stroke-dashoffset: 600;
      transform: rotate(0deg);
    }

    100% {
      stroke-dashoffset: 60;
      transform: rotate(720deg);
    }
  }

  &.${classes.invert} {
    circle {
      stroke: var(--cds-color-neutral-primary-invert);
    }

    .${classes.backgroundCircle} {
      opacity: 0.2;
    }
  }
`;

export default styles;
