import * as React from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import SubscriptionPropBulletPoints from 'bundles/enroll/components/subscriptions/free-trialV2/SubscriptionPropBulletPoints';
import SubscriptionVPropCDPFreeTrial from 'bundles/enroll/components/subscriptions/free-trialV2/SubscriptionVPropCDPFreeTrial';
import { freeTrial } from 'bundles/payments/common/constants';

import _t from 'i18n!nls/enroll';

import 'css!./__styles__/SubscriptionVPropFreeTrial';

type PropsFromCaller = {
  onSdp: boolean;
};

const SubscriptionVPropFreeTrial: React.FC<PropsFromCaller> = ({ onSdp }) => {
  return (
    <div className="rc-SubscriptionVPropFreeTrial">
      <h2 className="headline-5-text punch-line">
        <FormattedMessage message={_t('{numDays}-day Free Trial')} numDays={freeTrial.numDays} />
      </h2>
      {!onSdp && <SubscriptionVPropCDPFreeTrial />}

      <SubscriptionPropBulletPoints />
    </div>
  );
};

export default SubscriptionVPropFreeTrial;
