/* eslint-disable no-restricted-syntax */
import _t from 'i18n!nls/stackability-common';

const fcndLinkMap: Record<string, string> = {
  imba: 'https://giesonline.illinois.edu/explore-programs/degree-access-points',
  'master-of-science-data-science-boulder': 'https://boulder3.apply.colorado.edu/register/MSDS-new',
  'ms-computer-science-boulder': 'https://boulder3.apply.colorado.edu/register/MSCS-new',
  'msee-boulder': 'https://boulder3.apply.colorado.edu/register/MSEE',
};

const getFCNDLink = (slug: string): string | undefined => {
  return fcndLinkMap[slug];
};

const getDegreesConversionCopy = () => ({
  degreeDescription: {
    header: _t('Get a head start on this degree'),
    offersCreditCopy: {
      tabHeader: _t('Credit Eligible'),
      subHeader: _t('You can earn degree credit'),
      bodyText: _t(
        'When you complete these programs, you can earn degree credit if you are admitted and enroll in the degree program.¹'
      ),
      disclaimer: _t(
        '¹Each university determines the number of pre-approved prior learning credits that may count towards the degree requirements according to institutional policies.'
      ),
    },
    partOfCopy: {
      tabHeader: _t('Part of'),
      subHeader: _t('Make progress before you enroll'),
      bodyText: _t(
        'These courses are part of this degree program. If you are admitted and enroll in the degree program, your coursework can count toward your degree learning and your progress can transfer with you.'
      ),
    },
    relatedToCopy: {
      tabHeader: _t('Related To'),
      subHeader: _t('Prepare for this degree'),
      bodyText: _t(
        'These courses may provide you with a preview of the topics, materials and instructors in a related degree program which can help you decide if the topic or university is right for you.'
      ),
    },
  },
  lihpStackability: {
    header: {
      singleDegreeUpsell: (partner: string) => _t('Earn credit towards a degree from #{partner}', { partner }),
      multipleDegreeUpsells: _t('Earn credit towards one of these degrees'),
    },
    body: {
      singleCertificateEnrolled: {
        singleDegreeUpsell: (certificate: string, degree: string, partner: string) =>
          _t(
            'Your enrollment in #{certificate} is eligible for college credit towards the #{degree} from #{partner}. If you complete the certificate and are admitted into the program, you can transfer your credit.¹',
            { certificate, degree, partner }
          ),
        multipleDegreeUpsells: (certificate: string) =>
          _t(
            'Your enrollment in #{certificate} is eligible for college credit towards any of these degrees. If you complete the certificate and are admitted into the program, you can transfer your credit.¹',
            { certificate }
          ),
      },
      multipleCertificatesEnrolled: {
        singleDegreeUpsell: (degree: string, partner: string) =>
          _t(
            'Your enrollment in multiple certificates is eligible for college credit towards the #{degree} from #{partner}. If you complete a certificate and are admitted into the program, you can transfer your credit.¹',
            { degree, partner }
          ),
        multipleDegreeUpsells: _t(
          'Your enrollment in multiple certificates is eligible for college credit towards any of these degrees. If you complete a certificate and are admitted into the program, you can transfer your credit.¹'
        ),
      },
    },
    disclaimer: _t(
      '¹Each university determines the number of pre-approved prior learning credits that may count towards the degree requirements according to institutional policies.'
    ),
  },
  stackabilityModuleRefresh: {
    fcndModuleCopy: (slug: string) => ({
      title: _t('Enroll in a for-credit course today'),
      description: _t(
        'Want to enroll in the full degree course now and earn transcriptable credit? You can enroll directly with the university as a non-degree student.'
      ),
      disclaimer: _t(
        '¹Each university determines the number of pre-approved credits that may count towards the degree requirements according to institutional policies.'
      ),
      ctaLabel: _t('Enroll in for-credit now'),
      ctaLink: getFCNDLink(slug),
      enrollSteps: [
        {
          number: 1,
          title: _t('Enroll in course'),
          description: _t('Explore course options to find the courses that fit your needs'),
        },
        {
          number: 2,
          title: _t('Pursue admission to degree'),
          description: _t("Fill out a form or application on the university's website and pay to enroll"),
        },
        {
          number: 3,
          title: _t('Get accepted and enroll'),
          description: _t(
            'Complete the course and earn transcriptable college credit which will count toward your degree learning if you are accepted into the full degree program¹'
          ),
        },
        {
          number: 4,
          title: _t('Transfer credit'),
          description: _t(
            'You can apply to transfer the credits earned towards your degree program after admission to the program'
          ),
        },
      ],
    }),
    upsellsModuleCopy: {
      partOf: {
        title: _t('Build toward a degree'),
        description: _t(
          'These courses are part of the degree program. If you are admitted and enroll, your completed coursework may count toward your degree learning and your progress can transfer with you.¹'
        ),
        disclaimer: _t(
          '¹Successful application and enrollment are required. Eligibility requirements apply. Each institution determines the number of credits recognized by completing this content that may count towards degree requirements, considering any existing credits you may have. Click on a specific course for more information.'
        ),
      },
      offersCredit: {
        title: _t('Build toward a degree'),
        description: _t(
          'When you complete these programs, you may have learning recognized for credit toward this degree, if you are admitted and enroll.¹'
        ),
        disclaimer: _t(
          '¹Successful application and enrollment are required. Eligibility requirements apply. Each institution determines the number of credits recognized by completing this content that may count towards degree requirements, considering any existing credits you may have. Click on a specific course for more information.'
        ),
      },
      relatedTo: {
        title: _t('Prepare for this degree'),
        description: _t(
          'These courses may provide you with a preview of the topics, materials and instructors in a related degree program which can help you decide if the topic or university is right for you.'
        ),
      },
    },
  },
});

export default getDegreesConversionCopy;
