import { tupleToStringKey } from 'js/lib/stringKeyTuple';

import type { ProductType as ProductTypeType } from 'bundles/payments/common/ProductType';
import ProductType from 'bundles/payments/common/ProductType';

import NaptimeResource from './NaptimeResource';

class ProductPrices extends NaptimeResource {
  static RESOURCE_NAME = 'productPrices.v3';

  declare id: string;

  declare amount: number;

  declare countryIsoCode: string;

  declare currencyCode: string;

  declare finalAmount: number;

  declare formattedFinalAmount: {
    amount: number;
    currencySymbol: string;
    currencyCode: string;
    formattedString: string;
    currencyId: number;
  };

  declare promotionInfo?: {
    promotionId: number;
    promotionName: string;
    discountPercent?: number | null;
    discountAmount: number;
  } | null;

  declare couponId?: number | null;

  declare fxRateId?: number | null;

  declare liableForTax: boolean;

  declare priceCountryIsoCode: string;

  declare productItemId: string;

  declare productType: ProductTypeType;

  static getProductType({
    courseId,
    specializationId,
    onDemandSpecializationId,
  }: {
    courseId?: string | null;
    specializationId?: string | null;
    onDemandSpecializationId?: string | null;
  } = {}): ProductTypeType {
    if (courseId) {
      if (courseId.indexOf('v1-') === 0) {
        return ProductType.SPARK_VERIFIED_CERTIFICATE;
      } else {
        return ProductType.VERIFIED_CERTIFICATE;
      }
    } else if (specializationId) {
      return ProductType.SPARK_SPECIALIZATION;
    } else if (onDemandSpecializationId) {
      return ProductType.SPECIALIZATION;
    } else {
      throw new Error('Did not find any recognizable product to get type for');
    }
  }

  static deduceProductTypeAndGetProductPrices({
    courseId,
    specializationId,
    onDemandSpecializationId,
    required = true,
  }: {
    courseId?: string | null;
    specializationId?: string | null;
    onDemandSpecializationId?: string | null;
    required?: boolean;
  } = {}) {
    const productType = ProductPrices.getProductType({
      courseId,
      specializationId,
      onDemandSpecializationId,
    });
    const productItemId = courseId || specializationId || onDemandSpecializationId;
    if (!productItemId) {
      throw new Error('Exactly one productItemId must be specified');
    }
    return this.getProductPrices([{ productType, productItemId }], { required });
  }

  /**
   * Get prices of multiple products.
   *
   * @param {Array} productList - Array of objects consisting of productType and productItemId
   * @param {object} options - additional options for naptime
   * @returns {Promise.<Array.<ProductPrice>>} - Promise array of product prices.
   */
  static getProductPrices(
    productList: {
      productType: ProductTypeType;
      productItemId: string;
    }[] = [],
    options?: { required: boolean }
  ) {
    if (productList.length === 0) {
      throw new Error('productList cannot be empty');
    }

    const productIds = productList.map(({ productType, productItemId }) => {
      return tupleToStringKey([productType, productItemId]);
    });

    return this.finder('byProductIds', { params: { productIds }, required: options?.required ?? true });
  }

  static getPricesByCurrencyCodeAndCountry(
    productList: {
      productType: ProductTypeType;
      productItemId: string;
      countryIsoCode?: string;
      currencyCode?: string;
    }[],
    options?: { required: boolean }
  ) {
    if (productList.length === 0) {
      throw new Error('productList cannot be empty');
    }

    const productIds = productList.map(({ productType, productItemId, countryIsoCode, currencyCode }) => {
      if (!productType || !productItemId || !countryIsoCode || !currencyCode) {
        throw new Error('Invalid product or currency information');
      }
      return tupleToStringKey([productType, productItemId, currencyCode!, countryIsoCode!]);
    });

    return this.multiGet(productIds, {
      required: options?.required ?? true,
      params: { disableCountryDerivation: true },
    });
  }
}

export default ProductPrices;
