/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Typography2 } from '@coursera/cds-core';

import _t from 'i18n!nls/course-cards';

const styles = {
  textWrapper: css`
    margin-top: var(--cds-spacing-300);
  `,
};

const CourseCardAutoenrollmentDisclaimer = () => {
  return (
    <div>
      <Typography2 component="div" color="supportText" variant="bodySecondary" css={styles.textWrapper}>
        {_t('Automatically enrolled per your organization’s request')}
      </Typography2>
    </div>
  );
};

export default CourseCardAutoenrollmentDisclaimer;
