import * as React from 'react';

import { color, css } from '@coursera/coursera-ui';
import { SvgClose } from '@coursera/coursera-ui/svg';

import type { InformationCardProps } from 'bundles/program-common/components/InformationCard/InformationCard';
import InformationCard from 'bundles/program-common/components/InformationCard/InformationCard';
import InformationCardBody from 'bundles/program-common/components/InformationCard/InformationCardBody';
import type { TabListProps } from 'bundles/program-common/components/InformationCard/TabListCardLayout';
import { TabList } from 'bundles/program-common/components/InformationCard/TabListCardLayout';
import styles from 'bundles/program-common/components/InformationCard/styles';

import _t from 'i18n!nls/program-common';

export type TabListInformationCardProps = InformationCardProps &
  TabListProps & { hasDismissButton?: boolean; onDismiss?: () => void };

const TabListInformationCard: React.FC<TabListInformationCardProps> = (props) => {
  const { children, initialSelectedIndex, trackingName, trackingData, hasDismissButton, onDismiss, ...rest } = props;

  return (
    <InformationCard
      {...rest}
      rootClassName={css(styles.tabListRoot, props.rootClassName).className}
      trackingName={trackingName}
      trackingData={trackingData}
    >
      <InformationCardBody>
        {hasDismissButton && (
          <div {...css(styles.dismissButtonRow)}>
            <button
              type="button"
              {...css(styles.dismissButton)}
              onClick={onDismiss}
              data-e2e="tab-list-information-card-dismiss-button"
            >
              <span {...css(styles.dismissButtonText)}>{_t('Dismiss')}</span>
              <SvgClose color={color.primary} suppressTitle />
            </button>
          </div>
        )}

        <TabList initialSelectedIndex={initialSelectedIndex} trackingName={trackingName} trackingData={trackingData}>
          {children}
        </TabList>
      </InformationCardBody>
    </InformationCard>
  );
};

export default TabListInformationCard;
