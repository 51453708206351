import * as React from 'react';

import type { IconSize } from 'bundles/ai-coach-platform/types';

/**
 * MUI icon for "Expand" chat action
 */
const ExpandIcon = ({ size = 16 }: { size?: IconSize }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.7836 13.2164H6.27749C6.52507 13.2164 6.73764 13.3056 6.91518 13.4839C7.09273 13.6623 7.1815 13.8759 7.1815 14.1248C7.1815 14.3665 7.09273 14.5728 6.91518 14.7437C6.73764 14.9146 6.52507 15 6.27749 15H1.87958C1.639 15 1.43222 14.9136 1.25923 14.7408C1.08641 14.5678 1 14.361 1 14.1204V9.72251C1 9.47493 1.08682 9.26236 1.26045 9.08482C1.43409 8.90727 1.64177 8.8185 1.88349 8.8185C2.12521 8.8185 2.33566 8.90727 2.51483 9.08482C2.69401 9.26236 2.7836 9.47493 2.7836 9.72251V13.2164ZM13.2164 2.7836H9.69808C9.45734 2.7836 9.25055 2.69678 9.07773 2.52314C8.90491 2.34951 8.8185 2.14183 8.8185 1.90011C8.8185 1.65838 8.90491 1.44793 9.07773 1.26876C9.25055 1.08959 9.45734 1 9.69808 1H14.096C14.3436 1 14.5561 1.08877 14.7337 1.26632C14.9112 1.44386 15 1.65643 15 1.90401V6.30192C15 6.54266 14.9108 6.74945 14.7325 6.92227C14.5541 7.09509 14.3405 7.1815 14.0916 7.1815C13.8497 7.1815 13.6434 7.09509 13.4727 6.92227C13.3018 6.74945 13.2164 6.54266 13.2164 6.30192V2.7836Z"
      fill="var(--cds-color-grey-950)"
    />
  </svg>
);

export default ExpandIcon;
