import { truncate as lodashTruncate } from 'lodash';

import type ProgramCurriculums from 'bundles/naptimejs/resources/programCurriculums.v1';

export const assertNever = (value: never): never => {
  throw new Error(`Unhandled discriminated union member: ${JSON.stringify(value)}`);
};

export const unslugify = (slug?: string | null) => {
  if (!slug) return slug;
  const result = slug.replace(/-/g, ' ');
  return result.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const formatNumber = (input: number | null | undefined, includeSign = false): string => {
  if (input === undefined || input === null) {
    return '-';
  }

  const number = input.toLocaleString();

  if (includeSign && input > 0) {
    return `+${number}`;
  }

  return number;
};

export const truncate = (string?: string, length?: number) =>
  lodashTruncate(string, {
    length,
    separator: /,? +/,
    omission: '…',
  });

export const copyText = (text: string) => {
  try {
    return navigator.clipboard.writeText(text);
  } catch (e) {
    return Promise.resolve();
  }
};

export const isDomainCurriculum = (curriculum: ProgramCurriculums) => {
  return (
    curriculum.typeName === 'tracksCurriculum' &&
    curriculum.definition.coreTracks.some((track) => track.curriculumItems.some((_) => _.typeName === 'domainId'))
  );
};
