import * as React from 'react';

import { CoachIconButton } from 'bundles/ai-coach-platform/components/building-blocks';
import { ThumbDownIcon } from 'bundles/ai-coach-platform/components/icons/mui';

import _t from 'i18n!nls/ai-coach-platform';

type Props = {
  selected?: boolean;
  onSelect(): void;
  onDeselect(): void;
};

const FeedbackDislike: React.FC<Props> = ({ selected, onSelect, onDeselect }) => {
  return (
    <div data-testid="dislike-component">
      {selected ? (
        <CoachIconButton
          size="small"
          data-testid="thumbs-down-filled-icon"
          aria-label={_t('Cancel dislike')}
          tooltip={_t('Cancel dislike')}
          onClick={onDeselect}
          icon={<ThumbDownIcon filled={true} />}
        />
      ) : (
        <CoachIconButton
          size="small"
          data-testid="thumbs-down-icon"
          aria-label={_t('Dislike')}
          tooltip={_t('Dislike')}
          onClick={onSelect}
          icon={<ThumbDownIcon filled={false} />}
        />
      )}
    </div>
  );
};

export default FeedbackDislike;
