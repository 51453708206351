import * as React from 'react';

import { pure } from 'recompose';

let SvgCertificateConfetti = (props: $TSFixMe) => (
  <svg width="132px" height="114px" viewBox="0 0 265 228">
    <title>Certificate with confetti</title>
    <defs />
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <path
          d="M129.893,227.368 C72.182,227.368 25.398,224.824 25.398,221.686 C25.398,218.549 72.182,216.005 129.893,216.005 C187.603,216.005 234.387,218.549 234.387,221.686 C234.387,224.824 187.603,227.368 129.893,227.368"
          id="Fill-1"
          fill="#F1F0EE"
        />
        <polygon id="Fill-2" fill="#55BBDA" points="58.419 171.425 215.344 171.425 215.344 60.45 58.419 60.45" />
        <polygon id="Fill-3" fill="#FFFFFF" points="48.351 181.371 205.277 181.371 205.277 70.397 48.351 70.397" />
        <path
          d="M205.277,70.397 L203.777,70.397 L203.777,179.871 L49.851,179.871 L49.851,71.897 L205.277,71.897 L205.277,70.397 L203.777,70.397 L205.277,70.397 L205.277,68.897 L48.351,68.897 C47.956,68.897 47.57,69.057 47.29,69.336 C47.011,69.615 46.851,70.002 46.851,70.397 L46.851,181.371 C46.851,181.766 47.011,182.152 47.29,182.432 C47.57,182.711 47.956,182.871 48.351,182.871 L205.277,182.871 C205.672,182.871 206.058,182.711 206.337,182.431 C206.617,182.152 206.777,181.766 206.777,181.371 L206.777,70.397 C206.777,70.002 206.617,69.615 206.337,69.336 C206.058,69.057 205.672,68.897 205.277,68.897 L205.277,70.397"
          id="Fill-4"
          fill="#2D2E2C"
        />
        <polygon id="Fill-5" fill="#2D2E2C" points="69.046 114.668 117.792 114.668 117.792 111.668 69.046 111.668" />
        <polygon id="Fill-6" fill="#2D2E2C" points="69.046 124.678 117.792 124.678 117.792 121.678 69.046 121.678" />
        <path
          d="M174.394,163.931 L175.17,162.648 L163.038,155.306 C162.559,155.016 161.964,155.016 161.485,155.306 L151.629,161.27 L151.629,136.938 L172.893,136.938 L172.894,163.931 L174.394,163.931 L175.17,162.648 L174.394,163.931 L175.893,163.931 L175.893,135.438 C175.893,135.043 175.733,134.657 175.454,134.378 C175.175,134.098 174.788,133.938 174.394,133.938 L150.13,133.938 C149.735,133.938 149.348,134.098 149.069,134.378 C148.79,134.657 148.63,135.043 148.63,135.438 L148.63,163.931 C148.63,164.472 148.922,164.972 149.393,165.238 C149.864,165.503 150.444,165.494 150.906,165.214 L162.261,158.342 L173.617,165.214 C174.079,165.494 174.659,165.503 175.13,165.238 C175.601,164.972 175.893,164.471 175.893,163.931 L174.394,163.931"
          id="Fill-7"
          fill="#2D2E2C"
        />
        <polygon id="Fill-8" fill="#2D2E2C" points="69.046 135.606 117.792 135.606 117.792 132.606 69.046 132.606" />
        <polygon id="Fill-9" fill="#2D2E2C" points="69.046 146.607 117.792 146.607 117.792 143.607 69.046 143.607" />
        <path
          d="M189.709,120.91 C189.709,136.281 177.25,148.741 161.878,148.741 C146.508,148.741 134.047,136.281 134.047,120.91 C134.047,105.54 146.508,93.079 161.878,93.079 C177.25,93.079 189.709,105.54 189.709,120.91"
          id="Fill-10"
          fill="#55BBDA"
        />
        <path
          d="M143.192,120.91 L141.692,120.91 C141.693,132.06 150.729,141.096 161.878,141.097 C173.028,141.096 182.064,132.06 182.065,120.91 C182.064,109.762 173.028,100.725 161.878,100.724 C150.729,100.725 141.693,109.762 141.692,120.91 L144.692,120.91 C144.693,116.16 146.613,111.873 149.726,108.758 C152.841,105.645 157.128,103.725 161.878,103.724 C166.629,103.725 170.916,105.645 174.031,108.758 C177.144,111.873 179.064,116.16 179.065,120.91 C179.064,125.661 177.144,129.948 174.031,133.063 C170.916,136.176 166.629,138.096 161.878,138.097 C157.128,138.096 152.841,136.176 149.726,133.063 C146.613,129.948 144.693,125.661 144.692,120.91 L143.192,120.91"
          id="Fill-11"
          fill="#2D2E2C"
        />
        <path
          d="M134.047,13.953 C130.284,13.952 127.236,10.904 127.235,7.141 C127.236,3.378 130.284,0.33 134.047,0.329 C137.811,0.33 140.858,3.379 140.859,7.141 C140.858,10.904 137.811,13.952 134.047,13.953 M134.047,3.768 C133.11,3.769 132.278,4.143 131.662,4.756 C131.049,5.372 130.675,6.204 130.674,7.141 C130.675,8.078 131.049,8.911 131.662,9.527 C132.278,10.14 133.11,10.514 134.047,10.515 C134.984,10.514 135.817,10.14 136.433,9.527 C137.046,8.911 137.42,8.078 137.421,7.141 C137.42,6.204 137.046,5.372 136.433,4.756 C135.817,4.143 134.984,3.769 134.047,3.768"
          id="Fill-12"
          fill="#84BA52"
        />
        <path
          d="M253.514,147.879 C252.728,147.879 251.93,147.723 251.162,147.393 C248.134,146.092 246.734,142.583 248.035,139.556 L258.999,144.265 C258.028,146.525 255.827,147.879 253.514,147.879"
          id="Fill-13"
          fill="#DD505E"
        />
        <polyline
          id="Fill-14"
          fill="#FACA59"
          points="253.116 115.562 247.949 118.545 241.982 108.211 247.149 105.227 253.116 115.562"
        />
        <polygon id="Fill-15" fill="#E36851" points="22.415 105.207 28.381 105.207 28.381 99.241 22.415 99.241" />
        <polygon id="Fill-16" fill="#E36851" points="258.999 63.433 264.965 63.433 264.965 57.467 258.999 57.467" />
        <polyline
          id="Fill-17"
          fill="#5ABA9B"
          points="228.168 39.836 228.187 38.225 224.685 38.184 226.394 35.142 228.168 32.151 229.945 35.146 231.651 38.184 228.149 38.225 228.168 39.836 228.187 38.225 228.168 39.836 228.187 41.447 237.122 41.342 232.74 33.541 228.168 25.834 223.594 33.546 219.215 41.342 228.169 41.448 228.187 41.447 228.168 39.836"
        />
        <path
          d="M110.29,37.16 C110.13,37.16 109.971,37.154 109.809,37.141 C106.524,36.88 104.074,34.005 104.335,30.72 L116.23,31.668 C115.982,34.791 113.37,37.16 110.29,37.16"
          id="Fill-18"
          fill="#DD505E"
        />
        <polyline
          id="Fill-19"
          fill="#FACA59"
          points="13.807 145.414 8.64 148.398 2.673 138.063 7.841 135.08 13.807 145.414"
        />
        <path
          d="M44.004,39.907 C40.709,39.907 38.038,37.236 38.038,33.94 C38.038,30.645 40.709,27.974 44.004,27.974 C47.299,27.974 49.971,30.645 49.971,33.94 C49.971,37.236 47.299,39.907 44.004,39.907"
          id="Fill-20"
          fill="#84BA52"
        />
        <polyline
          id="Fill-21"
          fill="#FACA59"
          points="178.997 24.757 182.572 29.533 173.019 36.684 169.443 31.907 178.997 24.757"
        />
        <polygon id="Fill-22" fill="#E36851" points="78.776 21.443 86.182 21.443 86.182 14.038 78.776 14.038" />
        <polyline
          id="Fill-23"
          fill="#5ABA9B"
          points="6.882 58.012 13.1 58.085 10.055 63.507 6.882 68.856 3.71 63.507 0.664 58.085 6.882 58.012"
        />
      </g>
    </g>
  </svg>
);

// @ts-expect-error ts-migrate(2322) FIXME: Type 'ComponentType<any>' is not assignable to typ... Remove this comment to see the full error message
SvgCertificateConfetti = pure(SvgCertificateConfetti);
// @ts-expect-error ts-migrate(2339) FIXME: Property 'displayName' does not exist on type '(pr... Remove this comment to see the full error message
SvgCertificateConfetti.displayName = 'SvgCertificateConfetti';

export default SvgCertificateConfetti;
