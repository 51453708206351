import { useLocation } from 'js/lib/useRouter';

import { urlPathLocaleSubfolderPatternMatcher } from 'bundles/internationalization-lib/utils/subfolderPatternMatcher';
import { EQP_ROUTE } from 'bundles/search-common/constants';

const useIsEqp = () => {
  const currentRoute = useLocation();
  if (currentRoute?.pathname) {
    const isEQP = currentRoute?.pathname?.startsWith(`/${EQP_ROUTE}`);
    const isLocaleSubfolderEQP = urlPathLocaleSubfolderPatternMatcher(currentRoute?.pathname)?.restOfPath === EQP_ROUTE;
    return isEQP || isLocaleSubfolderEQP;
  }
  return false;
};

export default useIsEqp;
