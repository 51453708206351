import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('breadcrumbs', [
  'truncated',
  'list',
  'listItem',
  'homeIcon',
  'link',
  'disableNowrap',
  'pendingOverflowCalculation',
  'hide',
  'actionOverflowTrigger',
]);

const getBreadcrumbsCss = css`
  .${classes.list} {
    display: flex;
    padding: 0;
    margin: var(--cds-spacing-50) 0;
    margin-left: calc(var(--cds-spacing-100) * -1);
    list-style: none;

    &.${classes.pendingOverflowCalculation} {
      & > .${classes.actionOverflowTrigger} {
        display: none;
      }

      & > .${classes.listItem}.${classes.hide} {
        display: flex;
      }

      .${classes.disableNowrap} {
        white-space: nowrap;
      }
    }

    & > .${classes.listItem} {
      display: flex;

      & > svg {
        padding-top: 10px;
        box-sizing: content-box;
        overflow: visible;
      }

      &.${classes.hide} {
        display: none;
      }

      & > .${classes.homeIcon} {
        display: flex;
      }

      & > .${classes.link} {
        display: flex;
        padding: var(--cds-spacing-100);

        &:not(.${classes.disableNowrap}) {
          white-space: nowrap;
        }
      }
    }
  }
`;

export default getBreadcrumbsCss;
