import type { Uppy } from '@uppy/core';

import createUppyInstance, { DEFAULT_KEY, DEFAULT_TEMPLATE_ID } from 'bundles/asset-admin/utils/UppyUtils';
import type { UppyOptions, UppyUrlPlugin } from 'bundles/cml/editor/components/elements/imageUploader/uppy/types';
import { isScriptInjectableImageType } from 'bundles/cml/editor/utils/imageUtils';
import generateUUID from 'bundles/common/utils/uuid';

type PromiseOptions = {
  reject: (reason?: any) => void;
  resolve: (assetId: string) => void;
};

const onComplete = ({ resolve, reject }: PromiseOptions) => {
  return (assetIds?: string[]) => {
    if (!assetIds || !assetIds.length) {
      return reject(new Error('CMLEditor: Uppy Image upload failed. assetIds is undefined.'));
    }

    const assetId = assetIds[0];
    if (!assetId) {
      return reject(new Error('CMLEditor: Uppy Image upload failed. assetId is empty.'));
    }

    return resolve(assetId);
  };
};

const onAssemblyError = ({ reject }: PromiseOptions) => {
  return (assembly: unknown, error: Error = new Error('CMLEditor: transloadit unknown assembly error')) => {
    reject(error);
  };
};

const onAssetCreatonError = ({ reject }: PromiseOptions) => {
  return reject;
};

const getUppyBaseParams = (options: UppyOptions) => {
  const { assetContext, courseId, assetCreationUrl } = options;

  return {
    params: {
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['image/*'],
      },
    },
    key: DEFAULT_KEY,
    templateId: DEFAULT_TEMPLATE_ID,
    uppyId: generateUUID(),
    assetContext: assetContext || { courseId },
    assetCreationUrl,
  };
};

const addFile = async (uppy: Uppy, { src, file }: UppyOptions) => {
  if (file) {
    return uppy.addFile({ name: file.name, data: file, type: file.type });
  }

  const urlPlugin = uppy.getPlugin('Url') as UppyUrlPlugin;
  if (!urlPlugin || !urlPlugin.addFile) {
    return Promise.reject(new Error('CMLEditor: Uppy URL Plugin is not registered'));
  }

  return urlPlugin.addFile(src);
};

export const uploadImage = async (options: UppyOptions): Promise<string> => {
  const { isLearnerUpload, file } = options;
  if (isLearnerUpload && file && isScriptInjectableImageType(file)) {
    return Promise.reject(new Error('CMLEditor: SVG assets are not supported'));
  }

  return new Promise(async (resolve, reject) => {
    const { onProgress, mounted } = options;

    const uppy = createUppyInstance({
      ...getUppyBaseParams(options),
      events: {
        progress: onProgress,
        'assetCreation:complete': onComplete({ resolve, reject }),
        'assetCreation:error': onAssetCreatonError({ resolve, reject }),
        'transloadit:assembly-error': onAssemblyError({ resolve, reject }),
      },
    });

    try {
      await addFile(uppy, options);
      if (!mounted.current) {
        uppy.cancelAll();
        reject(new Error('CMLEditor: Uppy uploader component unloaded'));
        return;
      }

      await uppy.upload();
    } catch (e) {
      reject(e);
    }
  });
};
