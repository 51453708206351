/* eslint-disable @typescript-eslint/no-explicit-any, camelcase */

/**
 * This file is autogenerated, please don't edit it manually.
 * Use this command to regenerate it if you modified Epic experiments:
 * yarn epic:types
 */

/**
 * Epic experiments namespaces with their possible keys.
 * Autogenerated from static/bundles/epic/data/defaults/
 */
import client from 'bundles/epic/client';
import C4C from 'bundles/epic/data/defaults/C4C.json';
import type { Tags } from 'bundles/epic/lib/EpicTypes';

type Namespace = {
  privateSessionSupportAllowlist: any;
  enableEnterpriseContentCurationForOrg: any;
  enableSessionAutoEnrollment: boolean;
  disableDeepBrowse: boolean;
  enableCareerAcademy: boolean;
  enableCareerAcademyTabToggle: boolean;
};

const NAMESPACE = 'C4C';

// Adding safe optional chaining to avoid massive changes in unit tests.
client.addDefaults?.([C4C as $TSFixMe]);

const C4CEpicClient = {
  get<K extends keyof Namespace>(key: K, tags?: Tags): Namespace[K] {
    return client.get(NAMESPACE, key, tags);
  },

  preview<K extends keyof Namespace>(key: K, tags?: Tags, defaults?: Namespace): Namespace[K] {
    return client.preview(NAMESPACE, key, tags);
  },
};

export default C4CEpicClient;
