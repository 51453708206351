/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import type { Theme } from '@emotion/react';

import * as React from 'react';

import { Typography2 } from '@coursera/cds-core';

import LatexIcon from 'bundles/cml/editor/components/elements/math/LatexIcon';

import _t from 'i18n!nls/cml';

const styles = {
  placeholder: css`
    display: block;
    height: 100%;
    background-color: var(--cds-color-neutral-background-primary-weak);
    color: var(--cds-color-neutral-primary-weak);
    padding: 0 var(--cds-spacing-100);

    svg {
      margin-right: var(--cds-spacing-100);
    }
  `,
  inline: css`
    display: inline-block;

    svg {
      height: 20px;
      width: 20px;
      vertical-align: text-bottom;
    }
  `,
  block: css`
    display: flex;
    align-items: center;
    padding: var(--cds-spacing-150);

    svg {
      height: 28px;
      width: 28px;
    }
  `,
};

type Props = {
  isBlock: boolean;
};

const MathPlaceholder: React.FC<Props> = ({ isBlock }) => (
  <span css={[styles.placeholder, isBlock ? styles.block : styles.inline]}>
    <LatexIcon />
    <Typography2 component="span" variant="bodyPrimary" color="inherit">
      {_t('Add a TeX Equation')}
    </Typography2>
  </span>
);

export default MathPlaceholder;
