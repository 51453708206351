import { useLocation } from 'js/lib/useRouter';
import { isAuthenticatedUser } from 'js/lib/user';

const useIsLihpHome = () => {
  const currentRoute = useLocation();
  const wellIsIt =
    currentRoute?.pathname?.startsWith('/home') || (currentRoute?.pathname === '/' && isAuthenticatedUser());
  return wellIsIt;
};

export default useIsLihpHome;
