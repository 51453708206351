/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import type { Interpolation } from '@emotion/react';

import * as React from 'react';
import { useContext, useEffect, useRef } from 'react';

import { Dropdown, MenuList } from '@coursera/cds-core';
import type { DropdownProps, Theme } from '@coursera/cds-core';

import { MenuContext } from './utils';

export type MenuProps = Omit<DropdownProps, 'children' | 'anchorElement'> & {
  onClose(event: React.MouseEvent, reason: string): void;
  autoClose?: boolean;
  offset?: {
    x?: string;
    y?: string;
  };
  maxHeight?: string;
  css?: Interpolation<Theme>;

  anchorEl?: HTMLElement | null;

  children?: React.ReactNode;
};

export const mergeRefs = <T = HTMLElement,>(
  refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>
): React.RefCallback<T> => {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null && typeof ref !== 'string') {
        // eslint-disable-next-line no-param-reassign
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
};

const SafeArea = React.forwardRef<HTMLElement, React.HTMLAttributes<HTMLElement>>((props, ref) => {
  const innerRef = useRef<HTMLElement>(null);
  const handleRef = mergeRefs([ref, innerRef]);

  const safeAreaRef = useRef<HTMLDivElement>(null);

  // offset to cover for padding(16px) and gap(4px) + 2px for overlap
  const safeOffset = 22;

  const { children } = props;

  /**
   * Position safe area to match the paper position
   * Note: has to run every rerender to account for paper position changes
   */
  useEffect(() => {
    if (innerRef.current) {
      const sourceBounds = innerRef.current.getBoundingClientRect();

      if (safeAreaRef.current) {
        safeAreaRef.current.style.top = `${sourceBounds.top}px`;
        safeAreaRef.current.style.left = `${sourceBounds.left - safeOffset}px`;
        safeAreaRef.current.style.width = `${sourceBounds.width + safeOffset * 2}px`;
        safeAreaRef.current.style.height = `${sourceBounds.height}px`;
        safeAreaRef.current.style.pointerEvents = 'auto';
      }
    }
  });

  return (
    <React.Fragment>
      <div ref={handleRef} {...props}>
        {children}
      </div>
      {innerRef.current && <div ref={safeAreaRef} style={{ position: 'absolute', zIndex: '-1' }} />}
    </React.Fragment>
  );
});

const Menu: React.FC<React.PropsWithChildren<MenuProps>> = (props: React.PropsWithChildren<MenuProps>) => {
  const {
    open,
    offset,
    maxHeight,
    children,
    container,
    onClose,
    autoClose = true,
    className = 'cds-menu-root',
    anchorEl,
    ...rest
  } = props;

  const menuOffset = `translate(${offset?.x || 0}, ${offset?.y || 0})`;

  const styles = css`
    .cds-menu-paper {
      /* don't override the menu position onClose in order for close transition to work properly */
      transform: ${offset ? `${menuOffset} ${open ? '!important' : ''}` : 'none'};
      ${maxHeight != null ? `max-height: ${maxHeight};` : ''}
    }
  `;

  const parentMenuContext = useContext(MenuContext);

  const context = {
    className,
    container,
    onClose: (event: React.MouseEvent, reason: string) => {
      if (autoClose) {
        onClose(event, reason);
        parentMenuContext.onClose(event, reason);
      }
    },
  };

  return (
    <Dropdown
      anchorElement={anchorEl}
      open={open}
      onClose={onClose}
      container={container}
      classes={{
        paper: 'cds-menu-paper',
      }}
      marginTop="0"
      PaperProps={{
        // renders safe area to prevent submenu closing when hovering between menus
        component: parentMenuContext ? SafeArea : undefined,
      }}
      {...rest}
      // MenuList will always focus the first item on open
      autoFocus={false}
      css={styles}
      className={className}
    >
      <MenuContext.Provider value={context}>
        <div
          css={css`
            padding: var(--cds-spacing-200);
            pointer-events: auto;
            overflow: auto;
            flex: 1;
          `}
        >
          <MenuList>{children}</MenuList>
        </div>
      </MenuContext.Provider>
    </Dropdown>
  );
};

export default Menu;
