import type { AiCoach_Action as AiCoachAction } from '__generated__/graphql-types';

import type { Feedback } from '../types';
import * as actionsTypes from './types';

export function toggleChat(): actionsTypes.ToggleChat {
  return {
    type: actionsTypes.TOGGLE_CHAT,
  };
}

export function openCoachWidget(): actionsTypes.OpenCoachWidget {
  return {
    type: actionsTypes.OPEN_COACH_WIDGET,
  };
}

export function closeCoachWidget(): actionsTypes.CloseCoachWidget {
  return {
    type: actionsTypes.CLOSE_COACH_WIDGET,
  };
}

export function toggleInputDisabled(): actionsTypes.ToggleInputDisabled {
  return {
    type: actionsTypes.TOGGLE_INPUT_DISABLED,
  };
}

export function updateChatHistory(
  messages: actionsTypes.UpdateChatHistory['messages']
): actionsTypes.UpdateChatHistory {
  return {
    type: actionsTypes.UPDATE_CHAT_HISTORY,
    messages,
  };
}

export function addUserMessage(
  text: string,
  id?: string,
  timestamp?: number,
  action?: AiCoachAction
): actionsTypes.AddUserMessage {
  return {
    type: actionsTypes.ADD_NEW_USER_MESSAGE,
    text,
    id,
    timestamp,
    action,
  };
}

export function addResponseMessage(
  text: string,
  id?: string,
  userFeedback?: Feedback | null,
  timestamp?: number
): actionsTypes.AddResponseMessage {
  return {
    type: actionsTypes.ADD_NEW_RESPONSE_MESSAGE,
    text,
    id,
    userFeedback,
    timestamp,
  };
}

export function enableMsgLoader(enabledState: boolean): actionsTypes.EnableMsgLoader {
  return {
    type: actionsTypes.ENABLE_MESSAGE_LOADER,
    messageLoader: enabledState,
  };
}

export function dropMessages(): actionsTypes.DropMessages {
  return {
    type: actionsTypes.DROP_MESSAGES,
  };
}

export function hideAvatar(index: number): actionsTypes.HideAvatar {
  return {
    type: actionsTypes.HIDE_AVATAR,
    index,
  };
}

export function deleteMessages(count: number, id?: string): actionsTypes.DeleteMessages {
  return {
    type: actionsTypes.DELETE_MESSAGES,
    count,
    id,
  };
}

export function setUserInput(inputText: string): actionsTypes.SetUserInputText {
  return {
    type: actionsTypes.SET_USER_INPUT_TEXT,
    inputText,
  };
}

export function setLike(id?: string): actionsTypes.FeedbackSetLike {
  return {
    type: actionsTypes.FEEDBACK_SET_LIKE,
    id,
  };
}

export function setUnLike(id?: string): actionsTypes.FeedbackSetDisLike {
  return {
    type: actionsTypes.FEEDBACK_SET_UNLIKE,
    id,
  };
}

export function setUnSet(id?: string): actionsTypes.FeedbackUnSet {
  return {
    type: actionsTypes.FEEDBACK_UNSET,
    id,
  };
}

export function setChatSessionId(chatSessionId?: string): actionsTypes.SetChatSessionId {
  return {
    type: actionsTypes.SET_CHAT_SESSION_ID,
    chatSessionId,
  };
}

export function setChatSessionStorageKey(chatSessionStorageKey?: string): actionsTypes.SetChatSessionStorageKey {
  return {
    type: actionsTypes.SET_CHAT_SESSION_STORAGE_KEY,
    chatSessionStorageKey,
  };
}

export function clearChatSession(): actionsTypes.ClearChatSession {
  return {
    type: actionsTypes.CLEAR_CHAT_SESSION,
  };
}
