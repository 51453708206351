import {
  DeletedMember,
  EmailNotVerified,
  Invited,
  Member,
  NotMember,
  SoftDeletedMember,
  Whitelisted,
} from 'bundles/program-home/constants/ProgramMembershipState';

import { ProgramMembershipsV2NaptimeResource } from './__generated__/ProgramMembershipsV2';

class ProgramMemberships extends ProgramMembershipsV2NaptimeResource {
  static RESOURCE_NAME = 'programMemberships.v2' as const;

  get isMember() {
    return this.membershipState === Member;
  }

  get isNotProgramMember() {
    return this.membershipState === NotMember || this.membershipState === DeletedMember;
  }

  get isInvited() {
    return this.membershipState === Invited;
  }

  get isEmailNotVerified() {
    return this.membershipState === EmailNotVerified;
  }

  get isWhitelisted() {
    return this.membershipState === Whitelisted;
  }

  get isSoftDeletedMember() {
    return this.membershipState === SoftDeletedMember;
  }

  get isProgramMember() {
    return this.membershipState === Member;
  }

  get hasModalToDisplay() {
    return (
      this.isNotProgramMember ||
      this.isEmailNotVerified ||
      this.isInvited ||
      this.isWhitelisted ||
      this.isSoftDeletedMember
    );
  }

  get email() {
    return this.externalUserData?.definition?.email || '';
  }

  get fullName() {
    return this.externalUserData?.definition?.fullName || '';
  }
}

export default ProgramMemberships;
