import { combineReducers, compose, createStore } from 'redux';

import behavior from './reducers/behaviorReducer';
import input from './reducers/inputReducer';
import messages from './reducers/messagesReducer';
import sessionState from './reducers/sessionReducer';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers =
  // eslint-disable-next-line no-process-env
  (process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;
const reducer = combineReducers({ behavior, messages, input, sessionState });

export default createStore(reducer, composeEnhancers());
