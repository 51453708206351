import React from 'react';

import clsx from 'clsx';

import { useLocalizedStringFormatter } from '@coursera/cds-common';
import { ErrorIcon } from '@coursera/cds-icons';

import Delayed from '@core/Delayed';
import i18nMessages from '@core/loaders/i18n';
import Typography from '@core/Typography2';
import { clamp } from '@core/utils';
import VisuallyHidden from '@core/VisuallyHidden';

import getLoadingItemCss, { classes } from './getLoadingItemCss';

export type Props = {
  /**
   * Label for the loading section
   */
  label?: string;
  /**
   * Invert the color scheme. Use when displaying over dark backgrounds
   * @default false
   */
  inverted?: boolean;
  /**
   * When true, displays an error icon.
   * @default false
   */
  hasError?: boolean;
  /**
   * Current progress in percentage. Min value = 0 and max value = 100.
   */
  value?: number;
} & React.ComponentPropsWithRef<'div'>;

/**
 * LoadingItem notifies the user the loading status of an individual item or
 * indicates the loading progress of an upload or other status of data gathering.
 *
 * See [Props](__storybookUrl__/components-feedback-loading--default#props)
 */
const LoadingItem = React.forwardRef(function LoadingItem(
  props: Props,
  ref: React.Ref<HTMLDivElement>
) {
  const stringFormatter = useLocalizedStringFormatter(i18nMessages);

  const {
    hasError = false,
    label = stringFormatter.format('loading'),
    inverted,
    value: rawValue,
    className,
    ...rest
  } = props;

  const fontColor = inverted ? 'invertBody' : 'body';
  const isDeterminate = Number.isFinite(rawValue) && !hasError;
  const isInDeterminate = !Number.isFinite(rawValue) && !hasError;
  const value = isDeterminate
    ? clamp(rawValue as NonNullable<number>)
    : undefined;

  const loading = (
    <div
      ref={ref}
      className={clsx(className, {
        [classes.invert]: inverted,
      })}
      css={getLoadingItemCss({ ...props, value })}
      role="status"
      {...rest}
    >
      {/* Failed state */}
      {hasError && (
        <ErrorIcon color={inverted ? 'invert' : 'error'} size="large" />
      )}
      {/* Determinate state */}
      {isDeterminate && (
        <svg
          className={classes.loading}
          data-testid="determinate-svg"
          viewBox="0 0 100 100"
        >
          <circle className={classes.backgroundCircle} />
          <circle className={classes.fillCircle} />
        </svg>
      )}
      {/* Indeterminate state */}
      {isInDeterminate && (
        <svg
          aria-hidden
          className={classes.loading}
          data-testid="indeterminate-svg"
          viewBox="0 0 100 100"
        >
          <circle className={classes.mainCircle} />
        </svg>
      )}
      <div>
        <Delayed>
          <Typography
            aria-hidden={!!props['aria-label']}
            color={fontColor}
            component="h3"
            variant="subtitleMedium"
          >
            {hasError && (
              <VisuallyHidden>{stringFormatter.format('error')}</VisuallyHidden>
            )}
            {label}
          </Typography>
        </Delayed>
        {isDeterminate && (
          <Typography color={fontColor} component="h4" variant="bodySecondary">
            {stringFormatter.format('completion_status', {
              value: value || '0',
            })}
          </Typography>
        )}
      </div>
    </div>
  );

  return loading;
});

export default LoadingItem;
