/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { Fragment, useMemo, useState } from 'react';

import { branch } from 'recompose';

import keysToConstants from 'js/lib/keysToConstants';
import { MED_DATE_ONLY_DISPLAY, formatDateTimeDisplay } from 'js/utils/DateTimeUtils';

import type { Theme } from '@coursera/cds-core';
import { Button, Dialog, Grid, InlineNotification, Typography2, breakpoints, useTheme } from '@coursera/cds-core';
import { ArrowNextIcon } from '@coursera/cds-icons';
import { useTracker, useVisibilityTracker } from '@coursera/event-pulse/react';

import EnrollModal from 'bundles/enroll/components/EnrollModal';
import useProductCardInfo from 'bundles/enroll/components/finaid/enhancers/useProductCardInfo';
import type { UseProductCardInfoProps } from 'bundles/enroll/components/finaid/enhancers/useProductCardInfo';
import GrowthExperiments from 'bundles/epic/clients/Growth';
import TrackedButton from 'bundles/page/components/TrackedButton';
import {
  APPROVAL_BUFFER_DAYS,
  MAX_CART_VALID_MILLI_SECS,
} from 'bundles/payments/components/finaid-2021/constants/limits';
import { withFinancialAidApplicationsByUserAndProduct } from 'bundles/payments/components/finaid-2021/enhancers/withFinancialAidApplications';
import type { WithFinancialAidApplicationsByUserAndProductProps } from 'bundles/payments/components/finaid-2021/enhancers/withFinancialAidApplications';
import {
  getFinaidCeilingDiscountPercentage,
  isFinaidCeilingEnabled,
} from 'bundles/payments/utils/financialAidCeilingUtils';
import TopLevelModal from 'bundles/phoenix/components/TopLevelModal';

import _t from 'i18n!nls/certificate-enroll';

export type PropsFromCaller = {
  s12nId?: string;
  courseId: string;
  onContinue(): void;
  onClose(): void;
  approvalDurationInDays?: number;
  productType: 'course' | 'specialization';
};

export type Props = PropsFromCaller & UseProductCardInfoProps & WithFinancialAidApplicationsByUserAndProductProps;

const MODAL_TYPES = keysToConstants(['FINAID', 'ENROLL']);

const EVENTING_V3_MODAL_NAME = 'finaid_application_start';

const useStyles = (theme: Theme) => ({
  courseInfoContainer: (isS12n: boolean) =>
    css({
      display: 'flex',
      flexDirection: 'column',
      minHeight: isS12n ? '154px' : '130px', // prevents layout shift during initial load
    }),
  courseInfo: css({
    flex: 1,
    marginTop: 'var(--cds-spacing-200)',
    padding: `var(--cds-spacing-200) var(--cds-spacing-300)`,
    border: `1px solid var(--cds-color-grey-200)`,
    borderRadius: '4px',
  }),
  partnerName: css({
    marginTop: 'var(--cds-spacing-100)',
    color: `var(--cds-color-grey-700) !important`,
  }),

  expiredDiscountInfoContainer: css({
    padding: 'var(--cds-spacing-300) var(--cds-spacing-600) var(--cds-spacing-400) var(--cds-spacing-600)',
    [breakpoints.only('xs')]: {
      padding: 'var(--cds-spacing-300) var(--cds-spacing-200) var(--cds-spacing-400) var(--cds-spacing-200)',
    },
  }),
});

export const FinaidDeterrentModal: React.FC<Props> = ({
  s12nId,
  courseId,
  productCardInfo,
  onContinue,
  onClose,
  productType,
  approvalDurationInDays = 14,
  financialAidApplicationsByUserAndProduct,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const [activeModal, setActiveModal] = useState<keyof typeof MODAL_TYPES>(MODAL_TYPES.FINAID);

  const paymentExpiredApp = useMemo(() => {
    return financialAidApplicationsByUserAndProduct?.find((app) => app.state === 'PAYMENT_EXPIRED');
  }, [financialAidApplicationsByUserAndProduct]);

  const track = useTracker();

  const trackingProps = {
    modal: {
      name: EVENTING_V3_MODAL_NAME,
    },
    product: {
      id: courseId,
      type: 'course',
    },
  } as const;

  const visibilityTrackingRef: React.MutableRefObject<HTMLDivElement | null> = useVisibilityTracker(
    'view_modal',
    trackingProps
  );

  const handleEnrollClick = () => {
    track('click_button', {
      button: {
        name: 'enroll',
      },
      ...trackingProps,
    });

    setActiveModal(MODAL_TYPES.ENROLL);
  };

  const handleContinueClick = () => {
    track('click_button', {
      button: {
        name: 'continue_finaid_application',
      },
      ...trackingProps,
    });

    onContinue();
  };

  if (activeModal === MODAL_TYPES.ENROLL) {
    const props = s12nId && !courseId ? { s12nId, onSdp: true } : { courseId };

    return (
      <TopLevelModal>
        <EnrollModal {...props} partnerIds={productCardInfo.partnerIds} onClose={onClose} />
      </TopLevelModal>
    );
  }

  const showPaymentExpiredInfo = Boolean(paymentExpiredApp);
  const expirationDateString = paymentExpiredApp?.applicationDate
    ? formatDateTimeDisplay(paymentExpiredApp.applicationDate + MAX_CART_VALID_MILLI_SECS, MED_DATE_ONLY_DISPLAY)
    : null;
  const finaidCeilingDiscountPercentage = getFinaidCeilingDiscountPercentage(productCardInfo.partnerIds);
  const isFinaidCeilingVariant = isFinaidCeilingEnabled(productCardInfo.partnerIds);

  return (
    <div className="rc-FinaidDeterrentModal" ref={visibilityTrackingRef}>
      <Dialog
        data-testid="finaid-enroll-modal"
        aria-describedby="finaid-deterrent-modal-content"
        onClose={onClose}
        open
      >
        {isFinaidCeilingVariant && showPaymentExpiredInfo && (
          <div css={styles.expiredDiscountInfoContainer}>
            <InlineNotification severity="information" className="information-severity" tabIndex={0}>
              {_t(`The discount provided with your previous application expired on #{expirationDateString}.`, {
                expirationDateString,
              })}
            </InlineNotification>
          </div>
        )}
        <Dialog.HeadingGroup>
          {GrowthExperiments.get('isFinAidRigorEnabled')
            ? _t('Applications can take up to #{approvalDuration} days to be reviewed', {
                approvalDuration: approvalDurationInDays + APPROVAL_BUFFER_DAYS,
              })
            : _t('Apply for financial aid and we will review it shortly')}
        </Dialog.HeadingGroup>

        <Dialog.Content id="finaid-deterrent-modal-content">
          {productCardInfo.name && isFinaidCeilingVariant && (
            <Typography2
              component="p"
              variant="bodyPrimary"
              color="body"
              css={{ paddingBottom: 'var(--cds-spacing-300)' }}
            >
              {_t(
                `If approved, you’ll be eligible to receive up to #{finaidCeilingDiscountPercentage}% off the regular price.`,
                { finaidCeilingDiscountPercentage }
              )}
            </Typography2>
          )}
          <div css={styles.courseInfoContainer(productType === 'specialization')}>
            {productCardInfo && (
              <Fragment>
                <Typography2 component="p" variant="subtitleMedium">
                  {_t('Selected #{productType}', { productType })}
                </Typography2>

                <div css={styles.courseInfo}>
                  <Typography2 component="p" variant="bodyPrimary" color="body">
                    {productCardInfo?.name || ''}
                  </Typography2>

                  <Typography2 component="p" variant="bodyPrimary" color="body" css={styles.partnerName}>
                    {productCardInfo?.partnerName || ''}
                  </Typography2>

                  {productCardInfo?.numOfCourses != null && productCardInfo?.numOfCourses > 0 && (
                    <Typography2 component="div" variant="subtitleMedium" color="supportText" align="right">
                      {_t('#{numOfCourses} course series', { numOfCourses: productCardInfo?.numOfCourses || '' })}
                    </Typography2>
                  )}
                </div>
              </Fragment>
            )}
          </div>
        </Dialog.Content>

        <Dialog.Actions>
          <Grid container spacing={16}>
            <Grid item xs={12} sm={12} md={4}>
              <Button
                data-e2e="enroll-button"
                data-testid="enroll-button"
                component={TrackedButton}
                trackingName="financial_aid_deterrent_modal_enroll"
                onClick={handleEnrollClick}
                variant="secondary"
                fullWidth
                withVisibilityTracking
                requireFullyVisible
              >
                {_t('Enroll')}
              </Button>
            </Grid>

            <Grid item xs={12} sm={12} md={8}>
              <Button
                data-testid="continue-button"
                component={TrackedButton}
                trackingName="financial_aid_deterrent_modal_apply"
                onClick={handleContinueClick}
                variant="primary"
                icon={<ArrowNextIcon />}
                fullWidth
                withVisibilityTracking
                requireFullyVisible
              >
                {_t('Continue to the application')}
              </Button>
            </Grid>
          </Grid>
        </Dialog.Actions>
      </Dialog>
    </div>
  );
};

const FinaidDeterrentModalWithHoc = branch<UseProductCardInfoProps>(
  ({ productCardInfo }) => isFinaidCeilingEnabled(productCardInfo.partnerIds),
  withFinancialAidApplicationsByUserAndProduct({ limit: 1 }, true)
)(FinaidDeterrentModal);

const FinaidDeterrentModalWithProductCardInfo = (props: PropsFromCaller) => {
  const { s12nId, courseId, productType } = props;
  const { productCardInfo } = useProductCardInfo({ s12nId, courseId, productType });
  return <FinaidDeterrentModalWithHoc {...props} productCardInfo={productCardInfo} />;
};

export default FinaidDeterrentModalWithProductCardInfo;
