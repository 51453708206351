import type { Editor } from 'slate';

import { DEFAULT_HEADING_VARIANTS, getHeadingDisplayName } from 'bundles/cml/editor/utils/headingUtils';
import { getAncestorOfType } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import { HEADING_LEVELS } from 'bundles/cml/shared/types/coreTypes';
import type { HeadingLevel, HeadingVariant } from 'bundles/cml/shared/types/coreTypes';
import type { HeadingElement } from 'bundles/cml/shared/types/elementTypes';

import _t from 'i18n!nls/cml';

export const hasHeadingByLevel = (level: HeadingLevel, variant?: HeadingVariant) => {
  return (heading: HeadingElement) => {
    if (heading.level !== level) {
      return false;
    }

    if (variant) {
      const nodeVariant = heading.variant || DEFAULT_HEADING_VARIANTS[heading.level];
      return nodeVariant === variant;
    }

    return true;
  };
};

export const getHeadingLabel = (editor: Editor) => {
  const headingLevel = HEADING_LEVELS.find((level: HeadingLevel) => {
    const matcher = hasHeadingByLevel(level);
    const headingEntry = getAncestorOfType(editor, BLOCK_TYPES.HEADING);
    return !!headingEntry && matcher(headingEntry[0] as HeadingElement);
  });

  if (headingLevel) {
    return getHeadingDisplayName(headingLevel);
  }

  return _t('Body');
};
