import type React from 'react';
import { createContext, useContext } from 'react';

import type { WidgetMenuOptions, WidgetModalOptions } from 'bundles/cml/editor/types/widgetTypes';
import type { WidgetManagerType } from 'bundles/cml/shared/utils/WidgetManager';

export type WidgetContextType = {
  setWidgetModalOptions: (widgetOptions?: WidgetModalOptions) => void;
  widgetManager?: WidgetManagerType;
  widgetMenuOptions?: WidgetMenuOptions;
};

const NullComponent: React.FC = () => null;

export const WidgetContext = createContext<WidgetContextType>({
  setWidgetModalOptions: () => undefined,
  widgetManager: {
    cache: {},
    previewWidgetSession: () => Promise.resolve(undefined),
    getWidgetSession: () => Promise.resolve(undefined),
  },
  widgetMenuOptions: {
    WidgetConfigDialog: NullComponent,
    updateWidgetSession: () => Promise.resolve(undefined),
    publishWidgetSession: () => Promise.resolve(undefined),
  },
});

export const useWidgetContext = () => useContext(WidgetContext);
