/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import redirect from 'js/lib/coursera.redirect';
import keysToConstants from 'js/lib/keysToConstants';
import { useRetracked } from 'js/lib/retracked';
import useRouter from 'js/lib/useRouter';

import { VisuallyHidden } from '@coursera/cds-core';

import type { EnrollmentChoiceTypesValues } from 'bundles/enroll-course/common/EnrollmentChoiceTypes';
import {
  BULKPAY_FULL_SPECIALIZATION,
  BULKPAY_REMAINING_SPECIALIZATION_COURSES,
  ENROLL_THROUGH_GROUP,
  PURCHASE_SINGLE_COURSE,
  SUBSCRIBE_TO_COURSERA_PLUS,
} from 'bundles/enroll-course/common/EnrollmentChoiceTypes';
import CourseEnrollChoiceDescription from 'bundles/enroll-course/components/CourseEnrollChoiceDescription';
import CourseEnrollmentConfirmation from 'bundles/enroll-course/components/CourseEnrollmentConfirmation';
import EnrollmentReasonMessage from 'bundles/enroll-course/components/EnrollmentReasonMessage';
import {
  canPurchaseSingleCourseAndHasFullDiscount,
  enrollWithFullDiscountPromotion,
} from 'bundles/enroll-course/lib/apiClient';
import {
  choiceTypeToHandleSubmitPromise,
  submitEnrollmentPromise,
} from 'bundles/enroll-course/lib/enrollmentChoiceUtils';
import EnrollErrorModal from 'bundles/enroll/components/common/EnrollErrorModal';
import { useCourseEnrollModalData } from 'bundles/enroll/data/usePageData';
import type { ApiError } from 'bundles/enroll/utils/errorUtils';
import { useEnrollModalEventing } from 'bundles/enroll/utils/eventingUtils';
import Icon from 'bundles/iconfont/Icon';
import TrackedButton from 'bundles/page/components/TrackedButton';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import useUserAgent from 'bundles/page/hooks/useUserAgent';
import Modal from 'bundles/phoenix/components/Modal';
import { certDpURLForCertificatePilotCourse } from 'bundles/premium-certificate/utils/certificatePilotUtils';
import { isFreeReferralForFriendEnabled } from 'bundles/referral/utils/paymentUtils';

import _t from 'i18n!nls/enroll-course';

import 'css!./__styles__/StandaloneCourseEnrollModal';

const MODAL_TYPES = keysToConstants(['ENROLL', 'ERROR']);

type PropsFromCaller = {
  courseId: string;
  onClose: () => void;
  s12nId?: string;
};

const styles = {
  hideContainer: css`
    .bt3-hide {
      display: none !important;
    }
  `,
  radioContainer: css`
    .bt3-radio {
      display: block;
      min-height: 20px;
      margin-top: 10px;
      margin-bottom: 10px;
      padding-left: 20px;
    }

    .bt3-radio label {
      display: inline;
      font-weight: normal;
      cursor: pointer;
    }

    .bt3-radio input[type='radio'] {
      float: left;
      margin-left: -20px;
    }

    .bt3-radio + .bt3-radio {
      margin-top: -5px;
    }

    .bt3-radio[disabled],
    fieldset[disabled] .bt3-radio {
      cursor: not-allowed;
    }
  `,
};

export const StandaloneCourseEnrollModal: React.FC<PropsFromCaller> = ({ s12nId, onClose }) => {
  const router = useRouter();
  const userAgent = useUserAgent();
  const track = useRetracked();
  const { course, s12n, enrollmentAvailableChoices, promotionEligibilities, promotionDetails, group } =
    useCourseEnrollModalData();
  const { getEnrollModalTrackingRef, trackEnrollModalContinue, trackEnrollModalClose } = useEnrollModalEventing();

  const visibilityTrackingRef = getEnrollModalTrackingRef();

  const modalRef = useRef<HTMLDivElement | null>(null);

  const [didClickContinue, setDidClickContinue] = useState<boolean>(false);
  const [didJustEnroll, setDidJustEnroll] = useState<boolean>(false);
  const [activeModal, setActiveModal] = useState<keyof typeof MODAL_TYPES>(MODAL_TYPES.ENROLL);
  const [error, setError] = useState<ApiError | undefined>();
  const [selectedEnrollmentType, setSelectedEnrollmentType] = useState<string | undefined>();

  const getSelectedInputEl = useCallback((): HTMLInputElement | undefined => {
    if (!modalRef.current) {
      return undefined;
    }

    const radios: NodeListOf<HTMLInputElement> = modalRef.current.querySelectorAll('input[type="radio"]');

    // Convert NodeList to iterable Array and then find the checked element
    return Array.from(radios).find(({ checked }) => checked);
  }, [modalRef]);

  const getSelectedChoiceType = useCallback(() => {
    const selectedInputEl = getSelectedInputEl();
    return selectedInputEl ? selectedInputEl.value : '';
  }, [getSelectedInputEl]);

  useEffect(() => {
    setSelectedEnrollmentType(getSelectedChoiceType());
  }, [getSelectedChoiceType]);

  const getSelectedChoiceData = () => {
    const selectedInputEl = getSelectedInputEl();
    if (selectedInputEl) {
      return {
        ...(s12n?.id ? { s12nId: s12n.id } : {}),
        ...(group?.id ? { groupId: group.id } : {}),
      };
    }
    return undefined;
  };

  const handleModalClose = () => {
    if (didJustEnroll) {
      redirect.refresh();
    } else {
      trackEnrollModalClose();
      onClose();
    }
  };

  const handleRadioClick = (ev: React.MouseEvent<HTMLInputElement>) => {
    const enrollmentType = (ev.target as HTMLInputElement).value || '';
    setSelectedEnrollmentType(enrollmentType);
  };

  const onClickContinue = () => {
    setDidClickContinue(true);

    if (certDpURLForCertificatePilotCourse(course.id)) {
      window.open(certDpURLForCertificatePilotCourse(course.id), '_blank');
      return;
    }

    const selectedRadio = getSelectedInputEl();
    let selectedChoiceType = getSelectedChoiceType();
    let choiceData: Record<string, string> | undefined = getSelectedChoiceData();
    // There are styles of modals that don't have radio buttons. Order is guaranteed by server.
    if (!selectedChoiceType) {
      const choiceTypes = enrollmentAvailableChoices.choiceTypes;

      // Since there was no user selection, if bulk pay is offered, we want to buy the course and not the S12N
      if (
        choiceTypes.includes(BULKPAY_FULL_SPECIALIZATION) ||
        choiceTypes.includes(BULKPAY_REMAINING_SPECIALIZATION_COURSES)
      ) {
        selectedChoiceType = PURCHASE_SINGLE_COURSE;
      } else if (choiceTypes?.length >= 1) {
        selectedChoiceType = choiceTypes[0];
      } else {
        throw new Error('Cannot continue enrollment without selected choice type');
      }
    }

    trackEnrollModalContinue(selectedChoiceType);

    if (!choiceData && s12n) {
      choiceData = { s12nId: s12n.id };
    }

    const productSkuId = selectedRadio?.dataset.productSkuId;
    const promoCode = promotionEligibilities?.isEligible ? promotionEligibilities.promoCodeId : undefined;

    const options = {
      data: choiceData,
      courseId: course.id,
      productSkuId,
    };

    // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
    const handleSubmitPromise = choiceTypeToHandleSubmitPromise[selectedChoiceType];
    submitEnrollmentPromise({
      handleSubmitPromise,
      options,
      promoCode,
    })
      .then((data: $TSFixMe /* TODO: type submitEnrollmentPromise */) => {
        if (data.didEnroll) {
          setDidJustEnroll(true);
        }
      })
      .catch((data: $TSFixMe /* TODO: type submitEnrollmentPromise */) => {
        setActiveModal(MODAL_TYPES.ERROR);
        setError(data);
      });
  };

  // This only runs when using Friendbuy's referral discount
  const handleScriptLoadSuccess = (ev?: Event) => {
    track({
      trackingData: { event: ev },
      trackingName: 'friendbuy_script',
      action: 'onload',
    });
  };

  // This only runs when using Friendbuy's referral discount
  const handleScriptLoadFailure = (scriptError?: string | Event) => {
    track({
      trackingData: { error: scriptError },
      trackingName: 'friendbuy_script',
      action: 'onerror',
    });
    // eslint-disable-next-line no-alert
    alert(
      _t(
        'It looks like you have an ad blocker enabled.\n\nPlease consider temporarily disabling your ad-blocker and refreshing the page to ensure the person who referred you can receive their discount.'
      )
    );
  };

  const checkoutAndGoToCourseWithFullDiscount = () => {
    setDidClickContinue(true);
    const promoCode = promotionEligibilities?.promoCodeId ?? '';
    const referralSource = router.location.query?.referral;

    enrollWithFullDiscountPromotion({
      handleFriendbuyScriptLoadFailure: handleScriptLoadFailure,
      handleFriendbuyScriptLoadSuccess: handleScriptLoadSuccess,
      location: router.location,
      promotionDetails,
      promotionEligibilities,
      track,
      userAgent,
    })
      .then(() => {
        if (isFreeReferralForFriendEnabled(referralSource, promoCode)) {
          // Setting a delay of 1.5sec to ensure the friendbuy event is sent before redirection happens
          setTimeout(() => redirect.setLocation(course.phoenixHomeLink), 1500);
        } else {
          redirect.setLocation(course.phoenixHomeLink);
        }
      })
      .catch((data: $TSFixMe /* TODO: type submitEnrollmentPromise */) => {
        setActiveModal(MODAL_TYPES.ERROR);
        setError(data);
      });
  };

  const renderChoice = ({
    choiceType,
    index,
    selectedIndex,
    shouldShowRadioButton,
  }: {
    choiceType: EnrollmentChoiceTypesValues;
    index: number;
    selectedIndex: number;
    shouldShowRadioButton: boolean;
  }) => {
    let productSkuId;
    let maybeGroup;

    if (choiceType === ENROLL_THROUGH_GROUP) {
      maybeGroup = group;
    }

    const inputId = `choice-input-${choiceType}`;

    const descriptionWrapperClasses = classNames('choice-description-wrapper', {
      'show-radio-button': shouldShowRadioButton,
    });

    return (
      <div css={styles.radioContainer}>
        <div
          className="bt3-radio choice-radio-container"
          key={choiceType}
          data-e2e={choiceType}
          data-testid={choiceType}
        >
          <label htmlFor={inputId} className="horizontal-box align-items-vertical-center">
            <div className="input-container horizontal-box align-items-vertical-center">
              <input
                id={inputId}
                name="choice-input"
                type="radio"
                value={choiceType}
                defaultChecked={selectedIndex ? index === selectedIndex : index === 0}
                onClick={handleRadioClick}
                data-product-sku-id={productSkuId}
              />
              {shouldShowRadioButton && (
                <span className="cif-stack">
                  <i className="cif-circle-thin cif-stack-2x" />
                  <i className="cif-circle cif-stack-1x" />
                </span>
              )}
            </div>
            <div className={descriptionWrapperClasses}>
              <CourseEnrollChoiceDescription choiceType={choiceType} group={maybeGroup} />
            </div>
          </label>
        </div>
      </div>
    );
  };

  const renderEnrollmentChoices = (choiceTypes: Array<EnrollmentChoiceTypesValues>, selectedIndex: number) => {
    const shouldShowRadioButton = choiceTypes.length > 1;
    return (
      <div className="choices" data-testid="choices" role="group" aria-labelledby="enrollment-choice-label">
        <label id="enrollment-choice-label" htmlFor="enrollment-choices" className="screenreader-only">
          {_t('Enrollment choice')}
        </label>
        <div id="enrollment-choices">
          {choiceTypes.map((choiceType, index) =>
            renderChoice({ choiceType, index, selectedIndex, shouldShowRadioButton })
          )}
        </div>
      </div>
    );
  };

  const getChoiceTypes = (): Array<EnrollmentChoiceTypesValues> => {
    const choiceTypes = enrollmentAvailableChoices.choiceTypes ?? [];

    // Don't show the Coursera Plus option for standalone course enroll modal
    return choiceTypes.filter((choiceType: EnrollmentChoiceTypesValues) => choiceType !== SUBSCRIBE_TO_COURSERA_PLUS);
  };

  const renderModalBody = () => {
    let reasonCode = enrollmentAvailableChoices.enrollmentChoiceReasonCode;
    if (enrollmentAvailableChoices.isEnrolled && enrollmentAvailableChoices.hasChoice) {
      reasonCode = undefined;
    }

    if (didJustEnroll) {
      return (
        <TrackedDiv
          trackingName="enrollment_confirmation"
          // eslint-disable-next-line camelcase
          data={{ enrollment_type: selectedEnrollmentType }}
          withVisibilityTracking={true}
          requireFullyVisible={false}
          trackClicks={false}
        >
          <CourseEnrollmentConfirmation courseId={course.id} />
        </TrackedDiv>
      );
    } else if (reasonCode) {
      return <EnrollmentReasonMessage reasonCode={reasonCode} s12nId={s12nId || s12n?.id} />;
    } else {
      let choiceTypes = getChoiceTypes();
      const continueButtonProps = didClickContinue ? { disabled: true } : {};
      const canPurchaseCourseWithFullDiscount = canPurchaseSingleCourseAndHasFullDiscount({
        promotionDetails,
        promotionEligibilities,
        enrollmentAvailableChoices,
      });

      let selectedIndex: number;
      // Show only purchase course option for courses with 100% promotion. The discount is applied at checkout.
      if (canPurchaseCourseWithFullDiscount) {
        selectedIndex = 0;
        choiceTypes = [PURCHASE_SINGLE_COURSE];
      } else {
        selectedIndex = 0;
      }

      const isLoading = enrollmentAvailableChoices.canEnrollThroughGroup && !group;

      const loadingContainerClass = classNames('c-img-loading', {
        'bt3-hide': !isLoading,
      });

      // Using CSS to hide the content because `CourseEnrollChoiceDescription` must actually mount
      // in order to load its data and tell this component that it finished loading
      // So just render the child components but make not them not visible while loading
      const contentContainerClass = classNames({
        'bt3-hide': isLoading,
      });

      // Set data-e2e attribute based on loading state to track readiness in e2e tests
      const contentContainerDataE2E = isLoading
        ? 'enroll-modal-content-container-is-loading'
        : 'enroll-modal-content-container';

      return (
        <div className="cem-body">
          <div className={loadingContainerClass} data-e2e={contentContainerDataE2E} />
          <div className={contentContainerClass}>
            {renderEnrollmentChoices(choiceTypes, selectedIndex)}
            <hr />
            {canPurchaseCourseWithFullDiscount ? (
              <div className="align-horizontal-center">
                <TrackedButton
                  className="primary cozy continue-button"
                  onClick={checkoutAndGoToCourseWithFullDiscount}
                  {...continueButtonProps}
                  trackingName="course_checkout_with_full_discount_and_go_to_course_button"
                  dataE2e="course_checkout_with_full_discount_and_go_to_course_button"
                >
                  {didClickContinue ? (
                    <>
                      <Icon name="spinner" spin={true} />
                      <VisuallyHidden component="span" className="rc-A11yScreenReaderOnly">
                        {_t('Loading')}
                      </VisuallyHidden>
                    </>
                  ) : (
                    _t('Go to course')
                  )}
                </TrackedButton>
              </div>
            ) : (
              <div className="align-horizontal-center">
                <TrackedButton
                  className="primary continue-button cozy"
                  onClick={onClickContinue}
                  {...continueButtonProps}
                  trackingName="course_enroll_modal_continue_button"
                  dataE2e="course_enroll_modal_continue_button"
                >
                  {didClickContinue ? (
                    <>
                      <Icon name="spinner" spin={true} />
                      <VisuallyHidden component="span" className="rc-A11yScreenReaderOnly">
                        {_t('Loading')}
                      </VisuallyHidden>
                    </>
                  ) : (
                    _t('Continue')
                  )}
                </TrackedButton>
              </div>
            )}
          </div>
        </div>
      );
    }
  };

  const renderModalContent = () => {
    return (
      <div css={styles.hideContainer} className="enrollmentChoiceContainer" ref={modalRef}>
        <div className="theme-dark" ref={visibilityTrackingRef}>
          <div className="cem-title color-primary-text align-horizontal-center">
            <h2 className="headline-4-text">{course.name}</h2>
            {s12n && (
              <div
                data-unit="part-of-s12n-subheader"
                data-testid="part-of-s12n-subheader"
                className="cem-subtitle body-1-text color-secondary-text"
              >
                <FormattedMessage
                  message={_t('Part of a {length}-course series, {name}')}
                  length={s12n.courseIds.length}
                  name={s12n.name}
                />
              </div>
            )}
          </div>
        </div>
        {renderModalBody()}
      </div>
    );
  };

  switch (activeModal) {
    case MODAL_TYPES.ERROR:
      return <EnrollErrorModal error={error} onClose={onClose} />;

    case MODAL_TYPES.ENROLL:
      return (
        <Modal
          className="rc-StandaloneCourseEnrollModal"
          modalName="CourseEnrollModal"
          handleClose={handleModalClose}
          trackingName="course_enroll_modal"
          data={{ id: course.id }}
        >
          {renderModalContent()}
        </Modal>
      );

    default:
      return null;
  }
};

export default StandaloneCourseEnrollModal;
