import * as React from 'react';

import type { IconSize } from 'bundles/ai-coach-platform/types';

/**
 * MUI icon for "Copy" chat action
 */
const CopyIcon = ({ size = 16, filled = false }: { size?: IconSize; filled?: boolean }) =>
  filled ? (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.42112 12.7912C5.09605 12.7912 4.81273 12.6687 4.57115 12.4235C4.32957 12.1785 4.20878 11.897 4.20878 11.5789V2.21234C4.20878 1.89436 4.32957 1.61281 4.57115 1.36769C4.81273 1.12256 5.09605 1 5.42112 1H12.7948C13.1128 1 13.3943 1.12256 13.6394 1.36769C13.8846 1.61281 14.0071 1.89436 14.0071 2.21234V11.5789C14.0071 11.897 13.8846 12.1785 13.6394 12.4235C13.3943 12.6687 13.1128 12.7912 12.7948 12.7912H5.42112ZM3.21234 15C2.88728 15 2.60395 14.8774 2.36237 14.6323C2.12079 14.3872 2 14.1056 2 13.7877V3.80664C2 3.64024 2.05802 3.49902 2.17405 3.38299C2.29008 3.26685 2.43124 3.20878 2.59753 3.20878C2.76383 3.20878 2.90787 3.26685 3.02966 3.38299C3.15144 3.49902 3.21234 3.64024 3.21234 3.80664V13.7877H11.1839C11.3549 13.7877 11.5001 13.8458 11.6193 13.962C11.7387 14.0783 11.7983 14.2212 11.7983 14.3908C11.7983 14.5603 11.7387 14.7043 11.6193 14.8226C11.5001 14.9409 11.3549 15 11.1839 15H3.21234Z"
        fill="var(--cds-color-grey-950)"
      />
    </svg>
  ) : (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.42112 12.7912C5.09605 12.7912 4.81273 12.6687 4.57115 12.4235C4.32957 12.1785 4.20878 11.897 4.20878 11.5789V2.21234C4.20878 1.89436 4.32957 1.61281 4.57115 1.36769C4.81273 1.12256 5.09605 1 5.42112 1H12.7948C13.1128 1 13.3943 1.12256 13.6394 1.36769C13.8846 1.61281 14.0071 1.89436 14.0071 2.21234V11.5789C14.0071 11.897 13.8846 12.1785 13.6394 12.4235C13.3943 12.6687 13.1128 12.7912 12.7948 12.7912H5.42112ZM5.42112 11.5789H12.7948V2.21234H5.42112V11.5789ZM3.21234 15C2.88728 15 2.60395 14.8774 2.36237 14.6323C2.12079 14.3872 2 14.1056 2 13.7877V3.80664C2 3.64301 2.05901 3.50245 2.17703 3.38498C2.29506 3.26751 2.43622 3.20878 2.60052 3.20878C2.76482 3.20878 2.90787 3.26751 3.02966 3.38498C3.15144 3.50245 3.21234 3.64301 3.21234 3.80664V13.7877H11.1839C11.3522 13.7877 11.4966 13.8468 11.6173 13.965C11.738 14.0832 11.7983 14.226 11.7983 14.3937C11.7983 14.5613 11.738 14.7043 11.6173 14.8226C11.4966 14.9409 11.3522 15 11.1839 15H3.21234Z"
        fill="var(--cds-color-grey-950)"
      />
    </svg>
  );

export default CopyIcon;
