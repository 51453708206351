/** @jsx jsx */
import type { SerializedStyles } from '@emotion/react';
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import type { AiCoach_Action as AiCoachAction, AiCoach_RespondToUserMessageInput } from '__generated__/graphql-types';
import type { ApolloError } from 'apollo-client';

import { useLocation } from 'js/lib/useRouter';
import { get as getUser } from 'js/lib/user';

import { breakpoints } from '@coursera/cds-core';

import type { HideMessageType } from 'bundles/ai-coach-platform/components/patterns/chat/ChatMessages';
import Conversation from 'bundles/ai-coach-platform/components/patterns/chat/Conversation';
import { useChatHistory } from 'bundles/ai-coach-platform/components/patterns/chat/ConversationHistory';
import { SettingsContextProvider } from 'bundles/ai-coach-platform/components/patterns/chat/settings/SettingsContextProvider';
import SettingsHome from 'bundles/ai-coach-platform/components/patterns/chat/settings/SettingsHome';
import StoreProviderWrapper from 'bundles/ai-coach-platform/components/patterns/chat/store/StoreProviderWrapper';
import {
  addResponseMessage,
  clearChatSession,
  sendMessage as dispatchSendMessage,
  dropMessages,
  enableMsgLoader,
  setChatSessionId,
  setChatSessionStorageKey,
} from 'bundles/ai-coach-platform/components/patterns/chat/store/dispatcher';
import { captureMessageInSentry } from 'bundles/ai-coach-platform/utils/sentry';
import { CoachTrackingDataProvider } from 'bundles/ai-coach-platform/utils/tracking';

import _t from 'i18n!nls/ai-coach-platform';

import { getErrorMessageText } from './constants';
import store from './store';
import type { GlobalState } from './store/types';
import { createChatSessionStorageKey, persistentChatSessionId } from './utils/sessionUtils';

const styles = {
  root: css`
    z-index: 3100;
    bottom: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    right: 0;
    padding: var(--cds-spacing-200);

    ${breakpoints.down('xs')} {
      padding: 0;
    }

    .coach-new-message {
      width: 100%;
    }

    .coach-input {
      height: 60px;
    }
  `,
  viewContainer: (settingsOpen: boolean) => css`
    position: relative;
    height: 100%;
    width: 100%;

    ${settingsOpen &&
    `
      /* hide conversation from screen-readers when settings open */
      .coach-conversation-container {
        display: none;
      }
    }`}
  `,
};

export type Props = {
  additionalStyles?: SerializedStyles;

  /** Context info needed for Coach interactions  */
  contextConfig?: Omit<AiCoach_RespondToUserMessageInput, 'message'> & { message?: undefined };

  /**
   * Coach mode to be used for the chat interaction
   */
  mode: string;

  emptyState?: ReactElement;

  /**
   * Custom key for identifying a chat session uniquely
   */
  chatSessionKey?: string;

  /**
   * Enable/disable storing the chat session id
   */
  storeSessionId?: boolean;

  /**
   * Enable/disable the text input field for sending messages
   */
  disableInput?: boolean;

  /**
   * Callback for when Coach is prompted i.e. via input field or action button currently, or some other prompt in future
   * Can be also used for product-specific eventing.
   */
  onSend?: () => void;

  /**
   * Callback for when chat is mounted
   * Can be also used for product-specific eventing
   */
  onOpen?: () => void;

  /**
   * Callback for when Close button is clicked or chat is unmounted.
   * Can be also used for product-specific eventing
   */
  onClose?: () => void;

  /**
   * Callback for any API errors on the send message call
   */
  onSendError?: (apolloError: ApolloError) => void;

  /**
   * Placeholder for the chat input field
   *
   * @default 'Ask me anything'
   */
  inputPlaceholder?: string;

  /**
   * Custom header
   * Pass a React element to use a custom header, or null to hide the header
   * If undefined, the default header is used
   */
  customHeader?: ReactElement | null;

  /**
   * Custom footer
   * Pass a React element to use a custom footer, undefined or null to show the default footer.
   */
  customFooter?: ReactElement | null;

  /**
   * Whether to show the chat close button
   *
   * @default true
   */
  showCloseButton?: boolean;

  /**
   * Whether to show Coach Settings button in chat header and subsequent settings UI pages
   *
   * @default false
   */
  showSettings?: boolean;

  /**
   * Whether to show the "Start a new chat" button
   *
   * @default true
   */
  showStartNewChat?: boolean;

  /**
   * Whether to show the fullscreen expand/collapse button
   */
  showFullScreenButton?: boolean;

  /**
   * Hides the message in the chat. This function accepts an individual message and its index
   * returns a boolean value if the message needs to be hidden based on the function's implementation
   * at the parent level.
   * Eg: Hiding the first "initiation" message and chat summary message for coach item use case.
   *
   * @default undefined
   */
  hideMessage?: HideMessageType;

  /**
   * Whether to autofocus on the input field when chat mounts
   *
   * @default true
   */
  autofocus?: boolean;

  /**
   * Custom aria-label for the chat container
   * @default 'Coach chat container'
   */
  'aria-label'?: string;

  /**
   * Whether the chat is a Coach Studio instance
   */
  isCoachStudio?: boolean;

  /**
   * Toggles fullscreen view
   */
  toggleFullScreen?: () => void;

  /**
   * Whether the chat is in fullscreen view
   */
  isFullScreen?: boolean;

  /**
   * Maximum number of messages of chat history to query for
   * @default 5
   */
  chatHistoryLimit?: number;
};

/**
 * Main component for the Coach chat pattern.
 *
 * Eventing: wrap this component with `<CoachTrackingDataProvider>` passing the necessary context values
 */
const CoachChat = ({
  additionalStyles,
  contextConfig,
  mode,
  emptyState,
  chatSessionKey = '',
  storeSessionId = true,
  inputPlaceholder = _t('Ask me anything'),
  customHeader,
  customFooter,
  showCloseButton = true,
  showStartNewChat = true,
  showSettings = false,
  showFullScreenButton = false,
  disableInput = false,
  autofocus = true,
  hideMessage,
  onClose,
  onSendError,
  onSend,
  onOpen,
  'aria-label': ariaLabel,
  isCoachStudio,
  toggleFullScreen,
  isFullScreen,
  chatHistoryLimit = 5,
}: Props) => {
  const location = useLocation();
  const { sessionState } = store.getState();

  const { showMessageLoader } = useSelector((state: GlobalState) => ({
    showMessageLoader: state.behavior.messageLoader,
  }));

  const isMounted = useRef<boolean>(false);

  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);

  const { chatSessionId: chatSessionIdState } = sessionState;

  const { loading: isChatHistoryLoading, refreshHistory } =
    useChatHistory({
      chatSessionId: chatSessionIdState,
      historyLimit: chatHistoryLimit,
    }) || {};

  const getSessionStorageKey = useCallback(() => {
    const locationString = location.pathname + location.search + location.query;
    const sessionStorageKeyData = chatSessionKey || locationString;

    return createChatSessionStorageKey(sessionStorageKeyData);
  }, [location, chatSessionKey]);

  useEffect(() => {
    if (isMounted.current === false) {
      isMounted.current = true;
      onOpen?.();
    }

    return () => {
      isMounted.current = false;
    };
  }, [onOpen]);

  useEffect(() => {
    // auto-close Settings when a new message is sent
    // (only from external actions currently, such as Disclosure or ExternalCoachActionButton)
    if (settingsOpen && showMessageLoader) {
      setSettingsOpen(false);
    }
  }, [showMessageLoader, settingsOpen, setSettingsOpen]);

  useEffect(() => {
    if (storeSessionId) {
      const sessionStorageKey = getSessionStorageKey();
      const sanitizedChatSessionId = persistentChatSessionId.get(sessionStorageKey);
      setChatSessionStorageKey(sessionStorageKey);

      setChatSessionId(sanitizedChatSessionId);
      dropMessages();

      if (sanitizedChatSessionId) {
        refreshHistory?.(sanitizedChatSessionId);
      } else {
        dropMessages();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, storeSessionId]); // TODO: adding `refreshHistory` as dependency results in infinite loop, needs more investigation

  const handleClose = () => {
    onClose?.();
  };

  const handleError = (apolloError: ApolloError) => {
    onSendError?.(apolloError);

    enableMsgLoader(false);
    addResponseMessage({ text: getErrorMessageText() });
    if (apolloError.graphQLErrors && apolloError.graphQLErrors?.length > 0) {
      const graphQLError = apolloError.graphQLErrors[0];
      captureMessageInSentry({ error: graphQLError, mode });
    }
  };

  const handleNewSession = () => {
    // clear the chatSessionId from the session storage
    clearChatSession();
    // clear recommended action click timestamp from session storage
    const sessionStorageKey = getSessionStorageKey();
    setChatSessionStorageKey(sessionStorageKey);
    dropMessages();
  };

  const handleSettingsClose = ({ isChatHistoryDeleted = false }: { isChatHistoryDeleted: boolean }) => {
    setSettingsOpen(false);

    if (isChatHistoryDeleted) {
      handleNewSession();
    }
  };

  return (
    <div
      data-testid="ai-coach-chat-container"
      css={[styles.root, additionalStyles]}
      aria-label={ariaLabel ?? _t('Coach chat container')}
    >
      <div css={styles.viewContainer(settingsOpen)}>
        <Conversation
          showSettings={showSettings}
          handleSettingsClick={() => setSettingsOpen(true)}
          emptyState={emptyState}
          hideMessage={hideMessage}
          customHeader={customHeader}
          sendMessage={(message: string, action: AiCoachAction) => {
            dispatchSendMessage({
              input: {
                message,
                config: {
                  coachActionId: action,
                  coachModeId: mode,
                },
                ...contextConfig,
                chatSessionId: chatSessionIdState,
              },
            })
              .catch(handleError)
              .finally(() => {
                onSend?.();
              });
          }}
          senderPlaceHolder={inputPlaceholder}
          profileClientAvatar={getUser().photo_120}
          toggleChat={handleClose} // always close because if you can see the coach close button, it will always close
          showCloseButton={showCloseButton}
          showStartNewChat={showStartNewChat}
          disabledInput={disableInput}
          autofocus={autofocus}
          handleNewSession={handleNewSession}
          isChatHistoryLoading={isChatHistoryLoading}
          courseId={contextConfig?.courseraContext?.courseId || undefined}
          customFooter={customFooter}
          isCoachStudio={isCoachStudio}
          showFullScreenButton={showFullScreenButton}
          toggleFullScreen={toggleFullScreen}
          isFullScreen={isFullScreen}
        />
        {settingsOpen ? (
          <SettingsContextProvider settingsEnabled={showSettings}>
            <SettingsHome onClose={handleSettingsClose} isCoachStudio={isCoachStudio} />
          </SettingsContextProvider>
        ) : null}
      </div>
    </div>
  );
};

const CoachChatWithStore = (props: Props) => {
  return (
    <StoreProviderWrapper>
      <CoachTrackingDataProvider value={{ data: { mode: props.mode || 'UNKNOWN_UNDEFINED' } }}>
        <CoachChat {...props} />
      </CoachTrackingDataProvider>
    </StoreProviderWrapper>
  );
};
export default CoachChatWithStore;
