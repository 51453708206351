import { graphql } from 'react-apollo';

import type { Course_Course as Course, Maybe } from '__generated__/graphql-types';

import CourseSlugAndName from 'bundles/program-common/queries/CourseSlugAndName.graphql';
import type {
  CourseSlugAndNameQuery,
  CourseSlugAndNameQueryVariables,
} from 'bundles/program-common/queries/__generated__/CourseSlugAndName';

type PropsFromCaller = {
  id: string;
};

type PropsForWithCourseSlug = {
  course: Maybe<
    | ({
        __typename?: 'Course_Course' | undefined;
      } & Pick<Course, 'id' | 'slug' | 'name'> & { link: string; homeLink: string })
    | undefined
  >;
};

export default graphql<
  PropsFromCaller,
  CourseSlugAndNameQuery,
  CourseSlugAndNameQueryVariables,
  PropsForWithCourseSlug
>(CourseSlugAndName, {
  options: ({ id }) => ({
    variables: { id },
    context: {
      clientName: 'gatewayGql',
    },
  }),
  props: ({ data }) => {
    const course = data?.Course?.queryById;
    if (!course) return { course: undefined };
    return {
      course: {
        ...course,
        link: `/learn/${course?.slug}`,
        homeLink: `/learn/${course?.slug}/home/welcome`,
      },
    };
  },
});
