import videojs from 'video.js';

class ClippedCurrentTimeDisplay extends videojs.getComponent('CurrentTimeDisplay') {
  startTime = 0;

  constructor(player, options) {
    super(player, options);
    this.el_.classList.add('customComponent-ClippedCurrentTimeDisplay');
  }

  setStartTime(value) {
    this.startTime = value;
  }

  updateContent() {
    // Allows for smooth scrubbing, when player can't keep up.
    const time = this.player_.scrubbing() ? this.player_.getCache().currentTime : this.player_.currentTime();

    this.updateTextNode_(time - this.startTime);
  }
}

videojs.registerComponent('clippedCurrentTimeDisplay', ClippedCurrentTimeDisplay);
