import * as React from 'react';

import { color } from '@coursera/coursera-ui';
import { SvgLoaderSignal } from '@coursera/coursera-ui/svg';

class WidgetListLoader extends React.Component {
  render() {
    return (
      <div className="rc-WidgetListLoader horizontal-box align-items-absolute-center p-a-2">
        <SvgLoaderSignal size={48} color={color.primary} />
      </div>
    );
  }
}

export default WidgetListLoader;
