import * as React from 'react';
import { createContext, useEffect, useState } from 'react';

import type { ApolloError } from 'apollo-client';

import type { ProfileInfoField } from 'bundles/ai-coach-platform/components/patterns/chat/settings/types';
import { ChatHistoryStatus, SettingsView } from 'bundles/ai-coach-platform/components/patterns/chat/settings/types';
import useCoachSessions from 'bundles/ai-coach-platform/components/patterns/chat/settings/useCoachSessions';
import useCoachSettings from 'bundles/ai-coach-platform/components/patterns/chat/settings/useCoachSettings';
import { SettingsNotificationProvider } from 'bundles/ai-coach-platform/components/patterns/chat/settings/useSettingsNotification';
import { captureMessageInSentry } from 'bundles/ai-coach-platform/utils/sentry';

type ProfileInfoFields = Record<
  ProfileInfoField,
  {
    enabled: boolean;
  }
>;

export type CoachSettings = {
  /** settings data per settings view page */
  data: {
    [SettingsView.PROFILE_DATA]?: {
      enabled: boolean;
      fields?: ProfileInfoFields;
    };
    [SettingsView.CHAT_HISTORY]?: {
      status: ChatHistoryStatus;
    };
  };

  /** whether any settings data is being loaded */
  loading: boolean;

  /** update settings data */
  updateData: (data: CoachSettings['data']) => void;

  error?: ApolloError | null;
};

const CoachSettingsContext = createContext<CoachSettings>({
  data: {},
  loading: true,
  updateData: (data) => undefined,
});

const SettingsContextProvider = ({
  children,
  settingsEnabled,
}: {
  children: React.ReactNode;
  settingsEnabled: boolean;
}) => {
  const [settingsData, setSettingsData] = useState<CoachSettings['data']>({});
  const { data, loading: settingsLoading, error: settingsFetchError } = useCoachSettings({ settingsEnabled });
  const { sessions, loading: sessionsLoading, error: sessionsFetchError } = useCoachSessions({ settingsEnabled });
  const [error, setError] = useState<ApolloError | null>(null);

  useEffect(() => {
    if (!settingsLoading) {
      setSettingsData((prevData) => ({
        ...prevData,
        [SettingsView.PROFILE_DATA]: {
          enabled: data.enableProfile,
          fields: {
            educationHistory: {
              enabled: data.enableEducationHistory,
            },
            workHistory: { enabled: data.enableWorkHistory },
            location: { enabled: data.enableLocation },
          },
        },
      }));
    }

    if (!sessionsLoading) {
      setSettingsData((prevData) => ({
        ...prevData,
        [SettingsView.CHAT_HISTORY]: {
          status: sessions?.length ? ChatHistoryStatus.SESSIONS_AVAILABLE : ChatHistoryStatus.SESSIONS_DELETED,
        },
      }));
    }

    if (settingsFetchError) {
      setError(settingsFetchError);
      captureMessageInSentry({ error: settingsFetchError, source: 'SettingsContextProvider' });
    }

    if (sessionsFetchError) {
      setError(sessionsFetchError);
      captureMessageInSentry({ error: sessionsFetchError, source: 'SettingsContextProvider' });
    }
  }, [
    settingsLoading,
    settingsFetchError,
    sessionsLoading,
    sessionsFetchError,
    data.enableEducationHistory,
    data.enableLocation,
    data.enableProfile,
    data.enableWorkHistory,
    sessions?.length,
  ]);

  const updateData = (newData: CoachSettings['data']) => {
    setSettingsData(newData);
  };

  return (
    <CoachSettingsContext.Provider
      value={{
        data: settingsData,
        loading: settingsLoading || sessionsLoading,
        updateData,
        error,
      }}
    >
      <SettingsNotificationProvider>{children}</SettingsNotificationProvider>
    </CoachSettingsContext.Provider>
  );
};

export { CoachSettingsContext, SettingsContextProvider };
