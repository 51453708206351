/* eslint-disable camelcase */
import type { TrackingContextData, TrackingProps } from 'js/lib/retracked';
import Retracked from 'js/lib/retracked';
import { get as getUser } from 'js/lib/user';

import { NEXT_GEN_FRIENDBUY_MERCHANT_ID, NEXT_GEN_FRIENDBUY_SCRIPTS } from 'bundles/referral/constants';

import _t from 'i18n!nls/referral';

declare global {
  interface Window {
    friendbuyAPI: unknown[] & {
      merchantId?: string;
    };
  }
}

export const trackCustomerEvent = () => {
  const user = getUser();
  if (user.authenticated) {
    window.friendbuyAPI = window.friendbuyAPI || [];
    window.friendbuyAPI.push([
      'track',
      'customer',
      {
        id: user.id?.toString(),
        email: user.email_address,
      },
    ]);
  }
};

// Export for testing
export const loadScript = (src: string, success?: (ev?: Event) => void, error?: OnErrorEventHandler) => {
  const newScript = document.createElement('script');
  const scripts = document.getElementsByTagName('script');

  newScript.type = 'text/javascript';
  newScript.async = true;
  newScript.src = src;

  if (success) {
    newScript.onload = () => {
      newScript.onload = null;
      success();
    };
  }
  if (error) {
    newScript.onerror = error;
  }

  if (scripts && scripts.length) {
    scripts?.[0]?.parentNode?.insertBefore(newScript, scripts[0]);
  } else if (window.document && window.document.body) {
    window.document.body.appendChild(newScript);
  }
};

export const loadFriendbuyScript = (success?: (ev?: Event) => void, error?: OnErrorEventHandler) => {
  window.friendbuyAPI = window.friendbuyAPI || [];
  window.friendbuyAPI.merchantId = NEXT_GEN_FRIENDBUY_MERCHANT_ID;
  window.friendbuyAPI.push(['merchant', window.friendbuyAPI.merchantId]);

  NEXT_GEN_FRIENDBUY_SCRIPTS.forEach((src) => loadScript(src, success, error));
};

export const handleScriptLoadSuccess = (
  trackingContextData?: TrackingContextData,
  ev?: Event,
  trackComponent?: (p: TrackingProps) => void
) => {
  if (trackingContextData) {
    Retracked.trackComponent(trackingContextData, { event: ev }, 'friendbuy_script', 'onload');
  } else if (trackComponent) {
    trackComponent({ trackingData: { event: ev }, trackingName: 'friendbuy_script', action: 'onload' });
  }
};

// If referred user has adblock enabled, the payment will never get sent
// back to Friendbuy, so the original person who referred them will never
// get their own discount.
export const handleScriptLoadFailure = (
  trackingContextData?: TrackingContextData,
  error?: string | Event,
  trackComponent?: (p: TrackingProps) => void
) => {
  if (trackingContextData) {
    Retracked.trackComponent(trackingContextData, { error }, 'friendbuy_script', 'onerror');
  } else if (trackComponent) {
    trackComponent({ trackingData: { error }, trackingName: 'friendbuy_script', action: 'onerror' });
  }

  // eslint-disable-next-line no-alert
  alert(
    _t(
      // Language is specifically relevant to only Courses or Specializations here
      // We are only offering discounts on those product types for now through Friendbuy referrals
      // eslint-disable-next-line no-restricted-syntax
      'It looks like you have an ad blocker enabled.\n\nPlease consider temporarily disabling your ad-blocker until you have successfully enrolled in a course or Specialization to ensure the person who referred you can receive their discount.'
    )
  );
};

export const trackSignupEvent = ({
  email,
  userId,
  onScriptFailure,
  onScriptSuccess,
}: {
  email: string;
  userId: string;
  onScriptSuccess: (ev?: Event) => void;
  onScriptFailure: OnErrorEventHandler;
}) => {
  window.friendbuyAPI = window.friendbuyAPI || [];
  window.friendbuyAPI.push([
    'track',
    'sign_up',
    {
      id: userId,
      email,
    },
  ]);

  loadFriendbuyScript(onScriptSuccess, onScriptFailure);
};
