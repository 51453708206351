/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { ButtonProps } from '@coursera/cds-core';
import { Button, Dialog, Typography2 } from '@coursera/cds-core';
import { ArrowNextIcon, CheckIcon } from '@coursera/cds-icons';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import type { MaybeCopy } from 'bundles/coursera-plus/components/subscriptionTiers/subscriptionTiersUtils';
import type { StepOneIndiaTiersModalCopy } from 'bundles/coursera-plus/components/subscriptionTiers/upgradeToPlus/upgradeCopies';
import type Course from 'bundles/naptimejs/resources/courses.v1';
import type OnDemandSpecialization from 'bundles/naptimejs/resources/onDemandSpecializations.v1';
import TrackedDiv from 'bundles/page/components/TrackedDiv';

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps>(Button);

const styles = {
  checkMark: css`
    flex-shrink: 0;
  `,
  valuePropTitle: css`
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  `,
  price: css`
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;
  `,
  list: css`
    margin-top: 8px;
    margin-bottom: 42px;
    list-style-type: none;
    padding-left: 16px;

    li {
      display: flex;
      align-items: flex-start;
      gap: 20px;
      margin-bottom: 8px;
    }
  `,
};

type ValueProps = {
  list: MaybeCopy[];
};

const ValuePropItem: React.FC<{ copy: MaybeCopy }> = ({ copy }) => {
  if (!copy) {
    return null;
  }
  return (
    <li>
      <CheckIcon size="small" color="interactive" css={styles.checkMark} />
      <span>{copy}</span>
    </li>
  );
};

const ValuePropsList: React.FC<ValueProps> = (props) => {
  return (
    <ul css={styles.list}>
      {props.list.map((valueProp) => {
        return <ValuePropItem copy={valueProp} />;
      })}
    </ul>
  );
};

type props = {
  goToNextStep: () => void;
  copy: StepOneIndiaTiersModalCopy;
  s12n?: OnDemandSpecialization;
  course?: Course;
};

const StepOneIndiaUpgradeModal: React.FC<props> = ({ goToNextStep, copy, s12n, course }) => {
  const { HeadingGroup, Content, Actions } = Dialog;
  const trackingData = { s12nId: s12n?.id, courseId: course?.id };

  return (
    <TrackedDiv
      trackingName="india_tiers_upgrade_step_one"
      data-testId="india-tiers-upgrade-step-one"
      trackClicks={false}
      withVisibilityTracking={true}
      data={trackingData}
    >
      <HeadingGroup>{copy.stepHeader}</HeadingGroup>
      <Content>
        <Typography2 component="p" css={styles.valuePropTitle}>
          {copy.youGet}
        </Typography2>
        <ValuePropsList list={copy.valuePropsList} />
        <Typography2 component="p" css={styles.price}>
          {copy.price}
        </Typography2>
      </Content>
      <Actions style={{ paddingTop: 0 }}>
        <TrackedButton
          variant="primary"
          size="medium"
          data-testid="india-tiers-upgrade-step-one-continue-button"
          trackingName="india_tiers_upgrade_step_one_continue_button"
          trackingData={trackingData}
          onClick={goToNextStep}
          icon={<ArrowNextIcon />}
          iconPosition="after"
        >
          {copy.continue}
        </TrackedButton>
      </Actions>
    </TrackedDiv>
  );
};

export default StepOneIndiaUpgradeModal;
