/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';
import type { LegacyContextType } from 'types/legacy-context-types';
import FluxibleComponent from 'vendor/cnpm/fluxible.v0-4/addons/FluxibleComponent';

import mapProps from 'js/lib/mapProps';

/* @ts-expect-error ts-migrate(7016) FIXME: Untyped import */
import setupFluxibleApp from 'js/lib/setupFluxibleApp';
import user from 'js/lib/user';
import waitFor from 'js/lib/waitFor';

import { Dialog } from '@coursera/cds-core';

import SessionSwitchBranchChangeInfo from 'bundles/course-sessions/components/SessionSwitchBranchChangeInfo';
import SessionSwitchInfo from 'bundles/course-sessions/components/SessionSwitchInfo';
import SessionSwitchSuccess from 'bundles/course-sessions/components/SessionSwitchSuccess';
import { forceEnroll, switchSession } from 'bundles/course-sessions/utils/onDemandSessionsApi';
import Naptime from 'bundles/naptimejs';
import CoursesV1 from 'bundles/naptimejs/resources/courses.v1';

/* @ts-expect-error ts-migrate(7016) FIXME: Untyped import */
import onDemandLearnerSessions from 'bundles/naptimejs/resources/onDemandLearnerSessions.v1';

/* @ts-expect-error ts-migrate(7016) FIXME: Untyped import */
import OnDemandSessionMembershipsV1 from 'bundles/naptimejs/resources/onDemandSessionMemberships.v1';
import NaptimeStore from 'bundles/naptimejs/stores/NaptimeStore';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import ApplicationStore from 'bundles/ssr/stores/ApplicationStore';

import 'css!./__styles__/SessionSwitchModal';

const styles = {
  sessionSwitchModal: css`
    .cds-Dialog-dialog {
      width: 720px;
    }
  `,
  dialogContent: css`
    div {
      padding-bottom: var(--cds-spacing-150);
    }
  `,
};

class SessionSwitchModal extends React.Component {
  static propTypes = {
    course: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    onClose: PropTypes.func.isRequired,
    courseHomeLink: PropTypes.string.isRequired,
    upcomingEnrollableSessions: PropTypes.arrayOf(PropTypes.instanceOf(onDemandLearnerSessions)).isRequired,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'NaptimeProp' does not exist on type 'typ... Remove this comment to see the full error message
    naptime: PropTypes.instanceOf(Naptime.NaptimeProp).isRequired,
    activeNotEnrollableSession: PropTypes.instanceOf(onDemandLearnerSessions),
    trackingName: PropTypes.string,
  };

  static contextTypes = {
    getStore: PropTypes.func.isRequired,
  };

  declare context: LegacyContextType<typeof SessionSwitchModal.contextTypes>;

  static defaultProps = {
    trackingName: 'session_switch_modal',
  };

  constructor(props: $TSFixMe, context: $TSFixMe) {
    super(props, context);

    this.state = {
      showBranchChangesInfo: false,
      showSuccess: false,
    };
  }

  handleSessionSwitch = (learnerSession: $TSFixMe) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'activeNotEnrollableSession' does not exi... Remove this comment to see the full error message
    const { activeNotEnrollableSession, naptime } = this.props;

    if (activeNotEnrollableSession) {
      switchSession(activeNotEnrollableSession.sessionId, learnerSession.sessionId).then(() => {
        this.setState({
          showBranchChangesInfo: false,
          showSuccess: true,
          enrolledSession: learnerSession,
        });

        naptime.refreshData({
          resources: [
            'onDemandLearnerSessions.v1',
            'onDemandSessions.v1',

            // Memberships
            'onDemandSessionMemberships.v1',
            'memberships.v1',

            // GLE resources.
            'onDemandGuidedNextSteps.v1',

            'onDemandLearnerMaterials.v1',
            'onDemandLearnerMaterialWeeks.v1',
          ],
        });
      });
    } else {
      this.enrollInSession(learnerSession);
    }
  };

  showChangesDescription = (sessionToJoin: $TSFixMe) => {
    this.setState({
      showBranchChangesInfo: true,
      showSuccess: false,
      sessionToJoin,
    });
  };

  enrollInSession = (learnerSession: $TSFixMe) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'naptime' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { naptime } = this.props;

    forceEnroll(learnerSession.sessionId)
      .then(() => {
        this.setState({
          showBranchChangesInfo: false,
          showSuccess: true,
          enrolledSession: learnerSession,
        });

        naptime.refreshData({
          resources: [
            'onDemandLearnerSessions.v1',
            'onDemandSessions.v1',

            // Memberships
            'onDemandSessionMemberships.v1',
            'memberships.v1',

            // GLE resources.
            'onDemandGuidedNextSteps.v1',

            'onDemandLearnerMaterials.v1',
            'onDemandLearnerMaterialWeeks.v1',
          ],
        });
      })
      .done();
  };

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'courseHomeLink' does not exist on type '... Remove this comment to see the full error message
    const { courseHomeLink, onClose, upcomingEnrollableSessions, trackingName, course } = this.props;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'showBranchChangesInfo' does not exist on... Remove this comment to see the full error message
    const { showBranchChangesInfo, showSuccess, sessionToJoin, enrolledSession } = this.state;
    const showSessionSwitchInfo = !showBranchChangesInfo && !showSuccess;

    const ariaLabelledBy = 'ondemand-session-switch-modal-id';

    return (
      <TrackedDiv trackingName={trackingName} withVisibilityTracking requireFullyVisible={false} trackClicks={false}>
        <Dialog
          onClose={onClose}
          variant="standard"
          open={true}
          className="rc-SessionSwitchModal"
          css={styles.sessionSwitchModal}
          aria-labelledby={ariaLabelledBy}
        >
          <Dialog.Content css={styles.dialogContent}>
            {showSessionSwitchInfo && (
              // @ts-expect-error ts-migrate(2739) FIXME: Type '{ upcomingEnrollableSessions: any; showChang... Remove this comment to see the full error message
              <SessionSwitchInfo
                upcomingEnrollableSessions={upcomingEnrollableSessions}
                showChangesDescription={this.showChangesDescription}
                handleSessionSwitch={this.handleSessionSwitch}
                id={ariaLabelledBy}
              />
            )}

            {showBranchChangesInfo && (
              <SessionSwitchBranchChangeInfo
                sessionToJoin={sessionToJoin}
                handleSessionSwitch={this.handleSessionSwitch}
                changesDescription={sessionToJoin.sessionSwitch.changesDescription}
                courseId={course.id}
                id={ariaLabelledBy}
              />
            )}

            {showSuccess && (
              // @ts-expect-error ts-migrate(2739) FIXME: Type '{ courseHomeLink: any; courseName: any; enro... Remove this comment to see the full error message
              <SessionSwitchSuccess
                courseHomeLink={courseHomeLink}
                courseName={course.name}
                enrolledSession={enrolledSession}
                id={ariaLabelledBy}
              />
            )}
          </Dialog.Content>
        </Dialog>
      </TrackedDiv>
    );
  }
}

const SessionSwitchModalWithData = _.flowRight(
  Naptime.createContainer((props) => {
    return {
      learnerSessions: onDemandLearnerSessions.finder('runningAndUpcoming', {
        params: {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'courseId' does not exist on type '{}'.
          courseIds: props.courseId,
          learnerId: user.get().id,
          // Requesting 16 sessions as that should be enough to populate the list with at least 4 joinable sessions
          // TODO(Jason): talking with jeff to see if theres a better solution on backend to send back the 4 we need
          limit: 16,
        },
        fields: [
          'startsAt',
          'endsAt',
          'enrollmentEndsAt',
          'enrollmentStartsAt',
          'isActiveEnrollment',
          'isEnrollableNow',
          'sessionSwitch',
          'isEnrolled',
        ],
      }),
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'courseId' does not exist on type '{}'.
      course: CoursesV1.get(props.courseId, {
        params: {
          showHidden: true,
        },
      }),
      sessionMemberships: OnDemandSessionMembershipsV1.finder('byUserAndCourse', {
        params: {
          userId: user.get().id,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'courseId' does not exist on type '{}'.
          courseId: props.courseId,
        },
      }),
    };
  }),
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(props: any, context: any) => bo... Remove this comment to see the full error message
  waitFor((props, context) => !!props.learnerSessions && !!props.course),
  mapProps((props) => {
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    const currentSession = props.learnerSessions.find((session) => session.isActiveEnrollment);

    let sessionsToDisplay;
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    const recommendedIndex = props.learnerSessions.findIndex((session) => session.sessionSwitch.isRecommendedSwitch);
    if (recommendedIndex === -1) {
      // take recommended, plus 3 whose enrollment is not yet open
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      sessionsToDisplay = props.learnerSessions
        .filter((session: $TSFixMe) => session.sessionSwitch.isRecommendedSwitch || !session.enrollmentStarted)
        .slice(0, 3);
    } else {
      // take recommended plus next 4
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      sessionsToDisplay = props.learnerSessions.slice(recommendedIndex, recommendedIndex + 4);
    }

    return {
      upcomingEnrollableSessions: sessionsToDisplay,
      // if their active session is still running, we'll need to unenroll them first
      activeNotEnrollableSession: currentSession && currentSession.enrollmentEnded ? currentSession : null,
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      courseHomeLink: props.course.phoenixHomeLink,
    };
  })
)(SessionSwitchModal);

const setupApp = (fluxibleContext: $TSFixMe) =>
  setupFluxibleApp(fluxibleContext, (app: $TSFixMe) => {
    app.registerStore(NaptimeStore);
    app.registerStore(ApplicationStore);

    return fluxibleContext;
  });

export default class FluxifiedSessionSwitchModal extends React.Component {
  static contextTypes = {
    fluxibleContext: PropTypes.object,
  };

  declare context: LegacyContextType<typeof FluxifiedSessionSwitchModal.contextTypes>;

  constructor(props: $TSFixMe, context: $TSFixMe) {
    super(props, context);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'fluxibleContext' does not exist on type ... Remove this comment to see the full error message
    this.fluxibleContext = setupApp(context.fluxibleContext);
  }

  render() {
    return (
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'fluxibleContext' does not exist on type ... Remove this comment to see the full error message
      <FluxibleComponent context={this.fluxibleContext.getComponentContext()}>
        <SessionSwitchModalWithData {...this.props} />
      </FluxibleComponent>
    );
  }
}
