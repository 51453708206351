import { useQuery } from '@apollo/client';

import GetPartnerNamesByIds from 'bundles/enroll/queries/GetPartnerNamesByIdsQuery.graphql';
import type {
  GetPartnerNamesByIdsQuery,
  GetPartnerNamesByIdsQueryVariables,
  PartnerFieldsFragment,
} from 'bundles/enroll/queries/__generated__/GetPartnerNamesByIdsQuery';

export type Partner = PartnerFieldsFragment;

export const useGetPartnerNamesByIds = ({ partnerIds, skip }: { partnerIds: string[]; skip?: boolean }) => {
  const { data, loading } = useQuery<GetPartnerNamesByIdsQuery, GetPartnerNamesByIdsQueryVariables>(
    GetPartnerNamesByIds,
    {
      variables: { ids: partnerIds },
      context: { clientName: 'gatewayGql' },
      skip: skip || partnerIds.length === 0,
    }
  );

  return {
    partners: data?.Partner?.queryByIds ?? undefined,
    loading,
  };
};
