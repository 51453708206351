/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { ButtonProps } from '@coursera/cds-core';
import { Button, Typography, breakpoints, useMediaQuery } from '@coursera/cds-core';
import { ArrowNextIcon } from '@coursera/cds-icons';
import type { Recommender } from '@coursera/event-pulse-types';

import { ShowMoreGridSection } from 'bundles/cds-labs/components/';
import type { GridConfig } from 'bundles/cds-labs/components/ShowMoreGridSection/ShowMoreGridSection';
import {
  CARD_LIST_BREAKPOINTS_CONFIG,
  DESKTOP_LIST_IMAGE_CONFIG,
  MOBILE_CARD_IMAGE_CONFIG,
} from 'bundles/cds-labs/components/ShowMoreGridSection/constants';
import { COLLECTION_CAROUSEL_PRODUCT_CARD_IMAGE_HEIGHT } from 'bundles/collections-carousel/constants';
import type { CarouselCollection, CollectionEntity } from 'bundles/collections-carousel/types/CarouselCollection';
import { getDataE2ETag, mapToCardProps } from 'bundles/collections-common/utils/collectionsUtils';
import useIsBrowse from 'bundles/collections-common/utils/useIsBrowse';
import useIsLohp from 'bundles/collections-common/utils/useIsLohp';
import withSingleTracked, { TrackedCdsButton } from 'bundles/common/components/withSingleTracked';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';
import { useTracker } from 'bundles/page/lib/event-pulse/react';
import ProductCardV2 from 'bundles/product-card/components/ProductCardV2';
import { useStreamlinedXDP } from 'bundles/unified-description-page-common/utils/useStreamlinedXDP';

import _t from 'i18n!nls/collections-common';

const TrackedLinkButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps<'a'>>(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  Button
);

export const styles = {
  /* TrackedLink2 adds an extra div that interferes with the card styling.
  This wrapper div applies style to that extra div to ensure the cards are styled correctly. */
  targetCardWrapper: () => css`
    height: 100%;

    > * {
      height: inherit;
    }

    .cds-ProductCard-list {
      /* temporaryly force list card to have border */
      border: 2px solid var(--cds-color-grey-50);
    }
  `,
  desktopListCard: css`
    .cds-ProductCard-listPreviewImage {
      width: ${DESKTOP_LIST_IMAGE_CONFIG.width}px;
      height: ${DESKTOP_LIST_IMAGE_CONFIG.height}px;
      max-height: ${DESKTOP_LIST_IMAGE_CONFIG.height}px !important; /* override CDS */
    }
  `,
  titleLink: css`
    color: var(--cds-color-neutral-primary);
  `,
  secondaryCta: css`
    padding-top: var(--cds-spacing-150);
  `,
  showMoreSectionMobileVariantC: css`
    .ShowMoreGridSection-button-wrapper {
      flex-direction: column;
      gap: 0;

      .pcc-secondary-cta {
        width: 100% !important;
      }
    }
  `,
  mWebContainer: css`
    margin-bottom: calc(var(--cds-spacing-600) * -1);
  `,
};

type Props = {
  collection: CarouselCollection;
  collectionIndex?: number;
  itemsGridConfig?: GridConfig;
  showAllEntries?: boolean;
  renderTitle?: () => React.ReactNode;
  productCardTrackingName?: string | null;
};

type CollectionTitleProps = { title: string; url?: string | null };
const CollectionTitle = ({ title, url }: CollectionTitleProps) => {
  const trackingName = 'collection_carousel_title_link_to_collection_page';

  const TrackedTitle = (
    <TrackedLink2 href={url} data={{ url }} trackingName={trackingName} css={styles.titleLink}>
      {title}
    </TrackedLink2>
  );
  const collectionTitle = url ? TrackedTitle : title;
  return (
    <Typography variant="h1" component="h2">
      {collectionTitle}
    </Typography>
  );
};

const ProductCardCollection = ({
  collection,
  itemsGridConfig,
  showAllEntries = false,
  collectionIndex,
  renderTitle,
  productCardTrackingName,
}: Props) => {
  const isMobile = useMediaQuery(breakpoints.down('xs'));
  const isTabletOrSmaller = useMediaQuery(breakpoints.down('sm'));
  const isMWebStreamlinedXDP = useStreamlinedXDP() && isTabletOrSmaller;
  const track = useTracker();

  const isLohp = useIsLohp();
  const isBrowse = useIsBrowse();
  const { entities = [] } = collection;
  const shouldShowDesktopListView = entities.length < 3 && !isMobile && !isTabletOrSmaller;
  const shouldShowListView = shouldShowDesktopListView || isTabletOrSmaller || isMobile;

  const showMobileButton = isLohp && isMobile;

  let expandButtonVariant: 'primary' | 'secondary' | 'ghost' = 'secondary';
  if (isLohp) expandButtonVariant = 'primary';
  if (isMWebStreamlinedXDP) expandButtonVariant = 'ghost';

  const {
    title,
    collectionLink,
    header,
    footer,
    collectionTrackingData,
    collectionId,
    expandButtonUrl,
    expandedExternalLinkButtonText,
    expandedExternalLinkButtonUrl,
  } = collection;
  const trackingId: string | undefined = collectionTrackingData?.trackingId
    ? `${collectionTrackingData?.trackingId}`
    : '';
  const trackingData = {
    title,
    collectionId,
    ...collectionTrackingData,
  };

  let trackingE2eId = '';
  if (collectionTrackingData && collectionTrackingData.collectionId) {
    trackingE2eId = collectionTrackingData.collectionId as string;
  }

  const getProductCardImageProps = (imagePropsOverride?: {
    desktop?: { height: number; width: number };
    mobile?: { height: number; width: number };
  }) => {
    if (shouldShowDesktopListView) {
      return imagePropsOverride?.desktop ?? DESKTOP_LIST_IMAGE_CONFIG;
    } else if (shouldShowListView) {
      return imagePropsOverride?.mobile ?? MOBILE_CARD_IMAGE_CONFIG;
    }
    return { height: COLLECTION_CAROUSEL_PRODUCT_CARD_IMAGE_HEIGHT };
  };

  let numDisplayedRows = isMobile ? 2 : 1;

  if (showAllEntries) {
    numDisplayedRows = entities.length;
  }
  const trackingName = productCardTrackingName ?? 'collection_product_card';

  return (
    <ShowMoreGridSection<CollectionEntity>
      className="rc-ProductCardCollection"
      title={title}
      renderTitle={renderTitle ?? (() => header || <CollectionTitle title={title} url={collectionLink} />)}
      renderFooter={() => footer}
      items={entities}
      itemsGridConfig={shouldShowListView ? CARD_LIST_BREAKPOINTS_CONFIG : itemsGridConfig}
      defaultRows={numDisplayedRows}
      spacing={{ xs: 16, sm: 24 }}
      renderItem={({ item, itemIndex, itemRef, isExpandedCollection, defaultCardsCount }) => (
        <div css={[styles.targetCardWrapper, shouldShowDesktopListView && styles.desktopListCard]}>
          <ProductCardV2
            {...mapToCardProps(item, itemIndex)}
            variant={shouldShowListView ? 'list' : 'grid'}
            productCardImageProps={getProductCardImageProps(
              item.productType === 'LEARNING PATH' ? { desktop: { height: 171, width: 171 } } : {}
            )}
            isExpandedCollection={isExpandedCollection}
            defaultCardsCount={defaultCardsCount}
            isInCollection={true}
            customLinkProps={{
              trackingName,
              withVisibilityTracking: true,
              requireFullyVisible: true,
              data: {
                itemIndex,
                collectionId,
                ...collection.collectionTrackingData,
                ...(item.trackingData || { id: item.id, item }),
                collectionIndex:
                  collectionIndex !== undefined ? collectionIndex : collection?.collectionTrackingData?.collectionIndex,
              },
              refAlt: itemRef,
            }}
            useTempStylesForTags={isBrowse}
          />
        </div>
      )}
      renderExpandButton={({ onClick, defaultLabel, isExpanded, showMoreCount, ...rest }) => {
        return (
          <TrackedCdsButton
            aria-label={`${defaultLabel} ${title}`}
            trackingName="show_more"
            trackingData={{
              ...trackingData,
              title,
              isExpanded,
              showMoreCount,
            }}
            size={isLohp && !isMobile ? 'medium' : 'small'}
            variant={expandButtonVariant}
            onClick={() => {
              track('expand_carousel', {
                carousel: {
                  id: trackingData.collectionId,
                  recommender: collectionTrackingData?.carouselRecommender as Recommender | undefined,
                },
              });
              onClick();
            }}
            fullWidth={showMobileButton}
            {...rest}
          >
            {defaultLabel}
          </TrackedCdsButton>
        );
      }}
      renderSecondaryCta={() => {
        if (!expandButtonUrl && !expandedExternalLinkButtonUrl) return null;
        return (
          <div className="pcc-secondary-cta" css={isMobile && isLohp ? styles.secondaryCta : null}>
            <TrackedLinkButton
              trackingName="secondary_CTA"
              trackingData={trackingData}
              size={isLohp && !isMobile ? 'medium' : 'small'}
              variant={isLohp || showMobileButton ? 'secondary' : 'ghost'}
              component="a"
              href={expandButtonUrl || expandedExternalLinkButtonUrl || undefined}
              icon={!isMobile && !isTabletOrSmaller ? <ArrowNextIcon /> : undefined}
              fullWidth={showMobileButton}
            >
              {!isLohp ? expandedExternalLinkButtonText : _t('View all')}
            </TrackedLinkButton>
          </div>
        );
      }}
      data-e2e={getDataE2ETag(trackingE2eId || trackingId)}
      aria-label={_t('#{title} Collection', { title: !header ? title : '' })}
      css={isMWebStreamlinedXDP ? styles.mWebContainer : undefined}
    />
  );
};
export default ProductCardCollection;
