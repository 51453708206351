import * as React from 'react';

import type { IconSize } from 'bundles/ai-coach-platform/types';

/**
 * MUI icon for "Collapse" chat action
 */
const CollapseIcon = ({ size = 16 }: { size?: IconSize }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.39791 10.6021H1.87958C1.639 10.6021 1.43222 10.5153 1.25923 10.3416C1.08641 10.168 1 9.96033 1 9.7186C1 9.47688 1.08641 9.26643 1.25923 9.08726C1.43222 8.90809 1.639 8.8185 1.87958 8.8185H6.27749C6.52507 8.8185 6.73764 8.90727 6.91518 9.08482C7.09273 9.26236 7.1815 9.47493 7.1815 9.72251V14.1204C7.1815 14.361 7.09232 14.5678 6.91396 14.7408C6.7356 14.9136 6.52198 15 6.27309 15C6.03137 15 5.82507 14.9136 5.65421 14.7408C5.48334 14.5678 5.39791 14.361 5.39791 14.1204V10.6021ZM10.6021 5.39791H14.096C14.3436 5.39791 14.5561 5.48709 14.7337 5.66545C14.9112 5.8438 15 6.05743 15 6.30632C15 6.5482 14.9112 6.7545 14.7337 6.9252C14.5561 7.09607 14.3436 7.1815 14.096 7.1815H9.69808C9.45734 7.1815 9.25055 7.09509 9.07773 6.92227C8.90491 6.74945 8.8185 6.54266 8.8185 6.30192V1.90401C8.8185 1.65643 8.90532 1.44386 9.07895 1.26632C9.25259 1.08877 9.46027 1 9.70199 1C9.94371 1 10.1542 1.08877 10.3333 1.26632C10.5125 1.44386 10.6021 1.65643 10.6021 1.90401V5.39791Z"
      fill="var(--cds-color-grey-950)"
    />
  </svg>
);

export default CollapseIcon;
