/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { graphql } from 'react-apollo';
import { Link as ReactRouterLink } from 'react-router';

import gql from 'graphql-tag';
import { compose } from 'recompose';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { Button, Link, Typography2, useTheme } from '@coursera/cds-core';
import { CloseIcon, SpinnerIcon } from '@coursera/cds-icons';

import EnterpriseExperiments from 'bundles/epic/clients/Enterprise';
import { toProgramSkillSet } from 'bundles/program-common/links';
import storageUtils from 'bundles/program-common/utils/storageUtils';
import type { PropsFromSaveSkillSetToggle } from 'bundles/program-home/components/ProgramHomeApp';
import { org_coursera_enterprise_skills_TargetSkillProfileUserGoalState as SkillProfileStates } from 'bundles/program-home/components/__generated__/globalTypes';
import type {
  EnterpriseTargetSkillProfileQuery as EnterpriseTargetSkillProfileQueryData,
  EnterpriseTargetSkillProfileQueryVariables,
} from 'bundles/program-home/components/available/__generated__/EnterpriseTargetSkillProfileQuery';
import SaveSkillSetBannerQuery from 'bundles/program-home/components/available/api/SaveSkillSetBannerQuery.graphql';
import type {
  SaveSkillSetBannerQuery as SaveSkillSetBannerQueryData,
  SaveSkillSetBannerQueryVariables,
} from 'bundles/program-home/components/available/api/__generated__/SaveSkillSetBannerQuery';
import BlueStarSvg from 'bundles/skills-common/private/star-symbol/BlueStarSvg';

import _t from 'i18n!nls/program-skills-objectives';

export const DISMISSED_SKILLSETS_DATA = 'DISMISSED_SKILLSETS_DATA';
export const SKILLSET_DISMISSED_STATE = 'DISMISSED';

export type PropsFromCaller = PropsFromSaveSkillSetToggle & {
  productCount?: number;
  userId: number;
  targetSkillProfileId?: string;
  programSlug?: string;
  programId: string;
};

export type PropsFromWithSkillSetNameAndSlugById = {
  tspTitle?: string;
  objectiveSlug?: string;
};

export type Props = PropsFromWithSkillSetNameAndSlugById & PropsFromCaller;

export const EnterpriseTargetSkillProfileQuery = gql`
  query EnterpriseTargetSkillProfileQuery($id: String!) {
    EnterpriseTargetSkillProfilesV1Resource {
      get(id: $id) {
        id
        learnerFacingTitle
        title
        slug
      }
    }
  }
`;

type SkillSetMembershipData = {
  [key: string]: string;
};

export const getUserSkillSetData = (userId: number, programId: string): Record<string, string> | undefined => {
  const userSkillSetData = storageUtils.get<SkillSetMembershipData>(userId, programId, DISMISSED_SKILLSETS_DATA, {});
  return userSkillSetData;
};

export const SaveSkillSetBanner = ({
  productCount,
  userId,
  programId,
  // TODO: this should be a required prop
  targetSkillProfileId = '',
  tspTitle,
  programSlug,
  objectiveSlug,
  onSkillSetSaveToggle,
}: Props) => {
  const theme = useTheme();
  const [isSaved, setIsSaved] = React.useState(false);
  const [isDismissed, setIsDismissed] = React.useState(false);
  const [saveInProgress, setSaveInProgress] = React.useState(false);

  // ---

  const styles = {
    container: css`
      padding: var(--cds-spacing-150) var(--cds-spacing-200);
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0 1px 4px rgb(49 49 49 / 24%);
      background-color: var(--cds-color-blue-50);
    `,
    buttonContainer: css`
      flex-shrink: 0;
      display: flex;
      align-items: center;
    `,
    saveButton: css`
      flex-shrink: 0;
    `,
    closeButton: css`
      // Hack for label-less CDS button (for now..)
      > span > span {
        margin-left: 0 !important;
      }
    `,
  };

  const onDismissClick = () => {
    setIsDismissed(true);
    let parsedUserSkillSetData = getUserSkillSetData(userId, programId);
    parsedUserSkillSetData = {
      ...parsedUserSkillSetData,
      [targetSkillProfileId]: SKILLSET_DISMISSED_STATE,
    };
    storageUtils.set(userId, programId, DISMISSED_SKILLSETS_DATA, parsedUserSkillSetData);
  };

  const onSaveClick = () => {
    if (!saveInProgress) {
      setIsSaved(!isSaved);
      onSkillSetSaveToggle({
        targetSkillProfileId,
        state: isSaved ? SkillProfileStates.UNSAVED : SkillProfileStates.SAVED,
        setSaveInProgress,
        shouldRefetch: false,
      });
    }
  };

  if (tspTitle && objectiveSlug && programSlug) {
    const href = toProgramSkillSet(programSlug, objectiveSlug);
    const bannerText = _t(
      '{productCount, plural, =1 {This course is} other {These # courses are}} part of the {tspTitle} SkillSet. Do you want to save this SkillSet to get personalized course recommendations?'
    );
    const dismissedBannerText = _t('Recommendation removed.');

    return (
      <div css={styles.container} data-testid="save-skillset-banner">
        <Typography2 component="p" variant="bodySecondary">
          <FormattedMessage
            message={isDismissed ? dismissedBannerText : bannerText}
            productCount={productCount}
            tspTitle={
              <Link component={ReactRouterLink} to={href}>
                {tspTitle}
              </Link>
            }
          />
        </Typography2>
        {!isDismissed && (
          <div css={styles.buttonContainer}>
            <Button
              variant="ghost"
              size="small"
              icon={saveInProgress ? <SpinnerIcon size="small" /> : <BlueStarSvg filled={isSaved} />}
              iconPosition="before"
              onClick={onSaveClick}
              aria-live="polite"
              data-testid="save-button"
              css={styles.saveButton}
            >
              {isSaved ? _t('Remove from saved') : _t('Save SkillSet')}
            </Button>
            {!isSaved && (
              <Button
                variant="ghost"
                size="small"
                icon={<CloseIcon size="small" color="default" />}
                title={_t('Dismiss Save SkillSet banner')}
                onClick={onDismissClick}
                data-testid="close-button"
                css={styles.closeButton}
              >
                {/* Trick for label-less CDS button for now */}
                {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                {''}
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }

  return null;
};

const SaveSkillSetBannerHookProxy = (props: Props) => <SaveSkillSetBanner {...props} />;

export const withSkillSetNameAndSlugById = compose(
  graphql<
    Pick<PropsFromCaller, 'targetSkillProfileId'>,
    EnterpriseTargetSkillProfileQueryData,
    EnterpriseTargetSkillProfileQueryVariables,
    PropsFromWithSkillSetNameAndSlugById
  >(EnterpriseTargetSkillProfileQuery, {
    skip: ({ targetSkillProfileId }) =>
      !targetSkillProfileId || EnterpriseExperiments.get('enableSkillSetsByDefaultDGS'),
    options: ({ targetSkillProfileId }) => ({
      variables: {
        // See above `skip` option.

        id: targetSkillProfileId!,
      },
    }),
    props: ({ data }) => {
      const targetSkillProfileData = data?.EnterpriseTargetSkillProfilesV1Resource?.get;
      return {
        tspTitle: targetSkillProfileData?.title,
        objectiveSlug: targetSkillProfileData?.slug,
      };
    },
  }),
  graphql<
    Pick<PropsFromCaller, 'targetSkillProfileId'>,
    SaveSkillSetBannerQueryData,
    SaveSkillSetBannerQueryVariables,
    PropsFromWithSkillSetNameAndSlugById
  >(SaveSkillSetBannerQuery, {
    skip: ({ targetSkillProfileId }) =>
      !targetSkillProfileId || !EnterpriseExperiments.get('enableSkillSetsByDefaultDGS'),
    options: ({ targetSkillProfileId }) => ({
      variables: {
        // See above `skip` option.

        id: targetSkillProfileId!,
      },
      context: {
        clientName: 'gatewayGql',
      },
    }),
    props: ({ data }) => {
      const skillset = data?.EnterpriseSkillset?.queryById;
      return {
        tspTitle: skillset?.name,
        objectiveSlug: skillset?.slug,
      };
    },
  })
);

export const enhance = compose<Props, PropsFromCaller>(withSkillSetNameAndSlugById);

export default enhance(SaveSkillSetBannerHookProxy);
