/**
 * Manage all API requests so that at most one is pending at any time.
 * If multiple requests are made while a request is pending,
 * only the most recent will be executed.
 */
import { debounce } from 'lodash';
import Q from 'q';

class RequestManager {
  private declare debounceRequest: <T>(promise: () => Q.Promise<T>) => Q.Promise<T> | false;

  private inProgress = false;

  private pendingRequest: (() => Q.Promise<unknown>) | null = null;

  private deferred: Q.Deferred<unknown> | null = null;

  constructor(delay = 500) {
    this.debounceRequest = debounce(this.run, delay);
  }

  run<T>(request: () => Q.Promise<T>): Q.Promise<T> | false {
    if (this.inProgress) {
      this.pendingRequest = request;
      return false;
    }

    const currentDeferred = (this.deferred || Q.defer<T>()) as Q.Deferred<T>;
    this.deferred = null;
    this.inProgress = true;
    this.pendingRequest = null;

    request()
      .then((...args: $TSFixMe[]) => currentDeferred.resolve(...args))
      .catch((...args: $TSFixMe[]) => currentDeferred.reject(...args))
      .finally(() => {
        this.inProgress = false;

        if (this.pendingRequest) {
          this.run(this.pendingRequest);
        }
      })
      .done();

    return currentDeferred.promise;
  }

  debouncedRun<T>(request: () => Q.Promise<T>) {
    if (!this.deferred) {
      this.deferred = Q.defer();
    }

    this.debounceRequest(request);

    return this.deferred.promise as Q.Promise<T>;
  }
}

export default RequestManager;
