export type Name = {
  prefix?: string;
  first: string;
  middle?: string;
  last: string;
  suffix?: string;
};

const nameComponents = ['prefix', 'first', 'middle', 'last'] as const;
/**
 * Concatenate a name object (that can contain prefix, first, middle, last,
 * and suffix) such that there aren't 2 spaces in the middle when there's no
 * middle name. Also handles prefex and suffix generation
 * @param  {Object} nameObj Name Object
 * @return {String}         Concatenated name
 */
function concatName(nameObj: Name) {
  const full = nameComponents
    .map((comp) => nameObj[comp])
    .filter((s) => Boolean(s))
    .join(' ');

  if (nameObj.suffix) {
    return `${full}, ${nameObj.suffix}`;
  }
  return full;
}

export default concatName;
