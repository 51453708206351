import includes from 'lodash/includes';
import isArray from 'lodash/isArray';

import constants from 'pages/open-course/common/constants';

/**
 * Determines whether a feature is blacklisted in a course using an EPIC rollout experiment.
 * Works both for plain arrays of course IDs and objects of course IDs based on environment.
 * @param {Array<string>} epicValue - Value from epic.get(namespace, experiment)
 * @param {string} courseId - The ID of the course to check.
 * @returns {boolean} - True if the feature has been blacklisted in this course.
 */
export function isBlacklistedInEpic(epicValue: $TSFixMe, courseId: $TSFixMe) {
  let blacklistedCourses = epicValue;
  if (!isArray(blacklistedCourses)) {
    blacklistedCourses = blacklistedCourses[constants.config.environment];
  }
  return !!(blacklistedCourses && includes(blacklistedCourses, courseId));
}
