import * as React from 'react';

import type { IconSize } from 'bundles/ai-coach-platform/types';

/**
 * MUI icon for "New chat" chat action
 */
const NewChatIcon = ({ size = 16 }: { size?: IconSize }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.36374 7.55691V9.21823C7.36374 9.40381 7.42654 9.55916 7.55214 9.68429C7.67774 9.80953 7.82797 9.87216 8.00282 9.87216C8.17767 9.87216 8.3299 9.80953 8.45951 9.68429C8.58911 9.55916 8.65392 9.40381 8.65392 9.21823V7.55691H10.3152C10.4943 7.55691 10.6481 7.49293 10.7765 7.36498C10.9049 7.23702 10.9692 7.08385 10.9692 6.90546C10.9692 6.72708 10.9049 6.57603 10.7765 6.45231C10.6481 6.3286 10.4943 6.26674 10.3152 6.26674H8.65392V4.58774C8.65392 4.4136 8.58941 4.26402 8.46039 4.13901C8.33137 4.014 8.17685 3.95149 7.99681 3.95149C7.82196 3.95149 7.67273 4.014 7.54914 4.13901C7.42554 4.26402 7.36374 4.4136 7.36374 4.58774V6.26674H5.68475C5.51072 6.26674 5.36114 6.32966 5.23601 6.45549C5.111 6.58121 5.0485 6.73326 5.0485 6.91165C5.0485 7.09003 5.111 7.2422 5.23601 7.36816C5.36114 7.49399 5.51072 7.55691 5.68475 7.55691H7.36374ZM3.7053 12.8413L1.74353 14.8031C1.54323 15.0034 1.31053 15.0509 1.04542 14.9455C0.780318 14.8402 0.647766 14.6396 0.647766 14.3436V2.29017C0.647766 1.95178 0.778197 1.65216 1.03906 1.39129C1.29992 1.13043 1.59955 1 1.93794 1H14.062C14.4004 1 14.7001 1.13043 14.9609 1.39129C15.2218 1.65216 15.3522 1.95178 15.3522 2.29017V11.5512C15.3522 11.8897 15.2218 12.1893 14.9609 12.45C14.7001 12.7109 14.4004 12.8413 14.062 12.8413H3.7053ZM3.15742 11.5512H14.062V2.29017H1.93794V12.8767L3.15742 11.5512Z"
      fill="var(--cds-color-grey-950)"
    />
  </svg>
);

export default NewChatIcon;
