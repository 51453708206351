/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useRef } from 'react';

import { Menu } from 'bundles/authoring/common/components/Menu';
import BodyMenu from 'bundles/cml/editor/components/buttons/typography/BodyMenu';
import HeadingMenu from 'bundles/cml/editor/components/buttons/typography/HeadingMenu';
import { getMenuDirectionProps } from 'bundles/cml/editor/utils/menuUtils';
import type { ToolsKeys } from 'bundles/cml/shared/utils/customTools';

type Props = {
  anchorEl?: HTMLElement;
  tools: ToolsKeys[];
  open: boolean;
  handleClose: React.ReactEventHandler<Element>;
  isFocusVisible: boolean;
  menuDirection?: 'top' | 'bottom';
};

const styles = {
  hideFocusOutline: css`
    .cds-buttonBase-focusVisible::after {
      display: none !important;
    }
  `,
};

const TypographyMenu: React.FC<Props> = ({
  anchorEl,
  open,
  handleClose,
  tools,
  menuDirection,
  isFocusVisible,
}: Props) => {
  const hideFocusOutline = useRef(!isFocusVisible);

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      {...getMenuDirectionProps(menuDirection)}
      disableScrollLock={true}
      className="rc-CMLEditorMenu"
      css={hideFocusOutline.current && styles.hideFocusOutline}
    >
      <HeadingMenu tools={tools} css={hideFocusOutline.current && styles.hideFocusOutline} />
      <BodyMenu tools={tools} css={hideFocusOutline.current && styles.hideFocusOutline} />
    </Menu>
  );
};

export default TypographyMenu;
