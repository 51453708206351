import type { Descendant } from 'slate';
import { Element } from 'slate';
import { jsx } from 'slate-hyperscript';

import Retracked from 'js/lib/retracked';

import { DEFAULT_LINK_HREF } from 'bundles/cml/editor/components/buttons/link/linkUtils';
import { deserializeImageDataTransferItem, deserializeImageDataURI } from 'bundles/cml/editor/html/deserializeImages';
import { isEmptySpacerMSWord } from 'bundles/cml/editor/html/msWordUtils';
import type { Options } from 'bundles/cml/editor/html/types';
import type { NotificationMessage } from 'bundles/cml/editor/types/notification';
import { DEFAULT_HEADING_VARIANTS } from 'bundles/cml/editor/utils/headingUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { HeadingLevel } from 'bundles/cml/shared/types/coreTypes';
import { BODY1, BODY2 } from 'bundles/cml/shared/types/coreTypes';
import type {
  BlockElement,
  ImageUploaderElement,
  InlineElement,
  LinkElement,
  ListElement,
  ListItemElement,
} from 'bundles/cml/shared/types/elementTypes';

import _t from 'i18n!nls/cml';

const HEADINGS = new Set(['H1', 'H2', 'H3', 'H4']);

const DEFAULT_FONT_SIZE = 10;

// ignore these invalid tags for eventing because they are not meaningful for us to track at the moment
// see https://coursera.slack.com/archives/C02ATK66C66/p1646773984329589
const INVALID_TAGS_WHITELIST = new Set(['DIV', 'META', 'BR', 'COL', 'COLGROUP', 'INPUT', 'ASSET', 'SPAN']);

const INVALID_CUSTOM_TAGS = [
  'O:', // custom tag prefix from other editors like word/gdocs e.g. <O:P>
  'V:',
];

const INVALID_TAGS_NOTIFICATIONS: Record<string, () => NotificationMessage> = {
  MATH: () => ({
    type: 'warning',
    message: _t(
      'The pasted content contained <math>, and was converted to plain-text. Please use the Math button in the toolbar to add TeX formatting.'
    ),
  }),
};

const ELEMENT_TAGS: Record<
  string,
  (el: HTMLElement, images: DataTransferItem[], options: Options) => Partial<BlockElement | InlineElement> | null
> = {
  A: (el: HTMLElement) => {
    const href = el.getAttribute('href');
    if (!href || href === '#') {
      return null;
    }

    return { type: BLOCK_TYPES.LINK, isInline: true, href, title: el.getAttribute('title') ?? undefined };
  },
  H1: () => ({ type: BLOCK_TYPES.HEADING, level: '1' }),
  H2: () => ({ type: BLOCK_TYPES.HEADING, level: '2' }),
  H3: () => ({ type: BLOCK_TYPES.HEADING, level: '3' }),
  H4: () => ({ type: BLOCK_TYPES.HEADING, level: '4' }),
  OL: () => ({ type: BLOCK_TYPES.NUMBER_LIST }),
  UL: () => ({ type: BLOCK_TYPES.BULLET_LIST }),
  LI: () => ({ type: BLOCK_TYPES.LIST_ITEM }),
  TABLE: () => ({ type: BLOCK_TYPES.TABLE, headless: false }),
  TR: () => ({ type: BLOCK_TYPES.TABLE_ROW }),
  TD: () => ({ type: BLOCK_TYPES.TABLE_CELL }),
  TH: () => ({ type: BLOCK_TYPES.TABLE_CELL }),
  P: (el: HTMLElement) => {
    // A copied element from GDocs and MsWord is a <span> wrapped in a <p> tag and the style is associated with the <span>.
    const fontSizeEl = (el.firstElementChild as HTMLElement) ?? el;
    const fontSize = parseInt(fontSizeEl.style.fontSize, 10);
    const isPixelUnit = ['pt', 'px'].some((value) => fontSizeEl.style.fontSize.endsWith(value));

    return {
      type: BLOCK_TYPES.TEXT,
      variant: fontSize < DEFAULT_FONT_SIZE && isPixelUnit ? BODY2 : BODY1,
    };
  },
  IMG: (el: HTMLElement, images: DataTransferItem[], { eventingData }: Options): ImageUploaderElement | null => {
    const img = el as HTMLImageElement;
    const isDataURI = img.src.startsWith('data:image');
    const isFileURI = img.src.startsWith('file:///');

    if (eventingData) {
      Retracked.trackComponent(
        eventingData,
        { is_data_uri: isDataURI, is_file_uri: isFileURI } /* eslint-disable-line camelcase */,
        'cml_editor',
        'image_paste'
      );
    }

    if (isDataURI) {
      return deserializeImageDataURI(img.src);
    }

    if (isFileURI) {
      const image = images.shift();
      return deserializeImageDataTransferItem(image);
    }

    return {
      type: BLOCK_TYPES.IMAGE_UPLOADER,
      src: img.src,
      alt: img.alt,
      isVoid: true,
      children: [{ text: '' }],
    };
  },
};

const deserializeElementsImpl = (
  el: HTMLElement,
  images: DataTransferItem[],
  options: Options,
  children: Descendant[]
): null | Descendant | Descendant[] => {
  const { setNotification, eventingData } = options;
  let { nodeName } = el;

  // treat headings inside list items as PARAGRAPHS until we support them
  if (HEADINGS.has(nodeName) && el.parentElement?.nodeName === 'LI') {
    nodeName = BLOCK_TYPES.TEXT;
  }

  if (el.nodeName === 'BODY') {
    return jsx('fragment', {}, children);
  }

  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el, images, options);
    if (!attrs) {
      return null;
    }

    if (!children.length) {
      if (nodeName === 'A') {
        const linkAttrs = attrs as Partial<LinkElement>;
        children.push({ text: linkAttrs.href || DEFAULT_LINK_HREF });
      } else if (nodeName === 'TD' || nodeName === 'TH') {
        children.push({ type: BLOCK_TYPES.TEXT, children: [{ text: '' }] });
      }
    }

    return jsx('element', attrs, children);
  }

  if (INVALID_TAGS_WHITELIST.has(nodeName) || INVALID_CUSTOM_TAGS.some((customTag) => nodeName.startsWith(customTag))) {
    return null;
  }

  const invalidTagWithNotification = INVALID_TAGS_NOTIFICATIONS[nodeName.toUpperCase()];
  if (invalidTagWithNotification) {
    setNotification(invalidTagWithNotification());
    return null;
  }

  if (eventingData) {
    Retracked.trackComponent(eventingData, { invalidTag: el.tagName }, 'cml_editor', 'invalid_paste');
  }

  return null;
};

const deserializeGoogleDocsElements = (
  el: HTMLElement,
  images: DataTransferItem[],
  options: Options,
  children: Descendant[]
): null | Descendant | Descendant[] => {
  return deserializeElementsImpl(el, images, options, children);
};

const WORD_LIST_ATTRIBUTES = ['aria-level', 'aria-posinset', 'data-aria-level', 'data-aria-posinset'];
const getWordListAttributes = (el: HTMLLIElement) => {
  return WORD_LIST_ATTRIBUTES.reduce((result: Record<string, string>, key) => {
    const attr = el.getAttribute(key);
    if (attr) {
      result[key] = attr; // eslint-disable-line no-param-reassign
    }
    return result;
  }, {}) as Partial<ListItemElement>;
};

const deserializeWordElements = (
  el: HTMLElement,
  images: DataTransferItem[],
  options: Options,
  children: Descendant[]
): null | Descendant | Descendant[] => {
  // MS Word does not use the standard heading tag but a combination of `role` + `aria-level`
  if (el.getAttribute('role') === 'heading') {
    const level = (el.getAttribute('aria-level') ?? '1') as HeadingLevel;
    return jsx('element', { type: BLOCK_TYPES.HEADING, level, variant: DEFAULT_HEADING_VARIANTS[level] }, children);
  }

  if (el.nodeName === 'P' && isEmptySpacerMSWord(el)) {
    // [CP-7098] ignore empty spacer paragraphs since we have paragraph spacing already,
    // otherwise we get double spacing
    return null;
  }

  if (el.nodeName === 'LI') {
    return jsx('element', { type: BLOCK_TYPES.LIST_ITEM, ...getWordListAttributes(el as HTMLLIElement) }, children);
  }

  return deserializeElementsImpl(el, images, options, children);
};

const deserializeGenericElements = (
  el: HTMLElement,
  images: DataTransferItem[],
  options: Options,
  children: Descendant[]
): null | Descendant | Descendant[] => {
  const { nodeName } = el;
  if (nodeName === 'UL' || nodeName === 'OL') {
    // Pages sneaks in empty spans in between list items COREAUTH-4150
    const attrs = ELEMENT_TAGS[nodeName](el, images, options) as Partial<ListElement>;
    const listItems = children.filter((child) => Element.isElement(child) && child.type === BLOCK_TYPES.LIST_ITEM);
    return jsx('element', attrs, listItems);
  }

  return deserializeElementsImpl(el, images, options, children);
};

export const deserializeElements = (
  el: HTMLElement,
  images: DataTransferItem[],
  options: Options,
  children: Descendant[]
): null | Descendant | Descendant[] => {
  if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const { isWord, isGoogleDocs } = options;
  if (isWord) {
    return deserializeWordElements(el, images, options, children);
  }

  if (isGoogleDocs) {
    return deserializeGoogleDocsElements(el, images, options, children);
  }

  return deserializeGenericElements(el, images, options, children);
};
