/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useRef } from 'react';

import { SimpleLinkService } from 'bundles/cml/shared/components/asset/pdfViewer/PdfLinkService';
import { usePdfViewerContext } from 'bundles/cml/shared/components/asset/pdfViewer/PdfViewerContext';
import { pdfjsStyles } from 'bundles/cml/shared/components/asset/pdfViewer/pdfjsStyles';

export const AnnotationLayer = () => {
  const annotationLayerDiv = useRef<HTMLDivElement>(null);
  const { currentPage, pdfJsLib, zoom } = usePdfViewerContext();

  useEffect(() => {
    if (!annotationLayerDiv.current || !pdfJsLib) {
      return;
    }

    currentPage?.getAnnotations().then((annotations) => {
      if (annotationLayerDiv.current) {
        // we need to manually remove any existing annotations
        annotationLayerDiv.current.innerHTML = '';
      }
      const viewport = currentPage.getViewport({ scale: zoom });

      const annotationLayer = new pdfJsLib.AnnotationLayer({
        div: annotationLayerDiv.current,
        accessibilityManager: null,
        annotationCanvasMap: null,
        annotationEditorUIManager: null,
        annotations,
        page: currentPage,
        viewport: viewport.clone({ dontFlip: true }),
      });

      annotationLayer.render({
        annotations,
        imageResourcesPath: '',
        renderForms: false,
        linkService: new SimpleLinkService(),
        downloadManager: null,
        annotationStorage: null,
        enableScripting: false,
        hasJSActions: false,
        fieldObjects: null,
      });
    });
  }, [currentPage, pdfJsLib, zoom]);

  return <div ref={annotationLayerDiv} css={pdfjsStyles.annotationLayer} data-testid="annotationLayer" />;
};
