import * as React from 'react';

import PropTypes from 'prop-types';

import { StyleSheet, color, css } from '@coursera/coursera-ui';

/**
 * A simple divider that can accept children
 * Sample Usage:
 * <Divider />
 */

const styles = StyleSheet.create({
  Divider: {
    margin: 0,
    height: 1,
    border: 'none',
    backgroundColor: color.divider,
  },
  bgThemeDark: {
    backgroundColor: color.dividerThemeDark,
  },
  flex1: {
    flex: 1,
  },
});

const Divider = ({ htmlAttributes = {}, isThemeDark, style, inset, children, isDoubleInset }: $TSFixMe) => {
  let singleDivider = (
    <hr
      {...htmlAttributes}
      {...css(styles.Divider, isThemeDark && styles.bgThemeDark)}
      style={{
        marginLeft: inset,
        marginRight: isDoubleInset && inset,
        ...style,
      }}
    />
  );

  if (!children) {
    return singleDivider;
  }

  singleDivider = (
    <hr
      {...htmlAttributes}
      {...css(styles.Divider, isThemeDark && styles.bgThemeDark, styles.flex1)}
      style={{
        marginLeft: inset,
        ...style,
      }}
    />
  );

  return (
    <div className="horizontal-box align-items-spacebetween" aria-hidden="true">
      {singleDivider}
      {children}
      {singleDivider}
    </div>
  );
};

// Explicity declare the default props for documentation purpose
Divider.defaultProps = {
  htmlAttributes: {},
  style: {},
  inset: 0,
  isDoubleInset: false,
};

Divider.propTypes = {
  // Additional control for the root element, can add data-e2e, ariaLabel...
  htmlAttributes: PropTypes.object,
  // Whether divder has parent element with dark bg
  isThemeDark: PropTypes.bool,
  // Whether divider will be indented
  inset: PropTypes.number,
  // whether divider is indented from both sides
  isDoubleInset: PropTypes.bool,
  // overwrite the inline-styles of the root element
  style: PropTypes.object,
  // If childen exists, we'll render a box which has left and right divider and children in the middle
  children: PropTypes.node,
};

export default Divider;
