import { createContext } from 'react';
import type React from 'react';

type MenuContextType = {
  className?: string;
  container?: React.ReactInstance | (() => React.ReactInstance | null) | null;
  onClose: (event: React.MouseEvent<Element>, reason: string) => void;
};

export const MenuContext = createContext<MenuContextType>({
  onClose: () => {},
});

export const formatClassNames = (className: string): string => {
  const classNames = className.split(' ');
  return classNames.map((name: string) => `.${name}`).join('');
};
