import URI from 'jsuri';
import Q from 'q';

import Api from 'js/lib/api';

import type { AssetConfig } from '../types/assets';

const authoringAssetsApi = Api('/api/authoringAssets.v1/', { type: 'rest' });
const assetsApi = Api('/api/assets.v1/', { type: 'rest' });
const fields = [
  'name',
  'filename',
  'createdAt',
  'typeName',
  'pageCount',
  'audioDuration',
  'videoDuration',
  'isDescriptionAutoGenerated',
  'autoGenDescriptionConfidenceLevel',
  'tags',
];
type AssetIds = Array<string>;
export type UpdateAssetDataPayload = {
  courseId: string;
  assetId: string;
  description: string;
  longDescription: string;
  name: string;
};

export const getAssetsListByContext = (config: AssetConfig) => {
  const uri = new URI()
    .addQueryParam('q', 'listByCourse')
    .addQueryParam('courseId', config.courseId)
    .addQueryParam('limit', config.pageSize ?? 20)
    .addQueryParam('start', config.startIndex ?? 0)
    .addQueryParam('fields', fields.join(','));

  if (config.assetType) {
    uri.addQueryParam('typeName', config.assetType);
  }

  if (config.keyword) {
    uri.addQueryParam('name', config.keyword);
  }

  return Q(authoringAssetsApi.get(uri.toString())).then((response) => {
    if (response.errorCode) {
      return null;
    }
    return response;
  });
};

export const getAssetsById = (ids: AssetIds) => {
  const uri = new URI()
    .addQueryParam('ids', ids.join())
    .addQueryParam(
      'fields',
      'name,filename,typeName,tags,audioSourceUrls,videoSourceUrls,videoThumbnailUrls,fileExtension,isDescriptionAutoGenerated,autoGenDescriptionConfidenceLevel'
    );

  // use the `assets.v1` Api to accommodate both learner-side and partner-side calls
  return Q(assetsApi.get(uri.toString()));
};

export const getAssetById = (id: string) => {
  const uri = new URI(id).addQueryParam('fields', 'name,filename,typeName,tags');

  // use the `assets.v1` Api to accommodate both learner-side and partner-side calls
  return Q(assetsApi.get(uri.toString()));
};

export const updateNameAndDescription = ({
  courseId,
  assetId,
  name,
  description,
  longDescription,
}: UpdateAssetDataPayload) => {
  const uri = new URI()
    .addQueryParam('action', 'updateNameAndDescription')
    .addQueryParam('courseId', courseId)
    .addQueryParam('assetId', assetId);

  return Q(authoringAssetsApi.post(uri.toString(), { data: { name, description, longDescription } }));
};

export const deleteAssets = (assetIds: AssetIds) => {
  const uri = new URI().addQueryParam('action', 'hide').addQueryParam('assetIds', assetIds.join(','));
  return Q(authoringAssetsApi.post(uri.toString()));
};
