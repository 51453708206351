import { useId } from '@coursera/cds-common';

import Divider from '@core/Divider';
import Typography from '@core/Typography2';

import { GroupContext } from './context';
import { classes } from './styles/menuListCss';

export type Props = {
  /**
   * Group label.
   */
  label: string;

  /**
   * Individual `MenuList.Item` or `MenuList.ItemLink` elements used as menu items of this group.
   */
  children: React.ReactNode;
};

function Group(props: Props) {
  const { label, children } = props;
  const id = useId();
  const groupLabelId = `${id}-label`;
  return (
    <>
      <li className={classes.groupDivider} role="presentation">
        <Divider />
      </li>
      <li role="presentation">
        <Typography
          className={classes.groupLabel}
          component="div"
          id={groupLabelId}
          variant="subtitleMedium"
        >
          {label}
        </Typography>
        <GroupContext.Provider value={{ label, id }}>
          <ul
            aria-labelledby={groupLabelId}
            className={classes.groupList}
            role="group"
          >
            {children}
          </ul>
        </GroupContext.Provider>
      </li>
    </>
  );
}

export default Group;
