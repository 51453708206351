import type { Editor, Node, NodeEntry } from 'slate';
import { Text as SlateText, Transforms } from 'slate';

import { ALL_MARKS, MARKS_TO_TOOLS } from 'bundles/cml/editor/normalize/constants';
import type { Options } from 'bundles/cml/editor/normalize/types';
import type { MARK_VALUES } from 'bundles/cml/shared/constants';

export const normalizeMarks = (editor: Editor, { tools }: Options, nodeEntry: NodeEntry<Node>): boolean => {
  if (!SlateText.isText(nodeEntry[0])) {
    return false;
  }

  const [text, path] = nodeEntry;
  const unsupportedMarks = ALL_MARKS.filter((mark: MARK_VALUES) => {
    if (text[mark] === true) {
      const tool = MARKS_TO_TOOLS[mark];
      return !tools.has(tool);
    }
    return false;
  });

  if (!unsupportedMarks.length) {
    return false;
  }

  Transforms.unsetNodes(editor, unsupportedMarks, { at: path, mode: 'lowest' });
  return true;
};
