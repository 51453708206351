import * as React from 'react';

import { compose, withStateHandlers } from 'recompose';

import { Typography } from '@coursera/cds-core';
import { Button } from '@coursera/coursera-ui';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import CourseReviewPaginatedList from 'bundles/coursera-ui/components/rich/MiniXDP/CourseReviewPaginatedList';
import HorizontalCourseRatingBlock from 'bundles/coursera-ui/components/rich/MiniXDP/HorizontalCourseRatingBlock';
import Highlights from 'bundles/enterprise-legacy-xdp/components/cdp/courseReviewOverview/Highlights';
import TopReviews from 'bundles/enterprise-legacy-xdp/components/cdp/courseReviewOverview/TopReviews';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import type { RatingsObject } from 'bundles/program-common/types/xdpTypes';

import _t from 'i18n!nls/coursera-ui';

type PropsFromCaller = {
  id: string;
  ratings: RatingsObject | null;
  topReviews?: Array<any> | null;
  reviewHighlights?: Array<any> | null;
  isProject?: boolean;
};

type PropsFromWithStateHandlers = {
  showMoreReviews: boolean;
};

type StateHandlers = {
  onToggleShowReviews: () => Partial<PropsFromWithStateHandlers> | undefined;
};

type Props = PropsFromCaller & PropsFromWithStateHandlers & StateHandlers;

const TrackedButton = withSingleTracked({ type: 'BUTTON' })(Button);

class CourseReviewPaginatedOverview extends React.PureComponent<Props> {
  render() {
    const { id, ratings, reviewHighlights, topReviews, showMoreReviews, onToggleShowReviews, isProject } = this.props;
    const showTopReviewsSection =
      (reviewHighlights && reviewHighlights.length > 0) || (topReviews && topReviews.length > 0);
    return (
      <TrackedDiv
        className={`CourseReviewOverview bg-${isProject ? 'white' : 'light-blue-2'}`}
        trackingName="social_proof_course_reviews"
        data={{ courseId: id }}
        requireFullyVisible={true}
        withVisibilityTracking={true}
      >
        {ratings && <HorizontalCourseRatingBlock {...ratings} />}
        {showTopReviewsSection && (
          <Typography variant="h2semibold" component="h3" className="m-b-2 m-t-2">
            {_t('Top Reviews')}
          </Typography>
        )}
        {reviewHighlights && reviewHighlights.length > 0 && <Highlights highlights={reviewHighlights} />}
        {topReviews && topReviews.length > 0 && <TopReviews topReviews={topReviews} />}
        {topReviews && topReviews.length > 0 && (
          <TrackedButton
            trackingName={showMoreReviews ? 'reviews_button_show_less' : 'reviews_button_show_more'}
            trackingData={{ courseId: id }}
            size="zero"
            type="link"
            label={showMoreReviews ? _t('Show Less') : _t('Show More')}
            onClick={onToggleShowReviews}
            htmlAttributes={{ 'aria-label': showMoreReviews ? _t('Show less reviews') : _t('Show more reviews') }}
          />
        )}
        <div
          className={showMoreReviews ? ' m-t-2 overflow-hidden border-top' : 'm-t-2 overflow-hidden'}
          style={{ display: showMoreReviews ? 'block' : 'none' }}
        >
          <Typography variant="h2semibold" component="h3" className="m-b-2 m-t-2">
            {_t('All Reviews')}
          </Typography>
          <CourseReviewPaginatedList courseId={id} />
        </div>
      </TrackedDiv>
    );
  }
}

export default compose<Props, PropsFromCaller>(
  withStateHandlers<PropsFromWithStateHandlers, StateHandlers, PropsFromCaller>(
    ({ topReviews }) => ({
      showMoreReviews: !(topReviews && topReviews.length > 0), // automatically expand "all reviews" section if there's no top reviews
    }),
    {
      onToggleShowReviews:
        ({ showMoreReviews }) =>
        () => ({ showMoreReviews: !showMoreReviews }),
    }
  )
)(CourseReviewPaginatedOverview);
