import * as React from 'react';

import localStorageEx from 'bundles/common/utils/localStorageEx';

export const COACH_CHAT_HISTORY_DELETED = 'AiCoachPlatform_ChatHistoryDeleted';

export const DELETE_WAIT_DURATION = 30 * 1000; // milliseconds to wait after the delete all operation

const isChatHistoryDeleted = (): { timestamp: number } | null => {
  return localStorageEx.getItem<{ timestamp: number } | null>(COACH_CHAT_HISTORY_DELETED, JSON.parse, null);
};

const setChatHistoryDeleted = (timestamp: number) => {
  localStorageEx.setItem(COACH_CHAT_HISTORY_DELETED, { timestamp }, JSON.stringify);
};

const unsetChatHistoryDeleted = () => {
  localStorageEx.removeItem(COACH_CHAT_HISTORY_DELETED);
};

/**
 * Hook that temporarily saves a timestamp to localStorage after "Delete All" chat history operation,
 * used to derive if DELETE_WAIT_DURATION has elapsed before taking some other actions such as
 * re-enabling the Delete button, or showing some messaging in future.
 */
export const useChatHistoryDeleteAll = () => {
  const [isDurationElapsed, setDurationElapsed] = React.useState<boolean>(true);

  React.useEffect(() => {
    const deletedTimestamp = isChatHistoryDeleted()?.timestamp;

    // every time this page mounts, we want to check if there was a deletion in
    // the last DELETE_WAIT_DURATION, and keep delete button disabled
    if (deletedTimestamp) {
      const now = Date.now();
      const isDurationAgo = now - DELETE_WAIT_DURATION;

      if (deletedTimestamp < isDurationAgo) {
        // remove from localStorage
        unsetChatHistoryDeleted();

        setDurationElapsed(true);
      } else {
        setDurationElapsed(false);
      }
    }
  }, []);

  return {
    setChatHistoryDeleted,
    isDurationElapsed,
    unsetChatHistoryDeleted,
  };
};

export default useChatHistoryDeleteAll;
