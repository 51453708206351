/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { RadioGroup, Typography2 } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

import WidgetListLoader from 'bundles/author-questions/components/editors/widget/WidgetListLoader';
import WidgetSelectionListHeader from 'bundles/author-questions/components/editors/widget/WidgetSelectionListHeader';
import WidgetSelectionListItem from 'bundles/author-questions/components/editors/widget/WidgetSelectionListItem';
import type { AuthoringWidgetSummariesV1 } from 'bundles/widget-admin/types/WidgetTypes';

import _t from 'i18n!nls/widget-admin';

const styles = {
  root: () =>
    css({
      minHeight: 250,
    }),
  container: (theme: Theme) =>
    css({
      height: 300,
      overflowY: 'auto',
      overflowX: 'hidden',
      borderBottom: `1px solid var(--cds-color-neutral-stroke-primary)`,
      borderTop: `1px solid var(--cds-color-neutral-stroke-primary)`,
    }),

  emptyList: (theme: Theme) =>
    css({
      textAlign: 'center',
      paddingTop: 'var(--cds-spacing-600)',
    }),
};

type PropsFromCaller = {
  onWidgetSelected: (id: string) => void;
  searchKeyword: string;
  selectedWidgetId: string;
  widgets: AuthoringWidgetSummariesV1[];
  emptyMessage?: string;
  isWidgetListLoaded: boolean;
};

type Props = {
  isWidgetListLoaded: boolean;
} & PropsFromCaller;

const WidgetSelectionList: React.FC<Props> = ({
  onWidgetSelected,
  searchKeyword,
  selectedWidgetId,
  widgets,
  emptyMessage = _t('No plugins are currently available for use.'),
  isWidgetListLoaded,
}) => {
  const isEmpty = widgets.length === 0;

  const renderList = () => {
    if (isWidgetListLoaded && isEmpty) {
      return (
        <Typography2 component="p" variant="bodyPrimary" css={styles.emptyList}>
          {emptyMessage}
        </Typography2>
      );
    }

    if (!isWidgetListLoaded) {
      return <WidgetListLoader />;
    }

    return (
      <div data-testid="widgetSelectionList">
        <WidgetSelectionListHeader />
        <div css={styles.container}>
          <RadioGroup aria-label={_t('Select plugin')} value={selectedWidgetId}>
            {widgets.map((widget) => {
              const widgetName = widget.name.toLowerCase();
              if (widgetName.indexOf(searchKeyword) === -1) {
                return null;
              }
              return <WidgetSelectionListItem key={widget.id} widget={widget} onWidgetSelected={onWidgetSelected} />;
            })}
          </RadioGroup>
        </div>
      </div>
    );
  };

  return <div css={styles.root}>{renderList()}</div>;
};
export default WidgetSelectionList;
