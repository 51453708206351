import { default as Root } from './ActionOverflow';
import ActionOverflowGroup from './ActionOverflowGroup';
import ActionOverflowItem from './ActionOverflowItem';

export type { Props as ActionOverflowProps } from './ActionOverflow';
export type { Props as ActionOverflowItemProps } from './ActionOverflowItem';
export type { Props as ActionOverflowGroupProps } from './ActionOverflowGroup';

type RootType = typeof Root;

export interface ActionOverflowType extends RootType {
  Item: typeof ActionOverflowItem;
  Group: typeof ActionOverflowGroup;
}

/**
 * ActionOverflow exposes additional hidden actions due to space constraints or lower hierarchical importance.
 * A menu gets triggered when the user interacts with the action overflow button.
 *
 * See [Props](__storybookUrl__/components-inputs-ActionOverflow--default#props)
 *
 *
 * Anatomy:
 *
 * ```jsx
 * <ActionOverflow>
 *   <ActionOverflow.Item>Peer review</ActionOverflow.Item>
 *   <ActionOverflow.Item>LTI item</ActionOverflow.Item>
 *   <ActionOverflow.Group label="Import items">
 *     <ActionOverflow.Item>From another Coursera course</ActionOverflow.Item>
 *     <ActionOverflow.Item>From LMS (Canvas)</ActionOverflow.Item>
 *   </ActionOverflow.Group>
 * </ActionOverflow>
 * ```
 *
 * @see ActionOverflow.Item
 * @see ActionOverflow.Group
 */
const ActionOverflow: ActionOverflowType = Object.assign(Root, {
  Item: ActionOverflowItem,
  Group: ActionOverflowGroup,
});

export default ActionOverflow;
