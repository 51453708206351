import * as React from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import type { Program, ThirdPartyOrganization } from 'bundles/enroll-course/common/Enterprise';
import type OnDemandSpecializationsV1 from 'bundles/naptimejs/resources/onDemandSpecializations.v1';
import { getS12nCertificateName, getS12nVariantLabel } from 'bundles/s12n-common/lib/s12nProductVariantUtils';
import PaymentChoice from 'bundles/s12n-enroll/components/bulk-pay/PaymentChoice';

import _t from 'i18n!nls/s12n-enroll';

type PropsFromCaller = {
  program?: Program;
  thirdPartyOrganization?: ThirdPartyOrganization;
  s12n: OnDemandSpecializationsV1;
};

type PropsToComponent = PropsFromCaller;

class PaymentChoiceProgram extends React.Component<PropsToComponent> {
  getBodyMessage() {
    const { thirdPartyOrganization, s12n } = this.props;
    const { productVariant } = s12n;

    if (thirdPartyOrganization) {
      return (
        <FormattedMessage
          message={_t(
            `Your enrollment in this {productType} is sponsored by {orgName}.
            Join the full {productType} today to access all {numOfCourses} courses, and complete all courses
          and the Capstone project to earn your {certificate}.`
          )}
          productType={getS12nVariantLabel(productVariant)}
          certificate={getS12nCertificateName(productVariant)}
          orgName={thirdPartyOrganization.name}
          numOfCourses={s12n.courseIds.length}
        />
      );
    }
    return null;
  }

  render() {
    return (
      <PaymentChoice title={_t('Enroll with Learning Programs')} type="program" currentType="program">
        {this.getBodyMessage()}
      </PaymentChoice>
    );
  }
}

export default PaymentChoiceProgram;
