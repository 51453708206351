import * as React from 'react';

import { useReadOnly } from 'slate-react';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import type { AssetElement } from 'bundles/cml/shared/types/elementTypes';

import _t from 'i18n!nls/cml';

type Props = {
  element: AssetElement;
  onEmbedButtonClick: () => void;
  onPageSelectButtonClick: () => void;
};

const EmbedAssetButtons: React.FC<Props> = ({ onEmbedButtonClick, onPageSelectButtonClick, element }) => {
  const readonly = useReadOnly();

  return (
    <>
      <Button
        disabled={readonly}
        onClick={onEmbedButtonClick}
        type={TOOLBAR_BUTTON_TYPES.menu}
        role="menuitem"
        tooltipProps={{ placement: 'bottom' }}
      >
        {element.embedEnabled ? _t('Switch to card view') : _t('Switch to embed view')}
      </Button>
      {element.embedEnabled ? (
        <Button
          disabled={readonly}
          onClick={onPageSelectButtonClick}
          type={TOOLBAR_BUTTON_TYPES.menu}
          role="menuitem"
          tooltipProps={{ placement: 'bottom' }}
        >
          {element.embedStartPage && element.embedEndPage
            ? _t(`Pages #{startPage} - #{endPage}`, {
                startPage: element.embedStartPage,
                endPage: element.embedEndPage,
              })
            : _t('All pages')}
        </Button>
      ) : null}
    </>
  );
};

export default EmbedAssetButtons;
