/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import Imgix from 'js/components/Imgix';

import { Button, Dialog, Hidden, Typography2, breakpoints } from '@coursera/cds-core';
import type { ButtonProps } from '@coursera/cds-core';
import { ArrowNextIcon, CheckIcon } from '@coursera/cds-icons';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import type { StepOneCopyType } from 'bundles/coursera-plus/components/subscriptionTiers/upgradeToPlus/upgradeCopies';
import { logo } from 'bundles/coursera-plus/utils/courseraPlusUtils';
import TrackedDiv from 'bundles/page/components/TrackedDiv';

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps>(Button);

const styles = {
  logo: css`
    margin-left: var(--cds-spacing-600);
    ${breakpoints.down('xs')} {
      margin: var(--cds-spacing-200) var(--cds-spacing-400);
    }
  `,
  heading: css`
    font-size: 26px;
    margin-top: var(--cds-spacing-200);
    ${breakpoints.down('xs')} {
      padding: 0;
      margin: var(--cds-spacing-200) var(--cds-spacing-400);
    }
  `,
  content: css`
    ${breakpoints.down('xs')} {
      padding: 0;
      margin: var(--cds-spacing-200) var(--cds-spacing-400);
    }
  `,
  actions: css`
    ${breakpoints.down('xs')} {
      padding: 0;
      margin: var(--cds-spacing-400);
    }
  `,
  valuePropTitle: css`
    font-size: 20px;
    margin-bottom: var(--cds-spacing-200);
  `,
  valuePropList: css`
    list-style-type: none;
    padding-left: 0;

    li {
      display: flex;
      align-items: center;
      margin-bottom: var(--cds-spacing-200);
    }
  `,
  checkMark: css`
    flex-shrink: 0;
    margin-right: var(--cds-spacing-100);
  `,
};

type Props = {
  copy: StepOneCopyType;
  courseId?: string;
  s12nId?: string;
  goToNextStep: () => void;
};

export const StepOneUpgradeModalContent: React.FC<Props> = ({ copy, courseId, s12nId, goToNextStep }) => {
  const trackingData = { courseId, s12nId };
  const { Content, HeadingGroup, Actions } = Dialog;

  const getPrimaryCta = (isFullWidth: boolean) => (
    <TrackedButton
      variant="primary"
      trackingName="ideal_upgrade_step_one_continue_button"
      data-testId="ideal-upgrade-step-one-continue-button"
      trackingData={trackingData}
      onClick={goToNextStep}
      icon={<ArrowNextIcon />}
      iconPosition="after"
      fullWidth={isFullWidth}
    >
      {copy.primaryCta}
    </TrackedButton>
  );

  return (
    <TrackedDiv
      trackingName="ideal_upgrade_step_one_modal"
      data-testId="ideal-upgrade-step-one-modal"
      trackClicks={false}
      withVisibilityTracking={true}
      data={trackingData}
    >
      <div css={styles.logo}>
        <Imgix src={logo.PLUS_FULL_BLUE} alt={copy.courseraPlus} maxWidth={159} maxHeight={16} />
      </div>
      <HeadingGroup css={styles.heading}>{copy.title}</HeadingGroup>
      <Content id="dialog-content" css={styles.content}>
        <Typography2 component="h3" variant="subtitleMedium" css={styles.valuePropTitle}>
          {copy.valuePropTitle}
        </Typography2>
        <ul css={styles.valuePropList}>
          {copy.valuePropList.map((valueProp) => (
            <li key={valueProp.toString()}>
              <CheckIcon size="small" color="interactive" css={styles.checkMark} />
              <Typography2 component="p">{valueProp}</Typography2>
            </li>
          ))}
        </ul>
      </Content>
      <Actions css={styles.actions}>
        <Hidden xsDown>{getPrimaryCta(false)}</Hidden>
        <Hidden smUp>{getPrimaryCta(true)}</Hidden>
      </Actions>
    </TrackedDiv>
  );
};

export default StepOneUpgradeModalContent;
