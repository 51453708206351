import React from 'react';

import type { OverrideProps, OverridableComponent } from '@coursera/cds-common';

import Link from '@core/Link';

type BaseProps = {
  /**
   * Invert the color scheme. Use when displaying over dark backgrounds
   * @default false
   */
  invert?: boolean;

  children: React.ReactNode;
};

interface BreadcrumbItemTypeMap<D extends React.ElementType = 'a'> {
  props: BaseProps;
  defaultComponent: D;
}

export type Props<
  D extends React.ElementType = BreadcrumbItemTypeMap['defaultComponent']
> = OverrideProps<BreadcrumbItemTypeMap<D>, D> & {
  component?: React.ElementType;
};

const BreadcrumbsItem: OverridableComponent<BreadcrumbItemTypeMap> = React.forwardRef<
  HTMLAnchorElement,
  Props
>(function BreadcrumbsItem(props, ref) {
  return (
    <Link
      ref={ref}
      typographyVariant="bodySecondary"
      variant="quiet"
      {...props}
    />
  );
});

export type BreadcrumbsItemElement = React.ReactElement<
  Props,
  typeof BreadcrumbsItem
>;

export default BreadcrumbsItem;
