import * as React from 'react';

import { isRightToLeft, isUserRightToLeft } from 'js/lib/language';

import { StyleSheet, color, css } from '@coursera/coursera-ui';
import type { Svg as SvgComponentType } from '@coursera/coursera-ui/svg';

import _t from 'i18n!nls/page';

const makeCircleStyle = (circleSize: number) =>
  StyleSheet.create({
    circleStyle: {
      width: circleSize,
      height: circleSize,
      borderRadius: '50%',
      border: `2px solid ${color.divider}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
    },
  });

const flipStyle = { transform: 'scaleX(-1)' };

type IconInCircleProps = {
  circleSize: number;
  className?: string;
  iconColor?: string;
  iconSize: number;
  iconFlipInRtl?: boolean;
  Svg: SvgComponentType;
};

const IconInCircle = ({
  circleSize,
  className,
  iconColor = color.primary,
  iconSize,
  iconFlipInRtl,
  Svg,
  ...otherProps
}: IconInCircleProps) => {
  const flip = (iconFlipInRtl && isUserRightToLeft()) || isRightToLeft(_t.getLocale());
  return (
    <div {...css(className, makeCircleStyle(circleSize).circleStyle)}>
      <Svg size={iconSize} color={iconColor} aria-hidden="true" style={flip ? flipStyle : undefined} {...otherProps} />
    </div>
  );
};

export const DegreeUpsellAtTopIcon = () => {};

export default IconInCircle;
