import * as React from 'react';

import _ from 'lodash';

import a11yKeyPress from 'js/lib/a11yKeyPress';

import { Checkbox as CheckboxCds } from '@coursera/cds-core';

import TrackedButton from 'bundles/page/components/TrackedButton';

import 'css!./__styles__/SearchFilterOption';

type Props = {
  option: {
    count: number;
    isRefined: boolean;
    label: string;
    value: string | Array<string>;
  };
  // Note: refine is not supplied to this component when used in the `SearchFilter`.
  //       that component react-select, which uses the onChange event to respond
  //       to input. This makes it a11y, whereas locating it within the onclick did not.
  refine?: (x0: string | Array<string>) => void;
  filterName: string;
  onHover?: () => void;
};

const SearchFilterOption = ({ option, refine, filterName, onHover }: Props) => {
  const onClickHandler = refine
    ? () => {
        refine(option.value);
      }
    : undefined;

  const onKeyDownHandler = refine
    ? (event: React.KeyboardEvent<HTMLElement>) => {
        // For accessibility purposes
        a11yKeyPress(event, onClickHandler);
      }
    : undefined;

  return (
    <div className="rc-SearchFilterOption" onMouseOver={onHover}>
      <TrackedButton
        className="filter-button align-items-spacebetween horizontal-box"
        trackingName={_.snakeCase(`filter_${filterName}_${option.isRefined ? 'unchecked' : 'checked'}`)}
        key={option.label}
        data={{ label: option.label }}
        onKeyDown={onKeyDownHandler}
        onClick={onClickHandler}
        dataE2e={Array.isArray(option.value) ? option.value[0] : option.value}
        style={option.isRefined ? { fontWeight: 'bold' } : {}}
      >
        <CheckboxCds checked={option.isRefined} label={option.label} />
        <div style={option.isRefined ? { fontWeight: 'bold' } : {}} className="filter-count">{`(${option.count})`}</div>
      </TrackedButton>
    </div>
  );
};

export default SearchFilterOption;
