import type React from 'react';

import { isHotkey } from 'is-hotkey';
import type { Editor } from 'slate';

import { isTypeDisabled } from 'bundles/cml/editor/utils/elementUtils';
import { toggleMark } from 'bundles/cml/editor/utils/markUtils';
import { MARKS } from 'bundles/cml/shared/constants';
import type { MARK_VALUES } from 'bundles/cml/shared/constants';

const isBoldHotkey = isHotkey('mod+b');
const isItalicHotkey = isHotkey('mod+i');
const isUnderlineHotkey = isHotkey('mod+u');
const isSuperscriptHotkey = isHotkey('mod+.');
const isSubscriptHotkey = isHotkey('mod+,');
const isMonospaceHotkey = isHotkey('mod+shift+m');

// Keyboard layouts differ between region, and we often make assumptions based on the US English keyboard (e.g. for CP-5712).
// If a keyboard shortcut is merely for convenience, it's probably ok to first gate it for North American keyboard layouts
// while the user is working in English. (Note, this is guessing keyboard layouts from the user's preferred language in their browser.)
// Enable only for English language users, as the backtick key may have other uses in other languages (e.g. diacritics)
// UK layout is funky
const isProbablyAmericanQWERTYKeyboard = (): boolean => {
  return navigator?.language.toUpperCase().includes('EN') && !navigator?.language.toUpperCase().includes('GB');
};

const MARKS_KEYBOARD_SHORTCUTS: [MARK_VALUES, (event: KeyboardEvent) => boolean][] = [
  [MARKS.BOLD, isBoldHotkey],
  [MARKS.ITALIC, isItalicHotkey],
  [MARKS.UNDERLINE, isUnderlineHotkey],
  [MARKS.MONOSPACE, (event) => isMonospaceHotkey(event) && isProbablyAmericanQWERTYKeyboard()],
  [MARKS.SUPERSCRIPT, isSuperscriptHotkey],
  [MARKS.SUBSCRIPT, isSubscriptHotkey],
];

export const marksKeyDownHandler = (editor: Editor, event: React.KeyboardEvent): boolean => {
  const { nativeEvent } = event;
  for (const [mark, checkHotkey] of MARKS_KEYBOARD_SHORTCUTS) {
    if (checkHotkey(nativeEvent) && !isTypeDisabled(editor, mark)) {
      event.preventDefault();
      toggleMark(editor, mark);
      return true;
    }
  }

  return false;
};
