/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Typography2, useTheme } from '@coursera/cds-core';

const useStyles = () => {
  const theme = useTheme();

  return {
    cardContainer: css`
      border-style: solid;
      border-width: 1px;
      border-radius: 5px;
      border-color: var(--cds-color-grey-200);
      overflow: hidden;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    `,
    cardHeader: css`
      background-color: var(--cds-color-grey-25);
      display: flex;
      align-items: center;
      height: 56px;
      padding: var(--cds-spacing-200);
      color: var(--cds-color-grey-975);
    `,
    cardIcon: css`
      margin-right: var(--cds-spacing-150);
      display: flex;
      align-items: center;
    `,
    cardContent: css`
      padding: var(--cds-spacing-200);
    `,
  };
};

type CardProps = {
  title?: string;
  icon?: React.ReactNode;
};

const HelpBoxCard: React.FC<CardProps> = ({ title, icon, children }) => {
  const styles = useStyles();

  return (
    <div css={styles.cardContainer}>
      {title && icon && (
        <div css={styles.cardHeader}>
          <span css={styles.cardIcon}> {icon}</span>
          <Typography2 component="h3" variant="subtitleMedium">
            {title}
          </Typography2>
        </div>
      )}
      <div css={styles.cardContent}> {children}</div>
    </div>
  );
};

export default HelpBoxCard;
