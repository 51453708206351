import * as React from 'react';
import { useMemo } from 'react';

import { FillableBlanksContext } from 'bundles/cml/editor/context/fillableBlanksContext';
import type { FillableBlankTag } from 'bundles/cml/editor/types/cmlEditorProps';

export type Props = {
  fillableBlankTags?: FillableBlankTag[];
};

const FillableBlanksContextProvider: React.FC<Props> = ({ fillableBlankTags = [], children }) => {
  const context = useMemo(() => {
    return {
      fillableBlanks: fillableBlankTags.reduce((acc: Record<string, string>, blank) => {
        acc[blank.id] = blank.label;
        return acc;
      }, {}),
    };
  }, [fillableBlankTags]);

  return <FillableBlanksContext.Provider value={context}>{children}</FillableBlanksContext.Provider>;
};

export default FillableBlanksContextProvider;
