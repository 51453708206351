/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { ToolbarRightSideActions } from 'bundles/cml/shared/components/asset/pdfViewer/ToolbarRightSideActions';
import FloatingMenu from 'bundles/cml/shared/components/menu/FloatingMenu';

type Props = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
};

export const ToolbarMobileFloatingMenu: React.FC<Props> = ({ onClose, anchorEl }) => {
  return (
    <FloatingMenu
      anchorEl={anchorEl}
      pageless
      strategy="absolute"
      placement="bottom-end"
      enableReferenceHidden={false}
      enableEscaped={true}
      onClose={onClose}
    >
      <ToolbarRightSideActions />
    </FloatingMenu>
  );
};
