import * as React from 'react';

import _t from 'i18n!nls/program-common';

type SvgCredentialsProps = React.SVGProps<SVGSVGElement>;

const SvgCredentialsRtl: React.FC<SvgCredentialsProps> = (props) => (
  <svg width={60} height={62} viewBox="0 0 60 62" role="presentation" {...props}>
    <title id="SvgCredentials-Title">{_t('Shareable credentials')}</title>
    <g fill="none" fillRule="evenodd">
      <path
        d="M32.413 1.614L58.14 18.177a4 4 0 011.81 3.813h.037v19.022h-.107a4 4 0 01-1.74 2.492L31.918 60.386a4 4 0 01-4.33 0L1.858 43.823a3.99 3.99 0 01-1.796-2.81H.012V21.988h.014a4 4 0 011.833-3.494l26.223-16.88a4 4 0 014.33 0z"
        fillOpacity=".1"
        fill="#2A73CC"
      />
      <path
        d="M23.094 29.625a5.781 5.781 0 00-4.72 9.12l.001 9.568 4.72-2.783 4.718 2.784v-9.567a5.781 5.781 0 00-4.72-9.122zm-2.72 10.884c.81.433 1.737.678 2.72.678.983 0 1.908-.245 2.719-.677v4.301l-2.718-1.603-2.72 1.604zM12 19v24.313h3.218v-2L14 41.312V21h32v20.312H31.479v2H48V19H12zm11.094 12.625a3.781 3.781 0 110 7.563 3.781 3.781 0 010-7.563zm11.093-2.531v2h7.438v-2h-7.438zm-16.937-4.25v2h24.438v-2H17.25z"
        opacity=".75"
        fill="#1F1F1F"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SvgCredentialsRtl;
