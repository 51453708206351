import gql from 'graphql-tag';

import type { FindUserEmailsByUserIdResponse } from '@coursera/grpc-types-useremails/coursera/proto/useremails/v1/user_emails_api';

export type FindUserEmailsByUserIdQueryVariables = {
  userId: string;
};

export type FindUserEmailsByUserIdQueryResult = {
  FindUserEmailsByUserIdResponse: FindUserEmailsByUserIdResponse;
};

const FindUserEmailsByUserIdQuery = gql`
  query FindUserEmailsByUserIdQuery($userId: String!) {
    FindUserEmailsByUserIdResponse(userId: $userId)
      @rest(
        type: "FindUserEmailsByUserId"
        path: "grpc/useremails/v1/UserEmailsAPI/FindUserEmailsByUserId?{args}"
        method: "GET"
      ) {
      userEmails
    }
  }
`;

export default FindUserEmailsByUserIdQuery;
