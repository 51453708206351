/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Typography2 } from '@coursera/cds-core';

import CoachIcon from 'bundles/ai-coach-platform/components/branding/CoachIcon';

import _t from 'i18n!nls/ai-coach-platform';

type Props = {
  icon?: React.ReactElement;
  title?: string;
  subtitle?: string;
};

const styles = {
  root: css`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: var(--cds-spacing-300) var(--cds-spacing-200);
  `,
  icon: css`
    margin: var(--cds-spacing-300) 0;
  `,
  title: css`
    color: var(--cds-color-emphasis-primary-background-default);
  `,
  subtitle: css`
    margin: 0;
  `,
};

const ChatEmptyState = (props: Props): React.ReactElement => {
  const { icon, title, subtitle } = props;

  return (
    <div className="ai-coach-chat-empty-state" css={styles.root}>
      <div>
        <div css={styles.icon}>{icon ?? <CoachIcon size="large" />}</div>
        <Typography2 component="h3" variant="titleSmall" css={styles.title}>
          {title ?? _t('Hello!')}
        </Typography2>
        <Typography2 component="p" variant="titleSmall" css={styles.subtitle}>
          {subtitle ?? _t('How can I help?')}
        </Typography2>
      </div>
    </div>
  );
};

export default ChatEmptyState;
