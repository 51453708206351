import * as React from 'react';
import { useEffect, useRef } from 'react';

import { useQuery } from '@apollo/client';

import type { CMLViewerProps } from 'bundles/cml';
import { CMLUtils, CMLViewer } from 'bundles/cml';
import BatchTranslateCmlQuery from 'bundles/ondemand-translation/api/BatchTranslateCmlQuery.graphql';
import type {
  TranslateContent_BatchTranslateCmlQuery as BatchTranslateCmlQueryResult,
  TranslateContent_BatchTranslateCmlQueryVariables as BatchTranslateCmlQueryVariables,
} from 'bundles/ondemand-translation/api/__generated__/BatchTranslateCmlQuery';
import type { TranslatedContentId } from 'bundles/ondemand-translation/components/TranslationContextProvider';
import { TranslationContext } from 'bundles/ondemand-translation/components/TranslationContextProvider';

type TranslatedCMLViewerProps = CMLViewerProps & {
  enableTranslation?: boolean;
};

/**
 * This component takes CML content as input and renders either the original content or translated content as a result.
 *
 * The component utilizes translation Graphql API to load the translated content. It also reads TranslationContext to
 * determine whether it should show the original content or the translated content.
 *
 * This component wraps the CMLViewer and takes the same properties. All properties are then passed to the CMLViewer.
 *
 * In addition, the component takes the enableTranslation property which specifies whether the translation feature is
 * enabled or not.
 *
 * @param enableTranslation specifies whether the translation feature is enabled.
 * @param cml input content
 * @param otherProps passed to the CMLViewer component
 */
export const TranslatedCMLViewer = ({ enableTranslation, cml, ...otherProps }: TranslatedCMLViewerProps) => {
  const dtdId = CMLUtils.getDtdId(cml);
  const cmlValue = CMLUtils.getValue(cml);

  const context = React.useContext(TranslationContext);

  const contentIdRef = useRef<TranslatedContentId | undefined>(undefined);

  useEffect(() => {
    if (!contentIdRef.current) {
      contentIdRef.current = context?.getTranslatedContentId();
    }
  }, [context]);

  useEffect(() => {
    if (enableTranslation && context && contentIdRef.current) {
      const textValue = CMLUtils.getInnerTextFromValue(cmlValue);
      context.languageDetector.registerOriginalTextValue(contentIdRef.current, textValue);
    }
  }, [cmlValue, context, enableTranslation]);

  // Do not translate if input is empty or whitespace - backend returns validation error for empty input
  const shouldTranslate = enableTranslation && context?.showTranslatedContent && cmlValue && cmlValue.trim();

  const queryVariables = {
    inputs: [
      {
        dtdId,
        cmlValue,
      },
    ],
    // Pass empty source language as the language detection is more reliable on the backend.
    languageSettings: {},
  };

  const { data, error, refetch, loading } = useQuery<BatchTranslateCmlQueryResult, BatchTranslateCmlQueryVariables>(
    BatchTranslateCmlQuery,
    {
      context: { clientName: 'gatewayGql' },
      skip: !shouldTranslate,
      variables: queryVariables,
      notifyOnNetworkStatusChange: true, // this needs to be enabled, otherwise it will not rerender on refetch
    }
  );

  useEffect(() => {
    if (contentIdRef.current) {
      context?.progressTracker.registerQueryProgress(contentIdRef.current, loading);
    }
  }, [context?.progressTracker, loading]);

  let hasError = error !== undefined;

  let outputContent = cml;

  const result = data?.TranslateContent?.batchTranslateCml;

  useEffect(() => {
    if (contentIdRef.current) {
      if (result?.__typename === 'TranslateContent_BatchTranslateCMLSuccess' && result.detectedSourceLanguage) {
        context?.languageDetector.registerDetectedLanguage(contentIdRef.current, result.detectedSourceLanguage);
      }
    }
  }, [context?.languageDetector, result]);

  if (result?.__typename === 'TranslateContent_BatchTranslateCMLSuccess') {
    const translatedData = result.results?.[0];

    if (translatedData?.__typename === 'TranslateContent_TranslateCMLPayload') {
      outputContent = {
        dtdId: translatedData.cml.dtdId,
        cmlValue: translatedData.cml.cmlValue,
      };
    } else if (translatedData?.__typename === 'TranslateContent_TranslateCMLFailedError') {
      hasError = true;
    }
  } else if (result?.__typename === 'TranslateContent_RateLimitError') {
    hasError = true;
  } else if (result?.__typename === 'TranslateContent_TranslateInvalidArgumentError') {
    hasError = true;
  }

  useEffect(() => {
    // Do not register / unregister error while loading (prevents flickering when refetching)
    if (contentIdRef.current && !loading) {
      if (hasError) {
        context?.progressTracker.registerError(contentIdRef.current, refetch);
      } else {
        context?.progressTracker.unregisterError(contentIdRef.current);
      }
    }
  }, [context?.progressTracker, hasError, loading, refetch]);

  return <CMLViewer cml={outputContent} {...otherProps} />;
};
