import * as React from 'react';

import { Breadcrumb, StyleSheet, breakPoint, color, css } from '@coursera/coursera-ui';
import { SvgChevronRight } from '@coursera/coursera-ui/svg';

import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';

type BreadcrumbRoutes = {
  path: string;
  name: string;
};

const defaultStyles = StyleSheet.create({
  BreadCrumb: {
    marginBottom: '1.5rem',
    [`@media (max-width: ${breakPoint.md}px)`]: {
      display: 'none',
    },
  },
  breadcrumbFontSize: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  breadcrumbLink: {
    ':hover': {
      textDecoration: 'none',
      color: color.primaryTextThemeDark,
    },
  },
});

type Props = {
  routes: Array<BreadcrumbRoutes>;
  styles?: Partial<typeof defaultStyles>;
  chevronColor?: string;
};

export default function Breadcrumbs({
  routes,
  styles = defaultStyles,
  chevronColor = color.white,
}: Props): JSX.Element {
  return (
    <Breadcrumb
      rootClassName={styles.BreadCrumb}
      separator={
        <SvgChevronRight
          size={15}
          color={chevronColor}
          style={{ display: 'flex ', marginLeft: '0.5rem', marginRight: '0.5rem' }}
        />
      }
    >
      {routes.map(({ path, name }) => (
        <Breadcrumb.Item key={path}>
          <TrackedLink2
            {...css(styles.breadcrumbLink, styles.breadcrumbFontSize)}
            href={path}
            trackingName="breadcrumb_link"
          >
            {name}
          </TrackedLink2>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}
