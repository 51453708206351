import * as React from 'react';

import PropTypes from 'prop-types';
import { branch, compose, renderComponent, renderNothing, setDisplayName, setPropTypes, withProps } from 'recompose';

import redirect from 'js/lib/coursera.redirect';

import { Loading, StyleSheet, css, spacing } from '@coursera/coursera-ui';

import ErrorMessage from 'bundles/coursera-ui/components/extended/ErrorMessage';
import ErrorPage from 'bundles/coursera-ui/components/rich/ErrorPage';

import _t from 'i18n!nls/coursera-ui';

const DataFetchFailureMessage = ({ failureMessage = _t('Failed to load data') }) => {
  return (
    <div className="rc-DataFetchFailureMessage p-a-1 vertical-box align-items-absolute-center">{failureMessage}</div>
  );
};

const styles = StyleSheet.create({
  NotFoundOrAuthorized: {
    minHeight: 150,
    padding: spacing.lg,
    textAlign: 'center',
  },
});
/**
 * A reusable component to redirect user, show notFoundMessage or notAuthorizedMessage
 * Priority: redirectPath > notFoundMessage > notAuthorizedMessage
 */
class NotFoundOrAuthorized extends React.Component {
  static propTypes = {
    redirectPath: PropTypes.string,
    notFoundMessage: PropTypes.string,
    shouldRedirect: PropTypes.bool,
    notAuthorizedMessage: PropTypes.string,
    defaultMessage: PropTypes.string,
  };

  componentDidMount() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'shouldRedirect' does not exist on type '... Remove this comment to see the full error message
    const { shouldRedirect, redirectPath } = this.props;
    if (shouldRedirect && redirectPath) {
      redirect.setLocation(redirectPath);
    }
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'notFoundMessage' does not exist on type ... Remove this comment to see the full error message
    const { notFoundMessage, notAuthorizedMessage, defaultMessage } = this.props;
    return (
      <div {...css(styles.NotFoundOrAuthorized)}>
        <h3>{notFoundMessage || notAuthorizedMessage || defaultMessage}</h3>
      </div>
    );
  }
}

/**
 * A set of reusable branching hocs for handling
 * - loading state
 * - auth/notFound state
 * - ssrPlaceholder state. As the component can't access dom
 *   at SSR, we need to use placeholder components to be able to
 *   get the data / or the minium layout content, so we don't have
 *   to wait till CSR
 *   Usage:
 *   const CourseCardNotAuthorized = withNotFoundOrAuthorized(CourseCard);
 *   const CourseCardPlaceholder = () => <h1>Course Card SSR Placeholder Component</h1>;
 *   const CourseCardWithSSRPlaceholderBranch = withSSRPlaceholder(CourseCardPlaceholder)(CourseCard);
 *   <CourseCardNotAuthorized
 *    isNotAuthorized
 *    notAuthorizedMessage="Not authorized to see this course card"
 *    id={'c1'}
 *   />

*    <CourseCardWithSSRPlaceholderBranch
*     isCSROnly
*     id={'c1'}
*     onToggleCourseSelect={action('onToggleCourseSelect')}
*    />
*/

const withNotFoundOrAuthorized = (Component: $TSFixMe) =>
  compose(
    branch(
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      (props) => props.isNotFound || props.isNotAuthorized,
      renderComponent(NotFoundOrAuthorized),
      renderComponent(Component)
    ),
    setDisplayName(`withNotFoundOrAuthorized(${Component.displayName || Component.name})`),
    setPropTypes({
      isNotFound: PropTypes.bool,
      isNotAuthorized: PropTypes.bool,
    })
  )(Component);

const withLoading = (Component: $TSFixMe) =>
  compose(
    branch(
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      (props) => props.isLoading || (props.data && props.data.loading),
      renderComponent(Loading),
      renderComponent(Component)
    ),
    setDisplayName(`withLoading(${Component.displayName || Component.name})`),
    setPropTypes({
      isLoading: PropTypes.bool,
    })
  )(Component);

const withSSRPlaceholder = (PlaceholderComponent: $TSFixMe) => (Component: $TSFixMe) =>
  compose(
    branch(
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      (props) => props.isCSROnly || !props.isMounted,
      renderComponent(PlaceholderComponent),
      renderComponent(Component)
    ),
    setDisplayName(`withSSRPlaceholder(${Component.displayName || Component.name})`),
    setPropTypes({
      isCSROnly: PropTypes.bool,
      isMounted: PropTypes.bool,
    })
  )(Component);

const withLoadingPlaceholder = (PlaceholderComponent: $TSFixMe) => (Component: $TSFixMe) =>
  compose(
    branch(
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      (props) => (props.data && props.data.loading) || props.loading,
      renderComponent(PlaceholderComponent),
      renderComponent(Component)
    ),
    setDisplayName(`withLoadingPlaceholder(${Component.displayName || Component.name})`)
  )(Component);

const withError =
  (ErrorComponent = ErrorMessage) =>
  (Component: $TSFixMe) =>
    compose(
      branch(
        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
        (props) => (props.data && props.data.error) || props.error,
        renderComponent(ErrorComponent),
        renderComponent(Component)
      ),
      setDisplayName(`withError(${Component.displayName || Component.name})`)
    )(Component);

const withDataFailureMessage =
  (FailureMessageComponent = DataFetchFailureMessage) =>
  (Component: $TSFixMe) =>
    compose(
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      branch((props) => !props.isDataLoaded, renderComponent(FailureMessageComponent), renderComponent(Component)),
      setDisplayName(`withDataFailureMessage(${Component.displayName || Component.name})`),
      setPropTypes({
        failureMessage: PropTypes.node,
      })
    )(Component);

// Render ErrorPage when renderErrorPage prop is true. Prioritize HOC errorPageMsg over errorPageMsg prop
const withErrorPage =
  ({ errorPageMsg, errorPageIgnoreChildren }: $TSFixMe) =>
  (Component: $TSFixMe) =>
    compose(
      withProps((props) => {
        let errorPageMsg0;

        if (typeof errorPageMsg === 'function') {
          errorPageMsg0 = errorPageMsg();
        } else if (errorPageMsg) {
          errorPageMsg0 = errorPageMsg;
        } else {
          // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
          errorPageMsg0 = props.errorPageMsg;
        }

        return { errorPageMsg: errorPageMsg0, errorPageIgnoreChildren };
      }),
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      branch((props) => props.renderErrorPage, renderComponent(ErrorPage), renderComponent(Component)),
      setDisplayName(`withErrorPage(${Component.displayName || Component.name})`)
    )(Component);

const withRenderNothing = (Component: $TSFixMe) =>
  compose(
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    branch((props) => props.shouldRenderNothing, renderNothing, renderComponent(Component)),
    setDisplayName(`withRenderNothing(${Component.displayName || Component.name})`),
    setPropTypes({
      shouldRenderNothing: PropTypes.bool,
    })
  )(Component);

function EmptySpan() {
  return <span />;
}

const withRenderEmptySpan = (Component: $TSFixMe) =>
  compose(
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    branch((props) => props.shouldRenderEmptySpan, renderComponent(EmptySpan), renderComponent(Component)),
    setDisplayName(`branch(${Component.displayName || Component.name})`)
  )(Component);

export default {
  withNotFoundOrAuthorized,
  withLoading,
  withSSRPlaceholder,
  withLoadingPlaceholder,
  withError,
  withDataFailureMessage,
  withErrorPage,
  withRenderNothing,
  withRenderEmptySpan,
};

export {
  withNotFoundOrAuthorized,
  withLoading,
  withSSRPlaceholder,
  withLoadingPlaceholder,
  withError,
  withDataFailureMessage,
  withErrorPage,
  withRenderNothing,
  withRenderEmptySpan,
};
