import { compose, mapProps } from 'recompose';

import { tupleToStringKey } from 'js/lib/stringKeyTuple';

import type { EnrollmentChoices } from 'bundles/enroll/components/xdp/withEnrollment';
import Naptime from 'bundles/naptimejs';
import ProductSkuV1 from 'bundles/naptimejs/resources/productSkus.v1';
import S12nDerivativesV1 from 'bundles/naptimejs/resources/s12nDerivatives.v1';
import { CREDENTIAL_TRACK_SUBSCRIPTION } from 'bundles/payments/common/ProductType';

export type PropsToWithPrice = {
  enrollmentAvailableChoices?: EnrollmentChoices;
  s12nId?: string;
};

type PropsFromWithPriceNaptime = {
  s12nDerivative?: S12nDerivativesV1;
  multiS12nDerivatives?: Array<S12nDerivativesV1>;
};

export type PropsFromWithPrice = PropsFromWithPriceNaptime;

const withPrice = <InputProps extends PropsToWithPrice>(isNotRequired?: boolean) =>
  compose<PropsFromWithPriceNaptime, InputProps>(
    Naptime.createContainer<PropsFromWithPriceNaptime, InputProps>(
      ({ enrollmentAvailableChoices, s12nId }) => {
        if (!enrollmentAvailableChoices) {
          return {};
        }
        // if user can subscribe to multiple s12ns but can't do free trial for any s12n,
        // retrieve the price for every s12n
        if (
          enrollmentAvailableChoices.isMixAndMatch &&
          !enrollmentAvailableChoices.canEnrollThroughS12nSubscriptionFreeTrial
        ) {
          const enrollmentS12nIds = enrollmentAvailableChoices.subscriptionEnrollmentS12nIds;

          return {
            multiS12nDerivatives: S12nDerivativesV1.multiGet(enrollmentS12nIds, {
              fields: ['catalogPrice'],
              required: !isNotRequired,
            }),
          };
        } else if (enrollmentAvailableChoices.canSubscribeToS12n) {
          return {
            // If we can subscribe to the s12n, it follows that we have a s12nId.
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            s12nDerivative: S12nDerivativesV1.get(s12nId!, {
              fields: ['catalogPrice'],
              required: !isNotRequired,
            }),
          };
        } else {
          return {
            productPrices: [],
          };
        }
      }
    ),
    mapProps<PropsFromWithPrice, PropsFromWithPriceNaptime>(({ multiS12nDerivatives, ...rest }) => ({
      ...(multiS12nDerivatives && {
        multiS12nDerivatives,
        s12nDerivative: multiS12nDerivatives[0],
      }),
      ...rest,
    }))
  );

type PropsToWithCredentialTrackProductSkus = {
  id: string;
};

type PropsFromWithCredentialTrackProductSkus = {
  productSkus?: ProductSkuV1;
};

const withCredentialTrackProductSKUs = <InputProps extends PropsToWithCredentialTrackProductSkus>() =>
  Naptime.createContainer<PropsFromWithCredentialTrackProductSkus, InputProps>(({ id }) => {
    if (id) {
      return {
        productSkus: ProductSkuV1.finder('findByUnderlying', {
          params: {
            id: tupleToStringKey([CREDENTIAL_TRACK_SUBSCRIPTION, id]),
          },
          fields: ['id', 'productType', 'properties'],
        }),
      };
    } else {
      return {};
    }
  });

export { withPrice, withCredentialTrackProductSKUs };
