import type { CareerAcademy_Variant as CareerAcademyVariant } from '__generated__/graphql-types';

import type { EnterpriseSetting } from 'bundles/naptimejs/resources/enterpriseSettings.v1';
import { isCareerAcademyFreemiumEnabled, isCareerAcademyV2Enabled } from 'bundles/unified-career-academy/utils/helpers';

export const getIsCALearnerDefaultTab = (programSettings?: EnterpriseSetting[]) => {
  return programSettings
    ? programSettings.find((programSetting) => {
        const programSettingIdArr = programSetting.id.split('~');
        const programSettingId = programSettingIdArr[programSettingIdArr.length - 1];
        return programSettingId === 'DISABLE_CA_LEARNER_DEFAULT_TAB';
      })?.setting.definition.value !== false
    : true;
};

export const getShowCurationBannerCACondition = ({
  isCareerAcademySelfServeEnabled,
  isCareerAcademyLimitedCatalog,
  careerAcademyVariant,
}: {
  isCareerAcademySelfServeEnabled: boolean;
  isCareerAcademyLimitedCatalog: boolean;
  careerAcademyVariant?: CareerAcademyVariant;
}) => {
  if (isCareerAcademyFreemiumEnabled(careerAcademyVariant)) {
    return false;
  }

  if (isCareerAcademySelfServeEnabled && isCareerAcademyLimitedCatalog) {
    return !isCareerAcademyV2Enabled(careerAcademyVariant);
  }

  return true;
};

export const getRoleNameFromCollection = (collectionName: string) => {
  const regex = /Career Academy: /g;
  return collectionName.replace(regex, '');
};
