import { Editor, Element, Path, Transforms } from 'slate';
import type { NodeEntry, PathRef } from 'slate';

import { moveListItem, moveListItems } from 'bundles/cml/editor/components/buttons/list/listUtils';
import { getAncestorOfType } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { ListElement, ListItemElement } from 'bundles/cml/shared/types/elementTypes';

export const canDecreaseIndent = (editor: Editor) => {
  const listEntry = getAncestorOfType(editor, [BLOCK_TYPES.NUMBER_LIST, BLOCK_TYPES.BULLET_LIST]);
  if (!listEntry) {
    return false;
  }

  const listPath = listEntry[1];
  const [parentElement] = Editor.parent(editor, listPath);

  return Element.isElement(parentElement) && parentElement.type === BLOCK_TYPES.LIST_ITEM;
};

export const decreaseIndent = (editor: Editor) => {
  const currentItemEntry = getAncestorOfType(editor, BLOCK_TYPES.LIST_ITEM);
  if (!currentItemEntry) {
    return;
  }

  const [currentItem, currentItemPath] = currentItemEntry as NodeEntry<ListItemElement>;
  const [currentList, currentListPath] = Editor.parent(editor, currentItemPath) as NodeEntry<ListElement>;
  const currentListItems = currentList.children;
  const currentItemIndex = currentListItems.indexOf(currentItem);

  // move siblings from current list into the current item
  const pathRefs: PathRef[] = [];
  for (let i = currentItemIndex + 1; i < currentListItems.length; i += 1) {
    const pathRef = Editor.pathRef(editor, [...currentListPath, i]);
    pathRefs.push(pathRef);
  }

  moveListItems(editor, pathRefs, currentItemPath);

  const parentItemPath = Path.parent(currentListPath);
  Editor.withoutNormalizing(editor, () => {
    Transforms.moveNodes(editor, {
      at: currentItemPath,
      to: Path.next(parentItemPath),
    });

    if (currentItemIndex === 0) {
      Transforms.delete(editor, {
        at: currentListPath,
      });
    }
  });
};

export const canIncreaseIndent = (editor: Editor) => {
  const listItemEntry = getAncestorOfType(editor, BLOCK_TYPES.LIST_ITEM);
  if (!listItemEntry) {
    return false;
  }

  return Path.hasPrevious(listItemEntry[1]);
};

export const increaseIndent = (editor: Editor) => {
  const listItemEntry = getAncestorOfType(editor, BLOCK_TYPES.LIST_ITEM);
  if (!listItemEntry) {
    return;
  }

  const listItemPath = listItemEntry[1];
  const previousListItemPath = Path.previous(listItemPath);

  moveListItem(editor, listItemPath, previousListItemPath);
};
