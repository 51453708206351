import * as React from 'react';

import { EditIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';

import _t from 'i18n!nls/cml';

type Props = {
  onEditLink: () => void;
};

const EditLinkButton: React.FC<Props> = ({ onEditLink }) => {
  return (
    <Button onClick={onEditLink} title={_t('Edit link')} type={TOOLBAR_BUTTON_TYPES.menu} role="menuitem">
      <EditIcon size="small" />
    </Button>
  );
};

export default EditLinkButton;
