import type { RawEvaluationResponse } from 'bundles/code-evaluator/models/EvaluationResponse';
import EvaluationResponse from 'bundles/code-evaluator/models/EvaluationResponse';

export type EvaluationConstructor = Partial<{
  active: boolean;
  fail: boolean;
  response: RawEvaluationResponse | null;
}>;

/**
 * Represents a code evaluation state.
 */
class Evaluation {
  declare id: string;

  declare active: boolean;

  declare fail: boolean;

  declare response: EvaluationResponse | null | undefined;

  constructor({ active = false, fail = false, response }: EvaluationConstructor) {
    this.id = Math.random().toString(10);

    this.active = active;
    this.fail = fail;

    if (response != null) {
      this.response = new EvaluationResponse(response);
    }
  }

  get hasRequestError(): boolean {
    return !this.active && this.fail;
  }

  get hasSystemError(): boolean {
    return (
      !this.active &&
      !this.fail &&
      !!this.response &&
      (!!this.response.errorCodeResult || !!this.response.timeoutErrorResult)
    );
  }

  get hasRuntimeError(): boolean {
    return !this.active && !this.fail && !!this.response && !!this.response.runtimeErrorResult;
  }

  get hasSuccessResult(): boolean {
    return !this.active && !this.fail && !!this.response && !!this.response.successResult;
  }
}

export default Evaluation;
