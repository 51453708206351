import * as React from 'react';
import { useCallback, useContext } from 'react';

import { ReactEditor, useSlate, useSlateStatic } from 'slate-react';

import { PhotoIcon } from '@coursera/cds-icons';

import type { Asset } from 'bundles/asset-admin/types/assets';
import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import { assetToElement } from 'bundles/cml/editor/components/buttons/asset/assetUtils';
import { AssetContext } from 'bundles/cml/editor/context/assetContext';
import { isTypeDisabled } from 'bundles/cml/editor/utils/elementUtils';
import { hasAncestorOfType, insertBlockNodes } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';

import _t from 'i18n!nls/cml';

const ImageButton: React.FC = () => {
  const editor = useSlate();
  const staticEditor = useSlateStatic();
  const { setAssetModalOptions } = useContext(AssetContext);
  const disabled = isTypeDisabled(editor, BLOCK_TYPES.IMAGE);
  const active = hasAncestorOfType(editor, BLOCK_TYPES.IMAGE);

  const handleAssetModalSelect = useCallback(
    (selectedAssets: Array<Asset>) => {
      setAssetModalOptions();
      ReactEditor.focus(staticEditor);
      if (selectedAssets.length) {
        const assets = selectedAssets.map(assetToElement);
        insertBlockNodes(staticEditor, assets);
      }
    },
    [staticEditor, setAssetModalOptions]
  );

  const handleClick = useCallback(() => {
    setAssetModalOptions({
      type: 'image',
      allowMultiple: true,
      handleSelect: handleAssetModalSelect,
    });
  }, [handleAssetModalSelect, setAssetModalOptions]);

  return (
    <Button
      onClick={handleClick}
      disabled={disabled}
      active={active}
      className="rc-ImageButton"
      title={_t('Embed Images')}
      type={TOOLBAR_BUTTON_TYPES.insertion}
    >
      <PhotoIcon size="small" />
    </Button>
  );
};

export default ImageButton;
