import type { BaseComponentProps } from '@coursera/cds-common';
import { useLocalizedStringFormatter } from '@coursera/cds-common';

import CardBodyContent from '@core/cards/common/CardBodyContent';
import { classes } from '@core/cards/common/getCommonCardCss';
import i18nMessages from '@core/cards/common/i18n';
import Typography from '@core/Typography2';

export type CardClipsProps = {
  courseName?: string;
  lessonName?: string;
};

type Props = {
  clipInfo: CardClipsProps;
} & BaseComponentProps<'div'>;

/**
 * Common component to render clip info such as course or lesson name.
 * When both course and lesson names are provided, a dot separator is shown between them.
 */
const CardClips = ({ clipInfo, ...rest }: Props) => {
  const stringFormatter = useLocalizedStringFormatter(i18nMessages);
  const { courseName, lessonName } = clipInfo;
  const showSeparator = !!courseName && !!lessonName;

  return (
    <CardBodyContent {...rest}>
      <Typography color="supportText" component="p" variant="bodySecondary">
        {courseName && stringFormatter.format('course_prefix', { courseName })}
        {showSeparator && <span className={classes.dotSeparator}>{'·'}</span>}
        {lessonName && stringFormatter.format('lesson_prefix', { lessonName })}
      </Typography>
    </CardBodyContent>
  );
};

export default CardClips;
