import _ from 'lodash';

type ValidationFunc = (x0: any) => string | boolean;

export const runValidations = (value: string, validations: Array<ValidationFunc>) => {
  let error: string | boolean = '';
  if (validations.length > 0) {
    // shortcircuit when first error is found
    validations.some((validation) => {
      error = validation.apply(null, [value]);
      return error;
    });
  }
  return error;
};

export const getInitialsFromFullName = (fullName: string) => {
  if (typeof fullName === 'string') {
    const initials = fullName
      .trim()
      .split(' ')
      .map((item) => item.charAt(0))
      .join('');

    if (initials.length > 2) {
      // Take the first and last char if the initials are more than 2 chars
      return (initials[0] + initials[initials.length - 1]).toUpperCase();
    }
    return initials.toUpperCase();
  }
  return undefined;
};

/**
 * Get the middle value of an array, if sortFn is specified, use sortFn
 * With even number of elements, return the lower index
 * @param  {array} array
 * @param  {function}  sortFn
 * @return {any}
 */
export const getMiddleValueFromArray = (array: Array<any>, sortFn: (a: any, b: any) => number = (a, b) => a - b) => {
  if (Array.isArray(array)) {
    if (array.length === 1) return array[0];
    return array.sort(sortFn)[Math.ceil(array.length / 2) - 1];
  }
  return undefined;
};

/**
 * Get the missing field names
 * Sometimes in order to perform certain actions, we need to make sure all critical fiels
 * are present, if not, we'll throw a MissingFieldsError which states what data is missing
 *
 * @param  {object} valueObj: the input value that needs to be validated
 * @param  {[string]} fieldNamesArr: the fields that need to be present
 * @return {[sring] || undefined}: return an array of missing field names or undefined
 */
export const getMissingFields = (valueObj: any = {}, fieldNamesArr: Array<string> = []) => {
  const valueKeys = _.keys(valueObj);
  const missingFields = _.difference(fieldNamesArr, valueKeys);
  if (_.isEmpty(missingFields)) return undefined;
  return missingFields;
};

/**
 * Similar to underscore array compact, we'll remove all fields with undefined values
 * Optionally pass in shouldRemoveAllFalsyFields to remove all falsy fields.
 *
 * @param shouldRemoveAllFalsyFields If false, only removes `undefined` values.
 */
export const compactObject = <Value extends {}>(
  value: Value,
  { recursive = false, shouldRemoveAllFalsyFields = false } = {}
): Value => {
  if (typeof value !== 'object') {
    return value;
  }

  // It is possible to get `value === null` here, which will get converted into `{}
  // Recall that `typeof null === "object"`

  return _.reduce(
    value,
    (total, item, key0) => {
      const newTotal = total;
      const key = key0 as keyof Value;
      if (item !== undefined && item !== null && (!shouldRemoveAllFalsyFields || item)) {
        newTotal[key] = recursive ? compactObject(item, { shouldRemoveAllFalsyFields, recursive }) : item;
      }
      return newTotal;
    },
    {} as Value
  );
};

/**
 * Get the closest number to target inside an array of numbers (lower bound)
 *
 * @param  {number} num    Target number
 * @param  {[number]} arr   An array of numbers
 * @return {number}
 */
export const getClosestNumberInArray = (num: number, arr: Array<number> = []) => {
  if (!num || _.isEmpty(arr)) {
    return undefined;
  }

  let curr = arr[0];
  let diff = Math.abs(num - curr);
  arr.forEach((item) => {
    const newDiff = Math.abs(num - item);
    if (newDiff < diff) {
      diff = newDiff;
      curr = item;
    }
  });
  return curr;
};

/**
 * Check for explicit values of undefined and null on a value
 * @param {any} value
 * @return {boolean}
 */

export const isNullOrUndefined = (value: any): boolean => {
  return value === undefined || value === null;
};

/**
 * Format an array of strings into a comma-separated string, with the final string being separated with a union word
 * For example [item1, item2, item3] would return "item1, item2 and item3"
 * @param strings string[]
 * @param unionWord string
 * @returns string
 */
export const formatUserFriendlyList = (strings: string[], unionWord = 'and'): string => {
  if (strings.length === 1) {
    return strings[0];
  }
  return [strings.slice(0, strings.length - 1).join(', '), strings[strings.length - 1]].join(` ${unionWord} `);
};

/**
 * Filters undefined and return the item as that item type only
 * For example [item1, item2, undefined] with type (item | undefined)[] would return "item1 and item2" with type item[]
 * @param item T | undefined | null | false
 * @returns T
 */
export function filterUndefined<T>(item: T | undefined | null | false): item is T {
  return !!(item as T);
}
