import { ClassNames } from '@emotion/react';

import * as React from 'react';
import ReactModal from 'react-modal';

import PropTypes from 'prop-types';

import { CloseIcon } from '@coursera/cds-icons';

import { Clickable } from 'bundles/enterprise-ui/components/Clickable';

export type Modal = {
  children: React.ReactNode;
  style?: {
    content?: React.CSSProperties;
    overlay?: React.CSSProperties;
  };
};

export type ModalContextType = {
  openModal: (modal: Modal) => void;
  closeModal: () => void;
};

type State = {
  modals: Modal[];
};

const defaultModalStyle: {
  content: React.CSSProperties;
  overlay: React.CSSProperties;
  closeButton: React.CSSProperties;
} = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '619px',
    width: '100%',
    border: 'none',
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)',
    borderRadius: '3px',
    padding: '24px',
    maxHeight: '100vh',
  },
  overlay: {
    zIndex: 3002, // it should be on top of global nav and the overlay
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
  },
  closeButton: {
    position: 'absolute',
    top: '24px',
    right: '24px',
  },
};

/**
 * @deprecated use CDS Dialog instead
 */
export class ModalContext extends React.Component<{}, State> {
  state: State = {
    modals: [],
  };

  static childContextTypes = { openModal: PropTypes.func, closeModal: PropTypes.func };

  componentDidMount() {
    ReactModal.setAppElement('[data-testid="Admin_Dashboard_App"]');
  }

  getChildContext() {
    return {
      openModal: this.handleOpenModal,
      closeModal: this.handleCloseModal,
    };
  }

  handleOpenModal: ModalContextType['openModal'] = (modal) => {
    this.setState((state) => ({
      ...state,
      modals: [...state.modals, modal],
    }));
  };

  handleCloseModal: ModalContextType['closeModal'] = () => {
    this.setState((state) => ({
      ...state,
      modals: state.modals.slice(0, -1),
    }));
  };

  render() {
    const { children } = this.props;
    const { modals } = this.state;
    const modal = modals[modals.length - 1];
    return (
      <ClassNames>
        {({ css }) => (
          <div>
            {children}
            <ReactModal
              isOpen={modals.length > 0}
              onRequestClose={this.handleCloseModal}
              style={{
                content: {
                  ...defaultModalStyle.content,
                  ...modal?.style?.content,
                },
                overlay: {
                  ...defaultModalStyle.overlay,
                  ...modal?.style?.overlay,
                },
              }}
              bodyOpenClassName={css({
                overflowY: 'hidden',
                position: 'fixed',
                width: '100%',
                height: '100%',
              })}
            >
              <Clickable onClick={this.handleCloseModal} style={defaultModalStyle.closeButton}>
                <CloseIcon size="large" />
              </Clickable>
              {modal?.children}
            </ReactModal>
          </div>
        )}
      </ClassNames>
    );
  }
}
