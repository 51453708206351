import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('RatingStat', [
  'meter',
  'icon',
  'inverted',
  'sizeLabel',
  'sizeDisplay',
]);

const styles = css`
  --rating-stat-delimiter-margin: var(--cds-spacing-50);
  --rating-stat-icon-margin: var(--cds-spacing-50);

  display: flex;
  align-items: center;
  gap: var(--rating-stat-delimiter-margin);

  .${classes.meter} {
    display: flex;
    align-items: center;
    gap: var(--rating-stat-icon-margin);
  }

  &.${classes.sizeDisplay} {
    --rating-stat-delimiter-margin: var(--cds-spacing-100);
    --rating-stat-icon-margin: var(--cds-spacing-100);

    .${classes.icon} {
      width: 36px;
      height: 36px;
    }
  }
`;

export default styles;
