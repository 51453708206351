import { shuffle } from 'lodash';

/**
 * We inject a random funny console message to keep the users happy.
 * @type {Array}
 */
const funnyMessages = [
  'Flying to the moon and back...',
  'Locating the required gigapixels to render...',
  'Spinning up the hamster...',
  'Shovelling coal into the server...',
  'Go ahead -- hold your breath...',
  "Don't think of purple hippos...",
  'A few bits tried to escape, but we caught them...',
  "We're testing your patience...",
  'First snow, then silence.\n\n This expensive server dies\n\n So beautifully.',
];

const messages = ['Uploading...', 'Compiling...', 'Setting up execution environment...', 'Executing...'];

export type ExecutionTimeSummaryConstructorType = Partial<{
  max: number;
  median: number;
  p95: number;
}>;

class ExecutionTimeSummary {
  static slowExecutionThreshold = 5000;

  declare max: number | undefined;

  declare median: number | undefined;

  declare p95: number | undefined;

  declare messages: Array<string>;

  constructor({ max, median, p95 }: ExecutionTimeSummaryConstructorType) {
    this.max = max;
    this.median = median;
    this.p95 = p95;

    const postMessage = messages[3];
    const preMessages = messages.slice(0, 3);
    const funnyMessage = shuffle(funnyMessages).slice(1, 2)[0];

    this.messages = preMessages.concat([funnyMessage]).concat([postMessage]);
  }

  get duration() {
    if (this.p95) {
      return this.p95;
    } else if (this.median) {
      return this.median;
    } else if (this.max) {
      return this.max;
    }

    return 0;
  }

  get isExecutionSlow() {
    const { slowExecutionThreshold } = ExecutionTimeSummary;

    if (this.duration) {
      return this.duration > slowExecutionThreshold;
    } else {
      return false;
    }
  }

  getPercent(timeInMs: number) {
    return Math.min((timeInMs / this.duration) * 100, 100);
  }

  getExecutionStep(timeInMs: number) {
    const index = Math.round((this.getPercent(timeInMs) * this.messages.length) / 100);
    return this.messages[index] || this.messages[this.messages.length - 1];
  }
}

export default ExecutionTimeSummary;
