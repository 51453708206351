import * as React from 'react';

import { IdTypes } from 'bundles/product-features';
import { ProductFeaturesQuery } from 'bundles/program-home/productFeatureFlags';

import _t from 'i18n!nls/program-home';

const GetFilledProgramMessageComponent = ({ programId }: { programId: string }) => (
  <ProductFeaturesQuery idType={IdTypes.Program} id={programId}>
    {({ loading, features }) => {
      if (loading) {
        return <span>{_t('Loading...')}</span>;
      }

      const filledProgramCustomMessage = features.get('enterprise', 'filledProgramCustomMessage');

      return (
        <span>
          {filledProgramCustomMessage ||
            _t('Sorry, this program has been filled up. Please contact your learning team to add availability.')}
        </span>
      );
    }}
  </ProductFeaturesQuery>
);

const getFilledProgramErrorMessage = (programId: string) => GetFilledProgramMessageComponent({ programId });

const getUnavailableProgramErrorMessage = () => _t('Currently not available. Please reach out to your program admin.');

export default function getErrorMap(programId: string): Record<string, string | undefined | JSX.Element> {
  return {
    CREATE_MEMBERSHIP_USER_LIMIT_ERROR: getFilledProgramErrorMessage(programId),
    CREATE_MEMBERSHIP_CONTRACT_COURSES_PER_USER_LIMIT_ERROR: getFilledProgramErrorMessage(programId),
    CREATE_MEMBERSHIP_ENROLLMENT_CAP_LIMIT_ERROR: _t(
      'Sorry, you are unable to join the program at this time. All enrollments included in this program have been used, but more enrollments may become available later. If the problem persists, please contact your program admin.'
    ),
    CONTRACT_NOT_ACTIVE: getUnavailableProgramErrorMessage(),
    CONSUMABLE_CONTRACT_NOT_AVAILABLE: getUnavailableProgramErrorMessage(),
  };
}
