import {
  CLOSE_COACH_WIDGET,
  ENABLE_MESSAGE_LOADER,
  OPEN_COACH_WIDGET,
  TOGGLE_CHAT,
  TOGGLE_INPUT_DISABLED,
} from '../actions/types';
import type { BehaviorActions } from '../actions/types';
import type { BehaviorState } from '../types';
import { createReducer } from '../utils/createReducer';

const initialState = {
  showChat: false,
  disabledInput: false,
  messageLoader: false,
};

const behaviorReducer = {
  [TOGGLE_CHAT]: (state: BehaviorState) => {
    return { ...state, showChat: !state.showChat };
  },

  [OPEN_COACH_WIDGET]: (state: BehaviorState) => {
    return { ...state, showChat: true };
  },

  [CLOSE_COACH_WIDGET]: (state: BehaviorState) => {
    return { ...state, showChat: false };
  },

  [TOGGLE_INPUT_DISABLED]: (state: BehaviorState) => ({ ...state, disabledInput: !state.disabledInput }),

  [ENABLE_MESSAGE_LOADER]: (state: BehaviorState, { messageLoader }: { messageLoader: boolean }) => {
    return { ...state, messageLoader };
  },
};

export default (state: BehaviorState = initialState, action: BehaviorActions) =>
  createReducer(behaviorReducer, state, action);
