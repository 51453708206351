import { isEqual } from 'lodash';
import Q from 'q';

import type { ActionContext } from 'js/lib/ActionContext';

import {
  createWidgetSessionProvider as createWidgetSessionProviderApi,
  getWidgetSessionProviderByIdAndCourseId,
  previewWidgetSessionProvider as previewWidgetSessionProviderApi,
  updateWidgetSessionProvider as updateWidgetSessionProviderApi,
} from 'bundles/author-common/utils/AuthoringWidgetSessionAPIUtils';
import type { GraderConfiguration } from 'bundles/author-common/utils/AuthoringWidgetSessionAPIUtils';
import RequestManager from 'bundles/author-common/utils/RequestManager';
import {
  createWidgetItemDraft,
  getAuthoringWidgetItemDraft,
} from 'bundles/author-widget/utils/AuthoringWidgetItemAPIUtils';
import { getResourceId } from 'bundles/author-widget/utils/WidgetCommonUtils';
import { update } from 'bundles/author-widget/utils/WidgetUtils';
import type {
  AuthoringWidgetSessionProvider,
  AuthoringWidgetSessionProvidersV1,
} from 'bundles/naptimejs/resources/__generated__/AuthoringWidgetSessionProvidersV1';
import { createWidgetMap, getAuthoringWidgetSummaryListByCourse } from 'bundles/widget-admin/utils/WidgetAdminUtils';

const requestManager = new RequestManager();

export const loadWidget = (
  actionContext: ActionContext,
  {
    branchId,
    itemId,
    atomId,
    courseId,
    callback,
  }: { branchId: string; itemId: string; atomId: string; courseId: string; callback?: () => void }
) => {
  const authoringItemContentId = getResourceId(branchId, itemId, atomId);
  getAuthoringWidgetItemDraft(authoringItemContentId)
    .then((response) => {
      const widgetAuthoringData = response;
      const writeAccessToken = widgetAuthoringData.writeAccessToken;
      const writeAccessState = widgetAuthoringData.writeAccessState;
      const widgetItemContent = widgetAuthoringData.draft || widgetAuthoringData.published;
      const providerId =
        widgetAuthoringData.draft?.content.providerId || widgetAuthoringData.published.content.providerId;
      const hasDraft =
        widgetAuthoringData.draft !== undefined &&
        widgetAuthoringData.published !== undefined &&
        !isEqual(widgetAuthoringData.draft, widgetAuthoringData.published);

      if (providerId) {
        // this endpoint seems to always return the latest, even if the version is provided.
        const widgetSessionProviderId = providerId.split('@')[0];
        getWidgetSessionProviderByIdAndCourseId({
          id: widgetSessionProviderId,
          courseId,
        })
          .then((res) => {
            const widgetSummary = res.linked['authoringWidgetSummaries.v1'][0];
            const widgetSessionProviderDraft = res.elements[0];

            actionContext.dispatch('WIDGET_LOADED', {
              widgetItemContent,
              writeAccessToken,
              writeAccessState,
              widgetSessionProviderDraft,
              widgetSummary,
              hasDraft,
            });
            callback?.();
          })
          .catch(() => {
            actionContext.dispatch('WIDGET_LOADED', {
              widgetItemContent,
              writeAccessToken,
              writeAccessState,
              hasDraft,
            });
          });
      } else {
        actionContext.dispatch('WIDGET_LOADED', {
          widgetItemContent,
          writeAccessToken,
          writeAccessState,
          hasDraft,
        });
        callback?.();
      }
    })
    .catch((err) => {
      if (err.status === 404) {
        createWidgetItemDraft(authoringItemContentId).then((response) => {
          const widgetItemContent = response.elements[0].draftData;
          actionContext.dispatch('WIDGET_LOADED', {
            widgetItemContent,
          });
        });
      } else {
        throw err;
      }
    });
};

export const updateWidgetItem = (
  actionContext: ActionContext,
  {
    branchId,
    itemId,
    atomId,
    name,
    providerId,
    isGraded,
    timeCommitment,
    callback,
  }: {
    branchId: string;
    itemId: string;
    atomId: string;
    name: string;
    providerId: string;
    isGraded: boolean;
    timeCommitment: number | undefined;
    callback?: () => void;
  }
) => {
  actionContext.dispatch('WIDGET_WILL_UPDATE_DRAFT', { name, providerId, isGraded, timeCommitment });
  return update(actionContext, branchId, itemId, atomId, callback);
};

export const continueEditingWidgetItem = (actionContext: ActionContext) => {
  actionContext.dispatch('CONTINUE_EDITING_WIDGET_ITEM');
};

export const createWidgetSessionProvider = (
  actionContext: ActionContext,
  {
    widgetId,
    iframeTitle,
    courseId,
    configuration,
    branchId,
    itemId,
    atomId,
    name,
    isGraded,
    timeCommitment,
    isEmbeddedInCml,
    onContinue,
  }: {
    widgetId: string;
    iframeTitle: string;
    courseId: string;
    configuration: AuthoringWidgetSessionProvider['configuration'];
    branchId: string;
    itemId: string;
    atomId: string;
    name: string;
    isGraded: boolean;
    timeCommitment?: number;
    isEmbeddedInCml?: boolean;
    onContinue?: (id: string) => void;
  }
) => {
  actionContext.dispatch('CREATING_WIDGET_SESSION_PROVIDER');

  createWidgetSessionProviderApi(widgetId, iframeTitle, courseId, configuration)
    .then((response) => {
      const widgetSessionProviderDraft = response.elements[0];
      const providerId = widgetSessionProviderDraft.id;

      // If the widget is embedded in cml, we don't have a widget item and rely on the widget session.
      if (isEmbeddedInCml) {
        onContinue?.(providerId);
        return;
      }

      updateWidgetItem(actionContext, {
        branchId,
        itemId,
        atomId,
        name,
        providerId,
        isGraded,
        timeCommitment,
      });

      actionContext.dispatch('WIDGET_SESSION_PROVIDER_CREATED', {
        widgetSessionProviderDraft,
        widgetId,
      });
    })
    .catch((error) => actionContext.dispatch('WIDGET_AUTHORING_API_ERROR', error));
};

export const updateWidgetSessionProvider = (
  actionContext: ActionContext,
  {
    widgetSessionProvider,
    iframeTitle = '',
    widgetSessionProviderId,
    configuration,
    courseId,
    graderConfiguration,
    callback,
  }: {
    widgetSessionProvider?: AuthoringWidgetSessionProvidersV1;
    iframeTitle?: string;
    widgetSessionProviderId?: string;
    courseId: string;
    // these are user-supplied json converted to js object
    configuration?: object;
    // these are user-supplied json converted to js object
    graderConfiguration?: GraderConfiguration;
    callback?: () => void;
  }
): void => {
  requestManager.debouncedRun(() => {
    actionContext.dispatch('UPDATING_WIDGET_SESSION_PROVIDER');
    if (!widgetSessionProvider || !widgetSessionProviderId) {
      actionContext.dispatch('WIDGET_AUTHORING_API_ERROR', {
        message: 'widgetSessionProvider or widgetSessionProviderId is missing',
      });
      return Q.reject();
    }
    return updateWidgetSessionProviderApi(
      widgetSessionProvider,
      iframeTitle,
      widgetSessionProviderId,
      configuration,
      courseId,
      graderConfiguration
    )
      .then((response) => {
        const widgetSessionProviderDraft = response.elements[0];
        actionContext.dispatch('WIDGET_SESSION_PROVIDER_UPDATED', {
          widgetSessionProviderDraft,
        });
        callback?.();
        return widgetSessionProviderDraft;
      })
      .catch((error) => {
        actionContext.dispatch('WIDGET_AUTHORING_API_ERROR', error);
      });
  });
};

export const previewWidgetSessionProvider = (
  actionContext: ActionContext,
  {
    widgetSessionProviderId,
    courseId,
    itemId,
    branchId,
  }: {
    widgetSessionProviderId: string;
    courseId: string;
    itemId: string;
    branchId?: string;
  }
) => {
  actionContext.dispatch('GETTING_WIDGET_PREVIEW');
  previewWidgetSessionProviderApi(widgetSessionProviderId, courseId, itemId, branchId)
    .then((response) => {
      const { session, sessionId } = response;
      actionContext.dispatch('RECEIVE_WIDGET_SESSION', {
        session,
        sessionId,
      });
    })
    .catch((error) => actionContext.dispatch('WIDGET_AUTHORING_API_ERROR', error));
};

export const loadWidgets = (
  actionContext: ActionContext,
  { courseId, filterGraded, filterUngraded }: { courseId: string; filterGraded: boolean; filterUngraded: boolean }
) => {
  const filterHidden = true;
  const filterPublished = false;

  getAuthoringWidgetSummaryListByCourse(courseId, filterHidden, filterGraded, filterUngraded, filterPublished)
    .then((widgets) => {
      const widgetMap = createWidgetMap(widgets);
      actionContext.dispatch('RECEIVED_WIDGETS', {
        widgets,
        widgetMap,
      });
    })
    .catch(() => {});
};

export const clearWidgets = (actionContext: ActionContext) => {
  actionContext.dispatch('CLEAR_WIDGETS');
};
