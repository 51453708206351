import * as React from 'react';

import type { IconSize } from 'bundles/ai-coach-platform/types';

/**
 * MUI icon for Chevron right
 */
const ChevronRightIcon = ({ size = 16 }: { size?: IconSize }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.73333 8.00399L6 5.27065C5.87778 5.14843 5.81667 5.00676 5.81667 4.84565C5.81667 4.68454 5.87778 4.54288 6 4.42065C6.12222 4.29843 6.26389 4.23732 6.425 4.23732C6.58611 4.23732 6.72967 4.30032 6.85567 4.42632L10.0167 7.58732C10.0722 7.64732 10.1139 7.71232 10.1417 7.78232C10.1694 7.85232 10.1833 7.92732 10.1833 8.00732C10.1833 8.08732 10.1694 8.16232 10.1417 8.23232C10.1139 8.30232 10.0722 8.3651 10.0167 8.42065L6.85567 11.5817C6.72967 11.7077 6.58889 11.7679 6.43333 11.7623C6.27778 11.7568 6.13889 11.6929 6.01667 11.5707C5.89444 11.4484 5.83333 11.3068 5.83333 11.1457C5.83333 10.9845 5.89444 10.8429 6.01667 10.7207L8.73333 8.00399Z"
      fill="var(--cds-color-grey-950)"
    />
  </svg>
);

export default ChevronRightIcon;
