/**
 * Update type definitions using
 * yarn graphql:types static/bundles/enterprise-legacy-xdp/components/miniPDP/PageQuery.ts
 */
import gql from 'graphql-tag';

import { ProductMetricsCdpFragment } from 'bundles/enterprise-legacy-xdp/components/ProductMetrics';
import AboutProject from 'bundles/enterprise-legacy-xdp/components/miniPDP/AboutProject';
import Banner from 'bundles/enterprise-legacy-xdp/components/miniPDP/Banner';
import InstructorListWrapper from 'bundles/enterprise-legacy-xdp/components/miniPDP/InstructorListWrapper';
import PdpAdminBanner from 'bundles/enterprise-legacy-xdp/components/miniPDP/PdpAdminBanner';
import PdpBreadcrumb from 'bundles/enterprise-legacy-xdp/components/miniPDP/PdpBreadcrumb';
import PdpSkills from 'bundles/enterprise-legacy-xdp/components/miniPDP/PdpSkills';
import SchemaMarkup from 'bundles/enterprise-legacy-xdp/components/miniPDP/SchemaMarkup';
import Screenshots from 'bundles/enterprise-legacy-xdp/components/miniPDP/Screenshots';
import TaskList from 'bundles/enterprise-legacy-xdp/components/miniPDP/TaskList';
import UserReviewsWrapper from 'bundles/enterprise-legacy-xdp/components/miniPDP/UserReviewsWrapper';
import BannerInfoBox from 'bundles/enterprise-legacy-xdp/components/miniPDP/bannerInfo/BannerInfoBox';
import { PartnerIncentivesCdpFragment } from 'bundles/learner-incentives/components/LearnerIncentive';

export default gql`
  query PdpPageQuery($slug: String!) {
    XdpV1Resource {
      slug(productType: "COURSE", slug: $slug) {
        elements {
          id
          slug
          xdpMetadata {
            ... on XdpV1_cdpMetadataMember {
              cdpMetadata {
                projectId: id
                ...PdpAdminBanner
                ...PdpBreadcrumb
                ...Banner
                ...BannerInfoBox
                ...AboutProject
                ...TaskList
                ...Screenshots
                ...Skills
                ...Instructors
                ...SchemaMarkup
                ...UserReviews
                ...PartnerIncentivesCdpFragment
                ...ProductMetricsCdpFragment
                courseTypeMetadata {
                  ... on XdpV1_projectMember {
                    project {
                      typeNameIndex
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${PdpAdminBanner.fragments.PdpAdminBanner}
  ${PdpBreadcrumb.fragments.PdpBreadcrumb}
  ${Banner.fragments.Banner}
  ${BannerInfoBox.fragments.BannerInfoBox}
  ${AboutProject.fragments.AboutProject}
  ${TaskList.fragments.TaskList}
  ${Screenshots.fragments.Screenshots}
  ${PdpSkills.fragments.PdpSkills}
  ${InstructorListWrapper.fragments.Instructors}
  ${SchemaMarkup.fragments.SchemaMarkup}
  ${UserReviewsWrapper.fragments.UserReviews}
  ${PartnerIncentivesCdpFragment}
  ${ProductMetricsCdpFragment}
`;

export const PageQueryById = gql`
  query PdpPageQueryById($id: String!) {
    XdpV1Resource {
      get(id: $id) {
        id
        slug
        xdpMetadata {
          ... on XdpV1_cdpMetadataMember {
            cdpMetadata {
              projectId: id
              ...PdpBreadcrumb
              ...Banner
              ...BannerInfoBox
              ...AboutProject
              ...TaskList
              ...Screenshots
              ...Skills
              ...Instructors
              ...UserReviews
              ...PartnerIncentivesCdpFragment
              ...ProductMetricsCdpFragment
              courseTypeMetadata {
                ... on XdpV1_projectMember {
                  project {
                    typeNameIndex
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${PdpBreadcrumb.fragments.PdpBreadcrumb}
  ${Banner.fragments.Banner}
  ${BannerInfoBox.fragments.BannerInfoBox}
  ${AboutProject.fragments.AboutProject}
  ${TaskList.fragments.TaskList}
  ${Screenshots.fragments.Screenshots}
  ${PdpSkills.fragments.PdpSkills}
  ${InstructorListWrapper.fragments.Instructors}
  ${UserReviewsWrapper.fragments.UserReviews}
  ${PartnerIncentivesCdpFragment}
  ${ProductMetricsCdpFragment}
`;
