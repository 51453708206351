import * as React from 'react';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { simplifyQueryValue } from 'js/lib/queryUtils';
import { useLocation, useRouter } from 'js/lib/useRouter';

import { QUERY_PARAMS, QUERY_PARAM_VALUES } from 'bundles/unified-home-common/constants';

export type CurrentTabContextType = {
  currentTab: string;
  onTabChange: (value: string) => void;
};

export const CurrentTabContext = createContext<CurrentTabContextType | undefined>(undefined);

export const useCurrentTabContext = () => {
  const context = useContext(CurrentTabContext);

  return { currentTab: context?.currentTab, onTabChange: context?.onTabChange };
};

export const CurrentTabContextProvider: React.FC<Partial<CurrentTabContextType>> = ({ children }) => {
  const location = useLocation();
  const router = useRouter();
  const [currentTab, setCurrentTab] = useState<string>(
    simplifyQueryValue(location.query?.[QUERY_PARAMS.MY_LEARNING_TAB]) || QUERY_PARAM_VALUES.IN_PROGRESS
  );

  useEffect(() => {
    const queryParamValue = location.query[QUERY_PARAMS.MY_LEARNING_TAB];

    if (!queryParamValue) {
      router.push({
        pathname: location.pathname,
        query: {
          ...location.query,
          myLearningTab: currentTab || '',
        },
      });
    }
  }, [location, currentTab, router]);

  const onTabChange = useCallback(
    (tabQueryParamValue: string) => {
      setCurrentTab(tabQueryParamValue);

      delete location.query.page;
      router.push({
        pathname: location.pathname,
        query: { ...location.query, myLearningTab: tabQueryParamValue },
      });
    },
    [location, router, setCurrentTab]
  );

  return <CurrentTabContext.Provider value={{ currentTab, onTabChange }}>{children}</CurrentTabContext.Provider>;
};
