import gql from 'graphql-tag';

import discoveryCollectionsGql from 'js/lib/discoveryCollectionsGql';

export const SkillSetListQuery = gql`
  query SkillSetListQuery($programId: String!) {
    EnterpriseTargetSkillProfileSummariesV1Resource {
      byProgram(id: $programId) {
        elements {
          id
          slug
          title
          occupationId
          goalType
          description {
            ... on EnterpriseTargetSkillProfileSummariesV1_cmlMember {
              cml {
                dtdId
                value
              }
            }
          }
          targetSkillProficiencies {
            skillId
            skillName
            targetProficiency
          }
        }
      }
    }
  }
`;

export const SavedProductsQuery = gql`
  query SavedProductsQuery($programId: String!, $start: String!, $limit: Int) {
    ProgramCurriculumProductsV1Resource {
      selected(programId: $programId, start: $start, limit: $limit) {
        elements {
          id
          productState {
            ... on ProgramCurriculumProductsV1_programCourseWithStateMember {
              programCourseWithState {
                courseId
                state
                reasonsForState
                isWishlisted
                enrolledAt
                completedAt
                actions
                lastUpdatedAt
                enrolledTargetSkillProfileId
              }
            }
            ... on ProgramCurriculumProductsV1_programS12nWithStateMember {
              programS12nWithState {
                s12nId
                state
                reasonsForState
                isWishlisted
                enrolledAt
                latestS12nId
                baseS12nId
                actions
                lastUpdatedAt
                enrolledTargetSkillProfileId
                courseStates {
                  courseId
                  state
                  isWishlisted
                  enrolledAt
                  completedAt
                  actions
                  lastUpdatedAt
                  reasonsForState
                  enrolledTargetSkillProfileId
                }
              }
            }
          }
        }
        paging {
          next
          total
        }
      }
    }
  }
`;

export const DiscoveryBrowseCollectionsByOrganizationQuery = discoveryCollectionsGql`
  query DiscoveryBrowseCollectionsByOrganizationQuery($contextType: String!, $contextId: String!, $organizationId: String!, $start: Int!, $limit: Int!) {
    DiscoveryCollections {
      queryEnterpriseCollectionsByOrganization(
        input: {
          contextType: $contextType, 
          contextId: $contextId,
          organizationId: $organizationId, 
          cursor: {
            start: $start, 
            limit: $limit
          }
        }
        ) {
          productCollections {
            id
            label
            linkedCollectionPageMetadata {
              url
            }
            entities {
              __typename
              id
              slug
              name
              url
              partnerIds
              partners {
                id
                name
                logo
              }
              imageUrl
              ... on DiscoveryCollections_specialization {
                courseCount
              }
              ... on DiscoveryCollections_course {
                enterpriseCustomSessions {
                  courseId
                  startsAt {
                    seconds
                  }
                  endsAt {
                    seconds
                  }
                }
              }
              ... on DiscoveryCollections_guidedProject {
                enterpriseCustomSessions {
                  courseId
                  startsAt {
                    seconds
                  }
                  endsAt {
                    seconds
                  }
                }
              }
            }
          }
        next
      }
    }
  }
`;
