import { Editor, Element, Path, Range, Transforms } from 'slate';
import type { Node, NodeEntry, PathRef } from 'slate';

import { isTypeDisabled } from 'bundles/cml/editor/utils/elementUtils';
import { getAncestorOfType } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { ListElement, ListItemElement, TextElement } from 'bundles/cml/shared/types/elementTypes';

type LIST_TYPES = typeof BLOCK_TYPES.BULLET_LIST | typeof BLOCK_TYPES.NUMBER_LIST;

export const moveListItem = (editor: Editor, from: Path, to: Path) => {
  const [toListItem] = Editor.node(editor, to) as NodeEntry<ListItemElement>;
  if (toListItem.children.length === 2) {
    const subList = toListItem.children[1];
    Transforms.moveNodes(editor, {
      at: from,
      to: [...to, 1, subList.children.length],
    });
    return;
  }

  const parentListPath = Path.parent(to);
  const [parentList] = Editor.node(editor, parentListPath) as NodeEntry<ListElement>;

  Editor.withoutNormalizing(editor, () => {
    Transforms.wrapNodes(editor, { type: parentList.type, children: [] }, { at: from });
    Transforms.moveNodes(editor, {
      at: from,
      to: [...to, toListItem.children.length],
    });
  });
};

export const moveListItems = (editor: Editor, from: PathRef[], to: Path) => {
  from.forEach((pathRef: PathRef) => {
    if (pathRef.current) {
      moveListItem(editor, pathRef.current, to);
    }

    pathRef.unref();
  });
};

const wrapTextNodesInList = (editor: Editor, listType: LIST_TYPES) => {
  const textNodeEntries = Array.from<NodeEntry<TextElement>>(
    Editor.nodes(editor, {
      mode: 'lowest',
      match: (node: Node) => Element.isElement(node) && node.type === BLOCK_TYPES.TEXT,
    })
  );

  const pathRefs = textNodeEntries.map((textNodeEntry) => Editor.pathRef(editor, textNodeEntry[1]));

  Editor.withoutNormalizing(editor, () => {
    pathRefs.forEach((pathRef) => {
      const path = pathRef.current;
      if (path) {
        Transforms.wrapNodes(editor, { type: BLOCK_TYPES.LIST_ITEM, children: [] }, { at: path });
        Transforms.wrapNodes(editor, { type: listType, children: [] }, { at: path });
      }
      pathRef.unref();
    });
  });
};

export const toggleList = (editor: Editor, listType: LIST_TYPES) => {
  const listItemEntry = getAncestorOfType(editor, BLOCK_TYPES.LIST_ITEM);
  if (!listItemEntry) {
    wrapTextNodesInList(editor, listType);
    return;
  }

  Editor.withoutNormalizing(editor, () => {
    const [listItem, listItemPath] = listItemEntry as NodeEntry<ListItemElement>;
    let hasSubList = listItem.children.length > 1;

    const currentItemPath = [...listItemPath];
    while (currentItemPath.length > 1) {
      const [currentItem] = Editor.node(editor, currentItemPath) as NodeEntry<ListItemElement>;
      const [currentList, currentListPath] = Editor.parent(editor, currentItemPath) as [ListElement, Path];

      // move siblings to current list item
      const currentItems = currentList.children;
      const index = currentItems.indexOf(currentItem);

      const pathRefs: PathRef[] = [];
      for (let i = index + 1; i < currentItems.length; i += 1) {
        const pathRef = Editor.pathRef(editor, [...currentListPath, i]);
        pathRefs.push(pathRef);
        hasSubList = true;
      }

      moveListItems(editor, pathRefs, listItemPath);

      currentItemPath.pop();
      currentItemPath.pop();
    }

    if (hasSubList) {
      Transforms.moveNodes(editor, { at: [...listItemPath, 1], to: [listItemPath[0] + 1] });
    }

    Transforms.moveNodes(editor, {
      at: [...listItemPath, 0],
      to: [listItemPath[0] + 1],
    });

    const listItemIndex = listItemPath[listItemPath.length - 1];
    if (listItemIndex === 0) {
      listItemPath.pop();
      Transforms.removeNodes(editor, { at: listItemPath });
    } else {
      Transforms.removeNodes(editor, { at: listItemPath });
    }
  });
};

export const shouldDisableList = (editor: Editor, listType: LIST_TYPES) => {
  if (isTypeDisabled(editor, listType) || !editor.selection) {
    return true;
  }

  if (!Range.isExpanded(editor.selection)) {
    return false;
  }

  const listNodes = Array.from(
    Editor.nodes(editor, {
      mode: 'lowest',
      match: (node: Node) => Element.isElement(node) && node.type === BLOCK_TYPES.LIST_ITEM,
    })
  );

  if (listNodes.length > 1) {
    return true;
  }

  const nodes = Array.from(
    Editor.nodes(editor, {
      mode: 'lowest',
      match: (node: Node) => Element.isElement(node) && node.type === BLOCK_TYPES.TEXT,
    })
  );

  return nodes.length === 0;
};
