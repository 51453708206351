/** @jsx jsx */
import { css, jsx, keyframes } from '@emotion/react';

import { PreloaderIcon } from '@coursera/cds-icons';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const styles = {
  spinning: css`
    animation: ${spin} 1.5s linear infinite;
  `,
};

/**
 * @deprecated use CDS loading Button prop or LoadingSection component instead
 */
export function Spinner() {
  return <PreloaderIcon css={styles.spinning} />;
}

export default Spinner;
