/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import logger from 'js/app/loggerSingleton';

import { Button, Dialog, Typography2, useTheme } from '@coursera/cds-core';
import { CheckIcon } from '@coursera/cds-icons';

import CourseraLiteEnrollButton from 'bundles/coursera-plus/components/subscriptionTiers/CourseraLiteEnrollButton';
import { getLiteOnlyEnrollModalCopies } from 'bundles/coursera-plus/components/subscriptionTiers/SubscriptionTiersEnrollCopies';
import { SizedImage } from 'bundles/coursera-plus/components/subscriptionTiers/subscriptionTiersUtils';
import { logo } from 'bundles/coursera-plus/utils/courseraPlusUtils';
import usePageData from 'bundles/enroll/data/usePageData';
import TrackedDiv from 'bundles/page/components/TrackedDiv';

const useStyles = () => {
  const theme = useTheme();
  return {
    dialog: css`
      .cds-Dialog-dialog {
        max-width: 720px !important;
      }
    `,
    title: css`
      padding-bottom: var(--cds-spacing-200);
      font-size: 28px;
      font-weight: 600;
      line-height: 36px;

      > div {
        display: inline-block;
        vertical-align: middle;
      }

      img {
        margin-right: 15px;
      }
    `,
    valueProps: css`
      padding: 0;
      margin: 0 0 var(--cds-spacing-400);
      list-style-type: none;
    `,
    valuePropListItem: css`
      display: flex;
      align-items: flex-start;
      margin: var(--cds-spacing-100) 0;
    `,
    valuePropHeader: css`
      margin-bottom: 0;
    `,
    valueProp: css`
      margin: 0;
    `,
    checkMark: css`
      margin-top: 2px;
      margin-right: var(--cds-spacing-100);
    `,
    cta: css`
      padding: var(--cds-spacing-150) 0 var(--cds-spacing-300);
      margin-bottom: var(--cds-spacing-150);
    `,
    goBackButton: css`
      margin-left: 28px;
    `,
  };
};

type PropsFromCaller = {
  onClose: () => void;
  onGoBack: () => void;
};

const SubscriptionTiersLiteOnlyEnrollModal: React.FC<PropsFromCaller> = ({ onClose, onGoBack }) => {
  const { s12n, course, enrollmentAvailableChoices, courseraLiteProductPrice } = usePageData();
  const styles = useStyles();

  if (!s12n || !courseraLiteProductPrice) {
    logger.error('Unable to render Subscription Tiers enroll modal without s12n or pricing');
    return null;
  }

  const { Content, Actions } = Dialog;
  const copy = getLiteOnlyEnrollModalCopies(courseraLiteProductPrice, s12n);

  const hasFreeTrial = enrollmentAvailableChoices.canSubscribeToCourseraLiteFreeTrial;

  return (
    <TrackedDiv
      trackingName="subscription_tiers_lite_only_enroll_modal"
      trackClicks={false}
      withVisibilityTracking={true}
      data={{ course: course.id, s12n: s12n.id }}
    >
      <Dialog
        aria-describedby="dialog-content"
        open
        variant="standard"
        width="large"
        onClose={onClose}
        css={styles.dialog}
      >
        <Content id="dialog-content">
          <div css={styles.title}>
            <div>
              <SizedImage src={logo.LITE_FULL_BLUE} alt={copy.courseraLite} width={195} height={20} />
            </div>
            <div>{hasFreeTrial && copy.freeTrialTitle}</div>
          </div>

          <ul css={styles.valueProps}>
            {copy.valueProps.map((bullet) => (
              <li key={`value-prop-${bullet}`} css={styles.valuePropListItem}>
                <CheckIcon size="small" color="interactive" css={styles.checkMark} />
                <Typography2 component="div">
                  <p css={styles.valueProp}>{bullet}</p>
                </Typography2>
              </li>
            ))}
          </ul>
          <Typography2 component="p">
            <strong>{copy.monthlyPrice}</strong>
          </Typography2>
          <Actions css={styles.cta}>
            <CourseraLiteEnrollButton
              trackingName="subscription_tiers_lite_only_enroll_modal_enroll_button"
              variant="primary"
              data-testid="cta"
            >
              {copy.cta}
            </CourseraLiteEnrollButton>
            <Button variant="ghost" onClick={onGoBack} css={styles.goBackButton}>
              {copy.goBack}
            </Button>
          </Actions>
        </Content>
      </Dialog>
    </TrackedDiv>
  );
};

export default SubscriptionTiersLiteOnlyEnrollModal;
