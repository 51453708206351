import * as React from 'react';

import { Box, Pill, StyleSheet, color, css } from '@coursera/coursera-ui';
import { SvgBookmarkFilled, SvgCheckSolid, SvgSuccess } from '@coursera/coursera-ui/svg';

import { TrackedButton } from 'bundles/common/components/TrackedCui';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import type ProgramCurriculumProductsV1 from 'bundles/naptimejs/resources/programCurriculumProducts.v1';
import ProductCardState from 'bundles/program-common/components/ProductCardState';
import type { Product } from 'bundles/program-common/types/programCommon';

import _t from 'i18n!nls/program-common';

type BaseProps = {
  htmlAttributes?: { [styleAttr: string]: string | number };
  isS12n?: boolean;
  isEnrolled?: boolean;
  isNotSelected?: boolean;
  isSelected?: boolean;
  isWishlisted?: boolean;
  isUnavailable?: boolean;
  isIncluded?: boolean;
  product: Product;
  productState?: ProgramCurriculumProductsV1;
  invertS12nSelection?: boolean;
};

export type OnToggleSelect = (props: BaseProps) => void;

type Props = BaseProps & {
  onToggleSelect?: OnToggleSelect;
};

const styles = StyleSheet.create({
  notSelectedPillText: {
    color: color.black,
  },

  s12nSelectedText: {
    color: color.white,
  },

  notSelectedText: {
    color: color.darkGray,
  },

  buttonStyle: {
    ':focus': {
      boxShadow: `0 0 0 2px ${color.primary}`,
      borderRadius: '1.5rem',
      outline: 'none !important',
    },
  },
});

export default function ProductCardStateWrapper({
  htmlAttributes,
  isS12n,
  isEnrolled,
  isNotSelected,
  isSelected,
  isUnavailable,
  isWishlisted,
  isIncluded,
  onToggleSelect,
  product,
  productState,
  invertS12nSelection,
}: Props): JSX.Element {
  const canSelect = isSelected || isNotSelected;
  let iconColor: string;
  if (isS12n && invertS12nSelection) {
    iconColor = isSelected ? color.white : color.gray;
  } else {
    iconColor = isSelected ? color.primary : color.bgDarkGray;
  }

  const curriculumProduct = { productState: {} };
  if (productState && productState.productState) {
    // used for naptime which has a definition param or graphql which doesn't
    curriculumProduct.productState = productState.productState.definition || productState.productState;
  }

  const isProductS12n = isS12n || (productState && productState.isS12n);
  const isProductEnrolled = isEnrolled || (productState && productState.isEnrolled);
  const isProductUnavailable = isUnavailable || (productState && productState.isUnavailable);
  const isProductWishlisted = isWishlisted || (productState && productState.isWishlisted);

  const renderProductCardPill = () => {
    if (isProductEnrolled || isProductUnavailable || isProductWishlisted) {
      return (
        <>
          {isProductEnrolled && (
            <span
              key="ProductCardStateWrapper~Enrolled"
              {...css('p-r-1s', isS12n && invertS12nSelection ? styles.s12nSelectedText : styles.notSelectedText)}
            >
              {_t('Enrolled')}
            </span>
          )}
          {isProductUnavailable && (
            <span
              key="ProductCardStateWrapper~Unavailable"
              {...css('p-r-1s', isS12n && invertS12nSelection ? styles.s12nSelectedText : styles.notSelectedText)}
            >
              {_t('Unavailable')}
            </span>
          )}
          {isProductEnrolled && <SvgCheckSolid size={24} color={iconColor} />}
          {isNotSelected && !isIncluded && (
            <SvgSuccess size={24} color={iconColor} hoverColor={isUnavailable ? iconColor : color.primary} />
          )}
          {isProductWishlisted && <SvgBookmarkFilled color={color.primary} hoverColor={color.darkPrimary} />}
        </>
      );
    } else {
      return (
        <>
          <Pill
            type={isSelected || (isS12n && invertS12nSelection) ? 'filled' : 'outline'}
            fillColor={isSelected ? color.primary : color.white}
            borderColor="#1F1F1F"
            style={{ paddingLeft: '12px', paddingRight: '12px' }}
          >
            <span {...css('p-r-1s', isSelected ? styles.s12nSelectedText : styles.notSelectedPillText)}>
              {isSelected ? _t('Selected') : _t('Select')}
            </span>
            {isSelected ? (
              <SvgCheckSolid size={12} color={color.white} />
            ) : (
              <SvgSuccess size={12} color="#1F1F1F" hoverColor={color.primary} />
            )}
          </Pill>
        </>
      );
    }
  };

  const onClick = (e: React.SyntheticEvent<unknown>) => {
    e?.stopPropagation();
    onToggleSelect?.({
      isS12n: isProductS12n,
      isSelected: !isSelected,
      product,
    });
  };

  return (
    <div>
      {canSelect && (
        <Box
          alignItems="center"
          justifyContent="end"
          rootClassName="ProductCardStateWrapper text-secondary-dark font-xs m-b-1s"
          {...htmlAttributes}
        >
          {isIncluded ? (
            <Pill
              type="filled"
              fillColor="#888888"
              style={{ paddingLeft: '12px', paddingRight: '12px' }}
              data-e2e="is-included-pill"
            >
              <span {...css('p-r-1s', styles.s12nSelectedText)}>{_t('Included')}</span>
              <SvgCheckSolid size={12} color={color.white} />
            </Pill>
          ) : (
            <TrackedButton
              type="noStyle"
              rootClassName={css(styles.buttonStyle).className}
              size="zero"
              data-js="ToggleBtn"
              isTransparent
              disabled={isProductUnavailable}
              trackingName="product_select_button"
              trackingData={{
                action: isSelected ? 'deselect' : 'select',
                productId: product.id,
                isS12n,
              }}
              onClick={onClick}
            >
              {renderProductCardPill()}
            </TrackedButton>
          )}
        </Box>
      )}
      {!canSelect && <ProductCardState curriculumProduct={curriculumProduct} isThemeDark={isS12n} />}
    </div>
  );
}
