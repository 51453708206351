/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import 'js/lib/popups';

import Icon from 'bundles/iconfont/Icon';

const styles = {
  dropdownMenu: css`
    .bt3-dropdown-menu {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      display: none;
      float: left;
      min-width: 160px;
      padding: 5px 0;
      margin: 2px 0 0;
      list-style: none;
      font-size: 14px;
      background-color: #fff;
      border: 1px solid #ccc;
      border: 1px solid rgb(0 0 0 / 15%);
      border-radius: 4px;
      -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 17.5%);
      box-shadow: 0 6px 12px rgb(0 0 0 / 17.5%);
      background-clip: padding-box;
    }

    .bt3-dropdown-menu > li > a {
      display: block;
      padding: 3px 20px;
      clear: both;
      font-weight: normal;
      line-height: 1.4286;
      color: #333;
      white-space: nowrap;
    }

    .bt3-dropdown-menu > li > a:hover,
    .bt3-dropdown-menu > li > a:focus {
      text-decoration: none;
      color: #303030;
      background-color: #f5f5f5;
    }

    .bt3-dropdown-menu > .bt3-active > a,
    .bt3-dropdown-menu > .bt3-active > a:hover,
    .bt3-dropdown-menu > .bt3-active > a:focus {
      color: #fff;
      text-decoration: none;
      outline: 0;
      background-color: #428bca;
    }

    .bt3-open > .bt3-dropdown-menu {
      display: block;
    }

    .bt3-open > a {
      outline: 0;
    }
  `,
};

class Dropdown extends React.Component {
  static propTypes = {
    dropdownId: PropTypes.string.isRequired,
    listItems: PropTypes.arrayOf(PropTypes.node).isRequired,
    dropdownDirection: PropTypes.string.isRequired,
    className: PropTypes.string,
    toggleClassName: PropTypes.string,
    iconClassName: PropTypes.string,
    iconName: PropTypes.string,
    label: PropTypes.string,
  };

  static defaultProps = {
    dropdownDirection: 'se',
  };

  sanitizeId(id: $TSFixMe) {
    return id.replace(/\W/g, '');
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'dropdownId' does not exist on type 'Read... Remove this comment to see the full error message
    const { dropdownId, label } = this.props;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'toggleClassName' does not exist on type ... Remove this comment to see the full error message
    const toggleClassnames = classnames('nostyle', this.props.toggleClassName);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'iconName' does not exist on type 'Readon... Remove this comment to see the full error message
    const iconName = this.props.iconName || 'ellipsis-h';

    const sanitizedDropdownId = this.sanitizeId(dropdownId);

    return (
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
      <div className={this.props.className}>
        <div css={styles.dropdownMenu}>
          <button
            aria-haspopup="true"
            aria-expanded="false"
            data-toggle="dropdown"
            aria-owns={sanitizedDropdownId}
            data-popup={`#${sanitizedDropdownId}`}
            data-popup-bind-open="click"
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'dropdownDirection' does not exist on typ... Remove this comment to see the full error message
            data-popup-direction={this.props.dropdownDirection}
            data-popup-close="data-popup-close"
            className={toggleClassnames}
            aria-label={label}
            type="button"
          >
            {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'iconClassName' does not exist on type 'R... Remove this comment to see the full error message */}
            <Icon name={iconName} className={this.props.iconClassName} />
          </button>
          <ul className="styleguide dropdown bt3-dropdown-menu" id={sanitizedDropdownId}>
            {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'listItems' does not exist on type 'Reado... Remove this comment to see the full error message */}
            {this.props.listItems.map((item, index) => (
              <li key={`${sanitizedDropdownId}-listItem${index}`}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default Dropdown;
