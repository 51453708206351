import * as React from 'react';

import { Button, Dialog, InlineNotification } from '@coursera/cds-core';

import _t from 'i18n!nls/author-course';

type Props = {
  showModal: boolean;
  allowClose?: boolean;
  onAccept: (e: React.MouseEvent | React.KeyboardEvent) => void;
  onCancel: (e: React.MouseEvent | React.KeyboardEvent) => void;
  acceptLabel?: string;
  title: React.ReactNode & React.ReactText;
  warningContent?: string | JSX.Element;
  content?: React.ReactNode;
};

const AuthorConfirmModal = (props: Props) => {
  const { showModal, allowClose, onCancel, onAccept, acceptLabel, title, warningContent, content } = props;
  return (
    <Dialog
      open={showModal}
      variant={allowClose ? 'standard' : 'transactional'}
      onClose={onCancel}
      className="rc-AuthorConfirmModal"
    >
      <Dialog.HeadingGroup>{title}</Dialog.HeadingGroup>
      <Dialog.Content>
        {content}
        {warningContent && (
          <InlineNotification style={{ marginTop: '24px' }} severity="warning">
            {warningContent}
          </InlineNotification>
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <Button variant="primary" onClick={onAccept} style={{ marginRight: '24px' }}>
          {acceptLabel || _t('Confirm')}
        </Button>
        <Button variant="secondary" onClick={onCancel}>
          {_t('Cancel')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

AuthorConfirmModal.defaultProps = {
  allowClose: true,
};

export default AuthorConfirmModal;
