/** @jsx jsx */

/** @jsxFrag Fragment */
import { css, jsx } from '@emotion/react';

import { Fragment } from 'react';

import { FormattedHTMLMessage } from 'js/lib/coursera.react-intl';

import { Divider, Tag, Typography, Typography2, useTheme } from '@coursera/cds-core';

import { isSkillsInsightsTooltip } from 'bundles/skills-common/private/skillsInsightsEnabled';

import _t from 'i18n!nls/skills-common';

import PlaceholderShimmer from '../PlaceholderShimmer';
import BenchmarkScorePopoverContent from './BenchmarkScorePopoverContent';
import PersonalScorePopoverContent from './PersonalScorePopoverContent';
import type { ProgressBarProps } from './ProgressBar';
import ProgressTrackerPopover from './ProgressTrackerPopover';

const getScoreLabel = (score: number) => {
  if (score > 350) {
    return _t('Advanced');
  } else if (score > 150) {
    return _t('Intermediate');
  } else if (score > 50) {
    return _t('Beginner');
  }
  return _t('Conversant');
};

type ProgressTrackerHeaderProps = ProgressBarProps & {
  hideScore: boolean;
  hideTarget?: boolean;
  skillName?: string;
  headerSize?: 'sm' | 'md';
  personalScoreLabelText?: string;
  benchmarkLabelText?: string;
  personalScorePopoverContent?: JSX.Element;
  afterPersonalScore?: JSX.Element;
  endOfHeader?: JSX.Element;
  forScoring?: boolean;
  wrapHeader?: boolean;
};

type PropsForPlaceholder = Pick<ProgressTrackerHeaderProps, 'headerSize' | 'wrapHeader'>;

const useStyles = (wrapHeader: boolean) => {
  const { spacing } = useTheme();
  return {
    progressTrackerHeader: css`
      display: flex;
      justify-content: between;
      align-items: baseline;
      flex-wrap: ${wrapHeader ? 'wrap' : 'nowrap'};
      margin-top: -6px;
    `,
    skillName: css`
      text-overflow: ellipsis;
      overflow: hidden;
      margin: ${spacing(8, 8, 0, 0)};
      flex: 1 1 100%;
    `,
    score: css`
      flex: 0 0 auto;
      display: flex;
      margin-top: ${spacing(8)};
    `,
    scoreLabel: css`
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      gap: ${spacing(4)};
    `,
    targetScoreLabel: css`
      flex-direction: reverse-row;
    `,
    divider: css`
      margin: ${spacing(0, 4)};
    `,
    scoreTag: css`
      margin-left: ${spacing(8)};
    `,
  };
};

const ProgressTrackerHeader = (props: ProgressTrackerHeaderProps) => {
  const {
    score,
    skillName,
    headerSize = 'md',
    endOfHeader,
    forScoring,
    hideScore,
    target,
    personalScoreLabelText = _t('My Score'),
    benchmarkLabelText = _t('Target'),
    personalScorePopoverContent,
    hideTarget,
    afterPersonalScore,
    wrapHeader = false,
  } = props;

  const styles = useStyles(wrapHeader);

  let scoreContent;
  if (forScoring) {
    scoreContent = (
      <>
        <Typography2 component="p" variant="bodySecondary">
          <FormattedHTMLMessage message={_t(`Your score: <strong>{score}</strong>`)} score={(score || 0).toString()} />
        </Typography2>
        {score ? (
          <Tag css={styles.scoreTag} variant="category" priority="primary">
            {getScoreLabel(score)}
          </Tag>
        ) : undefined}
      </>
    );
  } else {
    const noValue = (
      <>
        <span aria-hidden role="presentation">
          &mdash;
        </span>
        <span className="sr-only">0</span>
      </>
    );

    const personalLabel = !hideScore && (
      <ProgressTrackerPopover
        popoverContent={
          personalScorePopoverContent || (
            <PersonalScorePopoverContent skillName={skillName} score={score} target={target} />
          )
        }
      >
        <div tabIndex={0} css={styles.scoreLabel}>
          <Typography2 variant="bodySecondary" component="span">
            {personalScoreLabelText}
          </Typography2>
          <Typography2 variant="subtitleMedium" component="span">
            {score || noValue}
          </Typography2>
          {afterPersonalScore}
        </div>
      </ProgressTrackerPopover>
    );

    const benchmarkLabel = !hideTarget && (
      <ProgressTrackerPopover
        popoverContent={
          isSkillsInsightsTooltip() && (
            <BenchmarkScorePopoverContent skillName={skillName} target={target} score={score} />
          )
        }
      >
        <div css={[styles.scoreLabel, styles.targetScoreLabel]}>
          <Typography2 variant="bodySecondary" component="span">
            {benchmarkLabelText}
          </Typography2>
          <Typography2 variant="subtitleMedium" component="span">
            {target || noValue}
          </Typography2>
        </div>
      </ProgressTrackerPopover>
    );
    scoreContent = (
      <>
        {personalLabel}
        {personalLabel && benchmarkLabel && <Divider css={styles.divider} color="dark" orientation="vertical" />}
        {benchmarkLabel}
      </>
    );
  }

  return (
    <div css={styles.progressTrackerHeader}>
      {skillName && (
        <Typography css={styles.skillName} variant={headerSize === 'md' ? 'h2semibold' : 'h3semibold'} component="span">
          {skillName}
        </Typography>
      )}
      {/* Needed to stop events other than the tooltip triggers on mobile. This is not a button. */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div css={styles.score} onClick={(e) => e.stopPropagation()}>
        {scoreContent}
      </div>
      {endOfHeader}
    </div>
  );
};

const ProgressTrackerHeaderPlaceholder = ({ headerSize, wrapHeader = false }: PropsForPlaceholder): JSX.Element => {
  const styles = useStyles(wrapHeader);
  return (
    <div css={styles.progressTrackerHeader}>
      <Typography css={styles.skillName} variant={headerSize === 'md' ? 'h2semibold' : 'h3semibold'} component="span">
        <PlaceholderShimmer width="100%" />
      </Typography>
    </div>
  );
};

export { ProgressTrackerHeader, ProgressTrackerHeaderPlaceholder };
