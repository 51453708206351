import * as React from 'react';
import { Component } from 'react';

import _ from 'lodash';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import user from 'js/lib/user';

import { StyleSheet, color, css, spacing } from '@coursera/coursera-ui';

import SvgCertificateConfetti from 'bundles/coursera-ui/assets/SvgCertificateConfetti';
import SvgClear from 'bundles/coursera-ui/components/svg/coursera/common/SvgClear';
import Naptime from 'bundles/naptimejs';
import EnterpriseEventsV1 from 'bundles/naptimejs/resources/enterpriseEvents.v1';
import ProgramUserCreditsV1 from 'bundles/naptimejs/resources/programUserCredits.v1';

import _t from 'i18n!nls/program-home';

const styles = StyleSheet.create({
  container: {
    padding: `${spacing.lg} ${spacing.xxl}`,
    marginBottom: spacing.lg,
    textAlign: 'center',
    backgroundColor: color.white,
    position: 'relative',
  },
  title: {
    fontSize: '2.125rem',
    lineHeight: '2.625rem',
    fontFamily: "'OpenSans-light', Arial, sans-serif",
    marginBottom: spacing.lg,
    color: color.primary,
  },
  cardMessage: {
    margin: `${spacing.lg} ${spacing.xxl}`,
    color: color.secondaryText,
  },
  dismissButton: {
    position: 'absolute',
    top: spacing.md,
    right: spacing.md,
  },
});

type UserCredits = {
  isLimited: boolean;
  hasFullCreditAvailable: boolean;
  coursesRemaining: number;
  totalAllocatedCourses: number;
  hasRemainingCourses: boolean;
};

type PropsFromCaller = {
  programId: string;
  programName: string;
  lastCompletedTime?: number;
};

type PropsFromNaptime = PropsFromCaller & {
  userCredits?: UserCredits;
  events?: EnterpriseEventsV1;
  naptime: Naptime.InjectedNaptime;
};

class CourseCompletionBanner extends Component<PropsFromNaptime> {
  state = {
    isDismissed: false,
  };

  handleDismiss = () => {
    const { programId, naptime } = this.props;
    this.setState({ isDismissed: true });
    const now = Math.round(new Date().getTime() / 1000);
    const eventId = `${user.get().id}~programEventKey!~${programId}!!!~COMPLETION_BANNER_DISMISSED`;
    naptime.executeMutation(EnterpriseEventsV1.action('addTimestampForEvents', {}, { ids: [eventId], timestamp: now }));
  };

  render() {
    const { programName, userCredits, events, lastCompletedTime } = this.props;

    const lastDismissedTime = _.max<number>(events?.timestamps) ?? 0;

    if (!lastCompletedTime || lastDismissedTime > lastCompletedTime || this.state.isDismissed) {
      return null;
    }

    const showKeepLearning = userCredits && userCredits.hasRemainingCourses;

    return (
      <div data-e2e="course-completed-banner" {...css(styles.container)}>
        <button
          {...css('nostyle', styles.dismissButton)}
          onClick={this.handleDismiss}
          type="button"
          aria-label={_t('Dismiss course completion banner')}
        >
          <SvgClear color={color.divider} htmlAttributes={{ role: 'presentation', 'aria-hidden': true }} />
        </button>

        <SvgCertificateConfetti />
        <p {...css(styles.title)}>
          <FormattedMessage message={_t('Congrats, {fullName}!')} fullName={user.get().full_name} />
        </p>
        <p {...css(styles.cardMessage)}>
          <FormattedMessage
            message={_t(
              `Congratulations on completing a course in {programName}! You've taken a big step
                towards transforming your career! Your program admin will automatically be notified of your
                progress. If you want to share your success with the world, add your Course Certificate to
                your LinkedIn profile.`
            )}
            programName={programName}
          />
        </p>
        {showKeepLearning && (
          <p {...css(styles.cardMessage)}>
            {_t('Now, keep learning to accelerate your career by enrolling in your next course.')}
          </p>
        )}
      </div>
    );
  }
}

export const ComponentForTest = CourseCompletionBanner;

const createContainerFn = Naptime.createContainer<PropsFromNaptime, PropsFromCaller>(({ programId }) => ({
  userCredits: user.get().id && programId ? ProgramUserCreditsV1.get(`${user.get().id}~${programId}`) : undefined,
  events:
    user.get().id && programId
      ? EnterpriseEventsV1.get(`${user.get().id}~programEventKey!~${programId}!!!~COMPLETION_BANNER_DISMISSED`)
      : undefined,
}));

export default createContainerFn(CourseCompletionBanner);
