/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import _ from 'lodash';

import Imgix from 'js/components/Imgix';

import { Grid, Hidden, Typography, Typography2 } from '@coursera/cds-core';
import { TextTruncate, css as cuicss, placeholder } from '@coursera/coursera-ui';

import { getProductCardDisplayProps } from 'bundles/browse/utils';
import type { CollectionProduct } from 'bundles/enterprise-collections/components/EnterpriseProductCardCollection';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import filterExistsOrDefault from 'bundles/program-common/utils/filterExistsOrDefault';

type ProductInfoCardProp = {
  products: CollectionProduct[];
  skillsetSlug: string;
  programSlug: string;
  skillSetOrder: number;
};

const styles = {
  courseContainer: css`
    display: flex;
    margin-bottom: var(--cds-spacing-150);
  `,
  courseImage: css`
    border-radius: 2px;
    flex-shrink: 0;
    width: 61px;
    height: 61px;
    margin-top: var(--cds-spacing-50);
  `,
  courseDescriptionSection: css`
    margin-left: var(--cds-spacing-100);
    display: flex;
    flex-direction: column;
    width: calc(100% - 69px);
  `,
  singleLineCourseName: css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  courseType: css`
    text-transform: uppercase;
  `,
};

export const NUMBER_OF_PRODUCTS_TO_RENDER = 3;
export function ProductInfoList({ products, programSlug, skillsetSlug, skillSetOrder }: ProductInfoCardProp) {
  return (
    <Grid container role="list">
      {products.slice(0, NUMBER_OF_PRODUCTS_TO_RENDER).map((collectionProduct, index) => {
        const { product } = collectionProduct;
        const { promoPhoto, name, id } = product;
        const partners = filterExistsOrDefault(product.partners?.elements);
        const { labelAsText } = getProductCardDisplayProps(product, !collectionProduct.isCourse);

        return (
          <Grid aria-label={labelAsText} key={id} item component={Hidden} xs={12} xsDown={index > 1}>
            {/**
             * Since these are nested in an interactive card they should be interactive while using
             * a screen reader but if the user clicks on this we still track the click with TrackedDiv
             * even though the interactive container handles the clicking logic.
             */}
            <TrackedDiv
              aria-label={labelAsText}
              role="listitem"
              trackingName="skillset_course"
              data={{
                skillSetOrder,
                courseOrder: index,
                objectId: `skillset~${programSlug}/${skillsetSlug}`,
                skillsetSlug,
                programSlug,
                tab: 'skillset-tab',
              }}
              css={styles.courseContainer}
            >
              {promoPhoto ? (
                <Imgix
                  css={styles.courseImage}
                  src={promoPhoto}
                  alt={`${partners[0].name} promo image`}
                  maxWidth={61}
                  maxHeight={61}
                />
              ) : (
                <div css={styles.courseImage} role="presentation" />
              )}
              <div css={styles.courseDescriptionSection}>
                <Hidden mdUp>
                  <Typography2 component="p" variant="bodyPrimary">
                    <TextTruncate line={2} text={name} />
                  </Typography2>
                </Hidden>
                <Hidden smDown>
                  <Typography2 component="p" variant="bodyPrimary" css={styles.singleLineCourseName}>
                    {name}
                  </Typography2>
                </Hidden>

                <Typography2
                  component="p"
                  variant="bodySecondary"
                  color="supportText"
                  css={styles.singleLineCourseName}
                >
                  {partners[0]?.name}
                </Typography2>
                <Typography2
                  component="p"
                  css={[styles.courseType, styles.singleLineCourseName]}
                  variant="bodySecondary"
                  color="supportText"
                >
                  {labelAsText}
                </Typography2>
              </div>
            </TrackedDiv>
          </Grid>
        );
      })}
    </Grid>
  );
}

export function ProductInfoListPlaceholder() {
  return (
    <Grid container aria-hidden>
      {_.times(NUMBER_OF_PRODUCTS_TO_RENDER, (index) => (
        <Grid key={index} item component={Hidden} xs={12} xsDown={index > 1} aria-hidden>
          <div css={styles.courseContainer} data-testId="placeholder-course">
            <span {...cuicss(placeholder.styles.shimmer)} css={styles.courseImage} />
            <div css={styles.courseDescriptionSection}>
              <Typography {...cuicss(placeholder.styles.shimmer)} variant="body1" aria-hidden>
                &nbsp;
              </Typography>
              <Typography variant="body2" {...cuicss(placeholder.styles.shimmer)} color="supportText" aria-hidden>
                &nbsp;
              </Typography>
              <Typography
                css={styles.courseType}
                variant="body2"
                {...cuicss(placeholder.styles.shimmer)}
                color="supportText"
                aria-hidden
              >
                &nbsp;
              </Typography>
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  );
}
