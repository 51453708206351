import * as React from 'react';

import AutoGeneratedImageAssetPageTag from 'bundles/asset-admin/components/AutoGeneratedImageAssetPageTag';
import type { Asset } from 'bundles/cml/shared/types/assetTypes';

type Props = {
  asset: Asset;
};

const AutoGeneratedImageDescription: React.FC<Props> = ({ asset }) => {
  return <AutoGeneratedImageAssetPageTag confidenceLevel={asset.autoGenDescriptionConfidenceLevel} />;
};

export default AutoGeneratedImageDescription;
