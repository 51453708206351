/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react';

import * as React from 'react';

import { Button, Dialog, InlineNotification, Typography2 } from '@coursera/cds-core';
import type { ButtonProps } from '@coursera/cds-core';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import type { StepTwoIndiaTiersModalCopy } from 'bundles/coursera-plus/components/subscriptionTiers/upgradeToPlus/upgradeCopies';
import type Course from 'bundles/naptimejs/resources/courses.v1';
import type OnDemandSpecialization from 'bundles/naptimejs/resources/onDemandSpecializations.v1';
import TrackedDiv from 'bundles/page/components/TrackedDiv';

import _t from 'i18n!nls/coursera-plus';

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps>(Button);

type Props = {
  handleUpgradeToPlus: () => void;
  ctaState: 'loading' | 'failed' | undefined;
  copy: StepTwoIndiaTiersModalCopy;
  s12n?: OnDemandSpecialization;
  course?: Course;
};

const StepTwoIndiaUpgradeModal: React.FC<Props> = ({ course, s12n, copy, ctaState, handleUpgradeToPlus }) => {
  const { HeadingGroup, Content, Actions } = Dialog;
  const trackingData = { s12nId: s12n?.id, courseId: course?.id };

  return (
    <TrackedDiv
      trackingName="india_tiers_upgrade_step_2"
      data-testId="india-tiers-upgrade-step-2"
      trackClicks={false}
      withVisibilityTracking={true}
      data={{ s12nId: s12n?.id, courseId: course?.id }}
    >
      <HeadingGroup>{copy.stepHeader}</HeadingGroup>
      <Content>
        <Typography2 component="p">{copy.disclaimer}</Typography2>
        {ctaState === 'failed' && (
          <InlineNotification severity="error">
            {_t('Something went wrong, please try again later.')}
          </InlineNotification>
        )}
      </Content>
      <Actions>
        <TrackedButton
          variant="primary"
          size="medium"
          loading={ctaState === 'loading'}
          disabled={ctaState === 'loading' || ctaState === 'failed'}
          data-testid="india-tiers-upgrade-step-two-upgrade-button"
          trackingName="india_tiers_upgrade_step_two_upgrade_button"
          trackingData={trackingData}
          onClick={handleUpgradeToPlus}
        >
          {copy.upgradeToCourseraPlus}
        </TrackedButton>
      </Actions>
    </TrackedDiv>
  );
};

export default StepTwoIndiaUpgradeModal;
