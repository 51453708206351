import { createContext, useContext } from 'react';

import type { ApolloQueryResult } from 'apollo-client';

import type AuthoringCourse from 'bundles/author-common/models/AuthoringCourse';
import type { CourseCatalogType } from 'bundles/author-course/utils/types';
import type { AuthoringCourseContextsWithCreationContexts } from 'bundles/authoring/common/types/authoringCourseContexts';
import type { AuthoringCourseContextsV1Query as ContextsQuery } from 'bundles/authoring/common/utils/__generated__/AuthoringCourseContextsV1Query';

export type TeachContextType = {
  course?: AuthoringCourse;
  courseRole: string;
  isGuidedProject: boolean;
  isProject: boolean;
  courseCatalogType: CourseCatalogType | null;
  courseContexts: AuthoringCourseContextsWithCreationContexts[];
  refetchContexts: () => Promise<ApolloQueryResult<ContextsQuery> | null>;
};

export const TeachContextDefaultValues: TeachContextType = {
  course: undefined,
  courseRole: '',
  isGuidedProject: false,
  isProject: false,
  courseCatalogType: null,
  courseContexts: [],
  refetchContexts: () => Promise.resolve(null),
};

const TeachContext = createContext<TeachContextType>(TeachContextDefaultValues);

export const useTeachContext = () => useContext(TeachContext);

export const TeachContextProvider = TeachContext.Provider;
export const TeachContextConsumer = TeachContext.Consumer;
