/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { LearnerOnboarding_Goal as GoalNames } from '__generated__/graphql-types';

import { FormattedHTMLMessage } from 'js/lib/coursera.react-intl';
import useRouter from 'js/lib/useRouter';

import { Button, Grid, Typography2, breakpoints, typography2 } from '@coursera/cds-core';
import { track } from '@coursera/event-pulse/sdk';

import { formatRoleIdToString, handleRedirect } from 'bundles/enterprise-learner-onboarding/utils';
import TrackedButton from 'bundles/page/components/TrackedButton';
import mapGoalToText from 'bundles/program-personalized-tab/utils/mapGoalToText';

import _t from 'i18n!nls/program-personalized-tab';

export type Props = {
  selectedRoleId?: string;
  hasCompletedOnboarding: boolean;
  selectedGoals?: Array<GoalNames>;
  isUnsureOfRole: boolean;
  programSlug: string;
  isBrowseCatalogTab?: boolean;
  programNameContainer?: React.ReactNode;
};

const styles = {
  resultsContainer: css`
    display: flex;
    justify-content: flex-end;
    gap: 25px;
    ${breakpoints.down('sm')} {
      justify-content: flex-start;
      border-bottom: 1px solid var(--cds-color-neutral-stroke-primary-weak);
    }
  `,
  onboardingResults: css`
    && {
      overflow: hidden;

      ${breakpoints.down('sm')} {
        padding: var(--cds-spacing-150) 0;
      }

      > span:first-of-type {
        padding-right: 16px;
      }

      strong {
        color: var(--cds-color-neutral-primary);
        font-family: inherit;
      }
    }
  `,
  cta: css`
    && {
      max-width: unset;
      height: unset;
      width: unset;
      white-space: nowrap;
      ${typography2.actionPrimary}
      vertical-align: unset;
      color: var(--cds-color-interactive-primary);
      position: relative;
      padding: 0 0 0 10px;
      margin-left: -10px;

      :hover {
        background: none;
        box-shadow: none;
        border: none;
        text-decoration: underline;
      }
    }
  `,
  wrapperContainer: (isBrowseCatalogTab?: boolean) => css`
    margin-top: ${isBrowseCatalogTab ? '-1rem' : '0'};

    ${breakpoints.down('sm')} {
      gap: var(--cds-spacing-150);
      width: 100%;
      flex-direction: column-reverse;
    }
  `,
};

// TODO: Add loading skeleton state
export const OnboardingResultsHeader = ({
  selectedGoals,
  selectedRoleId,
  hasCompletedOnboarding,
  isUnsureOfRole,
  programSlug,
  isBrowseCatalogTab,
  programNameContainer,
}: Props) => {
  const router = useRouter();
  let message;

  const onEditClick = () => {
    track('click_button', { button: { name: 'onboarding_edit' } });
    handleRedirect(`/programs/${programSlug}/onboarding`, router);
  };

  if (!hasCompletedOnboarding) {
    message = <FormattedHTMLMessage message={_t('Your career goal is <strong>pending</strong>')} />;
  } else {
    const mappedGoals = selectedGoals?.map((selectedGoal) => mapGoalToText(selectedGoal));
    if (isUnsureOfRole) {
      if (mappedGoals && mappedGoals.length > 1) {
        message = (
          <FormattedHTMLMessage
            message={_t(
              'Your career goal is to <strong>{mappedGoalsList}</strong> and <strong>{lastMappedGoal}</strong>'
            )}
            mappedGoalsList={mappedGoals.slice(0, -1).join(', ')}
            lastMappedGoal={mappedGoals[mappedGoals.length - 1]}
          />
        );
      } else if (mappedGoals) {
        message = (
          <FormattedHTMLMessage
            message={_t('Your career goal is to <strong>{mappedGoal}</strong>')}
            mappedGoal={mappedGoals[0]}
          />
        );
      }
    } else if (selectedRoleId) {
      const formattedRoleId = formatRoleIdToString(selectedRoleId);

      if (mappedGoals && mappedGoals.length > 1) {
        message = (
          <FormattedHTMLMessage
            message={_t(
              'Your career goal is to <strong>{mappedGoalsList}</strong> and <strong>{lastMappedGoal}</strong> as a(n) <strong>{selectedRole}</strong>'
            )}
            mappedGoalsList={mappedGoals.slice(0, -1).join(', ')}
            lastMappedGoal={mappedGoals[mappedGoals.length - 1]}
            selectedRole={formattedRoleId}
          />
        );
      } else if (mappedGoals) {
        message = (
          <FormattedHTMLMessage
            message={_t('Your career goal is to <strong>{mappedGoal}</strong> as a(n) <strong>{selectedRole}</strong>')}
            mappedGoal={mappedGoals[0]}
            selectedRole={formattedRoleId}
          />
        );
      }
    }
  }

  return (
    <Grid container data-testid="onboarding-results-header" css={styles.wrapperContainer(isBrowseCatalogTab)}>
      {programNameContainer && (
        <Grid item sm={12} md={4}>
          {programNameContainer}
        </Grid>
      )}
      <Grid item sm={12} md={programNameContainer ? 8 : 12} css={styles.resultsContainer}>
        <Typography2 component="p" variant="bodyPrimary" color="supportText" css={styles.onboardingResults}>
          {message}
          <Button
            variant="ghost"
            edgeAlign="start"
            size="small"
            onClick={onEditClick}
            css={styles.cta}
            component={TrackedButton}
            requireFullyVisible={false}
            withVisibilityTracking={false}
            trackingName="onboarding_results_header_edit"
            aria-label={_t('Set your learning goal and role.')}
          >
            {hasCompletedOnboarding ? _t('Edit') : _t('Set your goals today')}
          </Button>
        </Typography2>
      </Grid>
    </Grid>
  );
};
