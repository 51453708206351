import type constants from 'bundles/payments/common/constants';

import NaptimeResource from './NaptimeResource';

class PaymentWallets extends NaptimeResource {
  static RESOURCE_NAME = 'paymentWallets.v1';

  // These properties are always included.
  declare id: number;

  declare userId: number;

  // These properties must be requested.
  declare billingCountry?: string;

  declare billingZipcode?: string;

  declare creditCardExpiration?: number;

  declare creditCardLastFourDigits?: string;

  declare creditCardType?: string;

  declare email?: string;

  declare isValid?: boolean;

  declare paymentProcessorId?: keyof typeof constants.paymentMethods;

  static me(fields: Array<string> = []) {
    return this.finder('me', {
      params: { getLatest: true },
      fields,
    });
  }
}

export default PaymentWallets;
