import type { CarouselType } from '@coursera/event-pulse-types';

const cardTypes = {
  LEARNER_CARD: 'LEARNER_CARD',
  PROGRAM_CREATION_CARD: 'PROGRAM_CREATION_CARD',
};

export default cardTypes;

export const { LEARNER_CARD, PROGRAM_CREATION_CARD } = cardTypes;

export const ProductType = {
  CLIP: 'clip',
  COURSE: 'course',
  S12N: 's12n',
  DEGREE: 'degree',
  PREMIUM_CERTIFICATE: 'premium_certificate',
} as const;

export const CAROUSEL_TYPES: Record<string, CarouselType> = {
  SKILL_BASED_PERSONALIZED: 'skill_based_personalized',
  SEMI_PERSONALIZED: 'semi_personalized',
  RECONFIGURABLE_COMPONENTS: 'reconfigurable_components',
  FULLY_PERSONALIZED: 'fully_personalized',
  CURATED: 'curated',
  ALGOLIA_BASED: 'algolia_based',
};
