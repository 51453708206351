import * as React from 'react';

import type { SvgButtonProps } from '@coursera/coursera-ui';
import { SvgButton, color } from '@coursera/coursera-ui';

import SvgCertificate from 'bundles/coursera-ui/components/svg/coursera/common/SvgCertificate';
import Naptime from 'bundles/naptimejs';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import VcMembershipsV1 from 'bundles/naptimejs/resources/vcMemberships.v1';
import TrackedDiv from 'bundles/page/components/TrackedDiv';

import _t from 'i18n!nls/course-cards';

type PropsFromCaller = {
  membershipId: string;
  label?: string;
};

type PropsFromNaptime = {
  vcMembership: VcMembershipsV1;
};

type Props = PropsFromCaller & PropsFromNaptime & SvgButtonProps;

const ViewCertificateLink = ({ label = _t('View Certificate'), vcMembership, ...rest }: Props): JSX.Element | null => {
  if (!vcMembership || !vcMembership.certificateLink) return null;

  return (
    <TrackedDiv trackingName="view_certificate" trackClicks={true}>
      <SvgButton
        label={label}
        type="link"
        tag="a"
        size="sm"
        style={{ paddingLeft: 0, paddingRight: 0, border: 'none' }}
        svgElement={<SvgCertificate color={color.primary} />}
        htmlAttributes={{
          href: vcMembership.certificateLink,
          target: '_blank',
          rel: 'noopener noreferrer',
          title: _t('View Your Certificate'),
        }}
        data-js="ViewCertificateLink"
        {...rest}
      />
    </TrackedDiv>
  );
};

export default Naptime.createContainer<Props, PropsFromCaller>(ViewCertificateLink, ({ membershipId }) => ({
  vcMembership: VcMembershipsV1.get(membershipId, {
    fields: ['certificateCode'],
  }),
}));

export const BaseComponent = ViewCertificateLink;
