import { ContextGroup } from 'bundles/ai-coach-studio/types';
import type { ContextFetcher } from 'bundles/ai-coach-studio/types';

// Eventually this list could be fetched from the BE in a later version of Coach Studio so we don't need to keep updating the FE when new context fetchers are created
export const AVAILABLE_CONTEXT_FETCHERS: ContextFetcher[] = [
  { contextFetcherKey: 'course.metadata', contextGroup: ContextGroup.COURSE },
  {
    contextFetcherKey: 'course.difficulty',
    contextGroup: ContextGroup.COURSE,
  },
  { contextFetcherKey: 'course.name', contextGroup: ContextGroup.COURSE },
  {
    contextFetcherKey: 'course.faq.embedding',
    contextGroup: ContextGroup.COURSE,
  },
  {
    contextFetcherKey: 'course.item.content',
    contextGroup: ContextGroup.COURSE,
  },
  { contextFetcherKey: 's12n.metadata', contextGroup: ContextGroup.S12N },
  {
    contextFetcherKey: 's12n.faq.embedding',
    contextGroup: ContextGroup.S12N,
  },
  {
    contextFetcherKey: 'additional.products.metadata',
    contextGroup: ContextGroup.ADDITIONAL_PRODUCTS,
  },
  { contextFetcherKey: 'user.profile', contextGroup: ContextGroup.USER },
  {
    contextFetcherKey: 'user.enrolled.products',
    contextGroup: ContextGroup.USER,
  },
  { contextFetcherKey: 'user.locale', contextGroup: ContextGroup.USER },
  { contextFetcherKey: 'user.message', contextGroup: ContextGroup.USER },
  {
    contextFetcherKey: 'previous.response',
    contextGroup: ContextGroup.PREVIOUS_RESPONSE,
  },
];

export const COACH_STUDIO_MODELS = [
  'gpt-4o',
  'gpt-4o-mini',
  'gpt-4',
  'gpt-35-turbo-16k',
  'gemini-1.5-pro-001',
  'gemini-1.5-flash-002',
];

export const COACH_STUDIO_SESSION_STORAGE_KEY = 'COACH_STUDIO_SETTINGS';

export const COACH_STUDIO_DEFAULT_PROMPT =
  "You are a Coursera Learning Coach who is friendly, encouraging, and informative. You answer in a way that should make learners feel encouraged and empowered. Your goal is to help users be successful learners on Coursera. Answer a learner's question to the best of your ability.";
