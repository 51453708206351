/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum org_coursera_course_CertificateType {
  ACE = "ACE",
  SoA = "SoA",
  Specialization = "Specialization",
  VerifiedCert = "VerifiedCert",
}

export enum org_coursera_course_CourseStatus {
  draft = "draft",
  inactive = "inactive",
  launched = "launched",
  preenroll = "preenroll",
}

export enum org_coursera_course_PremiumExperienceVariant {
  BaseVariant = "BaseVariant",
  PremiumCourse = "PremiumCourse",
  PremiumGrading = "PremiumGrading",
}

export enum org_coursera_payment_financialaid_FinancialAidApplicationState {
  APPROVED = "APPROVED",
  APPROVED_PAID = "APPROVED_PAID",
  APPROVED_PAYMENT_SUCCESSFUL = "APPROVED_PAYMENT_SUCCESSFUL",
  PAYMENT_EXPIRED = "PAYMENT_EXPIRED",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  WITHDRAWN = "WITHDRAWN",
}

export enum org_coursera_payment_transaction_PaymentTransactionActionType {
  CHARGEBACK = "CHARGEBACK",
  PARTIAL_REFUND = "PARTIAL_REFUND",
  PAYMENT = "PAYMENT",
  QUERY = "QUERY",
  REFUND = "REFUND",
  VOID = "VOID",
}

export enum org_coursera_product_BillingCycle {
  ANNUAL = "ANNUAL",
  BIANNUAL = "BIANNUAL",
  MONTHLY = "MONTHLY",
}

export enum org_coursera_program_membership_ProgramMembershipState {
  DELETED_MEMBER = "DELETED_MEMBER",
  INVITED = "INVITED",
  INVITED_EMAIL_NOT_VERIFIED = "INVITED_EMAIL_NOT_VERIFIED",
  MEMBER = "MEMBER",
  NOT_MEMBER = "NOT_MEMBER",
  SOFT_DELETED_MEMBER = "SOFT_DELETED_MEMBER",
  WHITELISTED = "WHITELISTED",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
