import { ApolloClient } from '@apollo/client';

export default ApolloClient;
export type { ApolloQueryResult } from '@apollo/client';
export type { FetchPolicy } from '@apollo/client';
export type { MutationUpdaterFn } from '@apollo/client';
export type { OperationVariables } from '@apollo/client';
export type { QueryOptions } from '@apollo/client';
export type { WatchQueryFetchPolicy } from '@apollo/client';
export { ApolloClient } from '@apollo/client';
export { ApolloError } from '@apollo/client';
export { NetworkStatus } from '@apollo/client';
