import * as Sentry from '@sentry/react';
import type { Player } from 'video.js';
import type MediaError from 'video.js/dist/types/media-error';

export function handleVideoJsError(
  player: Player | null,
  sentryErrorSource: string,
  metadata?: { [key: string]: object }
) {
  const error = player?.error();
  if (error) {
    let videoJsFormattedError: Error | MediaError = error;

    if (!(error instanceof Error)) {
      // if this catches, it means that the error is not a stringifiable object
      videoJsFormattedError = new Error(`video.js: ${JSON.stringify(error)}`);
    }

    Sentry.captureException(videoJsFormattedError, {
      tags: { source: sentryErrorSource, errorType: 'media', metadata: JSON.stringify(metadata) },
    });
  }
}
