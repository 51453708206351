import React from 'react';

import gql from 'graphql-tag';

import type { SchemaMarkup as SchemaMarkupType } from 'bundles/enterprise-legacy-xdp/components/miniPDP/__generated__/SchemaMarkup';
import withFragments from 'bundles/graphql/components/withFragments';
import {
  KEYWORD_IDS,
  getBreadcrumbSchema,
  getInstructorMarkup,
  getSchemaMarkupElement,
  getSchemaOrgBooleanString,
  getTimeRequiredString,
  getTrimmedDescription,
} from 'bundles/seo/utils/schemaMarkupUtils';
import { clearEmptyFields } from 'bundles/seo/utils/utils';

type Props = Omit<SchemaMarkupType, '__typename'> & {
  slug: string;
};

export const SchemaMarkup: React.FunctionComponent<Props> = ({
  slug,
  name,
  description,
  primaryLanguages,
  avgLearningHoursAdjusted,
  partners,
  domains,
  ratings,
  instructors,
}) => {
  const keywordId = KEYWORD_IDS.PROJECT;
  const breadcrumbType = getBreadcrumbSchema(domains[0], keywordId);

  // Projects do not have an institutional provider - use instructor instead
  const provider = getInstructorMarkup(instructors);

  const projectType = {
    '@id': `https://www.coursera.org/projects/${slug}#${keywordId}`,
    '@type': 'Course',
    name,
    url: `https://www.coursera.org/projects/${slug}`,
    description: getTrimmedDescription(description, 160),
    image: partners?.[0]?.productBrandingLogo,
    educationalCredentialAwarded: 'Coursera Certificate',
    isAccessibleForFree: getSchemaOrgBooleanString(false),
    inLanguage: primaryLanguages,
    ...(avgLearningHoursAdjusted
      ? {
          timeRequired: getTimeRequiredString({ hours: avgLearningHoursAdjusted }),
        }
      : {}),
    ...(ratings
      ? {
          aggregateRating: clearEmptyFields({
            '@type': 'AggregateRating',
            ratingValue: ratings.averageFiveStarRating,
            ratingCount: ratings.ratingCount,
            reviewCount: ratings.commentCount,
          }),
        }
      : {}),
    provider,
  };

  const graph = [breadcrumbType, projectType];

  return <div>{getSchemaMarkupElement(graph)}</div>;
};

export default withFragments({
  SchemaMarkup: gql`
    fragment SchemaMarkup on XdpV1_org_coursera_xdp_cdp_CDPMetadata {
      name
      description
      primaryLanguages
      avgLearningHoursAdjusted
      partners {
        id
        productBrandingLogo
      }
      domains {
        domainId
        domainName
        subdomainName
        subdomainId
      }
      ratings {
        commentCount
        ratingCount
        averageFiveStarRating
      }
      instructors {
        id
        fullName
        shortName
        title
        photo
        websites {
          website
        }
      }
    }
  `,
})(SchemaMarkup);
