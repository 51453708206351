import { Editor, Range, Text } from 'slate';
import type { NodeEntry } from 'slate';

import type { MARK_VALUES } from 'bundles/cml/shared/constants';

export const isMarkActive = (editor: Editor, mark: MARK_VALUES) => {
  if (editor.selection && Range.isCollapsed(editor.selection)) {
    const marks = Editor.marks(editor);
    return marks ? marks[mark] === true : false;
  }

  const textNodes = Array.from(Editor.nodes(editor, { match: (node) => Text.isText(node) })) as NodeEntry<Text>[];
  return textNodes.some(([node]: NodeEntry<Text>) => {
    return node[mark] === true;
  });
};

export const toggleMark = (editor: Editor, mark: MARK_VALUES) => {
  const isActive = isMarkActive(editor, mark);

  if (isActive) {
    Editor.removeMark(editor, mark);
  } else {
    Editor.addMark(editor, mark, true);
  }
};
