import type React from 'react';
import { forwardRef } from 'react';

import type { OverrideProps, OverridableComponent } from '@coursera/cds-common';

import ItemBase from './ItemBase';
import type { BaseProps } from './ItemBase';

export interface ItemTypeMap<D extends React.ElementType = 'a'> {
  // there is not disabled state for links
  props: Omit<BaseProps, 'disabled'>;
  defaultComponent: D;
}

export type Props<
  D extends React.ElementType = ItemTypeMap['defaultComponent']
> = OverrideProps<ItemTypeMap<D>, D> & {
  component?: React.ElementType;
};

/**
 * Link component is used to render an anchor as a menu item. Can be used for navigation.
 */
const ItemLink: OverridableComponent<ItemTypeMap> = forwardRef<
  HTMLAnchorElement,
  Props
>(function Item(props, ref) {
  // role="presentation" removes weird "group" word announced by VoiceOver
  return (
    <li css={{ listStyle: 'none' }} role="presentation">
      <ItemBase ref={ref} component="a" role="menuitem" {...props} />
    </li>
  );
});

export default ItemLink;
