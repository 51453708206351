/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

const styles = css`
  display: inline;
  margin-left: 4px;
  position: relative;
  top: -2px;

  .line {
    display: inline-block;
    vertical-align: middle;
    margin-right: 2px;
    width: 4px;
    height: 6px;
    background-color: var(--cds-color-blue-500); /* todo: use new coach token */
    animation: wave 1s infinite;
    border-radius: var(--cds-spacing-50);
  }

  @keyframes wave {
    0% {
      height: 6px;
      opacity: 1;
    }

    50% {
      height: 16px;
      opacity: 0.2;
    }

    100% {
      height: 6px;
      opacity: 1;
    }
  }

  .line-2 {
    animation-delay: 0.25s;
  }

  .line-3 {
    animation-delay: 0.5s;
  }
`;

/**
 * An indeterminate loading indicator, to be used in conjunction with a text label, see `ProgressiveLoader`.
 *
 * [Figma spec](https://www.figma.com/file/htEgXzKJXw1nbe6Kl1lvL1/Loading-%5BCoach-104%5D?node-id=6201%3A48202)
 */
export const IndeterminateIndicator = (): React.ReactElement => {
  return (
    <div aria-hidden css={styles}>
      <span className="line" />
      <span className="line line-2" />
      <span className="line line-3" />
    </div>
  );
};

export default IndeterminateIndicator;
