import type React from 'react';

import { isHotkey } from 'is-hotkey';
import { Transforms } from 'slate';
import type { Editor } from 'slate';

import { getAncestorOfType } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';

const isEnterKey = isHotkey('enter');

const NEW_LINE_NODES = [
  BLOCK_TYPES.IMAGE,
  BLOCK_TYPES.IMAGE_UPLOADER,
  BLOCK_TYPES.ASSET,
  BLOCK_TYPES.MATH_BLOCK,
  BLOCK_TYPES.WIDGET,
];

export const newLineKeyDownHandler = (editor: Editor, event: React.KeyboardEvent): boolean => {
  const { nativeEvent } = event;
  if (!isEnterKey(nativeEvent)) {
    return false;
  }

  const entry = getAncestorOfType(editor, NEW_LINE_NODES);
  if (!entry) {
    return false;
  }

  const [, path] = entry;
  const newLinePath = [path[0]];

  Transforms.insertNodes(editor, { type: BLOCK_TYPES.TEXT, children: [{ text: '' }] }, { at: newLinePath });
  event.preventDefault();
  return true;
};
