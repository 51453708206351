import * as React from 'react';

import type { XdpV1_Org_Coursera_Xdp_Common_XdpCourseModule as WeeksModules } from '__generated__/graphql-types';
import classNames from 'classnames';

import { Typography, Typography2 } from '@coursera/cds-core';
import { Col, Row, StyleSheet, breakPoint, css } from '@coursera/coursera-ui';

import SyllabusModule from 'bundles/enterprise-legacy-xdp/components/cdp/syllabus/SyllabusModule';

import _t from 'i18n!nls/program-common';

import 'css!bundles/enterprise-legacy-xdp/components/cdp/syllabus/__styles__/SyllabusWeek';

const styles = StyleSheet.create({
  leftColumn: {
    display: 'inline-block',
    textAlign: 'center',
    [`@media (max-width: ${breakPoint.md}px)`]: {
      display: 'block',
    },
  },
});

type Props = {
  sectionNumber: number;
  modules: Array<WeeksModules>;
  slug: string;
  isLast?: boolean;
  courseId: string;
  role?: string;
};

export default function SyllabusWeek({ modules, sectionNumber, slug, isLast, courseId, role }: Props) {
  return (
    <Row key={sectionNumber} rootClassName="SyllabusWeek" role={role}>
      <Col xs={12} md={2} lg={2} rootClassName="hidden-sm-down p-t-1">
        <div {...css(styles.leftColumn)}>
          <Typography2
            className="header-week text-secondary text-uppercase font-weight-bold p-t-1s"
            style={{ letterSpacing: '4px' }}
            variant="bodyPrimary"
            component="span"
          >
            {_t('Week')}
          </Typography2>
          <Typography className="header-week text-secondary d-block m-y-1" component="span" variant="d2">
            {sectionNumber}
          </Typography>
        </div>
      </Col>
      <Col xs={12} rootClassName="hidden-sm-up m-t-2">
        <div style={{ fontSize: '18px', color: '#888', textAlign: 'center' }} className="bold m-b-2 label-text">
          {_t('Week')} {` ${sectionNumber}`}
        </div>
      </Col>
      <Col xs={12} md={10} lg={10} rootClassName={classNames('p-b-1', { 'border-bottom': !isLast })}>
        {modules?.map((module, index) => (
          <SyllabusModule
            key={module.id}
            {...module}
            isLast={index === modules.length - 1}
            slug={slug}
            courseId={courseId}
            weekNumber={sectionNumber}
          />
        ))}
      </Col>
    </Row>
  );
}
