/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import type { SerializedStyles } from '@emotion/react';

import * as React from 'react';

const shimmeringAnimation = `
@keyframes placeholder_shimmer_animation {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
animation: placeholder_shimmer_animation 1.3s infinite linear;
position: relative;
background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
background-size: 1000px 104px;
overflow: hidden;
`;

/*
  this component is only temporary until CDS has a proper placeholder shimmering skeleton
  the implementation is copied from coursera-ui shimmering css 
*/
const PlaceholderShimmer: React.FC<{ width?: string; height?: string; style?: SerializedStyles }> = ({
  width,
  height,
  style,
}) => {
  return (
    <div
      data-testid="placeholder-shimmer"
      css={css`
        ${style || ''};
        width: ${width || 'auto'};
        height: ${height || 'auto'};
        ${shimmeringAnimation}
      `}
      aria-hidden
    >
      &nbsp;
    </div>
  );
};

export default PlaceholderShimmer;
