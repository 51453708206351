import type { BaseComponentProps } from '@coursera/cds-common';

import { classes } from '@core/cards/common/getCommonCardCss';

type Props = BaseComponentProps<'div'>;

/**
 * Wrapper that adds some business logic styles for
 * any component that renders in the card body. E.g. limit content to max 3 lines.
 */
const CardBodyContent = ({ children, ...rest }: Props) => {
  return (
    <div className={classes.bodyContent} {...rest}>
      {children}
    </div>
  );
};

export default CardBodyContent;
