import type { TrackingContextData } from 'js/lib/retracked';
import Retracked from 'js/lib/retracked';

import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { ImageUploaderElement } from 'bundles/cml/shared/types/elementTypes';

const DATA_URI_REGEX = new RegExp('^data:(image/.*?);base64,(.*)$');

export const deserializeImageDataURI = (dataURI: string): ImageUploaderElement | null => {
  const matches = dataURI.match(DATA_URI_REGEX);
  if (!matches) {
    return null;
  }

  const mimeType = matches[1];
  const data = matches[2];

  const byteString = atob(data);
  const { buffer } = Uint8Array.from(byteString, (ch: string) => ch.charCodeAt(0));

  const file = new File([buffer], '', { type: mimeType });

  return {
    type: BLOCK_TYPES.IMAGE_UPLOADER,
    isVoid: true,
    src: URL.createObjectURL(file),
    file,
    children: [{ text: '' }],
  };
};

export const deserializeImageDataTransferItem = (imageItem?: DataTransferItem): ImageUploaderElement | null => {
  const file = imageItem?.getAsFile();
  if (!file) {
    return null;
  }

  return {
    type: BLOCK_TYPES.IMAGE_UPLOADER,
    isVoid: true,
    src: URL.createObjectURL(file),
    file,
    children: [{ text: '' }],
  };
};

export const deserializeImages = (imageItems: DataTransferItem[], eventingData?: TrackingContextData) => {
  const images = imageItems.reduce((result: ImageUploaderElement[], imageItem: DataTransferItem) => {
    const imageUploader = deserializeImageDataTransferItem(imageItem);
    if (imageUploader) {
      result.push(imageUploader);
    }
    return result;
  }, []);

  if (images.length && eventingData) {
    Retracked.trackComponent(
      eventingData,
      { num_images: images.length } /* eslint-disable-line camelcase */,
      'cml_editor',
      'image_drag_drop'
    );
  }

  return images;
};
