import { requireFields } from 'bundles/naptimejs/util/requireFieldsDecorator';

import { EnterpriseEventsV1NaptimeResource } from './__generated__/EnterpriseEventsV1';

class EnterpriseEvents extends EnterpriseEventsV1NaptimeResource {
  static RESOURCE_NAME = 'enterpriseEvents.v1' as const;

  @requireFields('timestamps')
  get hasTimestamps() {
    return this.timestamps && this.timestamps.length > 0;
  }

  @requireFields('timestamps')
  get earliestTimestamp() {
    return this.hasTimestamps && Math.max(...this.timestamps);
  }
}

export default EnterpriseEvents;
