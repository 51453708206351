/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Grid, Link } from '@coursera/cds-core';

import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';

export type LinkObject = {
  label: string;
  url: string;
  trackingName: string;
};

type Props = {
  links: LinkObject[];
};

const styles = {
  wrapper: css`
    border-radius: var(--cds-spacing-50);
    border: 1px solid var(--cds-color-neutral-stroke-primary-weak);
    background: var(--cds-color-neutral-primary-invert);
    flex-direction: column;
    padding: var(--cds-spacing-100) var(--cds-spacing-200);
  `,
  link: css`
    padding: var(--cds-spacing-100) var(--cds-spacing-100);
    gap: var(--cds-spacing-150);
    color: var(--cds-color-neutral-primary-weak) !important;
    font-size: 14px;
  `,
};

const NavigatorSelector = ({ links }: Props) => {
  return (
    <Grid container css={styles.wrapper}>
      {links.map((link) => (
        <Link
          key={link.label}
          href={link.url}
          css={styles.link}
          component={TrackedLink2}
          trackingName={`mylearning_${link.trackingName}`}
        >
          {link.label}
        </Link>
      ))}
    </Grid>
  );
};
export default NavigatorSelector;
