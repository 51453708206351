/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react';

import * as React from 'react';

import SubscriptionTiersEnrollButton from 'bundles/coursera-plus/components/subscriptionTiers/SubscriptionTiersEnrollButton';
import type { PropsFromCaller as EnrollButtonProps } from 'bundles/coursera-plus/components/subscriptionTiers/SubscriptionTiersEnrollButton';
import { courseraPlusProductVariants } from 'bundles/coursera-plus/constants/CourseraPlusProductVariant';
import type { CourseraPlusProductVariant } from 'bundles/coursera-plus/constants/CourseraPlusProductVariant';
import EnrollmentChoiceTypes from 'bundles/enroll-course/common/EnrollmentChoiceTypes';
import {
  choiceTypeToHandleSubmitPromise,
  submitEnrollmentPromise,
} from 'bundles/enroll-course/lib/enrollmentChoiceUtils';
import usePageData from 'bundles/enroll/data/usePageData';
import { useEnrollModalEventing } from 'bundles/enroll/utils/eventingUtils';

export type PropsFromCaller = Omit<EnrollButtonProps, 'onCtaClick'> & {
  productItemId?: CourseraPlusProductVariant;
};

const SubscriptionTiersCourseraPlusEnrollButton: React.FC<PropsFromCaller> = ({
  actionBeforeEnroll,
  productItemId = courseraPlusProductVariants.MONTHLY_WITH_FREE_TRIAL,
  ...props
}) => {
  const { s12n, course } = usePageData();
  const { trackEnrollModalContinue } = useEnrollModalEventing();

  const onCtaClick = () => {
    if (typeof actionBeforeEnroll === 'function') {
      actionBeforeEnroll();
    }

    const courseId = course.id;
    const s12nId = s12n?.id;
    const enrollmentChoiceType = EnrollmentChoiceTypes.SUBSCRIBE_TO_COURSERA_PLUS;

    trackEnrollModalContinue(enrollmentChoiceType, { productItemId });

    const handleSubmitPromise = choiceTypeToHandleSubmitPromise[enrollmentChoiceType];
    const options = { courseId, s12nId, productItemId };

    return submitEnrollmentPromise({
      handleSubmitPromise,
      options,
    });
  };

  return <SubscriptionTiersEnrollButton onCtaClick={onCtaClick} {...props} />;
};

export default SubscriptionTiersCourseraPlusEnrollButton;
