import * as React from 'react';
import { useEffect } from 'react';

import redirect from 'js/lib/coursera.redirect';

import { indicateRedirectedFromExpired } from 'bundles/coursera-plus-landing-page/contentful/constants';

type Props = {
  redirectPathOverride?: string;
};

const RedirectToHome: React.FC<Props> = ({ redirectPathOverride }) => {
  // making sure the window object exists when we redirect
  useEffect(() => {
    redirect.setLocation(redirectPathOverride ?? `/courseraplus/special/insite?${indicateRedirectedFromExpired}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div />;
};

export default RedirectToHome;
