import { withApollo } from 'react-apollo';

import type ApolloClient from 'apollo-client';
import { compose, mapProps } from 'recompose';

import { refetchQuery } from 'js/lib/apollo';

import { SavedProductsQuery } from 'bundles/program-home/components/enterprise-home/EnterpriseHomeQueries';
import { FETCH_LIMIT } from 'bundles/program-home/components/enterprise-home/SavedCoursesContainer';
import type {
  SavedProductsQuery as SavedProductsQueryType,
  SavedProductsQueryVariables,
} from 'bundles/program-home/components/enterprise-home/__generated__/SavedProductsQuery';

type PropsFromCaller = {
  programId: string;
};

type PropsFromWithApollo = {
  client: ApolloClient<unknown>;
};

export type PropsFromWithCacheInvalidationForNLJSavedCourses = {
  invalidateSavedCourses: () => Promise<void>;
};

export default compose<PropsFromWithCacheInvalidationForNLJSavedCourses, PropsFromCaller>(
  withApollo,
  mapProps<PropsFromWithCacheInvalidationForNLJSavedCourses, PropsFromCaller & PropsFromWithApollo>(
    ({ programId, client, ...rest }) => {
      const invalidateSavedCourses = () =>
        refetchQuery<SavedProductsQueryType, SavedProductsQueryVariables>(client, {
          query: SavedProductsQuery,
          variables: {
            programId,
            start: '0',
            limit: FETCH_LIMIT,
          },
        });
      return { programId, ...rest, invalidateSavedCourses };
    }
  )
);
