import * as React from 'react';

import { compose, pure } from 'recompose';

import hoistNonReactStatics from 'js/lib/hoistNonReactStatics';

import withBreakPoint from 'bundles/coursera-ui/components/hocs/withBreakPoint';

/**
 * @param  {[type]} breakPoint [description]
 * @return {[type]}            [description]
 */
// TODO:
// If the config is empty, find closest responsive point, prioritize on lower band
// function getClosestBreakPointFromSelections(breakPoint, selections = []) {
//   if (!breakPoint || _(selections).isEmpty()) {
//     return undefined
//   }
// }
/**
 * A HOC to allow config for different device sizes
 * Sample usage:
 * <ResponsiveComponent responsiveConfig={{
 *  sm: {
 *    name: 'Small',
 *    size: 2,
 *  },
 *  md: {
 *    name: 'Medium',
 *    size: 4,
 *  }
 * }}>
 */

const withResponsiveConfig = (Component: React.ComponentType<any>) => {
  type Props = {
    breakPoint: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
    responsiveConfig: any;
  };

  const HOC = ({ breakPoint, responsiveConfig, ...rest }: Props) => {
    const attributesByBreakpoint = responsiveConfig && responsiveConfig[breakPoint];
    return <Component {...attributesByBreakpoint} {...rest} />;
  };

  const componentName = Component.displayName || Component.name;
  HOC.displayName = `HOC(${componentName})`;
  hoistNonReactStatics(HOC, Component);

  // @ts-expect-error TODO
  return compose(withBreakPoint, pure)(HOC);
};

export default withResponsiveConfig;
