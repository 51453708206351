import { useEffect, useState } from 'react';

import {
  ToolbarFeatures as EquatioToolbarFeatures,
  addEventListener as equatioAddEventListener,
  isInstalled as equatioIsInstalled,
  removeEventListener as equatioRemoveEventListener,
  setExtractFunction as equatioSetFormula,
  setLoadConfig as equatioSetLoadConfig,
  toggleToolbarVisible as equatioSetOpen,
  triggerExtract as equatioTriggerFormula,
  getToolbarWrapper as getEquatioToolbar,
} from '@coursera/equatio-chrome-api';

const noop = () => {
  // noop
};

export const toggleEquatio = async (open: boolean): Promise<boolean> => {
  if (!open) {
    return equatioSetOpen(false);
  }

  equatioSetLoadConfig({
    actionButtons: true,
    extractButtonHidden: true,
    disabledFeatures: [EquatioToolbarFeatures.GraphEditor],
  });

  await equatioSetOpen(open);
  const toolbar = getEquatioToolbar();
  toolbar?.setAttribute('tabindex', '-1');

  return true;
};

export const updateEquatioFormula = (latex: string) => {
  equatioSetFormula(() =>
    Promise.resolve({
      type: 'math',
      latex,
    })
  );
  equatioTriggerFormula();
};

export const useEquatioFocusedState = (open: boolean | undefined): boolean | undefined => {
  const [equatioFocused, setEquatioFocused] = useState<boolean | undefined>(undefined);
  useEffect(() => {
    if (open == null) {
      return noop;
    }

    const handleFocus = (focused: boolean) => {
      if (focused !== equatioFocused) {
        setEquatioFocused(focused);
      }
    };

    equatioAddEventListener('focus', handleFocus);
    return () => equatioRemoveEventListener('focus', handleFocus);
  }, [equatioFocused, open]);

  return equatioFocused;
};

export const useEquatioDoneEffect = (onDone: () => void) => {
  useEffect(() => {
    equatioAddEventListener('insert', onDone);
    return () => {
      equatioRemoveEventListener('insert', onDone);
    };
  });
};

export const useEquatioOpenState = () => {
  const [equatioOpen, setEquatioOpen] = useState<boolean | undefined>(undefined);
  const [hasEquatio, setHasEquatio] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const checkIfhasEquatio = async () => {
      const installed = await equatioIsInstalled();
      setHasEquatio(installed);
    };

    checkIfhasEquatio();
  }, []);

  useEffect(() => {
    if (hasEquatio == null) {
      return noop;
    }

    if (!hasEquatio) {
      setEquatioOpen(false);
      return noop;
    }

    let equatioToolbar: HTMLElement | null | undefined;
    const intersectionObserver = new IntersectionObserver(
      ([entry]: IntersectionObserverEntry[]) => {
        if (entry.target === equatioToolbar) {
          setEquatioOpen(entry.isIntersecting);
        }
      },
      { threshold: 1 }
    );

    toggleEquatio(true).then(() => {
      const shadowHost = document.getElementById('equatio-toolbar-shadow-host');
      equatioToolbar = shadowHost?.shadowRoot?.querySelector('.equatio-toolbar');

      if (!equatioToolbar) {
        setEquatioOpen(false);
        return;
      }

      if (equatioToolbar) {
        intersectionObserver.observe(equatioToolbar);
      }
    });

    return () => {
      intersectionObserver.disconnect();
      setEquatioOpen(false);
    };
  }, [hasEquatio]);

  return equatioOpen;
};

export const useEquatioChangeEffect = (onChange: (formula: string) => void) => {
  useEffect(() => {
    equatioAddEventListener('latex', onChange);
    return () => equatioRemoveEventListener('latex', onChange);
  });
};
