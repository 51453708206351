import * as React from 'react';

import { branch, compose } from 'recompose';

import Imgix from 'js/components/Imgix';
import { FormattedMessage } from 'js/lib/coursera.react-intl';
import user from 'js/lib/user';

import { Typography, Typography2 } from '@coursera/cds-core';
import { Container, StyleSheet, css } from '@coursera/coursera-ui';

import { getLandingPageLink, logo, numberOfProducts } from 'bundles/coursera-plus/utils/courseraPlusUtils';
import type { Partners } from 'bundles/coursera-plus/utils/subscriptionTiersUtils';
import {
  generateSubscriptionTiersImpression,
  initSubscriptionTiersFullStory,
} from 'bundles/coursera-plus/utils/subscriptionTiersUtils';
import type { EligibilityCheckProps } from 'bundles/coursera-plus/utils/withCourseraPlusProductEligibilityCheck';
import withCourseraPlusProductEligibilityCheck from 'bundles/coursera-plus/utils/withCourseraPlusProductEligibilityCheck';
import withCourseraPlusProductOwnerships from 'bundles/coursera-plus/utils/withCourseraPlusProductOwnerships';
import type { PropsFromCourseraPlusProductOwnerships } from 'bundles/coursera-plus/utils/withCourseraPlusProductOwnerships';
import withEnrollment from 'bundles/enroll/components/xdp/withEnrollment';
import type { PropsFromWithEnrollment } from 'bundles/enroll/components/xdp/withEnrollment';
import PdpSection from 'bundles/enterprise-legacy-xdp/components/miniPDP/PdpSection';
import { TrackedA } from 'bundles/page/components/TrackedLink2';
import inServerContext from 'bundles/ssr/util/inServerContext';

import _t from 'i18n!nls/enroll';

const HEADER_WIDTH = 100;
const HEADER_HEIGHT = 11;

const sectionStyles = StyleSheet.create({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

const COURSERA_PLUS_RIBBON_BACKGROUND_COLOR = 'rgba(45, 47, 58, 0.4)';

const styles = StyleSheet.create({
  opacityOverGradient: {
    background: COURSERA_PLUS_RIBBON_BACKGROUND_COLOR,
    ':hover': {
      background: 'rgba(45, 47, 58, 0.5)',
    },
  },
  ribbonDefault: {
    background: COURSERA_PLUS_RIBBON_BACKGROUND_COLOR,
  },

  ribbonContentWrapper: {
    width: '100%',
  },
  container: {
    paddingTop: '12px !important',
    paddingBottom: '12px !important',
  },
  ribbonDisplay: {
    display: 'block !important',
  },
  ribbonContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    fontSize: '14px',
    color: '#FFF',
  },

  includedWithContainer: {
    minWidth: '133px',
    paddingRight: '33px',
  },

  subscribeValuePropsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  unlimitedAccessContainer: {
    marginRight: '22px',
  },

  learnMoreContainer: {
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
});

type PropsFromCaller = {
  s12nId?: string;
  courseId?: string;
  isSpecialization: boolean;
  isPdpPage?: boolean;
  shouldShowCourseraPlusRibbon?: boolean;
  partners?: Partners;
};

type PropsToComponent = EligibilityCheckProps &
  PropsFromCaller &
  PropsFromWithEnrollment &
  PropsFromCourseraPlusProductOwnerships;

export class CourseraPlusBannerRibbon extends React.Component<PropsToComponent> {
  componentDidMount() {
    const { isCourseraPlusEligibleProduct, isPdpPage, enrollmentAvailableChoices, s12nId, partners } = this.props;

    // Record impression for Subscription Tiers experiment if user is logged in,
    // product is Coursera Plus eligible, and not on PDP (only impress for products that are not Projects)
    if (user.isAuthenticatedUser() && isCourseraPlusEligibleProduct && !isPdpPage) {
      generateSubscriptionTiersImpression({ hasSpecialization: Boolean(s12nId), partners });

      // Record FullStory if the learner can subscribe to Lite and will see new Subscription Tiers modals
      if (enrollmentAvailableChoices?.canSubscribeToCourseraLite) {
        initSubscriptionTiersFullStory();
      }
    }
  }

  render() {
    const {
      isCourseraPlusEligibleProduct,
      enrollmentAvailableChoices,
      ownsCourseraPlus,
      isPdpPage,
      shouldShowCourseraPlusRibbon = true,
    } = this.props;

    const canSubscribeToCourseraPlus = enrollmentAvailableChoices?.canSubscribeToCourseraPlus;
    const isSubscribedToCourseraPlus = ownsCourseraPlus;

    const isUserEligibleToSeeRibbon = canSubscribeToCourseraPlus || isSubscribedToCourseraPlus;
    const shouldLinkToLandingPage = !isSubscribedToCourseraPlus; // only link to C+ landing page if user is not subscribed to C+

    if (!isCourseraPlusEligibleProduct || !isUserEligibleToSeeRibbon || !shouldShowCourseraPlusRibbon) {
      return null;
    }

    let ribbonContent = (
      <Container rootClassName={styles.container}>
        <div {...css(styles.ribbonContainer)}>
          <div {...css(styles.includedWithContainer)}>
            <Typography2 component="p" color="invertBody">
              <FormattedMessage
                message={_t('Included with {courseraPlus}')}
                courseraPlus={
                  <Imgix
                    className="logo"
                    src={logo.PLUS_FULL_WHITE}
                    maxWidth={HEADER_WIDTH}
                    maxHeight={HEADER_HEIGHT}
                    alt={_t('Coursera Plus')}
                  />
                }
              />
            </Typography2>
          </div>
          {shouldLinkToLandingPage && (
            <div {...css(styles.subscribeValuePropsContainer)}>
              <div {...css(styles.unlimitedAccessContainer)}>
                <Typography2 component="p" color="invertBody">
                  <FormattedMessage
                    message={_t(
                      'Unlimited access to {numberOfProducts} courses, Projects, Specializations, and Professional Certificates.'
                    )}
                    numberOfProducts={numberOfProducts}
                  />
                </Typography2>
              </div>
              <Typography
                color="invertBody"
                data-e2e="coursera-plus-banner-ribbon-learn-more"
                {...css(styles.learnMoreContainer)}
              >
                {_t('Learn More')}
              </Typography>
            </div>
          )}
        </div>
      </Container>
    );

    const ribbonStyles = shouldLinkToLandingPage ? styles.opacityOverGradient : styles.ribbonDefault;

    ribbonContent = isPdpPage ? <PdpSection styles={sectionStyles}>{ribbonContent}</PdpSection> : ribbonContent;
    ribbonContent = shouldLinkToLandingPage ? (
      <TrackedA
        className="nostyle"
        trackingName="coursera_plus_banner_ribbon_link"
        href={getLandingPageLink()}
        target="_blank"
        rel="noopener noreferrer"
      >
        {ribbonContent}
      </TrackedA>
    ) : (
      ribbonContent
    );

    return (
      <div data-e2e="coursera-plus-banner-ribbon" {...css(styles.ribbonContentWrapper, ribbonStyles)}>
        {ribbonContent}
      </div>
    );
  }
}

export default compose<PropsToComponent, PropsFromCaller>(
  withCourseraPlusProductEligibilityCheck<PropsFromCaller>(),
  branch<PropsFromCaller & EligibilityCheckProps>(
    () => !inServerContext,
    compose<PropsToComponent, PropsFromCaller & EligibilityCheckProps>(
      withEnrollment(),
      withCourseraPlusProductOwnerships()
    )
  )
)(CourseraPlusBannerRibbon);
