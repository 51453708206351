import { tupleToStringKey } from 'js/lib/stringKeyTuple';

import type { ProductType as ProductTypeType } from 'bundles/payments/common/ProductType';

import NaptimeResource from './NaptimeResource';

export type FormattedPrice = {
  amount: string;
  currencySymbol: string;
  formattedString: string;
  currencyId: number;
  currencyCode: string;
};

export type PromotionInfo = {
  discountAmount: string;
  discountPercent?: number;
  promotionId: number;
  promotionName: string;
};

export interface Price {
  amountNumber: number;
  currencySymbol: string;
  currencyId: number;
  currencyCode: string;
}

export type PromotionInContext = {
  code: string | null | undefined;
  originalPrice?: Price;
  discountedPrice?: Price;
  discountPercent?: number;
  promotionInfo?: PromotionInfo;
  isComplete: boolean;
  firstErrorFound?: string;
};

export const getPromoForLandingPage = (
  code: string | null | undefined,
  retrieved: Array<ProductPricesV4> | null
): PromotionInContext => {
  const returnValue: PromotionInContext = { code, isComplete: false };
  if (!code) {
    return returnValue;
  }
  returnValue.code = code;
  if (!Array.isArray(retrieved)) {
    returnValue.firstErrorFound = `Finder returned something that's not  an array for promo code ${code}`;
    return returnValue;
  }
  if (retrieved.length < 1) {
    returnValue.firstErrorFound = `Finder returned an array with no elements for promo code ${code}`;
    return returnValue;
  }
  const { promotionInfo, formattedFinalAmount, amount } = retrieved[0];
  if (!promotionInfo) {
    returnValue.firstErrorFound = `Missing promotion info for code ${code}`;
    return returnValue;
  }
  if (!formattedFinalAmount) {
    returnValue.firstErrorFound = `Missing discounted price for code ${code}`;
    return returnValue;
  }
  if (amount === undefined || Number.isNaN(amount)) {
    returnValue.firstErrorFound = `Missing or incorrect original amount for code ${code}`;
  }
  const { currencySymbol, currencyCode, currencyId } = formattedFinalAmount;
  const originalPrice: Price = {
    amountNumber: Number(amount),
    currencyCode,
    currencySymbol,
    currencyId,
  };
  const discountedPrice: Price = {
    amountNumber: Number(formattedFinalAmount.amount),
    currencyCode,
    currencySymbol,
    currencyId,
  };
  returnValue.promotionInfo = promotionInfo;
  returnValue.discountedPrice = discountedPrice;
  returnValue.originalPrice = originalPrice;
  returnValue.isComplete = true;
  return returnValue;
};

export const getOriginalPrice = (promo: ProductPricesV4): Price => {
  const { currencySymbol, currencyId, currencyCode } = promo.formattedFinalAmount;
  return {
    amountNumber: Number(promo.amount),
    currencySymbol,
    currencyCode,
    currencyId,
  };
};

export const getDiscountedPrice = (promo: ProductPricesV4): Price => {
  const { currencySymbol, currencyId, currencyCode, amount } = promo.formattedFinalAmount;
  return {
    amountNumber: Number(amount),
    currencySymbol,
    currencyCode,
    currencyId,
  };
};

export class ProductPricesV4 extends NaptimeResource {
  static RESOURCE_NAME = 'productPrices.v4';

  declare amount: number;

  declare formattedFinalAmount: FormattedPrice;

  declare promotionInfo: PromotionInfo;

  declare countryIsoCode: string;

  declare currencyCode: string;

  declare finalAmount: number;

  declare productItemId: string;

  static getPromoForProductInfo(promoId: string, productId: string) {
    return this.finder('previewDiscountedPrices', {
      params: { productId: `CourseraPlusSubscription~${productId}`, previewPromoCodeId: promoId },
    });
  }

  /**
   * Get prices of multiple products.
   *
   * @param {Array} productList - Array of objects consisting of productType and productItemId
   * @returns {Promise.<Array.<ProductPrice>>} - Promise array of product prices.
   */
  static getProductPrices(
    productList: {
      productType: ProductTypeType;
      productItemId: string;
    }[] = []
  ) {
    if (productList.length === 0) {
      throw new Error('productList cannot be empty');
    }

    const productIds = productList.map(({ productType, productItemId }) => {
      return tupleToStringKey([productType, productItemId]);
    });

    return this.finder('byProductIds', { params: { productIds } });
  }
}

export default ProductPricesV4;
