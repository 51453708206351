import * as React from 'react';
import type FormattedMessage from 'react-intl';

import classNames from 'classnames';

import { Typography } from '@coursera/cds-core';
import { Box, Col } from '@coursera/coursera-ui';

type Props = {
  rootClassName?: string;
  title: string | React.ReactElement<typeof FormattedMessage>;
  subtitle?: string | React.ReactElement<typeof FormattedMessage>;
  titleTag?: React.ElementType;
  subtitleTag?: React.ElementType;
  alignItems?: 'start' | 'center' | 'end';
  alignText?: 'left' | 'center' | 'right';
  hasNoColWrapper?: boolean;
};

const SectionTitle: React.SFC<Props> = ({
  rootClassName = '',
  title,
  subtitle,
  titleTag = 'h3',
  subtitleTag = 'p',
  alignItems = 'center',
  alignText = 'center',
  hasNoColWrapper,
}) => {
  if (!(title || subtitle)) {
    return null;
  }

  const wrapperRootClassName = classNames('SectionTitle', 'm-b-3', `text-xs-${alignText}`, rootClassName);

  const titleAndSubtitle = [
    <Typography component={titleTag} className="max-text-width-xl m-b-1" key="title" variant="h1">
      {title}
    </Typography>,
  ];
  if (subtitle) {
    titleAndSubtitle.push(
      <Typography component={subtitleTag} className="max-text-width m-b-1" key="subtitle" variant="body1">
        {subtitle}
      </Typography>
    );
  }

  if (hasNoColWrapper) {
    return (
      <Box rootClassName={wrapperRootClassName} flexDirection="column" alignItems={alignItems}>
        {titleAndSubtitle}
      </Box>
    );
  } else {
    return (
      <Col rootClassName={wrapperRootClassName} noGutter>
        <Box flexDirection="column" alignItems={alignItems}>
          {titleAndSubtitle}
        </Box>
      </Col>
    );
  }
};

export default SectionTitle;
