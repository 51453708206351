/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { isEmpty } from 'lodash';

import { useRetracked } from 'js/lib/retracked';
import useRouter from 'js/lib/useRouter';

import { Button, CardMetadata, ProductCard, Typography, breakpoints } from '@coursera/cds-core';
import type { ButtonProps } from '@coursera/cds-core';

import { FilterableShowMoreGridSection, FilterableShowMoreGridSectionPlaceholder } from 'bundles/cds-labs/components';
import withSingleTracked from 'bundles/common/components/withSingleTracked';
import { filterUndefined } from 'bundles/common/utils/commonUtils';
import { formatRoleIdToString } from 'bundles/enterprise-learner-onboarding/utils';
import { DESCRIPTION_PAGE_PATHS, launchMiniModal } from 'bundles/enterprise-legacy-xdp/hocs/withMiniModal';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import SkillScoreCard from 'bundles/program-personalized-tab/components/SkillScoreCard';
import { SCORED_SKILL_RECS_SOURCE_QUERY_VALUE } from 'bundles/program-personalized-tab/constants';
import { useOnboardingData } from 'bundles/program-personalized-tab/contexts/OnboardingContext';
import { useScoredSkillRecsData } from 'bundles/program-personalized-tab/contexts/ScoredSkillRecsContext';
import type {
  Partner,
  SkillScoreEntry,
  SkillScoreOrProductEntry,
} from 'bundles/program-personalized-tab/types/sharedTypes';
import { getProductRecsMetadata } from 'bundles/program-personalized-tab/utils/displayUtils';

import _t from 'i18n!nls/program-personalized-tab';

type Props = {
  programSlug: string;
};

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps>(Button);

const styles = {
  cardWrapper: css`
    height: 100%;
    padding: var(--cds-spacing-150);
    ${breakpoints.down('xs')} {
      padding: var(--cds-spacing-100) 0;
    }
  `,
  pointerCursor: css`
    cursor: pointer;
  `,
};

const getDescriptionPagePath = (productType: string) => {
  switch (productType) {
    case 'DiscoveryCollections_course':
      return DESCRIPTION_PAGE_PATHS.course;
    case 'DiscoveryCollections_specialization':
      return DESCRIPTION_PAGE_PATHS.specialization;
    case 'DiscoveryCollections_professionalCertificate':
      return DESCRIPTION_PAGE_PATHS.professionalCertificate;
    case 'DiscoveryCollections_guidedProject':
      return DESCRIPTION_PAGE_PATHS.project;
    default:
      return undefined;
  }
};

const ScoredSkillRecsCollection = ({ programSlug }: Props) => {
  const { getRecs, skillData, loading: isLoading } = useScoredSkillRecsData();
  const { skills, selectedRoleId, hasRoleSkills } = useOnboardingData();
  const track = useRetracked();
  const router = useRouter();
  const filters = useMemo(
    () => skills.filter((skill) => getRecs()[skill.id]).map((skill) => ({ id: skill.id, label: skill.name })) ?? [],
    [skills, getRecs]
  );
  const [currentFilterId, setCurrentFilterId] = useState('');
  const title =
    selectedRoleId && hasRoleSkills
      ? _t('Build upon your #{role} skill set', { role: formatRoleIdToString(selectedRoleId) })
      : _t('Build upon your skill set');
  const skillPageRoute = `/programs/${programSlug}/skills/${currentFilterId}?source=recs-tab-skill-score-card`;
  const productRecs = getRecs()[currentFilterId]?.entities ?? [];
  const items: SkillScoreOrProductEntry[] = [
    {
      type: 'skillScoreCard',
      name: skillData[currentFilterId]?.name ?? '',
      score: skillData[currentFilterId]?.score ?? 0,
      route: skillPageRoute,
    } as SkillScoreEntry,
    ...productRecs,
  ];

  useEffect(() => {
    if (filters.length > 0) {
      setCurrentFilterId(filters[0].id);
    }
  }, [filters]);

  const handleFilterSelection = (filterId?: string) => {
    if (filterId) {
      const filterIndex = filters.findIndex((f) => f.id === filterId);
      track({
        trackingData: { filterId, filterIndex, filterLabel: filters[filterIndex].label },
        trackingName: 'scored_skill_recs_filter_change',
        action: 'click',
      });
      setCurrentFilterId(filterId);
    }
  };

  const handleProductCardClick = (
    productId: string,
    productName: string,
    productType: string,
    productSlug: string,
    itemIndex: number,
    collectionTrackingId: string
  ) => {
    track({
      trackingData: { productId, productName, productType, itemIndex },
      trackingName: 'scored_skill_rec_product',
      action: 'click',
    });
    launchMiniModal(
      router,
      productId,
      productType === 'DiscoveryCollections_course' || productType === 'DiscoveryCollections_guidedProject',
      collectionTrackingId,
      undefined,
      { source: SCORED_SKILL_RECS_SOURCE_QUERY_VALUE },
      {
        slug: productSlug,
        productPagePath: getDescriptionPagePath(productType),
      }
    );
  };

  if (!isLoading && isEmpty(productRecs)) {
    track({
      trackingData: {},
      trackingName: 'no_scored_skill_recs',
      action: 'hide',
    });
    return null;
  }

  if (isLoading) {
    return (
      <FilterableShowMoreGridSectionPlaceholder
        renderPlaceholderItem={() => (
          <div css={styles.cardWrapper} data-testid="placeholder-card-wrapper">
            <ProductCard variant="grid" productType="course" loading />{' '}
          </div>
        )}
      />
    );
  }

  return (
    <TrackedDiv trackingName="scored_skill_recs" withVisibilityTracking>
      <FilterableShowMoreGridSection<SkillScoreOrProductEntry>
        filters={filters}
        selectedFilterId={currentFilterId}
        onFilterSelect={handleFilterSelection}
        title={title}
        renderTitle={() => (
          <Typography component="h2" variant="h2semibold">
            {title}
          </Typography>
        )}
        items={items}
        renderItem={({ item, itemIndex, itemRef }) => {
          const { type, id, name } = item;

          if (type === 'skillScoreCard') {
            return (
              <div css={styles.cardWrapper} key={`${title}-${currentFilterId}-skill-card`}>
                <SkillScoreCard id={currentFilterId} name={name} score={item.score} route={item.route} />
              </div>
            );
          }

          const partnersCleaned = item.partners
            ?.filter(filterUndefined)
            .map(({ name: partnerName, logo }: Partner) => ({ name: partnerName, logoUrl: logo ?? undefined }));
          const collectionTrackingId = `skill~${currentFilterId}`;

          return (
            <div css={[styles.cardWrapper, styles.pointerCursor]} key={`${title}-${currentFilterId}-${id}`}>
              <ProductCard
                variant="grid"
                productType={item.__typename ?? ''}
                title={{
                  name,
                  customLinkProps: { refalt: itemRef },
                }}
                previewImageSrc={item.imageUrl ?? undefined}
                partners={partnersCleaned}
                footer={
                  <CardMetadata
                    metadata={getProductRecsMetadata({
                      level: item.difficultyLevel ?? undefined,
                      productType: item.__typename,
                    })}
                  />
                }
                onClick={() =>
                  handleProductCardClick(id, name, item.__typename, item.slug, itemIndex, collectionTrackingId)
                }
              />
            </div>
          );
        }}
        renderExpandButton={({ onClick, defaultLabel, isExpanded, showMoreCount, ...rest }) => {
          return (
            <TrackedButton
              trackingName="show_more_scored_skill_recs"
              trackingData={{ title, isExpanded, showMoreCount }}
              size="small"
              variant="secondary"
              onClick={onClick}
              {...rest}
            >
              {defaultLabel}
            </TrackedButton>
          );
        }}
      />
    </TrackedDiv>
  );
};

export default ScoredSkillRecsCollection;
