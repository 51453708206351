import * as React from 'react';

type Props = {
  width?: number;
  height?: number;
};

const PersonLaptopBlueSVG = ({ width = 48, height = 48 }: Props) => {
  return (
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38.81 21.8999C38.81 30.6899 25.4 37.8999 16.61 37.8999C7.82 37.8999 7 30.6899 7 21.8999C7 13.1099 18.63 6.18994 27.42 6.18994C36.21 6.18994 38.81 13.1099 38.81 21.8999Z"
        fill="#D6EDF6"
      />
      <path
        d="M13.5 37.0601H13.26V38.0601C13.26 38.3412 13.3717 38.6108 13.5705 38.8096C13.7693 39.0084 14.0389 39.1201 14.32 39.1201H36.56C36.8089 39.1058 37.0451 39.0051 37.2277 38.8353C37.4102 38.6655 37.5278 38.4373 37.56 38.1901L38.89 26.6001C38.9167 26.4545 38.9107 26.3049 38.8725 26.1619C38.8343 26.019 38.7649 25.8863 38.6693 25.7734C38.5736 25.6605 38.4541 25.5703 38.3193 25.5092C38.1846 25.4481 38.0379 25.4176 37.89 25.4201H23.16C22.9011 25.4184 22.6507 25.5124 22.4569 25.684C22.2631 25.8557 22.1396 26.0929 22.11 26.3501L20.88 37.0601H18.88"
        stroke="black"
        strokeWidth="0.81"
        strokeLinejoin="round"
      />
      <path
        d="M25.15 25.21L22.44 21.82C22.2921 21.636 22.1048 21.4875 21.8919 21.3854C21.6791 21.2833 21.4461 21.2302 21.21 21.23H20.75C20.2239 22.0368 19.5049 22.6996 18.658 23.1584C17.8111 23.6172 16.8632 23.8575 15.9 23.8575C14.9368 23.8575 13.9889 23.6172 13.142 23.1584C12.2951 22.6996 11.5761 22.0368 11.05 21.23H10.63C10.2109 21.23 9.80907 21.3964 9.51276 21.6928C9.21645 21.9891 9.04999 22.3909 9.04999 22.81V35.45C9.06037 35.863 9.2311 36.2557 9.52601 36.545C9.82093 36.8343 10.2169 36.9975 10.63 37H18.49C18.3668 36.2617 17.9856 35.5911 17.4142 35.1077C16.8429 34.6242 16.1185 34.3593 15.37 34.36H12.21C12.1404 34.36 12.0715 34.3463 12.0072 34.3196C11.9429 34.293 11.8844 34.254 11.8352 34.2047C11.786 34.1555 11.747 34.0971 11.7203 34.0328C11.6937 33.9685 11.68 33.8996 11.68 33.83V27.55"
        stroke="black"
        strokeWidth="0.81"
        strokeLinejoin="round"
      />
      <path
        d="M30.12 33.37C30.9926 33.37 31.7 32.6626 31.7 31.79C31.7 30.9174 30.9926 30.21 30.12 30.21C29.2474 30.21 28.54 30.9174 28.54 31.79C28.54 32.6626 29.2474 33.37 30.12 33.37Z"
        stroke="black"
        strokeWidth="0.81"
        strokeLinejoin="round"
      />
      <path
        d="M21.69 12.8V18.07C21.6278 19.5653 20.9901 20.9787 19.9102 22.0148C18.8302 23.0508 17.3915 23.6293 15.895 23.6293C14.3984 23.6293 12.9598 23.0508 11.8798 22.0148C10.7998 20.9787 10.1621 19.5653 10.1 18.07V17"
        stroke="black"
        strokeWidth="0.81"
        strokeLinejoin="round"
      />
      <path
        d="M13.43 8.83008H23.8V9.92008C23.8 10.6441 23.5124 11.3385 23.0004 11.8505C22.4884 12.3625 21.794 12.6501 21.07 12.6501H13.43V13.7401C13.4273 14.6056 13.0816 15.4348 12.4687 16.0459C11.8557 16.6569 11.0255 17.0001 10.16 17.0001V17.0001V12.1101C10.16 11.2419 10.5042 10.4091 11.1171 9.7943C11.7301 9.17946 12.5618 8.83273 13.43 8.83008Z"
        stroke="black"
        strokeWidth="0.81"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PersonLaptopBlueSVG;
