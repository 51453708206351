import * as React from 'react';
import { Mutation } from 'react-apollo';

import type {
  CourseraTierSubscriptions_BillingCycle as BillingCycleType,
  CourseraTierSubscriptionsMutationQueriesCreateMidCycleUpgradeCartArgs,
  Mutation as MutationType,
} from '__generated__/graphql-types';

import prices from 'js/lib/prices';
import requestCountry from 'js/lib/requestCountry';
import user from 'js/lib/user';

import { createMidCycleUpgradeCartMutation } from 'bundles/coursera-plus/api/CourseraTierSubscriptionsMutations';
import { getProductEnrollmentInformationFromCourseAndS12nIds } from 'bundles/enroll-course/lib/enrollmentChoiceUtils';
import CartsV2 from 'bundles/naptimejs/resources/carts.v2';

export type PropsFromWithCreateMidCycleUpgradeCartMutation = {
  createMidCycleUpgradeCart?: (options: CreateUpgradeCartRequestOptions) => Promise<CartsV2 | undefined>;
};

type CreateUpgradeCartRequestOptions = {
  s12nId?: string;
  courseId?: string;
  toTierBillingCycle: BillingCycleType;
};

type PropsToComponent = {
  children: (renderProps: PropsFromWithCreateMidCycleUpgradeCartMutation) => React.ReactElement;
};

export const CourseraLiteCreateCartProvider: React.FunctionComponent<PropsToComponent> = ({ children }) => {
  return (
    <Mutation<{}, CourseraTierSubscriptionsMutationQueriesCreateMidCycleUpgradeCartArgs>
      mutation={createMidCycleUpgradeCartMutation}
      context={{ clientName: 'gatewayGql' }}
    >
      {(mutation) => {
        const createMidCycleUpgradeCart = ({
          courseId,
          s12nId,
          toTierBillingCycle,
        }: CreateUpgradeCartRequestOptions) => {
          const requestCountryCode = requestCountry.get();
          const productEnrollmentInformation = getProductEnrollmentInformationFromCourseAndS12nIds({
            courseId,
            s12nId,
          });
          return mutation({
            variables: {
              input: {
                userId: String(user.get().id),
                fromTier: 'COURSERA_TIER_LITE',
                toTier: 'COURSERA_PLUS_SUBSCRIPTION',
                toTierBillingCycle,
                currencyCode: prices.getCurrencyFromCountry(requestCountryCode),
                countryIsoCode: requestCountryCode,
                ...(productEnrollmentInformation ? { productEnrollmentInformation } : {}),
              },
            },
          }).then((response) => {
            // Return a CartsV2 object so that it's the same shape as other cart create requests in enrollmentChoiceUtils.ts
            const cart = (response?.data as MutationType)?.CourseraTierSubscriptionsMutations?.createMidCycleUpgradeCart
              .toTierCart;

            if (cart) {
              return new CartsV2(cart);
            }

            return undefined;
          });
        };
        return children({ createMidCycleUpgradeCart });
      }}
    </Mutation>
  );
};

export const withCreateMidCycleUpgradeCartMutation = function <PropsFromCaller>(
  Component: React.ComponentType<PropsFromCaller & PropsFromWithCreateMidCycleUpgradeCartMutation>
) {
  return (props: PropsFromCaller) => (
    <CourseraLiteCreateCartProvider>
      {({ createMidCycleUpgradeCart }) => (
        <Component {...props} createMidCycleUpgradeCart={createMidCycleUpgradeCart} />
      )}
    </CourseraLiteCreateCartProvider>
  );
};

export default withCreateMidCycleUpgradeCartMutation;
