import find from 'lodash/find';

import logger from 'js/app/loggerSingleton';

import type { EnrollmentChoices } from 'bundles/enroll/components/xdp/withEnrollment';
import type { SubscriptionProduct } from 'bundles/enroll/types/ProductInfoTypes';
import BillingCycleType from 'bundles/subscriptions/common/BillingCycleType';

export const getSubscriptionSkuId = (
  availableS12nSubscriptions: SubscriptionProduct[],
  billingType: string
): string | undefined => {
  const subscription = find(availableS12nSubscriptions, (sub) => {
    const billingCycle = sub.productProperties && sub.productProperties.billingCycle;
    return billingCycle === billingType;
  });
  if (subscription) {
    return subscription.productItemId;
  } else {
    logger.warn(`There are no available subscriptions of type ${billingType}`);
    return undefined;
  }
};

export const getS12nMonthlySkuId = (
  enrollmentAvailableChoices?: EnrollmentChoices,
  s12nId?: string
): string | undefined => {
  if (!enrollmentAvailableChoices || !s12nId) {
    return undefined;
  }

  const availableSubscriptions = enrollmentAvailableChoices.getS12nSubscriptionProducts(s12nId);

  if (availableSubscriptions) {
    return getSubscriptionSkuId(availableSubscriptions, BillingCycleType.MONTHLY);
  }

  return undefined;
};

export default {
  getSubscriptionSkuId,
  getS12nMonthlySkuId,
};
