/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum org_coursera_authentication_thirdpartyauth_OrganizationType {
  BUSINESS = "BUSINESS",
  CAMPUS = "CAMPUS",
  GOVERNMENT = "GOVERNMENT",
}

export enum org_coursera_authentication_thirdpartyauth_ProgramVisibilityRule {
  ACCEPTED_PRIVACY_NOTICE = "ACCEPTED_PRIVACY_NOTICE",
  EVERYONE = "EVERYONE",
}

export enum org_coursera_authentication_thirdpartyauth_SsoLoginMethod {
  JWT = "JWT",
  JWT_INVITE = "JWT_INVITE",
  SAML = "SAML",
}

export enum org_coursera_authentication_thirdpartyauth_saml_SamlSigningAlgorithm {
  SHA1_RSA = "SHA1_RSA",
  SHA256_RSA = "SHA256_RSA",
}

export enum org_coursera_enterprise_admin_role_EnterpriseAdminRole {
  ORGANIZATION_FULL = "ORGANIZATION_FULL",
  PROGRAM_FULL = "PROGRAM_FULL",
}

export enum org_coursera_enterprise_contract_tag_EnterpriseContractTag {
  C4C = "C4C",
  C4C_CORONA_TRIAL = "C4C_CORONA_TRIAL",
  C4C_FACULTY = "C4C_FACULTY",
  C4C_FREEMIUM = "C4C_FREEMIUM",
  C4C_GP_GOOG = "C4C_GP_GOOG",
  C4C_MOROCCO = "C4C_MOROCCO",
  C4C_PARTNER_CONSORTIUM = "C4C_PARTNER_CONSORTIUM",
  C4C_UKRAINE = "C4C_UKRAINE",
  C4C_UPSWELL = "C4C_UPSWELL",
  C4ER_PORTUGUESE = "C4ER_PORTUGUESE",
  C4ER_SPANISH = "C4ER_SPANISH",
  C4FW_ENGLISH = "C4FW_ENGLISH",
  C4FW_FRENCH = "C4FW_FRENCH",
  C4FW_GERMAN = "C4FW_GERMAN",
  C4FW_PORTUGUESE = "C4FW_PORTUGUESE",
  C4FW_SPANISH = "C4FW_SPANISH",
  C4P = "C4P",
  C4T_FREE_TRIAL = "C4T_FREE_TRIAL",
  C4WR_CORONA_TRIAL = "C4WR_CORONA_TRIAL",
  CAREER_ACADEMY_FULL = "CAREER_ACADEMY_FULL",
  CAREER_ACADEMY_LIMITED = "CAREER_ACADEMY_LIMITED",
  COURSERA_CORE = "COURSERA_CORE",
  DATA_TECH_ACADEMY = "DATA_TECH_ACADEMY",
  ENT_WD = "ENT_WD",
  ENT_WD_GP = "ENT_WD_GP",
  GCP_CLOUD_LEADERS = "GCP_CLOUD_LEADERS",
  GCP_CLOUD_SELECT = "GCP_CLOUD_SELECT",
  GCP_KICKSTART = "GCP_KICKSTART",
  GCP_RESELLER = "GCP_RESELLER",
  GEN_AI = "GEN_AI",
  GEN_AI_PROMO = "GEN_AI_PROMO",
  GOOGLE_SCHOLARSHIP = "GOOGLE_SCHOLARSHIP",
  GROW_WITH_GOOGLE = "GROW_WITH_GOOGLE",
  GWG_CC = "GWG_CC",
  GWG_E2E = "GWG_E2E",
  GWG_ENTERPRISE = "GWG_ENTERPRISE",
  GWG_SMB = "GWG_SMB",
  IBM_KICKSTART_COMB = "IBM_KICKSTART_COMB",
  IBM_KICKSTART_IND = "IBM_KICKSTART_IND",
  IBM_STANDARD = "IBM_STANDARD",
  INDUSTRY_PROF_CERTS = "INDUSTRY_PROF_CERTS",
  LEADERSHIP_LITE = "LEADERSHIP_LITE",
  META_SCHOLARSHIP = "META_SCHOLARSHIP",
  PRIVATE_CONTENT_ONLY = "PRIVATE_CONTENT_ONLY",
  PWC_RESELLER = "PWC_RESELLER",
  SOCIAL_IMPACT = "SOCIAL_IMPACT",
}

export enum org_coursera_payment_transaction_PaymentTransactionActionType {
  CHARGEBACK = "CHARGEBACK",
  PARTIAL_REFUND = "PARTIAL_REFUND",
  PAYMENT = "PAYMENT",
  QUERY = "QUERY",
  REFUND = "REFUND",
  VOID = "VOID",
}

export enum org_coursera_product_BillingCycle {
  ANNUAL = "ANNUAL",
  BIANNUAL = "BIANNUAL",
  MONTHLY = "MONTHLY",
}

export enum org_coursera_product_enterprise_contract_EnterpriseContractBillingType {
  INVOICE = "INVOICE",
  NONE = "NONE",
  ONE_TIME = "ONE_TIME",
  QUARTERLY = "QUARTERLY",
}

export enum org_coursera_product_enterprise_contract_EnterpriseContractState {
  ACTIVE = "ACTIVE",
  CREATED = "CREATED",
  EXPIRED = "EXPIRED",
  PAUSED = "PAUSED",
  TERMINATED = "TERMINATED",
}

export enum org_coursera_product_enterprise_contract_EnterpriseContractTerminatedReason {
  CONTRACT_END_DATE = "CONTRACT_END_DATE",
  DOWNGRADED = "DOWNGRADED",
  FREE_TRIAL_CONVERSION_FAILURE = "FREE_TRIAL_CONVERSION_FAILURE",
  FREE_TRIAL_EXPIRED = "FREE_TRIAL_EXPIRED",
  INITIAL_INVOICE_VOIDED = "INITIAL_INVOICE_VOIDED",
  INITIAL_PAYMENT_FAILED = "INITIAL_PAYMENT_FAILED",
  MIGRATED = "MIGRATED",
  MISSING_PAYMENT = "MISSING_PAYMENT",
  REFUNDED = "REFUNDED",
  RENEWAL_GRACE_PERIOD_EXCEEDED = "RENEWAL_GRACE_PERIOD_EXCEEDED",
}

export enum org_coursera_product_enterprise_contract_EnterpriseContractType {
  SELF_SERVE = "SELF_SERVE",
  STANDARD = "STANDARD",
}

export enum org_coursera_product_enterprise_contract_limits_EnterpriseContractLimitType {
  ENROLLMENT_CAP = "ENROLLMENT_CAP",
  NO_LIMIT = "NO_LIMIT",
  USER_LICENSE = "USER_LICENSE",
  USER_LICENSE_WITH_ENROLLMENT_CAP = "USER_LICENSE_WITH_ENROLLMENT_CAP",
}

export enum org_coursera_product_enterprise_term_EnterpriseContractTermTag {
  C4T_FREE_TRIAL = "C4T_FREE_TRIAL",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
