import { requireFields } from 'bundles/naptimejs/util/requireFieldsDecorator';

import { ProgramUserCreditsV1NaptimeResource } from './__generated__/ProgramUserCreditsV1';

class ProgramUserCredits extends ProgramUserCreditsV1NaptimeResource {
  static RESOURCE_NAME = 'programUserCredits.v1' as const;

  @requireFields('isLimited')
  get isUnlimited() {
    return !this.isLimited;
  }

  @requireFields('isLimited', 'coursesRemaining')
  get hasRemainingCourses() {
    // See above @requireFields()
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return !this.isLimited || this.coursesRemaining! > 0;
  }

  @requireFields('coursesRemaining', 'totalAllocatedCourses')
  get hasFullCreditAvailable() {
    return this.coursesRemaining === this.totalAllocatedCourses;
  }
}

export default ProgramUserCredits;
