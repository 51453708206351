/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useState } from 'react';

import { Button, Dialog } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

import WidgetSelectionSearch from 'bundles/author-questions/components/editors/widget/WidgetSelectionSearch';
import { createWidgetSessionProvider } from 'bundles/author-widget/actions/WidgetAuthoringActions';
import WidgetModalText from 'bundles/author-widget/components/widgetSelectionModal/WidgetModalText';
import WidgetSelectionList from 'bundles/author-widget/components/widgetSelectionModal/WidgetSelectionList';
import type { Props as PropsFromCaller } from 'bundles/author-widget/components/widgetSelectionModal/WidgetSelectionModalContainer';
import { TooltipWrapper } from 'bundles/common/components/Tooltip';

import _t from 'i18n!nls/author-widget';

// This has been left as is for now because there are other product areas that seem to override rules with the same level of specificity so moving this to emotion may break that  due to specificity or load order issues.
import 'css!../__styles__/WidgetSelectionModal';

const { HeadingGroup, Content, Actions } = Dialog;

const styles = {
  root: () =>
    css({
      '.cds-Dialog-dialog': {
        width: 800,
      },
    }),
  actions: (theme: Theme) =>
    css({
      display: 'flex',
      flexFlow: 'row wrap',
      gap: 'var(--cds-spacing-300)',
    }),
};

type Props = PropsFromCaller & {
  isEmbeddedInCml?: boolean;
};

const WidgetSelectionModal: React.FC<Props> = ({
  courseId,
  onCancel,
  onContinue,
  widgets,
  widgetMap,
  branchId,
  itemId,
  atomId,
  name,
  isGradedWidget,
  timeCommitment,
  selectedWidgetId = '',
  isWidgetListLoaded,
  executeAction,
  isEmbeddedInCml,
}) => {
  const [widgetId, setWidgetId] = useState(selectedWidgetId);
  const [searchKeyword, setSearchKeyword] = useState('');

  const handleSearch = (keyword: string) => {
    setSearchKeyword(keyword);
  };

  const handleContinue = () => {
    const widget = widgetMap[widgetId];
    const configuration = (widget.configurationExamples[0] && widget.configurationExamples[0].configuration) || {};
    const iframeTitle = widget.name;
    const isGraded = false;

    executeAction(createWidgetSessionProvider, {
      widgetId,
      iframeTitle,
      courseId,
      configuration,
      branchId,
      itemId,
      atomId,
      name,
      isGraded,
      timeCommitment,
      isEmbeddedInCml,
      onContinue,
    });
    onCancel();
  };

  const isCurrentlySelected = selectedWidgetId === widgetId;

  const getTooltipMessage = () => {
    if (!selectedWidgetId) {
      return _t('You have not selected a plugin.');
    }
    if (isCurrentlySelected) {
      return _t('This is your current plugin.');
    }
    return '';
  };

  return (
    <Dialog open css={styles.root} sticky="actions" data-testid="widget-selection-modal" onClose={onCancel}>
      <HeadingGroup>{_t('Which plugin do you want to use?')}</HeadingGroup>
      <Content>
        <WidgetModalText isGradedWidget={isGradedWidget} />
        <WidgetSelectionSearch handleSearch={handleSearch} />
        <WidgetSelectionList
          key={searchKeyword}
          onWidgetSelected={setWidgetId}
          searchKeyword={searchKeyword}
          selectedWidgetId={widgetId}
          widgets={widgets}
          isWidgetListLoaded={isWidgetListLoaded}
        />
      </Content>

      <Actions css={styles.actions}>
        <TooltipWrapper message={getTooltipMessage()}>
          <Button
            onClick={handleContinue}
            disabled={!widgetId || isCurrentlySelected}
            data-pendo="widget-selection-confirm"
          >
            {_t('Continue')}
          </Button>
        </TooltipWrapper>
        <Button variant="secondary" onClick={onCancel}>
          {_t('Cancel')}
        </Button>
      </Actions>
    </Dialog>
  );
};

export default WidgetSelectionModal;
