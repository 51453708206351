import { css } from '@emotion/react';

import * as React from 'react';

import gql from 'graphql-tag';

import PdpSection from 'bundles/enterprise-legacy-xdp/components/miniPDP/PdpSection';
import type {
  UserReviews_reviewHighlights as UserReviewHighlights,
  UserReviews_ratings as UserReviewRatings,
  UserReviews_topFourAndFiveStarReviews as UserReviewTopFourAndFiveStarReviews,
} from 'bundles/enterprise-legacy-xdp/components/miniPDP/__generated__/UserReviews';
import ReviewsModule from 'bundles/enterprise-legacy-xdp/components/reviews/cds/ReviewsModule';
import withFragments from 'bundles/graphql/components/withFragments';
import type { RatingsObject, ReviewHighlight } from 'bundles/program-common/types/xdpTypes';
import type { TopReview } from 'bundles/reviews-common/types/reviewsSharedTypes';

type Props = {
  name: string;
  ratings: UserReviewRatings;
  topFourAndFiveStarReviews: UserReviewTopFourAndFiveStarReviews[];
  reviewHighlights: UserReviewHighlights[] | null;
};

export function toRatings(ratings: UserReviewRatings): RatingsObject {
  return {
    averageFiveStarRating: ratings.averageFiveStarRating || undefined,
    ratingCount: ratings.ratingCount || undefined,
    commentCount: ratings.commentCount || undefined,
  };
}

export function toReviews(reviews: UserReviewTopFourAndFiveStarReviews[]): Array<TopReview> {
  return reviews.map((review) => {
    return {
      comment: review.comment || '',
      authorName: review.authorName || '',
      averageFiveStarRating: review.averageFiveStarRating || 0,
      timestamp: review.timestamp || '',
      reviewId: review.reviewId || '',
    };
  });
}

export function toReviewHighlights(reviewHighlights: UserReviewHighlights[]): Array<ReviewHighlight> {
  return reviewHighlights.map((review) => {
    return {
      highlightText: review.highlightText || '',
      reviewcount: review.reviewcount || 0,
    };
  });
}

const sectionStyles = css`
  .pdp-section-content {
    max-width: '100% !important';
  }
`;

export const UserReviewsWrapper = (props: Props) => {
  const { name, ratings, topFourAndFiveStarReviews, reviewHighlights } = props;
  const maybeHighlights = (reviewHighlights && toReviewHighlights(reviewHighlights)) || undefined;
  return (
    <PdpSection css={sectionStyles} altBackground={true}>
      <ReviewsModule
        productName={name}
        ratings={toRatings(ratings)}
        reviews={toReviews(topFourAndFiveStarReviews)}
        reviewHighlights={maybeHighlights}
      />
    </PdpSection>
  );
};

export default withFragments({
  UserReviews: gql`
    fragment UserReviews on XdpV1_org_coursera_xdp_cdp_CDPMetadata {
      topFourAndFiveStarReviews {
        comment
        authorName
        averageFiveStarRating
        timestamp
        reviewId
      }
      ratings {
        averageFiveStarRating
        ratingCount
        commentCount
      }
      reviewHighlights {
        highlightText: highlighttext
        reviewcount
      }
    }
  `,
})(UserReviewsWrapper);
