/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { AIGenerateIcon } from '@coursera/cds-icons';

import _t from 'i18n!nls/browse';

const styles = {
  svg: css`
    margin-bottom: -2px;
  `,
};

export const AiSkillsBadge = () => {
  return (
    <>
      <AIGenerateIcon size="small" css={styles.svg} />
      <span>{_t('AI skills')}</span>
    </>
  );
};

export default AiSkillsBadge;
