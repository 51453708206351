import * as React from 'react';
import { useEffect } from 'react';

import Retracked from 'js/lib/retracked';

import type { ApiError } from 'bundles/enroll/utils/errorUtils';
import { getMessage } from 'bundles/enroll/utils/errorUtils';
import Modal from 'bundles/phoenix/components/Modal';

import 'css!./__styles__/EnrollErrorModal';

const EnrollErrorModal = ({
  onClose,
  error,
  isFinancialAid = false,
  isFreeEnroll = false,
  messageOverride,
}: {
  onClose: () => void;
  error?: ApiError;
  isFinancialAid?: boolean;
  isFreeEnroll?: boolean;
  messageOverride?: string;
}) => {
  useEffect(() => {
    const CONTEXT_DATA = {
      namespace: {
        app: 'enroll',
        page: 'enroll_modal',
      },
    };
    Retracked.trackComponent(CONTEXT_DATA, {}, 'error_modal', 'view');
  }, []);

  const message = messageOverride || getMessage(error, isFinancialAid, isFreeEnroll);
  return (
    <Modal modalName="EnrollErrorModal" className="rc-EnrollErrorModal" handleClose={onClose} allowClose={true}>
      <p className="m-t-1s m-b-0">{message}</p>
    </Modal>
  );
};

export default EnrollErrorModal;
