import * as React from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import usePageData from 'bundles/enroll/data/usePageData';
import ReactPriceDisplay from 'bundles/payments-common/components/ReactPriceDisplay';
import cartUtils from 'bundles/payments/lib/cartUtils';

import _t from 'i18n!nls/enroll';

import 'css!./__styles__/SubscriptionPriceHeader';

const SubscriptionPriceHeader: React.FC<{}> = () => {
  const { s12nDerivatives } = usePageData();

  if (!s12nDerivatives || !s12nDerivatives.catalogPrice) {
    return null;
  }

  const { catalogPrice } = s12nDerivatives;

  return (
    <div className="price-header headline-5-text">
      <FormattedMessage
        message={_t('{price} {perMonth}')}
        price={
          <ReactPriceDisplay
            currency={catalogPrice.currencyCode}
            value={catalogPrice.amount}
            hideCurrencyCode={true}
            {...cartUtils.amountToDigitsProps(catalogPrice.amount)}
          />
        }
        perMonth={<span className="per-month">{_t('per month')}</span>}
      />
    </div>
  );
};

export default SubscriptionPriceHeader;
