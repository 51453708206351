import UserAgent from 'js/lib/useragent';

export const getToolBarShortcutKey = (letter: string) => {
  const { platform, isMobileBrowser } = new UserAgent(navigator.userAgent);
  if (!letter || isMobileBrowser) return '';

  if (platform === 'Apple Mac') return `⌘${letter}`;

  return `ctrl+${letter}`;
};
