import * as React from 'react';
import { useContext } from 'react';

import type {
  DescriptionPage_ProductVariant as DescriptionPageProductVariant,
  DescriptionPage_MarketingSource as MarketingSource,
} from '__generated__/graphql-types';

import type PAGE_TYPES from 'bundles/unified-description-page-common/constants/pageTypes';
import { getSpecializationProductUrl } from 'bundles/unified-description-page-common/utils';
import getCourseLink from 'bundles/unified-description-page-common/utils/getCourseLink';

export type PageInfo = {
  getCourseLink: ({ courseSlug, specializationSlug }: { courseSlug: string; specializationSlug?: string }) => string;
  getSpecializationLink: ({
    productSlug,
    productVariant,
  }: {
    productSlug: string;
    productVariant: DescriptionPageProductVariant;
  }) => string;
  productSlug?: string;
  marketingSource?: MarketingSource;
  pageType?: (typeof PAGE_TYPES)[keyof typeof PAGE_TYPES];
  navigationIdMap?: Record<string, string>;
  subfolderLocaleCode?: string;
};

const PageInfoContext = React.createContext<PageInfo>({
  getCourseLink,
  getSpecializationLink: getSpecializationProductUrl,
});

export const usePageInfo = () => {
  return useContext(PageInfoContext);
};

export default PageInfoContext;
