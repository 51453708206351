import { tupleToStringKey } from 'js/lib/stringKeyTuple';

import NaptimeResource from './NaptimeResource';

class LearnerCourseScheduleSuggestions extends NaptimeResource {
  static RESOURCE_NAME = 'learnerCourseScheduleSuggestions.v1';

  static getByUserIdAndCourseId(userId: number, courseId: string, fields: Array<'suggestions'>, required = true) {
    const id = tupleToStringKey([userId.toString(), courseId]);
    return this.get(id, {
      fields,
      required,
    });
  }
}

export default LearnerCourseScheduleSuggestions;
