import * as React from 'react';

import type OnDemandSpecializationsV1 from 'bundles/naptimejs/resources/onDemandSpecializations.v1';
import PaymentChoice from 'bundles/s12n-enroll/components/bulk-pay/PaymentChoice';
import type { PaymentChoiceType } from 'bundles/s12n-enroll/constants';

import _t from 'i18n!nls/s12n-enroll';

type ChoiceProps = {
  currentType: PaymentChoiceType;
  onClick: (type: PaymentChoiceType) => void;
};

type PropsToComponent = ChoiceProps & {
  s12n: OnDemandSpecializationsV1;
};

const getAuditOptionName = () => _t('Audit only');

const PremiumFreePaymentChoice = ({ currentType, onClick }: ChoiceProps) => {
  const body = _t('You will have access to all course materials except graded items.');

  return (
    <PaymentChoice title={getAuditOptionName()} type="free" currentType={currentType} onClick={onClick}>
      <span className="freeChoice">{body}</span>
    </PaymentChoice>
  );
};

const NonPremiumFreePaymentChoice = ({ currentType, onClick }: ChoiceProps) => {
  const body = _t("You won't get a certificate or be in the Specialization, but you can always upgrade later.");

  const title = _t('Full course, no certificate');

  return (
    <PaymentChoice title={title} type="free" currentType={currentType} onClick={onClick}>
      <span className="freeChoice">{body}</span>
    </PaymentChoice>
  );
};

class PaymentChoiceFree extends React.Component<PropsToComponent> {
  render() {
    const { s12n } = this.props;

    if (s12n.isPremiumGrading) {
      return <PremiumFreePaymentChoice currentType={this.props.currentType} onClick={this.props.onClick} />;
    } else {
      return <NonPremiumFreePaymentChoice currentType={this.props.currentType} onClick={this.props.onClick} />;
    }
  }
}

export default PaymentChoiceFree;
