import { useQuery } from '@apollo/client';

import filterExistsOrDefault from 'bundles/program-common/utils/filterExistsOrDefault';
import { SavedProductsQuery } from 'bundles/program-home/components/enterprise-home/EnterpriseHomeQueries';
import type {
  SavedProductsQuery as SavedProductsQueryType,
  SavedProductsQueryVariables,
} from 'bundles/program-home/components/enterprise-home/__generated__/SavedProductsQuery';
import ProgramCurriculumProduct from 'bundles/program-home/components/enterprise-home/models/ProgramCurriculumProduct';

type Props = {
  programId: string;
  start: string;
  limit: number;
};

export const useSavedProductsData = ({ programId, start, limit }: Props) => {
  const { loading, data } = useQuery<SavedProductsQueryType, SavedProductsQueryVariables>(SavedProductsQuery, {
    variables: {
      programId,
      start,
      limit,
    },
    fetchPolicy: 'cache-and-network',
  });

  const products = filterExistsOrDefault(data?.ProgramCurriculumProductsV1Resource?.selected?.elements);
  const savedProducts = products.map((product) => new ProgramCurriculumProduct({ productState: product.productState }));
  const totalNumSavedProducts = data?.ProgramCurriculumProductsV1Resource?.selected?.paging?.total ?? 0;

  return { loading, savedProducts, totalNumSavedProducts };
};
