import { createFeatureScope } from 'bundles/product-features';

export const { ProductFeaturesQuery, withProductFeatures } = createFeatureScope({
  enterprise: {
    contentCurationEnableC4B: false,
    contentCurationEnableC4C: false,
    contentCurationEnableC4G: false,
    allowOrgForCurriculumBuilder: false,
    allowOrgForSpecializationConfiguration: false,
  },
});
