import * as React from 'react';

import type { Editor } from 'slate';
import { Element, Transforms } from 'slate';

import AuthorConfirmModal from 'bundles/author-course/components/AuthorConfirmModal';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';

import _t from 'i18n!nls/cml';

export type Props = {
  editor?: Editor;
  setConfirm: (confirm: boolean) => void;
};

const BLOCK_TYPE_LABELS: Record<string, () => string> = {
  [BLOCK_TYPES.CODE]: () => _t('code block'),
  [BLOCK_TYPES.ASSET]: () => _t('attachment'),
  [BLOCK_TYPES.IMAGE]: () => _t('image'),
  [BLOCK_TYPES.TABLE]: () => _t('table'),
  [BLOCK_TYPES.WIDGET]: () => _t('plugin'),
  [BLOCK_TYPES.LEGACY_AUDIO]: () => _t('audio'),
};

const DeleteConfirmationDialog: React.FC<Props> = ({ editor, setConfirm }) => {
  if (!editor) {
    return null;
  }

  const selectionPath = editor.selection?.anchor.path[0];
  if (selectionPath == null) {
    return null;
  }

  const block = editor.children[selectionPath];
  if (!block) {
    return null;
  }

  const blockType = Element.isElement(block) && block.type && BLOCK_TYPE_LABELS[block.type]?.();
  if (!blockType) {
    return null;
  }

  const onClose = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setConfirm(false);
  };

  const handleConfirm = (e: React.MouseEvent | React.KeyboardEvent) => {
    Transforms.removeNodes(editor, { at: [selectionPath] });
    onClose(e);
  };

  return (
    <AuthorConfirmModal
      content={_t('Are you sure you want to remove this #{blockType}?', { blockType })}
      acceptLabel={_t('Delete')}
      title={_t('Delete this #{blockType}', { blockType })}
      onAccept={handleConfirm}
      onCancel={onClose}
      showModal={true}
      allowClose={true}
    />
  );
};

export default DeleteConfirmationDialog;
