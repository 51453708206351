/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Divider as CdsDivider } from '@coursera/cds-core';

const styles = {
  root: css({
    height: '24px',
    padding: '2px 0px',
  }),
  icon: css({ height: '100%' }),
};

const Divider: React.FC = () => (
  <div css={styles.root}>
    <CdsDivider orientation="vertical" css={styles.icon} />
  </div>
);

export default React.memo(Divider);
