import * as React from 'react';

import { Button } from '@coursera/cds-core';
import type { ButtonProps } from '@coursera/cds-core';

import { copyText } from 'bundles/enterprise-ui/utils';

import _t from 'i18n!nls/admin-dashboard';

const SUCCESS = 'SUCCESS';
const FAIL = 'FAIL';

type Props = {
  textToCopy: string;
} & ButtonProps;

type State = {
  status: string | null;
};

export class CopyToClipboardButton extends React.Component<Props, State> {
  state: State = {
    status: null,
  };

  handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { textToCopy } = this.props;
    e.stopPropagation();
    e.preventDefault();
    copyText(textToCopy).then(
      () => this.setState({ status: SUCCESS }),
      () => this.setState({ status: FAIL })
    );
    setTimeout(() => this.setState({ status: null }), 2000);
  };

  getLabel = () => {
    const { children } = this.props;
    const { status } = this.state;
    switch (status) {
      case SUCCESS:
        return _t('Copied!');
      case FAIL:
        return _t('Failed!');
      default:
        return children;
    }
  };

  render() {
    return (
      <Button onClick={this.handleClick} {...this.props}>
        {this.getLabel()}
      </Button>
    );
  }
}
