import * as React from 'react';
import Media from 'react-media';

import { compose, setDisplayName } from 'recompose';

import connectToStores from 'js/lib/connectToStores';
import type UserAgentInfo from 'js/lib/useragent';

import EnterpriseSearchBarV2, {
  UnifiedEnterpriseSearchBar,
} from 'bundles/enterprise-learner-search/components/search/EnterpriseSearchBarV2';
import { PAGE_HEADER_MOBILE_BREAKPOINT } from 'bundles/page-header/components/PageHeader';
import ApplicationStoreClass from 'bundles/ssr/stores/ApplicationStore';

type InnerProps = {
  userAgent: UserAgentInfo;
  enableSkillsInSearchAndBrowse: boolean;
};

type OuterProps = React.ComponentPropsWithoutRef<typeof UnifiedEnterpriseSearchBar>;

type Props = InnerProps & OuterProps;

const UnifiedMobileEnterpriseSearchBarImpl = ({
  userAgent,
  programSlug,
  programName,
  thirdPartyOrgSlug,
  thirdPartyOrganizationId,
  userId,
  programId,
  enableSkillsInSearchAndBrowse,
  shouldShowShortFormContent,
}: Props) => {
  return (
    <div className="rc-MobileEnterpriseSearchBar">
      <Media
        query={{ maxWidth: PAGE_HEADER_MOBILE_BREAKPOINT - 1 }}
        defaultMatches={userAgent && userAgent.isMobileBrowser}
      >
        {(matches) => {
          return (
            matches && (
              <div className="mobile-search-page-bar">
                <UnifiedEnterpriseSearchBar
                  programSlug={programSlug}
                  programName={programName}
                  thirdPartyOrgSlug={thirdPartyOrgSlug}
                  thirdPartyOrganizationId={thirdPartyOrganizationId}
                  userId={userId}
                  programId={programId}
                  enableSkillsInSearchAndBrowse={enableSkillsInSearchAndBrowse}
                  shouldShowShortFormContent={shouldShowShortFormContent}
                  isMobile={matches}
                />
              </div>
            )
          );
        }}
      </Media>
    </div>
  );
};

type LegacyOuterProps = React.ComponentPropsWithoutRef<typeof EnterpriseSearchBarV2>;

type LegacyProps = InnerProps & LegacyOuterProps;

const MobileEnterpriseSearchBar = ({
  userAgent,
  programIds,
  enableSkillsInSearchAndBrowse,
  shouldShowShortFormContent,
  programName,
}: LegacyProps) => {
  return (
    <div className="rc-MobileEnterpriseSearchBar">
      <Media
        query={{ maxWidth: PAGE_HEADER_MOBILE_BREAKPOINT - 1 }}
        defaultMatches={userAgent && userAgent.isMobileBrowser}
      >
        {(matches) => {
          return (
            matches && (
              <div className="mobile-search-page-bar">
                <EnterpriseSearchBarV2
                  programIds={programIds}
                  enableSkillsInSearchAndBrowse={enableSkillsInSearchAndBrowse}
                  shouldShowShortFormContent={shouldShowShortFormContent}
                  programName={programName}
                />
              </div>
            )
          );
        }}
      </Media>
    </div>
  );
};

export default compose<LegacyProps, LegacyOuterProps>(
  connectToStores<InnerProps, InnerProps>([ApplicationStoreClass], (ApplicationStore, props) => ({
    ...props,
    userAgent: ApplicationStore.getUserAgent(),
  })),
  setDisplayName('MobileEnterpriseSearchBarHOC')
)(MobileEnterpriseSearchBar);

export const UnifiedMobileEnterpriseSearchBar = compose<Props, OuterProps>(
  connectToStores<InnerProps, InnerProps>([ApplicationStoreClass], (ApplicationStore, props) => ({
    ...props,
    userAgent: ApplicationStore.getUserAgent(),
  })),
  setDisplayName('MobileEnterpriseSearchBarHOC')
)(UnifiedMobileEnterpriseSearchBarImpl);
