import { compose, setDisplayName } from 'recompose';

import type { ButtonProps } from '@coursera/cds-core';
import { Button } from '@coursera/cds-core';

import type { TrackingProps } from 'bundles/common/components/withSingleTracked';
import withSingleTracked from 'bundles/common/components/withSingleTracked';

type Props = {
  className?: string;
  type?: string;
  disabled?: boolean;
  label?: string;
} & TrackingProps &
  ButtonProps;

export default compose<Props, Props>(
  setDisplayName('AuthoringTrackedButton'),
  withSingleTracked({ type: 'BUTTON' })
)(Button);
