import * as React from 'react';
import { useMemo } from 'react';

import Imgix from 'js/components/Imgix';

import { PRODUCT_CARD_IMAGE_DEFAULT_VALUES } from 'bundles/collections-carousel/constants';
import LazyImgix from 'bundles/page/components/shared/LazyImgix';
import getProductCardImageUrl, {
  getGeneratedImageUrl,
} from 'bundles/product-card/components/legacy/utils/getProductCardImageUrl';

export type ProductCardImageFitProps = {
  height?: number;
  width?: number;
  fit?: string;
  crop?: string;
  facepad?: number;
};

type ProductCardImageProps = {
  id?: string;
  slug?: string;
  imageUrl?: string;
  partnerLogos?: string[];
  cardPosition?: number;
  productCardImageProps?: ProductCardImageFitProps;
  enableFluidWidthForLazyImg?: boolean;
  shouldPrioritizeImageLoading?: boolean;
};

const ProductCardImage: React.FC<ProductCardImageProps> = ({
  id,
  slug,
  imageUrl,
  partnerLogos,
  cardPosition,
  productCardImageProps,
  enableFluidWidthForLazyImg,
  shouldPrioritizeImageLoading,
}) => {
  const generatedImage = useMemo(() => getGeneratedImageUrl(slug), [slug]);
  const imgixProps = {
    src: getProductCardImageUrl(id, generatedImage || imageUrl, partnerLogos),
    alt: '',
    maxHeight: productCardImageProps?.height || PRODUCT_CARD_IMAGE_DEFAULT_VALUES.height,
    maxWidth: productCardImageProps?.width || PRODUCT_CARD_IMAGE_DEFAULT_VALUES.width,
    imgParams: {
      fit: productCardImageProps?.fit || PRODUCT_CARD_IMAGE_DEFAULT_VALUES.fit,
      auto: 'format,compress, enhance',
      quality: '50',
      ...productCardImageProps,
    },
    fetchpriority: shouldPrioritizeImageLoading ? ('high' as const) : undefined,
    style: { maxHeight: 'none', maxWidth: 'none', minWidth: '100%', minHeight: '100%' },
  };

  // If card is after the 6th element in list, use LazyImgix for perf boost.
  if (cardPosition && cardPosition > 5) {
    return <LazyImgix {...{ ...imgixProps, enableFluidWidth: enableFluidWidthForLazyImg }} />;
  } else {
    return <Imgix {...imgixProps} />;
  }
};

export default ProductCardImage;
