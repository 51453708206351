/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Dialog, useTheme } from '@coursera/cds-core';
import { SvgButton, color } from '@coursera/coursera-ui';
import { SvgVideo } from '@coursera/coursera-ui/svg';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import SimpleVideoPlayer from 'bundles/phoenix/components/SimpleVideoPlayer';

import _t from 'i18n!nls/program-skills-objectives';

const TrackedSvgButton = withSingleTracked({ type: 'BUTTON' })(SvgButton);

type WatchWelcomeVideoButtonProps = {
  videoUrl?: string;
  videoAssetId?: string;
  videoWidth?: number;
};

const { Content } = Dialog;

const useStyles = () => {
  const theme = useTheme();
  return {
    fixMargins: css`
      margin-bottom: var(--cds-spacing-200);
    `,
    videoPlayer: css`
      padding-left: var(--cds-spacing-200);
      padding-right: var(--cds-spacing-200);
    `,
  };
};

/**
 * fixme: fontsize should be 14px/24px. Larger than sm, smaller than md.
 */
const WatchWelcomeVideoButton: React.FC<WatchWelcomeVideoButtonProps> = ({
  videoUrl,
  videoAssetId,
  videoWidth = 400,
}: WatchWelcomeVideoButtonProps) => {
  const styling = useStyles();
  const [isVideoOpen, setIsVideoOpen] = React.useState(false);

  const onClickWatchButton = () => setIsVideoOpen(true);
  const onRequestCloseVideo = () => setIsVideoOpen(false);

  return (
    <div className="CatalogHelpVideo">
      <TrackedSvgButton
        rootClassName="CatalogHelpVideo-Button"
        type="primary"
        onClick={onClickWatchButton}
        label={_t('Watch the Welcome Video')}
        trackingName="how_it_works_button_video_click"
        htmlAttributes={{
          'data-e2e': 'catalog-help-video-button',
        }}
        svgElement={<SvgVideo color={color.white} />}
      />
      <Dialog open={isVideoOpen} aria-describedby="projects-authoring-dialog-content" onClose={onRequestCloseVideo}>
        <Content css={styling.videoPlayer}>
          <TrackedDiv
            trackingName="how_it_works_button_video_modal"
            data-e2e="catalog-help-video-modal"
            css={styling.fixMargins}
          >
            {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ assetId: string | undefined; src: string |... Remove this comment to see the full error message */}
            <SimpleVideoPlayer assetId={videoAssetId} src={videoUrl} width={videoWidth} />
          </TrackedDiv>
        </Content>
      </Dialog>
    </div>
  );
};

export default WatchWelcomeVideoButton;
