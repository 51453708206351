/* eslint-disable no-restricted-syntax */

/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import useRouter from 'js/lib/useRouter';

import { Button, Typography, Typography2, breakpoints, useTheme } from '@coursera/cds-core';
import type { ButtonProps, Theme } from '@coursera/cds-core';
import { ArrowNextIcon } from '@coursera/cds-icons';
import { track } from '@coursera/event-pulse/sdk';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import { handleRedirect } from 'bundles/enterprise-learner-onboarding/utils';
import { Spacer } from 'bundles/enterprise-ui';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import { TabName } from 'bundles/program-home/components/single-program/SingleProgramTabs';
import { ExploreIconSvg } from 'bundles/program-personalized-tab/components/svgs/ExploreIconSvg';

import _t from 'i18n!nls/program-personalized-tab';

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps>(Button);

export type Props = {
  hasCompletedOnboarding?: boolean;
  programSlug?: string;
  // only required if !hasCompletedOnboarding
  onTabChange?: (_: unknown, selectedIndex: number) => void;
  tabNames?: TabName[];
};

const promoBannerContainer = ({ theme, hasCompletedOnboarding }: { theme: Theme; hasCompletedOnboarding: boolean }) =>
  css({
    backgroundColor: hasCompletedOnboarding ? '#CFFAED' : '#E8EEF7',
    padding: '24px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '12px',
    marginTop: '24px',
    [breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      margin: '0 auto',
      padding: '24px 48px',
    },
  });

const leftSection = css({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
});

const iconContainer = ({ theme, hasCompletedOnboarding }: { theme: Theme; hasCompletedOnboarding: boolean }) =>
  css({
    width: '64px',
    height: '64px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: hasCompletedOnboarding ? '#66DAB7' : '#A7B4C9',
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  });

const ctaButtonStyles = css({
  color: 'black',
  border: 'none',
  boxShadow: 'none',
});

export const PromoBanner = ({ hasCompletedOnboarding = false, programSlug, onTabChange, tabNames }: Props) => {
  const theme = useTheme();
  const router = useRouter();

  const getBannerHeaderCopy = () =>
    hasCompletedOnboarding
      ? _t('Your personalized learning experience starts here')
      : _t('Personalized course recommendations begin here');

  const getBannerTaglineCopy = () =>
    hasCompletedOnboarding
      ? _t('Explore course recommendations that align with your career and learning goals.')
      : _t('Get course suggestions just for you when you complete onboarding.');

  const getCTACopy = () => (hasCompletedOnboarding ? _t('View recommendations') : _t('Complete onboarding'));

  const onPromoButtonClick = () => {
    if (hasCompletedOnboarding && tabNames) {
      const recommendationsTabIndex = tabNames.indexOf(TabName.Recommendations);
      onTabChange?.(null, recommendationsTabIndex);
    } else {
      track('click_button', { button: { name: 'onboarding_edit' } });
      handleRedirect(`/programs/${programSlug}/onboarding`, router);
    }
  };

  return (
    <TrackedDiv
      trackingName="promo_banner"
      trackingData={{ hasCompletedOnboarding }}
      withVisibilityTracking
      css={promoBannerContainer({ theme, hasCompletedOnboarding })}
    >
      <section css={leftSection}>
        <div css={iconContainer({ theme, hasCompletedOnboarding })}>
          <ExploreIconSvg />
        </div>
        <div>
          <Typography variant="h2semibold">{getBannerHeaderCopy()}</Typography>
          <Spacer size={4} />
          <Typography2 component="p" variant="bodyPrimary">
            {getBannerTaglineCopy()}
          </Typography2>
        </div>
      </section>
      <TrackedButton
        trackingName="promo_banner_button"
        trackingData={{ hasCompletedOnboarding }}
        variant="secondary"
        icon={<ArrowNextIcon />}
        onClick={onPromoButtonClick}
        css={ctaButtonStyles}
      >
        {getCTACopy()}
      </TrackedButton>
    </TrackedDiv>
  );
};
