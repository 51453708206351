import * as React from 'react';

import { Typography } from '@coursera/cds-core';

// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import AuthoringEvaluatorEditor from 'bundles/author-code-evaluator/components/AuthoringEvaluatorEditor';
import StarterCode from 'bundles/author-code-evaluator/models/StarterCode';
import type { LanguageType } from 'bundles/cml/legacy/constants/codeLanguages';
import Modal from 'bundles/phoenix/components/Modal';

import _t from 'i18n!nls/cml';

import 'css!./__styles__/CMLCodeEditorEvaluatorConfigModal';

type Props = {
  isOpen: boolean;
  value: string;

  evaluatorId: string;
  language: LanguageType;

  onRequestClose: () => void;
  onSave: (x: string, value: string) => void;

  allowClose?: boolean;
  isReadOnly?: boolean;
};

class CMLCodeEditorEvaluatorConfigModal extends React.Component<Props> {
  static defaultProps = {
    allowClose: true,
  };

  handlePublish = (evaluatorId: string, expression: string) => {
    this.props.onSave(evaluatorId, expression);
  };

  handleRemove = () => {
    const { value } = this.props;
    this.props.onSave('', value);
  };

  render() {
    const { value, evaluatorId, language, isOpen, onRequestClose, allowClose, isReadOnly } = this.props;
    const starterCode = new StarterCode({ expression: value, language });

    if (!isOpen) {
      return null;
    }

    const title = _t('Interactive Settings');
    return (
      <Modal
        modalName={title}
        handleClose={onRequestClose}
        className="rc-CMLCodeEditorEvaluatorConfigModal"
        allowClose={allowClose}
      >
        <Typography variant="h1semibold" component="h2">
          {title}
        </Typography>
        <div style={{ paddingTop: 20 }}>
          <AuthoringEvaluatorEditor
            publishedEvaluatorId={evaluatorId}
            starterCode={starterCode}
            onPublish={this.handlePublish}
            onRemove={this.handleRemove}
            onClose={onRequestClose}
            isReadOnly={isReadOnly}
          />
        </div>
      </Modal>
    );
  }
}

export default CMLCodeEditorEvaluatorConfigModal;
