import gql from 'graphql-tag';

export default gql`
  fragment CollectionDescriptionFragment on ProgramCurriculumCollectionsV1 {
    description {
      ... on ProgramCurriculumCollectionsV1_cmlMember {
        cml {
          value
          dtdId
        }
      }
    }
  }
`;
