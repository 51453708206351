/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { Link } from '@coursera/cds-core';
import { color } from '@coursera/coursera-ui';
import { SvgErrorOutline } from '@coursera/coursera-ui/svg';

import { isIntegrityPortalEnabled } from 'bundles/academic-integrity/lib/epics';
import { CheatingIncidentsByUserIdAndCourseIdAPI } from 'bundles/integrity-portal/components/api/CheatingIncidentsByUserIdAndCourseIds';
import type { CheatingIncident } from 'bundles/integrity-portal/lib/types';
import { getCheatingIncidentRoute, isCheatingIncidentImpactingLearner } from 'bundles/integrity-portal/lib/utils';

import _t from 'i18n!nls/integrity-portal';

// the following styles and the overall component layout are copied from static/bundles/course-cards/components/course-card/enterprise/PrivateSessionEndedCard.tsx
// for consistency in styles
const style = css`
  padding-top: 14px;
  margin-top: 30px;
  border-top: 1px solid rgb(234 234 234);
  display: flex;
  align-items: flex-start;
  gap: 12px;
  font-size: 14px;

  > svg {
    flex: 0 0 20px;
  }
`;

type Props = {
  incident?: CheatingIncident;
};

export const ProgramHomeIncidentNotification: React.FC<Props> = ({ incident }) => {
  if (!incident || !isCheatingIncidentImpactingLearner(incident)) {
    return null;
  }

  return (
    <div css={style}>
      <SvgErrorOutline size={20} color={color.danger} suppressTitle />
      <div>
        <div>
          <strong>{_t('Coursera honor code violation')}</strong>
        </div>
        <FormattedMessage
          message={_t(
            'There was one or more submissions flagged in association with this course.  {viewIncidentDetails}'
          )}
          viewIncidentDetails={
            <Link
              variant="quiet"
              href={getCheatingIncidentRoute(incident.id)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {_t('View incident details')}
            </Link>
          }
        />
      </div>
    </div>
  );
};

type InputProps = {
  courseId: string;
};

export const ProgramHomeIncidentNotificationContainer: React.FC<InputProps> = ({ courseId }) => {
  if (!isIntegrityPortalEnabled()) {
    return null;
  }
  return (
    <CheatingIncidentsByUserIdAndCourseIdAPI>
      {({ getCheatingIncidentsInCourse }) => {
        const incident = Object.values(getCheatingIncidentsInCourse(courseId))[0] as CheatingIncident | undefined;
        return <ProgramHomeIncidentNotification incident={incident} />;
      }}
    </CheatingIncidentsByUserIdAndCourseIdAPI>
  );
};

export default ProgramHomeIncidentNotificationContainer;
