export const PAGE_SIZE = 10;

export const ASSET_MANAGER_SIZE = 7;

export const FULL_WIDTH = 'FULL_WIDTH';
export const FLAT_MID_WIDTH = 'FLAT_MID_WIDTH';
export const MODAL_WIDTH = 'MODAL_WIDTH';

export const NAME_FIELD = 'NAME_FIELD';
export const OPENABLE_NAME_FIELD = 'OPENABLE_NAME_FIELD';
export const DESCRIPTION_FIELD = 'DESCRIPTION_FIELD';
export const FILETYPE_FIELD = 'FILETYPE_FIELD';
export const CREATED_AT_FIELD = 'CREATED_AT_FIELD';
export const ASSET_DOWNLOAD_FIELD = 'ASSET_DOWNLOAD_FIELD';

export const TOOLBAR_SELECT_ALL = 'SELECT_ALL';
export const TOOLBAR_DELETE = 'DELETE';
export const TOOLBAR_SEARCH = 'SEARCH';
export const TOOLBAR_FILETYPE_DROPDOWN = 'FILETYPE_DROPDOWN';
export const TOOLBAR_GAP = 'GAP';
export const TOOLBAR_UPLOAD = 'UPLOAD';
