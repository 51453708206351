import type { LanguageType } from 'bundles/cml';

class StarterCode {
  declare expression: string;

  declare language: LanguageType;

  constructor({ expression = '', language = 'javascript' }: StarterCodeType) {
    this.expression = expression;
    this.language = language;
  }

  toJSON() {
    const { expression, language } = this;
    return { expression, language };
  }
}

export type StarterCodeType = Omit<StarterCode, 'toJSON'>;

export default StarterCode;
