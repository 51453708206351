import * as React from 'react';

import styled from '@emotion/styled';

type Props = {
  size: 4 | 8 | 12 | 16 | 24 | 32 | 48 | 64 | 80 | 112;
} & React.HTMLAttributes<HTMLElement>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SpacerBase: React.FC<Props> = ({ size, ...props }) => <div {...props} />;

/**
 * @deprecated enterprise-ui use css gap instead
 */
export const Spacer = styled(SpacerBase)`
  min-width: ${(props) => props.size / 16}rem;
  min-height: ${(props) => props.size / 16}rem;
`;
Spacer.displayName = 'Spacer';
