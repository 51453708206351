import * as React from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { useCourseEnrollModalData } from 'bundles/enroll/data/usePageData';
import { freeTrial } from 'bundles/payments/common/constants';
import { getS12nDisplayName } from 'bundles/s12n-common/lib/s12nProductVariantUtils';

import _t from 'i18n!nls/enroll';

import 'css!./__styles__/SubscriptionVPropCDPFreeTrial';

const SubscriptionVPropCDPFreeTrial: React.FC<{}> = () => {
  const { s12n, course } = useCourseEnrollModalData();

  if (!s12n) {
    return null;
  }

  return (
    <div className="rc-SubscriptionVPropCDPFreeTrial">
      <p>
        <FormattedMessage
          className="headline-1-text"
          message={_t(
            '{courseName} is part of the larger {productDisplayName}. Your {numDays}-day free trial includes:'
          )}
          courseName={course.name}
          numDays={freeTrial.numDays}
          productDisplayName={getS12nDisplayName(s12n.name, s12n.productVariant)}
        />
      </p>
    </div>
  );
};

export default SubscriptionVPropCDPFreeTrial;
