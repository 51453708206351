import * as React from 'react';

import { CenterBox, GradientWrapper, color, gradient } from '@coursera/coursera-ui';
import type { Svg } from '@coursera/coursera-ui/svg';
import { SvgClock } from '@coursera/coursera-ui/svg';

const DEFAULT_ICON_SIZE = 40;

type Props = {
  bgGradient?: { start: string; end: string; deg: number };
  icon?: Svg;
  title?: string;
  size?: number;
};

export default function GradientIcon({
  bgGradient = gradient.peach,
  icon: Icon = SvgClock,
  title,
  size = DEFAULT_ICON_SIZE,
}: Props): JSX.Element {
  return (
    <GradientWrapper gradient={bgGradient} style={{ borderRadius: '50%' }}>
      <CenterBox style={{ width: size, height: size }}>
        <Icon size={Math.round(size / 2)} color={color.white} title={title} aria-hidden="true" />
      </CenterBox>
    </GradientWrapper>
  );
}
