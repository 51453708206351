import { createContext, useContext } from 'react';

import type { NotificationMessage } from 'bundles/cml/editor/types/notification';

export type NotificationContextType = {
  setNotification: (message?: NotificationMessage) => void;
};

export const NotificationContext = createContext<NotificationContextType>({
  setNotification: () => undefined,
});

export const useNotificationContext = () => useContext(NotificationContext);
