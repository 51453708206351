import type CoursesV1 from 'bundles/naptimejs/resources/courses.v1';
import type OnDemandSpecializationsV1 from 'bundles/naptimejs/resources/onDemandSpecializations.v1';

type CourseId = {
  courseId: string;
};

type S12nId = {
  s12nId: string;
};

/**
 * object with either `courseId` or `s12nId` required (not both)
 */
export type ProductId = CourseId | S12nId;

export function hasCourseId(productId: ProductId): productId is CourseId {
  return 'courseId' in productId;
}

export function hass12nId(productId: ProductId): productId is S12nId {
  return 's12nId' in productId;
}

type CourseProduct = {
  course: CoursesV1;
};

type S12nProduct = {
  s12n: OnDemandSpecializationsV1;
};

/**
 * object with either `course` or `s12n` required (not both)
 */
export type Product = CourseProduct | S12nProduct;

export function hasCourse(product: Product): product is CourseProduct {
  return 'course' in product;
}

export function hasSpecialization(product: Product): product is S12nProduct {
  return 's12n' in product;
}
