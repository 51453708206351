/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { isEmpty } from 'lodash';

import { Autocomplete, Button, Grid, Hidden, Typography2, breakpoints } from '@coursera/cds-core';
import type { ButtonProps } from '@coursera/cds-core';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { org_coursera_skill_GoalType as GoalType } from 'bundles/program-home/components/enterprise-home/__generated__/globalTypes';
import type {
  PropsFromSkillSetFiltersHOC,
  SelectOption,
} from 'bundles/program-home/components/single-program/SkillSetFiltersHOC';
import LiteracyIcon from 'bundles/program-home/components/single-program/svg/LiteracyIcon';
import ReskillIcon from 'bundles/program-home/components/single-program/svg/ReskillIcon';
import UpskillIcon from 'bundles/program-home/components/single-program/svg/UpskillIcon';

import _t from 'i18n!nls/program-home';

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps>(Button);

type PropsFromParent = {
  filterIdPrefix: string;
  showSkillSetRoleFilter: boolean;
  loading?: boolean;
};

export type Props = PropsFromParent & Omit<PropsFromSkillSetFiltersHOC, 'isFilterSelected'>;

const styles = {
  container: css`
    margin-bottom: var(--cds-spacing-300);
  `,
  heading: css`
    margin: var(--cds-spacing-300) 0 var(--cds-spacing-150) 0;
    text-transform: uppercase;
  `,
  filterControlsContainer: css`
    gap: var(--cds-spacing-200);
    ${breakpoints.down('xs')} {
      gap: var(--cds-spacing-100);
    }
  `,
  selectLabel: css`
    margin-bottom: var(--cds-spacing-100);
  `,
  resetButtonContainer: css`
    align-self: flex-end;
  `,
};

export const getGoalLabel = (type: GoalType) => {
  switch (type) {
    case GoalType.LITERACY:
      return _t('Develop foundational awareness in an area of interest');
    case GoalType.UPSKILL:
      return _t('Focus on specific skills to develop skills mastery and advance in your role');
    case GoalType.RESKILL:
      return _t('Learn a broad range of skills to start a new career and grow professionally');
    default:
      return '';
  }
};

export const getGoalIcon = (type: GoalType) => {
  switch (type) {
    case GoalType.LITERACY:
      return <LiteracyIcon />;
    case GoalType.UPSKILL:
      return <UpskillIcon />;
    case GoalType.RESKILL:
      return <ReskillIcon />;
    default:
      return '';
  }
};

const optionLocaleCompare = (a: SelectOption, b: SelectOption): number => a.label.localeCompare(b.label);

export const SkillSetFiltersContainer: React.FC<Props> = ({
  filterOptions,
  allOccupations,
  skillNames,
  filterSelections,
  onRoleChange,
  onSkillsChange,
  onGoalChange,
  onFilterReset,
  onFilterInputChange,
  showSkillSetRoleFilter,
  filterIdPrefix,
  loading,
}) => {
  const isLoading =
    loading ||
    !filterOptions ||
    !allOccupations ||
    isEmpty(allOccupations) ||
    !skillNames ||
    isEmpty(skillNames) ||
    !filterSelections;

  const roleFilterOptions = [...(filterOptions?.roles ?? [])]
    .map((role) => ({
      value: role,
      label: allOccupations?.[role] || '',
    }))
    .filter(({ label }) => !!label)
    .sort(optionLocaleCompare);
  const skillsFilterOptions = [...(filterOptions?.skills ?? [])]
    .map((skill) => ({
      value: skill,
      label: skillNames?.[skill] || '',
    }))
    .filter(({ label }) => !!label)
    .sort(optionLocaleCompare);
  const goalFilterOptions = [...(filterOptions?.goals ?? [])]
    .map((goal) => ({
      value: goal,
      label: getGoalLabel(goal),
    }))
    .filter(({ label }) => !!label)
    .sort(optionLocaleCompare);

  const onInputChange = (e: string, type: 'role' | 'skill' | 'goal') => {
    onFilterInputChange?.(e, type);
  };

  const skillsFilterId = filterIdPrefix + 'skillsFilter';
  const rolesFilterId = filterIdPrefix + 'roleFilter';
  const goalFilterId = filterIdPrefix + 'goalFilter';
  return (
    <TrackedDiv
      role="group"
      aria-label={_t('SkillSet Filters')}
      trackingName="skillset_filters"
      data-e2e="program-home-skillset-filters-container"
      withVisibilityTracking={true}
      trackClicks={false}
      css={styles.container}
      className="rc-SkillSetFiltersContainer"
    >
      <Typography2 variant="subtitleMedium" component="div" color="supportText" css={styles.heading}>
        {_t('Filter SkillSets by:')}
      </Typography2>
      <Grid container direction="row" justifyContent="space-between" css={styles.filterControlsContainer}>
        <Grid container item xs={12} md={11} direction="row" justifyContent="space-between" spacing={16}>
          <Grid container item xs={12} md={showSkillSetRoleFilter ? 4 : 6} direction="column">
            <Typography2 htmlFor={skillsFilterId} variant="subtitleMedium" component="label" css={styles.selectLabel}>
              {_t('Skills:')}
            </Typography2>
            <Autocomplete
              id={skillsFilterId}
              aria-label={_t('Skills')}
              placeholder={_t('Select a skill...')}
              popoverTrigger="focus"
              renderNotFoundMessage={() => (
                <Typography2 variant="bodySecondary" color="supportText" component="div">
                  {_t('No skills found')}
                </Typography2>
              )}
              onInputChange={(e: string) => onInputChange(e, 'skill')}
              selectedKey={filterSelections?.skills}
              onSelectionChange={(key) => onSkillsChange?.(key?.toString())}
              loading={isLoading}
            >
              {skillsFilterOptions.map(({ value, label }) => (
                <Autocomplete.Option key={value}>{label}</Autocomplete.Option>
              ))}
            </Autocomplete>
          </Grid>
          {showSkillSetRoleFilter && (
            <Grid container item xs={12} md={4} direction="column">
              <Typography2 htmlFor={rolesFilterId} variant="subtitleMedium" component="label" css={styles.selectLabel}>
                {_t('Role:')}
              </Typography2>
              <Autocomplete
                id={rolesFilterId}
                aria-label={_t('Role')}
                placeholder={_t('Select a role...')}
                popoverTrigger="focus"
                renderNotFoundMessage={() => (
                  <Typography2 variant="bodySecondary" color="supportText" component="div">
                    {_t('No roles found')}
                  </Typography2>
                )}
                onInputChange={(e: string) => onInputChange(e, 'role')}
                onSelectionChange={(key) => onRoleChange?.(key?.toString())}
                selectedKey={filterSelections?.role}
                loading={isLoading}
                data-testId="skillset-role-select"
              >
                {roleFilterOptions.map(({ value, label }) => (
                  <Autocomplete.Option key={value} data-testid="skillset-role-option">
                    {label}
                  </Autocomplete.Option>
                ))}
              </Autocomplete>
            </Grid>
          )}
          <Grid container item xs={12} md={showSkillSetRoleFilter ? 4 : 6} direction="column">
            <Typography2 htmlFor={goalFilterId} variant="subtitleMedium" component="label" css={styles.selectLabel}>
              {_t('Goal:')}
            </Typography2>
            <Autocomplete
              loading={isLoading}
              id={goalFilterId}
              aria-label={_t('Goal')}
              placeholder={_t('Select a goal...')}
              popoverTrigger="focus"
              renderNotFoundMessage={() => (
                <Typography2 variant="bodySecondary" color="supportText" component="div">
                  {_t('No goals found')}
                </Typography2>
              )}
              onInputChange={(e: string) => onInputChange(e, 'goal')}
              onSelectionChange={(key) => onGoalChange?.(key?.toString())}
              selectedKey={filterSelections?.goal}
            >
              {goalFilterOptions.map(({ value, label }) => (
                <Autocomplete.Option prefix={getGoalIcon(value as GoalType)} key={value}>
                  {label}
                </Autocomplete.Option>
              ))}
            </Autocomplete>
          </Grid>
        </Grid>
        <Grid item xs={12} md={1} css={styles.resetButtonContainer}>
          <Hidden xsDown>
            <TrackedButton
              {...Button.defaultProps}
              trackingName="skillset_filters_reset_button"
              variant="ghost"
              onClick={onFilterReset}
              disabled={loading}
              fullWidth
            >
              {_t('Reset')}
            </TrackedButton>
          </Hidden>
          <Hidden smUp>
            <TrackedButton
              {...Button.defaultProps}
              trackingName="skillset_filters_reset_button"
              variant="secondary"
              size="small"
              onClick={onFilterReset}
              disabled={loading}
              fullWidth
            >
              {_t('Reset Filters')}
            </TrackedButton>
          </Hidden>
        </Grid>
      </Grid>
    </TrackedDiv>
  );
};
