import * as React from 'react';

import { Button } from '@coursera/cds-core';

import _t from 'i18n!nls/asset-admin';

type AssetLibraryButtonProps = {
  onAssetLibraryClick: () => void;
};

export const AssetLibraryButton: React.SFC<AssetLibraryButtonProps> = (props) => {
  const { onAssetLibraryClick } = props;
  return (
    <Button
      className="asset-library-button"
      variant="secondary"
      size="small"
      onClick={onAssetLibraryClick}
      data-testid="asset-library-button"
    >
      {_t('Find in asset library')}
    </Button>
  );
};
