import { createContext, useContext } from 'react';

export type FocusContextType = {
  focused: boolean;
  setFocused: (focused: boolean) => void;
};

export const FocusContext = createContext<FocusContextType>({
  focused: false,
  setFocused: () => undefined,
});

export const useFocusedContext = () => useContext(FocusContext);
