import * as React from 'react';

import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';
import type { AiCoach_ChatSession } from '__generated__/graphql-types';

import { get as getUser } from 'js/lib/user';

import type {
  AiCoachPlatform_ChatSessionsByUserQuery as ChatSessionsByUserQuery,
  AiCoachPlatform_ChatSessionsByUserQueryVariables as ChatSessionsByUserQueryVariables,
} from 'bundles/ai-coach-platform/queries/__generated__/aiCoachChatSessionsByUser';
import aiCoachChatSessionsByUserQuery from 'bundles/ai-coach-platform/queries/aiCoachChatSessionsByUser.graphql';
import { captureMessageInSentry } from 'bundles/ai-coach-platform/utils/sentry';

const useCoachSessions = ({
  settingsEnabled,
}: {
  settingsEnabled: boolean;
}): {
  loading: boolean;
  error?: ApolloError;
  sessions: Array<AiCoach_ChatSession>;
} => {
  const {
    data: queryData,
    loading,
    error,
  } = useQuery<ChatSessionsByUserQuery, ChatSessionsByUserQueryVariables>(aiCoachChatSessionsByUserQuery, {
    variables: {
      userId: (getUser().id || '').toString(),
    },
    context: { clientName: 'gatewayGql' },
    fetchPolicy: 'network-only',
    skip: !settingsEnabled,
  });

  const sessions = queryData?.AiCoach?.queryChatSessionsByUserId.elements as AiCoach_ChatSession[];

  if (error) {
    captureMessageInSentry({ error, source: 'queryChatSessionsByUserId' });
  }

  return { sessions, loading, error };
};

export default useCoachSessions;
