import * as React from 'react';

import hoistNonReactStatics from 'js/lib/hoistNonReactStatics';

import Naptime from 'bundles/naptimejs';
import ThirdPartyOrganizationsV1 from 'bundles/naptimejs/resources/thirdPartyOrganizations.v1';

type PropsFromCaller = {
  provider: string;
  showFooter?: boolean;
  signupMode?: boolean;
  className?: string;
};

type PropsFromNaptime = {
  organizations?: {
    id: string;
    name: string;
  }[];
};

type PropsToWrappedComponent = PropsFromNaptime & PropsFromCaller;

const withThirdPartyOrgNameData =
  () =>
  <T extends Record<string, unknown>>(BaseComponent: React.ComponentType<T>) => {
    const componentName = BaseComponent.displayName || BaseComponent.name;

    class HOC extends React.Component<T & PropsToWrappedComponent> {
      static displayName = `withThirdPartyOrgNameData(${componentName})`;

      render() {
        return <BaseComponent {...this.props} />;
      }
    }

    hoistNonReactStatics(HOC, BaseComponent);

    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    return Naptime.createContainer<T & PropsToWrappedComponent, T & PropsFromCaller, PropsFromNaptime>(
      HOC,
      ({ provider }) => ({
        organizations:
          provider &&
          ThirdPartyOrganizationsV1.finder('bySlug', {
            params: {
              slug: provider,
            },
            fields: ['name'],
          }),
      })
    );
  };
export default withThirdPartyOrgNameData;
