import * as React from 'react';

import type { IconSize } from 'bundles/ai-coach-platform/types';

/**
 * MUI icon for "Close" chat action
 */
const CloseIcon = ({ size = 16 }: { size?: IconSize }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.02978 9.23752L3.03162 14.2357C2.85541 14.4119 2.64715 14.5 2.40686 14.5C2.16656 14.5 1.9583 14.4039 1.78209 14.2116C1.60587 14.0354 1.52177 13.8352 1.52978 13.6109C1.53779 13.3866 1.6299 13.1784 1.80612 12.9861L6.78024 8.01201L1.78209 2.98983C1.60587 2.81362 1.51776 2.61337 1.51776 2.38909C1.51776 2.16482 1.60587 1.95656 1.78209 1.76433C1.9583 1.58811 2.16656 1.5 2.40686 1.5C2.64715 1.5 2.85541 1.58811 3.03162 1.76433L8.02978 6.76248L12.9799 1.76433C13.1561 1.58811 13.3643 1.5 13.6046 1.5C13.8449 1.5 14.0532 1.58811 14.2294 1.76433C14.4056 1.95656 14.4897 2.16482 14.4817 2.38909C14.4737 2.61337 14.3896 2.81362 14.2294 2.98983L9.25528 8.01201L14.2294 12.9861C14.3896 13.1624 14.4737 13.3666 14.4817 13.5989C14.4897 13.8312 14.4056 14.0354 14.2294 14.2116C14.0532 14.4039 13.8449 14.5 13.6046 14.5C13.3643 14.5 13.1561 14.4119 12.9799 14.2357L8.02978 9.23752Z"
      fill="var(--cds-color-grey-950)"
    />
  </svg>
);

export default CloseIcon;
