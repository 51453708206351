import { useNaptime } from 'bundles/naptimejs';
import PaymentWalletsV1 from 'bundles/naptimejs/resources/paymentWallets.v1';
import type ValidPaymentProcessorsV1 from 'bundles/naptimejs/resources/validPaymentProcessors.v1';
import PaymentProcessorTypes from 'bundles/payments/common/PaymentProcessorTypes';

type PropsFromNaptime = {
  wallets: Array<PaymentWalletsV1>;
};

export type PropsFromUseWallets = {
  braintreeWallets: Array<PaymentWalletsV1>;
  paypalWallets: Array<PaymentWalletsV1>;
};

export const useWallets = (
  validPaymentProcessors?: ValidPaymentProcessorsV1,
  skipValidPaymentProcessorCheck?: boolean
) => {
  const {
    data: { wallets },
  } = useNaptime<PropsFromNaptime>(() => {
    const isInvalidPaymentProcessorConfig =
      !validPaymentProcessors || validPaymentProcessors?.isRazorpayEnabled || validPaymentProcessors?.isStripeEnabled;

    // On checkout, we require checking validPaymentProcesors to see if wallets should be fetched
    // In other contexts, like the C+ upgrade modal, we won't check validPaymentProcessors and instead always show wallets
    if (!skipValidPaymentProcessorCheck && isInvalidPaymentProcessorConfig) {
      return {};
    }

    return {
      wallets: PaymentWalletsV1.me([
        'billingCountry',
        'billingZipcode',
        'creditCardExpiration',
        'creditCardLastFourDigits',
        'creditCardType',
        'isValid',
        'email',
        'paymentProcessorId',
      ]),
    };
    // only trigger once when validPaymentProcessors is loaded (use id as a proxy)
  }, [validPaymentProcessors?.id]);

  if (!wallets) {
    return {
      braintreeWallets: [],
      paypalWallets: [],
    };
  }

  return {
    braintreeWallets: wallets.filter(
      (wallet) => (wallet.isValid && wallet.paymentProcessorId === PaymentProcessorTypes.braintree) ?? false
    ),
    paypalWallets: wallets.filter(
      (wallet) => (wallet.isValid && wallet.paymentProcessorId === PaymentProcessorTypes.paypal) ?? false
    ),
  };
};
