/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Grid, Typography } from '@coursera/cds-core';

import ProductEnrolledCard from 'bundles/course-cards/components/course-card/enterprise/ProductEnrolledCard';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import type ProgramCurriculumProductsV1 from 'bundles/naptimejs/resources/programCurriculumProducts.v1';
import type ThirdPartyOrganizationsV1 from 'bundles/naptimejs/resources/thirdPartyOrganizations.v1';
import NavigatorSelector from 'bundles/page-config-common/components/shared/NavigatorSelector';

import _t from 'i18n!nls/program-home';

type Props = {
  programId: string | undefined;
  thirdPartyOrganization: ThirdPartyOrganizationsV1;
  programSlug: string;
  userId: number;
  inProgressCourse: ProgramCurriculumProductsV1 | undefined;
};

const styles = {
  title: css`
    padding-bottom: var(--cds-spacing-200);
  `,
  container: css`
    margin-top: -35px;
    margin-bottom: -35px;
  `,
};

export const EnterpriseContinueLearningModule = ({
  programId,
  thirdPartyOrganization,
  programSlug,
  userId,
  inProgressCourse,
}: Props) => {
  const baseUrl = `/programs/${programSlug}?currentTab=MY_LEARNING`;

  if (!programId || !inProgressCourse) {
    return null;
  }

  return (
    <Grid container css={styles.container}>
      <Grid item xs={12}>
        <Typography css={styles.title} variant="h2semibold">
          {_t('Continue learning')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={32}>
          <Grid item xs={12} md={10}>
            <ProductEnrolledCard
              userId={userId}
              programId={programId}
              product={inProgressCourse}
              thirdPartyOrganizationId={thirdPartyOrganization.id}
              isGrouped
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <NavigatorSelector
              links={[
                { label: _t('In Progress'), url: `${baseUrl}&myLearningTab=IN_PROGRESS`, trackingName: 'in_progress' },
                { label: _t('Saved'), url: `${baseUrl}&myLearningTab=SAVED`, trackingName: 'saved' },
                { label: _t('Completed'), url: `${baseUrl}&myLearningTab=COMPLETED`, trackingName: 'completed' },
                { label: _t('Skills'), url: `${baseUrl}&myLearningTab=SKILLS_DASHBOARD`, trackingName: 'skills' },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EnterpriseContinueLearningModule;
