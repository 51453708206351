import * as React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';

import CourseCard from 'bundles/course-cards/components/course-card/enterprise/CourseCard';

import 'css!./__styles__/MultiCourseProductCourseCards';

import type { CircleMenuItem } from './MultiCourseProductCirclesMenu';

type Props = {
  circleMenuData: CircleMenuItem[];
  programId: string;
  userId: number;
};

const MultiCourseProductCourseCards: React.SFC<Props> = ({ circleMenuData = [], programId }) => {
  return (
    <div className="rc-MultiCourseProductCourseCards">
      {/* TODO(ppaskaris): Remove these transitions */}
      <CSSTransitionGroup
        component="div"
        transitionName="card"
        transitionAppear={true}
        transitionAppearTimeout={1000}
        transitionEnterTimeout={1000}
        transitionLeaveTimeout={200}
      >
        {circleMenuData.map(({ courseId, isEnrolled, isActive, canUnenroll }) => {
          return isActive ? (
            <CourseCard
              courseId={courseId}
              programId={programId}
              key={courseId}
              isEnrolled={isEnrolled}
              isPartOfS12n={true}
              canUnenroll={canUnenroll}
            />
          ) : null;
        })}
      </CSSTransitionGroup>
    </div>
  );
};

export default MultiCourseProductCourseCards;
