export const COURSE = 'COURSE';
export const RHYME_PROJECT = 'RHYME PROJECT';
export const GUIDED_PROJECT = 'GUIDED PROJECT';

export const SELF_PACED_PROJECT = 'PROJECT';
export const SPECIALIZATION = 'Specialization';
export const DEGREE = 'DEGREE';
export const SPECIALIZATION_ENTITY_TYPE = 'SPECIALIZATION';
export const MASTERTRACK = 'MASTERTRACK';
export const UNIVERSITY_CERTIFICATE = 'UNIVERSITY CERTIFICATE';
export const GRADUATE_CERTIFICATE = 'GRADUATE CERTIFICATE';
export const PROFESSIONAL_CERTIFICATE = 'PROFESSIONAL CERTIFICATE';
export const POSTGRADUATE_DIPLOMA = 'POSTGRADUATE DIPLOMA';

export const XDP_PRODUCT_TYPES = [
  COURSE,
  SELF_PACED_PROJECT,
  SPECIALIZATION_ENTITY_TYPE,
  'PROFESSIONAL_CERTIFICATE',
  'GUIDED_PROJECT',
];
