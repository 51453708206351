import type { ProfileInfoField } from 'bundles/ai-coach-platform/components/patterns/chat/settings/types';

import _t from 'i18n!nls/ai-coach-platform';

export const getProfileFieldName = (value: ProfileInfoField) => {
  switch (value) {
    case 'educationHistory':
      return _t('Education');

    case 'location':
      return _t('Location');

    case 'workHistory':
      return _t('Work history');

    default:
      return '';
  }
};

// in the same order we want to display in the UI (alphabetical currently)
export const getProfileFields = (): ProfileInfoField[] => {
  return ['educationHistory', 'location', 'workHistory'];
};
