import type { InputActions, SetUserInputText } from '../actions/types';
import { SET_USER_INPUT_TEXT } from '../actions/types';
import type { InputState } from '../types';
import { createReducer } from '../utils/createReducer';

const initialState = {
  inputText: '',
};

const inputReducer = {
  [SET_USER_INPUT_TEXT]: (state: InputState, action: SetUserInputText) => ({
    ...state,
    inputText: action.inputText,
  }),
};

export default (state: InputState = initialState, action: InputActions) => createReducer(inputReducer, state, action);
