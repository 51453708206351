/* eslint-disable camelcase */
import * as React from 'react';

import { compose } from 'recompose';

import logger from 'js/app/loggerSingleton';
import user from 'js/lib/user';

import BookmarkWithSaving from 'bundles/browse/components/Bookmark';
import type { GraphQLErrorObject, SavingBookmarkProps as Props } from 'bundles/browse/components/types/SavingBookmark';
import withCourseraPlusProductOwnerships from 'bundles/coursera-plus/utils/withCourseraPlusProductOwnerships';
import type { PropsFromCourseraPlusProductOwnerships } from 'bundles/coursera-plus/utils/withCourseraPlusProductOwnerships';

import 'css!./__styles__/SavingBookmark';

type PropsToComponent = Props & PropsFromCourseraPlusProductOwnerships;

type State = {
  shouldQuery: boolean;
  saved: boolean;
  hidingViaCSS: boolean;
  hidingFromLayout: boolean;
};
export class Bookmark extends React.Component<PropsToComponent, State> {
  timer: ReturnType<typeof setTimeout> = setTimeout(() => '', 0);

  constructor(props: Props) {
    super(props);
    const { saved } = props;

    this.state = {
      hidingFromLayout: true,
      shouldQuery: false,
      saved: saved || false,
      hidingViaCSS: true,
    };
  }

  clearTimer = () => clearTimeout(this.timer);

  hideNotification = () =>
    this.setState(
      () => ({ hidingViaCSS: true }),
      () =>
        setTimeout(() => {
          this.setState(() => ({ hidingFromLayout: true }));
        }, 1000)
    );

  showNotification = () => {
    clearTimeout(this.timer);
    this.setState(() => ({ hidingViaCSS: false, hidingFromLayout: false }));

    this.timer = setTimeout(() => {
      this.hideNotification();
    }, 3500);
  };

  handleOnClick = (event: React.MouseEvent | React.KeyboardEvent) => {
    event.preventDefault();
    event.stopPropagation();

    this.showNotification();

    this.setState((prevState) => ({ saved: !prevState.saved, shouldQuery: true }));
  };

  onComplete = () => this.setState({ shouldQuery: false });

  onError = (error: GraphQLErrorObject) => {
    const { graphQLErrors, networkError } = error;

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        logger.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );
    }

    if (networkError) logger.error(`[Network error]: ${networkError}`);

    this.setState((prevState) => ({ shouldQuery: false, saved: !prevState.saved }));
  };

  render() {
    const { product, ownsCourseraPlus } = this.props;
    const { saved, hidingViaCSS, hidingFromLayout, shouldQuery } = this.state;
    const { product_id, product_type } = product || {};

    // only display on cards where usable information is given
    if (!product_id || !product_type) return null;
    if (!(user.isAuthenticatedUser() && ownsCourseraPlus)) {
      return null;
    }

    return (
      <BookmarkWithSaving
        {...this.props}
        saved={saved}
        hidingViaCSS={hidingViaCSS}
        hidingFromLayout={hidingFromLayout}
        onClick={this.handleOnClick}
        onComplete={this.onComplete}
        onError={this.onError}
        shouldQuery={shouldQuery}
        clearTimer={this.clearTimer}
        hideNotification={this.hideNotification}
      />
    );
  }
}

export default compose<PropsToComponent, Props>(withCourseraPlusProductOwnerships())(Bookmark);
