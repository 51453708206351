import type { Editor, Node, NodeEntry } from 'slate';
import { Element, Transforms } from 'slate';

import { VOID_TYPES_TO_TOOLS } from 'bundles/cml/editor/normalize/constants';
import type { Options } from 'bundles/cml/editor/normalize/types';

export const normalizeVoids = (editor: Editor, { tools }: Options, [node, path]: NodeEntry<Node>) => {
  if (!Element.isElement(node) || !node.isVoid) {
    return false;
  }

  const tool = VOID_TYPES_TO_TOOLS[node.type];
  if (!tool || tools.has(tool)) {
    return false;
  }

  Transforms.removeNodes(editor, { at: path });
  return true;
};
