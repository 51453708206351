export function toProgramSkillSet(programSlug: string, objectiveSlug: string): string {
  return `/programs/${programSlug}/skillsets/${objectiveSlug}`;
}

export function toProgramHome(programSlug: string): string {
  return `/programs/${programSlug}`;
}

export function toProgramHomeTab(programSlug: string, tabName: string): string {
  return `/programs/${programSlug}?currentTab=${tabName}`;
}

export function toOrgHome(orgSlug: string): string {
  return `/programs/all/${orgSlug}`;
}

export function toOrgHomeTab(orgSlug: string, tabName: string): string {
  return `/programs/all/${orgSlug}?currentTab=${tabName}`;
}
