import type { FeedbackComments } from 'bundles/authoring/course-content-feedback/types/courseContentFeedback';
import { CMLUtils } from 'bundles/cml';
import type { CmlContent } from 'bundles/cml';

import Feedback from './Feedback';

type RatingFeedbackProps = {
  value: number;
  active: boolean;
  comment?: CmlContent;
  timestamp?: number;
  id?: string;
};

class RatingFeedback extends Feedback {
  constructor(props: RatingFeedbackProps) {
    const { value, active, comment, timestamp, id } = props;
    const maxValue = 5;

    const feedback = {
      rating: { value, maxValue, active },
      comments: comment && !CMLUtils.isEmpty(comment) ? ({ generic: comment } as FeedbackComments) : {},
      timestamp,
      id,
    };

    super(feedback);
  }

  get comment() {
    return this.comments && this.comments.generic;
  }

  hasComment() {
    return this.comment && !CMLUtils.isEmpty(this.comment);
  }
}

export default RatingFeedback;
