import { graphql } from 'react-apollo';

import waitForGraphQL from 'js/lib/waitForGraphQL';

import ScopedContentRulesQuery from 'bundles/enroll/utils/ScopedContentRulesQuery.graphql';
import type { ScopesKeys } from 'bundles/enroll/utils/scopedContentRule';
import { enrollmentProductType } from 'bundles/enroll/utils/scopedContentRule';
import type {
  ScopedContentRule as ScopedContentRulesData,
  ScopedContentRulesQueryData,
  ScopedContentRulesQueryVariables,
} from 'bundles/enroll/utils/scopedContentRulesQuery';

type InputProps = {
  data?: {
    projectId: string;
  };
  s12nId?: string | null;
  projectId?: string;
  courseId?: string;
};

export type PropsFromWithScopedContentRule = {
  scopedContentRule?: ScopedContentRulesData | null;
};

export const getProductFromId = ({ s12nId, courseId, projectId }: InputProps) => {
  const isOnlyS12n = s12nId && !courseId && !projectId;
  const productType = isOnlyS12n ? enrollmentProductType.SPECIALIZATION : enrollmentProductType.VERIFIED_CERTIFICATE;
  const nonS12nId = courseId ?? projectId;
  const productId = isOnlyS12n ? s12nId : nonS12nId;

  return { productType, productItemId: productId as string };
};

const withScopedContentRule = <TInputProps extends InputProps>(scope: ScopesKeys, isNotRequired?: boolean) => {
  const graphqlHoc = !isNotRequired ? waitForGraphQL : graphql;

  return graphqlHoc<
    TInputProps,
    ScopedContentRulesQueryData,
    ScopedContentRulesQueryVariables,
    PropsFromWithScopedContentRule
  >(ScopedContentRulesQuery, {
    skip: ({ courseId, projectId, s12nId, data }) => {
      return !courseId && !s12nId && !projectId && !data?.projectId;
    },
    options: ({ courseId, projectId, s12nId, data }) => {
      const { productType, productItemId } = getProductFromId({
        courseId,
        s12nId,
        projectId: projectId ?? data?.projectId,
      });
      return {
        variables: {
          input: {
            scope,
            productType,
            productItemId,
          },
        },
        context: { clientName: 'gatewayGql' },
      };
    },
    props: ({ data }) => {
      const scopedContentRule = !data?.error ? data?.ScopedContentRule?.getRule?.rule : undefined;
      return { scopedContentRule };
    },
  });
};

export default withScopedContentRule;
