import * as React from 'react';

import type { XdpV1_Org_Coursera_Xdp_Common_XdpModuleItem as XDPModuleItem } from '__generated__/graphql-types';

import ItemGroupView, { ITEM_GROUP_TYPES } from 'bundles/enterprise-legacy-xdp/components/cdp/syllabus/ItemGroupView';

type Items = Array<XDPModuleItem>;

type Props = {
  slug: string;
  videoItems: Items;
  readingItems: Items;
  practiceExerciseItems: Items;
};

export default function SyllabusModuleDetails({ slug, videoItems, readingItems, practiceExerciseItems }: Props) {
  return (
    <div className="SyllabusModuleDetails">
      {videoItems.length > 0 && <ItemGroupView items={videoItems} type={ITEM_GROUP_TYPES.LECTURE} slug={slug} />}
      {readingItems.length > 0 && <ItemGroupView items={readingItems} type={ITEM_GROUP_TYPES.SUPPLEMENT} slug={slug} />}
      {practiceExerciseItems.length > 0 && (
        <ItemGroupView items={practiceExerciseItems} type={ITEM_GROUP_TYPES.FORMATIVE_QUIZ} slug={slug} />
      )}
    </div>
  );
}
