import * as React from 'react';
import { useContext } from 'react';

import type { ThirdPartyOrganizationsV1 } from 'bundles/naptimejs/resources/__generated__/ThirdPartyOrganizationsV1';

const EnterpriseThirdPartyOrganizationContext = React.createContext<ThirdPartyOrganizationsV1 | undefined>(undefined);

export const useThirdPartyOrganization = () => {
  const thirdPartyOrganization = useContext(EnterpriseThirdPartyOrganizationContext);

  return thirdPartyOrganization;
};

export default EnterpriseThirdPartyOrganizationContext;
