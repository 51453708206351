import type { Delims } from 'bundles/cml/editor/components/elements/math/types';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';

// Match text inside $$ $$ or \( \)
export const LATEX_INLINE_REGEXP = /^(\$\$|\\\()((.|\n|\r)*?)(\$\$|\\\)$)/m;

// Match text inside \[ \]
export const LATEX_BLOCK_REGEXP = /^(\\\[)((.|\n|\r)*?)(\\\]$)/m;

export const BLOCK_DELIMS: Delims = ['\\[', '\\]'];
export const INLINE_DELIMS: Delims = ['$$', '$$'];

export const MATH_TYPES = {
  MATH_INLINE: BLOCK_TYPES.MATH_INLINE,
  MATH_BLOCK: BLOCK_TYPES.MATH_BLOCK,
};

export type MATH_TYPES_VALUES = (typeof MATH_TYPES)[keyof typeof MATH_TYPES];

export const PLACEHOLDER = String.raw`|x| = \begin{cases}
 x, &\quad x \geq 0 \\
-x, &\quad x < 0 
\end{cases}`;

export const KATEX_PARSE_ERROR = /^katex parse error:/i;
