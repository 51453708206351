import * as React from 'react';

import { color } from '@coursera/coursera-ui';
import { SvgCheck } from '@coursera/coursera-ui/svg';

import { TooltipWrapper } from 'bundles/common/components/Tooltip';

import _t from 'i18n!nls/asset-admin';

import 'css!./__styles__/DescriptionCompleteTag';

type InputProps = {
  description: string;
};

const DescriptionCompleteTag: React.FC<InputProps> = ({ description }) => {
  return (
    <div className="rc-DescriptionCompleteTag">
      <div className="flex-container">
        <TooltipWrapper message={description} placement="top">
          <div className="check-icon">
            <SvgCheck size={16} color={color.success} />
          </div>
          <span className="completed-label">{_t('Completed')}</span>
        </TooltipWrapper>
      </div>
    </div>
  );
};

export default DescriptionCompleteTag;
