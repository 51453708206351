import { useEffect, useMemo, useRef, useState } from 'react';

import { debounce, throttle } from 'lodash';

import type { Dimensions } from 'bundles/authoring/common/types/resizeTypes';

const isOverflowElement = (element: HTMLElement) => {
  // Firefox wants us to check `-x` and `-y` variations as well
  const { overflow, overflowX, overflowY } = window.getComputedStyle(element);
  return /auto|scroll|overlay|hidden/.test(overflow + overflowY + overflowX);
};

const getScrollableParent = (element: HTMLElement): HTMLElement | Window | null => {
  const parentEl = element.parentElement;
  if (!parentEl) {
    return element.ownerDocument.defaultView;
  }

  if (isOverflowElement(parentEl)) {
    return parentEl;
  }

  return getScrollableParent(parentEl);
};

type Props = {
  resizableEl: HTMLElement | null;
  resizable: boolean;
  onResize?: (dimensions: Dimensions) => void;
  onResizeEnd?: (dimensions: Dimensions) => void;
};

export const useAutoScrollOnResize = ({
  resizableEl,
  resizable,
  onResize = () => undefined,
  onResizeEnd = () => undefined,
}: Props) => {
  const dimensions = useRef({ height: 0, width: 0 });
  const scrollParent = useRef<HTMLElement | Window | null>(null);
  const [resizing, setResizing] = useState(false);

  const handleResize = useMemo(
    () =>
      throttle(({ contentRect: { height, width }, target }: ResizeObserverEntry) => {
        const prevHeight = dimensions.current.height;
        dimensions.current = { height, width };
        onResize({ height, width });

        const diff = height - prevHeight;
        if (!prevHeight || diff === 0) {
          return;
        }

        setResizing(true);
        const targetRect = target.getBoundingClientRect();

        if (scrollParent.current instanceof HTMLElement) {
          const parentRect = scrollParent.current.getBoundingClientRect();
          if (targetRect.bottom > parentRect.bottom || targetRect.bottom <= parentRect.top) {
            scrollParent.current?.scrollBy({ top: diff });
          }
        } else if (scrollParent.current instanceof Window) {
          if (targetRect.bottom > scrollParent.current.innerHeight || targetRect.bottom <= 0) {
            scrollParent.current?.scrollBy({ top: diff });
          }
        }
      }, 15),
    [onResize]
  );

  const handleResizeEnd = useMemo(
    () =>
      debounce(() => {
        setResizing(false);
        onResizeEnd(dimensions.current);
      }, 500),
    [onResizeEnd]
  );

  useEffect(() => {
    if (!resizableEl || !resizable) {
      return () => undefined;
    }

    scrollParent.current = getScrollableParent(resizableEl);
    const resizeObserver = new ResizeObserver(([entry]) => {
      handleResize(entry);
      handleResizeEnd();
    });
    resizeObserver.observe(resizableEl);

    return () => {
      resizeObserver.disconnect();
    };
  }, [resizableEl, handleResize, handleResizeEnd, resizable]);

  useEffect(() => {
    return () => {
      handleResize.cancel();
      handleResizeEnd.cancel();
    };
  }, [handleResize, handleResizeEnd]);

  return { resizing, dimensions: dimensions.current };
};
