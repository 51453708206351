import gql from 'graphql-tag';

export const createCartMutation = gql`
  mutation createCart($input: CourseraTierSubscriptions_CartCreateInput!) {
    CourseraTierSubscriptionsMutations {
      createCart(input: $input) {
        id
        cart {
          id
          userId
          createdAt
          lastUpdatedAt
          currencyId
          countryIsoCode
          fxRateId
        }
        currencyCode
        countryIsoCode
        computedCartFields {
          totalCartAmount
          originalTotalCartAmount
          cartItems {
            id
            cartItem {
              id
              cartId
              productType
              productItemId
              productAction
              priceId
              amount
              originalAmount
              couponId
              cartItemIdToRefund
              parentCartItemId
              metadata {
                __typename
                ... on CourseraTierSubscriptions_CourseraTierLiteCartItemMetadata {
                  paymentType
                  productEnrollmentInformation {
                    productIdToEnroll
                    courseIdToGrantMembership
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const checkoutMutation = gql`
  mutation checkout(
    $userId: ID!
    $cartId: ID!
    $paymentInformation: CourseraTierSubscriptions_SubscriptionPaymentInformationInputUnion!
  ) {
    CourseraTierSubscriptionsMutations {
      checkout(userId: $userId, cartId: $cartId, paymentInformation: $paymentInformation) {
        id
        userId
        productType
        productItemId
        paymentInformation {
          __typename
          ... on CourseraTierSubscriptions_WalletSubscriptionPaymentInformation {
            transactionId
            paymentWalletId
          }
          ... on CourseraTierSubscriptions_RazorpaySubscriptionPaymentInformation {
            razorpayPaymentId
            razorpaySubscriptionId
            razorpaySignature
            isSandbox
          }
        }
        startsAt
        nextBillingAt
        isActive
        cartId
        status
        paymentProcessorId
        productSku {
          productType
          productItemId
        }
      }
    }
  }
`;

export const midCycleUpgradeMutation = gql`
  mutation MidCycleUpgradeMutation($input: CourseraTierSubscriptions_MidCycleUpgradeInput!) {
    CourseraTierSubscriptionsMutations {
      midCycleUpgrade(input: $input) {
        cartId
        id
        isActive
        nextBillingAt
        paymentProcessorId
        productItemId
        productSku {
          productItemId
          productType
        }
        productType
        startsAt
        status
        userId
      }
    }
  }
`;

export const createMidCycleUpgradeCartMutation = gql`
  mutation CreateMidCycleUpgradeCart($input: CourseraTierSubscriptions_CreateMidCycleUpgradeCartInput!) {
    CourseraTierSubscriptionsMutations {
      createMidCycleUpgradeCart(input: $input) {
        fromTierSubscription {
          id
        }
        toTierCart {
          cart {
            countryIsoCode
            createdAt
            currencyId
            fxRateId
            id
            lastUpdatedAt
            userId
          }
          computedCartFields {
            cartItems {
              cartItem {
                amount
                cartId
                cartItemIdToRefund
                couponId
                id
                metadata {
                  ... on CourseraTierSubscriptions_CourseraTierLiteCartItemMetadata {
                    paymentType
                    productEnrollmentInformation {
                      courseIdToGrantMembership
                      productIdToEnroll
                    }
                  }
                }
                originalAmount
                parentCartItemId
                priceId
                productAction
                productItemId
                productType
              }
              id
              promotionInfo {
                discountAmount
                discountPercent
                promotionId
                promotionName
              }
            }
            originalTotalCartAmount
            totalCartAmount
          }
          countryIsoCode
          currencyCode
          id
        }
      }
    }
  }
`;
