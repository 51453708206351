import * as React from 'react';

import { compose, withProps } from 'recompose';

import { useLocation } from 'js/lib/useRouter';

import ContentfulError from 'bundles/coursera-plus-landing-page/components/ContentfulError';
import RedirectToContentfulHome from 'bundles/coursera-plus-landing-page/components/RedirectToContentfulHome';
import {
  getProductFromContentful,
  indicateRedirectedFromExpired,
  isMockNoTrial,
  landingPageTypeChoices,
} from 'bundles/coursera-plus-landing-page/contentful/constants';
import LandingPageController, {
  PAGE_EXPIRY_ERROR,
} from 'bundles/coursera-plus-landing-page/contentfulData/LandingPageController';
import PageContext from 'bundles/coursera-plus-landing-page/contentfulData/PageContext';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import {
  useGetSubscriptionTiersLandingPagePrices,
  useOpenCourseMemberships,
  usePromotion,
  useSubscriptionTiersFreeTrials,
} from 'bundles/coursera-plus-landing-page/contentfulData/providerHOC';
import type { PropsFromSubscriptionTiersFreeTrials } from 'bundles/coursera-plus-landing-page/contentfulData/providerHOC';
import type { CourseraPlusLandingPage } from 'bundles/coursera-plus-landing-page/__generated__/baseContentfulTypes';
import type { SubscriptionTiersProductVariant } from 'bundles/coursera-plus/constants/CourseraPlusProductVariant';
import { hasFreeTrialByVariantId as productHasFreeTrial } from 'bundles/coursera-plus/constants/CourseraPlusProductVariant';
import withCourseraPlusProductOwnerships from 'bundles/coursera-plus/utils/withCourseraPlusProductOwnerships';
import type { PropsFromCourseraPlusProductOwnerships } from 'bundles/coursera-plus/utils/withCourseraPlusProductOwnerships';
import { useExistingCourseraLiteSubscription } from 'bundles/coursera-plus/utils/withExistingCourseraLiteSubscription';
import type { PropsFromWithExistingCourseraLiteSubscription } from 'bundles/coursera-plus/utils/withExistingCourseraLiteSubscription';
import type OpenCourseMembershipsV1 from 'bundles/naptimejs/resources/openCourseMemberships.v1';
import type ProductPricesV3 from 'bundles/naptimejs/resources/productPrices.v3';
import type ProductPricesV4 from 'bundles/naptimejs/resources/productPrices.v4';

export type PropsFromLandingPageCaller = {
  landingPage: CourseraPlusLandingPage;
  hideRegionalPromotionBanner?: boolean;
};

export type PropsForProductIds = {
  primaryId?: SubscriptionTiersProductVariant;
  secondaryId?: SubscriptionTiersProductVariant;
  isCourseraLiteProductIds?: boolean;
};

type PropsFromRouter = { isMockNoFreeTrial?: boolean; redirectedFromExpired: boolean };

export type PropsFromPromotion = {
  promotion: SubscriptionTiersProductPrice[] | null;
};

type PropsFromWithOpenCourseMemberships = {
  openCourseMemberships: OpenCourseMembershipsV1[] | null;
};

// TODO(mawa): Ideally, we should migrate from ProductPricesV3 to ProductPricesV4.
// For now, I will consider this a low-priority chore which should be handled later.
export type SubscriptionTiersProductPrice = ProductPricesV3 | ProductPricesV4;

export type PropsFromPrices = { prices?: SubscriptionTiersProductPrice[] };

export type PropsToLandingPageProvider = PropsFromLandingPageCaller &
  PropsForProductIds &
  PropsFromCourseraPlusProductOwnerships &
  PropsFromRouter;

export type PropsToLandingPage = PropsToLandingPageProvider &
  PropsFromRouter &
  PropsFromWithOpenCourseMemberships &
  PropsFromSubscriptionTiersFreeTrials &
  PropsFromPromotion &
  PropsFromWithExistingCourseraLiteSubscription &
  PropsFromPrices;

export const LandingPageDataProvider: React.FC<PropsToLandingPageProvider> = (props) => {
  const { landingPage, hideRegionalPromotionBanner } = props;
  const pageTitle = landingPage.title;
  const { openCourseMemberships } = useOpenCourseMemberships(Boolean(landingPage.isCourseraLiteLandingPage));
  const { promotion } = usePromotion(props);
  const { liteTierFreeTrials, subscriptionTrials } = useSubscriptionTiersFreeTrials(props);
  const { existingCourseraLiteSubscription } = useExistingCourseraLiteSubscription();
  const { prices } = useGetSubscriptionTiersLandingPagePrices({
    ...props,
    existingCourseraLiteSubscription,
    promotion,
  });
  const redirectPathOverride =
    landingPage.landingPageType === landingPageTypeChoices.COMPUTER_SCIENCE_COLLECTION ? '/courseraplus' : undefined;

  const value = {
    ...props,
    openCourseMemberships,
    prices,
    promotion,
    liteTierFreeTrials,
    subscriptionTrials,
    existingCourseraLiteSubscription,
    hideRegionalPromotionBanner,
  };

  try {
    const pageController = new LandingPageController(value);
    return <PageContext.Provider value={{ pageController }}>{props.children}</PageContext.Provider>;
  } catch (e) {
    return e.message === PAGE_EXPIRY_ERROR ? (
      <RedirectToContentfulHome redirectPathOverride={redirectPathOverride} />
    ) : (
      <ContentfulError title={pageTitle} errorMessage={e.message} />
    );
  }
};

const getProductId = (contentfulId: string | null | undefined): SubscriptionTiersProductVariant | undefined => {
  if (!contentfulId) {
    return undefined;
  }
  return getProductFromContentful(contentfulId);
};

export type PropsToShouldGetFreeTrials = PropsFromLandingPageCaller & PropsFromCourseraPlusProductOwnerships;

// This function checks if it is necessary to make an API call to check
// if the learner is eligible for a free trial on the primary product.
// The primary product in this case can be either Coursera Plus or Coursera Lite.
export const shouldGetFreeTrials = (props: PropsToShouldGetFreeTrials): boolean => {
  // do we need to find out about past subscriptions (ie free trial eligibility)
  const { primaryCourseraPlusProduct, primaryCourseraLiteProduct, isCourseraLiteLandingPage } = props.landingPage || {};
  const primaryProduct = isCourseraLiteLandingPage ? primaryCourseraLiteProduct : primaryCourseraPlusProduct;
  if (!primaryProduct) {
    return false; // page won't happen anyhow
  }
  if (props.ownsCourseraPlus || (isCourseraLiteLandingPage && props.ownsCourseraLite)) {
    return false;
  }
  const primaryProductItemId = getProductFromContentful(primaryProduct);
  if (!primaryProductItemId) {
    // never happens
    throw new Error('Missing primary product');
  }
  return productHasFreeTrial(primaryProductItemId);
};

const EnhancedLandingPageProvider = compose<PropsToLandingPageProvider, PropsFromLandingPageCaller>(
  withProps<PropsForProductIds, PropsFromLandingPageCaller>((props) => {
    const { isCourseraLiteLandingPage, primaryCourseraLiteProduct } = props.landingPage || {};
    if (isCourseraLiteLandingPage) {
      return {
        primaryId: getProductId(primaryCourseraLiteProduct),
        isCourseraLiteProductIds: isCourseraLiteLandingPage,
      };
    }

    return {
      primaryId: getProductId(props.landingPage?.primaryCourseraPlusProduct),
      secondaryId: getProductId(props.landingPage?.secondaryCourseraPlusProduct),
    };
  }),
  withCourseraPlusProductOwnerships()
)(LandingPageDataProvider);

const LandingPageProviderContainer: React.FC<PropsFromLandingPageCaller> = (props) => {
  const location = useLocation();
  const routerProps = {
    isMockNoFreeTrial: isMockNoTrial(location),
    redirectedFromExpired: location.search.indexOf(indicateRedirectedFromExpired) >= 0,
  };

  return <EnhancedLandingPageProvider {...props} {...routerProps} />;
};

export default LandingPageProviderContainer;
