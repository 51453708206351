import * as React from 'react';
import { useEffect, useState } from 'react';

import classNames from 'classnames';

import type { Props } from 'bundles/cml/legacy/components/types';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import Viewer from 'bundles/cml/viewer/components/CMLViewer';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { TrackedOnLinkClickDiv } from 'bundles/page/components/TrackedOnLinkClick';

const CMLViewer: React.FC<Props> = (props) => {
  const {
    className,
    shouldApplyTracking,
    variableData,
    cml,
    contentId,
    forceLTR = false,
    shouldRenderFillableBlanksV2: enableFillableBlanksV2,
    videoSubtitlesEnabled = false,
  } = props;

  const [contentDirection, setContentDirection] = useState(forceLTR ? 'ltr' : 'auto');

  useEffect(() => {
    if (!forceLTR) {
      // override only when document direction is 'rtl' [CP-6003]
      if (document.dir === 'rtl') {
        setContentDirection('rtl');
      }
    }
  }, [forceLTR]);

  let viewer = (
    <Viewer
      cml={cml || undefined}
      variableData={variableData}
      contentId={contentId}
      enableFillableBlanksV2={enableFillableBlanksV2}
      videoSubtitlesEnabled={videoSubtitlesEnabled}
    />
  );

  if (shouldApplyTracking) {
    viewer = (
      <TrackedDiv trackingName="cml" trackClicks={false} requireFullyVisible={false} withVisibilityTracking={true}>
        <TrackedOnLinkClickDiv
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          trackingName="cml_link"
        >
          {viewer}
        </TrackedOnLinkClickDiv>
      </TrackedDiv>
    );
  }

  return (
    <div className={classNames('rc-CML', className)} dir={contentDirection}>
      {viewer}
    </div>
  );
};

export default CMLViewer;
