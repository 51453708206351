import * as React from 'react';
import Waypoint from 'react-waypoint';

import { compose, getDisplayName, setDisplayName, withProps, withState } from 'recompose';

import deferToClientSideRender from 'js/lib/deferToClientSideRender';

import { InlineNotification } from '@coursera/cds-core';

import ErrorMessage from 'bundles/coursera-ui/components/extended/ErrorMessage';
import { withError } from 'bundles/coursera-ui/components/hocs/withBranches';
import Collection from 'bundles/enterprise-collections/components/Collection';
import { EnterpriseProductCardCollectionsPlaceholder } from 'bundles/enterprise-collections/components/EnterpriseProductCardCollection';
import withDiscoveryBrowseCollectionsByOrganization from 'bundles/enterprise-collections/components/withDiscoveryBrowseCollectionsByOrganization';
import type { CollectionListItemStruct, Product, ProductType } from 'bundles/program-common/types/programCommon';
import withDiscoveryBrowseCollections from 'bundles/program-home/components/single-program/withDiscoveryBrowseCollections';

import _t from 'i18n!nls/enterprise-collections';

export const COLLECTION_LOAD_LIMIT = 3;

type OuterProps = {
  contextType: string;
  contextId: string | undefined;
  start?: string;
  limit?: number;
  onProductCardClick?: (product: Product, productType: ProductType, collectionId?: string | null) => void;
  shouldRenderTitle?: boolean;
  showNoCollectionsError?: boolean;
  allowOrgForSpecializationConfiguration?: boolean;
};

type PropsFromWithProps1 = {
  start: string;
  limit: number;
};

type PropsFromWithState2 = {
  paginationError: boolean;
  setPaginationError: (val: boolean) => void;
};

type PropsForProgramHomeGraphql = {
  programId: string;
};

type PropsForOrgHomeGraphql = {
  thirdPartyOrganizationId: string;
  userId: number;
};

type PropsFromGraphql = {
  collections: Array<CollectionListItemStruct> | undefined;
  loading: boolean;
  error: boolean;
  loadMoreItems: () => void;
  isAllLoaded: boolean;
};

export type InnerProps = OuterProps & PropsFromWithProps1 & PropsFromWithState2 & PropsFromGraphql;

export function CollectionListPlaceholder({ collectionCount = 2 }: { collectionCount?: number }) {
  const collections = [...Array(collectionCount).keys()];
  return (
    <div className="rc-CollectionLists m-t-2 CollectionListPlaceholder" data-e2e="CollectionLists">
      {collections.map((id) => (
        <EnterpriseProductCardCollectionsPlaceholder key={id} />
      ))}
    </div>
  );
}

export function BrowseCollectionList({
  collections,
  loading,
  loadMoreItems,
  onProductCardClick,
  isAllLoaded,
  paginationError,
  error,
  shouldRenderTitle,
  showNoCollectionsError,
  allowOrgForSpecializationConfiguration,
}: InnerProps) {
  const handleProductCardClick =
    (collectionTrackingId: string | null) => (product: Product, productType: ProductType) => {
      onProductCardClick?.(product, productType, collectionTrackingId);
    };

  if (error) {
    return (
      <InlineNotification severity="error">
        {_t('Sorry! Something went wrong. Please refresh the page.')}
      </InlineNotification>
    );
  }

  if (collections?.length) {
    return (
      <div className="rc-CollectionLists m-t-2" data-e2e="CollectionLists">
        {shouldRenderTitle && (
          <h2 style={{ fontSize: '1.5rem', lineHeight: '1.875rem' }}>{_t('Collections recommended by Coursera')}</h2>
        )}
        {collections.map((collection, index) => (
          <div key={collection.id}>
            <Collection
              collectionOrder={index + 1}
              id={collection.id}
              collection={collection}
              onProductCardClick={handleProductCardClick(collection.collectionTrackingId)}
              allowOrgForSpecializationConfiguration={allowOrgForSpecializationConfiguration}
            />
          </div>
        ))}
        {!isAllLoaded && !paginationError && (
          <Waypoint onEnter={loadMoreItems}>
            <EnterpriseProductCardCollectionsPlaceholder />
          </Waypoint>
        )}
        {paginationError && (
          <InlineNotification severity="error">
            {_t('Sorry! Something went wrong. Please refresh the page.')}
          </InlineNotification>
        )}
      </div>
    );
  }

  if (loading) {
    return <CollectionListPlaceholder />;
  }

  if (showNoCollectionsError && collections && collections?.length < 1) {
    return <InlineNotification severity="error">{_t('Sorry! No collections were found.')}</InlineNotification>;
  }

  return null;
}

export const defaultPropsMap = ({ start, limit }: OuterProps): PropsFromWithProps1 => ({
  start: start || '0',
  limit: limit || COLLECTION_LOAD_LIMIT,
});

const enhance = <TGraphqlProps extends {}>(queryHoc: $TSFixMe) =>
  compose<InnerProps, OuterProps & TGraphqlProps>(
    setDisplayName(getDisplayName(BrowseCollectionList)),
    withProps(defaultPropsMap),
    withState('paginationError', 'setPaginationError', false),
    // Apollo client context is getting removed somehow, so this GraphQL query fails. Looks like something to do with
    // Algolia in the SSR stack trace. Deferring to CSR for now as a workaround, like it was before.
    deferToClientSideRender,
    queryHoc,
    withError(ErrorMessage)
  )(BrowseCollectionList);

export default enhance<PropsForProgramHomeGraphql>(withDiscoveryBrowseCollections);
export const OrgHomeBrowseCollectionList = enhance<PropsForOrgHomeGraphql>(
  withDiscoveryBrowseCollectionsByOrganization
);
