import * as React from 'react';
import { useContext, useMemo } from 'react';

import type { RenderElementProps } from 'slate-react';

import AssetRenderer from 'bundles/cml/shared/components/asset/Asset';
import { useAsset } from 'bundles/cml/shared/hooks/useAsset';
import type { AssetData } from 'bundles/cml/shared/types/assetDataTypes';
import type { AssetElement } from 'bundles/cml/shared/types/elementTypes';
import { AssetContext } from 'bundles/cml/viewer/context/assetContext';

const Asset: React.FC<RenderElementProps> = (props) => {
  const { element } = props;

  const { assetManager } = useContext(AssetContext);

  const assetElement = element as AssetElement;
  const { id, assetData } = assetElement;

  const shouldFetchAsset = useMemo(() => {
    // we want to fetch the asset if the extension is a PDF since the URL returned in the renderableHTML is NOT the URL we want to use in the CML viewer
    if (assetElement.embedEnabled) {
      return true;
    }

    return !assetData;
  }, [assetData, assetElement.embedEnabled]);

  const { assetData: data } = useAsset(id, assetManager, { shouldFetchAsset });

  return <AssetRenderer {...props} assetData={shouldFetchAsset ? (data as AssetData) : assetData} readonly />;
};

export default Asset;
