import * as React from 'react';

import { EditIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';

import _t from 'i18n!nls/cml';

type Props = {
  onClick: () => void;
};

const EditConfigButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      title={_t('Edit configuration')}
      tooltipProps={{ placement: 'bottom' }}
      data-pendo="cml-widget-menu-edit"
      type={TOOLBAR_BUTTON_TYPES.menu}
    >
      <EditIcon size="small" />
    </Button>
  );
};

export default EditConfigButton;
