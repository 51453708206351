/* 
Note: These are the same utils we use to parse names in forumsV2 
https://github.com/webedx-spark/web/blob/main/static/bundles/discussions/utils/forumsV2-nameParser.ts
*/
import _t from 'i18n!nls/learning-assistant-chat-ai';

export function isAlphaCharacters(str: string): boolean {
  let code;
  let i;
  let len;

  for (i = 0, len = str.length; i < len; i += 1) {
    code = str.charCodeAt(i);
    if (
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123) // lower alpha (a-z)
    ) {
      return false;
    }
  }
  return true;
}

export function extractFirstAndLastNames(namesArray: string[]): string[] {
  const first = 0;
  const last = namesArray.length - 1;
  const result: string[] = [];
  if (!namesArray || namesArray.length < 1) {
    return ['-', '-'];
  }

  if (namesArray[first] && !isAlphaCharacters(namesArray[first][0])) {
    namesArray.shift();
  }

  if (namesArray[last] && !isAlphaCharacters(namesArray[last])) {
    namesArray.pop();
  }

  if (namesArray.length - 1 !== last) {
    return extractFirstAndLastNames(namesArray);
  } else {
    if (namesArray[first]) {
      const firstName = namesArray[first];
      result.push(firstName);
    }

    if (first !== last && namesArray[last]) {
      const lastName = namesArray[last];
      result.push(lastName);
    }

    return result;
  }
}
// If user does not have a name, we display "You" as their username in Coach chat
export function firstNameFromFullName(fullName: string = ''): string {
  const defaultName = _t('You');

  if (!fullName) {
    return defaultName;
  }

  const namesArray = fullName.trim().split(' ');
  const [firstName] = extractFirstAndLastNames(namesArray);
  if (firstName === '-') {
    return defaultName;
  } else {
    return firstName;
  }
}

// If user does not have a name, we display "Y" as their avatar initial in Coach chat
export function firstNameInitialFromName(fullName: string = ''): string {
  const firstName = firstNameFromFullName(fullName);

  const firstInitial = firstName[0];
  return `${firstInitial}`;
}
