import { requireFields } from 'bundles/naptimejs/util/requireFieldsDecorator';
import PromotionEligibilityTypes from 'bundles/promotions/common/PromotionEligibilityTypes';

import NaptimeResource from './NaptimeResource';

class PromotionEligibilities extends NaptimeResource {
  static RESOURCE_NAME = 'promotionEligibilities.v1';

  // These properties are always included.
  declare id: string;

  declare promoCodeId: string;

  declare productId: string;

  // These properties must be requested.
  declare eligibilityCode?: string;

  @requireFields('eligibilityCode')
  get isEligible(): boolean {
    return this.eligibilityCode === PromotionEligibilityTypes.ELIGIBLE;
  }

  @requireFields('eligibilityCode')
  get isProductNotEligible(): boolean {
    return this.eligibilityCode === PromotionEligibilityTypes.PRODUCT_NOT_ELIGIBLE;
  }
}

export default PromotionEligibilities;
