import { withApollo } from 'react-apollo';

import type ApolloClient from 'apollo-client';
import { compose, mapProps } from 'recompose';

import { refetchQuery } from 'js/lib/apollo';

import type {
  ProgramCurriculumProductsV1LatestEnrolledCourseActivitiesByThirdPartyOrganizationAndUserQuery,
  ProgramCurriculumProductsV1LatestEnrolledCourseActivitiesByThirdPartyOrganizationAndUserQueryVariables,
} from 'bundles/naptimejs/resources/__generated__/ProgramCurriculumProductsV1';
import ProgramCurriculumLatestEnrolledCoursesForOrg from 'bundles/program-home/queries/ProgramCurriculumLatestEnrolledCoursesForOrgQuery';

type PropsFromCaller = {
  userId: number;
  thirdPartyOrganizationId: string;
};

type PropsFromWithApollo = {
  client: ApolloClient<unknown>;
};

export type PropsFromWithCacheInvalidationForNLJLatestEnrolledCoursesForOrg = {
  invalidateLatestEnrolledCoursesForOrg: () => Promise<void>;
};

export default compose<PropsFromWithCacheInvalidationForNLJLatestEnrolledCoursesForOrg, PropsFromCaller>(
  withApollo,
  mapProps<PropsFromWithCacheInvalidationForNLJLatestEnrolledCoursesForOrg, PropsFromCaller & PropsFromWithApollo>(
    ({ userId, thirdPartyOrganizationId, client, ...rest }) => {
      const invalidateLatestEnrolledCoursesForOrg = () =>
        refetchQuery<
          ProgramCurriculumProductsV1LatestEnrolledCourseActivitiesByThirdPartyOrganizationAndUserQuery,
          ProgramCurriculumProductsV1LatestEnrolledCourseActivitiesByThirdPartyOrganizationAndUserQueryVariables
        >(client, {
          query: ProgramCurriculumLatestEnrolledCoursesForOrg,
          variables: {
            userId: userId.toString(),
            thirdPartyOrganizationId,
          },
        });
      return { ...rest, invalidateLatestEnrolledCoursesForOrg, userId, thirdPartyOrganizationId };
    }
  )
);
