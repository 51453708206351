import * as React from 'react';

import classNames from 'classnames';
import gql from 'graphql-tag';

import { processImage } from 'js/lib/imgix';

import { StyleSheet, breakPoint, css } from '@coursera/coursera-ui';

import type { Banner_partners as PartnersFragment } from 'bundles/enterprise-legacy-xdp/components/miniPDP/__generated__/Banner';
import withFragments from 'bundles/graphql/components/withFragments';

export type PartnerType = Omit<PartnersFragment, '__typename'>;

type Props = {
  renderLogo?: boolean;
  overrideLogo?: string | null;
  partner: PartnerType;
  mixedCaseName?: boolean;
  rootClassName?: string;
};

const styles = StyleSheet.create({
  image: {
    // Prevent image from overflowing parent flex container horizontal bounds
    maxWidth: '100%',
  },
  uppercaseText: {
    textTransform: 'uppercase',
  },
  rectangularXl: {
    maxHeight: '70px',
    [`@media (max-width: ${breakPoint.sm}px)`]: {
      maxHeight: '45px',
    },
  },
  adjustLogo: {
    [`@media (min-width: ${breakPoint.sm}px)`]: {
      maxWidth: '200px',
    },
  },
  rectangular: {
    maxHeight: '50px',
    [`@media (max-width: ${breakPoint.sm}px)`]: {
      maxHeight: '25px',
    },
  },
  square: {
    maxHeight: '90px',
    maxWidth: '90px',
    backgroundColor: 'white',
    [`@media (max-width: ${breakPoint.sm}px)`]: {
      maxHeight: '45px',
      maxWidth: '45px',
    },
  },
});

export function Partner({ renderLogo, overrideLogo, partner, mixedCaseName, rootClassName, ...rest }: Props) {
  const { name, rectangularLogo, logo, squareLogo, shortName, classLogo } = partner;
  const imageSrc = overrideLogo || classLogo || squareLogo || rectangularLogo || logo || undefined;

  if (renderLogo) {
    let cssClass;
    let processedSrc;

    if (shortName === 'jhu') {
      cssClass = styles.rectangular;
      processedSrc = processImage(imageSrc, { height: 50 });
    } else if (shortName === 'facebook') {
      // Technical debt incurred to offer a quick solution to this time-sensitive ticket (https://coursera.atlassian.net/browse/GR-24064)
      // Will find a long term solution in this follow-up ticket (https://coursera.atlassian.net/browse/GR-24369)
      cssClass = styles.rectangular;
      processedSrc = processImage(logo);
    } else if (overrideLogo || classLogo) {
      cssClass = styles.rectangularXl;
      processedSrc = processImage(imageSrc, { height: 70 });
    } else {
      cssClass = styles.square;
      processedSrc = processImage(imageSrc, { height: 90, width: 90 });
    }

    const imageClassName = classNames(css(styles.image, cssClass).className, rootClassName);
    return <img className={imageClassName} src={processedSrc} alt={name} title={name} {...rest} />;
  } else {
    const spanClassName = !mixedCaseName ? css(styles.uppercaseText).className : undefined;
    return <span className={classNames(spanClassName, rootClassName)}>{name}</span>;
  }
}

export default withFragments({
  partner: gql`
    fragment XdpPartnersFragment on XdpV1_org_coursera_xdp_common_PartnerWithId {
      id
      name
      classLogo
      rectangularLogo
      logo
      squareLogo
      shortName
      description
    }
  `,
})(Partner);
