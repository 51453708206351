import { createContext, useContext } from 'react';

import type { CodeBlockOptions } from 'bundles/cml/legacy/types/Content';

export type CodeBlockContextType = {
  codeBlockOptions?: CodeBlockOptions;
  codeBlockIndices?: Record<string, number>;
};

export const CodeBlockContext = createContext<CodeBlockContextType>({});

export const useCodeBlockContext = () => useContext(CodeBlockContext);
