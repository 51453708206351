import { graphql } from 'react-apollo';

import type { FinancialAidApplication } from '__generated__/graphql-types';
import gql from 'graphql-tag';
import flatMap from 'lodash/flatMap';
import orderBy from 'lodash/orderBy';
import { compose, mapProps } from 'recompose';

import logger from 'js/app/loggerSingleton';
import { tupleToStringKey } from 'js/lib/stringKeyTuple';
import user from 'js/lib/user';
import waitForGraphQL from 'js/lib/waitForGraphQL';

import { VERIFIED_CERTIFICATE } from 'bundles/payments/common/ProductType';
import QueryByUserAndProducts from 'bundles/payments/components/finaid-2021/enhancers/QueryByUserAndProducts.graphql';
import type { FinancialAidApplicationsWithUserAndProducts_FinancialAidApplicationsV2Resource_byUserAndProductIds as FinancialAidApplications } from 'bundles/payments/components/finaid-2021/enhancers/__generated__/FinancialAidApplicationsWithUserAndProducts';
import type {
  OnDemandSpecializations_OnDemandSpecializationsV1Resource_get_courses as OnDemandSpecializationCourses,
  OnDemandSpecializations,
  OnDemandSpecializationsVariables,
} from 'bundles/payments/components/finaid-2021/enhancers/__generated__/OnDemandSpecializations';
import type {
  ProductsOwnedById,
  ProductsOwnedById_ProductOwnershipsV2Resource_multiGet as ProductsOwnedByIdInfo,
  ProductsOwnedByIdVariables,
} from 'bundles/payments/components/finaid-2021/enhancers/__generated__/ProductsOwnedById';
import { FindAidState as APPLICATION_STATUS } from 'bundles/payments/components/finaid-2021/enhancers/types';
import type { FinancialAidApplicationType } from 'bundles/payments/components/finaid-2021/enhancers/types';
import { transformFinancialAidApplication } from 'bundles/payments/components/finaid-2021/enhancers/utils';

import type {
  QueryByUserAndProductsQuery,
  QueryByUserAndProductsQueryVariables,
} from './__generated__/QueryByUserAndProducts';

const PRODUCT_TYPE_STRING = 'VerifiedCertificate';

type InputProps = {
  s12nId: string;
  s12nSlug?: string;
  handleClose: () => void;
  handleSubmit: (courseId?: string) => void;
};

export type OnDemandSpecializationsResultProps = {
  courses?: OnDemandSpecializationCourses;
  partnerName?: string;
  productVariant?: string;
};

type ProductOwnershipsResultProps = {
  productOwnerships?: ProductsOwnedByIdInfo;
  loading?: boolean;
};

export type FinancialAidResultProps = {
  financialAidApplications?: FinancialAidApplications;
  loading?: boolean;
};

type QueryByUserAndProductsProps = {
  financialAidApplications?: { elements: FinancialAidApplicationType[] };
  loading?: boolean;
};

type EnhancedCoursesInputProps = InputProps &
  OnDemandSpecializationsResultProps &
  ProductOwnershipsResultProps &
  FinancialAidResultProps;

type EnhancedCoursesResultProps = {
  enhancedCourses?: {
    courseName: string;
    courseId: string;
    photoUrl?: string;
    applicationState?: string;
    ownsProduct?: boolean;
    catalogPrice?: {
      amount: number;
      currencyCode: string;
    };
  }[];
  loading?: boolean;
};

export type Props = EnhancedCoursesInputProps & EnhancedCoursesResultProps;

export const specializationsQuery = gql`
  query OnDemandSpecializations($id: String!) {
    OnDemandSpecializationsV1Resource {
      get(id: $id) {
        id
        name
        tagline
        slug
        courses {
          elements {
            id
            name
            photoUrl
            courseDerivatives {
              id
              catalogPrice {
                amount
                currencyCode
              }
            }
          }
        }
        partners {
          elements {
            id
            name
          }
        }
        productVariant
      }
    }
  }
`;

export const withSpecializations = (isNotRequired?: boolean) => {
  const graphqlHoc = isNotRequired ? graphql : waitForGraphQL;

  return graphqlHoc<
    InputProps,
    OnDemandSpecializations,
    OnDemandSpecializationsVariables,
    OnDemandSpecializationsResultProps
  >(specializationsQuery, {
    props: ({ data }) => {
      const { OnDemandSpecializationsV1Resource } = data || {};

      return {
        courses: OnDemandSpecializationsV1Resource?.get?.courses ?? undefined,
        partnerName: OnDemandSpecializationsV1Resource?.get?.partners?.elements[0]?.name ?? undefined,
        productVariant: OnDemandSpecializationsV1Resource?.get?.productVariant ?? undefined,
      };
    },
    options({ s12nId }) {
      return {
        variables: {
          id: s12nId,
        },
        errorPolicy: 'all',
      };
    },
  });
};

export const productOwnershipsQuery = gql`
  query ProductsOwnedById($ids: [String!]!) {
    ProductOwnershipsV2Resource {
      multiGet(ids: $ids) {
        elements {
          id
          userId
          productId
          owns
          expiredOwns
        }
      }
    }
  }
`;

export const withProductOwnerships = (isNotRequired?: boolean) => {
  const graphqlHoc = isNotRequired ? graphql : waitForGraphQL;
  return graphqlHoc<
    InputProps & OnDemandSpecializationsResultProps,
    ProductsOwnedById,
    ProductsOwnedByIdVariables,
    ProductOwnershipsResultProps
  >(productOwnershipsQuery, {
    skip: () => !user.isAuthenticatedUser(),
    options: ({ courses }) => {
      const userId = user.get().id.toString();
      return {
        variables: {
          ids: flatMap(courses?.elements || [], (course) =>
            course ? [tupleToStringKey([userId, PRODUCT_TYPE_STRING, course.id])] : []
          ),
        },
        errorPolicy: 'all',
      };
    },
    props: ({ data }) => {
      const loading = data?.loading;
      if (loading) {
        return { loading };
      }
      const productOwnerships = data?.ProductOwnershipsV2Resource?.multiGet ?? undefined;
      return { loading, productOwnerships };
    },
  });
};

export const withFinaidByUserAndProducts = (isNotRequired?: boolean) => {
  const graphqlHoc = isNotRequired ? graphql : waitForGraphQL;
  return compose(
    graphqlHoc<
      InputProps & OnDemandSpecializationsResultProps,
      QueryByUserAndProductsQuery,
      QueryByUserAndProductsQueryVariables,
      QueryByUserAndProductsProps
    >(QueryByUserAndProducts, {
      skip: () => !user.isAuthenticatedUser(),
      options: ({ courses }) => {
        return {
          variables: {
            userId: user.get().id.toString(),
            productIds: flatMap(courses?.elements || [], (course) =>
              course ? { productType: VERIFIED_CERTIFICATE, productItemId: course.id } : []
            ),
            limit: 1,
          },
          errorPolicy: 'all',
          context: { clientName: 'gatewayGql' },
        };
      },
      props: ({ data }) => {
        if (data?.error) {
          logger.error(data?.error);
        }

        const loading = data?.loading;
        if (loading) {
          return { loading };
        }

        const finAidApps =
          flatMap(data?.FinancialAidApplication?.queryByUserAndProducts.elements || [], (finaidApp) => {
            if (!finaidApp) return [];
            return [
              {
                ...transformFinancialAidApplication(finaidApp as FinancialAidApplication),
              },
            ];
          }) || [];
        return {
          loading,
          financialAidApplications: {
            elements: finAidApps,
          },
        };
      },
    })
  );
};

const withEnhancedCourses = mapProps<EnhancedCoursesResultProps, EnhancedCoursesInputProps>(
  ({ courses, financialAidApplications, productOwnerships, loading, ...rest }) => {
    if (loading) {
      return { loading };
    }
    const enhancedCourses = flatMap(courses?.elements || [], (course) => {
      if (!course) {
        return [];
      }
      const productId = tupleToStringKey([PRODUCT_TYPE_STRING, course.id]);
      const finAidApplications = orderBy(financialAidApplications?.elements, ['applicationDate'], ['desc']);
      const ownsProduct =
        productOwnerships?.elements.find((ownership) => ownership?.productId === course.id)?.owns ?? false;
      const applicationState =
        finAidApplications?.find(
          (a) =>
            a?.productId === productId &&
            (a?.state === APPLICATION_STATUS.Pending ||
              a?.state === APPLICATION_STATUS.Approved ||
              a?.state === APPLICATION_STATUS.ApprovedPaymentSuccessful ||
              a?.state === APPLICATION_STATUS.PaymentExpired ||
              a?.state === APPLICATION_STATUS.PaymentPending)
        )?.state ?? 'NONE';
      return [
        {
          courseName: course.name,
          courseId: course.id,
          photoUrl: course.photoUrl ?? undefined,
          applicationState,
          ownsProduct,
          catalogPrice: course.courseDerivatives?.catalogPrice
            ? {
                amount: course.courseDerivatives.catalogPrice.amount,
                currencyCode: course.courseDerivatives.catalogPrice.currencyCode,
              }
            : undefined,
        },
      ];
    });
    return {
      enhancedCourses,
      courses,
      financialAidApplications,
      productOwnerships,
      loading,
      ...rest,
    };
  }
);

export default compose<Props, InputProps>(
  withSpecializations(),
  withProductOwnerships(),
  withFinaidByUserAndProducts(true),
  withEnhancedCourses
);
