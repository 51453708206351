import type { BaseComponentProps } from '@coursera/cds-common';
import { useLocalizedStringFormatter } from '@coursera/cds-common';

import CardBodyContent from '@core/cards/common/CardBodyContent';
import i18nMessages from '@core/cards/common/i18n';
import Typography from '@core/Typography2';

type Props = {
  /**
   * list of skills to show
   */
  skills: string[];

  /**
   * Custom string to use as the prefix label.
   * Make sure to handle localization of this string when passing in.
   *
   * @default "Skills you'll gain"
   **/
  prefix?: string;
} & BaseComponentProps<'div'>;

/**
 * Common component to render a list of skills gained for a product
 */
const CardSkills = (props: Props) => {
  const stringFormatter = useLocalizedStringFormatter(i18nMessages);

  const {
    skills,
    prefix = stringFormatter.format('skills_prefix'),
    ...rest
  } = props;

  const skillsList = skills.join(', ');
  return (
    <CardBodyContent {...rest}>
      <Typography color="supportText" component="p" variant="bodySecondary">
        <Typography color="body" component="strong" variant="bodySecondary">
          {prefix}
          {': '}
        </Typography>
        {skillsList}
      </Typography>
    </CardBodyContent>
  );
};

export default CardSkills;
