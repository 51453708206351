/** @jsx jsx */
import { jsx } from '@emotion/react';

import { RatingStat } from '@coursera/cds-core';

type Props = { avgProductRating?: number; numProductRatings?: number };

const ProductCardReviews = ({ avgProductRating, numProductRatings }: Props) => {
  if (!avgProductRating || (numProductRatings && numProductRatings <= 5)) {
    return null;
  }

  const rating = Math.round(avgProductRating * 10) / 10;

  return <RatingStat rating={rating} size="label" reviews={numProductRatings} />;
};

export default ProductCardReviews;
