/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import _ from 'lodash';

import { Typography2 } from '@coursera/cds-core';
import { css as cuicss, placeholder } from '@coursera/coursera-ui';

const styles = {
  skillPillList: css`
    display: flex;
    flex-wrap: wrap;
    margin: var(--cds-spacing-100) 0;
    height: 68px;
    padding: 0;
  `,
  skillPillContainer: css`
    max-width: calc(50% - var(--cds-spacing-100));
    margin-right: var(--cds-spacing-100);
    display: flex;
    height: 26px;
  `,
  lastPill: css`
    max-width: 50%;
    margin-right: 0;
  `,
  itemText: css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background: var(--cds-color-grey-25);
    border-radius: 2px;
    padding: 1px var(--cds-spacing-100);
  `,
  moreText: css`
    padding: 1px 0;
    margin-left: var(--cds-spacing-100);
    flex-shrink: 0;
  `,
};

export function SkillPillListPlaceholder() {
  const placeholderStyles = {
    placeholderItemText: css`
      width: 100px;
    `,
  };
  return (
    <ul css={styles.skillPillList} aria-hidden>
      {_.times(4, (value) => (
        <li key={value} css={[styles.skillPillContainer]} aria-hidden>
          <Typography2 component="p" css={styles.itemText} aria-hidden>
            <div aria-hidden {...cuicss(placeholder.styles.shimmer)} css={placeholderStyles.placeholderItemText}>
              &nbsp;
            </div>
          </Typography2>
        </li>
      ))}
    </ul>
  );
}
