/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import ResponsiveToolbar from 'bundles/cml/editor/components/toolbars/ResponsiveToolbar';
import type { ToolbarProps } from 'bundles/cml/editor/components/toolbars/types';

const styles = {
  sticky: css`
    background-color: var(--cds-color-white-0);
    position: sticky;
    z-index: 1;
  `,
  top: css`
    top: 0;
    border-bottom: solid 1px var(--cds-color-grey-50);
  `,
  bottom: css`
    bottom: 0;
    border-top: solid 1px var(--cds-color-grey-50);
  `,
};

const StickyToolbar: React.FC<ToolbarProps> = (props) => {
  const { position } = props;
  return (
    <ResponsiveToolbar
      {...props}
      data-testid="sticky-toolbar"
      css={[styles.sticky, position === 'top' && styles.top, position === 'bottom' && styles.bottom]}
    />
  );
};

export default StickyToolbar;
