/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';

import { PageNotification } from '@coursera/cds-core';

import { zIndex } from 'bundles/authoring/style-constants/layout';
import type { NotificationMessage } from 'bundles/cml/editor/types/notification';

const styles = {
  notification: css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: ${zIndex.modal};
    display: flex;
    justify-content: center;
    opacity: 1;
    transition: opacity 500ms;
    pointer-events: none;
  `,
  message: css`
    width: 700px;
    max-width: 100%;
    pointer-events: auto;
  `,
  hidden: css`
    opacity: 0;
    pointer-events: none;
  `,
};

type Props = {
  notification?: NotificationMessage;
  onDismiss: () => void;
};

const NOTIFICATION_DURATION_MS = 4500;

const Notifications: React.FC<Props> = ({ notification, onDismiss }) => {
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    setHidden(!notification);
  }, [notification]);

  const handleDismiss = useCallback(() => {
    setHidden(true);
    const timeout = setTimeout(onDismiss, 500);
    return () => clearTimeout(timeout);
  }, [onDismiss]);

  useEffect(() => {
    if (notification) {
      const timeout = setTimeout(handleDismiss, NOTIFICATION_DURATION_MS);
      return () => clearTimeout(timeout);
    }

    return () => undefined;
  }, [notification, handleDismiss]);

  return (
    <div css={[styles.notification, hidden && styles.hidden]}>
      {notification && (
        <PageNotification severity={notification.type} onDismiss={onDismiss} css={styles.message}>
          {notification.message}
        </PageNotification>
      )}
    </div>
  );
};

export default Notifications;
