/* eslint-disable no-restricted-syntax */
import config from 'js/app/config';

export const CONSUMER_PAGE_CONTENT_CONTENTFUL_SLUG = 'consumer';

export const ENTERPRISE_PAGE_CONTENT_CONTENTFUL_SLUG_DEFAULT = 'enterprise';

export const ENTERPRISE_FREEMIUM_CA_CONTENTFUL_SLUG = 'enterprise-freemium-ca';

export const CAREER_CARD_OVERRIDE_PARTNER_LOGOS: { [key: string]: string } = {
  facebook: `${config.url.resource_assets}growth_discovery/unified-career-academy/unified-ca-override-meta-logo.png`,
  ibm: `${config.url.resource_assets}growth_discovery/unified-career-academy/unified-ca-override-ibm-logo-2.png`,
  'hubspot-academy': `${config.url.resource_assets}growth_discovery/unified-career-academy/hubspot-logo.png`,
};
