import * as React from 'react';

import config from 'js/app/config';
import logger from 'js/app/loggerSingleton';
import Imgix from 'js/components/Imgix';
import { FormattedHTMLMessage, FormattedMessage } from 'js/lib/coursera.react-intl';
import { LONG_DATE_ONLY_DISPLAY } from 'js/utils/DateTimeUtils';

import EnrollmentChoiceTypes from 'bundles/enroll-course/common/EnrollmentChoiceTypes';
import type { Group } from 'bundles/enroll-course/common/Enterprise';
import { canPurchaseSingleCourseAndHasFullDiscount } from 'bundles/enroll-course/lib/apiClient';
import { doesChoiceTypeHaveSubmitHandler } from 'bundles/enroll-course/lib/enrollmentChoiceUtils';
import PromotionApplicableCheckoutMessage from 'bundles/enroll/components/common/PromotionApplicableCheckoutMessage';
import { useCourseEnrollModalData } from 'bundles/enroll/data/usePageData';
import PriceWithDiscountIndicator from 'bundles/payments-common/components/PriceWithDiscountIndicator';
import type { ProductPrices } from 'bundles/payments-common/hooks/useGetProductPrices';
import { getPromotionExpirationDate } from 'bundles/promotions/utils/promotionDateUtils';

import _t from 'i18n!nls/enroll-course';

import 'css!./__styles__/CourseEnrollChoiceDescription';

const {
  BULKPAY_FULL_SPECIALIZATION,
  BULKPAY_REMAINING_SPECIALIZATION_COURSES,
  PURCHASE_SINGLE_COURSE,
  ENROLL_COURSE,
  AUDIT_COURSE,
  ENROLL_THROUGH_GROUP,
} = EnrollmentChoiceTypes;

type EnrollmentChoiceType = keyof typeof EnrollmentChoiceTypes;

const CERTIFICATE_ICON = `${config.url.resource_assets}conversion/ic_cert.png`;

type PropsFromCaller = {
  choiceType: EnrollmentChoiceType;
  group?: Group;
};

const CourseEnrollChoiceDescription: React.FC<PropsFromCaller> = ({ choiceType, group }) => {
  const {
    enrollmentAvailableChoices,
    promotionEligibilities,
    promotionDetails,
    productPrice,
    course,
    courseTypeMetadataWithVersion,
    s12n,
    s12nProductPrice,
  } = useCourseEnrollModalData();

  const courseLabel = courseTypeMetadataWithVersion?.courseLabel;
  const isCourse = courseTypeMetadataWithVersion?.isCourse;

  if (!doesChoiceTypeHaveSubmitHandler(choiceType)) {
    logger.error(`Cannot render choice type without submit handler: ${choiceType}`);
    return null;
  }

  // TODO(jnam) make sure to distinguish pay-by-course case and standalone course cert case
  const renderPrimaryDescription = () => {
    const renderProductPrice = (descriptionProductPrice?: ProductPrices) => {
      if (!descriptionProductPrice) {
        return null;
      }

      if (
        canPurchaseSingleCourseAndHasFullDiscount({
          enrollmentAvailableChoices,
          promotionDetails,
          promotionEligibilities,
        })
      ) {
        return (
          <PriceWithDiscountIndicator
            amount={descriptionProductPrice.amount}
            finalAmount={0}
            currencyCode={descriptionProductPrice.currencyCode}
            discountColorType="green"
            hideCurrencyCode={false}
            showDiscountPrice={true}
            showDiscountPercent={false}
          />
        );
      } else {
        return (
          <PriceWithDiscountIndicator
            amount={descriptionProductPrice.amount}
            finalAmount={descriptionProductPrice.finalAmount}
            currencyCode={descriptionProductPrice.currencyCode}
            hideCurrencyCode={false}
          />
        );
      }
    };

    switch (choiceType) {
      case BULKPAY_FULL_SPECIALIZATION:
        return (
          <span>
            <span>{_t('Purchase Specialization')}</span>
            <span className="price-delim"> · </span>
            {renderProductPrice(s12nProductPrice)}
          </span>
        );
      case BULKPAY_REMAINING_SPECIALIZATION_COURSES:
        return (
          <span>
            <span>{_t('Purchase Remaining Courses')}</span>
            <span className="price-delim"> · </span>
            {renderProductPrice(s12nProductPrice)}
          </span>
        );
      case PURCHASE_SINGLE_COURSE:
        return (
          <span>
            <span>{_t('Purchase Course')}</span>
            <span className="price-delim"> · </span>
            {renderProductPrice(productPrice)}
            {!canPurchaseSingleCourseAndHasFullDiscount({
              promotionDetails,
              promotionEligibilities,
              enrollmentAvailableChoices,
            }) && <PromotionApplicableCheckoutMessage course={course} s12n={s12n} />}
          </span>
        );
      case ENROLL_THROUGH_GROUP:
        return <span>{_t('Join now for free')}</span>;
      case ENROLL_COURSE:
        if (courseLabel && !isCourse) {
          return _t('Full #{courseType}, No Certificate', { courseType: courseLabel });
        }
        return _t('Full Course, No Certificate');
      case AUDIT_COURSE:
        return _t('Audit only');
      default:
        throw new Error(`Unable to get detect description for ${choiceType}`);
    }
  };

  const renderGroupDescription = () => {
    return (
      group && (
        <FormattedHTMLMessage
          message={_t(
            `As part of <span class="body-2-text">{groupName}</span>, you're able to join
            this course for free!  You'll have access to all graded assignments, a final grade for
          the course, and a shareable Certificate.`
          )}
          groupName={group.name}
        />
      )
    );
  };

  const renderDescriptionWithIconArea = (msg: React.ReactNode) => {
    return (
      <div className="horizontal-box">
        <div className="flex-1">
          <Imgix className="cert-icon" alt={Imgix.DECORATIVE} src={CERTIFICATE_ICON} maxHeight={35} maxWidth={35} />
        </div>
        <div className="flex-12">{msg}</div>
        <div className="flex-1" />
      </div>
    );
  };

  const renderSecondaryDescription = () => {
    let msg: string | undefined;
    switch (choiceType) {
      case BULKPAY_FULL_SPECIALIZATION: {
        const courseCount = s12n?.courseIds?.length;
        const hasDiscount =
          s12nProductPrice?.amount &&
          s12nProductPrice?.finalAmount &&
          s12nProductPrice.finalAmount < s12nProductPrice.amount;
        if (hasDiscount) {
          if (courseCount) {
            return (
              <FormattedMessage
                message={_t(
                  'Pre-pay for all {courseCount} courses in the Specialization and Specialization Certificate, and get a special one-time discount.'
                )}
                courseCount={courseCount}
              />
            );
          } else {
            return _t(
              'Pre-pay for all the courses in the Specialization and Specialization Certificate, and get a special one-time discount.'
            );
          }
        } else if (courseCount) {
          return (
            <FormattedMessage
              message={_t(
                'Pre-pay for all {courseCount} courses in the Specialization and Specialization Certificate.'
              )}
              courseCount={courseCount}
            />
          );
        } else {
          return _t('Pre-pay for all the courses in the Specialization and Specialization Certificate.');
        }
      }
      case BULKPAY_REMAINING_SPECIALIZATION_COURSES:
        return _t('Pre-pay for the remaining courses in the Specialization and Specialization Certificate.');
      case PURCHASE_SINGLE_COURSE:
        if (
          canPurchaseSingleCourseAndHasFullDiscount({
            promotionDetails,
            promotionEligibilities,
            enrollmentAvailableChoices,
          })
        ) {
          const endTimeInMilliseconds = promotionDetails?.endsAt ?? 0;
          return (
            <FormattedMessage
              message={_t('Free enrollment available until {date}. Valid for one enrollment per person.')}
              date={getPromotionExpirationDate(endTimeInMilliseconds, LONG_DATE_ONLY_DISPLAY)}
            />
          );
        } else {
          msg = _t("Commit to earning a Certificate\u2014it's a trusted, shareable way to showcase your new skills.");
          return renderDescriptionWithIconArea(msg);
        }
      case ENROLL_THROUGH_GROUP:
        return renderGroupDescription();
      case ENROLL_COURSE:
        if (courseLabel && !isCourse) {
          return _t('You will still have access to all project materials for this #{courseType}.', {
            courseType: courseLabel,
          });
        }

        return _t('You will still have access to all course materials for this course.');
      case AUDIT_COURSE:
        return _t('You will have access to all course materials except graded items.');
      default:
        throw new Error(`Unable to get detect description for ${choiceType}`);
    }
  };

  return (
    <div
      className="rc-CourseEnrollChoiceDescription"
      data-s12nid={s12n && s12n.id}
      data-groupid={group ? group.id : null}
    >
      <div className="primary-description">{renderPrimaryDescription()}</div>
      <div className="secondary-description color-secondary-text">{renderSecondaryDescription()}</div>
    </div>
  );
};

export default CourseEnrollChoiceDescription;
