import * as React from 'react';

import type { CmlContent } from 'bundles/cml';
import { CML, CMLUtils } from 'bundles/cml';
import CollectionDescriptionFragment from 'bundles/enterprise-collections/queries/CollectionDescriptionFragment';
import withFragments from 'bundles/graphql/components/withFragments';

export type Description = {
  cml?: {
    value: string;
    dtdId: string;
  };
};

type Props = {
  collection: {
    description: Description;
  };
};

export function CollectionDescription({ collection: { description } }: Props) {
  if (description.cml?.value && description.cml?.dtdId) {
    const cml: CmlContent = CMLUtils.create(description.cml.value, description.cml.dtdId);
    const isDescriptionPresent = CMLUtils.getLength(cml) > 0;

    return isDescriptionPresent ? <CML className="max-text-width" cml={cml} /> : null;
  }
  return null;
}

export default withFragments({
  collection: CollectionDescriptionFragment,
})(CollectionDescription);
