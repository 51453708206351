import * as React from 'react';
import { graphql } from 'react-apollo';

import gql from 'graphql-tag';
import { compose, mapProps } from 'recompose';

import { tupleToStringKey } from 'js/lib/stringKeyTuple';
import waitForGraphQL from 'js/lib/waitForGraphQL';

import { withLoading, withRenderNothing } from 'bundles/coursera-ui/components/hocs/withBranches';
import AboutS12nCourseListItem from 'bundles/coursera-ui/components/rich/MiniXDP/AboutS12nCourseListItem';
import type { Props as MiniSDPProps } from 'bundles/coursera-ui/components/rich/MiniXDP/MiniSDP';
import MiniSDP from 'bundles/coursera-ui/components/rich/MiniXDP/MiniSDP';
import type {
  CuiEnterpriseProgramSessionAssociationsQuery_EnterpriseProgramSessionAssociationsV1Resource_byProgramAndCourses_elements as CourseSession,
  CuiEnterpriseProgramSessionAssociationsQuery as EnterpriseProgramSessionAssociationsQueryData,
  CuiEnterpriseProgramSessionAssociationsQueryVariables as EnterpriseProgramSessionAssociationsQueryVariables,
} from 'bundles/coursera-ui/components/rich/MiniXDP/__generated__/CuiEnterpriseProgramSessionAssociationsQuery';
import type {
  HasCourseraLabsQuery as HasCourseraLabsQueryData,
  HasCourseraLabsQueryVariables,
} from 'bundles/coursera-ui/components/rich/MiniXDP/__generated__/HasCourseraLabsQuery';
import type { MiniSDPQuery_XdpV1Resource_get_xdpMetadata_XdpV1_sdpMetadataMember_sdpMetadata_courses as Course } from 'bundles/coursera-ui/components/rich/MiniXDP/__generated__/MiniSDPQuery';
import { PartnerIncentivesSdpFragment } from 'bundles/learner-incentives/components/LearnerIncentive';
import type { EnterpriseProductMetadataFlags } from 'bundles/program-common/types/programCommon';

type PropsFromCaller = Omit<MiniSDPProps, 'htmlAttributes' | 's12n'> & {
  id: string;
  programId?: string;
  orgId?: string;
  enableCurriculumBuilder?: boolean;
  coursesMetadata?: EnterpriseProductMetadataFlags[];
};

type PropsFromMiniSDPQuery = {
  s12n: any;
};

type PropsFromEnterpriseProgramSessionAssociationsQuery = {
  privateS12nSessionDates?: (CourseSession | null)[];
};

type PropsFromHasCourseraLabsQuery = {
  hasLabAssignments?: boolean;
};

type Props = PropsFromCaller &
  PropsFromMiniSDPQuery &
  PropsFromEnterpriseProgramSessionAssociationsQuery &
  PropsFromHasCourseraLabsQuery;

export const MiniSDPContainer: React.FC<Props> = ({ s12n, enableCurriculumBuilder, coursesMetadata, ...rest }) => {
  return (
    <MiniSDP
      s12n={s12n}
      htmlAttributes={{ 'data-e2e': 'MiniSDP' }}
      enableCurriculumBuilder={enableCurriculumBuilder}
      productMetadata={coursesMetadata}
      {...rest}
    />
  );
};

export const MiniSDPQuery = gql`
  query MiniSDPQuery($id: String!) {
    XdpV1Resource {
      get(id: $id) {
        id
        productId
        productType
        xdpMetadata {
          __typename
          ... on XdpV1_sdpMetadataMember {
            sdpMetadata {
              id
              name
              slug
              productVariant
              description
              avgLearningHoursPerWeek
              avgLearningMonthsAdjusted
              totalEnrollmentCount
              instructors {
                id
                firstName
                lastName
                fullName
                title
              }
              headerImage
              certificateLogo
              companyLogos
              cmlDescription {
                __typename
                ... on XdpV1_cmlMember {
                  cml {
                    value
                    dtdId
                    __typename
                  }
                }
              }
              skills
              s12nAndCourseSkills
              primaryLanguages
              recommendedBackground
              subtitleLanguages
              headline
              subheader
              headerImage
              ratings {
                averageFiveStarRating
                ratingCount
              }
              level
              learningObjectives
              companyLogos
              partners {
                id
                name
                shortName
                landingPageBanner
                description
                partnerMarketingBlurb
                classLogo
                logo
                squareLogo
                rectangularLogo
                primaryColor
                productBrandingLogo
              }
              courseIds
              capstone {
                courseIds
              }
              courses {
                ...AboutS12nCourseListItemFragment
              }
              ...PartnerIncentivesSdpFragment
            }
          }
        }
      }
    }
  }
  ${AboutS12nCourseListItem.fragments.course}
  ${PartnerIncentivesSdpFragment}
`;

const EnterpriseProgramSessionAssociationsQuery = gql`
  query CuiEnterpriseProgramSessionAssociationsQuery($programId: String!, $courseIds: [String!]!) {
    EnterpriseProgramSessionAssociationsV1Resource {
      byProgramAndCourses(programId: $programId, courseIds: $courseIds) {
        elements {
          id
          courseId
          session {
            id
            startsAt
            endsAt
          }
        }
      }
    }
  }
`;

const HasCourseraLabsQuery = gql`
  query HasCourseraLabsQuery($key: String!) {
    ExternallyAccessibleNostosV1Resource {
      getAllProperties(job_name: "enterprise_products_with_labs", keys: $key) {
        elements {
          id
          content
        }
      }
    }
  }
`;

export default compose<Props, PropsFromCaller>(
  // @ts-expect-error TODO type it
  mapProps(({ id, ...rest }) => ({ ...rest, id: tupleToStringKey(['SPECIALIZATION', id]) })),
  graphql(MiniSDPQuery, {
    options: () => ({
      errorPolicy: 'all',
    }),
  }),
  withLoading,
  // @ts-expect-error TODO type it
  mapProps(({ data, ...rest }) => {
    if (!data.XdpV1Resource || !data.XdpV1Resource.get) {
      return { shouldRenderNothing: true };
    }

    return {
      ...rest,
      s12n: {
        ...data.XdpV1Resource.get,
        ...data.XdpV1Resource.get.xdpMetadata.sdpMetadata,
        courseCount: data.XdpV1Resource.get.xdpMetadata.sdpMetadata.courses.length,
      },
    };
  }),
  waitForGraphQL<
    { programId: string; orgId: string } & PropsFromMiniSDPQuery,
    EnterpriseProgramSessionAssociationsQueryData,
    EnterpriseProgramSessionAssociationsQueryVariables,
    PropsFromEnterpriseProgramSessionAssociationsQuery
  >(EnterpriseProgramSessionAssociationsQuery, {
    skip: ({ programId, s12n, orgId }) => !programId || !s12n.courseCount || !orgId,
    options: ({ programId, s12n }) => ({
      variables: { programId, courseIds: s12n.courses.map((course: Course) => course.id) },
    }),
    props: ({ data }) => {
      const privateS12nSessionDates =
        data?.EnterpriseProgramSessionAssociationsV1Resource?.byProgramAndCourses?.elements ?? [];
      return { privateS12nSessionDates };
    },
  }),
  graphql<
    PropsFromMiniSDPQuery,
    HasCourseraLabsQueryData,
    HasCourseraLabsQueryVariables,
    PropsFromHasCourseraLabsQuery
  >(HasCourseraLabsQuery, {
    skip: ({ s12n }) => !s12n,
    options: ({ s12n }) => ({
      variables: { key: `SPECIALIZATION~${s12n.id}` },
    }),
    props: ({ data }) => {
      const content = data?.ExternallyAccessibleNostosV1Resource?.getAllProperties?.elements?.[0]?.content as
        | {
            hasLabAssignments?: boolean;
          }
        | undefined;

      return {
        hasLabAssignments: content?.hasLabAssignments,
      };
    },
  }),
  withRenderNothing
)(MiniSDPContainer);
