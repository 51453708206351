/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { ChevronDownIcon } from '@coursera/cds-icons';

const styles = {
  icon: css`
    height: 8px;
    width: 8px;
  `,
};

const DropdownIcon: React.FC = () => {
  return <ChevronDownIcon css={styles.icon} />;
};

export default React.memo(DropdownIcon);
