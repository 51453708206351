import { useQuery } from '@apollo/client';

import EnterpriseEpicEmailAllocation from 'bundles/saml-sso/components/queries/EnterpriseEpicEmailAllocation.graphql';
import type {
  EnterpriseEpicEmailAllocationQuery as EnterpriseEpicEmailAllocationQueryType,
  EnterpriseEpicEmailAllocationQueryVariables,
} from 'bundles/saml-sso/components/queries/__generated__/EnterpriseEpicEmailAllocation';

const useEpicForcedSsoEnabled = (emailAddress: string) => {
  const { data, loading } = useQuery<
    EnterpriseEpicEmailAllocationQueryType,
    EnterpriseEpicEmailAllocationQueryVariables
  >(EnterpriseEpicEmailAllocation, {
    skip: !emailAddress,
    variables: { emailId: emailAddress || '' },
    context: { clientName: 'gatewayGql' },
  });
  return { isEmailAllocatedForForceSsoEpic: !!data?.EntSso?.queryIfUserEmailForceSso, loading };
};

export default useEpicForcedSsoEnabled;
