import { Specialization_ProductVariant as PRODUCT_VARIANTS } from '__generated__/graphql-types';

import type { ProductCardCourseFragmentFragment as CourseType } from 'bundles/program-common/queries/__generated__/ProductCoursesQuery';
import type { Product } from 'bundles/program-common/types/programCommon';
import type ProgramCurriculumProduct from 'bundles/program-home/components/enterprise-home/models/ProgramCurriculumProduct';
import type { SavedContainerS12n_ProductCardS12nFragment as S12nProduct } from 'bundles/program-home/queries/__generated__/SavedContainerS12nQuery';
import mapExists from 'bundles/program-home/utils/mapExists';
import { LEARNING_PRODUCTS } from 'bundles/search-common/constants';
import ROUTE_CONFIG from 'bundles/unified-description-page-common/constants/routeConfig';

export const mapProductStates = (savedProducts: ProgramCurriculumProduct[]) => {
  const s12nIds: string[] = [];
  const courseIds: string[] = [];
  const displayOrderIds: string[] = [];

  // Map each productState to its id and add id to corresponding list
  savedProducts.forEach((state) => {
    if (state.isS12n && state.s12nId) {
      s12nIds.push(state.s12nId);
      displayOrderIds.push(state.s12nId);
    } else if (state.isCourse && state.courseId) {
      courseIds.push(state.courseId);
      displayOrderIds.push(state.courseId);
    }
  });

  return { s12nIds, courseIds, displayOrderIds };
};

export const mapS12nToProduct = (s12ns: Array<S12nProduct>): Array<Product> => {
  return s12ns.map((s12n) => ({
    ...s12n,
    partners: {
      elements: s12n.partners,
    },
    courseIds: s12n.courses.map((course) => course.id),
  }));
};

export const mapCourseToProduct = (courses: Array<CourseType>): Array<Product> => {
  return courses.map((course) => ({
    ...course,
    courseTypeMetadata: {
      courseTypeMetadata: {
        __typename: course?.courseTypeMetadata?.metadata.__typename || '',
      },
    },
    partners: { elements: course?.partners },
  }));
};

export const getOrderedProductsToShow = (
  courses: Array<CourseType>,
  s12ns: Array<S12nProduct>,
  displayOrderIds?: Array<string>
): Array<Product> => {
  // If no display order provided, return merged product lists
  const s12nProduct: Array<Product> = mapS12nToProduct(s12ns);
  const courseProduct = mapCourseToProduct(courses);

  if (!displayOrderIds) {
    const products: Product[] = [...s12nProduct, ...courseProduct];

    return products;
  }
  // Map each product to its id
  const productsObj: Record<string, Product> = {};

  s12nProduct.forEach((s12n) => {
    productsObj[s12n.id] = s12n;
  });

  courseProduct.forEach((course) => {
    productsObj[course.id] = course;
  });
  // Iterate through productStates and construct ordered list
  const products = mapExists(displayOrderIds, (id) => productsObj[id]);

  return products;
};

export const getS12nType = (productVariant: string): string => {
  switch (productVariant) {
    case PRODUCT_VARIANTS.ExternalCertificateS12N:
    case PRODUCT_VARIANTS.GoogleCertificateS12N:
    case PRODUCT_VARIANTS.ProfessionalCertificateS12N:
      return 'ProfessionalCertificate';
    case PRODUCT_VARIANTS.NormalS12N:
    default:
      return 'Specialization';
  }
};

export const getLearningProduct = (productType?: string) => {
  switch (productType) {
    case 'StandardCourse':
      return LEARNING_PRODUCTS.Course;
    case 'GuidedProject':
      return LEARNING_PRODUCTS.GuidedProject;
    case 'Specialization':
      return LEARNING_PRODUCTS.Specialization;
    case 'Project':
      return LEARNING_PRODUCTS.Project;
    case 'ProfessionalCertificate':
      return LEARNING_PRODUCTS.ProfessionalCertificate;
    default:
      return '';
  }
};

export const getRoute = (productType: string) => {
  switch (productType) {
    case LEARNING_PRODUCTS.Course:
      return `/${ROUTE_CONFIG.COURSE.pathname}`;
    case LEARNING_PRODUCTS.GuidedProject:
    case LEARNING_PRODUCTS.Project:
      return `/${ROUTE_CONFIG.PROJECTS.pathname}`;
    case LEARNING_PRODUCTS.Specialization:
      return `/${ROUTE_CONFIG.SPECIALIZATIONS.pathname}`;
    case LEARNING_PRODUCTS.ProfessionalCertificate:
      return `/${ROUTE_CONFIG.PROFESSIONAL_CERTIFICATES.pathname}`;
    default:
      return '';
  }
};
