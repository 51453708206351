import Evaluation from 'bundles/code-evaluator/models/Evaluation';
import EvaluatorAPIUtils from 'bundles/code-evaluator/utils/EvaluatorAPIUtils';

class EvaluationRunner {
  declare evaluatorId: string;

  declare evaluation: Evaluation;

  constructor(evaluatorId: string) {
    this.evaluatorId = evaluatorId;
    this.evaluation = new Evaluation({});
  }

  run(expression: string, onUpdate: (evaluation: Evaluation) => void) {
    this.evaluation = new Evaluation({ active: true });
    onUpdate(this.evaluation);

    const activeEvaluationId = this.evaluation.id;

    EvaluatorAPIUtils.run(this.evaluatorId, expression)
      .then((response) => {
        if (activeEvaluationId === this.evaluation.id) {
          this.evaluation = new Evaluation({ response });
          onUpdate(this.evaluation);
        }
      })
      .fail(() => {
        if (activeEvaluationId === this.evaluation.id) {
          this.evaluation = new Evaluation({ fail: true });
          onUpdate(this.evaluation);
        }
      });
  }

  cancel(onUpdate: (evaluation: Evaluation) => void) {
    this.evaluation = new Evaluation({});
    onUpdate(this.evaluation);
  }
}

export default EvaluationRunner;
