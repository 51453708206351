import * as React from 'react';

type SvgWhatScoresMeanProps = React.SVGProps<SVGSVGElement>;

const SvgWhatScoresMean: React.VFC<SvgWhatScoresMeanProps> = (props) => (
  <svg
    role="presentation"
    width="520"
    height="82"
    viewBox="0 0 520 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 41C1 37.134 4.13401 34 8 34H512C515.866 34 519 37.134 519 41C519 44.866 515.866 48 512 48H8C4.13401 48 1 44.866 1 41Z"
      stroke="#C3C5C6"
      strokeWidth="2"
    />
    <rect x="54" y="34" width="2" height="13" fill="#C4C4C4" />
    <rect x="156" y="34" width="2" height="13" fill="#C4C4C4" />
    <rect x="360" y="34" width="2" height="13" fill="#C4C4C4" />
    <rect x="58" y="37" width="96" height="8" fill="#F2D049" />
    <rect x="160" y="37" width="198" height="8" fill="#F2D049" />
    <path d="M364 37H512C514.209 37 516 38.7909 516 41C516 43.2091 514.209 45 512 45H364V37Z" fill="#F2D049" />
    <path d="M4 41C4 38.7909 5.79086 37 8 37H52V45H8C5.79086 45 4 43.2091 4 41Z" fill="#F2D049" />
    <path d="M12.5 29V23H48.5V29" stroke="#C3C5C6" />
    <path d="M58.5 55V61H153.5V55" stroke="#C3C5C6" />
    <path d="M364.5 55V61H515.5V55" stroke="#C3C5C6" />
    <path d="M160.5 29V23H357.5V29" stroke="#C3C5C6" />
  </svg>
);

export default SvgWhatScoresMean;
