/** @jsxFrag React.Fragment */

/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Grid, Link, PageGridContainer, Typography, Typography2, breakpoints } from '@coursera/cds-core';
import { ChevronPreviousIcon } from '@coursera/cds-icons';

import { getContrastRatio } from 'bundles/coursera-ui/utils/colorUtils';
import { TrackedLink2, TrackedReactLink } from 'bundles/page/components/TrackedLink2';
import { toOrgHome } from 'bundles/program-common/links';
import type { Program, ThirdPartyOrganization } from 'bundles/program-home/components/enterprise-home/EnterpriseBanner';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import { ButtonView, JoinButton } from 'bundles/program-home/components/enterprise-home/EnterpriseBanner';
import { useIsUnifiedAppCheck } from 'bundles/unified-common/contexts/UnifiedAppCheckContext';

import _t from 'i18n!nls/program-home';

export type EnterpriseBannerRendererProps = {
  thirdPartyOrganization: ThirdPartyOrganization;
  program?: Program | null;
  shouldShowJoinButton: boolean;
  joinMessage: string;
  showButtonView?: boolean;
  userId: number;
  isProgramDetailsView: boolean;
  onJoinClicked: () => void;
  isUpswell: boolean;
  isC4cv: boolean;
};

const getAccessibleTextColor = (backgroundColor: string, textColor1: string, textColor2: string): string => {
  const contrast1 = getContrastRatio(textColor1, backgroundColor);
  const contrast2 = getContrastRatio(textColor2, backgroundColor);

  return contrast1 > contrast2 ? textColor1 : textColor2;
};

export function EnterpriseBannerRenderer({
  thirdPartyOrganization,
  program,
  shouldShowJoinButton,
  joinMessage,
  showButtonView,
  userId,
  isProgramDetailsView,
  onJoinClicked,
  isUpswell,
  isC4cv,
}: EnterpriseBannerRendererProps) {
  const bannerBackgroundColor = program?.metadata?.primaryColor || thirdPartyOrganization?.primaryColor || '#00255d';
  // Hard-coding colour values in to avoid having to refactor this component switch accessible text colour
  // with CSS instead of JS.
  // If this gets refactored in the future, here's an example on how to do it with CSS:
  // https://codepen.io/facundocorradini/pen/LBVvyq
  const darkTextColor = '#1f1f1f';
  const lightTextColor = '#ffffff';
  const accessibleTextColor = getAccessibleTextColor(bannerBackgroundColor, darkTextColor, lightTextColor);
  const textColor = accessibleTextColor === darkTextColor ? 'body' : 'invertBody';
  const isInUnifiedApp = useIsUnifiedAppCheck();

  let tagline: string | null;
  if (isC4cv || isUpswell) {
    tagline = 'Coursera for Campus Basic plan';
  } else if (program?.metadata?.tagline) {
    tagline = program.metadata.tagline;
  } else {
    tagline = null;
  }

  const styles = {
    banner: css`
      background-color: ${bannerBackgroundColor};
      display: flex;
      justify-content: center;
      min-height: 132px;
      border-bottom: 1px solid var(--cds-color-grey-50);
    `,
    bannerContainer: css`
      display: grid;
      grid-template-columns: 1fr max-content;
      width: 100%;
      gap: 0 var(--cds-spacing-200);
      align-items: center;

      ${breakpoints.up('xs')} {
        padding: 0 var(--cds-spacing-150);
      }

      ${breakpoints.down('xs')} {
        grid-template-columns: 1fr;
        gap: var(--cds-spacing-150) 0;
        margin: var(--cds-spacing-400) 0;
        padding: 0 var(--cds-spacing-200);
      }
    `,
    topSectionContainer: css`
      h2 {
        margin-bottom: var(--cds-spacing-100);
      }

      a {
        margin-bottom: var(--cds-spacing-200);
        display: inline-block;
        ${breakpoints.down('xs')} {
          margin-bottom: var(--cds-spacing-400);
        }
      }

      p {
        margin-bottom: var(--cds-spacing-200);
      }
    `,
    headingContainer: css`
      width: 100%;
      ${breakpoints.down('xs')} {
        margin-bottom: var(--cds-spacing-100);
      }
    `,
    buttonContainer: css`
      align-self: center;
      display: grid;
      justify-items: flex-end;
      ${breakpoints.down('xs')} {
        grid-template-columns: 1fr;
        justify-items: flex-start;
      }
    `,
    taglineContainer: css`
      width: 100%;
      margin-top: var(--cds-spacing-100);
      ${breakpoints.down('xs')} {
        margin-bottom: var(--cds-spacing-100);
      }
    `,
    title: (shouldTruncate?: boolean) => css`
      display: -webkit-box;
      -webkit-line-clamp: ${shouldTruncate ? 1 : 2};
      -webkit-box-orient: vertical;
      overflow: hidden;
      ${breakpoints.down('xs')} {
        -webkit-line-clamp: 2;
      }
    `,
    tagline: css`
      align-self: start;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      ${breakpoints.down('xs')} {
        -webkit-line-clamp: 3;
      }
    `,
    backBreadcrumb: css`
      /* Need to use an ID selector here to override the Link colour
         coming from CDS since the default dark Link colour would not
         be accessible on some user-selected background colours */
      &#backBreadcrumb {
        color: ${accessibleTextColor};
      }
    `,
  };

  const shouldShowBannerContentRight = shouldShowJoinButton;

  const renderBannerContentLeft = () => {
    const bannerContentLeft = (
      <React.Fragment>
        <div css={styles.topSectionContainer}>
          {shouldShowJoinButton && (
            <Typography2 component="p" color={textColor}>
              {_t('Welcome to #{organization}', { organization: thirdPartyOrganization.name })}
            </Typography2>
          )}
          {!shouldShowJoinButton && showButtonView && isProgramDetailsView && (
            <Link
              href={toOrgHome(thirdPartyOrganization.slug)}
              component={isInUnifiedApp ? TrackedLink2 : TrackedReactLink}
              id="backBreadcrumb"
              trackingName="enterprise_banner_back_to_all_programs_breadcrumb"
              typographyVariant="bodySecondary"
              variant="quiet"
              icon={<ChevronPreviousIcon size="small" />}
              iconPosition="before"
              css={styles.backBreadcrumb}
            >
              {_t('Back to All Learning Programs')}
            </Link>
          )}
          {!isProgramDetailsView && (
            <Typography variant="h2" color={textColor}>
              {_t('All Learning Programs from')}
            </Typography>
          )}
        </div>
        <div css={styles.headingContainer}>
          <Typography
            variant="d2semibold"
            color={textColor}
            component="h1"
            css={styles.title(shouldShowBannerContentRight)}
          >
            {isProgramDetailsView ? program?.metadata?.name : thirdPartyOrganization?.name}
          </Typography>
        </div>
        {tagline && (
          <div css={styles.taglineContainer}>
            <Typography variant="h2" color={textColor} css={styles.tagline}>
              {tagline}
            </Typography>
          </div>
        )}
      </React.Fragment>
    );

    return shouldShowBannerContentRight ? (
      <Grid item xs={12} sm={8}>
        {bannerContentLeft}
      </Grid>
    ) : (
      bannerContentLeft
    );
  };

  const renderBannerContentRight = () => {
    const bannerContentRight = (
      <Grid item xs={12} sm={4} css={styles.buttonContainer}>
        {shouldShowJoinButton && <JoinButton onClick={onJoinClicked}>{joinMessage}</JoinButton>}
        {showButtonView && !shouldShowJoinButton && (
          <ButtonView userId={userId} thirdPartyOrganization={thirdPartyOrganization} program={program} />
        )}
      </Grid>
    );

    return shouldShowBannerContentRight ? bannerContentRight : null;
  };

  return (
    <div css={styles.banner} data-e2e="ProgramBanner">
      <div css={styles.bannerContainer}>
        <PageGridContainer>
          {renderBannerContentLeft()}
          {renderBannerContentRight()}
        </PageGridContainer>
      </div>
    </div>
  );
}
