import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { FillableBlankElement } from 'bundles/cml/shared/types/elementTypes';

import _t from 'i18n!nls/cml';

export const createFillableBlank = (): FillableBlankElement => {
  return {
    type: BLOCK_TYPES.FILLABLE_BLANK,
    isVoid: true,
    isInline: true,
    innerText: _t('Response'),
    responseType: 'multiple_choice',
    // TODO(sgallego): Replace with uuid() from 'bundles/common/utils/uuid'
    // Currently any non numerica values get changed when a
    // draft is saved. Temporary measure until we identify an
    // fix the issue.
    uuid: Math.random().toString().replaceAll('.', ''),
    children: [{ text: '' }],
  };
};
