import type * as React from 'react';

import logger from 'js/app/loggerSingleton';

import type { ButtonName, EnrollmentOptionsType, ModalName, ProductType } from '@coursera/event-pulse-types';
import { useTracker, useVisibilityTracker } from '@coursera/event-pulse/react';

import { courseraPlusProductVariants } from 'bundles/coursera-plus/constants/CourseraPlusProductVariant';
import EnrollmentChoiceTypes from 'bundles/enroll-course/common/EnrollmentChoiceTypes';
import type { EnrollmentChoices } from 'bundles/enroll/components/xdp/withEnrollment';
import type { CourseEnrollModalDataContextType } from 'bundles/enroll/data/PageDataContext';
import usePageData from 'bundles/enroll/data/usePageData';
import {
  getCanEnrollThroughS12nSubscription,
  getCanEnrollThroughS12nSubscriptionFreeTrial,
} from 'bundles/enroll/utils/mixAndMatchUtils';
import type CourseTypeMetadataV1 from 'bundles/naptimejs/resources/courseTypeMetadata.v1';
import { CourseType } from 'bundles/naptimejs/resources/courseTypeMetadata.v1';
import type CoursesV1 from 'bundles/naptimejs/resources/courses.v1';
import type OnDemandSpecializationsV1 from 'bundles/naptimejs/resources/onDemandSpecializations.v1';

const {
  ENROLL_THROUGH_COURSERA_PLUS,
  PURCHASE_COURSERA_TIER_LITE,
  SUBSCRIBE_TO_COURSERA_PLUS,
  ENROLL_THROUGH_S12N_PREPAID,
  ENROLL_THROUGH_S12N_SUBSCRIPTION,
  PRE_ENROLLMENT_ELIGIBLE,
  ENROLL_GUIDED_PROJECT,
  ENROLL_PROJECT,
  PURCHASE_SINGLE_COURSE,
  ENROLL_COURSE,
  AUDIT_COURSE,
} = EnrollmentChoiceTypes;

const shouldSendModalName = (enrollmentChoiceType: string) => {
  return enrollmentChoiceType !== ENROLL_THROUGH_COURSERA_PLUS;
};

const getEnrollModalName = ({
  enrollmentAvailableChoices,
  courseTypeMetadataWithVersion,
  s12n,
}: {
  enrollmentAvailableChoices: EnrollmentChoices;
  courseTypeMetadataWithVersion?: CourseTypeMetadataV1;
  s12n?: OnDemandSpecializationsV1;
}): ModalName | undefined => {
  const {
    isMixAndMatch,
    canEnrollThroughS12nPrepaid,
    canPreEnroll,
    canPurchaseSingleCourse,
    canSubscribeToCourseraLite,
    hasFreeEnrollOptionIntoCourse,
  } = enrollmentAvailableChoices;
  const courseTypeName = courseTypeMetadataWithVersion?.courseTypeMetadata?.typeName;
  const s12nId = s12n?.id;
  const enrollmentData = s12nId && { enrollmentAvailableChoices, isFromS12nSelection: isMixAndMatch, s12nId };

  if (canSubscribeToCourseraLite) {
    return 'subscription_tiers_hero';
  } else if (s12n && canPreEnroll) {
    return 'specialization_subscription_pre_enroll';
  } else if (canEnrollThroughS12nPrepaid) {
    return 'specialization_pass_purchase';
  } else if (enrollmentData && getCanEnrollThroughS12nSubscriptionFreeTrial(enrollmentData)) {
    return 'specialization_subscription_free_trial';
  } else if (enrollmentData && getCanEnrollThroughS12nSubscription(enrollmentData)) {
    return 'specialization_subscription_no_free_trial';
  } else if (courseTypeName === CourseType.RHYME_PROJECT && canPurchaseSingleCourse) {
    return 'guided_project_purchase';
  } else if (courseTypeName === CourseType.PROJECT && canPurchaseSingleCourse) {
    return 'project_purchase';
  } else if (canPurchaseSingleCourse || hasFreeEnrollOptionIntoCourse) {
    return 'standalone_course_purchase';
  } else {
    logger.error('Unable to determine enroll modal name');
    return undefined;
  }
};

type AdditionalProps = { ownershipDays?: number; productItemId?: string } | undefined;

const getEnrollmentOptionsType = (
  enrollmentChoiceType: string,
  { ownershipDays, productItemId }: AdditionalProps = {}
): EnrollmentOptionsType | undefined => {
  if (enrollmentChoiceType === ENROLL_THROUGH_COURSERA_PLUS) {
    return 'enroll_through_coursera_plus';
  } else if (enrollmentChoiceType === PURCHASE_COURSERA_TIER_LITE) {
    return 'clite_monthly_selection';
  } else if (
    enrollmentChoiceType === SUBSCRIBE_TO_COURSERA_PLUS &&
    productItemId === courseraPlusProductVariants.MONTHLY_WITH_FREE_TRIAL
  ) {
    return 'cplus_monthly_selection';
  } else if (
    enrollmentChoiceType === SUBSCRIBE_TO_COURSERA_PLUS &&
    productItemId === courseraPlusProductVariants.ANNUAL_SEVEN_DAY_FREE_TRIAL
  ) {
    return 'cplus_annually_selection';
  } else if (enrollmentChoiceType === ENROLL_THROUGH_S12N_PREPAID && ownershipDays === 30) {
    return 'specialization_payment_pass_one_month';
  } else if (enrollmentChoiceType === ENROLL_THROUGH_S12N_PREPAID && ownershipDays === 90) {
    return 'specialization_payment_pass_three_months';
  } else if (enrollmentChoiceType === ENROLL_THROUGH_S12N_PREPAID && ownershipDays === 180) {
    return 'specialization_payment_pass_six_months';
  } else if (
    enrollmentChoiceType === ENROLL_THROUGH_S12N_SUBSCRIPTION ||
    enrollmentChoiceType === PRE_ENROLLMENT_ELIGIBLE
  ) {
    return 'subscribe_specialization';
  } else if (enrollmentChoiceType === ENROLL_GUIDED_PROJECT) {
    return 'purchase_guided_project';
  } else if (enrollmentChoiceType === ENROLL_PROJECT) {
    return 'purchase_project';
  } else if (enrollmentChoiceType === PURCHASE_SINGLE_COURSE) {
    return 'purchase_course';
  } else if (enrollmentChoiceType === ENROLL_COURSE) {
    return 'enroll_free_course';
  } else if (enrollmentChoiceType === AUDIT_COURSE) {
    return 'audit_course';
  } else {
    logger.error(`Unable to determine enrollment options type for enrollmentChoiceType ${enrollmentChoiceType}`);
    return undefined;
  }
};

const getCourseTypeNameForEventing = (courseTypeMetadataWithVersion?: CourseTypeMetadataV1): ProductType => {
  const courseTypeName = courseTypeMetadataWithVersion?.courseTypeMetadata?.typeName;

  if (courseTypeName === CourseType.RHYME_PROJECT) {
    return 'guided_project';
  } else if (courseTypeName === CourseType.PROJECT) {
    return 'project';
  } else {
    return 'course';
  }
};

export const getProductInfo = ({
  enrollmentChoiceType,
  isSpecialization,
  s12n,
  course,
  courseTypeMetadataWithVersion,
}: {
  enrollmentChoiceType?: string;
  isSpecialization: boolean;
  s12n?: OnDemandSpecializationsV1;
  course: CoursesV1;
  courseTypeMetadataWithVersion?: CourseTypeMetadataV1;
}) => {
  let shouldUseCourse;

  // Always use the course for audit (as opposed to the s12nId for a course in s12n)
  if (enrollmentChoiceType === AUDIT_COURSE) {
    shouldUseCourse = true;
    // For C+ enrollments, use the s12n if on the SDP, and the course if on the CDP/SCDP
    // `isSpecialization` is a flag that is true when viewing on the SDP
  } else if (enrollmentChoiceType === ENROLL_THROUGH_COURSERA_PLUS) {
    shouldUseCourse = !isSpecialization;
    // Otherwise, for all other cases (standalone course, s12n subscription, course-in-s12n),
    // use the s12n if available, and if not, then the course
    // `s12n` is also available when on the SCDP for a course part of s12n,
    // so it is *not* equivalent to `isSpecialization`
  } else {
    shouldUseCourse = !s12n;
  }

  if (shouldUseCourse) {
    return { id: course.id, type: getCourseTypeNameForEventing(courseTypeMetadataWithVersion) };
  } else {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return { id: s12n!.id, type: 's12n' as const };
  }
};

export const useEnrollModalEventing = () => {
  const track = useTracker();
  // `courseTypeMetadataWithVersion` only exists in CourseEnrollModalDataContextType, so will access it assuming it can be undefined
  const { course, courseTypeMetadataWithVersion, s12n, enrollmentAvailableChoices, isSpecialization } =
    usePageData() as CourseEnrollModalDataContextType;

  const modalName = getEnrollModalName({ enrollmentAvailableChoices, courseTypeMetadataWithVersion, s12n });

  const getEnrollModalContinueButtonName = (enrollmentChoiceType?: string) => {
    // If auditing a course part of a s12n, audit is presented as a separate link,
    // so use the `audit` button name instead
    if (s12n?.id && enrollmentChoiceType === AUDIT_COURSE) {
      return 'audit';
    }

    return 'continue_process';
  };

  const getEnrollModalTrackingRef = (): React.MutableRefObject<HTMLDivElement | null> | undefined => {
    const product = getProductInfo({ isSpecialization, s12n, course, courseTypeMetadataWithVersion });

    if (!modalName || !product.id) {
      return undefined;
    }

    const visibilityTrackingRef: React.MutableRefObject<HTMLDivElement | null> = useVisibilityTracker('view_modal', {
      modal: {
        name: modalName,
      },
      product,
    });

    return visibilityTrackingRef;
  };

  const trackEnrollModalContinue = (enrollmentChoiceType: string, additionalProps?: AdditionalProps) => {
    const enrollmentOptionsType = getEnrollmentOptionsType(enrollmentChoiceType, additionalProps);
    const product = getProductInfo({
      enrollmentChoiceType,
      isSpecialization,
      s12n,
      course,
      courseTypeMetadataWithVersion,
    });
    const shouldSendName = shouldSendModalName(enrollmentChoiceType);

    if ((shouldSendName && !modalName) || !product.id || !enrollmentOptionsType) {
      return;
    }

    track('click_button', {
      button: {
        name: getEnrollModalContinueButtonName(enrollmentChoiceType),
      },
      ...(shouldSendName && modalName
        ? {
            modal: {
              name: modalName,
            },
          }
        : {}),
      product,
      enrollmentOptions: {
        enrollmentOptionsType,
      },
    });
  };

  const trackEnrollModalSecondaryCta = (name: ButtonName) => {
    const product = getProductInfo({ isSpecialization, s12n, course, courseTypeMetadataWithVersion });

    if (!modalName || !product.id) {
      return;
    }

    track('click_button', {
      button: {
        name,
      },
      modal: {
        name: modalName,
      },
      product,
    });
  };

  const trackEnrollModalClose = () => {
    trackEnrollModalSecondaryCta('dismiss');
  };

  return {
    getEnrollModalTrackingRef,
    trackEnrollModalContinue,
    trackEnrollModalSecondaryCta,
    trackEnrollModalClose,
  };
};
