import { createContext, useContext } from 'react';

type GroupContextType = {
  label: string;
  id: string;
};

export const GroupContext = createContext<GroupContextType | null>(null);

export const useGroupContext = () => useContext(GroupContext);
