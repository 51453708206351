import * as React from 'react';

import type { RenderElementProps } from 'slate-react';

type Props = RenderElementProps & {
  header?: React.ReactNode;
  rows: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
};

const Table = React.forwardRef<HTMLTableElement, Props>(
  ({ children, attributes, className, header, rows, onClick }, ref) => {
    return (
      <div {...attributes} className={className}>
        <table ref={ref} onClickCapture={onClick}>
          {header && <thead>{header}</thead>}
          <tbody>{rows}</tbody>
        </table>
        {children}
      </div>
    );
  }
);

Table.displayName = 'Table';

export default Table;
