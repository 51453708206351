import { Operation, Text } from 'slate';
import type { BaseOperation, InsertNodeOperation } from 'slate';

const skipEmptyTextElements = (op: BaseOperation) => {
  // Slate inserts empty text elements in a variety of cases. This shouldn't trigger
  // a change event on it's own.
  // One example is inline elements (e.g. links, inline-math, etc...) which cannot be
  // at the start or end of a block. Empty text nodes are merged with neighboring
  // text elements anyway so they can be safely ignored.
  if (!Operation.isNodeOperation(op)) {
    return false;
  }

  const { type } = op;
  if (type !== 'insert_node') {
    return false;
  }

  const { node } = op as InsertNodeOperation;
  return Text.isText(node) && !node.text;
};

const skipSelectionChanges = (op: BaseOperation) => {
  // Positioning the cursor in the document or highlighting text should
  // not trigger a change event.
  return Operation.isSelectionOperation(op);
};

const SKIP_OPERATIONS = [skipEmptyTextElements, skipSelectionChanges];

export const isValidOperation = (op: BaseOperation) => {
  return !SKIP_OPERATIONS.some((skipOperation) => skipOperation(op));
};
