import { compose, withProps } from 'recompose';

import { policies, scopes } from 'bundles/enroll/utils/scopedContentRule';
import withScopedContentRule from 'bundles/enroll/utils/withScopedContentRule';
import type { PropsFromWithScopedContentRule } from 'bundles/enroll/utils/withScopedContentRule';

type ContainerProps = {
  s12nId?: string | null;
  courseId?: string;
};

export type EligibilityCheckProps = {
  isCourseraPlusEligibleProduct: boolean;
};

const withCourseraPlusProductEligibilityCheck = <InputProps extends ContainerProps>(isNotRequired?: boolean) =>
  compose<InputProps & EligibilityCheckProps, InputProps>(
    withScopedContentRule<ContainerProps>(scopes.COURSERA_PLUS_SUBSCRIPTION, isNotRequired),
    withProps<EligibilityCheckProps, InputProps & PropsFromWithScopedContentRule>(({ scopedContentRule }) => ({
      isCourseraPlusEligibleProduct: scopedContentRule?.policy === policies.ALLOW,
    }))
  );

export default withCourseraPlusProductEligibilityCheck;
