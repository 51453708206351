/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import type { AiCoach_Action as AiCoachAction } from '__generated__/graphql-types';
import classNames from 'classnames';

import { get as getUser } from 'js/lib/user';

import { Grid, Typography2, breakpoints } from '@coursera/cds-core';

import CoachIcon from 'bundles/ai-coach-platform/components/branding/CoachIcon';
import {
  CopySingleResponse,
  LearnerAvatar,
  ProgressiveLoader,
} from 'bundles/ai-coach-platform/components/building-blocks';
import ChatEmptyState from 'bundles/ai-coach-platform/components/patterns/chat/common/ChatEmptyState';
import type { SendMessageFunc } from 'bundles/ai-coach-platform/components/patterns/chat/types';
import { firstNameFromFullName } from 'bundles/ai-coach-platform/utils/nameParser';

// import { CoachRecommendations } from 'bundles/ai-course-coach/components/Conversation/components/Messages';
import Feedback from './Feedback';
import ChatMessage from './common/ChatMessage';
import type { GlobalState, MessageTypes } from './store/types';
import {
  isClientMessage as isClientMessageUtil,
  isCoachMessage as isCoachMessageUtil,
  isErrorMessage as isErrorMessageUtil,
  isTextMessage as isTextMessageUtil,
} from './utils/messageUtils';

export type HideMessageType = ({
  message,
  index,
  messages,
}: {
  message: MessageTypes;
  index: number;
  messages: Array<MessageTypes>;
}) => boolean;

type Props = {
  profileClientAvatar?: string;
  sendMessage: SendMessageFunc;
  emptyState?: React.ReactElement;
  courseId?: string;

  /**
   * Hides the message in the chat. This function accepts an individual message and its index
   * returns a boolean value if the message needs to be hidden based on the function's implementation
   * at the parent level.
   * Eg: Hiding the first "initiation" message and chat summary message for coach item use case.
   */
  hideMessage?: HideMessageType;
};

const styles = {
  messagesContainer: () => css`
    /* Hide scrollbar for Chrome, Safari and Opera */

    ::-webkit-scrollbar {
      display: none;
    }

    height: 100%;
    max-height: none;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    ${breakpoints.down('xs')} {
      height: 100%;
      max-height: none;
    }
  `,
  message: css`
    padding: 0 var(--cds-spacing-200);
    margin: var(--cds-spacing-200) 0;
    word-wrap: break-word;

    svg {
      flex-shrink: 0;
    }

    &.coach-message-response {
      border-radius: 0;
      scroll-margin-top: var(--cds-spacing-150);
    }
  `,
  messageContainer: () => css`
    display: flex;
    gap: var(--cds-spacing-100);
  `,
  senderName: css`
    color: var(--cds-color-grey-950);
    font-weight: 600;
  `,
};

const MessageContainer = ({
  avatar,
  name,
  children,
}: {
  /**
   * Message sender avatar
   */
  avatar?: React.ReactNode;
  /**
   * Message sender name
   */
  name: string;
  /**
   * Message content
   */
  children: React.ReactNode;
}) => {
  return (
    <div className="coach-message-container" css={styles.messageContainer}>
      {avatar}
      <Grid
        container
        css={css`
          overflow: auto;
          display: flex;
          flex-direction: column;
          gap: none;
        `}
      >
        <Grid item>
          <Typography2 component="h4" variant="subtitleMedium" css={styles.senderName}>
            {name}
          </Typography2>
        </Grid>

        <Grid
          item
          css={css`
            max-width: 100%;
          `}
        >
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

const CoachLoader = ({ action }: { action?: AiCoachAction }): React.ReactElement => {
  const coachIconToRender = <CoachIcon size="medium" />;
  return (
    <div className="coach-message" css={styles.message} role="status" aria-live="polite">
      <MessageContainer avatar={coachIconToRender} name="Coach">
        <ProgressiveLoader action={action} />
      </MessageContainer>
    </div>
  );
};

const ChatMessages = ({ profileClientAvatar, emptyState, hideMessage }: Props) => {
  const { messages, showMessageLoader } = useSelector((state: GlobalState) => ({
    messages: state.messages.messages,
    showMessageLoader: state.behavior.messageLoader,
  }));

  const lastMessageRef = useRef<HTMLDivElement | null>(null);
  const messageContainerRef = useRef<HTMLDivElement | null>(null);

  const messageCount = messages.length;

  // scroll latest message into view every time `messageCount` updates i.e. new message is added
  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView?.({ behavior: 'smooth' });
    }
  }, [messageCount]);

  const { fullName: userName, id: userId } = getUser();
  const userFirstName = firstNameFromFullName(userName);

  const learnerAvatarToRender = <LearnerAvatar imageUrl={profileClientAvatar} name={userFirstName} />;
  const coachIconToRender = <CoachIcon size="medium" />;

  const emptyStateRenderer = () => {
    return emptyState ?? <ChatEmptyState />;
  };

  return (
    <div
      data-testid="ai-coach-chat-messages"
      ref={messageContainerRef}
      css={[
        styles.messagesContainer,
        !messages?.length &&
          css`
            height: 100%;
          `,
      ]}
    >
      {!messages?.length && emptyStateRenderer()}
      {messages?.map((message, index) => {
        const { timestamp, sender } = message;

        const isLastMessage = index === messages.length - 1;

        const isCoachMessage = isCoachMessageUtil(sender);
        const isClientMessage = isClientMessageUtil(sender);

        // only allow Coach text messages to be copied
        const showCopyMessage = isCoachMessage && isTextMessageUtil(message as MessageTypes);
        const isErrorMessage = isCoachMessage && isErrorMessageUtil(message as MessageTypes);

        if (hideMessage && hideMessage({ message, index, messages })) {
          return null;
        }

        return (
          <div
            className={classNames('coach-message', {
              'coach-message-client': isClientMessage,
              'coach-message-response': isCoachMessage,
            })}
            key={isCoachMessage ? message.customId : `${userId}-${timestamp.valueOf()}`}
            css={styles.message}
            ref={isLastMessage ? lastMessageRef : null}
            // we only want the latest message to be called out, not the entire chat history,
            // see https://coursera.atlassian.net/browse/COACH-130
            aria-live={isLastMessage ? 'polite' : 'off'}
          >
            <MessageContainer
              avatar={isClientMessage ? learnerAvatarToRender : coachIconToRender}
              name={isClientMessage ? userFirstName : 'Coach'} // 'Coach' is intentionally not translated since it is a product name
            >
              <ChatMessage message={message as MessageTypes} />
              {/* {isCoachMessage && (
                // @ts-expect-error
                <CoachRecommendations message={message} sendMessage={sendMessage} courseId={courseId} />
              )} */}
              {/* hide message actions for Coach error messages */}
              {!isErrorMessage && (
                <div
                  className="coach-message-actions"
                  css={css`
                    display: flex;
                    justify-content: end;
                    align-items: center;
                  `}
                >
                  {showCopyMessage && <CopySingleResponse message={message.text} />}
                  <Feedback message={message as MessageTypes} />
                </div>
              )}
            </MessageContainer>
          </div>
        );
      })}
      {showMessageLoader && <CoachLoader action={messages[messages.length - 1]?.action} />}
    </div>
  );
};

export default ChatMessages;
