import * as React from 'react';

import PropTypes from 'prop-types';

import { Box, StyleSheet } from '@coursera/coursera-ui';

import PageFooter from 'bundles/page-footer/components/PageFooter';
import PageHeader from 'bundles/page-header/components/PageHeader';
import { CourseraMetatags } from 'bundles/seo';

const styles = StyleSheet.create({
  errorPageContent: {
    height: '100vh',
  },
});

export default function ErrorPage({ title = 'ErrorPage', errorPageMsg, errorPageIgnoreChildren, children }: $TSFixMe) {
  return (
    <div className="ErrorPage">
      <PageHeader />
      <Box rootClassName={styles.errorPageContent} flexDirection="column" alignItems="center" justifyContent="center">
        <div className="max-text-width">
          <h2>{errorPageMsg}</h2>
          {!errorPageIgnoreChildren && children}
        </div>
      </Box>
      <PageFooter />
      {/* For robots */}
      <CourseraMetatags
        metaNameAndProperties={{
          title,
          disableCourseraSuffix: true,
          disableCrawlerIndexing: true,
        }}
      />
    </div>
  );
}

ErrorPage.propTypes = {
  title: PropTypes.string,
  errorPageMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
};
