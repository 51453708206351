import * as React from 'react';

import type { EnterpriseSkills_Skillset as SkillSet } from '__generated__/graphql-types';

import { Grid, Hidden } from '@coursera/cds-core';

import enterpriseEpic from 'bundles/epic/clients/Enterprise';
import { SkillSetCardV2, SkillSetCardV2Placeholder } from 'bundles/program-home/components/multiprogram/SkillSetCardV2';
import { SkillSetCardV3, SkillSetCardV3Placeholder } from 'bundles/program-home/components/multiprogram/SkillSetCardV3';
import type { ObjectiveListQuery_EnterpriseTargetSkillProfileSummariesV1Resource_byProgram_elements as TargetSkillProficiencySummary } from 'bundles/program-home/components/multiprogram/__generated__/ObjectiveListQuery';

type SkillSetGridProp = {
  objectives: TargetSkillProficiencySummary[] | SkillSet[];
  programSlug: string;
};

type PlaceholderProp = {
  skillSetLength?: number;
};

export const MAX_NUMBER_OF_SKILLSETS = 6;
export const SkillSetsGrid: React.FC<SkillSetGridProp> = ({ objectives, programSlug }) => {
  const enableHomeReorganize = enterpriseEpic.get('enableHomeReorganize');

  return (
    <Grid container spacing={24}>
      {objectives.slice(0, MAX_NUMBER_OF_SKILLSETS).map((objective, index) => (
        <Grid
          component={Hidden}
          item
          md={4}
          sm={index < 4 ? 6 : undefined}
          xs={index < 2 ? 12 : undefined}
          smDown={index >= 4}
          xsDown={index >= 2}
          key={objective.id}
        >
          {enableHomeReorganize ? (
            <SkillSetCardV3 skillSummary={objective} programSlug={programSlug} skillSetOrder={index + 1} />
          ) : (
            <SkillSetCardV2 skillSummary={objective} programSlug={programSlug} skillSetOrder={index + 1} />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export const SkillSetsGridPlaceholder: React.FC<PlaceholderProp> = ({ skillSetLength }) => {
  const enableHomeReorganize = enterpriseEpic.get('enableHomeReorganize');

  return (
    <Grid container spacing={24}>
      {[...Array(skillSetLength && skillSetLength <= 6 ? skillSetLength : 6).keys()].map((placeholder, index) => (
        <Grid
          component={Hidden}
          item
          md={4}
          sm={index < 4 ? 6 : undefined}
          xs={index < 2 ? 12 : undefined}
          smDown={index >= 4}
          xsDown={index >= 2}
          key={placeholder}
        >
          {enableHomeReorganize ? <SkillSetCardV3Placeholder /> : <SkillSetCardV2Placeholder />}
        </Grid>
      ))}
    </Grid>
  );
};
