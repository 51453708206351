import gql from 'graphql-tag';

export const CourseMetadataForCourseRecommendations = gql`
  query CourseMetadataForCourseRecommendations($courseIds: [String!]!) {
    XdpV1Resource {
      multiGet(ids: $courseIds) {
        elements {
          id
          xdpMetadata {
            __typename
            ... on XdpV1_cdpMetadataMember {
              cdpMetadata {
                id
                skills
                level
                totalContentWeeks
              }
            }
          }
        }
      }
    }
  }
`;
