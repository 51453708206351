import { createContext, useContext } from 'react';

export type ManagedFocusContextType = {
  focusedButton: HTMLButtonElement | null;
  setFocusedButton: (button: HTMLButtonElement | null) => void;
  focusNext: () => void;
  focusPrevious: () => void;
};

export const ManagedFocusContext = createContext<ManagedFocusContextType>({
  focusedButton: null,
  setFocusedButton: () => undefined,
  focusNext: () => undefined,
  focusPrevious: () => undefined,
});

export const useManagedFocus = () => useContext(ManagedFocusContext);
