import * as React from 'react';

import type { IconSize } from 'bundles/ai-coach-platform/types';

/**
 * MUI icon for "Thumb up" chat action
 */
const ThumbUpIcon = ({ size = 16, filled = false }: { size?: IconSize; filled?: boolean }) =>
  filled ? (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8293 6.10101C14.1308 6.10101 14.4013 6.22075 14.6408 6.46023C14.8803 6.69971 15 6.9702 15 7.27169V8.64009C15 8.7608 14.984 8.90256 14.9519 9.06539C14.9198 9.22832 14.8771 9.3681 14.8236 9.48474L12.9045 13.9389C12.7903 14.177 12.6208 14.3789 12.396 14.5445C12.1711 14.7101 11.9326 14.7929 11.6804 14.7929H5.0252C4.69378 14.7929 4.41581 14.6806 4.19129 14.4561C3.96678 14.2316 3.85452 13.9536 3.85452 13.6222V6.56607C3.85452 6.42196 3.88227 6.27912 3.93776 6.13757C3.99324 5.99602 4.07775 5.87158 4.19129 5.76424L7.33448 2.41258C7.52692 2.19876 7.75678 2.06779 8.02406 2.01968C8.29133 1.97157 8.54257 2.01166 8.77778 2.13996C9.00229 2.27894 9.16533 2.47673 9.2669 2.73331C9.36846 2.9899 9.38717 3.25718 9.32302 3.53515L8.7457 6.10101H13.8293ZM1.94424 14.7929C1.68893 14.7929 1.46774 14.6988 1.28064 14.5108C1.09355 14.3227 1 14.1014 1 13.8467V7.04717C1 6.7924 1.09419 6.57104 1.28257 6.38309C1.47105 6.19504 1.69289 6.10101 1.94809 6.10101C2.20339 6.10101 2.42459 6.19504 2.61168 6.38309C2.79878 6.57104 2.89233 6.7924 2.89233 7.04717V13.8467C2.89233 14.1014 2.79814 14.3227 2.60976 14.5108C2.42128 14.6988 2.19943 14.7929 1.94424 14.7929Z"
        fill="var(--cds-color-grey-950)"
      />
    </svg>
  ) : (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.7958 6.15908C14.1088 6.15908 14.3826 6.27643 14.6173 6.51113C14.852 6.74582 14.9693 7.01964 14.9693 7.33257V8.70169C14.9693 8.78153 14.9773 8.86533 14.9934 8.9531C15.0095 9.04087 14.9961 9.12227 14.9532 9.19729L12.8691 14.0158C12.756 14.2572 12.5856 14.4601 12.3579 14.6247C12.1303 14.7895 11.8918 14.8718 11.6424 14.8718H4.24718V6.15908L7.75157 2.39749C7.94018 2.19388 8.16625 2.06795 8.42978 2.01973C8.69319 1.9715 8.94402 2.01169 9.18225 2.14029C9.41802 2.26889 9.58681 2.4618 9.68862 2.719C9.79043 2.9762 9.80758 3.24412 9.74006 3.52276L9.16618 6.15908H13.7958ZM5.42067 6.64134V13.6983H11.7543L13.7958 8.82756V7.33257H7.68727L8.60355 3.26555L5.42067 6.64134ZM2.17349 14.8718C1.84395 14.8718 1.56601 14.7586 1.33967 14.5321C1.11322 14.3058 1 14.0279 1 13.6983V7.33257C1 7.00303 1.11322 6.72503 1.33967 6.49859C1.56601 6.27225 1.84395 6.15908 2.17349 6.15908H4.24718V7.33257H2.17349V13.6983H4.24718V14.8718H2.17349Z"
        fill="var(--cds-color-grey-950)"
      />
    </svg>
  );

export default ThumbUpIcon;
