import * as React from 'react';

import _t from 'i18n!nls/program-common';

type SvgCredentialsProps = React.SVGProps<SVGSVGElement>;

const SvgCredentials: React.FC<SvgCredentialsProps> = (props) => (
  <svg height={62} viewBox="0 0 60 62" width={60} role="presentation" {...props}>
    <title id="SvgCredentials-Title">{_t('Shareable credentials')}</title>
    <g fill="none" fillRule="evenodd" transform="translate(0 2)">
      <rect height={60} opacity={0.8} rx={15} width={60} y={-1} />
      <path
        d="M32.413-.386L58.14 16.177a4 4 0 011.81 3.813h.037v19.022h-.107a4 4 0 01-1.74 2.492L31.918 58.386a4 4 0 01-4.33 0L1.858 41.823a3.99 3.99 0 01-1.796-2.81H.012V19.988h.014a4 4 0 011.833-3.494L28.082-.384a4 4 0 014.33 0z"
        fill="#2a73cc"
        fillOpacity={0.1}
      />
      <path
        d="M36.906 27.625a5.781 5.781 0 014.72 9.12l-.001 9.568-4.72-2.783-4.718 2.784v-9.567a5.781 5.781 0 014.72-9.122zm2.72 10.884a5.756 5.756 0 01-2.72.679 5.756 5.756 0 01-2.719-.678v4.301l2.718-1.603 2.72 1.604zM48 17v24.313h-3.218v-2L46 39.312V19H14v20.312h14.521v2H12V17zM36.906 29.625a3.781 3.781 0 100 7.563 3.781 3.781 0 000-7.563zm-11.093-2.531v2h-7.438v-2zm16.937-4.25v2H18.312v-2z"
        fill="#1f1f1f"
        fillRule="nonzero"
        opacity={0.75}
      />
    </g>
  </svg>
);

export default SvgCredentials;
