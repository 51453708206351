export const PaymentChoiceTypes = {
  PROGRAM: 'program',
  GROUP: 'group',
  FULL: 'full',
  SINGLE: 'single',
  FREE: 'free',
  SUBSCRIPTION: 'subscription',
} as const;

export type PaymentChoiceType = typeof PaymentChoiceTypes[keyof typeof PaymentChoiceTypes];
