import * as React from 'react';

import type { SkillSetInfoSvgProps } from 'bundles/program-home/components/multiprogram/SkillSetInformationCard';

const SkillSetInfo3Svg: React.FC<SkillSetInfoSvgProps> = (props) => (
  <svg viewBox="0 0 509 217" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.261 121.517L103.899 109.129C102.79 108.29 101.38 108.29 100.272 109.129L83.5692 121.775C83.3659 121.929 83.177 122.108 83.0057 122.309H13.1257C6.77189 122.309 1.62109 127.459 1.62109 133.811C1.62109 140.164 6.77186 145.314 13.1257 145.314H84.8812L99.9081 156.691C101.017 157.53 102.427 157.53 103.536 156.691L118.563 145.314H233.427C239.781 145.314 244.932 140.164 244.932 133.811C244.932 127.459 239.781 122.309 233.427 122.309H121.026C120.811 122.008 120.555 121.74 120.261 121.517Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.1981 124.892H13.1257C8.19884 124.892 4.20485 128.885 4.20485 133.811C4.20485 138.737 8.19884 142.731 13.1257 142.731H85.7491L101.468 154.631C101.593 154.726 101.68 154.737 101.722 154.737C101.764 154.737 101.85 154.726 101.976 154.631L117.695 142.731H233.427C238.354 142.731 242.348 138.737 242.348 133.811C242.348 128.885 238.354 124.892 233.427 124.892H119.696L118.923 123.81C118.849 123.707 118.774 123.631 118.701 123.577L102.339 111.189C102.214 111.094 102.127 111.083 102.085 111.083C102.044 111.083 101.957 111.094 101.831 111.189L85.129 123.834C85.0807 123.871 85.027 123.92 84.9717 123.985L84.1981 124.892ZM121.026 122.309H233.427C239.781 122.309 244.932 127.459 244.932 133.811C244.932 140.164 239.781 145.314 233.427 145.314H118.563L103.536 156.691C102.427 157.53 101.017 157.53 99.9081 156.691L84.8812 145.314H13.1257C6.77186 145.314 1.62109 140.164 1.62109 133.811C1.62109 127.459 6.77189 122.309 13.1257 122.309H83.0057C83.177 122.108 83.3659 121.929 83.5692 121.775L100.272 109.129C101.38 108.29 102.79 108.29 103.899 109.129L120.261 121.517C120.555 121.74 120.811 122.008 121.026 122.309Z"
        fill="#C3C5C6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.47 137.838L95.3989 141.037L96.5585 134.261L91.6465 129.462L98.4345 128.474L101.47 122.309L104.506 128.474L111.294 129.462L106.382 134.261L107.542 141.037L101.47 137.838Z"
        fill="#D69700"
      />
      <path
        d="M6.21191 133.807C6.21191 129.995 9.30237 126.905 13.1147 126.905H66.81V140.708H13.1147C9.30237 140.708 6.21191 137.618 6.21191 133.807Z"
        fill="#D69700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.0571 54.4205L53.6069 41.9662C52.492 41.1223 51.0745 41.1223 49.9596 41.9662L33.1675 54.6796C32.9634 54.8341 32.7743 55.0134 32.6025 55.2143H13.1144C6.72639 55.2143 1.54785 60.3921 1.54785 66.7791C1.54785 73.1662 6.72637 78.3437 13.1144 78.3437H34.484L49.5943 89.7837C50.7089 90.6277 52.1266 90.6277 53.2415 89.7837L68.3518 78.3437H234.603C240.991 78.3437 246.17 73.1662 246.17 66.7791C246.17 60.3921 240.991 55.2143 234.603 55.2143H70.8242C70.6085 54.9126 70.3522 54.6439 70.0571 54.4205Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5674 56.892L33.7938 57.7977H13.1144C8.15335 57.7977 4.13161 61.8189 4.13161 66.7791C4.13161 71.7394 8.15335 75.7604 13.1144 75.7604H35.3519L51.1541 87.724C51.2828 87.8214 51.3727 87.8333 51.4179 87.8333C51.4631 87.8333 51.553 87.8217 51.6817 87.724L67.484 75.7604H234.603C239.564 75.7604 243.586 71.7394 243.586 66.7791C243.586 61.8189 239.564 57.7977 234.603 57.7977H69.4952L68.7221 56.7165C68.6477 56.6124 68.5709 56.5357 68.4973 56.4802L52.0471 44.0259C51.9184 43.9283 51.8285 43.9166 51.7833 43.9166C51.738 43.9166 51.6481 43.9283 51.5195 44.0259L34.7271 56.739L33.1675 54.6796L49.9596 41.9662C51.0745 41.1223 52.492 41.1223 53.6069 41.9662L70.0571 54.4205C70.3522 54.6439 70.6085 54.9126 70.8242 55.2143H234.603C240.991 55.2143 246.17 60.3921 246.17 66.7791C246.17 73.1662 240.991 78.3437 234.603 78.3437H68.3518L53.2415 89.7837C52.1266 90.6277 50.7089 90.6277 49.5943 89.7837L34.484 78.3437H13.1144C6.72637 78.3437 1.54785 73.1662 1.54785 66.7791C1.54785 60.3921 6.72639 55.2143 13.1144 55.2143H32.6025C32.7743 55.0134 32.9634 54.8341 33.1675 54.6796L34.7271 56.739C34.6778 56.7765 34.6232 56.8263 34.5674 56.892Z"
        fill="#C3C5C6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.1645 70.8283L45.0606 74.0446L46.2264 67.2323L41.2881 62.4079L48.1126 61.4139L51.1645 55.2159L54.2167 61.4139L61.0412 62.4079L56.1028 67.2323L57.2686 74.0446L51.1645 70.8283Z"
        fill="#D69700"
      />
      <path
        d="M6.16309 66.7765C6.16309 62.9441 9.27021 59.8376 13.103 59.8376H26.933V73.715H13.103C9.27021 73.715 6.16309 70.6086 6.16309 66.7765Z"
        fill="#D69700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M211.61 182.126L196.157 171.1C195.037 170.3 193.611 170.3 192.491 171.1L177.038 182.126H11.6269C5.20555 182.126 0 187.33 0 193.751C0 200.171 5.20555 205.376 11.6269 205.376H176.673L192.124 216.4C193.244 217.2 194.669 217.2 195.79 216.4L211.241 205.376H234.27C240.692 205.376 245.897 200.171 245.897 193.751C245.897 187.33 240.692 182.126 234.27 182.126H211.61Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M210.783 184.709L194.656 173.202C194.524 173.108 194.411 173.083 194.324 173.083C194.238 173.083 194.124 173.108 193.992 173.202L177.866 184.709H11.6269C6.6325 184.709 2.58376 188.757 2.58376 193.751C2.58376 198.744 6.63253 202.792 11.6269 202.792H177.5L193.625 214.298C193.756 214.392 193.87 214.417 193.957 214.417C194.043 214.417 194.157 214.392 194.289 214.298L210.413 202.792H234.27C239.265 202.792 243.314 198.744 243.314 193.751C243.314 188.757 239.265 184.709 234.27 184.709H210.783ZM211.61 182.126L196.157 171.1C195.037 170.3 193.611 170.3 192.491 171.1L177.038 182.126H11.6269C5.20555 182.126 0 187.33 0 193.751C0 200.171 5.20555 205.376 11.6269 205.376H176.673L192.124 216.4C193.244 217.2 194.669 217.2 195.79 216.4L211.241 205.376H234.27C240.692 205.376 245.897 200.171 245.897 193.751C245.897 187.33 240.692 182.126 234.27 182.126H211.61Z"
        fill="#C3C5C6"
      />
      <path
        d="M4.63867 193.747C4.63867 189.895 7.76199 186.772 11.6148 186.772H183.263V200.722H11.6148C7.76202 200.722 4.63867 197.599 4.63867 193.747Z"
        fill="#D69700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M195.212 177.895L207.582 186.155C208.396 186.698 208.818 187.644 208.778 188.595V198.849C208.78 199.351 208.645 199.858 208.375 200.314C208.169 200.662 207.892 200.956 207.565 201.175L194.937 209.606C194.098 210.166 193.032 210.166 192.194 209.606L179.824 201.347C178.994 200.793 178.57 199.82 178.629 198.849V188.595C178.637 188.112 178.771 187.626 179.031 187.187C179.236 186.839 179.513 186.545 179.841 186.326L192.469 177.895C193.307 177.336 194.373 177.336 195.212 177.895Z"
        fill="#D69700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M193.703 197.819L187.567 201.053L188.739 194.205L183.775 189.355L190.635 188.356L193.703 182.126L196.771 188.356L203.631 189.355L198.667 194.205L199.839 201.053L193.703 197.819Z"
        fill="white"
      />
      <path
        d="M477.083 129.167C477.083 177.676 437.959 217 389.7 217C341.441 217 302.317 177.676 302.317 129.167C302.317 80.6575 341.441 41.3333 389.7 41.3333C437.959 41.3333 477.083 80.6575 477.083 129.167Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M389.7 214.417C436.52 214.417 474.499 176.262 474.499 129.167C474.499 82.0714 436.52 43.9166 389.7 43.9166C342.88 43.9166 304.901 82.0714 304.901 129.167C304.901 176.262 342.88 214.417 389.7 214.417ZM389.7 217C437.959 217 477.083 177.676 477.083 129.167C477.083 80.6575 437.959 41.3333 389.7 41.3333C341.441 41.3333 302.317 80.6575 302.317 129.167C302.317 177.676 341.441 217 389.7 217Z"
        fill="#929599"
      />
      <path
        d="M461.663 129.167C461.663 169.115 429.444 201.5 389.7 201.5C349.957 201.5 317.74 169.115 317.74 129.167C317.74 89.218 349.957 56.8334 389.7 56.8334C429.444 56.8334 461.663 89.218 461.663 129.167Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M389.7 198.917C428.005 198.917 459.079 167.702 459.079 129.167C459.079 90.6319 428.005 59.4167 389.7 59.4167C351.396 59.4167 320.324 90.6319 320.324 129.167C320.324 167.702 351.396 198.917 389.7 198.917ZM389.7 201.5C429.444 201.5 461.663 169.115 461.663 129.167C461.663 89.218 429.444 56.8334 389.7 56.8334C349.957 56.8334 317.74 89.218 317.74 129.167C317.74 169.115 349.957 201.5 389.7 201.5Z"
        fill="#929599"
      />
      <path
        d="M446.243 129.167C446.243 160.555 420.927 186 389.7 186C358.473 186 333.16 160.555 333.16 129.167C333.16 97.7784 358.473 72.3334 389.7 72.3334C420.927 72.3334 446.243 97.7784 446.243 129.167Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M389.7 183.417C419.488 183.417 443.659 159.141 443.659 129.167C443.659 99.1923 419.488 74.9167 389.7 74.9167C359.914 74.9167 335.743 99.1923 335.743 129.167C335.743 159.141 359.914 183.417 389.7 183.417ZM389.7 186C420.927 186 446.243 160.555 446.243 129.167C446.243 97.7784 420.927 72.3334 389.7 72.3334C358.473 72.3334 333.16 97.7784 333.16 129.167C333.16 160.555 358.473 186 389.7 186Z"
        fill="#929599"
      />
      <path
        d="M366.064 144.586V115.255L391.008 100.592L415.951 115.261V144.586L391.008 159.249L366.064 144.586Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M364 114.045L391.007 98.1667L417.99 114.045V145.803L391.007 161.681L364 145.796V114.045ZM413.874 116.471L391.007 103.024L368.141 116.471V143.371L391.007 156.824L413.874 143.377V116.471Z"
        fill="#D69700"
      />
      <path d="M413.874 116.503L418.016 114.045V145.803L413.869 143.364L413.874 116.503Z" fill="#FFC500" />
      <path d="M364 145.796L368.097 143.345L391.046 156.837L391.008 161.675L364 145.796Z" fill="#FFC500" />
      <path d="M368.115 116.464L364 114.045L391.008 98.1667L390.982 102.985L368.115 116.464Z" fill="#FFC500" />
      <path
        d="M391.008 117.093L395.098 125.54L404.257 126.892L397.633 133.47L399.196 142.751L391.008 138.372L382.82 142.751L384.383 133.47L377.758 126.892L386.913 125.54L391.008 117.093Z"
        fill="#FFC500"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M391.011 112.306L396.496 123.636L408.689 125.436L399.873 134.189L401.961 146.597L391.008 140.74L380.056 146.597L382.146 134.189L373.33 125.436L385.515 123.636L391.011 112.306ZM391.008 121.881L388.308 127.444L382.187 128.348L386.621 132.75L385.585 138.905L391.008 136.005L396.431 138.905L395.395 132.75L399.829 128.349L393.7 127.444L391.008 121.881Z"
        fill="#D69700"
      />
      <path d="M396.948 114.855L404.802 85.3938L426.258 106.961L396.948 114.855Z" fill="#00255D" />
      <path
        d="M483.866 2.49658V22.1642L504.781 21.6286L486.938 41.5892H466.622V21.6286L483.866 2.49658Z"
        fill="#00255D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M471.943 41.3331L415.4 98.1664L411.757 94.5033L468.298 37.6699L471.943 41.3331Z"
        fill="#00255D"
      />
    </g>
    <defs>
      <clipPath id="SkillSetInfo3-clip0">
        <rect width="509" height="217" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SkillSetInfo3Svg;
