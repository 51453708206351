import { tupleToStringKey } from 'js/lib/stringKeyTuple';
import user from 'js/lib/user';

import { getProductFromContentful } from 'bundles/coursera-plus-landing-page/contentful/constants';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import { shouldGetFreeTrials } from 'bundles/coursera-plus-landing-page/contentfulData/LandingPageDataProvider';
import type {
  PropsFromPromotion,
  PropsToLandingPageProvider,
  SubscriptionTiersProductPrice,
} from 'bundles/coursera-plus-landing-page/contentfulData/LandingPageDataProvider';
import type { SubscriptionTiersProductVariant } from 'bundles/coursera-plus/constants/CourseraPlusProductVariant';
import courseraPlusProductVariants, {
  courseraLiteProductVariants,
} from 'bundles/coursera-plus/constants/CourseraPlusProductVariant';
import { constants } from 'bundles/coursera-plus/utils/courseraPlusUtils';
import { useExistingCourseraLiteSubscription } from 'bundles/coursera-plus/utils/withExistingCourseraLiteSubscription';
import type { PropsFromWithExistingCourseraLiteSubscription } from 'bundles/coursera-plus/utils/withExistingCourseraLiteSubscription';
import { useNaptime } from 'bundles/naptimejs';
import OpenCourseMembershipsV1 from 'bundles/naptimejs/resources/openCourseMemberships.v1';
import ProductPricesV3 from 'bundles/naptimejs/resources/productPrices.v3';
import ProductPricesV4 from 'bundles/naptimejs/resources/productPrices.v4';

/* @ts-expect-error ts-migrate(7016) FIXME: Untyped import */
import SubscriptionTrialsV1 from 'bundles/naptimejs/resources/subscriptionTrials.v1';
import { useGetProductPricesV3 } from 'bundles/payments-common/hooks/useGetProductPrices';
import type { ProductType as IProductType } from 'bundles/payments/common/ProductType';
import ProductType from 'bundles/payments/common/ProductType';
import { useCart } from 'bundles/refund/utils/hooks';

export const usePromotion = (props: PropsToLandingPageProvider) => {
  const promoCode = props.landingPage?.promotionCode;
  const primary = props.landingPage?.primaryCourseraPlusProduct;
  const productId = primary ? getProductFromContentful(primary) : undefined;
  const {
    data: { promotion },
  } = useNaptime<PropsFromPromotion>(() => {
    if (!promoCode || !productId) {
      return {
        promotion: null,
      };
    }

    return {
      promotion: ProductPricesV4.getPromoForProductInfo(promoCode, productId),
    };
  }, [promoCode, productId]);

  return {
    promotion,
  };
};

type PricesInput = PropsToLandingPageProvider & PropsFromPromotion & PropsFromWithExistingCourseraLiteSubscription;

type PropsFromNaptime = {
  pricesWithCurrency?: SubscriptionTiersProductPrice[];
  prices?: SubscriptionTiersProductPrice[];
};

export const useGetSubscriptionTiersLandingPagePrices = (input: PricesInput) => {
  const { promotion, primaryId, secondaryId, isCourseraLiteProductIds, existingCourseraLiteSubscription } = input;
  const { cart } = useCart({ cartId: existingCourseraLiteSubscription?.cartId });
  const currencyData = cart
    ? {
        currencyCode: cart?.currencyCode,
        countryIsoCode: cart?.countryIsoCode,
      }
    : undefined;

  const shouldFetchPriceByCurrency =
    currencyData?.currencyCode && existingCourseraLiteSubscription?.stripeSubscriptionPaymentInformation;

  const pricesWanted: SubscriptionTiersProductVariant[] = [];

  if (!promotion && primaryId) {
    pricesWanted.push(primaryId);
  }

  if (secondaryId) {
    pricesWanted.push(secondaryId);
  }

  const skip = pricesWanted.length === 0;

  const products = pricesWanted.map((productItemId) => {
    return {
      productType: isCourseraLiteProductIds ? ProductType.COURSERA_TIER_LITE : ProductType.COURSERA_PLUS_SUBSCRIPTION,
      productItemId,
    };
  });

  const { productPrices: prices } = useGetProductPricesV3({ products, skip });

  const {
    data: { pricesWithCurrency },
  } = useNaptime<PropsFromNaptime>(() => {
    if (skip || isCourseraLiteProductIds) {
      return {};
    }

    return {
      pricesWithCurrency: shouldFetchPriceByCurrency
        ? ProductPricesV3.getPricesByCurrencyCodeAndCountry(
            pricesWanted.map((productItemId) => {
              return {
                productType: ProductType.COURSERA_PLUS_SUBSCRIPTION,
                productItemId,
                ...currencyData,
              };
            })
          )
        : undefined,
    };
  }, [isCourseraLiteProductIds, currencyData?.currencyCode, shouldFetchPriceByCurrency]);

  return {
    prices: pricesWithCurrency || prices,
  };
};

type PropsFromWithOpenCourseMemberships = {
  openCourseMemberships: OpenCourseMembershipsV1[] | null;
};

export const useOpenCourseMemberships = (isCourseraLiteLandingPage: boolean) => {
  const {
    data: { openCourseMemberships },
  } = useNaptime<PropsFromWithOpenCourseMemberships>(() => {
    if (!user.isAuthenticatedUser() || !isCourseraLiteLandingPage) {
      return {
        openCourseMemberships: [],
      };
    }

    return {
      openCourseMemberships: OpenCourseMembershipsV1.byUser(user.get()?.id, {
        fields: ['courseRole'],
      }),
    };
  }, [isCourseraLiteLandingPage]);

  return {
    openCourseMemberships,
  };
};

type PropsFromProductPrices = {
  courseraLiteAndPlusProductPrices?: SubscriptionTiersProductPrice[];
  courseraLiteAndPlusProductPricesByCurrency?: SubscriptionTiersProductPrice[];
};

export const useGetSubscriptionTiersProductPrices = ({
  skip = false,
  shouldGetCourseraPlusAnnual = true,
}: {
  skip?: boolean;
  shouldGetCourseraPlusAnnual?: boolean;
}) => {
  const { existingCourseraLiteSubscription: liteSubscription } = useExistingCourseraLiteSubscription(skip);
  const { cart } = useCart({ cartId: liteSubscription?.cartId });
  const currencyData = {
    countryIsoCode: cart?.countryIsoCode,
    currencyCode: cart?.currencyCode,
  };
  const shouldFetchPriceByCurrency =
    currencyData?.currencyCode && liteSubscription?.stripeSubscriptionPaymentInformation;

  const products: { productType: IProductType; productItemId: string }[] = [
    {
      productType: ProductType.COURSERA_TIER_LITE,
      productItemId: courseraLiteProductVariants.MONTHLY_WITH_FREE_TRIAL,
    },
    {
      productType: ProductType.COURSERA_PLUS_SUBSCRIPTION,
      productItemId: courseraPlusProductVariants.MONTHLY_WITH_FREE_TRIAL,
    },
  ];

  if (shouldGetCourseraPlusAnnual) {
    products.push({
      productType: ProductType.COURSERA_PLUS_SUBSCRIPTION,
      productItemId: courseraPlusProductVariants.ANNUAL_SEVEN_DAY_FREE_TRIAL,
    });
  }

  const { productPrices: courseraLiteAndPlusProductPrices } = useGetProductPricesV3({ products, skip });

  const {
    data: { courseraLiteAndPlusProductPricesByCurrency },
  } = useNaptime<PropsFromProductPrices>(() => {
    if (skip) {
      return {};
    }

    const productListWithCurrencyCode = products.map((product) => ({ ...product, ...currencyData }));

    return {
      courseraLiteAndPlusProductPricesByCurrency: shouldFetchPriceByCurrency
        ? ProductPricesV3.getPricesByCurrencyCodeAndCountry(productListWithCurrencyCode)
        : undefined,
    };
  }, [liteSubscription?.[0]?.id, cart?.id, shouldFetchPriceByCurrency, products?.length]);

  const allProductPrices = courseraLiteAndPlusProductPricesByCurrency ?? courseraLiteAndPlusProductPrices;

  return {
    courseraLiteProductPrice: allProductPrices?.[0],
    courseraPlusProductPrice: allProductPrices?.[1],
    ...(shouldGetCourseraPlusAnnual ? { courseraPlusAnnualProductPrice: allProductPrices?.[2] } : {}),
  };
};

export type PropsFromSubscriptionTiersFreeTrials = {
  subscriptionTrials: Array<SubscriptionTrialsV1>;
  liteTierFreeTrials: Array<SubscriptionTrialsV1>;
};

export const useSubscriptionTiersFreeTrials = (props: PropsToLandingPageProvider) => {
  const {
    data: { liteTierFreeTrials, subscriptionTrials },
  } = useNaptime<PropsFromSubscriptionTiersFreeTrials>(() => {
    if (!user.isAuthenticatedUser() || !shouldGetFreeTrials(props)) {
      return { subscriptionTrials: [], liteTierFreeTrials: [] };
    }
    const shouldFetchCourseraLiteFreeTrials = props.landingPage.isCourseraLiteLandingPage && !props.ownsCourseraLite;
    const { COURSERA_PLUS_SUBSCRIPTION, COURSERA_TIER_LITE } = ProductType;
    const { COURSERA_PLUS_SUBSCRIPTION_UNDERLYING_PRODUCT_ITEM_ID, COURSERA_TIER_LITE_UNDERLYING_PRODUCT_ID } =
      constants;
    const userId = user.get().id;

    return {
      subscriptionTrials: SubscriptionTrialsV1.finder('findByUserAndUnderlyingProduct', {
        params: {
          userProductItemId: tupleToStringKey([
            userId,
            COURSERA_PLUS_SUBSCRIPTION,
            COURSERA_PLUS_SUBSCRIPTION_UNDERLYING_PRODUCT_ITEM_ID,
          ]),
        },
      }),
      ...(shouldFetchCourseraLiteFreeTrials
        ? {
            liteTierFreeTrials: SubscriptionTrialsV1.finder('findByUserAndUnderlyingProduct', {
              params: {
                userProductItemId: tupleToStringKey([
                  userId,
                  COURSERA_TIER_LITE,
                  COURSERA_TIER_LITE_UNDERLYING_PRODUCT_ID,
                ]),
              },
            }),
          }
        : { liteTierFreeTrials: [] }),
    };
  }, []);

  return {
    liteTierFreeTrials,
    subscriptionTrials,
  };
};
