import { createContext, useContext } from 'react';

import type { ProgramHome_EnrolledProduct } from '__generated__/graphql-types';
import type { ApolloQueryResult } from 'apollo-client';

import type {
  EnterpriseBadgeCollectionsQuery as EnterpriseBadgeCollectionsQueryData,
  EnterpriseBadgeCollectionsQueryVariables,
} from 'bundles/enterprise-learner-onboarding/queries/__generated__/EnterpriseBadgeCollectionsQuery';
import type { EnterpriseBadge } from 'bundles/page-config-common/providers/enterprise/EnterpriseBadgeCollectionsProvider';
import type {
  EnterpriseEnrolledProductsQuery as EnterpriseEnrolledProductsQueryType,
  EnterpriseEnrolledProductsQueryVariables,
} from 'bundles/unified-home-common/queries/__generated__/EnterpriseEnrolledProductsQuery';

export type EnterpriseUserEnrolledProductsContextType = {
  enrolledProducts: Array<ProgramHome_EnrolledProduct>;
  enrolledBadgeCollections: Array<EnterpriseBadge>;
  issuedBadgeCollections: Array<EnterpriseBadge>;
  loading: boolean;
  refetchEnterpriseBadgeCollections?: (
    variables?: EnterpriseBadgeCollectionsQueryVariables
  ) => Promise<ApolloQueryResult<EnterpriseBadgeCollectionsQueryData>>;
  refetchEnterpriseEnrolledProducts?: (
    variables?: EnterpriseEnrolledProductsQueryVariables
  ) => Promise<ApolloQueryResult<EnterpriseEnrolledProductsQueryType>>;
  handleViewMoreEnrolledProducts?: () => void;
  isFetchingMore: boolean;
};

const EnterpriseUserEnrolledProductsContext = createContext<EnterpriseUserEnrolledProductsContextType>({
  enrolledProducts: [],
  enrolledBadgeCollections: [],
  issuedBadgeCollections: [],
  loading: false,
  refetchEnterpriseBadgeCollections: undefined,
  refetchEnterpriseEnrolledProducts: undefined,
  handleViewMoreEnrolledProducts: undefined,
  isFetchingMore: false,
});

export const useEnterpriseUserEnrolledProducts = () => useContext(EnterpriseUserEnrolledProductsContext);

export default EnterpriseUserEnrolledProductsContext;
