import { useMutation } from '@apollo/client';
import type {
  CourseraTierSubscriptions_BillingCycle as BillingCycleType,
  CourseraTierSubscriptions_ProductType as ProductType,
} from '__generated__/graphql-types';

import prices from 'js/lib/prices';
import requestCountry from 'js/lib/requestCountry';
import user from 'js/lib/user';

import MidCycleUpgradeMutation from 'bundles/coursera-plus/api/MidCycleUpgradeMutation.graphql';
import type {
  MidCycleUpgradeMutation as MidCycleUpgradeMutationType,
  MidCycleUpgradeMutationVariables,
} from 'bundles/coursera-plus/api/__generated__/MidCycleUpgradeMutation';
import { getProductEnrollmentInformationFromCourseAndS12nIds } from 'bundles/enroll-course/lib/enrollmentChoiceUtils';

type MidCycleUpgradeRequestOptions = {
  courseId?: string;
  s12nId?: string;
  fromTierSubscriptionId: string;
  toTier?: ProductType;
  // TODO: check we BE, we probably only need this prop when creating the cart
  toTierBillingCycle: BillingCycleType;
  cartId?: string;
  paymentInformation?: {
    'org.coursera.payment.WalletSubscriptionPaymentInformation': {
      transactionId: string;
      paymentWalletId: string;
      paymentWalletThreeDsNonce?: string;
    };
  };
  promoCode?: string;
  countryIsoCode?: string;
  currencyCode?: string;
};

const useSubscriptionTiersMidCycleUpgradeMutation = () => {
  const [midCycleUpgradeMutation] = useMutation<MidCycleUpgradeMutationType, MidCycleUpgradeMutationVariables>(
    MidCycleUpgradeMutation,
    {
      context: { clientName: 'gatewayGql' },
    }
  );

  const midCycleUpgrade = ({
    courseId,
    s12nId,
    cartId,
    fromTierSubscriptionId,
    toTier = 'COURSERA_PLUS_SUBSCRIPTION',
    toTierBillingCycle,
    paymentInformation,
    promoCode,
    countryIsoCode,
    currencyCode,
  }: MidCycleUpgradeRequestOptions) => {
    const walletPaymentInformation = paymentInformation?.['org.coursera.payment.WalletSubscriptionPaymentInformation'];
    const isCheckout = cartId && walletPaymentInformation;
    if (!fromTierSubscriptionId) {
      throw new Error(`Invalid to tier subscrilption Id: ${fromTierSubscriptionId}`);
    }
    const productEnrollmentInformation = getProductEnrollmentInformationFromCourseAndS12nIds({
      courseId,
      s12nId,
    });

    const requestCountryCode = requestCountry.get();
    return midCycleUpgradeMutation({
      variables: {
        input: {
          countryIsoCode: countryIsoCode ?? requestCountryCode,
          currencyCode: currencyCode ?? prices.getCurrencyFromCountry(requestCountryCode),
          fromTierSubscriptionId: fromTierSubscriptionId as string,
          toTier,
          userId: String(user.get().id),
          toTierBillingCycle,
          ...(productEnrollmentInformation ? { productEnrollmentInformation } : {}),
          ...(isCheckout
            ? {
                checkoutInformation: {
                  toTierCartId: cartId,
                  fromTierSubscriptionId: fromTierSubscriptionId as string,
                  paymentInformation: {
                    walletPaymentInformation,
                  },
                },
              }
            : {}),
          ...(promoCode ? { promoCode } : {}),
        },
      },
    }).then((response) => {
      return response?.data?.CourseraTierSubscriptionsMutations?.midCycleUpgrade;
    });
  };

  return {
    midCycleUpgrade,
  };
};

export default useSubscriptionTiersMidCycleUpgradeMutation;
