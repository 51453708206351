import * as React from 'react';

import { UnlinkIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';

import _t from 'i18n!nls/cml';

export type Props = {
  onUnlink: () => void;
};

const RemoveLinkButton: React.FC<Props> = ({ onUnlink }) => {
  return (
    <Button onClick={onUnlink} title={_t('Remove link')} type={TOOLBAR_BUTTON_TYPES.insertion} role="menuitem">
      <UnlinkIcon size="small" />
    </Button>
  );
};

export default RemoveLinkButton;
