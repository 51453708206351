/* eslint-disable @typescript-eslint/no-explicit-any, camelcase */

/**
 * This file is autogenerated, please don't edit it manually.
 * Use this command to regenerate it if you modified Epic experiments:
 * yarn epic:types
 */

/**
 * Epic experiments namespaces with their possible keys.
 * Autogenerated from static/bundles/epic/data/defaults/
 */
import client from 'bundles/epic/client';
import edgeRouting from 'bundles/epic/data/defaults/edgeRouting.json';
import type { Tags } from 'bundles/epic/lib/EpicTypes';

type Namespace = {
  'ondemandBreakup:home': string;
  testLearnerServiceWorker: string;
  'cds-demo-page': 'default' | 'alternate';
  'admin-v2': 'default' | 'alternate';
  'teach-program-v2-rollout': 'default' | 'alternate';
  enableUnifiedXDP: 'default' | 'alternate';
  enableGoogleOwnedXDP: 'default' | 'alternate';
  unifiedXdpV2S12nPC: 'default' | 'alternate' | 'alternate2';
  unifiedXdpV2Course: 'default' | 'alternate1';
};

const NAMESPACE = 'edgeRouting';

// Adding safe optional chaining to avoid massive changes in unit tests.
client.addDefaults?.([edgeRouting as $TSFixMe]);

const edgeRoutingEpicClient = {
  get<K extends keyof Namespace>(key: K, tags?: Tags): Namespace[K] {
    return client.get(NAMESPACE, key, tags);
  },

  preview<K extends keyof Namespace>(key: K, tags?: Tags, defaults?: Namespace): Namespace[K] {
    return client.preview(NAMESPACE, key, tags);
  },
};

export default edgeRoutingEpicClient;
