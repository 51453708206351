/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { RenderElementProps } from 'slate-react';
import { useReadOnly, useSelected } from 'slate-react';

import type { Theme } from '@coursera/cds-core';

import TableCellRenderer from 'bundles/cml/shared/components/table/TableCell';

const styles = {
  selected: css`
    background-color: var(--cds-color-interactive-background-primary-hover-weak);
    border: 1px double var(--cds-color-interactive-primary) !important;
  `,
};

const TableCell: React.FC<RenderElementProps> = (props) => {
  const { children } = props;
  const selected = useSelected();
  const readonly = useReadOnly();

  return (
    <TableCellRenderer {...props} css={selected && !readonly && styles.selected}>
      {children}
    </TableCellRenderer>
  );
};

export default TableCell;
