import { default as Root } from './Breadcrumbs';
import { default as BreadcrumbsItem } from './BreadcrumbsItem';

export type { Props as BreadcrumbsProps } from './Breadcrumbs';
export type { Props as BreadcrumbsItemProps } from './BreadcrumbsItem';

type RootType = typeof Root;

export interface BreadcrumbsType extends RootType {
  /**
   * Individual `Breadcrumbs.Item` elements used as links.
   *
   * ⚠️The `Breadcrumbs.Item` elements **must** be direct descendants of `Breadcrumbs`.
   *
   * @see Breadcrumbs
   * @see Breadcrumbs.Item
   */
  Item: typeof BreadcrumbsItem;
}

/**
 * Breadcrumbs are a secondary navigation aid that displays a user’s current location.
 * It provides a way to navigate through a list of pages and understand the hierarchy among page levels.
 *
 * See [Props](__storybookUrl__/components-navigation-breadcrumbs--default#props)
 *
 *
 * Anatomy:
 *
 * ```jsx
 * <Breadcrumbs>
 *   <Breadcrumbs.Item />
 *   <Breadcrumbs.Item />
 *   <Breadcrumbs.Item />
 * </Breadcrumbs>
 * ```
 *
 * @see Breadcrumbs.Item
 */
const Breadcrumbs: BreadcrumbsType = Object.assign(Root, {
  Item: BreadcrumbsItem,
});

export default Breadcrumbs;
