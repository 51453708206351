/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { LoadingSection } from '@coursera/cds-core';

import _t from 'i18n!nls/ai-coach-platform';

export default function ChatHistoryLoading({ labelMessage }: { labelMessage?: string }) {
  const message = labelMessage || _t('Recalling our chat...');

  return (
    <div
      css={css`
        position: absolute;
        top: 50%;
        left: 50%;
        right: 50%;
      `}
    >
      <LoadingSection label={message} />
    </div>
  );
}
