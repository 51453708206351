import * as React from 'react';

import type {
  DescriptionPage_Instructor as DescriptionPageInstructor,
  DescriptionPage_Partner as DescriptionPagePartner,
} from '__generated__/graphql-types';
import moment from 'moment';

import config from 'js/app/config';
import logger from 'js/app/loggerSingleton';
import path from 'js/lib/path';

import type { Domain } from 'bundles/program-common/types/xdpTypes';
import { getBreadcrumbsFromDomain } from 'bundles/program-common/utils/xdpUtils';
import { limitDescriptionLength, removeLineBreakFromString } from 'bundles/seo/utils/domainAndMetadataUtils';
import { clearEmptyFields } from 'bundles/seo/utils/utils';

export type Partner = {
  id: number;
  name: string;
  shortName: string;
  links?: {
    website?: string;
  };
};

export type Instructor = {
  id: string;
  fullName: string | null;
  shortName?: string | null;
  title: string | null;
  photo: string | null;
  websites?: {
    website?: string | null;
  } | null;
};

export type ProductSchemaMarkup = {
  '@type': string;
  name: string;
  description: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  [key: string]: any;
};

export type BreadcrumbSchemaMarkup = {
  '@type': string;
  itemListElement: Array<{
    '@type': string;
    position: number;
    item: {
      name: string;
      '@id': string;
    };
  }>;
};

export type InstructorMarkup = {
  '@type': string;
  name: string;
  jobTitle: string;
  image: string;
  url: string;
  sameAs?: string;
};

export type ProviderMarkup = {
  '@id': string;
  '@type': string;
  name: string;
  url: string;
  sameAs?: string;
};

const COURSERA_ORIGIN = config.url.base;

export const KEYWORD_IDS = {
  COURSE: 'mooc',
  PROJECT: 'project',
  SPECIALIZATION: 'specialization',
};

export const getBreadcrumbSchema = (
  domain: Domain | undefined,
  keywordId: string,
  originOverride?: string
): BreadcrumbSchemaMarkup => {
  const origin = originOverride || COURSERA_ORIGIN;
  const routes = getBreadcrumbsFromDomain(domain);
  const breadcrumbType = {
    '@type': 'BreadcrumbList',
    itemListElement: routes.map((route, i) => {
      const isLastBreadcrumb = i === routes.length - 1;

      return {
        '@type': 'ListItem',
        position: i + 1,
        item: {
          name: route.name,
          '@id': isLastBreadcrumb
            ? path.join(origin, `${route.path}#${keywordId}`)
            : path.join(origin, `${route.path}`),
        },
      };
    }),
  };

  return breadcrumbType;
};

export const getTrimmedDescription = (description: string, limit: number) => {
  return limitDescriptionLength(removeLineBreakFromString(description), limit);
};

export const getProviderMarkup = (
  partners: Array<Partner | DescriptionPagePartner>,
  originOverride?: string
): Array<ProviderMarkup> => {
  const origin = originOverride || COURSERA_ORIGIN;

  const markup = partners.map((partner) => {
    const partnerUrl = path.join(origin, partner.shortName);
    let website;
    if ('links' in partner) {
      website = partner.links?.website;
    } else if ('website' in partner) {
      website = partner.website;
    }
    const partnerJson = {
      '@id': `${partnerUrl}#institution`,
      '@type': 'CollegeOrUniversity',
      name: partner.name,
      url: partnerUrl,
      sameAs: website,
    };
    return clearEmptyFields(partnerJson);
  });

  return markup.length > 1 ? markup : markup[0];
};

export const getInstructorMarkup = (
  instructors: Array<Instructor | DescriptionPageInstructor>,
  originOverride?: string
): Array<InstructorMarkup> => {
  const origin = originOverride || COURSERA_ORIGIN;

  const markup = instructors.map((instructor) => {
    let website;
    if ('websites' in instructor) {
      website = instructor.websites?.website;
    } else if ('website' in instructor) {
      website = instructor.website;
    }
    const instructorJson = {
      '@type': 'Person',
      name: instructor.fullName,
      jobTitle: instructor.title,
      image: instructor.photo,
      url: path.join(origin, 'instructor', instructor.shortName ? `${instructor.shortName}` : `~${instructor.id}`),
      sameAs: website,
    };

    return clearEmptyFields(instructorJson);
  });

  return markup.length > 1 ? markup : markup[0];
};

export const getSchemaOrgBooleanString = (b: boolean): string => {
  return `http://schema.org/${b ? 'True' : 'False'}`;
};

export const monthsToISO8601Duration = (numberOfMonths: number) => {
  return `P${numberOfMonths}M`;
};

export const getTimeRequiredString = (timeObj: { milliseconds?: number; hours?: number }): string => {
  let value;

  if (timeObj.milliseconds) {
    value = timeObj.milliseconds;
  } else if (timeObj.hours) {
    value = timeObj.hours * 60 * 60 * 1000;
  } else {
    logger.warn('Unable to get timeRequired value for schema markup');
    return '';
  }

  return moment.duration(value).toString();
};

export const getSchemaMarkupElement = (graph: Array<BreadcrumbSchemaMarkup | ProductSchemaMarkup>): React.ReactNode => {
  const graphType = {
    '@context': 'http://schema.org',
    '@graph': graph.map(clearEmptyFields),
  };

  const createMarkup = () => ({
    __html: `
      <script type="application/ld+json">
        ${JSON.stringify(clearEmptyFields(graphType))}
      </script>
    `,
  });

  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={createMarkup()} />;
};
