import * as React from 'react';

import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import URI from 'jsuri';

import hoistNonReactStatics from 'js/lib/hoistNonReactStatics';
import { useRouter } from 'js/lib/useRouter';

import { redirectClientOrServer } from 'bundles/common/utils/urlUtils';
import { useIsProgramAdmin } from 'bundles/enterprise-admin';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import { useRouterParams } from 'bundles/enterprise-legacy-xdp/components/ProgramMiniModal';
import ProductSlug from 'bundles/program-common/queries/ProductSlugQuery.graphql';
import type {
  ProductSlugQuery,
  ProductSlugQueryVariables,
} from 'bundles/program-common/queries/__generated__/ProductSlugQuery';
import { CourseTypeMetadataByCourseIdQuery } from 'bundles/program-home/utils/ProgramHomeGraphqlQueries';
import type {
  CourseTypeMetadataByCourseIdQuery as CourseTypeMetadataByCourseIdQueryType,
  CourseTypeMetadataByCourseIdQueryVariables,
} from 'bundles/program-home/utils/__generated__/CourseTypeMetadataByCourseIdQuery';
import CoursesSlug from 'bundles/unified-home-common/queries/CoursesSlugQuery.graphql';
import type {
  CoursesSlugQuery,
  CoursesSlugQueryVariables,
} from 'bundles/unified-home-common/queries/__generated__/CoursesSlugQuery';

type PropsFromCaller = { productId: string; spookyMultiprogramFlag?: boolean };

const useIsProject = (courseId?: string) => {
  const { data, loading, error } = useQuery<
    CourseTypeMetadataByCourseIdQueryType,
    CourseTypeMetadataByCourseIdQueryVariables
  >(
    CourseTypeMetadataByCourseIdQuery,
    // We confirm courseId is defined from the skip property.

    { variables: { courseId: courseId! }, skip: !courseId }
  );

  return {
    isProject:
      data &&
      data?.CourseTypeMetadataV1Resource.get?.courseTypeMetadata.__typename !==
        'CourseTypeMetadataV1_standardCourseMember',
    isProjectLoading: loading,
    projectError: error,
  };
};

const getProductType = (productVariantOrRouteParam?: string, isProject?: boolean) => {
  const productTypes = {
    course: 'learn',
    s12n: 'specializations',
    profS12n: 'professional-certificates',
    project: 'projects',
    ProfessionalCertificateS12n: 'professional-certificates',
    ExternalCertificateS12n: 'professional-certificates',
    NormalS12n: 'specializations',
    NORMAL_S12N: 'specializations',
    PROFESSIONAL_CERTIFICATE_S12N: 'professional-certificates',
  };

  const productType = isProject
    ? productTypes.project
    : productTypes[productVariantOrRouteParam as keyof typeof productTypes];
  return productType;
};

/**
 * Hook equivalent of withRedirectToUnifiedExperience
 * @param id - the product ID, whether course, Specialization, Guided Project, etc.
 * @param spookyMultiprogramFlag - TODO(ppaskaris) add description here
 * @return `true` if we should not bother rendering the mini-modal (because we are redirecting to the unified product
 *         page instead). This redirect is a side-effect of the Hook.
 */
export function useRedirectToUnifiedExperience(id: string, spookyMultiprogramFlag?: boolean): boolean {
  // The existing useRouterParams function (called in 16 other places, as of this writing) takes a whole router object
  // and uses both its `params` and `location.query`, so we'll just request the whole router to prevent a large blast
  // radius in this PR.
  const router = useRouter();
  // Note: useRouterParams is not a Hook, despite the name.
  const routerParams = useRouterParams(router);
  const runCourseQuery = routerParams?.productType === 'course';
  const {
    loading: isCourseSlugLoading,
    data: courseSlugData,
    error: courseSlugError,
  } = useQuery<CoursesSlugQuery, CoursesSlugQueryVariables>(CoursesSlug, {
    variables: {
      id,
    },
    context: { clientName: 'gatewayGql' },
    skip: !runCourseQuery,
  });
  const course = courseSlugData?.Course?.queryById;

  const {
    loading: isSlugLoading,
    data: slugData,
    error: slugError,
  } = useQuery<ProductSlugQuery, ProductSlugQueryVariables>(ProductSlug, {
    variables: {
      id,
    },
    skip: runCourseQuery,
    context: {
      clientName: 'gatewayGql',
    },
  });
  const s12n = slugData?.Specialization?.queryById;

  const { isProgramAdmin, loadingIsProgramAdmin } = useIsProgramAdmin();

  const loadingIsAdminUser = loadingIsProgramAdmin;
  const isAdminUser = isProgramAdmin;

  const { isProject, isProjectLoading, projectError } = useIsProject(course?.id);
  if (courseSlugError || slugError || projectError) {
    Sentry.captureException(courseSlugError ?? slugError ?? projectError);
    return true;
  }
  if (isCourseSlugLoading || isSlugLoading || isProjectLoading) {
    return true;
  }
  // routerParams will attribute any project type as a course. Ensure we pass the correct path here.
  const productType = getProductType(s12n?.productVariant || routerParams?.productType, isProject);

  // We need to exclude admin and multiprograms users as we don't support their use case in the unified XDP MVP.
  const showUnifiedXdp = !loadingIsAdminUser && !isAdminUser && !spookyMultiprogramFlag;

  const slug = course?.slug || s12n?.slug;
  const xdpProps = routerParams?.productType && router.params.programSlug && slug;

  if (showUnifiedXdp && xdpProps) {
    if (productType) {
      // Remove miniModal params so back navigation works
      const query = { ...(router?.location?.query || {}) };
      delete query.showMiniModal;
      Object.keys(routerParams).forEach((key) => delete query[key]);

      router.replace({
        pathname: router?.location?.pathname,
        query,
      });

      // Some query params we want to shed and some we'd like to keep. Params in this list will be retained.
      const queryParamsToRetain = new Set(['deepLinkItemId', 'fromClip', 'collectionId', 'source']);
      const redirectUrl = new URI(`/programs/${router.params.programSlug}/${productType}/${slug}`);

      if (Object.keys(router?.location?.query || {}).length > 0) {
        Object.keys(router.location.query).forEach((paramKey) => {
          if (queryParamsToRetain.has(paramKey) && router.location.query[paramKey]) {
            redirectUrl.addQueryParam(paramKey, router.location.query[paramKey]);
          }
        });
      }

      redirectClientOrServer(redirectUrl.toString());

      return true;
    }
  }

  return false;
}

export default function withRedirectToUnifiedExperience<T extends PropsFromCaller>(
  WrappedComponent: React.ComponentType<T>
): React.ComponentType<T> {
  const WithRedirectToUnifiedExperience = function WithRedirectToUnifiedExperience(props: T) {
    const { productId: id, spookyMultiprogramFlag } = props;
    const skipModal = useRedirectToUnifiedExperience(id, spookyMultiprogramFlag);
    if (skipModal) {
      return null;
    } else {
      return <WrappedComponent {...props} />;
    }
  };
  hoistNonReactStatics(WithRedirectToUnifiedExperience, WrappedComponent);
  return WithRedirectToUnifiedExperience;
}
