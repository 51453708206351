import type React from 'react';

import { isHotkey } from 'is-hotkey';
import { Editor, Element, Node, Range, Transforms } from 'slate';
import type { NodeEntry } from 'slate';

import {
  canDecreaseIndent,
  canIncreaseIndent,
  decreaseIndent,
  increaseIndent,
} from 'bundles/cml/editor/components/buttons/indent/listIndentUtils';
import { toggleList } from 'bundles/cml/editor/components/buttons/list/listUtils';
import { focusNextBlock, getAncestorOfType } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { BLOCK_VALUES } from 'bundles/cml/shared/constants';
import type {
  ListElement,
  ListItemElement,
  MathBlockElement,
  TextElement,
} from 'bundles/cml/shared/types/elementTypes';

const isReturnHotKey = isHotkey('return');
const isTabHotKey = isHotkey('tab');
const isShiftTabHotKey = isHotkey('shift+tab');
const isBackspaceHotKey = isHotkey('backspace');
const isDeleteHotKey = isHotkey('delete');

const LIST_TYPES: BLOCK_VALUES[] = [BLOCK_TYPES.NUMBER_LIST, BLOCK_TYPES.BULLET_LIST];

const isLastItem = (editor: Editor, [node, path]: NodeEntry<ListItemElement | ListElement>): boolean => {
  const parentEntry = Editor.parent(editor, path);
  const [parent, parentPath] = parentEntry;
  if (parentPath.length === 0) {
    return true;
  }

  if (Element.isElement(parent) && !LIST_TYPES.includes(parent.type) && parent.type !== BLOCK_TYPES.LIST_ITEM) {
    return true;
  }

  if (parent.children.indexOf(node) < parent.children.length - 1) {
    return false;
  }

  return isLastItem(editor, parentEntry as NodeEntry<ListItemElement | ListElement>);
};

const hasSubList = (listItem: ListItemElement) => {
  return listItem.children.some((child: TextElement | ListElement | MathBlockElement) =>
    LIST_TYPES.includes(child.type)
  );
};

const handleReturnKey = (editor: Editor, event: React.KeyboardEvent): boolean => {
  const listItemEntry = getAncestorOfType(editor, BLOCK_TYPES.LIST_ITEM) as NodeEntry<ListItemElement>;
  if (!listItemEntry) {
    return false;
  }

  event.preventDefault();

  const [listItem, listItemPath] = listItemEntry;
  const [currentList, currentListPath] = Editor.parent(editor, listItemPath) as NodeEntry<ListElement>;

  // decrease indent or break out of the list if the current item is empty
  // and the last item in the list
  const [text] = getAncestorOfType(editor, BLOCK_TYPES.TEXT) as NodeEntry<TextElement>;
  if (!Node.string(text) && !hasSubList(listItem) && isLastItem(editor, listItemEntry)) {
    if (canDecreaseIndent(editor)) {
      decreaseIndent(editor);
    } else {
      toggleList(editor, currentList.type);
    }

    return true;
  }

  const currentListItems = currentList.children;
  const currentItemIndex = currentListItems.indexOf(listItem);

  const { selection } = editor;
  const textNodePath = Editor.node(editor, [...listItemPath, 0])[1];
  const isStart = !!selection && Editor.isStart(editor, Range.start(selection), textNodePath);
  const isEnd = !!selection && Editor.isEnd(editor, Range.end(selection), textNodePath);

  const hasSelection = !!selection && Range.isExpanded(selection);
  if (hasSelection) {
    Transforms.delete(editor);
  }

  if (isStart || isEnd) {
    const newListItem: ListItemElement = {
      type: BLOCK_TYPES.LIST_ITEM,
      children: [
        {
          type: BLOCK_TYPES.TEXT,
          children: [{ text: '' }],
        },
      ],
    };

    if (isStart) {
      Transforms.insertNodes(editor, newListItem, { at: [...currentListPath, currentItemIndex] });
    } else {
      Transforms.insertNodes(editor, newListItem, { at: [...currentListPath, currentItemIndex + 1] });
      focusNextBlock(editor, listItemPath);
    }
  } else {
    Editor.withoutNormalizing(editor, () => {
      Transforms.splitNodes(editor);
      Transforms.moveNodes(editor, { to: [...currentListPath, currentItemIndex + 1] });
      Transforms.wrapNodes(editor, { type: BLOCK_TYPES.LIST_ITEM, children: [] });
    });
  }

  // move sublist of the current item into the new list item
  Transforms.moveNodes(editor, {
    at: listItemPath,
    to: [...currentListPath, currentItemIndex + 1, 1],
    match: (node) => Element.isElement(node) && listItem.children.includes(node) && LIST_TYPES.includes(node.type),
  });

  return true;
};

const handleBackspaceKey = (editor: Editor, event: React.KeyboardEvent): boolean => {
  const listItemEntry = getAncestorOfType(editor, BLOCK_TYPES.LIST_ITEM) as NodeEntry<ListItemElement>;
  if (!listItemEntry) {
    return false;
  }

  const listItemPath = listItemEntry[1];

  const { selection } = editor;
  const textNodePath = [...listItemPath, 0];
  const isStart =
    !!selection && Range.isCollapsed(selection) && Editor.isStart(editor, Range.start(selection), textNodePath);

  if (!isStart) {
    return false;
  }

  event.preventDefault();

  if (listItemPath[0] === 0 && listItemPath[1] === 0) {
    // toggle the list if this is the first node in the doc because
    // there's no previous block to delete backwards to
    const [currentList] = Editor.parent(editor, listItemPath) as NodeEntry<ListElement>;
    toggleList(editor, currentList.type);
  } else {
    Editor.deleteBackward(editor, { unit: 'block' });
  }

  return true;
};

const handleDeleteKey = (editor: Editor, event: React.KeyboardEvent): boolean => {
  const listItemEntry = getAncestorOfType(editor, BLOCK_TYPES.LIST_ITEM) as NodeEntry<ListItemElement>;
  if (!listItemEntry) {
    return false;
  }

  const listItemPath = listItemEntry[1];

  const { selection } = editor;
  const textNodePath = [...listItemPath, 0];
  const isEnd =
    !!selection && Range.isCollapsed(selection) && Editor.isEnd(editor, Range.start(selection), textNodePath);

  if (!isEnd) {
    return false;
  }

  event.preventDefault();
  Editor.deleteForward(editor, { unit: 'block' });

  return true;
};

const handleIncreaseIndent = (editor: Editor, event: React.KeyboardEvent): boolean => {
  const listItemEntry = getAncestorOfType(editor, BLOCK_TYPES.LIST_ITEM) as NodeEntry<ListItemElement>;
  if (!listItemEntry) {
    return false;
  }

  const [listItem] = listItemEntry;

  if (canIncreaseIndent(editor)) {
    event.preventDefault();
    increaseIndent(editor);
    return true;
  }

  // Prevent users from escaping a list unless the cursor is on the last list item.
  if (hasSubList(listItem) || !isLastItem(editor, listItemEntry)) {
    event.preventDefault();
    return true;
  }

  return false;
};

const handleDecreaseIndent = (editor: Editor, event: React.KeyboardEvent): boolean => {
  const listItemEntry = getAncestorOfType(editor, BLOCK_TYPES.LIST_ITEM) as NodeEntry<ListItemElement>;

  const [listItem, listItemPath] = listItemEntry;
  const [currentList] = Editor.parent(editor, listItemPath) as NodeEntry<ListElement>;

  if (!listItem) {
    return false;
  }

  event.preventDefault();
  if (canDecreaseIndent(editor)) {
    decreaseIndent(editor);
  } else {
    toggleList(editor, currentList.type);
  }
  return true;
};

export const listKeyDownHandler = (editor: Editor, event: React.KeyboardEvent): boolean => {
  const { nativeEvent } = event;
  if (isReturnHotKey(nativeEvent)) {
    return handleReturnKey(editor, event);
  }

  if (isBackspaceHotKey(nativeEvent)) {
    return handleBackspaceKey(editor, event);
  }

  if (isDeleteHotKey(nativeEvent)) {
    return handleDeleteKey(editor, event);
  }

  if (isTabHotKey(nativeEvent)) {
    return handleIncreaseIndent(editor, event);
  }

  if (isShiftTabHotKey(nativeEvent)) {
    return handleDecreaseIndent(editor, event);
  }

  return false;
};
