import config from 'js/app/config';

const ids = {
  Specialization: ['kLWnFWsyEeeVdhKUpvOPZg'],
  VerifiedCertificate: [
    'zErmN5rZEeajJhIEs83WWg',
    'RpCqbucEEeaH9Q51wS-wDA',
    'GZluGec0EeaqHRKenm-C8A',
    '10W6Te_sEea7DBK-0BPzLg',
  ],
};

const courseIdToCertDpURLMap = {
  zErmN5rZEeajJhIEs83WWg: `${config.url.base}professional-certificate/applied-project-management?showEnrollModal=true`,
  'RpCqbucEEeaH9Q51wS-wDA': `${config.url.base}professional-certificate/applied-project-management?showEnrollModal=true`,
  'GZluGec0EeaqHRKenm-C8A': `${config.url.base}professional-certificate/applied-project-management?showEnrollModal=true`,
  '10W6Te_sEea7DBK-0BPzLg': `${config.url.base}professional-certificate/applied-project-management?showEnrollModal=true`,
};

export const isCartForCertificatePilot = (cart: $TSFixMe) => {
  if (!cart) {
    return false;
  }

  return cart.topLevelCartItems.every(
    ({ cartItem }: $TSFixMe) =>
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      cartItem.productType && ids[cartItem.productType] && ids[cartItem.productType].includes(cartItem.productItemId)
  );
};

export const isS12nPartOfCertificatePilot = (s12nId: $TSFixMe) => {
  return ids.Specialization.includes(s12nId);
};

export const isCoursePartOfCertificatePilot = (courseId: $TSFixMe) => {
  return ids.VerifiedCertificate.includes(courseId);
};

export const certDpURLForCertificatePilotCourse = (courseId: $TSFixMe) => {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return courseIdToCertDpURLMap[courseId];
};
