/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import PropTypes from 'prop-types';

import Imgix from 'js/components/Imgix';

import { Typography, Typography2, breakpoints } from '@coursera/cds-core';
import { Box, Col } from '@coursera/coursera-ui';

import TopInstructor from 'bundles/enterprise-legacy-xdp/components/instructors/TopInstructor';
import { TrackedA } from 'bundles/page/components/TrackedLink2';
import LazyImgix from 'bundles/page/components/shared/LazyImgix';
import { noheadshotUrl } from 'bundles/partners-graphql/constants';
import { XDP_EXPERTISE_ICONS } from 'bundles/program-common/constants/xdpConstants';
import type { InstructorType, PRODUCT_TYPE } from 'bundles/program-common/types/xdpTypes';
import { generateInstructorImageAltValue } from 'bundles/program-common/utils/xdpUtils';

import _t from 'i18n!nls/program-common';

const PHOTO_SIZE = 112;

type Props = InstructorType & {
  productType?: PRODUCT_TYPE;
  noLink?: boolean;
};
const styles = {
  images: () => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: var(--cds-spacing-100);
  `,

  instructorExpertise: () => css`
    font-size: 12px;
    line-height: 17px;
    margin-top: var(--cds-spacing-100);

    img {
      margin-right: var(--cds-spacing-100);
    }
  `,

  instructorName: () => css`
    color: var(--cds-color-blue-700);
    line-height: 24px;
    margin-bottom: var(--cds-spacing-50);
  `,

  instructorTitle: () => css`
    display: block;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: var(--cds-spacing-50);
    margin-right: var(--cds-spacing-150);
    color: var(--cds-color-blue-700);
  `,

  instructorDepartment: () => css`
    color: var(--cds-color-yellow-700);
    display: block;
    font-size: 12px;
    line-height: 17px;
  `,

  avatarContainer: () => css`
    align-items: center;
    border-radius: 50%;
    display: inline-flex;
    height: 73px;
    justify-content: center;
    margin-bottom: var(--cds-spacing-200);
    overflow: hidden;
    position: relative;
    user-select: none;
    width: 73px;
    ${breakpoints.up('sm')} {
      height: 112px;
      margin-bottom: 0;
      margin-right: var(--cds-spacing-400);
      width: 112px;
    }

    img {
      width: 100%;
    }
  `,
  instructorListWrapper: () => css`
    font-size: 14px;
    line-height: 19px;
    align-items: start;
    display: flex;
    flex-direction: column;
    ${breakpoints.up('sm')} {
      align-items: center;
      flex-direction: row;
    }
  `,
};

type Context = {
  isProgramHome?: boolean;
};

function getInstructorPageUrl(id: string, shortName: string | null) {
  if (shortName) {
    return `/instructor/${shortName}`;
  } else {
    return `/instructor/~${id}`;
  }
}

const Instructor = (
  { id, title, fullName, photo, shortName, department, learnersReached, coursesTaught, isTopInstructor, noLink }: Props,
  { isProgramHome = false }: Context
) => {
  const shouldShowInstructorInfo = !!learnersReached && !!coursesTaught;
  const instructorImageProps = {
    maxWidth: PHOTO_SIZE,
    maxHeight: PHOTO_SIZE,
    imgParams: { fit: 'crop' },
    alt: generateInstructorImageAltValue(fullName),
    src: photo ?? noheadshotUrl,
    role: 'presentation',
  };

  const content = (
    <div css={styles.instructorListWrapper}>
      <div css={styles.avatarContainer}>
        {isProgramHome ? <Imgix {...instructorImageProps} /> : <LazyImgix {...instructorImageProps} />}
      </div>
      <Box justifyContent="center" flexDirection="column" flex={1}>
        <Typography variant="h2" css={styles.instructorName}>
          {fullName}
          {isTopInstructor && <TopInstructor instructorId={id} instructorName={fullName} />}
        </Typography>
        <Typography2 component="span" css={styles.instructorTitle} id="instructor-title">
          {title}
        </Typography2>
        {department && <div css={styles.instructorDepartment}>{department}</div>}
        {shouldShowInstructorInfo && (
          <div css={styles.instructorExpertise}>
            <div className="learners-count">
              <div css={styles.images}>
                <LazyImgix src={XDP_EXPERTISE_ICONS.learners} alt="" maxHeight={12} maxWidth={12} />
              </div>{' '}
              <FormattedHTMLMessage
                message={_t(
                  '<strong>{learnersCount, number}</strong> {learnersCount, plural, =1 {Learner} other {Learners}}'
                )}
                learnersCount={learnersReached}
              />
            </div>
            <div>
              <div css={styles.images}>
                <LazyImgix src={XDP_EXPERTISE_ICONS.courseBook} alt="" maxHeight={12} maxWidth={12} />
              </div>{' '}
              <FormattedHTMLMessage
                message={_t(
                  '<strong>{coursesCount, number}</strong> {coursesCount, plural, =1 {Course} other {Courses}}'
                )}
                coursesCount={coursesTaught}
              />
            </div>
          </div>
        )}
      </Box>
    </div>
  );

  return (
    <Col xs={12} lg={6} key={id} rootClassName="p-y-1 p-r-1">
      {noLink ? (
        content
      ) : (
        <TrackedA
          className="link-no-style"
          href={getInstructorPageUrl(id, shortName)}
          target="_blank"
          rel="noopener noreferrer"
          trackingName="instructor_avatar"
        >
          {content}
        </TrackedA>
      )}
    </Col>
  );
};

Instructor.contextTypes = {
  isProgramHome: PropTypes.bool,
};

export default Instructor;
