/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { SHORT_MONTH_DAY_DISPLAY, formatDateTimeDisplay } from 'js/utils/DateTimeUtils';

import { Typography2 } from '@coursera/cds-core';

import _t from 'i18n!nls/enroll';

const styles = {
  root: () => css`
    padding-left: var(--cds-spacing-50);
  `,
};

type Props = {
  plannedLaunchDate: Date;
  isPreEnrolled: boolean;
};

export const PreEnrollmentStatus = (props: Props) => {
  const { isPreEnrolled, plannedLaunchDate } = props;
  return (
    <Typography2 component="p" color="supportText" display="inline" css={styles.root}>
      {_t(' (#{preEnrollmentStatus} — opens #{startDate})', {
        startDate: formatDateTimeDisplay(plannedLaunchDate, SHORT_MONTH_DAY_DISPLAY),
        preEnrollmentStatus: isPreEnrolled ? _t('Pre-enrolled') : _t('Pre-enrollment available'),
      })}
    </Typography2>
  );
};
