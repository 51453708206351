/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useMemo } from 'react';

import { useRetracked } from 'js/lib/retracked';

import { Chip, Grid, PageGridContainer, Typography } from '@coursera/cds-core';

import { useCurrentTabContext } from 'bundles/unified-home-common/providers/consumer/CurrentTabContextProvider';

import _t from 'i18n!nls/logged-in-home';

const styles = {
  tabName: css`
    margin-top: 24px;
  `,
  tabContainer: css`
    gap: 8px;
    margin-top: 32px !important;
  `,
  tabContent: css`
    margin: 32px 0;
    min-height: 500px;
  `,
};

type LearningTab = { tabName: string; queryParamValue: string; content: React.ReactNode; trackingName: string };

type Props = {
  learningTabs: LearningTab[];
};

const getCurrentTabInfo = (learningTabs: LearningTab[], currentTab?: string) =>
  learningTabs.find((tab) => tab.queryParamValue === currentTab);

const MyLearningPage = ({ learningTabs }: Props) => {
  const track = useRetracked();

  const { currentTab, onTabChange } = useCurrentTabContext();

  const currentTabInfo = useMemo<LearningTab | undefined>(
    () => getCurrentTabInfo(learningTabs, currentTab),
    [currentTab, learningTabs]
  );

  if (!currentTab || !onTabChange) {
    return null;
  }

  return (
    <PageGridContainer>
      <Grid container item>
        <Grid item xs={12}>
          <Typography variant="h1semibold" css={styles.tabName}>
            {_t('My Learning')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container css={styles.tabContainer} role="tablist" wrap="wrap">
            {learningTabs.map((learningTab) => (
              <Chip
                key={learningTab.queryParamValue}
                variant="select"
                selected={learningTab.queryParamValue === currentTab}
                onClick={() => {
                  track({ trackingData: {}, trackingName: learningTab.trackingName, action: 'click' });
                  onTabChange(learningTab.queryParamValue);
                }}
                role="tab"
                data-e2e={`my-learning-tab-${learningTab.queryParamValue?.toLowerCase()}`}
              >
                {learningTab.tabName}
              </Chip>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} role="tabpanel" css={styles.tabContent}>
          {currentTabInfo?.content}
        </Grid>
      </Grid>
    </PageGridContainer>
  );
};

export default MyLearningPage;
