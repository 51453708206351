import { useEffect, useMemo, useState } from 'react';

import { throttle } from 'lodash';

const BORDER_ADJUSTMENT = 2;

type Props = {
  resizableEl: HTMLElement | null;
  enabled: boolean;
  contentSelector?: string;
};

export const useObserveContentHeight = ({ resizableEl, enabled, contentSelector }: Props) => {
  const [contentHeight, setContentHeight] = useState(0);

  const handleContentResize = useMemo(
    () =>
      throttle(({ target }: ResizeObserverEntry) => {
        const targetEl = target as HTMLElement;
        setContentHeight(targetEl.offsetHeight + BORDER_ADJUSTMENT);
      }, 15),
    []
  );

  useEffect(() => {
    return () => {
      handleContentResize.cancel();
    };
  }, [handleContentResize]);

  useEffect(() => {
    if (!contentSelector || !resizableEl || !enabled) {
      return () => undefined;
    }

    const resizeObserver = new ResizeObserver(([entry]) => {
      handleContentResize(entry);
    });

    const contentEl = document.querySelector(contentSelector);
    if (contentEl) {
      resizeObserver.observe(contentEl);
      return () => {
        resizeObserver.disconnect();
      };
    }

    // watch for mutations to handle scenario where content has
    // not yet been loaded. this happens with the SRTEditor which loads Monaco
    // asynchronously
    const mutationObserver = new MutationObserver(() => {
      const element = document.querySelector(contentSelector);
      if (!element) {
        return;
      }

      resizeObserver.observe(element);
      mutationObserver.disconnect();
    });

    mutationObserver.observe(resizableEl, { childList: true, subtree: true });

    return () => {
      mutationObserver.disconnect();
      resizeObserver.disconnect();
    };
  }, [contentSelector, resizableEl, handleContentResize, enabled]);

  return { contentHeight };
};
