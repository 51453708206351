import * as React from 'react';

import classNames from 'classnames';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import redirect from 'js/lib/coursera.redirect';
import keysToConstants from 'js/lib/keysToConstants';

import LoadingIcon from 'bundles/courseraLoadingIcon/LoadingIcon';
import onDemandSpecializationMemberships from 'bundles/enroll/utils/onDemandSpecializationMembershipsApi';
import Modal from 'bundles/phoenix/components/Modal';

import _t from 'i18n!nls/enroll';

import 'css!./__styles__/GetS12nCertificateModal';

const API_STATES = keysToConstants(['LOADING', 'SUCCESS', 'ERROR']);

type Props = {
  s12nId: string;
  onClose: () => void;
};

type State = {
  apiState: keyof typeof API_STATES;
};

class GetS12nCertificateModal extends React.Component<Props, State> {
  state: State = {
    apiState: API_STATES.LOADING,
  };

  componentDidMount() {
    const { s12nId } = this.props;

    onDemandSpecializationMemberships
      .create(s12nId)
      .then(() =>
        this.setState(() => ({
          apiState: API_STATES.SUCCESS,
        }))
      )
      .catch(() =>
        this.setState(() => ({
          apiState: API_STATES.ERROR,
        }))
      );
  }

  handleClose = () => {
    const { onClose } = this.props;

    // Refresh to update the user's enrollment state
    redirect.refresh();
    onClose();
  };

  renderModalContent(): React.ReactNode {
    const { apiState } = this.state;

    switch (apiState) {
      case API_STATES.LOADING:
        return <LoadingIcon />;
      case API_STATES.SUCCESS:
        return (
          <div>
            <h4 className="headline-4-text">{_t('Congratulations!')}</h4>
            <p className="m-a-0">
              <FormattedMessage
                message={_t(`You have received the certificate for this Specialization! To see your certificate,
                  visit your {accomplishmentsLink} page.
                `)}
                accomplishmentsLink={
                  <a className="accomplishments-link" href="/accomplishments">
                    {_t('Accomplishments')}
                  </a>
                }
              />
            </p>
            <button type="button" className="primary cozy m-a-0 m-t-2" onClick={this.handleClose}>
              {_t('Close')}
            </button>
          </div>
        );
      case API_STATES.ERROR:
        return (
          <div className="vertical-box align-items-absolute-center">
            <p className="m-a-0">{_t('Sorry, an error occurred. Please try again later.')}</p>
          </div>
        );
      default:
        return null;
    }
  }

  render() {
    const { apiState } = this.state;
    const wrapperClass = classNames('rc-GetS12nCertificateModal', {
      'success-state': apiState === API_STATES.SUCCESS,
      'error-state': apiState === API_STATES.ERROR,
    });
    const allowClose = apiState === API_STATES.ERROR;

    return (
      <Modal modalName="GetS12nCertificateModal" className={wrapperClass} allowClose={allowClose}>
        {this.renderModalContent()}
      </Modal>
    );
  }
}

export default GetS12nCertificateModal;
