import * as React from 'react';

import Divider from 'bundles/coursera-ui/components/basic/Divider';
import CustomPropTypes from 'bundles/ondemand/constants/PropTypes';

import S12NCourseProgress from './S12NCourseProgress';

class S12nInfo extends React.Component {
  static propTypes = {
    courses: CustomPropTypes.arrayOf(CustomPropTypes.course).isRequired,
    progressStatus: CustomPropTypes.progressStatus.isRequired,
  };

  static defaultProps = {
    courses: [],
    progressStatus: {},
  };

  renderCourseProgress = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'courses' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { courses, progressStatus } = this.props;
    const numCourses = courses.length;

    return courses.map((course: $TSFixMe, idx: $TSFixMe) => {
      const isLast = idx === numCourses - 1;
      const id = course.id;
      return (
        <div key={id}>
          <Divider />
          <S12NCourseProgress course={course} progressStatus={progressStatus[id]} />
          {isLast && <Divider />}
        </div>
      );
    });
  };

  render() {
    return <div className="rc-S12nInfo vertical-box">{this.renderCourseProgress()}</div>;
  }
}

export default S12nInfo;
