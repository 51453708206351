/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Button } from '@coursera/cds-core';

import type EvaluatorTestCaseRunner from 'bundles/author-code-evaluator/models/EvaluatorTestCaseRunner';

import _t from 'i18n!nls/author-code-evaluator';

const styles = {
  container: css`
    display: flex;
    padding: var(--cds-spacing-50) var(--cds-spacing-150);
    align-items: space-between;
    flex: 1;
  `,
  input: css`
    border: none;
    flex-grow: 1;
  `,
  actions: css`
    display: flex;
  `,
  button: css`
    margin-left: var(--cds-spacing-50);
  `,
};

class TestCaseTitleRow extends React.Component<{
  isTestCaseAdded: boolean;
  testCaseRunner: EvaluatorTestCaseRunner;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDelete: () => void;
  onAddTestCase: (x: any) => void;
  onDuplicate: () => void;
}> {
  render() {
    const { isTestCaseAdded, testCaseRunner, onChange, onDelete, onAddTestCase, onDuplicate } = this.props;

    const canAddTestCase =
      testCaseRunner.testCase.expression !== '' &&
      testCaseRunner.testCase.name !== '' &&
      testCaseRunner.response != null;

    return (
      <div css={styles.container} className="rc-TestCaseTitleRow">
        <input
          css={styles.input}
          type="text"
          maxLength={80}
          onChange={onChange}
          placeholder={_t('Enter test case title')}
          className="body-2-text input"
          value={testCaseRunner.testCase.name}
        />
        {!isTestCaseAdded && (
          <div css={styles.actions}>
            <Button css={styles.button} variant="ghost" onClick={onDelete} data-testid="cancel-button">
              {_t('Cancel')}
            </Button>
            <Button variant="ghost" disabled={!canAddTestCase} onClick={onAddTestCase} data-testid="add-button">
              {_t('Add')}
            </Button>
          </div>
        )}

        {isTestCaseAdded && (
          <div css={styles.actions}>
            <Button css={styles.button} variant="ghost" onClick={onDelete} data-testid="delete-button">
              {_t('Delete')}
            </Button>
            <Button css={styles.button} variant="ghost" onClick={onDuplicate} data-testid="duplicate-button">
              {_t('Duplicate')}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default TestCaseTitleRow;
