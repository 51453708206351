export enum CONTENT_TYPES {
  DEFAULT = 'DEFAULT',
  ASSET_LIBRARY = 'ASSET_LIBRARY',
}

export const PluginSources = {
  GoogleDrive: 'GoogleDrive',
  OneDrive: 'OneDrive',
  Dropbox: 'Dropbox',
  Url: 'Url',
  Zoom: 'Zoom',
} as const;

export const DEFAULT_PLUGIN_SOURCES = [
  PluginSources.GoogleDrive,
  PluginSources.OneDrive,
  PluginSources.Dropbox,
  PluginSources.Url,
];

export const PLUGIN_SOURCES_WITH_ZOOM = [...DEFAULT_PLUGIN_SOURCES, PluginSources.Zoom];

export type PluginSourcesType = typeof PluginSources[keyof typeof PluginSources];
