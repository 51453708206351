import * as React from 'react';
import { useContext } from 'react';

type PageContextType = {
  isEnterprise?: boolean;
  isConsumer?: boolean;
};

const PageContext = React.createContext<PageContextType | undefined>(undefined);

export const usePageContext = () => {
  const context = useContext(PageContext);

  return context;
};

export default PageContext;
