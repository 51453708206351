import * as React from 'react';

import { pure } from 'recompose';

import { SvgIcon } from '@coursera/coursera-ui';
import type { Svg } from '@coursera/coursera-ui/svg';

const SvgVideoSquareComponent: Svg = ({ title = 'Video', ...props }) => (
  <SvgIcon title={title} {...props} viewBox="0 0 48 48" fill="none">
    <g id="Video">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 41H48V8H0V41ZM2 39H46V10H2V39ZM19.3844 33.1449V15.1007L33.6784 24.1228L19.3844 33.1449Z"
        id="SvgVideoSquare"
      />
    </g>
  </SvgIcon>
);

const SvgVideoSquare = pure(SvgVideoSquareComponent);
SvgVideoSquare.displayName = 'SvgVideoSquare';

export default SvgVideoSquare;
