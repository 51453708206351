/** @jsx jsx */
import { jsx } from '@emotion/react';

import { pure } from 'recompose';

import type { ButtonProps } from '@coursera/coursera-ui';
import { ApiButton, Button } from '@coursera/coursera-ui';
import type { ApiStatus } from '@coursera/coursera-ui/lib/constants/sharedConstants';

import _t from 'i18n!nls/program-common';

type Props = {
  btnAttributes: ButtonProps;
  onClick: () => void;
  apiStatus: ApiStatus;
  isEnrolled: boolean;
  canEnroll: boolean;
  renderNothingIfConditionNotMet: boolean;
  initialBtnLabel?: JSX.Element | string;
  hasNoOpenSessions: boolean;
};
const styles = {
  loading: {
    color: 'var(--cds-color-neutral-primary)',
    borderColor: 'var(--cds-color-neutral-primary)',
  },
};

const CourseEnrollButton = ({
  btnAttributes = {},
  onClick,
  apiStatus,
  isEnrolled,
  canEnroll,
  renderNothingIfConditionNotMet,
  initialBtnLabel = _t('Enroll'),
  hasNoOpenSessions,
}: Props) => {
  const renderEnroll = !isEnrolled && canEnroll;
  const renderEnrolled = isEnrolled && !renderNothingIfConditionNotMet;

  if (renderEnroll) {
    return (
      <ApiButton
        {...btnAttributes}
        style={apiStatus === 'API_IN_PROGRESS' && { ...styles.loading }}
        htmlAttributes={{
          ...btnAttributes?.htmlAttributes,
          'aria-live': 'polite',
          'aria-busy': apiStatus === 'API_IN_PROGRESS',
        }}
        iconAttributes={{
          suppressTitle: true,
        }}
        onClick={onClick}
        apiStatus={apiStatus}
        apiStatusAttributesConfig={{
          label: {
            API_BEFORE_SEND: initialBtnLabel,
            API_IN_PROGRESS: _t('Enrolling...'),
            API_SUCCESS: _t('Enrolled'),
            API_ERROR: _t('Failed'),
          },
        }}
      />
    );
  } else if (renderEnrolled) {
    return <Button {...btnAttributes} type="disabled" label={_t('Enrolled')} disabled={true} />;
  } else if (hasNoOpenSessions) {
    return (
      <Button
        {...btnAttributes}
        type="primary"
        label={
          <div className="vertical-box">
            <span>{_t('Enroll')}</span>
            <span className="font-xs m-t-1s">{_t('No upcoming sessions available')}</span>
          </div>
        }
        disabled
      />
    );
  }
  return null;
};

export default pure(CourseEnrollButton);
