/* @jsx jsx */

/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react';

import * as React from 'react';

import logger from 'js/app/loggerSingleton';
import { FormattedMessage } from 'js/lib/coursera.react-intl';
import requestCountry from 'js/lib/requestCountry';

import { breakPoint } from '@coursera/coursera-ui';

import LoadingIcon from 'bundles/courseraLoadingIcon/LoadingIcon';
import type { PrepaidProduct } from 'bundles/enroll/types/ProductInfoTypes';
import type { ProductPrices } from 'bundles/payments-common/hooks/useGetProductPrices';
import type { ProductType } from 'bundles/payments/common/ProductType';
import NonRecurringChoice from 'bundles/s12n-enroll/components/non-recurring/NonRecurringChoice';
import NonRecurringChoiceTiles from 'bundles/s12n-enroll/components/non-recurring/NonRecurringChoiceTiles';
import type { ChoiceOption } from 'bundles/s12n-enroll/components/non-recurring/NonRecurringChoiceTiles';
import { NON_RECURRING_CHOICES } from 'bundles/s12n-enroll/components/non-recurring/utils/constants';
import {
  getSortedPaymentPassOptions,
  useNonRecurringChoiceData,
} from 'bundles/s12n-enroll/components/non-recurring/utils/nonRecurringChoiceDataUtils';
import { getDurationMessage } from 'bundles/s12n-enroll/components/non-recurring/utils/nonRecurringMessageUtils';

import _t from 'i18n!nls/s12n-enroll';

const NUM_OF_PREPAID_OPTIONS = 3;

export type PropsFromCaller = {
  numOfCourses: number;
  numOfHoursPerWeek: number;
  numOfMonths: number;
  choiceType: string;
  setChoiceType: (choice: string) => void;
  isFullDiscount?: boolean;
  s12nId: string;
};

const styles = {
  titleContainer: {
    padding: '0 50px',
    [`@media (max-width: ${breakPoint.md - 1}px)`]: {
      padding: '0 30px',
    },
  },
  title: {
    fontFamily: 'OpenSans',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '24px',
    marginBottom: '10px',
  },
  choicesContainer: {
    padding: '5px 50px 0',
    ':nth-of-type(n) > label': {
      borderBottom: '1px solid #BDBDBD',
    },
    ':nth-of-type(n) > label:last-of-type': {
      borderBottom: 'none',
    },
    [`@media (max-width: ${breakPoint.md - 1}px)`]: {
      padding: '5px 30px 50px',
    },
  },
};

export const getMonthsFromOwnershipDays = (ownershipDays: number) => Math.round(ownershipDays / 30);

export const getHoursPerWeek = (numOfMonths: number, numOfHoursPerWeek: number, ownershipMonths: number) =>
  Math.round((numOfMonths * numOfHoursPerWeek) / ownershipMonths);

const getProductPriceForPaymentPassOption = (
  productPrices: Array<ProductPrices>,
  prepaidOption: PrepaidProduct
): ProductPrices | undefined =>
  productPrices.find(({ productItemId }) => productItemId === prepaidOption.productItemId);

export const getPercentageOffBasePrice = (
  productPrice: ProductPrices | undefined,
  ownershipMonths: number,
  baseProductPrice: ProductPrices | undefined,
  baseOwnershipMonths: number
) => {
  if (!productPrice || !baseProductPrice) {
    return 0;
  }

  if (productPrice.amount === baseProductPrice.amount && ownershipMonths === baseOwnershipMonths) {
    return 0;
  }

  const basePriceForSameOwnershipMonths = (ownershipMonths / baseOwnershipMonths) * baseProductPrice.amount;
  const percentageOff = 1 - productPrice.amount / basePriceForSameOwnershipMonths;
  return Math.round(percentageOff * 100);
};

const getPaymentPassOptionData = (
  sortedPaymentPassOptions: Array<PrepaidProduct>,
  productPrices: Array<ProductPrices>,
  numOfMonths: number,
  numOfHoursPerWeek: number
): {
  productPrice: ProductPrices | undefined;
  numOfMonths: number;
  numOfHours: number;
  percentageOff: number;
  title: JSX.Element;
  duration: JSX.Element | string;
  productType: string | undefined;
}[] => {
  const basePaymentPassOption = sortedPaymentPassOptions[0];
  const baseOwnershipMonths = getMonthsFromOwnershipDays(basePaymentPassOption.productProperties.ownershipDays);
  const baseProductPrice = getProductPriceForPaymentPassOption(productPrices, basePaymentPassOption);

  return sortedPaymentPassOptions.map((paymentPassOption) => {
    const ownershipMonths = getMonthsFromOwnershipDays(paymentPassOption.productProperties.ownershipDays);
    const productPrice = getProductPriceForPaymentPassOption(productPrices, paymentPassOption);
    const numOfHours = getHoursPerWeek(numOfMonths, numOfHoursPerWeek, ownershipMonths);

    return {
      productPrice,
      numOfHours,
      numOfMonths: ownershipMonths,
      percentageOff: getPercentageOffBasePrice(productPrice, ownershipMonths, baseProductPrice, baseOwnershipMonths),
      title: (
        <FormattedMessage
          message={_t('{numOfMonths} {numOfMonths, plural, =1 {month} other {months}}')}
          numOfMonths={ownershipMonths}
        />
      ),
      duration: getDurationMessage(numOfHours),
      productType: paymentPassOption.productType as ProductType,
    };
  });
};

const NonRecurringChoices: React.FunctionComponent<PropsFromCaller> = ({
  numOfCourses,
  numOfHoursPerWeek,
  numOfMonths,
  choiceType,
  setChoiceType,
  isFullDiscount,
  s12nId,
}) => {
  const { prepaidProductPrices, prepaidOptions } = useNonRecurringChoiceData(s12nId);

  if (!prepaidProductPrices?.length || !prepaidOptions) {
    // Product prices is fetched async, so show loading state to user
    return <LoadingIcon />;
  } else if (
    prepaidOptions.length !== NUM_OF_PREPAID_OPTIONS ||
    prepaidProductPrices.length !== NUM_OF_PREPAID_OPTIONS
  ) {
    logger.error('Unable to render all three payment pass options');
    return null;
  }

  const sortedPaymentPassOptions = getSortedPaymentPassOptions(prepaidOptions);

  const paymentPassOptionData = getPaymentPassOptionData(
    sortedPaymentPassOptions,
    prepaidProductPrices,
    numOfMonths,
    numOfHoursPerWeek
  );

  const getPassesExtraInfo = () => {
    return _t(
      `We've estimated how many hours you'll need to learn each week to finish all #{numOfCourses} courses. One-time payment plans below do not automatically renew.`,
      { numOfCourses }
    );
  };

  if (!paymentPassOptionData) {
    logger.error('Did not retrieve the product price for all three payment pass options');
    return null;
  }

  return (
    <div data-testid="rc-NonRecurringChoices">
      <div css={styles.titleContainer}>
        <h3 css={styles.title}>{_t('How much time do you need to finish?')}</h3>
        {requestCountry.get() !== 'IN' && <p>{getPassesExtraInfo()}</p>}
      </div>
      <div css={styles.choicesContainer}>
        {requestCountry.get() === 'IN' ? (
          <NonRecurringChoiceTiles
            choices={paymentPassOptionData.reduce<ChoiceOption[]>((acc, option, index) => {
              const choice = Object.values(NON_RECURRING_CHOICES)[index];

              if (!choice) return acc;

              return [
                ...acc,
                {
                  ...option,
                  choice,
                },
              ];
            }, [])}
            choiceType={choiceType}
            onChange={setChoiceType}
            isFullDiscount={isFullDiscount}
          />
        ) : (
          <>
            <NonRecurringChoice
              choice={NON_RECURRING_CHOICES.PASS_ONE}
              currentChoice={choiceType}
              {...paymentPassOptionData[0]}
              onClick={() => setChoiceType(NON_RECURRING_CHOICES.PASS_ONE)}
              isFullDiscount={isFullDiscount}
            />
            <NonRecurringChoice
              choice={NON_RECURRING_CHOICES.PASS_TWO}
              currentChoice={choiceType}
              {...paymentPassOptionData[1]}
              onClick={() => setChoiceType(NON_RECURRING_CHOICES.PASS_TWO)}
            />
            <NonRecurringChoice
              choice={NON_RECURRING_CHOICES.PASS_THREE}
              currentChoice={choiceType}
              {...paymentPassOptionData[2]}
              onClick={() => setChoiceType(NON_RECURRING_CHOICES.PASS_THREE)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default NonRecurringChoices;
