import { useNaptime } from 'bundles/naptimejs';
import CartsV2 from 'bundles/naptimejs/resources/carts.v2';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import SubscriptionNextBillDetailsV1 from 'bundles/naptimejs/resources/subscriptionNextBillDetails.v1';

type PropsFromUseGetSubscriptionNextBillDetails = {
  subscriptionNextBillDetails?: SubscriptionNextBillDetailsV1;
  skip?: boolean;
};

type UseGetSubscriptionNextBillDetailsInput = {
  subscriptionId?: string;
  skip?: boolean;
};

export const useGetSubscriptionNextBillDetails = ({
  subscriptionId,
  skip = false,
}: UseGetSubscriptionNextBillDetailsInput) => {
  const {
    data: { subscriptionNextBillDetails },
  } = useNaptime<PropsFromUseGetSubscriptionNextBillDetails>(() => {
    if (skip || !subscriptionId) {
      return {};
    }

    return {
      subscriptionNextBillDetails: SubscriptionNextBillDetailsV1.get(subscriptionId, {
        fields: ['nextBillingPeriodAmount', 'currencyId', 'nextBillingAt'],
      }),
    };
  }, [subscriptionId]);

  return {
    subscriptionNextBillDetails,
  };
};

export const useCart = ({ skip = false, cartId }: { cartId?: number; skip?: boolean }) => {
  const {
    data: { cart },
  } = useNaptime<{ cart: CartsV2 }>(() => {
    if (!cartId || skip) {
      return {};
    }

    return {
      cart: CartsV2.get(cartId),
    };
  }, [cartId]);

  return { cart };
};
