import type { OnboardingContextType } from 'bundles/program-personalized-tab/contexts/OnboardingContext';

type CollectionType =
  | 'ONBOARDING_CTA'
  | 'PRODUCTS'
  | 'PRODUCTS_LEADERSHIP'
  | 'CLIPS'
  | 'CLIPS_LEADERSHIP'
  | 'ROLES'
  | 'RELATED_SKILLS'
  | 'RELATED_SKILLS_RECS'
  | 'ADMIN_CURATED'
  | 'POPULAR'
  | 'TOP_RECS'
  | 'SCORED_SKILL_RECS'
  | 'RECENT';

export const getCollectionOrder = (onboardingData: OnboardingContextType) => {
  const { hasCompletedOnboarding, selectedGoals, isUnsureOfRole, skillsLearnerInterestedIn } = onboardingData;
  const collections: CollectionType[] = [];

  // Learner has not completed onboarding
  if (!hasCompletedOnboarding) {
    collections.push('ONBOARDING_CTA', 'ROLES');
    if (skillsLearnerInterestedIn && skillsLearnerInterestedIn.length === 0) {
      collections.push('RELATED_SKILLS', 'RELATED_SKILLS_RECS');
    } else {
      collections.push('RELATED_SKILLS_RECS', 'RELATED_SKILLS');
    }

    // Advancer, Switcher, Starter, Explorer, Credentials
  } else if (
    selectedGoals?.includes('GROW') ||
    selectedGoals?.includes('SWITCH') ||
    selectedGoals?.includes('LAND') ||
    selectedGoals?.includes('EXPLORE') ||
    selectedGoals?.includes('CREDENTIALS')
  ) {
    if (isUnsureOfRole) {
      collections.push('ROLES');
    }
    collections.push('RELATED_SKILLS_RECS', 'PRODUCTS', 'SCORED_SKILL_RECS');
    if (skillsLearnerInterestedIn && skillsLearnerInterestedIn.length === 0) {
      collections.push('RELATED_SKILLS');
    }
    if (selectedGoals?.includes('LEADER')) {
      collections.push('PRODUCTS_LEADERSHIP');
    }
    if (skillsLearnerInterestedIn && skillsLearnerInterestedIn.length > 0) {
      collections.push('RELATED_SKILLS');
    }
    collections.push('CLIPS');

    // Leadership only
  } else if (selectedGoals?.includes('LEADER')) {
    if (isUnsureOfRole) {
      collections.push('ROLES');
    }
    if (skillsLearnerInterestedIn && skillsLearnerInterestedIn.length > 0) {
      collections.push('RELATED_SKILLS_RECS', 'PRODUCTS_LEADERSHIP', 'RELATED_SKILLS', 'CLIPS_LEADERSHIP');
    } else {
      collections.push('PRODUCTS_LEADERSHIP', 'RELATED_SKILLS', 'CLIPS_LEADERSHIP');
    }
  }
  return collections;
};
