import URI from 'jsuri';
import Q from 'q';

import API from 'js/lib/api';

import type { CmlContent } from 'bundles/cml';

const feedbackAPI = API('/api/feedbackEmails.v1/', { type: 'rest' });
const sentMessageAPI = API('/api/sentTargetedMessages.v1/', { type: 'rest' });

const ReplyMessageAPIUtils = {
  sendMessage(entryType: $TSFixMe, entryId: $TSFixMe, anonymizeSender: $TSFixMe, message: CmlContent) {
    const uri = new URI()
      .addQueryParam('action', 'publish')
      .addQueryParam('entryType', entryType)
      .addQueryParam('entryId', entryId)
      .addQueryParam('anonymizeSender', anonymizeSender);

    return Q(
      feedbackAPI.post(uri.toString(), {
        data: {
          cmlContent: message,
        },
      })
    );
  },

  getSentMessage(entryId: $TSFixMe) {
    const id = entryId.replace(/~/g, '!~');
    const uri = new URI()
      .addQueryParam('q', 'byScopeAndType')
      .addQueryParam('messageType', 'FeedbackEmailType')
      .addQueryParam('scope', `Feedback~${id}`);

    return Q(sentMessageAPI.get(uri.toString()));
  },
};

export default ReplyMessageAPIUtils;

export const { sendMessage, getSentMessage } = ReplyMessageAPIUtils;
