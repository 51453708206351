import { createFeatureScope } from 'bundles/product-features';

export const { ProductFeaturesQuery, withProductFeatures, useProductFeatures } = createFeatureScope({
  enterprise: {
    browseOnlyProgram: true,
    enableAcademicDisciplines: false,
    enableOrgHomepage: true,
    programContentBox: true,
    programEulaBanner: true,
    programJoinTrackingVerbiage: true,
    programSponsoredByMessage: true,
    programHideCourseraRecommendations: false,
    programJoinAgeVerification: false,
    programCollectionSectionDescription: true,
    programRecommendationsAvailableCoursesCopy: false,
    filledProgramCustomMessage: '',
    allowOrgForCurriculumBuilder: false,
    allowOrgForSpecializationConfiguration: false,
    programJoinTrackingCustomMessage: '',
    programDenyCustomMessage: '',
    programSkillSetFilters: false,
    isCareerAcademyLimitedCatalog: false,
    enableEnterpriseCustomBadges: false,
    enableSkillsInSearchAndBrowse: true,
    allowShortFormContentBasedOnAudience: false,
    enableSkillsDashboard: true,
    enableSkillsetRoleFilter: true,
    enableRenderDomainsMenu: true,
    enableInvitedOnlyProgram: false,
    personalizedRecommendations: true,
    inviteOnlyProgramDenyCustomMessage: '',
    enableOrgHomepageAutoJoinProgram: false,
  },
  diagnostics: {
    enableSkillsDiagnostics: true,
  },
});
