/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { useQuery } from '@apollo/client';
import type { DiscoveryCollections_PassThroughParameter as RequestSkillParams } from '__generated__/graphql-types';

import { InlineNotification } from '@coursera/cds-core';

import Collection from 'bundles/enterprise-collections/components/Collection';
import { EnterpriseProductCardCollectionsPlaceholder } from 'bundles/enterprise-collections/components/EnterpriseProductCardCollection';
import type { Product, ProductType } from 'bundles/program-common/types/programCommon';
import { Section } from 'bundles/program-home/components/AutoHeading';
import { MAX_ONBOARDING_SKILLS_LENGTH } from 'bundles/program-home/constants/skillCollectionsConstants';
import ClipsForSkillsInProgram from 'bundles/program-home/queries/ClipsForSkillsInProgram.graphql';
import type {
  ClipsForSkillsInProgramQuery,
  ClipsForSkillsInProgramQueryVariables,
} from 'bundles/program-home/queries/__generated__/ClipsForSkillsInProgram';
import type { DiscoClipsCollections, OnProductCardClick } from 'bundles/program-home/types/Products';
import { mergeAndMapSkillsClipsCollections } from 'bundles/program-home/utils/skillsClipsUtils';

import _t from 'i18n!nls/program-home';

type Props = {
  programId: string;
  onProductCardClick: OnProductCardClick;
  isAuthenticatedUser: boolean;
  skillNames?: string[] | undefined;
  translatedTitleText: string;
  translatedDescriptionText?: string;
  collectionId: string;
  onboardingDataLoading?: boolean;
};

type CollectionsViewProps = Omit<
  Props,
  'isAuthenticatedUser' | 'length' | 'onboardingSkills' | 'translatedTitleText'
> & {
  skillsClipsCollections: DiscoClipsCollections | undefined;
  loading: boolean;
  error: boolean;
};

type PlaceholderProps = {
  length: number;
};

const styles = {
  headerSection: () => css`
    margin-bottom: var(--cds-spacing-300);
  `,
};

const CollectionsViewPlaceholder = ({ length }: PlaceholderProps) => {
  return (
    <div>
      {[...Array(length)].map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <EnterpriseProductCardCollectionsPlaceholder key={`collection-list-item-${index}`} />
      ))}
    </div>
  );
};

const ClipsCollectionsView = ({
  onProductCardClick,
  skillsClipsCollections,
  loading,
  error,
  programId,
  translatedDescriptionText,
  collectionId,
  onboardingDataLoading,
}: CollectionsViewProps): JSX.Element | null => {
  if (error) {
    return (
      <InlineNotification severity="error">
        {_t('Sorry! Something went wrong. Please refresh the page.')}
      </InlineNotification>
    );
  } else if (loading || onboardingDataLoading) {
    return <CollectionsViewPlaceholder length={1} />;
  }

  if (!skillsClipsCollections || skillsClipsCollections?.length === 0) {
    return null;
  }

  const handleProductCardClick =
    (collectionTrackingId: string | null | undefined) => (product: Product, productType: ProductType) => {
      onProductCardClick(product, productType, collectionTrackingId, programId);
    };

  const collection = mergeAndMapSkillsClipsCollections(
    skillsClipsCollections ?? [],
    translatedDescriptionText ?? '',
    collectionId
  );
  return (
    <Collection
      key={collection.id}
      id={collection.id}
      customCollection={collection}
      collection={collection}
      onProductCardClick={handleProductCardClick(collection.collectionTrackingId)}
      disableDescription={!translatedDescriptionText}
      itemIdMappingFunction={(id: string) => id}
    />
  );
};

const useSkillClipsForSkillIdQuery = (
  programId: string,
  skillParams: RequestSkillParams[],
  onboardingDataLoading: boolean
) => {
  const { loading, error, data } = useQuery<ClipsForSkillsInProgramQuery, ClipsForSkillsInProgramQueryVariables>(
    ClipsForSkillsInProgram,
    {
      variables: {
        programId,
        skillParams,
        contextId: 'program-home-skill-based-clips',
      },
      skip: skillParams.length < 1 || onboardingDataLoading,
      ssr: false,
      notifyOnNetworkStatusChange: true,
      context: { clientName: 'gatewayGql' },
    }
  );
  return { loading, error, data };
};

function SkillsClipsCollection({
  programId,
  onProductCardClick,
  skillNames,
  isAuthenticatedUser,
  translatedTitleText,
  translatedDescriptionText,
  collectionId,
  onboardingDataLoading,
}: Props) {
  const [clipsSkillsRequestParams, setClipsSkillsRequestParams] = React.useState<RequestSkillParams[]>([]);
  const {
    loading: clipsLoading,
    error: clipsLoadingError,
    data: clipsCollectionsData,
  } = useSkillClipsForSkillIdQuery(programId, clipsSkillsRequestParams, onboardingDataLoading ?? false);

  // asserting type here as a quick fix for the complexity of the type returned from graphql. The endpoint can
  // return a collection of s12n, courses, etc, but in this case it's only ever going to be clips.
  const typeSafeCollections: DiscoClipsCollections = (clipsCollectionsData?.DiscoveryCollections
    ?.queryEnterpriseCollectionsByProgram?.productCollections || []) as DiscoClipsCollections;

  React.useMemo(() => {
    const requestSkillsParams = (skillNames?.slice(0, MAX_ONBOARDING_SKILLS_LENGTH) ?? []).map((skillName, i) => ({
      name: `skill-based-clips-${i + 1}.skillName`,
      value: skillName,
    }));
    setClipsSkillsRequestParams(isAuthenticatedUser ? requestSkillsParams : []);
  }, [skillNames, isAuthenticatedUser]);

  // hide the full section if there are no recs found after making the request
  if (!isAuthenticatedUser || (!clipsLoading && !clipsLoadingError && !typeSafeCollections?.length)) return null;

  return (
    <div className="rc-SkillCollections">
      <Section initialLevel={3}>
        <ClipsCollectionsView
          skillsClipsCollections={typeSafeCollections}
          loading={clipsLoading || !!onboardingDataLoading}
          error={!!clipsLoadingError}
          onProductCardClick={onProductCardClick}
          programId={programId}
          collectionId={collectionId}
          translatedDescriptionText={translatedTitleText}
        />
      </Section>
    </div>
  );
}

export default SkillsClipsCollection;
