import { useItemIndexInCollection } from '@core/utils';
import VisuallyHidden from '@core/VisuallyHidden';

export type Props = {
  id: string;
  groupId: string;
  groupLabel: string;
};

const VisuallyHiddenGroupLabel = (props: Props) => {
  const { id, groupId, groupLabel } = props;
  const indexInTheGroup = useItemIndexInCollection(id, groupId);

  if (indexInTheGroup !== 0) {
    return null;
  }

  return (
    <VisuallyHidden>
      {groupLabel}
      {','}
    </VisuallyHidden>
  );
};

export default VisuallyHiddenGroupLabel;
