import config from 'js/app/config';
import path from 'js/lib/path';

export const websitesInfo: Record<string, { icon: string; prefix: string }> = {
  website: {
    icon: 'cif-globe',
    prefix: '',
  },
  websiteFacebook: {
    icon: 'cif-facebook-official',
    prefix: 'https://www.facebook.com/',
  },
  websiteTwitter: {
    icon: 'cif-twitter',
    prefix: 'https://www.twitter.com/',
  },
  websiteLinkedin: {
    icon: 'cif-linkedin-square',
    prefix: 'https://www.linkedin.com/in/',
  },
};

const exported = {
  noheadshotUrl: path.join(config.url.resource_assets, 'pages/about/images/noheadshot.png'),
  masterTrackVariants: {
    Mastertrack: 'MasterTrack',
    PostgraduateDiploma: 'PostgraduateDiploma',
    UniversityCertificate: 'UniversityCertificate',
  },
  BLOCKED_RUSSIAN_PARTNERS_SHORTNAMES: [
    'tsu',
    'donstu',
    'bauman',
    'bioinfoinstitute',
    'hse',
    'yandex',
    'spbpu',
    'spbu',
    'sberbank-university',
    'nsu',
    'mephi',
    'mipt',
    'mgimo',
    'mailru',
    'eldf',
  ],
};

export default exported;

export const { noheadshotUrl, BLOCKED_RUSSIAN_PARTNERS_SHORTNAMES, masterTrackVariants } = exported;
