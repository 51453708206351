/** @jsx jsx */
import { jsx } from '@emotion/react';

import Imgix from 'js/components/Imgix';

import LazyImgix from 'bundles/page/components/shared/LazyImgix';
import type { ProductCardImageFitProps } from 'bundles/product-card/components/legacy/ProductCardImage';
import { getProductCardImageUrl } from 'bundles/product-card/components/legacy/utils/getProductCardImageUrl';
import type { SearchIndexHit } from 'bundles/search-common/types';

interface SearchCardImageProps {
  hit: SearchIndexHit;
  hitPosition?: number;
  searchCardImageProps?: ProductCardImageFitProps;
  enableFluidWidthForLazyImg?: boolean;
}

export const SearchCardImage = ({
  hit,
  hitPosition,
  searchCardImageProps,
  enableFluidWidthForLazyImg,
}: SearchCardImageProps) => {
  const imgixProps = {
    src: getProductCardImageUrl(hit.id, hit.imageUrl, hit.partnerLogos),
    alt: '',
    maxHeight: 330,
    maxWidth: 330,
    imgParams: {
      fit: 'fill',
      auto: 'format,compress',
      quality: '25',
      ...(searchCardImageProps || {}),
    },
    style: { width: '100%', maxHeight: 'none', maxWidth: 'none' },
  };

  // If card is after the 6th element in list, use LazyImgix for perf boost.
  if (hitPosition && hitPosition > 5) {
    return <LazyImgix {...{ ...imgixProps, enableFluidWidth: enableFluidWidthForLazyImg }} />;
  } else {
    return <Imgix {...imgixProps} />;
  }
};
