import URI from 'jsuri';

import type { CartCreateOptions } from 'bundles/payments/common/types';
import credentialTrackSubscriptionsApi from 'bundles/subscriptions/api/credentialTrackSubscriptionsV1';

const createCart = (options: CartCreateOptions) => {
  const uri = new URI();
  uri.addQueryParam('action', 'createCart');
  return credentialTrackSubscriptionsApi.post(uri.toString(), { data: options });
};

export default createCart;
