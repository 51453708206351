/** @jsx jsx */
import { jsx } from '@emotion/react';

import { Loading } from '@coursera/coursera-ui';

import type { Props } from 'bundles/program-home/components/enterprise-home/MyCoursesContainer';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import { MyCoursesContainer } from 'bundles/program-home/components/enterprise-home/MyCoursesContainer';

type WrapperProps = Omit<
  Props,
  'hasNoEnrolledItems' | 'isSummaryView' | 'completedProducts' | 'inProgressEnrolledProducts'
> & {
  isCompletedProducts?: boolean;
};

const MyCoursesContainerWrapper = ({
  loadingCourses,
  loadingS12ns,
  unfilteredOrderedPresentProducts,
  ...rest
}: WrapperProps) => {
  if (loadingCourses || loadingS12ns) {
    return <Loading data-testid="loading" />;
  }

  return (
    <MyCoursesContainer
      {...rest}
      isSummaryView={false}
      shouldShowSavedCourses={false}
      completedProducts={[]}
      inProgressEnrolledProducts={[]}
      unfilteredOrderedPresentProducts={unfilteredOrderedPresentProducts}
      hasNoEnrolledItems={!unfilteredOrderedPresentProducts || unfilteredOrderedPresentProducts.length === 0}
    />
  );
};

export default MyCoursesContainerWrapper;
