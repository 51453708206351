import * as React from 'react';
import { useEffect } from 'react';

import user from 'js/lib/user';

import SubscriptionTiersSideBySideEnrollModal from 'bundles/coursera-plus/components/subscriptionTiers/SubscriptionTiersSideBySideEnrollModal';
import SubscriptionTiersWithS12nEnrollModal from 'bundles/coursera-plus/components/subscriptionTiers/SubscriptionTiersWithS12nEnrollModal';
import AllPlansModal from 'bundles/coursera-plus/components/subscriptionTiers/tiersDesignV3/AllPlansModal';
import CourseraPlusHeroModal from 'bundles/coursera-plus/components/subscriptionTiers/tiersDesignV3/CourseraPlusHeroModal';
import {
  generateImpressionForTiersEnrollmentFromStandaloneCourses,
  isLiteSideBySideEnrollModalShown,
  isSubscriptionTiersDesignV3Variant1,
  isSubscriptionTiersDesignV3Variant3,
  isSubscriptionTiersDesignV3Variant4,
  isSubscriptionTiersDesignV3Variant5,
  isSubscriptionTiersVariantC,
} from 'bundles/coursera-plus/utils/subscriptionTiersUtils';
import CourseraPlusEnrollModal from 'bundles/enroll/components/coursera-plus/CourseraPlusEnrollModal';
import SubscriptionEnrollModal from 'bundles/enroll/components/subscriptions/SubscriptionEnrollModal';
import { useS12nEnrollModalData } from 'bundles/enroll/data/usePageData';
import { useEnrollInS12nSubscription } from 'bundles/enroll/utils/useEnrollInS12nSubscription';
import paymentsExperiments from 'bundles/epic/clients/payments';
import paymentsbackendExperiments from 'bundles/epic/clients/payments-backend';
import PartnersV1 from 'bundles/naptimejs/resources/partners.v1';
import {
  CHECKOUT_PARAM,
  generateCombineFreeTrialAndCheckoutImpression,
  isCombineFreeTrialAndCheckoutEligible,
  isCombineFreeTrialAndCheckoutEnabled,
} from 'bundles/payments-common/utils/combineFreeTrialAndCheckoutUtils';
import EnterpriseEnrollmentChoiceModalForXDP from 'bundles/program-common/components/EnterpriseEnrollmentChoiceModalForXDP';
import { PRODUCT_TYPES } from 'bundles/program-common/constants/ProgramActionConstants';
import S12nBulkPayEnrollModal from 'bundles/s12n-enroll/components/bulk-pay/S12nBulkPayEnrollModal';
import NonRecurringEnrollModal from 'bundles/s12n-enroll/components/non-recurring/NonRecurringEnrollModal';
import S12nPaymentMethodEnrollModal from 'bundles/s12n-enroll/components/non-recurring/S12nPaymentMethodEnrollModal';
import S12nPreEnrollModal from 'bundles/s12n-enroll/components/pre-enrollment/S12nPreEnrollModal';

export type PropsFromCaller = {
  s12nId: string;
  courseId?: string;
  partnerIds?: Array<string>;
  onSdp?: boolean;
  onClose: () => void;
};

const S12nEnrollModal: React.FC<PropsFromCaller> = ({ s12nId, onClose, courseId, onSdp = false }) => {
  const {
    enrollmentAvailableChoices,
    programs,
    invitedPrograms,
    thirdPartyOrganizations,
    invitedThirdPartyOrganizations,
    partners,
    s12n,
    promotionEligibilities,
  } = useS12nEnrollModalData();

  const {
    canEnrollThroughS12nSubscriptionFreeTrial,
    canSubscribeToS12n,
    canEnrollThroughCourseraPlus,
    canEnrollThroughS12nPrepaid,
    canEnrollThroughProgram,
    canEnrollThroughProgramInvitation,
    canPurchaseThroughCourseraPlus,
    canSubscribeToCourseraLite,
    canSubscribeToCourseraPlus,
  } = enrollmentAvailableChoices;

  const canSeePaymentMethodModal = canEnrollThroughS12nPrepaid && canSubscribeToS12n;
  const isCourseraPlusPrepaidEnabled = paymentsbackendExperiments.preview('courseraPlusPrepaidEnabled');
  const canSeeNonRecurringModal =
    (canEnrollThroughS12nPrepaid && !canSubscribeToS12n) ||
    (isCourseraPlusPrepaidEnabled && canPurchaseThroughCourseraPlus);
  const hasPromotion = Boolean(promotionEligibilities?.isEligible ? promotionEligibilities?.promoCodeId : undefined);

  const isEnterpriseUser = canEnrollThroughProgram || canEnrollThroughProgramInvitation;
  const waitForBothProgramDataFetch = canEnrollThroughProgram && canEnrollThroughProgramInvitation;

  const shouldShowPreEnrollModal =
    !paymentsExperiments.preview('disablePreEnrollment') &&
    (enrollmentAvailableChoices.canPreEnroll || enrollmentAvailableChoices.isPreEnrolled);
  const partnersListWithIds = partners ?? s12n.partnerIds?.map((id) => new PartnersV1({ id }));
  const hasSpecialization = Boolean(s12n);
  const showSubscriptionTiersDesignV3Variant3 = isSubscriptionTiersDesignV3Variant3({
    hasSpecialization: Boolean(s12n),
    partners: partnersListWithIds,
  });

  const shouldGenerateFreeTrialAndCheckoutEligibleImpression =
    isCombineFreeTrialAndCheckoutEligible(enrollmentAvailableChoices);
  const shouldRedirectToS12nSubscriptionCheckout =
    shouldGenerateFreeTrialAndCheckoutEligibleImpression && isCombineFreeTrialAndCheckoutEnabled();
  const { enrollInSubscription } = useEnrollInS12nSubscription();

  useEffect(() => {
    if (shouldGenerateFreeTrialAndCheckoutEligibleImpression) {
      generateCombineFreeTrialAndCheckoutImpression();
    }
  }, [shouldGenerateFreeTrialAndCheckoutEligibleImpression]);

  useEffect(() => {
    if (shouldRedirectToS12nSubscriptionCheckout) {
      enrollInSubscription({ [CHECKOUT_PARAM]: 'true' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRedirectToS12nSubscriptionCheckout]);

  useEffect(() => {
    if (canSubscribeToCourseraPlus && !hasPromotion) {
      generateImpressionForTiersEnrollmentFromStandaloneCourses({
        hasSpecialization,
        hasPromotion,
        isStandaloneCourseEligibleForTiersEnroll: false,
        partners: partnersListWithIds,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canSubscribeToCourseraPlus, hasPromotion, hasSpecialization, partnersListWithIds?.[0]]);

  // For the list of s12ns that are in the pre enrolled flow, show the pre enroll modal before any other s12n modal
  if (shouldShowPreEnrollModal && s12nId) {
    return (
      <S12nPreEnrollModal
        s12nId={s12nId}
        onSdp={onSdp}
        onClose={onClose}
        courseId={courseId}
        automaticallyEnroll={isEnterpriseUser || canEnrollThroughCourseraPlus}
      />
    );
  } else if (isEnterpriseUser) {
    return (
      <EnterpriseEnrollmentChoiceModalForXDP
        thirdPartyOrganizations={thirdPartyOrganizations}
        programs={programs}
        invitedPrograms={invitedPrograms}
        invitedThirdPartyOrganizations={invitedThirdPartyOrganizations}
        productId={s12nId}
        productType={PRODUCT_TYPES.SPECIALIZATION}
        userId={user.get().id}
        handleClose={onClose}
        waitForBothProgramDataFetch={waitForBothProgramDataFetch}
      />
    );
    // Only trigger Coursera Plus flow for users who are already subscribed users
  } else if (canEnrollThroughCourseraPlus) {
    return <CourseraPlusEnrollModal s12nId={s12nId} courseId={courseId} onClose={onClose} />;
  } else if (canSubscribeToCourseraLite && isSubscriptionTiersDesignV3Variant4() && !hasPromotion) {
    return <AllPlansModal onClose={onClose} />;
  } else if (
    canSubscribeToCourseraLite &&
    !hasPromotion &&
    (showSubscriptionTiersDesignV3Variant3 || isSubscriptionTiersDesignV3Variant5())
  ) {
    return <CourseraPlusHeroModal handleClose={onClose} isTiersVariant3={showSubscriptionTiersDesignV3Variant3} />;
  } else if (canSubscribeToCourseraLite && isLiteSideBySideEnrollModalShown() && !hasPromotion) {
    return <SubscriptionTiersSideBySideEnrollModal onClose={onClose} onSdp={onSdp} />;
  } else if (
    canSubscribeToCourseraLite &&
    !hasPromotion &&
    (isSubscriptionTiersVariantC() || isSubscriptionTiersDesignV3Variant1())
  ) {
    return <SubscriptionTiersWithS12nEnrollModal onClose={onClose} onSdp={onSdp} />;
  } else if (canSeePaymentMethodModal) {
    return <S12nPaymentMethodEnrollModal s12nId={s12nId} courseId={courseId} onClose={onClose} onSdp={onSdp} />;
  } else if (canSeeNonRecurringModal) {
    return <NonRecurringEnrollModal s12nId={s12nId} courseId={courseId} onClose={onClose} onSdp={onSdp} />;
  } else if (shouldRedirectToS12nSubscriptionCheckout) {
    return null;
  } else if (canSubscribeToS12n) {
    return <SubscriptionEnrollModal onClose={onClose} onSdp={onSdp} />;
  } else {
    return <S12nBulkPayEnrollModal onClose={onClose} onSdp={onSdp} />;
  }
};

export default S12nEnrollModal;
