import * as React from 'react';
import { useState } from 'react';

import type { InjectedRouter } from 'js/lib/connectToRouter';

import CourseEnrollModal from 'bundles/enroll-course/components/CourseEnrollModal';
import type { PropsFromCaller as CourseEnrollModalProps } from 'bundles/enroll-course/components/CourseEnrollModal';
import PageDataProvider from 'bundles/enroll/data/PageDataProvider';
import S12nEnrollModal from 'bundles/s12n-enroll/components/S12nEnrollModal';
import type { PropsFromCaller as S12nEnrollModalProps } from 'bundles/s12n-enroll/components/S12nEnrollModal';

export type PropsFromCaller =
  | S12nEnrollModalProps
  | (Omit<CourseEnrollModalProps, 'onS12nSelect'> & { router?: InjectedRouter });

const isS12nProps = (props: PropsFromCaller): props is S12nEnrollModalProps =>
  Boolean(props.s12nId && (props as S12nEnrollModalProps).onSdp);

const isCourseProps = (props: PropsFromCaller): props is CourseEnrollModalProps =>
  Boolean(props.courseId && !(props as S12nEnrollModalProps).onSdp);

const EnrollModal: React.FC<PropsFromCaller> = (props) => {
  // For Mix and Match, pass the selected s12n back to the provider
  const [selectedS12nId, setSelectedS12nId] = useState<string | undefined>();
  let childComponent;

  // TODO remove passing props to child components once all data is in provider
  if (isS12nProps(props)) {
    childComponent = <S12nEnrollModal {...props} />;
  } else if (isCourseProps(props)) {
    childComponent = <CourseEnrollModal {...props} onS12nSelect={setSelectedS12nId} />;
  } else {
    throw new Error('Cannot render EnrollModal with invalid props');
  }

  return (
    <PageDataProvider {...props} selectedS12nId={selectedS12nId}>
      {childComponent}
    </PageDataProvider>
  );
};

export default EnrollModal;
