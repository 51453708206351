/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { Typography2, useTheme } from '@coursera/cds-core';

import _t from 'i18n!nls/skills-common';

import ProgressBar from './ProgressBar';

const useStyles = () => {
  const { spacing } = useTheme();
  return {
    progressTrackerHeader: css`
      display: flex;
      justify-content: between;
      align-items: baseline;
      margin-top: -6px;
    `,
    score: css`
      flex: 0 0 auto;
      display: flex;
      margin-top: ${spacing(8)};
    `,
  };
};

const UnscorableProgressTracker = () => {
  const styles = useStyles();
  const { palette } = useTheme();
  return (
    <div>
      <div css={styles.progressTrackerHeader}>
        <Typography2 variant="bodySecondary" component="span" css={styles.score}>
          {_t('Skill scoring coming soon')}
        </Typography2>
      </div>
      <ProgressBar
        palette={{
          empty: palette.gray[300],
          border: palette.gray[300],
        }}
      />
    </div>
  );
};

export default UnscorableProgressTracker;
