import { LEARNING_PRODUCTS } from 'bundles/search-common/constants';

const isPremiumProduct = (entityType: typeof LEARNING_PRODUCTS[keyof typeof LEARNING_PRODUCTS]) => {
  return (
    entityType === LEARNING_PRODUCTS.Degree ||
    entityType === LEARNING_PRODUCTS.Mastertrack ||
    entityType === LEARNING_PRODUCTS.UniversityCertificate ||
    entityType === LEARNING_PRODUCTS.GraduateCertificate ||
    entityType === LEARNING_PRODUCTS.PostgraduateDiploma
  );
};

export default isPremiumProduct;
