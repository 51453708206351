/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useState } from 'react';

import { type ShiftOptions, type VirtualElement, limitShift } from '@floating-ui/react-dom';
import { ReactEditor, type RenderElementProps, useSlateStatic } from 'slate-react';

import createLoadableComponent from 'js/lib/createLoadableComponent';

import { useStyleContext } from 'bundles/cml/editor/context/styleContext';
import { getSelectedRange } from 'bundles/cml/editor/utils/writingAssistantUtils';
import FloatingMenu from 'bundles/cml/shared/components/menu/FloatingMenu';
import type { AIElement as AIElementType } from 'bundles/cml/shared/types/elementTypes';

// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
const AIEditor = createLoadableComponent(() => import('bundles/cml/editor/components/elements/ai/AIEditor'));

const styles = {
  floatingMenu: css`
    border: none;
    background: transparent;

    .rc-CMLEditor {
      border-radius: var(--cds-border-radius-50);
    }
  `,
  hidden: css`
    visibility: hidden;
  `,
};

const createVirtualElement = (parentEl: HTMLDivElement | null, range: Range | null): VirtualElement | null => {
  if (!range || !parentEl) {
    return null;
  }

  return {
    getBoundingClientRect: () => {
      const rect = range.getBoundingClientRect();
      const parentRect = parentEl.getBoundingClientRect();

      const mergedRect = {
        y: rect.y,
        top: rect.y,
        bottom: rect.bottom,
        height: rect.height,
        x: parentRect.x,
        left: parentRect.left,
        right: parentRect.right,
        width: parentRect.width,
      };

      return mergedRect;
    },
    contextElement: parentEl,
  };
};

const SHIFT_OPTIONS: ShiftOptions = {
  mainAxis: false,
  crossAxis: true,
  limiter: limitShift({ mainAxis: false, crossAxis: true }),
};

const DOM_RANGE_TIMEOUT_MS = 100;
const INLINE_OFFSET = 12;

const AIElement: React.FC<RenderElementProps> = ({ attributes, children, element }: RenderElementProps) => {
  const staticEditor = useSlateStatic();
  const aiElement = element as AIElementType;
  const [parentRef, setParentRef] = useState<HTMLDivElement | null>(null);
  const { pageless } = useStyleContext();
  const [hidden, setHidden] = useState(false);
  const [virtualEl, setVirtualEl] = useState<VirtualElement | null>(null);

  useEffect(() => {
    if (!pageless) {
      return () => undefined;
    }

    const timeout = setTimeout(() => {
      const range = getSelectedRange(staticEditor, aiElement);
      try {
        const domRange = range ? ReactEditor.toDOMRange(staticEditor, range) : null;
        setVirtualEl(createVirtualElement(parentRef, domRange));
      } catch {
        // eslint-disable-next-line no-console
        console.error('[CMLEditor] Unable to get range from selection');
      }
    }, DOM_RANGE_TIMEOUT_MS);

    return () => clearTimeout(timeout);
  }, [staticEditor, aiElement, parentRef, pageless]);

  const { anchorEl } = aiElement;

  const placement = pageless ? 'bottom' : 'bottom-start';
  const offset = pageless ? undefined : INLINE_OFFSET;

  return (
    <div {...attributes}>
      <div ref={setParentRef}>{children}</div>
      <div contentEditable={false}>
        <FloatingMenu
          anchorEl={anchorEl ?? virtualEl}
          strategy="fixed"
          pageless={pageless}
          placement={placement}
          onHide={setHidden}
          enableEscaped={pageless}
          shiftOptions={SHIFT_OPTIONS}
          offset={offset}
          css={[styles.floatingMenu, hidden && styles.hidden]}
        >
          <AIEditor aiElement={aiElement} />
        </FloatingMenu>
      </div>
    </div>
  );
};

export default AIElement;
