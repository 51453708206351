/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Grid, Typography, Typography2, breakpoints } from '@coursera/cds-core';

import type { InformationCardProps } from 'bundles/program-common/components/InformationCard/InformationCard';
import InformationCard from 'bundles/program-common/components/InformationCard/InformationCard';
import InformationCardBody from 'bundles/program-common/components/InformationCard/InformationCardBody';
import SkillSetInfo1Svg from 'bundles/program-home/components/multiprogram/assets/svg/SkillSetInfo1Svg';
import SkillSetInfo2Svg from 'bundles/program-home/components/multiprogram/assets/svg/SkillSetInfo2Svg';
import SkillSetInfo3Svg from 'bundles/program-home/components/multiprogram/assets/svg/SkillSetInfo3Svg';

import _t from 'i18n!nls/program-home';

type SkillSetInformationCarddProps = Omit<InformationCardProps, 'trackingName' | 'trackingData'>;

export type SkillSetInfoSvgProps = React.SVGProps<SVGSVGElement>;

type SkillSetInfoProp = {
  SvgElement: React.ComponentType<SkillSetInfoSvgProps>;
  heading: string;
  description: string;
};

const styles = {
  skillSetInformationContainer: css`
    ${breakpoints.down('xl')} {
      padding: var(--cds-spacing-400) var(--cds-spacing-600);
    }
    ${breakpoints.down('md')} {
      padding: var(--cds-spacing-400);
    }

    background-color: var(--cds-color-purple-25);
    border: 1px solid var(--cds-color-grey-600);
    border-radius: 4px;
  `,
  descriptionHeading: css`
    margin-bottom: var(--cds-spacing-300);
  `,
  imageContainer: css`
    width: 100%;
    ${breakpoints.down('xl')} {
      height: 164px;
    }
    ${breakpoints.down('sm')} {
      height: 140px;
    }
    ${breakpoints.down('xs')} {
      height: 234px;
    }

    background: var(--cds-color-white-0);
    margin-bottom: var(--cds-spacing-300);
    display: flex;
  `,
  infoImage: css`
    ${breakpoints.down('xl')} {
      height: 110px;
    }
    ${breakpoints.down('sm')} {
      height: 65%;
      width: 80%;
    }
    ${breakpoints.down('xs')} {
      height: 120px;
    }

    margin: auto;
  `,
  infoHeading: css`
    margin-bottom: var(--cds-spacing-150);
  `,
};

const SkillSetInfoSection = ({ SvgElement, heading, description }: SkillSetInfoProp) => {
  return (
    <div>
      <div css={styles.imageContainer}>
        <SvgElement css={styles.infoImage} />
      </div>
      <Typography2 css={styles.infoHeading} component="div" variant="subtitleMedium">
        {heading}
      </Typography2>
      <Typography2 component="p" variant="bodySecondary">
        {description}
      </Typography2>
    </div>
  );
};

const SkillSetInformationCard: React.FC<SkillSetInformationCarddProps> = (props) => {
  return (
    <InformationCard {...props} trackingName="skillset_discovery_information_card">
      <InformationCardBody isFullBleed>
        <div css={styles.skillSetInformationContainer}>
          <Typography css={styles.descriptionHeading} variant="h2semibold" component="div">
            {_t('Fast track your professional growth with SkillSets')}
          </Typography>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={4}>
              <SkillSetInfoSection
                SvgElement={SkillSetInfo1Svg}
                heading={_t('Get current, in-demand skills you need')}
                description={_t(
                  'Learn the latest  skills that matter for your job and to your organization—all powered by world-class content from top universities and companies'
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SkillSetInfoSection
                SvgElement={SkillSetInfo2Svg}
                heading={_t('Grow with personalized recommendations')}
                description={_t(
                  'Enroll in personalized recommendations to increase your proficiency level—the recommendations will keep updating to match your level'
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SkillSetInfoSection
                SvgElement={SkillSetInfo3Svg}
                heading={_t('Showcase your competencies as you progress')}
                description={_t(
                  'Increase your skill score dynamically as you complete coursework—track progress towards your organization’s targets and get noticed'
                )}
              />
            </Grid>
          </Grid>
        </div>
      </InformationCardBody>
    </InformationCard>
  );
};

export default SkillSetInformationCard;
