import { CareerAcademy_Variant as CareerAcademyVariant } from '__generated__/graphql-types';

import { getCmsLocaleString } from 'js/lib/language';

import type { Asset } from 'bundles/unified-career-academy/types';

export type Partner = {
  partnerName?: string | null;
  partnerLogo?: { url?: string | null };
  partnerSlug?: string | null;
};

export type Certificate = {
  slug?: string | null;
  programName?: string | null;
  programId?: string | null;
  programType?: string | null;
  partnerInformationCollection?: {
    items?: ({ partnerLogo?: Asset; partnerSlug?: string | null } | null)[] | null;
  } | null;
  localizations?: (string | null)[] | null;
  collectionTrackingId?: string;
};

// remove "SPECIALIZATION" from productId, and format "child" string
export const formatS12nId = (productId: string, addChildExclamation: boolean) => {
  const productIdChunked = productId.split('~');
  const baseId = productIdChunked.slice(-1).pop();

  const penultimateIndex = productIdChunked.length - 2;
  if (['child', 'child!'].includes(productIdChunked[penultimateIndex])) {
    const childString = addChildExclamation ? 'child!~' : 'child~';
    return childString + baseId;
  }

  return baseId;
};

export type PropCareerAcademyVariant = {
  careerAcademyVariant?: CareerAcademyVariant;
  refetchCareerAcademyVariant?: () => void;
  isCareerAcademyVariantLoading?: boolean;
};

export const isCareerAcademyFreemiumEnabled = (careerAcademyVariant?: CareerAcademyVariant) => {
  if (!careerAcademyVariant) return false;

  return careerAcademyVariant === CareerAcademyVariant.GrowWithGoogle;
};

export const isCareerAcademyV2Enabled = (careerAcademyVariant?: CareerAcademyVariant) => {
  if (!careerAcademyVariant) return false;

  return [CareerAcademyVariant.Default, CareerAcademyVariant.Spanish, CareerAcademyVariant.Arabic].some(
    (variant) => variant === careerAcademyVariant
  );
};

export type ValuePropsType = {
  items: Array<{ description?: string | null; title?: string | null; visual?: { url?: string | null } | null } | null>;
};

export const getLocaleString = () => {
  let cmsLocaleString = getCmsLocaleString();

  if (cmsLocaleString === 'es-LA') {
    cmsLocaleString = 'es';
  }

  return cmsLocaleString;
};

const getFilteredCertificatesHelper = (certificates: (Certificate | null)[], targetCareerAcademyVariant: string) =>
  certificates.filter((p) => {
    return p && p.localizations && p.localizations.includes(targetCareerAcademyVariant);
  });

export const getFilteredCertificates = ({
  certificates,
  careerAcademyVariant,
}: { certificates?: Array<Certificate | null> | null } & { careerAcademyVariant?: string }) => {
  const careerAcademyVariantToUse = careerAcademyVariant ?? CareerAcademyVariant.Default;

  const filteredCertificates = certificates
    ? getFilteredCertificatesHelper(certificates, careerAcademyVariantToUse)
    : [];

  return filteredCertificates;
};

/*
Filters certificates by CA variant and filter out duplicate partners
*/
const filterCertificatesNoDuplicatePartners = (
  certificates?: Array<Certificate | null> | null,
  careerAcademyVariant?: CareerAcademyVariant | string
) => {
  const careerAcademyVariantToUse = careerAcademyVariant ?? CareerAcademyVariant.Default;

  const variantFilteredCertificates = certificates?.filter((certificate) =>
    certificate?.localizations?.includes(careerAcademyVariantToUse)
  );

  const uniqueSlugs = new Set();

  return variantFilteredCertificates?.filter((certificate) => {
    const partnerSlug = certificate?.partnerInformationCollection?.items?.[0]?.partnerSlug;
    const returnValue = !uniqueSlugs.has(partnerSlug);
    uniqueSlugs.add(partnerSlug);
    return returnValue;
  });
};

/*
There are language variant professional certificates for
Enterprise CA that should not be in Consumer CA
*/
// STEVEN TODO: Check if consumer certificates have updated localizations metadata
export const filterConsumerCertificates = (certificates?: Array<Certificate | null> | null) => {
  return certificates?.filter((certificate) => certificate?.localizations?.includes(CareerAcademyVariant.Default));
};

/*
We are running a test for Consumer Role Description Pages where 
we are showing partner logos instead of links to certificates in the individual 
Career cards in the Consumer CA.
We shouldn't show duplicate partners as part of this test
*/
export const filterConsumerCertificatesNoDuplicatePartners = (certificates?: Array<Certificate | null> | null) => {
  return filterCertificatesNoDuplicatePartners(certificates);
};

/*
On the enterprise side, we check for the correct variant,
and we also filter out duplicate partners
*/
export const filterEnterpriseCertificates = (
  certificates?: Array<Certificate | null> | null,
  careerAcademyVariant?: CareerAcademyVariant | string
) => {
  return filterCertificatesNoDuplicatePartners(certificates, careerAcademyVariant);
};

export const getDomainsCountObj = (careerCardsWithRoles?: Array<{ role?: string | null; card?: React.ReactNode }>) => {
  const domains = {} as Record<string, number>;

  careerCardsWithRoles?.forEach((card) => {
    if (card?.role) {
      domains[card.role] = (domains[card.role] || 0) + 1;
    }
  });

  return domains;
};
