/** @jsx jsx */
import { Global, css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';

import _t from 'i18n!nls/about';

type PopoverOffset = { top?: number; bottom?: number; left?: number; right?: number };

type PopoverProps = {
  isOpen: boolean;
  onClose: () => void;
  offset?: PopoverOffset;
};

type ContainerProps = {
  offset?: PopoverOffset;
};

const styles = {
  backdrop: css`
    .popoverBackdrop {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 150;
    }
  `,
  container: (offset: PopoverOffset | undefined) => css`
    position: fixed;
    z-index: 200;
    ${(offset?.top || offset?.top === 0) &&
    css`
      top: ${offset.top}px;
    `}
    ${(offset?.bottom || offset?.bottom === 0) &&
    css`
      bottom: ${offset.bottom}px;
    `}
      ${(offset?.left || offset?.left === 0) &&
    css`
      left: ${offset.left}px;
    `}
      ${(offset?.right || offset?.right === 0) &&
    css`
      right: ${offset.right}px;
    `}
  `,
};

const getHelpText = () => {
  return _t('Help');
};

const PopoverContainer: React.FC<ContainerProps> = ({ offset, children }) => {
  return (
    <div role="dialog" aria-label={getHelpText()} css={styles.container(offset)}>
      {children}
    </div>
  );
};

const Popover: React.FC<PopoverProps> = ({ isOpen, onClose, offset = { top: 0, left: 0 }, children }) => {
  useEffect(() => {
    if (isOpen) {
      const backdrop = document.createElement('div');

      backdrop.addEventListener('click', () => onClose());
      backdrop.classList.add('popoverBackdrop');
      document.body.appendChild(backdrop);

      return () => {
        document.body.removeChild(backdrop);
      };
    }
    return undefined;
  }, [isOpen, onClose]);

  return createPortal(
    <div>
      <Global styles={styles.backdrop} />
      {isOpen && <PopoverContainer offset={offset}>{children}</PopoverContainer>}
    </div>,
    document.body
  );
};

export default Popover;
