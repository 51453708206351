import { useLocation } from 'js/lib/useRouter';

import { breakpoints, useMediaQuery } from '@coursera/cds-core';

import GrowthDiscoveryEpicClient from 'bundles/epic/clients/GrowthDiscovery';
import useUserAgent from 'bundles/page/hooks/useUserAgent';

const pathnameAllowList = [
  '/learn',
  '/professional-certificates',
  '/specializations',
  '/degrees',
  '/browse',
  '/search',
  '/frontpage',
];

// check for /programs/{programSlug} without a trailing slash
const programHomePathnamePattern = new RegExp('^/programs/[^/]*$');

// Map variant to tag side to make checks in other files easier to read
const mapVariantToTagSide = { B: 'left', C: 'right', control: 'control' };

// If the pathname is in the allow list and learner is not on tablet/mobile, make the impression and get the variant, otherwise return 'control'.
export const useProductCardLeftOrRightTag = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const userAgent = useUserAgent();
  const isTabletOrSmaller = useMediaQuery(breakpoints.down('sm')) || userAgent?.isMobileBrowser;

  // check if learner is not on tablet/mobile && pathname exists && check for home page || program home || other pathnames in allow list
  if (
    !isTabletOrSmaller &&
    pathname &&
    (pathname === '/' ||
      programHomePathnamePattern.test(pathname) ||
      pathnameAllowList.some((path) => pathname.includes(path)))
  ) {
    const variant = GrowthDiscoveryEpicClient.get('productCardLeftVsRight');

    return mapVariantToTagSide[variant];
  }

  return 'control';
};
