import * as React from 'react';


import 'css!./__styles__/ProgressTracker';

import BenchmarkScorePopoverContent from './BenchmarkScorePopoverContent';
import type { ProgressBarProps } from './ProgressBar';
import ProgressBar from './ProgressBar';
import { ProgressTrackerHeader, ProgressTrackerHeaderPlaceholder } from './ProgressTrackerHeader';

type ProgressTrackerProps = ProgressBarProps & {
  skillName?: string;
  headerSize?: 'sm' | 'md';
  hideScore?: boolean;
  hideTarget?: boolean;
  personalScoreLabelText?: string;
  benchmarkLabelText?: string;
  personalScorePopoverContent?: JSX.Element;
  afterPersonalScore?: JSX.Element;
  endOfHeader?: JSX.Element;
  forScoring?: boolean;
  segments?: number[];
  wrapHeader?: boolean;
  scoreTooltip?: React.ReactNode;
  children?: JSX.Element;
};

type PropsForPlaceholder = Pick<ProgressTrackerProps, 'headerSize'>;

const ProgressTracker = (props: ProgressTrackerProps): JSX.Element => {
  const { skillName, headerSize, hideScore = false, hideTarget, target, children, ...rest } = props;

  const benchmarkTooltip = <BenchmarkScorePopoverContent skillName={skillName} target={target} score={props.score} />;

  return (
    <div className="rc-ProgressTracker">
      <ProgressTrackerHeader
        skillName={skillName}
        headerSize={headerSize}
        hideScore={hideScore}
        hideTarget={hideTarget}
        target={target}
        {...rest}
      />
      {children}
      <ProgressBar targetTooltip={benchmarkTooltip} target={!hideTarget ? target : undefined} {...rest} />
    </div>
  );
};

ProgressTracker.Placeholder = ({ headerSize }: PropsForPlaceholder): JSX.Element => (
  <div className="rc-ProgressTracker">
    <ProgressTrackerHeaderPlaceholder headerSize={headerSize} />
    <ProgressBar.Placeholder />
  </div>
);

export default ProgressTracker;
