import type { DescriptionPage_DifficultyLevel as DifficultyLevel } from '__generated__/graphql-types';

import _t from 'i18n!nls/xdp';

// Values are XDP slugs.
export const GOOGLE_GATEWAYS_FOR_OVERRIDES_SLUGS = {
  GOOGLE_IT_SUPPORT: 'google-it-support',
  GOOGLE_DATA_ANALYTICS: 'google-data-analytics',
  GOOGLE_PROJECT_MANAGEMENT: 'google-project-management',
  GOOGLE_UX_DESIGN: 'google-ux-design',
  GOOGLE_IT_AUTOMATION: 'google-it-automation',
};

export const XDP_META_DESC_LENGTH_LIMIT = 160;

export const NAV_SQUARE_LOGO_SIZE = 28;

export const HERO_BACKGROUND_COLOR = 'rgba(0, 86, 210, 0.05)';

export const getXdpMetaDescriptionSuffix = () => _t('Enroll for free.');

export const humanizeDifficultyLevel = (level: DifficultyLevel) => {
  return {
    BEGINNER: _t('Beginner'),
    INTERMEDIATE: _t('Intermediate'),
    ADVANCED: _t('Advanced'),
  }[level];
};

export const CREDITS_ANCHOR_ID = 'credits';

export const PROJECTS_LEARN_ANCHOR_ID = 'learn';

export const FIXED_PAGE_NAVIGATION_Z_INDEX = 3000;

// Ensures suggested pace (n weeks * m hrs/week) matches course's total duration.
export const CDP_SUGGESTED_NUM_WEEKS = 3;

export const MAX_NUMBER_OF_KIM_ITEMS = 4;

export const MAX_NUMBER_OF_HORIZONTAL_KIM_ITEMS = 5;

export const ELIGIBLE_DOMAINS_FOR_ARTICLES_RECS = [
  'business',
  'computer-science',
  'information-technology',
  'data-science',
  'life-sciences',
  'personal-development',
];

export const ARTICLES_RECS_TRACKING_NAME = 'articles_unified_description_page_recs';

export const NUMBER_OF_ARTICLES_RECS_TO_SHOW = 4;

// Show only top three FAQs https://coursera.atlassian.net/browse/ACQU-1457
export const MAX_FAQS_TO_SHOW = 3;

export const getDefaultDifficultyDetail = () => ({
  get BEGINNER() {
    return _t('No prior experience required');
  },
  get INTERMEDIATE() {
    return _t('Some related experience required');
  },
  get ADVANCED() {
    return _t('Designed for those already in the industry');
  },
});

export const KIM_MOBILE_SUBTITLE_DISPLAY_OPTIONS = {
  DISPLAY_IN_MODAL: 'displayInModal',
  DISPLAY_BLOCK: 'displayBlock',
  DISPLAY_INLINE: 'displayInline',
  DISPLAY_SUBTITLE_ONLY: 'displaySubtitleOnly',
  NO_SUBTITLE: 'noSubtitle',
};

export const PROF_CERTS_USING_SKILL_CERT_LABEL = ['generative-ai-for-software-development'];

// Types Officially Recognized by the Save API
export const savedProductTypes = {
  COURSE: 'PRODUCT_TYPE_COURSE',
  SPECIALIZATION: 'PRODUCT_TYPE_SPECIALIZATION',
  GUIDED_PROJECT: 'PRODUCT_TYPE_GUIDED_PROJECT',
  PROFESSIONAL_CERTIFICATE: 'PRODUCT_TYPE_PROFESSIONAL_CERTIFICATE',
  MASTERTRACK: 'PRODUCT_TYPE_MASTERTRACK',
  DEGREE: 'PRODUCT_TYPE_DEGREE',
  INVALID: 'PRODUCT_TYPE_INVALID',
} as const;
