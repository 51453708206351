import * as React from 'react';

import { pure } from 'recompose';

import { SvgIcon } from '@coursera/coursera-ui';
import type { Svg } from '@coursera/coursera-ui/svg';

const SvgCloudComponent: Svg = ({ title = 'Cloud', ...props }) => (
  <SvgIcon title={title} {...props} viewBox="0 0 24 24" fill="none">
    <g id="Cloud">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9295 9.519C18.4485 6.11 15.487 3.5 12 3.5C8.14 3.5 5 6.6405 5 10.5V11H4.75C2.3995 11 0.426 12.7225 0.0645 15H0V20.5H8H16C16 20.5 16 19.8905 16 19.5C10.1421 19.5 1 19.5 1 19.5V15.7325L1.012 15.5295C1.1275 13.5505 2.7695 12 4.75 12H6V10.5C6 7.192 8.6915 4.5 12 4.5C15.1175 4.5 17.7445 6.933 17.9805 10.039L18.0365 10.5H18.5C20.9815 10.5 23 12.519 23 15C23 17.4815 20.9815 19.5 18.5 19.5C18.5 19.5 16.9763 19.5 16 19.5C16 19.8905 16 20.5 16 20.5H18.5C21.533 20.5 24 18.033 24 15C24 12.118 21.772 9.7465 18.9295 9.519Z"
        id="SvgCloud"
      />
    </g>
  </SvgIcon>
);

const SvgCloud = pure(SvgCloudComponent);
SvgCloud.displayName = 'SvgCloud';

export default SvgCloud;
