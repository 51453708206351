import { createContext, useContext } from 'react';

import type { AddTagCallback } from 'bundles/cml/editor/types/cmlEditorProps';

export type PersonalizationTagContextType = {
  onPersonalizationTagClick?: (callback: AddTagCallback) => void;
};

export const PersonalizationTagContext = createContext<PersonalizationTagContextType>({});

export const usePersonalizationTagContext = () => useContext(PersonalizationTagContext);
