/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { useParams } from 'js/lib/useRouter';

import { ProductCard, Typography2, breakpoints, useMediaQuery } from '@coursera/cds-core';
import { useTracker, useVisibilityTracker } from '@coursera/event-pulse/react';

import type { EnterpriseBadgeWithCollection } from 'bundles/page-config-common/providers/enterprise/EnterpriseBadgeCollectionsProvider';

import _t from 'i18n!nls/consumer-home';

type Props = {
  enterpriseBadgeWithCollection: EnterpriseBadgeWithCollection;
  isSingularView: boolean;
  numOfEnterpriseBadges: number;
  itemIndex: number;
};

const styles = {
  targetCardWrapper: () => css`
    padding: 12px;
    height: 100%;

    .cds-ProductCard-list {
      border: 2px solid var(--cds-color-grey-50);
    }
  `,
  learningPathProductCardImageWrapper: () => css`
    height: 100%;
    background-color: var(--cds-color-interactive-background-primary-hover-weak);
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  learningPathProductCardImage: ({ height, width }: { height: string; width: string }) => css`
    height: ${height} !important;
    width: ${width} !important;
  `,
};

export const LearningPathProductCardImage = ({
  height,
  width,
  src,
}: {
  height: string;
  width: string;
  src: string;
}) => {
  return (
    <div css={styles.learningPathProductCardImageWrapper}>
      <img src={src} alt={_t('Learning path image')} css={styles.learningPathProductCardImage({ height, width })} />
    </div>
  );
};

const EnterpriseLearningPathProductCard = ({
  enterpriseBadgeWithCollection,
  isSingularView,
  numOfEnterpriseBadges,
  itemIndex,
}: Props) => {
  const track = useTracker();
  const { programSlug } = useParams();
  const isMobile = useMediaQuery(breakpoints.down('xs'));
  const isTabletOrSmaller = useMediaQuery(breakpoints.down('sm'));
  const isDesktopOrSmaller = useMediaQuery(breakpoints.down('md'));
  const shouldShowDesktopListView = !isSingularView && !isMobile && !isTabletOrSmaller && numOfEnterpriseBadges < 3;
  const shouldShowListView = shouldShowDesktopListView || isTabletOrSmaller || isMobile;

  const visibilityTrackingRef: React.MutableRefObject<HTMLDivElement | null> = useVisibilityTracker(
    'view_product_card',
    {
      productCard: {
        index: itemIndex,
      },
      product: {
        id: enterpriseBadgeWithCollection?.enterpriseBadge?.badgeTemplateId,
        slug: enterpriseBadgeWithCollection?.enterpriseBadge?.slug,
        type: 'learning_path',
      },
      enterprise: {
        ...(programSlug ? { programSlug } : {}),
      },
    }
  );

  const getVariantType = () => {
    if (!isSingularView && !shouldShowListView) {
      return 'grid';
    } else {
      return 'list';
    }
  };

  const getProductCardDimensions = () => {
    if (isDesktopOrSmaller) {
      return { height: '80px', width: '80px' };
    }
    return { height: '171px', width: '171px' };
  };

  return (
    <div css={styles.targetCardWrapper}>
      <ProductCard
        ref={visibilityTrackingRef}
        data-testid="learning-path"
        productType="Learning Path"
        variant={getVariantType()}
        title={{
          name: enterpriseBadgeWithCollection.enterpriseBadge.title,
          linkProps: {
            href: `/programs/${programSlug}/learning-path/${enterpriseBadgeWithCollection.enterpriseBadge.slug}?collectionId=${enterpriseBadgeWithCollection.enterpriseBadge.collectionId}`,
          },
        }}
        partners={[]}
        renderPreviewImage={() => (
          <LearningPathProductCardImage
            src={enterpriseBadgeWithCollection.enterpriseBadge.imageUrl}
            {...getProductCardDimensions()}
          />
        )}
        footer={
          <Typography2 variant="bodySecondary" component="p" color="supportText">
            {_t('Learning Path')}
          </Typography2>
        }
        onClick={() => {
          track('click_product_card', {
            productCard: {
              index: itemIndex,
            },
            product: {
              id: enterpriseBadgeWithCollection?.enterpriseBadge?.badgeTemplateId,
              slug: enterpriseBadgeWithCollection?.enterpriseBadge?.slug,
              type: 'learning_path',
            },
            enterprise: {
              ...(programSlug ? { programSlug } : {}),
            },
          });
        }}
      />
    </div>
  );
};

export default EnterpriseLearningPathProductCard;
