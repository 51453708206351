import { graphql, withApollo } from 'react-apollo';

import type ApolloClient from 'apollo-client';
import { compose, withProps } from 'recompose';

import { LOCAL_ENTERPRISE_PRODUCT_METADATA_TYPENAME } from 'bundles/enterprise-learner-search/components/search/withEnterpriseProductMetadata';
import type {
  ProgramProductMetadataMultiGetQuery_ProgramProductMetadataV1Resource_multiGet_elements_metadataType_ProgramProductMetadataV1_courseMember as CourseMemberMetadata,
  ProgramProductMetadataMultiGetQuery as ProgramProductMetadataMultiGetQueryData,
  ProgramProductMetadataMultiGetQueryVariables,
} from 'bundles/program-common/components/__generated__/ProgramProductMetadataMultiGetQuery';
import { PRODUCT_TYPES } from 'bundles/program-common/constants/ProgramActionConstants';
import {
  ProgramCourseMetadataFragment,
  ProgramProductMetadataMultiGetQuery,
  ProgramProductMetadataQueryV1,
  ProgramSpecializationMetadataFragment,
} from 'bundles/program-common/constants/ProgramCommonGraphqlQueries';
import type {
  ProgramProductMetadataGetQuery,
  ProgramProductMetadataGetQueryVariables,
} from 'bundles/program-common/constants/__generated__/ProgramProductMetadataGetQuery';
import type { SpecializationAndUserCredits_SpecializationFragment as Specialization } from 'bundles/program-common/queries/__generated__/SpecializationAndUserCreditsQuery';
import type { EnterpriseProductMetadataFlags } from 'bundles/program-common/types/programCommon';
import filterExistsOrDefault from 'bundles/program-common/utils/filterExistsOrDefault';

type PropsFromCaller = {
  productType: string;
  s12n?: Specialization;
  programId: string;
  isEnterpriseAdminView?: boolean;
  productId?: string;
  allowOrgForSpecializationConfiguration?: boolean;
};

type PropsFromWithApollo = {
  client: ApolloClient<any>;
};

type PropsForProgramProductMetadataGraphql = Pick<
  PropsFromCaller,
  'productId' | 'programId' | 'productType' | 'isEnterpriseAdminView'
>;

type PropsFromProgramProductMetadataGraphql = {
  enterpriseProductConfiguration?: EnterpriseProductMetadataFlags;
};

type PropsFromProgramProductMultiGetMetadataGraphql = {
  coursesMetadata?: EnterpriseProductMetadataFlags[];
};

type PropsFromWithProps = {
  missingCourseIds: string[];
  coursesMetadata: (EnterpriseProductMetadataFlags | null)[];
} & PropsFromProgramProductMetadataGraphql;

export type Props = PropsFromProgramProductMetadataGraphql & PropsFromProgramProductMultiGetMetadataGraphql;

const getCourseCacheData = (programId: string, id: string, client: ApolloClient<any>) => {
  return client.readFragment({
    id: `${LOCAL_ENTERPRISE_PRODUCT_METADATA_TYPENAME}:${programId}~VerifiedCertificate~${id}`,
    fragment: ProgramCourseMetadataFragment,
  });
};

const getS12nCacheData = (programId: string, id: string, client: ApolloClient<any>) => {
  return client.readFragment({
    id: `${LOCAL_ENTERPRISE_PRODUCT_METADATA_TYPENAME}:${programId}~Specialization~${id}`,
    fragment: ProgramSpecializationMetadataFragment,
  });
};

const withLocalCacheEnterpriseProductMetadata = compose<Props, PropsFromCaller>(
  withApollo,
  withProps<PropsFromWithProps, PropsFromCaller & PropsFromWithApollo>(
    ({
      productType,
      s12n,
      programId,
      isEnterpriseAdminView,
      productId,
      client,
      allowOrgForSpecializationConfiguration,
    }) => {
      const coursesMetadata: EnterpriseProductMetadataFlags[] = [];
      const missingCourseIds: string[] = [];
      let enterpriseProductConfiguration;

      if (!isEnterpriseAdminView && productId && productType !== PRODUCT_TYPES.SPECIALIZATION) {
        enterpriseProductConfiguration = getCourseCacheData(programId, productId, client);
      } else if (s12n) {
        if (allowOrgForSpecializationConfiguration && productId && !isEnterpriseAdminView) {
          enterpriseProductConfiguration = getS12nCacheData(programId, productId, client);
        }

        s12n.courses.forEach((course) => {
          const formattedId = `${programId}~VerifiedCertificate~${course.id}`;
          const courseMetadataFlags = getCourseCacheData(programId, course.id, client);

          if (courseMetadataFlags) {
            coursesMetadata.push(courseMetadataFlags);
          } else {
            missingCourseIds.push(formattedId);
          }
        });
      }
      return { coursesMetadata, enterpriseProductConfiguration, missingCourseIds };
    }
  ),
  graphql<
    PropsForProgramProductMetadataGraphql & PropsFromWithProps,
    ProgramProductMetadataGetQuery,
    ProgramProductMetadataGetQueryVariables,
    PropsFromProgramProductMetadataGraphql
  >(ProgramProductMetadataQueryV1, {
    skip: ({ productId, isEnterpriseAdminView, enterpriseProductConfiguration }) =>
      !!enterpriseProductConfiguration || !!isEnterpriseAdminView || !productId,
    options: ({ programId, productId, productType }) => ({
      variables: {
        id: `${programId}~${
          productType === PRODUCT_TYPES.COURSE ? 'VerifiedCertificate' : 'Specialization'
        }~${productId}`,
      },
    }),
    props: ({ data }) => {
      const metadata = data?.ProgramProductMetadataV1Resource?.get?.metadataType;
      return {
        enterpriseProductConfiguration:
          metadata?.__typename === 'ProgramProductMetadataV1_courseMember' ? metadata.course : metadata?.specialization,
      };
    },
  }),
  graphql<
    PropsFromCaller & PropsFromWithProps,
    ProgramProductMetadataMultiGetQueryData,
    ProgramProductMetadataMultiGetQueryVariables,
    PropsFromProgramProductMultiGetMetadataGraphql
  >(ProgramProductMetadataMultiGetQuery, {
    skip: ({ productType, s12n, missingCourseIds }) =>
      !missingCourseIds.length || productType === PRODUCT_TYPES.COURSE || !s12n,
    options: ({ missingCourseIds }) => ({
      variables: {
        ids: missingCourseIds,
      },
    }),
    props: ({ data, ownProps }) => {
      const { coursesMetadata } = ownProps;
      const metadata = data?.ProgramProductMetadataV1Resource?.multiGet?.elements;
      const newCoursesMetadata =
        metadata?.map((element) => (element?.metadataType as CourseMemberMetadata)?.course) ?? [];
      return { coursesMetadata: [...filterExistsOrDefault(coursesMetadata), ...newCoursesMetadata] };
    },
  })
);

export default withLocalCacheEnterpriseProductMetadata;
