import * as React from 'react';

import _ from 'lodash';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { Typography2 } from '@coursera/cds-core';

import CourseActionBox from 'bundles/course-cards/components/course-card/enterprise/CourseActionBox';
import type { PropsForWithModal as PropsForCourseAndPartnerNameWithModal } from 'bundles/course-cards/components/course-card/enterprise/CourseAndPartnerName';
import { CourseAndPartnerNameWithModal } from 'bundles/course-cards/components/course-card/enterprise/CourseAndPartnerName';
import CourseCardAutoenrollmentDisclaimer from 'bundles/course-cards/components/course-card/enterprise/CourseCardAutoenrollmentDisclaimer';
import CourseProgressBox from 'bundles/course-cards/components/course-card/enterprise/CourseProgressBox';
import CourseProgressInfo from 'bundles/course-cards/components/course-card/enterprise/CourseProgressInfo';
import CourseWithProgressLinkButton from 'bundles/course-cards/components/course-card/enterprise/CourseWithProgressLinkButton';
import SessionEndInfo from 'bundles/course-cards/components/course-card/enterprise/SessionEndInfo';
import type CoursesV1 from 'bundles/naptimejs/resources/courses.v1';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import type OnDemandLearnerMaterialWeeksV1 from 'bundles/naptimejs/resources/onDemandLearnerMaterialWeeks.v1';
import type OnDemandLearnerMaterialsV1 from 'bundles/naptimejs/resources/onDemandLearnerMaterials.v1';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import type OnDemandLearnerSessionsV1 from 'bundles/naptimejs/resources/onDemandLearnerSessions.v1';
import { getProgressByLearnerMaterial } from 'bundles/program-home/utils/CourseUtils';

import _t from 'i18n!nls/course-cards';

type Props = {
  course: CoursesV1;
  cardDropdownWidget: JSX.Element;
  learnerMaterials?: OnDemandLearnerMaterialsV1;
  learnerMaterialWeeks?: OnDemandLearnerMaterialWeeksV1;
  enrolledSession?: OnDemandLearnerSessionsV1;
  isAutoenrolled?: boolean;
  isPartOfS12n?: boolean;
} & Omit<PropsForCourseAndPartnerNameWithModal, 'courseId' | 'courseName' | 'partnerIds'>;

// Don't show critical progress, current week
const CourseEnrolledRunningPrivateSessionCard: React.FC<Props> = ({
  course: { id, name, homeLink, partnerIds },
  cardDropdownWidget,
  learnerMaterialWeeks,
  learnerMaterials,
  enrolledSession,
  isAutoenrolled,
  isPartOfS12n,
  ...rest
}) => {
  const numberOfAssignments = (learnerMaterialWeeks && learnerMaterialWeeks.totalPassablesCount) || 0;
  const duration = learnerMaterials && _.size(learnerMaterials.weekNumbers);

  const summaryCoreProgressPercentage = getProgressByLearnerMaterial(learnerMaterials);

  // If now it's week 6 and the session is 5 weeks in total, the learnerMaterialWeeks will be undefined
  // If the user hasn't made any progress, learnerMaterialWeeks will also be undefined

  return (
    <div className="rc-CourseEnrolledRunningPrivateSessionCard row flex-1">
      <div className="col-xs-12 col-sm-8 col-md-9 pos-static-override-hack">
        <CourseAndPartnerNameWithModal courseId={id} courseName={name} partnerIds={partnerIds} {...rest} />
        {cardDropdownWidget}
        {isPartOfS12n && (
          <Typography2 component="p" className="m-b-1" variant="bodyPrimary">
            {_t('Started')}
          </Typography2>
        )}
        <CourseProgressBox>
          <CourseProgressInfo
            summaryCoreProgressPercentage={summaryCoreProgressPercentage}
            numberOfAssignments={numberOfAssignments}
            courseName={name}
          />
          {enrolledSession && <SessionEndInfo endsAt={enrolledSession.endsAt} isEnded={enrolledSession.isEnded} />}
        </CourseProgressBox>
        {isAutoenrolled && <CourseCardAutoenrollmentDisclaimer />}
      </div>
      <CourseActionBox>
        <div className="vertical-box align-items-absolute-center">
          {duration && (
            <Typography2 component="span" variant="subtitleMedium">
              <FormattedMessage message={_t('{duration, plural, =1 {# Week} other {# Weeks}}')} duration={duration} />
            </Typography2>
          )}
          <CourseWithProgressLinkButton
            link={homeLink}
            progress={summaryCoreProgressPercentage}
            trackingNamePrefix="enrolled_running_private"
            courseName={name}
          />
        </div>
      </CourseActionBox>
    </div>
  );
};

export default CourseEnrolledRunningPrivateSessionCard;
