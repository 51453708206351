import * as React from 'react';

const UpskillIcon = () => (
  <svg
    role="presentation"
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="upskill-icon"
  >
    <circle cx="12" cy="12" r="12" fill="#F5F7F8" />
    <g clipPath="url(#UpskillIconA)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.858 9.607v-3.591h.355v4.198H4.197v-.355h3.591L4.89 6.141l.251-.251 3.717 3.717z"
        fill="#1F1F1F"
      />
    </g>
    <g clipPath="url(#UpskillIconB)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.393 9.858h3.591v.355h-4.198V5.818h.355v3.591l3.717-3.717.251.251-3.717 3.717z"
        fill="#1F1F1F"
      />
    </g>
    <g clipPath="url(#UpskillIconC)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.607 14.721H5.017v-.355h4.198v4.198h-.355v-3.592l-3.717 3.717-.251-.251 3.717-3.717z"
        fill="#1F1F1F"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.142 14.974v3.591h-.355v-4.198h4.197v.355h-3.591l3.717 3.717-.251.251-3.717-3.717z"
      fill="#1F1F1F"
    />
    <circle cx="11.95" cy="12.239" r="1.882" fill="#fff" stroke="#1F1F1F" />
    <defs>
      <clipPath id="UpskillIconA">
        <path fill="#fff" transform="rotate(45 -0.109 9.981)" d="M0 0h7.107v7.107H0z" />
      </clipPath>
      <clipPath id="UpskillIconB">
        <path fill="#fff" transform="rotate(135 9.338 8.569)" d="M0 0h7.107v7.107H0z" />
      </clipPath>
      <clipPath id="UpskillIconC">
        <path fill="#fff" transform="rotate(-45 20.982 5.862)" d="M0 0h7.107v7.107H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default UpskillIcon;
