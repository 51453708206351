import * as React from 'react';

import SubscriptionVPropBulletPoints from 'bundles/enroll/components/subscriptions/SubscriptionVPropBulletPoints';
import SubscriptionVPropCDP from 'bundles/enroll/components/subscriptions/SubscriptionVPropCDP';
import SubscriptionVPropSDP from 'bundles/enroll/components/subscriptions/SubscriptionVPropSDP';

import 'css!./__styles__/SubscriptionVProp';

type PropsFromCaller = {
  onSdp: boolean;
};

const SubscriptionVProp: React.FC<PropsFromCaller> = ({ onSdp }) => {
  return (
    <div className="rc-SubscriptionVProp">
      <div className="punch-line">{onSdp ? <SubscriptionVPropSDP /> : <SubscriptionVPropCDP />}</div>
      <SubscriptionVPropBulletPoints />
    </div>
  );
};

export default SubscriptionVProp;
