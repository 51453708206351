export const ToolbarTools = {
  UNORDERED_LIST: 'UNORDERED_LIST',
  ORDERED_LIST: 'ORDERED_LIST',
  TABLE: 'TABLE',
  IMAGE: 'IMAGE',
  ASSET: 'ASSET',
  CODE: 'CODE',
  PERSONALIZATION_TAG: 'PERSONALIZATION_TAG',
  UNDO: 'UNDO',
  REDO: 'REDO',
  TYPOGRAPHY: 'TYPOGRAPHY',
  BOLD: 'BOLD',
  ITALIC: 'ITALIC',
  UNDERLINE: 'UNDERLINE',
  MONOSPACE: 'MONOSPACE',
  LINK: 'LINK',
  UNLINK: 'UNLINK',
  MATH: 'MATH',
  FILLABLE_BLANK: 'FILLABLE_BLANK',
  INCREASE_INDENT: 'INCREASE_INDENT',
  DECREASE_INDENT: 'DECREASE_INDENT',
  SUPERSCRIPT: 'SUPERSCRIPT',
  SUBSCRIPT: 'SUBSCRIPT',
  WIDGET: 'WIDGET',
  AI: 'AI',
} as const;

export const MoreTool = 'MORE';

export const HoverTools = {
  TYPOGRAPHY: 'TYPOGRAPHY',
  BOLD: 'BOLD',
  ITALIC: 'ITALIC',
  UNDERLINE: 'UNDERLINE',
  MONOSPACE: 'MONOSPACE',
  LINK: 'LINK',
  UNLINK: 'UNLINK',
  MATH: 'MATH',
  INCREASE_INDENT: 'INCREASE_INDENT',
  DECREASE_INDENT: 'DECREASE_INDENT',
  SUPERSCRIPT: 'SUPERSCRIPT',
  SUBSCRIPT: 'SUBSCRIPT',
  AI: 'AI',
} as const;

export const HeadingTools = {
  HEADING_1: 'HEADING_1',
  HEADING_2: 'HEADING_2',
  HEADING_3: 'HEADING_3',
  HEADING_4: 'HEADING_4',
} as const;

export const BodyTools = {
  BODY1: 'BODY1',
  BODY2: 'BODY2',
} as const;

export const BasicMarkdownTools = {
  ...HeadingTools,
  BODY1: 'BODY1',
  AI: 'AI',
  BOLD: 'BOLD',
  ITALIC: 'ITALIC',
  UNDERLINE: 'UNDERLINE',
  MONOSPACE: 'MONOSPACE',
  UNORDERED_LIST: 'UNORDERED_LIST',
  LINK: 'LINK',
  UNLINK: 'UNLINK',
  ORDERED_LIST: 'ORDERED_LIST',
  TABLE: 'TABLE',
  SUPERSCRIPT: 'SUPERSCRIPT',
  SUBSCRIPT: 'SUBSCRIPT',
  CODE: 'CODE',
};

export const TypographyTools = {
  ...HeadingTools,
  ...BodyTools,
} as const;

export const Tools = {
  ...TypographyTools,
  ...ToolbarTools,
} as const;

export type ToolsKeys = keyof typeof Tools;
export type ToolbarToolsKeys = keyof typeof ToolbarTools;
export type TypographyToolsKeys = keyof typeof TypographyTools;
export type HoverToolsKeys = keyof typeof HoverTools;
export type HeadingToolsKeys = keyof typeof HeadingTools;

export type BasicMarkdownToolsKeys = keyof typeof BasicMarkdownTools;

// maps the dtdId to the allowed custom tools for EditorToolbar.jsx
const toolMap: Record<string, ToolsKeys[]> = {
  // https://github.com/webedx-spark/infra-services/blob/main/libs/models/src/main/resources/dtd/branchDescription/1.dtd
  'branchDescription/1': [
    Tools.HEADING_1,
    Tools.HEADING_2,
    Tools.HEADING_3,
    Tools.HEADING_4,
    Tools.BODY1,
    Tools.BODY2,
    Tools.AI,
    Tools.BOLD,
    Tools.ITALIC,
    Tools.UNDERLINE,
    Tools.LINK,
    Tools.UNLINK,
    Tools.UNORDERED_LIST,
    Tools.ORDERED_LIST,
    Tools.SUBSCRIPT,
    Tools.SUPERSCRIPT,
  ],
  'course/1': [Tools.AI, Tools.BOLD, Tools.ITALIC, Tools.UNDERLINE, Tools.MATH, Tools.SUBSCRIPT, Tools.SUPERSCRIPT],
  'email/1': [
    Tools.AI,
    Tools.BOLD,
    Tools.ITALIC,
    Tools.UNDERLINE,
    Tools.LINK,
    Tools.UNLINK,
    Tools.UNORDERED_LIST,
    Tools.ORDERED_LIST,
    Tools.SUBSCRIPT,
    Tools.SUPERSCRIPT,
  ],
  // https://github.com/webedx-spark/infra-services/blob/main/libs/models/src/main/resources/dtd/QnA/1.dtd
  'QnA/1': [
    Tools.HEADING_1,
    Tools.HEADING_2,
    Tools.HEADING_3,
    Tools.HEADING_4,
    Tools.BODY1,
    Tools.BODY2,
    Tools.AI,
    Tools.BOLD,
    Tools.ITALIC,
    Tools.UNDERLINE,
    Tools.MATH,
    Tools.LINK,
    Tools.UNLINK,
    Tools.SUBSCRIPT,
    Tools.SUPERSCRIPT,
  ],
  // https://github.com/webedx-spark/infra-services/blob/main/libs/models/src/main/resources/dtd/s12n/1.dtd
  's12n/1': [
    Tools.HEADING_1,
    Tools.HEADING_2,
    Tools.HEADING_3,
    Tools.HEADING_4,
    Tools.BODY1,
    Tools.BODY2,
    Tools.BOLD,
    Tools.ITALIC,
    Tools.UNDERLINE,
    Tools.MATH,
    Tools.LINK,
    Tools.UNLINK,
    Tools.UNORDERED_LIST,
    Tools.ORDERED_LIST,
    Tools.SUBSCRIPT,
    Tools.SUPERSCRIPT,
  ],
};

export const getCustomTools = (dtdId: string): ToolsKeys[] => toolMap[dtdId];

export default {
  getCustomTools,
};
