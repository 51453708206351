import { createContext, useContext } from 'react';

export type StyleContextType = {
  pageless: boolean;
  editorNode: HTMLElement | null;
};

export const StyleContext = createContext<StyleContextType>({
  pageless: false,
  editorNode: null,
});

export const useStyleContext = () => useContext(StyleContext);
