/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Button, ProductCard, Typography2 } from '@coursera/cds-core';
import { AddIcon, CheckIcon } from '@coursera/cds-icons';

import TrackedDiv from 'bundles/page/components/TrackedDiv';

import _t from 'i18n!nls/program-personalized-tab';

export type Props = {
  skillName: string;
  isSelected: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  onCardClick: () => void;
  onButtonClick: () => void;
};

const styles = {
  skillSuggestionCard: css`
    height: 100%;
    cursor: pointer;
  `,
  footer: css`
    align-items: start;
  `,
};
const SkillSuggestionCard = ({ skillName, isSelected, isDisabled, isLoading, onCardClick, onButtonClick }: Props) => {
  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (!isDisabled) {
      onButtonClick();
    }
  };

  const handleCardClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (!isDisabled) {
      onCardClick();
    }
  };

  return (
    <TrackedDiv trackingName="skill_suggestion_card" css={styles.skillSuggestionCard}>
      <ProductCard
        data-testid="skill-suggestion-card"
        variant="grid"
        productType="Skill"
        onClick={handleCardClick}
        title={{
          name: skillName,
        }}
        footer={
          <div css={styles.footer}>
            <Button
              data-testid="skill-suggestion-card-add-button"
              size="small"
              variant="ghost"
              icon={isSelected ? <CheckIcon size="small" /> : <AddIcon size="small" />}
              iconPosition="before"
              onClick={handleButtonClick}
              disabled={isDisabled}
              loading={isLoading}
            >
              <Typography2 component="p" color={isSelected ? 'highlightBlue' : 'supportText'} variant="bodySecondary">
                {isSelected ? _t('Added to my recommendations') : _t('Add to recommendations')}
              </Typography2>
            </Button>
          </div>
        }
      />
    </TrackedDiv>
  );
};

export default SkillSuggestionCard;
