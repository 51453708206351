type DomainSkillMapping = {
  [key: string]: string;
};

type DomainRename = {
  [id: string]: string;
};

export const domainSkillMapping: DomainSkillMapping = {
  'leadership-and-management': 'management',
  finance: 'finance',
  marketing: 'marketing',
  entrepreneurship: 'entrepreneurship',
  'business-essentials': 'business-analysis',
  'business-strategy': 'strategy',
  'machine-learning': 'machine-learning',
  'data-analysis': 'data-analysis',
  'probability-and-statistics': 'probability-and-statistics',
  'cloud-computing': 'cloud-computing',
  'data-management': 'data-management',
  'software-development': 'software-engineering',
  algorithms: 'algorithms',
  'mobile-and-web-development': 'mobile-application-development',
  'computer-security-and-networks': 'security-engineering',
  'design-and-product': 'design-and-product',
  security: 'security-engineering',
  networking: 'network-architecture',
};

export const domainRenameForSkills: DomainRename = {
  'business-essentials': 'Business Analysis',
};
