import * as React from 'react';

import AuthorEvaluatorSectionCaption from 'bundles/author-code-evaluator/components/AuthorEvaluatorSectionCaption';
import AuthorEvaluatorSectionHeader from 'bundles/author-code-evaluator/components/AuthorEvaluatorSectionHeader';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import PreambleEditor from 'bundles/author-code-evaluator/components/PreambleEditor';
import TestCaseHarness from 'bundles/author-code-evaluator/models/TestCaseHarness';
import type { LanguageType } from 'bundles/cml';
import CodeBlockV2 from 'bundles/code-evaluator/components/CodeBlockV2';

import _t from 'i18n!nls/author-code-evaluator';

class TestCaseHarnessEditor extends React.Component<{
  onChange: (rawTestCaseHarness: any) => void;
  harness: TestCaseHarness;
  language: LanguageType;
}> {
  handleChange = (preamble: string, testCase: string) => {
    const harness = new TestCaseHarness({ preamble, testCase });
    this.props.onChange(harness.toJSON());
  };

  handlePreambleChange = (preamble: string) => {
    const {
      harness: { testCase },
    } = this.props;
    this.handleChange(preamble, testCase);
  };

  handleTestCaseChange = (testCase: string) => {
    const {
      harness: { preamble },
    } = this.props;
    this.handleChange(preamble, testCase);
  };

  renderCodeEditor = () => {
    const {
      harness: { testCase },
      language,
    } = this.props;
    return (
      <CodeBlockV2
        readOnly={false}
        codeLanguage={language}
        expression={testCase}
        onChange={this.handleTestCaseChange}
      />
    );
  };

  render() {
    const {
      harness: { preamble, testCase },
      language,
    } = this.props;

    return (
      <div className="rc-TestCaseHarnessEditor">
        <PreambleEditor preamble={preamble} language={language} onChange={this.handlePreambleChange} />

        <AuthorEvaluatorSectionHeader style={{ paddingTop: 20, paddingBottom: 5 }}>
          {_t('Grading Feedback Code')}
        </AuthorEvaluatorSectionHeader>

        <AuthorEvaluatorSectionCaption style={{ paddingBottom: 10 }}>
          {_t('This code will be used to evaluate and provide feedback to the learner on their submission.')}
        </AuthorEvaluatorSectionCaption>

        {testCase != null && this.renderCodeEditor()}
      </div>
    );
  }
}

export default TestCaseHarnessEditor;
