import * as React from 'react';
import { Component } from 'react';

import type { ApolloQueryResult } from 'apollo-client';

import { isUserRightToLeft } from 'js/lib/language';

import { DropDown, StyleSheet, color, css } from '@coursera/coursera-ui';
import { SvgMoreHorizontalFilled } from '@coursera/coursera-ui/svg';

import type {
  EnterpriseBadgeCollectionsQuery as EnterpriseBadgeCollectionsQueryData,
  EnterpriseBadgeCollectionsQueryVariables,
} from 'bundles/enterprise-learner-onboarding/queries/__generated__/EnterpriseBadgeCollectionsQuery';
import SessionSwitchModal from 'bundles/ondemand/components/sessions/SessionSwitchModal';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import UnenrollConfirmationModal from 'bundles/program-home/components/enrollmentWidget/UnenrollConfirmationModal';

import _t from 'i18n!nls/course-cards';

const styles = StyleSheet.create({
  CardDropdownWidget: {
    position: 'absolute',
  },
  CardDropdownWidgetLTR: {
    right: 0,
  },
  CardDropdownWidgetRTL: {
    left: 0,
  },
  zeroSpacing: {
    top: 0,
  },
});

const positionLTR = {
  vertical: 'bottom',
  horizontal: 'right',
};

const positionRTL = {
  vertical: 'bottom',
  horizontal: 'left',
};

const itemWidthStyle = {
  width: 'max-content',
};

export type Props = {
  itemType: 'COURSE' | 'S12N' | 'BADGE';
  itemId: string;
  programId: string;
  enableSwitchSessions?: boolean;
  canUnenroll?: boolean;
  isPartOfS12n?: boolean;
  trackingName: string;
  name: string;
  thirdPartyOrganizationId?: string;
  userId: number;
  refetchEnterpriseBadgeCollections?: (
    variables?: EnterpriseBadgeCollectionsQueryVariables
  ) => Promise<ApolloQueryResult<EnterpriseBadgeCollectionsQueryData>>;
};

export type State = {
  showUnenrollModal: boolean;
  showSessionSwitchModal: boolean;
};

/**
 * A simple dropdown dot button for S12nCard and CourseCard
 */
// TODO(Audrey): show session switch, only show unenroll when available (waiting for new api)
class CardDropdownWidget extends Component<Props, State> {
  state: State = {
    showUnenrollModal: false,
    showSessionSwitchModal: false,
  };

  handleCloseUnenrollModal = () => {
    this.setState({ showUnenrollModal: false });
  };

  handleOpenUnenrollModal = () => {
    this.setState({ showUnenrollModal: true });
  };

  handleOpenSessionSwitchModal = () => {
    this.setState({ showSessionSwitchModal: true });
  };

  handleCloseSessionSwitchModal = () => {
    this.setState({ showSessionSwitchModal: false });
  };

  render() {
    const {
      itemType,
      itemId,
      enableSwitchSessions,
      canUnenroll = true,
      programId,
      trackingName,
      name,
      thirdPartyOrganizationId,
      userId,
      refetchEnterpriseBadgeCollections,
    } = this.props;

    const rtl = isUserRightToLeft();
    const isCourse = itemType === 'COURSE';
    const productName = name;
    const label = productName && _t('Enrollment actions for #{productName}', { productName });
    const listItems: React.ReactElement[] = [];
    let dropdownUnenrollLabel = '';
    switch (itemType) {
      case 'COURSE':
        dropdownUnenrollLabel = _t('Unenroll from Course');
        break;
      case 'S12N':
        dropdownUnenrollLabel = _t('Unenroll from Specialization');
        break;
      case 'BADGE':
        dropdownUnenrollLabel = _t('Unenroll from Badge');
        break;
      default:
    }

    if (canUnenroll) {
      listItems.push(
        <DropDown.Item
          key="unenroll"
          onClick={this.handleOpenUnenrollModal}
          label={dropdownUnenrollLabel}
          htmlAttributes={{ 'data-e2e': `CardDropdownUnenrollButton~${itemId}` }}
          style={itemWidthStyle}
        />
      );
    }
    if (enableSwitchSessions) {
      listItems.push(
        <DropDown.Item
          key="switchSessions"
          onClick={this.handleOpenSessionSwitchModal}
          label={_t('Switch sessions')}
          htmlAttributes={{ 'data-e2e': `CardDropdownSessionSwitchButton~${itemId}` }}
          style={itemWidthStyle}
        />
      );
    }
    return (
      <TrackedDiv
        trackingName={trackingName}
        trackClicks={true}
        {...css(
          'rc-CardDropdownWidget',
          styles.CardDropdownWidget,
          rtl ? styles.CardDropdownWidgetRTL : styles.CardDropdownWidgetLTR,
          styles.zeroSpacing
        )}
        data-e2e={`CardDropdownWidget~${itemId}`}
      >
        {listItems.length > 0 && (
          <DropDown.ButtonMenuV2
            renderButton={({ getDropDownButtonProps }) => (
              <button {...getDropDownButtonProps()} type="button" className="nostyle">
                <SvgMoreHorizontalFilled size={16} color={color.black} title={label} />
              </button>
            )}
            maxWidth={500}
            dropDownPosition={rtl ? positionRTL : positionLTR}
          >
            {listItems}
          </DropDown.ButtonMenuV2>
        )}
        {this.state.showSessionSwitchModal && (
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ onClose: () => void; courseId: string; }' ... Remove this comment to see the full error message
          <SessionSwitchModal onClose={() => this.setState({ showSessionSwitchModal: false })} courseId={itemId} />
        )}
        <UnenrollConfirmationModal
          itemId={itemId}
          programId={programId}
          thirdPartyOrganizationId={thirdPartyOrganizationId}
          isCourse={isCourse}
          itemType={itemType}
          open={this.state.showUnenrollModal}
          name={name}
          userId={userId}
          handleClose={this.handleCloseUnenrollModal}
          refetchEnterpriseBadgeCollections={refetchEnterpriseBadgeCollections}
        />
      </TrackedDiv>
    );
  }
}

export default CardDropdownWidget;
