import _t from 'i18n!nls/logged-in-home';

export enum COURSE_TYPES {
  RHYME_PROJECT = 'RHYME_PROJECT',
  STANDARD_COURSE = 'STANDARD_COURSE',
  PROJECT = 'PROJECT',
}

export const SECONDARY_CTA_BUTTON_TYPES = {
  RATE_COURSE: 'RATE_COURSE',
  RATE_COURSES: 'RATE_COURSES',
  RESET_DEADLINES: 'RESET_DEADLINES',
  SWITCH_SESSIONS: 'SWITCH_SESSIONS',
};

export const COURSE_ACTIVITY_STATE_TYPES = {
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETE: 'COMPLETED',
  OVERDUE: 'OVERDUE',
};

export const UPSELL_STATE_TYPES = {
  NO_UPSELL: 'NO_UPSELL',
  UPSELL_FROM_AUDIT: 'UPSELL_FROM_AUDIT',
  UPSELL_FOR_CERTIFICATE: 'UPSELL_FOR_CERTIFICATE',
};

export const MOCKED_COURSES_DATA = [
  {
    __typename: 'LoggedInCourseCard',
    courseId: 'asdfasdf', // Might need to be updated to something more realistic later down the line
    courseName: "Marketing in a Mock'd World",
    courseSlug: 'marketing-in-a-mocke-d-world',
    associatedS12nInfo: {
      __typename: 'CourseAssociatedS12nInfo',
      s12nId: 'asdfasdf', // Might need to be updated to something more realistic later down the line
      s12nName: 'Digital Mocking',
      s12nSlug: 'digital-mocking',
      coursesEnrolledCount: 2,
      coursesCompletedCount: 0,
      productVariant: 'NormalS12n',
    },
    userCourseState: {
      __typename: 'LoggedInHomeUserCourseDerivedState',
      activityState: 'COMPLETE',
      upsellState: UPSELL_STATE_TYPES.NO_UPSELL,
      canUnenrollFromCourse: true,
      primaryButtonCtaType: 'VIEW_CERTIFICATE',
      secondaryCtaType: 'RATE_COURSE',
    },
    partners: [
      {
        __typename: 'PartnerInfoForLoggedInCourseCard',
        name: 'University of Mock Data',
        slug: 'a-company',
        logoUrl: 'https://s3.amazonaws.com/coursera_assets/growth_frontpage/partners_logo/jhu_logo.png',
      },
    ],
  },
  {
    __typename: 'LoggedInCourseCard',
    courseId: 'tTIZY0EqEeaYxQofsrK9hw',
    courseSlug: 'statistical-inferences',
    courseName: 'Improving your statistical inferences',
    associatedS12nInfo: {
      __typename: 'CourseAssociatedS12nInfo',
      s12nId: '',
      s12nName: '',
      s12nSlug: '',
      productVariant: 'NormalS12n',
      coursesEnrolledCount: 0,
      coursesCompletedCount: 0,
    },
    partners: [
      {
        __typename: 'PartnerInfoForLoggedInCourseCard',
        name: 'Eindhoven University of Technology',
        slug: 'tue',
        logoUrl: '',
      },
    ],
    userCourseState: {
      __typename: 'LoggedInHomeUserCourseDerivedState',
      activityState: 'IN_PROGRESS',
      upsellState: UPSELL_STATE_TYPES.UPSELL_FROM_AUDIT,
      canUnenrollFromCourse: false,
      primaryButtonCtaType: 'GO_TO_COURSE',
      secondaryCtaType: '',
    },
  },
  {
    __typename: 'LoggedInCourseCard',
    courseId: 'OmP4nwi9EeajCxI_63r-IQ',
    courseSlug: 'construction-project-management',
    courseName: 'Construction Project Management',
    partners: [
      {
        __typename: 'PartnerInfoForLoggedInCourseCard',
        name: 'Columbia University',
        slug: 'columbia',
        logoUrl:
          'https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://coursera-university-assets.s3.amazonaws.com/9b/42f2eca2f10769400d1f8519892413/ColumbiaLOGO.png',
      },
    ],
    // not available from API yet
    associatedS12nInfo: {
      __typename: 'CourseAssociatedS12nInfo',
      s12nId: '',
      s12nName: '',
      s12nSlug: '',
      productVariant: 'NormalS12n',
      coursesEnrolledCount: 0,
      coursesCompletedCount: 0,
    },
    userCourseState: {
      __typename: 'LoggedInHomeUserCourseDerivedState',
      activityState: 'IN_PROGRESS',
      upsellState: UPSELL_STATE_TYPES.UPSELL_FROM_AUDIT,
      canUnenrollFromCourse: false,
      primaryButtonCtaType: '',
      secondaryCtaType: '',
    },
  },
];

export const PRIMARY_CTA_BUTTON_LOCATION = {
  COURSE_MAIN_CARD: 'COURSE_MAIN_CARD',
  S12N_MODAL_MINI_COURSE_CARD: 'S12N_MODAL_MINI_COURSE_CARD',
};

export const SHARE_CONTENT_UTM = 'lih';

export const SHARE_MEDIUM_UTM = 'page_share';

export const COMPLETED_SHARE_MEDIUM_UTM = 'certificate';

export const SHARE_CAMPAIGN_UTM = 'card_button';

export const PRIMARY_CTA_BUTTON_TYPES = {
  GO_TO_COURSE: 'GO_TO_COURSE',
  VIEW_CERTIFICATE: 'VIEW_CERTIFICATE',
  PURCHASE_CERTIFICATE: 'PURCHASE_CERTIFICATE',
  START_COURSE: 'START_COURSE',
  VERIFY_ID: 'VERIFY_ID',
  NO_BUTTON: 'NO_BUTTON',
};

export const PHONE_OR_SMALLER = 767;
export const TABLET_OR_SMALLER = 991;
export const DESKTOP_OR_SMALLER = 1199;
export const PHONE_OR_LARGER = 768;

export const LOGGED_IN_HOME_PAGE_TYPES = {
  HOME: 'HOME',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  SAVED: 'SAVED',
};

export const LOGGED_IN_HOME_PAGE_TYPE_TO_NAME = {
  get HOME() {
    return _t('Home');
  },
  get IN_PROGRESS() {
    return _t('In Progress');
  },
  get COMPLETED() {
    return _t('Completed');
  },
  get SAVED() {
    return _t('Saved');
  },
};

export const NUMBER_OF_SEARCH_RESULTS = 4;

export const PAGE_SIZE_DEFAULT = 8;

export const WELCOME_MODAL_VISIT_EVENT_ID = 'dashboardEventKey~LAST_WELCOME_MODAL_VIEW';
export const ASSET_URL = '//s3.amazonaws.com/coursera_assets/logged-in-home/';
