import * as React from 'react';

import { StyleSheet, css } from '@coursera/coursera-ui';

import { getColorsByCardState } from 'bundles/program-home/utils/ProgramHomeUtils';

export type CardState = 'green' | 'gray-cover' | 'green-cover';

type Props = {
  text: string;
  cardState: CardState;
  width?: number;
};

const styles = StyleSheet.create({
  CircleWidget: {
    textAlign: 'center',
    padding: '0.5rem',
  },
});

const CircleWidget: React.FunctionComponent<Props> = ({ text = '1', cardState = 'green', width = 44 }) => {
  const { color, backgroundColor, textColor } = getColorsByCardState(cardState);
  const containerStyle = {
    width: `${width}px`,
    height: `${width}px`,
    borderRadius: `${width / 2}px`,
    color,
    backgroundColor,
    border: `2px solid ${color}`,
  };
  const textStyle = {
    lineHeight: `${width - 8 * 2 - 2}px`, // 8 padding, 2 border
    color: textColor,
  };

  return (
    <div {...css(styles.CircleWidget)} style={containerStyle}>
      <span className="h4" style={textStyle}>
        {text}
      </span>
    </div>
  );
};

export default CircleWidget;
