/** @jsx jsx */
import type { SerializedStyles } from '@emotion/react';
import { css, jsx } from '@emotion/react';

import * as React from 'react';

type Props = {
  badgeStyle?: SerializedStyles;
  containerStyle?: SerializedStyles;
  textStyle?: SerializedStyles;
  text: string;
};

const styles = {
  badgeStyle: css`
    height: 24px;
    padding: 2px 10px;
    background-color: #adf9da;
    text-align: center;
    margin-left: 9px;
  `,
  textStyle: css`
    font-size: 14px;
    line-height: 19px;
    color: #000;
    margin-bottom: 0;
  `,
};

export default function ProjectBadge({ badgeStyle, containerStyle, textStyle, text }: Props) {
  return (
    <div className="rc-ProjectBadge" css={containerStyle}>
      <div className="horizontal-box align-items-vertical-center" css={[styles.badgeStyle, badgeStyle]}>
        <p css={[styles.textStyle, textStyle]}>{text}</p>
      </div>
    </div>
  );
}
