import { simplifyQueryValue } from 'js/lib/queryUtils';
import requestCountry from 'js/lib/requestCountry';
import { useLocation } from 'js/lib/useRouter';

import GoogleCertificateEpicClient from 'bundles/epic/clients/GoogleCertificate';
import paymentsEpicClient from 'bundles/epic/clients/payments';
import type CartsV2 from 'bundles/naptimejs/resources/carts.v2';
import usePageData from 'bundles/payments/data/usePageData';

// These partner IDs are for Standford,Princeton and Yale
const FINAID_CEILING_EXCLUDED_PARTNERS = [1, 4, 109];

// FinAid Ceiling 3.0 countries with 75% discount
const FINAID_V3_EXPANSION_REGIONS_B = [
  'RO',
  'LT',
  'XK',
  'AL',
  'MT',
  'HU',
  'GR',
  'MK',
  'SK',
  'RS',
  'ME',
  'PL',
  'EE',
  'HR',
  'TR',
  'BG',
  'LV',
  'BA',
  'SI',
  'CZ',
  'BH',
  'KW',
  'SA',
  'CY',
  'IL',
  'AE',
  'OM',
  'QA',
  'KZ',
  'TM',
  'UZ',
  'BY',
  'MD',
  'AM',
  'KG',
  'GE',
  'AZ',
  'TJ',
];

// Exclude from FinAid Ceiling
const FINAID_CEILING_EXCLUDED_REGIONS = ['RU', 'CN', 'SD', 'SY', 'KP', 'IR', 'CU', 'UA'];

export const getIsPartnerExcludedFromFinAidCeilingEpic = (partnerIds?: Array<string>) => {
  const isPartnerExcludedFromFinAidCeilingTest = partnerIds
    ?.map((partnerId) => Number(partnerId))
    ?.some((partnerId) => FINAID_CEILING_EXCLUDED_PARTNERS.includes(partnerId));

  return Boolean(isPartnerExcludedFromFinAidCeilingTest);
};

export const getFinaidCeilingDiscountPercentage = (partnerIds?: Array<string>) => {
  if (
    getIsPartnerExcludedFromFinAidCeilingEpic(partnerIds) ||
    FINAID_CEILING_EXCLUDED_REGIONS.includes(requestCountry.get())
  ) {
    return 100;
  }

  // FinAid Ceiling 3.0 uses country targeting for expansion regions
  // with two different discount percentages (90% and 75%) across the regions
  const isInPaymentsFinAidVariantV3 = paymentsEpicClient.preview('finaidCeilingVariantV3') !== 'optOut';
  if (isInPaymentsFinAidVariantV3 || GoogleCertificateEpicClient.preview('finaidCeilingVariantV3') !== 'optOut') {
    const finaidCeilingDiscountPercentageV3RegionA = isInPaymentsFinAidVariantV3
      ? paymentsEpicClient.preview('finaidCeilingDiscountPercentageV3RegionA')
      : GoogleCertificateEpicClient.preview('finaidCeilingDiscountPercentageV3RegionA');
    const finaidCeilingDiscountPercentageV3RegionB = isInPaymentsFinAidVariantV3
      ? paymentsEpicClient.preview('finaidCeilingDiscountPercentageV3RegionB')
      : GoogleCertificateEpicClient.preview('finaidCeilingDiscountPercentageV3RegionB');
    return FINAID_V3_EXPANSION_REGIONS_B.includes(requestCountry.get())
      ? finaidCeilingDiscountPercentageV3RegionB
      : finaidCeilingDiscountPercentageV3RegionA;
  }

  // FinAid Ceiling 2.0 uses country targeting for India and non-US developed countries
  // and uses different discount percentages for India vs non-US developed countries
  const isInPaymentsFinAidVariantV2 = paymentsEpicClient.preview('finaidCeilingVariantV2') !== 'optOut';
  if (isInPaymentsFinAidVariantV2 || GoogleCertificateEpicClient.preview('finaidCeilingVariantV2') !== 'optOut') {
    const finaidCeilingDiscountPercentageIndia = isInPaymentsFinAidVariantV2
      ? paymentsEpicClient.preview('finaidCeilingDiscountPercentageIndia')
      : GoogleCertificateEpicClient.preview('finaidCeilingDiscountPercentageIndia');
    const finaidCeilingDiscountPercentageNonUSDeveloped = isInPaymentsFinAidVariantV2
      ? paymentsEpicClient.preview('finaidCeilingDiscountPercentageNonUSDeveloped')
      : GoogleCertificateEpicClient.preview('finaidCeilingDiscountPercentageNonUSDeveloped');
    return requestCountry.get() === 'IN'
      ? finaidCeilingDiscountPercentageIndia
      : finaidCeilingDiscountPercentageNonUSDeveloped;
  }

  // Else, return the FinAid Ceiling 1.0 discount percentage,
  // either the value for US-impressed learners, or the default Epic value
  return paymentsEpicClient.preview('finaidCeilingVariant') !== 'optOut'
    ? paymentsEpicClient.preview('finaidCeilingDiscountPercentage')
    : GoogleCertificateEpicClient.preview('finaidCeilingDiscountPercentage');
};

export const isFinaidCeilingEnabled = (partnerIds?: Array<string>) =>
  getFinaidCeilingDiscountPercentage(partnerIds) !== 100;

export const isFinancialAidCeilingVariantCart = (cart: CartsV2, isFinancialAid?: string): boolean =>
  Boolean(isFinancialAid === 'true' && cart.hasDiscount && cart.isVC);

export const useGetIsFinancialAidCeilingVariantCart = () => {
  const { cart } = usePageData();
  const location = useLocation();
  const isFinancialAid = location?.query?.isFinancialAid ?? '';

  return isFinancialAidCeilingVariantCart(cart, simplifyQueryValue(isFinancialAid));
};
