import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import type { XdpV1_Org_Coursera_Xdp_Common_XdpModuleItem as XDPModuleItem } from '__generated__/graphql-types';
import moment from 'moment';
import type { Duration } from 'moment';

import { Typography2 } from '@coursera/cds-core';
import { Box, color as colorAlt } from '@coursera/coursera-ui';
import { SvgQuiz, SvgReading, SvgVideo } from '@coursera/coursera-ui/svg';

import { TrackedA } from 'bundles/page/components/TrackedLink2';

import _t from 'i18n!nls/program-common';

import 'css!bundles/enterprise-legacy-xdp/components/cdp/syllabus/__styles__/ItemGroupView';

export const ITEM_GROUP_TYPES = {
  LECTURE: 'lecture',
  SUPPLEMENT: 'supplement',

  /* Quiz */
  SUMMATIVE_QUIZ: 'exam',
  FORMATIVE_QUIZ: 'quiz',
} as const;

export type ItemGroupType = 'lecture' | 'supplement' | 'quiz';

type MessageInfo = {
  message: string;
  numHours?: number;
  numMinutes?: number;
  numSeconds?: number;
};

type Props = {
  items: Array<XDPModuleItem>;
  type: ItemGroupType;
  slug: string;
};

const CONFIG = {
  lecture: {
    color: colorAlt.primary,
    icon: SvgVideo,
  },
  supplement: {
    color: colorAlt.success,
    icon: SvgReading,
  },
  quiz: {
    color: colorAlt.darkPrimary,
    icon: SvgQuiz,
  },
};

function getDuration(duration: Duration) {
  let messageInfo = {} as MessageInfo;

  if (duration.hours() > 0) {
    if (duration.minutes() > 0) {
      messageInfo = {
        message: _t('{numHours}h {numMinutes}m'),
        numHours: duration.hours(),
        numMinutes: duration.minutes(),
      };
    } else {
      messageInfo = {
        message: _t('{numHours}h'),
        numHours: duration.hours(),
      };
    }
  } else if (duration.minutes() > 0) {
    messageInfo = {
      message: _t('{numMinutes}m'),
      numMinutes: duration.minutes(),
    };
  } else {
    messageInfo = {
      message: _t('{numSeconds}s'),
      numSeconds: duration.seconds(),
    };
  }

  return <FormattedMessage {...messageInfo} />;
}

export default function ItemGroupView({ items, type, slug }: Props) {
  const { icon: IconTag, color } = CONFIG[type];
  return (
    <div className="ItemGroupView border-top p-t-2">
      <div className="items">
        <Box alignItems="center">
          <IconTag color={color} aria-hidden="true" />
          <Typography2 className="m-x-1 learning-item" variant="bodyPrimary" component="strong">
            {type === 'lecture' && (
              <FormattedMessage
                message={_t('{numItems} {numItems, plural, =1 { video} other { videos}}')}
                numItems={items.length}
              />
            )}
            {type === 'supplement' && (
              <FormattedMessage
                message={_t('{numItems} {numItems, plural, =1 { reading} other { readings}}')}
                numItems={items.length}
              />
            )}
            {type === 'quiz' && (
              <FormattedMessage
                message={_t('{numItems} {numItems, plural, =1 { practice exercise} other { practice exercises}}')}
                numItems={items.length}
              />
            )}
          </Typography2>
        </Box>
        {items.map(({ id, name: itemName, duration, slug: itemSlug }, index) => (
          <Typography2
            key={id}
            className="m-y-2"
            variant="bodyPrimary"
            component="span"
            data-test-id="item-view"
            display="block"
          >
            {type === 'lecture' && index < 1 ? (
              <TrackedA
                className="text-primary link-no-style"
                href={`/lecture/${slug}/${itemSlug}-${id}`}
                target="_blank"
                rel="noopener noreferrer"
                data-test="lecture-item-link"
                trackingName="syllabus_item_link"
                data={{
                  name: itemName,
                }}
              >
                {itemName}
              </TrackedA>
            ) : (
              itemName
            )}
            {!!duration && <span className="duration-text m-x-1s">{getDuration(moment.duration(duration))}</span>}
          </Typography2>
        ))}
      </div>
    </div>
  );
}
