import * as React from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { useCourseEnrollModalData } from 'bundles/enroll/data/usePageData';

import _t from 'i18n!nls/enroll';

import 'css!./__styles__/SubscriptionVPropCDP';

const SubscriptionVPropCDP: React.FC<{}> = () => {
  const { s12n, course } = useCourseEnrollModalData();

  if (!s12n) {
    return null;
  }

  const s12nTitle = s12n.name;
  const courseName = course.name;

  return (
    <div className="rc-SubscriptionVPropCDP">
      <div>
        <h2 className="sub-prop-header headline-1-text">
          <strong>
            <FormattedMessage message={_t('Subscribe and get access to the entire specialization.')} />
          </strong>
        </h2>
        <div className="flex-11">
          <p className="body-1-text">
            <FormattedMessage
              message={_t(
                "{courseName} is part of the larger {s12nTitle} series. When you subscribe you'll get access to:"
              )}
              s12nTitle={<strong>{s12nTitle}</strong>}
              courseName={<strong>{courseName}</strong>}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionVPropCDP;
