import * as React from 'react';
import { useMemo } from 'react';

import AiSkillsBadge from 'bundles/product-card/components/AiSkillsBadge';
import GenAiBadge from 'bundles/product-card/components/GenAiBadge';
import {
  shouldShowAiSkillsBadge,
  shouldShowRecentlyUpdatedGenAiBadge,
} from 'bundles/product-card/utils/recentlyUpdatedBadgeUtils';

export function useGenAiBadges(slug: string | undefined) {
  const result = useMemo(() => {
    return getGenAiBadges(slug);
  }, [slug]);

  return result;
}

export function getGenAiBadges(slug: string | undefined) {
  if (!slug) {
    return { badges: [] };
  }

  const badges = [];

  if (shouldShowRecentlyUpdatedGenAiBadge(slug)) {
    badges.push(<GenAiBadge />);
  }
  if (shouldShowAiSkillsBadge(slug)) {
    badges.push(<AiSkillsBadge />);
  }

  return { badges };
}

export default useGenAiBadges;
