export const s3AssetsUrl = 'https://s3.amazonaws.com/coursera-university-assets/program-assets/shared/';

type IconConfigMap = { [key: string]: string };

const exported = {
  s3AssetsUrl,

  defaultAssets: {
    tutorialVideoUrl: 'http://www.w3schools.com/html/mov_bbb.mp4',
  },

  defaultsUIConfig: {
    primaryColor: '#3277b2',
    landingPageBanner: 'jumbotron_bg.png', // ADD
    banner: 'jumbotron_bg.png', // ADD
    profileBackgroundImage: 'profile_background_image.png',
    logo: '',
    classLogo: '',
    squareLogo: '',
    rectangularLogo: '',
  },

  iconConfig: {
    defaultIcon: 'stack.png',

    // Cards icons
    certificate: 'certificate.png',
    stack: 'stack.png',
    clock: 'clock.png',
    calendar: 'calendar.png',

    // Banner Icons light
    curatedLearning: 'curated_learning.png',
    getRecognized: 'get_recognized.png',
    advanceYourCareer: 'advance_your_career.png',
    // Banner Icons dark
    curatedLearningDark: 'curated_learning_dark.png',
    getRecognizedDark: 'get_recognized_dark.png',
    advanceYourCareerDark: 'advance_your_career_dark.png',
  } as IconConfigMap,

  apiConfig: {
    PROGRAM_PRODUCT_ENROLLED_FETCH_LIMIT: 50,
    PROGRAM_PRODUCT_ENROLLED_DISPLAY_LIMIT: 5,
  },
  collectionResponsiveConfig: {
    xs: { displayLimit: 2 },
    sm: { displayLimit: 2 },
    md: { displayLimit: 2 },
    lg: { displayLimit: 3 },
    xl: { displayLimit: 4 },
    xxl: { displayLimit: 4 },
  },
};

export default exported;

export const { defaultAssets, defaultsUIConfig, iconConfig, apiConfig, collectionResponsiveConfig } = exported;
