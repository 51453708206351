import type { BaseComponentProps } from '@coursera/cds-common';
import { useLocalizedStringFormatter } from '@coursera/cds-common';
import { VideoIcon } from '@coursera/cds-icons';

import { classes } from '@core/cards/common/getCommonCardCss';
import i18nMessages from '@core/cards/common/i18n';
import Typography from '@core/Typography2';

export type CardWatchNowProps = {
  viewCount?: string;
};

type Props = {
  watchNow: CardWatchNowProps;
} & BaseComponentProps<'div'>;

/**
 * Common component to render Clips cards
 */
const CardWatchNow = ({ watchNow, ...rest }: Props) => {
  const stringFormatter = useLocalizedStringFormatter(i18nMessages);
  return (
    <div className={classes.watchNow} {...rest}>
      <Typography
        className={classes.watchNowIconLabel}
        component="p"
        variant="bodySecondary"
      >
        <VideoIcon size="medium" />
        {stringFormatter.format('watch_now')}
      </Typography>
      <Typography color="supportText" component="p" variant="bodySecondary">
        {'·'}
      </Typography>
      <Typography color="supportText" component="p" variant="bodySecondary">
        {stringFormatter.format('view_count_label', {
          viewCount: watchNow.viewCount || '',
        })}
      </Typography>
    </div>
  );
};

export default CardWatchNow;
