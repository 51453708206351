/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import tinycolor from 'tinycolor2';

import { useLocation } from 'js/lib/useRouter';

import type { ButtonProps } from '@coursera/cds-core';

import { TrackedCdsButton } from 'bundles/common/components/withSingleTracked';

const HOVER_ALPHA = 0.7;

export type EnrollButtonProps = {
  ctaLabel?: string | React.ReactNode;
  buttonStyle?: React.CSSProperties;
  buttonVariant?: ButtonProps['variant'];
  buttonClassName?: string;
  fullWidth?: boolean;
  onClick?: () => void;
  buttonSize?: ButtonProps['size'];
  buttonDisabled?: boolean;
  trackingName?: string;
  customStyles?: React.CSSProperties;
  isEnrolled?: boolean;
  icon?: React.ReactNode;
};

// Prevent default if TTI is achieved so modal will open as normal.
// If button is clicked before hydration/TTI the form will submit to the current URL + ?action=enroll or go to course home if enrolled.
const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();
};

export function EnrollButton({
  ctaLabel,
  buttonVariant = 'primaryInvert',
  fullWidth = false,
  buttonStyle,
  buttonSize = 'medium',
  buttonClassName,
  buttonDisabled,
  onClick,
  trackingName,
  isEnrolled,
  customStyles: customStylesFromCaller,
  icon,
}: EnrollButtonProps) {
  const { pathname } = useLocation();

  let hoverProp;
  if (buttonStyle && buttonStyle.backgroundColor) {
    const rgbaString = tinycolor(buttonStyle.backgroundColor).setAlpha(HOVER_ALPHA).toRgbString();
    hoverProp = {
      backgroundColor: `${rgbaString} !important`,
    };
  } else {
    hoverProp = {
      opacity: HOVER_ALPHA,
    };
  }

  const customStyles = {
    ':hover': hoverProp,
    ':focus': { outline: 'auto 3px black' },
    ':disabled': hoverProp,
    // Note: This declaration exists to make the CDS button occupy more space similar to the CUI one.
    // Some styling quirks on the XDP pages make it necessary so that components aren't too tightly squeezed together.
    minWidth: '180px',
    width: 'max-content',
    ...customStylesFromCaller,
  };

  return (
    <div style={{ width: buttonStyle && buttonStyle.width }} className="EnrollButton">
      {/* Using form element as a fallback if a user clicks on the button before TTI. 
          After TTI onSubmit prevents default action and modal opens. */}
      <form
        onSubmit={onSubmit}
        action={isEnrolled ? `${pathname}/home/welcome` : pathname}
        style={{ width: buttonStyle && buttonStyle.width }}
        method="GET"
        className="d-inline-block"
      >
        {!isEnrolled && <input type="hidden" name="action" value="enroll" data-testid="ssr-enroll-action" />}
        <TrackedCdsButton
          trackingName={trackingName || 'enroll_button'}
          variant={buttonVariant}
          fullWidth={fullWidth}
          size={buttonSize}
          css={customStyles}
          style={buttonStyle}
          disabled={buttonDisabled}
          data-e2e="enroll-button"
          type="submit"
          onClick={onClick}
          className={buttonClassName}
        >
          {ctaLabel}
          {icon}
        </TrackedCdsButton>
      </form>
    </div>
  );
}

export default EnrollButton;
