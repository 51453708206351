import * as React from 'react';

import { useReadOnly } from 'slate-react';

import { CommentDotsIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';

import _t from 'i18n!nls/cml';

export type Props = {
  onClick: () => void;
};

const CodeBlockNameButton: React.FC<Props> = ({ onClick }) => {
  const readonly = useReadOnly();

  return (
    <Button
      role="menuitem"
      disabled={readonly}
      onClick={onClick}
      title={_t('Accessibility name')}
      tooltipProps={{ placement: 'bottom' }}
      type={TOOLBAR_BUTTON_TYPES.menu}
    >
      <CommentDotsIcon size="small" />
    </Button>
  );
};

export default CodeBlockNameButton;
