import type React from 'react';
import { forwardRef } from 'react';

import { Popover } from '@core/Popover';
import PopoverBody from '@core/Popover/Body';
import Typography from '@core/Typography2';
import { useItemsCollection, useKeyboardNavigation } from '@core/utils';

import getActionOverflowMenuCss, {
  classes,
} from './styles/getActionOverflowCss';

export type CloseReason = 'backdropClick' | 'escapeKeyDown' | 'itemClick';

type Props = {
  triggerButtonId: string;
  children: React.ReactNode;
  label?: string;
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: (
    event: React.SyntheticEvent,
    reason?: 'backdropClick' | 'escapeKeyDown' | 'itemClick' | undefined
  ) => void;
};

const ActionOverflowMenu = forwardRef<HTMLUListElement, Props>(
  function ActionOverflowMenu(props, ref) {
    const { children, triggerButtonId, anchorEl, label, open, onClose } = props;

    const collection = useItemsCollection<HTMLButtonElement>();

    const keyboardNavigation = useKeyboardNavigation<HTMLUListElement>({
      elements: collection?.items.map((item) => item.element),
    });

    return (
      <Popover
        anchorElement={anchorEl}
        drawerProps={{ autoFocus: false }}
        dropdownProps={{ autoFocus: false }}
        initialFocusRef={{
          current: collection?.items[0]?.element || null,
        }}
        open={open}
        onClose={onClose}
      >
        {({ isDrawer }) => (
          <>
            {isDrawer && label && (
              <Popover.Header>
                <Typography
                  aria-hidden
                  align="left"
                  component="div"
                  variant="subtitleMedium"
                >
                  {label}
                </Typography>
              </Popover.Header>
            )}
            <PopoverBody
              ref={ref}
              aria-labelledby={triggerButtonId}
              className={classes.menu}
              component="ul"
              css={getActionOverflowMenuCss}
              role="menu"
              // TODO: orest - for some reason the types can't be inferred in this place, even though VSCode infers it correctly with TS 4.1.5
              onKeyDown={(event: React.KeyboardEvent<HTMLUListElement>) => {
                // Header has a close button, don't close the popover
                if (!isDrawer && event.key === 'Tab') {
                  event.preventDefault();
                  onClose(event, 'backdropClick');
                }

                keyboardNavigation.onKeyDown(event);
              }}
            >
              {children}
            </PopoverBody>
          </>
        )}
      </Popover>
    );
  }
);

export default ActionOverflowMenu;
