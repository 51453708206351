import * as React from 'react';

import type { XdpV1_Org_Coursera_Xdp_Sdp_SdpCourse as Course } from '__generated__/graphql-types';

import type { SubscriptionTiersProductPrice } from 'bundles/coursera-plus-landing-page/contentfulData/LandingPageDataProvider';
import type { GroupsData, InvitedProgramsData, ProgramsData } from 'bundles/enroll-course/lib/apiClient';
import type { EnrollmentChoices } from 'bundles/enroll/components/xdp/withEnrollment';
import type { Partner } from 'bundles/enroll/hooks/useGetPartnerNamesByIds';
import type CourseTypeMetadataV1 from 'bundles/naptimejs/resources/courseTypeMetadata.v1';
import type CoursesV1 from 'bundles/naptimejs/resources/courses.v1';
import type OnDemandSpecializationsV1 from 'bundles/naptimejs/resources/onDemandSpecializations.v1';
import type ProductOwnershipsV1 from 'bundles/naptimejs/resources/productOwnerships.v1';
import type PromotionDetailsV1 from 'bundles/naptimejs/resources/promotionDetails.v1';
import type PromotionEligibilitiesV1 from 'bundles/naptimejs/resources/promotionEligibilities.v1';
import type S12nDerivativesV1 from 'bundles/naptimejs/resources/s12nDerivatives.v1';
import type { ProductPrices } from 'bundles/payments-common/hooks/useGetProductPrices';

type BaseDataContextType = {
  isCourseraPlusEligibleProduct?: boolean;
  isSpecialization: boolean;
  course: CoursesV1;
  courses?: Course[];
  courseraLiteProductPrice?: SubscriptionTiersProductPrice;
  courseraPlusProductPrice?: SubscriptionTiersProductPrice;
  courseraPlusAnnualProductPrice?: SubscriptionTiersProductPrice;
  enrollmentAvailableChoices: EnrollmentChoices;
  productPrice?: ProductPrices;
  promotionDetails?: PromotionDetailsV1 | null;
  promotionEligibilities?: PromotionEligibilitiesV1 | null;
  s12nDerivatives?: S12nDerivativesV1;
  s12nPrepaidProductPrices?: ProductPrices[];
  courseraPlusPrepaidProductPrices?: ProductPrices[];
  s12nPrepaidProductPrice?: ProductPrices;
  s12nProductPrice?: ProductPrices;
  partners?: Partner[];
} & GroupsData &
  InvitedProgramsData &
  ProgramsData;

export type CourseEnrollModalDataContextType = BaseDataContextType & {
  courseTypeMetadataWithVersion: CourseTypeMetadataV1;
  ownsCourseraLite?: boolean;
  s12n?: OnDemandSpecializationsV1;
  s12ns?: OnDemandSpecializationsV1[];
  s12nCourses?: CoursesV1[];
};

export type S12nEnrollModalDataContextType = BaseDataContextType & {
  productOwnerships: ProductOwnershipsV1;
  s12n: OnDemandSpecializationsV1;
};

const PageDataContext = React.createContext<
  CourseEnrollModalDataContextType | S12nEnrollModalDataContextType | undefined
>(undefined);

export default PageDataContext;
