/*
  this contains utility functions for dealing with copy-paste via MS Word, see `rules.ts` for usage.
*/
import { NEWLINES_REGEX } from 'bundles/cml/editor/html/constants';

// MS Word markup either has `OutlineElement` or `TextRun` parent classes to differentiate from other external content,
// if there's an ancestor node with that unique id we consider this as MS Word content and handle accordingly.
const MS_WORD_SELECTOR = 'div[class^="OutlineElement"],span[class^="TextRun"]';

const isWordWeb = (document: Document): boolean => {
  return !!document.body?.querySelector(MS_WORD_SELECTOR);
};

const isWordDesktop = (document: Document): boolean => {
  return document.querySelector('meta[name="ProgId"]')?.getAttribute('content') === 'Word.Document';
};

export const isMsWordContent = (document: Document): boolean => {
  return isWordWeb(document) || isWordDesktop(document);
};

// parse style string for text formatting attributes
export const isBold = (style: string): boolean =>
  style.includes('font-weight: 700') ||
  style.includes('font-weight: bold') ||
  style.includes('font-weight:bold') ||
  false;
export const isItalic = (style: string): boolean =>
  style.includes('font-style: italic') || style.includes('font-style:italic') || false;
export const isUnderline = (style: string): boolean =>
  style.includes('text-decoration: underline') || style.includes('text-decoration:underline') || false;

// finds empty spacer paragraphs in MS Word
// they look like "<p class=MsoNormal><span lang=EN-GB style='font-size:12.0pt;color:black'><o:p>&nbsp;</o:p></span></p>"
export const isEmptySpacerMSWord = (el: HTMLElement | null): boolean => {
  return el
    ? el.classList.contains('MsoNormal') && el.textContent?.replace(NEWLINES_REGEX, ' ').trim().length === 0
    : false;
};

export const isSuperscript = (style: string): boolean => style.includes('vertical-align: super') || false;
export const isSubscript = (style: string): boolean => style.includes('vertical-align: sub') || false;
