import * as React from 'react';
import { useState } from 'react';

import { SvgCheck } from '@coursera/coursera-ui/svg';

import EnrollmentChoiceTypes from 'bundles/enroll-course/common/EnrollmentChoiceTypes';
import {
  choiceTypeToHandleSubmitPromise,
  submitEnrollmentPromise,
} from 'bundles/enroll-course/lib/enrollmentChoiceUtils';
import EnrollErrorModal from 'bundles/enroll/components/common/EnrollErrorModal';
import PromotionApplicableCheckoutMessage from 'bundles/enroll/components/common/PromotionApplicableCheckoutMessage';
import { useCourseEnrollModalData } from 'bundles/enroll/data/usePageData';
import type { Partner } from 'bundles/enroll/hooks/useGetPartnerNamesByIds';
import { EnrollModalType } from 'bundles/enroll/types/modalTypes';
import type { ApiError } from 'bundles/enroll/utils/errorUtils';
import { useEnrollModalEventing } from 'bundles/enroll/utils/eventingUtils';
import Icon from 'bundles/iconfont/Icon';
import TrackedButton from 'bundles/page/components/TrackedButton';
import ReactPriceDisplay from 'bundles/payments-common/components/ReactPriceDisplay';
import Modal from 'bundles/phoenix/components/Modal';

import _t from 'i18n!nls/enroll-course';

import 'css!./__styles__/GuidedProjectEnrollModal';

const getBulletPoints = () => [
  _t('All learning materials, including the interactive workspace and final quiz'),
  _t('Instant access to the necessary software packages through Rhyme'),
  _t('A split-screen video walkthrough of each step, from a subject-matter expert'),
  _t('Free download of what you create'),
  _t('Ability to access your cloud desktop across six different sessions'),
];

export const getFormattedPartnerDisplayName = (partners: Array<Partner>): string => {
  const partnerNames = partners.map((partner) => partner.name);

  let partnerDisplayString;
  if (partnerNames.length > 1) {
    const combinedNames = partnerNames.slice(0, partnerNames.length - 1).join(', ');
    partnerDisplayString = _t('Offered by #{combinedNames} and #{lastPartner}', {
      combinedNames,
      lastPartner: partnerNames[partnerNames.length - 1],
    });
  } else {
    partnerDisplayString = _t('Offered by #{partnerName}', { partnerName: partnerNames[0] });
  }

  return partnerDisplayString;
};

export const renderValueProps = (): React.ReactNode => {
  const bullets = getBulletPoints();
  return (
    <ul className="value-props nostyle">
      {bullets.map((bullet) => (
        <li key={`value-prop-${bullet}`}>
          <div className="bullet horizontal-box">
            <div className="checkmark">
              <SvgCheck size={22} color="#2A73CC" suppressTitle={true} />
            </div>
            <p className="body-1-text">{bullet}</p>
          </div>
        </li>
      ))}
    </ul>
  );
};

type PropsFromCaller = {
  courseId: string;
  onClose: () => void;
};

const GuidedProjectEnrollModal: React.FC<PropsFromCaller> = ({ courseId, onClose }) => {
  const [didClickContinue, setDidClickContinue] = useState<boolean>(false);
  const [error, setError] = useState<ApiError | undefined>(undefined);
  const [activeModal, setActiveModal] = useState<EnrollModalType>(EnrollModalType.ENROLL);

  const { getEnrollModalTrackingRef, trackEnrollModalContinue, trackEnrollModalClose } = useEnrollModalEventing();
  const visibilityTrackingRef = getEnrollModalTrackingRef();

  const { promotionEligibilities, course, partners, productPrice } = useCourseEnrollModalData();
  const enrollmentChoiceType = EnrollmentChoiceTypes.ENROLL_GUIDED_PROJECT;

  const handleClose = () => {
    trackEnrollModalClose();
    onClose();
  };

  const onClickContinue = () => {
    setDidClickContinue(true);
    trackEnrollModalContinue(enrollmentChoiceType);

    const promoCode = promotionEligibilities?.isEligible ? promotionEligibilities.promoCodeId : undefined;
    const options = { courseId };

    const handleSubmitPromise = choiceTypeToHandleSubmitPromise[enrollmentChoiceType];
    submitEnrollmentPromise({ handleSubmitPromise, options, promoCode }).catch(
      (data: $TSFixMe /* type submitEnrollmentPromise */) => {
        setActiveModal(EnrollModalType.ERROR);
        setError(data);
      }
    );
  };

  switch (activeModal) {
    case EnrollModalType.ERROR:
      return <EnrollErrorModal error={error} onClose={handleClose} />;
    case EnrollModalType.ENROLL:
      return (
        <Modal
          className="rc-GuidedProjectEnrollModal"
          modalName="GuidedProjectEnrollModal"
          handleClose={handleClose}
          trackingName="guided_project_enroll_modal"
          data={{ id: course.id }}
        >
          <div className="body" ref={visibilityTrackingRef}>
            <div className="headline-2-text project-name">{course.name}</div>
            {partners && (
              <div className="offered-by-partner caption-text">
                {partners && getFormattedPartnerDisplayName(partners)}
              </div>
            )}
            {productPrice && (
              <div className="price">
                <ReactPriceDisplay value={productPrice.finalAmount} currency={productPrice.currencyCode} />
              </div>
            )}
            <PromotionApplicableCheckoutMessage course={course} />
            <hr />
            <div className="value-props-container">
              <p className="value-props-title">{_t('Included in this Guided Project:')}</p>
              {renderValueProps()}
            </div>
          </div>
          <TrackedButton
            className="primary continue-button cozy"
            data-e2e="guided_project_enroll_modal_continue_button"
            onClick={onClickContinue}
            disabled={didClickContinue}
            trackingName="guided_project_enroll_modal_continue"
          >
            {didClickContinue ? <Icon name="spinner" spin={true} /> : _t('Continue')}
          </TrackedButton>
        </Modal>
      );
    default:
      return null;
  }
};

export default GuidedProjectEnrollModal;
