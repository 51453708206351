import * as React from 'react';

import type { UserEmail } from '@coursera/grpc-types-useremails/coursera/proto/useremails/v1/email_address';

import type { InjectedNaptime } from 'bundles/naptimejs';
import type ThirdPartyOrganizationsV1 from 'bundles/naptimejs/resources/thirdPartyOrganizations.v1';
import type { PropsFromSaveSkillSetToggle } from 'bundles/program-home/components/ProgramHomeApp';
import type { TargetSkillProfileUserStatesQuery_TargetSkillProfileUserStatesV1Resource_byUserAndProgram_elements as UserSkillProfileStateType } from 'bundles/program-home/components/__generated__/TargetSkillProfileUserStatesQuery';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import MyCoursesContainer from 'bundles/program-home/components/enterprise-home/MyCoursesContainer';
import type { OnProductCardClick } from 'bundles/program-home/types/Products';

import 'css!./__styles__/MyCoursesTabContent';

type PropsFromCaller = PropsFromSaveSkillSetToggle & {
  programId: string;
  programSlug: string;
  programName: string;
  userId: number;
  onProductCardClick: OnProductCardClick;
  naptime: InjectedNaptime;
  userSkillProfileStates?: UserSkillProfileStateType[];
  userSkillProfileStatesLoading?: boolean;
  showCatalog: () => void;
  userEmails: UserEmail[] | undefined;
  thirdPartyOrganization: ThirdPartyOrganizationsV1;
  shouldShowEulaBanner: boolean;
  enableCurriculumBuilder?: boolean;
  isCareerAcademyLimitedCatalogProgram?: boolean;
  allowOrgForSpecializationConfiguration?: boolean;
};

export type Props = PropsFromCaller;

export default function MyCoursesTabContent({
  programId,
  programSlug,
  programName,
  userId,
  onProductCardClick,
  userSkillProfileStates,
  userSkillProfileStatesLoading,
  onSkillSetSaveToggle,
  showCatalog,
  userEmails,
  thirdPartyOrganization,
  shouldShowEulaBanner,
  enableCurriculumBuilder,
  allowOrgForSpecializationConfiguration,
  isCareerAcademyLimitedCatalogProgram,
}: Props): JSX.Element {
  return (
    <div className="rc-MyCoursesTabContent">
      <MyCoursesContainer
        programId={programId}
        programSlug={programSlug}
        programName={programName}
        userId={userId}
        onProductCardClick={onProductCardClick}
        userSkillProfileStates={userSkillProfileStates}
        userSkillProfileStatesLoading={userSkillProfileStatesLoading}
        onSkillSetSaveToggle={onSkillSetSaveToggle}
        toggleCatalogTab={showCatalog}
        userEmails={userEmails}
        thirdPartyOrganization={thirdPartyOrganization}
        shouldShowEulaBanner={shouldShowEulaBanner}
        isSummaryView={false}
        enableCurriculumBuilder={enableCurriculumBuilder}
        isCareerAcademyLimitedCatalogProgram={isCareerAcademyLimitedCatalogProgram}
        allowOrgForSpecializationConfiguration={allowOrgForSpecializationConfiguration}
      />
    </div>
  );
}
