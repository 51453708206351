import * as React from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import useRouter from 'js/lib/useRouter';

import { Typography2 } from '@coursera/cds-core';

import _t from 'i18n!nls/author-widget';

type Props = {
  isGradedWidget: boolean;
};

const WidgetModalText: React.FC<Props> = (props) => {
  const { isGradedWidget } = props;
  const {
    params: { courseSlug, contextId },
  } = useRouter();

  const message = isGradedWidget
    ? _t('Choose a Plugin to use as a Question. Plugin examples can be found {githubLink}. You can also {upload}.')
    : _t('Only ungraded plugins can be used here. You can also {upload}.');
  return (
    <Typography2 component="p" variant="bodyPrimary">
      <FormattedMessage
        message={message}
        githubLink={
          <a href="https://github.com/coursera/plugin-examples" target="_blank" rel="noopener noreferrer">
            {_t('on GitHub')}
          </a>
        }
        upload={
          <a href={`/teach/${courseSlug}/${contextId}/content/plugins`} target="_blank" rel="noopener noreferrer">
            {_t('upload a new plugin package')}
          </a>
        }
      />
    </Typography2>
  );
};

export default WidgetModalText;
