import type * as React from 'react';

const withPreventDefault =
  <T = Element, E = Event>(func: (event: React.SyntheticEvent<T, E>) => void) =>
  (event: React.SyntheticEvent<T, E>) => {
    event?.preventDefault();
    func(event);
  };

export default withPreventDefault;
