/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { ButtonProps } from '@coursera/cds-core';
import { Button } from '@coursera/cds-core';
import { ChevronPreviousIcon } from '@coursera/cds-icons';

import withSingleTracked from 'bundles/common/components/withSingleTracked';

import _t from 'i18n!nls/coursera-plus';

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps>(Button);

type Props = {
  goToPreviousStep: () => void;
};

const styles = {
  button: css`
    position: absolute;
    top: 16px;
    left: 16px;
  `,
};

const ModalBackButton: React.FC<Props> = ({ goToPreviousStep }) => {
  return (
    <TrackedButton
      variant="ghost"
      trackingName="ideal_upgrade_back_button"
      data-testId="ideal-upgrade-back-button"
      icon={<ChevronPreviousIcon />}
      iconPosition="before"
      size="small"
      onClick={goToPreviousStep}
      css={styles.button}
    >
      {_t('Back')}
    </TrackedButton>
  );
};

export default ModalBackButton;
