import * as React from 'react';
import { useContext } from 'react';

import type { UnifiedCareerAcademyPageContentFieldsFragment } from 'bundles/unified-career-academy/__generated__/contentfulTypes';

export type CareerAcademyContentfulContextType = {
  careerAcademyData?: UnifiedCareerAcademyPageContentFieldsFragment | null;
  careerAcademyDataLoading: boolean;
};

const CareerAcademyContentfulContext = React.createContext<CareerAcademyContentfulContextType | undefined>(undefined);

export const useCareerAcademyContentfulData = () => {
  const careerAcademyContentfulContext = useContext(CareerAcademyContentfulContext);
  return careerAcademyContentfulContext;
};

export default CareerAcademyContentfulContext;
