import * as React from 'react';

import { useQuery } from '@apollo/client';

import PartnerContainerQuery from 'bundles/coursera-ui/components/rich/PartnerContainerQuery.graphql';
import PartnerName from 'bundles/coursera-ui/components/rich/PartnerName';
import type {
  PartnerContainerQueryVariables,
  PartnerContainerQuery as PartnerContainerResponse,
} from 'bundles/coursera-ui/components/rich/__generated__/PartnerContainerQuery';

type Props = {
  id: string | number;
};

const PartnerContainer = ({ id }: Props) => {
  const partnerId = String(id);
  const { data } = useQuery<PartnerContainerResponse, PartnerContainerQueryVariables>(PartnerContainerQuery, {
    variables: {
      id: partnerId,
    },
    context: {
      clientName: 'gatewayGql',
    },
  });
  const partner = data?.Partner?.queryById;
  if (!partner) {
    return null;
  }
  return <PartnerName partner={partner} />;
};

export default PartnerContainer;
