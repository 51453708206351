import * as React from 'react';
import { useCallback } from 'react';

import type { RenderElementProps, RenderLeafProps } from 'slate-react';

import Heading from 'bundles/cml/shared/components/heading/Heading';
import List from 'bundles/cml/shared/components/list/List';
import ListItem from 'bundles/cml/shared/components/list/ListItem';
import Math from 'bundles/cml/shared/components/math/Math';
import Paragraph from 'bundles/cml/shared/components/paragraph/Paragraph';
import TableCell from 'bundles/cml/shared/components/table/TableCell';
import TableRow from 'bundles/cml/shared/components/table/TableRow';
import Text from 'bundles/cml/shared/components/text/Text';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import Asset from 'bundles/cml/viewer/components/Asset';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import Code from 'bundles/cml/viewer/components/Code';
import FillableBlank from 'bundles/cml/viewer/components/FillableBlank';
import Image from 'bundles/cml/viewer/components/Image';
import Link from 'bundles/cml/viewer/components/Link';
import Table from 'bundles/cml/viewer/components/Table';
import Widget from 'bundles/cml/viewer/components/Widget';

export const useRenderElement = (renderElementOverride?: (props: RenderElementProps) => JSX.Element | null) =>
  useCallback(
    (props: RenderElementProps) => {
      const override = renderElementOverride?.(props);
      if (override) {
        return override;
      }

      switch (props.element.type) {
        case BLOCK_TYPES.HEADING:
          return <Heading {...props} />;

        case BLOCK_TYPES.LINK:
          return <Link {...props} />;

        case BLOCK_TYPES.IMAGE:
          return <Image {...props} />;

        case BLOCK_TYPES.LEGACY_AUDIO:
        case BLOCK_TYPES.ASSET:
          return <Asset {...props} />;

        case BLOCK_TYPES.TABLE:
          return <Table {...props} />;

        case BLOCK_TYPES.TABLE_ROW:
          return <TableRow {...props} />;

        case BLOCK_TYPES.TABLE_CELL:
          return <TableCell {...props} />;

        case BLOCK_TYPES.MATH_BLOCK:
        case BLOCK_TYPES.MATH_INLINE:
          return <Math {...props} />;

        case BLOCK_TYPES.BULLET_LIST:
        case BLOCK_TYPES.NUMBER_LIST:
          return <List {...props} />;

        case BLOCK_TYPES.LIST_ITEM:
          return <ListItem {...props} />;

        case BLOCK_TYPES.CODE:
          return <Code {...props} />;

        case BLOCK_TYPES.FILLABLE_BLANK:
          return <FillableBlank {...props} />;

        case BLOCK_TYPES.WIDGET:
          return <Widget {...props} />;

        case BLOCK_TYPES.TEXT:
        default:
          return <Paragraph {...props} />;
      }
    },
    [renderElementOverride]
  );

export const useRenderTextElement = () => useCallback((props: RenderLeafProps) => <Text {...props} />, []);
