import ExecutionTimeSummary from 'bundles/code-evaluator/models/ExecutionTimeSummary';
import type { ExecutionTimeSummaryConstructorType } from 'bundles/code-evaluator/models/ExecutionTimeSummary';

export type EvaluatorSummaryConstructorType = {
  id?: string;
  latestUserExpression?: string;
  executionTimeSummary?: ExecutionTimeSummaryConstructorType;
};

export class EvaluatorSummary {
  declare executionTimeSummary: ExecutionTimeSummary;

  declare id: string | undefined;

  declare latestUserExpression: string | undefined;

  constructor({ id, latestUserExpression, executionTimeSummary = {} }: EvaluatorSummaryConstructorType) {
    this.id = id;
    this.latestUserExpression = latestUserExpression;
    this.executionTimeSummary = new ExecutionTimeSummary(executionTimeSummary);
  }
}

export default EvaluatorSummary;
