import * as React from 'react';

import PropTypes from 'prop-types';

// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import ReplHarnessEditor from 'bundles/author-code-evaluator/components/ReplHarnessEditor';
import TestCaseHarnessEditor from 'bundles/author-code-evaluator/components/TestCaseHarnessEditor';
import ExpressionHarness from 'bundles/author-code-evaluator/models/ExpressionHarness';
import ReplHarness from 'bundles/author-code-evaluator/models/ReplHarness';
import TestCaseHarness from 'bundles/author-code-evaluator/models/TestCaseHarness';

class HarnessEditor extends React.Component {
  static propTypes = {
    harness: PropTypes.instanceOf(ExpressionHarness),
    language: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  };

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'harness' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { harness } = this.props;

    if (harness instanceof ReplHarness) {
      return <ReplHarnessEditor {...this.props} />;
    } else if (harness instanceof TestCaseHarness) {
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      return <TestCaseHarnessEditor {...this.props} />;
    }

    return null;
  }
}

export default HarnessEditor;
