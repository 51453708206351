import * as React from 'react';

import { StyleSheet, color, css, placeholder } from '@coursera/coursera-ui';

const dropdownWidth = 160;
const dropdownHeight = 36;

const styles = StyleSheet.create({
  filterButtonPlaceholder: {
    width: dropdownWidth,
    height: dropdownHeight,
    marginRight: 14,
    marginTop: 30,
    backgroundColor: color.gray,
    animationDelay: `${placeholder.animationDuration / 4}s`,
  },
});

const SearchFilterPlaceholder = () => {
  return <div {...css(placeholder.styles.shimmer, styles.filterButtonPlaceholder)} data-testid="filter-placeholder" />;
};

export default SearchFilterPlaceholder;
