import * as React from 'react';
import { useContext } from 'react';

import type { ApolloError } from '@apollo/client';

import type { CollectionTypeForRecsTab } from 'bundles/program-personalized-tab/types/sharedTypes';

type S12nAndProCertRecsContextType = {
  recs: CollectionTypeForRecsTab;
  loading?: boolean;
  error?: ApolloError;
  leadershipRecs?: CollectionTypeForRecsTab;
  leadershipRecsLoading?: boolean;
  leadershipRecsError?: ApolloError;
};

const S12nAndProCertRecsContext = React.createContext<S12nAndProCertRecsContextType | undefined>(undefined);

export const useS12nAndProCertRecsData = () => {
  const s12nAndProCertRecsContext = useContext(S12nAndProCertRecsContext);
  return s12nAndProCertRecsContext;
};

export default S12nAndProCertRecsContext;
