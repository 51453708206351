import * as React from 'react';

import gql from 'graphql-tag';

import { FormattedMessage, FormattedNumber } from 'js/lib/coursera.react-intl';
import keysToConstants from 'js/lib/keysToConstants';

import { StyleSheet, color, css } from '@coursera/coursera-ui';

import _t from 'i18n!nls/payments';

export const VIEWS_AND_ENROLLMENT_THRESHOLD = 1500;
export const ProductMetricsRenderType = keysToConstants(['ALREADY_ENROLLED_LARGE_FONT', 'VIEWS_WITH_TEXT_ONLY']);

type PropsFromCaller = {
  productItemId?: string;
  productType: string;
  isDarkTheme?: boolean;
  totalEnrollmentCount?: number | null;
  pageViewsInLastMonthCount?: number | null;
  productMetricsRenderType: keyof typeof ProductMetricsRenderType;
};

type PropsToComponent = PropsFromCaller;

const styles = StyleSheet.create({
  enrolledLargeFont: {
    fontSize: '16px',
    paddingTop: 24,
  },
  viewsWithTextOnly: {
    fontSize: '14px',
    color: '#666',
    fontWeight: 'bold',
  },
});

export class ProductMetrics extends React.Component<PropsToComponent> {
  renderViewsWithTextOnly() {
    const { pageViewsInLastMonthCount } = this.props;
    if (!pageViewsInLastMonthCount || pageViewsInLastMonthCount < VIEWS_AND_ENROLLMENT_THRESHOLD) {
      return null;
    }

    return (
      <div {...css(styles.viewsWithTextOnly)}>
        <FormattedMessage
          message={_t('{views} recent views')}
          views={<FormattedNumber value={pageViewsInLastMonthCount} />}
        />
      </div>
    );
  }

  renderAlreadyEnrolledLargeFont() {
    const { totalEnrollmentCount, isDarkTheme } = this.props;
    if (!totalEnrollmentCount || totalEnrollmentCount < VIEWS_AND_ENROLLMENT_THRESHOLD) {
      return null;
    }
    const fontColor = isDarkTheme ? color.black : color.white;

    return (
      <div {...css(styles.enrolledLargeFont)} style={{ color: fontColor }}>
        <FormattedMessage
          message={_t('{learnerCount} already enrolled')}
          learnerCount={
            <strong>
              <FormattedNumber value={totalEnrollmentCount} />
            </strong>
          }
        />
      </div>
    );
  }

  render() {
    const { productMetricsRenderType } = this.props;
    const { ALREADY_ENROLLED_LARGE_FONT, VIEWS_WITH_TEXT_ONLY } = ProductMetricsRenderType;

    let content;
    switch (productMetricsRenderType) {
      case ALREADY_ENROLLED_LARGE_FONT:
        content = this.renderAlreadyEnrolledLargeFont();
        break;
      case VIEWS_WITH_TEXT_ONLY:
        content = this.renderViewsWithTextOnly();
        break;
      default:
        break;
    }

    if (content) {
      return <div className="rc-ProductMetrics">{content}</div>;
    } else {
      return null;
    }
  }
}

export const ProductMetricsCdpFragment = gql`
  fragment ProductMetricsCdpFragment on XdpV1_org_coursera_xdp_cdp_CDPMetadata {
    totalEnrollmentCount
    pageViewsInLastMonthCount
  }
`;
export const ProductMetricsSdpFragment = gql`
  fragment ProductMetricsSdpFragment on XdpV1_org_coursera_xdp_sdp_SDPMetadata {
    totalEnrollmentCount
    pageViewsInLastMonthCount
  }
`;

export default ProductMetrics;
