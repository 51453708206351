/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { debounce, noop } from 'lodash';

import { useRetracked } from 'js/lib/retracked';

import { Typography, breakpoints } from '@coursera/cds-core';

import { ShowMoreGridSection, ShowMoreGridSectionPlaceholder } from 'bundles/cds-labs/components/';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import SkillSuggestionCard from 'bundles/program-personalized-tab/components/SkillSuggestionCard';
import { useOnboardingData } from 'bundles/program-personalized-tab/contexts/OnboardingContext';
import type { Skill } from 'bundles/program-personalized-tab/types/sharedTypes';

import _t from 'i18n!nls/program-personalized-tab';

type Props = {
  skills: Skill[];
  selectedSkills: Skill[];
  onSelectedSkillIdsChange: (newSelectedSkillIds: string[]) => void;
};

const MAX_NUM_SELECTABLE_SKILLS = 6;

const styles = {
  cardWrapper: css`
    height: 100%;
  `,
};

const SkillSuggestionsRenderer = ({ skills, selectedSkills, onSelectedSkillIdsChange }: Props) => {
  const track = useRetracked();
  const title = _t('Focus your learning on individual skills');
  const selectedSkillIds = selectedSkills.map((skill) => skill.id);
  const skillsWithoutSelectedSkills = skills.filter((skill) => !selectedSkillIds.includes(skill.id));
  // Hacky way to get the selected skills to show up at the beginning of the list but only on initial render so that
  // ShowMoreGridSection doesn't re-render when selectedSkills changes and collapse itself if expanded
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const orderedSkills = React.useMemo(() => [...selectedSkills, ...skillsWithoutSelectedSkills], []);

  const handleClick = debounce(
    (skillId: string, skillName: string, itemIndex: number) => {
      let newSelectedSkillIds;

      if (selectedSkillIds.includes(skillId)) {
        track({
          trackingName: 'remove_related_skill_suggestion',
          action: 'click',
          trackingData: { skillId, skillName, itemIndex },
        });
        newSelectedSkillIds = selectedSkillIds.filter((existingSkillId) => existingSkillId !== skillId);
      } else {
        track({
          trackingName: 'add_related_skill_suggestion',
          action: 'click',
          trackingData: { skillId, skillName, itemIndex },
        });
        newSelectedSkillIds = [...selectedSkillIds, skillId];
      }

      onSelectedSkillIdsChange(newSelectedSkillIds);
    },
    500,
    { leading: true }
  );

  return (
    <TrackedDiv trackingName="related_skill_suggestions" withVisibilityTracking>
      <ShowMoreGridSection<Skill>
        title={title}
        renderTitle={() => (
          <Typography component="h2" variant="h2semibold">
            {title}
          </Typography>
        )}
        items={orderedSkills}
        spacing={{ sm: 24, xs: 16 }}
        renderItem={({ item, itemIndex }) => {
          const { id, name } = item;
          return (
            <div css={styles.cardWrapper} key={id}>
              {/* TODO: Add loading state when button is clicked until selectedSkills gets updated value */}
              <SkillSuggestionCard
                skillName={name}
                isSelected={selectedSkillIds.includes(id)}
                isDisabled={!selectedSkillIds.includes(id) && selectedSkillIds.length >= MAX_NUM_SELECTABLE_SKILLS}
                onButtonClick={() => handleClick(id, name, itemIndex)}
                onCardClick={() => handleClick(id, name, itemIndex)}
              />
            </div>
          );
        }}
      />
    </TrackedDiv>
  );
};

const SkillSuggestions = () => {
  const track = useRetracked();
  const {
    relatedSkillsQueryLoading,
    relatedSkills,
    skillsLearnerInterestedIn,
    loading: onboardingQueryLoading,
    updateInterestedSkills,
    selectedRoleId,
    skills,
  } = useOnboardingData();

  const handleSelectedSkillsChange = (newSelectedSkillIds: string[]) => {
    updateInterestedSkills({ variables: { input: { ids: newSelectedSkillIds } } });
  };

  if (!relatedSkillsQueryLoading && relatedSkills.length === 0) {
    track({
      trackingName: 'no_related_skills',
      action: 'hide',
      trackingData: { selectedRoleId, occupationSkills: skills },
    });
    return null;
  }

  if (relatedSkillsQueryLoading || onboardingQueryLoading || !skillsLearnerInterestedIn) {
    return (
      <ShowMoreGridSectionPlaceholder
        renderPlaceholderItem={() => (
          <div css={styles.cardWrapper} data-testid="placeholder-card-wrapper">
            <SkillSuggestionCard isLoading skillName="" isSelected={false} onButtonClick={noop} onCardClick={noop} />
          </div>
        )}
      />
    );
  }

  return (
    <SkillSuggestionsRenderer
      skills={relatedSkills}
      selectedSkills={skillsLearnerInterestedIn}
      onSelectedSkillIdsChange={handleSelectedSkillsChange}
    />
  );
};

export default SkillSuggestions;
