/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect } from 'react';

import { announce, clearAnnouncer } from '@react-aria/live-announcer';

import { Typography2 } from '@coursera/cds-core';

import { CoachIconButton } from 'bundles/ai-coach-platform/components/building-blocks';
import { ChevronLeftIcon } from 'bundles/ai-coach-platform/components/icons/mui';

import _t from 'i18n!nls/ai-coach-platform';

const styles = {
  root: css`
    width: 100%;
    height: 100%;
    position: relative;

    .settings-page-header {
      display: flex;
      z-index: 1;
      align-items: center;
      height: 56px;
      border-bottom: 1px solid var(--cds-color-grey-100);

      button {
        margin-left: var(--cds-spacing-150);
      }
    }

    .back-button-container,
    .empty-spacer {
      flex: 1;
    }

    a {
      color: inherit !important;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }

      &:focus {
        outline: 1px solid var(--cds-color-emphasis-quaternary-background-strong);
        outline-offset: 2px;
        border-radius: var(--cds-border-radius-50);
      }
    }
  `,
  content: css`
    padding: var(--cds-spacing-200);
    height: calc(100% - 130px);
    overflow: auto;
  `,
  footer: css`
    height: 48px;
    position: sticky;
    bottom: 56px;
    padding: var(--cds-spacing-200);
  `,
};

type Props = {
  title: string;
  onBack: () => void;
  children: React.ReactNode;
  footer?: React.ReactNode;
};

const SettingsPage = (props: Props): React.ReactElement => {
  const { onBack, title, children, footer } = props;

  useEffect(() => {
    // announces current page title on mount
    clearAnnouncer('assertive');
    announce(title, 'assertive');
  }, [title]);

  return (
    <div className="coach-settings-page-container" css={styles.root}>
      <div className="settings-page-header">
        <div className="back-button-container">
          <CoachIconButton
            onClick={onBack}
            size="small"
            icon={<ChevronLeftIcon size={16} />}
            tooltip={_t('Go back')}
            aria-label={_t('Back to Coach settings')}
          />
        </div>

        <div>
          <Typography2 variant="subtitleMedium" component="h2">
            {title}
          </Typography2>
        </div>

        {/* allows centering title while keeping back button left-aligned */}
        <div className="empty-spacer" />
      </div>

      <div css={styles.content}>{children}</div>

      <div css={styles.footer}>{footer}</div>
    </div>
  );
};

export default SettingsPage;
