import * as React from 'react';

import { CardSection, CardV2, SvgButton } from '@coursera/coursera-ui';
import { SvgShare } from '@coursera/coursera-ui/svg';

import CopyLinkInput from 'bundles/sharing-common/components/modal/CopyLinkInput';
import ShareButtonWithModal from 'bundles/sharing-common/components/modal/ShareButtonWithModal';
import EmailButton from 'bundles/sharing-common/components/social-media-buttons/EmailButton';
import FacebookButton from 'bundles/sharing-common/components/social-media-buttons/FacebookButton';
import LinkedinButton from 'bundles/sharing-common/components/social-media-buttons/LinkedinButton';
import TwitterButton from 'bundles/sharing-common/components/social-media-buttons/TwitterButton';
import WhatsAppButton from 'bundles/sharing-common/components/social-media-buttons/WhatsAppButton';
import type { SocialCaptions } from 'bundles/sharing-common/types/sharingSharedTypes';

import _t from 'i18n!nls/program-home';

import 'css!./__styles__/SocialShareCard';

type Props = {
  shareLink?: string;
  utmMediumParam?: string;
  utmContentParam?: string;
  utmCampaignParam?: string;
  utmProductParam?: string;
  useCustomUrl?: boolean;
  captions?: SocialCaptions;
  title?: string;
  description?: string;
};

const SocialShareCard = ({
  shareLink,
  utmMediumParam,
  utmContentParam,
  utmCampaignParam,
  utmProductParam,
  useCustomUrl,
  captions,
  title,
  description,
}: Props) => {
  return (
    <CardV2 rootClassName="rc-SocialShareCard" showBorder={true}>
      <CardSection rootClassName="main-container">
        <p className="heading bold">{description}</p>
        <div className="social-container-desktop">
          <div className="social-buttons-container">
            <FacebookButton
              shareLink={shareLink}
              utmMediumParam={utmMediumParam}
              utmContentParam={utmContentParam}
              utmCampaignParam={utmCampaignParam}
              utmProductParam={utmProductParam}
              useCustomUrl={useCustomUrl}
              withLabel
            />
            <LinkedinButton
              shareLink={shareLink}
              utmMediumParam={utmMediumParam}
              utmContentParam={utmContentParam}
              utmCampaignParam={utmCampaignParam}
              utmProductParam={utmProductParam}
              useCustomUrl={useCustomUrl}
              withLabel
            />
            <TwitterButton
              shareLink={shareLink}
              utmMediumParam={utmMediumParam}
              utmContentParam={utmContentParam}
              utmCampaignParam={utmCampaignParam}
              utmProductParam={utmProductParam}
              generateCaption={captions?.twitter}
              useCustomUrl={useCustomUrl}
              withLabel
            />
            <WhatsAppButton
              shareLink={shareLink}
              utmMediumParam={utmMediumParam}
              utmContentParam={utmContentParam}
              utmCampaignParam={utmCampaignParam}
              utmProductParam={utmProductParam}
              generateCaption={captions?.whatsapp}
              useCustomUrl={useCustomUrl}
              withLabel
            />
            <EmailButton
              shareLink={shareLink}
              utmMediumParam={utmMediumParam}
              utmContentParam={utmContentParam}
              utmCampaignParam={utmCampaignParam}
              utmProductParam={utmProductParam}
              generateSubject={captions?.emailSubject}
              generateBody={captions?.emailBody}
              useCustomUrl={useCustomUrl}
              withLabel
            />
          </div>
          <CopyLinkInput
            shareLink={shareLink}
            utmMediumParam={utmMediumParam}
            utmContentParam={utmContentParam}
            utmCampaignParam={utmCampaignParam}
            utmProductParam={utmProductParam}
            useCustomUrl={useCustomUrl}
          />
        </div>
        <div className="social-container-mobile">
          <ShareButtonWithModal
            rootClassName="certificate-share-button"
            shareLink={shareLink}
            title={title}
            description={description}
            utmMediumParam={utmMediumParam}
            utmCampaignParam={utmCampaignParam}
            useCustomUrl={useCustomUrl}
            captions={captions}
          >
            <SvgButton
              type="primary"
              label={_t('Share')}
              tag="button"
              size="zero"
              svgElement={
                <SvgShare isThemeDark color="#FFF" hoverColor="#FFF" stroke="#FFF" size={20} strokeWidth={1} />
              }
              style={{
                padding: '7px 8px',
                width: '302px',
              }}
              isThemeDark={false}
            />
          </ShareButtonWithModal>
        </div>
      </CardSection>
    </CardV2>
  );
};

export default SocialShareCard;
