/* eslint-disable no-param-reassign */
import extend from 'lodash/extend';

import ProductType from 'bundles/payments/common/ProductType';
import createCartPromise from 'bundles/payments/promises/createCart';

/**
 * Create a Credential Track Subscription Cart. NOTE: Refer to ./createCart for more information.
 * @param {string} id - The productItemId
 * @param {Object} options - Refer to ./createCart
 * @param {Array} options.auxiliaryCartInfo - Refer to ./createCart
 * @returns {Promise.<Object>} ./createCart response.
 */
export default function (id: string, options = {}) {
  options = extend(
    {},
    {
      productItems: [
        {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'productType' does not exist on type '{}'... Remove this comment to see the full error message
          productType: options.productType || ProductType.CREDENTIAL_TRACK_SUBSCRIPTION,
          productItemId: id,
          productAction: 'Buy',
          cartItemIdToRefund: {},
        },
      ],
    },
    options
  );
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
  return createCartPromise(options);
}
