import * as React from 'react';

import { pure } from 'recompose';

import SvgIcon from '../../SvgIcon';

let NavigationChevronLeft = (props: $TSFixMe) => (
  <SvgIcon {...props}>
    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
  </SvgIcon>
);

// @ts-expect-error ts-migrate(2322) FIXME: Type 'ComponentType<any>' is not assignable to typ... Remove this comment to see the full error message
NavigationChevronLeft = pure(NavigationChevronLeft);
// @ts-expect-error ts-migrate(2339) FIXME: Property 'displayName' does not exist on type '(pr... Remove this comment to see the full error message
NavigationChevronLeft.displayName = 'NavigationChevronLeft';

export default NavigationChevronLeft;
