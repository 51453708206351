import * as React from 'react';

import HoveringToolbar from 'bundles/cml/editor/components/toolbars/HoveringToolbar';
import PagelessToolbar from 'bundles/cml/editor/components/toolbars/PagelessToolbar';
import StickyToolbar from 'bundles/cml/editor/components/toolbars/StickyToolbar';
import type { ToolbarProps } from 'bundles/cml/editor/components/toolbars/types';

type Props = ToolbarProps & {
  scrollingContainer?: HTMLElement | null;
};

const Toolbars: React.FC<Props> = ({ scrollingContainer, ...props }) => {
  const { pageless, customTools } = props;
  if (!customTools.length) {
    return null;
  }

  if (pageless) {
    return (
      <>
        <PagelessToolbar {...props} scrollingContainer={scrollingContainer} />
        <HoveringToolbar {...props} />
      </>
    );
  }

  return <StickyToolbar {...props} />;
};

export default Toolbars;
