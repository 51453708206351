/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { FilterSelections } from 'bundles/program-home/components/single-program/SkillSetFiltersHOCDGS';

import _t from 'i18n!nls/program-home';

const styles = {
  filterResultsStatus: css`
    height: 1px;
    width: 1px;
    position: fixed;
    top: -1;
    left: -1;
    overflow: hidden;
  `,
};

type FilterResultsStatusProps = {
  filteredSkillSetCount: number;
  filterSelections?: FilterSelections;
  /**
   * This state means the results are updating and we should not rerender until we receive the updated count
   */
  loading?: boolean;
};

/**
 * This component is an aria live area hidden off-screen so it is only visible to a screen reader.
 * It allows the SkillSet filter results to be announced by the screen reader after a filter change.
 */
const SkillSetFilterResultsA11yAnnouncer = ({
  filteredSkillSetCount,
  filterSelections,
  loading = false,
}: FilterResultsStatusProps) => {
  /**
   * We want to announce the the new results when the filter selection changes regardless if the results have changed.
   * The alert guide recommends clearing the value and replacing it to trigger the announcement.
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/alert_role#example_2_dynamically_changing_the_content_inside_an_element_with_an_alert_role
   */
  const [shouldRenderMessage, setShouldRenderMessage] = React.useState(true);

  const hasFilterBeenSelectedOnceRef = React.useRef(false);

  const isMountedRef = React.useRef(false);

  React.useEffect(() => {
    if (isMountedRef.current) {
      /**
       * We only want to trigger an announcement when the user changes the filer.
       * We can't just check if a filter is selected because we also want to announce when
       * filters are cleared. So we keep track of the first filter change.
       */
      if (!hasFilterBeenSelectedOnceRef.current) {
        hasFilterBeenSelectedOnceRef.current = true;
      }
      setShouldRenderMessage(false);
    } else if (!isMountedRef.current) {
      isMountedRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterSelections)]);

  React.useEffect(() => {
    if (!shouldRenderMessage) {
      setTimeout(() => {
        setShouldRenderMessage(true);
        // If the timeout is not long enough the clear update would not have rendered
        // https://reactjs.org/docs/hooks-reference.html#batching-of-state-updates
      }, 200);
    }
  }, [shouldRenderMessage]);

  const shouldAnnounce =
    filteredSkillSetCount && hasFilterBeenSelectedOnceRef.current && shouldRenderMessage && !loading;

  return (
    <div role="status" aria-live="polite" css={styles.filterResultsStatus}>
      {shouldAnnounce
        ? _t(`#{numberOfSkillSets} SkillSet filter results found`, {
            numberOfSkillSets: filteredSkillSetCount,
          })
        : ''}
    </div>
  );
};

export default SkillSetFilterResultsA11yAnnouncer;
