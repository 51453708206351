import * as React from 'react';

import ConsoleOutput from 'bundles/code-evaluator/components/ConsoleOutput';
import type { StringOutputContents } from 'bundles/code-evaluator/models/EvaluationResponse';

import _t from 'i18n!nls/code-evaluator';

import 'css!./__styles__/StringOutput';

type Props = {
  output: StringOutputContents;
};

const StringOutput: React.VFC<Props> = (props) => {
  return (
    <div className="rc-StringOutput">
      <ConsoleOutput>
        {props.output.message ? (
          <div className="color-secondary-dark-text">{props.output.message}</div>
        ) : (
          <div className="color-hint-text">{_t('No Output')}</div>
        )}
      </ConsoleOutput>
    </div>
  );
};

export default StringOutput;
