import { css } from '@emotion/react';

import { breakpoints } from '@coursera/cds-common';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('ButtonGroup', ['vertical']);

const getButtonGroupCss = css`
  display: flex;
  gap: var(--cds-spacing-200);

  &.${classes.vertical} {
    flex-direction: column;
  }

  ${breakpoints.down('xs')} {
    flex-direction: column;
  }
`;

export default getButtonGroupCss;
