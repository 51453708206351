import * as React from 'react';

import { FocusContext } from 'bundles/cml/editor/context/focusContext';

type Props = {
  focused: boolean;
  setFocused: (focused: boolean) => void;
};

const FocusContextProvider: React.FC<Props> = ({ focused, setFocused, children }) => {
  return <FocusContext.Provider value={{ focused, setFocused }}>{children}</FocusContext.Provider>;
};

export default FocusContextProvider;
