import gql from 'graphql-tag';

export const S12nMetadataForS12nRecommendations = gql`
  query S12nMetadataForS12nRecommendations($s12nIds: [String!]!) {
    XdpV1Resource {
      multiGet(ids: $s12nIds) {
        elements {
          id
          xdpMetadata {
            __typename
            ... on XdpV1_sdpMetadataMember {
              sdpMetadata {
                id
                skills
                level
                totalContentWeeks
              }
            }
          }
        }
      }
    }
  }
`;
