import * as React from 'react';

import { useQuery } from '@apollo/client';
import { CareerAcademy_Variant as CareerAcademyVariant } from '__generated__/graphql-types';

import { getCmsLocaleString } from 'js/lib/language';
import useRouter from 'js/lib/useRouter';

import CareerAcademyContentfulContext from 'bundles/program-personalized-tab/contexts/CareerAcademyContentfulContext';
import { useOnboardingData } from 'bundles/program-personalized-tab/contexts/OnboardingContext';
import type {
  UnifiedCareerAcademyPageContentQuery,
  UnifiedCareerAcademyPageContentQueryVariables,
} from 'bundles/unified-career-academy/__generated__/contentfulTypes';
import { getEnterpriseContentfulSlug } from 'bundles/unified-career-academy/hocs/withEnterpriseContentfulSlug';
import { unifiedCareerAcademyPageContentQuery } from 'bundles/unified-career-academy/queries/contentfulQueries';

type PropsToComponent = {
  children: React.ReactNode;
};

const CareerAcademyContentfulDataProvider = ({ children }: PropsToComponent) => {
  const router = useRouter();
  const onboardingData = useOnboardingData();
  const hasCompletedOnboarding = Boolean(onboardingData?.hasCompletedOnboarding);
  const isUnsureOfRole = onboardingData?.isUnsureOfRole;
  const previewParam = router.location.query.preview;
  const isPreview = Number(previewParam) === 1;

  const contentfulSlug = getEnterpriseContentfulSlug(CareerAcademyVariant.Default);

  const { data: careerAcademyData, loading: careerAcademyDataLoading } = useQuery<
    UnifiedCareerAcademyPageContentQuery,
    UnifiedCareerAcademyPageContentQueryVariables
  >(unifiedCareerAcademyPageContentQuery, {
    variables: {
      slug: contentfulSlug,
      locale: getCmsLocaleString(),
      preview: isPreview,
    },
    skip: hasCompletedOnboarding && !isUnsureOfRole,
    context: { clientName: isPreview ? 'contentfulPreviewGql' : 'contentfulGql' },
  });

  return (
    <CareerAcademyContentfulContext.Provider
      value={{
        careerAcademyData: careerAcademyData?.unifiedCareerAcademyPageContentCollection?.items?.[0],
        careerAcademyDataLoading,
      }}
    >
      {children}
    </CareerAcademyContentfulContext.Provider>
  );
};

export default CareerAcademyContentfulDataProvider;
