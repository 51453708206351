import * as React from 'react';

import type { EnterpriseSkills_Skillset as SkillSet } from '__generated__/graphql-types';

import a11yKeyPress from 'js/lib/a11yKeyPress';
import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { Link, Typography, Typography2 } from '@coursera/cds-core';
import type { LinkProps } from '@coursera/cds-core';
import { ArrowNextIcon, CheckIcon } from '@coursera/cds-icons';
import { TextTruncate, css, placeholder } from '@coursera/coursera-ui';
import type { SectionName } from '@coursera/event-pulse-types';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import { redirectClientOrServer } from 'bundles/common/utils/urlUtils';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { useTracker, useVisibilityTracker } from 'bundles/page/lib/event-pulse/react';
import { toProgramSkillSet } from 'bundles/program-common/links';
import type { ObjectiveListQuery_EnterpriseTargetSkillProfileSummariesV1Resource_byProgram_elements as TargetSkillProficiencySummary } from 'bundles/program-home/components/multiprogram/__generated__/ObjectiveListQuery';
import { GoldStarSvg } from 'bundles/skills-common';

import _t from 'i18n!nls/program-home';

import 'css!./__styles__/SkillSetCardV2';

type SkillSetCardContentProps = {
  skillSummary: TargetSkillProficiencySummary | SkillSet;
  programSlug: string;
  skillSetOrder: number;
  filterTrackingData?: string[];
};

const TrackedLink = withSingleTracked({ type: 'BUTTON' })<LinkProps>(Link);

const getIsTSP = (
  skillSummary: TargetSkillProficiencySummary | SkillSet
): skillSummary is TargetSkillProficiencySummary => {
  return (skillSummary as TargetSkillProficiencySummary).targetSkillProficiencies !== undefined;
};

/**
 * This component displays the CDS Link but makes it non interactive from a keyboard or screenreader
 * Click events bubble up to the card container which is interactive
 * Full context here: https://coursera.atlassian.net/browse/ACCESS-2497?focusedCommentId=586399
 */
const NonInteractiveLink = (props: LinkProps) => (
  <>
    {/* not all link props are compatible with div. Specifically interactive props. But we disable events for this div anyway */}
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-expect-error */}
    <div {...props} role="presentation" tabIndex={-1} aria-hidden>
      {props.children}
    </div>
  </>
);

/**
 * Note on tracking:
 * Only the container is clickable or focusable in this component to avoid an A11y error for nested interactive elements.
 * However we still want to track clicks to to the "fake" link. The skillset card will also register a click
 * in these instances but we can also subtract the subclass click count from the card click count to get the accurate count.
 */
export const SkillSetCardV2 = ({
  skillSummary,
  programSlug,
  skillSetOrder,
  filterTrackingData,
}: SkillSetCardContentProps) => {
  const isTSP = getIsTSP(skillSummary);
  const track = useTracker();

  const href = toProgramSkillSet(programSlug, skillSummary.slug);
  const { id } = skillSummary;
  const order = skillSetOrder > 0 ? skillSetOrder - 1 : skillSetOrder;

  const eventingV3SkillSetData = {
    skillsetCard: {
      index: order,
    },
    pageSection: {
      sectionName: 'cta_banner' as SectionName,
      index: 3,
    },
    ...(filterTrackingData && {
      filter: filterTrackingData,
    }),
    skillset: {
      id,
    },
  };
  const onSkillSetContainerClick = React.useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      redirectClientOrServer(href);

      track('click_skillset_card', eventingV3SkillSetData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [href]
  );

  const skillsetCardRef: React.MutableRefObject<HTMLDivElement | null> = useVisibilityTracker(
    'view_skillset_card',
    eventingV3SkillSetData
  );
  return (
    <div ref={skillsetCardRef}>
      <TrackedDiv
        data-testId="skillset-card-container"
        className="skillset-container"
        role="link"
        trackingName="skillset_container"
        trackClicks
        withVisibilityTracking
        data={{
          skillSetOrder,
          skillSetId: skillSummary.id,
          objectId: `skillset~${programSlug}/${skillSummary.slug}`,
          skillsetSlug: skillSummary.slug,
          programSlug,
          tab: 'catalog-tab',
        }}
        data-e2e="program-home-catalog-skillset-container"
        onClick={onSkillSetContainerClick}
        onKeyPress={(event) => a11yKeyPress(event, onSkillSetContainerClick)}
      >
        <div className="header-container">
          <div className="header-title">
            <GoldStarSvg width={31} height={36} />
            <Typography2
              display="inline"
              className="skillset-header"
              component="div"
              variant="subtitleMedium"
              color="invertBody"
            >
              {_t('SKILLSET')}
            </Typography2>
          </div>
          <Typography className="skillset-name" variant="h2semibold" component="div" color="invertBody">
            <TextTruncate line={2} text={isTSP ? skillSummary.title : skillSummary.name} />
          </Typography>
        </div>
        <div className="details-container">
          <Typography2 variant="subtitleMedium" className="gain-message" component="div">
            <FormattedMessage
              message={_t('Gain {numSkills, plural, =1 {# skill} other {# skills}}:')}
              numSkills={
                isTSP
                  ? skillSummary.targetSkillProficiencies.length
                  : skillSummary.skillProficiencyTargets?.totalCount ?? 0
              }
            />
          </Typography2>
          <ul className="skills-list">
            {isTSP
              ? skillSummary.targetSkillProficiencies.slice(0, 4).map((skill, index) => {
                  return (
                    <li key={skill.skillId} className="list-item-container">
                      <CheckIcon className="list-bullet" size="small" />
                      <Typography2 className="list-item" component="div">
                        <span className="item-text">{skill.skillName}</span>
                        {index === 3 && skillSummary.targetSkillProficiencies.length > 4 && (
                          <span className="more-trailer">
                            <FormattedMessage
                              message={_t(' + {numSkills} more')}
                              numSkills={skillSummary.targetSkillProficiencies.length - 4}
                            />
                          </span>
                        )}
                      </Typography2>
                    </li>
                  );
                })
              : skillSummary.skillProficiencyTargets?.edges
                  ?.map((_) => _.node)
                  .slice(0, 4)
                  .map((skill, index) => {
                    return (
                      <li key={skill.id} className="list-item-container">
                        <CheckIcon className="list-bullet" size="small" />
                        <Typography2 className="list-item" component="div">
                          <span className="item-text">{skill.skillName}</span>
                          {index === 3 && (skillSummary.skillProficiencyTargets?.totalCount ?? 0) > 4 && (
                            <span className="more-trailer">
                              <FormattedMessage
                                message={_t(' + {numSkills} more')}
                                numSkills={(skillSummary.skillProficiencyTargets?.totalCount ?? 0) - 4}
                              />
                            </span>
                          )}
                        </Typography2>
                      </li>
                    );
                  })}
          </ul>
          <TrackedLink
            className="link-section"
            variant="quiet"
            typographyVariant="subtitleMedium"
            component={NonInteractiveLink}
            trackingName="skillset_link"
            trackingData={{
              skillSetOrder,
              skillSetId: skillSummary.id,
              skillsetSlug: skillSummary.slug,
              programSlug,
              objectId: `skillset~${programSlug}/${skillSummary.slug}`,
              tab: 'catalog-tab',
            }}
            icon={<ArrowNextIcon size="medium" />}
            iconPosition="after"
          >
            {_t('View SkillSet details')}
          </TrackedLink>
        </div>
      </TrackedDiv>
    </div>
  );
};

export const SkillSetCardV2Placeholder = () => {
  return (
    <div className="skillset-container" aria-hidden data-testId="SkillSetCardV2Placeholder">
      <div className="header-container" aria-hidden>
        <div className="header-title" aria-hidden>
          <GoldStarSvg width={31} height={36} />
          <Typography2
            display="inline"
            className="skillset-header"
            component="div"
            variant="subtitleMedium"
            color="invertBody"
          >
            {_t('SKILLSET')}
          </Typography2>
        </div>
        <Typography
          className={css(placeholder.styles.shimmer, 'skillset-name').className}
          variant="h2semibold"
          component="div"
          color="invertBody"
          aria-hidden
        >
          &nbsp;
        </Typography>
      </div>
      <div className="details-container" aria-hidden>
        <div className={css(placeholder.styles.shimmer, 'gain-message').className}>&nbsp;</div>
        <ul className="skills-list" aria-hidden>
          {[1, 2, 3].map((value) => {
            return (
              <li key={value} className="list-item-container">
                <CheckIcon className="list-bullet" size="small" />
                <Typography2
                  className={css(placeholder.styles.shimmer, 'list-item').className}
                  component="div"
                  aria-hidden
                >
                  <span className="item-text">&nbsp;</span>
                </Typography2>
              </li>
            );
          })}
        </ul>
        <Typography2
          component="h3"
          className={css(placeholder.styles.shimmer, 'link-section').className}
          variant="subtitleMedium"
        >
          &nbsp;
        </Typography2>
      </div>
    </div>
  );
};
