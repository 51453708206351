import * as React from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import usePageData from 'bundles/enroll/data/usePageData';
import ReactPriceDisplay from 'bundles/payments-common/components/ReactPriceDisplay';
import PaymentChoice from 'bundles/s12n-enroll/components/bulk-pay/PaymentChoice';
import type { PaymentChoiceType } from 'bundles/s12n-enroll/constants';

import _t from 'i18n!nls/s12n-enroll';

type PropsFromCaller = {
  courseId: string;
  currentType: PaymentChoiceType;
  onClick: (newType: PaymentChoiceType) => void;
  onSdp?: boolean;
};

const PaymentChoiceSingle: React.FC<PropsFromCaller> = ({ currentType, onClick, onSdp }) => {
  const { productPrice, course, enrollmentAvailableChoices } = usePageData();

  if (!productPrice) {
    return null;
  }

  const getTitle = () => {
    let message;
    if (enrollmentAvailableChoices.isEnrolled) {
      if (onSdp) {
        message = _t('Buy Next Course');
      } else {
        message = _t('Buy This Course');
      }
    } else if (onSdp) {
      message = _t('Pay by Course');
    } else {
      message = _t('Purchase Course');
    }
    return message + ' • {price}';
  };

  const title = (
    <FormattedMessage
      message={getTitle()}
      price={<ReactPriceDisplay value={productPrice.amount} currency={productPrice.currencyCode} />}
    />
  );

  const message = onSdp
    ? _t('Individual payments, paid before you start each course, beginning with {courseName}.')
    : _t(
        `Pay for this course only, and get a shareable certificate after you've completed the course.
          You can upgrade to the full Specialization later.`
      );

  return (
    <PaymentChoice type="single" title={title} currentType={currentType} onClick={onClick}>
      <FormattedMessage message={message} courseName={course.name} />
    </PaymentChoice>
  );
};

export default PaymentChoiceSingle;
