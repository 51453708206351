import { hashToHex } from 'js/lib/hash';

import localStorageEx from 'bundles/common/utils/localStorageEx';

type HashDictionary<T> = {
  [hash: string]: T | undefined;
};

type StorageUtilsGet = {
  <T>(userId: number, objectId: string, key: string): T | undefined;
  <T>(userId: number, objectId: string, key: string, valueIfNotFound: T): T;
};

/**
 * Gets an item from localStorage.
 *
 * @template T Value type.
 * @param userId userId to store for.
 * @param objectId objectId to store for, e.g. programId.
 * @param key Key to store at.
 * @param valueIfNotFound Value to return if no item exists.
 * @returns Stored value or the appropriate default value.
 */
const get: StorageUtilsGet = <T>(userId: number, objectId: string, key: string, valueIfNotFound?: T): T | undefined => {
  const hash = hashToHex(`${userId}-${objectId}`);
  const dict = localStorageEx.getItem<HashDictionary<T> | undefined>(key, JSON.parse, undefined);
  const value = dict?.[hash];

  return value !== undefined ? value : valueIfNotFound;
};

/**
 * Stores an item to localStorage.
 *
 * @template T Value type.
 * @param userId userId to store for.
 * @param objectId objectId to store for, e.g. programId.
 * @param key Key to store at.
 * @param value Value to store.
 * @returns void
 */
const set = <T>(userId: number, objectId: string, key: string, value: T) => {
  const hash = hashToHex(`${userId}-${objectId}`);
  const dict = localStorageEx.getItem<HashDictionary<T>>(key, JSON.parse, {});

  dict[hash] = value;
  localStorageEx.setItem(key, dict, JSON.stringify);
};

/**
 * Removes an item from localStorage.
 *
 * @param userId userId to remove at.
 * @param objectId objectId to remove at, e.g. programId.
 * @param key Key to remove at.
 * @returns void
 */
const remove = (userId: number, objectId: string, key: string) => {
  const hash = hashToHex(`${userId}-${objectId}`);
  const dict = localStorageEx.getItem(key, JSON.parse, undefined);

  if (!dict) {
    return;
  }
  dict[hash] = undefined;
  localStorageEx.setItem(key, dict, JSON.stringify);
};

export default { get, set, remove };
