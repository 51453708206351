import * as React from 'react';
import { useCallback } from 'react';

import { useSlate, useSlateStatic } from 'slate-react';

import { ParagraphIndentMoreIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import { canIncreaseIndent, increaseIndent } from 'bundles/cml/editor/components/buttons/indent/listIndentUtils';

import _t from 'i18n!nls/cml';

const IncreaseIndentButton: React.FC = () => {
  const editor = useSlate();
  const staticEditor = useSlateStatic();
  const disabled = !canIncreaseIndent(editor);

  const handleChange = useCallback(() => increaseIndent(staticEditor), [staticEditor]);

  const shortcutKey = `(tab)`;
  return (
    <Button
      onClick={handleChange}
      disabled={disabled}
      active={false}
      className="rc-IncreaseIndentButton"
      title={_t('Increase indent #{shortcutKey}', { shortcutKey })}
      type={TOOLBAR_BUTTON_TYPES.formatting}
    >
      <ParagraphIndentMoreIcon size="small" />
    </Button>
  );
};

export default IncreaseIndentButton;
