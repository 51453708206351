/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { useMutation } from '@apollo/client';

import logger from 'js/app/loggerSingleton';

import { ProductCard, Typography, breakpoints } from '@coursera/cds-core';

import { FilterableShowMoreGridSection, FilterableShowMoreGridSectionPlaceholder } from 'bundles/cds-labs/components/';
import { TrackedCdsButton } from 'bundles/common/components/withSingleTracked';
import { filterUndefined } from 'bundles/common/utils/commonUtils';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import UpdateLearnerRoleMutation from 'bundles/program-personalized-tab/components/api/UpdateLearnerRole.graphql';
import type {
  LearnerOnboarding_UpdateRoleMutation as LearnerOnboardingUpdateRoleMutationData,
  LearnerOnboarding_UpdateRoleMutationVariables as LearnerOnboardingUpdateRoleMutationVariables,
} from 'bundles/program-personalized-tab/components/api/__generated__/UpdateLearnerRole';
import { RoleCardModule } from 'bundles/program-personalized-tab/components/modules/RoleCardModule';
import type { Props as RoleCardModuleProps } from 'bundles/program-personalized-tab/components/modules/RoleCardModule';
import { RoleInfoDialogModule } from 'bundles/program-personalized-tab/components/modules/RoleInfoDialogModule';
import { useCareerAcademyContentfulData } from 'bundles/program-personalized-tab/contexts/CareerAcademyContentfulContext';
import { useCareerAcademyData } from 'bundles/program-personalized-tab/contexts/CareerAcademyContext';

import _t from 'i18n!nls/program-personalized-tab';

export const styles = {
  cardWrapper: css`
    height: 100%;
    padding: var(--cds-spacing-150);
    ${breakpoints.down('xs')} {
      padding: var(--cds-spacing-100) 0;
    }
  `,
};

type RoleRecs = {
  id: string;
  image?: { url?: string | null } | null;
} & Omit<RoleCardModuleProps, 'imgUrl'>;

export const RoleRecsCollection: React.FC = () => {
  const [selectedFilterId, setSelectedFilterId] = React.useState<string>('ALL_CAREERS');
  const [selectedRoleSlug, setSelectedRoleSlug] = React.useState<string | null>('');

  const [updateLearnerRoleMutation] = useMutation<
    LearnerOnboardingUpdateRoleMutationData,
    LearnerOnboardingUpdateRoleMutationVariables
  >(UpdateLearnerRoleMutation, { context: { clientName: 'gatewayGql' } });

  const careerAcademyContentfulData = useCareerAcademyContentfulData();
  const careerAcademyData = useCareerAcademyData();
  const careerAcademyCategories = careerAcademyData?.careerAcademyCategories;
  const careerAcademyRoles = careerAcademyData?.careerAcademyRoles;
  const careerAcademyCategoriesLoading = careerAcademyData?.careerAcademyCategoriesLoading;
  const careerAcademyContentfulDataLoading = careerAcademyContentfulData?.careerAcademyDataLoading;

  const roleList = React.useMemo(
    () => careerAcademyContentfulData?.careerAcademyData?.careersCardsCollection?.items ?? [],
    [careerAcademyContentfulData?.careerAcademyData?.careersCardsCollection?.items]
  );
  const selectedRoleData = roleList.find((roleData) => roleData?.slug === selectedRoleSlug);

  const roleCategories = React.useMemo(
    () => [
      { id: 'ALL_CAREERS', label: _t('All Careers (#{numRoles})', { numRoles: careerAcademyRoles?.length }) },
      ...(careerAcademyCategories ?? []).map((category) => ({
        id: category.name,
        label: `${category.name} (${category.roles.length})`,
      })),
    ],
    [careerAcademyCategories, careerAcademyRoles?.length]
  );

  const onFilterClick = (nextFilter?: string) => {
    if (nextFilter) setSelectedFilterId(nextFilter);
  };

  const onRoleCardClick = (roleSlug: string) => {
    setSelectedRoleSlug(roleSlug);
  };

  const onSetFocusedRoleClick = async (occupationId: string) => {
    try {
      await updateLearnerRoleMutation({
        variables: {
          occupationId,
        },
      });
    } catch (err) {
      logger.error(err);
    }
    setSelectedRoleSlug(null);
  };

  const isAllCareers = selectedFilterId === 'ALL_CAREERS';
  const title = _t('Begin a new career in an industry-leading role');
  const filteredRoleList = roleList.filter(filterUndefined).map((role) => ({ id: role.slug || '', ...role }));
  const items = isAllCareers
    ? filteredRoleList
    : filteredRoleList.filter((roleCardData) => roleCardData?.jobCategory === selectedFilterId);

  if (careerAcademyContentfulDataLoading || careerAcademyCategoriesLoading)
    return (
      <FilterableShowMoreGridSectionPlaceholder
        renderPlaceholderItem={() => (
          <div css={styles.cardWrapper} data-testid="placeholder-card-wrapper">
            {' '}
            <ProductCard variant="grid" productType="course" loading />{' '}
          </div>
        )}
      />
    );

  return (
    <TrackedDiv trackingName="role_recs" withVisibilityTracking data-testid="role-recs-section">
      <FilterableShowMoreGridSection<RoleRecs>
        title={title}
        renderTitle={() => (
          <Typography component="h2" variant="h2semibold">
            {title}
          </Typography>
        )}
        filters={roleCategories}
        selectedFilterId={selectedFilterId}
        onFilterSelect={onFilterClick}
        items={items}
        renderItem={({ item }) => {
          return (
            <div css={styles.cardWrapper} key={item?.slug}>
              <RoleCardModule
                title={item?.title}
                skills={item?.skills}
                description={item?.description}
                roleSlug={item?.slug}
                imgUrl={item?.image?.url}
                onClick={onRoleCardClick}
              />
            </div>
          );
        }}
        renderExpandButton={({ onClick, defaultLabel, isExpanded, showMoreCount, ...rest }) => {
          return (
            <TrackedCdsButton
              trackingName="show_more_role_recs"
              trackingData={{
                title,
                isExpanded,
                showMoreCount,
              }}
              size="small"
              variant="secondary"
              onClick={onClick}
              {...rest}
            >
              {defaultLabel}
            </TrackedCdsButton>
          );
        }}
      />
      <RoleInfoDialogModule
        isOpen={Boolean(selectedRoleSlug)}
        onClose={() => setSelectedRoleSlug(null)}
        title={selectedRoleData?.title}
        description={selectedRoleData?.description}
        skills={selectedRoleData?.skills}
        roleSlug={selectedRoleData?.slug}
        onSetFocusedRoleClick={onSetFocusedRoleClick}
      />
    </TrackedDiv>
  );
};
