/** @deprecated */
export const ALGOLIA_ENTERPRISE_INDEX = 'prod_enterprise_products';
/** @deprecated */
export const ALGOLIA_ENTERPRISE_SHORT_FORM_CONTENT_INDEX = 'prod_enterprise_products_with_sfc';

export const SEARCH_RENDER_DELAY = 1200; // 1200 milliseconds
export const NUMBER_OF_RESULTS_PER_PAGE = 12;

export const ENTERPRISE_AUTOCOMPLETE_HITS_PER_PAGE = 7;
export const ENTERPRISE_AUTOCOMPLETE_HITS_PER_PAGE_CLIPS_EXPERIMENT = 40;
export const ENTERPRISE_AUTOCOMPLETE_HITS_PER_PAGE_CLIPS = 4;

export const SEARCH_FILTERS = [
  'productDuration',
  'productTypeDescription',
  'topic',
  'skills',
  'productDifficultyLevel',
  'partners',
  'language',
  'subtitleLanguage',
];
export const DOMAIN_PAGE_SEARCH_FILTERS = [
  'productDuration',
  'productTypeDescription',
  'skills',
  'productDifficultyLevel',
  'partners',
  'language',
  'subtitleLanguage',
]; // only difference is without topic

export const SHORT_FORM_CONTENT_VIDEO_ENTITY_TYPE_DESCRIPTION = 'Short Form Content Video';
export const SHORT_FORM_CONTENT_LESSON_ENTITY_TYPE_DESCRIPTION = 'Short Form Content Lesson';

export const SHORT_FORM_CONTENT_VIDEO_ENTITY_TYPE = 'VIDEO';
export const SHORT_FORM_CONTENT_LESSON_ENTITY_TYPE = 'LESSON';

export const SFC_FILTER_LABELS = [
  'Under 60 Minutes',
  SHORT_FORM_CONTENT_LESSON_ENTITY_TYPE_DESCRIPTION,
  SHORT_FORM_CONTENT_VIDEO_ENTITY_TYPE_DESCRIPTION,
];

// Note: This should be an exhaustive list of all non-clip product types.
// If new product types are introduced in the enterprise index, please add it here as well.
export const ENTERPRISE_AUTOCOMPLETE_NON_CLIP_FACETFILTER_VALUES = [
  [
    'productTypeDescription:Specializations',
    'productTypeDescription:Professional Certificates',
    'productTypeDescription:Guided Projects',
    'productTypeDescription:Projects',
    'productTypeDescription:Courses',
  ],
];

export const ENTERPRISE_AUTOCOMPLETE_CLIPS_FACETFILTER_VALUES = [
  ['productTypeDescription:Short Form Content Video', 'productTypeDescription:Short Form Content Lesson'],
];
