import * as React from 'react';

/* [fe-tech-debt] Temporary until the TeX icon can be added to CDS */
const LatexIcon: React.FC<{}> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1028 590" width="28" height="28">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M364.09 23.89H14.82L4.61 157.66h10.87c7.82-100.19 16.41-117.73 110.21-117.73 10.87 0 28.53 0 33.39 0 11.55 1.81 11.55 9.16 11.55 23.08V392.27c0 21.86-1.83 28.53-52.78 28.53h-17.17v14.61c29.02-.56 59.26-1.15 88.93-1.15 29.58 0 59.91 .59 88.91 1.15v-14.61h-16.88c-50.19 0-52-6.67-52-28.53V63.02c0-13.36 0-20.61 10.89-23.08h33.19c92.55 0 101.61 17.44 109.42 117.73h10.89"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M667.6 414.32H656.73C643.47 504.01 635.65 552.76 529.26 552.76h-83.95c-24.16 0-25.3-3.05-25.3-23.86V359.83h57.06c57.06 0 62.5 20.91 62.5 71.96h9.72V274.44h-9.72c0 50.19-5.45 70.71-62.5 70.71H420V195.25c0-20.42 1.15-23.47 25.3-23.47h82.7c93.6 0 104.49 37.2 112.97 117.17H651.87L637.36 155.17H331.1v16.61c42.84 0 49.7 0 49.7 27.19v326.42c0 27.19-6.77 27.19-49.7 27.19v14.61h314.76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M830.66 206.51 920.15 77.13c8.96-12.7 27.19-38.84 76.82-39.5V23.03c-13.85 1.15-36.83 1.15-51.34 1.15-19.93 0-44.75 0-59.82-1.15v14.61c19.37 1.81 24.13 13.92 24.13 23.67 0 7.23-2.96 12.11-7.25 18.12L822.84 195.25 733.35 64.26c-4.2-6.59-4.79-8.5-4.79-10.31 0-5.45 6.59-15.75 26.62-16.32V23.03c-19.37 1.15-48.95 1.15-68.88 1.15-15.66 0-45.89 0-60.5-1.15v14.61c33.22 0 44.09 1.25 57.45 20.03l116.68 171.37-105.22 153.71c-25.96 37.59-65.26 38.25-76.82 38.25v14.61c13.82-1.15 36.83-1.15 51.34-1.15 16.32 0 44.75 0 59.82 1.15v-14.61c-18.71-1.81-24.13-13.92-24.13-23.67 0-7.82 2.96-12.11 6.01-16.41l96.75-141.2 105.22 154.66c4.76 6.69 4.76 8.5 4.76 10.31 0 4.76-5.42 15.17-26.6 16.41v14.61c19.37-1.15 48.95-1.15 68.88-1.15 15.66 0 45.89 0 60.57 1.15v-14.61c-38.74 0-44.84-2.96-56.86-20.03"
      />
    </svg>
  );
};

export default LatexIcon;
