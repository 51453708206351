import { Element, Text } from 'slate';
import type { Descendant } from 'slate';

import { deserializeElements } from 'bundles/cml/editor/html/deserializeElements';
import { deserializeMarks } from 'bundles/cml/editor/html/deserializeMarks';
import { deserializeText } from 'bundles/cml/editor/html/deserializeText';
import type { Options } from 'bundles/cml/editor/html/types';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { Marks, TextElement } from 'bundles/cml/shared/types/elementTypes';

export const deserializeHtml = (
  el: HTMLElement,
  images: DataTransferItem[],
  options: Options,
  prevMarks: Marks = {},
  level = 0
): null | Descendant | Descendant[] => {
  if (el.nodeType === Node.TEXT_NODE) {
    return deserializeText(el, options, prevMarks);
  }

  const { nodeName } = el;
  let parent = el;

  if (nodeName === 'PRE' && el.childNodes[0] && el.childNodes[0].nodeName === 'CODE') {
    parent = el.childNodes[0] as HTMLElement;
  }

  const marks = { ...prevMarks, ...deserializeMarks(el, options) };
  let children = (Array.from(parent.childNodes) as HTMLElement[])
    .map((child) => deserializeHtml(child, images, options, marks, level + 1))
    .flat()
    .filter((child) => !!child) as Descendant[];

  if (level === 0 && children.every((child) => Text.isText(child) || (Element.isElement(child) && child.isInline))) {
    // wrap top level text/inline nodes in a Text element to conform with our layout rules
    children = [
      {
        type: BLOCK_TYPES.TEXT,
        children,
      } as TextElement,
    ];
  }

  const element = deserializeElements(el, images, options, children);
  if (element != null) {
    return element;
  }

  return children;
};
