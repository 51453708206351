import keysToConstants from 'js/lib/keysToConstants';

export default keysToConstants([
  'ELIGIBLE',
  'PROMOTION_INACTIVE',
  'TOTAL_REDEMPTION_LIMIT_REACHED',
  'PRODUCT_NOT_ELIGIBLE',
  'USER_REDEMPTION_LIMIT_REACHED',
  'NOT_ELIGIBLE_FOR_NEW_PAID_LEARNER_PROMOTION',
  // This is a hardcoded error when the promotion cannot be applied but not errorCode back from the BE
  'PROMOTION_CANNOT_BE_APPLIED',
]);
