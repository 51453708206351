import * as React from 'react';

import IENotificationForGuidedProject from 'bundles/common/components/IENotificationForGuidedProject';
import CourseReviewPaginatedOverview from 'bundles/coursera-ui/components/rich/MiniXDP/CourseReviewPaginatedOverview';
import AboutProjectWrapper from 'bundles/enterprise-legacy-xdp/components/miniPDP/AboutProjectWrapper';
import Banner from 'bundles/enterprise-legacy-xdp/components/miniPDP/Banner';
import InstructorListWrapper from 'bundles/enterprise-legacy-xdp/components/miniPDP/InstructorListWrapper';
import PdpSection from 'bundles/enterprise-legacy-xdp/components/miniPDP/PdpSection';
import TaskList from 'bundles/enterprise-legacy-xdp/components/miniPDP/TaskList';
import type {
  PdpPageQueryById_XdpV1Resource_get_xdpMetadata_XdpV1_cdpMetadataMember_cdpMetadata as CDPMetadata,
  PdpPageQueryById_XdpV1Resource_get_xdpMetadata_XdpV1_cdpMetadataMember_cdpMetadata_ratings as GeneratedProjectRatingsType,
  PdpPageQueryById_XdpV1Resource_get_xdpMetadata_XdpV1_cdpMetadataMember_cdpMetadata_courseTypeMetadata_XdpV1_rhymeProjectMember_rhymeProject as RhymeProjectMetadata,
} from 'bundles/enterprise-legacy-xdp/components/miniPDP/__generated__/PdpPageQueryById';
import MiniPdpPageDataProvider from 'bundles/enterprise-legacy-xdp/components/miniPDP/data/MiniPdpPageDataProvider';
import type { MiniPdpPageDataContext } from 'bundles/enterprise-legacy-xdp/components/miniPDP/data/PageDataContext';
import usePageData from 'bundles/enterprise-legacy-xdp/components/miniPDP/data/usePageData';
import PartnerList from 'bundles/enterprise-legacy-xdp/components/partners/PartnerList';
import type { LearnerIncentive as LearnerIncentiveType } from 'bundles/learner-incentives/types';
import type { EnterpriseProductConfiguration, Partner, RatingsObject } from 'bundles/program-common/types/xdpTypes';
import { isGuidedProject } from 'bundles/program-common/utils/courseTypeMetadataUtils';

type Props = {
  id: string;
  actionElement: JSX.Element;
  privateSessionDates?: { startsAt: number; endsAt: number };
  enableCurriculumBuilder?: boolean;
  isEnterpriseAdminView?: boolean;
  enterpriseProductConfiguration?: EnterpriseProductConfiguration;
};

const toRatingsObject = (ratings: GeneratedProjectRatingsType): RatingsObject => ({
  averageFiveStarRating: ratings.averageFiveStarRating || undefined,
  ratingCount: ratings.ratingCount || undefined,
  commentCount: ratings.commentCount || undefined,
});

function getRhymeProjectMetadata(data: CDPMetadata): RhymeProjectMetadata | undefined {
  const { courseTypeMetadata } = data;
  return isGuidedProject(courseTypeMetadata) ? courseTypeMetadata.rhymeProject : undefined;
}

const MiniPDP = ({
  actionElement,
  privateSessionDates,
  enableCurriculumBuilder,
  isEnterpriseAdminView,
  enterpriseProductConfiguration,
}: Props) => {
  const {
    data,
    pageViewController: { courseType },
    userAgent,
  } = usePageData() as MiniPdpPageDataContext;
  const rhymeProject = getRhymeProjectMetadata(data);

  const partners: Array<Partner> = data.partners.map((gqlPartner) => {
    return { ...gqlPartner, partnerMarketingBlurb: null, landingPageBanner: null };
  });

  return (
    <div className="rc-MiniPdp">
      <Banner
        name={data.name}
        ratings={data.ratings}
        partners={data.partners}
        projectId={data.projectId}
        level={data.level}
        workload={data.workload}
        subtitleLanguages={data.subtitleLanguages}
        primaryLanguages={data.primaryLanguages}
        avgLearningHoursAdjusted={data.avgLearningHoursAdjusted}
        cmlRecommendedBackground={data.cmlRecommendedBackground}
        cmlLearningObjectives={data.cmlLearningObjectives}
        actionElement={actionElement}
        shouldShowCourseraPlusRibbon={false}
        isEnterpriseAdminView={isEnterpriseAdminView}
        enterpriseProductConfiguration={enableCurriculumBuilder ? enterpriseProductConfiguration : undefined}
        shouldShowInstructorInfoBox={courseType === 'Project'}
      />
      <IENotificationForGuidedProject isProject={true} />
      <AboutProjectWrapper
        description={data.description}
        skills={data.skills}
        learnerIncentives={data.partnerIncentives as Array<LearnerIncentiveType>}
        partners={partners}
        privateSessionDates={enableCurriculumBuilder ? privateSessionDates : undefined}
        enterpriseProductConfiguration={enableCurriculumBuilder ? enterpriseProductConfiguration : undefined}
      />
      <TaskList
        tasks={rhymeProject?.tasks ?? []}
        screenshotUrls={rhymeProject?.screenshotUrls ?? []}
        canExpand={false}
      />
      {/* TODO fix ProjectsHowItWorks: https://coursera.atlassian.net/browse/HP-781  */}
      {/* <ProjectsHowItWorks /> */}
      {courseType === 'Project' && (
        <PdpSection>
          <PartnerList partners={partners} userAgent={userAgent} />
        </PdpSection>
      )}
      {data.ratings && data.ratings.ratingCount && (
        <div className="bg-white p-t-3 p-b-2 p-x-3">
          <CourseReviewPaginatedOverview
            id={data.projectId}
            topReviews={data.topFourAndFiveStarReviews}
            ratings={toRatingsObject(data.ratings)}
            reviewHighlights={data.reviewHighlights}
            isProject
          />
        </div>
      )}
      <InstructorListWrapper
        id={data.projectId}
        instructors={data.instructors}
        averageInstructorRating={data.averageInstructorRating}
        instructorRatingCount={data.instructorRatingCount}
        noLink={true}
        courseType={courseType}
      />
    </div>
  );
};

const Wrapper = (props: Props) => {
  return (
    <MiniPdpPageDataProvider id={props.id}>
      <MiniPDP {...props} />
    </MiniPdpPageDataProvider>
  );
};

export default Wrapper;

export const BaseComponent = MiniPDP;
