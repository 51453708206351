/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useEffect, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';

import { useFloating } from '@floating-ui/react';
import { autoUpdate, flip, hide, limitShift, offset, shift } from '@floating-ui/react-dom';
import type { Placement, ShiftOptions, VirtualElement } from '@floating-ui/react-dom';

import { MuiClickAwayListener, MuiFade } from '@coursera/cds-core';

import { zIndex } from 'bundles/authoring/style-constants/layout';
import { hoverMenu } from 'bundles/cml/shared/styles';

export const PAGELESS_TOOLBAR_HEIGHT = 53 + 72;

const styles = {
  container: css`
    ${hoverMenu()}
    z-index: ${zIndex.modal};
  `,
};

type Props = {
  anchorEl: HTMLElement | VirtualElement | null;
  pageless: boolean;
  placement?: Placement;
  onClose?: () => void;
  offset?: number;
  onHide?: (hidden: boolean) => void;
  className?: string;
  strategy?: 'absolute' | 'fixed';
  enableFlip?: boolean;
  enableShift?: boolean;
  enableEscaped?: boolean;
  enableReferenceHidden?: boolean;
  shiftOptions?: ShiftOptions;
};

const OFFSET = 4;

const FloatingMenu: React.FC<Props> = ({
  anchorEl,
  onClose = () => undefined,
  onHide,
  pageless,
  placement = 'bottom-start',
  strategy = 'fixed',
  offset: offsetWidth = OFFSET,
  enableShift = true,
  enableFlip = false,
  enableEscaped = true,
  enableReferenceHidden = true,
  shiftOptions,
  className,
  children,
}) => {
  const padding = {
    top: pageless ? PAGELESS_TOOLBAR_HEIGHT : 0,
    bottom: 0,
    left: 0,
    right: 0,
  };
  const altBoundary = !pageless;

  const {
    x,
    y,
    refs: { setReference, setFloating },
    middlewareData,
  } = useFloating({
    placement,
    strategy,
    middleware: [
      offset(offsetWidth),
      ...(enableShift
        ? [
            shift(
              shiftOptions ?? {
                mainAxis: false,
                crossAxis: true,
                limiter: limitShift({
                  crossAxis: true,
                  mainAxis: false,
                }),
                altBoundary,
                padding,
              }
            ),
          ]
        : []),
      ...(enableFlip ? [flip()] : []),
      ...(enableEscaped
        ? [
            hide({
              strategy: 'escaped',
              altBoundary,
            }),
          ]
        : []),
      ...(enableReferenceHidden
        ? [
            hide({
              strategy: 'referenceHidden',
              altBoundary,
            }),
          ]
        : []),
    ],
    whileElementsMounted: autoUpdate,
  });

  useLayoutEffect(() => {
    setReference(anchorEl as Element);
  }, [anchorEl, setReference]);

  const hidden = !!anchorEl && ((middlewareData.hide?.escaped || middlewareData.hide?.referenceHidden) ?? false);
  useEffect(() => {
    if (onHide) {
      onHide(hidden);
    } else if (hidden) {
      onClose();
    }
  }, [hidden, onClose, onHide]);

  const handleClickAway = useCallback(
    (e: React.MouseEvent<Document>) => {
      if (!(anchorEl as Node)?.contains?.(e.target as Node)) {
        onClose();
      }
    },
    [anchorEl, onClose]
  );

  if (!anchorEl) {
    return null;
  }

  const menu = (
    <MuiClickAwayListener onClickAway={handleClickAway}>
      <MuiFade in={true}>
        <div
          className={`rc-CMLEditorMenu ${className}`}
          ref={setFloating}
          style={{
            position: strategy,
            left: x ?? '',
            top: y ?? '',
          }}
          css={styles.container}
        >
          {children}
        </div>
      </MuiFade>
    </MuiClickAwayListener>
  );

  if (strategy === 'fixed') {
    return createPortal(menu, document.body);
  }

  return menu;
};

export default FloatingMenu;
