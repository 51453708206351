import type * as React from 'react';

import isHotkey from 'is-hotkey';
import type { Editor } from 'slate';

import { addLink } from 'bundles/cml/editor/components/buttons/link/linkUtils';
import { isTypeDisabled } from 'bundles/cml/editor/utils/elementUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';

const isLinkHotkey = isHotkey('mod+k');

export const linkKeyDownHandler = (editor: Editor, event: React.KeyboardEvent): boolean => {
  const { nativeEvent } = event;
  if (isLinkHotkey(nativeEvent) && !isTypeDisabled(editor, BLOCK_TYPES.LINK)) {
    event.preventDefault();
    addLink(editor);
    return true;
  }

  return false;
};
