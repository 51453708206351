import { useEffect, useState } from 'react';

import { isEqual } from 'lodash';
import { Editor } from 'slate';
import type { Descendant } from 'slate';

import { usePrevious } from 'bundles/cml/shared/hooks/usePrevious';
import { cmlToSlate } from 'bundles/cml/shared/utils/cmlToSlate';

export const useCMLToSlate = (editor: Editor, focused: boolean, cmlValue = '') => {
  const [value, setValue] = useState<Descendant[] | undefined>();
  const previousCMLValue = usePrevious(cmlValue);

  useEffect(() => {
    if (focused && editor.children) {
      return;
    }

    if (previousCMLValue === cmlValue) {
      return;
    }

    const newValue = cmlToSlate(cmlValue);
    if (isEqual(newValue, editor.children)) {
      return;
    }

    editor.children = newValue; // eslint-disable-line no-param-reassign
    const points = Array.from(Editor.positions(editor, { at: [0] }))[0];

    // reset the cursor position in case the focused node no longer exists
    editor.selection = points ? { anchor: points, focus: points } : null; // eslint-disable-line no-param-reassign

    setValue(newValue);
  }, [editor, focused, cmlValue, previousCMLValue]);
  return value ?? [];
};
