import * as React from 'react';
import { createContext, useContext, useState } from 'react';

import type { AiCoach_RespondToUserMessageInput } from '__generated__/graphql-types';

type CoachChatConfig = Omit<AiCoach_RespondToUserMessageInput, 'message'> & { message?: undefined };

type CoachStateProviderType = {
  coachOpen: boolean;
  setCoachOpen: React.Dispatch<React.SetStateAction<boolean>>;
  contextConfig: CoachChatConfig;
  setContextConfig: React.Dispatch<React.SetStateAction<CoachChatConfig>>;
};
const defaultCoachState: CoachStateProviderType = {
  coachOpen: false,
  setCoachOpen: () => {},
  contextConfig: {
    config: {
      coachActionId: '',
      coachModeId: 'BASIC',
    },
  },
  setContextConfig: () => {},
};

const CoachStateContext = createContext(defaultCoachState);

export const useCoachState = () => {
  const context = useContext(CoachStateContext);
  return context;
};

export const CoachStateProvider = ({ children }: { children: React.ReactNode }) => {
  const [coachOpen, setCoachOpen] = useState(defaultCoachState.coachOpen);
  const [contextConfig, setContextConfig] = useState(defaultCoachState.contextConfig);

  return (
    <CoachStateContext.Provider
      value={{
        coachOpen,
        setCoachOpen,
        contextConfig,
        setContextConfig: (...data) => {
          setContextConfig(...data);
        },
      }}
    >
      {children}
    </CoachStateContext.Provider>
  );
};
