/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { Link as RouterLink } from 'react-router';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import useRouter from 'js/lib/useRouter';

import { Hidden, Link, Typography2, breakpoints } from '@coursera/cds-core';

import { HELP_CENTER_LINK } from 'bundles/enterprise-admin-constants/help';
import CourseraLogo from 'bundles/page/components/CourseraLogo';
import { TrackedA } from 'bundles/page/components/TrackedLink2';

import _t from 'i18n!nls/enterprise-admin-program-creation';

const bannerStyles = css`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--cds-color-white-0);
  box-shadow: inset 0 -1px 0 var(--cds-color-grey-200);
  padding: 0 var(--cds-spacing-600);
  ${breakpoints.down('sm')} {
    padding: '0 var(--cds-spacing-200)';
  }
`;

export const Header = () => {
  const router = useRouter();
  const { thirdPartySlug, programSlug } = router.params;
  const link = (
    <Link
      component={TrackedA}
      href={HELP_CENTER_LINK}
      target="_blank"
      rel="noopener noreferrer"
      trackingName="get_help"
      data={{ location: router.location.pathname }}
    >
      {_t('Get help')}
    </Link>
  );

  return (
    <header css={bannerStyles} role="banner">
      <RouterLink
        to={programSlug ? `/o/${thirdPartySlug}/admin/programs/${programSlug}/main` : `/o/${thirdPartySlug}/admin/home`}
        css={css`
          display: flex;
        `}
      >
        <CourseraLogo
          css={css`
            height: 16px;
          `}
        />
      </RouterLink>
      <Hidden xsDown>
        <Typography2 component="p" color="supportText">
          <FormattedMessage message={_t(`Having trouble? {link}`)} link={link} />
        </Typography2>
      </Hidden>
      <Hidden smUp>{link}</Hidden>
    </header>
  );
};
