import * as React from 'react';
import { useContext } from 'react';

import type { ApolloError } from '@apollo/client';
import type { LearnerOnboarding_Goal as GoalNames } from '__generated__/graphql-types';

import type { UpdateInterestedSkillsMutationVariables } from 'bundles/program-personalized-tab/queries/__generated__/EnterpriseUpdateInterestedSkills';
import type { Skill } from 'bundles/program-personalized-tab/types/sharedTypes';

type MutationInput = {
  variables: UpdateInterestedSkillsMutationVariables;
};

export type OnboardingContextType = {
  selectedRoleId?: string | null;
  hasCompletedOnboarding: boolean;
  selectedGoals?: Array<GoalNames> | null;
  isUnsureOfRole: boolean;
  hasRoleSkills?: boolean;
  skills: Array<Skill>;
  skillsLearnerInterestedIn?: Array<Skill>;
  error?: ApolloError;
  loading?: boolean;
  relatedSkills: Array<Skill>;
  relatedSkillsQueryLoading?: boolean;
  relatedSkillsQueryError?: ApolloError;
  updateInterestedSkills: (variables: MutationInput) => void;
};

const OnboardingContext = React.createContext<OnboardingContextType>({
  selectedRoleId: null,
  hasCompletedOnboarding: false,
  selectedGoals: null,
  isUnsureOfRole: true,
  skills: [],
  relatedSkills: [],
  updateInterestedSkills: () => undefined,
});

export const useOnboardingData = () => {
  const onboardingContext = useContext(OnboardingContext);
  return onboardingContext;
};

export default OnboardingContext;
