/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { compose, getDisplayName, setDisplayName } from 'recompose';

import connectToRouter from 'js/lib/connectToRouter';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import EnterpriseAutoComplete from 'bundles/enterprise-learner-search/components/search/EnterpriseAutoComplete';
import { withUnifiedSearchBarProps } from 'bundles/enterprise-learner-search/components/search/withUnifiedSearchBarProps';
import PageHeaderContext from 'bundles/page-header/contexts/PageHeaderContext';

import _t from 'i18n!nls/program-home';

import 'css!./__styles__/EnterpriseSearchBarV2';

export type SearchScope = {
  trackingData: { programSlug: string } | { thirdPartyOrgSlug: string };
  pathname: string;
  placeholder: string;
  title: string;
  programIds: Array<string>;
};

type Props = {
  scopes: SearchScope[] | undefined;
  initialScopeIndex: number | undefined;
  enableSkillsInSearchAndBrowse?: boolean;
  shouldShowShortFormContent?: boolean;
  isMobile?: boolean;
  shouldHideSearchScope?: boolean;
};

type State = {
  scopeIndex: number;
  hasMounted: boolean;
};

const TrackedSelect = withSingleTracked({
  type: 'CHECKBOX',
})((props: React.SelectHTMLAttributes<HTMLSelectElement>) => <select {...props} />);

const styles = {
  mobileSearchBar: css`
    && {
      background: transparent;
      padding: 0;
      margin: 0;
    }
  `,
  desktopSearchBar: css`
    && {
      height: auto;
      margin: 0;
    }
  `,
};

export class EnterpriseSearchBarImpl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      scopeIndex: props.initialScopeIndex ?? 0,
      hasMounted: false,
    };
  }

  componentDidMount() {
    this.setState({ hasMounted: true });
  }

  componentDidUpdate(prevProps: Props) {
    const nextProps = this.props;
    const prevScopeIndex = prevProps.initialScopeIndex ?? 0;
    const nextScopeIndex = nextProps.initialScopeIndex ?? 0;
    if (prevScopeIndex !== nextScopeIndex) {
      this.setState<'scopeIndex'>(({ scopeIndex }) => ({
        scopeIndex: scopeIndex === prevScopeIndex ? nextScopeIndex : scopeIndex,
      }));
    }
  }

  _handleChangeScope = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ scopeIndex: Number(event.target.value) });
  };

  _getTrackingDataFromChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => ({
    prevOption: this.props.scopes?.[this.state.scopeIndex]?.trackingData,
    nextOption: this.props.scopes?.[Number(event.target.value)]?.trackingData,
  });

  render() {
    const { scopes, enableSkillsInSearchAndBrowse, shouldShowShortFormContent, isMobile } = this.props;
    const { scopeIndex, hasMounted } = this.state;
    const scope = scopes?.[scopeIndex] ?? scopes?.[0];
    const shouldRenderScopeSelect = Number(scopes?.length) > 1 && !this.props.shouldHideSearchScope;

    return (
      <PageHeaderContext.Consumer>
        {({ isSimplifiedPageHeader }) => {
          let placeholder = scope?.placeholder ?? '';
          if (isSimplifiedPageHeader && scope?.pathname.includes('programs/all/')) {
            placeholder = _t('Search All Learning Programs');
          } else if (isSimplifiedPageHeader) {
            placeholder = _t('Search Coursera for #{programName}', { programName: scope?.title });
          }

          return (
            <div
              className={`rc-EnterpriseSearchBarV2 ${shouldRenderScopeSelect ? '--scope' : '--no-scope'}`}
              {...(isSimplifiedPageHeader
                ? { css: [isMobile ? styles.mobileSearchBar : styles.desktopSearchBar] }
                : {})}
            >
              {shouldRenderScopeSelect && !isSimplifiedPageHeader && (
                <TrackedSelect
                  onChange={this._handleChangeScope}
                  aria-label={_t('Program to search within')}
                  trackingName="enterprise_search_bar_program_select"
                  getTrackingDataFromChangeEvent={this._getTrackingDataFromChangeEvent}
                >
                  <optgroup label={_t('Search in')}>
                    {/* See above shouldRenderScopeSelect */}
                    {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                    {scopes!.map((scope0, index) => (
                      <option value={index} selected={index === scopeIndex} key={scope0.pathname}>
                        {scope0.title}
                      </option>
                    ))}
                  </optgroup>
                </TrackedSelect>
              )}
              {scope && hasMounted ? (
                <EnterpriseAutoComplete
                  pathname={scope.pathname}
                  programIds={scope.programIds}
                  enableSkillsInSearchAndBrowse={enableSkillsInSearchAndBrowse}
                  shouldShowShortFormContent={shouldShowShortFormContent}
                  shouldRenderScopeSelect={shouldRenderScopeSelect}
                  placeholder={placeholder}
                />
              ) : null}
            </div>
          );
        }}
      </PageHeaderContext.Consumer>
    );
  }
}

type LegacyEnterpriseSearchBarPropsFromCaller = {
  programIds: Array<string>;
  enableSkillsInSearchAndBrowse: boolean;
  shouldShowShortFormContent?: boolean;
  programName?: string;
};

type LegacyEnterpriseSearchBarPropsFromRouter = {
  programSlug: string;
};

type LegacyEnterpriseSearchBarProps = LegacyEnterpriseSearchBarPropsFromCaller &
  LegacyEnterpriseSearchBarPropsFromRouter;

export const EnterpriseSearchBarV2 = ({
  programSlug,
  programIds,
  enableSkillsInSearchAndBrowse,
  shouldShowShortFormContent,
  programName,
}: LegacyEnterpriseSearchBarProps) => (
  <EnterpriseSearchBarImpl
    scopes={[
      {
        trackingData: { programSlug },
        placeholder: _t('What do you want to learn?'),
        title: programName || '',
        pathname: `/programs/${programSlug}`,
        programIds,
      },
    ]}
    enableSkillsInSearchAndBrowse={enableSkillsInSearchAndBrowse}
    initialScopeIndex={0}
    shouldShowShortFormContent={shouldShowShortFormContent}
  />
);

export const UnifiedEnterpriseSearchBar = withUnifiedSearchBarProps(EnterpriseSearchBarImpl);

export default compose<LegacyEnterpriseSearchBarProps, LegacyEnterpriseSearchBarPropsFromCaller>(
  setDisplayName(getDisplayName(EnterpriseSearchBarV2)),
  connectToRouter((router) => ({ programSlug: router.params.programSlug }))
)(EnterpriseSearchBarV2);
