/** @jsx jsx */
import type { Interpolation } from '@emotion/react';
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { TextFieldProps, Theme } from '@coursera/cds-core';
import { TextField } from '@coursera/cds-core';

export type PropsForCDSTextField = Omit<Partial<TextFieldProps>, 'value'>;

type Props = {
  inputRef?:
    | ((instance: HTMLInputElement | HTMLTextAreaElement | null) => void)
    | React.RefObject<HTMLInputElement | HTMLTextAreaElement | null>
    | null
    | undefined;
  textFieldStyles?: Interpolation<Theme>;
  value?: React.ReactText;
  label?: string;
  e2eDataName?: string;
  hideFromScreenReader?: boolean;
} & PropsForCDSTextField;

const styles = {
  disabledInput: () => css`
    width: 100%;

    input {
      background-color: var(--cds-color-neutral-background-primary-weak) !important;
    }

    fieldset {
      border: 1px solid var(--cds-color-neutral-disabled-strong) !important;
    }
  `,
};

export const DisabledTextField: React.FC<Props> = ({
  inputRef,
  label,
  textFieldStyles,
  value,
  e2eDataName,
  hideFromScreenReader,
  ...textFieldProps
}) => {
  return (
    <TextField
      label={label || ''}
      inputRef={inputRef}
      css={[styles.disabledInput, textFieldStyles]}
      type="text"
      value={value}
      {...(e2eDataName && { 'data-e2e': e2eDataName })}
      inputProps={{
        disabled: true,
        'aria-hidden': !!hideFromScreenReader,
      }}
      fullWidth={true}
      {...textFieldProps}
    />
  );
};
