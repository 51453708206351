import type React from 'react';

import { isHotkey } from 'is-hotkey';
import type { NodeEntry } from 'slate';
import { Editor, Transforms } from 'slate';

import { getAncestorOfType, insertBlockNodes } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { HeadingElement } from 'bundles/cml/shared/types/elementTypes';

const isReturnHotKey = isHotkey('return');

const handleReturnKey = (editor: Editor, event: React.KeyboardEvent): boolean => {
  const headingEntry = getAncestorOfType(editor, BLOCK_TYPES.HEADING) as NodeEntry<HeadingElement>;
  if (!headingEntry) {
    return false;
  }

  const { selection } = editor;
  if (!selection) {
    return false;
  }

  event.preventDefault();

  const path = headingEntry[1];

  const start = Editor.isStart(editor, Editor.start(editor, selection), [...path, 0]);
  const end = Editor.isEnd(editor, Editor.end(editor, selection), [...path, 0]);

  if (start || end) {
    insertBlockNodes(editor, { type: BLOCK_TYPES.TEXT, children: [{ text: '' }] });
    if (start) {
      Transforms.move(editor, { unit: 'line' });
    }
    return true;
  }

  Transforms.splitNodes(editor, { at: selection });
  Transforms.setNodes(editor, { type: BLOCK_TYPES.TEXT, children: [] }, { at: [headingEntry[1][0] + 1] });
  Transforms.unsetNodes(editor, ['level']);

  return true;
};

export const headingKeyDownHandler = (editor: Editor, event: React.KeyboardEvent): boolean => {
  const { nativeEvent } = event;
  if (isReturnHotKey(nativeEvent)) {
    return handleReturnKey(editor, event);
  }

  return false;
};
