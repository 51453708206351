/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useState } from 'react';

import type { Property } from 'csstype';

import { useAutoScrollOnResize } from 'bundles/authoring/common/components/hooks/useAutoScrollOnResize';
import { useObserveContentHeight } from 'bundles/authoring/common/components/hooks/useObserveContentHeight';
import type { Dimensions } from 'bundles/authoring/common/types/resizeTypes';

const styles = {
  root: css`
    --border-color: var(--cds-color-neutral-primary-weak);

    &:hover {
      --background-color: var(--cds-color-interactive-background-primary-hover-weak);
    }

    position: relative;
  `,
  resizable: css`
    overflow: hidden;
    border: 1px solid var(--border-color);
    transition: border-color 250ms ease-out;
    border-radius: var(--cds-border-radius-50);
  `,
  resizeHandle: css`
    resize: vertical;
  `,
  resizing: css`
    --border-color: var(--cds-color-interactive-primary);
  `,
};

type Props = {
  contentSelector?: string;
  resizable?: boolean;
  className?: string;
  onResize?: (dimensions: Dimensions) => void;
  onResizeEnd?: () => void;
  minHeight?: number;
  initialHeight?: number;
  borderColor?: Property.BorderColor;
};

const ResizableContainer: React.FC<Props> = ({
  className,
  children,
  onResize,
  onResizeEnd,
  initialHeight,
  minHeight = initialHeight,
  resizable = true,
  contentSelector = '[data-testid="resizable-container"] > *',
  borderColor,
}) => {
  const [resizableEl, setResizableEl] = useState<HTMLDivElement | null>(null);
  const [autoHeight, setAutoHeight] = useState<number>(0);

  const { contentHeight } = useObserveContentHeight({
    resizableEl,
    enabled: resizable,
    contentSelector,
  });
  const { resizing } = useAutoScrollOnResize({ resizableEl, resizable, onResize, onResizeEnd });

  useEffect(() => {
    if (!autoHeight && contentHeight > 0) {
      setAutoHeight(contentHeight);
    }
  }, [autoHeight, contentHeight]);

  const height = initialHeight || autoHeight;
  const canResize = resizable && (contentHeight > height || contentHeight > (minHeight ?? autoHeight));
  const resizeStyles = {
    height,
    minHeight: minHeight ?? autoHeight,
    maxHeight: Math.max(contentHeight, minHeight ?? 0),
    borderColor,
  };

  return (
    <div data-testid="resizable-root" css={[styles.root, resizing && styles.resizing]} className={className}>
      <div
        data-testid="resizable-container"
        ref={setResizableEl}
        css={[resizable && styles.resizable, canResize && styles.resizeHandle]}
        style={resizable ? resizeStyles : undefined}
      >
        {children}
      </div>
    </div>
  );
};

export default ResizableContainer;
