export enum SettingsView {
  HOME = 'home',
  PROFILE_DATA = 'profileData',
  STUDIO = 'studio',
  CHAT_HISTORY = 'chatHistory',
  MEMORY = 'memory',
}

export type ProfileInfoField = 'workHistory' | 'educationHistory' | 'location';

/** derived state for all chat sessions for a user */
export enum ChatHistoryStatus {
  SESSIONS_AVAILABLE = 'available',

  SESSIONS_DELETED = 'deleted',
}
