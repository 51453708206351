import * as React from 'react';

import { useQuery } from '@apollo/client';

import CareerAcademyContext from 'bundles/program-personalized-tab/contexts/CareerAcademyContext';
import GetCareerAcademyCategories from 'bundles/unified-career-academy/queries/GetCareerAcademyCategories.graphql';
import type {
  GetCareerAcademyCategoriesQuery,
  GetCareerAcademyCategoriesQueryVariables,
} from 'bundles/unified-career-academy/queries/__generated__/GetCareerAcademyCategories';

type PropsToComponent = {
  children: React.ReactNode;
};

const CareerAcademyDataProvider = ({ children }: PropsToComponent) => {
  const { data, loading: careerAcademyCategoriesLoading } = useQuery<
    GetCareerAcademyCategoriesQuery,
    GetCareerAcademyCategoriesQueryVariables
  >(GetCareerAcademyCategories, {
    context: {
      clientName: 'gatewayGql',
    },
  });

  const careerAcademyCategories = React.useMemo(() => data?.CareerAcademy?.queryAllCategories ?? [], [data]);

  const careerAcademyRoles = React.useMemo(
    () => careerAcademyCategories.flatMap((category) => category.roles),
    [careerAcademyCategories]
  );

  return (
    <CareerAcademyContext.Provider
      value={{
        careerAcademyCategories,
        careerAcademyRoles,
        careerAcademyCategoriesLoading,
      }}
    >
      {children}
    </CareerAcademyContext.Provider>
  );
};

export default CareerAcademyDataProvider;
