import type { FinancialAidApplication } from '__generated__/graphql-types';

import { tupleToStringKey } from 'js/lib/stringKeyTuple';

import { FindAidState } from 'bundles/payments/components/finaid-2021/enhancers/types';

export const transformFinancialAidApplication = (financialAidApplication: FinancialAidApplication) => {
  let additionalQuestions;
  if (
    financialAidApplication.educationalBackground ||
    financialAidApplication.financialBackground ||
    financialAidApplication.careerProgressionQuestionnaire ||
    financialAidApplication.externalFinancialAssistanceQuestionnaire
  ) {
    additionalQuestions = {
      educationalBackground: financialAidApplication.educationalBackground?.educationalBackground,
      employmentStatus: financialAidApplication.financialBackground?.employmentStatus,
      affordToPay: financialAidApplication.financialBackground?.affordToPay,
      affordToPayCurrencyCode: financialAidApplication.financialBackground?.affordToPayCurrencyCode,
      payPeriod: financialAidApplication.financialBackground?.payPeriod,
      howContributeToCareer: financialAidApplication.careerProgressionQuestionnaire?.howContributeToCareer,
      willingToTakeOutLoan: financialAidApplication.externalFinancialAssistanceQuestionnaire?.willingToTakeOutLoan,
      whyNotWillingToTakeLoan:
        financialAidApplication.externalFinancialAssistanceQuestionnaire?.whyNotWillingToTakeOutLoan,
    };
  }
  const returnData = {
    id: financialAidApplication.id,
    cartId: financialAidApplication.cartId?.id,
    userId: financialAidApplication.userId?.id,
    approvalDate: financialAidApplication.approvalEstimatedAt
      ? new Date(financialAidApplication.approvalEstimatedAt).getTime()
      : null,
    applicationDate: financialAidApplication.createdAt ? new Date(financialAidApplication.createdAt).getTime() : null,
    country: financialAidApplication.countryIsoCode,
    annualIncome: financialAidApplication.financialBackground?.annualIncome,
    annualIncomeCurrencyCode: financialAidApplication.financialBackground?.annualIncomeCurrencyCode as string,
    whyApplying: financialAidApplication.careerProgressionQuestionnaire?.whyApplying,
    whyTakingCourse: financialAidApplication.careerProgressionQuestionnaire?.whyTakingCourse,
    howContributeToCommunity: financialAidApplication.careerProgressionQuestionnaire?.howContributeToCommunity,
    denied: financialAidApplication.state === FindAidState.Rejected,
    state:
      financialAidApplication.state === FindAidState.ApprovedPaid
        ? FindAidState.ApprovedPaymentSuccessful
        : financialAidApplication.state,
    productId: financialAidApplication.productId
      ? tupleToStringKey([
          financialAidApplication.productId?.productType,
          financialAidApplication.productId?.productItemId,
        ])
      : null,
    additionalQuestions,
  };

  return returnData;
};
