import URI from 'jsuri';
import Q from 'q';

import API from 'js/lib/api';

const widgetsAPI = API('/api/authoringWidgets.v1/', { type: 'rest' });
const widgetSummaryAPI = API('/api/authoringWidgetSummaries.v1/', { type: 'rest' });

const WidgetAdminAPIUtils = {
  getAuthoringWidgetSummary(courseId: string) {
    const uri = new URI()
      .addQueryParam('q', 'course')
      .addQueryParam('courseId', courseId)
      .addQueryParam(
        'fields',
        'id,name,description,updatedAt,instances,configurationExamples,maintainer,downloadLink,isHidden,isGraded,isPublished,pluginType'
      );

    return Q(widgetSummaryAPI.get(uri.toString()));
  },

  hideWidget(widgetId: $TSFixMe, courseId: $TSFixMe) {
    const uri = new URI()
      .addQueryParam('action', 'hide')
      .addQueryParam('id', widgetId)
      .addQueryParam('scope', `COURSE~${courseId}`);

    return Q(widgetSummaryAPI.post(uri.toString()));
  },

  createWidget(assetsDirectory: $TSFixMe, assetsArchive: $TSFixMe, courseId: $TSFixMe) {
    const uri = new URI().addQueryParam('fields', 'draft,metadata,approval,note');
    const data = {
      assetsDirectory,
      assetsArchive,
      owner: `COURSE~${courseId}`,
    };

    return Q(widgetsAPI.post(uri.toString(), { data }));
  },

  updateWidget(assetsDirectory: $TSFixMe, assetsArchive: $TSFixMe, courseId: $TSFixMe, widgetId: $TSFixMe) {
    const uri = new URI(widgetId).addQueryParam('fields', 'draft,metadata,approval,note');
    const data = {
      assetsDirectory,
      assetsArchive,
      owner: `COURSE~${courseId}`,
    };

    return Q(widgetsAPI.patch(uri.toString(), { data }));
  },

  getPreviewSession(
    widgetId: $TSFixMe,
    courseId: $TSFixMe,
    isGraded: $TSFixMe,
    sessionConfiguration: $TSFixMe,
    graderConfiguration: $TSFixMe
  ) {
    const uri = new URI().addQueryParam('action', 'preview').addQueryParam('id', widgetId);

    const data = {
      configuration: sessionConfiguration,
      context: {
        courseId,
      },
    };

    if (isGraded) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'graderConfiguration' does not exist on t... Remove this comment to see the full error message
      data.graderConfiguration = graderConfiguration;
    }

    return Q(widgetsAPI.post(uri.toString(), { data }));
  },

  publishWidget(widgetId: $TSFixMe) {
    const uri = new URI().addQueryParam('action', 'publish').addQueryParam('id', widgetId);

    return Q(widgetsAPI.post(uri.toString()));
  },
};

export default WidgetAdminAPIUtils;

export const { getAuthoringWidgetSummary, hideWidget, createWidget, updateWidget, getPreviewSession, publishWidget } =
  WidgetAdminAPIUtils;
