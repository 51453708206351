import * as React from 'react';

import type { ButtonProps } from '@coursera/cds-core';
import { Button } from '@coursera/cds-core';

import withSingleTracked from 'bundles/common/components/withSingleTracked';

import _t from 'i18n!nls/course-cards';

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps<'a'>>(
  // TODO(ppaskaris): Ask how to type this.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  Button
);

type Props = {
  homeLink: string;
  label?: string;
  trackingName?: string;
  courseName: string;
};

const CourseHomeLink = ({ homeLink, label = _t('View Course'), trackingName, courseName }: Props): JSX.Element => {
  return trackingName ? (
    <TrackedButton
      {...Button.defaultProps}
      variant="primary"
      component="a"
      size="small"
      href={homeLink}
      target="_blank"
      rel="noopener noreferrer"
      trackingName={trackingName}
      aria-label={`${label}: ${courseName}`}
    >
      {label}
    </TrackedButton>
  ) : (
    <Button
      variant="primary"
      component="a"
      size="small"
      href={homeLink}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={`${label}: ${courseName}`}
    >
      {label}
    </Button>
  );
};

export default CourseHomeLink;
