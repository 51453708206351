import * as React from 'react';

import PropTypes from 'prop-types';
import { pure } from 'recompose';

const PartnerName = ({ partner, htmlAttributes }: $TSFixMe) => {
  if (!partner || !partner.name) {
    return null;
  }
  return <span {...htmlAttributes}>{partner.name}</span>;
};

PartnerName.propTypes = {
  partner: PropTypes.shape({
    name: PropTypes.string,
  }),
  htmlAttributes: PropTypes.object,
};

export default pure(PartnerName);
