/** @jsx jsx */
import { jsx } from '@emotion/react';

function SkillIcon() {
  return (
    <svg role="presentation" xmlns="http://www.w3.org/2000/svg" width="19" height="18" fill="none" viewBox="0 0 19 18">
      <path
        fill="#BD8400"
        stroke="#BD8400"
        strokeLinecap="round"
        strokeWidth="1.616"
        d="M9.5 2l2.315 4.609L17 7.347l-3.75 3.589.884 5.064L9.5 13.61 4.866 16l.884-5.064L2 7.346l5.181-.737L9.5 2z"
      />
    </svg>
  );
}

export default SkillIcon;
