import * as React from 'react';
import type { DataValue } from 'react-apollo';
import { graphql } from 'react-apollo';

import gql from 'graphql-tag';
import _ from 'lodash';
import { branch, compose, renderNothing, setDisplayName, withProps } from 'recompose';

import cookie from 'js/lib/cookie';
import waitForGraphQL from 'js/lib/waitForGraphQL';

import { StyleSheet, TextInput, css, spacing } from '@coursera/coursera-ui';
import type { ValidationResult } from '@coursera/coursera-ui/lib/utils/validation';
import { email, maxLength, minLength, required, runValidations } from '@coursera/coursera-ui/lib/utils/validation';
import type { UserEmail } from '@coursera/grpc-types-useremails/coursera/proto/useremails/v1/email_address';

import { TrackedApiButton, TrackedButton } from 'bundles/common/components/TrackedCui';
import ErrorMessage from 'bundles/coursera-ui/components/extended/ErrorMessage';
import type { PropsFromWithApiHandler } from 'bundles/coursera-ui/components/hocs/withApiHandler';
import withApiHandler from 'bundles/coursera-ui/components/hocs/withApiHandler';
import Modal from 'bundles/phoenix/components/Modal';
import storageUtils from 'bundles/program-common/utils/storageUtils';
import type {
  ProgramAlternativeEmailModalQuery,
  ProgramAlternativeEmailModalQueryVariables,
} from 'bundles/program-home/components/modals/__generated__/ProgramAlternativeEmailModalQuery';
import type {
  OnDemandLearnerMaterialsQuery,
  OnDemandLearnerMaterialsQueryVariables,
  OnDemandLearnerMaterialsQuery_OnDemandLearnerMaterialsV1Resource_courses_elements as Progress,
} from 'bundles/program-home/components/modals/__generated__/onDemandLearnerMaterialsQuery';
import type {
  ProgramCurriculumProductsQuery_ProgramCurriculumProductsV1Resource_enrolled_elements as Product,
  ProgramCurriculumProductsQuery_ProgramCurriculumProductsV1Resource_enrolled_elements_productState_ProgramCurriculumProductsV1_programCourseWithStateMember as ProgramCourseWithStateMember,
  ProgramCurriculumProductsQuery,
  ProgramCurriculumProductsQueryVariables,
} from 'bundles/program-home/components/modals/__generated__/programCurriculumProductsQuery';
import { apiConfig } from 'bundles/program-home/constants/ProgramHomeConfig';
import { API_TAGS } from 'bundles/program-home/constants/ProgramHomeConstants';
import type ProgramLearnerApiManager from 'bundles/program-home/utils/ProgramLearnerApiManager';

import _t from 'i18n!nls/program-home';

const { PROGRAM_PRODUCT_ENROLLED_DISPLAY_LIMIT } = apiConfig;
const COURSE_PROGRESS_THRESHOLD = 15;
export const ALTERNATIVE_EMAIL_TIMEOUT = 14 * 86400000; // 14 days
export const ALTERNATIVE_EMAIL_COURSE_PROGRESS_CACHE_DAYS = 1 * 86400000;
export const ALTERNATIVE_EMAIL_NEVER_EXPIRE = 9999 * 86400000;

type PropsFromCaller = {
  userId: number;
  programId: string;
  programName: string;
  userEmails: UserEmail[];
  onClose?: () => void;
  thirdPartyOrganizationId: string;
  apiManager: ProgramLearnerApiManager;
};

type PropsFromGraphql = {
  data: DataValue<ProgramAlternativeEmailModalQuery, ProgramAlternativeEmailModalQueryVariables> | undefined;
};

type PropsFromProductsQuery = {
  products: Product[];
};

type PropsFromProgressQuery = {
  learnerMaterialsArray?: (Progress | null)[];
};

type PropsFromSignificantCourseProgress = {
  hasSignificantCourseProgress: boolean;
};

type PropsFromCheckShouldShowAlternativeEmail = {
  shouldShowAlternativeEmailModal: boolean;
};

type Props = PropsFromCaller &
  PropsFromWithApiHandler &
  PropsFromProgressQuery &
  PropsFromProductsQuery &
  PropsFromSignificantCourseProgress &
  PropsFromCheckShouldShowAlternativeEmail;

const storageKey = 'program-home-hideAlternativeEmailModal';

function cookieKey(userId: number) {
  return `program-home-hideAlternativeEmailModal-${userId}`;
}

function getTranslations() {
  return {
    header: _t('Keep your accomplishments!'),
    body: _t(
      `
      Keep your Coursera accomplishments forever by adding an alternative email.
      `
    ),
    DEFAULT_ERROR_MSG: _t('An error has occurred, please try again later'),
    alternativeEmailLabel: _t('Alternative Email'),
    alternativeEmailPlaceholder: _t('Enter alternative email'),
    confirmAlternativeLabel: _t('Confirm Alternative Email'),
    confirmAlternativePlaceholder: _t('Confirm alternative email'),
    apiButtonLabel: {
      API_BEFORE_SEND: _t('Add Alternative Email'),
      API_IN_PROGRESS: _t('Adding Alternative Email...'),
      API_SUCCESS: _t('Added'),
      API_ERROR: _t('Failed to add'),
    },
    remindLater: _t('Remind me later'),
    emailNotMatch: _t('Emails do not match, please try again'),
  };
}

const styles = StyleSheet.create({
  modalContent: {
    minWidth: spacing.minWidth,
    maxWidth: '100%',
    textAlign: 'left',
    marginLeft: 100,
    marginRight: 100,
  },
  header: {
    width: '100%',
  },
});

type State = {
  alternativeEmailField: string;
  confirmEmailField: string;
  apiError?: string;
  error: string;
  alternativeEmailFieldError: ValidationResult;
  confirmEmailFieldError: ValidationResult;
  dismissed: boolean;
};

const programCurriculumProductsQuery = gql`
  query ProgramCurriculumProductsQuery($programId: String!, $limit: Int!) {
    ProgramCurriculumProductsV1Resource {
      enrolled(programId: $programId, limit: $limit) {
        elements {
          id
          productState {
            ... on ProgramCurriculumProductsV1_programCourseWithStateMember {
              productState: programCourseWithState {
                courseId
              }
            }
          }
        }
      }
    }
  }
`;

const onDemandLearnerMaterialsQuery = gql`
  query OnDemandLearnerMaterialsQuery($courseIds: [String!]!) {
    OnDemandLearnerMaterialsV1Resource {
      courses(courseIds: $courseIds) {
        elements {
          id
          summaryCoreProgressPercentage
        }
      }
    }
  }
`;

export class ProgramAlternativeEmailModal extends React.Component<Props, State> {
  state = {
    alternativeEmailField: '',
    confirmEmailField: '',
    error: '',
    apiError: '',
    alternativeEmailFieldError: '',
    confirmEmailFieldError: '',
    dismissed: false,
  };

  onAlternativeEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ alternativeEmailField: value, alternativeEmailFieldError: '' });
  };

  onConfirmEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ confirmEmailField: value, confirmEmailFieldError: '' });
  };

  onAlternativeEmailBlur = () => {
    const { alternativeEmailField, confirmEmailField } = this.state;
    const error = runValidations(alternativeEmailField, [required, minLength(3), maxLength(40), email]);

    this.setState({ alternativeEmailFieldError: error });

    if (alternativeEmailField && confirmEmailField) {
      this.setState({ error: alternativeEmailField !== confirmEmailField ? getTranslations().emailNotMatch : '' });
    }
  };

  onConfirmEmailBlur = () => {
    const { alternativeEmailField, confirmEmailField } = this.state;
    const error = runValidations(confirmEmailField, [required, minLength(3), maxLength(40), email]);

    this.setState({ confirmEmailFieldError: error });

    if (alternativeEmailField && confirmEmailField) {
      this.setState({ error: alternativeEmailField !== confirmEmailField ? getTranslations().emailNotMatch : '' });
    }
  };

  onButtonClick = () => {
    const { confirmEmailField, alternativeEmailField } = this.state;
    if (!alternativeEmailField || !confirmEmailField) {
      this.setState({ error: getTranslations().emailNotMatch });
    } else {
      this.setState({ error: '' });
      this.onSubmitAlternativeEmail(confirmEmailField);
    }
  };

  onSubmitAlternativeEmail = (userEmail: string) => {
    const { userId, apiManager, handleApiPromise, onClose } = this.props;

    const apiPromise = apiManager.setAlternativeEmailPromise({
      email: userEmail,
      userId,
    });
    this.setState({ apiError: '' });

    handleApiPromise({
      apiPromise,
      apiTag: API_TAGS.SET_ALTERNATIVE_EMAIL,
      apiSuccessCallback: () => {
        storageUtils.set(userId, this.props.programId, storageKey, Date.now() + ALTERNATIVE_EMAIL_NEVER_EXPIRE);
        setTimeout(() => {
          this.setState({ dismissed: true });
        }, 7000);

        if (onClose) {
          onClose();
        }
      },
      apiErrorCallback: (response) => {
        const message = (response && response.responseJSON) || {};
        const { errorCode, message: errorMessage, details } = message;
        this.setState({ apiError: `${errorCode || ''} ${errorMessage || ''} ${details || ''}` });
      },
    });
  };

  setAlternativeEmailLater = () => {
    const { userId, onClose, programId } = this.props;
    storageUtils.set(userId, programId, storageKey, Date.now() + ALTERNATIVE_EMAIL_TIMEOUT);
    this.setState({ dismissed: true });
    if (onClose) {
      onClose();
    }
  };

  render() {
    const { programId, programName, apiStatus } = this.props;
    const { alternativeEmailFieldError, error, confirmEmailFieldError, apiError, dismissed } = this.state;

    if (dismissed) {
      return null;
    }

    const _T = getTranslations();

    return (
      <div className="rc-ProgramNonBlockingModal">
        <Modal
          data-e2e="ProgramAlternativeEmailModal"
          modalName="ProgramAlternativeEmailModal"
          trackingName="program_alternative_email_modal"
          data={{ programId, programName }}
          handleClose={this.setAlternativeEmailLater}
        >
          <div
            {...css('ProgramAlternativeEmailModal vertical-box m-t-3 m-b-0', styles.modalContent)}
            data-e2e="ProgramAlternativeEmailModal"
          >
            <h2 {...css('m-b-1', styles.header)}>{_T.header}</h2>
            <p className="m-b-2">{_T.body}</p>
            {error && <ErrorMessage error={error} defaultErrorMsg={_T.DEFAULT_ERROR_MSG} />}
            {!error && apiError && <ErrorMessage error={apiError} defaultErrorMsg={_T.DEFAULT_ERROR_MSG} />}
            <TextInput
              componentId="alternativeEmailField"
              style={{
                paddingBottom: spacing.md,
              }}
              name="alternativeEmailField"
              label={_T.alternativeEmailLabel}
              placeholder={_T.alternativeEmailPlaceholder}
              onChange={this.onAlternativeEmailChange}
              onBlur={this.onAlternativeEmailBlur}
              error={alternativeEmailFieldError}
            />
            <TextInput
              style={{
                paddingBottom: spacing.lg,
              }}
              componentId="confirmEmailField"
              name="confirmEmailField"
              label={_T.confirmAlternativeLabel}
              placeholder={_T.confirmAlternativePlaceholder}
              onChange={this.onConfirmEmailChange}
              onBlur={this.onConfirmEmailBlur}
              error={confirmEmailFieldError}
            />

            <div className="vertical-box align-items-absolute-center">
              <TrackedApiButton
                trackingName="add_alternative_email_button"
                trackingData={{
                  programId,
                  programName,
                }}
                type="primary"
                style={{
                  marginBottom: spacing.md,
                }}
                onClick={this.onButtonClick}
                apiStatus={apiStatus}
                size="lg"
                disabled={!!(error || alternativeEmailFieldError || confirmEmailFieldError)}
                apiStatusAttributesConfig={{
                  label: _T.apiButtonLabel,
                }}
              />
              <TrackedButton
                label={_T.remindLater}
                type="link"
                onClick={this.setAlternativeEmailLater}
                trackingName="remind_later_alternative_email_button"
                trackingData={{
                  programId,
                }}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

function checkShouldShowAlternativeEmail<P extends PropsFromCaller & PropsFromGraphql>() {
  type WrapperState = {
    shouldShowAlternativeEmailModal: boolean;
  };

  return (Component: React.ComponentClass<P & WrapperState>) => {
    class HOC extends React.Component<P, WrapperState> {
      state = {
        shouldShowAlternativeEmailModal: false,
      };

      componentDidMount() {
        const { userId, userEmails, data, programId } = this.props;

        if (data?.GdprSettingsV1Resource?.get?.recoveryAccountAfterFirstEnrollment === false || userEmails.length > 1) {
          this.setState({ shouldShowAlternativeEmailModal: false });
          storageUtils.set(userId, programId, storageKey, Date.now() + ALTERNATIVE_EMAIL_NEVER_EXPIRE);
          return;
        }

        const hideAlternativeEmailModal =
          (storageUtils.get(userId, programId, storageKey, undefined) || -Infinity) > Date.now() ||
          cookie.get(cookieKey(userId));
        if (!hideAlternativeEmailModal) {
          this.setState({ shouldShowAlternativeEmailModal: true });
        }
      }

      render() {
        const { shouldShowAlternativeEmailModal } = this.state;
        return React.createElement(Component, {
          ...this.props,
          shouldShowAlternativeEmailModal,
        });
      }
    }

    return HOC;
  };
}

const checkSignificantCourseProgress = compose(
  graphql<
    PropsFromCaller,
    ProgramCurriculumProductsQuery,
    ProgramCurriculumProductsQueryVariables,
    PropsFromProductsQuery
  >(programCurriculumProductsQuery, {
    options: ({ programId }) => ({
      variables: {
        programId,
        limit: PROGRAM_PRODUCT_ENROLLED_DISPLAY_LIMIT,
      },
    }),
    props: (props) => {
      const products = (props.data?.ProgramCurriculumProductsV1Resource?.enrolled?.elements ?? []) as Product[];
      return { products };
    },
  }),
  graphql<
    PropsFromCaller & PropsFromProductsQuery,
    OnDemandLearnerMaterialsQuery,
    OnDemandLearnerMaterialsQueryVariables,
    PropsFromProgressQuery
  >(onDemandLearnerMaterialsQuery, {
    skip: (props: PropsFromCaller & PropsFromProductsQuery) => _.isEmpty(props.products),
    options: (props: PropsFromCaller & PropsFromProductsQuery) => ({
      variables: {
        courseIds: _.filter(props.products, ({ productState }) =>
          Boolean((productState as ProgramCourseWithStateMember).productState?.courseId)
        ).map(({ productState }) => (productState as ProgramCourseWithStateMember).productState.courseId),
      },
    }),
    props: ({ data }) => ({
      learnerMaterialsArray: data?.OnDemandLearnerMaterialsV1Resource?.courses?.elements ?? [],
    }),
  }),

  withProps<PropsFromSignificantCourseProgress, PropsFromCaller & PropsFromProgressQuery>(
    ({ learnerMaterialsArray, userId, programId }) => {
      const hasSignificantCourseProgress =
        learnerMaterialsArray && !_.isEmpty(learnerMaterialsArray)
          ? _.some(learnerMaterialsArray, (item) =>
              item?.summaryCoreProgressPercentage
                ? item.summaryCoreProgressPercentage > COURSE_PROGRESS_THRESHOLD
                : false
            )
          : false;

      if (!hasSignificantCourseProgress) {
        storageUtils.set(userId, programId, storageKey, Date.now() + ALTERNATIVE_EMAIL_COURSE_PROGRESS_CACHE_DAYS);
      }

      return {
        hasSignificantCourseProgress,
      };
    }
  )
);

export default compose<Props, PropsFromCaller>(
  setDisplayName('ProgramAlternativeEmailModalHOC'),
  waitForGraphQL<
    PropsFromCaller,
    ProgramAlternativeEmailModalQuery,
    ProgramAlternativeEmailModalQueryVariables,
    PropsFromGraphql
  >(
    gql`
      query ProgramAlternativeEmailModalQuery($thirdPartyOrganizationId: String!) {
        GdprSettingsV1Resource {
          get(id: $thirdPartyOrganizationId) {
            id
            recoveryAccountAfterFirstEnrollment
          }
        }
      }
    `,
    {
      options: ({ thirdPartyOrganizationId }) => ({
        variables: {
          thirdPartyOrganizationId,
        },
      }),
    }
  ),
  checkShouldShowAlternativeEmail(),
  branch<PropsFromCheckShouldShowAlternativeEmail>((props) => !props.shouldShowAlternativeEmailModal, renderNothing),
  checkSignificantCourseProgress,
  branch<PropsFromSignificantCourseProgress>((props) => !props.hasSignificantCourseProgress, renderNothing),
  withApiHandler({
    shouldResetToDefaultStatus: true,
    apiStatusResetTime: 8000,
  })
)(ProgramAlternativeEmailModal);
