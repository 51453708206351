/* eslint-disable no-restricted-syntax */
import _t from 'i18n!nls/stackability-common';

const getLeeCoreLexCopy = () => ({
  singleDegreeNotification: {
    offersCredit: {
      titleCopy: _t('Earn credit towards a degree!'),
      bodyCopy: _t(
        'When you complete this certificate you can earn college credit if you are admitted and enroll in the degree program.¹'
      ),
      disclaimerCopy: _t(
        '¹Each university determines the number of pre-approved prior learning credits that may count towards the degree requirements according to institutional policies.'
      ),
    },
    partOf: {
      titleCopy: _t('Make progress towards a degree today!'),
      bodyCopy: (degreeName: string, partnerName: string) =>
        _t(
          'This course is part of the #{degreeName} program from #{partnerName}. If you are admitted and enroll in the degree program, your coursework can count toward your degree learning and your progress can transfer with you.',
          { degreeName, partnerName }
        ),
      disclaimerCopy: undefined,
    },
    relatedTo: {
      titleCopy: _t('Prepare for this degree'),
      bodyCopy: _t(
        'This course may provide you with a preview of the topics, materials and instructors in a related degree program which can help you decide if the topic or university is right for you.'
      ),
      disclaimerCopy: undefined,
    },
    indiaPMFVariantA: {
      titleCopy: _t('Take manageable steps towards your degree'),
      bodyCopy: (degreeName: string, partnerName: string) =>
        _t(
          'Your learning in this course will seamlessly lead you to the destination – a #{degreeName} from #{partnerName} – whenever you decide to take that leap.',
          { degreeName, partnerName }
        ),
      disclaimerCopy: undefined,
    },
    indiaPMFVariantB: {
      titleCopy: _t('This course takes you closer to a degree!'),
      bodyCopy: (degreeName: string, partnerName: string) =>
        _t(
          "This course is a part of #{degreeName} from #{partnerName}. If you enrol for the degree, you may be able to either pick up this course from where you left off or skip if you've already completed it.",
          { degreeName, partnerName }
        ),
      disclaimerCopy: undefined,
    },
  },
  multipleDegreesNotification: {
    offersCredit: {
      titleCopy: _t('Earn credit towards a degree!'),
      bodyCopy: _t(
        'When you complete this certificate you can earn college credit if you are admitted and enroll in an eligible degree program.¹'
      ),
      disclaimerCopy: _t(
        '¹Each university determines the number of pre-approved prior learning credits that may count towards the degree requirements according to institutional policies.'
      ),
    },
    partOf: {
      titleCopy: _t('This course is part of multiple degree programs'),
      bodyCopy: _t(
        'If you are admitted and enroll, your coursework can count toward your degree learning and your progress can transfer with you.'
      ),
      disclaimerCopy: undefined,
    },
    relatedTo: {
      titleCopy: _t('Prepare for this degree'),
      bodyCopy: _t(
        'This course may provide you with a preview of the topics, materials and instructors in a related degree program which can help you decide if the topic or university is right for you.'
      ),
      disclaimerCopy: undefined,
    },
    indiaPMFVariantA: {
      titleCopy: _t('Take manageable steps towards your degree'),
      bodyCopy: _t(
        'Your learning in this course will seamlessly lead you to a destination – a degree from IIIT Hyderabad or OP Jindal University – whenever you decide to take that leap.'
      ),
      disclaimerCopy: undefined,
    },
    indiaPMFVariantB: {
      titleCopy: _t('This course takes you closer to a degree!'),
      bodyCopy: _t(
        "This course is a part of degrees from IIIT Hyderabad and OP Jindal University. If you enrol in the degree, you may be able to either pick up this course from where you left off or skip if you've already completed it."
      ),
      disclaimerCopy: undefined,
    },
  },

  completionStackability: {
    singleDegreeUpsell: {
      offersCreditCopy: {
        title: (productVariant: string) =>
          _t('Did you know completing the #{productVariant} can earn you credit towards a degree?', { productVariant }),
        body: _t(
          'View the program or request info to learn about how to apply your progress towards college credit. Each university determines the number of credits that may count towards the degree requirements based on institutional policies, which may consider existing credits you may have.'
        ),
      },
      partOfCopy: {
        title: (productVariant: string, degree: string, partner: string) =>
          _t('You completed a #{productVariant} that is part of the #{degree} degree from #{partner}! ', {
            productVariant,
            degree,
            partner,
          }),
        body: _t('View the program and request info to learn about how your progress can transfer towards a degree.'),
      },
      relatedToCopy: {
        title: (productVariant: string) =>
          _t(
            'You completed a #{productVariant} that may provide you with a preview of the topics, materials and instructors in a related degree program.',
            { productVariant }
          ),
        body: _t('View the program and request info to see if the degree may be a good fit for you.'),
      },
    },
    multipleDegreeUpsells: {
      offersCreditCopy: {
        title: (productVariant: string) =>
          _t('Did you know completing the #{productVariant} can earn you credit towards a degree?', { productVariant }),
        body: _t(
          'View the degrees that your progress can count towards, and request info to learn how to apply your progress towards credit. Each university determines the number of credits that may count towards the degree requirements based on institutional policies, which may consider existing credits you may have.'
        ),
      },
      partOfCopy: {
        title: (productVariant: string) =>
          _t('You completed a #{productVariant} that is part of multiple degree programs!', { productVariant }),
        body: _t('View the program and request info to learn about how your progress can transfer towards a degree.'),
      },
      relatedToCopy: {
        title: _t('You’ve taken the first step towards a degree!'),
        body: (productVariant: string) =>
          _t(
            'You completed a #{productVariant} that may provide you with a preview of the topics, materials and instructors in a related degree program. View the program and request info to see if the degree may be a good fit for you.',
            { productVariant }
          ),
      },
    },
  },
});

export default getLeeCoreLexCopy;
