import * as React from 'react';

import type UserAgentInfo from 'js/lib/useragent';

import { Box, Col, Row } from '@coursera/coursera-ui';

import { renderToggleButtonWithoutCollapse } from 'bundles/enterprise-legacy-xdp/components/aboutXrenderFuncs';
import TogglableContent from 'bundles/page/components/TogglableContent';
import { TrackedA } from 'bundles/page/components/TrackedLink2';
import LazyImgix from 'bundles/page/components/shared/LazyImgix';
import type { Partner } from 'bundles/program-common/types/xdpTypes';

import _t from 'i18n!nls/program-common';

import 'css!bundles/enterprise-legacy-xdp/components/partners/__styles__/PartnerList';

const PHOTO_SIZE = 120;

type PartnerListProps = {
  partners: Array<Partner>;
  userAgent?: UserAgentInfo;
};

type PartnerProps = {
  partner: Partner;
  userAgent?: UserAgentInfo;
};

function PartnerComponent({ partner, userAgent }: PartnerProps) {
  const isMobileBrowser = !!userAgent?.isMobileBrowser;

  return (
    <Col xs={12} lg={6} key={partner.id} rootClassName="p-y-1 p-r-1">
      <div className="rc-Partner">
        {partner.squareLogo && (
          <TrackedA
            trackingName="partner_avatar"
            className="link-no-style"
            rel="noopener noreferrer"
            href={`/${partner.shortName}`}
          >
            <div className="avatar-container m-r-2">
              <LazyImgix
                src={partner.squareLogo}
                maxWidth={PHOTO_SIZE}
                maxHeight={PHOTO_SIZE}
                alt={_t('#{partnerName} logo', { partnerName: partner.name })}
              />
            </div>
          </TrackedA>
        )}

        <Box justifyContent="center" flexDirection="column" flex={1}>
          <TrackedA
            trackingName="partner_name"
            className="link-no-style"
            href={`/${partner.shortName}`}
            rel="noopener noreferrer"
          >
            <h3 className="headline-4-text bold rc-Partner__title">{partner.name}</h3>
          </TrackedA>
          <TogglableContent
            addCollapseAnimation
            className="about-section"
            collapsedHeight={isMobileBrowser ? 67 : 198}
            disableCollapseScroll
            hideShowLessBtn
            renderToggleButtonContentFunction={renderToggleButtonWithoutCollapse('PARTNER DESCRIPTION')}
            toggleButtonClassName="bold body-2-text"
            toggleButtonType="link"
            trackingName="partner_description"
            useSmartCollapse
          >
            {partner.description}
          </TogglableContent>
          {partner.partnerMarketingBlurb && (
            <div className="rc-Partner__description color-black">{_t(partner.partnerMarketingBlurb)}</div>
          )}
        </Box>
      </div>
    </Col>
  );
}

export default function PartnerList({ partners = [], userAgent }: PartnerListProps) {
  if (partners.length === 0) {
    return null;
  }

  return (
    <div className="PartnerList">
      <Row>
        <h2 className="bold headline-4-text p-l-1">{_t('Offered by')}</h2>
      </Row>
      <Row>
        {partners &&
          partners.map((partner) => <PartnerComponent partner={partner} userAgent={userAgent} key={partner.id} />)}
      </Row>
    </div>
  );
}
