/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import classNames from 'classnames';

import { Button, Grid, Typography, Typography2 } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

import AuthoringTrackedButton from 'bundles/authoring/common/components/cds/AuthoringTrackedButton';
import PhoenixModal from 'bundles/phoenix/components/Modal';

import 'css!./__styles__/Modal';

type TitleProps = {
  icon?: React.ReactNode;
  children?: React.ReactNode;
  leftAlignHeading?: boolean;
  rowAlignHeading?: boolean;
};

const styles = {
  subHeading: (theme: Theme) =>
    css({
      color: 'var(--cds-color-grey-600)',
      marginTop: 12,
    }),
};
export class Title extends React.Component<TitleProps> {
  renderTitle() {
    const { icon, children, leftAlignHeading, rowAlignHeading } = this.props;
    const iconContainer = <div className="icon-container">{icon}</div>;
    const title = <div>{children}</div>;
    const titleContent = (
      <Typography variant="h1semibold" component="h2">
        {icon && iconContainer}
        {title}
      </Typography>
    );

    if (rowAlignHeading) {
      return (
        <Grid direction="row">
          {icon && <div className="m-r-1">{iconContainer}</div>}
          <Typography variant="h1semibold" component="h2" css={{ textAlign: 'left' }}>
            {title}
          </Typography>
        </Grid>
      );
    } else if (leftAlignHeading) {
      return <div className="align-left">{titleContent}</div>;
    } else {
      return (
        <Grid direction="column" alignItems="center">
          {titleContent}
        </Grid>
      );
    }
  }

  render() {
    return (
      <div className="rc-AuthorModalTitle">
        <div className="authoring-modal-title">{this.renderTitle()}</div>
      </div>
    );
  }
}

type FooterProps = {
  onConfirm?: () => void;
  onCancel?: () => void;

  hideCancel?: boolean;
  hideConfirm?: boolean;

  disableCancel?: boolean;
  disableConfirm?: boolean;

  confirmLabel?: React.ReactNode;
  cancelLabel?: React.ReactNode;

  useConfirmDanger?: boolean;
  trackingName?: string | undefined | null;

  confirmIcon?: React.ReactElement | undefined | null;
};

export class Footer extends React.Component<FooterProps> {
  static defaultProps = {
    confirmLabel: 'Confirm',
    cancelLabel: 'Cancel',
    trackingName: null,
  };

  render() {
    let ConfirmButton;

    if (this.props.trackingName) {
      ConfirmButton = AuthoringTrackedButton;
    } else {
      ConfirmButton = Button;
    }

    return (
      <div className="rc-AuthorModalFooter">
        <div className="footer-actions">
          {!this.props.hideCancel && (
            <Button
              variant="secondary"
              size="medium"
              onClick={this.props.onCancel}
              data-e2e="cancel-button"
              disabled={this.props.disableCancel}
            >
              {this.props.cancelLabel}
            </Button>
          )}

          {!this.props.hideConfirm && (
            <ConfirmButton
              variant="primary"
              size="medium"
              data-e2e="confirm-button"
              onClick={this.props.onConfirm}
              className={classNames({ danger: this.props.useConfirmDanger })}
              disabled={this.props.disableConfirm}
              trackingName={this.props.trackingName as string}
              {...(this.props.confirmIcon && { icon: this.props.confirmIcon })}
              iconPosition="before"
            >
              {this.props.confirmLabel}
            </ConfirmButton>
          )}
        </div>
      </div>
    );
  }
}

export type ModalProps = {
  className?: string;
  dataE2E?: string;
  dataPendo?: string;

  allowClose?: boolean;
  allowStaticBackdrop?: boolean;

  heading: string;
  subHeading?: React.ReactNode;
  titleIcon?: React.ReactNode;
  leftAlignHeading?: boolean;
  rowAlignHeading?: boolean;
  modalName?: string;

  withTitle?: boolean;
  withFooter?: boolean;

  cancelLabel?: React.ReactNode;
  onCancel?: (isFromClickingX?: boolean) => void;

  hideConfirm?: boolean;
  hideCancel?: boolean;
  disableConfirm?: boolean;

  confirmLabel?: React.ReactNode;
  onConfirm?: () => void;
  useConfirmDanger?: boolean;
  trackingName?: string | undefined | null; // uses the AuthoringTrackedButton for the confirm action when passed in
  confirmIcon?: React.ReactElement | undefined | null;
  children?: React.ReactNode;
  'data-js'?: string;

  skipCancelModal?: boolean;
};

class Modal extends React.Component<ModalProps> {
  static defaultProps = {
    allowClose: true,
    useConfirmDanger: false,
    trackingName: null,
  };

  handleClose = () => {
    // pass `true` to indicate onCancel was called from clicking 'x', not cancel button
    this.props.onCancel?.(true);
  };

  render() {
    const {
      className,
      heading,
      subHeading,
      leftAlignHeading,
      rowAlignHeading,
      allowClose,
      allowStaticBackdrop,
      children,
      withTitle,
      titleIcon,
      withFooter,
      onCancel,
      onConfirm,
      confirmLabel,
      cancelLabel,
      hideCancel,
      hideConfirm,
      disableConfirm,
      useConfirmDanger,
      trackingName,
      confirmIcon,
    } = this.props;

    return (
      <PhoenixModal
        className={classNames('rc-AuthorModal', className)}
        modalName={heading}
        handleClose={this.handleClose}
        data-js={this.props['data-js']}
        data-e2e={this.props.dataE2E}
        dataPendo={this.props.dataPendo}
        allowClose={allowClose}
        allowStaticBackdrop={allowStaticBackdrop}
      >
        {withTitle && (
          <Title icon={titleIcon} leftAlignHeading={leftAlignHeading} rowAlignHeading={rowAlignHeading}>
            {heading}
            {subHeading && (
              <Typography2 variant="bodySecondary" component="div" css={styles.subHeading}>
                {subHeading}
              </Typography2>
            )}
          </Title>
        )}
        <div className="authoring-modal-body">{children}</div>
        {withFooter && (
          <Footer
            onCancel={onCancel}
            onConfirm={onConfirm}
            confirmLabel={confirmLabel}
            cancelLabel={cancelLabel}
            hideCancel={hideCancel}
            hideConfirm={hideConfirm}
            disableConfirm={disableConfirm}
            useConfirmDanger={useConfirmDanger}
            trackingName={trackingName}
            confirmIcon={confirmIcon}
          />
        )}
      </PhoenixModal>
    );
  }
}

export default Modal;
