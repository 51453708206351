import * as React from 'react';

import Imgix from 'js/components/Imgix';

type SizedImageProps = {
  src: string;
  alt?: string;
  width: number;
  height: number;
};

export const SizedImage: React.FC<SizedImageProps> = (props) => {
  const { src, alt, width, height } = props;
  return (
    <Imgix
      src={src}
      alt={alt || Imgix.DECORATIVE}
      maxWidth={width}
      maxHeight={height}
      style={{ width: `${width}px`, height: `${height}px` }}
    />
  );
};

export type Copy = JSX.Element | string;
export type MaybeCopy = Copy | undefined;
