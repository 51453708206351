/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import _t from 'i18n!nls/skills-common';

import ProgressTrackerPopoverContent from './ProgressTrackerPopoverContent';
import { generateBenchmarkCopyForSkillScoring } from './messages';

type BenchmarkScorePopoverContentProps = {
  score?: number;
  target?: number;
  skillName?: string;
};

const styles = {
  noScoreText: {
    fontSize: 12,
    color: '#666666',
  },
};

const BenchmarkScorePopoverContent: React.FC<BenchmarkScorePopoverContentProps> = (props) => {
  const { score, skillName, target } = props;

  if (target === undefined) {
    return (
      <ProgressTrackerPopoverContent trackingData={{ skillName, score, target }} score={target} skillName={skillName}>
        <div css={styles.noScoreText}>{_t('There is no set target for this skill.')}</div>
      </ProgressTrackerPopoverContent>
    );
  }

  const benchmarkCopy = generateBenchmarkCopyForSkillScoring(target);

  return (
    <ProgressTrackerPopoverContent
      trackingData={{ skillName, score, target }}
      score={target}
      copy={benchmarkCopy}
      skillName={skillName}
    />
  );
};

export default BenchmarkScorePopoverContent;
