import * as React from 'react';
import { useContext } from 'react';

import type { ApolloError } from '@apollo/client';

import type { CollectionTypeForRecsTab } from 'bundles/program-personalized-tab/types/sharedTypes';

export type CourseRecsContextType = {
  recs: CollectionTypeForRecsTab;
  loading?: boolean;
  error?: ApolloError;
  leadershipRecs?: CollectionTypeForRecsTab;
  leadershipRecsLoading?: boolean;
  leadershipRecsError?: ApolloError;
};

const CourseRecsContext = React.createContext<CourseRecsContextType | undefined>(undefined);

export const useCourseRecsData = () => {
  const courseRecsContext = useContext(CourseRecsContext);
  return courseRecsContext;
};

export default CourseRecsContext;
