/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useRef } from 'react';
import type Slider from 'react-slick';

import type { EnterpriseSkills_Skillset as SkillSet } from '__generated__/graphql-types';

import { Grid, Hidden, breakpoints, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';
import { breakPoint } from '@coursera/coursera-ui';

import Carousel from 'bundles/design-system/components/Carousel';
import type { CarouselSettings } from 'bundles/design-system/components/types';
import { SkillSetCardV2, SkillSetCardV2Placeholder } from 'bundles/program-home/components/multiprogram/SkillSetCardV2';
import type { ObjectiveListQuery_EnterpriseTargetSkillProfileSummariesV1Resource_byProgram_elements as TargetSkillProficiencySummary } from 'bundles/program-home/components/multiprogram/__generated__/ObjectiveListQuery';

type SkillSetGridProp = {
  objectives: TargetSkillProficiencySummary[] | SkillSet[];
  programSlug: string;
  filterTrackingData: string[];
};

type PlaceholderProp = {
  skillSetLength?: number;
};

export const MAX_NUMBER_OF_SKILLSETS = 6;

const EnterpriseCarouselSettings: CarouselSettings = {
  dots: false,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  isFluid: true,
  // react-slick is super broken in RTL, so we have to use tricks to make it work. This means disabling `lazyLoad`,
  // since it assumes LTR and doesn't even render the things in the viewport, and doing tricks to fixup the position
  // when it loads (see tricks in static/bundles/design-system/components/Carousel.tsx).
  lazyLoad: undefined,
  responsive: [
    {
      breakpoint: breakPoint.lg,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: breakPoint.md,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: breakPoint.sm,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: breakPoint.xs,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const styles = {
  container: (theme: Theme) =>
    css({
      padding: '0 40px',
      [breakpoints.up('lg')]: {
        padding: 0,
      },
    }),
  carousel: css({ width: '100%' }),
  slide: (theme: Theme) => css({ padding: 'var(--cds-spacing-100)' }),
};

export const SkillSetsCarousel = ({ objectives, programSlug, filterTrackingData }: SkillSetGridProp) => {
  const carouselRef = useRef<Slider | null>(null);
  // Reset carousel current slide after filter
  useEffect(() => {
    carouselRef.current?.slickGoTo(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(objectives)]);

  const theme = useTheme();
  return (
    <div css={styles.container(theme)}>
      <Carousel
        settings={EnterpriseCarouselSettings}
        css={styles.carousel}
        setSlickRef={(ref) => {
          carouselRef.current = ref;
        }}
      >
        {objectives.slice(0, MAX_NUMBER_OF_SKILLSETS).map((objective, index) => (
          <div key={objective.id} css={styles.slide}>
            <SkillSetCardV2
              skillSummary={objective}
              programSlug={programSlug}
              skillSetOrder={index + 1}
              filterTrackingData={filterTrackingData}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export const SkillSetsCarouselPlaceholder: React.FC<PlaceholderProp> = ({ skillSetLength }) => (
  <Grid container spacing={24}>
    {[...Array(skillSetLength && skillSetLength <= 3 ? skillSetLength : 3).keys()].map((placeholder, index) => (
      <Grid
        component={Hidden}
        item
        md={4}
        sm={index < 4 ? 6 : undefined}
        xs={index < 2 ? 12 : undefined}
        smDown={index >= 4}
        xsDown={index >= 2}
        key={placeholder}
      >
        <SkillSetCardV2Placeholder />
      </Grid>
    ))}
  </Grid>
);

export default SkillSetsCarousel;
