import _ from 'lodash';

export type ProductId = {
  id: string;
  isCourse: boolean;
};

export type ProductCardType = 'course' | 'degree' | 's12n' | 'clip';

export function getProductId(id: string, isCourse: boolean, productType?: string): string {
  const idType = productType ?? (isCourse ? 'course' : 's12n');
  return `${idType}~${id}`;
}

export function extractProductId(productId: string): ProductId {
  const pos = productId.indexOf('~');
  const type = productId.substring(0, pos);
  const id = productId.substring(pos + 1);
  return { id, isCourse: type === 'course' };
}

/**
 * Convert courseIds + s12nIds to product ids (`course~<course_id>` or `specialization~<s12n_id>`)
 * @param courseIds course ids.
 * @param s12nIds specialization ids.
 */
export function getProductIds(courseIds: string[], s12nIds: string[]): string[] {
  return _.concat(
    _.map(courseIds, (id) => getProductId(id, true)),
    _.map(s12nIds, (id) => getProductId(id, false))
  );
}

/**
 * Convert productIds (`course~<course_id>` or `specialization~<s12n_id>`) back to courseIds + s12nIds.
 * @param productIds product ids.
 */
export function extractProductIds(productIds: string[]): [string[], string[]] {
  const courseIds: string[] = [];
  const s12nIds: string[] = [];

  _.forEach(productIds, (productId) => {
    const { id, isCourse } = extractProductId(productId);
    if (isCourse) {
      courseIds.push(id);
    } else {
      s12nIds.push(id);
    }
  });

  return [courseIds, s12nIds];
}

export const PRODUCT_TYPE: {
  [key: string]: ProductCardType;
} = {
  COURSE: 'course',
  DEGREE: 'degree',
  S12N: 's12n',
  CLIP: 'clip',
};
