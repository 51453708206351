import * as React from 'react';
import { useCallback, useMemo } from 'react';

// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import CMLEditorProvider from 'bundles/cml/editor/components/CMLEditorProvider';
import type { Props } from 'bundles/cml/editor/types/cmlEditorProps';
import type { CmlContent as NaptimeCmlContent } from 'bundles/cml/legacy/types/Content';
import { getDtdId, getValue } from 'bundles/cml/legacy/utils/CMLUtils';

export type CMLEditorNaptimeProps = Props<NaptimeCmlContent>;

const CMLEditorNaptime: React.FC<CMLEditorNaptimeProps> = React.memo(
  ({ initialCML, onContentChange, children, ...props }) => {
    const cmlValue = useMemo(() => getValue(initialCML), [initialCML]);
    const dtdId = useMemo(() => getDtdId(initialCML), [initialCML]);

    const handleChange = useCallback(
      (value: string) => {
        onContentChange({
          typeName: 'cml',
          definition: { dtdId, value },
        });
      },
      [onContentChange, dtdId]
    );

    return (
      <CMLEditorProvider {...props} cmlValue={cmlValue} onChange={handleChange}>
        {children}
      </CMLEditorProvider>
    );
  }
);

export default CMLEditorNaptime;
