import * as React from 'react';
import { useEffect, useState } from 'react';

import { Typography2, VisuallyHidden } from '@coursera/cds-core';

import LoadingSpinner from 'bundles/coursera-ui/components/basic/LoadingSpinner';
import CurrentAppliedFilters from 'bundles/enterprise-collections/components/filters/CurrentAppliedFilters';
import SearchFilter from 'bundles/enterprise-collections/components/filters/SearchFilter';
import SearchFilterPlaceholder from 'bundles/enterprise-collections/components/filters/SearchFilterPlaceholder';
import { DOMAIN_PAGE_SEARCH_FILTERS } from 'bundles/enterprise-learner-search/constants';
import type { FilterData } from 'bundles/search-common/constants/filter';
import { DESKTOP_FILTER_LIMIT, FILTER_INDEX_TO_FILTER_DATA } from 'bundles/search-common/constants/filter';
import type { SearchFilterOption } from 'bundles/search-common/types';

import _t from 'i18n!nls/enterprise-collections';

import 'css!./__styles__/DesktopSearchFilters';

type Filters = Record<string, SearchFilterOption[] | undefined>;
type Props = {
  activeFilters: string[];
  filters: Filters;
  addFilters?: (x0: string) => void;
  removeFilters?: (filters: string[]) => void;
  clearFilters?: () => void;
  isLoading?: boolean;
};

type State = {
  filtersAvailable: { language: boolean; partners: boolean; skills: boolean; productDifficultyLevel: boolean };
  componentDidMount: boolean;
};

const appendAriaLabelFilterOptions = (activeFilters: string[]) => {
  let ariaLabelFilterOptionsText = '';
  if (activeFilters.length > 1) {
    activeFilters.forEach((option, idx) => {
      ariaLabelFilterOptionsText += idx + 1 === activeFilters.length ? 'and ' + option : option + ', ';
    });
  }
  return ariaLabelFilterOptionsText;
};

export const DesktopSearchFilters = (props: Props) => {
  const { activeFilters, filters, addFilters, removeFilters, clearFilters, isLoading } = props;
  const filterAttributes = DOMAIN_PAGE_SEARCH_FILTERS;

  const [filtersAvailable, setFiltersAvailable] = useState({
    language: false,
    partners: false,
    skills: false,
    productDifficultyLevel: false,
  });
  const [componentDidMount, setComponentDidMount] = useState(false);
  const areAnyFiltersAvailable = Object.values(filtersAvailable).some((f) => f);

  useEffect(() => setComponentDidMount(true), []);

  const ariaSelectedFiltersLabel = _t('Results filtered by #{selectedFilterOptions}', {
    selectedFilterOptions: appendAriaLabelFilterOptions(activeFilters),
  });
  const currentAriaLabelForFilters = filters?.length ? ariaSelectedFiltersLabel : '';

  const updateFilterAvailability = (attribute: string, filtersIsAvailable: boolean) => {
    // We want a way to see if there are any filters available
    filtersAvailable[attribute as keyof State['filtersAvailable']] = filtersIsAvailable;
    setFiltersAvailable(filtersAvailable);
  };

  const FilterButtons = filterAttributes
    .map((filterIndex) => FILTER_INDEX_TO_FILTER_DATA[filterIndex])
    .map((filter?: FilterData) => {
      if (!filter) return undefined;
      const { getName, property } = filter;
      if (!componentDidMount) return <SearchFilterPlaceholder key={`SearchFilterPlaceholder~${property}`} />;
      const name = getName();
      const currentRefinementForFacet = activeFilters.filter((value) => value.includes(property));
      return (
        <SearchFilter
          items={filters[property] || []}
          searching={!!isLoading}
          currentRefinement={currentRefinementForFacet}
          key={name}
          filterLabel={name}
          updateFilterAvailability={updateFilterAvailability}
          filterName={name}
          attribute={property}
          limit={DESKTOP_FILTER_LIMIT}
          addFilters={addFilters}
          removeFilters={(filtersToRemove: string[]) => removeFilters?.(filtersToRemove)}
        />
      );
    });

  return (
    <div className="rc-DesktopSearchFilters programHome-filter" data-testid="filter-wrapper">
      <div className="filterby-section">
        {areAnyFiltersAvailable && (
          <Typography2 variant="bodySecondary" component="h3" className="filter-by">
            {_t('Filter By')}
          </Typography2>
        )}
      </div>
      <div className="filters-section horizontal-box">
        {areAnyFiltersAvailable}
        {FilterButtons}
      </div>
      <CurrentAppliedFilters
        currentFilters={activeFilters}
        removeFilters={(filterItem: string) => removeFilters?.([filterItem])}
        clearFilters={clearFilters}
      />
      {isLoading && (
        <>
          <LoadingSpinner size={24} />
        </>
      )}
      <VisuallyHidden component="span" className="rc-A11yScreenReaderOnly">
        <div role="alert" aria-live="polite">
          {currentAriaLabelForFilters}
        </div>
      </VisuallyHidden>
    </div>
  );
};

export default DesktopSearchFilters;
