import * as React from 'react';

import styled from '@emotion/styled';

import { color } from '@coursera/coursera-ui';

import { Header } from 'bundles/enterprise-ui/components/Header';

const Container = styled.div({
  position: 'absolute',
  zIndex: 3000,
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
  overflow: 'auto',
  backgroundColor: color.white,
});

export const Overlay = ({ children }: { children: React.ReactNode }) => (
  <Container id="overlay">
    <Header />
    {children}
  </Container>
);

Overlay.displayName = 'Overlay';
