/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { Link as ReactRouterLink } from 'react-router';

import { Link, Typography, useTheme } from '@coursera/cds-core';
import { Button } from '@coursera/coursera-ui';
import { SvgLoaderSignal } from '@coursera/coursera-ui/svg';

// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import ProductListWithExpandToggle from 'bundles/course-cards/components/course-card/enterprise/ProductListWithExpandToggle';
import Dropdown from 'bundles/phoenix/components/Dropdown';
import { toProgramSkillSet } from 'bundles/program-common/links';
import type { PropsFromSaveSkillSetToggle } from 'bundles/program-home/components/ProgramHomeApp';
import CourseCompletionBanner from 'bundles/program-home/components/SkillSetCourseCompletionBanner';
import { org_coursera_enterprise_skills_TargetSkillProfileUserGoalState as SkillProfileStates } from 'bundles/program-home/components/__generated__/globalTypes';
import type { ProductStub } from 'bundles/program-home/types/Products';

import _t from 'i18n!nls/program-skills-objectives';

type PropsFromCaller = PropsFromSaveSkillSetToggle & {
  targetSkillProfileId: string;
  tspTitle?: string;
  objectiveSlug?: string;
  products: Array<ProductStub>;
  programId: string;
  programSlug: string;
  userId: number;
  thirdPartyOrganizationId: string;
};

export type Props = PropsFromCaller;

const SkillSetEnrolledProducts: React.FC<Props> = ({
  tspTitle,
  targetSkillProfileId,
  objectiveSlug,
  products,
  programId,
  programSlug,
  userId,
  onSkillSetSaveToggle,
  thirdPartyOrganizationId,
}) => {
  const [saveInProgress, setSaveInProgress] = React.useState(false);
  const theme = useTheme();

  // ---

  const styles = {
    titleContainer: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: var(--cds-spacing-150);
    `,
    actionsContainer: css`
      display: flex;
    `,
    tspLink: css`
      margin-right: var(--cds-spacing-200);
    `,
  };

  const hasCompletedProducts = products.some((product) => product.isCompleted);
  const hasInProgressProducts = products.some((product) => product.isEnrolled && !product.isCompleted);
  const showCourseCompletionBanner = (hasCompletedProducts && !hasInProgressProducts) || !products.length;
  const dropdownLabel = _t('SkillSet actions for #{tspTitle}', { tspTitle });
  const dropdownItem = (
    <Button
      label={_t('Remove from saved')}
      onClick={() =>
        onSkillSetSaveToggle({ targetSkillProfileId, state: SkillProfileStates.UNSAVED, setSaveInProgress })
      }
      type="noStyle"
      size="zero"
    />
  );

  return (
    <div className="rc-SkillSetEnrolledProducts">
      <div css={styles.titleContainer}>
        <Typography component="p" variant="h2">
          {tspTitle}
        </Typography>
        <div css={styles.actionsContainer}>
          {objectiveSlug && programSlug && (
            <Link
              component={ReactRouterLink}
              variant="quiet"
              to={toProgramSkillSet(programSlug, objectiveSlug)}
              aria-label={_t('View SkillSet: #{name}', { name: tspTitle })}
              css={styles.tspLink}
              typographyVariant="bodyPrimary"
            >
              {_t('View SkillSet')}
            </Link>
          )}
          {saveInProgress ? (
            <SvgLoaderSignal size={10} />
          ) : (
            <Dropdown
              dropdownId={`${tspTitle}-dropdown-menu`}
              label={dropdownLabel}
              listItems={[dropdownItem]}
              iconClassName="ellipsis-h"
            />
          )}
        </div>
      </div>
      {showCourseCompletionBanner && objectiveSlug && programSlug && (
        <CourseCompletionBanner href={toProgramSkillSet(programSlug, objectiveSlug)} tspTitle={tspTitle} />
      )}
      {products.length > 0 && (
        <ProductListWithExpandToggle
          products={products}
          programId={programId}
          programSlug={programSlug}
          userId={userId}
          onSkillSetSaveToggle={onSkillSetSaveToggle}
          viewMoreLessContext={_t('courses from SkillSet: #{skillSetName}', { skillSetName: tspTitle })}
          thirdPartyOrganizationId={thirdPartyOrganizationId}
        />
      )}
    </div>
  );
};

export default SkillSetEnrolledProducts;
