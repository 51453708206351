import type { BaseComponentProps } from '@coursera/cds-common';

import { classes } from '@core/cards/common/getCommonCardCss';

type Props = {
  /**
   * Pass the ratings/reviews component
   */
  children?: React.ReactNode;
} & BaseComponentProps<'div'>;

/**
 * Placeholder to render the ratings/reviews component
 */
const CardRatings = ({ children, ...rest }: Props) => {
  return (
    <div className={classes.ratings} {...rest}>
      {children}
    </div>
  );
};

export default CardRatings;
