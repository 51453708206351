/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Grid, PageGridContainer, Typography2, breakpoints, useMediaQuery } from '@coursera/cds-core';

import { ShowMoreGridSection } from 'bundles/cds-labs/components/';
import {
  CARD_LIST_BREAKPOINTS_CONFIG,
  DEFAULT_GRID_BREAKPOINTS_CONFIG,
} from 'bundles/cds-labs/components/ShowMoreGridSection/constants';
import EnterpriseLearningPathProductCard from 'bundles/page-config-common/components/enterprise/EnterpriseLearningPathProductCard';
import type { EnterpriseBadgeWithCollection } from 'bundles/page-config-common/providers/enterprise/EnterpriseBadgeCollectionsProvider';

import _t from 'i18n!nls/consumer-home';

type Props = {
  enterpriseBadgesWithCollection: Array<EnterpriseBadgeWithCollection>;
};

const styles = {
  title: css`
    margin-top: var(--cds-spacing-600);
  `,
  showMoreGrid: css`
    padding-top: 0;
    margin: 0 -12px;
  `,
};

const EnterpriseLearningPathsModule = ({ enterpriseBadgesWithCollection }: Props) => {
  const isMobile = useMediaQuery(breakpoints.down('xs'));
  const isTabletOrSmaller = useMediaQuery(breakpoints.down('sm'));

  const shouldShowDesktopListView = enterpriseBadgesWithCollection.length < 3 && !isMobile && !isTabletOrSmaller;
  const shouldShowListView = shouldShowDesktopListView || isTabletOrSmaller || isMobile;

  const numDisplayedRows = isMobile ? 2 : 1;

  return (
    <PageGridContainer data-testid="enterprise-learning-paths" className="learning-path-container">
      <Grid item xs={12}>
        <Typography2 component="h3" variant="titleSmall" css={styles.title}>
          {_t('Learning paths created by your organization')}
        </Typography2>
        <ShowMoreGridSection<EnterpriseBadgeWithCollection>
          css={styles.showMoreGrid}
          items={enterpriseBadgesWithCollection}
          itemsGridConfig={shouldShowListView ? CARD_LIST_BREAKPOINTS_CONFIG : DEFAULT_GRID_BREAKPOINTS_CONFIG}
          defaultRows={numDisplayedRows}
          renderItem={({ item, itemIndex }) => (
            <EnterpriseLearningPathProductCard
              isSingularView={false}
              enterpriseBadgeWithCollection={item}
              numOfEnterpriseBadges={enterpriseBadgesWithCollection.length}
              itemIndex={itemIndex}
            />
          )}
        />
      </Grid>
    </PageGridContainer>
  );
};

export default EnterpriseLearningPathsModule;
