import * as React from 'react';

import URI from 'jsuri';
import { compose, withProps } from 'recompose';

import connectToRouter from 'js/lib/connectToRouter';

import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { TrackedA } from 'bundles/page/components/TrackedLink2';
import { FACEBOOK_APP_ID, FACEBOOK_SOURCE_PARAM } from 'bundles/sharing-common/constants';
import type {
  SocialButtonPropsFromCaller,
  SocialButtonPropsToComponent,
} from 'bundles/sharing-common/types/sharingSharedTypes';
import { generateJsUriObjCurrentPage } from 'bundles/sharing-common/utils/utils';
import withCustomUrl from 'bundles/sharing-common/utils/withCustomUrl';
import withFormattedShareLink from 'bundles/sharing-common/utils/withFormattedShareLink';

import _t from 'i18n!nls/sharing-common';

const BASE_FACEBOOK_SHARE_URL = 'https://www.facebook.com/dialog/share';

class FacebookButton extends React.Component<SocialButtonPropsToComponent> {
  render() {
    const { customShareLink, createSocialShareLink, targetShareLink, withLabel, onSocialButtonClick } = this.props;

    return (
      <TrackedDiv className="rc-FacebookButton social-media-button" trackingName="social_share_button" trackMouseEnters>
        <TrackedA
          trackingName="facebook_share_button"
          className="social-media-button_link"
          href={createSocialShareLink(customShareLink || targetShareLink)}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onSocialButtonClick}
          ariaLabel={_t('Share with Facebook')}
        >
          <svg
            className="social-media-button_icon"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.155884 24.7208C0.155884 11.0678 11.2237 0 24.8767 0C38.5296 0 49.5974 11.0678 49.5974 24.7208C49.5974 38.3737 38.5296 49.4416 24.8767 49.4416C11.2237 49.4416 0.155884 38.3737 0.155884 24.7208ZM30.8898 12.5908L34.309 12.5893H34.3095V6.56725L29.3269 6.55967C24.3979 6.55967 21.0217 9.56915 21.0217 15.095V19.8565H15.4468V26.313H21.0217V42.8819H27.6906V26.313H34.0809L34.0839 19.8565H27.6901V15.7343C27.6901 13.8649 28.2086 12.5908 30.8898 12.5908Z"
              fill="#2A73CC"
            />
          </svg>
          {withLabel && <p className="social-media-button_label">{_t('Facebook')}</p>}
        </TrackedA>
      </TrackedDiv>
    );
  }
}

export default compose<SocialButtonPropsToComponent, SocialButtonPropsFromCaller>(
  // order of these are important
  connectToRouter((router) => ({ router })),
  withFormattedShareLink(FACEBOOK_SOURCE_PARAM),
  withProps(({ postShareRedirectLink, router }: SocialButtonPropsToComponent) => {
    const createSocialShareLink = (shareLink: string) => {
      const facebookButtonUrl = new URI(BASE_FACEBOOK_SHARE_URL);
      const redirectUrl = postShareRedirectLink ? new URI(postShareRedirectLink) : generateJsUriObjCurrentPage(router);

      facebookButtonUrl
        .addQueryParam('app_id', FACEBOOK_APP_ID)
        .addQueryParam('redirect_uri', redirectUrl.addQueryParam('shared', 'facebook').toString())
        .addQueryParam('href', shareLink);

      return facebookButtonUrl.toString();
    };

    return {
      createSocialShareLink,
    };
  }),
  withCustomUrl()
)(FacebookButton);
