import * as React from 'react';

import type { RenderElementProps } from 'slate-react';

// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import FillableBlankRender from 'bundles/cml/shared/components/fillableBlank/FillableBlank';
import { useFillableBlanksContext } from 'bundles/cml/viewer/context/fillableBlanksContext';

const FillableBlank: React.FC<RenderElementProps> = (props) => {
  const { enableFillableBlanksV2 } = useFillableBlanksContext();
  return <FillableBlankRender {...props} enableFillableBlanksV2={enableFillableBlanksV2} />;
};

export default FillableBlank;
