/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useContext, useRef, useState } from 'react';

import { useFocusRing } from '@coursera/cds-core';
import { MoreVerticalFilledIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import MoreFloatingMenu from 'bundles/cml/editor/components/buttons/more/MoreFloatingMenu';
import { FocusContext } from 'bundles/cml/editor/context/focusContext';
import { usePrevious } from 'bundles/cml/shared/hooks/usePrevious';
import type { ToolbarToolsKeys, ToolsKeys } from 'bundles/cml/shared/utils/customTools';

import _t from 'i18n!nls/cml';

const styles = {
  more: css`
    margin-left: auto;
  `,
};

export type Props = {
  tools: ToolsKeys[];
  menuDirection?: 'top' | 'bottom';
  overflowTools: ToolbarToolsKeys[];
};

const MoreButton: React.FC<Props> = (props: Props) => {
  const { ...buttonProps } = props;

  const [ref, setRef] = useState<HTMLButtonElement | null>(null);
  const [moreMenuOpen, setMoreMenuOpen] = useState<boolean>(false);
  const { setFocused } = useContext(FocusContext);

  const { isFocusVisible, focusProps } = useFocusRing();

  const handleOpen = useCallback(
    (menuOpen: boolean) => {
      if (menuOpen) {
        setFocused(true);
        setMoreMenuOpen(true);
      } else {
        setMoreMenuOpen(false);
        if (isFocusVisible) {
          ref?.focus();
        }

        setTimeout(() => {
          setFocused(false);
        }, 250);
      }
    },
    [setFocused, isFocusVisible, ref]
  );

  const autoFocus = usePrevious(isFocusVisible);

  return (
    <div css={styles.more}>
      <Button
        ref={setRef}
        {...focusProps}
        onClick={() => handleOpen(!moreMenuOpen)}
        title={_t('More options')}
        className="rc-MoreButton"
        active={moreMenuOpen}
        type={TOOLBAR_BUTTON_TYPES.menu}
      >
        <MoreVerticalFilledIcon size="small" />
      </Button>
      {moreMenuOpen && (
        <MoreFloatingMenu
          anchorEl={ref}
          onClose={() => handleOpen(false)}
          autoFocus={autoFocus ?? false}
          {...buttonProps}
        />
      )}
    </div>
  );
};

export default MoreButton;
