import * as React from 'react';
import { useCallback } from 'react';

import { useSlate, useSlateStatic } from 'slate-react';

import { LinkIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import { addLink, isLinkDisabled } from 'bundles/cml/editor/components/buttons/link/linkUtils';
import { hasAncestorOfType } from 'bundles/cml/editor/utils/slateUtils';
import { getToolBarShortcutKey } from 'bundles/cml/editor/utils/toolbarUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';

import _t from 'i18n!nls/cml';

const LinkButton: React.FC = () => {
  const editor = useSlate();
  const staticEditor = useSlateStatic();

  const disabled = isLinkDisabled(editor);
  const active = hasAncestorOfType(editor, BLOCK_TYPES.LINK);

  const handleClick = useCallback(() => addLink(staticEditor), [staticEditor]);

  const shortcutKey = getToolBarShortcutKey('K');
  return (
    <Button
      onClick={handleClick}
      disabled={disabled}
      active={active}
      className="rc-LinkButton"
      title={_t('Insert link #{shortcutKey}', { shortcutKey })}
      type={TOOLBAR_BUTTON_TYPES.insertion}
    >
      <LinkIcon size="small" />
    </Button>
  );
};
export default LinkButton;
