import * as React from 'react';

import AuthorEvaluatorSectionCaption from 'bundles/author-code-evaluator/components/AuthorEvaluatorSectionCaption';
import AuthorEvaluatorSectionHeader from 'bundles/author-code-evaluator/components/AuthorEvaluatorSectionHeader';
import StarterCode from 'bundles/author-code-evaluator/models/StarterCode';
import type { StarterCodeType } from 'bundles/author-code-evaluator/models/StarterCode';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import CodeBlockV2 from 'bundles/code-evaluator/components/CodeBlockV2';

import _t from 'i18n!nls/author-code-evaluator';

export type Props = {
  starterCode: StarterCodeType;
  onChange: (starterCode: StarterCodeType) => void;
  isReadOnly?: boolean;
};

const SimpleStarterCodeEditor = ({ starterCode, onChange, isReadOnly }: Props) => {
  const { expression, language } = starterCode;

  const handleChange = (newExpression: StarterCodeType['expression']) => {
    const newStarterCode = new StarterCode({ expression: newExpression, language });

    onChange(newStarterCode);
  };

  return (
    <div className="rc-SimpleStarterCodeEditor">
      <AuthorEvaluatorSectionHeader style={{ paddingTop: 20, paddingBottom: 5, display: 'inline-block' }}>
        {_t('Starter Code')}
      </AuthorEvaluatorSectionHeader>
      <AuthorEvaluatorSectionCaption style={{ marginLeft: 20, display: 'inline-block' }}>
        {_t('Visible to learners')}
      </AuthorEvaluatorSectionCaption>

      <CodeBlockV2 codeLanguage={language} expression={expression} onChange={handleChange} readOnly={!!isReadOnly} />
    </div>
  );
};

export default SimpleStarterCodeEditor;
