import Group from './Group';
import Item from './Item';
import ItemLink from './ItemLink';
import ItemLoading from './ItemLoading';
import Root from './MenuList';

export type { Props as MenuListProps } from './MenuList';
export type { Props as MenuListItemProps } from './Item';
export type { Props as MenuListItemLinkProps } from './ItemLink';
export type { Props as MenuListGroupProps } from './Group';

type RootType = typeof Root;

export interface MenuListType extends RootType {
  /**
   * The MenuList Item component is used to display a menu item.
   *
   * See [Props](__storybookUrl__/components-utility-menu-list--default#props)
   *
   * @see MenuList
   * @see MenuList.Item
   * @see MenuList.ItemLink
   * @see MenuList.ItemLoading
   * @see MenuList.Group
   */
  Item: typeof Item;

  /**
   * The MenuList ItemLink component. Use when you need to render an anchor element inside the menu.
   *
   * See [Props](__storybookUrl__/components-utility-menu-list--default#props)
   *
   * @see MenuList
   * @see MenuList.Item
   * @see MenuList.ItemLink
   * @see MenuList.ItemLoading
   * @see MenuList.Group
   */
  ItemLink: typeof ItemLink;

  /**
   * The MenuList ItemLoading component. Use when you need to render a loading state of the Menu.
   *
   * See [Props](__storybookUrl__/components-utility-menu-list--default#props)
   *
   * @see MenuList
   * @see MenuList.Item
   * @see MenuList.ItemLink
   * @see MenuList.ItemLoading
   * @see MenuList.Group
   */
  ItemLoading: typeof ItemLoading;

  /**
   * The MenuList Group component. Use it to group menu items.
   *
   * See [Props](__storybookUrl__/components-utility-menu-list--default#props)
   *
   * @see MenuList
   * @see MenuList.Item
   * @see MenuList.ItemLink
   * @see MenuList.ItemLoading
   * @see MenuList.Group
   */
  Group: typeof Group;
}

/**
 * The MenuList utility is a responsive container that can be used to display a menu list of menu options or a custom component.
 * MenuList is stateless and does not manage selection state internally.
 *
 * See [Props](__storybookUrl__/components-utility-MenuList--default#props)
 *
 * @see MenuList
 * @see MenuList.Item
 * @see MenuList.ItemLink
 * @see MenuList.ItemLoading
 * @see MenuList.Group
 *
 */
const MenuList: MenuListType = Object.assign(Root, {
  Item,
  ItemLink,
  ItemLoading,
  Group,
});

export { MenuList };
