/**
 * getPropsFromPromise
 *
 * Load data from a promise. It does not block render while it is fetching
 * the promise.
 *
 * 1. If you want to block rendering your component until the data is there, use this
 * in conjunction with `js/lib/waitFor`.
 *
 * module.exports = compose(
 *   getPropsFromPromise((props) => {
 *     return Q.all([
 *     	getCourseFromId(props.courseId),
 *     	getInstructorForCourse(props.courseId)
 *     ]).spread((course, instructor) => ({
 *       course: course
 *       instructor: instructor
 *     }))
 *   }),
 *   waitFor(props => !!course && !!instructor)
 * )(SomeComponent)
 *
 * 2. If you want to custom handle logic in your component while the data is loading,
 * use `js/lib/mapProps` to give yourself an `isLoaded` prop that your component can hook onto
 *
 * module.exports = compose(
 *   getPropsFromPromise((props) => {
 *     return Q.all([
 *     	getCourseFromId(props.courseId),
 *     	getInstructorForCourse(props.courseId)
 *     ]).spread((course, instructor) => ({
 *       course: course
 *       instructor: instructor
 *     }))
 *   }),
 *   mapProps(props => ({
 *     ...props,
 *     isLoaded: !!course && !!instructor
 *   }))
 * )(SomeComponent)*
 */
import * as React from 'react';

import isEqual from 'lodash/isEqual';
import without from 'lodash/without';

import hoistNonReactStatics from 'js/lib/hoistNonReactStatics';

export default (promiseFactoryFn) => {
  return (Component) => {
    const componentName = Component.displayName || Component.name;
    class GetPropsFromPromiseWrapper extends React.Component {
      static displayName = componentName + 'GetPropsFromPromiseWrapper';

      componentDidMount() {
        this.update(this.props);
      }

      componentWillReceiveProps(nextProps) {
        const allKeys = new Set([...Object.keys(nextProps), ...Object.keys(this.props)]);
        const uniqueKeys = [...allKeys];

        const filteredKeys = without(
          uniqueKeys,
          'children',
          'history',
          'location',
          'params',
          'route',
          'routeParams',
          'routes'
        );

        const refresh = filteredKeys.some((propKey) => !isEqual(this.props[propKey], nextProps[propKey]));

        if (refresh) {
          this.update(nextProps);
        }
      }

      update(props) {
        const promise = promiseFactoryFn(props).then((data) => {
          this.setState(data);
        });

        if (promise.catch) {
          promise.catch((e) => {
            throw e;
          });
        }

        if (promise.done) {
          promise.done();
        }
      }

      render() {
        return React.createElement(Component, Object.assign({}, this.props, this.state));
      }
    }
    hoistNonReactStatics(GetPropsFromPromiseWrapper, Component);

    return GetPropsFromPromiseWrapper;
  };
};
