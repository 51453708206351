import * as React from 'react';
import { createContext, useRef, useState } from 'react';

import { useLanguageDetector } from 'bundles/ondemand-translation/language-detection/languageDetector';
import type { LanguageDetector } from 'bundles/ondemand-translation/language-detection/languageDetector';
import { useTranslationProgressTracker } from 'bundles/ondemand-translation/tracker/translationProgressTracker';
import type { TranslationProgressTracker } from 'bundles/ondemand-translation/tracker/translationProgressTracker';

export type TranslatedContentId = number;

export type TranslationContextType = {
  showTranslatedContent: boolean;
  setShowTranslatedContent: (x: boolean) => void;
  languageDetector: LanguageDetector;
  progressTracker: TranslationProgressTracker;
  getTranslatedContentId: () => TranslatedContentId;
};

export const TranslationContext = createContext<TranslationContextType | undefined>(undefined);

type TranslationContextProviderProps = {
  children: React.ReactNode;
};

/**
 * A wrapper component that will hold shared state for all components that utilize on-demand translation.
 *
 * showTranslatedContent: Specifies whether the components should or shouldn't show the translated content. This is
 * used when multiple components should switch to show translated content at the same time.
 *
 * @param props children
 */
export const TranslationContextProvider = (props: TranslationContextProviderProps) => {
  const [showTranslatedContent, setShowTranslatedContent] = useState(false);
  const translatedContentCounter = useRef(0);

  const languageDetector = useLanguageDetector();
  const progressTracker = useTranslationProgressTracker();

  const getTranslatedContentId = (): TranslatedContentId => {
    translatedContentCounter.current += 1;
    return translatedContentCounter.current;
  };

  const contextValue = {
    showTranslatedContent,
    setShowTranslatedContent,
    languageDetector,
    progressTracker,
    getTranslatedContentId,
  };

  return <TranslationContext.Provider value={contextValue}>{props.children}</TranslationContext.Provider>;
};
