/** @jsx jsx */
import { css, jsx, keyframes } from '@emotion/react';

import * as React from 'react';
import { useRef, useState } from 'react';

import { Button, typography2 } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';
import { DropDown } from '@coursera/coursera-ui';

import AuthoringEvaluatorCreate from 'bundles/author-code-evaluator/components/AuthoringEvaluatorCreate';
import type { AuthoringEvaluatorResponse } from 'bundles/author-code-evaluator/utils/AuthoringEvaluatorAPIUtils';
import AuthoringEvaluatorApiUtils from 'bundles/author-code-evaluator/utils/AuthoringEvaluatorAPIUtils';
import type {
  Evaluator,
  EvaluatorTemplatesResponse,
} from 'bundles/cml/editor/components/elements/code/api/authoringEvaluatorTemplates';
import { getEvaluatorTemplatesForTag } from 'bundles/cml/editor/components/elements/code/api/authoringEvaluatorTemplates';
import { useNotificationContext } from 'bundles/cml/editor/context/notificationContext';
import type { LanguageType } from 'bundles/cml/legacy/constants/codeLanguages';
import Modal from 'bundles/phoenix/components/Modal';

import _t from 'i18n!nls/cml';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const styles = {
  root: css`
    display: flex;
    align-items: center;
  `,
  dropdown: css`
    > div {
      border: 1px solid var(--cds-color-interactive-primary-hover) !important;
      border-radius: 4px !important;
      box-shadow: none !important;

      ul {
        list-style: none;
        padding: 12px;
      }

      li {
        margin: 4px 2px !important;
        padding: 8px 6px !important;
        white-space: nowrap;
        ${typography2.bodyPrimary}
        border-radius: 4px;
        background-color: var(--cds-color-neutral-background-primary);
        color: var(--cds-color-neutral-primary);

        &:hover {
          background-color: var(--cds-color-interactive-background-primary-hover-weak);
          color: var(--cds-color-interactive-primary-hover);
        }
      }
    }
  `,
  icon: css`
    animation: ${rotate} 2s infinite linear;
  `,
};

const CUSTOM_EVALUATOR_ID = 'CUSTOM_EVALUATOR_ID';

type Props = {
  language: LanguageType;
  courseId: string;
  branchId: string;
  itemId: string;
  evaluatorId?: string;
  value: string;
  onSave: (evaluatorId: string, value: string) => void;
  handleOpen: () => void;
  toggleEcbConfigModal?: (ecbModalComponent: React.ReactElement | null) => void;
  codeBlockLabel: string;
};

const CMLCodeEditorEvaluatorDropdown: React.FC<Props> = ({
  language,
  courseId,
  branchId,
  itemId,
  evaluatorId,
  value,
  onSave,
  handleOpen,
  toggleEcbConfigModal,
  codeBlockLabel,
}) => {
  const ref = useRef<HTMLButtonElement>(null);
  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [evaluators, setEvaluators] = useState<Array<Evaluator>>([]);

  const { setNotification } = useNotificationContext();

  const handleCreate = (templateEvaluatorId: string) => {
    setLoading(true);
    setDropdownOpen(false);

    AuthoringEvaluatorApiUtils.create({
      courseId,
      branchId,
      itemId,
      evaluatorId: templateEvaluatorId,
    })
      .then((createdEvaluator: AuthoringEvaluatorResponse) => {
        onSave(createdEvaluator.id, value);
        setLoading(false);
        handleOpen();
      })
      .catch(() => {
        setLoading(false);
        setNotification({ message: 'Error while cloning evaluator', type: 'error' });
      })
      .done();
  };

  const handleSelect = (evaluatorKey: string) => {
    if (evaluatorKey === CUSTOM_EVALUATOR_ID) {
      setLoading(false);
      setDropdownOpen(false);

      // pass up the modal component to render for parent CMLEditor
      toggleEcbConfigModal?.(
        <Modal modalName={_t('Clone a custom evaluator')} handleClose={() => toggleEcbConfigModal?.(null)}>
          <AuthoringEvaluatorCreate
            onClickCreate={(newEvaluatorId: string) => {
              toggleEcbConfigModal?.(null);
              handleCreate(newEvaluatorId);
            }}
          />
        </Modal>
      );
    } else {
      handleCreate(evaluatorKey);
    }
  };

  const handleClick = () => {
    if (evaluatorId) {
      handleOpen();
      return;
    }

    if (dropdownOpen) {
      setDropdownOpen(false);
      return;
    }

    setLoading(true);

    getEvaluatorTemplatesForTag(language, courseId)
      .then((response: EvaluatorTemplatesResponse) => {
        setLoading(false);
        setDropdownOpen(true);
        setEvaluators(response.elements);
      })
      .catch(() => {
        setLoading(false);
        setDropdownOpen(false);
        setNotification({ message: 'An unknown error has occurred', type: 'error' });
      });
  };

  return (
    <div className="rc-CMLCodeEditorEvaluatorDropdown" css={styles.root}>
      <Button
        ref={ref}
        size="small"
        onClick={handleClick}
        loading={loading}
        iconPosition="before"
        title={
          evaluatorId
            ? _t('Interactive Settings #{codeBlockLabel}', { codeBlockLabel })
            : _t('Make interactive #{codeBlockLabel}', { codeBlockLabel })
        }
      >
        {evaluatorId ? _t('Interactive Settings') : _t('Make it interactive')}
      </Button>
      {dropdownOpen && (
        <div css={styles.dropdown}>
          <DropDown.MenuV2
            isOpen
            anchorElement={ref.current}
            onRequestClose={() => setDropdownOpen(false)}
            maxWidth={400}
          >
            {evaluators.map((evaluator) => {
              return (
                <DropDown.Item
                  key={evaluator.id}
                  value={evaluator.id}
                  label={evaluator.name}
                  onClick={() => handleSelect(evaluator.id)}
                />
              );
            })}
            <DropDown.Item
              key={CUSTOM_EVALUATOR_ID}
              value={CUSTOM_EVALUATOR_ID}
              label={_t('Enter a custom evaluator')}
              onClick={() => handleSelect(CUSTOM_EVALUATOR_ID)}
            />
          </DropDown.MenuV2>
        </div>
      )}
    </div>
  );
};

export default CMLCodeEditorEvaluatorDropdown;
