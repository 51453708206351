export enum UserPreferenceKeys {
  ONBOARDING = 'ONBOARDING',
  INVITATION = 'INVITATION',
  PAYMENT = 'PAYMENT',
  STORE_CREDIT_CARD = 'saveCreditCard',
  HONORS = 'HONORS',
  S12N_UPGRADE = 'S12N_UPGRADE',
  ADMIN_SELECTED_PARTNER = 'ADMIN_SELECTED_PARTNER',
  PROMOTION_LANDING_PAGE = 'PROMOTION_LANDING_PAGE',
  GENERIC = 'GENERIC',
  RECENTLY_VIEWED_XDP = 'RECENTLY_VIEWED_XDP',
}
