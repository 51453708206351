import React from 'react';

import IdealUpgradeEnrollModal from 'bundles/coursera-plus/components/subscriptionTiers/upgradeToPlus/IdealUpgradeEnrollModal';
import IndiaPriceTestUpgradeModal from 'bundles/coursera-plus/components/subscriptionTiers/upgradeToPlus/IndiaUpgrade/IndiaPriceTestUpgradeModal';
import { getIsLearnerImpressedInIndiaTiersEpic } from 'bundles/coursera-plus/utils/subscriptionTiersUtils';
import usePageData from 'bundles/enroll/data/usePageData';

type PropsFromCaller = {
  selectedStep?: 1 | 2 | 3 | 'upgrade-failure';
  onClose: () => void;
  courseId?: string;
};

const IdealUpgradeEnrollModalContainer: React.FC<PropsFromCaller> = ({ onClose, courseId, selectedStep }) => {
  const { s12n, courseraPlusProductPrice } = usePageData();

  if (getIsLearnerImpressedInIndiaTiersEpic()) {
    return <IndiaPriceTestUpgradeModal onClose={onClose} />;
  }

  return (
    <IdealUpgradeEnrollModal
      courseraPlusProductPrice={courseraPlusProductPrice}
      onClose={onClose}
      courseId={courseId}
      s12nId={s12n?.id}
      selectedStep={selectedStep}
    />
  );
};

export default IdealUpgradeEnrollModalContainer;
