import type React from 'react';

import { isHotkey } from 'is-hotkey';
import { Transforms } from 'slate';
import type { Editor } from 'slate';

import { hasAncestorOfType } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';

const isSoftBreakHotKey = isHotkey('shift+return');

export const softBreaksKeyDownHandler = (editor: Editor, event: React.KeyboardEvent): boolean => {
  const { nativeEvent } = event;
  if (!isSoftBreakHotKey(nativeEvent)) {
    return false;
  }

  const hasTableCell = hasAncestorOfType(editor, BLOCK_TYPES.TABLE_CELL);
  if (hasTableCell) {
    return false;
  }

  event.preventDefault();
  Transforms.insertText(editor, '\n');
  return true;
};
