import moment from 'moment';

import _t from 'i18n!nls/program-personalized-tab';

type ProductType =
  | 'course'
  | 's12n'
  | 'proCert'
  | 'guidedProject'
  | 'DiscoveryCollections_course'
  | 'DiscoveryCollections_specialization'
  | 'DiscoveryCollections_professionalCertificate'
  | 'DiscoveryCollections_guidedProject';

const getProductTypeLabel = (productType: ProductType) => {
  switch (productType) {
    case 'course':
      return _t('Course');
    case 'DiscoveryCollections_course':
      return _t('Course');
    case 's12n':
      return _t('Specialization');
    case 'DiscoveryCollections_specialization':
      return _t('Specialization');
    case 'proCert':
      return _t('Professional Certificate');
    case 'DiscoveryCollections_professionalCertificate':
      return _t('Professional Certificate');
    case 'guidedProject':
      return _t('Guided Project');
    case 'DiscoveryCollections_guidedProject':
      return _t('Guided Project');
    default:
      return '';
  }
};

type ProductMetadata = {
  level?: 'BEGINNER' | 'INTERMEDIATE' | 'ADVANCED';
  productType?: ProductType;
  durationWeeks?: number;
};

export const getProductRecsMetadata = ({ level, productType, durationWeeks }: ProductMetadata): Array<string> => {
  const metadata: Array<string> = [];
  const LEVELS = {
    BEGINNER: _t('Beginner'),
    INTERMEDIATE: _t('Intermediate'),
    ADVANCED: _t('Advanced'),
  };

  if (level) {
    metadata.push(LEVELS[level]);
  }

  if (productType) {
    metadata.push(getProductTypeLabel(productType));
  }

  if (productType === 'guidedProject' || productType === 'DiscoveryCollections_guidedProject') {
    metadata.push(_t('2 hours'));
  } else if (durationWeeks) {
    const momentDuration = moment.duration(durationWeeks, 'weeks');
    const lowerMonthLimit = Math.floor(momentDuration.asMonths());
    const upperMonthLimit = Math.ceil(momentDuration.asMonths());
    let durationFormatted = '';

    if (upperMonthLimit === 0) {
      // both lower and upper are zero
      durationFormatted = _t('#{numberOfWeeks} weeks', { numberOfWeeks: durationWeeks });
    } else if (lowerMonthLimit === 0 && upperMonthLimit === 1) {
      // lower is zero, upper is one
      durationFormatted = _t('1 month');
    } else if (lowerMonthLimit === upperMonthLimit) {
      // integer months
      if (lowerMonthLimit === 1) {
        durationFormatted = _t('#{numberOfMonths} month', { numberOfMonths: lowerMonthLimit });
      } else {
        durationFormatted = _t('#{numberOfMonths} months', { numberOfMonths: lowerMonthLimit });
      }
    } else {
      // non-integer months
      durationFormatted = _t('#{lowerMonthLimit}-#{upperMonthLimit} months', { lowerMonthLimit, upperMonthLimit });
    }

    metadata.push(durationFormatted);
  } else if (
    productType === 's12n' ||
    productType === 'DiscoveryCollections_specialization' ||
    productType === 'proCert' ||
    productType === 'DiscoveryCollections_professionalCertificate'
  ) {
    metadata.push(_t('3-6 months'));
  } else if (productType === 'course' || productType === 'DiscoveryCollections_course') {
    metadata.push(_t('1-3 months'));
  }

  return metadata;
};

export const getFormattedViewCount = (viewCount: number | null | undefined) => {
  if (viewCount) {
    if (viewCount > 999) {
      return `${Math.round(viewCount / 100) / 10}k`;
    }
    return viewCount.toString();
  }

  return undefined;
};

export const getClipsRecsMetadata = (duration: string | undefined) => {
  const metadata = ['Video'];

  if (duration) {
    const formattedDuration = moment.duration(duration).humanize();
    metadata.push(formattedDuration);
  }

  return metadata;
};
