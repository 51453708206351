/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Grid, Typography2 } from '@coursera/cds-core';
import { CheckIcon } from '@coursera/cds-icons';

import { CML } from 'bundles/cml';
import type { BannerInfoBox_cmlLearningObjectives_cml as LearningObjectivesCML } from 'bundles/enterprise-legacy-xdp/components/miniPDP/bannerInfo/__generated__/BannerInfoBox';
import { getNaptimeCMLShape } from 'bundles/program-common/utils/xdpUtils';

const styles = {
  infoTextWrapper: css`
    margin-bottom: 8px;
    width: 100%;
  `,
  iconWrapper: css`
    height: 16px;
    margin-top: 4px;
  `,
  textWrapper: css`
    margin-left: 12px;
    padding-right: 12px;
    width: calc(100% - 28px);
  `,
};

type Props = {
  cml: LearningObjectivesCML;
};

const BannerInfoText: React.FunctionComponent<Props> = ({ cml }) => {
  const cmlNaptimeShape = getNaptimeCMLShape(cml);
  return (
    <Grid container alignItems="flex-start" css={styles.infoTextWrapper} role="listitem">
      <Grid item css={styles.iconWrapper}>
        <CheckIcon size="small" color="interactive" />
      </Grid>
      <Grid item css={styles.textWrapper}>
        <Typography2 component="span" variant="bodySecondary">
          <CML isCdsEnabled={true} cml={cmlNaptimeShape} />
        </Typography2>
      </Grid>
    </Grid>
  );
};

export default BannerInfoText;
