import { useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { CourseraTierSubscriptions_PaymentProcessorId } from '__generated__/graphql-types';
import uniq from 'lodash/uniq';

import store from 'js/lib/coursera.store';
import { stringKeyToTuple } from 'js/lib/stringKeyTuple';
import user from 'js/lib/user';

import { getReadableDateFromTimestamp } from 'bundles/coursera-plus/utils/generalUtils';
import ConvertFreeTrialToActive from 'bundles/enroll/queries/ConvertFreeTrialToActiveMutation.graphql';
import GetCourseIdsByS12nId from 'bundles/enroll/queries/GetCourseIdsByS12nIdQuery.graphql';
import GetCoursesEnrolledViaCourseraPlus from 'bundles/enroll/queries/GetCoursesEnrolledViaCourseraPlusQuery.graphql';
import GetSubscriptionTrialForCourse from 'bundles/enroll/queries/GetSubscriptionTrialForCourseQuery.graphql';
import type {
  ConvertFreeTrialToActiveMutation,
  ConvertFreeTrialToActiveMutationVariables,
} from 'bundles/enroll/queries/__generated__/ConvertFreeTrialToActiveMutation';
import type {
  GetCourseIdsByS12nIdQuery,
  GetCourseIdsByS12nIdQueryVariables,
} from 'bundles/enroll/queries/__generated__/GetCourseIdsByS12nIdQuery';
import type {
  GetCoursesEnrolledViaCourseraPlusQuery,
  GetCoursesEnrolledViaCourseraPlusQueryVariables,
} from 'bundles/enroll/queries/__generated__/GetCoursesEnrolledViaCourseraPlusQuery';
import type {
  GetSubscriptionTrialForCourseQuery,
  GetSubscriptionTrialForCourseQueryVariables,
} from 'bundles/enroll/queries/__generated__/GetSubscriptionTrialForCourseQuery';
import googleCertificateExperiments from 'bundles/epic/clients/GoogleCertificate';
import paymentsExperiments from 'bundles/epic/clients/payments';
import { useNaptime } from 'bundles/naptimejs';
import OpenCourseMembershipsV1 from 'bundles/naptimejs/resources/openCourseMemberships.v1';
import { useGetSpecializationById } from 'bundles/payments-common/hooks/useGetSpecializationById';
import type { ProductType } from 'bundles/payments/common/ProductType';
import {
  COURSERA_PLUS_SUBSCRIPTION,
  SPECIALIZATION,
  SPECIALIZATION_SUBSCRIPTION,
} from 'bundles/payments/common/ProductType';

const { StripeBvConsumer, StripeBvConsumerSandbox, StripeConsumer, StripeConsumerSandbox } =
  CourseraTierSubscriptions_PaymentProcessorId;

const EXPERIMENT_LAUNCH_TIMESTAMP = 1709510400000; // 2023-03-04 00:00:00 UTC

const COURSE_IDS_TO_EXCLUDE_REGEXES = [
  /v[0-9]*-[A-z0-9]*/, // e.g. `v1-2831` (from Spark courses)
];

const CONVERT_FREE_TRIAL_TO_ACTIVE_SUPPORTED_PAYMENT_PROCESSOR_IDS = [
  StripeBvConsumer,
  StripeBvConsumerSandbox,
  StripeConsumer,
  StripeConsumerSandbox,
];

export const generateImpressionForBlockCertInFreeTrial = () =>
  googleCertificateExperiments.get('blockCertInFreeTrialEnabled');

// `subscriptionStartsAt` is only passed in the context of the subscription cancel modal
// to ensure that we are not showing the treatment for previously started subscriptions
export const isBlockCertInFreeTrialEnabled = (subscriptionStartsAt?: number) => {
  const isExperimentEnabled = googleCertificateExperiments.preview('blockCertInFreeTrialEnabled');
  return subscriptionStartsAt
    ? isExperimentEnabled && subscriptionStartsAt > EXPERIMENT_LAUNCH_TIMESTAMP
    : isExperimentEnabled;
};

export const generateImpressionForBlockCertInFreeTrialPhase2 = () =>
  paymentsExperiments.get('blockCertInFreeTrialPhase2Enabled');

export const isBlockCertInFreeTrialPhase2Enabled = () =>
  paymentsExperiments.preview('blockCertInFreeTrialPhase2Enabled');

type CourseEnrollment = {
  name: string;
  photoUrl: string;
  partner: {
    name: string;
    squareLogo: string;
  };
};

type PropsFromNaptime = {
  courseMemberships?: { id: string; isLearner: boolean }[];
};

// TODO remove SPECIALIZATION_SUBSCRIPTION after https://github.com/webedx-spark/subscriptions-application/pull/45 is deployed
const isSpecialization = (productType?: string) =>
  productType ? [SPECIALIZATION, SPECIALIZATION_SUBSCRIPTION].includes(productType) : false;

export const useGetCourseEnrollmentsWithProgressDuringFreeTrial = ({
  productType,
  productItemId,
}: {
  productType: ProductType;
  productItemId?: string;
}) => {
  const [courseEnrollments, setCourseEnrollments] = useState<CourseEnrollment[]>([]);
  const [loading, setLoading] = useState(true);

  const { s12n, loading: loadingS12n } = useGetSpecializationById({
    s12nId: productItemId,
    skip: !isSpecialization(productType),
  });

  const {
    data: { courseMemberships },
    pending: loadingCourseMemberships,
  } = useNaptime<PropsFromNaptime>(() => {
    if (!isSpecialization(productType)) {
      return {};
    }

    return {
      courseMemberships: OpenCourseMembershipsV1.byUser(user.get().id, {}),
    };
  });

  const enrolledCourseIds =
    courseMemberships
      ?.filter((courseMembership) => courseMembership.isLearner)
      .map(({ id }) => stringKeyToTuple(id)[1]) ?? [];

  // We only show one featured course and up to five partner logos,
  // but request additional course enrollments in case of duplicate partners
  const MAX_COURSERA_PLUS_COURSE_ENROLLMENTS_TO_REQUEST = 10;

  const { data: courseraPlusData, loading: loadingPlusEnrollments } = useQuery<
    GetCoursesEnrolledViaCourseraPlusQuery,
    GetCoursesEnrolledViaCourseraPlusQueryVariables
  >(GetCoursesEnrolledViaCourseraPlus, {
    variables: {
      limit: MAX_COURSERA_PLUS_COURSE_ENROLLMENTS_TO_REQUEST,
    },
    context: { clientName: 'gatewayGql' },
    skip: productType !== COURSERA_PLUS_SUBSCRIPTION,
  });

  const courseraPlusCourseEnrollments =
    courseraPlusData?.CourseraTierSubscriptionsQueries?.queryCoursesEnrolledViaCourseraPlusByUser?.elements;

  useEffect(() => {
    if (loadingS12n || loadingCourseMemberships || loadingPlusEnrollments) {
      return;
    }

    let _courseEnrollments: CourseEnrollment[] = [];

    if (productType === COURSERA_PLUS_SUBSCRIPTION && courseraPlusCourseEnrollments) {
      // @ts-expect-error URL: string
      _courseEnrollments = courseraPlusCourseEnrollments.map(({ name, promoPhotoUrl, partners }) => {
        const partner = partners?.[0];

        return {
          name,
          photoUrl: promoPhotoUrl,
          partner: {
            name: partner.name,
            squareLogo: partner.squareLogo,
          },
        };
      });
    } else if (isSpecialization(productType) && s12n && enrolledCourseIds) {
      const { partners, courses } = s12n;
      const partner = partners?.[0];
      // @ts-expect-error URL: string
      _courseEnrollments = courses
        .filter(({ id }) => enrolledCourseIds.includes(id))
        .map(({ name, promoPhotoUrl }) => ({
          name,
          photoUrl: promoPhotoUrl,
          partner: {
            name: partner.name,
            squareLogo: partner.squareLogo,
          },
        }));
    }

    setCourseEnrollments(_courseEnrollments);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loadingS12n,
    loadingCourseMemberships,
    loadingPlusEnrollments,
    s12n?.id,
    enrolledCourseIds?.length,
    courseraPlusCourseEnrollments?.length,
  ]);

  return {
    courseEnrollments,
    loading,
  };
};

export const formatCourseEnrollmentsProgressData = (courseEnrollments: CourseEnrollment[]) => {
  const MAX_PARTNER_LOGO_COUNT = 5;
  const courseEnrollmentsLength = courseEnrollments.length;
  if (!courseEnrollmentsLength) {
    return null;
  }

  const featuredCourse = courseEnrollments[0];
  let uniquePartnerLogos: Array<string> = [];
  let countOfPartnerLogosToRender = 0;
  const countOfRemainingCourses = courseEnrollmentsLength - 1;
  if (courseEnrollmentsLength > 1) {
    const partnerLogos: Array<string> = [];
    courseEnrollments.slice(1).forEach((courseData) => {
      if (courseData.partner.squareLogo) {
        partnerLogos.push(courseData.partner.squareLogo);
      }
    });
    uniquePartnerLogos = uniq(partnerLogos);
    countOfPartnerLogosToRender = Math.min(MAX_PARTNER_LOGO_COUNT, uniquePartnerLogos.length);
  }
  return {
    courseName: featuredCourse.name,
    partnerName: featuredCourse.partner.name,
    courseLogo: featuredCourse.photoUrl,
    partnerLogos: countOfPartnerLogosToRender > 0 ? uniquePartnerLogos.slice(0, countOfPartnerLogosToRender) : null,
    countOfRemainingCourses,
  };
};

export const useGetActiveFreeTrialsForUserAndProduct = ({
  courseId,
  s12nId,
  skipGetFreeTrials,
}: {
  courseId?: string;
  s12nId?: string;
  skipGetFreeTrials: boolean;
}) => {
  const isMissingProduct = !(courseId || s12nId);

  const {
    loading: loadingS12n,
    error: s12nError,
    data: s12nData,
  } = useQuery<GetCourseIdsByS12nIdQuery, GetCourseIdsByS12nIdQueryVariables>(GetCourseIdsByS12nId, {
    variables: { id: `${s12nId}` },
    context: { clientName: 'gatewayGql' },
    skip: !s12nId,
  });

  const s12n = s12nData?.Specialization?.queryById;
  const courseIdToUse = courseId ?? s12n?.courses?.[0]?.id;

  const {
    data,
    loading: loadingSubscriptionTrials,
    error: subscriptionTrialsError,
  } = useQuery<GetSubscriptionTrialForCourseQuery, GetSubscriptionTrialForCourseQueryVariables>(
    GetSubscriptionTrialForCourse,
    {
      variables: { id: `${courseIdToUse}` },
      skip: skipGetFreeTrials || !courseIdToUse,
      context: { clientName: 'gatewayGql' },
    }
  );

  const subscriptionFreeTrialData = data?.Course?.queryById?.enrolledViaSubscriptionTrialMetadata;

  if (skipGetFreeTrials || isMissingProduct) {
    return { loading: false };
  }

  // If API errors out, persist a loading state to prevent possibly incorrect access to cert
  if (s12nError || subscriptionTrialsError) {
    return { loading: true };
  }

  return {
    loading: loadingS12n || loadingSubscriptionTrials,
    ...subscriptionFreeTrialData,
  };
};

export type BlockCertsInFreeTrialDataType = {
  readableNextBillingDate?: string;
  isInActiveFreeTrial?: boolean;
  hasCancelledInFreeTrial?: boolean;
  hasInactiveFreeTrial?: boolean;
  courseId?: string;
  productItemId?: string;
  productType?: string;
  subscriptionId?: string;
  isConvertFreeTrialToActiveSupported?: boolean;
  loadingFreeTrialData: boolean;
};

export const shouldExcludeCourseIdFromBlockCertCheck = (courseId?: string) =>
  !!courseId && COURSE_IDS_TO_EXCLUDE_REGEXES.some((regex) => regex.test(courseId));

export const getIsConvertFreeTrialToActiveSupported = (paymentProcessorId?: string) => {
  return paymentProcessorId
    ? CONVERT_FREE_TRIAL_TO_ACTIVE_SUPPORTED_PAYMENT_PROCESSOR_IDS.includes(paymentProcessorId)
    : false;
};

export const useGetBlockCertsInFreeTrialData = ({
  courseId,
  s12nId,
}: {
  courseId?: string;
  s12nId?: string;
}): BlockCertsInFreeTrialDataType | undefined => {
  const skipGetFreeTrials = !isBlockCertInFreeTrialEnabled() || shouldExcludeCourseIdFromBlockCertCheck(courseId);
  const {
    loading,
    productType,
    underlyingProductItemId: productItemId,
    paymentProcessorId,
    subscriptionId,
    subscriptionStatus,
    trialEndsAt,
  } = useGetActiveFreeTrialsForUserAndProduct({
    courseId,
    s12nId,
    skipGetFreeTrials,
  }) ?? {};
  const hasInactiveFreeTrial = subscriptionStatus === 'INACTIVE';

  if (loading) {
    return { loadingFreeTrialData: true };
  }

  if (skipGetFreeTrials || !productType || !trialEndsAt || !subscriptionStatus) {
    return undefined;
  }

  return {
    // @ts-expect-error DateTime: string
    readableNextBillingDate: getReadableDateFromTimestamp(trialEndsAt),
    isInActiveFreeTrial: subscriptionStatus === 'FREE_TRIAL',
    hasCancelledInFreeTrial: subscriptionStatus === 'CANCELLED',
    hasInactiveFreeTrial,
    loadingFreeTrialData: Boolean(loading),
    productItemId,
    productType,
    courseId,
    isConvertFreeTrialToActiveSupported: getIsConvertFreeTrialToActiveSupported(paymentProcessorId),
    subscriptionId,
  };
};

export const useConvertFreeTrialToActive = () => {
  const [executeMutation, mutationState] = useMutation<
    ConvertFreeTrialToActiveMutation,
    ConvertFreeTrialToActiveMutationVariables
  >(ConvertFreeTrialToActive, { context: { clientName: 'gatewayGql' } });

  const convertFreeTrialToActive = (subscriptionId: string) => {
    return executeMutation({
      variables: {
        input: {
          subscriptionId,
        },
      },
    });
  };

  return {
    convertFreeTrialToActive,
    isLoading: mutationState.loading,
    error: mutationState.error,
  };
};

export type ConfirmationData = {
  userId: number;
  isCourseraPlusSubscription: boolean;
  subscriptionId: string;
};

const CONFIRMATION_DATA_KEY = 'convertFreeTrialConfirmationData';

export const saveConvertFreeTrialData = (data: ConfirmationData) => {
  store.set(CONFIRMATION_DATA_KEY, JSON.stringify(data));
};

export const clearConvertFreeTrialData = () => {
  store.remove(CONFIRMATION_DATA_KEY);
};

export const getConvertFreeTrialData = (): ConfirmationData | undefined => {
  const rawData = store.get(CONFIRMATION_DATA_KEY);
  return rawData ? JSON.parse(rawData) : undefined;
};
