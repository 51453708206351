/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useState } from 'react';

import { Button, Dialog, Divider, Typography, Typography2, useTheme } from '@coursera/cds-core';
import { CheckIcon } from '@coursera/cds-icons';

import { formatUserFriendlyList } from 'bundles/common/utils/commonUtils';
import EnrollmentChoiceTypes from 'bundles/enroll-course/common/EnrollmentChoiceTypes';
import {
  choiceTypeToHandleSubmitPromise,
  submitEnrollmentPromise,
} from 'bundles/enroll-course/lib/enrollmentChoiceUtils';
import EnrollErrorModal from 'bundles/enroll/components/common/EnrollErrorModal';
import PromotionApplicableCheckoutMessage from 'bundles/enroll/components/common/PromotionApplicableCheckoutMessage';
import { useCourseEnrollModalData } from 'bundles/enroll/data/usePageData';
import type { Partner } from 'bundles/enroll/hooks/useGetPartnerNamesByIds';
import { EnrollModalType } from 'bundles/enroll/types/modalTypes';
import type { ApiError } from 'bundles/enroll/utils/errorUtils';
import { useEnrollModalEventing } from 'bundles/enroll/utils/eventingUtils';
import Icon from 'bundles/iconfont/Icon';
import TrackedButton from 'bundles/page/components/TrackedButton';
import ReactPriceDisplay from 'bundles/payments-common/components/ReactPriceDisplay';

import _t from 'i18n!nls/enroll';

const useStyles = () => {
  const theme = useTheme();
  return {
    courseName: css`
      padding-bottom: var(--cds-spacing-100);
    `,
    price: css`
      margin-top: var(--cds-spacing-100);
    `,
    valueProps: css`
      padding: 0;
      list-style-type: none;
    `,
    valuePropListItem: css`
      display: flex;
      align-items: center;
      margin: var(--cds-spacing-100) 0;
    `,
    checkMark: css`
      margin: var(--cds-spacing-100);
    `,
    divider: css`
      margin: var(--cds-spacing-300) 0;
    `,
  };
};

const getBulletPoints = () => [
  _t('All learning materials, including the interactive workspace'),
  _t('Instant access to the hands-on platform'),
  _t('Short learning activities that teach a specific tool or skill'),
  _t('Direct, temporary, hands-on access to real cloud resources'),
];

export const getFormattedPartnerDisplayName = (partners: Array<Partner>): string => {
  const partnerNames = partners.map((partner) => partner.name);
  const combinedNames = formatUserFriendlyList(partnerNames);
  const partnerDisplayString = _t('Offered by #{combinedNames}', { combinedNames });
  return partnerDisplayString;
};

type PropsFromCaller = {
  courseId: string;
  onClose: () => void;
};

const SelfPacedProjectEnrollModal: React.FC<PropsFromCaller> = ({ courseId, onClose }) => {
  const [didClickContinue, setDidClickContinue] = useState<boolean>(false);
  const [error, setError] = useState<ApiError | undefined>(undefined);
  const [activeModal, setActiveModal] = useState<EnrollModalType>(EnrollModalType.ENROLL);

  const { getEnrollModalTrackingRef, trackEnrollModalContinue, trackEnrollModalClose } = useEnrollModalEventing();
  const visibilityTrackingRef = getEnrollModalTrackingRef();

  const { promotionEligibilities, course, partners, productPrice } = useCourseEnrollModalData();
  const bullets = getBulletPoints();
  const styles = useStyles();
  const enrollmentChoiceType = EnrollmentChoiceTypes.ENROLL_PROJECT;

  const handleClose = () => {
    trackEnrollModalClose();
    onClose();
  };

  const onClickContinue = () => {
    setDidClickContinue(true);
    trackEnrollModalContinue(enrollmentChoiceType);

    const promoCode = promotionEligibilities?.isEligible ? promotionEligibilities.promoCodeId : undefined;
    const options = { courseId };

    const handleSubmitPromise = choiceTypeToHandleSubmitPromise[enrollmentChoiceType];
    submitEnrollmentPromise({ handleSubmitPromise, options, promoCode }).catch((apiError: ApiError) => {
      setActiveModal(EnrollModalType.ERROR);
      setError(apiError);
    });
  };

  const { HeadingGroup, Content, Actions } = Dialog;

  switch (activeModal) {
    case EnrollModalType.ERROR:
      return <EnrollErrorModal error={error} onClose={handleClose} />;
    case EnrollModalType.ENROLL:
      return (
        <Dialog
          aria-describedby="dialog-content"
          open={true}
          sticky="headingGroup"
          variant="standard"
          width="medium"
          onClose={handleClose}
          ref={visibilityTrackingRef}
        >
          <HeadingGroup css={styles.courseName}>{course.name}</HeadingGroup>
          <Content id="dialog-content">
            {partners && (
              <Typography2 component="p" variant="bodySecondary" data-test="partners">
                {getFormattedPartnerDisplayName(partners)}
              </Typography2>
            )}
            {productPrice && (
              <Typography css={styles.price} variant="h2semibold" component="p" data-test="price">
                <ReactPriceDisplay value={productPrice.finalAmount} currency={productPrice.currencyCode} />
              </Typography>
            )}
            <PromotionApplicableCheckoutMessage course={course} />
            <Divider color="light" css={styles.divider} />
            <Typography variant="h2semibold">{_t('Included in this Project:')}</Typography>
            <ul css={styles.valueProps}>
              {bullets.map((bullet) => (
                <li css={styles.valuePropListItem} key={`value-prop-${bullet}`}>
                  <CheckIcon size="small" color="interactive" css={styles.checkMark} />
                  <Typography2 component="p">{bullet}</Typography2>
                </li>
              ))}
            </ul>
          </Content>
          <Actions>
            <Button
              component={TrackedButton}
              data-e2e="self_paced_project_enroll_modal_continue_button"
              trackingName="self_paced_project_enroll_modal_continue"
              onClick={onClickContinue}
              disabled={didClickContinue}
              withVisibilityTracking={false}
              requireFullyVisible={false}
              size="medium"
              variant="primary"
              type="button"
            >
              {didClickContinue ? <Icon name="spinner" spin={true} /> : _t('Continue')}
            </Button>
          </Actions>
        </Dialog>
      );
    default:
      return null;
  }
};

export default SelfPacedProjectEnrollModal;
