/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { Children, useCallback, useState } from 'react';

import { ReactEditor, useReadOnly, useSelected, useSlateStatic } from 'slate-react';
import type { RenderElementProps } from 'slate-react';

import DeleteConfirmationDialog from 'bundles/cml/editor/components/dialogs/DeleteConfirmDialog';
import TableMenu from 'bundles/cml/editor/components/elements/table/TableMenu';
import { useFocusedContext } from 'bundles/cml/editor/context/focusContext';
import TableRenderer from 'bundles/cml/shared/components/table/Table';
import type { TableElement } from 'bundles/cml/shared/types/elementTypes';

const styles = {
  root: css`
    position: relative;
  `,
};

const splitHeader = ({ headless }: TableElement, children: React.ReactChildren) => {
  const rows = Children.toArray(children);
  if (headless || rows.length <= 1) {
    return { header: null, rows };
  }

  return {
    header: rows[0],
    rows: rows.slice(1),
  };
};

const Table: React.FC<RenderElementProps> = (props) => {
  const tableElement = props.element as TableElement;
  const readonly = useReadOnly();
  const [ref, setRef] = useState<HTMLTableElement | null>(null);
  const { setFocused } = useFocusedContext();
  const [menuOpen, setMenuOpen] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const staticEditor = useSlateStatic();
  const handleDelete = useCallback(() => setDeleteConfirm(true), []);

  const handleOpen = useCallback(() => {
    setFocused(true);
    setMenuOpen(true);
  }, [setFocused]);

  const handleClose = useCallback(() => {
    setMenuOpen(false);
    ReactEditor.focus(staticEditor);
    setFocused(false);
  }, [setFocused, staticEditor]);

  const showMenu = !readonly && menuOpen;
  const { header, rows } = splitHeader(tableElement, props.children);

  return (
    <TableRenderer ref={setRef} {...props} header={header} rows={rows} css={styles.root} onClick={handleOpen}>
      {showMenu && (
        <span contentEditable={false}>
          <TableMenu anchorEl={ref} table={tableElement} onClose={handleClose} onDelete={handleDelete} />
          {deleteConfirm && <DeleteConfirmationDialog editor={staticEditor} setConfirm={setDeleteConfirm} />}
        </span>
      )}
    </TableRenderer>
  );
};

export default Table;
