import * as React from 'react';
import { useCallback } from 'react';

import { useSlate, useSlateStatic } from 'slate-react';

import { BulletListIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import { shouldDisableList, toggleList } from 'bundles/cml/editor/components/buttons/list/listUtils';
import { hasAncestorOfType } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';

import _t from 'i18n!nls/cml';

const { BULLET_LIST } = BLOCK_TYPES;

const BulletListButton: React.FC = () => {
  const editor = useSlate();
  const staticEditor = useSlateStatic();
  const active = hasAncestorOfType(editor, BULLET_LIST);
  const disabled = shouldDisableList(editor, BULLET_LIST);

  const handleChange = useCallback(() => toggleList(staticEditor, BULLET_LIST), [staticEditor]);

  return (
    <Button
      onClick={handleChange}
      disabled={disabled}
      active={active}
      className="rc-BulletListButton"
      title={_t('Bulleted list')}
      type={TOOLBAR_BUTTON_TYPES.insertion}
    >
      <BulletListIcon size="small" />
    </Button>
  );
};

export default BulletListButton;
