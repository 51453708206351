/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useState } from 'react';
import type { MutationFn } from 'react-apollo';
import { Mutation } from 'react-apollo';

import logger from 'js/app/loggerSingleton';

import { Button, Dialog, Typography2 } from '@coursera/cds-core';
import { ArrowNextIcon } from '@coursera/cds-icons';

import LoadingIcon from 'bundles/courseraLoadingIcon/LoadingIcon';
import RemovePreEnrollMutation from 'bundles/enroll/api/RemovePreEnrollMutation.graphql';
import type {
  RemovePreEnrollMutationMutation,
  RemovePreEnrollMutationMutationVariables,
} from 'bundles/enroll/api/__generated__/RemovePreEnrollMutation';
import type { ApiError } from 'bundles/enroll/utils/errorUtils';
import type OnDemandSpecializationV1 from 'bundles/naptimejs/resources/onDemandSpecializations.v1';
import TrackedButton from 'bundles/page/components/TrackedButton';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { NormalS12n } from 'bundles/s12n-common/constants/s12nProductVariants';
import { getProductVariantString } from 'bundles/s12n-common/lib/s12nProductVariantUtils';

import _t from 'i18n!nls/enroll';

const { HeadingGroup, Content, Actions } = Dialog;

type Props = {
  onSdp: boolean;
  s12nId: string;
  handleClose: () => void;
  openModal: boolean;
  automaticallyEnroll?: boolean;
  preEnrollmentId?: string;
  s12n?: OnDemandSpecializationV1;
};

const CancelPreEnrollModal: React.FC<Props> = ({
  onSdp,
  handleClose,
  openModal,
  automaticallyEnroll,
  preEnrollmentId,
  s12nId,
  s12n,
}) => {
  const [isEnrolling, setIsEnrolling] = useState(false);
  const [isSuccessfullyUnenrolled, setIsSuccessfullyUnenrolled] = useState(false);
  const [enrollmentError, setEnrollmentError] = useState<ApiError | undefined>(undefined);

  if (!preEnrollmentId) {
    logger.error('Unable to render CancelPreEnrollModal');
    return null;
  }

  const closeAndRefresh = () => {
    handleClose();
    window.location.href = window.location.pathname;
  };

  const handleRemove = (
    removePreEnrollUser: MutationFn<RemovePreEnrollMutationMutation, RemovePreEnrollMutationMutationVariables>
  ) => {
    setIsEnrolling(true);
    setIsSuccessfullyUnenrolled(false);
    setEnrollmentError(undefined);

    removePreEnrollUser({
      variables: {
        preEnrollmentId,
      },
    })
      .then((result) => {
        const onSuccess = result?.data?.removePreEnrollment;

        if (onSuccess) {
          setIsSuccessfullyUnenrolled(true);
          setIsEnrolling(false);
        }
      })
      .catch((error: ApiError) => {
        setEnrollmentError(error);
      });
  };

  if (enrollmentError) {
    return (
      <Dialog
        onClose={handleClose}
        open={openModal}
        css={css`
          text-align: center;
        `}
      >
        <HeadingGroup>{_t('Could not unenroll user. Please try again later.')}</HeadingGroup>
        <Actions>
          <Button variant="primary" onClick={handleClose}>
            {_t('Close')}
          </Button>
        </Actions>
      </Dialog>
    );
  }

  if (isSuccessfullyUnenrolled) {
    return (
      <Dialog
        onClose={closeAndRefresh}
        open={openModal}
        css={css`
          text-align: center;
        `}
      >
        <HeadingGroup>{_t('You successfully cancelled your pre-enrollment')}</HeadingGroup>
        <Actions>
          <Button variant="primary" onClick={closeAndRefresh}>
            {_t('Close')}
          </Button>
        </Actions>
      </Dialog>
    );
  }

  const productType = onSdp ? getProductVariantString(s12n?.productVariant || NormalS12n) : _t('course');

  return (
    <Mutation<RemovePreEnrollMutationMutation, RemovePreEnrollMutationMutationVariables>
      mutation={RemovePreEnrollMutation}
      context={{ clientName: 'gatewayGql' }}
    >
      {(removePreEnrollUser) => (
        <Dialog onClose={handleClose} open={openModal}>
          <TrackedDiv
            trackingName="cancel_pre_enroll_modal"
            data={{ id: s12nId }}
            withVisibilityTracking
            requireFullyVisible={false}
          >
            <HeadingGroup>{_t('Do you want to cancel your pre-enrollment?')}</HeadingGroup>
            {isEnrolling ? (
              <Content>
                <LoadingIcon />
              </Content>
            ) : (
              <div>
                <Content>
                  <Typography2 component="p" variant="bodyPrimary">
                    {automaticallyEnroll
                      ? _t(
                          'When you cancel your pre-enrollment, you will no longer be automatically enrolled when this #{productType} launches. You can still come back and pre-enroll again if you change your mind.',
                          { productType }
                        )
                      : _t(
                          'When you cancel your pre-enrollment, you will no longer be automatically enrolled when this #{productType} launches and your credit card will not be charged. You can still come back and pre-enroll again if you change your mind.',
                          { productType }
                        )}
                  </Typography2>
                </Content>
                <Actions>
                  <Button
                    variant="secondary"
                    onClick={handleClose}
                    css={css`
                      margin-right: 8px;
                    `}
                  >
                    {_t('Never mind')}
                  </Button>
                  <Button
                    component={TrackedButton}
                    icon={<ArrowNextIcon size="small" />}
                    iconPosition="after"
                    variant="primary"
                    onClick={() => handleRemove(removePreEnrollUser)}
                    withVisibilityTracking={false}
                    requireFullyVisible={false}
                    trackingName="cancel_pre_enroll_modal_cancel_button"
                    data={{ preEnrollmentId }}
                  >
                    {_t('Yes, cancel my pre-enrollment')}
                  </Button>
                </Actions>
              </div>
            )}
          </TrackedDiv>
        </Dialog>
      )}
    </Mutation>
  );
};

export default CancelPreEnrollModal;
