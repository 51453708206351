import { useContext } from 'react';

import PageDataContext from 'bundles/enroll/data/PageDataContext';
import type {
  CourseEnrollModalDataContextType,
  S12nEnrollModalDataContextType,
} from 'bundles/enroll/data/PageDataContext';

type PageDataType = CourseEnrollModalDataContextType | S12nEnrollModalDataContextType;

const usePageData = (): PageDataType => {
  const context = useContext(PageDataContext);
  if (!context) {
    throw new Error('Missing PageDataContext. Make sure you wrap your page with a data provider.');
  }
  return context;
};

const isCourseEnrollModalData = (pageData: PageDataType): pageData is CourseEnrollModalDataContextType =>
  pageData.isSpecialization === false;

const isS12nEnrollModalData = (pageData: PageDataType): pageData is S12nEnrollModalDataContextType =>
  pageData.isSpecialization === true;

export const useCourseEnrollModalData = (): CourseEnrollModalDataContextType => {
  const pageData = usePageData();

  if (!isCourseEnrollModalData(pageData)) {
    throw new Error('Missing CourseEnrollModalData in data provider.');
  }

  return pageData;
};

export const useS12nEnrollModalData = (): S12nEnrollModalDataContextType => {
  const pageData = usePageData();

  if (!isS12nEnrollModalData(pageData)) {
    throw new Error('Missing S12nEnrollModalData in data provider.');
  }

  return pageData;
};

export default usePageData;
