import * as React from 'react';
import { Query } from 'react-apollo';

import { branch, compose } from 'recompose';

import connectToRouter from 'js/lib/connectToRouter';
import user from 'js/lib/user';

import Naptime from 'bundles/naptimejs';
import OpenCourseMembershipsV1 from 'bundles/naptimejs/resources/openCourseMemberships.v1';
import inServerContext from 'bundles/ssr/util/inServerContext';
import UserContext from 'bundles/unified-description-page-common/contexts/UserContext';
import ProgramMembershipsByUserId from 'bundles/unified-description-page-common/queries/ProgramMembershipsQuery.graphql';
import type {
  ProgramMembershipsByUserIdQuery as ProgramMembershipsByUserIdQueryType,
  ProgramMembershipsByUserIdQueryVariables,
} from 'bundles/unified-description-page-common/queries/__generated__/ProgramMembershipsQuery';

type PropsFromNaptime = {
  openCourseMemberships?: OpenCourseMembershipsV1;
};

type PropsToComponent = PropsFromNaptime & {
  children: React.ReactElement | null;
};

const UserDataProvider = ({ children, openCourseMemberships }: PropsToComponent) => {
  return (
    <Query<ProgramMembershipsByUserIdQueryType, ProgramMembershipsByUserIdQueryVariables>
      skip={!user.isAuthenticatedUser()}
      query={ProgramMembershipsByUserId}
      variables={{ userId: user.get().id }}
      context={{ clientName: 'gatewayGql' }}
    >
      {({ data, loading }) => {
        const thirdPartyOrganizations =
          data?.ProgramMemberships?.queryProgramMembershipsByUserIdAndIncludeInvitations?.edges?.map(
            (edge) => edge?.node?.program?.naptimeThirdPartyOrganization
          );

        return (
          <UserContext.Provider
            value={{ openCourseMemberships, thirdPartyOrganizations, loadingThirdPartyOrganizations: loading }}
          >
            {children}
          </UserContext.Provider>
        );
      }}
    </Query>
  );
};

export default compose<PropsToComponent, { includeOpenCourseMemberships?: boolean }>(
  connectToRouter(({ params }) => {
    return {
      slug: params.productSlug,
    };
  }),
  branch(
    ({ includeOpenCourseMemberships = false }: { includeOpenCourseMemberships?: boolean }) =>
      user.isAuthenticatedUser() && !inServerContext && includeOpenCourseMemberships,
    Naptime.createContainer(({ slug }: { slug: string }) => {
      return {
        openCourseMemberships: OpenCourseMembershipsV1.byUserAndSlug(user.get().id, slug, {
          fields: ['courseRole'],
          required: false,
        }),
      };
    })
  )
)(UserDataProvider);
