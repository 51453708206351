import * as React from 'react';
import { useMemo, useRef, useState } from 'react';

import { debounce } from 'lodash';
import type { BaseEditor, Descendant } from 'slate';

// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import CMLEditor from 'bundles/cml/editor/components/CMLEditor';
import { useCustomTools } from 'bundles/cml/editor/components/hooks/useCustomTools';
import { useSlateEditor } from 'bundles/cml/editor/components/hooks/useSlateEditor';
import AssetContextProvider from 'bundles/cml/editor/context/AssetContextProvider';
import CodeBlockContextProvider from 'bundles/cml/editor/context/CodeBlockContextProvider';
import FillableBlanksContextProvider from 'bundles/cml/editor/context/FillableBlanksContextProvider';
import FocusContextProvider from 'bundles/cml/editor/context/FocusContextProvider';
import ImageContextProvider from 'bundles/cml/editor/context/ImageContextProvider';
import NotificationContextProvider from 'bundles/cml/editor/context/NotificationContextProvider';
import PersonalizationTagContextProvider from 'bundles/cml/editor/context/PersonalizationTagContextProvider';
import ToolsContextProvider from 'bundles/cml/editor/context/ToolsContextProvider';
import WidgetContextProvider from 'bundles/cml/editor/context/WidgetContextProvider';
import { withEventingContext } from 'bundles/cml/editor/context/eventingContext';
import type { Props as EditorProps } from 'bundles/cml/editor/types/cmlEditorProps';
import type { NotificationMessage } from 'bundles/cml/editor/types/notification';
import { slateToCml } from 'bundles/cml/editor/utils/slateToCML';
import { useAiWritingAssistantSupported } from 'bundles/cml/editor/utils/writingAssistantUtils';
import type { BlockElement } from 'bundles/cml/shared/types/elementTypes';

export type Props = Omit<EditorProps, 'initialCML' | 'onContentChange'> & {
  cmlValue: string;
  onChange: (value: string) => void;
  onMarkdownChange?: (value: string) => void;
  /**
   * Advanced/dangerous: Override this method to prevent normalizing the editor.
   *
   * See [Slate documentation](https://docs.slatejs.org/api/nodes/editor#shouldnormalize-options-greater-than-boolean) for more information.
   */
  editorShouldNormalize?: BaseEditor['shouldNormalize'];
};

const CMLEditorProvider: React.FC<Props> = (props) => {
  const {
    uploadOptions,
    codeBlockOptions,
    onPersonalizationTagClick,
    isLearnerUpload = false,
    usePagelessDesign: pageless = false,
    enableMonospace: monospace = true,
    children,
    fillableBlankTags = [],
    enableWidgets = false,
    enableAiWritingAssistant,
    readOnly,
    customTools,
    onChange,
    debounceDuration = 500,
    ...editorProps
  } = props;

  const writingAssistantSupported = useAiWritingAssistantSupported();
  const [notification, setNotification] = useState<NotificationMessage | undefined>(undefined);
  const { tools, options } = useCustomTools({
    monospace,
    pageless,
    isLearnerUpload,
    customTools,
    enableWidgets,
    enableAiWritingAssistant: enableAiWritingAssistant || writingAssistantSupported,
  });
  const editor = useSlateEditor(setNotification, tools);
  const staticEditor = useRef(editor);
  const resizable = !readOnly && !pageless;
  const [focused, setFocused] = useState(false);

  const handleChange = useMemo(() => {
    return debounce((data: Descendant[]) => {
      const cml = `<co-content>${slateToCml(data as BlockElement[])}</co-content>`;
      return onChange(cml);
    }, debounceDuration);
  }, [onChange, debounceDuration]);

  return (
    <React.Fragment>
      <FocusContextProvider focused={focused} setFocused={setFocused}>
        <AssetContextProvider isLearnerUpload={isLearnerUpload} pageless={pageless} uploadOptions={uploadOptions}>
          <ImageContextProvider>
            <CodeBlockContextProvider codeBlockOptions={codeBlockOptions} editor={staticEditor.current}>
              <PersonalizationTagContextProvider onPersonalizationTagClick={onPersonalizationTagClick}>
                <NotificationContextProvider notification={notification} setNotification={setNotification}>
                  <FillableBlanksContextProvider fillableBlankTags={fillableBlankTags}>
                    <ToolsContextProvider tools={tools} options={options}>
                      <WidgetContextProvider enableWidgets={enableWidgets}>
                        <CMLEditor
                          editor={editor}
                          usePagelessDesign={pageless}
                          readOnly={readOnly}
                          resizable={resizable}
                          onChange={handleChange}
                          {...editorProps}
                        >
                          {children}
                        </CMLEditor>
                      </WidgetContextProvider>
                    </ToolsContextProvider>
                  </FillableBlanksContextProvider>
                </NotificationContextProvider>
              </PersonalizationTagContextProvider>
            </CodeBlockContextProvider>
          </ImageContextProvider>
        </AssetContextProvider>
      </FocusContextProvider>
    </React.Fragment>
  );
};

export default React.memo(withEventingContext(CMLEditorProvider));
