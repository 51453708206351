import { createContext, useContext } from 'react';

import type { ToolOptions } from 'bundles/cml/editor/components/toolbars/types';
import type { ToolsKeys } from 'bundles/cml/shared/utils/customTools';

export type ToolsContextType = {
  tools: ToolsKeys[];
  options: ToolOptions;
};

export const ToolsContext = createContext<ToolsContextType>({
  tools: [],
  options: {},
});

export const useToolsContext = () => useContext(ToolsContext);
