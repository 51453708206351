import * as React from 'react';

type Props = {
  width?: number;
  height?: number;
};

export const ExploreIconSvg = ({ width = 40, height = 40 }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 40 40"
      role="presentation"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M3.334 20c0-9.2 7.467-16.667 16.667-16.667 9.2 0 16.666 7.467 16.666 16.667 0 9.2-7.466 16.666-16.666 16.666S3.334 29.2 3.334 20zm3.333 0c0 7.35 5.984 13.333 13.334 13.333S33.334 27.35 33.334 20c0-7.35-5.983-13.334-13.333-13.334C12.65 6.666 6.667 12.65 6.667 20zm4.167 9.166l12.517-5.816 5.816-12.517-12.516 5.817-5.817 12.516zm11-9.166a1.828 1.828 0 00-1.833-1.834A1.828 1.828 0 0018.167 20c0 1.016.817 1.833 1.834 1.833A1.828 1.828 0 0021.834 20z"
        clipRule="evenodd"
      />
    </svg>
  );
};
