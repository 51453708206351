import * as React from 'react';

import type { SkillSetInfoSvgProps } from 'bundles/program-home/components/multiprogram/SkillSetInformationCard';

const SkillSetInfo2Svg: React.FC<SkillSetInfoSvgProps> = (props) => (
  <svg viewBox="0 0 589 279" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0)">
      <path
        d="M141.891 175.93C149.003 175.93 154.768 170.178 154.768 163.082C154.768 155.986 149.003 150.234 141.891 150.234C134.779 150.234 129.014 155.986 129.014 163.082C129.014 170.178 134.779 175.93 141.891 175.93Z"
        fill="#00255D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M208.735 75.79L141.131 165.727L137.894 163.299L205.498 73.3622L208.735 75.79Z"
        fill="#00255D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M139.659 103.109C107.241 103.109 80.9617 129.359 80.9617 161.739C80.9617 194.12 107.241 220.37 139.659 220.37C172.077 220.37 198.357 194.12 198.357 161.739C198.357 129.359 172.077 103.109 139.659 103.109ZM76.9136 161.739C76.9136 127.125 105.006 99.0653 139.659 99.0653C174.313 99.0653 202.405 127.125 202.405 161.739C202.405 196.353 174.313 224.413 139.659 224.413C105.006 224.413 76.9136 196.353 76.9136 161.739Z"
        fill="#F5F7F8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M140.672 115.239C114.362 115.239 93.1062 136.097 93.1062 161.739C93.1062 187.381 114.362 208.239 140.672 208.239C166.981 208.239 188.237 187.381 188.237 161.739C188.237 136.097 166.981 115.239 140.672 115.239ZM89.0581 161.739C89.0581 133.786 112.206 111.196 140.672 111.196C169.137 111.196 192.285 133.786 192.285 161.739C192.285 189.693 169.137 212.283 140.672 212.283C112.206 212.283 89.0581 189.693 89.0581 161.739Z"
        fill="#F5F7F8"
      />
      <path
        d="M192.766 66.621V30.1506L224.01 11.9197L255.254 30.1586V66.621L224.01 84.8521L192.766 66.621Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M190.181 28.6474L224.01 8.90442L257.808 28.6474V68.1331L224.01 87.8762L190.181 68.1251V28.6474ZM252.653 31.6634L224.011 14.9442L195.368 31.6634V65.1098L224.011 81.8371L252.653 65.1179V31.6634Z"
        fill="#D69700"
      />
      <path d="M252.652 31.7033L257.839 28.6475V68.1333L252.644 65.1017L252.652 31.7033Z" fill="#FFC500" />
      <path d="M190.181 68.1257L195.312 65.0778L224.058 81.8531L224.01 87.8685L190.181 68.1257Z" fill="#FFC500" />
      <path d="M195.337 31.6551L190.181 28.6474L224.01 8.90442L223.979 14.8957L195.337 31.6551Z" fill="#FFC500" />
      <path
        d="M224.01 32.437L229.133 42.9399L240.606 44.6204L232.308 52.7993L234.265 64.3395L224.01 58.8948L213.754 64.3395L215.711 52.7993L207.413 44.6204L218.878 42.9399L224.01 32.437Z"
        fill="#FFC500"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M224.011 28.7097L230.225 41.4486L244.092 43.4798L234.063 53.3648L236.433 67.3405L224.01 60.7449L211.586 67.3405L213.956 53.3648L203.927 43.4801L217.786 41.4486L224.011 28.7097ZM224.008 36.1642L219.969 44.4311L210.899 45.7607L217.466 52.2336L215.922 61.3384L224.01 57.0446L232.097 61.3383L230.553 52.2336L237.12 45.7609L228.041 44.4311L224.008 36.1642Z"
        fill="#D69700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.47 193.922C104.316 197.367 103.827 202.464 100.378 205.306L44.683 251.213C41.2342 254.055 36.1313 253.567 33.2854 250.122C30.4395 246.677 30.9282 241.58 34.3771 238.737L90.0724 192.831C93.5212 189.989 98.6241 190.477 101.47 193.922Z"
        fill="#929599"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M140.348 112.207C112.575 112.207 90.07 134.669 90.07 162.367C90.07 190.064 112.575 212.527 140.348 212.527C168.121 212.527 190.626 190.064 190.626 162.367C190.626 134.669 168.121 112.207 140.348 112.207ZM79.9497 162.367C79.9497 129.076 106.996 102.098 140.348 102.098C173.7 102.098 200.747 129.076 200.747 162.367C200.747 195.657 173.7 222.635 140.348 222.635C106.996 222.635 79.9497 195.657 79.9497 162.367Z"
        fill="#929599"
      />
      <path
        d="M17.3761 152.976C24.1239 150.736 27.7734 143.461 25.5275 136.728C23.2816 129.996 15.9907 126.354 9.24292 128.595C2.49509 130.836 -1.15442 138.111 1.0915 144.843C3.33742 151.576 10.6283 155.217 17.3761 152.976Z"
        fill="#00255D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.8902 88.4849L12.5053 143.09L9.88525 140.007L74.2702 85.4026L76.8902 88.4849Z"
        fill="#00255D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.5539 96.9191L127.623 145.1L121.708 150.621L76.6382 102.44L82.5539 96.9191Z"
        fill="#F5F7F8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.5847 89.8422L141.189 163.719L138.201 166.447L70.5967 92.5702L73.5847 89.8422Z"
        fill="#00255D"
      />
      <path
        d="M74.2876 102.053C81.3994 102.053 87.1646 96.3012 87.1646 89.2053C87.1646 82.1095 81.3994 76.3572 74.2876 76.3572C67.1759 76.3572 61.4106 82.1095 61.4106 89.2053C61.4106 96.3012 67.1759 102.053 74.2876 102.053Z"
        fill="#00255D"
      />
      <path
        d="M362.646 122.223H301.846C299.382 122.223 297.384 124.216 297.384 126.675V204.928C297.384 207.387 299.382 209.38 301.846 209.38H362.646C365.11 209.38 367.108 207.387 367.108 204.928V126.675C367.108 124.216 365.11 122.223 362.646 122.223Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M301.846 123.234C299.939 123.234 298.396 124.776 298.396 126.675V204.928C298.396 206.827 299.939 208.369 301.846 208.369H362.645C364.552 208.369 366.095 206.827 366.095 204.928V126.675C366.095 124.776 364.552 123.234 362.645 123.234H301.846ZM296.372 126.675C296.372 123.657 298.823 121.212 301.846 121.212H362.645C365.668 121.212 368.119 123.657 368.119 126.675V204.928C368.119 207.946 365.668 210.391 362.645 210.391H301.846C298.823 210.391 296.372 207.946 296.372 204.928V126.675Z"
        fill="#929599"
      />
      <path
        d="M298.804 126.629C298.804 124.99 300.136 123.661 301.778 123.661H362.713C364.355 123.661 365.688 124.99 365.688 126.629V156.707H298.804V126.629Z"
        fill="#EBF3FF"
      />
      <path d="M344.646 177.644H307.528V182.15H344.646V177.644Z" fill="#AAC0E1" />
      <path d="M354.055 186.603H307.528V191.11H354.055V186.603Z" fill="#AAC0E1" />
      <path d="M339.343 195.565H307.528V200.072H339.343V195.565Z" fill="#AAC0E1" />
      <path d="M333.7 138.764H307.528V165.801H333.7V138.764Z" fill="#AAC0E1" />
      <path
        d="M501.12 87.5588H381.469C379.005 87.5588 377.007 89.5521 377.007 92.011V229.192C377.007 231.651 379.005 233.644 381.469 233.644H501.12C503.584 233.644 505.582 231.651 505.582 229.192V92.011C505.582 89.5521 503.584 87.5588 501.12 87.5588Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M381.469 88.5696C379.562 88.5696 378.019 90.1113 378.019 92.0109V229.192C378.019 231.091 379.562 232.633 381.469 232.633H501.12C503.026 232.633 504.57 231.091 504.57 229.192V92.0109C504.57 90.1113 503.026 88.5696 501.12 88.5696H381.469ZM375.995 92.0109C375.995 88.9927 378.447 86.5479 381.469 86.5479H501.12C504.142 86.5479 506.594 88.9927 506.594 92.0109V229.192C506.594 232.21 504.142 234.655 501.12 234.655H381.469C378.447 234.655 375.995 232.21 375.995 229.192V92.0109Z"
        fill="#929599"
      />
      <path
        d="M380.782 93.8757C380.782 92.2364 382.115 90.9076 383.757 90.9076H498.832C500.477 90.9076 501.807 92.2364 501.807 93.8757V145.56H380.782V93.8757Z"
        fill="#EBF3FF"
      />
      <path d="M463.734 180.186H396.569V187.638H463.734V180.186Z" fill="#AAC0E1" />
      <path d="M480.761 195.004H396.569V202.456H480.761V195.004Z" fill="#AAC0E1" />
      <path d="M454.139 209.828H396.569V217.28H454.139V209.828Z" fill="#AAC0E1" />
      <path d="M443.927 115.885H396.569V160.601H443.927V115.885Z" fill="#AAC0E1" />
      <path
        d="M584.205 122.223H519.944C517.479 122.223 515.481 124.216 515.481 126.675V204.928C515.481 207.387 517.479 209.38 519.944 209.38H584.205C586.669 209.38 588.667 207.387 588.667 204.928V126.675C588.667 124.216 586.669 122.223 584.205 122.223Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M519.944 123.234C518.037 123.234 516.494 124.776 516.494 126.675V204.928C516.494 206.827 518.037 208.369 519.944 208.369H584.205C586.112 208.369 587.655 206.827 587.655 204.928V126.675C587.655 124.776 586.112 123.234 584.205 123.234H519.944ZM514.47 126.675C514.47 123.657 516.922 121.212 519.944 121.212H584.205C587.227 121.212 589.679 123.657 589.679 126.675V204.928C589.679 207.946 587.227 210.391 584.205 210.391H519.944C516.922 210.391 514.47 207.946 514.47 204.928V126.675Z"
        fill="#929599"
      />
      <path
        d="M517.041 126.629C517.041 124.99 518.371 123.661 520.016 123.661H584.134C585.776 123.661 587.108 124.99 587.108 126.629V156.707H517.041V126.629Z"
        fill="#EBF3FF"
      />
      <path d="M565.063 177.644H526.178V182.15H565.063V177.644Z" fill="#AAC0E1" />
      <path d="M574.921 186.603H526.178V191.11H574.921V186.603Z" fill="#AAC0E1" />
      <path d="M559.508 195.565H526.178V200.072H559.508V195.565Z" fill="#AAC0E1" />
      <path d="M553.596 138.764H526.178V165.801H553.596V138.764Z" fill="#AAC0E1" />
    </g>
    <defs>
      <clipPath id="SkillSetInfo2-clip0">
        <rect width="589" height="279" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SkillSetInfo2Svg;
