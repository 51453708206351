import * as React from 'react';

const LiteracyIcon = () => (
  <svg
    role="presentation"
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="literacy-icon"
  >
    <circle cx="12" cy="12" r="12" fill="#F5F7F8" />
    <rect x="3.25" y="14.019" width="17.5" height="3.192" rx="1.596" fill="#F5F7F8" stroke="#1F1F1F" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m12.15 11.034 2.915-2.915.288.288-3.407 3.407-3.407-3.407.288-.288 2.915 2.915V5h.408v6.034z"
      fill="#1F1F1F"
    />
  </svg>
);

export default LiteracyIcon;
