import * as React from 'react';

import _ from 'lodash';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { Typography2 } from '@coursera/cds-core';
import { color } from '@coursera/coursera-ui';

import CourseActionBox from 'bundles/course-cards/components/course-card/enterprise/CourseActionBox';
import type { PropsForWithModal as PropsForCourseAndPartnerNameWithModal } from 'bundles/course-cards/components/course-card/enterprise/CourseAndPartnerName';
import { CourseAndPartnerNameWithModal } from 'bundles/course-cards/components/course-card/enterprise/CourseAndPartnerName';
import CourseCardAutoenrollmentDisclaimer from 'bundles/course-cards/components/course-card/enterprise/CourseCardAutoenrollmentDisclaimer';
import CourseSessionInfoString from 'bundles/course-cards/components/course-card/enterprise/CourseSessionInfoString';
import CourseWithProgressLinkButton from 'bundles/course-cards/components/course-card/enterprise/CourseWithProgressLinkButton';
import StaticLinearProgress from 'bundles/course-cards/components/course-card/enterprise/StaticLinearProgress';
import type CoursesV1 from 'bundles/naptimejs/resources/courses.v1';
import type OnDemandLearnerMaterialsV1 from 'bundles/naptimejs/resources/onDemandLearnerMaterials.v1';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import type OnDemandLearnerSessionsV1 from 'bundles/naptimejs/resources/onDemandLearnerSessions.v1';
import { getProgressByLearnerMaterial } from 'bundles/program-home/utils/CourseUtils';

import _t from 'i18n!nls/course-cards';

type Props = {
  course: CoursesV1;
  cardDropdownWidget: JSX.Element;
  learnerSession?: OnDemandLearnerSessionsV1;
  learnerMaterials?: OnDemandLearnerMaterialsV1;
  isAutoenrolled?: boolean;
  isPartOfS12n?: boolean;
} & Omit<PropsForCourseAndPartnerNameWithModal, 'courseId' | 'courseName' | 'partnerIds'>;

const CourseEnrolledPastPublicSessionCard: React.FC<Props> = ({
  course: { id, name, homeLink, partnerIds, upcomingSessionStartDateString },
  cardDropdownWidget,
  learnerMaterials,
  // Used as a discard.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  learnerSession,
  isAutoenrolled,
  isPartOfS12n,
  ...rest
}) => {
  const duration = (learnerMaterials && _.size(learnerMaterials.weekNumbers)) || -1;
  const summaryCoreProgressPercentage = getProgressByLearnerMaterial(learnerMaterials);

  return (
    <div className="rc-CourseEnrolledPastPublicSessionCard row flex-1">
      <div className="col-xs-12 col-sm-8 col-md-9 pos-static-override-hack">
        <CourseAndPartnerNameWithModal
          courseId={id}
          courseName={name}
          partnerIds={partnerIds}
          isPartOfS12n={isPartOfS12n}
          {...rest}
        />
        {cardDropdownWidget}
        {isPartOfS12n && (
          <Typography2 component="p" className="m-b-1" variant="bodyPrimary">
            {_t('Started')}
          </Typography2>
        )}
        <Typography2 component="span" variant="subtitleMedium" className="text-capitalize" aria-hidden="true">
          {_t('progress: #{summaryCoreProgressPercentage}%', { summaryCoreProgressPercentage })}
        </Typography2>
        <StaticLinearProgress
          progress={summaryCoreProgressPercentage}
          color={summaryCoreProgressPercentage !== 100 ? color.danger : color.success}
          aria-label={name}
        />
        {isAutoenrolled && <CourseCardAutoenrollmentDisclaimer />}
        <div className="assignment-rating-container horizontal-box align-items-spacebetween">
          <CourseSessionInfoString
            courseId={id}
            upcomingSessionStartDateString={upcomingSessionStartDateString ?? undefined}
          />
        </div>
      </div>
      <CourseActionBox>
        <div className="vertical-box align-items-absolute-center">
          {duration !== -1 && (
            <Typography2 component="span" variant="subtitleMedium">
              <FormattedMessage message={_t('{duration, plural, =1 {# Week} other {# Weeks}}')} duration={duration} />
            </Typography2>
          )}
          <CourseWithProgressLinkButton
            link={homeLink}
            progress={summaryCoreProgressPercentage}
            trackingNamePrefix="enrolled_past_public"
            courseName={name}
          />
        </div>
      </CourseActionBox>
    </div>
  );
};

export default CourseEnrolledPastPublicSessionCard;
