import type React from 'react';

import { isHotkey } from 'is-hotkey';
import type { NodeEntry } from 'slate';
import { Editor, Element, Path, Range } from 'slate';

import { focusTableCell, getTableData } from 'bundles/cml/editor/components/elements/table/tableUtils';
import { focusNextBlock, focusPreviousBlock, getAncestorOfType } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { TableCellElement, TableElement } from 'bundles/cml/shared/types/elementTypes';

const isUpHotkey = isHotkey('up');
const isDownHotkey = isHotkey('down');

const handleUpArrow = (editor: Editor, event: React.KeyboardEvent) => {
  const tableEntry = getAncestorOfType(editor, BLOCK_TYPES.TABLE);
  const tableCellEntry = getAncestorOfType(editor, BLOCK_TYPES.TABLE_CELL) as NodeEntry<TableCellElement>;
  const { selection } = editor;
  if (!tableEntry || !tableCellEntry || !selection) {
    return false;
  }

  const { rowIndex, columnIndex, path } = getTableData(editor, tableEntry[0] as TableElement);
  if (!path) {
    return false;
  }

  const tableCellPath = tableCellEntry[1];
  const nodes = Array.from(
    Editor.nodes(editor, {
      at: tableCellPath,
      mode: 'all',
      match: (el) => Element.isElement(el) && el.type === BLOCK_TYPES.TEXT,
    })
  );
  const firstNodeEntry = nodes[0];
  const isStart = Path.isDescendant(Range.start(selection).path, firstNodeEntry[1]);
  if (!isStart) {
    return false;
  }

  event.preventDefault();

  if (rowIndex <= 0) {
    focusPreviousBlock(editor, path);
  } else {
    focusTableCell(editor, path, rowIndex - 1, columnIndex, true);
  }

  return true;
};

const handleDownArrow = (editor: Editor, event: React.KeyboardEvent) => {
  const tableEntry = getAncestorOfType(editor, BLOCK_TYPES.TABLE) as NodeEntry<TableElement>;
  const tableCellEntry = getAncestorOfType(editor, BLOCK_TYPES.TABLE_CELL) as NodeEntry<TableCellElement>;
  const { selection } = editor;
  if (!tableEntry || !tableCellEntry || !selection) {
    return false;
  }

  const { rowIndex, columnIndex, path, numRows } = getTableData(editor, tableEntry[0]);
  if (!path) {
    return false;
  }

  const tableCellPath = tableCellEntry[1];
  const nodes = Array.from(
    Editor.nodes(editor, {
      at: tableCellPath,
      mode: 'all',
      match: (el) => Element.isElement(el) && el.type === BLOCK_TYPES.TEXT,
    })
  );
  const lastNodeEntry = nodes[nodes.length - 1];
  const isEnd = Path.isDescendant(Range.end(selection).path, lastNodeEntry[1]);
  if (!isEnd) {
    return false;
  }

  event.preventDefault();

  if (rowIndex >= numRows - 1) {
    focusNextBlock(editor, path);
  } else {
    focusTableCell(editor, path, rowIndex + 1, columnIndex);
  }

  return true;
};

export const tableKeyDownHandler = (editor: Editor, event: React.KeyboardEvent): boolean => {
  const { nativeEvent } = event;
  if (isUpHotkey(nativeEvent)) {
    return handleUpArrow(editor, event);
  }

  if (isDownHotkey(nativeEvent)) {
    return handleDownArrow(editor, event);
  }

  return false;
};
