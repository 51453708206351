import type { EnterpriseBadge } from 'bundles/page-config-common/providers/enterprise/EnterpriseBadgeCollectionsProvider';

import _t from 'i18n!nls/program-home';

export const getProductsFromUnenrolledBadges = (badges: Array<EnterpriseBadge>) => {
  const enrolledProductIds = new Set<string>();
  const recommendedProductIds = new Set<string>();
  const badgeProductMapping: { [key: string]: EnterpriseBadge } = {};

  if (badges) {
    badges.forEach((badge) => {
      if (badge.userState !== 'ENROLLED') {
        return;
      }
      badge.productIds.forEach((product) => {
        enrolledProductIds.add(product.id);
      });
    });
    badges.forEach((badge) => {
      if (badge.userState === 'ENROLLED' || badge.userState === 'ISSUED') {
        return;
      }
      badge.productIds.forEach((product) => {
        if (enrolledProductIds.has(product.id)) {
          return false;
        }
        badgeProductMapping[product.id] = !badgeProductMapping[product.id] ? badge : badgeProductMapping[product.id];
        recommendedProductIds.add(product.id);

        return true;
      });
    });
  }

  return {
    recommendedProductIds,
    badgeProductMapping,
  };
};

// Function to remove 'credential' from badge titles that were created during the development of enterprise badges
// since the BE doesn't support updating badge titles.
export const removeCredentialFromBadgeTitle = (title: string): string => {
  const badgeTitle = title.substring(0, title.trim().toLowerCase().lastIndexOf('credential')) || title;
  return _t('#{badgeTitle} learning path', { badgeTitle });
};
