import _ from 'lodash';

import { tupleToStringKey } from 'js/lib/stringKeyTuple';

import { requireFields } from 'bundles/naptimejs/util/requireFieldsDecorator';
import ProductType from 'bundles/payments/common/ProductType';
import { subscriptionBillingType } from 'bundles/payments/common/constants';

import NaptimeResource from './NaptimeResource';

const { MONTHLY, ANNUAL } = subscriptionBillingType;

class ProductSkus extends NaptimeResource {
  static RESOURCE_NAME = 'productSkus.v1';

  declare underlyingProductId: string;

  declare id: string;

  declare productType: string;

  declare productItemId: string;

  declare iapSku?: string;

  declare isAcive: boolean;

  declare properties?:
    | {
        'org.coursera.product.SubscriptionProductProperties'?: {
          billingCycle: 'MONTHLY' | 'ANNUAL' | 'BIANNUAL';
          numberOfCycles?: number;
          numberOfTrialDays: number;
          refundDays?: number;
          status: 'ENABLED' | 'DISABLED';
        };
        'org.coursera.product.PrepaidProductProperties': undefined;
      }
    | {
        'org.coursera.product.SubscriptionProductProperties': undefined;
        'org.coursera.product.PrepaidProductProperties'?: {
          ownershipDays: number;
          refundDays: number;
        };
      };

  @requireFields('properties')
  get subscriptionType() {
    // TODO(htran): add support for `PrepaidProductProperties` once BE is ready
    const subscriptionProperties =
      this.properties && this.properties['org.coursera.product.SubscriptionProductProperties'];
    return subscriptionProperties ? subscriptionProperties.billingCycle : null;
  }

  @requireFields('properties')
  get isSubscription() {
    return _.includes([MONTHLY, ANNUAL], this.subscriptionType);
  }

  @requireFields('properties')
  get numberOfCycles(): number | null {
    // TODO(htran): add support for `PrepaidProductProperties` once BE is ready
    const subsProperties = this.properties && this.properties['org.coursera.product.SubscriptionProductProperties'];
    return subsProperties?.numberOfCycles || null;
  }

  static getSpecializationSubscription(s12nId: string, query = {}) {
    return this.get(tupleToStringKey([ProductType.SPECIALIZATION_SUBSCRIPTION, s12nId]), query);
  }

  static getSpecializationPrepaid(productItemId: string, query = {}) {
    return this.get(tupleToStringKey([ProductType.SPECIALIZATION_PREPAID, productItemId]), query);
  }

  static getCourseraPlusSubscription(productItemId: string, query = {}) {
    return this.get(tupleToStringKey([ProductType.COURSERA_PLUS_SUBSCRIPTION, productItemId]), query);
  }

  static getCourseraLiteSubscription(productItemId: string, query = {}) {
    return this.get(tupleToStringKey([ProductType.COURSERA_TIER_LITE, productItemId]), query);
  }

  static getCredentialTrackSubscription(credentialTrackId: string, query = {}) {
    return this.get(tupleToStringKey([ProductType.CREDENTIAL_TRACK_SUBSCRIPTION_V2, credentialTrackId]), query);
  }

  static multiGetSpecializationSubscriptions(s12nIds: string[], query = {}) {
    return this.multiGet(
      s12nIds.map((id) => tupleToStringKey([ProductType.SPECIALIZATION_SUBSCRIPTION, id])),
      query
    );
  }

  static findByUnderlying(s12nId: string, opts = {}) {
    return this.finder(
      'findByUnderlying',
      Object.assign(
        {
          params: {
            id: tupleToStringKey([ProductType.SPECIALIZATION, s12nId]),
          },
        },
        opts
      )
    );
  }
}

export default ProductSkus;
