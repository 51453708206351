import React from 'react';

import clsx from 'clsx';

import type { BaseComponentProps } from '@coursera/cds-common';

import Button from '@core/Button';
import type { ButtonProps } from '@core/Button';

import getButtonGroupCss, { classes } from './getButtonGroupCss';

export type PrimaryButtonProps = Omit<
  ButtonProps,
  'variant' | 'size' | 'iconPosition'
>;

export type AlternateButtonProps = Omit<
  ButtonProps,
  'variant' | 'size' | 'iconPosition'
> & {
  variant: 'ghost' | 'secondary';
};

export type Props = BaseComponentProps<'div'> & {
  /**
   * Specify positioning of the icon relative to the button text.
   * @default after
   */
  iconPosition?: 'before' | 'after';
  /**
   * Set it on dark background to automatically convert primary to primaryInvert and ghost to ghostInvert.
   * @default false
   */
  invert?: boolean;
  /**
   * Vertical or horizonatl alignment of the buttons.
   * @default horizontal
   */
  orientation?: 'horizontal' | 'vertical';
  /**
   * The size of the buttons.
   * @default medium
   */
  size?: 'small' | 'medium';
  /**
   * All primary button props except `variant`, `iconPosition` and `size`
   */
  primaryButtonProps: PrimaryButtonProps;
  /**
   * All button props for the alternative button except `iconPosition` and `size`
   * `variant` is limited to `secondary`, `ghost` and `primaryInvert`
   */
  alternateButtonProps: AlternateButtonProps;
};

/**
 * The button group is used to group 2 related transactional buttons critical to the user's task flow.
 * The group always includes a primary button for the default action and a button that represents an alternate choice for the user.
 *
 * See [Props](__storybookUrl__/components-actions-button-group--default#props)
 */
const ButtonGroup = React.forwardRef(function ButtonGroup(
  props: Props,
  ref: React.Ref<HTMLDivElement>
) {
  const {
    className,
    size = 'medium',
    invert = false,
    iconPosition = 'after',
    orientation = 'horizontal',
    primaryButtonProps,
    alternateButtonProps: { variant, ...restAlternateButtonProps },
    ...rest
  } = props;

  const alternateBtnVariant =
    variant === 'ghost' && invert ? 'ghostInvert' : variant;

  return (
    <div
      ref={ref}
      className={clsx(className, {
        [classes.vertical]: orientation === 'vertical',
      })}
      css={getButtonGroupCss}
      role="group"
      {...rest}
    >
      <Button
        {...primaryButtonProps}
        iconPosition={iconPosition}
        size={size}
        variant={invert ? 'primaryInvert' : 'primary'}
      />
      <Button
        {...restAlternateButtonProps}
        iconPosition={iconPosition}
        size={size}
        variant={alternateBtnVariant}
      />
    </div>
  );
});

export default ButtonGroup;
