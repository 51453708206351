export default function fetchMoreErrorFilter(err: Error) {
  // If we are yanked from the DOM while fetching more results, apollo throws because it can't find the query to merge
  // into. Unfortunately, there's no way to know about this or even cancel the request in this version of apollo.
  // Swallow the error since it's actually a "problem" that needs to be reported.
  //
  // Ideally, we would strictly filter on `err.message.startsWith("ObservableQuery with this id doesn't exist:")`, but
  // there's some bug that's making it pass through an `undefined` message, and so we get an error in the form
  // "Invariant Error: Invariant Error"
  //
  // See: https://github.com/apollographql/apollo-client/issues/4114#issuecomment-502111099
  const componentDidUnmount =
    err.name === 'Invariant Violation' &&
    (err.message.startsWith("ObservableQuery with this id doesn't exist:") || err.message === 'Invariant Error');
  if (!componentDidUnmount) {
    throw err;
  }
}
