import type { FunctionComponent } from 'react';
import * as React from 'react';
import { Link as ReactRouterLink } from 'react-router';

import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { compose } from 'recompose';

import { tupleToStringKey } from 'js/lib/stringKeyTuple';
import useRouter from 'js/lib/useRouter';

import { Typography2 } from '@coursera/cds-core';
import type { UserEmail } from '@coursera/grpc-types-useremails/coursera/proto/useremails/v1/email_address';

import { closeMiniModalWithRoute, useRouterParams } from 'bundles/enterprise-legacy-xdp/components/ProgramMiniModal';
import type { InjectedNaptime } from 'bundles/naptimejs';
import Naptime from 'bundles/naptimejs';
import type EnterpriseProgramsV1 from 'bundles/naptimejs/resources/enterprisePrograms.v1';
import type ProgramMembershipsV2Type from 'bundles/naptimejs/resources/programMemberships.v2';
import type ThirdPartyOrganizationsV1Type from 'bundles/naptimejs/resources/thirdPartyOrganizations.v1';
import ExperimentalProgramFullScreenModal from 'bundles/program-common/components/ExperimentalProgramFullScreenModal';
import type { ProductCardCourseFragmentFragment as Course } from 'bundles/program-common/queries/__generated__/ProductCoursesQuery';
import storageUtils from 'bundles/program-common/utils/storageUtils';
import AutoenrolledCoursesModal from 'bundles/program-home/components/modals/AutoenrolledCoursesModal';
import ProgramAccessModal from 'bundles/program-home/components/modals/ProgramAccessModal';
import ProgramAddEmailModal from 'bundles/program-home/components/modals/ProgramAddEmailModal';
import ProgramAlternativeEmailModal from 'bundles/program-home/components/modals/ProgramAlternativeEmailModal';
import type {
  ProgramHomeModalManagerXdpQuery,
  ProgramHomeModalManagerXdpQueryVariables,
} from 'bundles/program-home/components/modals/__generated__/ProgramHomeModalManagerXdpQuery';
import {
  AUTOENROLLED_COURSES_COOKIE_KEY,
  AUTOENROLLED_COURSES_COOKIE_PATH,
} from 'bundles/program-home/components/modals/constants';
import ProgramLearnerApiManager from 'bundles/program-home/utils/ProgramLearnerApiManager';
import { useProgramMembership } from 'bundles/unified-common/contexts/EnterpriseProgramContext';
import { useAvailableEnrollmentOptions } from 'bundles/unified-description-page-common/contexts/enterprise/EnterpriseAvailableEnrollmentProgramsContext';

import _t from 'i18n!nls/program-home';

export type PropsFromCaller = {
  isProgramAdmin: boolean;
  program: EnterpriseProgramsV1;
  programId: string;
  programMembership?: ProgramMembershipsV2Type;
  reloadProgramMembership: () => void;
  thirdPartyOrganization: ThirdPartyOrganizationsV1Type;
  userEmails: UserEmail[] | undefined;
  userId: number;
  isInLoginMode: boolean;
  isUpswell: boolean;
  isC4cv: boolean;
  isC4C?: boolean;
  isNonBlacklistedC4cv: boolean;
  showNoCertificateNotification?: boolean;
  hasFreemiumTag?: boolean;
  isBrowseOnlyProgram: boolean;
  shouldShowSponsoredByMessage: boolean;
  shouldUseJoinTrackingVerbiage: boolean;
  isGwGProgram?: boolean;
  programRecommendationsAvailableCoursesCopy: boolean;
  programSlug: string;
  autoEnrolledCoursesWithData?: Course[];
};

export type PropsFromNaptime = {
  naptime: InjectedNaptime;
};

export type Props = PropsFromCaller & PropsFromNaptime;

export const programHomeModalManagerXdpQuery = gql`
  query ProgramHomeModalManagerXdpQuery($courseStringKey: String!) {
    XdpV1Resource {
      get(id: $courseStringKey) {
        id
      }
    }
  }
`;

export const ProgramHomeModalManager: FunctionComponent<Props> = ({
  programMembership,
  reloadProgramMembership,
  thirdPartyOrganization,
  program,
  programSlug,
  userId,
  isProgramAdmin,
  userEmails,
  programId,
  isUpswell,
  isNonBlacklistedC4cv,
  showNoCertificateNotification,
  hasFreemiumTag,
  isBrowseOnlyProgram,
  shouldShowSponsoredByMessage,
  shouldUseJoinTrackingVerbiage,
  isC4C,
  isGwGProgram,
  programRecommendationsAvailableCoursesCopy,
  autoEnrolledCoursesWithData,
  isInLoginMode,
  naptime,
}) => {
  // TODO: we still need to use the customized `useRouterParams()` (NOTE: This is NOT the useRouterParams hook!)
  // due to compatibility. Need to check if this can be removed.
  const router = useRouter();
  const routerParams = useRouterParams(router);
  const availableEnrollmentOptions = useAvailableEnrollmentOptions();
  const unifiedProgramMembership = useProgramMembership();

  // test if logged out user trying to view a private course
  const courseStringKey = routerParams ? tupleToStringKey(['COURSE', routerParams.productId]) : null;
  const productType = routerParams?.productType;
  const skipQuery = userId || !courseStringKey || productType !== 'course';

  const { loading, data } = useQuery<ProgramHomeModalManagerXdpQuery, ProgramHomeModalManagerXdpQueryVariables>(
    programHomeModalManagerXdpQuery,
    {
      // We don't need this query to run if the user is logged in or if the course string key isn't
      // formatted properly or they aren't trying to view a course mini modal.
      // @ts-expect-error react-apollo@2.2.0
      skip: skipQuery,
      variables: {
        courseStringKey: courseStringKey ?? '',
      },
      ssr: false,
    }
  );

  // const isLoggedOutUserViewingPrivateCourse = skipQuery ? false : !(loading || data?.XdpV1Resource?.get);
  // only show the login dialog if: (1) query sent (== query condition met) (2) query done (3) query returned null
  const isLoggedOutUserViewingPrivateCourse = !(skipQuery || loading || data?.XdpV1Resource?.get);

  const [isAccessModalDismissed, setIsAccessModalDismissed] = React.useState(false);
  const [isAutoenrolledCoursesModalDismissed, setIsAutoenrolledCoursesModalDismissed] = React.useState(false);

  const apiManager = new ProgramLearnerApiManager({ program, naptime });
  const isProgramMember = !!(programMembership && programMembership.isMember);
  const hasModalToDisplay = programMembership && programMembership.hasModalToDisplay;

  // Do not show this modal when in specific case on enterprise unified XDP where user is not member of current program,
  // but is member of other programs in current org and has other options to enroll.
  // availableEnrollmentOptions.programs will have data if this is the case.
  // In this scenario, this modal will surface the "program deny modal". This is not relevant for learners in this case
  // since they can enroll and could cause confusion.
  const showProgramAccessModal =
    !isBrowseOnlyProgram &&
    !isInLoginMode &&
    !!programMembership &&
    !isAccessModalDismissed &&
    !unifiedProgramMembership?.isMembershipLoading &&
    !availableEnrollmentOptions?.isLoading &&
    !availableEnrollmentOptions?.programs?.length &&
    !availableEnrollmentOptions?.invitedPrograms?.length;

  const showProgramMiniModal = isBrowseOnlyProgram || isAccessModalDismissed || !hasModalToDisplay;

  const showNonBlockingModal = !isBrowseOnlyProgram && !isInLoginMode && isAccessModalDismissed;

  // Check to see if there are any autoenrollment course triggers stored, and compare it to the
  // autoenrolledCourseIds to see if there are any new autoenrollment triggers to show
  const autoenrolledModalCookieValue: string[] = storageUtils.get(
    userId,
    AUTOENROLLED_COURSES_COOKIE_PATH,
    AUTOENROLLED_COURSES_COOKIE_KEY,
    []
  );

  const filteredAutoenrollmentCourses = autoEnrolledCoursesWithData?.filter(
    (course) => !autoenrolledModalCookieValue.includes(course.id)
  );

  const showAutoenrolledCoursesModal =
    !isBrowseOnlyProgram &&
    !isInLoginMode &&
    isProgramMember &&
    Boolean(filteredAutoenrollmentCourses && filteredAutoenrollmentCourses.length > 0) &&
    !isAutoenrolledCoursesModalDismissed &&
    Boolean(filteredAutoenrollmentCourses?.length);

  const showLoginModalForPrivateCourse = showProgramMiniModal && !isInLoginMode && isLoggedOutUserViewingPrivateCourse;

  const onCloseAccessModal = () => {
    setIsAccessModalDismissed(true);
  };
  const onCloseMiniModal = () => {
    closeMiniModalWithRoute(router);
  };

  if (showProgramAccessModal) {
    return (
      <ProgramAccessModal
        apiManager={apiManager}
        isAccessModalDismissed={isAccessModalDismissed}
        isProgramAdmin={isProgramAdmin}
        onCloseAccessModal={onCloseAccessModal}
        program={program}
        programMembership={programMembership}
        reloadProgramMembership={reloadProgramMembership}
        router={router}
        thirdPartyOrganization={thirdPartyOrganization}
        userId={userId}
        isUpswell={isUpswell}
        isNonBlacklistedC4cv={isNonBlacklistedC4cv}
        shouldUseJoinTrackingVerbiage={shouldUseJoinTrackingVerbiage}
        isGwGProgram={isGwGProgram}
        programRecommendationsAvailableCoursesCopy={programRecommendationsAvailableCoursesCopy}
        autoEnrolledCoursesWithData={autoEnrolledCoursesWithData}
      />
    );
  } else if (showLoginModalForPrivateCourse) {
    router.push({
      pathname: router.location.pathname,
      params: router.params,
      query: { ...router.location.query, authMode: 'login' },
    });
    return null;
  } else if (showProgramMiniModal && routerParams) {
    return (
      <ExperimentalProgramFullScreenModal
        thirdPartyOrganizationName={thirdPartyOrganization.name}
        thirdPartyOrgId={thirdPartyOrganization.id}
        isProgramMember={isProgramMember}
        isProgramAdmin={isProgramAdmin}
        programId={programId}
        renderCourseHomeLink
        onCloseMiniModal={onCloseMiniModal}
        isUpswell={isUpswell}
        isNonBlacklistedC4cv={isNonBlacklistedC4cv}
        showNoCertificateNotification={showNoCertificateNotification || false}
        hasFreemiumTag={hasFreemiumTag}
        hideAction={isBrowseOnlyProgram}
        disableSponsoredByMessage={!shouldShowSponsoredByMessage}
        isC4C={isC4C}
        {...routerParams}
        thirdPartyOrganizationSlug={thirdPartyOrganization.slug}
        program={program}
        breadcrumbExtraMargin
        breadcrumb={
          <ReactRouterLink to={`/programs/${programSlug}`}>
            <Typography2 variant="subtitleMedium" component="div" color="highlightBlue">
              {_t('Back to #{programName}', { programName: program.name })}
            </Typography2>
          </ReactRouterLink>
        }
      />
    );
  } else if (showAutoenrolledCoursesModal && autoEnrolledCoursesWithData) {
    return (
      <AutoenrolledCoursesModal
        userId={userId}
        autoEnrolledCoursesWithData={autoEnrolledCoursesWithData}
        setIsAutoenrolledCoursesModalDismissed={setIsAutoenrolledCoursesModalDismissed}
        programId={programId}
        programSlug={programSlug}
      />
    );
  } else if (showNonBlockingModal) {
    return (
      <>
        <ProgramAddEmailModal
          apiManager={apiManager}
          programId={program.id}
          programName={program.name}
          userId={userId}
          thirdPartyOrganizationId={thirdPartyOrganization.id}
        />
        {userEmails && (
          <ProgramAlternativeEmailModal
            apiManager={apiManager}
            programId={program.id}
            programName={program.name}
            userEmails={userEmails}
            userId={userId}
            thirdPartyOrganizationId={thirdPartyOrganization.id}
          />
        )}
      </>
    );
  }
  return null;
};

export const enhance = compose<Props, PropsFromCaller>(
  Naptime.createContainer(() => ({})) // Pass naptime down as prop
);

export default enhance(ProgramHomeModalManager);
