/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import type { MouseEvent } from 'react';
import * as React from 'react';

import type { TrackingData } from 'js/lib/retracked';

import { Link, Tag, Typography2, breakpoints } from '@coursera/cds-core';
import type { LinkProps } from '@coursera/cds-core';
import { ArrowNextIcon, SuccessFilledIcon } from '@coursera/cds-icons';

import { BasicCard } from 'bundles/cds-labs/components';
import withSingleTracked from 'bundles/common/components/withSingleTracked';
import { redirectClientOrServer } from 'bundles/common/utils/urlUtils';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { useTracker, useVisibilityTracker } from 'bundles/page/lib/event-pulse/react';
import { SKILL_LEVEL_SEGMENTS, getLevelName } from 'bundles/program-skills-objectives/utils';
import { PlaceholderShimmer, ProgressTracker, UnscorableProgressTracker } from 'bundles/skills-common';
import { ProgressTrackerMyScorePopoverWithScore } from 'bundles/skills-common/private/progress-tracker/ProgressTrackerMyScorePopover';
import SkillsInsightMessage from 'bundles/skills-common/private/progress-tracker/SkillsInsightMessage';

import _t from 'i18n!nls/program-home';

import SkillIcon from './SkillIcon';
import type { SkillProgress } from './withSkillsData';

const TrackedLink = withSingleTracked({ type: 'BUTTON' })<LinkProps>(Link);

const styles = {
  skillCard: css`
    border: 2px solid var(--cds-color-neutral-stroke-primary-weak);
    padding: var(--cds-spacing-300);
    cursor: pointer;
    border-radius: var(--cds-spacing-200);
  `,
  skillName: css`
    display: flex;
    align-items: center;
    gap: var(--cds-spacing-100);
  `,
  levelsetCompleted: css`
    ${breakpoints.up('sm')} {
      margin-left: auto;
    }
    ${breakpoints.down('xs')} {
      flex-basis: 100%;
      margin-top: var(--cds-spacing-100);
    }

    display: flex;
    align-items: center;
    gap: var(--cds-spacing-50);
  `,
  links: css`
    display: flex;
    flex-wrap: wrap;
    gap: var(--cds-spacing-300);
    margin-top: var(--cds-spacing-200);
  `,
  link: css`
    display: flex;
    align-items: center;
    gap: var(--cds-spacing-50);
    ${breakpoints.down('sm')} {
      width: 100%;
    }
  `,
};

type Props = {
  className?: string;
  skill: SkillProgress;
  programSlug: string;
  trackingData: TrackingData;
  trackingName: string;
  size: 'small' | 'large';
  source: string;
  currentRefinement?: string | undefined;
  hideLinks?: boolean;
};

const SkillCardPlaceholder = ({ className }: Pick<Props, 'className'>) => {
  return (
    <BasicCard className={className} css={styles.skillCard}>
      <PlaceholderShimmer height="106px" />
    </BasicCard>
  );
};

const SkillCard: React.FunctionComponent<Props> = (props) => {
  const track = useTracker();
  const { currentRefinement } = props;
  const { skillId, skillName, score } = props.skill;
  const { order } = props.trackingData;

  const levelsetCompleted =
    props.skill.levelsetStatus === 'COMPLETED_ALL' || props.skill.levelsetStatus === 'COMPLETED_ONCE';
  const canTakeLevelset =
    props.skill.levelsetStatus !== 'COMPLETED_ALL' && props.skill.levelsetStatus !== 'UNAVAILABLE';

  const skillUrl = `/programs/${props.programSlug}/skills/${props.skill.skillId}`;

  const eventingV3SkillData = {
    skillCard: {
      index: order,
      skillScore: score,
    },
    skill: {
      id: skillId,
      name: skillName,
    },
    ...(props.trackingData?.pageSection && { pageSection: props.trackingData.pageSection }),
    ...(currentRefinement && {
      searchedQuery: currentRefinement,
    }),
  };

  const skillCardRef: React.MutableRefObject<HTMLDivElement | null> = useVisibilityTracker(
    'view_skill_card',
    eventingV3SkillData
  );

  const handleSkillCardClick = () => {
    track('click_skill_card', eventingV3SkillData);
    redirectClientOrServer(`${skillUrl}?source=${props.source}`);
  };

  const handleSkillCardKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleSkillCardClick();
    }
  };

  const handleTrackedLinkClick = (e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
    e.stopPropagation();
    track('click_skill_card', eventingV3SkillData);
  };

  return (
    // This is not the only way to get to the skills recs, there is a link which will be more useful for a11y and keyboard
    <BasicCard
      ref={skillCardRef}
      role="button"
      tabIndex={0}
      className={props.className}
      css={styles.skillCard}
      onClick={handleSkillCardClick}
      onKeyPress={handleSkillCardKeypress}
      data-testid={props.trackingName}
    >
      <TrackedDiv trackClicks withVisibilityTracking trackingName={props.trackingName} data={props.trackingData}>
        <Typography2 variant="subtitleMedium" component="h3" css={styles.skillName}>
          <SkillIcon />
          {props.skill.skillName}
        </Typography2>
        {props.skill.isEligibleForScoring ? (
          <ProgressTracker
            forScoring
            hideTarget
            wrapHeader
            score={props.skill.score}
            segments={SKILL_LEVEL_SEGMENTS}
            afterPersonalScore={
              props.skill.score ? <Tag priority="secondary">{getLevelName(props.skill.score)}</Tag> : undefined
            }
            endOfHeader={
              props.size === 'large' && levelsetCompleted ? (
                <div css={styles.levelsetCompleted}>
                  <SuccessFilledIcon color="success" size="small" />
                  <Typography2 color="supportText" variant="bodySecondary" component="span">
                    {_t('LevelSet completed')}
                  </Typography2>
                </div>
              ) : undefined
            }
            scoreTooltip={
              <ProgressTrackerMyScorePopoverWithScore
                score={props.skill.score}
                skillId={props.skill.skillId}
                skillName={props.skill.skillName}
              />
            }
          />
        ) : (
          <UnscorableProgressTracker />
        )}
        {props.skill.isEligibleForScoring && (
          <SkillsInsightMessage score={props.skill.score} skillId={props.skill.skillId} />
        )}
        {!props.hideLinks && (
          <div css={styles.links}>
            <TrackedLink
              {...Link.defaultProps}
              css={styles.link}
              tabIndex={0}
              trackingName={`${props.trackingName}_link`}
              trackingData={props.trackingData}
              href={`${skillUrl}?source=${props.source}`}
              variant="quiet"
              onClick={(e) => handleTrackedLinkClick(e)}
            >
              <Typography2
                color="inherit"
                variant="subtitleMedium"
                component="span"
                aria-label={_t('View recommendations for #{skillName}', { skillName: props.skill.skillName })}
              >
                {_t('View recommendations')}
              </Typography2>
              <ArrowNextIcon />
            </TrackedLink>
            {props.size === 'large' && canTakeLevelset && (
              <TrackedLink
                {...Link.defaultProps}
                tabIndex={0}
                css={styles.link}
                trackingName={`${props.trackingName}_levelset`}
                variant="quiet"
                trackingData={props.trackingData}
                href={`/levelsets/${props.skill.skillId}?source=${props.source}&programSlug=${props.programSlug}&skillId=${props.skill.skillId}`}
                onClick={(e) => handleTrackedLinkClick(e)}
              >
                <Typography2 color="inherit" variant="subtitleMedium" component="span">
                  {props.skill.levelsetStatus === 'NOT_STARTED' && _t('Check your level')}
                  {props.skill.levelsetStatus === 'COMPLETED_ONCE' && _t('Retake assessment')}
                  {props.skill.levelsetStatus === 'IN_PROGRESS' && _t('Finish assessment')}
                </Typography2>
                <ArrowNextIcon />
              </TrackedLink>
            )}
          </div>
        )}
      </TrackedDiv>
    </BasicCard>
  );
};

export { SkillCardPlaceholder };
export default SkillCard;
