import * as React from 'react';

import _t from 'i18n!nls/program-common';

type SvgAccessToAllContentProps = React.SVGProps<SVGSVGElement>;

const SvgAccessToAllContent: React.FC<SvgAccessToAllContentProps> = (props) => (
  <svg
    width={60}
    height={62}
    viewBox="0 0 60 62"
    role="presentation"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title id="SvgAccessToAllContent-Title">{_t('Access to all content')}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.4127 1.61518L58.1409 18.1778C59.4628 19.0288 60.1156 20.5276 59.9513 21.9907L59.9881 21.99V41.0131L59.8808 41.0133C59.781 41.461 59.6019 41.899 59.3391 42.3072C59.0298 42.7876 58.6213 43.1961 58.1409 43.5054L31.9177 60.3867C30.5991 61.2355 28.9061 61.2355 27.5874 60.3867L1.85918 43.824C0.839934 43.1678 0.218446 42.1265 0.062645 41.0133L0.0119629 41.0131V21.99L0.0261191 21.9904C-0.000222431 21.2056 0.203744 20.4049 0.661008 19.6946C0.970252 19.2143 1.37881 18.8057 1.85918 18.4965L28.0823 1.61518C29.401 0.766296 31.094 0.766296 32.4127 1.61518Z"
      fill="#2A73CC"
      fillOpacity="0.1"
    />
    <g opacity="0.75">
      <path d="M37.2999 23.6992H26.8999V25.7992H37.2999V23.6992Z" fill="#1F1F1F" />
      <path d="M37.2999 30H26.8999V32.1H37.2999V30Z" fill="#1F1F1F" />
      <path d="M37.2999 36.1992H26.8999V38.2992H37.2999V36.1992Z" fill="#1F1F1F" />
      <path
        d="M23.7 25.6992C24.2522 25.6992 24.7 25.2515 24.7 24.6992C24.7 24.1469 24.2522 23.6992 23.7 23.6992C23.1477 23.6992 22.7 24.1469 22.7 24.6992C22.7 25.2515 23.1477 25.6992 23.7 25.6992Z"
        fill="#1F1F1F"
      />
      <path
        d="M23.7 32C24.2522 32 24.7 31.5523 24.7 31C24.7 30.4477 24.2522 30 23.7 30C23.1477 30 22.7 30.4477 22.7 31C22.7 31.5523 23.1477 32 23.7 32Z"
        fill="#1F1F1F"
      />
      <path
        d="M23.7 38.3008C24.2522 38.3008 24.7 37.8531 24.7 37.3008C24.7 36.7485 24.2522 36.3008 23.7 36.3008C23.1477 36.3008 22.7 36.7485 22.7 37.3008C22.7 37.8531 23.1477 38.3008 23.7 38.3008Z"
        fill="#1F1F1F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 16.4004V45.6004H41.5V16.4004H18.5ZM39.4 43.5004H20.6V18.5004H39.4V43.5004Z"
        fill="#1F1F1F"
      />
    </g>
  </svg>
);

export default SvgAccessToAllContent;
