import keysToConstants from 'js/lib/keysToConstants';

// REF https://github.com/webedx-spark/infra-services/blob/main/libs/models/src/main/pegasus/org/coursera/enrollment/EnrollmentChoiceReasonCode.courier
const exported = keysToConstants([
  'BLOCKED_ON_IOS',
  'ENROLLED',
  'PURCHASED_SINGLE_COURSE',
  'CAPSTONE_ACCESS_LOCKED',
  'SPECIALIZATION_BULK_PAID',
  'SPECIALIZATION_ENROLLED_THROUGH_COURSERA_PLUS',
  'SPECIALIZATION_SUBSCRIBED',
  'REACHED_MAX_ALLOWANCE',
  'REGION_BLOCKED',
  'NO_AVAILABLE_SESSION',
  'SPECIALIZATION_UPGRADE_REQUIRED',
  'CATALOG_SUBSCRIBED',
  'EARNED_S12N_CERTIFICATE',
  'PRE_ENROLLED',
  'NOT_ELIGIBLE_TO_PRE_ENROLL',
]);

export const {
  BLOCKED_ON_IOS,
  ENROLLED,
  PURCHASED_SINGLE_COURSE,
  CAPSTONE_ACCESS_LOCKED,
  SPECIALIZATION_BULK_PAID,
  SPECIALIZATION_ENROLLED_THROUGH_COURSERA_PLUS,
  SPECIALIZATION_SUBSCRIBED,
  REACHED_MAX_ALLOWANCE,
  REGION_BLOCKED,
  NO_AVAILABLE_SESSION,
  SPECIALIZATION_UPGRADE_REQUIRED,
  CATALOG_SUBSCRIBED,
  EARNED_S12N_CERTIFICATE,
  PRE_ENROLLED,
  NOT_ELIGIBLE_TO_PRE_ENROLL,
} = exported;

export default exported;
