import { isRightToLeft } from 'js/lib/language';

import _t from 'i18n!nls/cml';

const getFocusableButtons = (el: HTMLElement | null) =>
  Array.from(el?.querySelectorAll('button:not([disabled])') || []) as (HTMLButtonElement | null)[];

const getFocusableButton = (
  el: HTMLElement | null,
  focusedButton: HTMLButtonElement | null,
  direction: 'next' | 'previous'
): HTMLButtonElement | null => {
  const buttons = getFocusableButtons(el);
  if (!buttons.length) {
    return null;
  }

  let nextPosition = buttons.indexOf(focusedButton) + (direction === 'next' ? 1 : -1);
  if (nextPosition >= buttons.length) {
    nextPosition = 0;
  } else if (nextPosition < 0) {
    nextPosition = buttons.length - 1;
  }

  return buttons[nextPosition];
};

export const getNextButton = (
  el: HTMLElement | null,
  focusedButton: HTMLButtonElement | null
): HTMLButtonElement | null => {
  const rtl = isRightToLeft(_t.getLocale());
  const direction = rtl ? 'previous' : 'next';
  return getFocusableButton(el, focusedButton, direction);
};

export const getPreviousButton = (
  el: HTMLElement | null,
  focusedButton: HTMLButtonElement | null
): HTMLButtonElement | null => {
  const rtl = isRightToLeft(_t.getLocale());
  const direction = rtl ? 'next' : 'previous';
  return getFocusableButton(el, focusedButton, direction);
};

export const maybeUpdateFocusedButton = (el: HTMLElement | null, focusedButton: HTMLButtonElement | null) => {
  const buttons = getFocusableButtons(el);
  if (!focusedButton) {
    return buttons[0];
  }

  if (buttons.includes(focusedButton)) {
    return focusedButton;
  }

  const lastButton = buttons[buttons.length - 1] || null;
  return lastButton;
};
