import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('MenuList', [
  'groupLabel',
  'groupDivider',
  'groupList',
]);

const styles = css`
  display: flex;
  gap: var(--cds-spacing-50);
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;

  .${classes.groupDivider} {
    padding: var(--cds-spacing-150) 0;

    &:first-of-type {
      display: none;
    }
  }

  .${classes.groupLabel} {
    padding: var(--cds-spacing-100);
  }

  .${classes.groupList} {
    padding: 0;
  }
`;

export default styles;
