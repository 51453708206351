import * as React from 'react';

import { useId } from '@coursera/cds-core';

/**
 * Coach avatar icon with three states: default, wink, and disabled
 */

const CoachIcon = ({
  size = 'medium',
  state = 'default',
}: {
  size?: 'small' | 'medium' | 'large';
  state?: 'default' | 'wink' | 'disabled';
}) => {
  // A unique gradientId is needed only if the same SVG is used multiple times on a page. This enables us to have unique ids so the gradient renders properly for each instance of the SVG
  const baseId = useId();
  const gradientId = `${baseId}-gradient`;

  const smallDefault = (
    <svg
      data-testid="coachSmallDefault"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path
        d="M4.78062 18.5628C4.9461 18.3642 4.93183 18.073 4.7574 17.8821C3.06422 16.0294 2 13.5003 2 11C2 8.34784 3.05357 5.8043 4.92893 3.92893C6.8043 2.05357 9.34784 1 12 1C13.6874 1 15.3474 1.46971 16.8254 2.36539C18.3034 3.26107 19.5513 4.55359 20.4529 6.1226C21.3545 7.69161 21.8805 9.48607 21.9819 11.3389C22.0833 13.1917 21.7569 15.0426 21.0329 16.7193C20.309 18.396 19.2111 19.8439 17.8415 20.9281C16.4719 22.0124 14.8751 22.6978 13.1998 22.9205C12.8005 22.9736 12.3999 23 12 23C9.24138 23 6.19664 21.7908 4.51767 20.0963C4.1915 19.7671 4.20942 19.2486 4.50602 18.8925L4.78062 18.5628Z"
        fill={`url(#paint0_radial_256_1312_${gradientId})`}
      />
      <circle cx="18" cy="11" r="5" fill={`url(#paint1_linear_256_1312_${gradientId})`} />
      <circle cx="6" cy="11" r="5" fill={`url(#paint2_linear_256_1312_${gradientId})`} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 14.5C7.933 14.5 9.5 12.933 9.5 11C9.5 9.067 7.933 7.5 6 7.5C4.067 7.5 2.5 9.067 2.5 11C2.5 12.933 4.067 14.5 6 14.5ZM6 16C8.76142 16 11 13.7614 11 11C11 10.5903 10.9507 10.192 10.8577 9.81093C11.1925 9.61316 11.5826 9.5 12 9.5C12.4174 9.5 12.8075 9.61316 13.1423 9.81093C13.0493 10.192 13 10.5903 13 11C13 13.7614 15.2386 16 18 16C20.7614 16 23 13.7614 23 11C23 10.6575 22.9656 10.3231 22.9 10H23.5C23.7761 10 24 9.77614 24 9.5V8.5C24 8.22386 23.7761 8 23.5 8H22.0004C21.0882 6.78555 19.6358 6 18 6C16.1841 6 14.5943 6.96805 13.7185 8.41624C13.2035 8.15036 12.619 8 12 8C11.381 8 10.7965 8.15036 10.2815 8.41624C9.40573 6.96805 7.81592 6 6 6C4.36418 6 2.91184 6.78555 1.99963 8H0.5C0.223858 8 0 8.22386 0 8.5V9.5C0 9.77614 0.223858 10 0.5 10H1.10002C1.03443 10.3231 1 10.6575 1 11C1 13.7614 3.23858 16 6 16ZM18 14.5C19.933 14.5 21.5 12.933 21.5 11C21.5 9.067 19.933 7.5 18 7.5C16.067 7.5 14.5 9.067 14.5 11C14.5 12.933 16.067 14.5 18 14.5Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 11.75C8 12.4404 7.44036 13 6.75 13C6.05964 13 5.5 12.4404 5.5 11.75C5.5 11.0596 6.05964 10.5 6.75 10.5C7.44036 10.5 8 11.0596 8 11.75ZM18.5 11.75C18.5 12.4404 17.9404 13 17.25 13C16.5596 13 16 12.4404 16 11.75C16 11.0596 16.5596 10.5 17.25 10.5C17.9404 10.5 18.5 11.0596 18.5 11.75ZM10.4951 16.4366C10.893 16.7863 11.4192 17 12 17C12.5808 17 13.107 16.7863 13.5049 16.4366C13.8161 16.1632 14.29 16.1938 14.5634 16.5049C14.8368 16.8161 14.8062 17.29 14.4951 17.5634C13.8315 18.1465 12.9559 18.5 12 18.5C11.0441 18.5 10.1685 18.1465 9.50493 17.5634C9.19378 17.29 9.16319 16.8161 9.43661 16.5049C9.71004 16.1938 10.1839 16.1632 10.4951 16.4366Z"
        fill="var(--cds-color-grey-950)"
      />
      <defs>
        <radialGradient
          id={`paint0_radial_256_1312_${gradientId}`}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.5 5) rotate(135) scale(22.6274 22.6274)"
        >
          <stop stopColor="#AFEFDA" />
          <stop offset="0.333333" stopColor="#92E6E7" />
          <stop offset="0.666667" stopColor="#91D2F3" />
          <stop offset="1" stopColor="#99B9F8" />
        </radialGradient>
        <linearGradient
          id={`paint1_linear_256_1312_${gradientId}`}
          x1="18"
          y1="6"
          x2="18"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFE2FF" />
          <stop offset="0.5" stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id={`paint2_linear_256_1312_${gradientId}`}
          x1="6"
          y1="6"
          x2="6"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFE2FF" />
          <stop offset="0.5" stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );

  const mediumDefault = (
    <svg
      data-testid="coachMediumDefault"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path
        d="M6.37449 24.7503C6.59513 24.4855 6.57609 24.0972 6.34353 23.8427C4.08595 21.3724 2.66699 18.0003 2.66699 14.6666C2.66699 11.1304 4.07175 7.73898 6.57224 5.23849C9.07272 2.73801 12.4641 1.33325 16.0003 1.33325C18.2502 1.33325 20.4636 1.95953 22.4342 3.15377C24.4048 4.34801 26.0687 6.07138 27.2708 8.16339C28.473 10.2554 29.1743 12.648 29.3096 15.1184C29.4448 17.5889 29.0095 20.0568 28.0442 22.2923C27.0789 24.5279 25.6151 26.4584 23.7889 27.9041C21.9628 29.3498 19.8338 30.2637 17.6001 30.5606C17.0677 30.6314 16.5335 30.6666 16.0003 30.6666C12.3222 30.6666 8.26251 29.0544 6.02389 26.795C5.589 26.356 5.61289 25.6647 6.00835 25.1899L6.37449 24.7503Z"
        fill={`url(#paint0_radial_2816_17298_${gradientId})`}
      />
      <circle cx="23.9997" cy="14.6667" r="6.66667" fill={`url(#paint1_linear_2816_17298_${gradientId})`} />
      <circle cx="7.99967" cy="14.6667" r="6.66667" fill={`url(#paint2_linear_2816_17298_${gradientId})`} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99935 19.9998C10.9449 19.9998 13.3327 17.6119 13.3327 14.6664C13.3327 11.7209 10.9449 9.33309 7.99935 9.33309C5.05383 9.33309 2.66602 11.7209 2.66602 14.6664C2.66602 17.6119 5.05383 19.9998 7.99935 19.9998ZM7.99935 21.3331C11.6812 21.3331 14.666 18.3483 14.666 14.6664C14.666 13.9998 14.5682 13.356 14.3861 12.7486C14.864 12.4837 15.4137 12.3331 15.9993 12.3331C16.585 12.3331 17.1347 12.4837 17.6126 12.7486C17.4305 13.356 17.3327 13.9998 17.3327 14.6664C17.3327 18.3483 20.3175 21.3331 23.9993 21.3331C27.6812 21.3331 30.666 18.3483 30.666 14.6664C30.666 13.9696 30.5591 13.2978 30.3608 12.6664H30.666C31.0342 12.6664 31.3327 12.3679 31.3327 11.9998V11.3331C31.3327 10.9649 31.0342 10.6664 30.666 10.6664H29.3332C28.1169 9.04716 26.1804 7.99976 23.9993 7.99976C21.4589 7.99976 19.2504 9.42069 18.1252 11.5111C17.4875 11.1843 16.7647 10.9998 15.9993 10.9998C15.234 10.9998 14.5112 11.1843 13.8735 11.5111C12.7483 9.42069 10.5398 7.99976 7.99935 7.99976C5.81826 7.99976 3.8818 9.04716 2.66553 10.6664H1.33268C0.964492 10.6664 0.666016 10.9649 0.666016 11.3331V11.9998C0.666016 12.3679 0.964493 12.6664 1.33268 12.6664H1.63788C1.43958 13.2978 1.33268 13.9696 1.33268 14.6664C1.33268 18.3483 4.31745 21.3331 7.99935 21.3331ZM23.9993 19.9998C26.9449 19.9998 29.3327 17.6119 29.3327 14.6664C29.3327 11.7209 26.9449 9.33309 23.9993 9.33309C21.0538 9.33309 18.666 11.7209 18.666 14.6664C18.666 17.6119 21.0538 19.9998 23.9993 19.9998Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6673 15.6664C10.6673 16.5869 9.92113 17.3331 9.00065 17.3331C8.08018 17.3331 7.33398 16.5869 7.33398 15.6664C7.33398 14.7459 8.08018 13.9998 9.00065 13.9998C9.92113 13.9998 10.6673 14.7459 10.6673 15.6664ZM24.6673 15.6664C24.6673 16.5869 23.9211 17.3331 23.0007 17.3331C22.0802 17.3331 21.334 16.5869 21.334 15.6664C21.334 14.7459 22.0802 13.9998 23.0007 13.9998C23.9211 13.9998 24.6673 14.7459 24.6673 15.6664ZM13.8667 20.9328C13.6456 20.6384 13.2277 20.579 12.9333 20.8001C12.6389 21.0212 12.5795 21.4391 12.8006 21.7335C13.5292 22.7036 14.6913 23.3332 16.0003 23.3332C17.3093 23.3332 18.4715 22.7036 19.2001 21.7335C19.4212 21.4391 19.3618 21.0212 19.0674 20.8001C18.773 20.579 18.355 20.6384 18.1339 20.9328C17.6463 21.582 16.8722 21.9998 16.0003 21.9998C15.1284 21.9998 14.3543 21.582 13.8667 20.9328Z"
        fill="var(--cds-color-grey-950)"
      />
      <defs>
        <radialGradient
          id={`paint0_radial_2816_17298_${gradientId}`}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23.3337 6.66659) rotate(135) scale(30.1699 30.1699)"
        >
          <stop stopColor="#76EBC8" />
          <stop offset="0.333333" stopColor="#24DBE7" />
          <stop offset="0.666667" stopColor="#66A5FF" />
          <stop offset="1" stopColor="var(--cds-color-blue-600)" />
        </radialGradient>
        <linearGradient
          id={`paint1_linear_2816_17298_${gradientId}`}
          x1="23.9997"
          y1="8"
          x2="23.9997"
          y2="21.3333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFE2FF" />
          <stop offset="0.5" stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id={`paint2_linear_2816_17298_${gradientId}`}
          x1="7.99967"
          y1="8"
          x2="7.99967"
          y2="21.3333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFE2FF" />
          <stop offset="0.5" stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );

  const largeDefault = (
    <svg
      data-testid="coachLargeDefault"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.56124 37.1256C9.8922 36.7283 9.86365 36.1459 9.5148 35.7642C6.12844 32.0587 4 27.0006 4 22C4 16.6957 6.10714 11.6086 9.85786 7.85786C13.6086 4.10714 18.6957 2 24 2C27.3749 2 30.6949 2.93942 33.6508 4.73078C36.6068 6.52214 39.1026 9.10719 40.9058 12.2452C42.709 15.3832 43.761 18.9721 43.9638 22.6778C44.1667 26.3834 43.5137 30.0853 42.0658 33.4386C40.6179 36.792 38.4221 39.6877 35.6829 41.8563C32.9437 44.0248 29.7502 45.3956 26.3997 45.8411C25.6011 45.9472 24.7998 46 24 46C18.4828 46 12.3933 43.5817 9.03534 40.1926C8.38301 39.5342 8.41885 38.4972 9.01203 37.785L9.56124 37.1256Z"
        fill={`url(#paint0_radial_256_1327_${gradientId})`}
      />
      <circle cx="36" cy="22" r="10" fill={`url(#paint1_linear_256_1327_${gradientId})`} />
      <circle cx="12" cy="22" r="10" fill={`url(#paint2_linear_256_1327_${gradientId})`} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 30C16.4183 30 20 26.4183 20 22C20 17.5817 16.4183 14 12 14C7.58172 14 4 17.5817 4 22C4 26.4183 7.58172 30 12 30ZM12 32C17.5228 32 22 27.5228 22 22C22 21 21.8532 20.0344 21.5801 19.1233C22.297 18.7259 23.1216 18.5 24 18.5C24.8784 18.5 25.703 18.7259 26.4199 19.1233C26.1468 20.0344 26 21 26 22C26 27.5228 30.4772 32 36 32C41.5228 32 46 27.5228 46 22C46 20.9548 45.8396 19.947 45.5422 19H46C46.5523 19 47 18.5523 47 18V17C47 16.4477 46.5523 16 46 16H44.0007C42.1763 13.5711 39.2716 12 36 12C32.1894 12 28.8766 14.1314 27.1887 17.2671C26.2323 16.7769 25.148 16.5 24 16.5C22.852 16.5 21.7677 16.7769 20.8113 17.2671C19.1234 14.1314 15.8106 12 12 12C8.72836 12 5.82368 13.5711 3.99927 16H2C1.44772 16 1 16.4477 1 17V18C1 18.5523 1.44772 19 2 19H2.4578C2.16035 19.947 2 20.9548 2 22C2 27.5228 6.47715 32 12 32ZM36 30C40.4183 30 44 26.4183 44 22C44 17.5817 40.4183 14 36 14C31.5817 14 28 17.5817 28 22C28 26.4183 31.5817 30 36 30Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 23.5C16 24.8807 14.8807 26 13.5 26C12.1193 26 11 24.8807 11 23.5C11 22.1193 12.1193 21 13.5 21C14.8807 21 16 22.1193 16 23.5ZM37 23.5C37 24.8807 35.8807 26 34.5 26C33.1193 26 32 24.8807 32 23.5C32 22.1193 33.1193 21 34.5 21C35.8807 21 37 22.1193 37 23.5ZM20.7996 31.3995C20.4679 30.9579 19.8411 30.8687 19.3995 31.2004C18.9579 31.5321 18.8687 32.1589 19.2004 32.6005C20.2933 34.0556 22.0365 35 24 35C25.9635 35 27.7067 34.0556 28.7996 32.6005C29.1313 32.1589 29.0421 31.5321 28.6005 31.2004C28.1589 30.8687 27.5321 30.9579 27.2004 31.3995C26.469 32.3733 25.3078 33 24 33C22.6922 33 21.531 32.3733 20.7996 31.3995Z"
        fill="var(--cds-color-grey-950)"
      />
      <defs>
        <radialGradient
          id={`paint0_radial_256_1327_${gradientId}`}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35 10) rotate(135) scale(45.2548 45.2548)"
        >
          <stop stopColor="#76EBC8" />
          <stop offset="0.333333" stopColor="#24DBE7" />
          <stop offset="0.666667" stopColor="#66A5FF" />
          <stop offset="1" stopColor="var(--cds-color-blue-600)" />
        </radialGradient>
        <linearGradient
          id={`paint1_linear_256_1327_${gradientId}`}
          x1="36"
          y1="12"
          x2="36"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFE2FF" />
          <stop offset="0.5" stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id={`paint2_linear_256_1327_${gradientId}`}
          x1="12"
          y1="12"
          x2="12"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFE2FF" />
          <stop offset="0.5" stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );

  const smallWink = (
    <svg
      data-testid="coachSmallWink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path
        d="M4.78062 18.5628C4.9461 18.3642 4.93183 18.073 4.7574 17.8821C3.06422 16.0294 2 13.5003 2 11C2 8.34784 3.05357 5.8043 4.92893 3.92893C6.8043 2.05357 9.34784 1 12 1C13.6874 1 15.3474 1.46971 16.8254 2.36539C18.3034 3.26107 19.5513 4.55359 20.4529 6.1226C21.3545 7.69161 21.8805 9.48607 21.9819 11.3389C22.0833 13.1917 21.7569 15.0426 21.0329 16.7193C20.309 18.396 19.2111 19.8439 17.8415 20.9281C16.4719 22.0124 14.8751 22.6978 13.1998 22.9205C12.8005 22.9736 12.3999 23 12 23C9.24138 23 6.19664 21.7908 4.51767 20.0963C4.1915 19.7671 4.20942 19.2486 4.50602 18.8925L4.78062 18.5628Z"
        fill={`url(#paint0_radial_3394_12994_${gradientId})`}
      />
      <circle cx="18" cy="11" r="5" fill={`url(#paint1_linear_3394_12994_${gradientId})`} />
      <circle cx="6" cy="11" r="5" fill={`url(#paint2_linear_3394_12994_${gradientId})`} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 14.5C7.933 14.5 9.5 12.933 9.5 11C9.5 9.067 7.933 7.5 6 7.5C4.067 7.5 2.5 9.067 2.5 11C2.5 12.933 4.067 14.5 6 14.5ZM6 16C8.76142 16 11 13.7614 11 11C11 10.5903 10.9507 10.192 10.8577 9.81093C11.1925 9.61316 11.5826 9.5 12 9.5C12.4174 9.5 12.8075 9.61316 13.1423 9.81093C13.0493 10.192 13 10.5903 13 11C13 13.7614 15.2386 16 18 16C20.7614 16 23 13.7614 23 11C23 10.6575 22.9656 10.3231 22.9 10H23.5C23.7761 10 24 9.77614 24 9.5V8.5C24 8.22386 23.7761 8 23.5 8H22.0004C21.0882 6.78555 19.6358 6 18 6C16.1841 6 14.5943 6.96805 13.7185 8.41624C13.2035 8.15036 12.619 8 12 8C11.381 8 10.7965 8.15036 10.2815 8.41624C9.40573 6.96805 7.81592 6 6 6C4.36418 6 2.91184 6.78555 1.99963 8H0.5C0.223858 8 0 8.22386 0 8.5V9.5C0 9.77614 0.223858 10 0.5 10H1.10002C1.03443 10.3231 1 10.6575 1 11C1 13.7614 3.23858 16 6 16ZM18 14.5C19.933 14.5 21.5 12.933 21.5 11C21.5 9.067 19.933 7.5 18 7.5C16.067 7.5 14.5 9.067 14.5 11C14.5 12.933 16.067 14.5 18 14.5Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4208 9.66475C19.6061 10.0352 19.4559 10.4857 19.0854 10.671L18.4271 11.0002L19.0854 11.3293C19.4559 11.5146 19.6061 11.9651 19.4208 12.3356C19.2356 12.7061 18.7851 12.8562 18.4146 12.671L16.4146 11.671C16.1605 11.5439 16 11.2842 16 11.0002C16 10.7161 16.1605 10.4564 16.4146 10.3293L18.4146 9.32934C18.7851 9.1441 19.2356 9.29427 19.4208 9.66475ZM8 11.2502C8 11.9405 7.44036 12.5002 6.75 12.5002C6.05964 12.5002 5.5 11.9405 5.5 11.2502C5.5 10.5598 6.05964 10.0002 6.75 10.0002C7.44036 10.0002 8 10.5598 8 11.2502ZM11.4978 16.8983C11.2205 17.0339 10.9086 17.0655 10.61 16.9737C10.3115 16.8819 10.0711 16.6806 9.91786 16.4126C9.71222 16.053 9.25404 15.9283 8.89447 16.1339C8.53491 16.3395 8.41012 16.7977 8.61575 17.1573C8.94622 17.7351 9.48412 18.1969 10.1693 18.4075C10.8544 18.6181 11.5588 18.5383 12.1568 18.2458C12.5289 18.0639 12.683 17.6147 12.5011 17.2426C12.3191 16.8705 11.8699 16.7164 11.4978 16.8983Z"
        fill="var(--cds-color-grey-950)"
      />
      <defs>
        <radialGradient
          id={`paint0_radial_3394_12994_${gradientId}`}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.5 5) rotate(135) scale(22.6274 22.6274)"
        >
          <stop stopColor="#AFEFDA" />
          <stop offset="0.333333" stopColor="#92E6E7" />
          <stop offset="0.666667" stopColor="#91D2F3" />
          <stop offset="1" stopColor="#99B9F8" />
        </radialGradient>
        <linearGradient
          id={`paint1_linear_3394_12994_${gradientId}`}
          x1="18"
          y1="6"
          x2="18"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFE2FF" />
          <stop offset="0.5" stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id={`paint2_linear_3394_12994_${gradientId}`}
          x1="6"
          y1="6"
          x2="6"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFE2FF" />
          <stop offset="0.5" stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );

  const mediumWink = (
    <svg
      data-testid="coachMediumWink"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.37376 24.7503C6.5944 24.4855 6.57536 24.0972 6.3428 23.8427C4.08522 21.3724 2.66626 18.0003 2.66626 14.6666C2.66626 11.1304 4.07102 7.73898 6.5715 5.23849C9.07199 2.73801 12.4634 1.33325 15.9996 1.33325C18.2495 1.33325 20.4628 1.95953 22.4335 3.15377C24.4041 4.34801 26.068 6.07138 27.2701 8.16339C28.4723 10.2554 29.1736 12.648 29.3088 15.1184C29.444 17.5889 29.0088 20.0568 28.0435 22.2923C27.0782 24.5279 25.6143 26.4584 23.7882 27.9041C21.9621 29.3498 19.833 30.2637 17.5994 30.5606C17.067 30.6314 16.5328 30.6666 15.9996 30.6666C12.3214 30.6666 8.26178 29.0544 6.02315 26.795C5.58826 26.356 5.61216 25.6647 6.00761 25.1899L6.37376 24.7503Z"
        fill={`url(#paint0_radial_3378_18351_${gradientId})`}
      />
      <circle cx="23.9999" cy="14.6667" r="6.66667" fill={`url(#paint1_linear_3378_18351_${gradientId})`} />
      <circle cx="7.99992" cy="14.6667" r="6.66667" fill={`url(#paint2_linear_3378_18351_${gradientId})`} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99959 20C10.9451 20 13.3329 17.6122 13.3329 14.6667C13.3329 11.7211 10.9451 9.33333 7.99959 9.33333C5.05407 9.33333 2.66626 11.7211 2.66626 14.6667C2.66626 17.6122 5.05407 20 7.99959 20ZM7.99959 21.3333C11.6815 21.3333 14.6663 18.3486 14.6663 14.6667C14.6663 14 14.5684 13.3562 14.3863 12.7489C14.8643 12.4839 15.414 12.3333 15.9996 12.3333C16.5852 12.3333 17.1349 12.4839 17.6129 12.7489C17.4308 13.3562 17.3329 14 17.3329 14.6667C17.3329 18.3486 20.3177 21.3333 23.9996 21.3333C27.6815 21.3333 30.6663 18.3486 30.6663 14.6667C30.6663 13.9699 30.5594 13.298 30.3611 12.6667H30.6663C31.0345 12.6667 31.3329 12.3682 31.3329 12V11.3333C31.3329 10.9651 31.0345 10.6667 30.6663 10.6667H29.3334C28.1171 9.0474 26.1807 8 23.9996 8C21.4592 8 19.2507 9.42094 18.1254 11.5114C17.4878 11.1846 16.7649 11 15.9996 11C15.2343 11 14.5114 11.1846 13.8738 11.5114C12.7485 9.42094 10.54 8 7.99959 8C5.8185 8 3.88205 9.0474 2.66577 10.6667H1.33293C0.964737 10.6667 0.66626 10.9651 0.66626 11.3333V12C0.66626 12.3682 0.964737 12.6667 1.33293 12.6667H1.63813C1.43983 13.298 1.33293 13.9699 1.33293 14.6667C1.33293 18.3486 4.31769 21.3333 7.99959 21.3333ZM23.9996 20C26.9451 20 29.3329 17.6122 29.3329 14.6667C29.3329 11.7211 26.9451 9.33333 23.9996 9.33333C21.0541 9.33333 18.6663 11.7211 18.6663 14.6667C18.6663 17.6122 21.0541 20 23.9996 20Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.2634 13.7018C25.428 14.0311 25.2945 14.4316 24.9652 14.5962L22.8245 15.6666L24.9652 16.737C25.2945 16.9017 25.428 17.3021 25.2634 17.6314C25.0987 17.9607 24.6982 18.0942 24.3689 17.9296L21.0356 16.2629C20.8097 16.15 20.6671 15.9191 20.6671 15.6666C20.6671 15.4141 20.8097 15.1833 21.0356 15.0703L24.3689 13.4037C24.6982 13.239 25.0987 13.3725 25.2634 13.7018ZM10.6671 15.6666C10.6671 16.5871 9.92088 17.3333 9.00041 17.3333C8.07993 17.3333 7.33374 16.5871 7.33374 15.6666C7.33374 14.7461 8.07993 13.9999 9.00041 13.9999C9.92088 13.9999 10.6671 14.7461 10.6671 15.6666ZM15.6176 22.7835C15.2156 22.9801 14.7576 23.028 14.3174 22.8927C13.8772 22.7573 13.5252 22.4605 13.303 22.072C13.1202 21.7524 12.713 21.6414 12.3934 21.8242C12.0737 22.007 11.9628 22.4143 12.1456 22.7339C12.5253 23.3979 13.1418 23.9262 13.9256 24.1671C14.7095 24.4081 15.5162 24.3173 16.2034 23.9813C16.5341 23.8196 16.6711 23.4203 16.5094 23.0895C16.3476 22.7588 15.9484 22.6218 15.6176 22.7835Z"
        fill="var(--cds-color-grey-950)"
      />
      <defs>
        <radialGradient
          id={`paint0_radial_3378_18351_${gradientId}`}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23.3329 6.66659) rotate(135) scale(30.1699 30.1699)"
        >
          <stop stopColor="#76EBC8" />
          <stop offset="0.333333" stopColor="#24DBE7" />
          <stop offset="0.666667" stopColor="#66A5FF" />
          <stop offset="1" stopColor="var(--cds-color-blue-600)" />
        </radialGradient>
        <linearGradient
          id={`paint1_linear_3378_18351_${gradientId}`}
          x1="23.9999"
          y1="8"
          x2="23.9999"
          y2="21.3333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFE2FF" />
          <stop offset="0.5" stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id={`paint2_linear_3378_18351_${gradientId}`}
          x1="7.99992"
          y1="8"
          x2="7.99992"
          y2="21.3333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFE2FF" />
          <stop offset="0.5" stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );

  const smallDisabled = (
    <svg
      data-testid="coachSmallDisabled"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path
        d="M4.78062 19.5628C4.9461 19.3642 4.93183 19.073 4.7574 18.8821C3.06422 17.0294 2 14.5003 2 12C2 9.34784 3.05357 6.8043 4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2C13.6874 2 15.3474 2.46971 16.8254 3.36539C18.3034 4.26107 19.5513 5.55359 20.4529 7.1226C21.3545 8.69161 21.8805 10.4861 21.9819 12.3389C22.0833 14.1917 21.7569 16.0426 21.0329 17.7193C20.309 19.396 19.2111 20.8439 17.8415 21.9281C16.4719 23.0124 14.8751 23.6978 13.1998 23.9205C12.8005 23.9736 12.3999 24 12 24C9.24138 24 6.19664 22.7908 4.51767 21.0963C4.1915 20.7671 4.20942 20.2486 4.50602 19.8925L4.78062 19.5628Z"
        fill={`url(#paint0_radial_4283_8573_${gradientId})`}
      />
      <circle cx="18" cy="12" r="5" fill={`url(#paint1_linear_4283_8573_${gradientId})`} />
      <circle cx="6" cy="12" r="5" fill={`url(#paint2_linear_4283_8573_${gradientId})`} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 15.5C7.933 15.5 9.5 13.933 9.5 12C9.5 10.067 7.933 8.5 6 8.5C4.067 8.5 2.5 10.067 2.5 12C2.5 13.933 4.067 15.5 6 15.5ZM6 17C8.76142 17 11 14.7614 11 12C11 11.5903 10.9507 11.192 10.8577 10.8109C11.1925 10.6132 11.5826 10.5 12 10.5C12.4174 10.5 12.8075 10.6132 13.1423 10.8109C13.0493 11.192 13 11.5903 13 12C13 14.7614 15.2386 17 18 17C20.7614 17 23 14.7614 23 12C23 11.6575 22.9656 11.3231 22.9 11H23.5C23.7761 11 24 10.7761 24 10.5V9.5C24 9.22386 23.7761 9 23.5 9H22.0004C21.0882 7.78555 19.6358 7 18 7C16.1841 7 14.5943 7.96805 13.7185 9.41624C13.2035 9.15036 12.619 9 12 9C11.381 9 10.7965 9.15036 10.2815 9.41624C9.40573 7.96805 7.81592 7 6 7C4.36418 7 2.91184 7.78555 1.99963 9H0.5C0.223858 9 0 9.22386 0 9.5V10.5C0 10.7761 0.223858 11 0.5 11H1.10002C1.03443 11.3231 1 11.6575 1 12C1 14.7614 3.23858 17 6 17ZM18 15.5C19.933 15.5 21.5 13.933 21.5 12C21.5 10.067 19.933 8.5 18 8.5C16.067 8.5 14.5 10.067 14.5 12C14.5 13.933 16.067 15.5 18 15.5Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00003 12.5C5.58718 12.5 5.22488 12.3645 4.96171 12.159C4.63528 11.904 4.16395 11.9619 3.90897 12.2884C3.654 12.6148 3.71192 13.0861 4.03835 13.3411C4.57132 13.7574 5.26035 14 6.00003 14C6.73971 14 7.42875 13.7574 7.96171 13.3411C8.28815 13.0861 8.34607 12.6148 8.09109 12.2884C7.83611 11.9619 7.36479 11.904 7.03835 12.159C6.77518 12.3645 6.41289 12.5 6.00003 12.5ZM18 12.5C17.5872 12.5 17.2249 12.3645 16.9617 12.159C16.6353 11.904 16.164 11.9619 15.909 12.2884C15.654 12.6148 15.7119 13.0861 16.0384 13.3411C16.5713 13.7574 17.2604 14 18 14C18.7397 14 19.4287 13.7574 19.9617 13.3411C20.2881 13.0861 20.3461 12.6148 20.0911 12.2884C19.8361 11.9619 19.3648 11.904 19.0384 12.159C18.7752 12.3645 18.4129 12.5 18 12.5ZM10.4951 17.4366C10.893 17.7863 11.4192 18 12 18C12.5808 18 13.107 17.7863 13.505 17.4366C13.8161 17.1632 14.29 17.1938 14.5634 17.5049C14.8368 17.8161 14.8063 18.29 14.4951 18.5634C13.8315 19.1465 12.9559 19.5 12 19.5C11.0441 19.5 10.1685 19.1465 9.50496 18.5634C9.19381 18.29 9.16322 17.8161 9.43665 17.5049C9.71007 17.1938 10.184 17.1632 10.4951 17.4366Z"
        fill="var(--cds-color-grey-950)"
      />
      <defs>
        <radialGradient
          id={`paint0_radial_4283_8573_${gradientId}`}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.5 6) rotate(135) scale(22.6274 22.6274)"
        >
          <stop stopColor="#EAEAEA" />
          <stop offset="0.333333" stopColor="#CECECE" />
          <stop offset="0.666667" stopColor="#B6C7D0" />
          <stop offset="1" stopColor="#95A2BC" />
          <stop stopColor="#EAEAEA" />
          <stop offset="0.333333" stopColor="#CECECE" />
          <stop offset="0.666667" stopColor="#B6C7D0" />
          <stop offset="1" stopColor="#95A2BC" />
        </radialGradient>
        <linearGradient
          id={`paint1_linear_4283_8573_${gradientId}`}
          x1="18"
          y1="7"
          x2="18"
          y2="17"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0E4EA" />
          <stop offset="0.5" stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id={`paint2_linear_4283_8573_${gradientId}`}
          x1="6"
          y1="7"
          x2="6"
          y2="17"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0E4EA" />
          <stop offset="0.5" stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );

  const mediumDisabled = (
    <svg
      data-testid="coachMediumDisabled"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path
        d="M6.37351 24.7503C6.59415 24.4855 6.57512 24.0972 6.34255 23.8427C4.08498 21.3724 2.66602 18.0003 2.66602 14.6666C2.66602 11.1304 4.07077 7.73898 6.57126 5.23849C9.07174 2.73801 12.4631 1.33325 15.9993 1.33325C18.2493 1.33325 20.4626 1.95953 22.4332 3.15377C24.4039 4.34801 26.0677 6.07138 27.2699 8.16339C28.472 10.2554 29.1734 12.648 29.3086 15.1184C29.4438 17.5889 29.0085 20.0568 28.0432 22.2923C27.078 24.5279 25.6141 26.4584 23.788 27.9041C21.9618 29.3498 19.8328 30.2637 17.5991 30.5606C17.0667 30.6314 16.5325 30.6666 15.9993 30.6666C12.3212 30.6666 8.26154 29.0544 6.02291 26.795C5.58802 26.356 5.61191 25.6647 6.00737 25.1899L6.37351 24.7503Z"
        fill={`url(#paint0_radial_3317_25864_${gradientId})`}
      />
      <circle cx="23.9997" cy="14.6667" r="6.66667" fill={`url(#paint1_linear_3317_25864_${gradientId})`} />
      <circle cx="7.99967" cy="14.6667" r="6.66667" fill={`url(#paint2_linear_3317_25864_${gradientId})`} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99935 20C10.9449 20 13.3327 17.6122 13.3327 14.6667C13.3327 11.7211 10.9449 9.33333 7.99935 9.33333C5.05383 9.33333 2.66602 11.7211 2.66602 14.6667C2.66602 17.6122 5.05383 20 7.99935 20ZM7.99935 21.3333C11.6812 21.3333 14.666 18.3486 14.666 14.6667C14.666 14 14.5682 13.3562 14.3861 12.7489C14.864 12.4839 15.4137 12.3333 15.9993 12.3333C16.585 12.3333 17.1347 12.4839 17.6126 12.7489C17.4305 13.3562 17.3327 14 17.3327 14.6667C17.3327 18.3486 20.3175 21.3333 23.9993 21.3333C27.6812 21.3333 30.666 18.3486 30.666 14.6667C30.666 13.9699 30.5591 13.298 30.3608 12.6667H30.666C31.0342 12.6667 31.3327 12.3682 31.3327 12V11.3333C31.3327 10.9651 31.0342 10.6667 30.666 10.6667H29.3332C28.1169 9.0474 26.1804 8 23.9993 8C21.4589 8 19.2504 9.42094 18.1252 11.5114C17.4875 11.1846 16.7647 11 15.9993 11C15.234 11 14.5112 11.1846 13.8735 11.5114C12.7483 9.42094 10.5398 8 7.99935 8C5.81826 8 3.8818 9.0474 2.66553 10.6667H1.33268C0.964492 10.6667 0.666016 10.9651 0.666016 11.3333V12C0.666016 12.3682 0.964493 12.6667 1.33268 12.6667H1.63788C1.43958 13.298 1.33268 13.9699 1.33268 14.6667C1.33268 18.3486 4.31745 21.3333 7.99935 21.3333ZM23.9993 20C26.9449 20 29.3327 17.6122 29.3327 14.6667C29.3327 11.7211 26.9449 9.33333 23.9993 9.33333C21.0538 9.33333 18.666 11.7211 18.666 14.6667C18.666 17.6122 21.0538 20 23.9993 20Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00068 15.6667C7.37758 15.6667 6.82191 15.4622 6.41106 15.1413C6.1209 14.9147 5.70194 14.9662 5.47529 15.2563C5.24865 15.5465 5.30014 15.9654 5.5903 16.1921C6.24096 16.7003 7.08707 17 8.00068 17C8.91429 17 9.7604 16.7003 10.4111 16.1921C10.7012 15.9654 10.7527 15.5465 10.5261 15.2563C10.2994 14.9662 9.88046 14.9147 9.5903 15.1413C9.17945 15.4622 8.62378 15.6667 8.00068 15.6667ZM24.0007 15.6667C23.3776 15.6667 22.8219 15.4622 22.4111 15.1413C22.1209 14.9147 21.7019 14.9662 21.4753 15.2563C21.2486 15.5465 21.3001 15.9654 21.5903 16.1921C22.241 16.7003 23.0871 17 24.0007 17C24.9143 17 25.7604 16.7003 26.4111 16.1921C26.7012 15.9654 26.7527 15.5465 26.5261 15.2563C26.2994 14.9662 25.8805 14.9147 25.5903 15.1413C25.1795 15.4622 24.6238 15.6667 24.0007 15.6667ZM13.8671 20.933C13.646 20.6386 13.2281 20.5792 12.9337 20.8003C12.6393 21.0214 12.5798 21.4393 12.801 21.7337C13.5295 22.7038 14.6917 23.3334 16.0007 23.3334C17.3097 23.3334 18.4718 22.7038 19.2004 21.7337C19.4215 21.4393 19.3621 21.0214 19.0677 20.8003C18.7733 20.5792 18.3554 20.6386 18.1343 20.933C17.6467 21.5822 16.8726 22 16.0007 22C15.1288 22 14.3547 21.5822 13.8671 20.933Z"
        fill="var(--cds-color-grey-950)"
      />
      <defs>
        <radialGradient
          id={`paint0_radial_3317_25864_${gradientId}`}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23.3327 6.66659) rotate(135) scale(30.1699 30.1699)"
        >
          <stop stopColor="#EAEAEA" />
          <stop offset="0.333333" stopColor="#CECECE" />
          <stop offset="0.666667" stopColor="#B6C7D0" />
          <stop offset="1" stopColor="#95A2BC" />
        </radialGradient>
        <linearGradient
          id={`paint1_linear_3317_25864_${gradientId}`}
          x1="23.9997"
          y1="8"
          x2="23.9997"
          y2="21.3333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0E4EA" />
          <stop offset="0.5" stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id={`paint2_linear_3317_25864_${gradientId}`}
          x1="7.99967"
          y1="8"
          x2="7.99967"
          y2="21.3333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0E4EA" />
          <stop offset="0.5" stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );

  switch (state) {
    case 'default':
      if (size === 'small') {
        return smallDefault;
      } else if (size === 'large') {
        return largeDefault;
      } else {
        return mediumDefault;
      }
    case 'wink':
      return size === 'small' ? smallWink : mediumWink;
    case 'disabled':
      return size === 'small' ? smallDisabled : mediumDisabled;
    default:
      return smallDefault;
  }
};

export default CoachIcon;
