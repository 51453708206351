import { SCORE_LEVEL } from 'bundles/skills-common/private/progress-tracker/constants';

export const getScoreLevel = (score: number) => {
  let scoreLevel = SCORE_LEVEL.CONVERSANT;
  let minValue = 1;
  let maxValue = 50;

  if (score > 350) {
    scoreLevel = SCORE_LEVEL.ADVANCED;
    minValue = 351;
    maxValue = 500;
  } else if (score > 150) {
    scoreLevel = SCORE_LEVEL.INTERMEDIATE;
    minValue = 151;
    maxValue = 350;
  } else if (score > 50) {
    scoreLevel = SCORE_LEVEL.BEGINNER;
    minValue = 51;
    maxValue = 150;
  }

  return { scoreLevel, minValue, maxValue };
};
