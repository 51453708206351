import * as React from 'react';

import _t from 'i18n!nls/program-common';

type SvgCatalogProps = React.SVGProps<SVGSVGElement>;

const SvgCatalog: React.FC<SvgCatalogProps> = (props) => (
  <svg width={60} height={62} viewBox="0 0 60 62" role="presentation" {...props}>
    <title id="SvgCatalog-Title">{_t('Curated Catalog')}</title>
    <g fill="none" fillRule="evenodd">
      <path
        d="M32.413 1.614L58.14 18.177a4 4 0 011.81 3.813h.037v19.022h-.107a4 4 0 01-1.74 2.492L31.918 60.386a4 4 0 01-4.33 0L1.858 43.823a3.99 3.99 0 01-1.796-2.81H.012V21.988h.014a4 4 0 011.833-3.494l26.223-16.88a4 4 0 014.33 0z"
        fillOpacity=".1"
        fill="#2A73CC"
      />
      <g opacity=".75" stroke="#1F1F1F" strokeLinejoin="round">
        <path
          strokeWidth="2"
          d="M30.047 23.332L46 19V39.804l-6.165 1.617-9.78 2.565L30 44l-16-4.196V19l16.047 4.332M30 44V23"
        />
        <path d="M17 24l10 3M17 28l10 3M17 32l10 3M17 36l10 3" />
      </g>
    </g>
  </svg>
);

export default SvgCatalog;
