/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import type { Interpolation } from '@emotion/react';

import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import { Tag, Typography2, VisuallyHidden } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

import { TrackedCdsButton } from 'bundles/common/components/withSingleTracked';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import type { PropsForCDSTextField } from 'bundles/ui/components/cds/DisabledTextField';
import { DisabledTextField } from 'bundles/ui/components/cds/DisabledTextField';

import _t from 'i18n!nls/ui';

const styles = {
  copyItem: () => css`
    position: relative;
    display: flex;
    gap: var(--cds-spacing-150);

    label {
      margin: 0;
    }
  `,
  copyItemInputWrapper: css`
    display: inline-block;
    flex-grow: 1;
  `,
  copyItemButtonWrapper: css`
    display: flex;
    align-items: end;
  `,
  copyItemButton: () => css`
    padding: var(--cds-spacing-200);
  `,
  copyText: () => css`
    color: var(--cds-color-interactive-primary);
  `,
  copySuccessMessage: css`
    width: 100%;
  `,
};

export type Props = {
  item: string;
  trackingName?: string;
  e2eDataName?: string;
  onItemClick?: () => void;
  copySuccessMessage?: string;
  textFieldStyles?: Interpolation<Theme>;
  autoFocus?: boolean;
  copyButtonLabel?: string;
} & PropsForCDSTextField;

export const CopyItemWithCdsTextField: React.FC<Props> = ({
  item,
  trackingName = 'copy_item',
  e2eDataName,
  onItemClick,
  copySuccessMessage,
  textFieldStyles,
  autoFocus,
  copyButtonLabel,
  ...textFieldProps
}: Props) => {
  const textInput = useRef<HTMLInputElement>(null);
  const [showCopiedMessage, setShowCopiedMessage] = useState<boolean>(false);
  const timerRef = useRef<number | null>(null);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        window?.clearInterval(timerRef.current);
      }
    };
  });
  const handleClick = () => {
    if (textInput?.current) {
      textInput.current.select();
      navigator.clipboard.writeText(textInput.current.value);
      setShowCopiedMessage(true);
      if (timerRef.current) {
        window?.clearInterval(timerRef.current);
      }
      timerRef.current = window?.setTimeout(() => setShowCopiedMessage(false), 6000);
      // clear text input selection
      const selection = document.getSelection();
      if (selection) {
        selection.empty();
      }

      if (onItemClick) onItemClick();
    }
  };

  return (
    <div>
      {showCopiedMessage && (
        <Tag variant="status" priority="secondary" color="success" css={styles.copySuccessMessage}>
          {copySuccessMessage ?? _t('Copied!')}
        </Tag>
      )}
      <TrackedDiv css={styles.copyItem} trackingName={`${trackingName}_container`}>
        <TrackedDiv css={styles.copyItemInputWrapper} trackingName={`${trackingName}_input`}>
          <DisabledTextField
            inputRef={textInput}
            value={item}
            textFieldStyles={textFieldStyles}
            e2eDataName={e2eDataName}
            hideFromScreenReader={true}
            {...textFieldProps}
          />
        </TrackedDiv>
        <div css={styles.copyItemButtonWrapper}>
          <TrackedCdsButton
            trackingName={`${trackingName}_button`}
            variant="ghost"
            type="button"
            size="small"
            tabIndex={0}
            onClick={handleClick}
            css={styles.copyItemButton}
            autoFocus={autoFocus}
          >
            <Typography2 variant="subtitleMedium" css={styles.copyText} component="span" aria-hidden="true">
              {_t('COPY')}
            </Typography2>
            <VisuallyHidden component="span" className="rc-A11yScreenReaderOnly">
              {copyButtonLabel}
            </VisuallyHidden>
          </TrackedCdsButton>
        </div>
      </TrackedDiv>
    </div>
  );
};
export default CopyItemWithCdsTextField;
