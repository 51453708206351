import { hasHeadingByLevel } from 'bundles/cml/editor/components/buttons/typography/headingUtils';
import type { HeadingLevel } from 'bundles/cml/shared/types/coreTypes';
import type { HeadingElement } from 'bundles/cml/shared/types/elementTypes';
import type { TypographyToolsKeys } from 'bundles/cml/shared/utils/customTools';
import { TypographyTools } from 'bundles/cml/shared/utils/customTools';

type HeadingToolToLevel = {
  tool: TypographyToolsKeys;
  level: HeadingLevel;
  match: (el: HeadingElement) => boolean;
};

export const HEADINGS: HeadingToolToLevel[] = [
  { tool: TypographyTools.HEADING_1, level: '1', match: hasHeadingByLevel('1') },
  { tool: TypographyTools.HEADING_2, level: '2', match: hasHeadingByLevel('2') },
  { tool: TypographyTools.HEADING_3, level: '3', match: hasHeadingByLevel('3') },
  { tool: TypographyTools.HEADING_4, level: '4', match: hasHeadingByLevel('4') },
];
