import * as React from 'react';

type Props = {
  width?: number;
  height?: number;
};

const GoogleFullLogoSVG = ({ width = 120, height = 41 }: Props) => {
  return (
    <svg width={width} height={height} viewBox="0 0 120 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M51.0659 21.0262C51.0659 26.7171 46.6586 30.9107 41.2498 30.9107C35.8409 30.9107 31.4336 26.7171 31.4336 21.0262C31.4336 15.2951 35.8409 11.1416 41.2498 11.1416C46.6586 11.1416 51.0659 15.2951 51.0659 21.0262ZM46.7689 21.0262C46.7689 17.4699 44.2145 15.0366 41.2498 15.0366C38.2851 15.0366 35.7307 17.4699 35.7307 21.0262C35.7307 24.5468 38.2851 27.0157 41.2498 27.0157C44.2145 27.0157 46.7689 24.5424 46.7689 21.0262Z"
        fill="#EA4335"
      />
      <path
        d="M72.2417 21.0262C72.2417 26.7171 67.8344 30.9107 62.4256 30.9107C57.0167 30.9107 52.6094 26.7171 52.6094 21.0262C52.6094 15.2995 57.0167 11.1416 62.4256 11.1416C67.8344 11.1416 72.2417 15.2951 72.2417 21.0262ZM67.9447 21.0262C67.9447 17.4699 65.3903 15.0366 62.4256 15.0366C59.4608 15.0366 56.9064 17.4699 56.9064 21.0262C56.9064 24.5468 59.4608 27.0157 62.4256 27.0157C65.3903 27.0157 67.9447 24.5424 67.9447 21.0262Z"
        fill="#FBBC05"
      />
      <path
        d="M92.5362 11.7388V29.4846C92.5362 36.7844 88.2744 39.7658 83.2362 39.7658C78.4935 39.7658 75.6391 36.5616 74.5626 33.9412L78.3038 32.368C78.97 33.9768 80.6023 35.8753 83.2318 35.8753C86.4568 35.8753 88.4553 33.8654 88.4553 30.0818V28.6602H88.3053C87.3435 29.859 85.4906 30.9063 83.1523 30.9063C78.2597 30.9063 73.7773 26.6013 73.7773 21.0618C73.7773 15.4823 78.2597 11.1416 83.1523 11.1416C85.4862 11.1416 87.3391 12.1889 88.3053 13.352H88.4553V11.7432H92.5362V11.7388ZM88.7597 21.0618C88.7597 17.5813 86.4612 15.0366 83.5362 15.0366C80.5715 15.0366 78.0876 17.5813 78.0876 21.0618C78.0876 24.5067 80.5715 27.0157 83.5362 27.0157C86.4612 27.0157 88.7597 24.5067 88.7597 21.0618Z"
        fill="#4285F4"
      />
      <path d="M99.2654 1.33691V30.3043H95.0742V1.33691H99.2654Z" fill="#34A853" />
      <path
        d="M115.598 24.279L118.934 26.5251C117.857 28.1339 115.263 30.9058 110.781 30.9058C105.222 30.9058 101.07 26.5652 101.07 21.0213C101.07 15.1431 105.257 11.1367 110.3 11.1367C115.378 11.1367 117.861 15.2189 118.673 17.4249L119.119 18.5479L106.038 24.0205C107.039 26.0037 108.597 27.0153 110.781 27.0153C112.969 27.0153 114.486 25.9279 115.598 24.279ZM105.332 20.7227L114.076 17.055C113.595 15.8205 112.148 14.9604 110.445 14.9604C108.261 14.9604 105.222 16.9079 105.332 20.7227Z"
        fill="#EA4335"
      />
      <path
        d="M15.5705 18.455V14.2614H29.5602C29.6969 14.9922 29.7675 15.8568 29.7675 16.7927C29.7675 19.939 28.916 23.8295 26.1719 26.6015C23.5028 29.4091 20.0925 30.9065 15.5749 30.9065C7.20133 30.9065 0.160156 24.0167 0.160156 15.5582C0.160156 7.09974 7.20133 0.209961 15.5749 0.209961C20.2072 0.209961 23.5072 2.04605 25.9866 4.4392L23.0572 7.39833C21.2793 5.71377 18.8705 4.40355 15.5705 4.40355C9.45574 4.40355 4.67339 9.38148 4.67339 15.5582C4.67339 21.735 9.45574 26.7129 15.5705 26.7129C19.5366 26.7129 21.7955 25.1041 23.2425 23.6424C24.416 22.4569 25.1881 20.7634 25.4925 18.4505L15.5705 18.455Z"
        fill="#4285F4"
      />
    </svg>
  );
};

export default GoogleFullLogoSVG;
