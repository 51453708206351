/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Grid, Typography2 } from '@coursera/cds-core';
import {
  BarChartOneFillIcon,
  BarChartThreeFillIcon,
  BarChartTwoFillIcon,
  CertificateIcon,
  ClockOutlineIcon,
  CloudDownloadIcon,
  CommentDotsIcon,
  LaptopIcon,
  VideoIcon,
} from '@coursera/cds-icons';

import { TooltipWrapper } from 'bundles/common/components/Tooltip';

const styles = {
  iconTextWrapper: css`
    margin-bottom: 12px;
    padding-left: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  `,
  iconGraphic: css`
    height: 24px;
  `,
  textWrapper: css`
    width: calc(100% - 36px);
  `,
  overlayTrigger: css`
    text-decoration: underline;
  `,
  iconText: css`
    display: inline-block;
    margin-left: 12px;

    &::before {
      content: ' ';
    }
  `,
};

const ALL_ICONS = {
  ClockOutlineIcon,
  CloudDownloadIcon,
  VideoIcon,
  LaptopIcon,
  CommentDotsIcon,
  BarChartOneFillIcon,
  BarChartTwoFillIcon,
  BarChartThreeFillIcon,
  CertificateIcon,
} as const;

export type LogoIconType = keyof typeof ALL_ICONS;

type Props = {
  logo: LogoIconType;
  value: string;
  hoverText?: React.ReactNode;
};

const getIcon = (logo: LogoIconType) => {
  const IconTag = ALL_ICONS[logo];
  return <IconTag size="large" color="default" />;
};

const BannerInfoLogoText: React.FunctionComponent<Props> = ({ logo, value, hoverText }) => {
  const icon = getIcon(logo);
  const row = (
    <TooltipWrapper placement="top" message={hoverText}>
      <Typography2 css={[styles.iconText, styles.overlayTrigger]} component="span" variant="subtitleMedium">
        {value}
      </Typography2>
    </TooltipWrapper>
  );

  return (
    <Grid container alignItems="flex-start" css={styles.iconTextWrapper}>
      <Grid item css={styles.iconGraphic}>
        {icon}
      </Grid>
      <Grid item css={styles.textWrapper}>
        {row}
      </Grid>
    </Grid>
  );
};

export default BannerInfoLogoText;
