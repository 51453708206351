import styled from '@emotion/styled';

import { fontFamily } from '@coursera/coursera-ui';

export const Clickable = styled.button({
  fontFamily: fontFamily.body,
  fontSize: '14px',
  background: 'none',
  border: 'none',
  padding: 0,
  textDecoration: 'none',
  textAlign: 'left',
  outlineOffset: '9px',
  cursor: 'pointer',
});
export const Labeled = styled.label({
  margin: 0,
  cursor: 'pointer',
  color: 'inherit',
  fontWeight: 'normal',
  display: 'block',
});
