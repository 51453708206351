import type { BaseComponentProps } from '@coursera/cds-common';

import { classes } from '@core/cards/common/getCommonCardCss';

type Props = {
  /**
   * The custom category to render, such as "Coursera Plus" or "Enterprise"
   */
  children?: React.ReactNode;
} & BaseComponentProps<'div'>;

/**
 * Placeholder to render the category such as "Coursera Plus" or "Enterprise"
 */
const CardCategory = ({ children, ...rest }: Props) => {
  return (
    <div className={classes.category} {...rest}>
      {children}
    </div>
  );
};

export default CardCategory;
