import * as React from 'react';
import { useContext, useRef, useState } from 'react';

import { ReactEditor, useSlate, useSlateStatic } from 'slate-react';

import { CodeIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import CodeMenu from 'bundles/cml/editor/components/buttons/code/CodeMenu';
import { FocusContext } from 'bundles/cml/editor/context/focusContext';
import { isTypeDisabled } from 'bundles/cml/editor/utils/elementUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';

import _t from 'i18n!nls/cml';

export type Props = {
  menuDirection?: 'top' | 'bottom';
};

const CodeButton: React.FC<Props> = (props: Props) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const staticEditor = useSlateStatic();
  const [open, setOpen] = useState(false);
  const { setFocused } = useContext(FocusContext);
  const editor = useSlate();

  const { menuDirection } = props;
  const disabled = isTypeDisabled(editor, BLOCK_TYPES.CODE);

  const handleOpen = (menuOpen: boolean) => {
    if (menuOpen) {
      setFocused(true);
      setOpen(true);
    } else {
      setOpen(false);
      ReactEditor.focus(staticEditor);
      setFocused(false);
    }
  };

  return (
    <div ref={rootRef}>
      <Button
        ref={buttonRef}
        onClick={() => handleOpen(!open)}
        disabled={disabled}
        active={open}
        title={_t('Code block')}
        className="rc-CodeButton"
        type={TOOLBAR_BUTTON_TYPES.menu}
      >
        <CodeIcon size="small" />
      </Button>
      <CodeMenu
        anchorEl={rootRef?.current || undefined}
        open={open}
        handleClose={() => handleOpen(false)}
        menuDirection={menuDirection}
      />
    </div>
  );
};

export default CodeButton;
