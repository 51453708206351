import { classes } from '@core/cards/common/getCommonCardCss';
import Typography from '@core/Typography2';

type Props = {
  /**
   * Date string to use. Note that the date needs to be pre-formatted and only
   * CDS typography is applied within this component.
   */
  children: NonNullable<React.ReactNode>;
};

/**
 * Common component to render a date string.
 *
 * @example <CardEnrollmentDate>{"Sept 21 - Dec 21, 2022"}</CardEnrollmentDate>
 */
const CardEnrollmentDate = ({ children }: Props) => {
  return (
    <Typography
      className={classes.enrollmentDate}
      component="h4"
      variant="subtitleMedium"
    >
      {children}
    </Typography>
  );
};

export default CardEnrollmentDate;
