import { useEffect } from 'react';

import { useQuery } from '@apollo/client';

import { useLocation, useNavigate } from 'js/lib/useRouter';

import { thirdPartyOrganizationsQuery } from 'bundles/program-home/queries/ThirdPartyOrganizationsQuery';
import type {
  ThirdPartyOrganizationsQuery,
  ThirdPartyOrganizationsQueryVariables,
} from 'bundles/program-home/queries/__generated__/ThirdPartyOrganizationsQuery';

type Props = {
  userId?: number;
  thirdPartyOrgSlug: string;
};

export const useSsoAuthProviderParams = ({ userId, thirdPartyOrgSlug }: Props) => {
  const { data } = useQuery<ThirdPartyOrganizationsQuery, ThirdPartyOrganizationsQueryVariables>(
    thirdPartyOrganizationsQuery,
    { variables: { orgSlug: thirdPartyOrgSlug }, errorPolicy: 'all', skip: !!userId }
  );
  const navigate = useNavigate();
  const { query, pathname } = useLocation();
  const loginMethod = data?.ThirdPartyOrganizationsV1Resource?.bySlug?.elements?.[0]?.loginMethod;

  useEffect(
    () => {
      if (loginMethod === 'SAML') {
        navigate({
          pathname,
          search: '?' + new URLSearchParams({ ...query, ...{ authProvider: thirdPartyOrgSlug } }).toString(),
        });
      }
    }, // FIXME: existing react-hooks/exhaustive-deps violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loginMethod, navigate, pathname, thirdPartyOrgSlug]
  );
};
