/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';
import { useState } from 'react';

import type { EditorProps as Props } from 'bundles/cml/shared/components/widget/dialog/WidgetConfigDialog';
import { ExternalUrlConfigTextField } from 'bundles/widget/components/config/ExternalUrlEditor';
import { YoutubeConfigTextFields } from 'bundles/widget/components/config/YoutubeEditor';

const YoutubeConfigEditor: React.FC<Props> = ({ config, onConfigChange, setSaveEnabled, readonly }) => {
  const [urlError, setUrlError] = useState(false);
  const [startError, setStartError] = useState(false);

  const onUrlChange = (value: string, isValid: boolean) => {
    if (!isValid) {
      setUrlError(true);
      setSaveEnabled(false);
      return;
    }
    setUrlError(false);

    setSaveEnabled(!startError);
  };

  const onStartChange = (value: string, isValid: boolean) => {
    if (!isValid) {
      setStartError(true);
      setSaveEnabled(false);
      return;
    }
    setStartError(false);

    setSaveEnabled(!urlError);
  };

  return (
    <YoutubeConfigTextFields
      config={config}
      onUrlChange={onUrlChange}
      onStartChange={onStartChange}
      onConfigChange={onConfigChange}
      urlError={urlError}
      startError={startError}
      readOnly={readonly}
    />
  );
};

const ExternalUrlConfigEditor: React.FC<Props> = ({ config, onConfigChange, setSaveEnabled }) => {
  const [urlError, setUrlError] = useState(false);
  const handleUrlChange = (value: string, isValid: boolean) => {
    if (!isValid) {
      setUrlError(true);
      setSaveEnabled(false);
      return;
    }
    setUrlError(false);

    setSaveEnabled(true);
  };

  return (
    <ExternalUrlConfigTextField
      config={config}
      handleChange={handleUrlChange}
      urlError={urlError}
      onConfigChange={onConfigChange}
    />
  );
};

const SimpleConfigEditor: React.FC<Props> = (props) => {
  const ConfigEditor = props.isExternalUrl ? ExternalUrlConfigEditor : YoutubeConfigEditor;

  return <ConfigEditor {...props} />;
};

export default SimpleConfigEditor;
