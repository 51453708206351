import gql from 'graphql-tag';

export const thirdPartyOrganizationsQuery = gql`
  query ThirdPartyOrganizationsQuery($orgSlug: String!) {
    ThirdPartyOrganizationsV1Resource {
      bySlug(slug: $orgSlug) {
        elements {
          id
          slug
          name
          loginMethod
        }
      }
    }
  }
`;
