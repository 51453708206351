import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

export type EnterpriseBadgeUnenrollInput = {
  input: { user_id: number; badge_template_id: string; request_origin: string };
};

const EnterpriseBadgeUnenrollMutation = gql`
  mutation EnterpriseBadgeUnenrollMutation($input: EnterpriseBadgeUnenrollInput!) {
    UnenrollBadgeResponse(input: $input)
      @rest(
        type: "UnenrollBadgeResponse"
        path: "grpc/badge/badgeuserstates/v1/BadgeUserStatesAPI/UnenrollBadge"
        method: "POST"
      ) {
      response
    }
  }
`;

const useEnterpriseBadgeUnenrollMutation = function () {
  const [enterpriseBadgeUnenroll] = useMutation<{}, EnterpriseBadgeUnenrollInput>(EnterpriseBadgeUnenrollMutation);
  return ({
    user_id,
    badge_template_id,
    request_origin = 'REQUEST_ORIGIN_USER',
  }: EnterpriseBadgeUnenrollInput['input']) => {
    return enterpriseBadgeUnenroll({ variables: { input: { user_id, badge_template_id, request_origin } } });
  };
};

export default useEnterpriseBadgeUnenrollMutation;
