import getDegreesConversionCopy from 'bundles/stackability-common/copy/degreesConversionCopy';
import getDegreesDiscoveryCopy from 'bundles/stackability-common/copy/degreesDiscoveryCopy';
import getDegreesPathwaysCopy from 'bundles/stackability-common/copy/degreesPathwaysCopy';
import getHomepagesNavXDPCopy from 'bundles/stackability-common/copy/homepagesNavXDPCopy';
import getLeeCoreLexCopy from 'bundles/stackability-common/copy/leeCoreLexCopy';

/**
 * Use this to get upsell-related copy that is displayed in the UI.
 * See `README.md` in the `stackability-common` bundle for more information.
 * @returns An object containing upsell-related copy, grouped by team.
 */
export const getUpsellCopy = () => ({
  degreesConversion: getDegreesConversionCopy(),
  degreesDiscovery: getDegreesDiscoveryCopy(),
  degreesPathways: getDegreesPathwaysCopy(),
  homepagesNavXdp: getHomepagesNavXDPCopy(),
  leeCoreLex: getLeeCoreLexCopy(),
});
