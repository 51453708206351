import * as React from 'react';
import { graphql } from 'react-apollo';
import { Link } from 'react-router';

import PropTypes from 'prop-types';
import { compose, getContext, setDisplayName, withHandlers, withProps, wrapDisplayName } from 'recompose';

import type { InjectedRouter } from 'js/lib/connectToRouter';
import connectToRouter from 'js/lib/connectToRouter';
import Retracked from 'js/lib/retracked';
import type { EventData, TrackingData, WithTrackingData } from 'js/lib/retracked';

import { Grid, PageGridContainer } from '@coursera/cds-core';
import { ChevronDownIcon } from '@coursera/cds-icons';
import { Button, DropDown, css, placeholder } from '@coursera/coursera-ui';

import PopularTopicsPerDomain from 'bundles/browse/components/PageHeader/PopularTopicsPerDomain';
import { TrackedSvgButton } from 'bundles/common/components/TrackedCui';
import filterExistsOrDefault from 'bundles/program-common/utils/filterExistsOrDefault';
import { Scroller } from 'bundles/program-home/components/multiprogram/Scroller';
import { domainRenameForSkills, domainSkillMapping } from 'bundles/program-home/constants/DomainSkillMapping';
import { getEnableDomainReordering } from 'bundles/program-home/epicFeatureFlags';
import { ProgramCurriculumDomainsQuery } from 'bundles/program-home/utils/ProgramHomeGraphqlQueries';
import type {
  ProgramCurriculumDomainsQuery as ProgramCurriculumDomainsQueryData,
  ProgramCurriculumDomainsQueryVariables,
} from 'bundles/program-home/utils/__generated__/ProgramCurriculumDomainsQuery';

import _t from 'i18n!nls/program-home';

import 'css!./__styles__/HorizontalDomainsMenu';

// ---

type Domain = {
  id: string;
  slug: string | null;
  name: string;
  subdomainIds: string[];
};

type Subdomain = {
  id: string;
  name: string;
  domainId: string;
  slug: string | null;
};

type DomainWithSubdomain = Domain & {
  subdomains: {
    elements: Subdomain[];
  };
};

// ---

function createDomainHref(baseUrl: string, domain: Domain): string {
  return `${baseUrl}/${domain.slug ?? domain.id}`;
}

function createAltDomainHref(baseUrl: string, domain: Domain): string {
  const domainSlug = domain.slug ?? domain.id;
  return `${baseUrl}/${domainSlug}/all-${domainSlug}`;
}

function createSubdomainHref(baseUrl: string, domain: Domain, subdomain: Subdomain): string {
  return `${baseUrl}/${domain.slug ?? domain.id}/${subdomain.slug ?? subdomain.id}`;
}

function createTopicHref(baseUrl: string, skillId?: string, enableSkillsInSearchAndBrowse?: boolean): string {
  if (enableSkillsInSearchAndBrowse && skillId) {
    return `${baseUrl}/skills/${skillId}`;
  }
  // query is already encoded in the static data
  return `${baseUrl}/browse`;
}

function preventDefault(event: React.MouseEvent<Link, MouseEvent>) {
  event?.preventDefault();
}

// ---

type PropsForDomainListItem = {
  domain: DomainWithSubdomain;
  baseUrl: string;
  enableSkillsInSearchAndBrowse?: boolean;
  createHandleItemClick: Handlers['createHandleItemClick'];
};

export const DomainListItem = ({
  domain,
  baseUrl,
  enableSkillsInSearchAndBrowse = false,
  createHandleItemClick,
}: PropsForDomainListItem): JSX.Element => {
  const getSkillHrefForDomain = (id: string) => {
    const skillId = domainSkillMapping[id];
    if (enableSkillsInSearchAndBrowse && skillId) {
      return `${baseUrl}/skills/${skillId}`;
    }
    return '';
  };
  const topics = PopularTopicsPerDomain[domain.id];
  const hasTopics = topics?.length > 0;
  const generatedDomainHref = (hasTopics ? createAltDomainHref : createDomainHref)(baseUrl, domain);
  const domainHref = getSkillHrefForDomain(domain.id) || generatedDomainHref;
  const shouldRenderDropDown = hasTopics || domain.subdomains.elements.length > 1;

  if (!shouldRenderDropDown) {
    // a11yUtils from coursera-ui calls event.preventDefault(), meaning we can't use <Link />, so we again must hack
    // around bad assumptions in that component library.
    const onClickWithPreventDefault = (event: React.SyntheticEvent) => {
      event?.preventDefault();
      createHandleItemClick({
        href: domainHref,
        trackingName: 'horizontal_domains_menu_domain_button',
        trackingData: { domain: { id: domain.id, name: domain.name } },
      })();
    };
    return (
      <li className="HorizontalDomainsMenu-DomainListItem">
        <Button
          rootClassName="HorizontalDomainsMenu-DomainListItem-Link"
          label={domain.name}
          tag="a"
          type="noStyle"
          size="zero"
          htmlAttributes={{ href: domainHref, 'data-e2e': domain.name }}
          onClick={onClickWithPreventDefault}
        />
      </li>
    );
  }

  return (
    <li className="HorizontalDomainsMenu-DomainListItem">
      <DropDown.ButtonMenuV2
        renderButton={({ getDropDownButtonProps }) => {
          const {
            onClick,
            onKeyDown,
            ref,
            'aria-haspopup': ariaHasPopup,
            'aria-expanded': ariaExpanded,
            ...buttonProps
          } = getDropDownButtonProps();
          return (
            <TrackedSvgButton
              rootClassName="HorizontalDomainsMenu-DomainListItem-DropDownButton"
              label={domain.name}
              size="zero"
              type="noStyle"
              svgElement={<ChevronDownIcon size="small" />}
              isChildrenOnRight
              trackingName="horizontal_domains_menu_domain_dropdown_button"
              trackingData={{ domain: { id: domain.id, name: domain.name } }}
              _refAlt={ref}
              onClick={onClick}
              htmlAttributes={{
                onKeyDown,
                'aria-haspopup': ariaHasPopup,
                'aria-expanded': ariaExpanded,
                'data-e2e': domain.name,
              }}
              {...buttonProps}
            />
          );
        }}
        maxWidth={320}
        usePopper
        usePopperPositionFixed
      >
        <DropDown.Item
          rootClassName="HorizontalDomainsMenu-DomainListItem-DropDownItem HorizontalDomainsMenu-DomainListItem-ViewAll"
          onClick={createHandleItemClick({
            href: domainHref,
            trackingName: 'horizontal_domains_menu_domain_menu_item',
            trackingData: { domain: { id: domain.id, name: domain.name } },
          })}
          htmlAttributes={{ 'data-e2e': 'View all' }}
        >
          <Link to={domainHref} onClick={preventDefault}>
            {_t('View all #{categoryName}', { categoryName: domain.name })}
          </Link>
        </DropDown.Item>
        {hasTopics
          ? topics.map((topic) => {
              const topicHref = createTopicHref(baseUrl, topic.skillId, enableSkillsInSearchAndBrowse);
              const showTopicSkillName = enableSkillsInSearchAndBrowse && topic.skillName;
              const shouldAddQueryParam = !topic.skillId || !enableSkillsInSearchAndBrowse;
              return (
                <DropDown.Item
                  key={topic.name}
                  rootClassName="HorizontalDomainsMenu-DomainListItem-DropDownItem"
                  onClick={createHandleItemClick({
                    href: topicHref,
                    trackingName: 'horizontal_domains_menu_topic_menu_item',
                    trackingData: {
                      domain: { id: domain.id, name: domain.name },
                      topic: { query: shouldAddQueryParam ? topic.query : '', name: topic.name },
                    },
                    // Only add the query param if we are not going to a skill page
                    ...(shouldAddQueryParam ? { topicQuery: { query: topic.query } } : {}),
                  })}
                  htmlAttributes={{ 'data-e2e': topic.name }}
                >
                  <Link to={topicHref} onClick={preventDefault}>
                    {showTopicSkillName ? topic.skillName : topic.name}
                  </Link>
                </DropDown.Item>
              );
            })
          : domain.subdomains.elements.map((subdomain) => {
              const subdomainHref =
                getSkillHrefForDomain(subdomain.id) || createSubdomainHref(baseUrl, domain, subdomain);
              const shouldRename = enableSkillsInSearchAndBrowse && domainRenameForSkills[subdomain.id];
              return (
                <DropDown.Item
                  key={subdomain.id}
                  rootClassName="HorizontalDomainsMenu-DomainListItem-DropDownItem"
                  onClick={createHandleItemClick({
                    href: subdomainHref,
                    trackingName: 'horizontal_domains_menu_subdomain_menu_item',
                    trackingData: {
                      domain: { id: domain.id, name: domain.name },
                      subdomain: { id: subdomain.id, name: subdomain.name },
                    },
                  })}
                  htmlAttributes={{ 'data-e2e': subdomain.name }}
                >
                  <Link to={subdomainHref} onClick={preventDefault}>
                    {shouldRename ? domainRenameForSkills[subdomain.id] : subdomain.name}
                  </Link>
                </DropDown.Item>
              );
            })}
      </DropDown.ButtonMenuV2>
    </li>
  );
};

DomainListItem.Placeholder = ({ length }: { length: number }): JSX.Element => (
  <li className="HorizontalDomainsMenu-DomainListItem">
    <span
      className={css(placeholder.styles.shimmer, 'HorizontalDomainsMenu-DomainListItem-Skeleton').className}
      role="presentation"
      style={{ width: length }}
    >
      &nbsp;
    </span>
  </li>
);

// ---

type PropsForDomainsList = {
  domains: DomainWithSubdomain[];
  baseUrl: string;
  enableSkillsInSearchAndBrowse?: boolean;
  'aria-labelledBy': string;
  createHandleItemClick: Handlers['createHandleItemClick'];
};

export const DomainsList = ({
  domains,
  baseUrl,
  enableSkillsInSearchAndBrowse = false,
  'aria-labelledBy': ariaLabeledBy,
  createHandleItemClick,
}: PropsForDomainsList): JSX.Element => (
  <ol className="HorizontalDomainsMenu-DomainsList" aria-labelledby={ariaLabeledBy}>
    {domains.map((domain) => (
      <DomainListItem
        key={domain.id}
        domain={domain}
        baseUrl={baseUrl}
        enableSkillsInSearchAndBrowse={enableSkillsInSearchAndBrowse}
        createHandleItemClick={createHandleItemClick}
      />
    ))}
  </ol>
);

// Simple Lehmer LC-RNG with m=127 and a=65 (fully periodic).
// Might want to use fixed widths in final implementation.
function genLengths<T>(count: number, func: (number: number, index: number) => T): T[] {
  const array: T[] = [];
  for (let i = 0, x = 1; i < count; i += 1) {
    x = (65 * x) % 127;
    array.push(func(x, i));
  }
  return array;
}

DomainsList.Placeholder = ({ count }: { count: number }) => (
  <ol className="HorizontalDomainsMenu-DomainsList">
    {genLengths(count, (length, index) => (
      <DomainListItem.Placeholder key={index} length={80 + length} />
    ))}
  </ol>
);

// ---

type PropsFromCaller = {
  programId: string;
  thirdPartyOrganizationId: string;
  enableSkillsInSearchAndBrowse: boolean;
  onSelect?: (href: string, topicQuery?: { query: string }) => void;
};

type PropsFromGraphql = {
  error: boolean;
  loading: boolean;
  domains: DomainWithSubdomain[];
};

type PropsFromRouter = {
  router: InjectedRouter;
  baseUrl: string;
};

type PropsFromGetContext = {
  _eventData: EventData;
  _withTrackingData: WithTrackingData;
};

type Handlers = {
  createHandleItemClick: (arg: {
    href: string;
    trackingName: string;
    trackingData: TrackingData;
    topicQuery?: { query: string };
  }) => () => void;
};

type Props = PropsFromCaller & PropsFromRouter & Handlers & PropsFromGraphql;

// this is a list of domains ordered by demand in ascending order to be used for AB testing
export const domainOrdering = [
  'computer-science',
  'data-science',
  'business',
  'information-technology',
  'arts-and-humanities',
  'language-learning',
  'personal-development',
  'life-sciences',
  'math-and-logic',
  'physical-science-and-engineering',
  'social-sciences',
];

export const HorizontalDomainsMenu = ({
  error,
  loading,
  baseUrl,
  domains,
  enableSkillsInSearchAndBrowse,
  createHandleItemClick,
}: Props): JSX.Element | null => {
  if (error) {
    return null;
  }

  if (loading) {
    return <HorizontalDomainsMenu.Placeholder />;
  }

  if (domains.length <= 0) {
    return null;
  }

  const shouldEnableDomainReordering: boolean = getEnableDomainReordering();

  const domainMap = new Map<string, DomainWithSubdomain>();
  domains.forEach((domain) => {
    domainMap.set(domain.id, domain);
  });

  let reorderedDomains: DomainWithSubdomain[] = domains;

  if (shouldEnableDomainReordering) {
    reorderedDomains = [];
    domainOrdering.forEach((id) => {
      if (domainMap.has(id)) {
        // Casting is needed here: our version of typescript cannot dive into a Map and determine its type
        const domainWithId: DomainWithSubdomain = domainMap.get(id) as DomainWithSubdomain;
        reorderedDomains.push(domainWithId);
      }
    });
  }

  return (
    <PageGridContainer className="HorizontalDomainsMenu-Container">
      <Grid item xs={12}>
        <Scroller className="HorizontalDomainsMenu-Scroller" trackingName="horizontal_domains_menu">
          <nav className="HorizontalDomainsMenu">
            <span className="HorizontalDomainsMenu-Label" id="HorizontalDomainsMenu-Label">
              {_t('Categories:')}
            </span>
            <DomainsList
              domains={reorderedDomains}
              baseUrl={baseUrl}
              enableSkillsInSearchAndBrowse={enableSkillsInSearchAndBrowse}
              createHandleItemClick={createHandleItemClick}
              aria-labelledBy="HorizontalDomainsMenu-Label"
            />
          </nav>
        </Scroller>
      </Grid>
    </PageGridContainer>
  );
};

HorizontalDomainsMenu.Placeholder = (): JSX.Element => (
  <PageGridContainer className="HorizontalDomainsMenu-Container">
    <Grid item xs={12}>
      <div className="Scroller HorizontalDomainsMenu-Scroller">
        <nav className="HorizontalDomainsMenu" aria-busy>
          <span className="HorizontalDomainsMenu-Label">{_t('Categories:')}</span>
          <DomainsList.Placeholder count={8} />
        </nav>
      </div>
    </Grid>
  </PageGridContainer>
);

const withTrackingContext = getContext({
  _eventData: PropTypes.object,
  _withTrackingData: PropTypes.func,
});

const withClickHandler = withHandlers<PropsFromRouter & PropsFromGetContext & PropsFromCaller, Handlers>({
  createHandleItemClick:
    ({ router, _eventData, _withTrackingData, onSelect }) =>
    ({ href, trackingName, trackingData, topicQuery }) =>
    () => {
      // Can't easily cancel this when the link itself is clicked while maintaining bubbling for the Dropdown, so do
      // we track and navigate imperatively instead.
      Retracked.trackComponent(_eventData, { href, ...trackingData }, trackingName, 'click', _withTrackingData);

      if (onSelect) {
        onSelect(href, topicQuery);
      } else {
        router.push({
          pathname: href,
          query: {
            ...topicQuery,
            source: 'browse',
          },
        });
      }
    },
  // TODO(ppaskaris): Hook into DropDown.ButtonMenu for onOpen onClose events, use that to override the nonsense
  // overflow style on body causing the layout to shift.
});

const injectSubdomainsToDomains = (domains: Domain[], subdomains: Subdomain[]): DomainWithSubdomain[] =>
  domains.map((domain) => ({
    ...domain,
    subdomains: {
      elements: subdomains.filter((subdomain) => domain.subdomainIds.includes(subdomain.id)),
    },
  }));

export const enhancer = compose<Props, PropsFromCaller>(
  setDisplayName(wrapDisplayName(HorizontalDomainsMenu, 'enhancer')),
  connectToRouter((router) => ({ router, baseUrl: `/programs/${router.params.programSlug}` })),
  withTrackingContext,
  withClickHandler,
  graphql<PropsFromCaller, ProgramCurriculumDomainsQueryData, ProgramCurriculumDomainsQueryVariables, PropsFromGraphql>(
    ProgramCurriculumDomainsQuery,
    {
      options: ({ programId }) => ({
        variables: { programId },
        ssr: false,
      }),
      props: ({ data }) => {
        const domains = filterExistsOrDefault(data?.ProgramCurriculumDomainsV1Resource?.get?.domains);
        const subdomains = filterExistsOrDefault(data?.SubdomainsV1Resource?.getAll?.elements);
        return {
          error: Boolean(data?.error),
          loading: data?.loading ?? true,
          domains: injectSubdomainsToDomains(domains, subdomains),
        };
      },
    }
  )
);

export default enhancer(HorizontalDomainsMenu);

// ---

type PropsFromOrgHome = {
  domains: Domain[];
  subdomains: Subdomain[];
};

type PropsFromOrgHomeWithProps = {
  loading: boolean;
  error: boolean;
  domains: DomainWithSubdomain[];
};

type PropsForOrgHome = PropsFromCaller & PropsFromRouter & Handlers & PropsFromOrgHomeWithProps;

export const enhanceForOrgHome = compose<PropsForOrgHome, PropsFromOrgHome>(
  setDisplayName(wrapDisplayName(HorizontalDomainsMenu, 'enhancer')),
  connectToRouter((router) => ({ router, baseUrl: `/programs/all/${router.params.orgSlug}` })),
  withTrackingContext,
  withClickHandler,
  withProps<PropsFromOrgHomeWithProps, PropsFromOrgHome>(({ domains, subdomains }) => ({
    loading: false,
    error: false,
    domains: injectSubdomainsToDomains(domains, subdomains),
  }))
);

export const EnterpriseHomeHorizontalDomainsMenu = enhanceForOrgHome(HorizontalDomainsMenu);
