/* eslint-disable camelcase */
import config from 'js/app/config';

export const c4blink = `${config.url.base}business`;

type UtmContent = 'c4b-xdp-m1' | 'c4b-xdp-m2' | 'c4b-xdp-m3';
type UtmTerm = 'in' | 'out';

type UtmParams = {
  utm_medium: string;
  utm_source: string;
  utm_campaign: string;
  utm_content: UtmContent;
  utm_term: UtmTerm;
};

export function getUtmParams(content: UtmContent, isLoggedIn: boolean): UtmParams {
  return {
    utm_medium: 'coursera',
    utm_source: 'xdp',
    utm_campaign: 'website',
    utm_content: content,
    utm_term: isLoggedIn ? 'in' : 'out',
  };
}

export function getLinkTarget(content: UtmContent, isLoggedIn: boolean) {
  const params = getUtmParams(content, isLoggedIn);
  const qs = Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k as keyof UtmParams])}`)
    .join('&');

  return `${c4blink}?${qs}`;
}
