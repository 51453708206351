import Uri from 'jsuri';
import Q from 'q';

import user from 'js/lib/user';

import courseraPlusExperiments from 'bundles/epic/clients/courseraPlus';
import growthFalconsExperiments from 'bundles/epic/clients/growthFalcons';
import paymentsExperiments from 'bundles/epic/clients/payments';
import paymentsbackendExperiments from 'bundles/epic/clients/payments-backend';
import ProductTypeObject from 'bundles/payments/common/ProductType';
import type { BillingInfo } from 'bundles/payments/common/types';
import CourseraPlusUpgradeV2 from 'bundles/subscriptions/api/courseraPlusUpgradeV2';
import S12nSubscriptionWelcomeEmailsV1 from 'bundles/subscriptions/api/s12nSubscriptionWelcomeEmailsV1';

const upgradeEligibilityUri = (productItemId: string, productType: string): Uri => {
  return new Uri()
    .addQueryParam('action', 'isPostPurchaseEligible')
    .addQueryParam('productItemId', productItemId)
    .addQueryParam('productType', productType);
};

const upgradeTypeUri = (productItemId: string, productType: string): Uri => {
  return new Uri()
    .addQueryParam('action', 'postPurchaseUpsellType')
    .addQueryParam('productItemId', productItemId)
    .addQueryParam('productType', productType);
};

export const isEligibleForCourseraPlusUpgrade = (productItemId: string, productType: string): Q.Promise<boolean> => {
  const uri = upgradeEligibilityUri(productItemId, productType);
  // API response returns a boolean by default
  // If API fails, recover as a non-blocking issue by returning `false` as a default
  return Q(CourseraPlusUpgradeV2.post(uri.toString())).fail(() => {
    return false;
  });
};

export const isS12nEligibleForCourseraPlusUpgrade = (productItemId: string): Q.Promise<boolean> => {
  return isEligibleForCourseraPlusUpgrade(productItemId, ProductTypeObject.SPECIALIZATION);
};

export type CourseraPlusUpsellType =
  | 'GoogleS12NUpsell49' // upsell from Google $49 s12n
  | 'GoogleS12NUpsell39' // upsell from Google $39 s12n
  | 'S12NUpsell49' // upsell from a Specialization priced at $49
  | 'S12NUpsell49NoFreeTrial' // We're offering upsell for s12n w/o free trial to include learners in Test Drive (an experiment that eliminates free trial)
  | 'CourseUpsell49' // upsell from a $49 course
  | 'GatewayUpsell39WithPromotion' // for $39 Gateways
  | 'S12NUpsell39WithPromotion' // upsell from a Specialization priced at $39
  | 'None'; // no upsell (invalid product or user does not qualify, e.g. already owns Coursera Plus)

export const getCourseraPlusUpsellType = (
  productItemId: string,
  productType: string,
  originalCartId: string | number,
  shouldCheckProductOwnership = true
): Q.Promise<CourseraPlusUpsellType> => {
  const uri = upgradeTypeUri(productItemId, productType).addQueryParam('originalCartId', originalCartId);

  if (shouldCheckProductOwnership) {
    uri.addQueryParam('ownedByUser', user.get().id);
  }

  return (
    Q(CourseraPlusUpgradeV2.post(uri.toString()))
      // API response returns Default" or an empty string (which gets interpreted as `undefined`) by default
      .then((response) => {
        if (!response) {
          return 'None';
        } else {
          return response;
        }
      })
      // If API fails, recover as a non-blocking issue by returning "None" as a default
      .fail(() => {
        return 'None';
      })
  );
};

export const upgradeS12nToCourseraPlus = (
  s12nId: string,
  s12nProductType: string,
  courseIdToGrantMembership: string,
  originalCartId: number
): Q.Promise<boolean> => {
  const data = {
    userId: user.get().id,
    productItemId: s12nId,
    productType: s12nProductType,
    originalCartId,
    courseIdToGrantMembership,
  };
  const uri = new Uri().addQueryParam('action', 'postPurchaseUpgrade');
  return Q(CourseraPlusUpgradeV2.post(uri.toString(), { data }));
};

type CourseUpgradeInputType = {
  productItemId: string;
  productType: string;
  cartId: number;
  paymentWalletId?: number | null;
  paymentProcessorId?: string;
  taxRatePercentage?: string;
  billingInfo?: BillingInfo;
};

export const upgradeCourseToCourseraPlus = ({
  productItemId,
  productType,
  cartId: originalCartId,
  paymentWalletId,
  paymentProcessorId,
  taxRatePercentage,
  billingInfo,
}: CourseUpgradeInputType): Q.Promise<boolean> => {
  const uri = new Uri().addQueryParam('action', 'postPurchaseUpgrade');
  const { paymentMethodId, billingAddress } = billingInfo ?? {};
  const billingZipcode = billingAddress?.country === 'CA' ? billingAddress?.postalCode : billingAddress?.zipcode;

  const stripePaymentInformation = {
    upsellPaymentInfo: {
      typeName: 'stripePaymentInformation',
      definition: {
        billingInfo: {
          billingZipcode,
          billingCountry: billingAddress?.country,
          billingState: billingAddress?.state,
        },
        paymentMethodId,
        taxRatePercentage,
        paymentProcessorId,
      },
    },
  };

  const walletSubscriptionPaymentInformation = {
    upsellPaymentInfo: {
      typeName: 'walletSubscriptionPaymentInformation',
      definition: {
        transactionId: '',
        paymentWalletId,
      },
    },
  };
  const upsellPaymentInformation = paymentMethodId ? stripePaymentInformation : walletSubscriptionPaymentInformation;
  const data = {
    userId: user.get().id,
    productItemId,
    productType,
    originalCartId,
    ...upsellPaymentInformation,
  };
  return Q(CourseraPlusUpgradeV2.post(uri.toString(), { data }));
};

export const sendWelcomeEmail = (cartId: number, subscriptionId: string): Q.Promise<boolean> => {
  const uri = new Uri().addQueryParam('action', 'sendConsolidatedWelcomeEmail');
  const data = { cartId, subscriptionId };

  // API response returns a boolean by default
  // If API fails, recover as a non-blocking issue by returning `false` as a default
  return Q(S12nSubscriptionWelcomeEmailsV1.post(uri.toString(), { data })).fail(() => {
    return false;
  });
};

export const isGateway = (productItemId?: string): boolean => {
  const gatewayIds = paymentsExperiments.preview('courseraPlusGatewayCertificates');

  for (const gatewayId of gatewayIds) {
    if (gatewayId === productItemId) {
      return true;
    }
  }
  return false;
};

export const isStripeUpsellEnabled = () => courseraPlusExperiments.preview('s12n49StripeUpsellVariant') !== 'optOut';

export const isInPostPurchaseUpsellEpics = () =>
  paymentsbackendExperiments.preview('enableCourseraPlusUpsellUpgrade') ||
  growthFalconsExperiments.preview('isCourseUpsellNoFreeTrialEnabled') ||
  courseraPlusExperiments.preview('s12n49GoogleUpsellVariant') !== 'control' ||
  courseraPlusExperiments.preview('s12n39GoogleUpsellVariant') !== 'control' ||
  courseraPlusExperiments.preview('s12n49StripeUpsellVariant') !== 'optOut';
