import { compose } from 'recompose';

import { isValidCourseraLiteSubscription } from 'bundles/coursera-plus/utils/subscriptionTiersUtils';
import withSubscriptionsByUser, { useSubscriptionsByUser } from 'bundles/coursera-plus/utils/withSubscriptionsByUser';
import type { PropsFromWithSubscriptionsByUser } from 'bundles/coursera-plus/utils/withSubscriptionsByUser';
import Naptime, { useNaptime } from 'bundles/naptimejs';

/* @ts-expect-error ts-migrate(7016) FIXME: Untyped import */
import SubscriptionNextBillDetailsV1 from 'bundles/naptimejs/resources/subscriptionNextBillDetails.v1';
import type SubscriptionsV1 from 'bundles/naptimejs/resources/subscriptions.v1';

export type PropsFromWithExistingCourseraLiteSubscription = {
  existingCourseraLiteSubscription?: SubscriptionNextBillDetailsV1 | SubscriptionsV1;
  allSubscriptions?: SubscriptionNextBillDetailsV1 | SubscriptionsV1;
};

// This hook fetches an existing Lite subscription and ensures
// that it always has a non-null value for the nextBillingAt field.
// We do this by conditionally making an extra call to SubscriptionNextBillDetailsV1
// which will always have a valid value for nextBillingAt (unlike the SubscriptionsV1 resource
// whose nextBillingAt is null for cancelled subscriptions).
export const useExistingCourseraLiteSubscription = (skip = false) => {
  const { subscriptions } = useSubscriptionsByUser(skip);
  const {
    data: { existingCourseraLiteSubscription },
  } = useNaptime<PropsFromWithExistingCourseraLiteSubscription>(() => {
    const subscription = subscriptions?.find(isValidCourseraLiteSubscription);
    if (skip || !subscription) {
      return {};
    }

    if (subscription?.nextBillingAt) {
      return {
        existingCourseraLiteSubscription: subscription,
      };
    }

    return {
      existingCourseraLiteSubscription: SubscriptionNextBillDetailsV1.get(subscription?.id, {
        // cartId and paymentInformation for existing Lite subscription needed to fetch price for Plus
        // in the same currency as the price for the existing Lite subscription
        fields: ['cartId', 'paymentInformation', 'nextBillingAt'],
      }),
    };
  }, [subscriptions?.[0]?.id]);

  return {
    existingCourseraLiteSubscription,
    allSubscriptions: subscriptions,
  };
};

// Deprecated. Use useExistingCourseraLiteSubscription instead
const withExistingCourseraLiteSubscription = () =>
  compose(
    withSubscriptionsByUser(),
    Naptime.createContainer<PropsFromWithSubscriptionsByUser>(({ subscriptions }) => {
      const existingCourseraLiteSubscription = subscriptions?.find(isValidCourseraLiteSubscription);
      if (existingCourseraLiteSubscription?.nextBillingAt) {
        return {
          existingCourseraLiteSubscription,
        };
      }

      return {
        existingCourseraLiteSubscription: SubscriptionNextBillDetailsV1.get(existingCourseraLiteSubscription?.id, {
          fields: ['nextBillingAt'],
        }),
      };
    })
  );

export default withExistingCourseraLiteSubscription;
