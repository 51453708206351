/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import { Grid, PageGridContainer } from '@coursera/cds-core';

const gridStyle: React.CSSProperties = { paddingBottom: 0, paddingTop: 0 };

export const PageSection: React.FC<React.HTMLAttributes<HTMLElement>> = ({ children, css, ...props }) => {
  return (
    <div css={css as $TSFixMe} {...props}>
      <PageGridContainer maxWidth="lg" css={{ '> div': { marginTop: 0, marginBottom: 0 } }}>
        <Grid item xs={12} style={gridStyle}>
          {children}
        </Grid>
      </PageGridContainer>
    </div>
  );
};
