/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import gql from 'graphql-tag';

import { Typography2 } from '@coursera/cds-core';

import type { AboutProject as AboutProjectType } from 'bundles/enterprise-legacy-xdp/components/miniPDP/__generated__/AboutProject';
import withFragments from 'bundles/graphql/components/withFragments';

type Props = Omit<AboutProjectType, '__typename'>;

const styles = {
  container: css`
    white-space: pre-wrap;
    margin-top: 48px;
  `,
  description: css`
    margin: 0;
    line-height: 24px;
  `,
};

export function AboutProject({ description }: Props) {
  return (
    // NOTE: CDPs wrap the description with `TogglableContent`. Project authoring has a more aggressive restriction on description so I didn't feel the need to use that here. It may be valuable (especially on mobile web), but it's hard to say for certain since we don't have a real project authored through the new flow yet. We can revisit this and decide whether `TogglableContent` has any value once we have a project authored through the new flow.
    <div css={styles.container}>
      <Typography2 component="p" variant="bodyPrimary" css={styles.description}>
        {description}
      </Typography2>
    </div>
  );
}

export default withFragments({
  AboutProject: gql`
    fragment AboutProject on XdpV1_org_coursera_xdp_cdp_CDPMetadata {
      description
    }
  `,
})(AboutProject);
