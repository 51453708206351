import * as React from 'react';

import { compose } from 'recompose';

import type { InjectedRouter } from 'js/lib/connectToRouter';
import connectToRouter from 'js/lib/connectToRouter';
import deferToClientSideRender from 'js/lib/deferToClientSideRender';

import withEnrollModal from 'bundles/enroll/components/xdp/withEnrollModal';
import withEnrollment from 'bundles/enroll/components/xdp/withEnrollment';
import type { PropsFromWithEnrollment } from 'bundles/enroll/components/xdp/withEnrollment';
import { scopes } from 'bundles/enroll/utils/scopedContentRule';
import type { PropsFromWithScopedContentRule } from 'bundles/enroll/utils/withScopedContentRule';
import withScopedContentRule from 'bundles/enroll/utils/withScopedContentRule';
import BannerEnroll from 'bundles/enterprise-legacy-xdp/components/conversion/BannerEnroll';
import usePageData from 'bundles/enterprise-legacy-xdp/components/miniPDP/data/usePageData';
import Naptime from 'bundles/naptimejs';
import CoursesV1 from 'bundles/naptimejs/resources/courses.v1';
import withPromotionInfo from 'bundles/promotions/components/withPromotionInfo';
import EnrollButtonViewController from 'bundles/unified-description-page-common/data/controllers/EnrollButtonViewController';

const EnrollmentButton = withEnrollModal(BannerEnroll);

// shouldCheckActionParams is used to check showing the enroll modal after logging in
type PropsFromCaller = {
  courseId: string;
  buttonRootClassName: string;
  shouldCheckActionParams: boolean;

  // This is passed to BannerEnroll and affects the enroll button's hover/focus font/background color
  accentColor: string;
};

type PropsFromRouter = {
  router: InjectedRouter;
};

type PropsFromNaptime = {
  course: CoursesV1;
};

type PropsToDataWrapper = PropsFromCaller &
  PropsFromRouter &
  PropsFromWithEnrollment &
  PropsFromNaptime &
  PropsFromWithScopedContentRule;

const DataWrapper: React.FC<PropsToDataWrapper> = ({
  enrollmentAvailableChoices,
  course,
  scopedContentRule,
  ...rest
}) => {
  const { pageViewController } = usePageData();
  const enrollButtonViewController = new EnrollButtonViewController(
    pageViewController.courseType,
    course,
    enrollmentAvailableChoices,
    scopedContentRule ?? undefined
  );
  const props = {
    ...enrollButtonViewController.getButtonProps(),
    ...rest,
  };
  return <EnrollmentButton {...props} isEligibleForPlusMonthlyToAnnualUpgrade={false} />;
};

export default compose<PropsToDataWrapper, PropsFromCaller>(
  deferToClientSideRender,
  connectToRouter<PropsFromRouter, PropsFromCaller>((router) => ({ router })),
  withEnrollment(),
  withPromotionInfo(),
  Naptime.createContainer<PropsFromNaptime, PropsFromCaller>(({ courseId }) => ({
    course: CoursesV1.get(courseId, {
      fields: ['courseStatus'],
      params: {
        showHidden: true,
      },
    }),
  })),
  withScopedContentRule<PropsFromCaller>(scopes.COURSE_WITH_FULL_DISCOUNT)
)(DataWrapper);
