import type Maybe from 'graphql/tsutils/Maybe';

import logger from 'js/app/loggerSingleton';
import contentfulGql from 'js/lib/contentfulGql';
import waitForGraphQL from 'js/lib/waitForGraphQL';

import type { PartnerIncentive as LearnerIncentive } from '@coursera/grpc-types-partnerincentives/coursera/proto/partnerincentives/v1/partner_incentive';

import type {
  LearnerIncentivePromotionQuery,
  LearnerIncentivePromotionQueryVariables,
} from 'bundles/learner-incentives/__generated__/contentfulTypes';
import type { LearnerIncentive as LearnerIncentiveType } from 'bundles/learner-incentives/types';

export const learnerIncentivePromotionContentfulQuery = contentfulGql`#graphql
  query LearnerIncentivePromotion($id: Int!) {
    learnerIncentiveCollection(where: { id: $id }, limit: 1) {
      items {
        xdpMerchandisingImage {
          url
        }
        xdpMerchandisingTitle
        xdpMerchandisingText
        redeemModalLogo {
          url
        }
        redeemModalTitle
        redeemModalDetails
        redeemModalRedeemButtonText
        redeemModalFinePrint
      }
    }
  }
`;

export type LearnerIncentiveRedeemData = {
  redeemModalLogo?: Maybe<{
    url?: Maybe<string>;
  }>;
  redeemModalTitle?: Maybe<string> | JSX.Element;
  redeemModalDetails?: Maybe<string> | JSX.Element;
  redeemModalRedeemButtonText?: Maybe<string>;
  redeemModalFinePrint?: Maybe<string> | JSX.Element;
};

export type LearnerIncentivePromotionData = {
  xdpMerchandisingTitle?: Maybe<string>;
  xdpMerchandisingText?: Maybe<string>;
  xdpMerchandisingImage?: Maybe<{
    url?: Maybe<string>;
  }>;
};

export type LearnerIncentiveContentfulData = LearnerIncentivePromotionData & LearnerIncentiveRedeemData;

type InputProps = {
  learnerIncentives?: Array<LearnerIncentiveType>;
  learnerIncentive?: LearnerIncentive;
};

type PropsFromContentfulGraphQL = {
  learnerIncentivePromotionData?: LearnerIncentiveContentfulData;
};

export type PropsFromWithLearnerIncentivePromotionData = PropsFromContentfulGraphQL;

type CopyDestinationType = 'modal' | 'xdp';

const getSelectedFields = (data: LearnerIncentiveContentfulData, copyDestination: CopyDestinationType) => {
  const {
    redeemModalLogo,
    redeemModalTitle,
    redeemModalDetails,
    redeemModalRedeemButtonText,
    redeemModalFinePrint,
    xdpMerchandisingTitle,
    xdpMerchandisingText,
    xdpMerchandisingImage,
  } = data;

  if (copyDestination === 'modal') {
    return {
      redeemModalLogo,
      redeemModalTitle,
      redeemModalDetails,
      redeemModalRedeemButtonText,
      redeemModalFinePrint,
    };
  } else {
    return {
      xdpMerchandisingTitle,
      xdpMerchandisingText,
      xdpMerchandisingImage,
    };
  }
};

export const withLearnerIncentivePromotionData = <TInputProps extends InputProps>(
  copyDestination: CopyDestinationType
) =>
  waitForGraphQL<
    TInputProps,
    LearnerIncentivePromotionQuery,
    LearnerIncentivePromotionQueryVariables,
    PropsFromContentfulGraphQL
  >(learnerIncentivePromotionContentfulQuery, {
    options: ({ learnerIncentives, learnerIncentive }) => {
      // query requires id to be a number, using -1 as a default value. If no id will be found the call will be skipped.
      let id = -1;

      if (learnerIncentive) {
        id = learnerIncentive.id;
      }

      if (learnerIncentives && learnerIncentives.length > 0) {
        id = learnerIncentives[0].id;
      }

      return {
        // Do not remove Number casting, types are screwed up and it's required event thought typescript is saying it's always a number
        // TODO: fix types node_modules/@coursera/grpc-types-partnerincentives/coursera/proto/partnerincentives/v1/partner_incentive.d.ts
        variables: { id: Number(id) },
        context: { clientName: 'contentfulGql' },
        skip: id === -1,
      };
    },
    props: ({ data }) => {
      const learnerIncentivePromotionData = data?.learnerIncentiveCollection?.items[0];
      if (!learnerIncentivePromotionData) {
        logger.error(`Missing learner incentive Contentful data for this Specialization.`);
        return {};
      }

      return { learnerIncentivePromotionData: getSelectedFields(learnerIncentivePromotionData, copyDestination) };
    },
  });
