import type { CmlContent } from 'bundles/cml';
import type { ProductGlanceList } from 'bundles/enterprise-legacy-xdp/components/ProductGlance';
import {
  make100percentOnlineS12nGlance,
  makeApproximatelyMonthsToCompleteGlance,
  makeDifficultyLevelGlance,
  makeDifficultyLevelGlanceWithDefault,
  makeFlexibleScheduleGlance,
  makeHasCourseraLabsGlance,
  makeLanguagesGlance,
  makeMultipleAvailabilityOptionsGlance,
  makeS12nCanBeOfferedForCreditGlance,
  makeS12nCourseAvailabilityGlance,
  makeS12nLimitedAvailabilityGlance,
  makeS12nOfferedForCreditGlance,
  makeS12nRecommendedForCreditGlance,
  makeShareAbleCertificateGlance,
} from 'bundles/enterprise-legacy-xdp/components/atAGlanceItems';
import type S12nDerivativesV1 from 'bundles/naptimejs/resources/s12nDerivatives.v1';
import type { DifficultyLevel, EnterpriseProductConfiguration } from 'bundles/program-common/types/xdpTypes';

type S12nAtAGlanceConfig = {
  avgLearningMonthsAdjusted?: number;
  avgLearningHoursPerWeek?: number;
  backgroundLevelCml?: CmlContent;
  backgroundLevelHtml?: string;
  level?: DifficultyLevel;
  languageLineThreshold?: number;
  primaryLanguages: Array<string>;
  subtitleLanguages: Array<string>;
  showDefaultBackgroundLevel?: boolean;
  hasPrivateSessionDates?: boolean;
  s12nDerivative?: S12nDerivativesV1;
  hasMultipleAvailabilities?: boolean;
  isEnterpriseAdminView?: boolean;
  enableCurriculumBuilder?: boolean;
  isC4C?: boolean;
  enterpriseProductConfiguration?: EnterpriseProductConfiguration;
  showCourseraLabsGlance?: boolean;
};

const getS12nGlanceList = (config: S12nAtAGlanceConfig): ProductGlanceList => {
  const {
    avgLearningMonthsAdjusted,
    avgLearningHoursPerWeek,
    level,
    backgroundLevelCml,
    backgroundLevelHtml,
    primaryLanguages = [],
    subtitleLanguages = [],
    showDefaultBackgroundLevel,
    hasPrivateSessionDates,
    isEnterpriseAdminView,
    enableCurriculumBuilder,
    hasMultipleAvailabilities,
    isC4C,
    enterpriseProductConfiguration,
    showCourseraLabsGlance,
  } = config;

  const glanceList = [makeShareAbleCertificateGlance(), make100percentOnlineS12nGlance()];

  if (showCourseraLabsGlance) {
    glanceList.push(makeHasCourseraLabsGlance());
  }

  if (hasPrivateSessionDates) {
    glanceList.unshift(makeS12nCourseAvailabilityGlance());
  } else {
    glanceList.push(makeFlexibleScheduleGlance());
  }

  if (level) {
    if (showDefaultBackgroundLevel) {
      glanceList.push(makeDifficultyLevelGlanceWithDefault(level, backgroundLevelCml, backgroundLevelHtml));
    } else {
      glanceList.push(makeDifficultyLevelGlance(level, backgroundLevelCml));
    }
  }

  if (avgLearningMonthsAdjusted) {
    glanceList.push(makeApproximatelyMonthsToCompleteGlance(avgLearningMonthsAdjusted, avgLearningHoursPerWeek));
  }

  if (primaryLanguages.length > 0) {
    glanceList.push(makeLanguagesGlance(primaryLanguages, subtitleLanguages));
  }

  if (isEnterpriseAdminView && enableCurriculumBuilder) {
    glanceList.unshift(
      hasMultipleAvailabilities ? makeMultipleAvailabilityOptionsGlance() : makeS12nLimitedAvailabilityGlance()
    );

    if (isC4C && enterpriseProductConfiguration?.isRecommendedForCredit) {
      glanceList.unshift(makeS12nRecommendedForCreditGlance());
    } else if (isC4C) {
      glanceList.unshift(makeS12nCanBeOfferedForCreditGlance());
    }
  }

  if (!isEnterpriseAdminView) {
    if (enterpriseProductConfiguration?.isSelectedForCredit) {
      glanceList.unshift(makeS12nOfferedForCreditGlance());
    }
  }

  return glanceList.filter((itm) => itm);
};

export default getS12nGlanceList;
