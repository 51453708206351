import * as React from 'react';

import ValuePropBulletPoint from 'bundles/enroll/components/common/ValuePropBulletPoint';
import { getPaidSubscriptionBullets } from 'bundles/enroll/components/subscriptions/free-trialV2/utils/bulletData';


import 'css!./__styles__/SubscriptionVPropBulletPoints';

const SubscriptionVPropBulletPoints: React.FC<{}> = () => {
  const bullets = getPaidSubscriptionBullets();
  return (
    <ul className="rc-SubscriptionVPropBulletPoints nostyle">
      {bullets.map((bullet) => (
        <li key={`bullet_${bullet}`}>
          <ValuePropBulletPoint>
            <p className="body-1-text">{bullet}</p>
          </ValuePropBulletPoint>
        </li>
      ))}
    </ul>
  );
};

export default SubscriptionVPropBulletPoints;
