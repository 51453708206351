import * as React from 'react';
import { useContext } from 'react';

import type { ApolloError } from '@apollo/client';

import type { ClipsCollectionsBySkill } from 'bundles/program-personalized-tab/types/sharedTypes';

export type ClipsRecsContextType = {
  recs: ClipsCollectionsBySkill;
  loading?: boolean;
  error?: ApolloError;
  leadershipRecs?: ClipsCollectionsBySkill;
  leadershipRecsLoading?: boolean;
  leadershipRecsError?: ApolloError;
};

const ClipsRecsContext = React.createContext<ClipsRecsContextType | undefined>(undefined);

export const useClipsRecsData = () => {
  const clipsRecsContext = useContext(ClipsRecsContext);
  return clipsRecsContext;
};

export default ClipsRecsContext;
