import usePageData from 'bundles/enroll/data/usePageData';
import type { PrepaidProduct } from 'bundles/enroll/types/ProductInfoTypes';
import paymentsbackendExperiments from 'bundles/epic/clients/payments-backend';
import type { ProductPrices } from 'bundles/payments-common/hooks/useGetProductPrices';
import { NON_RECURRING_CHOICES } from 'bundles/s12n-enroll/components/non-recurring/utils/constants';

export const useNonRecurringChoiceData = (selectedS12nId?: string) => {
  const { enrollmentAvailableChoices, s12nPrepaidProductPrices, courseraPlusPrepaidProductPrices, s12n } =
    usePageData();
  const s12nId = selectedS12nId ?? s12n?.id ?? '';
  let prepaidProductPrices: ProductPrices[] | undefined = [];
  let prepaidOptions: PrepaidProduct[] | undefined;

  if (
    paymentsbackendExperiments.preview('courseraPlusPrepaidEnabled') &&
    enrollmentAvailableChoices.canPurchaseThroughCourseraPlus &&
    enrollmentAvailableChoices?.courseraPlusPrepaidEnrollmentS12nIds?.includes(s12nId)
  ) {
    prepaidProductPrices = courseraPlusPrepaidProductPrices;
    prepaidOptions = enrollmentAvailableChoices.getCourseraPlusPrepaidProducts(s12nId);
  } else {
    prepaidProductPrices = s12nPrepaidProductPrices;
    prepaidOptions = enrollmentAvailableChoices.getS12nPrepaidProducts(s12nId);
  }

  return { prepaidProductPrices, prepaidOptions };
};

export const getSortedPaymentPassOptions = (prepaidOptions: Array<PrepaidProduct>) =>
  prepaidOptions.sort((firstOption, secondOption) => {
    return firstOption.productProperties.ownershipDays - secondOption.productProperties.ownershipDays;
  });

export const getCurrentPaymentPassOption = (choiceType: string, sortedPaymentPassOptions: Array<PrepaidProduct>) => {
  switch (choiceType) {
    case NON_RECURRING_CHOICES.PASS_ONE:
      return sortedPaymentPassOptions[0];
    case NON_RECURRING_CHOICES.PASS_TWO:
      return sortedPaymentPassOptions[1];
    case NON_RECURRING_CHOICES.PASS_THREE:
      return sortedPaymentPassOptions[2];
    // Select the middle option by default
    default:
      return sortedPaymentPassOptions[1];
  }
};
