import { StyleSheet, zIndex } from '@coursera/coursera-ui';

export default StyleSheet.create({
  root: {
    overflow: 'hidden',
  },

  closeButtonWrapper: {
    zIndex: zIndex.xs,
    position: 'absolute',
    top: 0,
    right: 0,

    ':nth-child(1n) button': {
      margin: 0,
      padding: 22,
    },
  },

  tabListRoot: {
    padding: '24px 14px',

    ':nth-child(n) .TabListCardLayout-TabPanel': {
      '@media (min-width: 1024px)': {
        padding: '0.25rem 1.25rem',
      },
    },
  },

  tabContentColumns: {
    ':nth-child(n) > div': {
      flex: '0 1 50%',
    },

    ':nth-child(n) > div:nth-child(n+2)': {
      marginLeft: '1.25rem',
    },

    '@media (max-width: 1024px)': {
      flexWrap: 'wrap',
      flex: '0 1 100%',

      ':nth-child(n) > div': {
        flex: '0 1 80%',
      },

      ':nth-child(n) > div:nth-child(n+2)': {
        marginLeft: '0rem',
      },
    },
  },

  dismissButton: {
    background: 'none',
    border: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    color: '#2A73CC',
  },

  dismissButtonText: {
    marginRight: '12px',
  },

  dismissButtonRow: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});
