import user from 'js/lib/user';

import paymentsbackendEpicClient from 'bundles/epic/clients/payments-backend';

// These partner IDs are for Google and we are excluding Google from the pricing test.
// Google Cloud is not excluded
export const CHARM_PRICING_US_TEST_EXCLUDED_PARTNERS = [318, 330, 463, 481];

const getIsPartnerExcludedFromCharmPricingUsTest = (partnerIds?: Array<string>) => {
  const isPartnerExcludedFromCharmPricingUsTest = partnerIds
    ?.map((partnerId) => Number(partnerId))
    ?.some((partnerId) => CHARM_PRICING_US_TEST_EXCLUDED_PARTNERS.includes(partnerId));

  return Boolean(isPartnerExcludedFromCharmPricingUsTest);
};
export const generateCharmPricingInUsTestImpression = (partnerIds?: Array<string>) => {
  if (user.isAuthenticatedUser() && !getIsPartnerExcludedFromCharmPricingUsTest(partnerIds)) {
    paymentsbackendEpicClient.get('2024q1-charm-pricing-us');
  }
};
