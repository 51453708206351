import * as React from 'react';
import { Route } from 'react-router';

import { Route as CourseraRoute } from '@coursera/react-router';

import loadOnRoute from 'bundles/common/components/loadOnRoute';
import NotFound from 'bundles/phoenix/components/NotFound';
import ConsumerPageWrapper from 'bundles/unified-career-academy/pages/consumer/ConsumerPageWrapper';
import EnterprisePageWrapper from 'bundles/unified-career-academy/pages/enterprise/EnterprisePageWrapper';

const ConsumerPageRoot = loadOnRoute(() => import('bundles/unified-career-academy/pages/consumer/ConsumerPageRoot'));
const EnterprisePageRoot = loadOnRoute(
  () => import('bundles/unified-career-academy/pages/enterprise/career-academy/EnterprisePageRoot')
);
const EnterpriseRolePageRoot = loadOnRoute(
  () => import('bundles/unified-career-academy/pages/enterprise/role-page/EnterpriseRolePageRoot')
);

const ConsumerRolePageRoot = loadOnRoute(
  () => import('bundles/unified-career-academy/pages/consumer/role-page/ConsumerRolePageRoot')
);

export default (
  <Route>
    <Route path="career-academy/programs" component={EnterprisePageWrapper}>
      <CourseraRoute
        handle={{
          tracking: {
            data: (params) => ({
              page: { type: 'career_academy', segment: 'enterprise' },
              enterprise: {
                ...(params?.programSlug ? { programSlug: params.programSlug } : {}),
              },
            }),
          },
        }}
        path=":programSlug"
        getComponent={EnterprisePageRoot}
      />
      <CourseraRoute
        handle={{
          tracking: {
            data: (params) => ({
              page: { type: 'role_description_page', segment: 'enterprise' },
              enterprise: {
                ...(params?.programSlug ? { programSlug: params.programSlug } : {}),
              },
            }),
          },
        }}
        path=":programSlug/roles/:roleSlug"
        getComponent={EnterpriseRolePageRoot}
      />
    </Route>
    <Route path="/" component={ConsumerPageWrapper}>
      <CourseraRoute
        handle={{
          tracking: {
            data: () => ({
              page: { type: 'career_academy', segment: 'consumer' },
            }),
          },
        }}
        path="career-academy"
        getComponent={ConsumerPageRoot}
      />
      <CourseraRoute
        handle={{
          tracking: {
            data: () => ({
              page: { type: 'career_academy', segment: 'consumer' },
            }),
          },
        }}
        path="career-academy-unified"
        getComponent={ConsumerPageRoot}
      />
      <CourseraRoute
        handle={{
          tracking: {
            data: () => ({
              page: { type: 'role_description_page', segment: 'consumer' },
            }),
          },
        }}
        path="career-academy/roles/:roleSlug"
        getComponent={ConsumerRolePageRoot}
      />
    </Route>
    <Route path="*" component={NotFound} />
    );
  </Route>
);
