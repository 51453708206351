import { graphql } from 'react-apollo';
import type { DataValue } from 'react-apollo';

import { useQuery } from '@apollo/client';
import { reject, toNumber, unionBy } from 'lodash';

import { tupleToStringKey } from 'js/lib/stringKeyTuple';

import type { Description } from 'bundles/enterprise-collections/components/CollectionDescription';
import type {
  CollectionListItemStruct,
  Item,
  Partner,
  PartnerList,
  Product,
  ProductList,
  SessionList,
  SessionType,
} from 'bundles/program-common/types/programCommon';
import fetchMoreErrorFilter from 'bundles/program-common/utils/fetchMoreErrorFilter';
import filterExistsOrDefault from 'bundles/program-common/utils/filterExistsOrDefault';
import { DiscoveryBrowseCollectionsQuery } from 'bundles/program-home/components/single-program/SingleProgramGraphqlQueries';
import type {
  DiscoveryBrowseCollectionsQuery_DiscoveryCollections_queryEnterpriseCollectionsByProgram_productCollections as DiscoveryBrowseCollection,
  DiscoveryBrowseCollectionsQuery as DiscoveryBrowseCollectionsQueryData,
  DiscoveryBrowseCollectionsQueryVariables,
  DiscoveryBrowseCollectionsQuery_DiscoveryCollections_queryEnterpriseCollectionsByProgram_productCollections_entities_DiscoveryCollections_course_enterpriseCustomSessions as DiscoveryCollectionCourseSession,
  DiscoveryBrowseCollectionsQuery_DiscoveryCollections_queryEnterpriseCollectionsByProgram_productCollections_entities as DiscoveryCollectionEntity,
  DiscoveryBrowseCollectionsQuery_DiscoveryCollections_queryEnterpriseCollectionsByProgram_productCollections_entities_DiscoveryCollections_guidedProject_enterpriseCustomSessions as DiscoveryCollectionGuidedProjectSession,
} from 'bundles/program-home/components/single-program/__generated__/DiscoveryBrowseCollectionsQuery';
import { NormalS12n, ProfessionalCertificateS12n } from 'bundles/s12n-common/constants/s12nProductVariants';

const COLLECTION_LOAD_LIMIT = 4;

type PropsFromWithState = {
  paginationError: boolean;
  setPaginationError: (val: boolean) => void;
};

type PropsFromCaller = {
  contextType: string;
  contextId: string;
  programId: string;
  start: number | string;
  limit: number;
  isAuthenticatedUser: boolean;
} & PropsFromWithState;

export type DiscoveryCollectionPropsToComponent = {
  collections: Array<CollectionListItemStruct> | undefined;
  totalCollections?: number | null;
  loading: boolean;
  error: boolean;
  browseCollections: Array<CollectionListItemStruct> | undefined;
  browseLoading: boolean;
  browseError: boolean;
};

export type PropsFromGraphql = DiscoveryCollectionPropsToComponent & PropsFromWithState;

function discoveryEntityToItem(discoveryCollectionCourse: DiscoveryCollectionEntity, isS12n: boolean): Item {
  return {
    productId: tupleToStringKey([isS12n ? 'Specialization' : 'VerifiedCertificate', discoveryCollectionCourse.id]),
  };
}

function enterpriseCustomSessionToSessionType(
  session: DiscoveryCollectionCourseSession | DiscoveryCollectionGuidedProjectSession
): SessionType {
  return {
    courseId: session.courseId,
    startsAt: toNumber(session.startsAt.seconds) * 1000,
    endsAt: toNumber(session.endsAt.seconds) * 1000,
  };
}

function discoveryCollectionEntityToProduct(discoveryCollectionCourse: DiscoveryCollectionEntity): Product {
  const partnersList: PartnerList = { elements: [] };
  discoveryCollectionCourse.partners?.forEach((partner) => {
    if (partner) {
      const currentPartner: Partner | null = {
        name: partner.name,
        id: partner.id,
        squareLogo: partner.logo,
      };
      partnersList.elements.push(currentPartner);
    }
  });

  const product: Product = {
    id: discoveryCollectionCourse.id,
    slug: discoveryCollectionCourse.slug,
    name: discoveryCollectionCourse.name,
    promoPhoto: discoveryCollectionCourse.imageUrl,
    partners: partnersList,
    display: true,
  };

  switch (discoveryCollectionCourse.__typename) {
    case 'DiscoveryCollections_specialization':
      product.productVariant = NormalS12n;
      product.courseIds = new Array(discoveryCollectionCourse.courseCount);
      break;
    case 'DiscoveryCollections_professionalCertificate':
      product.productVariant = ProfessionalCertificateS12n;
      product.courseIds = new Array(1);
      break;
    case 'DiscoveryCollections_guidedProject':
      product.courseTypeMetadata = {
        courseTypeMetadata: {
          __typename: 'CourseTypeMetadataV1_rhymeProjectMember',
        },
      };
      break;
    case 'DiscoveryCollections_course':
      product.courseTypeMetadata = {
        courseTypeMetadata: {
          __typename: 'CourseTypeMetadataV1_standardCourseMember',
        },
      };
      break;
    default:
  }
  return product;
}

function discoveryCollectionToCollectionListItemStruct(
  discoveryCollection: DiscoveryBrowseCollection
): CollectionListItemStruct {
  const description: Description = {
    cml: undefined,
  };
  const courses: ProductList = { elements: [] };
  const courseIds: string[] = [];
  const s12nIds: string[] = [];
  const s12ns: ProductList = { elements: [] };
  const items: Array<Item> = [];
  const associatedSessions: SessionList = { elements: [] };

  discoveryCollection?.entities?.forEach((course) => {
    if (
      course?.__typename === 'DiscoveryCollections_specialization' ||
      course?.__typename === 'DiscoveryCollections_professionalCertificate'
    ) {
      s12nIds.push(course.id);
      items.push(discoveryEntityToItem(course, true));
      s12ns.elements.push(discoveryCollectionEntityToProduct(course));
    } else if (
      course?.__typename === 'DiscoveryCollections_course' ||
      course?.__typename === 'DiscoveryCollections_guidedProject'
    ) {
      courseIds.push(course.id);
      items.push(discoveryEntityToItem(course, false));
      courses.elements.push(discoveryCollectionEntityToProduct(course));
      if (course.enterpriseCustomSessions?.[0] && associatedSessions.elements) {
        associatedSessions.elements.push(enterpriseCustomSessionToSessionType(course.enterpriseCustomSessions[0]));
      }
    }
  });

  return {
    id: discoveryCollection.id,
    title: discoveryCollection.label,
    image: null,
    collectionTrackingId: null,
    courseIds,
    s12nIds,
    description,
    trackId: discoveryCollection.id,
    courses,
    s12ns,
    items,
    associatedSessions,
  };
}

export function discoveryCollectionsToCollectionListItemStrucArray(
  discoveryCollections: Array<DiscoveryBrowseCollection | null>
): CollectionListItemStruct[] {
  const collectionList: CollectionListItemStruct[] = [];
  discoveryCollections.forEach(
    (collection) => collection && collectionList.push(discoveryCollectionToCollectionListItemStruct(collection))
  );
  return collectionList;
}

export function updateCollectionQuery(
  prevResult: DiscoveryBrowseCollectionsQueryData,
  newResult: DiscoveryBrowseCollectionsQueryData | undefined
): DiscoveryBrowseCollectionsQueryData {
  if (!newResult?.DiscoveryCollections?.queryEnterpriseCollectionsByProgram) {
    return prevResult;
  }

  const productCollections = unionBy(
    prevResult.DiscoveryCollections?.queryEnterpriseCollectionsByProgram?.productCollections,
    newResult.DiscoveryCollections?.queryEnterpriseCollectionsByProgram?.productCollections,
    (productCollection) => productCollection?.id
  );

  return {
    DiscoveryCollections: {
      __typename: newResult.DiscoveryCollections.__typename,
      queryEnterpriseCollectionsByProgram: {
        __typename: newResult.DiscoveryCollections.queryEnterpriseCollectionsByProgram.__typename,
        next: newResult.DiscoveryCollections.queryEnterpriseCollectionsByProgram.next,
        productCollections,
      },
    },
  };
}

export const loadMoreItemsDisco =
  (
    data:
      | Pick<
          DataValue<DiscoveryBrowseCollectionsQueryData, DiscoveryBrowseCollectionsQueryVariables>,
          'loading' | 'fetchMore' | 'DiscoveryCollections'
        >
      | undefined,
    setPaginationError: (val: boolean) => void
  ) =>
  () => {
    if (!data) {
      return;
    }

    const { loading, fetchMore } = data;
    if (loading) {
      return;
    }
    const start = data?.DiscoveryCollections?.queryEnterpriseCollectionsByProgram?.next;
    if (!start) {
      return;
    }

    fetchMore({
      variables: {
        start,
        limit: COLLECTION_LOAD_LIMIT,
      },
      updateQuery: (prevResult, { fetchMoreResult: newResult }) => {
        return updateCollectionQuery(prevResult, newResult);
      },
    })
      .catch(fetchMoreErrorFilter)
      .catch(() => {
        setPaginationError(true);
      });
  };

// filter out collections that contains the word "free"
// currently only LmyL-2WZTmeRq-rWx2tDVw
const BannedCollectionIds = new Set(['LmyL-2WZTmeRq-rWx2tDVw']);

export const filterBlacklistCollections = (collections: CollectionListItemStruct[]) => {
  return reject(collections, (collection) => BannedCollectionIds.has(collection.id));
};

const withDiscoveryBrowseCollections = graphql<
  PropsFromCaller,
  DiscoveryBrowseCollectionsQueryData,
  DiscoveryBrowseCollectionsQueryVariables,
  DiscoveryCollectionPropsToComponent
>(DiscoveryBrowseCollectionsQuery, {
  options: ({ programId, start, contextId, contextType, limit }) => {
    return {
      ssr: false,
      variables: {
        contextId,
        contextType,
        programId,
        start: Number(start),
        limit,
      },
      context: { clientName: 'gatewayGql' },
      errorPolicy: 'all',
    };
  },
  props: ({ data, ownProps }) => {
    const collections = discoveryCollectionsToCollectionListItemStrucArray(
      filterExistsOrDefault(data?.DiscoveryCollections?.queryEnterpriseCollectionsByProgram?.productCollections)
    );
    const filteredCollections = filterBlacklistCollections(filterExistsOrDefault(collections));
    const isAllLoaded = data?.DiscoveryCollections?.queryEnterpriseCollectionsByProgram?.next === null;
    const loading = data?.loading ?? true;
    const error = Boolean(data?.error);
    // Returning variables for what the calling components are expecting
    return {
      // Corresponding component - AllBrowseCollections.tsx & BrowseCollections.tsx
      collections: filteredCollections,
      loading,
      error,
      isAllLoaded,
      loadMoreItems: loadMoreItemsDisco(data, ownProps.setPaginationError),
      // Corresponding component - ProgramCollectionsView.tsx
      browseCollections: filteredCollections,
      browseLoading: loading,
      browseError: error,
    };
  },
});

const loadMoreItems =
  ({
    fetchMore,
    data,
    loading,
  }: {
    data?: DiscoveryBrowseCollectionsQueryData;
    loading?: boolean;
    fetchMore: DataValue<DiscoveryBrowseCollectionsQueryData, DiscoveryBrowseCollectionsQueryVariables>['fetchMore'];
  }) =>
  () => {
    const start = data?.DiscoveryCollections?.queryEnterpriseCollectionsByProgram?.next;

    if (!data || loading || !start) {
      return;
    }

    fetchMore({
      variables: {
        start,
        limit: COLLECTION_LOAD_LIMIT,
      },
      updateQuery: (prevResult, { fetchMoreResult: newResult }) => {
        return updateCollectionQuery(prevResult, newResult);
      },
    }).catch(fetchMoreErrorFilter);
  };

export const useDiscoveryBrowseCollectionsData = ({
  contextId,
  contextType,
  programId,
  start,
  limit,
}: Pick<PropsFromCaller, 'contextId' | 'contextType' | 'programId' | 'start' | 'limit'>) => {
  const { data, loading, error, fetchMore } = useQuery<
    DiscoveryBrowseCollectionsQueryData,
    DiscoveryBrowseCollectionsQueryVariables
  >(DiscoveryBrowseCollectionsQuery, {
    variables: {
      contextId,
      contextType,
      programId,
      start: Number(start),
      limit,
    },
    context: { clientName: 'gatewayGql' },
  });

  const collections = discoveryCollectionsToCollectionListItemStrucArray(
    filterExistsOrDefault(data?.DiscoveryCollections?.queryEnterpriseCollectionsByProgram?.productCollections)
  );
  const filteredCollections = filterBlacklistCollections(filterExistsOrDefault(collections));
  const isAllLoaded = data?.DiscoveryCollections?.queryEnterpriseCollectionsByProgram?.next === null;
  // Returning variables for what the calling components are expecting
  return {
    // Corresponding component - AllBrowseCollections.tsx & BrowseCollections.tsx
    collections: filteredCollections,
    loading,
    error: Boolean(error),
    isAllLoaded,
    loadMoreItems: loadMoreItems({ data, fetchMore, loading }),
    // Corresponding component - ProgramCollectionsView.tsx
    browseCollections: filteredCollections,
    browseLoading: loading,
    browseError: error,
  };
};

export { discoveryCollectionEntityToProduct };
export default withDiscoveryBrowseCollections;
