/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { typography2 } from '@coursera/cds-core';

import DashboardUploaderBaseComponent from 'bundles/asset-admin/components/DashboardUploader';
import type { Props } from 'bundles/asset-admin/components/DashboardUploader';

const styles = {
  cdsOverrides: css`
    .rc-DashboardUploader,
    .uppy-ProviderBrowser-searchInput {
      /* default body 1 overrides */
      font-family: 'Source Sans Pro', Arial, sans-serif;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      letter-spacing: 0;
    }

    .rc-DashboardUploader .uppy-Dashboard .uppy-Dashboard-dropFilesTitle,
    .rc-DashboardUploader .uppy-Dashboard .uppy-Dashboard-AddFiles-title,
    .rc-DashboardUploader .uppy-Dashboard .uppy-Dashboard-AddFiles-title button {
      /* CDS H2 overrides for initial instructions */
      font-size: 1.25rem;
      font-weight: 600;
      letter-spacing: -0.1px;
    }

    .rc-DashboardUploader .uppy-Dashboard .uppy-Dashboard-AddFiles-title .uppy-Dashboard-browse {
      /* CDS override for the browse button */
      color: var(--cds-color-interactive-primary);
    }

    .rc-DashboardUploader .uppy-Dashboard .uppy-DashboardTab .uppy-DashboardTab-name,
    .uppy-size--md .uppy-DashboardContent-title {
      /* CDS Body1 overrides for dashboard provider tabs, navbar */
      font-size: 1rem;
      font-weight: 400;
      color: var(--cds-color-neutral-primary);
    }

    .uppy-size--md .uppy-Provider-authTitle {
      /* CDS override for the auth title */
      color: var(--cds-color-neutral-primary-weak);
    }

    .uppy-u-reset.uppy-ProviderBrowserItem-inner,
    .uppy-Provider-breadcrumbs,
    .uppy-ProviderBrowser-user,
    .uppy-ProviderBrowser-userLogout,
    .uppy-ProviderBrowser-searchInput,
    .uppy-Dashboard-Item-name {
      /* CDS Body2 overrides for item list */
      letter-spacing: 0;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }

    .uppy-DashboardContent-back,
    .uppy-ProviderBrowser-userLogout {
      /* CDS ghost button overrides */
      color: var(--cds-color-interactive-primary);
      text-decoration: none;
      /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
      color: var(--cds-color-interactive-primary);

      &:hover {
        text-decoration: underline;
        color: var(--cds-color-interactive-primary-hover);
        background-color: var(--cds-color-interactive-stroke-primary-focus-invert);
      }

      &:active {
        text-decoration: underline;
        color: var(--cds-color-interactive-primary-pressed);
        background-color: var(--cds-color-interactive-background-primary-pressed-weak);

        &.disableElevation {
          &::after {
            content: unset;
          }
        }
      }

      &.disabled {
        color: var(--cds-color-neutral-disabled-strong);
      }
    }

    .rc-DashboardUploader .uppy-Dashboard .uppy-Dashboard-inner .uppy-c-btn-primary {
      /* CDS primary button overrides */
      color: var(--cds-color-neutral-primary-invert);
      background-color: var(--cds-color-interactive-primary);
      box-shadow: inset 0 0 0 1px var (--cds-color-interactive-primary);

      &:hover {
        border-color: var(--cds-color-interactive-primary-hover);
        background-color: var(--cds-color-interactive-primary-hover);
      }

      &:active {
        border-color: var(--cds-color-interactive-primary-pressed);
        background-color: var(--cds-color-interactive-primary-pressed);

        &.disableElevation {
          &::after {
            content: unset;
          }
        }
      }

      &.disabled {
        color: var(--cds-color-neutral-disabled);
        background: var(--cds-color-neutral-disabled-strong);
      }
    }

    .uppy-DashboardContent-back,
    .uppy-ProviderBrowser-userLogout,
    .rc-DashboardUploader .uppy-Dashboard .uppy-Dashboard-inner .uppy-c-btn-primary {
      /* CDS default button overrides */

      ${typography2.subtitleMedium}
      padding: var(--cds-spacing-100) var(--cds-spacing-200);
      border-radius: 4px;
      text-transform: none;
      text-align: center;
      max-width: 320px;
      min-width: unset;
      transition: none;
    }

    .uppy-c-btn-primary:focus,
    .uppy-u-reset.uppy-Dashboard-Item-action.uppy-Dashboard-Item-action--remove:focus {
      outline-offset: 2px;
      outline: 1px solid var(--cds-color-interactive-stroke-primary-focus);
      box-shadow: none;
    }

    .uppy-StatusBar-content {
      font-size: 0.875rem;
    }
  `,
};

export const DashboardUploader: React.FunctionComponent<Props> = (props) => (
  <div css={styles.cdsOverrides}>
    <DashboardUploaderBaseComponent {...props} />
  </div>
);

export default DashboardUploader;
