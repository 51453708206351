import { useMemo } from 'react';

import type { ToolOptions } from 'bundles/cml/editor/components/toolbars/types';
import { getDefaultTools } from 'bundles/cml/editor/components/toolbars/utils';
import type { ToolsKeys } from 'bundles/cml/shared/utils/customTools';

type Options = ToolOptions & {
  customTools?: ToolsKeys[];
};

const getFilteredTools = (tools: ToolsKeys[], { enableAiWritingAssistant }: ToolOptions) => {
  return tools.filter((tool: ToolsKeys) => {
    if (tool === 'AI' && !enableAiWritingAssistant) {
      return false;
    }

    return true;
  });
};

export const useCustomTools = ({
  monospace,
  pageless,
  isLearnerUpload,
  customTools,
  enableWidgets,
  enableAiWritingAssistant,
}: Options) => {
  return useMemo(() => {
    const options: ToolOptions = {
      monospace,
      pageless,
      isLearnerUpload,
      enableWidgets,
      enableAiWritingAssistant,
    };

    const tools = getFilteredTools(customTools || getDefaultTools(options), options);

    return { tools, options };
  }, [monospace, pageless, isLearnerUpload, enableWidgets, enableAiWritingAssistant, customTools]);
};
