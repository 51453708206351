// Combines `Array#map` and `Array#filter(_ != null)` into one step.
export default function mapExists<TElement, TResult>(
  array: TElement[],
  func: (item: TElement, index: number, array: TElement[]) => TResult | undefined | null
) {
  let indexAfterEnd = 0;
  const result: TResult[] = new Array(array.length);
  for (let index = 0; index < array.length; index += 1) {
    const temp = func(array[index], index, array);
    if (temp != null) {
      result[indexAfterEnd] = temp;
      indexAfterEnd += 1;
    }
  }
  if (indexAfterEnd < result.length) {
    result.length = indexAfterEnd;
  }
  return result;
}
