import * as React from 'react';

import { Button, Dialog, Link, Typography2 } from '@coursera/cds-core';

import { Spacer } from 'bundles/enterprise-ui/components/Spacer';

import _t from 'i18n!nls/enterprise-ui';

const { HeadingGroup, Content, Actions } = Dialog;

export const UnauthorizedApiCallDialog: React.VFC<{ open: boolean; api?: string; onClose: () => void }> = ({
  open,
  api,
  onClose,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <HeadingGroup>{_t('You are not authorized to use this api')}</HeadingGroup>
      <Content>
        <Typography2 component="p">
          {_t(
            'As a power user you do not automatically get access to all new apis. Please click on the link below and manually add permissions required for your role and then reload this page'
          )}
        </Typography2>
        <Spacer size={16} />
        <Link href="/internal/employees/power-users/roles" target="_blank">
          {_t('Power user roles')}
        </Link>
        <Spacer size={16} />
        {api && (
          <Typography2 component="p" color="success">
            {api}
          </Typography2>
        )}
      </Content>
      <Actions>
        <Button variant="primary" onClick={onClose}>
          {_t('OK')}
        </Button>
      </Actions>
    </Dialog>
  );
};
