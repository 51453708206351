/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useContext } from 'react';

import { useTracker } from '@coursera/event-pulse/react';

import { CoachButton } from 'bundles/ai-coach-platform/components/building-blocks';
import { useSettingsNotification } from 'bundles/ai-coach-platform/components/patterns/chat/settings/useSettingsNotification';
import CoachStudioSettings from 'bundles/ai-coach-studio/components/CoachStudioSettings';
import { CoachStudioSettingsContext } from 'bundles/ai-coach-studio/components/CoachStudioSettingsContextProvider';
import { COACH_STUDIO_DEFAULT_PROMPT, COACH_STUDIO_SESSION_STORAGE_KEY } from 'bundles/ai-coach-studio/constants';
import type { StudioSettings } from 'bundles/ai-coach-studio/types';

import _t from 'i18n!nls/ai-coach-platform';

import SettingsPage from '../SettingsPage';

const styles = {
  root: css`
    height: 100%;
  `,
};

type Props = {
  onBack: () => void;
};

const getSettingsUserUpdated = (studioSettings: StudioSettings) => {
  const isModelUpdated = studioSettings.model !== 'gpt-4o';
  const isSystemPromptUpdated = studioSettings.systemPrompt !== COACH_STUDIO_DEFAULT_PROMPT;
  const isPageContextUpdated = !!studioSettings.pageContext;
  const isAdditionalContextUpdated = studioSettings.additionalContext.length > 0;
  const isContextFetchersUpdated = studioSettings.contextFetchers.length > 0;
  const isModelParametersUpdated =
    studioSettings.modelParameters.chatHistoryLimit !== 10 ||
    studioSettings.modelParameters.chatHistoryTokenLimit !== 500 ||
    studioSettings.modelParameters.totalTokenLimit !== 4000;

  const updatedSettings = [];
  if (isModelUpdated) {
    updatedSettings.push('studioModel');
  }
  if (isSystemPromptUpdated) {
    updatedSettings.push('studioSystemPrompt');
  }
  if (isPageContextUpdated) {
    updatedSettings.push('studioPageContext');
  }
  if (isAdditionalContextUpdated) {
    updatedSettings.push('studioAdditionalContext');
  }
  if (isContextFetchersUpdated) {
    updatedSettings.push('studioContextFetchers');
  }
  if (isModelParametersUpdated) {
    updatedSettings.push('studioModelParameters');
  }

  return updatedSettings;
};

export const SettingsStudio = (props: Props): React.ReactElement => {
  const { studioSettings, isSettingsFormValid } = useContext(CoachStudioSettingsContext);
  const { addNotification } = useSettingsNotification();
  const track = useTracker();
  const { onBack } = props;

  const handleBack = () => {
    onBack();
  };

  // Add settings to session storage so that they persist across page reloads
  const handleSave = () => {
    sessionStorage.setItem(COACH_STUDIO_SESSION_STORAGE_KEY, JSON.stringify(studioSettings));

    track('interact_coach_settings', {
      settingsInteractionType: 'save_studio_settings',
      settingsMetadata: {
        ids: getSettingsUserUpdated(studioSettings),
      },
      coach: {
        mode: 'STUDIO',
      },
    });

    addNotification({
      content: _t('Coach Studio settings saved to session storage'),
      severity: 'success',
      isTransient: true,
    });
  };

  return (
    <div className="coach-settings-studio" css={styles.root}>
      <SettingsPage
        onBack={handleBack}
        title={_t('Studio settings')}
        footer={
          <CoachButton aria-label={_t('Save')} onClick={handleSave} disabled={!isSettingsFormValid}>
            {_t('Save')}
          </CoachButton>
        }
      >
        <CoachStudioSettings />
      </SettingsPage>
    </div>
  );
};

export default SettingsStudio;
