import * as React from 'react';

type Props = {
  width?: number;
  height?: number;
};

const CertificateCongratsBlueSVG = ({ width = 48, height = 48 }: Props) => {
  return (
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41.75 20.3199C39.9053 23.6802 37.3544 26.6011 34.273 28.8815C31.1916 31.1619 27.6528 32.7477 23.9 33.5299L9.65 36.5299C9.08438 36.6472 8.49935 36.633 7.94009 36.4883C7.38083 36.3437 6.8623 36.0724 6.42452 35.6955C5.98674 35.3186 5.64143 34.8462 5.41524 34.3146C5.18905 33.7831 5.08804 33.2067 5.12 32.6299C5.40427 27.453 7.47874 22.5357 10.9884 18.7196C14.4981 14.9034 19.2249 12.4255 24.36 11.7099L34.82 10.2699C36.0744 10.0952 37.3526 10.2727 38.5119 10.7826C39.6712 11.2925 40.6659 12.1147 41.3848 13.1574C42.1038 14.2 42.5187 15.422 42.5832 16.6868C42.6477 17.9517 42.3592 19.2095 41.75 20.3199Z"
        fill="#D6EDF6"
      />
      <path
        d="M5.89001 11.2001L5.89001 36.8101H42.1V11.2001L5.89001 11.2001Z"
        stroke="black"
        strokeWidth="0.81"
        strokeLinejoin="round"
      />
      <path d="M10.67 21.0703H21.92" stroke="black" strokeWidth="0.81" strokeLinejoin="round" />
      <path d="M10.67 23.3804H21.92" stroke="black" strokeWidth="0.81" strokeLinejoin="round" />
      <path
        d="M34.98 28.6001V32.7801L32.18 31.0901L29.38 32.7801V28.6001"
        stroke="black"
        strokeWidth="0.81"
        strokeLinejoin="round"
      />
      <path d="M10.67 25.9004H21.92" stroke="black" strokeWidth="0.81" strokeLinejoin="round" />
      <path d="M10.67 28.4404H21.92" stroke="black" strokeWidth="0.81" strokeLinejoin="round" />
      <path
        d="M32.09 29.2702C35.6357 29.2702 38.51 26.3958 38.51 22.8502C38.51 19.3045 35.6357 16.4302 32.09 16.4302C28.5443 16.4302 25.67 19.3045 25.67 22.8502C25.67 26.3958 28.5443 29.2702 32.09 29.2702Z"
        stroke="black"
        strokeWidth="0.81"
        strokeLinejoin="round"
      />
      <path
        d="M32.09 27.16C34.4704 27.16 36.4 25.2304 36.4 22.85C36.4 20.4697 34.4704 18.54 32.09 18.54C29.7097 18.54 27.78 20.4697 27.78 22.85C27.78 25.2304 29.7097 27.16 32.09 27.16Z"
        stroke="black"
        strokeWidth="0.81"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CertificateCongratsBlueSVG;
