import * as React from 'react';
import { useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';

import user from 'js/lib/user';

import { DEFAULT_RELATED_SKILLS, DEFAULT_SKILLS } from 'bundles/program-personalized-tab/constants';
import OnboardingContext from 'bundles/program-personalized-tab/contexts/OnboardingContext';
import EnterpriseLearnerOnboarding from 'bundles/program-personalized-tab/queries/EnterpriseLearnerOnboarding.graphql';
import UpdateInterestedSkills from 'bundles/program-personalized-tab/queries/EnterpriseUpdateInterestedSkills.graphql';
import RelatedSkillsQuery from 'bundles/program-personalized-tab/queries/RelatedSkillsQuery.graphql';
import type {
  EnterpriseLearningOnboardingQuery as EnterpriseLearnerOnboardingQueryType,
  EnterpriseLearningOnboardingQueryVariables,
} from 'bundles/program-personalized-tab/queries/__generated__/EnterpriseLearnerOnboarding';
import type {
  UpdateInterestedSkillsMutation,
  UpdateInterestedSkillsMutationVariables,
} from 'bundles/program-personalized-tab/queries/__generated__/EnterpriseUpdateInterestedSkills';
import type {
  RelatedSkillsQueryQueryVariables,
  RelatedSkillsQueryQuery as RelatedSkillsQueryType,
} from 'bundles/program-personalized-tab/queries/__generated__/RelatedSkillsQuery';
import type { Skill } from 'bundles/program-personalized-tab/types/sharedTypes';

type PropsToComponent = {
  programId?: string;
  children: React.ReactNode;
};

const OnboardingDataProvider = ({ children, programId }: PropsToComponent) => {
  const userId = user.get().id;
  const [skillsLearnerInterestedIn, setSkillsLearnerInterestedIn] = useState<Skill[] | undefined>(undefined);

  const { data, loading, error } = useQuery<
    EnterpriseLearnerOnboardingQueryType,
    EnterpriseLearningOnboardingQueryVariables
  >(EnterpriseLearnerOnboarding, {
    variables: { userId: userId.toString() },
    context: { clientName: 'gatewayGql' },
    skip: !userId,
    onCompleted: (d) => {
      setSkillsLearnerInterestedIn(d.LearnerOnboarding?.queryResponseByUserId.skillsLearnerInterestedIn ?? []);
    },
  });

  const hasCompletedOnboarding = data?.LearnerOnboarding?.queryResponseByUserId?.isCompleted ?? true;
  const selectedRoleSkills = useMemo(
    () => data?.LearnerOnboarding?.queryResponseByUserId.occupationSkills ?? [],
    [data?.LearnerOnboarding?.queryResponseByUserId.occupationSkills]
  );
  const hasRoleSkills = Boolean(selectedRoleSkills.length);
  const skills = useMemo(() => (selectedRoleSkills.length ? selectedRoleSkills : DEFAULT_SKILLS), [selectedRoleSkills]);
  const onboardingRoleId = data?.LearnerOnboarding?.queryResponseByUserId?.occupationId;
  const onboardingGoals = data?.LearnerOnboarding?.queryResponseByUserId?.goals;
  const isUnsureOfRole = Boolean(data?.LearnerOnboarding?.queryResponseByUserId?.isUnsureOfRole);

  const {
    data: relatedSkillsData,
    loading: relatedSkillsQueryLoading,
    error: relatedSkillsQueryError,
  } = useQuery<RelatedSkillsQueryType, RelatedSkillsQueryQueryVariables>(RelatedSkillsQuery, {
    variables: { skillIds: skills.map((skill) => skill.id), programId },
    context: { clientName: 'gatewayGql' },
    skip: !(selectedRoleSkills && selectedRoleSkills.length),
  });

  const relatedSkills =
    selectedRoleSkills && selectedRoleSkills.length
      ? relatedSkillsData?.LearnerOnboarding?.queryRelatedSkills ?? []
      : DEFAULT_RELATED_SKILLS;

  const [updateInterestedSkills] = useMutation<UpdateInterestedSkillsMutation, UpdateInterestedSkillsMutationVariables>(
    UpdateInterestedSkills,
    {
      context: { clientName: 'gatewayGql' },
      onCompleted: (d) => {
        setSkillsLearnerInterestedIn(
          d.LearnerOnboarding_UpdateInterestedSkills?.learnerOnboarding.skillsLearnerInterestedIn ?? []
        );
      },
    }
  );

  return (
    <OnboardingContext.Provider
      value={{
        hasCompletedOnboarding,
        selectedRoleId: onboardingRoleId,
        isUnsureOfRole,
        hasRoleSkills,
        selectedGoals: onboardingGoals,
        skills,
        skillsLearnerInterestedIn,
        loading,
        error,
        relatedSkills,
        relatedSkillsQueryLoading,
        relatedSkillsQueryError,
        updateInterestedSkills,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export default OnboardingDataProvider;
