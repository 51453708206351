import type * as Types from '__generated__/graphql-types';

import type { ClipItem, ClipPartners, CollectionType } from 'bundles/enterprise-collections/components/CollectionItem';
import type { DiscoClipsCollectionItem, DiscoClipsCollections } from 'bundles/program-home/types/Products';

import _t from 'i18n!nls/program-home';

/* 
  Temp util function just for clips until we have a proper backend response
*/
export function zipClips<T>(...arrays: T[][]): T[] {
  const maxLength = Math.min(...arrays.map((arr) => arr.length));
  const zippedResult: T[] = [];

  for (let i = 0; i < maxLength; i += 1) {
    for (let j = 0; j < arrays.length; j += 1) {
      zippedResult.push(arrays[j][i]);
    }
  }

  return zippedResult;
}

export function mapPartners(
  partners: Types.Maybe<
    { __typename?: 'Partner_Partner' | undefined } & Pick<Types.Partner_Partner, 'name' | 'id' | 'logo'>
  >[]
): ClipPartners {
  return partners.map((discoPartner) => ({
    id: discoPartner?.id ?? '',
    squareLogo: discoPartner?.logo ?? '',
    name: discoPartner?.name ?? '',
  }));
}

export function discoClipToClipItem(discoClip: DiscoClipsCollectionItem): ClipItem {
  const courseSlug = discoClip?.url?.split('/')[2] ?? '';
  const videoId = discoClip?.url?.split('/')[3] ?? '';

  return {
    __typename: 'Clip_CourseItem',
    id: discoClip?.id ?? '',
    name: discoClip?.name ?? '',
    atomId: 'a1',
    duration: discoClip?.duration ?? '',
    views: discoClip?.views ?? 0,
    videoId: discoClip?.id ?? videoId,
    thumbnail: discoClip?.imageUrl ?? '',
    partners: mapPartners(discoClip?.partners ?? []),
    course: {
      __typename: 'Course_Course',
      id: 'unknown',
      slug: courseSlug,
      name: discoClip?.parentCourseName ?? '',
    },
  };
}
export function mergeAndMapSkillsClipsCollections(
  skillsClipsCollections: DiscoClipsCollections,
  translatedTitle: string,
  trackId: string
): CollectionType {
  const allClipItems = skillsClipsCollections?.reduce<ClipItem[][]>((acc, curr) => {
    acc.push((curr?.entities ?? []).map(discoClipToClipItem));
    return acc;
  }, []);

  const mergedClipItems = zipClips<ClipItem>(...(allClipItems ?? []));

  const collection: CollectionType = {
    id: trackId,
    title: translatedTitle,
    trackId,
    description: {},
    courses: null,
    s12ns: null,
    clips: mergedClipItems,
    items: mergedClipItems?.map((clipItem) => ({
      productId: clipItem.id,
      clipId: {
        courseId: clipItem.id.split('~')[0],
        itemId: clipItem.id.split('~')[1],
      },
    })),
  };
  return collection;
}

export function joinWithAnd(words?: string[]): string {
  const wordCount = words?.length;

  if (!wordCount || wordCount === 0) {
    return '';
  } else if (wordCount === 1) {
    return words[0];
  } else if (wordCount === 2) {
    return words.join(` ${_t('and')} `);
  } else {
    const allExceptLast = words.slice(0, -1).join(', ');
    return `${allExceptLast}, and ${words[wordCount - 1]}`;
  }
}
