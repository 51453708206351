import React from 'react';

import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';

import user from 'js/lib/user';

import type { ButtonProps } from '@coursera/coursera-ui';
import { Button } from '@coursera/coursera-ui';

import SpecializationProgressAndCourses from 'bundles/program-common/queries/SpecializationProgressAndCourses.graphql';
import type {
  SpecializationProgressAndCoursesQuery,
  SpecializationProgressAndCoursesQueryVariables,
} from 'bundles/program-common/queries/__generated__/SpecializationProgressAndCourses';
import { getCourseHomeLinkBySlug, getNextCourseSlug } from 'bundles/program-common/utils/courseAndS12nUtils';

import _t from 'i18n!nls/program-common';

type Props = ButtonProps & {
  s12nId: string;
};

const S12nStartNextCourseButton: React.FunctionComponent<Props> = ({
  s12nId,
  label = _t('Go to Course'),
  htmlAttributes = {},
  ...rest
}) => {
  const { loading, data, error } = useQuery<
    SpecializationProgressAndCoursesQuery,
    SpecializationProgressAndCoursesQueryVariables
  >(SpecializationProgressAndCourses, {
    variables: {
      userId: user.get().id,
      s12nId,
      s12nIdString: s12nId,
    },
    ssr: false,
    context: {
      clientName: 'gatewayGql',
    },
  });
  if (error) {
    Sentry.captureException(error);
    return null;
  }
  if (loading || !data?.Specialization?.queryById) {
    return null;
  }
  const s12n = data.Specialization.queryById;
  const s12nProgress = data?.OnDemandSpecializationProgress?.progress;
  const nextCourseSlug = getNextCourseSlug({ s12n, s12nProgress });
  const nextCourseLink = getCourseHomeLinkBySlug(nextCourseSlug);
  return (
    <Button
      type="primary"
      tag="a"
      size="md"
      label={label}
      htmlAttributes={{
        href: nextCourseLink,
        target: '_blank',
        rel: 'noopener noreferrer',
        ...htmlAttributes,
      }}
      {...rest}
    />
  );
};

// TODO(Audrey):convert to GraphQL https://coursera.atlassian.net/browse/UP-7587
export default S12nStartNextCourseButton;
