import { MAX_ONBOARDING_SKILLS_LENGTH } from 'bundles/program-personalized-tab/constants';
import type { Skill } from 'bundles/program-personalized-tab/types/sharedTypes';

export const getRequestSkillsParams = (skills: Skill[] | undefined) => {
  const skillsParams: { name: string; value: string }[] = [];

  if (skills?.length) {
    skills.slice(0, MAX_ONBOARDING_SKILLS_LENGTH).forEach((skill, i) => {
      skillsParams.push({ name: `collection${i + 1}.skillId`, value: skill.id });
      skillsParams.push({ name: `collection${i + 1}.skillName`, value: skill.name });
    });
  }

  return skillsParams;
};
