import { useQuery } from '@apollo/client';

import SkillScoresPercentilesQuery from 'bundles/program-home/utils/QuerySkillScoresPercentiles.graphql';
import type {
  QuerySkillScoresPercentilesQuery,
  QuerySkillScoresPercentilesQueryVariables,
} from 'bundles/program-home/utils/__generated__/QuerySkillScoresPercentiles';

type Props = {
  occupationId: string;
  skillScores: {
    skillId: string;
    score: number;
  }[];
  shouldSkipQuery?: boolean;
};

const MAX_AMOUNT_OF_SKILLS = 100;

const useSkillPercentileData = ({ occupationId, skillScores, shouldSkipQuery }: Props) => {
  const { loading, data, error } = useQuery<
    QuerySkillScoresPercentilesQuery,
    QuerySkillScoresPercentilesQueryVariables
  >(SkillScoresPercentilesQuery, {
    skip: !!shouldSkipQuery || !skillScores.length,
    variables: {
      occupationId,
      skillScores,
      first: MAX_AMOUNT_OF_SKILLS,
    },
    context: { clientName: 'gatewayGql' },
  });

  const skillPercentile =
    data?.SkillScores?.querySkillScoresPercentiles.edges.map(({ node }) => ({
      percentile: node.percentile,
      skillId: node.id,
    })) ?? [];

  return { loading, error, skillPercentile };
};

export default useSkillPercentileData;
