import type React from 'react';
import { forwardRef } from 'react';

import clsx from 'clsx';

import type { OverrideProps, OverridableComponent } from '@coursera/cds-common';
import { CheckIcon } from '@coursera/cds-icons';

import CheckboxCheckedIcon from '@core/forms/CheckboxInput/CheckboxCheckedIcon';
import CheckboxUncheckedIcon from '@core/forms/CheckboxInput/CheckboxUncheckedIcon';

import ItemBase from './ItemBase';
import type { BaseProps as ItemBaseProps } from './ItemBase';
import itemCss, { classes } from './styles/itemCss';

type BaseProps = (
  | {
      /**
       * Specify the role of the item based on the behavior you want.
       * Use `menuitemradio` when only one item can be selected.
       * Use `menuitemcheckbox` when multiple items can be selected.
       * @default menuitem
       */
      role?: 'menuitem';
    }
  | {
      /**
       * Specify the role of the item based on the behavior you want.
       * Use `menuitemradio` when only one item can be selected.
       * Use `menuitemcheckbox` when multiple items can be selected.
       */
      role: 'menuitemradio' | 'menuitemcheckbox';

      /**
       * If `true`, the corresponding selected style will be applied to the item based on the role of the item.
       *
       */
      checked?: boolean;
    }
) &
  ItemBaseProps;

export interface ItemTypeMap<D extends React.ElementType = 'button'> {
  props: BaseProps;
  defaultComponent: D;
}

export type Props<
  D extends React.ElementType = ItemTypeMap['defaultComponent']
> = OverrideProps<ItemTypeMap<D>, D> & {
  component?: React.ElementType;
};

const Item: OverridableComponent<ItemTypeMap> = forwardRef<
  HTMLButtonElement,
  Props
>(function Item(props, ref) {
  const {
    prefix,
    suffix,
    className,
    role = 'menuitem',
    disabled,
    // @ts-expect-error We need to extract it always, so it it's not passed to the sub-component
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    checked,
    ...rest
  } = props;

  // Use props directly so typescript infers type correctly
  const isChecked =
    props.role === 'menuitemradio' || props.role === 'menuitemcheckbox'
      ? Boolean(props.checked) // always cast it to boolean for aria-checked attribute
      : undefined;

  let prefixComponent = prefix;
  let suffixComponent = suffix;

  if (!prefixComponent && props.role === 'menuitemcheckbox') {
    if (props.checked) {
      prefixComponent = (
        <CheckboxCheckedIcon className={classes.checkboxIcon} />
      );
    } else {
      prefixComponent = (
        <CheckboxUncheckedIcon className={classes.checkboxIcon} />
      );
    }
  }

  if (!suffixComponent && props.role === 'menuitemradio' && isChecked) {
    suffixComponent = (
      <div css={{ padding: '0 var(--cds-spacing-50)', margin: 'auto 0' }}>
        <CheckIcon
          className={classes.checkboxIcon}
          color="interactive"
          size="medium"
        />
      </div>
    );
  }

  return (
    <ItemBase
      ref={ref}
      aria-checked={isChecked}
      className={clsx(className, {
        [classes.checked]: isChecked,
      })}
      component="li"
      css={itemCss}
      disabled={disabled}
      role={role}
      {...rest}
      prefix={prefixComponent}
      suffix={suffixComponent}
    />
  );
});

export default Item;
