import * as React from 'react';

import URI from 'jsuri';
import { compose, withProps } from 'recompose';

import connectToRouter from 'js/lib/connectToRouter';

import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { TrackedA } from 'bundles/page/components/TrackedLink2';
import { LINKEDIN_SOURCE_PARAM } from 'bundles/sharing-common/constants';
import type {
  SocialButtonPropsFromCaller,
  SocialButtonPropsToComponent,
} from 'bundles/sharing-common/types/sharingSharedTypes';
import withCustomUrl from 'bundles/sharing-common/utils/withCustomUrl';
import withFormattedShareLink from 'bundles/sharing-common/utils/withFormattedShareLink';

import _t from 'i18n!nls/sharing-common';

const BASE_LINKEDIN_SHARE_URL = 'https://www.linkedin.com/sharing/share-offsite';

class LinkedinButton extends React.Component<SocialButtonPropsToComponent> {
  render() {
    const { customShareLink, createSocialShareLink, targetShareLink, withLabel, onSocialButtonClick } = this.props;

    return (
      <TrackedDiv className="rc-LinkedinButton social-media-button" trackingName="social_share_button" trackMouseEnters>
        <TrackedA
          trackingName="linkedin_share_button"
          className="social-media-button_link"
          href={createSocialShareLink(customShareLink || targetShareLink)}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onSocialButtonClick}
          ariaLabel={_t('Share with LinkedIn')}
        >
          <svg
            className="social-media-button_icon"
            width="51"
            height="50"
            viewBox="0 0 51 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.727295 24.7208C0.727295 11.0678 11.7951 0 25.4481 0C39.101 0 50.1689 11.0678 50.1689 24.7208C50.1689 38.3737 39.101 49.4416 25.4481 49.4416C11.7951 49.4416 0.727295 38.3737 0.727295 24.7208ZM9.23634 41.4581H16.186V19.0979H9.23634V41.4581ZM12.7123 16.0413C10.4848 16.0413 8.6816 14.2356 8.6816 12.0111C8.68211 9.78863 10.4848 7.98294 12.7123 7.98294C14.9337 7.98294 16.7394 9.78863 16.7394 12.0111C16.7394 14.2361 14.9332 16.0413 12.7123 16.0413ZM35.2712 41.4581H42.2153V29.1935C42.2153 23.173 40.9164 18.542 33.8793 18.542C30.498 18.542 28.2321 20.3952 27.3039 22.1533H27.2104V19.0978H20.545V41.4576H27.4885V30.3963C27.4885 27.4799 28.0444 24.6555 31.6588 24.6555C35.2232 24.6555 35.2712 27.9907 35.2712 30.584V41.4581Z"
              fill="#2A73CC"
            />
          </svg>
          {withLabel && <p className="social-media-button_label">{_t('LinkedIn')}</p>}
        </TrackedA>
      </TrackedDiv>
    );
  }
}

export default compose<SocialButtonPropsToComponent, SocialButtonPropsFromCaller>(
  // order of these are important
  connectToRouter((router) => ({ router })),
  withFormattedShareLink(LINKEDIN_SOURCE_PARAM),
  withProps(() => {
    const createSocialShareLink = (shareLink: string) => {
      const linkedinButtonUrl = new URI(BASE_LINKEDIN_SHARE_URL);

      linkedinButtonUrl.addQueryParam('url', shareLink);

      return linkedinButtonUrl.toString();
    };

    return {
      createSocialShareLink,
    };
  }),
  withCustomUrl()
)(LinkedinButton);
