import { useQuery } from '@apollo/client';
import type { ApolloError } from '@apollo/client';

import GetSpecializationById from 'bundles/payments-common/api/GetSpecializationById.graphql';
import type {
  GetSpecializationByIdQuery,
  GetSpecializationByIdQueryVariables,
  SpecializationFieldsFragment,
} from 'bundles/payments-common/api/__generated__/GetSpecializationById';

export type SpecializationType = SpecializationFieldsFragment | undefined | null;

type PropsFromGetSpecialization = {
  s12n?: SpecializationType;
  loading: boolean;
  error?: ApolloError;
};

export const useGetSpecializationById = ({
  s12nId,
  skip,
}: {
  s12nId?: string;
  skip?: boolean;
}): PropsFromGetSpecialization => {
  const { loading, error, data } = useQuery<GetSpecializationByIdQuery, GetSpecializationByIdQueryVariables>(
    GetSpecializationById,
    {
      variables: { id: s12nId as string },
      context: { clientName: 'gatewayGql' },
      skip: !s12nId || skip,
    }
  );

  return { loading, error, s12n: data?.Specialization?.queryById };
};
