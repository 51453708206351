import * as React from 'react';
import { useEffect, useState } from 'react';

import redirect from 'js/lib/coursera.redirect';
import keysToConstants from 'js/lib/keysToConstants';

import LoadingIcon from 'bundles/courseraLoadingIcon/LoadingIcon';
import EnrollmentChoiceTypes from 'bundles/enroll-course/common/EnrollmentChoiceTypes';
import {
  choiceTypeToHandleSubmitPromise,
  submitEnrollmentPromise,
} from 'bundles/enroll-course/lib/enrollmentChoiceUtils';
import EnrollErrorModal from 'bundles/enroll/components/common/EnrollErrorModal';
import type { CourseEnrollModalDataContextType } from 'bundles/enroll/data/PageDataContext';
import usePageData from 'bundles/enroll/data/usePageData';
import type { ApiError } from 'bundles/enroll/utils/errorUtils';
import Modal from 'bundles/phoenix/components/Modal';
import { isPhoneOrSmaller } from 'bundles/phoenix/utils/matchMedia';
import { getCourseHomeLinkBySlug } from 'bundles/program-common/utils/courseAndS12nUtils';

import _t from 'i18n!nls/enroll';

import 'css!./__styles__/CourseraPlusEnrollModal';

const MODAL_TYPES = keysToConstants(['ENROLL', 'LOADING', 'ERROR']);

type PropsFromCaller = {
  courseId?: string;
  s12nId?: string;
  onClose: () => void;
};

const CourseraPlusEnrollModal: React.FC<PropsFromCaller> = ({ onClose }) => {
  const [activeModal, setActiveModal] = useState<keyof typeof MODAL_TYPES>(MODAL_TYPES.ENROLL);
  const [error, setError] = useState<ApiError | undefined>();

  // This `as CourseEnrollModalDataContextType` is actually not dangerous because all props exist in all page contexts
  // except `courseTypeMetadataWithVerson` which is still safely accessed with `?.`
  const { enrollmentAvailableChoices, course, courseTypeMetadataWithVersion, s12n } =
    usePageData() as CourseEnrollModalDataContextType;

  const { canEnrollThroughCourseraPlus, isSpecializationEnrolledThroughCourseraPlus } = enrollmentAvailableChoices;
  const courseId = course.id;
  const s12nId = s12n?.id;
  const courseSlug = course.slug;

  useEffect(() => {
    if (canEnrollThroughCourseraPlus) {
      const enrollmentChoiceType = EnrollmentChoiceTypes.ENROLL_THROUGH_COURSERA_PLUS;
      const handleSubmitPromise = choiceTypeToHandleSubmitPromise[enrollmentChoiceType];

      const options = {
        // Don't pass s12nId if already enrolled in s12n through C+, enroll into the course individually
        s12nId: isSpecializationEnrolledThroughCourseraPlus ? undefined : s12nId,
        courseId,
      };

      submitEnrollmentPromise({ handleSubmitPromise, options })
        .then((data: $TSFixMe /* TODO: type submitEnrollmentPromise */) => {
          // Redirects to course home of the selected course or the s12n's first course
          if (data.didEnroll && courseSlug) {
            redirect.setLocation(getCourseHomeLinkBySlug(courseSlug));
          } else {
            throw new Error('Did not complete enrollment');
          }
        })
        .catch((promiseError: Record<string, string>) => {
          setActiveModal(MODAL_TYPES.ERROR);
          setError(promiseError);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canEnrollThroughCourseraPlus, isSpecializationEnrolledThroughCourseraPlus, s12nId, courseId, courseSlug]);

  const isMobile = isPhoneOrSmaller();

  // `courseTypeMetadataWithVersion` is only not available in a SDP context, in which case it is always "course"
  // because only courses belong to a Specialization
  const modalMessage = _t('Taking you to #{courseType} home...', {
    courseType: courseTypeMetadataWithVersion?.courseLabel ?? _t('course'),
  });

  switch (activeModal) {
    case MODAL_TYPES.ENROLL:
      return (
        <Modal
          className="rc-CourseraPlusEnrollModal subscribed"
          trackingName="coursera_plus_enroll_modal"
          modalName={_t('Enrollment modal')}
          handleClose={onClose}
          shouldFocusOnXButton={!isMobile}
        >
          <div className="subscribed-container">
            <div>{modalMessage}</div>
            <LoadingIcon />
          </div>
        </Modal>
      );
    case MODAL_TYPES.ERROR:
      return <EnrollErrorModal error={error} onClose={onClose} isFinancialAid={false} isFreeEnroll={false} />;
    default:
      return null;
  }
};

export default CourseraPlusEnrollModal;
