import contentfulGql from 'js/lib/contentfulGql';

export const unifiedRolePageTemplateQuery = contentfulGql`#graphql
query UnifiedRolePageTemplateContent($slug: String!, $locale: String!, $preview: Boolean!) {
  unifiedCareerAcademyRdpTemplateCollection(preview: $preview, locale: $locale, where: { slug: $slug }, limit: 1) {
    items {
      ...UnifiedRolePageTemplateContentFields
    }
  }
}

fragment UnifiedRolePageTemplateContentFields on UnifiedCareerAcademyRdpTemplate {
  certificatesTitle
  certificatesSubtitle
  certificatesValuePropsCollection(limit: 3) {
    items {
      title
      subtitle
      iconName
    }
  }
  guidedProjectsTitle
  guidedProjectSubtitle
  guidedProjectsValuePropsCollection(limit: 3) {
    items {
      title
      subtitle
      iconName
    }
  }
}
`;

export const unifiedRolePageCareerContentQuery = contentfulGql`#graphql
query UnifiedRolePageCareerContent($slug: String!, $locale: String!, $preview: Boolean!) {
  unifiedCareerAcademyCareerCardCollection(preview: $preview, locale: $locale, where: { slug: $slug }, limit: 1) {
    items {
      ...UnifiedRolePageCareerContentFields
    }
  }
}

fragment UnifiedRolePageCareerContentFields on UnifiedCareerAcademyCareerCard {
  title
  slug
  programsCollection(limit: 10) {
    items {
      ...ProgramItem
    }
  }
  rdpHeroSubtitle
  rdpHeroValueProps
  rdpDescriptionText
  rdpDescriptionTitle
  rdpDescriptionSkills
  rdpDescriptionJobTitles
  rdpDescriptionFootnotes
  rdpHeroBackgroundImage {
    url
  }
  rdpHeroCareerStats1Icon
  rdpHeroCareerStats1Description
  rdpHeroCareerStats2Icon
  rdpHeroCareerStats2Description
  beginnerArticle {
    slug
     title
     intro
     featuredImage {       
       url
       description
     }
     sys {
      publishedAt
     }
     localizedCountryCode
   }
   advancedArticle {
     slug
     title
     intro
     featuredImage {       
       url
       description
     }
     sys {
      publishedAt
     }
     localizedCountryCode
   }
}

fragment ProgramItem on DegreesHubDegreeMarketingInformation {
  slug
  programName
  programId
  programType
  localizations
  partnerInformationCollection(limit: 1) {
    items {
      partnerLogo {
        url
        description
      }
      partnerName
      partnerSlug
    }
  }
}
`;

export const unifiedCareerAcademyPageContentQuery = contentfulGql`#graphql
query UnifiedCareerAcademyPageContent($slug: String!, $locale: String!, $preview: Boolean!) {
  unifiedCareerAcademyPageContentCollection(preview: $preview, locale: $locale, where: { slug: $slug }, limit: 1) {
    items {
      ...UnifiedCareerAcademyPageContentFields
    }
  }
}

fragment UnifiedCareerAcademyPageContentFields on UnifiedCareerAcademyPageContent {
  ...Hero
  ...ValueProps
  ...Careers
  ...Testimonials
  ...About
  ...Recommendations
  ...Faqs
}

fragment Hero on UnifiedCareerAcademyPageContent {
  heroTitle
  heroSubtitle
  heroValueProps
  heroCtaText
  heroCtaLink
  heroBackgroundImage {
    url
    description
  }
}

fragment ValueProps on UnifiedCareerAcademyPageContent {
  valuePropTitle
  valuePropSubtitle
  valuePropPartnerLogosCollection(limit: 10) {
    items {
      sys {
        id
      }
      title
      image {
        url
      }
      imageAltText
    }
  }
  valuePropValuesCollection(limit: 4) {
    items {
      title
      subtitle
      description
      iconName
    }
  }
}

fragment Careers on UnifiedCareerAcademyPageContent {
  careersTitle
  careersSubtitle
  careersCardsCollection(limit: 100) {
    items {
      title
      slug
      description
      skills
      image {
        url
        description
      }
      careerStats
      programsCollection(limit: 15) {
        items {
          slug
          programName
          localizations
          partnerInformationCollection(limit: 1) {
            items {
              partnerLogo {
                url
                description
              }
              partnerSlug
            }
          }
        }
      }
      jobCategory
    }
  }
  careersFootnotes
}

fragment Testimonials on UnifiedCareerAcademyPageContent {
  testimonialsTitle
  testimonialsSubtitle
  testimonialsUserTestimonialCollection(limit: 10) {
    items {
      videoPlaceholderImage {
        url
        description
      }
      videoEmbedUrl
      authorName
      authorTitle
      authorOrganization
      image {
        url
      }
      text
    }
  }
}

fragment About on UnifiedCareerAcademyPageContent {
  aboutTitle
  aboutSubtitle
  aboutValueProps
  aboutImage {
    url
    description
  }
}

fragment Recommendations on UnifiedCareerAcademyPageContent {
  recommendationsCollectionSetId
  recommendationsCollectionSetType
}

fragment Faqs on UnifiedCareerAcademyPageContent {
  faqsCollection(limit: 20) {
    items {
      question
      answer
    }
  }
}
`;
