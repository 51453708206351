/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

const styles = {
  root: css`
    font-size: 0;
    line-height: 0;
  `,
};

const NON_BREAKING_SPACE = String.fromCodePoint(160);

// Put this at the start and end of an inline element to work around not being
// to edit the start of an inline in Chromium:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405

const InlineSpacer: React.FC = () => (
  <span contentEditable={false} css={styles.root}>
    {NON_BREAKING_SPACE}
  </span>
);

export default InlineSpacer;
