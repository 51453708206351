import gql from 'graphql-tag';

import type {
  EnterpriseProgramsV1SlugQuery as EnterpriseProgramsV1SlugQueryResult,
  EnterpriseProgramsV1SlugQueryVariables,
} from 'bundles/naptimejs/resources/__generated__/EnterpriseProgramsV1';

export type EnterpriseProgramBySlugQueryResult = EnterpriseProgramsV1SlugQueryResult;

export type EnterpriseProgramBySlugQueryVariables = {
  programSlug: EnterpriseProgramsV1SlugQueryVariables['slug'];
};

const EnterpriseProgramBySlugQuery = gql`
  query ProgramBySlug($programSlug: String!) {
    EnterpriseProgramsV1 @naptime {
      slug(
        slug: $programSlug
        eventualConsistency: true
        includes: "browsingExperience, thirdPartyOrg, contractTags, contract, curriculum"
      ) {
        elements {
          id
          thirdPartyOrganizationId
          metadata {
            name
            slug
            tagline
            endedAt
            landingPageBanner
            tutorialVideoUrl
            tutorialVideoAssetId
            curriculumId
            linkedTargetSkillProfileIds
            linkedAcademicDisciplineIds
            primaryColor
            skillSetAvailabilityType
          }
        }
        linked {
          programBrowsingExperiencesV1 {
            id
            browsingExperience
            numCollections
          }
          thirdPartyOrganizationsV1 {
            id
            name
            slug
            rectangularLogo
            squareLogo
            primaryColor
            landingPageBanner
            loginMethod
            programVisibilityRule
            organizationType
          }
          enterpriseContractTagsV1 {
            contractTag
          }
          enterpriseContractsV1 {
            enterpriseContractId
            contractType
            contractState
            hardPausedAt
          }
        }
      }
    }
  }
`;

export default EnterpriseProgramBySlugQuery;
