import { startCase } from 'lodash';

import {
  DOMAIN_PAGE_SEARCH_FILTERS,
  NUMBER_OF_RESULTS_PER_PAGE,
  SEARCH_FILTERS,
} from 'bundles/enterprise-learner-search/constants';
import type { SearchConfig, SearchContext } from 'bundles/search-common/providers/searchTypes';

type options = {
  searchContext?: SearchContext;
  shouldEnableShortFormContent?: boolean;
  facets?: string[];
  facetFilters?: string[][];
  shouldSyncFiltersWithUrl?: boolean;
};

export const FacetFiltersForExcludingSFC = [
  ['productTypeDescription:-Short Form Content Video'],
  ['productTypeDescription:-Short Form Content Lesson'],
];

const enterpriseIndexConfig = (options: options = {}): SearchConfig[] => {
  const { searchContext, shouldEnableShortFormContent, facets = SEARCH_FILTERS } = options;
  const facetFilters = options.facetFilters ?? (shouldEnableShortFormContent ? undefined : FacetFiltersForExcludingSFC);
  const shouldSyncFiltersWithUrl = options.shouldSyncFiltersWithUrl ?? true;

  return [
    {
      id: 'search-results-all',
      entityType: 'PRODUCTS',
      searchContext,
      limit: NUMBER_OF_RESULTS_PER_PAGE,
      facets,
      facetFilters,
      syncQueryWithUrl: true,
      syncFiltersWithUrl: !!shouldSyncFiltersWithUrl,
      sortBy: 'BEST_MATCH',
    },
    {
      id: 'search-results-new',
      entityType: 'PRODUCTS',
      searchContext,
      limit: NUMBER_OF_RESULTS_PER_PAGE,
      facets,
      facetFilters,
      syncQueryWithUrl: true,
      syncFiltersWithUrl: !!shouldSyncFiltersWithUrl,

      sortBy: 'NEW',
    },
    // For Proper filtering keep main search page index as the first index and the search-filters index as the last index
    {
      id: 'search-filters',
      entityType: 'PRODUCTS',
      searchContext,
      limit: NUMBER_OF_RESULTS_PER_PAGE,
      facets,
      facetFilters,
      syncQueryWithUrl: true,
    },
  ];
};

type domainOptions = options & { domainId?: string; subdomainId?: string };

export const enterpriseDomainsIndexConfig = (options: domainOptions = {}): SearchConfig[] => {
  const { searchContext, shouldEnableShortFormContent, domainId, subdomainId } = options;
  const facetFilters = shouldEnableShortFormContent ? [] : [...FacetFiltersForExcludingSFC];
  if (domainId) facetFilters.push([`topic:${startCase(domainId)}`]);
  if (subdomainId && !subdomainId.includes('all-')) facetFilters.push([`subtopic:${startCase(subdomainId)}`]);

  return [
    {
      id: 'search-results-all',
      entityType: 'PRODUCTS',
      searchContext,
      limit: 10,
      facets: DOMAIN_PAGE_SEARCH_FILTERS,
      facetFilters,
    },
    // For Proper filtering keep main search page index as the first index and the search-filters index as the last index
    {
      id: 'search-filters',
      entityType: 'PRODUCTS',
      searchContext,
      limit: 10,
      facets: DOMAIN_PAGE_SEARCH_FILTERS,
    },
  ];
};

export default enterpriseIndexConfig;
