/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import type { Interpolation } from '@emotion/react';

import { useContext } from 'react';

import { languageCodeToName } from 'js/lib/language';

import type { Theme } from '@coursera/cds-core';
import { Typography2 } from '@coursera/cds-core';
import { TranslationIcon } from '@coursera/cds-icons';
import type { PageSection, Product, SectionName } from '@coursera/event-pulse-types';
import { Button } from '@coursera/event-pulse/react';

import { TranslationButtonNames } from 'bundles/ondemand-translation/common/eventing';
import { TranslationContext } from 'bundles/ondemand-translation/components/TranslationContextProvider';

import _t from 'i18n!nls/ondemand-translation';

const styles = {
  viewOriginalButtonContainer: () => css`
    display: flex;
    align-items: center;
  `,
  viewOriginalLabelContainer: () => css`
    display: flex;
    align-items: center;
  `,
  viewOriginalLanguageLabel: () => css`
    margin-left: 8px;
  `,
};

type Props = {
  cssOverride?: Interpolation<Theme>;
  courseId?: string;
  eventingSectionName?: SectionName;
};

/**
 * This button is very similar to the static/bundles/peer/components/translation/TranslateSubmissionButton.tsx.
 *
 * Differences are:
 * 1. The default button state shows 'Translate to #{languageName}' where the language name is the user language.
 * 2. The labels of the button have normal weight instead of being bold.
 * 3. Allows applying CSS override to the outermost returned element. See the `cssOverride` property.
 */
export const TranslateContentButton = ({ cssOverride, courseId, eventingSectionName }: Props) => {
  const context = useContext(TranslationContext);

  if (context === undefined) {
    throw new Error('Missing TranslationContext. Make sure your component is nested under TranslationContextProvider.');
  }

  const { showTranslatedContent, setShowTranslatedContent, languageDetector, progressTracker } = context;

  const toggleShowTranslatedContent = () => {
    setShowTranslatedContent(!showTranslatedContent);
  };

  // Do not show the button if there isn't language mismatch.
  if (!languageDetector.isLanguageMismatch) {
    return null;
  }

  const userLanguageName = languageCodeToName(languageDetector.userLanguageCode);

  const eventingProduct = courseId ? { product: { id: courseId, type: 'course' } as Product } : {};
  const eventingPageSection = eventingSectionName
    ? { pageSection: { sectionName: eventingSectionName } as PageSection }
    : {};

  const translateButtonTrackingData = {
    button: {
      name: TranslationButtonNames.TRANSLATION_TRANSLATE,
    },
    ...eventingProduct,
    ...eventingPageSection,
  };

  const showOriginalButtonTrackingData = {
    button: {
      name: TranslationButtonNames.TRANSLATION_SHOW_ORIGINAL,
    },
    ...eventingProduct,
    ...eventingPageSection,
  };

  if (!showTranslatedContent) {
    return (
      <Button
        onClick={toggleShowTranslatedContent}
        variant="ghost"
        size="small"
        icon={<TranslationIcon size="small" />}
        iconPosition="before"
        edgeAlign="start"
        css={cssOverride}
        trackingData={translateButtonTrackingData}
      >
        {_t('Translate to #{languageName}', { languageName: userLanguageName })}
      </Button>
    );
  } else if (progressTracker.hasError || progressTracker.isLoading) {
    return (
      <Button
        onClick={progressTracker.refetchFailedQueries}
        variant="ghost"
        size="small"
        icon={<TranslationIcon size="small" />}
        iconPosition="before"
        edgeAlign="start"
        css={cssOverride}
        loading={progressTracker.isLoading}
        trackingData={translateButtonTrackingData}
      >
        {_t('Translate to #{languageName}', { languageName: userLanguageName })}
      </Button>
    );
  } else if (languageDetector.detectedLanguageCode) {
    const detectedLanguageName = languageCodeToName(languageDetector.detectedLanguageCode);
    return (
      <div css={[styles.viewOriginalButtonContainer, cssOverride]}>
        <div css={styles.viewOriginalLabelContainer}>
          <TranslationIcon color="support" size="small" />
          <Typography2 component="p" css={styles.viewOriginalLanguageLabel} variant="bodySecondary" color="supportText">
            {_t('Translated from #{languageName}', { languageName: detectedLanguageName })}
          </Typography2>
        </div>
        <Button
          onClick={toggleShowTranslatedContent}
          variant="ghost"
          size="small"
          trackingData={showOriginalButtonTrackingData}
        >
          {_t('View original')}
        </Button>
      </div>
    );
  } else {
    return (
      <Button
        onClick={toggleShowTranslatedContent}
        variant="ghost"
        size="small"
        icon={<TranslationIcon size="small" />}
        iconPosition="before"
        edgeAlign="start"
        css={cssOverride}
        trackingData={showOriginalButtonTrackingData}
      >
        {_t('View original')}
      </Button>
    );
  }
};
