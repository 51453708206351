/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Dashboard, DashboardModal } from '@uppy/react';
import type { DashboardProps as UppyDashboardProps } from '@uppy/react/src/Dashboard';

import type {
  PropsToUppyAssetUploader,
  RenderProps,
  UppyEventHandlers,
} from 'bundles/asset-admin/components/UppyAssetUploader';
import UppyAssetUploader from 'bundles/asset-admin/components/UppyAssetUploader';
import { DEFAULT_PLUGIN_SOURCES } from 'bundles/asset-admin/constants/AssetUpload';
import type { PluginSourcesType } from 'bundles/asset-admin/constants/AssetUpload';
import type { UppyRef } from 'bundles/asset-admin/types/uppy';
import AssetAdminTracking from 'bundles/asset-admin/utils/AssetAdminTracking';
import { mapAssetTypeToMimes } from 'bundles/asset-admin/utils/AssetUploaderUtils';
import type { Assembly, TransloaditFileMap } from 'bundles/authoring/common/types/Upload';

import _t from 'i18n!nls/asset-admin';

// eslint-disable-next-line no-restricted-syntax, import/extensions
import 'css!@uppy/core/dist/style.css';
// eslint-disable-next-line no-restricted-syntax, import/extensions
import 'css!@uppy/dashboard/dist/style.css';
// eslint-disable-next-line no-restricted-syntax, import/extensions
import 'css!@uppy/url/dist/style.css';

// TODO: When updating the uppy version, we will need to test that these styles are applied as expected.
const styles = {
  hideExtraFeatures: css`
    .uppy-DashboardContent-bar button:not(.uppy-c-btn-primary),
    .uppy-StatusBar button:not(.uppy-c-btn-primary) {
      display: none;
    }
  `,

  dashboardUploader: css`
    .uppy-size--md .uppy-Dashboard-AddFiles-list {
      max-width: 90%;
    }

    .uppy-Dashboard {
      font-family: inherit;
      display: flex;
      justify-content: center;
      align-items: center;

      .uppy-Dashboard-innerWrap > .uppy-Dashboard-AddFiles {
        border: none;
      }

      .uppy-Dashboard-close {
        display: none;
      }

      .uppy-Dashboard-inner {
        background: var(--cds-color-neutral-primary-invert);
        border: 1px var(--cds-color-interactive-primary) dashed;
        border-radius: 0;

        .uppy-c-btn {
          border-radius: 0;
        }

        .uppy-c-btn-primary {
          background-color: var(--cds-color-interactive-primary);

          &:hover {
            background-color: var(--cds-color-interactive-primary-hover);
          }
        }
      }

      .uppy-Dashboard-dropFilesTitle,
      .uppy-Dashboard-AddFiles-title {
        font-size: 24px;
        line-height: 28px;
        color: var(--cds-color-neutral-primary);
        max-width: 420px;
        margin-bottom: var(--cds-spacing-400);
      }

      .uppy-DashboardTab {
        margin: 0 8px;

        .uppy-DashboardTab-btn {
          width: 92px;
        }

        svg {
          width: 48px;
          height: 48px;
        }

        .uppy-DashboardTab-name {
          color: var(--cds-color-neutral-primary);
          font-size: 14px;
          line-height: 24px;
        }
      }

      .uppy-Url {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .uppy-Url-input {
          width: 90%;
          max-width: 650px;
          margin-bottom: var(--cds-spacing-200);
        }
      }
    }
  `,
};

/*
 * Wrapper component around the Uppy Dashboard (https://uppy.io/docs/dashboard/) file uploader
 * It declares event handlers to both prepare files for uploading after they have been selected
 * and to save assets after their transloadit (https://transloadit.com/docs/) assemblies have completed.
 *
 * We override the proptypes here https://github.com/transloadit/uppy/blob/main/packages/%40uppy/react/src/propTypes.js#L2 which is referencing the child dependency of @uppy/react (because of the version resolution we enforce) rather than the sibling @uppy/core we instantiate from (so the instanceOf check fails).
 */

export type Props = Omit<PropsToUppyAssetUploader, 'uppyProps'> & {
  autoProceed?: boolean;
  // null = do not use plugin sources
  pluginSources?: Array<PluginSourcesType> | null;
  inline?: boolean;
  open?: boolean;
  hideUploadButton?: boolean;
  hideExtraFeatures?: boolean;
} & Partial<UppyEventHandlers> &
  Partial<Pick<UppyDashboardProps, 'uppy' | 'height' | 'width'>>;

export default class DashboardUploader extends React.Component<Props> {
  static defaultProps = {
    onUploadStart: (files: TransloaditFileMap) => files,
    onUploadComplete: (assembly: Assembly) => assembly,
    onUploadError: (assembly: Assembly) => assembly,
    onAssetCreationComplete: (assetIds: string[]) => assetIds,
    onAssetCreationError: (error: Error) => error,
  };

  componentDidMount() {
    const { uppyRef } = this.props;

    if (uppyRef) {
      uppyRef.current = this.uppyRef.current;
    }
  }

  handleUploadError = (assembly: Assembly, error: Error | undefined | null): void => {
    const { onUploadError, assetType } = this.props;

    if (onUploadError) {
      onUploadError(assembly, error);
    }

    AssetAdminTracking.track('upload', 'failed', { assetType });
  };

  handleAssetCreationComplete = (assetIds: Array<string>, assembly: Assembly): void | Promise<void> => {
    const { onAssetCreationComplete } = this.props;

    if (onAssetCreationComplete) {
      return onAssetCreationComplete(assetIds, assembly);
    }

    return undefined;
  };

  getLocale = () => {
    const { locale, allowedFileTypes, assetType } = this.props;
    const fileTypes = (allowedFileTypes || (assetType && mapAssetTypeToMimes(assetType)) || []).join(', ');
    const acceptedFileTypes =
      fileTypes.length > 0 ? _t('The following file types are accepted:  #{fileTypes}', { fileTypes }) : '';

    return {
      strings: {
        youCanOnlyUploadFileTypes: _t('Unsupported file type. #{acceptedFileTypes}', {
          acceptedFileTypes,
        }),
        ...locale?.strings,
      },
    };
  };

  uppyRef: UppyRef = { current: undefined };

  render() {
    const {
      courseId,
      assetContext,
      templateId,
      uploadToAssetService,
      uploadPaused,
      allowMultiple,
      allowedFileTypes,
      assetType,
      autoProceed,
      inline,
      open,
      height,
      width,
      onFileAdded,
      onFilesReadyForUpload,
      onUploadStart,
      onUploadProgress,
      onUploadSuccess,
      onAssetCreationError,
      onAssetCreationComplete,
      onFileUploadComplete,
      onUploadsComplete,
      pluginSources = DEFAULT_PLUGIN_SOURCES,
      assetCreationUrl,
      showPostAssemblyLoader,
      onAssemblyComplete,
      hideUploadButton,
      hideExtraFeatures = false,
      transloaditPluginFields,
    } = this.props;

    const DashboardComponent = inline ? Dashboard : DashboardModal;

    return (
      <div
        className="rc-DashboardUploader"
        css={[styles.dashboardUploader, hideExtraFeatures && styles.hideExtraFeatures]}
      >
        <UppyAssetUploader
          courseId={courseId}
          assetContext={assetContext}
          assetCreationUrl={assetCreationUrl}
          uploadToAssetService={uploadToAssetService}
          uploadPaused={uploadPaused}
          templateId={templateId}
          uppyProps={{ autoProceed }}
          allowMultiple={allowMultiple}
          allowedFileTypes={allowedFileTypes}
          assetType={assetType}
          onFileAdded={onFileAdded}
          onFilesReadyForUpload={onFilesReadyForUpload}
          onUploadStart={onUploadStart}
          onUploadProgress={onUploadProgress}
          onUploadError={this.handleUploadError}
          onUploadSuccess={onUploadSuccess}
          onAssetCreationError={onAssetCreationError}
          onAssetCreationComplete={onAssetCreationComplete}
          onFileUploadComplete={onFileUploadComplete}
          onUploadsComplete={onUploadsComplete}
          uppyRef={this.uppyRef}
          showPostAssemblyLoader={showPostAssemblyLoader}
          transloaditPluginFields={transloaditPluginFields}
          onAssemblyComplete={onAssemblyComplete}
          locale={this.getLocale()}
          render={({ uppy }: RenderProps) => (
            <div className="uppy-wrapper">
              <DashboardComponent
                uppy={uppy}
                height={height ?? 400}
                width={width ?? 786}
                open={open ?? true}
                plugins={pluginSources === null ? undefined : pluginSources}
                showLinkToFileUploadResult={!hideExtraFeatures}
                showProgressDetails={!hideExtraFeatures}
                hideRetryButton={hideExtraFeatures}
                hidePauseResumeButton={hideExtraFeatures}
                hideCancelButton={hideExtraFeatures}
                {...(hideUploadButton ? { hideUploadButton } : {})}
              />
            </div>
          )}
        />
      </div>
    );
  }
}
