import * as React from 'react';

import { useQuery } from '@apollo/client/react';
import gql from 'graphql-tag';
import { times } from 'lodash';

import { ProductCard as CdsProductCard, breakpoints, useMediaQuery } from '@coursera/cds-core';
import { Col, Divider, Row } from '@coursera/coursera-ui';

import { getProductCardDisplayProps } from 'bundles/browse/utils';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import type ProgramCurriculumProductsV1 from 'bundles/naptimejs/resources/programCurriculumProducts.v1';
import { PRODUCT_TYPE } from 'bundles/program-common/components/ProductCard';
import { ProductCardCds } from 'bundles/program-common/components/ProductCardCds';
import ProductCoursesQuery from 'bundles/program-common/queries/ProductCoursesQuery.graphql';
import type {
  ProductCardCourseFragmentFragment as CourseProduct,
  ProductCoursesQuery as ProductCoursesQueryType,
  ProductCoursesQueryVariables,
} from 'bundles/program-common/queries/__generated__/ProductCoursesQuery';
import type {
  EnterpriseProductMetadataConfiguration,
  Product,
  ProductType,
} from 'bundles/program-common/types/programCommon';
import { isProjectOrSelfPacedProject } from 'bundles/program-common/utils/courseUtils';
import filterExistsOrDefault from 'bundles/program-common/utils/filterExistsOrDefault';
import { getProductMetadata } from 'bundles/program-common/utils/programCommonUtils';
import { Heading, Section } from 'bundles/program-home/components/AutoHeading';
import type {
  ProgramProductMetadataQuery as ProgramProductMetadataQueryData,
  ProgramProductMetadataQueryVariables,
} from 'bundles/program-home/components/available/__generated__/ProgramProductMetadataQuery';
import type { AdminProgramSearchPrivateCourseMetadata } from 'bundles/program-home/constants/ProgramProductTypes';
import ProductsContainerS12nQuery from 'bundles/program-home/queries/ProductsContainerS12nQuery.graphql';
import type {
  SavedContainerS12n_ProductCardS12nFragment as ProductsCardSpecialization,
  SavedContainerS12nQuery as ProductsContainerS12nQueryType,
  SavedContainerS12nQueryVariables as ProductsContainerS12nQueryVariablesType,
} from 'bundles/program-home/queries/__generated__/SavedContainerS12nQuery';
import mapExists from 'bundles/program-home/utils/mapExists';

import _t from 'i18n!nls/program-home';

import 'css!./__styles__/ProductsListItem';

type ProductStates = Record<string, ProgramCurriculumProductsV1 | undefined>;

export type CourseMeta = {
  [id: string]: AdminProgramSearchPrivateCourseMetadata;
};

export type Props = {
  onProductCardClick?: (product: Product, productType: ProductType) => void;
  products?: Array<ProgramCurriculumProductsV1>;
  displayLoading?: boolean;
  programId?: string;
  enableCurriculumBuilder?: boolean;
  allowOrgForSpecializationConfiguration?: boolean;

  displayCount?: number;
  displayOrderIds?: Array<string>;
};

function mapS12nToProduct(s12ns: Array<ProductsCardSpecialization>): Array<Product> {
  return s12ns.map((s12n) => ({
    ...s12n,
    partners: {
      elements: s12n.partners,
    },
    courseIds: s12n.courses.map((course) => course.id),
  }));
}

function getProductsToShow(
  courses: Array<CourseProduct>,
  s12ns: Array<ProductsCardSpecialization>,
  displayCount?: number,
  displayOrderIds?: Array<string>
): Array<Product> {
  const s12nProduct: Array<Product> = mapS12nToProduct(s12ns);
  const correctedCourses = courses.map((course) => ({
    ...course,
    courseTypeMetadata: {
      courseTypeMetadata: {
        __typename: course?.courseTypeMetadata?.metadata.__typename || '',
      },
    },
    partners: {
      elements: course.partners,
    },
  }));
  // If no display order provided, return merged product lists
  if (!displayOrderIds) {
    const products: Product[] = [...s12nProduct, ...correctedCourses];
    return displayCount === undefined ? products : products.slice(0, displayCount);
  }

  // Map each product to its id
  const productsObj: Record<string, Product> = {};

  s12nProduct.forEach((s12n) => {
    productsObj[s12n.id] = s12n;
  });

  correctedCourses.forEach((course) => {
    productsObj[course.id] = course;
  });

  // Iterate through productStates and construct ordered list
  const products = mapExists(displayOrderIds, (id) => productsObj[id]);
  return products;
}

// TODO: This framgment is duplicated from `SingleProgramGraphqlQueries.ts`.
// Since that file contains some graphql gateway queries that should have been moved to .graphql files,
// `yarn graphql:types` won't run on that file (error) and thus the fragment types won't be generated.
// Once `SingleProgramGraphqlQueries.ts` is correctly refactored, we can revise if this duplicated needs to be removed or kept.
export const SingleProgramProductMetadataFragment = gql`
  fragment SingleProgramProductMetadataFragment on ProgramProductMetadataV1 {
    id
    metadataType {
      ... on ProgramProductMetadataV1_courseMember {
        course {
          isExclusive
          isSelectedForCredit
          courseId
        }
      }

      ... on ProgramProductMetadataV1_specializationMember @include(if: $allowOrgForSpecializationConfiguration) {
        specialization {
          isSelectedForCredit
          s12nId
          associatedSessionId
        }
      }
    }
  }
`;

// allowOrgForSpecializationConfiguration is used inside the fragment
export const ProgramProductMetadataQuery = gql`
  query ProgramProductMetadataQuery($ids: [String!]!, $allowOrgForSpecializationConfiguration: Boolean!) {
    ProgramProductMetadataV1Resource {
      multiGet(ids: $ids) {
        elements {
          ...SingleProgramProductMetadataFragment
        }
      }
    }
  }
  ${SingleProgramProductMetadataFragment}
`;

export const ProductsListItem: React.FC<Props> = ({
  displayCount,
  products = [],
  onProductCardClick: onProductCardClick0,
  displayLoading,
  programId,
  enableCurriculumBuilder,
  allowOrgForSpecializationConfiguration = false,
}) => {
  const productStateObj: ProductStates = {};
  const s12nIds: string[] = [];
  const courseIds: string[] = [];
  const displayOrderIds: string[] = [];

  // Map each productState to its id and add id to corresponding list
  products.forEach((state) => {
    if (state.isS12n && state.s12nId) {
      s12nIds.push(state.s12nId);
      displayOrderIds.push(state.s12nId);
      productStateObj[state.s12nId] = state;
    } else if (state.isCourse && state.courseId) {
      courseIds.push(state.courseId);
      displayOrderIds.push(state.courseId);
      productStateObj[state.courseId] = state;
    }
  });

  let formattedIds = courseIds?.map((id) => `${programId}~VerifiedCertificate~${id}`) ?? [];

  if (allowOrgForSpecializationConfiguration && s12nIds) {
    const s12nFormattedIds = s12nIds?.map((id) => `${programId}~Specialization~${id}`) ?? [];
    formattedIds = formattedIds.concat(s12nFormattedIds);
  }

  const { data: productMetadata, loading: productMetadataLoading } = useQuery<
    ProgramProductMetadataQueryData,
    ProgramProductMetadataQueryVariables
  >(ProgramProductMetadataQuery, {
    skip: (!courseIds?.length && !s12nIds?.length) || !programId || !enableCurriculumBuilder,
    variables: { ids: formattedIds, allowOrgForSpecializationConfiguration },
  });

  const enterpriseProductConfigurations = filterExistsOrDefault(
    productMetadata?.ProgramProductMetadataV1Resource?.multiGet?.elements
  ) as EnterpriseProductMetadataConfiguration;

  const { data: s12nData, loading: s12nsLoading } = useQuery<
    ProductsContainerS12nQueryType,
    ProductsContainerS12nQueryVariablesType
  >(ProductsContainerS12nQuery, {
    skip: !s12nIds || s12nIds.length === 0,
    variables: {
      s12nIds,
    },
    context: {
      clientName: 'gatewayGql',
    },
  });

  const s12ns = (s12nData?.Specialization?.queryByIds ?? []).filter(({ id }) => s12nIds?.includes(id));

  const { data: courseData, loading: coursesLoading } = useQuery<ProductCoursesQueryType, ProductCoursesQueryVariables>(
    ProductCoursesQuery,
    {
      skip: !courseIds || courseIds.length === 0,
      variables: {
        courseIds,
      },
      context: {
        clientName: 'gatewayGql',
      },
    }
  );

  const courses = filterExistsOrDefault(courseData?.Course?.queryByIds).filter(({ id }) => courseIds?.includes(id));

  // ProductCard calls this with a weird third argument that we strip.
  const onProductCardClick =
    onProductCardClick0 != null
      ? (product: Product, productType: ProductType) => onProductCardClick0(product, productType)
      : undefined;
  const productsToShow = getProductsToShow(courses, s12ns, displayCount, displayOrderIds);
  const productsLoading = displayLoading || coursesLoading || s12nsLoading;
  const shouldShowListView = useMediaQuery(breakpoints.down('sm'));

  return (
    <div className="rc-ProductsListItem">
      <Heading defaultLevel={2} className="title">
        {_t('Saved Courses')}
      </Heading>
      <Section initialLevel={3}>
        <div className="divider-container">
          <Divider />
        </div>
        <Row tag="ul" rootClassName="ProductsContainer list-style-none p-a-0" style={{ minWidth: '100%' }}>
          {productsToShow.map((product, index) => {
            const { id } = product;
            const productState = productStateObj[id];

            const isS12n = productState.isS12n || !!product.courseIds;
            const isUnavailable = productState.isUnavailable;

            const productMetadata = getProductMetadata(!isS12n, id, enterpriseProductConfigurations);
            const productType = isS12n ? PRODUCT_TYPE.S12N : PRODUCT_TYPE.COURSE;
            const { label } = getProductCardDisplayProps(product, isS12n);
            const shouldShowNewBadge = isProjectOrSelfPacedProject(product.courseTypeMetadata);

            const eventingV3ProductData = {
              id,
              name: product.name,
              type: productType,
              slug: product.slug || '',
            };

            return (
              <Col tag="li" rootClassName="p-b-1" xs={12} sm={6} md={4} lg={4} xl={4} key={id}>
                <ProductCardCds
                  product={product}
                  productType={productType}
                  onClick={onProductCardClick}
                  variant={shouldShowListView ? 'list' : 'grid'}
                  shouldShowNewBadge={shouldShowNewBadge}
                  enterpriseProductMetadata={productMetadata}
                  isUnavailable={isUnavailable}
                  eventingV3Data={{
                    productCard: {
                      index: index ?? 0,
                    },
                    product: eventingV3ProductData,
                  }}
                  label={label}
                />
              </Col>
            );
          })}
          {productsLoading &&
            times(4 - (productsToShow.length % 4), (key) => (
              <Col tag="li" xs={12} sm={6} md={4} lg={4} xl={4} key={key}>
                <CdsProductCard variant="grid" productType="course" loading={true} />
              </Col>
            ))}
        </Row>
      </Section>
    </div>
  );
};
export default ProductsListItem;
