import * as React from 'react';

import type { Specialization_ProductVariant as SpecializationProductVariant } from '__generated__/graphql-types';
import type { ApolloQueryResult } from 'apollo-client';

import { isUserRightToLeft } from 'js/lib/language';

import { StyleSheet, breakPoint, css, spacing } from '@coursera/coursera-ui';

import CardDropdownWidget from 'bundles/course-cards/components/course-card/common/CardDropdownWidget';
import type { PartnerWithName } from 'bundles/course-cards/components/course-card/enterprise/CourseAndPartnerName';
import type {
  EnterpriseBadgeCollectionsQuery as EnterpriseBadgeCollectionsQueryData,
  EnterpriseBadgeCollectionsQueryVariables,
} from 'bundles/enterprise-learner-onboarding/queries/__generated__/EnterpriseBadgeCollectionsQuery';
import MultiCourseProductCardHeader from 'bundles/program-home/components/multiCourseProductCard/MultiCourseProductCardHeader';
import type { CircleMenuItem } from 'bundles/program-home/components/multiCourseProductCard/MultiCourseProductCirclesMenu';
import MultiCourseProductEnrolledCardExtended from 'bundles/program-home/components/multiCourseProductCard/MultiCourseProductEnrolledCardExtended';

const styles = StyleSheet.create({
  extendedS12nCardContainer: {
    marginLeft: -132,
    marginRight: `-${spacing.md}`,
    marginBottom: `-${spacing.md}`,
    [`@media (max-width: ${breakPoint.md}px)`]: {
      marginLeft: `-${spacing.md}`,
    },
  },
  extendedS12nCardContainerRTL: {
    marginRight: -132,
    marginLeft: `-${spacing.md}`,
    marginBottom: `-${spacing.md}`,
    [`@media (max-width: ${breakPoint.md}px)`]: {
      marginRight: `-${spacing.md}`,
    },
  },
});

type Props = {
  programId: string;
  userId: number;
  canUnenroll: boolean;
  itemType: 'COURSE' | 'S12N' | 'BADGE';
  id: string;
  name: string;
  logo: string;
  slug?: string;
  productVariant?: SpecializationProductVariant;
  partners?: Array<PartnerWithName>;
  description?: string;
  circleMenuData?: CircleMenuItem[];
  courseCount: number;
  capstoneCourses?: string[];
  firstCourseId: string;
  s12nProgress?: {
    lastActiveCourseId: string;
  };
  refetchEnterpriseBadgeCollections?: (
    variables?: EnterpriseBadgeCollectionsQueryVariables
  ) => Promise<ApolloQueryResult<EnterpriseBadgeCollectionsQueryData>>;
};

const MultiCourseProductEnrolledCard: React.SFC<Props> = ({
  id,
  name,
  logo,
  slug,
  partners,
  programId,
  userId,
  canUnenroll,
  description,
  itemType,
  circleMenuData,
  courseCount,
  capstoneCourses,
  firstCourseId,
  s12nProgress,
  productVariant,
  refetchEnterpriseBadgeCollections,
}) => {
  return (
    <div className="rc-MultiCourseProductEnrolledCard" data-e2e={`MultiCourseProductEnrolledCard~${id}`}>
      <MultiCourseProductCardHeader
        name={name}
        id={id}
        logo={logo}
        slug={slug}
        partners={partners}
        isEnrolled={true}
        description={description}
        productVariant={productVariant}
      >
        <CardDropdownWidget
          trackingName="s12n_card_ellipses"
          itemType={itemType}
          programId={programId}
          itemId={id}
          canUnenroll={canUnenroll}
          name={name}
          userId={userId}
          refetchEnterpriseBadgeCollections={refetchEnterpriseBadgeCollections}
        />
        <div {...css(isUserRightToLeft() ? styles.extendedS12nCardContainerRTL : styles.extendedS12nCardContainer)}>
          <MultiCourseProductEnrolledCardExtended
            id={id}
            programId={programId}
            name={name}
            userId={userId}
            itemType={itemType}
            circleMenuData={circleMenuData}
            courseCount={courseCount}
            capstoneCourses={capstoneCourses}
            firstCourseId={firstCourseId}
            s12nProgress={s12nProgress}
          />
        </div>
      </MultiCourseProductCardHeader>
    </div>
  );
};

export default MultiCourseProductEnrolledCard;
