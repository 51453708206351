import * as React from 'react';
import { useMemo, useState } from 'react';

import AssetModal from 'bundles/cml/editor/components/dialogs/AssetModal';
import { AssetContext, type AssetContextType } from 'bundles/cml/editor/context/assetContext';
import { useFocusedContext } from 'bundles/cml/editor/context/focusContext';
import type { AssetModalOptions, AssetUploadOptions } from 'bundles/cml/editor/types/assetTypes';
import AssetManager from 'bundles/cml/shared/utils/AssetManager';

export type Props = {
  isLearnerUpload: boolean;
  pageless: boolean;
  uploadOptions?: AssetUploadOptions;
};

const AssetContextProvider: React.FC<Props> = ({ isLearnerUpload, pageless, uploadOptions, children }) => {
  const [assetModalOptions, setAssetModalOptions] = useState<AssetModalOptions | undefined>();
  const context = useMemo<AssetContextType>(
    () => ({
      isLearnerUpload,
      setAssetModalOptions,
      pageless,
      uploadOptions,
      assetManager: AssetManager,
    }),
    [isLearnerUpload, pageless, uploadOptions]
  );
  const { setFocused } = useFocusedContext();

  return (
    <AssetContext.Provider value={context}>
      {children}
      <AssetModal
        assetOptions={assetModalOptions}
        isLearnerUpload={isLearnerUpload}
        uploadOptions={uploadOptions}
        onClose={() => setAssetModalOptions(undefined)}
        onFocused={setFocused}
      />
    </AssetContext.Provider>
  );
};

export default AssetContextProvider;
