// The Contentful expiry date is just a date, that is, a year, month and day picked
// by the content editor, with no reference to time zone.
//
// Unfortunately the date-time string that comes out of the
// Contentful "date only" date-time field translates as the previous day (0 h 0 sec etc
// is the midnight of yesterday).
//
// Out of the possible workarounds I chose the one that remembers the year, month and day
// picked, It's the simplest and the easiest when it comes
// to figuring out whether the expiry date is passed for the user; ie we can just compare these 3
// as the values retrieved from the date seen by the user's browser as "now".

export type DayValue = {
  year: number;
  month: number;
  date: number;
};

export const dayValueFromDateString = (dateTimeString: string): DayValue | undefined => {
  if (typeof dateTimeString !== 'string') {
    return undefined;
  }
  const [yearString, monthString, withDate] = dateTimeString.split('-');
  const year = Number(yearString);
  if (Number.isNaN(year)) {
    return undefined;
  }
  const month = Number(monthString);
  if (Number.isNaN(month)) {
    return undefined;
  }
  const [dateString] = withDate.split('T');
  const date = Number(dateString);
  if (Number.isNaN(date)) {
    return undefined;
  }
  return { year, month: month - 1, date };
};

export const isDayPassed = (dv: DayValue | undefined): boolean => {
  if (!dv) {
    return false;
  }
  const { year, month, date } = dv;
  const now = new Date(Date.now());
  const yearNow = now.getFullYear();
  if (yearNow < year) {
    return false;
  }
  if (yearNow > year) {
    return true;
  }
  const monthNow = now.getMonth();
  if (monthNow < month) {
    return false;
  }
  if (monthNow > month) {
    return true;
  }
  const dateNow = now.getDate();
  return dateNow > date;
};

export const displayDayValue = (dv: DayValue | undefined, locale: string): string => {
  if (!dv) {
    return ''; // not expected
  }
  const { year, month, date } = dv;
  return new Date(year, month, date).toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
  });
};
