import type React from 'react';

import config from 'js/app/config';
import type { InjectedRouter } from 'js/lib/connectToRouter';
import useRouter from 'js/lib/useRouter';

import { handleRedirect } from 'bundles/enterprise-admin-coursera-for-campus-basic/utils/utils';

type Props = {
  programSlug: string;
};

export const redirectToUnifiedCA = (router: InjectedRouter, programSlug: string) => {
  const redirectUrl = new URL(`/career-academy/programs/${programSlug}`, config.url.base);

  Object.keys(router.location.query).forEach((key) => {
    redirectUrl.searchParams.append(key, router.location.query[key]);
  });

  redirectUrl.searchParams.delete('currentTab');

  handleRedirect(redirectUrl.toString(), router);
};

export const EnterpriseCADirectorNotConfigurable: React.VFC<Props> = ({ programSlug }) => {
  const router = useRouter();

  redirectToUnifiedCA(router, programSlug);

  return null;
};
