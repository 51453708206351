import * as React from 'react';

import type { IconSize } from 'bundles/ai-coach-platform/types';

/**
 * MUI icon for "Thumb down" chat action
 */
const ThumbDownIcon = ({ size = 16, filled = false }: { size?: IconSize; filled?: boolean }) =>
  filled ? (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.17068 10.6919C1.86919 10.6919 1.5987 10.5721 1.35922 10.3326C1.11974 10.0932 1 9.82268 1 9.52119V8.15278C1 8.03208 1.01604 7.89032 1.04811 7.72749C1.08018 7.56456 1.12295 7.42477 1.1764 7.30813L3.09551 2.85395C3.20969 2.61586 3.3792 2.41401 3.60403 2.24841C3.82887 2.0828 4.06738 2 4.31959 2H10.9748C11.3062 2 11.5842 2.11226 11.8087 2.33677C12.0332 2.56128 12.1455 2.83925 12.1455 3.17068V10.2108C12.1455 10.3587 12.1187 10.5053 12.0653 10.6505C12.0118 10.7957 11.9317 10.9217 11.8247 11.0286L8.68156 14.3803C8.47843 14.5941 8.24322 14.7251 7.97595 14.7732C7.70867 14.8213 7.46277 14.7759 7.23826 14.6369C7.00305 14.5086 6.83467 14.3162 6.7331 14.0596C6.63154 13.803 6.61818 13.5357 6.69301 13.2577L7.2543 10.6919H2.17068ZM14.0558 2C14.3111 2 14.5323 2.09403 14.7194 2.28209C14.9065 2.47003 15 2.69139 15 2.94616V9.7457C15 10.0004 14.9058 10.2217 14.7174 10.4098C14.529 10.5978 14.3071 10.6919 14.0519 10.6919C13.7966 10.6919 13.5754 10.5978 13.3883 10.4098C13.2012 10.2217 13.1077 10.0004 13.1077 9.7457V2.94616C13.1077 2.69139 13.2019 2.47003 13.3902 2.28209C13.5787 2.09403 13.8006 2 14.0558 2Z"
        fill="var(--cds-color-grey-950)"
      />
    </svg>
  ) : (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.20418 10.7127C1.89125 10.7127 1.61743 10.5954 1.38273 10.3607C1.14804 10.126 1.03069 9.85218 1.03069 9.53925V8.17013C1.03069 8.09029 1.02265 8.00648 1.00658 7.91871C0.990501 7.83094 1.0039 7.74955 1.04676 7.67453L3.13091 2.856C3.24397 2.61466 3.41437 2.41168 3.6421 2.24708C3.86972 2.08236 4.10822 2 4.3576 2H11.7528V10.7127L8.24843 14.4582C8.05982 14.6726 7.83375 14.8039 7.57022 14.8521C7.30681 14.9003 7.05598 14.8548 6.81775 14.7154C6.57758 14.6004 6.40772 14.4135 6.30816 14.1549C6.2085 13.8963 6.19242 13.6223 6.25994 13.333L6.83382 10.7127H2.20418ZM10.5793 10.2305V3.17349H4.24572L2.20418 8.04426V9.53925H8.31274L7.41253 13.5902L10.5793 10.2305ZM13.8265 2C14.1561 2 14.434 2.11317 14.6605 2.33951C14.8868 2.56595 15 2.84395 15 3.17349V9.53925C15 9.86879 14.8868 10.1467 14.6605 10.3731C14.434 10.5995 14.1561 10.7127 13.8265 10.7127H11.7528V9.53925H13.8265V3.17349H11.7528V2H13.8265Z"
        fill="var(--cds-color-grey-950)"
      />
    </svg>
  );

export default ThumbDownIcon;
