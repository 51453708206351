import * as React from 'react';

import { Specialization_ProductVariant as SpecializationProductVariant } from '__generated__/graphql-types';

import type { ButtonProps } from '@coursera/coursera-ui';
import { Button } from '@coursera/coursera-ui';

import withLogin from 'bundles/coursera-ui/components/hocs/withLogin';
import MiniSDPInfoRows from 'bundles/coursera-ui/components/rich/MiniSDPInfoRows';
import withMiniModal, { DESCRIPTION_PAGE_PATHS } from 'bundles/enterprise-legacy-xdp/hocs/withMiniModal';
import ProgramActionButtonContainer from 'bundles/program-common/components/programActionButton/ProgramActionButtonContainer';
import { PRODUCT_TYPES } from 'bundles/program-common/constants/ProgramActionConstants';
import MultiCourseProductCardHeader from 'bundles/program-home/components/multiCourseProductCard/MultiCourseProductCardHeader';
import type { S12nCardSpecialization_SpecializationFragment as Specialization } from 'bundles/program-home/queries/__generated__/S12nCardSpecializationQuery';

import _t from 'i18n!nls/program-home';

const ViewDetailsBtnWithMiniModal = withMiniModal<ButtonProps>()(Button);
const ProgramActionButtonWithLogin = withLogin(ProgramActionButtonContainer);

const S12nNotEnrolledCard: React.SFC<{
  programId: string;
  s12n: Specialization;
}> = ({
  s12n: {
    id,
    name,
    slug,
    cardImageUrl,
    descriptionAsPlainText,
    partners,
    courseCount,
    capstoneCourses,
    productVariant,
  },
  programId,
}) => {
  return (
    <div className="rc-S12nNotEnrolledCard" data-e2e={`S12nNotEnrolledCard~${id}`}>
      <MultiCourseProductCardHeader
        name={name}
        slug={slug}
        id={id}
        logo={cardImageUrl}
        description={descriptionAsPlainText}
        partners={partners}
        isEnrolled={false}
        productVariant={productVariant}
      >
        <MiniSDPInfoRows
          courseCount={courseCount - capstoneCourses.length}
          capstoneProjectCount={capstoneCourses.length}
        />
        <div className="horizontal-box align-items-right align-items-vertical-center p-t-1">
          <ViewDetailsBtnWithMiniModal
            miniModalProps={{
              isCourse: false,
              id,
              unifiedDescriptionPageProps: {
                slug,
                productPagePath:
                  productVariant === SpecializationProductVariant.NormalS12N
                    ? DESCRIPTION_PAGE_PATHS.specialization
                    : DESCRIPTION_PAGE_PATHS.professionalCertificate,
              },
            }}
            type="link"
            size="sm"
            label={_t('View Details')}
            style={{ marginBottom: 4 }}
          />
          <ProgramActionButtonWithLogin
            loggedoutBtnAttributes={{
              label: _t('Enroll'),
              size: 'sm',
            }}
            loginHashHistoryReplacement="autoEnroll/specialization/id"
            programId={programId}
            productId={id}
            productType={PRODUCT_TYPES.SPECIALIZATION}
            actionType="ENROLL"
            initialBtnLabel={_t('Enroll')}
            btnAttributes={{
              type: 'primary',
              size: 'sm',
              htmlAttributes: {
                'data-e2e': `ProgramActionButtonContainer~${id}`,
              },
            }}
            trackingInfo={{
              trackingData: { s12nId: id },
              trackingName: 's12n_enroll_button',
            }}
          />
        </div>
      </MultiCourseProductCardHeader>
    </div>
  );
};

export default S12nNotEnrolledCard;
