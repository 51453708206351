/**
 * See the following document for details on each union member
 * https://coursera.atlassian.net/wiki/spaces/B2C/pages/2466578543/Course+Types
 */
import type {
  PdpPageQuery_XdpV1Resource_slug_elements_xdpMetadata_XdpV1_cdpMetadataMember_cdpMetadata_courseTypeMetadata_XdpV1_projectMember as ProjectMetadata,
  PdpPageQuery_XdpV1Resource_slug_elements_xdpMetadata_XdpV1_cdpMetadataMember_cdpMetadata_courseTypeMetadata_XdpV1_rhymeProjectMember as RhymeProjectMetadata,
  PdpPageQuery_XdpV1Resource_slug_elements_xdpMetadata_XdpV1_cdpMetadataMember_cdpMetadata_courseTypeMetadata_XdpV1_standardCourseMember as StandCourseMetadata,
} from 'bundles/enterprise-legacy-xdp/components/miniPDP/__generated__/PdpPageQuery';

const STANDARD_COURSE_METADATA = ['XdpV1_standardCourseMember', 'CourseTypeMetadataV1_standardCourseMember'];
const RHYME_PROJECT_METADATA = ['XdpV1_rhymeProjectMember', 'CourseTypeMetadataV1_rhymeProjectMember'];
const PROJECT = ['XdpV1_projectMember', 'CourseTypeMetadataV1_projectMember'];

export type CourseTypeMetadata = StandCourseMetadata | RhymeProjectMetadata | ProjectMetadata;

export const isGuidedProject = (
  courseTypeMetadata: CourseTypeMetadata | null
): courseTypeMetadata is RhymeProjectMetadata => {
  return RHYME_PROJECT_METADATA.includes(courseTypeMetadata?.__typename ?? '');
};

export const isSelfPacedProject = (
  courseTypeMetadata: CourseTypeMetadata | null
): courseTypeMetadata is ProjectMetadata => {
  return PROJECT.includes(courseTypeMetadata?.__typename ?? '');
};

/**
 * Identify the course type based on the metadata type name property.
 * @param metadata The course type metadata
 * @returns A CourseType or undefined
 */
export const getCourseTypeFromCourseTypeMetadata = (metadata: CourseTypeMetadata): CourseType | undefined => {
  const typeName = metadata.__typename;
  if (RHYME_PROJECT_METADATA.includes(typeName)) {
    return 'GuidedProject';
  } else if (PROJECT.includes(typeName)) {
    return 'Project';
  } else if (STANDARD_COURSE_METADATA.includes(typeName)) {
    return 'StandardCourse';
  } else {
    return undefined;
  }
};

export type CourseType = 'StandardCourse' | 'GuidedProject' | 'Project' | 'Assessment';
