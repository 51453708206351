import { useQuery } from '@apollo/client';

import GetCourseForFinAidProductCardInfo from 'bundles/enroll/queries/GetCourseForFinAidProductCardInfoQuery.graphql';
import type {
  GetCourseForFinAidProductCardInfoQuery,
  GetCourseForFinAidProductCardInfoQueryVariables,
} from 'bundles/enroll/queries/__generated__/GetCourseForFinAidProductCardInfoQuery';
import { useGetSpecializationById } from 'bundles/payments-common/hooks/useGetSpecializationById';

export type PropsFromCaller = {
  s12nId?: string;
  courseId: string;
  productType: 'course' | 'specialization';
};

export type ProductCardInfo = {
  name?: string;
  partnerName?: string;
  partnerIds?: Array<string>;
  numOfCourses?: number;
};

export type UseProductCardInfoProps = {
  productCardInfo: ProductCardInfo;
};

const useProductCardInfo = ({ s12nId, courseId, productType }: PropsFromCaller) => {
  const shouldFetchS12n = productType === 'specialization' && !!s12nId;

  const { data: courseData } = useQuery<
    GetCourseForFinAidProductCardInfoQuery,
    GetCourseForFinAidProductCardInfoQueryVariables
  >(GetCourseForFinAidProductCardInfo, {
    variables: {
      id: courseId,
    },
    context: { clientName: 'gatewayGql' },
    errorPolicy: 'all',
    skip: shouldFetchS12n,
  });

  const { s12n } = useGetSpecializationById({
    s12nId: `${s12nId}`,
    skip: !shouldFetchS12n,
  });

  if (courseData) {
    const { name, partners } = courseData?.Course?.queryById || {};

    return {
      productCardInfo: {
        name,
        partnerName: partners?.[0]?.name,
        partnerIds: partners?.map(({ id }) => id) ?? [],
      },
    };
  }

  if (s12n) {
    const { name, partners, courses } = s12n;

    return {
      productCardInfo: {
        name,
        partnerName: partners?.[0]?.name,
        partnerIds: partners?.map(({ id }) => id) ?? [],
        numOfCourses: courses?.length || 0,
      },
    };
  }

  return { productCardInfo: {} };
};

export default useProductCardInfo;
