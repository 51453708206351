/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react';

import * as React from 'react';

import logger from 'js/app/loggerSingleton';

import SubscriptionTiersEnrollButton from 'bundles/coursera-plus/components/subscriptionTiers/SubscriptionTiersEnrollButton';
import type { PropsFromCaller as EnrollButtonProps } from 'bundles/coursera-plus/components/subscriptionTiers/SubscriptionTiersEnrollButton';
import withCourseraLiteCreateCartMutation from 'bundles/coursera-plus/utils/withCourseraLiteCreateCartMutation';
import type { PropsFromWithCourseraLiteCreateCartMutation } from 'bundles/coursera-plus/utils/withCourseraLiteCreateCartMutation';
import EnrollmentChoiceTypes from 'bundles/enroll-course/common/EnrollmentChoiceTypes';
import { submitEnrollmentPromise } from 'bundles/enroll-course/lib/enrollmentChoiceUtils';
import usePageData from 'bundles/enroll/data/usePageData';
import { useEnrollModalEventing } from 'bundles/enroll/utils/eventingUtils';
import { SPECIALIZATION, VERIFIED_CERTIFICATE } from 'bundles/payments/common/ProductType';
import type { ProductType } from 'bundles/payments/common/ProductType';

type PropsFromCaller = Omit<EnrollButtonProps, 'onCtaClick'>;

type PropsToComponent = PropsFromCaller & PropsFromWithCourseraLiteCreateCartMutation;

const CourseraLiteEnrollButton: React.FC<PropsToComponent> = ({ createCourseraLiteCart, ...props }) => {
  const { s12n, course } = usePageData();
  const { trackEnrollModalContinue } = useEnrollModalEventing();

  if (!s12n && !course) {
    logger.error('Unable to render CourseraLiteEnrollButton without course or s12n');
    return null;
  }

  let productType: ProductType = SPECIALIZATION;
  let productItemId = s12n?.id;
  const courseId = course?.id;
  if (!s12n && course) {
    productType = VERIFIED_CERTIFICATE;
    productItemId = course?.id;
  }

  const onCtaClick = () => {
    trackEnrollModalContinue(EnrollmentChoiceTypes.PURCHASE_COURSERA_TIER_LITE);

    const options = { productType, productItemId, courseId };
    return submitEnrollmentPromise({ handleSubmitPromise: createCourseraLiteCart, options });
  };

  return <SubscriptionTiersEnrollButton onCtaClick={onCtaClick} {...props} />;
};

export default withCourseraLiteCreateCartMutation<PropsFromCaller>(CourseraLiteEnrollButton);
