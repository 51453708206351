/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Link, Tag, Typography2 } from '@coursera/cds-core';
import { ArrowNextIcon } from '@coursera/cds-icons';

import { TrackedReactLink } from 'bundles/page/components/TrackedLink2';
import { SKILL_LEVEL_SEGMENTS, getLevelName } from 'bundles/program-skills-objectives/utils';
import { ProgressTracker } from 'bundles/skills-common';
import { ProgressTrackerMyScorePopoverWithScore } from 'bundles/skills-common/private/progress-tracker/ProgressTrackerMyScorePopover';

import _t from 'i18n!nls/program-personalized-tab';

type Props = {
  id: string;
  name: string;
  score: number;
  route: string;
};

const styles = {
  skillScoreCard: css`
    background-color: var(--cds-color-neutral-background-primary-weak);
    padding: var(--cds-spacing-300);
    height: 100%;
    display: flex;
    flex-direction: column;

    /* Below properties copied to match CDS ProductCard */
    border-radius: var(--cds-spacing-200);
    min-height: 200px;
    max-width: 450px;
    min-width: 230px;
  `,
  skillContent: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
  `,
  heading: css`
    margin-bottom: var(--cds-spacing-200);
  `,
  description: css`
    margin-bottom: var(--cds-spacing-200);
  `,
};

const SkillScoreCard = ({ id, name, score, route }: Props) => {
  const heading = _t('Recommendations for #{name}', { name });
  const description = _t('These recommendations are personalized to help increase your knowledge.');
  const link = _t('View all for #{name}', { name });

  return (
    <div css={styles.skillScoreCard}>
      <div css={styles.skillContent}>
        <Typography2 component="h3" css={styles.heading} variant="subtitleMedium">
          {heading}
        </Typography2>
        <Typography2 component="p" css={styles.description} color="supportText">
          {description}
        </Typography2>
        <ProgressTracker
          forScoring
          hideTarget
          wrapHeader
          score={score}
          segments={SKILL_LEVEL_SEGMENTS}
          afterPersonalScore={score ? <Tag>{getLevelName(score)}</Tag> : undefined}
          scoreTooltip={<ProgressTrackerMyScorePopoverWithScore score={score} skillId={id} skillName={name} />}
        />
      </div>
      <div>
        <Link
          component={TrackedReactLink}
          to={route}
          variant="quiet"
          typographyVariant="subtitleMedium"
          icon={<ArrowNextIcon />}
          role="link"
          trackingName="link_to_skill_page"
        >
          {link}
        </Link>
      </div>
    </div>
  );
};

export default SkillScoreCard;
