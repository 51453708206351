import * as React from 'react';

// [fe-tech-debt] Replace with cds icon when available in web.
const WidgetIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 1.69321H5.51823V3.64603C5.51823 4.43778 6.16007 5.07963 6.95182 5.07963C7.74358 5.07963 8.38542 4.43778 8.38542 3.64603V1.69321H12.6136V5.5634L13.5634 5.5634C14.9091 5.5634 16 6.6543 16 8C16 9.34569 14.9091 10.4366 13.5634 10.4366H12.6136V14.3068H8.38542V12.354C8.38542 11.5622 7.74358 10.9204 6.95182 10.9204C6.16007 10.9204 5.51823 11.5622 5.51823 12.354V14.3068H5.67943e-07L3.28809e-07 9.43359H1.95282C2.74458 9.43359 3.38642 8.79175 3.38642 8C3.38642 7.20824 2.74458 6.5664 1.95282 6.5664L1.7935e-07 6.5664L0 1.69321ZM1.003 2.69621L1.003 5.5634L1.95282 5.5634C3.29852 5.5634 4.38942 6.6543 4.38942 8C4.38942 9.34569 3.29852 10.4366 1.95282 10.4366H1.003L1.003 13.3038H4.51523V12.354C4.51523 11.0083 5.60613 9.91737 6.95182 9.91737C8.29752 9.91737 9.38842 11.0083 9.38842 12.354V13.3038H11.6106V9.43359H13.5634C14.3552 9.43359 14.997 8.79175 14.997 8C14.997 7.20824 14.3552 6.5664 13.5634 6.5664L11.6106 6.5664V2.69621H9.38842V3.64603C9.38842 4.99172 8.29752 6.08263 6.95182 6.08263C5.60613 6.08263 4.51523 4.99172 4.51523 3.64603V2.69621H1.003Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default WidgetIcon;
