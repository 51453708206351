import * as React from 'react';

import { PersonalizationTagContext } from 'bundles/cml/editor/context/personalizationTagContext';
import type { AddTagCallback } from 'bundles/cml/editor/types/cmlEditorProps';

export type Props = {
  onPersonalizationTagClick?: (callback: AddTagCallback) => void;
};

const PersonalizationTagContextProvider: React.FC<Props> = ({ onPersonalizationTagClick, children }) => {
  return (
    <PersonalizationTagContext.Provider value={{ onPersonalizationTagClick }}>
      {children}
    </PersonalizationTagContext.Provider>
  );
};

export default PersonalizationTagContextProvider;
