import * as React from 'react';
import { useCallback } from 'react';

import { Transforms } from 'slate';
import { useSlateStatic } from 'slate-react';

import { AddRowIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import { createTableRow, focusTableCell, getTableData } from 'bundles/cml/editor/components/elements/table/tableUtils';
import type { TableElement } from 'bundles/cml/shared/types/elementTypes';

import _t from 'i18n!nls/cml';

type Props = {
  table: TableElement;
};

const AddTableRowButton: React.FC<Props> = ({ table }) => {
  const staticEditor = useSlateStatic();

  const handleAddRow = useCallback(() => {
    const { path, columnIndex, rowIndex, numColumns } = getTableData(staticEditor, table);
    if (!path || columnIndex < 0 || rowIndex < 0) {
      return;
    }

    const newRowIndex = rowIndex + 1;
    const rowPath = [...path, newRowIndex];

    const tableRow = createTableRow(numColumns);

    Transforms.insertNodes(staticEditor, tableRow, { at: rowPath });
    focusTableCell(staticEditor, path, newRowIndex, columnIndex);
  }, [staticEditor, table]);

  return (
    <Button role="menuitem" onClick={handleAddRow} title={_t('Insert row')} type={TOOLBAR_BUTTON_TYPES.insertion}>
      <AddRowIcon size="small" />
    </Button>
  );
};

export default AddTableRowButton;
