/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect } from 'react';

import { Transforms } from 'slate';
import { ReactEditor, useSelected, useSlateStatic } from 'slate-react';
import type { RenderElementProps } from 'slate-react';

import type { Theme } from '@coursera/cds-core';

import { useFillableBlanksContext } from 'bundles/cml/editor/context/fillableBlanksContext';
import { useFocusedContext } from 'bundles/cml/editor/context/focusContext';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import FillableBlankRenderer from 'bundles/cml/shared/components/fillableBlank/FillableBlank';
import type { FillableBlankElement } from 'bundles/cml/shared/types/elementTypes';

const styles = {
  fillableBlank: css`
    /* overide horizontal spacing so the cursor
     lines up with the edge of the select */
    margin: var(--cds-spacing-50) 0;

    .cds-input-root {
      user-select: none;
      outline: 2px solid transparent;
    }
  `,
  selected: css`
    .cds-input-root {
      outline-color: var(--cds-color-callouts-tertiary);
    }
  `,
};

const FillableBlank: React.FC<RenderElementProps> = ({ element, attributes, children }) => {
  const fillableBlank = element as FillableBlankElement;
  const { innerText, uuid = '' } = fillableBlank;
  const { focused } = useFocusedContext();
  const { fillableBlanks } = useFillableBlanksContext();
  const selected = useSelected();
  const staticEditor = useSlateStatic();

  const label = fillableBlanks[uuid];

  useEffect(() => {
    if (label && label !== innerText) {
      const path = ReactEditor.findPath(staticEditor, element);
      Transforms.setNodes(staticEditor, { innerText: label }, { at: path });
    }
  }, [innerText, label, element, staticEditor]);

  return (
    <FillableBlankRenderer
      element={element}
      attributes={attributes}
      label={label}
      data-uuid={uuid}
      css={[styles.fillableBlank, focused && selected && styles.selected]}
    >
      {children}
    </FillableBlankRenderer>
  );
};

export default FillableBlank;
