import path from 'js/lib/path';

import { requireFields } from 'bundles/naptimejs/util/requireFieldsDecorator';

import NaptimeResource from './NaptimeResource';

class Partner extends NaptimeResource {
  static RESOURCE_NAME = 'partners.v1';

  // These properties are always included.
  declare id: string;

  declare name: string;

  declare shortName: string;

  // These properties must be requested.
  declare courseIds?: string[];

  declare abbrName?: string;

  declare display?: boolean;

  declare primaryColor?: string;

  declare description?: string;

  declare homeLink?: string;

  declare logo?: string;

  declare classLogo?: string;

  declare rectangularLogo?: string;

  declare squareLogo?: string;

  declare links?: Record<string, string>;

  declare location?: Record<string, string>;

  declare partnerType?: number;

  declare instructorIds?: string[];

  @requireFields('links')
  get prettyWebsiteUrl() {
    let url = this.links?.website ?? '';
    url = url.replace(/^.*:\/\//, '');
    url = url.replace(/\/$/, '');
    return url;
  }

  @requireFields('homeLink', 'shortName')
  get partnerPageUrl() {
    if (this.homeLink) {
      return this.homeLink;
    } else {
      return path.join('/', this.shortName);
    }
  }

  @requireFields('rectangularLogo', 'logo', 'classLogo', 'squareLogo')
  get logoForCert() {
    return this.rectangularLogo || this.logo || this.classLogo || this.squareLogo;
  }
}

export default Partner;
