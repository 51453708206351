import * as React from 'react';

import URI from 'jsuri';
import { compose, withProps } from 'recompose';

import connectToRouter from 'js/lib/connectToRouter';

import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { TrackedA } from 'bundles/page/components/TrackedLink2';
import { TWITTER_SOURCE_PARAM } from 'bundles/sharing-common/constants';
import type {
  SocialButtonPropsFromCaller,
  SocialButtonPropsToComponent,
} from 'bundles/sharing-common/types/sharingSharedTypes';
import withCustomUrl from 'bundles/sharing-common/utils/withCustomUrl';
import withFormattedShareLink from 'bundles/sharing-common/utils/withFormattedShareLink';

import _t from 'i18n!nls/sharing-common';

const BASE_TWITTER_SHARE_URL = 'https://twitter.com/intent/tweet';

class TwitterButton extends React.Component<SocialButtonPropsToComponent> {
  render() {
    const { createSocialShareLink, targetShareLink, customShareLink, withLabel, onSocialButtonClick } = this.props;

    return (
      <TrackedDiv className="rc-TwitterButton social-media-button" trackingName="social_share_button" trackMouseEnters>
        <TrackedA
          trackingName="twitter_share_button"
          className="social-media-button_link"
          href={createSocialShareLink(customShareLink || targetShareLink)}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onSocialButtonClick}
          ariaLabel={_t('Share with Twitter')}
        >
          <svg
            className="social-media-button_icon"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.298706 24.7208C0.298706 11.07 11.3687 0 25.0195 0C38.6703 0 49.7403 11.07 49.7403 24.7208C49.7403 38.3716 38.6703 49.4416 25.0195 49.4416C11.3687 49.4416 0.298706 38.3716 0.298706 24.7208ZM36.3211 20.0079C36.3211 19.7624 36.3158 19.5179 36.3048 19.2746C37.4127 18.4738 38.3757 17.4742 39.135 16.3362C38.1185 16.7881 37.0245 17.0925 35.8767 17.2298C37.0483 16.527 37.9476 15.4158 38.3719 14.0914C37.2754 14.7413 36.0615 15.2136 34.7688 15.4686C33.7334 14.3656 32.2593 13.6757 30.6271 13.6757C27.4936 13.6757 24.9523 16.217 24.9523 19.3493C24.9523 19.7944 25.0025 20.2275 25.0998 20.6431C20.384 20.4059 16.2022 18.1479 13.4041 14.7146C12.9167 15.5531 12.6357 16.527 12.6357 17.5666C12.6357 19.5353 13.6372 21.2731 15.1607 22.2897C14.2298 22.261 13.3554 22.0053 12.5908 21.5801C12.5901 21.6039 12.5901 21.6269 12.5901 21.6526C12.5901 24.4002 14.5459 26.6947 17.1422 27.2145C16.6654 27.3446 16.1637 27.4144 15.6458 27.4144C15.2811 27.4144 14.9254 27.3782 14.5795 27.3118C15.3022 29.5664 17.3965 31.2069 19.8804 31.2529C17.9381 32.7749 15.4916 33.6818 12.833 33.6818C12.3754 33.6818 11.9235 33.6557 11.4796 33.6033C13.9906 35.2129 16.9725 36.1521 20.1769 36.1521C30.6139 36.1517 36.3211 27.5057 36.3211 20.0079Z"
              fill="#2A73CC"
            />
          </svg>
          {withLabel && <p className="social-media-button_label">{_t('Twitter')}</p>}
        </TrackedA>
      </TrackedDiv>
    );
  }
}

export default compose<SocialButtonPropsToComponent, SocialButtonPropsFromCaller>(
  // order of these are important
  connectToRouter((router) => ({ router })),
  withFormattedShareLink(TWITTER_SOURCE_PARAM),
  withProps(({ generateCaption }: SocialButtonPropsToComponent) => {
    const createSocialShareLink = (shareLink: string) => {
      const twitterButtonUrl = new URI(BASE_TWITTER_SHARE_URL);

      twitterButtonUrl
        .addQueryParam('text', generateCaption ? generateCaption(shareLink) : '')
        .addQueryParam('hashtags', 'Coursera')
        .addQueryParam('related', 'Coursera')
        .addQueryParam('url', shareLink);

      return twitterButtonUrl.toString();
    };

    return {
      createSocialShareLink,
    };
  }),
  withCustomUrl()
)(TwitterButton);
