import { StyleSheet } from '@coursera/coursera-ui';

export const noShowStyles = StyleSheet.create({
  noShowList: {
    listStyleType: 'none',
    paddingLeft: 0,
  },
  noShowListRtl: {
    listStyleType: 'none',
    paddingRight: 0,
  },
});

export const GLANCE_ICON_SVG_SIZE = 20;
export const GLANCE_ICON_BORDER_SIZE = 44;
