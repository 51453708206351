import * as React from 'react';

import gql from 'graphql-tag';

import user from 'js/lib/user';

import { StyleSheet, breakPoint, color, css } from '@coursera/coursera-ui';

import type { PropsFromWithLearnerIncentivePromotionData } from 'bundles/learner-incentives/data-fetching/learner-incentive-contentful-data';
import { withLearnerIncentivePromotionData } from 'bundles/learner-incentives/data-fetching/learner-incentive-contentful-data';
import type { LearnerIncentive as LearnerIncentiveType } from 'bundles/learner-incentives/types';
import type { AdditionalIncentiveInfo } from 'bundles/learner-incentives/utils/learnerIncentiveUtils';
import LazyImgix from 'bundles/page/components/shared/LazyImgix';

const IMAGE_WIDTH = 80;

const styles = StyleSheet.create({
  container: {
    padding: '32px',
    backgroundColor: '#F5F7F8',
    display: 'flex',
    alignItems: 'center',
    [`@media (max-width: ${breakPoint.lg - 1}px)`]: {
      padding: '24px',
    },
  },
  separator: {
    height: `${IMAGE_WIDTH}px`,
    width: '1px',
    margin: '0 32px',
    backgroundColor: color.borderThemeDark,
    [`@media (max-width: ${breakPoint.lg - 1}px)`]: {
      display: 'none',
    },
  },
  imageContainer: {
    height: `${IMAGE_WIDTH}px`,
    width: `${IMAGE_WIDTH}px`,
    [`@media (max-width: ${breakPoint.lg - 1}px)`]: {
      marginRight: '16px',
    },
  },
  title: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  description: {
    marginRight: '80px',
    fontSize: '14px',
    [`@media (max-width: ${breakPoint.lg - 1}px)`]: {
      width: '100%',
      marginRight: 0,
    },
  },
});

type PropsFromCaller = {
  learnerIncentives: Array<LearnerIncentiveType>;
  additionalInfo?: AdditionalIncentiveInfo;
};

type PropsToComponent = PropsFromCaller & PropsFromWithLearnerIncentivePromotionData;

export const shouldShowLearnerIncentive = (learnerIncentives?: Array<LearnerIncentiveType>): boolean => {
  // Pick the first incentive. We don't support multiple learner incentives yet.
  const learnerIncentiveData: LearnerIncentiveType | undefined = learnerIncentives?.[0];
  return (user.isCourserian() && learnerIncentiveData?.status === 'TEST') || learnerIncentiveData?.status === 'ACTIVE';
};

export const LearnerIncentive: React.FC<PropsToComponent> = ({ learnerIncentivePromotionData }) => {
  if (!learnerIncentivePromotionData) {
    return null;
  }

  const { xdpMerchandisingTitle, xdpMerchandisingText, xdpMerchandisingImage } = learnerIncentivePromotionData;
  const bannerInfo = {
    title: xdpMerchandisingTitle,
    description: xdpMerchandisingText,
    imageUrl: xdpMerchandisingImage?.url,
  };

  return (
    <div {...css(styles.container)}>
      <div {...css(styles.imageContainer)}>
        {bannerInfo.imageUrl && (
          <LazyImgix
            maxWidth={IMAGE_WIDTH}
            maxHeight={IMAGE_WIDTH}
            imgParams={{ fit: 'crop' }}
            src={bannerInfo.imageUrl}
            alt=""
          />
        )}
      </div>
      <div {...css(styles.separator)} />
      <div>
        <div {...css(styles.title)}>{bannerInfo.title}</div>
        <div {...css(styles.description)}>{bannerInfo.description}</div>
      </div>
    </div>
  );
};

export const PartnerIncentivesCdpFragment = gql`
  fragment PartnerIncentivesCdpFragment on XdpV1_org_coursera_xdp_cdp_CDPMetadata {
    partnerIncentives {
      id
      status
      fetchType
    }
  }
`;

export const PartnerIncentivesSdpFragment = gql`
  fragment PartnerIncentivesSdpFragment on XdpV1_org_coursera_xdp_sdp_SDPMetadata {
    partnerIncentives {
      id
      status
      fetchType
    }
  }
`;

export default withLearnerIncentivePromotionData<PropsFromCaller>('xdp')(LearnerIncentive);
