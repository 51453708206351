import type { UpdateAssetDataPayload } from 'bundles/cml/editor/types/assetTypes';
import type { Asset } from 'bundles/cml/shared/types/assetTypes';
import { getAssetTag } from 'bundles/cml/shared/utils/assetUtils';

export const getAssetDescription = (asset?: Asset) => getAssetTag(asset, 'description');

export const getAssetDataPayload = (asset: Asset): UpdateAssetDataPayload => {
  return {
    assetId: asset.id,
    name: asset.name,
    courseId: getAssetTag(asset, 'courseId'),
    description: getAssetTag(asset, 'description'),
    longDescription: getAssetTag(asset, 'longDescription'),
  };
};

export const isScriptInjectableImageType = ({ type, ext }: { type?: string; ext?: string }) => {
  return type?.startsWith('image/svg') || ext === 'svg';
};
