/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { compose, withProps } from 'recompose';

import deferToClientSideRender from 'js/lib/deferToClientSideRender';

import { Typography2 } from '@coursera/cds-core';

import CourseSpecializationEnrollmentNote from 'bundles/enroll/components/xdp/CourseSpecializationEnrollmentNote';
import { PreEnrollmentStatus } from 'bundles/enroll/components/xdp/PreEnrollmentStatus';
import type { PropsFromWithEnrollment } from 'bundles/enroll/components/xdp/withEnrollment';
import withEnrollment from 'bundles/enroll/components/xdp/withEnrollment';
import type { CommonSpecialization } from 'bundles/enroll/utils/mixAndMatchUtils';
import paymentsExperiments from 'bundles/epic/clients/payments';
import { TrackedA } from 'bundles/page/components/TrackedLink2';
import { getS12nDisplayName } from 'bundles/s12n-common/lib/s12nProductVariantUtils';

import _t from 'i18n!nls/enroll';

const INITIAL_S12NS_SHOWN = 2;

const styles = {
  hidden: css`
    display: none;
  `,
};

type State = {
  isExpanded: boolean;
};

type PropsFromCaller = {
  courseId: string;
  s12ns: Array<CommonSpecialization>;
  isPreEnrollmentEnabled?: boolean;
};

type PropsToComponent = PropsFromCaller & PropsFromWithEnrollment;

class CourseSpecializationList extends React.Component<PropsToComponent, State> {
  state = {
    isExpanded: false,
  };

  toggleExpand = () => {
    this.setState(({ isExpanded }) => ({
      isExpanded: !isExpanded,
    }));
  };

  renderItem = ({ slug, name, id, productVariant }: CommonSpecialization, i: number) => {
    const { courseId, isPreEnrollmentEnabled = false, enrollmentAvailableChoices } = this.props;
    const { isExpanded } = this.state;

    const plannedLaunchDate = enrollmentAvailableChoices?.getPlannedLaunchDateForS12n(id);
    const shouldShowPreEnrollmentStatus =
      isPreEnrollmentEnabled && id && enrollmentAvailableChoices?.isPreEnrollEnabledS12n(id) && plannedLaunchDate;
    const isPreEnrolled = enrollmentAvailableChoices?.preEnrolledS12nIds.includes(id) ?? false;

    return (
      <li className="m-t-1s" css={!isExpanded && i >= INITIAL_S12NS_SHOWN && styles.hidden} key={slug}>
        <TrackedA
          trackingName="course_specialization_list_link"
          data={{ courseId, slug }}
          href={`/specializations/${slug}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {getS12nDisplayName(name, productVariant)}
          {shouldShowPreEnrollmentStatus && (
            <PreEnrollmentStatus isPreEnrolled={isPreEnrolled} plannedLaunchDate={plannedLaunchDate} />
          )}
        </TrackedA>
        <CourseSpecializationEnrollmentNote s12nId={id} courseId={courseId} />
      </li>
    );
  };

  render() {
    const { s12ns } = this.props;
    const { isExpanded } = this.state;

    return (
      <div className="CourseSpecializationList">
        <Typography2 component="h3" variant="subtitleMedium">
          {_t('This course is part of multiple programs')}
        </Typography2>
        <div className="m-t-1">
          {_t(`
            This course can be applied to multiple Specializations or Professional Certificates programs.
            Completing this course will count towards your learning in any of the following programs:
          `)}
        </div>
        <ul className="m-t-1">{s12ns.map(this.renderItem)}</ul>
        {s12ns.length > INITIAL_S12NS_SHOWN && (
          <button type="button" className="button-link" onClick={this.toggleExpand}>
            {isExpanded ? _t('SHOW LESS') : _t('SHOW ALL')}
          </button>
        )}
      </div>
    );
  }
}

export default compose<PropsToComponent, PropsFromCaller>(
  deferToClientSideRender,
  withEnrollment(),
  withProps(() => ({
    isPreEnrollmentEnabled: !paymentsExperiments.get('disablePreEnrollment'),
  }))
)(CourseSpecializationList);
