import * as React from 'react';

import gql from 'graphql-tag';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import { languageCodeToName } from 'js/lib/language';

import { Col, Row, StyleSheet, breakPoint, color, css } from '@coursera/coursera-ui';

import { CML } from 'bundles/cml';
import type { LogoIconType } from 'bundles/enterprise-legacy-xdp/components/miniPDP/bannerInfo/BannerInfoLogoText';
import BannerInfoLogoText from 'bundles/enterprise-legacy-xdp/components/miniPDP/bannerInfo/BannerInfoLogoText';
import BannerInfoText from 'bundles/enterprise-legacy-xdp/components/miniPDP/bannerInfo/BannerInfoText';
import type {
  BannerInfoBox as BannerInfoBoxType,
  BannerInfoBox_cmlLearningObjectives,
  BannerInfoBox_cmlLearningObjectives_cml as LearningObjectivesCML,
} from 'bundles/enterprise-legacy-xdp/components/miniPDP/bannerInfo/__generated__/BannerInfoBox';
import type { org_coursera_ondemand_common_DifficultyLevel as DifficultyLevel } from 'bundles/enterprise-legacy-xdp/components/miniPDP/bannerInfo/__generated__/globalTypes';
import usePageData from 'bundles/enterprise-legacy-xdp/components/miniPDP/data/usePageData';
import withFragments from 'bundles/graphql/components/withFragments';
import { getNaptimeCMLShape } from 'bundles/program-common/utils/xdpUtils';

import _t from 'i18n!nls/program-common';

const DIVIDER_COLOR = color.dividerThemeDark;

type LogoTextType = {
  logo: LogoIconType;
  text: string;
  hoverText?: React.ReactNode;
};

type PropsFromCaller = Omit<BannerInfoBoxType, '__typename'> & {
  canEnrollWithFullDiscount?: boolean;
};

type PropsToComponent = PropsFromCaller;

const styles = StyleSheet.create({
  container: {
    background: 'white',
    padding: 24,
    fontSize: '14px',
    lineHeight: '24px',
    [`@media (max-width: ${breakPoint.md - 1}px)`]: {
      padding: '16px 16px 24px',
    },
  },
  summaryContainer: {
    [`@media (max-width: ${breakPoint.md - 1}px) and (min-width: ${breakPoint.sm}px)`]: {
      borderRight: `1px solid ${DIVIDER_COLOR}`,
    },
    [`@media (max-width: ${breakPoint.sm - 1}px)`]: {
      border: '0',
    },
  },
  summary: {
    paddingBottom: '24px',
    borderBottom: `1px solid ${DIVIDER_COLOR}`,
    [`@media (max-width: ${breakPoint.md - 1}px) and (min-width: ${breakPoint.sm}px)`]: {
      border: '0',
    },
    [`@media (max-width: ${breakPoint.sm - 1}px)`]: {
      paddingBottom: '16px',
      borderBottom: `1px solid ${DIVIDER_COLOR}`,
    },
  },
  bannerInfoTextContainer: {
    margin: '12px 0 0',
  },
  bannerInfoLogoTextColumn: {
    [`@media (max-width: ${breakPoint.md - 1}px)`]: {
      ':nth-child(2n)': {
        paddingTop: '12px',
      },
    },
  },
  bannerInfoLogoTextContainer: {
    marginTop: '24px',
    [`@media (max-width: ${breakPoint.md - 1}px) and (min-width: ${breakPoint.sm}px)`]: {
      marginTop: '0',
    },
    [`@media (max-width: ${breakPoint.sm - 1}px)`]: {
      marginTop: '16px',
    },
  },
});

export const LEVEL_TO_ICON = {
  BEGINNER: 'SvgLevelBeginner',
  INTERMEDIATE: 'SvgLevelIntermediate',
  ADVANCED: 'SvgLevelAdvanced',
} as const;

const getLevelToString = (level: DifficultyLevel, canEnrollWithFullDiscount?: boolean): string => {
  const beginnerLevelText = canEnrollWithFullDiscount ? _t('Beginner-friendly') : _t('Beginner');
  const levels = {
    BEGINNER: beginnerLevelText,
    INTERMEDIATE: _t('Intermediate'),
    ADVANCED: _t('Advanced'),
  };
  return levels[level];
};

const concatenateLanguages = (languageCodes: string[]): string => {
  return languageCodes.length > 0 ? languageCodes.map(languageCodeToName).join(', ') : '';
};

/* TODO(dle): Commenting out for now. Use this when we want to add subtitles info.
const getLanguageInfo = (primaryLanguages: string[], subtitleLanguages: string[]): string => {
  const languages = concatenateLanguages(primaryLanguages);
  return subtitleLanguages.length > 0 ? _t('#{languageList} + subtitles', { languageList: languages }) : languages;
};

const getSubtitleTooltip = (subtitleLanguages: string[]): string | undefined => {
  const languages = concatenateLanguages(subtitleLanguages);
  return subtitleLanguages.length > 0 ? _t('Subtitles: #{subtitleList}', { subtitleList: languages }) : undefined;
};
*/
type ConstructInfoLogoTextArgs = {
  cmlRecommendedBackground: { cml: { dtdId: string; value: string } } | null;
  level: DifficultyLevel | null;
  primaryLanguages: Array<string>;
  workload: string | null;
  canEnrollWithFullDiscount?: boolean;
};

const constructInfoLogoText = ({
  level,
  cmlRecommendedBackground,
  workload,
  primaryLanguages,
  canEnrollWithFullDiscount,
}: ConstructInfoLogoTextArgs): Array<LogoTextType> => {
  const infoLogoText: Array<LogoTextType> = [];
  if (workload) {
    infoLogoText.push({ logo: 'SvgClock', text: workload });
  }
  if (level) {
    const hoverTextCML = cmlRecommendedBackground?.cml && (
      <CML cml={getNaptimeCMLShape(cmlRecommendedBackground.cml)} />
    );
    infoLogoText.push({
      logo: LEVEL_TO_ICON[level],
      text: getLevelToString(level, canEnrollWithFullDiscount),
      hoverText: hoverTextCML,
    });
  }
  infoLogoText.push({ logo: 'SvgCloudDownload', text: _t('No download needed') });
  infoLogoText.push({ logo: 'SvgVideo', text: _t('Split-screen video') });
  infoLogoText.push({
    logo: 'SvgCommentDots',
    text: concatenateLanguages(primaryLanguages),
  });
  infoLogoText.push({ logo: 'SvgLaptop', text: _t('Desktop only') });
  return infoLogoText;
};

const getInfoLogoTextColumns = (infoLogoText: Array<LogoTextType>): Array<Array<LogoTextType>> => {
  const length = infoLogoText.length;
  const midPoint = Math.ceil(length / 2); // use ceil to ensure first column is longer for odd number of elements
  return [infoLogoText.slice(0, midPoint), infoLogoText.slice(midPoint, length)];
};

const getLearningObjectives = (
  cmlLearningObjectives: BannerInfoBox_cmlLearningObjectives[],
  canEnrollWithFullDiscount: boolean | undefined
): BannerInfoBox_cmlLearningObjectives[] => {
  const extraObjectiveForFreeGuidedProjects = {
    __typename: 'XdpV1_cmlMember',
    cml: {
      __typename: 'XdpV1_org_coursera_ondemand_models_CmlContentType',
      dtdId: 'course/1',
      value: '<co-content><text>' + _t('Showcase this hands-on experience in an interview') + '</text></co-content>',
    } as LearningObjectivesCML,
  } as BannerInfoBox_cmlLearningObjectives;

  if (canEnrollWithFullDiscount) {
    return [...cmlLearningObjectives, extraObjectiveForFreeGuidedProjects];
  }
  return cmlLearningObjectives;
};

export const BannerInfoBox: React.FunctionComponent<PropsToComponent> = ({
  cmlRecommendedBackground,
  cmlLearningObjectives,
  primaryLanguages,
  workload,
  level,
  canEnrollWithFullDiscount,
}) => {
  const infoLogoText = constructInfoLogoText({
    level: level as DifficultyLevel,
    cmlRecommendedBackground,
    workload,
    primaryLanguages,
    canEnrollWithFullDiscount,
  });

  const { bannerInfoBoxViewController } = usePageData();

  return (
    <div {...css(styles.container)}>
      <Row>
        <Col sm={7} md={12} rootClassName={css(styles.summaryContainer).className}>
          <div className={css(styles.summary).className}>
            <FormattedMessage
              message={_t('In this {guidedProject}, you will:')}
              guidedProject={<strong>{bannerInfoBoxViewController.productTitle}</strong>}
            />
            <div {...css(styles.bannerInfoTextContainer)}>
              {getLearningObjectives(cmlLearningObjectives, canEnrollWithFullDiscount).map(({ cml }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <BannerInfoText key={`banner-info-text-${cml.dtdId}~${index}`} cml={cml} />
              ))}
            </div>
          </div>
        </Col>
        <Col sm={5} md={12}>
          <Row rootClassName={css(styles.bannerInfoLogoTextContainer).className}>
            {getInfoLogoTextColumns(infoLogoText).map((infoLogoTextColumn, colIndex) => (
              <Col
                md={6}
                // eslint-disable-next-line react/no-array-index-key
                key={`banner-info-logo-text-column-${colIndex}`}
                rootClassName={css(styles.bannerInfoLogoTextColumn).className}
              >
                {infoLogoTextColumn.map((info) => (
                  <BannerInfoLogoText
                    key={`banner-info-logo-text-${info.logo}`}
                    logo={info.logo}
                    value={info.text}
                    hoverText={info.hoverText}
                  />
                ))}
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default withFragments({
  BannerInfoBox: gql`
    fragment BannerInfoBox on XdpV1_org_coursera_xdp_cdp_CDPMetadata {
      level
      workload
      subtitleLanguages
      primaryLanguages
      avgLearningHoursAdjusted
      cmlRecommendedBackground {
        ... on XdpV1_cmlMember {
          cml {
            value
            dtdId
          }
        }
      }
      cmlLearningObjectives {
        ... on XdpV1_cmlMember {
          cml {
            value
            dtdId
          }
        }
      }
    }
  `,
})(BannerInfoBox);
