/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

// CDS
import { Colors, Grid, Typography2 } from '@coursera/cds-core';
import type { Svg } from '@coursera/coursera-ui/svg';
import { SvgLevelAdvanced, SvgLevelBeginner, SvgLevelIntermediate, SvgLevelMixed } from '@coursera/coursera-ui/svg';

const styles = {
  productDifficultyLabel: css`
    .difficulty-label-wrap {
      .difficulty {
        padding-left: 4px;
        color: var(--cds-color-grey-500);
      }
    }

    [dir='rtl'] {
      .difficulty-label-wrap {
        svg {
          -webkit-transform: scaleX(-1);
          -moz-transform: scaleX(-1);
          -o-transform: scaleX(-1);
          transform: scaleX(-1);
          filter: fliph;
          -ms-filter: 'FlipH';
        }
      }
    }
  `,
};

type Props = {
  productDifficultyLevel: string;
};

const mapLevelToSvg: Record<string, Svg | undefined> = {
  Beginner: SvgLevelBeginner,
  Intermediate: SvgLevelIntermediate,
  Advanced: SvgLevelAdvanced,
  Mixed: SvgLevelMixed,
};

const ProductDifficultyLabel = ({ productDifficultyLevel }: Props) => {
  // Unable to use CSS variables here because this needs to be set on the SVG
  // color attribute, not just in CSS.
  const props = { size: 18, color: Colors.gray[500] };
  const DifficultyLevelSvg = productDifficultyLevel && mapLevelToSvg[productDifficultyLevel];
  if (!DifficultyLevelSvg) return null;
  return (
    <div className="rc-ProductDifficultyLabel" css={styles.productDifficultyLabel}>
      <Grid container className="difficulty-label-wrap">
        <Grid item>
          <DifficultyLevelSvg {...props} />
        </Grid>
        <Grid item>
          <Typography2 component="span" variant="subtitleMedium" className="difficulty">
            {productDifficultyLevel}
          </Typography2>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProductDifficultyLabel;
