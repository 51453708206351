import * as React from 'react';

type Props = {
  width?: number;
  height?: number;
};

const MountainBlueSCG = ({ width = 48, height = 48 }: Props) => {
  return (
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35.64 20.62C37.85 29.34 29.58 37.76 20.78 35.19C13.61 33.09 8.66 24.61 11.35 17.42C16 4.91999 32.79 9.34999 35.64 20.62Z"
        fill="#D6EDF6"
      />
      <path
        d="M29.02 19.1201L28.59 7.06006L38.34 9.76006L28.83 14.5601"
        stroke="black"
        strokeWidth="0.81"
        strokeLinejoin="round"
      />
      <path
        d="M41.78 40.94H6.22L15.84 24.78L21.61 31.23L29.08 18.53L36.55 31.23L41.78 40.94Z"
        stroke="black"
        strokeWidth="0.81"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MountainBlueSCG;
