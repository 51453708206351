/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Typography, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

import GoogleFullLogoSVG from 'bundles/enterprise-admin-grow-with-google/components/svgs/GoogleFullLogoSVG';

import _t from 'i18n!nls/enterprise-admin-grow-with-google';

const googleBannerContainer = (theme: Theme) =>
  css({
    backgroundColor: 'var(--cds-color-blue-50)',
    paddingTop: 'var(--cds-spacing-400)',
    paddingBottom: 'var(--cds-spacing-400)',
  });

const googleBannerDescription = (theme: Theme) =>
  css({
    '&&': {
      fontSize: '24px',
      color: 'var(--cds-color-blue-900)',
    },
  });

const googleBannerBody = (theme: Theme) =>
  css({
    maxWidth: '1224px',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '0 var(--cds-spacing-150)',
    display: 'flex',
  });

const topInstructorStyle = (theme: Theme) =>
  css({
    color: 'var(--cds-color-white-0)',
    backgroundColor: 'var(--cds-color-blue-950)',
    border: `1px solid var(--cds-color-blue-950)`,
    borderRadius: '4px',
    display: 'inline-block',
    padding: '0 var(--cds-spacing-100)',
    '&&': {
      fontWeight: 'bold',
      fontSize: '12px',
    },
    maxHeight: '20px',
    marginBottom: 'var(--cds-spacing-200)',
    whiteSpace: 'nowrap',
  });

const googleLogoSection = (theme: Theme) =>
  css({
    marginRight: 'var(--cds-spacing-300)',
  });

const GoogleBanner = () => {
  const theme = useTheme();
  return (
    <div css={googleBannerContainer(theme)}>
      <div css={googleBannerBody(theme)}>
        <div css={googleLogoSection(theme)}>
          <span css={topInstructorStyle(theme)}>{_t('TOP INSTRUCTOR')}</span>
          <GoogleFullLogoSVG />
        </div>
        <div>
          <Typography css={googleBannerDescription(theme)} variant="h1semibold" component="p">
            {_t(
              '75% of Google Career Certificate Graduates in the United States report an improvement in their career trajectory (e.g. new job or career, promotion or raise) within 6 months of certificate completion*.'
            )}
          </Typography>
          <Typography>{_t('*Based on program graduate survey, United States 2022.')}</Typography>
        </div>
      </div>
    </div>
  );
};

export default GoogleBanner;
