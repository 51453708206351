/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { MED_DATE_ONLY_DISPLAY, formatDateTimeDisplay } from 'js/utils/DateTimeUtils';

import { Typography2 } from '@coursera/cds-core';
import { ErrorIcon, TriangleFlagIcon } from '@coursera/cds-icons';

import _t from 'i18n!nls/course-cards';

const styles = {
  container: css(`
    font-weight: bold;
    font-size: 12px;
    display: flex;
    align-items: flex-start;
    gap: 4px;
    margin-top: 18px;
    text-transform: uppercase;

    @media (max-width: 575px) {
      margin-top: 0;
    }
  `),
  container2: css(`
    display: flex;
    align-items: flex-start;
    gap: 4px;
    margin-top: 18px;

    @media (max-width: 575px) {
      margin-top: 0;
    }
  `),
};

type Props = {
  endsAt: string;
  isEnded: boolean;
};

const SessionEndInfo: React.FC<Props> = ({ endsAt, isEnded }) => {
  if (isEnded) {
    return (
      <Typography2 component="div" css={styles.container2} variant="subtitleMedium">
        <ErrorIcon size="medium" color="error" />{' '}
        {_t('Ended on #{endsAt}', { endsAt: formatDateTimeDisplay(endsAt, MED_DATE_ONLY_DISPLAY) })}
      </Typography2>
    );
  } else {
    return (
      <Typography2 component="div" css={styles.container2} variant="subtitleMedium">
        <TriangleFlagIcon size="medium" />{' '}
        {_t('Ends on #{endsAt}', { endsAt: formatDateTimeDisplay(endsAt, MED_DATE_ONLY_DISPLAY) })}
      </Typography2>
    );
  }
};

export default SessionEndInfo;
