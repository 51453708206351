import Fluxible from 'vendor/cnpm/fluxible.v0-4/index';

import NaptimeStore from 'bundles/naptimejs/stores/NaptimeStore';
import ApplicationStore from 'bundles/ssr/stores/ApplicationStore';

import routes from './routes';

export type Stores = {
  ApplicationStore: ApplicationStore;
  NaptimeStore: NaptimeStore;
};

const appEnv = new Fluxible({
  component: routes,
});

appEnv.registerStore(ApplicationStore);
appEnv.registerStore(NaptimeStore);

export default appEnv;
