import uniq from 'lodash/uniq';

import type { EnrollmentChoices } from 'bundles/enroll/components/xdp/withEnrollment';
import { MIX_AND_MATCH_ORDER } from 'bundles/enroll/constants/constants';
import subscriptionPriceUtils from 'bundles/enroll/utils/subscriptionPriceUtils';
import type OnDemandSpecializationsV1 from 'bundles/naptimejs/resources/onDemandSpecializations.v1';
import type { Specialization } from 'bundles/program-common/types/xdpTypes';
import BillingCycleType from 'bundles/subscriptions/common/BillingCycleType';

export type CommonSpecialization = OnDemandSpecializationsV1 | Specialization;

const s12nOrderMap: Record<string, Array<string>> = {};

export const getS12nOrder = (courseId: string, s12ns: Array<CommonSpecialization>): Array<CommonSpecialization> => {
  // don't cache the server-rendered order because it won't match the client-rendered order
  if (typeof window === 'undefined') {
    return s12ns;
  }

  if (!s12nOrderMap[courseId]) {
    const s12nSlugs = s12ns.map(({ slug }) => slug);

    // XdpV1Resource can reuturn multiple versions of the same s12n, so get unique by slug
    // This does not cause problems with enrollment because EAC derives its own list of s12nIds
    // and this function is purely used for display purposes to show all SDP links
    const uniqueS12nSlugs = uniq(s12nSlugs);

    // order is based on the hardcoded MIX_AND_MATCH_ORDER list for specific s12ns. If the s12n is not in the list, show it at the bottom
    uniqueS12nSlugs.sort((slugOne, slugTwo) => {
      const slugOneIndex = MIX_AND_MATCH_ORDER.indexOf(slugOne);
      const slugTwoIndex = MIX_AND_MATCH_ORDER.indexOf(slugTwo);

      if (slugTwoIndex === -1) {
        return -1;
      } else if (slugOneIndex === -1) {
        return 1;
      } else if (slugOneIndex < slugTwoIndex) {
        return -1;
      } else {
        return 1;
      }
    });

    s12nOrderMap[courseId] = uniqueS12nSlugs;
  }

  return (
    s12nOrderMap[courseId]
      // We know that s12n.find will find specialization, so we cast it as CommonSpecialization
      .map((s12nId) => s12ns.find(({ slug }) => s12nId === slug) as CommonSpecialization)
      // XdpV1Resource can return non-equal amounts of s12ns on subsequent renders,
      // so filter out missing s12ns (the missing ones are usually hidden / private)
      // TODO investigate both issues on the BE
      .filter((s12n) => !!s12n)
      // Do not display google certs, these only get traffic from google.com.
      .filter((s12n) => s12n.productVariant !== 'GoogleCertificateS12n')
  );
};

type EnrollmentData = {
  enrollmentAvailableChoices?: EnrollmentChoices;
  isFromS12nSelection?: boolean;
  s12nId: string;
};

// used for mix and match when the user has selected to enroll in a specific s12n among several s12ns
const getSelectedS12nEnrollmentData = ({ enrollmentAvailableChoices, isFromS12nSelection, s12nId }: EnrollmentData) => {
  return isFromS12nSelection ? enrollmentAvailableChoices?.getS12nSubscriptionEnrollmentData(s12nId) : undefined;
};

export const getS12nMonthlySkuId = ({ enrollmentAvailableChoices, isFromS12nSelection, s12nId }: EnrollmentData) => {
  if (!enrollmentAvailableChoices) {
    return undefined;
  }

  const selectedS12nEnrollmentData = getSelectedS12nEnrollmentData({
    enrollmentAvailableChoices,
    isFromS12nSelection,
    s12nId,
  });
  let availableS12nSubscriptions;

  if (selectedS12nEnrollmentData) {
    availableS12nSubscriptions = enrollmentAvailableChoices.getS12nSubscriptionProducts(s12nId);
  } else {
    ({ availableS12nSubscriptions } = enrollmentAvailableChoices);
  }

  if (availableS12nSubscriptions) {
    return subscriptionPriceUtils.getSubscriptionSkuId(availableS12nSubscriptions, BillingCycleType.MONTHLY);
  } else {
    return undefined;
  }
};

export const getCanEnrollThroughS12nSubscriptionFreeTrial = ({
  enrollmentAvailableChoices,
  isFromS12nSelection,
  s12nId,
}: EnrollmentData): boolean => {
  if (!enrollmentAvailableChoices) {
    return false;
  }

  const selectedS12nEnrollmentData = getSelectedS12nEnrollmentData({
    enrollmentAvailableChoices,
    isFromS12nSelection,
    s12nId,
  });

  if (selectedS12nEnrollmentData) {
    return enrollmentAvailableChoices.getCanEnrollThroughS12nSubscriptionFreeTrial(selectedS12nEnrollmentData);
  } else {
    return enrollmentAvailableChoices.canEnrollThroughS12nSubscriptionFreeTrial;
  }
};

export const getCanEnrollThroughS12nSubscription = ({
  enrollmentAvailableChoices,
  isFromS12nSelection,
  s12nId,
}: EnrollmentData): boolean => {
  if (!enrollmentAvailableChoices) {
    return false;
  }

  const selectedS12nEnrollmentData = getSelectedS12nEnrollmentData({
    enrollmentAvailableChoices,
    isFromS12nSelection,
    s12nId,
  });

  if (selectedS12nEnrollmentData) {
    return enrollmentAvailableChoices.getCanEnrollThroughS12nSubscription(selectedS12nEnrollmentData);
  } else {
    return enrollmentAvailableChoices.canEnrollThroughS12nSubscription;
  }
};
