import * as React from 'react';
import { useContext } from 'react';

import type { ApolloError } from '@apollo/client';

import type { MixedCollectionsBySkill } from 'bundles/program-personalized-tab/types/sharedTypes';

export type SelectedSkillRecsContextType = {
  recs: MixedCollectionsBySkill;
  loading?: boolean;
  error?: ApolloError | undefined;
};

const SelectedSkillRecsContext = React.createContext<SelectedSkillRecsContextType>({ recs: {} });

export const useSelectedSkillRecsData = () => {
  const selectedSkillRecsContext = useContext(SelectedSkillRecsContext);
  return selectedSkillRecsContext;
};

export default SelectedSkillRecsContext;
