import Q from 'q';

import API from 'js/lib/api';
import type { ApiOptions } from 'js/lib/api';

import type { InjectedNaptime, MutationOptions } from 'bundles/naptimejs';
import EnterpriseEventsV1 from 'bundles/naptimejs/resources/enterpriseEvents.v1';
import EnterpriseProgramsV1 from 'bundles/naptimejs/resources/enterprisePrograms.v1';
import ProgramInvitationsV1 from 'bundles/naptimejs/resources/programInvitations.v1';
import ProgramMembershipsV2 from 'bundles/naptimejs/resources/programMemberships.v2';
import ProgramSwitcherSelectionsV1 from 'bundles/naptimejs/resources/programSwitcherSelections.v1';
import type { SwitcherSelectionType } from 'bundles/program-home/constants/SwitcherSelectionTypes';

const API_OPTIONS: ApiOptions = { type: 'rest' };
const USER_EMAILS_API = API('/api/grpc/useremails/v1beta1/UserEmailsAPI', API_OPTIONS);

type Program = { id: string };

class ProgramLearnerApiManager {
  declare _naptime: InjectedNaptime;

  declare _program: Program;

  declare _resourceName: string;

  constructor({ program, naptime }: { program: Program; naptime: InjectedNaptime }) {
    this._naptime = naptime;
    this._program = program;
    this._resourceName = EnterpriseProgramsV1.RESOURCE_NAME;
  }

  set program(program: Program) {
    this._program = program;
  }

  getRefreshDataPromise(resources: string[]) {
    return this._naptime.refreshData({ resources });
  }

  getJoinProgramPromise({ programId, userId }: { programId: string; userId: number }, options?: MutationOptions) {
    return this._naptime.executeMutation(ProgramInvitationsV1.action('join', {}, { programId, userId }), options);
  }

  getResendEmailPromise(
    { programMembershipId, redirectUrl }: { programMembershipId: string; redirectUrl?: string },
    options?: MutationOptions
  ) {
    return this._naptime.executeMutation(
      ProgramMembershipsV2.action('resendVerificationEmail', {}, { id: programMembershipId, redirectUrl }),
      options
    );
  }

  getProgramSwitcherUpdatePromise(
    { programId, userId }: { programId: string; userId: number },
    options?: MutationOptions
  ) {
    const request = programId ? { programId } : {};
    return this._naptime.executeMutation(
      ProgramSwitcherSelectionsV1.update(userId, {
        selectionType: 'PROGRAM',
        ...request,
      }),
      options
    );
  }

  getDeleteProgramPromise({ id }: { id: string }, options?: MutationOptions) {
    return this._naptime.executeMutation(
      EnterpriseProgramsV1.action('archive', {}, { id: id || this._program.id }),
      options
    );
  }

  setAlternativeEmailEventPromise({ userId }: { userId: number }) {
    const now = new Date().getTime();
    const eventId = `${userId}~genericEventKey!~ALTERNATIVE_EMAIL_PROMPT_DISMISSED`;
    return this._naptime.executeMutation(
      EnterpriseEventsV1.action('addTimestampForEvents', {}, { ids: [eventId], timestamp: now })
    );
  }

  setAlternativeEmailPromise({ email, userId }: { email: string; userId: number }) {
    return Q(
      USER_EMAILS_API.post('InsertUserEmail', {
        data: {
          isPrimary: false,
          isVerified: false,
          emailAddress: email,
          userId,
        },
      })
    );
  }

  setSwitcher({ userId, selectionType }: { userId: number; selectionType: SwitcherSelectionType }) {
    return this._naptime.executeMutation(
      ProgramSwitcherSelectionsV1.update(userId, {
        selectionType,
      })
    );
  }
}

export default ProgramLearnerApiManager;
