import Uri from 'jsuri';
import uniq from 'lodash/uniq';

import logger from 'js/app/loggerSingleton';
import memoize from 'js/lib/memoize';
import { simplifyQueryValue } from 'js/lib/queryUtils';
import Retracked from 'js/lib/retracked';
import type { EventData, useRetracked } from 'js/lib/retracked';
import { tupleToStringKey } from 'js/lib/stringKeyTuple';
import type { useLocation } from 'js/lib/useRouter';
import user from 'js/lib/user';
import type UserAgentInfo from 'js/lib/useragent';

import CourseRoles from 'bundles/common/constants/CourseRoles';
import type { Program, ThirdPartyOrganization } from 'bundles/enroll-course/common/Enterprise';
import type { EnrollmentChoices } from 'bundles/enroll/components/xdp/withEnrollment';
import Group from 'bundles/groups/models/Group';
import type PromotionDetailsV1 from 'bundles/naptimejs/resources/promotionDetails.v1';
import type PromotionEligibilitiesV1 from 'bundles/naptimejs/resources/promotionEligibilities.v1';
import { SPECIALIZATION, VERIFIED_CERTIFICATE } from 'bundles/payments/common/ProductType';
import API from 'bundles/phoenix/lib/apiWrapper';
import { isFreeReferralForFriendEnabled, sendBlankPurchaseEventToFriendbuy } from 'bundles/referral/utils/paymentUtils';

export const canPurchaseSingleCourseAndHasFullDiscount = ({
  enrollmentAvailableChoices,
  promotionEligibilities,
  promotionDetails,
}: {
  enrollmentAvailableChoices: EnrollmentChoices;
  promotionEligibilities: PromotionEligibilitiesV1 | null | undefined;
  promotionDetails: PromotionDetailsV1 | null | undefined;
}) => {
  return !!(
    enrollmentAvailableChoices.canPurchaseSingleCourse &&
    promotionEligibilities?.isEligible &&
    promotionDetails?.discountPercent === 100
  );
};

// This is a separate flow from the `ENROLL_COURSE_WITH_FULL_DISCOUNT` method in `enrollmentChoiceUtils`
// This is for 100% free standalone courses whereas that is for 100% free Guided Projects; ideally both should be using `ENROLL_COURSE_WITH_FULL_DISCOUNT`
// See  https://coursera.slack.com/archives/CAH3JRZ9V/p1655250294033369?thread_ts=1655248493.443849&cid=CAH3JRZ9V for more context
export const enrollWithFullDiscountPromotion = ({
  promotionEligibilities,
  promotionDetails,
  location,
  handleFriendbuyScriptLoadSuccess,
  handleFriendbuyScriptLoadFailure,
  userAgent,
  _eventData,
  track,
}: {
  promotionEligibilities: PromotionEligibilitiesV1 | null | undefined;
  promotionDetails: PromotionDetailsV1 | null | undefined;
  location: ReturnType<typeof useLocation>;
  handleFriendbuyScriptLoadSuccess: (ev?: Event) => void;
  handleFriendbuyScriptLoadFailure: (scriptError?: string | Event) => void;
  userAgent?: UserAgentInfo;
  _eventData?: EventData;
  track?: ReturnType<typeof useRetracked>;
}) => {
  const EnrollFullDiscountPostAPI = API('/api/userEntitlements.v1/', { type: 'rest' });
  const productId = promotionEligibilities?.productId ?? '';
  const promoCode = promotionEligibilities?.promoCodeId ?? '';
  const referralSource = location.query?.referral;

  // TODO handle null/undefined case for referralSource
  if (isFreeReferralForFriendEnabled(simplifyQueryValue(referralSource) ?? '', promoCode)) {
    if (_eventData) {
      Retracked.trackComponent(_eventData, { promotionDetails }, 'free_referral_for_friend_enabled', 'track');
    } else {
      track?.({
        trackingData: { promotionDetails },
        trackingName: 'free_referral_for_friend_enabled',
        action: 'track',
      });
    }
    sendBlankPurchaseEventToFriendbuy(handleFriendbuyScriptLoadSuccess, handleFriendbuyScriptLoadFailure, userAgent);
  }

  const uri = new Uri()
    .addQueryParam('action', 'enrollWithFullDiscountPromotion')
    .addQueryParam('productId', productId)
    .addQueryParam('promoCode', promoCode);
  return Promise.resolve(EnrollFullDiscountPostAPI.post(uri.toString()));
};

/**
 * @param  {String} courseId
 * @return {Promise} String. Null on error
 */
export const getCourseStatusPromise = memoize((courseId) => {
  const coursesApi = API('/api/courses.v1', { type: 'rest' });
  return Promise.resolve(coursesApi.get(courseId + '?fields=courseStatus&showHidden=true'))
    .then((res) => res.elements[0].courseStatus)
    .catch(() => {
      logger.error(`Unable to get course status for course ${courseId}`);
      return null;
    });
});

type ProgramsOrGroupsInput = { courseId: string; s12nId?: string } | { courseId?: string; s12nId: string };

export type ProgramsData = {
  program?: Program;
  programs?: Program[];
  thirdPartyOrganization?: ThirdPartyOrganization;
  thirdPartyOrganizations?: ThirdPartyOrganization[];
};

/**
 * Enroll in a course or s12n using program
 * @param  {Object} courseId, s12nId
 * @return {Promise} True on success, false on error
 */
export const getAvailablePrograms = ({ courseId, s12nId }: ProgramsOrGroupsInput): Promise<ProgramsData> => {
  if (!user.isAuthenticatedUser() || !(courseId || s12nId)) {
    return Promise.resolve({});
  }

  const programEnrollmentAPI = API('/api/programEnrollments.v2/', {
    type: 'rest',
  });
  const thirdPartyOrgAPI = API('/api/thirdPartyOrganizations.v1/', {
    type: 'rest',
  });

  const uri = new Uri()
    .addQueryParam('includes', 'enterprisePrograms')
    .addQueryParam('fields', 'enterprisePrograms.v1(metadata,thirdPartyOrganizationId)')
    .addQueryParam('userId', user.get().id);

  if (courseId) {
    uri.addQueryParam('q', 'availableProgramsForUserAndCourseId').addQueryParam('courseId', courseId);
  } else if (s12nId) {
    uri.addQueryParam('q', 'availableProgramsForUserAndS12nId').addQueryParam('s12nId', s12nId);
  }

  return Promise.resolve(programEnrollmentAPI.get(uri.toString())).then((res) => {
    const program = res.linked['enterprisePrograms.v1'][0];
    const programs = res.linked['enterprisePrograms.v1'];
    const uri2 = new Uri()
      .addQueryParam('fields', 'name')
      .addQueryParam(
        'ids',
        uniq(programs.map((mappedProgram: Program) => mappedProgram.thirdPartyOrganizationId)).join(',')
      );

    if (programs?.length) {
      return Promise.resolve(thirdPartyOrgAPI.get(uri2.toString())).then(({ elements }) => {
        const thirdPartyOrganization = elements.find(
          (element: ThirdPartyOrganization) => element.id === program.thirdPartyOrganizationId
        );
        const thirdPartyOrganizations = elements;
        return { program, programs, thirdPartyOrganization, thirdPartyOrganizations };
      });
    } else {
      return Promise.resolve({ programs });
    }
  });
};

export type InvitedProgramsData = {
  invitedPrograms?: Program[];
  invitedThirdPartyOrganizations?: ThirdPartyOrganization[];
};

export const getAvailableInvitedPrograms = ({
  courseId,
  s12nId,
}: ProgramsOrGroupsInput): Promise<InvitedProgramsData> => {
  if (!user.isAuthenticatedUser() || !(courseId || s12nId)) {
    return Promise.resolve({});
  }

  const programEnrollmentAPI = API('/api/programEnrollments.v2/', {
    type: 'rest',
  });
  const thirdPartyOrgAPI = API('/api/thirdPartyOrganizations.v1/', {
    type: 'rest',
  });
  const uri = new Uri()
    .addQueryParam('q', 'availableInvitedProgramsForProduct')
    .addQueryParam('includes', 'enterprisePrograms')
    .addQueryParam('fields', 'enterprisePrograms.v1(metadata,thirdPartyOrganizationId)')
    .addQueryParam('userId', user.get().id);

  if (courseId) {
    uri.addQueryParam('productId', tupleToStringKey([VERIFIED_CERTIFICATE, courseId]));
  } else if (s12nId) {
    uri.addQueryParam('productId', tupleToStringKey([SPECIALIZATION, s12nId]));
  }

  return Promise.resolve(programEnrollmentAPI.get(uri.toString())).then((res) => {
    const invitedPrograms = res.linked['enterprisePrograms.v1'];
    const uri2 = new Uri()
      .addQueryParam('fields', 'name')
      .addQueryParam(
        'ids',
        uniq(invitedPrograms.map((program: Program) => program.thirdPartyOrganizationId)).join(',')
      );

    if (invitedPrograms?.length) {
      return Promise.resolve(thirdPartyOrgAPI.get(uri2.toString())).then(({ elements }) => {
        const invitedThirdPartyOrganizations = elements;
        return { invitedPrograms, invitedThirdPartyOrganizations };
      });
    } else {
      return Promise.resolve({ invitedPrograms });
    }
  });
};

export type GroupsData = { group?: Group };

/**
 * Available Groups to use in enrollment in a course or s12n for current user
 * @param  {String} courseId
 * @return {Promise} Group info on success
 */
export const getAvailableGroups = ({ courseId, s12nId }: ProgramsOrGroupsInput): Promise<GroupsData> => {
  const groupEnrollmentAPI = API('/api/groupEnrollments.v1/', { type: 'rest' });

  if (!user.isAuthenticatedUser() || !(courseId || s12nId)) {
    return Promise.resolve({});
  }

  const uri = new Uri().addQueryParam('includes', 'groups').addQueryParam('userId', user.get().id);

  if (courseId) {
    uri.addQueryParam('q', 'availableGroupsForUserAndCourseId').addQueryParam('courseId', courseId);
  } else if (s12nId) {
    uri.addQueryParam('q', 'availableGroupsForUserAndS12nId').addQueryParam('s12nId', s12nId);
  }

  return Promise.resolve(groupEnrollmentAPI.get(uri.toString())).then((res) => {
    const group = new Group(res.linked['groups.v1'][0]);
    return { group };
  });
};

/**
 * @param  {String} courseId
 * @return {String} Value in CourseRoles. Null if no desired role found.
 */
export const getFreeEnrollCourseRolePromise = (courseId: string): Promise<keyof typeof CourseRoles | undefined> => {
  if (!user.isAuthenticatedUser()) {
    return Promise.resolve(undefined);
  }

  return getCourseStatusPromise(courseId).then((courseStatus) => {
    if (courseStatus === 'launched') {
      return CourseRoles.LEARNER;
    } else if (courseStatus === 'preenroll') {
      return CourseRoles.PRE_ENROLLED_LEARNER;
    } else {
      return undefined;
    }
  });
};

/**
 * Enroll in a course using program
 * @param  {String} courseId
 * @param  {String} programId
 * @return {Promise} True on success, false on error
 */
export const enrollInCourseWithProgram = (courseId: string, programId: string): Promise<boolean> => {
  const programEnrollmentAPI = API('/api/programEnrollments.v2/', {
    type: 'rest',
  });

  if (!user.isAuthenticatedUser()) {
    return Promise.resolve(false);
  }

  const uri = new Uri()
    .addQueryParam('action', 'enrollInCourse')
    .addQueryParam('programId', programId)
    .addQueryParam('userId', user.get().id)
    .addQueryParam('courseId', courseId);

  return Promise.resolve(programEnrollmentAPI.post(uri.toString())).then((res) => !!res);
};

/**
 * Enroll in a s12n using program
 * @param  {String} s12nId
 * @param  {String} programId
 * @return {Promise} True on success, false on error
 */
export const enrollInS12nWithProgram = (s12nId: string, programId: string): Promise<boolean> => {
  const programEnrollmentAPI = API('/api/programEnrollments.v2/', {
    type: 'rest',
  });

  if (!user.isAuthenticatedUser()) {
    return Promise.resolve(false);
  }

  const uri = new Uri()
    .addQueryParam('action', 'enrollInS12n')
    .addQueryParam('programId', programId)
    .addQueryParam('userId', user.get().id)
    .addQueryParam('s12nId', s12nId);

  return Promise.resolve(programEnrollmentAPI.post(uri.toString())).then((res) => !!res);
};

/**
 * Enroll in a course using group
 * @param  {String} courseId
 * @param  {String} groupId
 * @return {Promise} True on success, false on error
 */
export const enrollInCourseWithGroup = (courseId: string, groupId: string): Promise<boolean> => {
  const groupEnrollmentAPI = API('/api/groupEnrollments.v1/', { type: 'rest' });

  if (!user.isAuthenticatedUser()) {
    return Promise.resolve(false);
  }

  const uri = new Uri()
    .addQueryParam('action', 'enrollInCourse')
    .addQueryParam('groupId', groupId)
    .addQueryParam('userId', user.get().id)
    .addQueryParam('courseId', courseId);

  return Promise.resolve(groupEnrollmentAPI.post(uri.toString())).then((res) => !!res);
};

/**
 * Enroll in a s12n using group
 * @param  {String} s12nId
 * @param  {String} groupId
 * @return {Promise} True on success, false on error
 */
export const enrollInS12nWithGroup = (s12nId: string, groupId: string): Promise<boolean> => {
  const groupEnrollmentAPI = API('/api/groupEnrollments.v1/', { type: 'rest' });

  if (!user.isAuthenticatedUser()) {
    return Promise.resolve(false);
  }

  const uri = new Uri()
    .addQueryParam('action', 'enrollInS12n')
    .addQueryParam('groupId', groupId)
    .addQueryParam('userId', user.get().id)
    .addQueryParam('s12nId', s12nId);

  return Promise.resolve(groupEnrollmentAPI.post(uri.toString())).then((res) => !!res);
};

/**
 * Enroll in a course for free
 * @param  {String} courseId
 * @return {Promise} True on success, false on error
 */
export const enrollInCourseForFreePromise = (
  courseId: string,
  _courseRole?: keyof typeof CourseRoles
): Promise<boolean> => {
  const openCourseMembershipsApi = API('/api/openCourseMemberships.v1', {
    type: 'rest',
  });

  if (!user.isAuthenticatedUser()) {
    return Promise.resolve(false);
  }

  return Promise.resolve(_courseRole || getFreeEnrollCourseRolePromise(courseId)).then((courseRole) => {
    if (!courseRole) {
      return Promise.resolve(false);
    }

    const postData = {
      userId: user.get().id,
      courseId,
      courseRole,
    };

    return Promise.resolve(openCourseMembershipsApi.post('', { data: postData })).then((res) => !!res);
  });
};
