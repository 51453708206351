import { requireFields } from '../util/requireFieldsDecorator';
import NaptimeResource from './NaptimeResource';

export type EnterpriseSetting = {
  id: string;
  setting: { typeName: string; definition: { value: boolean | string | number } };
};

class EnterpriseSettings extends NaptimeResource {
  static RESOURCE_NAME = 'enterpriseSettings.v1';

  declare setting: EnterpriseSetting['setting'];

  @requireFields('setting')
  get value() {
    return this.setting.definition.value;
  }
}

export default EnterpriseSettings;
