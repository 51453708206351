import * as React from 'react';

import { VisuallyHidden, useId } from '@coursera/cds-core';

import { FloatingTooltip } from 'bundles/common/components/Tooltip';
import type { Placement } from 'bundles/common/components/Tooltip';

import 'css!./__styles__/ProgressTrackerPopover';

type ProgressTrackerPopoverProps = {
  popoverContent: React.ReactNode;
  placement?: Placement;
  defaultOverlayShown?: boolean;
  children: React.ReactNode;
};

const ProgressTrackerPopover: React.FC<ProgressTrackerPopoverProps> = (props) => {
  const { children, placement = 'top', popoverContent, ...rest } = props;
  const tooltip = <div>{popoverContent}</div>;
  const id = useId();

  return (
    <FloatingTooltip
      placement={placement}
      message={tooltip}
      variant="secondary"
      {...rest}
      id="rc-ProgressTrackerPopover-Popover"
      className="progressTrackerPopover"
    >
      <g tabIndex={0} aria-labelledby={id}>
        {React.Children.only(children)}
        <foreignObject>
          <VisuallyHidden id={id} className="rc-A11yScreenReaderOnly">
            {tooltip}
          </VisuallyHidden>
        </foreignObject>
      </g>
    </FloatingTooltip>
  );
};

export default ProgressTrackerPopover;
