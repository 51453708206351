/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useContext, useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import type { AiCoach_CoachSettingsProfilePreferenceInput } from '__generated__/graphql-types';
import isEqual from 'lodash/isEqual';

import config from 'js/app/config';
import { FormattedHTMLMessage } from 'js/lib/coursera.react-intl';

import { Checkbox, CheckboxGroup, Link, Typography2 } from '@coursera/cds-core';
import { useTracker } from '@coursera/event-pulse/react';

import { CoachButton } from 'bundles/ai-coach-platform/components/building-blocks';
import { CoachSettingsContext } from 'bundles/ai-coach-platform/components/patterns/chat/settings/SettingsContextProvider';
import SettingsSwitch from 'bundles/ai-coach-platform/components/patterns/chat/settings/SettingsSwitch';
import { SettingsView } from 'bundles/ai-coach-platform/components/patterns/chat/settings/types';
import type { ProfileInfoField } from 'bundles/ai-coach-platform/components/patterns/chat/settings/types';
import { useSettingsNotification } from 'bundles/ai-coach-platform/components/patterns/chat/settings/useSettingsNotification';
import {
  getProfileFieldName,
  getProfileFields,
} from 'bundles/ai-coach-platform/components/patterns/chat/settings/utils';
import type {
  AiCoachPlatform_SaveCoachUserSettingsMutation as SaveCoachUserSettingsMutation,
  AiCoachPlatform_SaveCoachUserSettingsMutationVariables as SaveCoachUserSettingsMutationVariables,
} from 'bundles/ai-coach-platform/queries/__generated__/aiCoachSaveUserSettingsMutation';
import aiCoachSaveUserSettingsMutation from 'bundles/ai-coach-platform/queries/aiCoachSaveUserSettingsMutation.graphql';
import { captureMessageInSentry } from 'bundles/ai-coach-platform/utils/sentry';
import { useCoachTrackingContext } from 'bundles/ai-coach-platform/utils/tracking';

import _t from 'i18n!nls/ai-coach-platform';

import SettingsPage from '../SettingsPage';

const styles = {
  root: css`
    height: 100%;
  `,
  profileFields: css`
    margin-top: var(--cds-spacing-300);

    /* CDS overrides */
    --cds-color-interactive-primary: var(--cds-color-blue-600);

    .cds-checkboxAndRadio-labelContent {
      color: var(--cds-color-neutral-primary);
    }
  `,
  notification: css`
    position: absolute;
    bottom: 96px;
    max-width: 500px;
    z-index: 99999;
    margin: 0 auto;
    left: 0;
    right: 0;
  `,
};

type Props = {
  onBack: () => void;
};

export const SettingsProfileInfo = (props: Props): React.ReactElement => {
  const { onBack } = props;
  const track = useTracker();
  const trackingData = useCoachTrackingContext()?.data;
  const { updateData, data } = useContext(CoachSettingsContext);
  const { addNotification, clearNotification } = useSettingsNotification();
  const [saveSettings, { loading: isSaving }] = useMutation<
    SaveCoachUserSettingsMutation,
    SaveCoachUserSettingsMutationVariables
  >(aiCoachSaveUserSettingsMutation, {
    context: { clientName: 'gatewayGql' },
  });

  const [selectedProfileFields, setSelectedFields] = useState<Array<string>>([]);
  const [toggle, setToggle] = useState<boolean>(!!data.profileData?.enabled);
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  React.useEffect(() => {
    track('view_coach_settings', {
      pageType: 'profileData',
      coach: {
        mode: trackingData?.mode,
      },
    });
  }, []);

  React.useEffect(() => {
    return () => {
      clearNotification();
    };
  }, [clearNotification]);

  const handleCheckboxChange = (value: string, checked: boolean) => {
    const profileFields = data.profileData?.fields;

    if (!profileFields) {
      return;
    }

    const currentSelectedFields = Object.keys(profileFields).filter(
      (key) => profileFields[key as ProfileInfoField].enabled === true
    );
    const newFieldsSet = new Set(selectedProfileFields);

    if (checked) {
      newFieldsSet.add(value);
    } else {
      newFieldsSet.delete(value);
    }

    setSelectedFields([...newFieldsSet]);

    setHasChanges(!isEqual(newFieldsSet, new Set(currentSelectedFields)));

    track('interact_coach_settings', {
      settingsInteractionType: `${checked ? 'select' : 'unselect'}_profileDataItem`,
      settingsMetadata: {
        name: value,
      },
      coach: {
        mode: trackingData?.mode,
      },
    });
  };

  const saveProfilePreference = async (
    {
      enableProfile,
      enableEducationHistory,
      enableWorkHistory,
      enableLocation,
    }: {
      enableProfile?: boolean;
      enableEducationHistory?: boolean;
      enableWorkHistory?: boolean;
      enableLocation?: boolean;
    },
    onError?: () => void
  ) => {
    clearNotification();

    const profilePreferenceInput: AiCoach_CoachSettingsProfilePreferenceInput = {
      enableAdditionalInfo: false, // currently unused
      enableLocation:
        typeof enableLocation === 'undefined' ? !!data.profileData?.fields?.location.enabled : enableLocation,
      enableProfile: typeof enableProfile === 'undefined' ? !!data.profileData?.enabled : enableProfile,
      enableEducationHistory:
        typeof enableEducationHistory === 'undefined'
          ? !!data.profileData?.fields?.educationHistory.enabled
          : enableEducationHistory,
      enableWorkHistory:
        typeof enableWorkHistory === 'undefined' ? !!data.profileData?.fields?.workHistory.enabled : enableWorkHistory,
    };

    saveSettings({
      variables: {
        input: {
          profilePreferenceInput,
        },
      },
    })
      .then(() => {
        updateData({
          ...data,
          [SettingsView.PROFILE_DATA]: {
            fields: {
              workHistory: { enabled: profilePreferenceInput.enableWorkHistory },
              educationHistory: { enabled: profilePreferenceInput.enableEducationHistory },
              location: { enabled: profilePreferenceInput.enableLocation },
            },
            enabled: profilePreferenceInput.enableProfile,
          },
        });
        setHasChanges(false);
        addNotification({
          content: _t('Profile data preferences updated'),
          severity: 'success',
          isTransient: true,
        });
      })
      .catch((error) => {
        onError?.();
        addNotification({ content: _t('Something went wrong, please try again'), severity: 'error' });
        captureMessageInSentry({ error, source: 'SaveProfilePreferences' });
      });
  };

  const handleSave = async () => {
    const profileFields = data.profileData?.fields;
    if (!profileFields) {
      return;
    }

    const newFieldsSet = new Set(selectedProfileFields);

    Object.keys(profileFields).forEach((profileField) => {
      profileFields[profileField as ProfileInfoField] = {
        enabled: newFieldsSet.has(profileField),
      };
    });

    track('interact_coach_settings', {
      settingsInteractionType: 'save_profileData',
    });

    saveProfilePreference({
      enableWorkHistory: newFieldsSet.has('workHistory'),
      enableEducationHistory: newFieldsSet.has('educationHistory'),
    });
  };

  const handleToggle = (newSelected: boolean) => {
    // optimistically update toggle, revert on error
    setToggle(newSelected);

    saveProfilePreference({ enableProfile: newSelected }, () => setToggle(!newSelected));

    track('interact_coach_settings', {
      settingsInteractionType: `toggle_profileData_${newSelected ? 'on' : 'off'}`,
    });
  };

  useEffect(() => {
    const profileFields = data.profileData?.fields;

    if (profileFields) {
      setSelectedFields(
        Object.keys(profileFields).filter((key) => profileFields[key as ProfileInfoField].enabled === true)
      );
    }
  }, [data]);

  const showProfileFieldsSelection = data.profileData?.enabled;

  return (
    <div className="coach-settings-profileinfo" css={styles.root}>
      <SettingsPage
        onBack={onBack}
        title={_t('Profile data')}
        footer={
          <CoachButton
            loading={isSaving}
            aria-disabled={!hasChanges || !showProfileFieldsSelection}
            onClick={handleSave}
          >
            {_t('Save preferences')}
          </CoachButton>
        }
      >
        <SettingsSwitch
          disabled={isSaving}
          label={_t('Profile data')}
          supportText={
            <Typography2 variant="bodySecondary" component="div" color="supportText">
              {_t(
                'Coach will customize its responses based on the profile information you select below to provide you with more helpful guidance.'
              )}
              <br />
              <br />
              <Typography2 variant="bodySecondary" component="p" color="supportText">
                <FormattedHTMLMessage
                  message={_t(`The selections you make will only affect how Coach responds and will not change your Coursera profile
                information. To update your profile information, {editProfileUrl}.`)}
                  editProfileUrl={
                    <Link href={`${config.url.base}account-profile`} target="_blank" rel="noopener noreferrer">
                      {_t('edit your profile')}
                    </Link>
                  }
                />
              </Typography2>
            </Typography2>
          }
          selected={toggle}
          onToggle={handleToggle}
        />

        {showProfileFieldsSelection ? (
          <CheckboxGroup
            css={styles.profileFields}
            aria-label={_t('Profile data fields')}
            label=""
            optional
            value={selectedProfileFields}
            onChange={(e, checked) => handleCheckboxChange(e.target.value, checked)}
          >
            {getProfileFields().map((profileField) => {
              return (
                <Checkbox
                  key={profileField}
                  label={getProfileFieldName(profileField)}
                  value={profileField}
                  disabled={isSaving}
                />
              );
            })}
          </CheckboxGroup>
        ) : null}
      </SettingsPage>
    </div>
  );
};

export default SettingsProfileInfo;
