import * as React from 'react';

import { CoachIconButton } from 'bundles/ai-coach-platform/components/building-blocks';
import { ThumbUpIcon } from 'bundles/ai-coach-platform/components/icons/mui';

import _t from 'i18n!nls/ai-coach-platform';

type Props = {
  selected?: boolean;
  onSelect(): void;
  onDeselect(): void;
};

const FeedbackLike: React.FC<Props> = ({ selected, onSelect, onDeselect }) => {
  return (
    <div>
      {selected ? (
        <CoachIconButton
          size="small"
          aria-label={_t('Cancel like')}
          tooltip={_t('Cancel like')}
          onClick={onDeselect}
          icon={<ThumbUpIcon filled={true} />}
        />
      ) : (
        <CoachIconButton
          size="small"
          aria-label={_t('Like')}
          tooltip={_t('Like')}
          onClick={onSelect}
          icon={<ThumbUpIcon filled={false} />}
        />
      )}
    </div>
  );
};

export default FeedbackLike;
