import user from 'js/lib/user';

import { isBlacklistedInEpic } from 'pages/open-course/common/utils/experiment';

import type { EnrollmentChoices } from 'bundles/enroll/components/xdp/withEnrollment';
import type { Product } from 'bundles/enroll/types/productTypes';
import { hasCourse, hasSpecialization } from 'bundles/enroll/types/productTypes';
import XDPExperiments from 'bundles/epic/clients/XDP';

type ProductAndEac = Product & {
  enrollmentAvailableChoices?: EnrollmentChoices;
};

const shouldShowFinaid = ({ enrollmentAvailableChoices, ...product }: ProductAndEac) => {
  const isAuthenticated = user.isAuthenticatedUser();
  const isEligibleForVCFinaid = enrollmentAvailableChoices && enrollmentAvailableChoices.isEligibleForVCFinaid;
  const isEnrollmentValid = !isAuthenticated || isEligibleForVCFinaid;

  const isCourseValid = hasCourse(product)
    ? !isBlacklistedInEpic(XDPExperiments.get('denylistCoursesFromFinaid'), product.course.id) && product.course.hasCert
    : false;

  const isS12nValid = hasSpecialization(product)
    ? // eslint-disable-next-line camelcase
      !XDPExperiments.get('denylistS12nsFromFinaid', { specialization_id: product.s12n.id })
    : false;

  return isEnrollmentValid && (isCourseValid || isS12nValid);
};

const exported = {
  shouldShowFinaid,
};

export default exported;
export { shouldShowFinaid };
