import * as React from 'react';
import Media from 'react-media';

import classNames from 'classnames';

import type UserAgentInfo from 'js/lib/useragent';

import type { ButtonProps } from '@coursera/cds-core';
import { Box } from '@coursera/coursera-ui';

import EnrollButton from 'bundles/enroll/components/xdp/EnrollButton';
import type { PropsFromCaller as BannerEnrollTitleComponentProps } from 'bundles/enroll/components/xdp/MultipleLearningProgramsLink';
import type { EnrollmentChoices } from 'bundles/enroll/components/xdp/withEnrollment';
import { MOBILE_BREAKPOINT_PX } from 'bundles/page-footer/components/global-footer/constants';
import { getBackgroundAndFontColor } from 'bundles/program-common/utils/colorUtils';

export type PropsFromCaller = {
  buttonLink?: string;
  buttonLabel?: string;
  buttonStyle?: React.CSSProperties;
  title?: string;
  TitleComponent?: React.FunctionComponent<BannerEnrollTitleComponentProps>;
  titleSecondary?: string;
  subtitleElem?: React.ReactNode;
  onClick?: () => void;
  onMultipleLearningProgramsLinkClick?: () => void;
  ctaBottom?: string | JSX.Element;
  buttonSize?: ButtonProps['size'];
  buttonMinHeightInPixels?: number;
  buttonDisabled?: boolean;
  mobileBreakpoint?: number;
  userAgent?: UserAgentInfo;
  accentColor?: string;
  onStickyHeader?: boolean;
  wrapperClassName?: React.CSSProperties;
  enrollmentAvailableChoices?: EnrollmentChoices;
  isEnrolled?: boolean;

  // Used for customizing the complete look of the button
  buttonClassName?: string;
  buttonTrackingName?: string;
};

type PropsToComponent = PropsFromCaller & {
  isMobile?: boolean;
};

// Exporting the inner component to properly unit test it
export const EnrollBannerInner = (props: PropsToComponent) => {
  const {
    buttonLabel,
    title,
    titleSecondary,
    subtitleElem,
    onClick,
    ctaBottom,
    buttonSize,
    buttonMinHeightInPixels,
    buttonDisabled,
    accentColor,
    isMobile,
    buttonClassName,
    buttonStyle: customButtonStyle,
    wrapperClassName,
    isEnrolled,
    buttonTrackingName,
    TitleComponent,
    onMultipleLearningProgramsLinkClick,
  } = props;

  const { fontColor, backgroundColor: buttonColor } = getBackgroundAndFontColor(accentColor);

  const baseButtonStyles: React.CSSProperties = {
    backgroundColor: buttonColor,
    color: fontColor,
    fontWeight: 'bold',
    borderColor: 'transparent',
    height: 71,
    ...customButtonStyle,
  };

  let buttonStyle: React.CSSProperties;
  if (buttonMinHeightInPixels) {
    buttonStyle = {
      ...baseButtonStyles,
      minHeight: `${buttonMinHeightInPixels}px`,
    };
  } else {
    buttonStyle = baseButtonStyles;
  }

  if (isMobile) {
    buttonStyle = {
      width: '100%',
      ...buttonStyle,
    };
  }

  return (
    <div className={classNames('BannerEnroll', wrapperClassName)} data-testid="banner">
      <Box flexWrap={isMobile ? 'wrap' : undefined} alignItems="center">
        <EnrollButton
          ctaLabel={buttonLabel}
          fullWidth={isMobile}
          buttonDisabled={buttonDisabled}
          onClick={onClick}
          buttonStyle={buttonStyle}
          customStyles={customButtonStyle}
          buttonSize={buttonSize}
          buttonClassName={buttonClassName}
          isEnrolled={isEnrolled}
          trackingName={buttonTrackingName}
        />
        {(!!subtitleElem || !!title || !!titleSecondary || !!TitleComponent) && (
          <div className={isMobile ? classNames('mobile-wrap', 'p-t-2') : classNames('desktop-wrap', 'p-l-2')}>
            <p className="m-b-0">
              {title && (
                <span
                  style={{
                    fontSize: '1.25rem',
                    lineHeight: '1.5rem',
                  }}
                >
                  <strong>{title}</strong>
                </span>
              )}
              {TitleComponent && (
                <TitleComponent onMultipleLearningProgramsLinkClick={onMultipleLearningProgramsLinkClick} />
              )}
              {titleSecondary && (
                <span
                  style={{
                    fontSize: '1.25rem',
                    lineHeight: '1.5rem',
                  }}
                >
                  {'\u00A0'}
                  {titleSecondary}
                </span>
              )}
            </p>
            {subtitleElem}
          </div>
        )}
      </Box>
      {ctaBottom && <div className="m-t-1 font-sm">{ctaBottom}</div>}
    </div>
  );
};

export default function BannerEnroll({ mobileBreakpoint, userAgent, ...rest }: PropsFromCaller) {
  return (
    <Media query={{ maxWidth: mobileBreakpoint || MOBILE_BREAKPOINT_PX }} defaultMatches={userAgent?.isMobileBrowser}>
      {(matches: boolean) => {
        return <EnrollBannerInner isMobile={matches} {...rest} />;
      }}
    </Media>
  );
}
