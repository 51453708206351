/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useContext, useEffect, useState } from 'react';

import { Typography2 } from '@coursera/cds-core';
import { useTracker } from '@coursera/event-pulse/react';

import { CoachIconButton } from 'bundles/ai-coach-platform/components/building-blocks';
import { CloseIcon } from 'bundles/ai-coach-platform/components/icons/mui';
import { CoachSettingsContext } from 'bundles/ai-coach-platform/components/patterns/chat/settings/SettingsContextProvider';
import SettingsStudio from 'bundles/ai-coach-platform/components/patterns/chat/settings/pages/SettingsStudio';
import { useCoachTrackingContext } from 'bundles/ai-coach-platform/utils/tracking';

import _t from 'i18n!nls/ai-coach-platform';

import SettingsList from './SettingsList';
import SettingsProfileInfo from './pages/SettingsProfileInfo';
import SettingsChatHistory from './pages/chatHistory/SettingsChatHistory';
import { ChatHistoryStatus, SettingsView } from './types';
import { useSettingsNotification } from './useSettingsNotification';

type Props = {
  onClose: ({ isChatHistoryDeleted }: { isChatHistoryDeleted: boolean }) => void;
  isCoachStudio?: boolean;
};

const styles = {
  root: css`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 11;
    background: var(--cds-color-white-0);
    border-radius: var(--cds-border-radius-200);

    /* CDS overrides */
    --cds-color-neutral-primary: var(--cds-color-grey-950);
  `,
  homeHeader: css`
    display: flex;
    height: 56px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--cds-color-grey-100);
    padding: 0 var(--cds-spacing-200);
    z-index: 1;
  `,
  homeContent: css`
    padding: var(--cds-spacing-200);
    height: calc(100vh - 120px);
    overflow: auto;
  `,
};

/**
 * Top-level controller for Coach Settings UI
 */
export const SettingsHome = (props: Props): React.ReactElement => {
  const { onClose, isCoachStudio = false } = props;

  const [currentView, setCurrentView] = useState<SettingsView>(SettingsView.HOME);
  const { data, loading: dataLoading, error } = useContext(CoachSettingsContext);
  const trackingData = useCoachTrackingContext()?.data;
  const track = useTracker();

  const { addNotification } = useSettingsNotification();

  useEffect(() => {
    if (isCoachStudio) {
      setCurrentView(SettingsView.STUDIO);
    }
  }, [isCoachStudio]);

  const handleBack = () => {
    setCurrentView(SettingsView.HOME);

    track('interact_coach_settings', {
      settingsInteractionType: 'navigate_settingsHome',
      coach: {
        mode: trackingData?.mode,
      },
    });
  };

  const handleClose = () => {
    onClose({ isChatHistoryDeleted: data.chatHistory?.status === ChatHistoryStatus.SESSIONS_DELETED });
    track('interact_coach_settings', {
      settingsInteractionType: 'close_settings',
      coach: {
        mode: trackingData?.mode,
      },
    });
  };

  const handleNavigate = (page: SettingsView) => {
    setCurrentView(page);

    track('interact_coach_settings', {
      settingsInteractionType: `navigate_${page}`,
      coach: {
        mode: trackingData?.mode,
      },
    });
  };

  React.useEffect(() => {
    if (error) {
      addNotification({ content: _t('Error loading settings. Please try again'), severity: 'error' });
    }
  }, [error, addNotification]);

  return (
    <div aria-label={_t('Coach settings container')} className="coach-settings-container" css={styles.root}>
      {currentView === 'home' ? (
        <div className="coach-settings-home">
          <div className="home-settings-header" css={styles.homeHeader}>
            <Typography2 variant="subtitleMedium" component="h2">
              {_t('Coach settings')}
            </Typography2>
            <CoachIconButton
              icon={<CloseIcon />}
              size="small"
              tooltip={_t('Close')}
              aria-label={_t('Close settings')}
              onClick={handleClose}
            />
          </div>

          <div css={styles.homeContent}>
            <SettingsList loading={dataLoading} disabled={!!error}>
              <SettingsList.SettingGroup title={_t('General')}>
                <SettingsList.ListItemButton
                  onClick={() => {
                    handleNavigate(SettingsView.CHAT_HISTORY);
                  }}
                >
                  {_t('Chat history')}
                </SettingsList.ListItemButton>
              </SettingsList.SettingGroup>
              <SettingsList.SettingGroup title={_t('Personalization')}>
                <SettingsList.ListItemButton
                  onClick={() => handleNavigate(SettingsView.PROFILE_DATA)}
                  suffix={data.profileData?.enabled ? _t('On') : _t('Off')}
                >
                  {_t('Profile data')}
                </SettingsList.ListItemButton>
              </SettingsList.SettingGroup>
            </SettingsList>
          </div>
        </div>
      ) : null}

      {currentView === SettingsView.PROFILE_DATA ? <SettingsProfileInfo onBack={handleBack} /> : null}

      {currentView === SettingsView.CHAT_HISTORY ? <SettingsChatHistory onBack={handleBack} /> : null}

      {currentView === SettingsView.STUDIO ? (
        <SettingsStudio
          // Once we integrate Studio into the regular settings menu, the onBack will update to handleBack
          onBack={handleClose}
        />
      ) : null}
    </div>
  );
};

export default SettingsHome;
