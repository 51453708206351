import * as React from 'react';

import classNames from 'classnames';

import type { GraphQLCML } from 'bundles/collections-tool/types/collectionTypesSU';
import CmlLearningObjectives from 'bundles/enterprise-legacy-xdp/components/CmlLearningObjectives';
import Skills from 'bundles/enterprise-legacy-xdp/components/Skills';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { MIN_NUM_OF_SKILLS_TO_SHOW } from 'bundles/program-common/constants/xdpConstants';

import 'css!bundles/enterprise-legacy-xdp/components/__styles__/LearningObjectivesAndSkills';

const getTrackingNameForLearningObjectivesAndSkills = (hasLearningObjectives: boolean, hasSkills: boolean) => {
  if (hasLearningObjectives && hasSkills) {
    return 'top_what_and_skills';
  } else if (hasLearningObjectives) {
    return 'top_what_only';
  } else {
    return 'top_skills_only';
  }
};

type PropsToComponent = {
  rootClassName?: string;
  skills?: Array<string>;
  cmlLearningObjectives: Array<GraphQLCML>;
  htmlLearningObjectives?: Array<string>;
};

const LearningObjectivesAndSkills = ({
  rootClassName,
  skills,
  cmlLearningObjectives,
  htmlLearningObjectives = [],
}: PropsToComponent) => {
  const hasLearningObjectives = cmlLearningObjectives?.length > 0;
  const shouldShowSkills = skills && skills.length >= MIN_NUM_OF_SKILLS_TO_SHOW;

  if (!hasLearningObjectives && !shouldShowSkills) {
    return null;
  }

  return (
    <TrackedDiv
      className={classNames('rc-LearningObjectivesAndSkills', 'border-a', rootClassName)}
      trackingName={getTrackingNameForLearningObjectivesAndSkills(hasLearningObjectives, !!shouldShowSkills)}
      trackClicks={false}
      requireFullyVisible={true}
      withVisibilityTracking={true}
    >
      {hasLearningObjectives && <CmlLearningObjectives cmlLearningObjectives={cmlLearningObjectives.slice(0, 4)} />}
      {hasLearningObjectives && shouldShowSkills && <hr className="separator" />}
      {shouldShowSkills && (
        <Skills
          skills={(skills as Array<string>).slice(0, 12)}
          rootClassName={classNames('p-x-2 p-t-1 p-b-2', {
            'skills-combined-with-learning-objs': hasLearningObjectives,
            'skills-sdp-content-exp': !hasLearningObjectives,
          })}
          enableToggle
        />
      )}
    </TrackedDiv>
  );
};

export default LearningObjectivesAndSkills;
