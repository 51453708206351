/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useContext, useState } from 'react';

import { useFocusRing } from '@react-aria/focus';
import { ReactEditor, useSlate, useSlateStatic } from 'slate-react';

import { ChevronDownIcon, ParagraphIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import Divider from 'bundles/cml/editor/components/buttons/typography/Divider';
import TypographyMenu from 'bundles/cml/editor/components/buttons/typography/TypographyMenu';
import { getHeadingLabel } from 'bundles/cml/editor/components/buttons/typography/headingUtils';
import { hasTextWithBody2Variant } from 'bundles/cml/editor/components/buttons/typography/textUtils';
import DropdownIcon from 'bundles/cml/editor/components/icons/DropdownIcon';
import { FocusContext } from 'bundles/cml/editor/context/focusContext';
import { isTypeDisabled } from 'bundles/cml/editor/utils/elementUtils';
import { hasAncestorOfType } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { ToolsKeys } from 'bundles/cml/shared/utils/customTools';

import _t from 'i18n!nls/cml';

export type Props = {
  pageless?: boolean;
  tools: ToolsKeys[];
  menuDirection?: 'top' | 'bottom';
};

const styles = {
  pageless: css`
    display: flex;
    align-items: center;
    column-gap: var(--cds-spacing-200);

    button {
      justify-content: space-between;
      min-width: 116px;
    }
  `,
};

const { HEADING, TEXT } = BLOCK_TYPES;

const TypographyButton: React.FC<Props> = (props: Props) => {
  const editor = useSlate();
  const staticEditor = useSlateStatic();
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const { setFocused } = useContext(FocusContext);
  const { tools, pageless, menuDirection } = props;

  const disabled = isTypeDisabled(editor, HEADING) && isTypeDisabled(editor, TEXT);
  const active = hasAncestorOfType(editor, HEADING) || hasTextWithBody2Variant(editor);

  const {
    isFocusVisible,
    focusProps: { onClick, ...focusProps },
  } = useFocusRing();

  const handleOpen = (menuOpen: boolean) => {
    if (menuOpen) {
      setFocused(true);
      setOpen(true);
    } else {
      setOpen(false);
      setTimeout(() => {
        setFocused(false);
        ReactEditor.focus(staticEditor);
      }, 250);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(e);
    handleOpen(!open);
  };

  return (
    <div ref={setRef}>
      <div css={pageless && styles.pageless}>
        {pageless && <Divider />}
        <Button
          onClick={handleClick}
          active={open || (!pageless && active)}
          disabled={disabled}
          className="rc-TypographyButton"
          title={_t('Styles')}
          type={TOOLBAR_BUTTON_TYPES.menu}
          {...focusProps}
        >
          {pageless && (
            <React.Fragment>
              {getHeadingLabel(editor)}
              <ChevronDownIcon size="small" />
            </React.Fragment>
          )}
          {!pageless && (
            <React.Fragment>
              <ParagraphIcon size="small" />
              <DropdownIcon />
            </React.Fragment>
          )}
        </Button>
        {pageless && <Divider />}
      </div>
      {open && (
        <TypographyMenu
          anchorEl={ref || undefined}
          open={open}
          handleClose={() => handleOpen(false)}
          tools={tools}
          menuDirection={menuDirection}
          isFocusVisible={isFocusVisible}
        />
      )}
    </div>
  );
};

export default TypographyButton;
