import { createContext, useContext } from 'react';

import type { ApolloQueryResult } from 'apollo-client';

import type {
  EnterpriseBadgeCollectionsQuery as EnterpriseBadgeCollectionsQueryData,
  EnterpriseBadgeCollectionsQueryVariables,
} from 'bundles/enterprise-learner-onboarding/queries/__generated__/EnterpriseBadgeCollectionsQuery';
import type { EnterpriseBadgeWithCollection } from 'bundles/page-config-common/providers/enterprise/EnterpriseBadgeCollectionsProvider';

export type EnterpriseBadgeCollectionsContextType = {
  enterpriseBadgeCollectionsLoading: boolean;
  enterpriseBadgesWithCollection: Array<EnterpriseBadgeWithCollection>;
  refetchEnterpriseBadgeCollections?: (
    variables?: EnterpriseBadgeCollectionsQueryVariables
  ) => Promise<ApolloQueryResult<EnterpriseBadgeCollectionsQueryData>>;
};

const EnterpriseBadgeCollectionsContext = createContext<EnterpriseBadgeCollectionsContextType>({
  enterpriseBadgeCollectionsLoading: false,
  enterpriseBadgesWithCollection: [],
  refetchEnterpriseBadgeCollections: () =>
    Promise.resolve({} as ApolloQueryResult<EnterpriseBadgeCollectionsQueryData>),
});

export const useEnterpriseBadgeCollections = () => useContext(EnterpriseBadgeCollectionsContext);

export default EnterpriseBadgeCollectionsContext;
