import type { XdpV1_Org_Coursera_Xdp_Sdp_SdpCourse as Course } from '__generated__/graphql-types';

export function getTotalWeeksFromCourses(courses: Array<Course>) {
  return courses.reduce((s12nWeeks, course) => {
    return s12nWeeks + (course?.material?.weeks?.length || 0);
  }, 0);
}

// Only use this function if the monthsToCompleteOverride field from xdpMetadataResource is NOT defined
export function getTotalMonthsFromCourses(courses: Array<Course>) {
  const totalWeeks = getTotalWeeksFromCourses(courses);

  return Math.ceil(totalWeeks / 4);
}
