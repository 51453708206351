import type { ActionContext } from 'js/lib/ActionContext';

import authoringStateActionsFactory from 'bundles/author-common/actions/AuthoringStateActions';
import RequestManager from 'bundles/author-common/utils/RequestManager';
import { updateAuthoringWidgetItemDraft } from 'bundles/author-widget/utils/AuthoringWidgetItemAPIUtils';
import { getResourceId } from 'bundles/author-widget/utils/WidgetCommonUtils';

import type { WidgetItemContent } from '../types/widgetItemContent';

const AuthoringStateActions = authoringStateActionsFactory('item');
const requestManager = new RequestManager();
const CONFLICT_ERROR_STATUS = 409;

export const update = (
  actionContext: ActionContext,
  branchId: string,
  itemId: string,
  atomId?: string,
  callback?: () => void
) => {
  return requestManager.debouncedRun(() => {
    const store = actionContext.getStore('WidgetStore');
    const widgetDraft: WidgetItemContent = store.getWidgetItemContent();
    const writeAccessToken = store.getWriteAccessToken();
    actionContext.executeAction(AuthoringStateActions.setInProgress);
    const authoringItemContentId = getResourceId(branchId, itemId, atomId);

    return updateAuthoringWidgetItemDraft(authoringItemContentId, widgetDraft, writeAccessToken)
      .then((response) => {
        const name = response.draft?.name;
        const providerId = response.draft?.content.providerId;
        const isGraded = response.draft?.content.isGraded;
        const newWriteAccessToken = response.writeAccessToken;
        const newWriteAccessState = response.writeAccessState;
        const timeCommitment = response.draft?.timeCommitment;

        actionContext.executeAction(AuthoringStateActions.setSuccess);
        actionContext.dispatch('WIDGET_DRAFT_UPDATED', {
          name,
          providerId,
          isGraded,
          timeCommitment,
        });
        actionContext.dispatch('WIDGET_WRITE_ACCESS_UPDATED', { newWriteAccessToken, newWriteAccessState });

        callback?.();
      })
      .catch((error) => {
        if (error.status === CONFLICT_ERROR_STATUS) {
          actionContext.executeAction(AuthoringStateActions.setConflict);
        } else {
          actionContext.executeAction(AuthoringStateActions.setError);
          actionContext.dispatch('WIDGET_AUTHORING_API_ERROR', error);
        }
      });
  });
};
