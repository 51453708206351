import { forwardRef, useEffect } from 'react';

import {
  ItemsCollectionProvider,
  useKeyboardNavigation,
  useItemsCollection,
} from '@core/utils';

import menuListCss from './styles/menuListCss';

export type Props = {
  /**
   * Individual `Menu.Item` or 'Menu.ItemLink` elements used as menu items. To group menu items, use `Menu.Group`.
   */
  children: React.ReactNode;

  /**
   * If `true`, the first item in the list will not receive focus on mount.
   * @default false
   */
  disableFirstItemAutofocus?: boolean;
};

const List = forwardRef<HTMLUListElement, Props>(function _List(props, ref) {
  const { children, disableFirstItemAutofocus = true, ...rest } = props;
  const collection = useItemsCollection<HTMLButtonElement>();

  const { onKeyDown } = useKeyboardNavigation<HTMLUListElement>({
    elements: collection?.items.map((item) => item.element),
  });

  useEffect(
    function setInitialFocus() {
      if (!disableFirstItemAutofocus) {
        collection?.items[0]?.element?.focus();
      }
    },
    [collection?.items, disableFirstItemAutofocus]
  );

  return (
    <ul ref={ref} css={menuListCss} role="menu" onKeyDown={onKeyDown} {...rest}>
      {children}
    </ul>
  );
});

const MenuList = forwardRef<HTMLUListElement, Props>(function _MenuList(
  props,
  ref
) {
  const { children, disableFirstItemAutofocus = false, ...rest } = props;
  return (
    <ItemsCollectionProvider commonProps={null}>
      <List
        ref={ref}
        disableFirstItemAutofocus={disableFirstItemAutofocus}
        {...rest}
      >
        {children}
      </List>
    </ItemsCollectionProvider>
  );
});

export default MenuList;
