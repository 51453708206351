import * as React from 'react';

import { FormattedHTMLMessage } from 'js/lib/coursera.react-intl';

import type Group from 'bundles/groups/models/Group';
import type OnDemandSpecializationsV1 from 'bundles/naptimejs/resources/onDemandSpecializations.v1';
import PaymentChoice from 'bundles/s12n-enroll/components/bulk-pay/PaymentChoice';

import _t from 'i18n!nls/s12n-enroll';

type PropsFromCaller = {
  group: Group;
  s12n: OnDemandSpecializationsV1;
};

type PropsToComponent = PropsFromCaller;

type State = {
  orgName?: string;
};

class PaymentChoiceGroup extends React.Component<PropsToComponent, State> {
  state = {
    orgName: undefined,
  };

  componentDidMount() {
    const { group } = this.props;
    this.setState({ orgName: group.name });
  }

  getBodyMessage() {
    const { group, s12n } = this.props;
    const { orgName } = this.state;

    if (orgName) {
      return (
        <div>
          <FormattedHTMLMessage
            tagName="p"
            message={_t(
              `As part of <span class="body-2-text">{groupName}</span>, you're able to
              join the Specialization for free!  You'll have access to all {numOfCourses} courses,
            the Capstone project, and the Specialization Certificate.`
            )}
            groupName={group.name}
            numOfCourses={s12n.courseIds.length}
          />
          <p />
          <FormattedHTMLMessage
            tagName="p"
            message={_t(
              `If you decide you don't want to complete the course, you can un-enroll from the course 
              within 2 weeks of joining and {orgName} will not be charged.`
            )}
            orgName={orgName}
          />
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <PaymentChoice title={_t('Join now for free')} type="group" currentType="group">
        {this.getBodyMessage()}
      </PaymentChoice>
    );
  }
}

export default PaymentChoiceGroup;
