import type { Editor } from 'slate';
import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import type { InjectedRouter } from 'js/lib/connectToRouter';

import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { Asset } from 'bundles/cml/shared/types/assetTypes';
import type { AssetElement, ImageElement } from 'bundles/cml/shared/types/elementTypes';
import { getAssetTag } from 'bundles/cml/shared/utils/assetUtils';

import _t from 'i18n!nls/cml';

export const getAssetDisbaledText = (asset: Asset, courseId: string | undefined) => {
  const isAssetDeleted = getAssetTag(asset, 'listed') !== 'true';
  const isAssetMissingInLibrary = getAssetTag(asset, 'courseId') !== courseId;

  const isDisabled = isAssetDeleted || isAssetMissingInLibrary;
  if (isDisabled) {
    return isAssetDeleted
      ? _t('This asset has been deleted from the Asset Library')
      : _t('This asset cannot be opened in this course’s Asset Library');
  }

  return undefined;
};

export const openInAssetLibrary = (assetId: string, router: InjectedRouter) => {
  if (!assetId) {
    return;
  }

  const {
    params: { courseSlug, contextId },
  } = router;

  const url = `${window.location.origin}/teach/${courseSlug}/${contextId}/content/assets/${assetId}`;
  window.open(url);
};

export const updateAsset = (editor: Editor, element: AssetElement | ImageElement, asset?: Asset) => {
  if (!asset || element.id === asset.id) {
    return;
  }

  const { id, typeName: assetType } = asset;
  const path = ReactEditor.findPath(editor, element);
  const type = assetType === 'image' ? BLOCK_TYPES.IMAGE : BLOCK_TYPES.ASSET;
  const embedEnabled = assetType === 'pdf' || false;

  Transforms.setNodes(editor, { id, type, embedEnabled }, { at: path });
};

export const updateAssetEmbedState = (
  editor: Editor,
  element: AssetElement,
  embedEnabled: boolean,
  embedStartPage?: number,
  embedEndPage?: number
) => {
  const path = ReactEditor.findPath(editor, element);
  const props = { embedEnabled, embedStartPage, embedEndPage };
  Transforms.setNodes(editor, props, { at: path });
};
