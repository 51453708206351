import type React from 'react';
import { forwardRef } from 'react';

import useForkRef from '@material-ui/core/utils/useForkRef';

import clsx from 'clsx';

import type { OverrideProps, OverridableComponent } from '@coursera/cds-common';

import ButtonBase from '@core/ButtonBase';
import Typography from '@core/Typography2';
import {
  useItemIndexInCollection,
  useItemsCollection,
  useRegisterItemInCollection,
} from '@core/utils';

import { useGroupContext } from './context';
import itemCss, { classes, itemLayoutCss } from './styles/itemCss';

export type VisuallyHiddenGroupLabelProps = {
  id: string;
  groupId: string;
  groupLabel: string;
};

export type BaseProps = {
  /**
   * Content slot. Use this if you need to render something other than text.
   */
  children: React.ReactNode;

  /**
   * If set to true, the item will be disabled using aria-disabled.
   */
  disabled?: boolean;

  /**
   * Prefix slot.
   */
  prefix?: React.ReactNode;

  /**
   * Additional support text.
   */
  supportText?: React.ReactNode;

  /**
   * Suffix slot.
   */
  suffix?: React.ReactNode;
};

export interface ItemTypeMap<D extends React.ElementType = 'button'> {
  props: BaseProps;
  defaultComponent: D;
}

export type Props<
  D extends React.ElementType = ItemTypeMap['defaultComponent']
> = OverrideProps<ItemTypeMap<D>, D> & {
  component?: React.ElementType;
};

const ItemBase: OverridableComponent<ItemTypeMap> = forwardRef<
  HTMLButtonElement,
  Props
>(function Item(props, ref) {
  const {
    prefix,
    suffix,
    children,
    className,
    supportText,
    disabled,
    id: idFromProps,
    ...rest
  } = props;

  const groupContext = useGroupContext();
  const collection = useItemsCollection<HTMLButtonElement>();

  const {
    ref: groupItemRef,
    id,
  } = useRegisterItemInCollection<HTMLButtonElement>({
    id: idFromProps,
    groupId: groupContext?.id,
  });

  const indexInCollection = useItemIndexInCollection(id);

  /**
   * role="presentation" removes weird "group" word announced by VoiceOver
   */
  return (
    <ButtonBase
      ref={useForkRef(ref, groupItemRef)}
      aria-disabled={disabled}
      aria-posinset={indexInCollection + 1}
      aria-setsize={collection?.items.length ?? 0}
      className={clsx(className, {
        [classes.disabled]: disabled,
      })}
      css={[itemLayoutCss, itemCss]}
      tabIndex={indexInCollection === 0 ? 0 : -1}
      {...rest}
      id={id}
    >
      {prefix && (
        <div className={classes.prefix} role="presentation">
          {prefix}
        </div>
      )}
      <div className={classes.content} role="presentation">
        <Typography color="inherit" component="div" role="presentation">
          {children}
        </Typography>
        {supportText && (
          <Typography
            color={disabled ? 'inherit' : 'supportText'}
            component="div"
            role="presentation"
            variant="bodySecondary"
          >
            {supportText}
          </Typography>
        )}
      </div>
      {suffix && (
        <div className={classes.suffix} role="presentation">
          {suffix}
        </div>
      )}
    </ButtonBase>
  );
});

export default ItemBase;
