import { graphql } from 'react-apollo';

import _ from 'lodash';
import { branch, compose, pure, withProps } from 'recompose';
import type { ComponentEnhancer } from 'recompose';

import EnterpriseBadgeCollectionsQuery from 'bundles/enterprise-learner-onboarding/queries/EnterpriseBadgeCollectionsQuery.graphql';
import type {
  EnterpriseBadgeCollectionsQuery as EnterpriseBadgeCollectionsQueryData,
  EnterpriseBadgeCollectionsQueryVariables,
} from 'bundles/enterprise-learner-onboarding/queries/__generated__/EnterpriseBadgeCollectionsQuery';
import type {
  ProgramCurriculumProductsV1EnrolledCoursesQuery,
  ProgramCurriculumProductsV1EnrolledCoursesQueryVariables,
  ProgramCurriculumProductsV1EnrolledS12nsQuery,
  ProgramCurriculumProductsV1EnrolledS12nsQueryVariables,
} from 'bundles/naptimejs/resources/__generated__/ProgramCurriculumProductsV1';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import ProgramCurriculumProductsV1 from 'bundles/naptimejs/resources/programCurriculumProducts.v1';
import filterExistsOrDefault from 'bundles/program-common/utils/filterExistsOrDefault';
import { withComputedProducts } from 'bundles/program-home/components/available/withComputedProducts';
import { withProgramsStartingLater } from 'bundles/program-home/components/available/withProgramsStartingLater';
import type {
  Props,
  PropsFromCaller,
  PropsFromEnterpriseBadges,
  PropsFromNaptime,
} from 'bundles/program-home/components/enterprise-home/MyCoursesContainer';
import { EnterpriseBadge_userState as EnterpriseBadgeUserState } from 'bundles/program-home/components/single-program/__generated__/EnterpriseBadgeCollectionsQuery';
import EnrolledCoursesQuery from 'bundles/program-home/queries/EnrolledCoursesQuery';
import EnrolledS12nsQuery from 'bundles/program-home/queries/EnrolledS12nsQuery';

const createS12nElement = (element: ProgramCurriculumProductsV1) => {
  const s12nElement = {
    ...element,
    productState: {
      ...element.productState,
      definition: {
        ...element.productState.definition,
        // known issue using the @naptime directive in graphql query where 's12n' is incorrectly converted to camelcase 's12N', we need to add the correct field names manually
        // see confluence: https://coursera.atlassian.net/wiki/spaces/EN/pages/186417951/Fetching+and+mutating+data+using+GraphQL+from+Naptime+via+naptime-link+deprecated#Known-Problems
        s12nId: element.productState.definition.s12NId,
        baseS12nId: element.productState.definition.baseS12NId,
        latestS12nId: element.productState.definition.latestS12NId,
      },
    },
  };

  return new ProgramCurriculumProductsV1(s12nElement);
};

export const withMyCoursesContainer: ComponentEnhancer<Props, PropsFromCaller> = compose<Props, PropsFromCaller>(
  pure,
  withProgramsStartingLater,
  graphql<
    PropsFromCaller,
    ProgramCurriculumProductsV1EnrolledCoursesQuery,
    ProgramCurriculumProductsV1EnrolledCoursesQueryVariables,
    { loadingCourses: boolean; enrolledCourses?: ProgramCurriculumProductsV1[] | null }
  >(EnrolledCoursesQuery, {
    options: ({ programId }) => {
      return {
        ssr: false,
        variables: { programId },
      };
    },
    props: ({ data }) => {
      const elements = data?.ProgramCurriculumProductsV1?.enrolledCourses?.elements;

      const enrolledCourses = elements && elements.map((element) => new ProgramCurriculumProductsV1(element));

      return {
        loadingCourses: data?.loading ?? true,
        enrolledCourses,
      };
    },
  }),
  graphql<
    PropsFromCaller,
    ProgramCurriculumProductsV1EnrolledS12nsQuery,
    ProgramCurriculumProductsV1EnrolledS12nsQueryVariables,
    { loadingS12ns: boolean; enrolledS12ns?: ProgramCurriculumProductsV1[] | null }
  >(EnrolledS12nsQuery, {
    options: ({ programId }) => {
      return {
        ssr: false,
        variables: { programId },
      };
    },
    props: ({ data }) => {
      const elements = data?.ProgramCurriculumProductsV1?.enrolledS12ns?.elements;

      const enrolledS12ns = elements && elements.map((element) => createS12nElement(element));

      return {
        loadingS12ns: data?.loading ?? true,
        enrolledS12ns,
      };
    },
  }),
  branch<{ loadingCourses: boolean; loadingS12ns: boolean }>(
    ({ loadingCourses, loadingS12ns }) => !loadingCourses && !loadingS12ns,
    compose<Props, PropsFromCaller>(
      withComputedProducts,
      graphql<
        PropsFromCaller,
        EnterpriseBadgeCollectionsQueryData,
        EnterpriseBadgeCollectionsQueryVariables,
        PropsFromEnterpriseBadges
      >(EnterpriseBadgeCollectionsQuery, {
        options: ({ userId, programId }) => {
          return {
            ssr: false,
            variables: {
              userId: String(userId),
              programId,
            },
            context: { clientName: 'gatewayGql' },
          };
        },
        props: ({ data }) => {
          const enrolledBadgeCollections = filterExistsOrDefault(
            data?.EnterpriseBadge?.findByUserAndProgram.badgeCollectionsWithUserState
          ).filter((badge) => badge.userState === EnterpriseBadgeUserState.ENROLLED);
          const enterpriseBadgeCollections = data?.EnterpriseBadge?.findByUserAndProgram.badgeCollectionsWithUserState;

          return {
            enterpriseBadgeCollections,
            refetchEnterpriseBadgeCollections: data?.refetch,
            enrolledBadgeCollections,
          };
        },
      }),
      withProps(({ products, enrolledBadgeCollections, ...rest }: PropsFromNaptime & PropsFromEnterpriseBadges) => {
        if (_.isEmpty(products) && _.isEmpty(enrolledBadgeCollections)) {
          return { hasNoEnrolledItems: true, ...rest };
        }

        const inProgressEnrolledCourses = _.filter(
          products,
          (product) => product.isCourse && product.isEnrolled && !product.isCompleted
        );
        const inProgressEnrolledS12ns = _.filter(
          products,
          (product) => product.isS12n && product.isEnrolled && !product.isCompleted
        );

        const completedCourses = _.filter(products, (product) => product.isCourse && product.isCompleted);
        const completedS12ns = _.filter(products, (product) => product.isS12n && product.isCompleted);
        const completedProducts = [...completedS12ns, ...completedCourses];
        const inProgressEnrolledProducts = [...inProgressEnrolledS12ns, ...inProgressEnrolledCourses];

        return {
          products: [...inProgressEnrolledProducts, ...completedProducts],
          completedProducts,
          inProgressEnrolledProducts,
        };
      })
    )
  )
);

export default withMyCoursesContainer;
