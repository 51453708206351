/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import cx from 'classnames';

import { Button, TextField } from '@coursera/cds-core';

import { PLACEHOLDER } from 'bundles/cml/editor/components/elements/math/constants';

import _t from 'i18n!nls/cml';

const styles = {
  editor: css`
    width: 352px;
    padding: var(--cds-spacing-300);
  `,
  controls: css`
    display: flex;
    align-items: center;
    column-gap: var(--cds-spacing-150);
    margin-top: var(--cds-spacing-400);
  `,
};

const FIREFOX_FOCUS_DELAY_MS = 150;

export type Props = {
  value: string;
  error?: string;
  className?: string;
  style?: React.CSSProperties;
  onChange: (value: string) => void;
  onDone: () => void;
  onDelete: () => void;
  onBlur: () => void;
};

const MathEditorDialog = React.forwardRef<HTMLDivElement, Props>(
  ({ value: initialValue, error, className, onDone, onChange, onDelete, onBlur, style }, ref) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const [focused, setFocused] = useState(false);
    const [value, setValue] = useState(initialValue);

    useEffect(
      () => {
        setValue(initialValue);
      },
      [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    useEffect(() => {
      // introducing a small delay to fix focus management issues in Firefox (CP-12083)
      const timeout = setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus({ preventScroll: true });
          setFocused(true);
        }
      }, FIREFOX_FOCUS_DELAY_MS);

      return () => clearTimeout(timeout);
    }, [inputRef]);

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const targetValue = e.target.value;
        setValue(targetValue);
        onChange(targetValue);
      },
      [onChange]
    );

    const handleFocus = () => {
      setFocused(true);
    };

    const handleBlur = (event: React.FocusEvent<HTMLElement>) => {
      if (focused && !event.currentTarget.contains(event.relatedTarget as Node)) {
        onBlur();
      }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Esc' || event.key === 'Escape') {
        onDone();
      }
    };

    return (
      <div
        ref={ref}
        role="dialog"
        aria-modal="false"
        tabIndex={-1}
        className={cx('rc-MathEditorDialog', className)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        css={styles.editor}
        style={style}
      >
        <TextField
          inputRef={inputRef}
          multiline
          value={value}
          label={_t('Expression in TeX')}
          placeholder={PLACEHOLDER}
          optional={true}
          onChange={handleChange}
          validationLabel={error}
          validationStatus={error ? 'error' : undefined}
          inputProps={{ spellCheck: false, onKeyDown: handleKeyDown }}
          fullWidth
          maxRows={12}
        />
        <div css={styles.controls}>
          <Button size="small" onClick={onDone}>
            {_t('Done')}
          </Button>
          <Button variant="secondary" size="small" onClick={onDelete}>
            {_t('Delete Expression')}
          </Button>
        </div>
      </div>
    );
  }
);

export default MathEditorDialog;
