import { omit } from 'lodash';

import type { TargetSkillProfileUserStatesQuery_TargetSkillProfileUserStatesV1Resource_byUserAndProgram_elements as UserSkillProfileStateType } from 'bundles/program-home/components/__generated__/TargetSkillProfileUserStatesQuery';
import { org_coursera_enterprise_skills_TargetSkillProfileUserGoalState as SkillProfileStates } from 'bundles/program-home/components/__generated__/globalTypes';
import type { ObjectiveSummaryQuery_EnterpriseTargetSkillProfileSummariesV1Resource_bySlug_elements as ObjectiveSummary } from 'bundles/program-home/utils/__generated__/ObjectiveSummaryQuery';

type TSPBySkillId = {
  [skillId: string]: {
    skillId: string;
    skillName: string;
    targetProficiency: number;
    readableScore?: number;
  };
};

export const getLearnerScoresWithTargets = (
  objectiveSummary: ObjectiveSummary,
  skillScores: { [skillId: string]: number }
) => {
  return objectiveSummary.targetSkillProficiencies.map((skill) => ({
    skillId: skill.skillId,
    skillName: skill.skillName,
    targetProficiency: skill.targetProficiency,
    score: skillScores[skill.skillId] ?? 0,
  }));
};

export const getTSPBySkillId = (
  objectiveSummary: ObjectiveSummary,
  skillScores: { [skillId: string]: number }
): TSPBySkillId => {
  return objectiveSummary.targetSkillProficiencies.reduce((result, targetSkillProciency) => {
    return Object.assign(result, {
      [targetSkillProciency.skillId]: {
        ...omit(targetSkillProciency, '__typename'),
        readableScore: skillScores[targetSkillProciency.skillId] ?? 0,
      },
    });
  }, {});
};

export const isSavedSkillSet = (
  targetSkillProfileId: string | undefined,
  userSkillProfileStates?: UserSkillProfileStateType[]
): boolean | undefined =>
  userSkillProfileStates?.some(
    (skillProfileState) =>
      skillProfileState.targetSkillProfileId === targetSkillProfileId &&
      skillProfileState.state === SkillProfileStates.SAVED
  );
