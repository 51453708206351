/* eslint-disable object-shorthand */

import PropTypes from 'prop-types';

// extends React.PropTypes and adds custom proptypes
export default Object.assign(
  {},
  {
    s12nData: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      courseIds: PropTypes.arrayOf(PropTypes.string),
    }),
    s12nNotifications: PropTypes.shape({
      oldS12nId: PropTypes.string.isRequired,
      newS12nId: PropTypes.string.isRequired,
      expiresAt: PropTypes.number.isRequired,
    }),
    courseProgress: PropTypes.arrayOf(PropTypes.object),
    progressStatus: PropTypes.shape({
      status: PropTypes.string,
      progress: PropTypes.string,
    }),
    course: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    upgradeEligibility: PropTypes.shape({
      oldS12nId: PropTypes.string.isRequired,
      newS12nId: PropTypes.string.isRequired,
      expiresAt: PropTypes.number,
    }),
    upgradePreferences: PropTypes.shape({
      id: PropTypes.string,
      preference: PropTypes.object,
    }),
    // this is a map with a dynamic string key, so we need custom validation.
    // per https://facebook.github.io/react/docs/typechecking-with-proptypes.html
    upgradePreferenceMap: function (props: $TSFixMe, propName: $TSFixMe, componentName: $TSFixMe) {
      const firstKey = Object.keys(props[propName])[0];

      // throw validation error if the key is not a string
      if (typeof firstKey !== 'string') {
        return new Error(
          'Invalid prop `' + firstKey + '` supplied to' + ' `' + componentName + '`. Validation failed.'
        );
      }

      return null;
    },
  },
  PropTypes
);
