import * as React from 'react';

import Imgix from 'js/components/Imgix';

type Props = {
  className: string;
  alt: string;
  width: number;
  height: number;
  src?: string | null;
  imgParams?: Record<string, string>;
};

const SvgCompatibleImage = (props: Props) => {
  const { className, alt, width, height, src } = props;
  const isImageSvg = src && src.indexOf('.svg') !== -1;
  const isImgFromContentful = src && src.indexOf('ctfassets.net') !== -1;
  return isImageSvg || isImgFromContentful ? (
    <img src={src} className={className} alt={alt} width={width} height={height} />
  ) : (
    <Imgix {...props} src={src || ''} />
  );
};

export default SvgCompatibleImage;
