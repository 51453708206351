import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import moment from 'moment';

import { Box, Col, Row, StarRating, StyleSheet, css } from '@coursera/coursera-ui';

import { ReviewComment } from 'bundles/reviews/components/ReviewComment';

import _t from 'i18n!nls/program-common';

const styles = StyleSheet.create({
  TopReviewsComment: {
    overflowWrap: 'break-word',
  },
});

export type Review = {
  reviewId: string;
  comment: string;
  averageFiveStarRating: number;
  authorName: string;
  timestamp: string;
};

export type Props = {
  topReviews: Array<Review>;
};

export default function TopReviews({ topReviews }: Props) {
  function renderReview(review: Review) {
    const { comment, averageFiveStarRating, authorName, timestamp } = review;
    return (
      <div className="review">
        <Box alignItems="center" flexWrap="nowrap">
          <StarRating
            rating={averageFiveStarRating}
            enableColorOpacity
            ariaLabel={_t('#{rating} out of 5 stars', { rating: Number(averageFiveStarRating.toFixed(1)) })}
          />
          <span className="author-container font-xs d-inline-block m-l-1s">
            <FormattedMessage message={_t('By {author}')} author={authorName} />
            <span className="m-x-1s" aria-hidden>
              &bull;
            </span>
            <span className="text-uppercase">{moment(timestamp).format('MMM Do YYYY')}</span>
          </span>
        </Box>
        <p {...css('font-sm', styles.TopReviewsComment)}>
          <ReviewComment comment={comment} textVariant="body2" />
        </p>
      </div>
    );
  }

  return (
    <div className="rc-TopReviews p-y-2 border-top">
      {topReviews.length > 1 ? (
        <Row>
          {topReviews.map((review) => (
            <Col xs={12} lg={6} key={review.comment}>
              {renderReview(review)}
            </Col>
          ))}
        </Row>
      ) : (
        renderReview(topReviews[0])
      )}
    </div>
  );
}
