import * as React from 'react';

import { pure } from 'recompose';

import { SvgIcon } from '@coursera/coursera-ui';
import type { Svg } from '@coursera/coursera-ui/svg';

const SvgBeginnerLevelComponent: Svg = ({ title = 'Beginner', ...props }) => (
  <SvgIcon title={title} {...props} viewBox="0 0 48 48" fill="none">
    <g id="Beginner">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7825 15V46H14.6086V30H4.17384V43.9058L4.17383 45L4.17384 46H43.826L43.826 45L43.826 43.9058V2H33.3912V46H29.2173V15H18.7825ZM20.8694 43.9058H27.1303V17H20.8694V43.9058ZM35.4782 43.9048H41.7381V4H35.4782V43.9048Z"
        id="SvgBeginner"
      />
    </g>
  </SvgIcon>
);

const SvgBeginner = pure(SvgBeginnerLevelComponent);
SvgBeginner.displayName = 'SvgBeginner';

export default SvgBeginner;
