/** @jsx jsx */

/* eslint-disable react-hooks/exhaustive-deps */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { memo, useEffect } from 'react';

import { Button, Dialog, Typography2 } from '@coursera/cds-core';

import LoadingIcon from 'bundles/courseraLoadingIcon/LoadingIcon';
import TrackedDiv from 'bundles/page/components/TrackedDiv';

import _t from 'i18n!nls/enroll';

const { HeadingGroup, Content, Actions } = Dialog;

type Props = {
  s12nId: string;
  handleClose: () => void;
  openModal: boolean;
  onEnrollButtonClick: () => void;
  isEnrolling: boolean;
};

const AutomaticallyPreEnrollModal: React.FC<Props> = ({
  s12nId,
  handleClose,
  openModal,
  onEnrollButtonClick,
  isEnrolling,
}) => {
  useEffect(() => {
    // Pre enroll user on when component first renders
    onEnrollButtonClick();
  }, []);

  const closeAndRefresh = () => {
    handleClose();
    window.location.href = window.location.pathname;
  };

  return (
    <Dialog
      onClose={closeAndRefresh}
      open={openModal}
      css={css`
        text-align: center;
      `}
    >
      <TrackedDiv
        trackingName="auto_pre_enroll_modal"
        data={{ id: s12nId }}
        withVisibilityTracking
        requireFullyVisible={false}
      >
        {isEnrolling ? (
          <div>
            <HeadingGroup>{_t('Enrolling')}</HeadingGroup>
            <Content>
              <LoadingIcon />
            </Content>
          </div>
        ) : (
          <div>
            <HeadingGroup>{_t('You successfully pre-enrolled')}</HeadingGroup>
            <Content>
              <Typography2 component="p" variant="bodyPrimary">
                {_t('Got it! You will be auto-enrolled when the program starts.')}
              </Typography2>
            </Content>
            <Actions>
              <Button variant="primary" onClick={closeAndRefresh}>
                {_t('Close')}
              </Button>
            </Actions>
          </div>
        )}
      </TrackedDiv>
    </Dialog>
  );
};

export default memo(AutomaticallyPreEnrollModal);
