import * as React from 'react';
import { useState } from 'react';

import type { CourseraTierSubscriptions_BillingCycle as BillingCycleType } from '__generated__/graphql-types';
import { compose } from 'recompose';

import type { ButtonProps } from '@coursera/cds-core';
import { Button } from '@coursera/cds-core';
import type { ApiStatus } from '@coursera/coursera-ui';
import {
  API_BEFORE_SEND,
  API_ERROR,
  API_IN_PROGRESS,
  API_SUCCESS,
} from '@coursera/coursera-ui/lib/constants/sharedConstants';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import { saveRedirectUrlAfterUpgrade } from 'bundles/coursera-plus/utils/subscriptionTiersUtils';
import useSubscriptionTiersMidCycleUpgradeMutation from 'bundles/coursera-plus/utils/useSubscriptionTiersMidCycleUpgradeMutation';
import type { PropsFromWithCreateMidCycleUpgradeCartMutation } from 'bundles/coursera-plus/utils/withCreateMidCycleUpgradeCartMutation';
import withCreateMidCycleUpgradeCartMutation from 'bundles/coursera-plus/utils/withCreateMidCycleUpgradeCartMutation';
import { submitEnrollmentPromise } from 'bundles/enroll-course/lib/enrollmentChoiceUtils';
import savedCartUtils from 'bundles/payments/lib/savedCartUtils';

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps>(Button);

type PropsFromCaller = {
  courseId?: string;
  s12nId?: string;
  fromTierSubscriptionId: string;
  handleUpgradeSuccessful: () => void;
  showErrorStateModal: () => void;
  isFullWidth?: boolean;
  isStandaloneCourseraPlusUpgrade?: boolean;
  children: React.ReactNode;
  toTierBillingCycle: BillingCycleType;
  countryIsoCode?: string;
  currencyCode?: string;
};

type PropsToComponent = PropsFromCaller & PropsFromWithCreateMidCycleUpgradeCartMutation;

const UpgradeNowButton: React.FC<PropsToComponent> = ({
  children,
  courseId,
  s12nId,
  fromTierSubscriptionId,
  createMidCycleUpgradeCart,
  showErrorStateModal,
  handleUpgradeSuccessful,
  isFullWidth = false,
  isStandaloneCourseraPlusUpgrade,
  toTierBillingCycle,
  countryIsoCode,
  currencyCode,
}) => {
  const { midCycleUpgrade } = useSubscriptionTiersMidCycleUpgradeMutation();
  const [apiStatus, setApiStatus] = useState<ApiStatus>(API_BEFORE_SEND);
  const trackingData = {
    courseId,
    s12nId,
  };

  // We are temporarily disabling the ability to go to checkout and change credit
  // card details during upgrade as we transition to support multiple payment
  // processors through the Payment Processor Agnostic upgrade solution.
  // This feature will be brought back in the future.
  const shouldRedirectToCheckout = false;

  const handleClick = () => {
    if (apiStatus === API_IN_PROGRESS || typeof midCycleUpgrade !== 'function') {
      return;
    }

    setApiStatus(API_IN_PROGRESS);

    const options = { courseId, s12nId, toTierBillingCycle, fromTierSubscriptionId, countryIsoCode, currencyCode };
    midCycleUpgrade(options)
      .then(() => {
        setApiStatus(API_SUCCESS);

        if (isStandaloneCourseraPlusUpgrade) {
          setTimeout(() => {
            handleUpgradeSuccessful();
          }, 1000);
        }

        // we are clearing any saved cart from local storage if the learner
        // first visited the checkout page before coming back to upgrade here
        // this will prevent them from seeing the persistent cart icon
        // on the global navigation bar
        savedCartUtils.reset();
        handleUpgradeSuccessful();
      })
      .catch(() => {
        setApiStatus(API_ERROR);
        if (shouldRedirectToCheckout) {
          // First timeout to let error state on upgrade button linger for 1 second.
          // Second timeout to display error state modal before redirecting to checkout
          setTimeout(() => {
            showErrorStateModal();

            setTimeout(() => {
              submitEnrollmentPromise({
                handleSubmitPromise: createMidCycleUpgradeCart,
                options,
              }).then(() => {
                saveRedirectUrlAfterUpgrade();
              });
            }, 5000);
          }, 1000);
        }
      });
  };

  return (
    <TrackedButton
      variant="primary"
      trackingName="ideal_upgrade_button"
      data-testId="ideal-upgrade-button"
      trackingData={trackingData}
      onClick={handleClick}
      loading={apiStatus !== API_BEFORE_SEND}
      disabled={apiStatus !== API_BEFORE_SEND}
      fullWidth={isFullWidth}
    >
      {children}
    </TrackedButton>
  );
};

export default compose<PropsToComponent, PropsFromCaller>(withCreateMidCycleUpgradeCartMutation)(UpgradeNowButton);
