/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Typography } from '@coursera/cds-core';

import _t from 'i18n!nls/program-home';

const styles = {
  quickjump: css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 24px 0;
  `,
};

type Props = {
  collections: Array<{ id: string; title: string }>;
};

export const AcademicSubjectQuickJump: React.FC<Props> = ({ collections }) => {
  if (!collections) {
    return null;
  }

  return (
    <div>
      <Typography variant="h2semibold">{_t('Quick jump to most popular academic subjects')}</Typography>
      <div css={styles.quickjump}>
        {collections.map((collection) => (
          <a href={`#${collection.id}`}>{collection.title}</a>
        ))}
      </div>
    </div>
  );
};

export default AcademicSubjectQuickJump;
