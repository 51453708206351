

import type { DropdownProps } from '@coursera/cds-core';

type Direction = {
  anchorOrigin: DropdownProps['anchorOrigin'];
  transformOrigin: DropdownProps['transformOrigin'];
  offset: {
    x?: string;
    y?: string;
  };
  // this needs to be `null` when we're using `anchorOrigin` to avoid react warnings
  getContentAnchorEl: null;
};

const BOTTOM_DIRECTION: Direction = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  offset: { y: '8px' },
  getContentAnchorEl: null,
};

const TOP_DIRECTION: Direction = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  offset: { y: '-8px' },
  getContentAnchorEl: null,
};

const directionMap = {
  top: TOP_DIRECTION,
  bottom: BOTTOM_DIRECTION,
};

export const getMenuDirectionProps = (direction?: 'top' | 'bottom'): Direction => {
  return directionMap[direction ?? 'bottom'];
};
