/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { placeholder, css as yuckyCSS } from '@coursera/coursera-ui';

type SkeletonProps = {
  width?: number | string;
  // TODO(ppaskaris): improve this interface with something like width and isFluid
  fixedWidth?: number | string;
  // css from the caller overwrites our inner css, and we can't use inline styles for some reason.
  bonusCSS?: Parameters<typeof css>[0];
  htmlAttributes?: React.HTMLAttributes<HTMLElement> & {
    'data-testId'?: string;
  };
};

const Skeleton = ({ width, fixedWidth, bonusCSS, htmlAttributes = {} }: SkeletonProps) => {
  let widthCSS: Parameters<typeof css>[0];
  if (width) {
    widthCSS = {
      display: 'inline-block',
      width: '100%',
      maxWidth: width,
      // Tricks to make it line up correctly.
      verticalAlign: 'bottom',
      lineHeight: 1.25,
    };
  } else if (fixedWidth) {
    widthCSS = {
      display: 'inline-block',
      width: fixedWidth,
      // Tricks to make it line up correctly.
      verticalAlign: 'bottom',
      lineHeight: 1.25,
    };
  } else {
    widthCSS = {
      display: 'block',
    };
  }
  return (
    <span
      {...yuckyCSS(placeholder.styles.shimmer)}
      role="presentation"
      css={css(widthCSS, bonusCSS)}
      {...htmlAttributes}
    >
      &nbsp;
    </span>
  );
};

export default Skeleton;
