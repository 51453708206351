import * as React from 'react';

import { compose } from 'recompose';

import Retracked from 'js/app/retracked';

import type { Program } from 'bundles/program-common/types/programCommon';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import { PersonalizedTabRootContent } from 'bundles/program-personalized-tab/pages/enterprise/PersonalizedTabRootContent';
import PersonalizedTabDataProvider from 'bundles/program-personalized-tab/providers/PersonalizedTabDataProvider';

type PropsFromCaller = {
  program: Pick<Program, 'id' | 'slug' | 'name'>;
};

type PropsToComponent = PropsFromCaller;

const PersonalizedTabRoot = ({ program }: PropsToComponent) => (
  <PersonalizedTabDataProvider programId={program.id}>
    <PersonalizedTabRootContent programSlug={program.slug} programName={program.name} />
  </PersonalizedTabDataProvider>
);

export default compose<PropsToComponent, PropsFromCaller>(
  Retracked.createTrackedContainer(() => ({
    namespace: {
      app: 'program_home_v3',
      page: 'personalized_tab',
    },
  }))
)(PersonalizedTabRoot);
