import * as React from 'react';

import type { IconColor, IconSize } from 'bundles/ai-coach-platform/types';

/**
 * MUI icon for "Add"
 */
const Add = ({ size = 16, color = 'default' }: { size?: IconSize; color?: IconColor }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.14865 8.875H1.875C1.62669 8.875 1.41882 8.78939 1.25139 8.61818C1.0838 8.44696 1 8.242 1 8.00331C1 7.76462 1.0838 7.5625 1.25139 7.39696C1.41882 7.23142 1.62669 7.14865 1.875 7.14865H7.14865V1.875C7.14865 1.63536 7.23268 1.42962 7.40074 1.25777C7.56881 1.08592 7.76982 1 8.00378 1C8.23775 1 8.44144 1.08592 8.61487 1.25777C8.78829 1.42962 8.875 1.63536 8.875 1.875V7.14865H14.1486C14.3817 7.14865 14.5818 7.23284 14.7491 7.40122C14.9164 7.56944 15 7.77289 15 8.01159C15 8.25028 14.9164 8.4539 14.7491 8.62243C14.5818 8.79081 14.3817 8.875 14.1486 8.875H8.875V14.1486C8.875 14.3815 8.78868 14.5817 8.61605 14.7491C8.44341 14.9164 8.23664 15 7.99574 15C7.76178 15 7.56211 14.9164 7.39672 14.7491C7.23134 14.5817 7.14865 14.3815 7.14865 14.1486V8.875Z"
      fill={color === 'invert' ? 'var(--cds-color-white-0)' : 'var(--cds-color-grey-950)'}
    />
  </svg>
);

export default Add;
