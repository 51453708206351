import * as React from 'react';

import { Button } from '@coursera/cds-core';
import type { ButtonProps } from '@coursera/cds-core';
import { ChevronDownIcon, ChevronUpIcon } from '@coursera/cds-icons';
import { SvgButton, color } from '@coursera/coursera-ui';
import { SvgChevronDown, SvgChevronUp } from '@coursera/coursera-ui/svg';

import type { TrackingProps } from 'bundles/common/components/withSingleTracked';
import withSingleTracked from 'bundles/common/components/withSingleTracked';

const TrackedSvgButton = withSingleTracked({ type: 'BUTTON' })(SvgButton);
const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps>(Button);

export type InformationCardToggleProps = TrackingProps & {
  /** Determines the direction of the SvgChevron */
  isOpen: boolean;

  /** on click handler  */
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;

  /** button label */
  label: string;

  rootClassName?: string;

  style?: React.CSSProperties;
  cardHtmlId?: string;
  buttonSize?: 'small' | 'medium';
  isCdsEnabled?: boolean;
};

// eslint-disable-next-line camelcase
const CustomStyles_AntiPattern_PleaseDoNotCopyThis = {
  Button: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '24px',
    ':hover': {
      textDecoration: 'underline',
      outline: 'none',
    },
    ':focus': {
      textDecoration: 'underline',
      outline: 'none',
    },
  },
};

const InformationCardToggle: React.FC<InformationCardToggleProps> = ({
  isOpen,
  onClick,
  label,
  rootClassName,
  style,
  trackingName,
  trackingData,
  cardHtmlId,
  buttonSize = 'small',
  isCdsEnabled = false,
}) => {
  if (isCdsEnabled) {
    const SvgElement = isOpen ? ChevronUpIcon : ChevronDownIcon;
    return (
      <TrackedButton
        {...Button.defaultProps}
        className={rootClassName}
        variant="ghost"
        size={buttonSize}
        icon={<SvgElement size={buttonSize} />}
        iconPosition="after"
        onClick={onClick}
        style={style}
        aria-expanded={isOpen}
        aria-controls={cardHtmlId}
        trackingName={trackingName}
        trackingData={trackingData}
      >
        {label}
      </TrackedButton>
    );
  } else {
    const SvgElement = isOpen ? SvgChevronUp : SvgChevronDown;
    return (
      <TrackedSvgButton
        rootClassName={rootClassName}
        type="link"
        label={label}
        svgElement={<SvgElement color={color.primary} hoverColor={color.primary} size={18} suppressTitle />}
        size="zero"
        onClick={onClick}
        isChildrenOnRight={true}
        style={style}
        // eslint-disable-next-line camelcase
        customStyles={CustomStyles_AntiPattern_PleaseDoNotCopyThis}
        htmlAttributes={{
          'aria-expanded': isOpen,
          'aria-controls': cardHtmlId,
        }}
        trackingName={trackingName}
        trackingData={trackingData}
      />
    );
  }
};

export default InformationCardToggle;
