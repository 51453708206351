export const API_TAGS = {
  JOIN_PROGRAM: 'JOIN_PROGRAM',
  ACCEPT_INVITATION: 'ACCEPT_INVITATION',
  RESEND_EMAIL: 'RESEND_EMAIL',
  UPDATE_SWITCHER: 'UPDATE_SWITCHER',
  SET_ALTERNATIVE_EMAIL: 'SET_ALTERNATIVE_EMAIL',
  SET_ALTERNATIVE_EMAIL_LATER: 'SET_ALTERNATIVE_EMAIL_LATER',
};

export const ProgramHomePageTypes = {
  CATALOG: 'lihp_catalog',
  RECOMMENDATIONS: 'lihp_recommendations',
  MY_COURSES: 'lihp_my_courses',
  SKILLSETS: 'lihp_skillsets',
  PROGRAM_SKILLSETS: 'skillsets',
  PROGRAM_SKILLS: 'skills',
  EXPLORE_CAREERS: 'lihp_explore_careers',
  MY_LEARNING: 'lihp_my_learnings',
  HOME: 'lihp_home',
  ENTERPRISE_SEARCH: 'search_result_page',
} as const;
