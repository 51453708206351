import { useQuery } from '@apollo/client';

import user from 'js/lib/user';

import { EnterpriseAdminUser } from 'bundles/enterprise-admin/EnterpriseAdminUser';
import IsProgramAdmin from 'bundles/enterprise-admin/queries/IsProgramAdmin.graphql';
import type {
  IsProgramAdminQuery,
  IsProgramAdminQueryVariables,
} from 'bundles/enterprise-admin/queries/__generated__/IsProgramAdmin';

/**
 * The singleton pattern here is redundant because of the webpack build system,
 * but makes the intent of this module more obvious.
 *
 * This model is being used as a persistent data store.
 */
const admin = new EnterpriseAdminUser();

export default admin;

export const useIsProgramAdmin = (programId?: string) => {
  const { data, loading, error } = useQuery<IsProgramAdminQuery, IsProgramAdminQueryVariables>(IsProgramAdmin, {
    variables: { programId },
    context: { clientName: 'gatewayGql' },
    skip: !user.isAuthenticatedUser(),
  });
  const isProgramAdmin = data?.EnterprisePermission?.queryIsProgramAdmin;
  return { isProgramAdmin, loadingIsProgramAdmin: loading, isProgramAdminError: error };
};
