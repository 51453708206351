import { useContext, useEffect, useState } from 'react';

import { FluxibleContext } from './FluxibleContext';

type StoreClass = { new (...args: any[]): any };

function useFluxibleStore<S extends StoreClass, T>(Store: S, stateMapper: (store: InstanceType<S>) => T): T;
function useFluxibleStore<S extends object, T>(Store: S, stateMapper: (store: S) => T): T;
// store typed as any because typescript does not allow us to do (store: S | InstanceType<S>),
// but using any here still preserves the overloaded types for the function signature
function useFluxibleStore<S, T>(Store: S, stateMapper: (store: any) => T): T {
  const context = useContext(FluxibleContext);

  const store = context.getStore(Store as any);
  const [state, setState] = useState(() => stateMapper(store));

  useEffect(() => {
    const onStoreChange = () => {
      setState(stateMapper(store));
    };

    store.addListener('change', onStoreChange);
    return () => {
      store.removeListener('change', onStoreChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  return state;
}

export default useFluxibleStore;
