const ReplyMessageConstants = {
  States: {
    Idle: 'idle',
    InProgress: 'inProgress',
    Success: 'success',
  },

  EntryTypes: {
    Flag: 'FLAG',
    Feedback: 'FEEDBACK',
    Story: 'STORY',
  },
};

export default ReplyMessageConstants;

export const { States, EntryTypes } = ReplyMessageConstants;
