import cookie from 'js/lib/cookie';

const PURCHASE_TRACKER_COOKIE = 'purchaseTrackerForCourseraPlus';

export const setPurchaseTrackerCookie = (promoCode: string) => {
  cookie.set(PURCHASE_TRACKER_COOKIE, promoCode);
};

export const removePurchaseTrackerCookie = () => {
  cookie.remove(PURCHASE_TRACKER_COOKIE);
};
