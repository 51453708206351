/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Grid } from '@coursera/cds-core';

type Props = {
  header: React.ReactNode;
} & React.HTMLAttributes<HTMLElement>;

const styles = {
  container: () => css`
    margin-bottom: 32px;
    box-shadow: 1px 2px 3px 0 var(--cds-color-grey-50);
    border: 1px solid var(--cds-color-grey-50);
  `,
  title: () => css`
    padding: 14px 18px 10px 18px;
    background-color: var(--cds-color-grey-25);
    border-bottom: 1px solid var(--cds-color-grey-50);
  `,
  children: css`
    padding: 18px;
  `,
};

export const CardWithHeader: React.FC<Props> = ({ header, children, ...props }) => {
  return (
    <Grid container css={styles.container} {...props}>
      <Grid item xs={12} css={styles.title}>
        {header}
      </Grid>
      <Grid item xs={12} css={styles.children}>
        {children}
      </Grid>
    </Grid>
  );
};
