import * as React from 'react';
import { useEffect } from 'react';

import { noop } from 'lodash';

import { VisuallyHidden } from '@coursera/cds-core';
import type { ButtonProps } from '@coursera/coursera-ui';
import type { ApiStatus } from '@coursera/coursera-ui/lib/constants/sharedConstants';

import type { TrackingData } from 'bundles/common/components/withSingleTracked';
import withSingleTracked from 'bundles/common/components/withSingleTracked';
import ErrorMessage from 'bundles/coursera-ui/components/extended/ErrorMessage';
import type { NaptimeError } from 'bundles/naptimejs';
import CourseEnrollButton from 'bundles/program-common/components/programActionButton/CourseEnrollButton';
import CourseLinkButton from 'bundles/program-common/components/programActionButton/CourseLinkButton';
import CourseSelectButton from 'bundles/program-common/components/programActionButton/CourseSelectButton';
import CourseUnenrollButton from 'bundles/program-common/components/programActionButton/CourseUnenrollButton';
import CourseUnselectButton from 'bundles/program-common/components/programActionButton/CourseUnselectButton';
import type { CurriculumProduct } from 'bundles/program-common/components/programActionButton/CurriculumProduct';
import S12nEnrollButton from 'bundles/program-common/components/programActionButton/S12nEnrollButton';
import S12nSelectButton from 'bundles/program-common/components/programActionButton/S12nSelectButton';
import S12nUnenrollButton from 'bundles/program-common/components/programActionButton/S12nUnenrollButton';
import S12nUnselectButton from 'bundles/program-common/components/programActionButton/S12nUnselectButton';
import { ACTION_TYPES } from 'bundles/program-common/constants/ProgramActionConstants';
import ProgramAdminActions from 'bundles/program-home/components/ProgramAdminActions';
import getErrorMap from 'bundles/program-home/constants/ProgramJoinModalErrors';

import _t from 'i18n!nls/program-common';

type Props = {
  trackingInfo?: {
    trackingName: string;
    trackingData: TrackingData;
  };
  btnAttributes?: ButtonProps;
  resumeBtnAttributes?: ButtonProps & { 'data-e2e'?: string };
  renderNothingIfConditionNotMet?: boolean;
  productId: string;
  actionType: string;
  curriculumProduct: CurriculumProduct;
  programId: string;
  isCourse: boolean;
  isProject?: boolean;
  renderAdminAction?: boolean;
  renderIfEnrolled?: boolean;
  renderIfNotEnrolled?: boolean;
  renderIfSelected?: boolean;
  renderIfNotSelected?: boolean;
  renderCourseHomeLink?: boolean;
  initialBtnLabel?: JSX.Element | string;
  error?: NaptimeError | null;
  apiStatus?: ApiStatus;
  thirdPartyOrganizationId?: string;
  deepLinkItemId?: string;
  autoEnroll?: (onTriggerEnrollment: () => void) => void;
  callbacks: {
    [key: string]: () => void;
    enrollInCourse: () => void;
    unenrollFromCourse: () => void;
    selectCourse: () => void;
    unselectCourse: () => void;
    enrollInS12n: () => void;
    unenrollFromS12n: () => void;
    selectS12n: () => void;
    unselectS12n: () => void;
  };
};

const getDefaultErrorMessage = () => _t('Failed to update');
const { ENROLL, UNENROLL, SELECT, UNSELECT, RESUME } = ACTION_TYPES;

type CCEnroll = {
  component: typeof CourseEnrollButton;
  callbackName: 'enrollInCourse';
  condition: 'canEnroll';
};

type CCUnEnroll = {
  component: typeof CourseUnenrollButton;
  callbackName: 'unenrollFromCourse';
  condition: 'canUnenroll';
};

type CCSelect = {
  component: typeof CourseSelectButton;
  callbackName: 'selectCourse';
  condition: 'canSelect';
};

type CCUnselect = {
  component: typeof CourseUnselectButton;
  callbackName: 'unselectCourse';
  condition: 'canUnselect';
};

type CCResume = {
  component: typeof CourseLinkButton;
  callbackName: undefined;
  condition: undefined;
};

type CSEnroll = {
  component: typeof S12nEnrollButton;
  callbackName: 'enrollInS12n';
  condition: 'canEnroll';
};
type CSUnEnroll = {
  component: typeof S12nUnenrollButton;
  callbackName: 'unenrollFromS12n';
  condition: 'canUnenroll';
};
type CSSelect = {
  component: typeof S12nSelectButton;
  callbackName: 'selectS12n';
  condition: 'canSelect';
};
type CSUnSelect = {
  component: typeof S12nUnselectButton;
  callbackName: 'unselectS12n';
  condition: 'canUnselect';
};

type ComponentConfig =
  | CCEnroll
  | CCUnEnroll
  | CCSelect
  | CCUnselect
  | CCResume
  | CSEnroll
  | CSUnEnroll
  | CSSelect
  | CSUnSelect;

const CONFIG: { [key: string]: { [key: string]: ComponentConfig } } = {
  COURSE: {
    ENROLL: {
      component: CourseEnrollButton,
      callbackName: 'enrollInCourse',
      condition: 'canEnroll',
    },
    UNENROLL: {
      component: CourseUnenrollButton,
      callbackName: 'unenrollFromCourse',
      condition: 'canUnenroll',
    },
    SELECT: {
      component: CourseSelectButton,
      callbackName: 'selectCourse',
      condition: 'canSelect',
    },
    UNSELECT: {
      component: CourseUnselectButton,
      callbackName: 'unselectCourse',
      condition: 'canUnselect',
    },
    RESUME: {
      component: CourseLinkButton,
      callbackName: undefined,
      condition: undefined,
    },
  },
  S12N: {
    ENROLL: {
      component: S12nEnrollButton,
      callbackName: 'enrollInS12n',
      condition: 'canEnroll',
    },
    UNENROLL: {
      component: S12nUnenrollButton,
      callbackName: 'unenrollFromS12n',
      condition: 'canUnenroll',
    },
    SELECT: {
      component: S12nSelectButton,
      callbackName: 'selectS12n',
      condition: 'canSelect',
    },
    UNSELECT: {
      component: S12nUnselectButton,
      callbackName: 'unselectS12n',
      condition: 'canUnselect',
    },
  },
};

//
// TODO: the child components of this have poor intersection on their props.
// For example; ApiStatus vs string; different props, etc. Much code change work
// has to be done to make the types here sane. To progress in the static typing
// process, I've used an any here to create an anonymous type to work around the
// issue. But a better fix would be better composition here (or consistent props
// between the children).
//
type AnonymousComponent = React.ComponentType<$TSFixMe>;

const TrackedComponents = new WeakMap<AnonymousComponent, AnonymousComponent>();

const ProgramActionButton = ({
  trackingInfo,
  btnAttributes = {},
  resumeBtnAttributes = {
    label: _t('Resume'),
    type: 'primary',
  },
  renderNothingIfConditionNotMet,
  productId,
  actionType,
  curriculumProduct,
  curriculumProduct: { isEnrolled, isSelected, canManage },
  programId,
  isCourse,
  isProject,
  renderAdminAction,
  renderIfEnrolled,
  renderIfNotEnrolled,
  renderIfSelected,
  renderIfNotSelected,
  renderCourseHomeLink,
  error,
  apiStatus,
  callbacks,
  thirdPartyOrganizationId,
  deepLinkItemId,
  autoEnroll,
  ...rest
}: Props) => {
  const { component, callbackName, condition } = CONFIG[isCourse ? 'COURSE' : 'S12N'][actionType];

  useEffect(
    () => {
      if (deepLinkItemId && callbackName && autoEnroll) {
        autoEnroll(callbacks[callbackName]);
      }
    }, // FIXME: existing react-hooks/exhaustive-deps violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deepLinkItemId, autoEnroll]
  );

  if (canManage && renderAdminAction) {
    return <ProgramAdminActions programId={programId} />;
  }

  if (
    (renderIfEnrolled && !isEnrolled) ||
    (renderIfNotEnrolled && isEnrolled) ||
    (renderIfSelected && !isSelected) ||
    (renderIfNotSelected && isSelected)
  ) {
    return null;
  }

  let propsToPassdown: {
    isEnrolled?: boolean;
    isSelected?: boolean;
    canManage?: boolean;
    canEnroll?: boolean;
    canUnenroll?: boolean;
    canSelect?: boolean;
    canUnselect?: boolean;
    hasNoOpenSessions?: boolean;
  } = {};

  if (actionType === ENROLL || actionType === UNENROLL) {
    // Generate {isEnrolled, canEnroll, hasNoOpenSessions} or {isEnrolled, canUnenroll, hasNoOpenSessions}
    propsToPassdown = {
      isEnrolled,
      hasNoOpenSessions: curriculumProduct.hasNoOpenSessions,
    };

    if (condition) {
      propsToPassdown[condition] = curriculumProduct[condition];
    }
  } else if (actionType === SELECT || actionType === UNSELECT) {
    // Generate {isSelected, canSelect} or {isSelected, canUnselect}
    propsToPassdown = { isSelected };

    if (condition) {
      propsToPassdown[condition] = curriculumProduct[condition];
    }
  }

  Object.assign(propsToPassdown, rest);

  const renderResume = actionType === RESUME && isCourse;
  let Component: AnonymousComponent = component;

  const errorMap = getErrorMap(programId) as Record<string, string>;

  if (trackingInfo) {
    // To avoid creating a new component type every render, memoize the call to withSingleTracked.
    let TrackedComponent = TrackedComponents.get(Component);
    if (TrackedComponent == null) {
      TrackedComponent = withSingleTracked({ type: 'BUTTON' })(Component);
      TrackedComponents.set(Component, TrackedComponent);
    }
    Component = TrackedComponent;
    propsToPassdown = { ...propsToPassdown, ...trackingInfo };
  }

  if (!error && renderResume) {
    return <CourseLinkButton id={productId} {...resumeBtnAttributes} />;
  }

  const ariaLiveLabel = !error ? '' : 'Failed to enroll you into the course. Please refresh page and try again';

  return (
    <React.Fragment>
      {!error ? (
        <Component
          {...propsToPassdown}
          productId={productId}
          isProject={isProject}
          renderCourseHomeLink={renderCourseHomeLink}
          onClick={callbackName ? callbacks[callbackName] : noop}
          apiStatus={apiStatus}
          btnAttributes={btnAttributes}
          renderNothingIfConditionNotMet={renderNothingIfConditionNotMet || false}
        />
      ) : (
        <ErrorMessage
          error={error}
          defaultErrorMsg={(error.responseJSON && errorMap[error.responseJSON.errorCode]) || getDefaultErrorMessage()}
        />
      )}
      <VisuallyHidden component="span" className="rc-A11yScreenReaderOnly">
        <div role="alert" aria-live="assertive">
          {ariaLiveLabel}
        </div>
      </VisuallyHidden>
    </React.Fragment>
  );
};

export default ProgramActionButton;
