import type { AiCoach_AdditionalContextInput as AiCoachAdditionalContextInput } from '__generated__/graphql-types';

export enum ContextGroup {
  COURSE = 'COURSE',
  S12N = 'S12N',
  ADDITIONAL_PRODUCTS = 'ADDITIONAL_PRODUCTS',
  USER = 'USER',
  PREVIOUS_RESPONSE = 'PREVIOUS_RESPONSE',
}

export type ContextFetcher = { contextFetcherKey: string; contextGroup: ContextGroup };

export type StudioSettings = {
  model: string;
  systemPrompt: string;
  pageContext: string;
  additionalContext: AiCoachAdditionalContextInput[];
  contextFetchers: string[];
  additionalProducts: { productId: string; productType: string; id: string }[];
  modelParameters: { chatHistoryLimit: number; chatHistoryTokenLimit: number; totalTokenLimit: number };
  courseId?: string;
  specializationId?: string;
};
