/* eslint-disable camelcase */
import { getUserLanguageCode } from 'js/lib/language';

export type PageType = 'learner' | 'admin' | 'educator';

type PreChatFormData = {
  firstName: string;
  lastName: string;
  email: string;
  reasonForContacting: string;
  preferredSupportLanguage: string;
  agentSkill: string;
  description: string;
};

type AgentAvailabilityData = {
  isAgentAvailable: boolean | undefined;
};

const salesforceEnv = {
  dev: {
    init1: 'https://courseraservices--dev3.sandbox.my.salesforce.com',
    init2: 'https://courseraservices--dev3.sandbox.my.site.com/',
    init3: '00D7e00000GMCFS',
    baseLiveAgentContentUrl: 'https://c.la3-c1cs-ia5.salesforceliveagent.com/content',
    baseLiveAgentUrl: 'https://d.la3-c1cs-ia5.salesforceliveagent.com/chat',
    eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I7e0000008OTDEA2_182da1eb0b2',
    setAttribute: 'https://courseraservices--dev3.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js',
  },
  prod: {
    init1: 'https://courseraservices.my.salesforce.com',
    init2: 'https://www.coursera.support/',
    init3: '00D1U000000y4UJ',
    baseLiveAgentContentUrl: 'https://c.la5-c1-ia5.salesforceliveagent.com/content',
    baseLiveAgentUrl: 'https://d.la5-c1-ia5.salesforceliveagent.com/chat',
    eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I8W000000bnwWUAQ_1833cb966d0',
    setAttribute: 'https://courseraservices.my.salesforce.com/embeddedservice/5.0/esw.min.js',
  },
};

// This initializes Salesforce chat config. This should happen only once at page load (in HelpBox.tsx)
export const initializeSFChatConfig = (
  pageType: PageType,
  setIsAgentAvailable: (isAgentAvailable: boolean) => void
) => {
  const upgradedWindow: typeof window & { embedded_svc?: $TSFixMe } = window;
  const getChatLanguage = () => {
    // Learner and educator chat is available in English and Spanish, any other language defaults to English
    // Admin chat is only available in English
    const userLanguage = getUserLanguageCode();
    let chatLanguage = 'en';
    if ((pageType === 'learner' || pageType === 'educator') && userLanguage === 'es') {
      chatLanguage = 'es';
    }
    return chatLanguage;
  };

  const initESW = function (gslbBaseURL: string | null) {
    const pageTypeToButtonId = {
      learner: '5731U000000kkyB',
      admin: '5738W000000QTG6',
      educator: '5738W000000QTGB',
    };

    upgradedWindow.embedded_svc.settings.displayHelpButton = false; // Or false
    upgradedWindow.embedded_svc.settings.language = getChatLanguage(); // For example, enter 'en' or 'en-US'

    // Settings for Chat | It will dynamically change the chat button Id based on pageType
    upgradedWindow.embedded_svc.settings.directToButtonRouting = function () {
      return pageTypeToButtonId[pageType];
    };

    upgradedWindow.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
    upgradedWindow.embedded_svc.settings.entryFeature = 'LiveAgent';

    /* 
    We use the onSettingsCallCompleted and onAvailability event handlers to dynamically update the pre-chat form contents based on agent availability. 
    If an agent is available, the user can initiate a chat. Otherwise, we link them to the admin help center or educator resource center to submit a case. 
    */
    // onSettingsCallCompleted event handler fires when chat is initialized on page load and indicates initial agent availability status
    upgradedWindow.embedded_svc.addEventHandler('onSettingsCallCompleted', (data: AgentAvailabilityData) => {
      const { isAgentAvailable } = data;
      setIsAgentAvailable(!!isAgentAvailable);
    });
    // onAvailability event handler fires a loop every 60 seconds and indicates agent availability status
    upgradedWindow.embedded_svc.addEventHandler('onAvailability', (data: AgentAvailabilityData) => {
      const { isAgentAvailable } = data;
      setIsAgentAvailable(!!isAgentAvailable);
    });

    upgradedWindow.embedded_svc.init(
      salesforceEnv.prod.init1,
      salesforceEnv.prod.init2,
      gslbBaseURL,
      salesforceEnv.prod.init3,
      'AHC_Chat_Platform',
      {
        baseLiveAgentContentURL: salesforceEnv.prod.baseLiveAgentContentUrl,
        deploymentId: '5721U000000kkkS',
        buttonId: '5738W000000QTG6',
        baseLiveAgentURL: salesforceEnv.prod.baseLiveAgentUrl,
        eswLiveAgentDevName: salesforceEnv.prod.eswLiveAgentDevName,
        isOfflineSupportEnabled: false,
      }
    );
  };

  if (!upgradedWindow.embedded_svc) {
    const s = document.createElement('script');
    s.setAttribute('src', salesforceEnv.prod.setAttribute);
    s.onload = function () {
      initESW(null);
    };
    document.body.appendChild(s);
  } else {
    initESW('https://service.force.com');
  }
};

// This initializes and opens a new Salesforce chat. It should be called whenever a user clicks a button to initiate chat.
export const initializeSFChat = (pageType: PageType, formData: PreChatFormData) => {
  const { firstName, lastName, email, reasonForContacting, preferredSupportLanguage, agentSkill, description } =
    formData;

  const upgradedWindow = window as unknown as Window & { embedded_svc: $TSFixMe };

  // Pre-chat fields: name, email, subject, reason for contacting, preferred support language, help center, agent skill, origin, channel, degree program
  const attributes = {
    firstName,
    lastName,
    email,
    origin: 'Platform',
    channel: 'Chat',
    // learner: {
    //   buttonId: '5731U000000kkyB',
    //   reasonForContacting, // 'Account & login', 'Courses & certificates', 'Payments & subscriptions', 'Coursera policies', 'Platform Issues & Access'
    //   recordTypeId: '0121U000000lUhhQAE',
    //   preferredSupportLanguage, // English, Spanish, anything else defaults to English
    //   agentSkill,
    //   description,
    //   helpCenter: 'Learner',
    //   degreeProgram: 'LHC',
    // },
    admin: {
      buttonId: '5738W000000QTG6',
      reasonForContacting, // 'Platform Access Questions', 'Learner Experience Questions', 'Dashboard / Reports Questions', 'Data Download / Update Requests'
      recordTypeId: '0121U000000lUhwQAE',
      preferredSupportLanguage, // English, anything else default to English
      agentSkill,
      description,
      helpCenter: 'Admin',
      degreeProgram: 'EHC',
    },
    educator: {
      buttonId: '5738W000000QTGB',
      reasonForContacting, // 'Coursera Onboarding', 'Authoring Guidance', 'Roles & Permissions', 'Quizzes, SGAs, & Peer Reviews', 'Data & Dashboards'
      recordTypeId: '0121U000000lUhmQAE',
      preferredSupportLanguage, // English, Spanish, anything else default to English
      agentSkill,
      description,
      helpCenter: 'Educator',
      degreeProgram: 'PRC',
    },
  };

  if (pageType === 'admin' || pageType === 'educator') {
    upgradedWindow.embedded_svc.liveAgentAPI.startChat({
      directToAgentRouting: {
        buttonId: attributes[pageType].buttonId,
        fallback: true,
      },
      extraPrechatInfo: [
        {
          entityName: 'Contact',
          showOnCreate: true,
          linkToEntityName: 'Case',
          linkToEntityField: 'ContactId',
          saveToTranscript: 'ContactId',
          entityFieldMaps: [
            {
              isExactMatch: true,
              fieldName: 'FirstName',
              doCreate: false,
              doFind: true,
              label: 'First Name',
            },
            {
              isExactMatch: true,
              fieldName: 'LastName',
              doCreate: false,
              doFind: true,
              label: 'Last Name',
            },
            {
              isExactMatch: true,
              fieldName: 'Email',
              doCreate: false,
              doFind: true,
              label: 'Email',
            },
          ],
        },
        {
          entityName: 'Case',
          showOnCreate: true,
          saveToTranscript: 'CaseId',
          entityFieldMaps: [
            {
              isExactMatch: false,
              fieldName: 'Subject',
              doCreate: true,
              doFind: false,
              label: 'Subject',
            },
            {
              isExactMatch: false,
              fieldName: 'Reason_for_contacting__c',
              doCreate: true,
              doFind: false,
              label: 'Reason for contacting',
            },
            {
              isExactMatch: false,
              fieldName: 'RecordTypeId',
              doCreate: true,
              doFind: false,
              label: 'Record Type',
            },
            {
              isExactMatch: false,
              fieldName: 'Support_Language__c',
              doCreate: true,
              doFind: false,
              label: 'Preferred support language',
            },
            {
              isExactMatch: false,
              fieldName: 'Agent_Skill__c',
              doCreate: true,
              doFind: false,
              label: 'Agent Skill',
            },
            {
              isExactMatch: false,
              fieldName: 'Help_Center__c',
              doCreate: true,
              doFind: false,
              label: 'Help Center',
            },
            { isExactMatch: false, fieldName: 'Origin', doCreate: true, doFind: false, label: 'Case Origin' },
            { isExactMatch: false, fieldName: 'Channel__c', doCreate: true, doFind: false, label: 'Channel' },
            {
              isExactMatch: false,
              fieldName: 'Degree_Program__c',
              doCreate: true,
              doFind: false,
              label: 'Degree Program',
            },
          ],
        },
      ],
      extraPrechatFormDetails: [
        {
          label: 'First Name',
          name: 'FirstName',
          value: attributes.firstName,
          displayToAgent: true,
        },
        {
          label: 'Last Name',
          value: attributes.lastName,
          displayToAgent: true,
        },
        {
          label: 'Email',
          value: attributes.email,
          displayToAgent: true,
        },
        {
          label: 'Reason for contacting',
          value: attributes[pageType].reasonForContacting,
          displayToAgent: true,
        },
        {
          label: 'Subject',
          value: attributes[pageType].description,
          displayToAgent: true,
        },
        {
          label: 'Record Type',
          value: attributes[pageType].recordTypeId,
          displayToAgent: true,
        },
        {
          label: 'Preferred support language',
          value: attributes[pageType].preferredSupportLanguage,
          displayToAgent: true,
          transcriptFields: ['Support_Language__c'],
        },
        {
          label: 'Agent Skill',
          value: attributes[pageType].agentSkill,
          displayToAgent: true,
          transcriptFields: ['Agent_Skill__c'],
        },
        {
          label: 'Help Center',
          value: attributes[pageType].helpCenter,
          displayToAgent: true,
          transcriptFields: ['Help_Center__c'],
        },
        { label: 'Case Origin', value: attributes.origin, displayToAgent: true },
        {
          label: 'Channel',
          value: attributes.channel,
          displayToAgent: true,
        },
        {
          label: 'Degree Program',
          value: attributes[pageType].degreeProgram,
          displayToAgent: true,
        },
      ],
    });
  }
};
