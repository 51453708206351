import { graphql } from 'react-apollo';

import { useQuery } from '@apollo/client';
import { CareerAcademy_Variant as CareerAcademyVariant } from '__generated__/graphql-types';
import gql from 'graphql-tag';

import waitForGraphQL from 'js/lib/waitForGraphQL';

import type { ProgramCreationPageQuery_EnterpriseProgramsV1Resource_slug_elements as EnterpriseProgram } from 'bundles/enterprise-admin-program-creation/components/__generated__/ProgramCreationPageQuery';
import type { Program } from 'bundles/enterprise-admin-program-home/types';
import type { EnterpriseProgramsV1 } from 'bundles/naptimejs/resources/__generated__/EnterpriseProgramsV1';
import type TEnterpriseProgramsV1 from 'bundles/naptimejs/resources/enterprisePrograms.v1';
import GetCareerAcademyVariantByProgram from 'bundles/unified-career-academy/queries/GetCareerAcademyVariantByProgram.graphql';
import GetProgramCareerAcademyEligibility from 'bundles/unified-career-academy/queries/GetProgramCareerAcademyEligibilityQuery.graphql';
import type {
  GetCareerAcademyVariantByProgramQuery,
  GetCareerAcademyVariantByProgramQueryVariables,
} from 'bundles/unified-career-academy/queries/__generated__/GetCareerAcademyVariantByProgram';
import type {
  GetProgramCareerAcademyEligibilityQuery,
  GetProgramCareerAcademyEligibilityQueryVariables,
} from 'bundles/unified-career-academy/queries/__generated__/GetProgramCareerAcademyEligibilityQuery';
import type { PropCareerAcademyVariant } from 'bundles/unified-career-academy/utils/helpers';

export const getProgramCareerAcademyVariantQueryGraphQl = <
  TInputProps extends {
    program?: Program | EnterpriseProgramsV1 | EnterpriseProgram | TEnterpriseProgramsV1;
    programId?: string;
  }
>(
  blockingCall?: boolean
) => {
  const graphqlToUse = blockingCall ? waitForGraphQL : graphql;

  return graphqlToUse<
    TInputProps,
    GetCareerAcademyVariantByProgramQuery,
    GetCareerAcademyVariantByProgramQueryVariables,
    PropCareerAcademyVariant
  >(GetCareerAcademyVariantByProgram, {
    skip: ({ program, programId }) => !program && !programId,
    options: ({ program, programId }) => ({
      variables: {
        programId: program ? (program.id as string) : (programId as string),
      },
      context: {
        clientName: 'gatewayGql',
      },
    }),
    props: ({ data }) => {
      const variant = data?.CareerAcademy?.queryVariantByProgramId;

      return {
        careerAcademyVariant: variant,
        refetchCareerAcademyVariant: data?.refetch,
        isCareerAcademyVariantLoading: data?.loading,
      };
    },
  });
};

export const useProgramCareerAcademyVariantQuery = (programId?: string) => {
  const { data, refetch, loading } = useQuery<
    GetCareerAcademyVariantByProgramQuery,
    GetCareerAcademyVariantByProgramQueryVariables
  >(GetCareerAcademyVariantByProgram, {
    skip: !programId,
    variables: {
      // see skip
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      programId: programId!,
    },
    context: { clientName: 'gatewayGql' },
  });

  const variant = data?.CareerAcademy?.queryVariantByProgramId;

  return {
    careerAcademyVariant: variant,
    refetchCareerAcademyVariant: refetch,
    isCareerAcademyVariantLoading: loading,
  };
};

export const useProgramCareerAcademyEligibilityQuery = ({
  programId,
  variant,
}: {
  programId?: string;
  variant?: CareerAcademyVariant;
}) => {
  const { data } = useQuery<GetProgramCareerAcademyEligibilityQuery, GetProgramCareerAcademyEligibilityQueryVariables>(
    GetProgramCareerAcademyEligibility,
    {
      skip: !programId,
      variables: {
        // See above skip
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        programId: programId!,
        variant: variant ?? CareerAcademyVariant.Default,
      },
      context: { clientName: 'gatewayGql' },
    }
  );
  const isCareerAcademyEligible = data?.CareerAcademy?.queryProgramEligibilityByIdAndVariant?.eligible;

  return isCareerAcademyEligible;
};

export const ProgramBySlugQuery = gql`
  query ProgramBySlugQuery($slug: String!) {
    ProgramsV1Resource {
      slug(slug: $slug) {
        elements {
          id
          program {
            ... on ProgramsV1_enterpriseProgramMember {
              enterpriseProgram {
                metadata {
                  slug
                  name
                }
              }
            }
          }
          curriculum {
            ... on ProgramsV1_tracksCurriculumMember {
              tracksCurriculum {
                coreTracks {
                  id
                  title
                  isLocked
                  subjectId
                  roleId
                  curriculumItems {
                    ... on ProgramsV1_domainIdMember {
                      domainId {
                        id
                      }
                    }
                  }
                }
                electiveTracks {
                  id
                  title
                  isLocked
                  subjectId
                  roleId
                  curriculumItems {
                    ... on ProgramsV1_domainIdMember {
                      domainId {
                        id
                      }
                    }
                  }
                }
              }
            }
            ... on ProgramsV1_wildcardCurriculumMember {
              wildcardCurriculum {
                electiveTracks {
                  id
                  title
                  isLocked
                  subjectId
                  roleId
                  curriculumItems {
                    ... on ProgramsV1_courseIdMember {
                      courseId {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
