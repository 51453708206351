export const DEFAULT_LENGTH = 2;
export const DEFAULT_SKILL_ID = 'communication';
export const DEFAULT_SKILL_NAME = 'Communication';
export const MAX_ONBOARDING_SKILLS_LENGTH = 3;
export const SKILLS_IDS_FOR_CLIPS_ALLOW_LIST = [
  'python-programming-language',
  'communication',
  'data-analysis',
  'sql',
  'project-management',
  'microsoft-excel',
  'network-security',
  'power-bi',
  'javascript',
  'amazon-web-services',
  'c-programming-language-family',
  'deep-learning',
  'cloud-computing',
  'data-structure',
  'r-programming-language',
  'agile-software-development',
  'digital-marketing',
  'probability-and-statistics',
  'devops',
  'software-engineering',
  'software-testing',
  'blockchains',
  'data-management',
  'storytelling',
  'problem-solving',
  'accounting',
  'kubernetes',
  'linux',
  'emotional-intelligence',
  'user-experience',
  'computer-vision',
  'operating-system',
  'cyberattacks',
  'critical-thinking',
  'salesforce',
  'jira-software',
  'django',
  'android-software-development',
  'negotiation',
  'user-experience-design',
  'software-architecture',
  'matlab',
  'reinforcement-learning',
  'postgresql',
  'tensorflow',
  'cryptography',
  'financial-accounting',
  'financial-technology',
  'swift-programming-language',
  'machine-learning-algorithms',
  'sas-software',
  'ibm-cloud-computing',
  'procurement',
  'advertising',
  'data-mining',
  'epidemiology',
  'data-warehousing',
  'e-commerce',
  'game-theory',
  'culture',
  'nosql',
  'arcgis',
  'information-engineering',
  'people-development',
  'recruitment',
  'scala-programming-language',
  'people-management',
  'probability-distribution',
  'cloud-storage',
  'network-analysis',
  'accounts-payable-and-receivable',
  'entrepreneurial-finance',
  'cash-management',
  'continuous-integration',
  'experiment',
  'feature-engineering',
  'cloud-infrastructure',
  'system-software',
  'product-development',
  'customer-analysis',
  'spatial-data-analysis',
  'continuous-delivery',
  'payments',
  'transportation-operations-management',
  'database-application',
  'markov-model',
  'dimensionality-reduction',
  'extract--transform--load',
  'semantic-web',
  'statistical-machine-learning',
  'geometry',
  'looker-software',
  'management',
  'marketing',
  'finance',
  'computer-programming',
  'java-programming-language',
  'entrepreneurship',
  'html-and-css',
  'web-development',
  'strategy',
  'google-cloud-platform',
  'graphic-design',
  'microsoft-azure',
  'human-resources',
  'big-data',
  'spreadsheet-software',
  'supply-chain-and-logistics',
  'tableau-software',
  'artificial-neural-network',
  'visualization-graphic',
  'front-end-web-development',
  'algorithms',
  'product-management',
  'risk-management',
  'natural-language-processing',
  'social-media',
  'writing',
  'leadership-development',
  'change-management',
  'full-stack-web-development',
  'sales',
  'data-analysis-software',
  'web-design',
  'theoretical-computer-science',
  'security-engineering',
  'financial-analysis',
  'data-visualization-software',
  'database',
  'computer-architecture',
  'linear-algebra',
  'angular',
  'collaboration',
  'calculus',
  'decision-making',
  'internet-of-things',
  'search-engine-optimization',
  'system-security',
  'business-intelligence',
  'human-computer-interaction',
  'bayesian-statistics',
  'application-development',
  'regression',
  'people-analysis',
  'data-model',
  'audit',
  'creativity',
  'differential-equations',
  'public-relations',
  'stock-management',
  'mergers-and-acquisitions',
  'professional-development',
  'banking',
  'user-research',
  'business-to-business',
  'clinical-data-management',
  'performance-management',
  'algebra',
  'cloud-engineering',
  'talent-management',
  'application-security',
  'journalism',
  'product-lifecycle',
  'taxes',
  'data-architecture',
  'marketing-psychology',
  'training',
  'computer-graphic-techniques',
  'media-strategy',
  'account-management',
  'industrial-relations',
  'statistical-analysis',
  'interactive-data-visualization',
  'computational-thinking',
  'management-accounting',
  'apache',
  'estimation',
  'retail-sales',
  'software-engineering-tools',
  'software-defined-networking',
  'system-programming',
  'bayesian-network',
  'cloud-applications',
  'survey-creation',
  'supplier-relationship-management',
  'spatial-analysis',
  'security-strategy',
  'mobile-development-tools',
  'cloud-platforms',
  'statistical-visualization',
  'cloud-load-balancing',
  'plot-graphics',
  'other-web-frameworks',
  'security-software',
  'human-factors-security',
  'visualization-computer-graphics',
  'cloud-management',
  'cloud-based-integration',
  'other-web-programming-languages',
  'sales-systems',
  'software-visualization',
  'benefits',
];
