import { breakpoints, useMediaQuery, useTheme } from '@coursera/cds-core';

const useIsMobile = (): boolean => {
  const theme = useTheme();
  const mobileBreakpoint = breakpoints.down('xs');
  const isMobile = useMediaQuery(mobileBreakpoint);

  return isMobile;
};

export default useIsMobile;
