import { useApolloClient } from '@apollo/client';

import { tupleToStringKey } from 'js/lib/stringKeyTuple';

import type CartsV2 from 'bundles/naptimejs/resources/carts.v2';
import GetProductOwnershipById from 'bundles/payments/api/GetProductOwnershipById.graphql';
import type {
  GetProductOwnershipByIdQuery as GetProductOwnershipByIdQueryData,
  GetProductOwnershipByIdQueryVariables,
} from 'bundles/payments/api/__generated__/GetProductOwnershipById';

export const OWNERSHIP_POLLING_TIMEOUT = 'ownershipPollingTimeout';

export const usePollForProductOwnership = () => {
  const client = useApolloClient();

  const pollForProductOwnership = async (cart: CartsV2): Promise<undefined> => {
    const userId = cart.userId;
    const productItemId =
      cart.isSpecializationSubscription || cart.isSpecializationPrepaid
        ? cart.getSpecializationIdFromCartItem(cart.firstCartItem) || ''
        : cart.productItemId || '';
    const productType =
      cart.isSpecializationSubscription || cart.isSpecializationPrepaid
        ? cart.getSpecializationProductTypeFromCartItem(cart.firstCartItem) || ''
        : cart.productType || '';
    const userProductItem = tupleToStringKey([userId, productType, productItemId]);

    return new Promise((resolve, reject) => {
      let pollCount = 0;

      const polling = () => {
        if (pollCount >= 18) {
          // Polling timeout 18*2 = 36 s, usually the ownership is updated within 5s
          reject(new Error(OWNERSHIP_POLLING_TIMEOUT));
          return;
        }

        client
          .query<GetProductOwnershipByIdQueryData, GetProductOwnershipByIdQueryVariables>({
            query: GetProductOwnershipById,
            variables: {
              productOwnershipId: userProductItem,
            },
            fetchPolicy: 'no-cache',
            context: { clientName: 'gatewayGql' },
          })
          .then(({ data, error }) => {
            if (data?.Entitlement?.queryProductOwnership?.owns) {
              resolve(undefined);
            } else if (error) {
              reject(error);
            } else {
              pollCount += 1;
              setTimeout(polling, 2000);
            }
          });
      };
      setTimeout(polling, 2000);
    });
  };

  return {
    pollForProductOwnership,
  };
};

export default usePollForProductOwnership;
