import * as React from 'react';
import { Link as ReactRouterLink } from 'react-router';

import { useQuery } from '@apollo/client';
import _ from 'lodash';

import { useParams } from 'js/lib/useRouter';

import type { ButtonProps, LinkProps } from '@coursera/cds-core';
import { Button, InlineNotification, Link, Typography, Typography2 } from '@coursera/cds-core';
import { ArrowNextIcon } from '@coursera/cds-icons';
import { Box } from '@coursera/coursera-ui';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import Collection from 'bundles/enterprise-collections/components/Collection';
import { EnterpriseProductCardCollectionsPlaceholder } from 'bundles/enterprise-collections/components/EnterpriseProductCardCollection';
import useCourseEntitiesAsCurriculumCourses from 'bundles/page-config-common/hooks/useCourseEntitiesAsCurriculumCourses';
import type { CollectionListItemStruct, Product, ProductType } from 'bundles/program-common/types/programCommon';
import { Heading, Section } from 'bundles/program-home/components/AutoHeading';
import CatalogSection from 'bundles/program-home/components/single-program/CatalogSection';
import { COLLECTION_TYPE } from 'bundles/program-home/components/single-program/SingleProgramConstants';
import { toViewAll } from 'bundles/program-home/components/single-program/links';
import CurriculumCollectionsQuery from 'bundles/program-home/components/single-program/queries/CurriculumCollections.graphql';
import type {
  CurriculumCollectionsQuery as CurriculumCollectionsQueryData,
  CurriculumCollectionsQueryVariables,
} from 'bundles/program-home/components/single-program/queries/__generated__/CurriculumCollections';
import type { OnProductCardClick } from 'bundles/program-home/types/Products';

import _t from 'i18n!nls/program-home';

import 'css!./__styles__/CuratedCollections';

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps<ReactRouterLink>>(
  // TODO(ppaskaris): Ask how to type this.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  Button
);

const TrackedLink = withSingleTracked({ type: 'BUTTON' })<LinkProps<ReactRouterLink>>(
  // TODO(ppaskaris): Ask how to type this.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  Link
);

const DefaultLength = 2;

type PropsFromCaller = {
  programId: string;
  onProductCardClick: OnProductCardClick;
  placeholderLength?: number;
  length?: number;
  programRecommendationsAvailableCoursesCopy: boolean;
  programCollectionSectionDescription: boolean;
  enableCurriculumBuilder?: boolean;
  title?: string;
  disableDescription?: boolean;
  academicDisciplineName?: string;
  allowOrgForSpecializationConfiguration?: boolean;
  isAuthenticatedUser?: boolean;
};

type PropsFromGraphql = {
  collections?: Array<CollectionListItemStruct>;
  totalCollections?: number | null;
  loading: boolean;
  error: boolean;
};

type PropsForCollectionsView = Omit<
  PropsFromCaller,
  'programRecommendationsAvailableCoursesCopy' | 'programCollectionSectionDescription'
> &
  PropsFromGraphql;

export function CollectionsView({
  onProductCardClick,
  collections,
  loading,
  error,
  programId,
  length = DefaultLength,
  placeholderLength = length,
  disableDescription,
  allowOrgForSpecializationConfiguration,
  isAuthenticatedUser,
}: PropsForCollectionsView): JSX.Element | null {
  const handleProductCardClick =
    (collectionTrackingId: string | null) => (product: Product, productType: ProductType) => {
      onProductCardClick(product, productType, collectionTrackingId, programId);
    };

  if (error) {
    return (
      <InlineNotification severity="error">
        {_t('Sorry! Something went wrong. Please refresh the page.')}
      </InlineNotification>
    );
  } else if (loading) {
    return <CollectionsView.Placeholder length={placeholderLength} />;
  } else if (!collections?.length) {
    return null;
  }

  // Slice to length because the View All page changes the cache in Apollo. That is a problem for another day.
  const collectionElements = collections.slice(0, length).map((collection, idx) => {
    const allItemsAreClips = collection.items.every((item) => item.clipId);
    // hide this collection in below case because clips aren't available unless logged in for now.
    if (allItemsAreClips && !isAuthenticatedUser) return null;
    return (
      <Collection
        key={collection.id}
        id={collection.id}
        collectionOrder={idx + 1}
        collection={collection}
        onProductCardClick={handleProductCardClick(collection.collectionTrackingId)}
        productMetadata={collection.programProductMetadata?.elements}
        disableDescription={disableDescription}
        allowOrgForSpecializationConfiguration={allowOrgForSpecializationConfiguration}
      />
    );
  });

  return <>{collectionElements}</>;
}

type PropsForPlaceholder = {
  length: number;
};

CollectionsView.Placeholder = ({ length }: PropsForPlaceholder) => {
  return (
    <div>
      {_.times(length, (key) => (
        <EnterpriseProductCardCollectionsPlaceholder key={key} />
      ))}
    </div>
  );
};

type PropsForOrgHomeCuratedCollections = {
  programSlug: string;
  programName: string;
  academicDisciplineName?: string;
} & Omit<
  PropsFromCaller,
  'programRecommendationsAvailableCoursesCopy' | 'programCollectionSectionDescription' | 'disableDescription'
> &
  PropsFromGraphql;

export function OrgHomeCuratedCollections({
  onProductCardClick,
  collections,
  loading,
  error,
  programId,
  programSlug,
  length = DefaultLength,
  placeholderLength = length,
  academicDisciplineName,
  isAuthenticatedUser,
}: PropsForOrgHomeCuratedCollections) {
  const title = academicDisciplineName
    ? _t('Academic subjects recommended in #{academicDisciplineName}', { academicDisciplineName })
    : _t('Recommended by your organization');
  if (!error && !loading && !collections?.length) {
    return null;
  }

  return (
    <div className="rc-CuratedCollections">
      <Box className="header-box">
        <Heading defaultLevel={3} className="title">
          {title}
        </Heading>
        <div className="view-all-title-link">
          <TrackedButton
            {...Button.defaultProps}
            component={ReactRouterLink}
            variant="ghost"
            className="coursera-recommendations-link"
            icon={<ArrowNextIcon size="medium" />}
            iconPosition="after"
            size="medium"
            trackingData={{ programSlug }}
            trackingName="curated_collections_title_view_all_arrow_link"
            to={toViewAll(programSlug, COLLECTION_TYPE.CURATED)}
            aria-label={_t('View all: #{title}', { title })}
          >
            {_t('View all')}
          </TrackedButton>
        </div>
      </Box>
      <Section initialLevel={4}>
        <CollectionsView
          onProductCardClick={onProductCardClick}
          collections={collections}
          loading={loading}
          error={error}
          programId={programId}
          length={length}
          placeholderLength={placeholderLength}
          isAuthenticatedUser={isAuthenticatedUser}
          disableDescription
        />
      </Section>
    </div>
  );
}

export function CuratedCollections({
  academicDisciplineName,
  allowOrgForSpecializationConfiguration,
  enableCurriculumBuilder,
  length = DefaultLength,
  programId,
  programRecommendationsAvailableCoursesCopy,
  programCollectionSectionDescription,
  onProductCardClick,
  placeholderLength,
  title,
  disableDescription,
  isAuthenticatedUser,
}: PropsFromCaller): JSX.Element | null {
  const { programSlug } = useParams();
  const { data, loading, error } = useQuery<CurriculumCollectionsQueryData, CurriculumCollectionsQueryVariables>(
    CurriculumCollectionsQuery,
    {
      ssr: false,
      variables: {
        programId,
        start: '0',
        limit: length,
        enableCurriculumBuilder: Boolean(enableCurriculumBuilder),
        allowOrgForSpecializationConfiguration: Boolean(allowOrgForSpecializationConfiguration),
      },
      context: { clientName: 'gatewayGql' },
    }
  );
  const collections = data?.NaptimeQueries?.ProgramCurriculumCollectionsV1Resource?.curriculumTracksByProgram?.elements;
  const totalCollections =
    data?.NaptimeQueries?.ProgramCurriculumCollectionsV1Resource?.curriculumTracksByProgram?.paging.total;
  const allCourseIds =
    data?.NaptimeQueries?.ProgramCurriculumCollectionsV1Resource?.curriculumTracksByProgram?.elements?.reduce(
      (acc, collection) => {
        if (collection?.courseIds?.length) {
          acc.push(...(collection.courseIds as string[]));
        }

        return acc;
      },
      [] as string[]
    );
  const [curriculumCourseMap] = useCourseEntitiesAsCurriculumCourses(allCourseIds);

  if (!loading && !error && (!collections || !collections.length)) {
    return null;
  }

  const shouldRenderViewAll = totalCollections == null || totalCollections > length;
  const collectionsWithCourses = collections?.map((collection) => {
    return {
      ...collection,
      courses: {
        elements: curriculumCourseMap
          ? (collection?.courseIds?.map((id) => {
              if (curriculumCourseMap[id as string]) {
                return curriculumCourseMap[id as string];
              } else {
                return null;
              }
            }) as Array<Product | null> | null)
          : [],
      },
    };
  });

  const getTitle = () => {
    if (academicDisciplineName) {
      return _t('Academic subjects recommended in #{academicDisciplineName}', { academicDisciplineName });
    } else if (programRecommendationsAvailableCoursesCopy) {
      return _t('Available courses');
    } else {
      return _t('Recommended by your organization');
    }
  };

  const getFooterViewAllLink = () => {
    if (academicDisciplineName) {
      return _t('View all subjects in #{academicDisciplineName}', { academicDisciplineName });
    } else if (programRecommendationsAvailableCoursesCopy) {
      return _t('View all courses');
    } else {
      return _t('View all recommendations from your organization');
    }
  };

  const titleViewAllLink = _t('View all: #{title}', { title: getTitle() });

  return (
    <CatalogSection>
      <div className="rc-CuratedCollections">
        <Box className="header-box">
          <Heading defaultLevel={2} className="title">
            {getTitle()}
          </Heading>
          {shouldRenderViewAll && (
            <div className="view-all-title-link">
              <TrackedButton
                {...Button.defaultProps}
                component={ReactRouterLink}
                variant="ghost"
                className="coursera-recommendations-link"
                icon={<ArrowNextIcon size="medium" />}
                iconPosition="after"
                size="medium"
                trackingData={{ programSlug }}
                trackingName="curated_collections_title_view_all_arrow_link"
                to={toViewAll(programSlug, COLLECTION_TYPE.CURATED)}
                aria-label={titleViewAllLink}
              >
                {_t('View all')}
              </TrackedButton>
            </div>
          )}
        </Box>
        <Section initialLevel={3}>
          <Typography2 component="p" className="description">
            {programCollectionSectionDescription &&
              !academicDisciplineName &&
              _t(
                'Enroll in any course hand-picked by your organization. These courses cover topics and skills your organization is focused on improving.'
              )}
          </Typography2>
          <CollectionsView
            programId={programId}
            onProductCardClick={onProductCardClick}
            placeholderLength={placeholderLength}
            length={length}
            enableCurriculumBuilder={enableCurriculumBuilder}
            title={title}
            disableDescription={disableDescription}
            academicDisciplineName={academicDisciplineName}
            allowOrgForSpecializationConfiguration={allowOrgForSpecializationConfiguration}
            isAuthenticatedUser={isAuthenticatedUser}
            collections={collectionsWithCourses as Array<CollectionListItemStruct>}
            loading={loading}
            error={Boolean(error)}
          />
          {shouldRenderViewAll && (
            <Typography component="div" variant="h2semibold">
              {_t('Want to see more? ')}
              <TrackedLink
                {...ReactRouterLink.defaultProps}
                component={ReactRouterLink}
                className="collection-footer-link"
                variant="quiet"
                trackingData={{ programSlug }}
                trackingName="curated_collections_footer_view_all_arrow_link"
                to={toViewAll(programSlug, COLLECTION_TYPE.CURATED)}
              >
                {getFooterViewAllLink()} <ArrowNextIcon size="medium" style={{ verticalAlign: 'middle' }} />
              </TrackedLink>
            </Typography>
          )}
        </Section>
      </div>
    </CatalogSection>
  );
}

export default CuratedCollections;
