import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { color } from '@coursera/coursera-ui';
import { SvgThumbsUpFilled } from '@coursera/coursera-ui/svg';

import ExpertiseTooltip from 'bundles/enterprise-legacy-xdp/components/ExpertiseTooltip';
import TrackedDiv from 'bundles/page/components/TrackedDiv';

import _t from 'i18n!nls/program-common';

import 'css!bundles/enterprise-legacy-xdp/components/cdp/syllabus/__styles__/SyllabusContentRating';

export type Props = {
  averageRating?: number;
  ratingsCount?: number;
};

export default function SyllabusContentRating({ averageRating, ratingsCount }: Props) {
  if (!averageRating || !ratingsCount) {
    return null;
  }
  return (
    <div className="rc-SyllabusContentRating">
      <TrackedDiv withVisibilityTracking trackClicks trackMouseEnters trackingName="syllabus_syllabus_rating_tooltip">
        <ExpertiseTooltip
          id="syllabus-rating-tooltip"
          text={_t('Learners can give thumbs up or down to indicate the quality of this content.')}
        >
          <span className="expertise-rating p-l-1">
            <span>{_t('Content Rating')}</span>
            <span className="expertise-rating__img">
              <SvgThumbsUpFilled size={15} color={color.warning} />
            </span>
            <span className="expertise-rating__average-rating">{Math.round(averageRating)}%</span>
            <FormattedMessage
              message={_t('({ratingsCount} {ratingsCount, plural, =1 { rating} other {ratings}})')}
              ratingsCount={ratingsCount.toLocaleString()}
            />
          </span>
        </ExpertiseTooltip>
      </TrackedDiv>
    </div>
  );
}
