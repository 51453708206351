import { EnterpriseContractTagType } from 'bundles/admin-dashboard/types';

export const enterpriseContractType = {
  STANDARD: 'STANDARD',
  SELF_SERVE: 'SELF_SERVE',
} as const;

export const enterpriseContractUtilizationType = {
  NO_LIMIT: 'NO_LIMIT',
  ENROLLMENT_CAP: 'ENROLLMENT_CAP',
  USER_LICENSE: 'USER_LICENSE',
  USER_LICENSE_WITH_ENROLLMENT_CAP: 'USER_LICENSE_WITH_ENROLLMENT_CAP',
} as const;

export const enterpriseContractActivationType = {
  IMMEDIATE: 'IMMEDIATE',
  SUPERUSER: 'SUPERUSER',
  AUTO: 'AUTO',
} as const;

export const freemiumContractTags: Array<EnterpriseContractTagType> = [
  EnterpriseContractTagType.C4C_CORONA_TRIAL,
  EnterpriseContractTagType.C4WR_CORONA_TRIAL,
  EnterpriseContractTagType.C4C_FREEMIUM,
  EnterpriseContractTagType.C4C_UPSWELL,
];

export const c4cbContractTags: Array<EnterpriseContractTagType> = [
  EnterpriseContractTagType.C4C_CORONA_TRIAL,
  EnterpriseContractTagType.C4C_FREEMIUM,
];

export const gwgContractTags: Array<EnterpriseContractTagType> = [
  EnterpriseContractTagType.GWG_CC,
  EnterpriseContractTagType.GWG_ENTERPRISE,
  EnterpriseContractTagType.GWG_SMB,
];

export default {
  enterpriseContractType,
  enterpriseContractUtilizationType,
  enterpriseContractActivationType,
  freemiumContractTags,
  c4cbContractTags,
};
