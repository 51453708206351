import * as React from 'react';

import { ExternalLinkIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import { getAssetDisbaledText, openInAssetLibrary } from 'bundles/cml/editor/components/elements/asset/assetUtils';
import { useAssetContext } from 'bundles/cml/editor/context/assetContext';
import { useCourseContext, useRouter } from 'bundles/cml/shared/hooks/context';
import type { Asset } from 'bundles/cml/shared/types/assetTypes';
import { FloatingTooltip } from 'bundles/common/components/Tooltip';

import _t from 'i18n!nls/cml';

type Props = {
  asset: Asset;
  title?: string;
};

const OpenInLibraryButton: React.FC<Props> = ({ asset, title = _t('Open in library') }) => {
  const router = useRouter();
  const { isLearnerUpload } = useAssetContext();
  const { courseContext } = useCourseContext();

  if (isLearnerUpload) {
    return null;
  }

  const disabledText = getAssetDisbaledText(asset, courseContext?.courseId);
  const handleOpenIn = () => openInAssetLibrary(asset.id, router);

  return (
    <FloatingTooltip show={!!disabledText} message={disabledText} offset={3} placement="bottom">
      <div>
        <Button
          role="menuitem"
          title={title}
          onClick={handleOpenIn}
          className="open-asset-menu-button"
          disabled={!!disabledText}
          tooltipProps={{ placement: 'bottom' }}
          type={TOOLBAR_BUTTON_TYPES.menu}
        >
          <ExternalLinkIcon size="small" />
        </Button>
      </div>
    </FloatingTooltip>
  );
};

export default OpenInLibraryButton;
