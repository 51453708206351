import * as React from 'react';

import { Specialization_ProductVariant as SpecializationProductVariant } from '__generated__/graphql-types';

import { Typography2 } from '@coursera/cds-core';
import { StyleSheet, breakPoint, color, css } from '@coursera/coursera-ui';

import type { PartnerWithName } from 'bundles/course-cards/components/course-card/enterprise/CourseAndPartnerName';
import CourseAndPartnerName, {
  CourseAndPartnerNameWithModal,
} from 'bundles/course-cards/components/course-card/enterprise/CourseAndPartnerName';
import { CourseS12nPhotoWithModal as CourseS12nPhoto } from 'bundles/course-cards/components/course-card/enterprise/CourseS12nPhoto';
import TogglableContent from 'bundles/course-cards/components/course-card/enterprise/TogglableContent';
import { DESCRIPTION_PAGE_PATHS } from 'bundles/enterprise-legacy-xdp/hocs/withMiniModal';

import _t from 'i18n!nls/program-home';

const styles = StyleSheet.create({
  MultiCourseProductCardHeader: {
    backgroundColor: color.white,
  },
  // TODO(jkemp): Aphrodite styles will be removed from program home by the end of Q2, didn't want to include the migration for this styling
  // in this PR
  CourseBadgePhoto: {
    width: 100,
    height: 100,
  },
  hiddenMdDown: {
    [`@media (max-width: ${breakPoint.md}px)`]: {
      display: 'none',
    },
  },
});

const MultiCourseProductCardHeader: React.SFC<{
  name: string;
  id: string;
  logo?: string;
  slug?: string;
  partners?: PartnerWithName[];
  description?: string;
  isEnrolled: boolean;
  children: React.ReactNode;
  productVariant?: SpecializationProductVariant;
}> = ({ name, slug, id, logo, partners = [], description, isEnrolled, children, productVariant }) => {
  const miniModalProps = {
    id,
    isCourse: false,
    tag: 'div' as const,
    unifiedDescriptionPageProps: {
      slug,
      productPagePath:
        productVariant === SpecializationProductVariant.NormalS12N
          ? DESCRIPTION_PAGE_PATHS.specialization
          : DESCRIPTION_PAGE_PATHS.professionalCertificate,
    },
  };
  const viewLessMoreContextLabel = _t('#{name}s description', { name });
  return (
    <div {...css('rc-MultiCourseProductCardHeader', styles.MultiCourseProductCardHeader)}>
      <div className={`horizontal-box wrap ${isEnrolled && 'p-a-1'}`}>
        {slug ? (
          <CourseS12nPhoto
            isS12n={true}
            imgixClassName="s12n-logo"
            imageSrc={logo}
            slug={slug}
            id={id}
            title={name}
            miniModalProps={{
              ...miniModalProps,
              style: {
                height: 100,
                overflow: 'hidden',
              },
            }}
          />
        ) : (
          <img
            // @ts-expect-error FIXME result of fixing typing on coursera-ui aphrodite exports in PR#45351
            {...css('m-r-1', styles.CourseS12nPhoto, styles.hiddenMdDown)}
            src={logo}
            alt=""
            aria-hidden="true"
            width="100"
            height="100"
          />
        )}
        <div className="c-description flex-1 pos-relative">
          {slug ? (
            <CourseAndPartnerNameWithModal
              courseId={id}
              courseName={name}
              partners={partners}
              miniModalProps={miniModalProps}
              isEnrolled={isEnrolled}
            />
          ) : (
            <CourseAndPartnerName courseId={id} courseName={name} partners={partners} isEnrolled={isEnrolled} />
          )}

          {description && (
            <TogglableContent moreLessButtonContextLabel={viewLessMoreContextLabel}>
              <div>
                <Typography2 component="p" variant="bodySecondary" aria-label={description}>
                  {description}
                </Typography2>
              </div>
            </TogglableContent>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default MultiCourseProductCardHeader;
