import Naptime from 'bundles/naptimejs';
import CartsV2 from 'bundles/naptimejs/resources/carts.v2';

export type WithCartProps = {
  cartData?: CartsV2;
};

export default Naptime.createContainer<WithCartProps, { cartId: number }>(({ cartId }) => {
  return {
    cartData: CartsV2.get(cartId),
  };
});
