/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';
import { Fragment, useCallback, useState } from 'react';

import { VisuallyHidden } from '@coursera/cds-core';

import type { UppyEventHandlers } from 'bundles/asset-admin/components/UppyAssetUploader';

import _t from 'i18n!nls/asset-admin';

type SupportedEvents = Partial<
  Pick<UppyEventHandlers, 'onUploadsComplete' | 'onUploadStart' | 'onFileUploadComplete' | 'onUploadError'>
>;

export type Props = {
  enabled?: boolean;
  children: (props: SupportedEvents) => React.ReactNode;
} & SupportedEvents;

export const UploadStatusReader = ({
  onUploadsComplete,
  onUploadStart,
  onFileUploadComplete,
  onUploadError,
  enabled = false,
  children,
}: Props) => {
  const [statusMessage, setStatusMessage] = useState<string>();

  const onUploadStartWithAnnouncement = useCallback(
    (files) => {
      onUploadStart?.(files);

      setStatusMessage(_t('Started upload'));
    },
    [onUploadStart]
  );

  const onFileUploadCompleteWithAnnouncement = useCallback(
    (assembly) => {
      setStatusMessage(_t('Saving assets to Coursera'));

      onFileUploadComplete?.(assembly);
    },
    [onFileUploadComplete]
  );

  const onUploadsCompleteWithAnnouncement = useCallback(
    (uploadCompletion) => {
      setStatusMessage(_t('upload complete'));

      onUploadsComplete?.(uploadCompletion);
    },
    [onUploadsComplete]
  );

  const onUploadErrorWithAnnouncement = useCallback(
    (assembly, error) => {
      setStatusMessage(_t('failed to upload asset'));
      onUploadError?.(assembly, error);
    },
    [onUploadError]
  );

  return (
    <Fragment>
      {enabled && statusMessage && (
        <div>
          <VisuallyHidden role="status">{_t('Upload status: #{statusMessage}', { statusMessage })}</VisuallyHidden>
        </div>
      )}
      {children({
        onUploadStart: onUploadStartWithAnnouncement,
        onFileUploadComplete: onFileUploadCompleteWithAnnouncement,
        onUploadsComplete: onUploadsCompleteWithAnnouncement,
        onUploadError: onUploadErrorWithAnnouncement,
      })}
    </Fragment>
  );
};
