import { compose, withProps } from 'recompose';

import Naptime from 'bundles/naptimejs';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import ProgramCurriculumProductsV1 from 'bundles/naptimejs/resources/programCurriculumProducts.v1';

type InputProps = {
  programId: string;
};

type PropsFromNaptime = {
  futureProducts: Array<ProgramCurriculumProductsV1>;
};

export type OutputProps = {
  futureProductIds: string[];
};

/**
 * Populate a futureProductIds prop based on a programId to help
 * organize products with future starting dates.
 *
 * - part of C4C private sessions project
 */
export const withProgramsStartingLater = compose<OutputProps, InputProps>(
  Naptime.createContainer(({ programId }: InputProps) => ({
    futureProducts: ProgramCurriculumProductsV1.finder('enrollmentsStartingLater', {
      params: { programId },
    }),
  })),
  withProps(({ futureProducts }: PropsFromNaptime) => {
    const futureProductIds = futureProducts?.map((product) => product.id) ?? [];
    return { futureProductIds };
  })
);
