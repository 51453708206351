import { useContext } from 'react';

import PageDataContext from 'bundles/enterprise-legacy-xdp/components/miniPDP/data/PageDataContext';

const usePageData = () => {
  const context = useContext(PageDataContext);
  if (!context) {
    throw new Error('Missing PageDataContext. Make sure you wrap your page with a data provider.');
  }
  return context;
};

export default usePageData;
