import redirect from 'js/lib/coursera.redirect';

import { CheatingIncidentRulings } from 'bundles/integrity-portal/lib/constants';
import type { CheatingIncident } from 'bundles/integrity-portal/lib/types';

export const getCheatingIncidentRoute = (incidentId: string) => {
  return `/integrity-portal/incident/${incidentId}`;
};

export const isCheatingIncidentImpactingLearner = (incident?: CheatingIncident) => {
  return incident && incident.ruling === CheatingIncidentRulings.CheatingConfirmed;
};

export const getCheatingIncidentImpactingLearnerCount = (incidents: CheatingIncident[]) => {
  return incidents.reduce((count, incident) => (isCheatingIncidentImpactingLearner(incident) ? count + 1 : count), 0);
};

export const getMilliSecondsFromGraphqlTimestamp = (timestamp: string) => {
  return new Date(timestamp).getTime();
};

export const onGoToAssignmentClick = (courseSlug: string | undefined, itemId: string | undefined) => {
  return redirect.setLocation(`/learn/${courseSlug}/item/${itemId}`);
};
