import { MAX_ONBOARDING_SKILLS_LENGTH } from 'bundles/program-personalized-tab/constants';
import type { Skill } from 'bundles/program-personalized-tab/types/sharedTypes';

export const getClipsRequestSkillsParams = (skills: Skill[] | undefined) =>
  skills?.length
    ? skills.slice(0, MAX_ONBOARDING_SKILLS_LENGTH).map((skill, i) => ({
        name: `skill-based-clips-${i + 1}.skillName`,
        value: skill.name,
      }))
    : [];
