import type { InjectedRouter } from 'react-router';

import redirect from 'js/lib/coursera.redirect';

import { RedirectError } from 'bundles/ssr/lib/errors/directives';

export const handleRedirect = (redirectUrl: string, router: InjectedRouter) => {
  // Check to see if it's ssr
  if (typeof window === 'undefined') {
    throw new RedirectError(302, redirectUrl);
  } else if (redirect) {
    redirect.setLocation(redirectUrl);
  } else {
    router.replace(redirectUrl);
  }
};

export const formatRoleIdToString = (roleId: string) =>
  roleId
    .split('-')
    .map((roleWord) => roleWord.charAt(0).toUpperCase() + roleWord.slice(1))
    .join(' ');
