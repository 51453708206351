/* @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useMemo } from 'react';
import type { FunctionComponent } from 'react';

import { uniqueId } from 'lodash';

import Imgix from 'js/components/Imgix';
import { FormattedMessage } from 'js/lib/coursera.react-intl';
import requestCountry from 'js/lib/requestCountry';

import { Grid, Link, Typography2, breakpoints, useTheme } from '@coursera/cds-core';

import paymentsbackendExperiments from 'bundles/epic/clients/payments-backend';
import ReactPriceDisplay from 'bundles/payments-common/components/ReactPriceDisplay';
import type { ProductPrices } from 'bundles/payments-common/hooks/useGetProductPrices';
import ProductType from 'bundles/payments/common/ProductType';
import NonRecurringChoiceScreenReaderDescription from 'bundles/s12n-enroll/components/non-recurring/NonRecurringChoiceScreenReaderDescription';
import { LEARN_MORE_LINK, NON_RECURRING_CHOICES } from 'bundles/s12n-enroll/components/non-recurring/utils/constants';
import { getNumberOfHoursToDisplay } from 'bundles/s12n-enroll/components/non-recurring/utils/nonRecurringMessageUtils';

import courseraPlusImage from 'images/s12n-enroll/courseraPlus.png';
import courseraPlusMobileImage from 'images/s12n-enroll/courseraplusmobile.png';
import courseraPlusPeople from 'images/s12n-enroll/cpluspeople.png';

import _t from 'i18n!nls/s12n-enroll';

export type ChoiceOption = {
  choice: string;
  productPrice?: ProductPrices;
  percentageOff: number;
  numOfMonths: number | null;
  numOfHours: number;
  productType?: string;
};

export type Props = {
  choices: ChoiceOption[];
  choiceType: string;
  onChange(choice: string): void;
  isFullDiscount?: boolean;
};

const useStyles = () => {
  const theme = useTheme();

  return {
    choiceTiles: css`
      display: flex;
      margin: -8px;

      input[type='radio']:checked + label {
        border: var(--cds-color-blue-700) solid 2px;
      }

      input[type='radio']:focus-visible + label {
        outline: 2px solid var(--cds-color-blue-700);
        outline-offset: 4px;
      }
      ${breakpoints.up('md')} {
        margin: -16px;
      }
    `,
    choiceTile: css`
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: var(--cds-spacing-100);
      padding: var(--cds-spacing-200);
      border: 1px solid;
      border-color: var(--cds-color-grey-600);
      border-radius: 4px;
      font-size: 16px;
      position: relative;
      font-weight: normal;
      text-align: center;
      cursor: pointer;
      ${breakpoints.down('sm')} {
        font-size: 14px;
      }
      ${breakpoints.up('md')} {
        margin: var(--cds-spacing-200);
      }
    `,
    choiceTileMobile: css`
      ${breakpoints.down('xs')} {
        padding: 35px;
      }
    `,
    checkedChoiceTile: css`
      border-color: var(--cds-color-blue-700);
      color: var(--cds-color-blue-700);
      background-color: var(--cds-color-blue-50);
    `,
    choiceTileMonth: css`
      display: block;
      margin: 0;
      margin-bottom: var(--cds-spacing-100);
      font-size: 24px;
      font-weight: 800;
      ${breakpoints.down('sm')} {
        margin-bottom: var(--cds-spacing-50);
      }
      ${breakpoints.up('md')} {
        margin-bottom: var(--cds-spacing-100);
      }
    `,
    choiceSummary: css`
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: var(--cds-spacing-300);
      padding: var(--cds-spacing-100);
      font-size: 16px;
      border-radius: 4px;
      ${breakpoints.up('sm')} {
        background-color: var(--cds-color-grey-25);
        height: 100px;
        padding: var(--cds-spacing-200) var(--cds-spacing-400);
      }
    `,
    choiceSummaryPrice: css`
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    `,
    choiceSummaryPriceAmount: css`
      font-size: 16px;
      font-weight: 800;
    `,
    choiceSummaryHours: css`
      font-size: 24px;
      font-weight: 800;
    `,
    badge: css`
      width: auto;
      display: inline-flex;
      border-radius: 4px;
      text-transform: uppercase;
      padding: 0 4px;
      margin-top: var(--cds-spacing-50);
      margin-bottom: 0;
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      color: var(--cds-color-grey-975);
    `,
    discountBadge: css`
      background: #ffe057;
    `,
    price: css`
      font-size: '16px';
      line-height: '30px';
      /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
      font-family: OpenSans;
      font-weight: 700;
      margin-bottom: 0;
    `,
    priceExtras: css`
      font-weight: 400;
      margin-bottom: '6px';
    `,
    recommendedPaceBadge: css`
      background: #adf9da;
    `,
    freeUpgrade: css`
      position: absolute;
      bottom: -15%;
      display: flex;
      color: white;
      left: 20%;
      text-transform: uppercase;
      border-radius: 4px;
      font-weight: 700;
      background: #0056d2;
      padding: 1px 4px;
      text-align: center;
      justify-content: center;

      ${breakpoints.down('xs')} {
        text-transform: capitalize;
        font-size: 11px;
        left: 0;
        bottom: 0;
        width: 100%;
        border-radius: 0;
      }
    `,
    courseraPlusUpgradeBadge: css`
      display: flex;
      align-items: center;
      padding: 1px 4px;
      background: var(--cds-color-yellow-200);
      border-radius: 4px;
      max-width: fit-content;
      width: 100%;
      right: 50%;
      font-size: 14px;
      margin-right: 14px;

      ${breakpoints.down('xs')} {
        position: absolute;
        top: -5%;
        left: 20%;
        margin-right: 0;
      }
    `,
    courseraPlusSummary: css`
      background-color: var(--cds-color-grey-25);
      padding: 0 var(--cds-spacing-400) var(--cds-spacing-400);
      position: relative;

      ${breakpoints.down('xs')} {
        margin-top: 25px;
        padding: var(--cds-spacing-400);
      }
    `,
  };
};

const ChoiceTile: FunctionComponent<{
  isChecked: boolean;
  choiceOption: ChoiceOption;
  onChange: Props['onChange'];
  isRecommended: boolean;
}> = ({ isChecked, choiceOption, onChange, isRecommended }) => {
  const { choice, numOfMonths, numOfHours, productType, productPrice, percentageOff } = choiceOption;
  const styles = useStyles();

  const descriptionId = uniqueId('non-recurring-choice-tile-description-');
  const id = uniqueId('choice-tile-');
  const isCourseraPlusPrepaidEnabled = paymentsbackendExperiments.preview('courseraPlusPrepaidEnabled');

  return (
    <Grid xs={4} data-e2e={`choice-tile-${choice}`}>
      <>
        <input
          type="radio"
          id={id}
          value={choice}
          checked={isChecked}
          onChange={() => {
            onChange(choice);
          }}
          aria-describedby={descriptionId}
          style={{ opacity: 0, width: '1px', height: '1px', margin: 0, padding: 0 }}
        />

        <label
          htmlFor={id}
          css={[
            styles.choiceTile,
            isChecked && styles.checkedChoiceTile,
            isCourseraPlusPrepaidEnabled && styles.choiceTileMobile,
          ]}
        >
          <NonRecurringChoiceScreenReaderDescription
            numOfMonths={numOfMonths}
            numOfHours={numOfHours}
            productPrice={productPrice}
            percentageOff={percentageOff}
            isRecommended={isRecommended}
          />
          <span aria-hidden="true">
            <span css={styles.choiceTileMonth}>{numOfMonths}</span>
            <FormattedMessage message={_t('{count, plural, =1 {month} other {months}}')} count={numOfMonths} />
            {productType === ProductType.COURSERA_PLUS &&
              paymentsbackendExperiments.preview('courseraPlusPrepaidEnabled') && (
                <Typography2 component="p" css={styles.freeUpgrade}>
                  {_t('free upgrade')}
                </Typography2>
              )}
          </span>
        </label>
      </>
    </Grid>
  );
};

const NonRecurringChoiceTiles: FunctionComponent<Props> = ({ choices, choiceType, onChange, isFullDiscount }) => {
  const styles = useStyles();
  const theme = useTheme();

  const selectedChoice = useMemo(() => choices.find(({ choice }) => choice === choiceType), [choices, choiceType]);
  const isCourseraPlusPrepaidEnabled = paymentsbackendExperiments.preview('courseraPlusPrepaidEnabled');

  const showRecommendedBadge =
    !isCourseraPlusPrepaidEnabled && selectedChoice?.choice === NON_RECURRING_CHOICES.PASS_TWO;

  const showCourseraPlusSummary =
    isCourseraPlusPrepaidEnabled && selectedChoice?.productType === ProductType.COURSERA_PLUS;

  const numOfHoursToDisplay = selectedChoice && getNumberOfHoursToDisplay(selectedChoice.numOfHours);

  const choiceTiles = choices?.map((choiceOption) => (
    <ChoiceTile
      key={choiceOption.choice}
      isChecked={choiceOption.choice === selectedChoice?.choice}
      choiceOption={choiceOption}
      onChange={onChange}
      isRecommended={showRecommendedBadge}
    />
  ));

  const renderChoicePrice = () => {
    if (selectedChoice?.choice === NON_RECURRING_CHOICES.PASS_ONE && isFullDiscount) {
      return (
        <strong data-test-id="free-pass" css={styles.choiceSummaryPriceAmount}>
          {_t('Free')}
        </strong>
      );
    } else if (selectedChoice?.numOfMonths && selectedChoice?.productPrice?.amount && requestCountry.get() === 'IN') {
      return (
        <>
          <div>
            <span css={styles.price}>
              <ReactPriceDisplay
                value={Math.round(selectedChoice?.productPrice?.amount / selectedChoice.numOfMonths)}
                currency={selectedChoice?.productPrice?.currencyCode}
              />
            </span>
            <span css={styles.priceExtras}>/{_t('month')}</span>
          </div>

          <div css={styles.priceExtras}>
            <span>{_t('Total')}&nbsp;</span>
            <ReactPriceDisplay
              value={selectedChoice?.productPrice?.amount}
              currency={selectedChoice?.productPrice?.currencyCode}
            />
          </div>
        </>
      );
    } else if (selectedChoice?.productPrice?.amount) {
      return (
        <strong css={styles.choiceSummaryPriceAmount}>
          <ReactPriceDisplay
            value={selectedChoice?.productPrice?.amount}
            currency={selectedChoice?.productPrice?.currencyCode}
          />
        </strong>
      );
    }

    return null;
  };

  return (
    <div className="rc-NonRecurringChoiceTiles">
      <div css={styles.choiceTiles}>{choiceTiles}</div>
      {selectedChoice?.productPrice && (
        <div css={styles.choiceSummary} aria-hidden="true">
          <div
            css={css({
              display: 'flex',
              flexDirection: 'column',
            })}
          >
            <Typography2
              component="h3"
              variant="subtitleMedium"
              color="supportText"
              style={{ textTransform: 'uppercase', fontSize: 12 }}
            >
              {_t('Estimated study time')}
            </Typography2>
            <div>
              <span css={styles.choiceSummaryHours}>{numOfHoursToDisplay}&nbsp;</span>

              <FormattedMessage
                message={_t('{hours, plural, =1 {hour/week} other {hours/week}}')}
                hours={numOfHoursToDisplay}
              />
            </div>

            {showRecommendedBadge && (
              <div css={[styles.badge, styles.recommendedPaceBadge]}>{_t('Recommended Pace')}</div>
            )}
          </div>

          <div css={styles.choiceSummaryPrice}>
            {renderChoicePrice()}

            {!isCourseraPlusPrepaidEnabled && selectedChoice.percentageOff > 0 && (
              <div css={[styles.badge, styles.discountBadge]}>
                {_t('#{percentageOff}% off', { percentageOff: selectedChoice.percentageOff })}
              </div>
            )}
          </div>
        </div>
      )}
      {showCourseraPlusSummary && (
        <Grid container css={styles.courseraPlusSummary}>
          <Grid item lg={11} xs={12}>
            <div
              css={css({
                display: 'flex',
              })}
            >
              <div css={styles.courseraPlusUpgradeBadge}>
                <Typography2 component="h3" color="inherit" variant="subtitleMedium">
                  {_t('COURSERA PLUS INCLUDED')}
                </Typography2>
              </div>

              <div>
                <Imgix
                  src={courseraPlusImage}
                  css={css({
                    [breakpoints.down('xs')]: {
                      display: 'none',
                    },
                  })}
                  maxHeight="12"
                  alt={_t('Coursera Plus logo')}
                />
                <Imgix
                  src={courseraPlusMobileImage}
                  css={css({
                    display: 'none',
                    [breakpoints.down('xs')]: {
                      display: 'flex',
                    },
                  })}
                  maxHeight="12"
                  alt={_t('Coursera Plus logo')}
                />
              </div>
            </div>

            <Typography2
              component="p"
              css={css({
                margin: '17px 0',
              })}
            >
              {_t(
                '#{months} months of unlimited access to 7,000+ world-class courses, hands-on projects, and job-ready certificates',
                { months: selectedChoice.numOfMonths }
              )}
            </Typography2>
            <div>
              <Imgix src={courseraPlusPeople} maxHeight="30" alt="" />
            </div>
            <Typography2
              component="p"
              css={css({
                margin: '12px 0',
              })}
            >
              {_t(' Join thousands of learners who are achieving their goals with Coursera Plus')}
            </Typography2>
            <Link
              href={LEARN_MORE_LINK}
              aria-label={_t('Learn more about Coursera Plus')}
              variant="quiet"
              typographyVariant="subtitleMedium"
              target="_blank"
              rel="noopener noreferrer"
            >
              {_t('LEARN MORE')}
            </Link>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default NonRecurringChoiceTiles;
