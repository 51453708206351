/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { VideoIcon } from '@coursera/cds-icons';

type Props = { imageUrl?: string | null };

const imgWrapper = css`
  position: relative;
`;

const iconWrapper = css`
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgb(0 0 0 / 40%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const icon = css`
  width: 30%;
  height: 30%;
`;

const ClipsCardPreviewImage = ({ imageUrl }: Props) => {
  return (
    <div css={imgWrapper}>
      {/* TODO: Figure out placeholder image in case imageUrl is not present */}
      {imageUrl ? <img aria-hidden="true" alt="" src={imageUrl} /> : null}
      <div css={iconWrapper}>
        <VideoIcon css={icon} color="invert" />
      </div>
    </div>
  );
};

export default ClipsCardPreviewImage;
