import * as React from 'react';

import * as Sentry from '@sentry/react';
import { reduce } from 'lodash';
import PropTypes from 'prop-types';

import { InlineNotification } from '@coursera/cds-core';

import { getPlayerOptions, getVideoJsNode } from 'bundles/author-lecture/utils/videoUtils';
import ErrorBoundaryWithTagging from 'bundles/common/components/ErrorBoundaryWithTagging';
import Naptime from 'bundles/naptimejs';

/* @ts-expect-error ts-migrate(7016) FIXME: Untyped import */
import AssetsV1 from 'bundles/naptimejs/resources/assets.v1';
import VideoPlayer from 'bundles/phoenix/components/VideoPlayer';
import { handleVideoJsError } from 'bundles/videojs/utils';

import _t from 'i18n!nls/phoenix';

const loadVideoJSPlayer = () => import('pages/open-course/video/util/video');

const CONFIG = {
  videoRes: '360p',
  videoType: 'video/mp4',
};

/**
 * A simple video player that can either play src or videos from asset service
 * One of src or assetId has to be present
 * If both are present, prioritize assetId
 * If the src is provided, the format has to be 'video/mp4'
 * For style adjustment, check bundles/program-home/components/VideoModal
 */
export class SimpleVideoPlayer extends React.Component {
  static propTypes = {
    assetId: PropTypes.string,
    src: PropTypes.string,
    assets: PropTypes.shape({
      videoSourceUrls: PropTypes.object,
    }),
    width: PropTypes.number,
  };

  static defaultProps = {
    width: 400,
  };

  state = {};

  componentDidMount() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'src' does not exist on type 'Readonly<{}... Remove this comment to see the full error message
    const { src, assets } = this.props;
    if (assets && assets.videoSourceUrls) {
      this.setAssetVideoJs();
      return;
    }
    let url = src;
    // When the asset is being processed, we won't have the videoSourceUrls, but only url
    // And prioritize the asset url over src
    if (assets && assets.url) {
      url = assets.url.url || src;
    }

    if (url) {
      const sources = SimpleVideoPlayer.convertVideoSrcToSources(url);
      this.setVideoJs(sources);
    }
  }

  setAssetVideoJs = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'assets' does not exist on type 'Readonly... Remove this comment to see the full error message
    const sources = SimpleVideoPlayer.convertAssetsToSources(this.props.assets);
    this.setVideoJs(sources);
  };

  setVideoJs = (sources: $TSFixMe) => {
    loadVideoJSPlayer().then((VideoJSPlayerModule) => {
      const VideoJSPlayer = VideoJSPlayerModule.default;
      const options = getPlayerOptions(sources, true);
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      const videoNode = getVideoJsNode(sources);
      // @ts-expect-error ts-migrate(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
      const videoJs = new VideoJSPlayer(videoNode, options);

      videoJs.on('error', () => {
        handleVideoJsError(videoJs, 'SimpleVideoPlayer');
      });

      this.setState({ videoJs }); // Use state to avoid rendering videoplayer in SSR
    });
  };

  static convertVideoSrcToSources = (src: $TSFixMe) => {
    return { [CONFIG.videoRes]: [{ src, type: CONFIG.videoType }] };
  };

  static convertAssetsToSources = (assets: $TSFixMe) => {
    const { videoSourceUrls } = assets;
    return reduce(
      videoSourceUrls,
      (total, item, key) => {
        const subVideoSourceUrls = videoSourceUrls[key];
        const subVideoSrcArray = Object.keys(subVideoSourceUrls).map((item1) => {
          return {
            src: subVideoSourceUrls[item1].url,
            type: item1,
          };
        });
        return {
          ...total,
          [key]: subVideoSrcArray,
        };
      },
      {}
    );
  };

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'width' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { width } = this.props;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'videoJs' does not exist on type '{}'.
    const { videoJs } = this.state;
    if (!videoJs) {
      return null;
    }

    return (
      <div className="rc-SimpleVideoPlayer">
        <VideoPlayer width={width} height="auto" videoJs={videoJs} />
      </div>
    );
  }
}

const ErrorBoundSimpleVideoPlayer = (props: $TSFixMe) => {
  return (
    <ErrorBoundaryWithTagging
      componentName="SimpleVideoPlayer"
      errorComponent={
        <InlineNotification severity="error" title={_t('There has been an error')} role="alert">
          {_t(
            'An error was encountered while playing this video, and our development team has been alerted. Please try again later.'
          )}
        </InlineNotification>
      }
    >
      <SimpleVideoPlayer {...props} />
    </ErrorBoundaryWithTagging>
  );
};

// @ts-expect-error ts-migrate(2339) FIXME: Property 'assetId' does not exist on type '{}'.
export default Naptime.createContainer(ErrorBoundSimpleVideoPlayer, ({ assetId }) => ({
  assets:
    assetId &&
    AssetsV1.get(assetId, {
      fields: ['videoSourceUrls'],
    }),
}));
