export const PRE_ENROLLMENT_FLOW_VARIANTS = {
  creditCard: 'PAYMENT_WALLET_CAPTURE',
  automaticallyEnroll: 'AUTOMATICALLY_ENROLL',
  isEnrolled: 'PRE_ENROLLED',
};

export const MIX_AND_MATCH_ORDER = [
  'facebook-social-media-marketing',
  'meta-front-end-developer',
  'meta-back-end-developer',
  'meta-database-engineer',
  'meta-ios-developer',
  'facebook-marketing-analytics',
  'meta-android-developer',
  'meta-react-native',
  'ibm-data-science',
  'ibm-data-analyst',
  'ibm-cybersecurity-analyst',
  'ibm-full-stack-cloud-developer',
  'ibm-data-engineer',
  'devops-and-software-engineering',
  'introduction-data-science',
  'it-fundamentals-cybersecurity',
  'data-science-fundamentals-python-sql',
  'ai-engineer',
  'ibm-data-analyst-r-excel',
  'data-analysis-visualization-foundations',
  'ibm-machine-learning',
  'ibm-technical-support',
  'applied-artifical-intelligence-ibm-watson-ai',
  'applied-data-science',
  'devops-cloud-and-agile-foundations',
  'data-engineering-foundations',
  'it-cloud-fundamentals',
  'software-engineering-fundamentals',
  'data-warehouse-engineering',
  'key-technologies-for-business',
  'digital-strategy',
  'advanced-data-science-ibm',
  'ibm-cloud-application-development-foundations',
  'bi-foundations-sql-etl-data-warehouse',
  'security-analyst-fundamentals',
  'people-and-soft-skills-for-professional-success',
  'ibm-ai-foundations-for-business',
  'ai-foundations-for-everyone',
  'nosql-big-data-and-spark-foundations',
  'linux-private-cloud-administration-power-systems',
  'applied-data-science-r',
  'ibm-intro-machine-learning',
  'ibm-z-mainframe',
  'ibm-mainframe-developer',
  'ibm-ai-workflow',
  'ibm-microservices',
  'ibm-artificial-intelligence',
];

const exported = {
  // google-it-support
  GOOGLE_CERT_S12N_ID: '7lHCSlFIEeeffRIHljDI_g',

  GOOGLE_CERT_S12N_SLUG: 'google-it-support',
};

export default exported;

export const { GOOGLE_CERT_S12N_ID, GOOGLE_CERT_S12N_SLUG } = exported;
