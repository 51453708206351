import { useQuery } from '@apollo/client';
import type { OwnableProduct_ProductType } from '__generated__/graphql-types';

import paymentExperiments from 'bundles/epic/clients/payments';
import { useNaptime } from 'bundles/naptimejs';
import ProductPricesV3 from 'bundles/naptimejs/resources/productPrices.v3';
import ProductPricesV4 from 'bundles/naptimejs/resources/productPrices.v4';
import GetOwnableProductsByLegacyProductIds from 'bundles/payments-common/api/GetOwnableProductsByLegacyProductIdsQuery.graphql';
import type {
  GetOwnableProductsByLegacyProductIdsQuery,
  GetOwnableProductsByLegacyProductIdsQueryVariables,
  OwnableProductPriceFragment,
} from 'bundles/payments-common/api/__generated__/GetOwnableProductsByLegacyProductIdsQuery';
import ProductType from 'bundles/payments/common/ProductType';
import type { ProductType as ProductTypeType } from 'bundles/payments/common/ProductType';

export type ProductPrices = ProductPricesV3 | ProductPricesV4;

type PropsFromNaptime = {
  productPrices?: ProductPricesV3[] | ProductPricesV4[];
};

export type PropsFromGetProductPrices = PropsFromNaptime & {
  loading: boolean;
};

type ProductInput = {
  productType: ProductTypeType;
  productItemId: string;
};

// Maps the `productType` enum values used in Naptime to the ones used in GraphQL Gateway
const LEGACY_PRODUCT_TYPE_MAP = {
  [ProductType.CATALOG_SUBSCRIPTION]: 'PRODUCT_TYPE_INVALID',
  [ProductType.COURSERA_PLUS]: 'PRODUCT_TYPE_COURSERA_PLUS',
  [ProductType.COURSERA_PLUS_SUBSCRIPTION]: 'PRODUCT_TYPE_COURSERA_PLUS_SUBSCRIPTION',
  [ProductType.COURSERA_TIER_LITE]: 'PRODUCT_TYPE_COURSERA_TIER_LITE',
  [ProductType.CREDENTIAL_TRACK]: 'PRODUCT_TYPE_INVALID',
  [ProductType.CREDENTIAL_TRACK_SUBSCRIPTION]: 'PRODUCT_TYPE_CREDENTIAL_TRACK_SUBSCRIPTION_V2',
  [ProductType.CREDENTIAL_TRACK_SUBSCRIPTION_V2]: 'PRODUCT_TYPE_CREDENTIAL_TRACK_SUBSCRIPTION_V2',
  [ProductType.ENTERPRISE_CONTRACT]: 'PRODUCT_TYPE_ENTERPRISE_CONTRACT',
  [ProductType.INTEREST_DEPOSIT]: 'PRODUCT_TYPE_INVALID',
  [ProductType.SPECIALIZATION]: 'PRODUCT_TYPE_SPECIALIZATION',
  [ProductType.SPECIALIZATION_PREPAID]: 'PRODUCT_TYPE_SPECIALIZATION_PREPAID',
  [ProductType.SPECIALIZATION_SUBSCRIPTION]: 'PRODUCT_TYPE_SPECIALIZATION_SUBSCRIPTION',
  [ProductType.VERIFIED_CERTIFICATE]: 'PRODUCT_TYPE_VERIFIED_CERTIFICATE',
  [ProductType.SPARK_COURSE_SHELL]: 'PRODUCT_TYPE_INVALID',
  [ProductType.SPARK_SPECIALIZATION]: 'PRODUCT_TYPE_INVALID',
  [ProductType.SPARK_VERIFIED_CERTIFICATE]: 'PRODUCT_TYPE_INVALID',
} as const;

// Maps the `productType` enum values used in GraphQL Gateway to the ones used in Naptime
const PRODUCT_TYPE_MAP = Object.keys(LEGACY_PRODUCT_TYPE_MAP).reduce((map: Record<string, string>, key: string) => {
  const value = LEGACY_PRODUCT_TYPE_MAP[key as keyof typeof LEGACY_PRODUCT_TYPE_MAP];

  if (value !== 'PRODUCT_TYPE_INVALID') {
    map[value] = key;
  }

  return map;
}, {});

const mapToLegacyProductId = (product: ProductInput) => {
  const productType = LEGACY_PRODUCT_TYPE_MAP[product.productType];

  if (!productType) {
    throw new Error(
      `Cannot map productType ${product.productType} and productItemId ${product.productItemId} to legacyProductId`
    );
  }

  return {
    productType: productType as OwnableProduct_ProductType,
    productItemId: product.productItemId,
  };
};

const getIsOwnableProductPricesEnabled = () => paymentExperiments.get('ownableProductsGraphqlEnabled');

export const mapToProductPricesOutput = (ownableProduct: OwnableProductPriceFragment | null) => {
  if (!ownableProduct) {
    return {};
  }

  const { priceCountryIsoCode, amount } = ownableProduct.price;
  const legacyProductId = ownableProduct.fulfillmentConfiguration?.legacyProductId;
  const productItemId = legacyProductId?.productItemId ?? '';
  const productType = legacyProductId?.productType;

  return {
    amount: amount.value,
    countryIsoCode: priceCountryIsoCode,
    currencyCode: amount.currencyCode,
    finalAmount: Number(amount.value),
    formattedFinalAmount: {
      amount: amount.value,
      currencyCode: amount.currencyCode,
    },
    productItemId,
    productType: productType ? PRODUCT_TYPE_MAP[productType] : '',
    promotionInfo: {},
  };
};

const useGetOwnableProductPrices = ({ products = [], skip }: { products: ProductInput[]; skip?: boolean }) => {
  const { data, loading } = useQuery<
    GetOwnableProductsByLegacyProductIdsQuery,
    GetOwnableProductsByLegacyProductIdsQueryVariables
  >(GetOwnableProductsByLegacyProductIds, {
    variables: { legacyProductIds: products.map(mapToLegacyProductId) },
    context: { clientName: 'gatewayGql' },
    skip,
  });

  const ownableProducts = data?.OwnableProductQueries?.findLatestByLegacyProductIds;

  return {
    productPrices: ownableProducts?.map(mapToProductPricesOutput),
    loading,
  };
};

export const useGetProductPricesV3 = ({ products = [], skip }: { products: ProductInput[]; skip?: boolean }) => {
  const isOwnableProductPricesEnabled = getIsOwnableProductPricesEnabled();
  const skipNaptime = skip || isOwnableProductPricesEnabled;
  const skipGraphql = skip || !isOwnableProductPricesEnabled;

  const {
    data: { productPrices: naptimeProductPrices },
    pending: naptimeLoading,
  } = useNaptime<PropsFromNaptime>(() => {
    if (skipNaptime) {
      return {};
    }

    return {
      productPrices: ProductPricesV3.getProductPrices(products),
    };
  }, [products?.length, skipNaptime]);

  const { productPrices, loading } = useGetOwnableProductPrices({ products, skip: skipGraphql });
  const graphqlProductPrices = productPrices?.map((output) => new ProductPricesV3(output));

  return {
    productPrices: naptimeProductPrices ?? graphqlProductPrices,
    loading: Boolean(naptimeLoading || loading),
  };
};

export const useGetProductPricesV4 = ({
  products = [],
  skip,
}: {
  products: {
    productType: ProductTypeType;
    productItemId: string;
  }[];
  skip?: boolean;
}) => {
  const isOwnableProductPricesEnabled = getIsOwnableProductPricesEnabled();
  const skipNaptime = skip || isOwnableProductPricesEnabled;
  const skipGraphql = skip || !isOwnableProductPricesEnabled;

  const {
    data: { productPrices: naptimeProductPrices },
    pending: naptimeLoading,
  } = useNaptime<PropsFromNaptime>(() => {
    if (skipNaptime) {
      return {};
    }

    return {
      productPrices: ProductPricesV4.getProductPrices(products),
    };
  }, [products?.length, skipNaptime]);

  const { productPrices, loading } = useGetOwnableProductPrices({ products, skip: skipGraphql });
  const graphqlProductPrices = productPrices?.map((output) => new ProductPricesV4(output));

  return {
    productPrices: naptimeProductPrices ?? graphqlProductPrices,
    loading: Boolean(naptimeLoading || loading),
  };
};
