import { createContext } from 'react';
import type React from 'react';

import type { Asset } from 'bundles/asset-admin/types/assets';

export type Props = {
  asset: Asset;
  onClose: () => void;
};

export type ImageContextType = {
  ImageDescriptionDialog: React.ComponentType<Props>;
};

const NullComponent: React.FC = () => null;

export const ImageContext = createContext<ImageContextType>({
  ImageDescriptionDialog: NullComponent,
});
