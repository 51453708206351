/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { useEffect } from 'react';

import URI from 'jsuri';

import * as redirect from 'js/lib/coursera.redirect';

import { LoadingSection } from '@coursera/cds-core';

import API from 'bundles/phoenix/lib/apiWrapper';

import _t from 'i18n!nls/program-home';

type OuterProps = {
  thirdPartyOrganizationId: string;
};

const styles = {
  container: css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--cds-color-white-0);
    z-index: 11000;
    display: grid;
    place-items: center;
  `,
};

function BypassLogin({ thirdPartyOrganizationId }: OuterProps) {
  useEffect(() => {
    const params = new URI(redirect.getQueryParams()).deleteQueryParam('attemptSSOLogin');

    const uri = new URI()
      .addQueryParam('action', 'initiateLoginFlow')
      .addQueryParam('id', thirdPartyOrganizationId)
      .addQueryParam('returnTo', redirect.getPathname() + params.toString());

    API('/api/thirdPartyOrganizations.v1')
      .post(uri.toString())
      .then((res: string) => {
        window.location.href = res;
      });
  }, [thirdPartyOrganizationId]);

  return (
    <div css={styles.container}>
      <LoadingSection label={_t('Redirecting you to your SSO provider...')} />
    </div>
  );
}

export default BypassLogin;
