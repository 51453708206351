import * as React from 'react';

import { Container, StyleSheet, containerMaxWidth, spacing } from '@coursera/coursera-ui';

import PageFooter from 'bundles/page-footer/components/PageFooter';
import PageHeader from 'bundles/page-header/components/PageHeader';
import SimpleCourseraMetatags from 'bundles/seo/components/SimpleCourseraMetatags';

import _t from 'i18n!nls/program-home';

const styles = StyleSheet.create({
  container: {
    margin: '0 auto',
    padding: spacing.sm,
  },
});

export default function ProgramNotFound(): JSX.Element {
  return (
    <div className="rc-ProgramNotFound" data-e2e="ProgramNotFound">
      <SimpleCourseraMetatags disableCrawlerIndexing />
      <PageHeader logoWrapper="div" />
      <Container rootClassName={styles.container} style={{ maxWidth: containerMaxWidth.lg, padding: '100px 30px' }}>
        <h1 className="headline-5-text">{_t('Sorry')}</h1>
        <p className="headline-2-text">{_t("We couldn't find the program that you're looking for.")}</p>
      </Container>
      <PageFooter />
    </div>
  );
}
