import waitForGraphQL from 'js/lib/waitForGraphQL';

import GetCourseForFinaidApp from 'bundles/payments/api/GetCourseForFinaidAppQuery.graphql';
import type {
  GetCourseForFinaidAppQuery,
  GetCourseForFinaidAppQueryVariables,
} from 'bundles/payments/api/__generated__/GetCourseForFinaidAppQuery';

type PropsFromCaller = {
  courseId: string;
};

export type WithCourseProps = {
  courseId: string | undefined;
  courseName: string | undefined;
  courseSlug: string | undefined;
  partnerIds?: Array<string>;
};

// Deprecated. Use useCourseForFinaidApp instead
export default () =>
  waitForGraphQL<PropsFromCaller, GetCourseForFinaidAppQuery, GetCourseForFinaidAppQueryVariables, WithCourseProps>(
    GetCourseForFinaidApp,
    {
      props: ({ data }) => {
        const course = data?.Course?.queryById;

        return {
          courseId: course?.id,
          courseName: course?.name,
          courseSlug: course?.slug,
          partnerIds: course?.partners?.map(({ id }) => id),
        };
      },
      options: ({ courseId }) => ({
        variables: {
          id: courseId,
        },
        errorPolicy: 'all',
        context: { clientName: 'gatewayGql' },
      }),
      skip: ({ courseId }) => !courseId,
    }
  );
