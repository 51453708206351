import * as React from 'react';

import CareerAcademyContentfulDataProvider from 'bundles/program-personalized-tab/providers/CareerAcademyContentfulDataProvider';
import CareerAcademyDataProvider from 'bundles/program-personalized-tab/providers/CareerAcademyDataProvider';
import ClipsRecsDataProvider from 'bundles/program-personalized-tab/providers/ClipsRecsDataProvider';
import CourseRecsDataProvider from 'bundles/program-personalized-tab/providers/CourseRecsDataProvider';
import OnboardingDataProvider from 'bundles/program-personalized-tab/providers/OnboardingDataProvider';
import S12nAndProCertRecsDataProvider from 'bundles/program-personalized-tab/providers/S12nAndProCertRecsDataProvider';
import ScoredSkillRecsDataProvider from 'bundles/program-personalized-tab/providers/ScoredSkillRecsDataProvider';
import SelectedSkillRecsDataProvider from 'bundles/program-personalized-tab/providers/SelectedSkillRecsDataProvider';

type Props = {
  programId: string;
  children: React.ReactNode;
};

const PersonalizedTabDataProvider = ({ programId, children }: Props) => (
  <OnboardingDataProvider programId={programId}>
    <CareerAcademyContentfulDataProvider>
      <CareerAcademyDataProvider>
        <CourseRecsDataProvider programId={programId}>
          <S12nAndProCertRecsDataProvider programId={programId}>
            <ClipsRecsDataProvider programId={programId}>
              <ScoredSkillRecsDataProvider programId={programId}>
                <SelectedSkillRecsDataProvider programId={programId}>{children}</SelectedSkillRecsDataProvider>
              </ScoredSkillRecsDataProvider>
            </ClipsRecsDataProvider>
          </S12nAndProCertRecsDataProvider>
        </CourseRecsDataProvider>
      </CareerAcademyDataProvider>
    </CareerAcademyContentfulDataProvider>
  </OnboardingDataProvider>
);

export default PersonalizedTabDataProvider;
