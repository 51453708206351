import * as React from 'react';

import type { InformationCardToggleProps } from 'bundles/program-common/components/InformationCard/InformationCardToggle';
import InformationCardToggle from 'bundles/program-common/components/InformationCard/InformationCardToggle';

import _t from 'i18n!nls/program-home';

type SkillsDiscoveryProp = {
  enableSkillsDiscovery?: boolean;
};

type Props = Omit<InformationCardToggleProps, 'label' | 'trackingName'> & SkillsDiscoveryProp;

const DetailedObjectivesInformationLink: React.FC<Props> = (props) => {
  const { enableSkillsDiscovery, ...rest } = props;
  let label: string;

  if (enableSkillsDiscovery) {
    label = rest.isOpen ? _t('Hide information about SkillSets') : _t('More information about SkillSets');
  } else {
    label = _t('What are SkillSets');
  }
  return (
    <InformationCardToggle
      {...rest}
      label={label}
      trackingName="detailed_objectives_information_link"
      buttonSize={enableSkillsDiscovery ? 'medium' : 'small'}
      isCdsEnabled
    />
  );
};

export default DetailedObjectivesInformationLink;
