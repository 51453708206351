/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useState } from 'react';

import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import { Button, Grid, Radio, RadioGroup } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';
import { ChevronPreviousIcon } from '@coursera/cds-icons';

import type { ImageSizes } from 'bundles/cml/shared/types/coreTypes';
import type { ImageElement } from 'bundles/cml/shared/types/elementTypes';

import _t from 'i18n!nls/cml';

const styles = {
  resizeMenu: css`
    width: 215px;
    padding: var(--cds-spacing-200) var(--cds-spacing-300);

    .cds-formControl-formLabel {
      display: none; /* hide unused label border */
    }
  `,
};

export type Props = {
  image: ImageElement;
  onClose: () => void;
};

const ImageResizeDialog: React.FC<Props> = ({ image, onClose }) => {
  const editor = useSlateStatic();
  const [size, setSize] = useState(image.size || 'default');

  const handleResize = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const newSize = e.target.value as ImageSizes;
    setSize(newSize);

    const path = ReactEditor.findPath(editor, image);
    Transforms.setNodes(editor, { size: newSize }, { at: path });

    onClose();
  };

  const handleClose = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    onClose();
  };

  return (
    <div css={styles.resizeMenu}>
      <Grid container spacing={8} direction="column" justifyContent="space-between">
        <Grid item>
          <Button
            variant="ghost"
            size="medium"
            icon={<ChevronPreviousIcon />}
            iconPosition="before"
            onClick={handleClose}
            data-test="back-btn"
            edgeAlign="start"
          >
            {_t('Back')}
          </Button>
        </Grid>

        <Grid item>
          <RadioGroup
            value={size}
            name="image-resize-radio-group"
            aria-label={_t('Select image size')}
            onChange={handleResize}
          >
            <Radio value="small" label={_t('Small')} />
            <Radio value="default" label={_t('Best fit')} />
            <Radio value="fullWidth" label={_t('Full width')} />
          </RadioGroup>
        </Grid>
      </Grid>
    </div>
  );
};

export default ImageResizeDialog;
