import * as React from 'react';

import CoachButton from 'bundles/ai-coach-platform/components/building-blocks/CoachButton';
import { SparkleIcon } from 'bundles/ai-coach-platform/components/icons/mui';

import _t from 'i18n!nls/ai-coach-platform';

import type { Props as CoachButtonProps } from './CoachButton';

type Props = { children: string } & CoachButtonProps;

/**
 * Simple wrapper around CoachButton to provide some specific styles.
 */
export const CoachTriggerButton = (props: Props): React.ReactElement => {
  const { children, ...rest } = props;

  return (
    <CoachButton
      variant="secondary"
      size="small"
      aria-label={_t('#{label}, #{coachLabel} trigger', { label: children, coachLabel: 'Coach' })}
      icon={<SparkleIcon />}
      iconPosition="before"
      {...rest}
    >
      {children}
    </CoachButton>
  );
};

export default CoachTriggerButton;
