import * as React from 'react';
import { SortableHandle as sortableHandle } from 'react-sortable-hoc';

type Props = {
  color?: string;
  hoverColor?: string;
} & React.SVGAttributes<SVGElement>;

type State = {
  isHovered: boolean;
};

class SvgDrag extends React.Component<Props, State> {
  state = {
    isHovered: false,
  };

  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  render() {
    const { isHovered } = this.state;
    const { color: fillColor = 'var(--cds-color-interactive-primary)', hoverColor, style, ...rest } = this.props;
    const hoverFillColor = hoverColor || fillColor;
    const fill = isHovered ? hoverFillColor : fillColor;

    return (
      <svg
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        width={18}
        height={19}
        viewBox="0 0 18 19"
        style={{
          cursor: 'pointer',
          ...style,
        }}
        {...rest}
      >
        <defs>
          <path
            d="M15 19l-3 3-3-3h6zm6-3.7v1H3v-1h18zm0-3.45v1H3v-1h18zm0-3.45v1H3v-1h18zM12 3l3 3H9l3-3z"
            id="prefix__a"
          />
        </defs>
        <g transform="translate(-3 -3)" fill="none" fillRule="evenodd">
          <path d="M0 0h24v24H0z" />
          <mask id="prefix__b" fill="#fff">
            <use xlinkHref="#prefix__a" />
          </mask>
          <use fill={fill} xlinkHref="#prefix__a" />
          <g mask="url(#prefix__b)" fill={fill}>
            <path d="M0 0h24v24H0z" />
          </g>
        </g>
      </svg>
    );
  }
}

export const DragHandle = sortableHandle<Props>((props: {}) => <SvgDrag {...props} />);
