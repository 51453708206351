/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Typography, breakpoints, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

import LearnerProgramHomeBannerItem from 'bundles/enterprise-admin-grow-with-google/components/learner/LearnerProgramHomeBannerItem';
import CertificateCongratsBlueSVG from 'bundles/enterprise-admin-grow-with-google/components/svgs/CertificateCongratsBlueSVG';
import MountainBlueSVG from 'bundles/enterprise-admin-grow-with-google/components/svgs/MountainBlueSVG';
import PersonLaptopBlueSVG from 'bundles/enterprise-admin-grow-with-google/components/svgs/PersonLaptopBlueSVG';

import _t from 'i18n!nls/enterprise-admin-grow-with-google';

const bannerContainer = (theme: Theme) =>
  css({
    maxWidth: '1224px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'var(--cds-spacing-600)',
    marginBottom: 'var(--cds-spacing-600)',
    padding: '0 var(--cds-spacing-150)',
  });

const bannerItem = (theme: Theme) =>
  css({
    width: '30%',
    [breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: 'var(--cds-spacing-300)',
    },
  });

const bannerItemContainer = css({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
});

const LearnerProgramHomeBanner = () => {
  const theme = useTheme();
  return (
    <div css={bannerContainer(theme)}>
      <Typography variant="h1semibold" css={{ marginBottom: 'var(--cds-spacing-400)' }} component="h2">
        {_t('Get professional-level training with Google Certificates')}
      </Typography>
      <div css={bannerItemContainer}>
        <div css={bannerItem(theme)}>
          <LearnerProgramHomeBannerItem
            title={_t('No experience necessary')}
            description={_t('Learn job-ready skills even with no relevant experience or a post-secondary degree.')}
            icon={<PersonLaptopBlueSVG />}
          />
        </div>
        <div css={bannerItem(theme)}>
          <LearnerProgramHomeBannerItem
            title={_t('Learn at your own pace')}
            description={_t('Complete the online program on your own time and set your own schedule.')}
            icon={<MountainBlueSVG />}
          />
        </div>
        <div css={bannerItem(theme)}>
          <LearnerProgramHomeBannerItem
            title={_t('Stand out to employers')}
            description={_t('Make your resume competitive with a credential from Google.')}
            icon={<CertificateCongratsBlueSVG />}
          />
        </div>
      </div>
    </div>
  );
};

export default LearnerProgramHomeBanner;
