import gql from 'graphql-tag';

export const EnterpriseHomePageQuery = gql`
  query EnterpriseHomePageQuery($userId: String!, $thirdPartyOrgSlug: String!) {
    EnterpriseLearnerSummariesV1Resource {
      byUserIdAndThirdPartyOrgSlug(userId: $userId, thirdPartyOrgSlug: $thirdPartyOrgSlug) {
        elements {
          id
          thirdPartyOrganization {
            id
            name
            slug
            primaryColor
            landingPageBanner
            rectangularLogo
          }
          enterprisePrograms(eventualConsistency: true) {
            elements {
              id
              thirdPartyOrganizationId
              metadata {
                slug
                name
                tagline
                description
                logo
                squareLogo
                linkedTargetSkillProfileIds
                linkedAcademicDisciplineIds
                skillSetAvailabilityType
              }
            }
          }
          invitations {
            elements {
              id
              createdAt
              userRoster {
                id
                program(eventualConsistency: true) {
                  id
                  metadata {
                    name
                    slug
                    tagline
                    description
                    logo
                    squareLogo
                  }
                }
              }
            }
          }
          domains {
            id
            name
            slug
            subdomainIds
          }
        }
      }
    }

    SubdomainsV1Resource {
      getAll {
        elements {
          id
          name
          slug
          domainId
        }
      }
    }
  }
`;
