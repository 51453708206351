import * as React from 'react';

import { StyleSheet, css, fontFamily } from '@coursera/coursera-ui';

const styles = StyleSheet.create({
  captionCommon: {
    color: '#335475',
    fontWeight: 'bold',
    fontFamily: fontFamily.bold,
    fontSize: '1rem',
    lineHeight: '1.875rem',
  },

  howItWorksCaption: {
    margin: '0rem',
  },

  captionHowToStart: {
    margin: '1.125rem 0',
  },

  cardTitle: {
    color: '#335475',
    fontWeight: 'normal',
    fontFamily: fontFamily.body,
    fontSize: '1.5rem',
    lineHeight: '1.875rem',
    margin: 0,
  },
});

/*
 * hierarchy assumptions:
 * - page has h1
 * - card title is h2
 * - card subheadings are h3
 */

export const CardTitle: React.FC = (props) => <h2 {...css(styles.cardTitle)}>{props.children}</h2>;

export const HowItWorksCaption: React.FC = (props) => (
  <h3 {...css(styles.captionCommon, styles.howItWorksCaption)}>{props.children}</h3>
);

export const HowToStartCaption: React.FC = (props) => (
  <h3 {...css(styles.captionCommon, styles.captionHowToStart)}>{props.children}</h3>
);
