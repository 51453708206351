import gql from 'graphql-tag';

const EnrolledS12nsQuery = gql`
  query EnrolledS12nsQuery($programId: String!) {
    ProgramCurriculumProductsV1 @naptime {
      enrolledS12ns(programId: $programId, limit: 50, includes: "typeName,definition") {
        elements {
          id
          programId
          productType
          productItemId
          productState
          program
          programProductMetadata
          targetSkillProfileUserStates
          s12nProgress
          s12nIds
          courseProgress
          programs
          courseSchedules
          programUserCredits
          catalogCourses
          partnerIds
          targetSkillProfiles
        }
      }
    }
  }
`;

export default EnrolledS12nsQuery;
