import * as React from 'react';

import { Dialog, Typography2 } from '@coursera/cds-core';

import { TrackedCdsButton } from 'bundles/common/components/withSingleTracked';

import _t from 'i18n!nls/program-common';

const { HeadingGroup, Content, Actions } = Dialog;

export function CourseEnrollmentWarningModal() {
  const [showModal, setShowModal] = React.useState(true);

  function closeModal() {
    setShowModal(false);
  }

  if (!showModal) {
    return null;
  }

  return (
    <Dialog
      open={showModal}
      aria-describedby="course-enrollment-warning-dialog-content"
      color="darkBlue"
      onClose={closeModal}
    >
      <HeadingGroup>{_t('Welcome to your free course!')}</HeadingGroup>
      <Content>
        <Typography2 component="p" variant="bodyPrimary">
          {_t(
            "You just used your free annual course enrollment. Start learning right away and, if you don't want to continue, just unenroll within 14 days and we'll restore your free credit."
          )}
        </Typography2>
      </Content>
      <Actions>
        <TrackedCdsButton variant="primary" onClick={closeModal} trackingName="course_enrollment_warning_close_button">
          {_t('Got it')}
        </TrackedCdsButton>
      </Actions>
    </Dialog>
  );
}

export default CourseEnrollmentWarningModal;
