import * as React from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { VisuallyHidden } from '@coursera/cds-core';

import ReactPriceDisplay from 'bundles/payments-common/components/ReactPriceDisplay';
import type { ProductPrices } from 'bundles/payments-common/hooks/useGetProductPrices';
import { getDurationMessage } from 'bundles/s12n-enroll/components/non-recurring/utils/nonRecurringMessageUtils';

import _t from 'i18n!nls/s12n-enroll';

type PropsFromCaller = {
  numOfMonths: number | null;
  numOfHours: number;
  productPrice?: ProductPrices;
  percentageOff: number;
  isRecommended?: boolean;
};

const NonRecurringChoiceScreenReaderDescription: React.FunctionComponent<PropsFromCaller> = ({
  numOfMonths,
  numOfHours,
  productPrice,
  percentageOff,
  isRecommended,
}) => (
  <VisuallyHidden component="span" className="rc-A11yScreenReaderOnly">
    {numOfMonths && (
      <>
        <FormattedMessage
          message={_t('{numOfMonths} {numOfMonths, plural, =1 {month} other {months}} of access.')}
          numOfMonths={numOfMonths}
        />
        &nbsp;
      </>
    )}
    <FormattedMessage message={_t('Estimated study time: {studyTime}.')} studyTime={getDurationMessage(numOfHours)} />
    &nbsp;
    {isRecommended && <>{_t('This is the recommended pace.')}&nbsp;</>}
    {productPrice && (
      <>
        <FormattedMessage
          message={_t('Price: {price}.')}
          price={<ReactPriceDisplay value={productPrice.amount} currency={productPrice.currencyCode} />}
        />
        &nbsp;
      </>
    )}
    {percentageOff > 0 && (
      <FormattedMessage
        message={_t('This price is {percentageOff}% off per month compared to 1 month of access.')}
        percentageOff={percentageOff}
      />
    )}
  </VisuallyHidden>
);

export default NonRecurringChoiceScreenReaderDescription;
