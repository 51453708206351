// eslint-disable no-restricted syntax
import * as React from 'react';
import ReactDOM from 'react-dom';

import $ from 'jquery';
import PropTypes from 'prop-types';

import 'css!bundles/videojs/css/coursera';
import 'css!pages/open-course/video/styl/video';
import 'css!video.js/dist/video-js.css';

class VideoPlayer extends React.Component {
  static propTypes = {
    videoJs: PropTypes.object.isRequired,
    width: PropTypes.number,
    height: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string, // 'auto'
    ]),
  };

  componentDidMount() {
    this.attachVideoPlayer(ReactDOM.findDOMNode(this));
  }

  componentDidUpdate() {
    this.attachVideoPlayer(ReactDOM.findDOMNode(this));
  }

  attachVideoPlayer(node: $TSFixMe) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'videoJs' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { videoJs } = this.props;
    if (videoJs) {
      $(node).html(videoJs.el());
    }
  }

  render() {
    return <div className="rc-VideoPlayer" />;
  }
}

export default VideoPlayer;
