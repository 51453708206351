import * as React from 'react';

import SubscriptionVPropCDP from 'bundles/enroll/components/subscriptions/SubscriptionVPropCDP';
import SubscriptionVPropSDP from 'bundles/enroll/components/subscriptions/SubscriptionVPropSDP';
import SubscriptionVPropBulletPointV2 from 'bundles/enroll/components/subscriptions/free-trialV2/SubscriptionVPropBulletPoint';
import { getPaidSubscriptionBulletsV2 } from 'bundles/enroll/components/subscriptions/free-trialV2/utils/bulletData';
import usePageData from 'bundles/enroll/data/usePageData';
import ReactPriceDisplay from 'bundles/payments-common/components/ReactPriceDisplay';

import 'css!./__styles__/SubscriptionVProp';

type PropsFromCaller = {
  onSdp: boolean;
};

const SubscriptionVProp: React.FC<PropsFromCaller> = ({ onSdp }) => {
  const { s12nDerivatives, s12n } = usePageData();

  if (!(s12n && s12nDerivatives?.catalogPrice)) {
    return null;
  }

  const { catalogPrice } = s12nDerivatives;

  const bullets = getPaidSubscriptionBulletsV2({
    s12nProductVariant: s12n?.productVariant,
  });
  const monthlyPrice = <ReactPriceDisplay currency={catalogPrice.currencyCode} value={catalogPrice.amount} />;
  return (
    <div className="rc-SubscriptionVProp">
      <div className="punch-line">{onSdp ? <SubscriptionVPropSDP /> : <SubscriptionVPropCDP />}</div>
      <ul className="bullet_list nostyle">
        {bullets.map((bullet) => {
          return (
            <li key={`bullet_${bullet.header}`}>
              <SubscriptionVPropBulletPointV2 messageProps={{ monthlyPrice }} highlightHeader={true} {...bullet} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SubscriptionVProp;
