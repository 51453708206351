import { SPECIALIZATION, VERIFIED_CERTIFICATE } from 'bundles/payments/common/ProductType';

export const scopes = {
  COURSERA_PLUS_SUBSCRIPTION: 'COURSERA_PLUS_SUBSCRIPTION',
  COURSE_WITH_FULL_DISCOUNT: 'COURSE_WITH_FULL_DISCOUNT',
} as const;

export const policies = {
  BLOCK: 'BLOCK',
  ALLOW: 'ALLOW',
} as const;

export const enrollmentProductType = {
  VERIFIED_CERTIFICATE,
  SPECIALIZATION,
} as const;

export type ScopesKeys = typeof scopes[keyof typeof scopes];

export type PoliciesKeys = typeof policies[keyof typeof policies];

export type EnrollmentProductTypekeys = typeof enrollmentProductType[keyof typeof enrollmentProductType];
