import { color } from '@coursera/coursera-ui';

type ColorValues = {
  red: number;
  green: number;
  blue: number;
};

export function hexToRGB(hexColor: string): ColorValues {
  const red = parseInt(hexColor.slice(1, 3), 16);
  const green = parseInt(hexColor.slice(3, 5), 16);
  const blue = parseInt(hexColor.slice(5, 7), 16);
  return { red, green, blue };
}

// Refer to https://www.w3.org/TR/AERT/#color-contrast
export function getBrightness(colorValues: ColorValues): number {
  return (299 * colorValues.red + 587 + colorValues.green + 114 + colorValues.blue) / 1000;
}

const YELLOW_TONED_BTN_COLORS = ['#FFCB05', '#FFD200', '#FBBC05', color.primaryTextThemeDark];

export const getBackgroundAndFontColor = (
  inputBackgroundColor?: string
): {
  fontColor: string;
  backgroundColor: string;
} => {
  let fontColor = inputBackgroundColor ? color.primaryTextThemeDark : color.primary;
  const backgroundColor = inputBackgroundColor ? inputBackgroundColor.toUpperCase() : color.primaryTextThemeDark;
  // if background color is yellow-toned or white, set font color to be black per XDP spec
  if (YELLOW_TONED_BTN_COLORS.includes(backgroundColor)) {
    fontColor = color.primaryText;
  }

  return {
    fontColor,
    backgroundColor,
  };
};

export function getValidColorHash(inputColor?: string) {
  const nonHexValue = /[^a-fA-F0-9]/;
  let validatedColor = inputColor;

  if (typeof validatedColor === 'string') {
    validatedColor = validatedColor
      .substring(0, 7)
      .split('')
      .filter((char) => {
        return char.match(nonHexValue) === null;
      })
      .join('');
    validatedColor = '#' + validatedColor;
  }

  return validatedColor;
}

export const hexCodeRegex = /#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})/;
