import type { Editor, Node, NodeEntry } from 'slate';

import logger from 'js/app/loggerSingleton';

import { normalizeBody } from 'bundles/cml/editor/normalize/normalizeBody';
import { normalizeHeadings } from 'bundles/cml/editor/normalize/normalizeHeadings';
import { normalizeLastNode } from 'bundles/cml/editor/normalize/normalizeLastNode';
import { normalizeLinks } from 'bundles/cml/editor/normalize/normalizeLinks';
import { normalizeLists } from 'bundles/cml/editor/normalize/normalizeLists';
import { normalizeMarks } from 'bundles/cml/editor/normalize/normalizeMarks';
import { normalizeMath } from 'bundles/cml/editor/normalize/normalizeMath';
import { normalizeTables } from 'bundles/cml/editor/normalize/normalizeTables';
import { normalizeTopLevelElements } from 'bundles/cml/editor/normalize/normalizeTopLevelElements';
import { normalizeVoids } from 'bundles/cml/editor/normalize/normalizeVoids';
import type { Options } from 'bundles/cml/editor/normalize/types';

export const NORMALIZERS = [
  normalizeMarks,
  normalizeHeadings,
  normalizeMath,
  normalizeVoids,
  normalizeTopLevelElements,
  normalizeLinks,
  normalizeTables,
  normalizeLastNode,
  normalizeLists,
  normalizeBody,
];

type Normalizer = (editor: Editor, options: Options, entry: NodeEntry<Node>) => boolean;

export const handleNormalize = (
  editor: Editor,
  options: Options,
  normalizers: Normalizer[],
  normalizeFallback: (node: NodeEntry<Node>) => void
) => {
  return (entry: NodeEntry<Node>) => {
    if (normalizers.some((normalizer) => normalizer(editor, options, entry))) {
      return;
    }

    try {
      normalizeFallback(entry);
    } catch (e) {
      logger.warn('[CMLEditor]: normalization failed', e);
    }
  };
};

export const normalize = (editor: Editor, options: Options, normalizeFallback: (node: NodeEntry<Node>) => void) => {
  return handleNormalize(editor, options, NORMALIZERS, normalizeFallback);
};
