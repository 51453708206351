import * as React from 'react';

import { compose, withProps } from 'recompose';

import connectToRouter from 'js/lib/connectToRouter';

import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { TrackedA } from 'bundles/page/components/TrackedLink2';
import { EMAIL_SOURCE_PARAM } from 'bundles/sharing-common/constants';
import type {
  SocialButtonPropsFromCaller,
  SocialButtonPropsToComponent,
} from 'bundles/sharing-common/types/sharingSharedTypes';
import withCustomUrl from 'bundles/sharing-common/utils/withCustomUrl';
import withFormattedShareLink from 'bundles/sharing-common/utils/withFormattedShareLink';

import _t from 'i18n!nls/sharing-common';

type EmailButtonPropsFromCaller = SocialButtonPropsFromCaller & {
  generateSubject?: (shareLink?: string) => string;
  generateBody?: (shareLink?: string) => string;
};

class EmailButton extends React.Component<SocialButtonPropsToComponent> {
  render() {
    const { customShareLink, createSocialShareLink, targetShareLink, withLabel, onSocialButtonClick } = this.props;

    return (
      <TrackedDiv className="rc-EmailButton social-media-button" trackingName="social_share_button" trackMouseEnters>
        <TrackedA
          trackingName="email_share_button"
          className="social-media-button_link"
          href={createSocialShareLink(customShareLink || targetShareLink)}
          rel="noopener noreferrer"
          onClick={onSocialButtonClick}
          ariaLabel={_t('Share with email')}
        >
          <svg
            className="social-media-button_icon"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.54541 24.7208C0.54541 11.07 11.6154 0 25.2662 0C38.917 0 49.987 11.07 49.987 24.7208C49.987 38.3716 38.917 49.4416 25.2662 49.4416C11.6154 49.4416 0.54541 38.3716 0.54541 24.7208ZM9.37409 37.0811H39.9808V14.1261H9.37409V37.0811ZM11.6004 15.4015H37.7538L24.6771 27.9094L11.6004 15.4015ZM10.65 16.256V35.806H38.7061V16.256L24.678 29.6744L10.65 16.256Z"
              fill="#2A73CC"
            />
          </svg>
          {withLabel && <p className="social-media-button_label">{_t('Email')}</p>}
        </TrackedA>
      </TrackedDiv>
    );
  }
}

export default compose<SocialButtonPropsToComponent, EmailButtonPropsFromCaller>(
  // order of these are important
  connectToRouter((router) => ({ router })),
  withFormattedShareLink(EMAIL_SOURCE_PARAM),
  withProps(({ generateSubject, generateBody }: EmailButtonPropsFromCaller) => {
    const createSocialShareLink = (shareLink: string) => {
      return `mailto:?Subject=${generateSubject ? encodeURIComponent(generateSubject(shareLink)) : shareLink}&body=${
        generateBody ? encodeURIComponent(generateBody(shareLink)) : ''
      }`;
    };

    return {
      createSocialShareLink,
    };
  }),
  withCustomUrl()
)(EmailButton);
