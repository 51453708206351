/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import _ from 'lodash';
import moment from 'moment';

import { SelectField, SelectOption, breakpoints, useTheme } from '@coursera/cds-core';

import _t from 'i18n!nls/program-home';

export type PropsForDay = {
  day: number | undefined;
  setDay: (val: number) => void;
  renderDayValidationError?: boolean;
};

export type PropsForMonth = {
  month: number | undefined;
  setMonth: (val: number) => void;
};

export type PropsForYear = {
  year: number | undefined;
  setYear: (val: number) => void;
};

type PropsFromCaller = { className?: string } & PropsForDay & PropsForMonth & PropsForYear;

type Props = PropsFromCaller;

const DAY_LIMIT = 31;
const MIN_YEAR = 1900;
const MAX_YEAR = moment().year();

const useStyles = () => {
  const theme = useTheme();
  return {
    group: css`
      display: flex;
      flex-wrap: wrap;
      gap: var(--cds-spacing-300);
    `,
    field: css`
      flex: 1 1 175px;
    `,
    month: css`
      ${breakpoints.down('xs')} {
        flex-basis: 100%;
      }
    `,
  };
};

type SillyCdsType = React.ChangeEvent<{ name?: string; value: unknown }>;

export default function DatePicker({
  className,
  setMonth,
  setDay,
  setYear,
  renderDayValidationError,
}: Props): JSX.Element {
  const styles = useStyles();
  function onMonthChange(event: SillyCdsType) {
    setMonth(Number(event.target.value));
  }
  function onDayChange(event: SillyCdsType) {
    setDay(Number(event.target.value));
  }
  function onYearChange(event: SillyCdsType) {
    setYear(Number(event.target.value));
  }
  const months = moment.localeData().months();
  const days = _.range(1, DAY_LIMIT + 1);
  const years = _.range(MAX_YEAR, MIN_YEAR);
  return (
    <fieldset className={className} css={styles.group}>
      <legend className="sr-only">{_t('Date of birth')}</legend>
      <SelectField
        label={_t('Month')}
        placeholder={_t('Select Month')}
        onChange={onMonthChange}
        aria-describedby="AgePrompt-Error"
        css={[styles.field, styles.month]}
      >
        {_.map(months, (month, index) => (
          <SelectOption key={month} value={index}>
            {month}
          </SelectOption>
        ))}
      </SelectField>
      <SelectField
        label={_t('Day')}
        placeholder={_t('Select Day')}
        onChange={onDayChange}
        aria-describedby="AgePrompt-Error"
        validationStatus={renderDayValidationError ? 'error' : undefined}
        css={styles.field}
      >
        {_.map(days, (day) => (
          <SelectOption key={day} value={day}>
            {day}
          </SelectOption>
        ))}
      </SelectField>
      <SelectField
        label={_t('Year')}
        placeholder={_t('Select Year')}
        onChange={onYearChange}
        aria-describedby="AgePrompt-Error"
        css={styles.field}
      >
        {_.map(years, (year) => (
          <SelectOption key={year} value={year}>
            {year}
          </SelectOption>
        ))}
      </SelectField>
    </fieldset>
  );
}
