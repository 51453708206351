import type React from 'react';

import { isHotkey } from 'is-hotkey';
import { Editor, Range, Text, Transforms } from 'slate';

const isDeleteKey = isHotkey('delete');
const isBackspaceKey = isHotkey('backspace');

export const deleteMarkKeyDownHandler = (editor: Editor, event: React.KeyboardEvent): boolean => {
  const { nativeEvent } = event;

  const deleteKey = isDeleteKey(nativeEvent);
  const backspaceKey = isBackspaceKey(nativeEvent);
  const selection = editor.selection;

  if (!(deleteKey || backspaceKey) || !selection) {
    return false;
  }

  const marks = Object.keys(Editor.marks(editor) || {});
  if (!marks.length) {
    return false;
  }

  const [text, path] = Editor.node(editor, selection);
  if (!Text.isText(text)) {
    return false;
  }

  const isCollapsed = Range.isCollapsed(selection);
  if (isCollapsed && text.text.length === 1) {
    const offset = selection.anchor.offset;
    const isStart = deleteKey && offset === 0;
    const isEnd = backspaceKey && offset === 1;
    if (isStart || isEnd) {
      Transforms.unsetNodes(editor, marks, { at: path });
      return true;
    }
  }

  const textValue = Editor.string(editor, selection);
  if (!isCollapsed && textValue === text.text) {
    Transforms.unsetNodes(editor, marks, { at: path });
    return true;
  }

  return false;
};
