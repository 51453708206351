import * as React from 'react';
import { useRef } from 'react';

import MathEditorDialog from 'bundles/cml/editor/components/elements/math/MathEditorDialog';
import type { Props as DialogProps } from 'bundles/cml/editor/components/elements/math/MathEditorDialog';
import { useStyleContext } from 'bundles/cml/editor/context/styleContext';
import FloatingMenu from 'bundles/cml/shared/components/menu/FloatingMenu';

type Props = DialogProps & {
  anchorEl: HTMLElement;
  isBlock: boolean;
};

const MathEditorDesktop = React.forwardRef<HTMLDivElement, Props>(({ anchorEl, isBlock, ...dialogProps }, ref) => {
  const defaultRef = useRef<HTMLDivElement>(null);
  const { pageless } = useStyleContext();

  const onClose = dialogProps.onBlur;

  return (
    <FloatingMenu
      anchorEl={anchorEl}
      pageless={pageless}
      placement={isBlock ? 'bottom' : 'bottom-start'}
      enableFlip
      enableEscaped={false}
      onClose={onClose}
    >
      <MathEditorDialog ref={ref || defaultRef} {...dialogProps} className="rc-CMLEditorMenu" />
    </FloatingMenu>
  );
});

MathEditorDesktop.displayName = 'MathEditorDesktop';

export default MathEditorDesktop;
