export const EXCLUDED_SHARE_PROMO_CODES = [
  'free-genai-course',
  '9pKuaUa9daFdNgMEs6HtJKzoQ',
  'mHjK68NSxCQCf3x',
  'Slz9uT6Zr8ZBrUR',
  'tdTMcgGO6ufXS7g',
  'Q4onaA5nJTADfvc',
  'OwWP2N6CS3fmL1J',
  'WIVI5bAe8SB04as',
  'vxwrpDW1tWZinDt',
  'u4LynXXD0PQa4Ql',
  'v9BHI459kVpmALh',
  'heYRvfgGr1pHJwv',
];

export const SOURCE_PROMOTION_BANNER = 'promo';
