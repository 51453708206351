import type React from 'react';

import { useId } from '@coursera/cds-common';

import Divider from '@core/Divider';

import { ActionOverflowGroupContext } from './context';
import { classes } from './styles/getActionOverflowCss';

export type Props = {
  /**
   * Group label.
   */
  label: string;
  /**
   * Individual `ActionOverflow.Item` elements used as menu items of this group.
   */
  children: React.ReactNode;
};

const ActionOverflowGroup = (props: Props) => {
  const { label, children } = props;
  const id = useId();

  return (
    <>
      <li aria-hidden className={classes.groupDivider}>
        <Divider />
      </li>
      <li aria-hidden className={classes.groupLabel}>
        {label}
      </li>
      <ActionOverflowGroupContext.Provider value={{ label, id }}>
        {children}
      </ActionOverflowGroupContext.Provider>
    </>
  );
};

export default ActionOverflowGroup;
