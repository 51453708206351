import {
  CLEAR_CHAT_SESSION,
  NEW_CHAT_SESSION,
  SET_CHAT_SESSION_ID,
  SET_CHAT_SESSION_STORAGE_KEY,
} from '../actions/types';
import type { SessionActions } from '../actions/types';
import type { SessionState } from '../types';
import { createReducer } from '../utils/createReducer';

const initialState = {
  chatSessionId: undefined,
  chatSessionStorageKey: undefined,
};

const sessionStateReducer = {
  [NEW_CHAT_SESSION]: (state: SessionState) => {
    return {
      ...state,
      chatSessionId: undefined,
      chatSessionStorageKey: undefined,
    };
  },
  [CLEAR_CHAT_SESSION]: (state: SessionState) => ({
    ...state,
    chatSessionId: undefined,
    chatSessionStorageKey: undefined,
  }),
  [SET_CHAT_SESSION_ID]: (state: SessionState, { chatSessionId }: SessionState) => ({
    ...state,
    chatSessionId,
  }),

  [SET_CHAT_SESSION_STORAGE_KEY]: (state: SessionState, chatSessionStorageKey: string) => ({
    ...state,
    chatSessionStorageKey,
  }),
};

export default (state = initialState, action: SessionActions) => createReducer(sessionStateReducer, state, action);
