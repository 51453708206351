type CatalogBrowseCollection = {
  contextType: string;
  contextId: string;
  start: string;
  limit: number;
};

const DefaultCatalogLoggedInBrowseCollections: Omit<CatalogBrowseCollection, 'limit'> = {
  contextType: 'PAGE',
  contextId: 'logged-in-enterprise-program-home-v2',
  start: '0',
};

const DefaultCatalogLoggedOutBrowseCollections: Omit<CatalogBrowseCollection, 'limit'> = {
  contextType: 'PAGE',
  contextId: 'logged-out-enterprise-program-home-v2',
  start: '0',
};

export default function getCatalogBrowseCollections(isAuthenticated: boolean, limit: number): CatalogBrowseCollection {
  const collection = isAuthenticated
    ? { ...DefaultCatalogLoggedInBrowseCollections, limit }
    : { ...DefaultCatalogLoggedOutBrowseCollections, limit };
  return collection;
}
