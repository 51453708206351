import * as React from 'react';

import { ApiButton, Button } from '@coursera/coursera-ui';
import type { ApiStatus } from '@coursera/coursera-ui/lib/constants/sharedConstants';

import type { PropsFromWithApiHandler } from 'bundles/coursera-ui/components/hocs/withApiHandler';
import withApiHandler from 'bundles/coursera-ui/components/hocs/withApiHandler';
import type { InjectedNaptime } from 'bundles/naptimejs';
import { API_BEFORE_SEND } from 'bundles/phoenix/components/ApiNotification';
import Modal from 'bundles/phoenix/components/Modal';
import { REFRESH_RESOURCE } from 'bundles/program-common/constants/ProgramActionConstants';
import ProgramActionApiManager from 'bundles/program-common/models/ProgramActionApiManager';

import _t from 'i18n!nls/program-home';

type Props = {
  willUpgradeS12n: boolean;
  handleClose: () => void;
  s12nId: string;
  naptime: InjectedNaptime;
  programId: string;
  userId: number;
} & PropsFromWithApiHandler;

type State = {
  apiStatus: ApiStatus;
};

class S12nVersioningConfirmationModal extends React.Component<Props, State> {
  declare _apiManager: ProgramActionApiManager;

  constructor(props: Props) {
    super(props);
    const { apiStatus, naptime, programId, userId } = this.props;
    this._apiManager = new ProgramActionApiManager({ naptime, programId, userId });
    this.state = {
      apiStatus,
    };
  }

  componentDidUpdate = (prevProps: Props) => {
    const { apiStatus } = this.props;
    if (prevProps.apiStatus !== apiStatus) {
      this.setState({ apiStatus });
    }
  };

  handleUpgradingS12n = () => {
    const { s12nId, handleApiPromise } = this.props;
    const apiPromise = this._apiManager.getUpgradeS12nPromise({ s12nId });
    const refreshDataPromiseFn = () => this._apiManager.getRefreshDataPromise(REFRESH_RESOURCE.programHome.upgradeS12n);
    handleApiPromise({ apiPromise, refreshDataPromiseFn });
  };

  render() {
    const { handleClose, willUpgradeS12n } = this.props;
    const { apiStatus } = this.state;
    return (
      <Modal modalName="S12nVersioningConfirmationModal" handleClose={handleClose}>
        <div className="vertical-box align-items-absolute-center">
          <h2 className="m-b-2">{willUpgradeS12n ? _t('Ready to switch?') : _t('Thanks for letting us know')}</h2>
          {!willUpgradeS12n && <p>{_t('If you change your mind you can switch to the new version anytime.')}</p>}
          {willUpgradeS12n && (
            <div className="vertical-box text-xs-center">
              <p>
                {_t(
                  "You can get started right away and you'll get credit for anything you've already finished, as long as it's included in the new version."
                )}
              </p>
              <p className="m-t-1">
                {_t('Please also note that once you switch, you cannot switch back to the previous version.')}
              </p>
            </div>
          )}
          <div className="horizontal-box w-100 align-items-right m-t-1">
            {!willUpgradeS12n && <Button type="primary" label={_t('OK')} onClick={handleClose} />}
            {willUpgradeS12n && (
              <div>
                <Button
                  rootClassName="m-r-1"
                  type="secondary"
                  label={_t('Cancel')}
                  onClick={handleClose}
                  htmlAttributes={{ tabIndex: -1 }}
                />
                <ApiButton
                  type="primary"
                  onClick={this.handleUpgradingS12n}
                  disabled={apiStatus !== API_BEFORE_SEND}
                  apiStatus={apiStatus}
                  apiStatusAttributesConfig={{
                    label: {
                      API_BEFORE_SEND: _t('Confirm'),
                      API_IN_PROGRESS: _t('Upgrading...'),
                      API_SUCCESS: _t('Upgraded!'),
                      API_ERROR: _t('Failed!'),
                    },
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default withApiHandler({})(S12nVersioningConfirmationModal);
