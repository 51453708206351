import {
  DEFAULT_TOOLS,
  HOVERING_TOOLS,
  PAGELESS_BUTTON_SPACING,
  PAGELESS_TOOLS,
  RELATED_TOOLS,
  TOOLBAR_BUTTON_SPACING,
  TOOLBAR_PADDING,
  TYPOGRAPHY_TOOLS_SET,
} from 'bundles/cml/editor/components/toolbars/constants';
import type { ToolOptions } from 'bundles/cml/editor/components/toolbars/types';
import type { HoverToolsKeys, ToolbarToolsKeys, ToolsKeys } from 'bundles/cml/shared/utils/customTools';
import { Tools } from 'bundles/cml/shared/utils/customTools';

export const DEFAULT_TOOL_OPTIONS: ToolOptions = {
  monospace: true,
  indent: true,
  pageless: false,
  isLearnerUpload: false,
  enableWidgets: false,
  enableAiWritingAssistant: false,
};

export const getDefaultTools = (options?: ToolOptions): ToolsKeys[] => {
  const {
    monospace: enableMonospace,
    pageless: usePagelessDesign,
    enableWidgets,
    enableAiWritingAssistant,
  } = { ...DEFAULT_TOOL_OPTIONS, ...options };

  const defaultTools = usePagelessDesign ? PAGELESS_TOOLS : DEFAULT_TOOLS;
  return defaultTools.reduce((tools: ToolsKeys[], tool: ToolsKeys) => {
    if (tool === Tools.MONOSPACE && !enableMonospace) {
      return tools;
    }

    if (tool === Tools.WIDGET && !enableWidgets) {
      return tools;
    }

    if (tool === Tools.AI && !enableAiWritingAssistant) {
      return tools;
    }

    tools.push(tool);
    return tools;
  }, []);
};

export const getEditorTools = (customTools?: ToolsKeys[], options?: ToolOptions): ToolbarToolsKeys[] => {
  const { indent: enableIndent, isLearnerUpload } = { ...DEFAULT_TOOL_OPTIONS, ...options };
  if (customTools?.length === 0) {
    return [];
  }

  const tools = customTools ? [...customTools] : getDefaultTools(options);
  const toolsSet = new Set<string>();

  const hasAssetTool = tools.includes(Tools.ASSET);

  const hasList = tools?.includes(Tools.ORDERED_LIST) || tools?.includes(Tools.UNORDERED_LIST);
  const hasIndent = tools?.includes(Tools.INCREASE_INDENT) || tools?.includes(Tools.DECREASE_INDENT);

  if (hasList && !hasIndent) {
    tools?.push(Tools.INCREASE_INDENT, Tools.DECREASE_INDENT);
  }

  return tools.reduce((result: ToolbarToolsKeys[], tool: ToolsKeys) => {
    const toolKey = TYPOGRAPHY_TOOLS_SET.has(tool) ? Tools.TYPOGRAPHY : tool;

    // removes image icon from /teach, since image upload can be done from asset
    if (toolKey === Tools.IMAGE && hasAssetTool && !isLearnerUpload) {
      return result;
    }

    if (!enableIndent && (toolKey === Tools.INCREASE_INDENT || toolKey === Tools.DECREASE_INDENT)) {
      return result;
    }

    if (!toolsSet.has(toolKey)) {
      result.push(toolKey as ToolbarToolsKeys);
      toolsSet.add(toolKey);
    }

    return result;
  }, []);
};

export type ToolsWidthMap = Record<ToolsKeys, number>;

export const getVisibleTools = (
  toolbarTools: ToolbarToolsKeys[],
  pageless: boolean,
  toolsWidth: ToolsWidthMap,
  toolbarWidth: number
) => {
  const gap = pageless ? PAGELESS_BUTTON_SPACING : TOOLBAR_BUTTON_SPACING;
  const maxWidth = toolbarWidth - TOOLBAR_PADDING * 2 + gap;

  let total = 0;
  const visible: ToolbarToolsKeys[] = [];
  const overflow: ToolbarToolsKeys[] = [];
  for (const tool of toolbarTools) {
    total += (toolsWidth[tool] ?? 0) + gap;
    // 24 is the padding
    // need to add 1 gap because n icons should have n-1 gap
    if (total < maxWidth) {
      visible.push(tool);
    } else {
      overflow.push(tool);
    }
  }

  if (overflow.length && visible.length) {
    let current = visible.pop() as ToolbarToolsKeys;
    // the related tools should be in the same list(visible/expand)
    const needFindNextUnrelated = RELATED_TOOLS.find((tools) =>
      tools.some((tool, index) => tool === current && index >= 1)
    );
    if (needFindNextUnrelated) {
      const temp = [current];
      const flatRelatedTools = RELATED_TOOLS.flat();
      // find the next unrelated tool
      while (visible.length) {
        const next = visible.pop() as ToolbarToolsKeys;
        if (flatRelatedTools.some((tool) => tool === next)) {
          temp.push(next);
        } else {
          visible.push(...temp.reverse());
          current = next;
          break;
        }
      }
    }
    overflow.unshift(current);
  }

  return { visible, overflow };
};

export const getHoveringTools = (customTools?: ToolsKeys[], options?: ToolOptions): HoverToolsKeys[] => {
  const tools = getEditorTools(customTools, { ...options, pageless: true });
  return HOVERING_TOOLS.filter((tool: HoverToolsKeys) => tools.includes(tool));
};
