/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { Props } from 'bundles/program-home/components/enterprise-home/MyCoursesContainer';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import MyCoursesContainerWrapper from 'bundles/program-home/components/enterprise-home/MyCoursesContainerWrapper';
import { withMyCoursesContainer } from 'bundles/program-home/components/enterprise-home/withMyCoursesContainer';
import SavedTabContainer from 'bundles/program-home/components/myLearning/SavedTabContainer';
import TabContentDisplayModule from 'bundles/program-home/components/myLearning/TabContentDisplayModule';
import SkillsDashboard from 'bundles/program-home/components/myLearning/skillsDashboard/SkillsDashboard';
import { QUERY_PARAM_VALUES } from 'bundles/unified-home-common/constants';
import { CurrentTabContextProvider } from 'bundles/unified-home-common/providers/consumer/CurrentTabContextProvider';

import _t from 'i18n!nls/program-home';

const styles = {
  pageContainer: css`
    min-height: 500px;
  `,
};

const MyLearningPage = ({
  programId,
  programSlug,
  programName,
  userId,
  onProductCardClick,
  userSkillProfileStates,
  userSkillProfileStatesLoading,
  onSkillSetSaveToggle,
  userEmails,
  shouldShowEulaBanner,
  thirdPartyOrganization,
  enableCurriculumBuilder,
  allowOrgForSpecializationConfiguration,
  isCareerAcademyLimitedCatalogProgram,
  toggleCatalogTab,
  enableSkillsDashboard = false,
  completedProducts,
  inProgressEnrolledProducts,
  futureProductIds,
  products,
  unfilteredOrderedFutureProducts,
  enterpriseBadgeCollections,
  enrolledBadgeCollections,
  loadingCourses,
  loadingS12ns,
}: Props) => {
  const getMyLearningTabs = (showSkills: boolean) => {
    const myLearningTabs = [
      {
        tabName: _t('In Progress'),
        queryParamValue: QUERY_PARAM_VALUES.IN_PROGRESS,
        content: (
          <MyCoursesContainerWrapper
            programId={programId}
            programSlug={programSlug}
            programName={programName}
            userId={userId}
            onProductCardClick={onProductCardClick}
            userSkillProfileStates={userSkillProfileStates}
            userSkillProfileStatesLoading={userSkillProfileStatesLoading}
            onSkillSetSaveToggle={onSkillSetSaveToggle}
            toggleCatalogTab={toggleCatalogTab}
            userEmails={userEmails}
            thirdPartyOrganization={thirdPartyOrganization}
            shouldShowEulaBanner={shouldShowEulaBanner}
            enableCurriculumBuilder={enableCurriculumBuilder}
            isCareerAcademyLimitedCatalogProgram={isCareerAcademyLimitedCatalogProgram}
            allowOrgForSpecializationConfiguration={allowOrgForSpecializationConfiguration}
            futureProductIds={futureProductIds}
            products={products}
            unfilteredOrderedFutureProducts={unfilteredOrderedFutureProducts}
            enterpriseBadgeCollections={enterpriseBadgeCollections}
            enrolledBadgeCollections={enrolledBadgeCollections}
            loadingCourses={loadingCourses}
            loadingS12ns={loadingS12ns}
            unfilteredOrderedPresentProducts={inProgressEnrolledProducts}
          />
        ),
        trackingName: 'program_home_mylearning_inprogress',
      },
      {
        tabName: _t('Saved'),
        queryParamValue: QUERY_PARAM_VALUES.SAVED,
        content: <SavedTabContainer programId={programId} toggleCatalogTab={toggleCatalogTab} />,
        trackingName: 'program_home_mylearning_saved',
      },
      {
        tabName: _t('Completed'),
        queryParamValue: QUERY_PARAM_VALUES.COMPLETED,
        content: (
          <MyCoursesContainerWrapper
            programId={programId}
            programSlug={programSlug}
            programName={programName}
            userId={userId}
            onProductCardClick={onProductCardClick}
            userSkillProfileStates={userSkillProfileStates}
            userSkillProfileStatesLoading={userSkillProfileStatesLoading}
            onSkillSetSaveToggle={onSkillSetSaveToggle}
            toggleCatalogTab={toggleCatalogTab}
            userEmails={userEmails}
            thirdPartyOrganization={thirdPartyOrganization}
            shouldShowEulaBanner={shouldShowEulaBanner}
            enableCurriculumBuilder={enableCurriculumBuilder}
            isCareerAcademyLimitedCatalogProgram={isCareerAcademyLimitedCatalogProgram}
            allowOrgForSpecializationConfiguration={allowOrgForSpecializationConfiguration}
            futureProductIds={futureProductIds}
            products={products}
            unfilteredOrderedFutureProducts={unfilteredOrderedFutureProducts}
            enterpriseBadgeCollections={enterpriseBadgeCollections}
            enrolledBadgeCollections={enrolledBadgeCollections}
            loadingCourses={loadingCourses}
            loadingS12ns={loadingS12ns}
            unfilteredOrderedPresentProducts={completedProducts}
          />
        ),
        trackingName: 'program_home_mylearning_completed',
      },
    ];
    const skillsTab = {
      tabName: _t('Skills'),
      queryParamValue: QUERY_PARAM_VALUES.SKILLS_DASHBOARD,
      content: <SkillsDashboard programId={programId} programSlug={programSlug} />,
      trackingName: 'program_home_mylearning_skills',
    };

    if (showSkills) {
      return myLearningTabs.concat(skillsTab);
    }

    return myLearningTabs;
  };

  const learningTabs = getMyLearningTabs(enableSkillsDashboard);

  return (
    <CurrentTabContextProvider>
      <div css={styles.pageContainer}>
        <TabContentDisplayModule learningTabs={learningTabs} />
      </div>
    </CurrentTabContextProvider>
  );
};

export default withMyCoursesContainer(MyLearningPage);
