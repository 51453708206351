import type { Descendant, Location, NodeEntry, Path } from 'slate';
import { Editor, Element, Node, Range, Text, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { BLOCK_VALUES } from 'bundles/cml/shared/constants';
import type { BlockElement, InlineElement } from 'bundles/cml/shared/types/elementTypes';

export const isVoidOrTable = (node?: InlineElement | BlockElement): boolean => {
  return node?.isVoid || (node as BlockElement)?.type === BLOCK_TYPES.TABLE;
};

export const isCurrentLocationEmptyTextNode = (editor: Editor) => {
  if (!editor.selection || !Range.isCollapsed(editor.selection)) {
    return false;
  }

  const [index] = Editor.start(editor, editor.selection).path;
  const node = editor.children[index];

  return (
    Element.isElement(node) &&
    (node.type === BLOCK_TYPES.TEXT || node.type === BLOCK_TYPES.HEADING) &&
    !Node.string(node)
  );
};

export const insertBlockNodes = (editor: Editor, nodes: BlockElement | BlockElement[]) => {
  if (isCurrentLocationEmptyTextNode(editor)) {
    Editor.withoutNormalizing(editor, () => {
      const numNodes = Array.isArray(nodes) ? nodes.length : 1;
      const index = editor.selection?.anchor.path[0] ?? 0;
      Transforms.insertNodes(editor, nodes, { at: [index] });
      Transforms.removeNodes(editor, { at: [index + numNodes] });
      Transforms.select(editor, [index]);
    });
    return;
  }

  Transforms.insertNodes(editor, nodes, { mode: 'highest' });
};

export const removeElement = (editor: Editor, element: Element) => {
  const path = ReactEditor.findPath(editor, element);
  Transforms.removeNodes(editor, { at: path });
};

export const getAncestorOfType = (
  editor: Editor,
  type: BLOCK_VALUES | BLOCK_VALUES[],
  at?: Location
): NodeEntry<Node> | undefined => {
  const types = Array.isArray(type) ? type : [type];
  const nodes = Array.from(
    Editor.nodes(editor, { at, match: (node) => Element.isElement(node) && types.includes(node.type) })
  ).reverse();
  return nodes[0];
};

export const hasAncestorOfType = (editor: Editor, type: BLOCK_VALUES | BLOCK_VALUES[], at?: Location): boolean => {
  return !!getAncestorOfType(editor, type, at);
};

export const focusNextBlock = (editor: Editor, path: Path) => {
  const point = Editor.after(editor, path, { unit: 'block' });
  if (point) {
    Transforms.select(editor, point);
  }
};

export const focusPreviousBlock = (editor: Editor, path: Path) => {
  const point = Editor.before(editor, path, { unit: 'block' });
  if (point) {
    Transforms.select(editor, point);
  }
};

export const getCurrentNode = (editor: Editor): Descendant | undefined => {
  const selectionPath = editor.selection?.anchor.path[0];

  return selectionPath ? editor.children[selectionPath] : undefined;
};

export const getPreviousNode = (editor: Editor) => {
  const { selection } = editor;
  const selectionPath = selection?.anchor.path[0];

  const prevNode = Editor.previous(editor, { at: [selectionPath || 0] });

  return prevNode && (prevNode[0] as Element);
};

export const getNextNode = (editor: Editor) => {
  const { selection } = editor;
  const selectionPath = selection?.anchor.path[0];

  const nextNode = Editor.next(editor, { at: [selectionPath || 0] });

  return nextNode && (nextNode[0] as Element);
};

export const getTextNode = (editor: Editor, defaultPath?: Path) => {
  const path = defaultPath || editor.selection?.anchor.path || [];
  const [element] = Editor.node(editor, path);

  return Text.isText(element) ? element.text : '';
};
