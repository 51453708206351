import gql from 'graphql-tag';

/* eslint-disable graphql/template-strings */
export const SaveProductQuery = gql`
  query SaveProductQuery($input: SimpleProductRequestQueryVariables!) {
    SaveProductResponse(input: $input)
      @rest(
        type: "SaveProductResponse"
        path: "grpc/discoverybehaviors/savedproducts/v1beta1/SavedProductsAPI/SaveProduct"
        method: "POST"
      ) {
      SaveProductResponse
    }
  }
`;

/* eslint-disable graphql/template-strings */
export const DeleteSavedProductQuery = gql`
  query DeleteSavedProductQuery($input: SimpleProductRequestQueryVariables!) {
    DeleteSavedProductResponse(input: $input)
      @rest(
        type: "DeleteSavedProductResponse"
        path: "grpc/discoverybehaviors/savedproducts/v1beta1/SavedProductsAPI/DeleteSavedProduct"
        method: "POST"
      ) {
      DeleteSavedProductResponse
    }
  }
`;

/* eslint-disable graphql/template-strings */
export const GetSavedProductsQuery = gql`
  query GetSavedProductsQuery($input: SimpleProductRequestQueryVariables!) {
    GetSavedProductsResponse(input: $input)
      @rest(
        type: "GetSavedProductsResponse"
        path: "grpc/discoverybehaviors/savedproducts/v1beta1/SavedProductsAPI/GetSavedProducts"
        method: "POST"
      ) {
      savedProduct
      nextPageToken
      totalSize
    }
  }
`;

/* eslint-disable graphql/template-strings */
export const IsProductSavedQuery = gql`
  query IsProductSavedQuery($input: SimpleProductRequestQueryVariables!) {
    IsProductSavedResponse(input: $input)
      @rest(
        type: "IsProductSavedResponse"
        path: "grpc/discoverybehaviors/savedproducts/v1beta1/SavedProductsAPI/IsProductSaved"
        method: "POST"
      ) {
      isSavedProduct
    }
  }
`;
