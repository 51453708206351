import * as React from 'react';

import { StyleContext } from 'bundles/cml/editor/context/styleContext';

type Props = {
  element: HTMLElement | null;
  pageless: boolean;
};

const StyleContextProvider: React.FC<Props> = ({ element, pageless, children }) => {
  return <StyleContext.Provider value={{ editorNode: element, pageless }}>{children}</StyleContext.Provider>;
};

export default StyleContextProvider;
