import videojs from 'video.js';

const getAttributes = (tag) => {
  const obj = {};

  // known boolean attributes
  // we can check for matching boolean properties, but older browsers
  // won't know about HTML5 boolean attributes that we still read from
  const knownBooleans = ',autoplay,controls,playsinline,loop,muted,default,defaultMuted,';

  if (tag && tag.attributes && tag.attributes.length > 0) {
    const attrs = tag.attributes;

    for (let i = attrs.length - 1; i >= 0; i -= 1) {
      const attrName = attrs[i].name;
      let attrVal = attrs[i].value;

      // check for known booleans
      // the matching element property will return a value for typeof
      if (typeof tag[attrName] === 'boolean' || knownBooleans.indexOf(',' + attrName + ',') !== -1) {
        // the value of an included boolean attribute is typically an empty
        // string ('') which would equal false if we just check for a false value.
        // we also don't want support bad code like autoplay='false'
        attrVal = attrVal !== null;
      }

      obj[attrName] = attrVal;
    }
  }

  return obj;
};

class LocalFilePlayer extends videojs.getComponent('Player') {
  constructor(player, options) {
    super(player, options);
    this.el_.classList.add('customComponent-LocalFilePlayer');
  }

  updateSourceCaches_(srcObj) {
    let src = srcObj;
    let type = '';

    if (typeof src !== 'string') {
      src = srcObj.src;
      type = srcObj.type;
    }

    // NOTE: the following code was preventing local files from playing

    // if we are a blob url, don't update the source cache
    // blob urls can arise when playback is done via Media Source Extension (MSE)
    // such as m3u8 sources with @videojs/http-streaming (VHS)
    // if (/^blob:/.test(src)) {
    //   return;
    // }

    // make sure all the caches are set to default values
    // to prevent null checking
    this.cache_.source = this.cache_.source || {};
    this.cache_.sources = this.cache_.sources || [];

    // update `currentSource` cache always
    this.cache_.source = Object.assign({}, srcObj, { src, type });

    const matchingSources = this.cache_.sources.filter((s) => {
      return s.src && s.src === src;
    });

    const sourceElSources = [];
    const sourceEls = this.$$('source');
    const matchingSourceEls = [];

    for (let i = 0; i < sourceEls.length; i += 1) {
      const sourceObj = getAttributes(sourceEls[i]);

      sourceElSources.push(sourceObj);

      if (sourceObj.src && sourceObj.src === src) {
        matchingSourceEls.push(sourceObj.src);
      }
    }

    // if we have matching source els but not matching sources
    // the current source cache is not up to date
    if (matchingSourceEls.length && !matchingSources.length) {
      this.cache_.sources = sourceElSources;
      // if we don't have matching source or source els set the
      // sources cache to the `currentSource` cache
    } else if (!matchingSources.length) {
      this.cache_.sources = [this.cache_.source];
    }

    // update the tech `src` cache
    this.cache_.src = src;
  }
}

videojs.registerComponent('player', LocalFilePlayer);
