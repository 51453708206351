/** @jsx jsx */

/**  @jsxFrag jsx  */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { memo } from 'react';

import moment from 'moment';
import { compose, withProps } from 'recompose';

import useRouter from 'js/lib/useRouter';
import { formatDateTimeDisplay } from 'js/utils/DateTimeUtils';

import { Grid, breakpoints } from '@coursera/cds-core';
import type { GridProps, ProductCardProps } from '@coursera/cds-core';

import type { EnterpriseCardData } from 'bundles/enterprise-learner-search/components/search/EnterpriseSearchCard';
import EnterpriseSearchCard from 'bundles/enterprise-learner-search/components/search/EnterpriseSearchCard';
import withEnterpriseProgramMetadata from 'bundles/enterprise-learner-search/components/search/withEnterpriseProductMetadata';
import type {
  PrivateSession,
  Props as PropsFromWithEnterpriseProductMetadata,
} from 'bundles/enterprise-learner-search/components/search/withEnterpriseProductMetadata';
import { getCleanIdFromObjectId } from 'bundles/enterprise-learner-search/utils/getCleanIdFromObjectId';
import { shouldRedirectToUnifiedDescriptionPage } from 'bundles/enterprise-legacy-xdp/hocs/withMiniModal';
import filterExistsOrDefault from 'bundles/program-common/utils/filterExistsOrDefault';
import { ProductsCardsCdsPlaceholder } from 'bundles/search-common/components/search-cards/Placeholder';
import { LEARNING_PRODUCTS_ENUM_MAP, getTranslatedDifficulty } from 'bundles/search-common/constants';
import type { LEARNING_PRODUCTS, PRODUCT_DIFFICULTY_LEVELS } from 'bundles/search-common/constants';
import type { SearchProductHit } from 'bundles/search-common/providers/searchTypes';
import type { SearchIndexHit } from 'bundles/search-common/types';
import { getIsPathwayContent } from 'bundles/search-common/utils/SearchUtils';

import _t from 'i18n!nls/program-home';

const styles = {
  container: css`
    list-style: none;
    padding-left: 0;
  `,
  searchCardWrapper: () => css`
    height: 100%;
    padding: 0 6px 24px;
  `,
  /* TrackedLink2 adds an extra div that interferes with the search card styling. 
  This wrapper div applies style to that extra div to ensure the search cards are styled correctly. */
  targetSearchCardWrapper: css`
    height: 100%;
    padding: 0 6px 24px;

    > * {
      height: inherit;
    }
    ${breakpoints.down('xs')} {
      padding: 0 0 24px 0;
    }
  `,
};

type ProductForEnterpriseMetadata = {
  id: string;
  offeringType: string; // using a string because of built in translator in the HOC
};

type PropsForEnterpriseMetadata = {
  products: ProductForEnterpriseMetadata[];
};

type InputProps = {
  hits?: SearchProductHit[];
  isLoading?: boolean;
  noResults?: boolean;
  resultsPerPage: number;
  eventingData?: {
    searchIndex: string;
    searchIndexPosition: number;
    page?: string;
    searchQuery?: string;
    filtersApplied?: Record<string, string[]>;
  };
  searchCardImageProps?: { fit: string };
  programId?: string;
  thirdPartyOrganizationId: string;
  renderSearchHit?: (hit: SearchProductHit, cardData?: EnterpriseCardData) => React.ReactNode;
  placeholder?: JSX.Element;
  gridSpacing?: GridProps['spacing'];
  variant?: ProductCardProps['variant'];
  renderAdminContent?: (hit: SearchProductHit) => ProductCardProps['adminContent'];
  onSearchCardClick?: (hit: SearchProductHit) => void;
};

type PropsToComponent = InputProps & PropsFromWithEnterpriseProductMetadata;

const getEnterprisePrivateSession = (id: string, privateSessions?: Array<PrivateSession | null>) => {
  return privateSessions?.find((session) => session?.productId === id);
};

export const isShortFormContent = (entityType?: string | null) => ['VIDEO', 'LESSON'].includes(entityType || '');

export const combineHitData = (hits: SearchProductHit[], privateSessions?: Array<PrivateSession | null>) =>
  hits.map((hit) => {
    const searchableId = getCleanIdFromObjectId(hit.id || '');
    const hitPrivateSession = getEnterprisePrivateSession(searchableId, privateSessions);

    if (hitPrivateSession?.startsAt && hitPrivateSession?.endsAt) {
      const startsAt = formatDateTimeDisplay(hitPrivateSession?.startsAt, 'MMM D');
      const endsAt = formatDateTimeDisplay(hitPrivateSession?.endsAt, 'MMM D, YYYY');
      const session = `${startsAt} - ${endsAt}`;

      return {
        ...hit,
        session,
      };
    } else {
      return hit;
    }
  });

type productType = (typeof LEARNING_PRODUCTS)[keyof typeof LEARNING_PRODUCTS];
const mapToSearchIndexHit = (hits?: SearchProductHit[]): SearchIndexHit[] => {
  // TODO: refactor EnterpriseSearchCard to take SearchProductHit and remove this mapping
  // @ts-expect-error URL: string
  return (
    hits?.map((hit) => {
      const productDifficultyLevel = getTranslatedDifficulty(
        hit.productDifficultyLevel || ''
      ) as (typeof PRODUCT_DIFFICULTY_LEVELS)[keyof typeof PRODUCT_DIFFICULTY_LEVELS];
      return {
        ...hit,
        id: hit.id || undefined,
        name: hit.translatedName || hit.name || undefined,
        isFree: !!hit.isCourseFree,
        isPartOfCourseraPlus: !!hit.isPartOfCourseraPlus,
        isCreditEligible: !!hit.isCreditEligible,
        isNewContent: hit.isNewContent || undefined,
        isPathwayContent: getIsPathwayContent(hit) || undefined,
        entityType: hit.productType as productType,
        objectUrl: hit.url || undefined,
        productDifficultyLevel,
        productDuration: hit.productDuration || undefined,
        partners: hit.partners || undefined,
        partnerLogos: hit.partnerLogos || undefined,
        avgProductRating: hit.avgProductRating || undefined,
        numProductRatings: hit.numProductRatings || undefined,
        skills: hit.translatedSkills || hit.skills || undefined,
        parentCourseName: hit.translatedParentCourseName || hit.parentCourseName || undefined,
        parentLessonName: hit.translatedParentLessonName || hit.parentLessonName || undefined,
        videosInLesson: hit.videosInLesson || undefined,
        completions: hit.completions || undefined,
        cobrandingEnabled: !!hit.cobrandingEnabled,
      };
    }) || []
  );
};

export const EnterpriseSearchCards = ({
  hits,
  noResults = false,
  resultsPerPage,
  eventingData,
  privateSessions,
  renderSearchHit,
  placeholder,
  gridSpacing,
  isLoading,
  variant,
  renderAdminContent,
  onSearchCardClick,
}: PropsToComponent) => {
  const router = useRouter();

  if (isLoading || !hits) {
    if (placeholder) return placeholder;
    return <ProductsCardsCdsPlaceholder {...{ resultsPerPage, variant }} />;
  }

  const combinedHits = combineHitData(hits, privateSessions);
  const trackingName = noResults ? 'no_results_search_card' : 'search_card';
  // Directly link to unified description page if no in multi-program home.
  const redirectToUnifiedDescriptionPage = shouldRedirectToUnifiedDescriptionPage(router);

  return (
    <Grid container component="ul" css={styles.container} spacing={gridSpacing} className="rc-EnterpriseSearchCards">
      {combinedHits.length < 1 && <div>{_t('No results found')}</div>}
      {combinedHits.map((hit: SearchProductHit, hitPosition) => {
        const isSFC = isShortFormContent(hit.productType);
        const searchQuery = router.location.search;
        let urlToUse = redirectToUnifiedDescriptionPage
          ? `/programs/${router.params.programSlug}${hit.url}?source=search`
          : undefined;
        urlToUse = isSFC ? `${hit.url}${searchQuery}` : urlToUse;
        const entityType = LEARNING_PRODUCTS_ENUM_MAP[hit.productType?.toUpperCase() || ''];
        const mappedHit: SearchIndexHit = {
          ...mapToSearchIndexHit([hit])[0],
          productDuration: undefined, // removed for feature parity
          entityType,
          durationMs: moment.duration(hit.duration).asMilliseconds(),
        };

        const cardData: EnterpriseCardData = {
          objectID: hit.id ?? '',
          hitPosition,
          query: eventingData?.searchQuery || '',
          queryID: eventingData?.searchQuery || '',
          listIndex: Number(eventingData?.page) || 1,
          hasNoResults: noResults,
          filtersApplied: eventingData?.filtersApplied || {},
          indexName: eventingData?.searchIndex,
          indexPosition: eventingData?.searchIndexPosition,
        };

        if (renderSearchHit) {
          return renderSearchHit(hit, cardData);
        }

        return (
          <Grid
            key={hit.id}
            item
            md={variant === 'list' ? 12 : 4}
            sm={variant === 'list' ? 12 : 6}
            xs={12}
            component="li"
          >
            <div css={styles.targetSearchCardWrapper}>
              <EnterpriseSearchCard
                key={hit.id}
                {...{
                  isSFC,
                  urlToUse,
                  trackingName,
                  cardData,
                  hit: mappedHit,
                  variant,
                  adminContent: renderAdminContent?.(hit),
                  onClick: onSearchCardClick ? () => onSearchCardClick?.(hit) : undefined,
                }}
              />
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default memo(
  compose<PropsToComponent, InputProps>(
    withProps<PropsForEnterpriseMetadata, PropsToComponent>(({ hits }) => {
      const filteredHits = filterExistsOrDefault(hits);

      const products = filteredHits
        .filter((hit) => !!hit.id && !!hit.productType)
        .map(({ id, productType }) => ({
          id: getCleanIdFromObjectId(id || ''),
          offeringType: String(productType),
        }));

      return {
        products,
      };
    }),
    withEnterpriseProgramMetadata
  )(EnterpriseSearchCards)
);
