import * as React from 'react';

import { Typography } from '@coursera/cds-core';
import type { TypographyProps } from '@coursera/cds-core';

const AutoHeadingContext = React.createContext<number | undefined>(undefined);

type PropsForProvider = {
  level: number;
  children: React.ReactNode;
};

const HeadingProvider = ({ level, children }: PropsForProvider) => (
  <AutoHeadingContext.Provider value={level}>{children}</AutoHeadingContext.Provider>
);

type PropsForHeading = {
  defaultLevel?: number;
  variant?: TypographyProps['variant'];
  color?: TypographyProps['color'];
} & Omit<React.HTMLAttributes<HTMLHeadingElement>, 'color'>;

// TODO(ppaskaris): push this into the PropsForHeading and make callers specify how they want it to look.
const CdsVariants = new Map<number, TypographyProps['variant']>([
  [1, 'd2'],
  [2, 'h1semibold'],
  [3, 'h2semibold'],
  [4, 'h3semibold'],
  [5, 'h4bold'],
  [6, 'h4bold'],
]);

const Heading = ({ defaultLevel, children, variant, color, ...props }: PropsForHeading) => {
  let level = React.useContext(AutoHeadingContext);
  if (level == null) {
    if (defaultLevel == null) {
      throw new Error('<Heading /> must be nested within <Section /> or supply a defaultLevel prop.');
    } else {
      level = defaultLevel;
    }
  }
  if (level < 1) {
    level = 1;
  }
  if (level > 6) {
    level = 6;
  }

  const component = `h${level}` as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

  const variant0 = variant ?? CdsVariants.get(level);
  const props0 = props as Omit<TypographyProps, 'variant' | 'component'>;
  return (
    <Typography variant={variant0} component={component} color={color} {...props0}>
      {children}
    </Typography>
  );
};

type PropsForSection = {
  initialLevel?: number;
  children: React.ReactNode;
};

const Section = ({ initialLevel, children }: PropsForSection) => {
  let level = React.useContext(AutoHeadingContext);
  if (level == null) {
    if (initialLevel == null) {
      throw new Error('<Section /> must be nested within <HeadingProvider /> or have a initialLevel prop.');
    } else {
      level = initialLevel;
    }
  } else {
    level += 1;
  }

  return <HeadingProvider level={level}>{children}</HeadingProvider>;
};

export { Heading, Section, HeadingProvider };
